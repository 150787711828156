<div *ngIf="showColumn()" id="open-file-menu">
	<section id="open-file-menu" class="left-column hamburger-mode" [ngClass]="{ 'visible': showColumn()  }">

		<div class="list-container">
			<div class="list-header d-flex justify-content-between">
				<div class="list-title">
					<span>Open Files ({{ list().length }})</span>
				</div>
				<div class="close-button" (click)="toggleLeftColumn()">
					<fa-icon [icon]="closeIcon"></fa-icon>
				</div>
			</div>

			<div class="search-container">
				<input #textbox class="e-input" type="text" id="textbox" placeholder="Filter" title="Type in a name or case number" (keyup)="handleSearch()" />
			</div>

			<ejs-listview id='list' #casefilelist [dataSource]='list()' cssClass='e-list-template' [showHeader]='false' sortOrder='Ascending' (select)="onSelect()">
				<ng-template #template let-data>
					<div [id]="data.id" class="list-item e-card d-flex flex-row justify-content-between align-items-center" (click)="onClick($event)">
						<div [id]="data.id" class="item-content">
							<span class="name">{{data.name}}</span>
							<span class="id">{{data.id}}</span>
						</div>
						<div class="item-close">
							<button ejs-button (click)="deleteItem($event)">
								<fa-icon [icon]="deleteIcon"></fa-icon>
							</button>
						</div>
					</div>
				</ng-template>
			</ejs-listview>

		</div>
		<div class="blur"></div>
	</section>
</div>