/**
 * @internal
 * Internal HTTP error interceptor.
 * Automatically handles HTTP errors through ErrorHandlingService.
 * Do not modify or bypass this interceptor.
 */
import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlingService } from '@core/error/error.service';

export const ErrorInterceptor: HttpInterceptorFn = (req, next) => {
    const errorHandling = inject(ErrorHandlingService);

    return next(req).pipe(
        catchError(error => {
            if (error instanceof HttpErrorResponse) {
                errorHandling.handleError(error, {
                    context: 'HTTP',
                    userMessage: 'Request failed'
                });
            }
            return throwError(() => error);
        })
    );
};
