const itDict = {
  'Account recovery requires verified contact information': "Il ripristino dell'account richiede informazioni di contatto verificate",
  'Authenticator App (TOTP)': 'App di autenticazione (TOTP)',
  'Back to Sign In': 'Torna alla schermata di accesso',
  'Change Password': 'Cambia la password',
  Changing: 'Modifica in corso',
  Code: 'Codice',
  'Confirm Email Code': 'Conferma codice e-mail',
  'Confirm Password': 'Conferma la password',
  'Confirm Sign Up': 'Conferma registrazione',
  'Confirm SMS Code': 'Conferma codice SMS',
  'Confirm TOTP Code': 'Conferma codice TOTP',
  Confirm: 'Conferma',
  'Confirmation Code': 'Codice di verifica',
  Confirming: 'Conferma in corso',
  'Create a new account': 'Crea un nuovo account',
  'Create Account': 'Crea Account',
  'Creating Account': 'Creazione account in corso',
  'Dismiss alert': `Ignora l'avviso`,
  Email: 'Email',
  'Email Message': 'Messaggio e-mail',
  'Enter your code': 'Inserisci il tuo codice',
  'Enter your Email': 'Inserisci la tua e-mail',
  'Enter your phone number': 'Inserisci il tuo numero di telefono"',
  'Enter your username': 'Inserisci il tuo nome utente',
  'Forgot your password?': 'Password dimenticata?',
  'Hide password': 'Nascondi password',
  'It may take a minute to arrive': "L'arrivo potrebbe richiedere qualche minuto",
  Loading: 'Caricamento in corso',
  'Multi-Factor Authentication': 'Autenticazione a più fattori',
  'Multi-Factor Authentication Setup': "Configurazione dell'autenticazione a più fattori",
  'New password': 'Nuova password',
  or: 'oppure',
  Password: 'Password',
  'Phone Number': 'Numero di telefono',
  'Resend Code': 'Invia nuovamente il codice',
  'Reset your Password': 'Reimposta la tua Password',
  'Reset your password': 'Reimposta la tua password',
  'Select MFA Type': 'Seleziona il tipo di MFA',
  'Send code': 'Invia codice',
  'Send Code': 'Invia codice',
  Sending: 'Invio in corso',
  'Setup Email': "Configura l'e-mail",
  'Setup TOTP': 'Configura TOTP',
  'Show password': 'Mostra password',
  'Sign in to your account': 'Accedi al tuo account',
  'Sign In with Amazon': 'Accedi con Amazon',
  'Sign In with Apple': 'Accedi con Apple',
  'Sign In with Facebook': 'Accedi con Facebook',
  'Sign In with Google': 'Accedi con Google',
  'Sign in': 'Accedi',
  'Sign In': 'Accedi',
  'Signing in': 'Accesso in corso',
  Skip: 'Salta',
  Submit: 'Invia',
  Submitting: 'Invio in corso',
  'Text Message (SMS)': 'Messaggio di testo (SMS)',
  Username: 'Nome utente',
  'Verify Contact': 'Verifica contatto',
  Verify: 'Verifica',
  'We Emailed You': "Ti abbiamo inviato un'e-mail",
  'We Sent A Code': 'Ti abbiamo inviato un codice',
  'We Texted You': 'Ti abbiamo inviato un SMS',
  'Your code is on the way. To log in, enter the code we emailed to': "Il codice è in arrivo. Per effettuare l'accesso, immetti il codice che ti abbiamo inviato via e-mail",
  'Your code is on the way. To log in, enter the code we sent you': 'Il codice è in arrivo. Per accedere, immetti il codice che ti abbiamo inviato',
  'Your code is on the way. To log in, enter the code we texted to': 'Il codice è in arrivo. Per accedere, immetti il codice che abbiamo inviato tramite SMS',
  // Additional translations provided by customers
  'An account with the given email already exists.': 'Questa email è già utilizzata da un altro account.',
  'Confirm a Code': 'Conferma un codice',
  'Confirm Sign In': "Conferma l'accesso",
  'Create account': 'Crea account',
  'Enter your password': 'Inserisci la tua password',
  'Forgot Password?': 'Password dimenticata?',
  'Have an account? ': 'Già registrato?',
  'Incorrect username or password': 'Nome utente o password errati',
  'Invalid password format': 'Formato della password non valido',
  'Invalid phone number format': 'Formato del numero di telefono non valido',
  'Lost your code?': 'Codice smarrito?',
  'New Password': 'Nuova password',
  'No account? ': 'Non hai un account?',
  'Password attempts exceeded': 'Il numero massimo di tentativi di accesso falliti è stato raggiunto',
  'Reset password': 'Reimposta password',
  'Sign Out': 'Esci',
  'Sign Up': 'Registrati',
  'User already exists': 'Utente già esistente',
  'User does not exist': 'Utente inesistente',
  'Username cannot be empty': 'Il nome utente non può essere vuoto'
};
export { itDict };