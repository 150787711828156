// Angular
import { Component, computed, Input, OnInit, OnDestroy, signal, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// 3rd Party
import { ButtonAllModule } from '@syncfusion/ej2-angular-buttons';
import { AccordionAllModule, ExpandedEventArgs } from '@syncfusion/ej2-angular-navigations';
import { TooltipModule, Tooltip, DialogModule, DialogComponent } from '@syncfusion/ej2-angular-popups';

// Internal
import { PerformanceData } from '@features/financial/models/financial-performance.model';
import { FileHubService } from '@features/file-hub/services/file-hub.service';
import { LoadingModule } from '@modules/loading.module';
import { DataUtilsService } from '@services/globals/data-utils.service';
import { safeFormatDateToMMDDYYYY } from '@app/utils';

// Add type definitions
interface PatientHeader {
  id: string;
  label: string;
  value: any;
}

interface AdditionalInfo {
  id: string;
  label: string;
  value: any;
}

@Component({
  selector: 'patient-info',
  standalone: true,
  imports: [CommonModule, ButtonAllModule, AccordionAllModule, TooltipModule, LoadingModule, DialogModule],
  templateUrl: './patient-info.component.html',
  styleUrl: './patient-info.component.scss',
})
export class PatientInfoComponent implements OnInit, OnDestroy {
  constructor(
    private fileHub: FileHubService,
    private dataUtils: DataUtilsService
  ) {}

  @ViewChild('AuthLimitWarningDialog') authLimitWarningDialog!: DialogComponent;

  // Inputs
  @Input() inputData!: any | null;

  // Signals
  protected readonly loading = {
    performance: signal(true),
    patient: signal(true),
  };
  protected readonly data = {
    additionalInfo: signal<AdditionalInfo | null>(null),
    header: signal<PatientHeader[]>([]),
    phoneNumber: signal('Loading...'),
  };
  protected readonly ui = {
    columnClass: signal('col-'),
    expandText: signal('Show Additional Info'),
  };

  // Computed
  protected readonly isPerformanceLoaded = computed(() =>
    !this.loading.performance() && !!this.fileHub.performanceData
  );

  protected readonly showAuthLimitWarning = computed(() => {
    const { BalanceDue, AuthorizedAmount } = this.fileHub.performanceData || {};
    // Return false if either value is missing or both are zero
    if (
      !BalanceDue ||
      !AuthorizedAmount ||
      !this.fileHub.authLimitPercentage ||
      (BalanceDue === 0 && AuthorizedAmount === 0) ||
      BalanceDue < 0
    ) {
      return false;
    }
    const balanceDuePercentage = (BalanceDue / AuthorizedAmount) * 100;
    return balanceDuePercentage >= this.fileHub.authLimitPercentage;
  });

  // Clean up subscriptions
  private destroy$ = new Subject<void>();

  // Initialize data
  ngOnInit() {
    this.initializeData();

    // Subscribe to caseFile changes to detect when performanceData updates
    this.fileHub.caseFile$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (this.fileHub.performanceData) {
          this.updateHeaderWithPerformanceData();
        }
      });
  }

  // Clean up subscriptions
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // Apply DOM changes
  ngAfterViewInit() {
    this.setColumnWidth();
    setTimeout(() => this.initializeTooltip(), 100);
  }

  // Await all data to load
  private async initializeData() {
    await this.mapBasicInfo();

    // If performance data is not available yet, fetch it
    if (!this.fileHub.performanceData) {
      try {
        await this.fileHub.setPerformanceData();
      } catch (error) {
        console.error('Error loading performance data:', error);
      } finally {
        this.loading.performance.set(false);
      }
    }

    // Update the header with performance data (whether it was just fetched or already available)
    this.updateHeaderWithPerformanceData();
  }

  // Set basic info displayed in header
  private async mapBasicInfo() {
    if (!this.fileHub.caseFile?.Patient) return;
    const patientPhoneNumber = this.fileHub.caseFile.Patient.XrefPhonePatients?.[0]?.Phone?.PhoneNumber;

    this.data.header.set([
      { id: 'dob', label: 'DOB', value: safeFormatDateToMMDDYYYY(this.fileHub.caseFile.Patient.Dob) },
      { id: 'dol', label: 'DOL', value: safeFormatDateToMMDDYYYY(this.fileHub.caseFile.DateOfLoss) },
      {
        id: 'client-state',
        label: 'Client State',
        value: this.fileHub.caseFile.Patient.XrefAddressPatients?.[0]?.Address?.StateNavigation?.Name ?? 'N/A',
      },
      {
        id: 'phone',
        label: 'Phone',
        value: this.fileHub.caseFile.Patient.XrefPhonePatients?.[0]?.Phone?.PhoneNumber ?? 'N/A',
      },
      {
        id: 'email',
        label: 'Email',
        value: {
          text: this.fileHub.caseFile.Patient.Email ?? 'N/A',
          isLink: true,
          href: `mailto:${this.fileHub.caseFile.Patient.Email}`,
        },
      },
      { id: 'case-manager', label: 'Case Manager', value: this.fileHub.caseFile.CaseManagerNavigation?.Name ?? 'N/A' },
      { id: 'p&l', label: 'P&L', value: this.fileHub.caseFile.PAndLNavigation?.Description ?? 'N/A' },
      { id: 'law-firm', label: 'Law Firm', value: this.fileHub.caseFile.LawFirm?.Name ?? 'N/A' },
      {
        id: 'statuser',
        label: 'Statuser',
        value: this.fileHub.caseFile.StatusingGroupNavigation?.Description ?? 'N/A',
      },
      {
        id: 'balance-due',
        label: 'Balance Due',
        value: this.fileHub.performanceData ? this.formatCurrency(this.fileHub.performanceData?.BalanceDue) : 'Loading...',
      },
      {
        id: 'authorized-amount',
        label: 'Authorized Amount',
        value: this.fileHub.performanceData ? this.formatCurrency(this.fileHub.performanceData?.AuthorizedAmount) : 'Loading...',
      },
    ]);
    this.loading.patient.set(false);
  }

  // Updates Balance Due and Authorized Amount in header
  private async updateHeaderWithPerformanceData() {
    // If performance data is not available, don't update
    if (!this.fileHub.performanceData) {
      return;
    }

    const currentHeader = this.data.header();
    if (currentHeader && currentHeader.length > 0) {
      const performanceDataUpdates = [
        { id: 'balance-due', value: this.formatCurrency(this.fileHub.performanceData?.BalanceDue ?? 0) },
        { id: 'authorized-amount', value: this.formatCurrency(this.fileHub.performanceData?.AuthorizedAmount ?? 0) },
      ];
      this.dataUtils.updateArrayItemsWithMatchingIds(currentHeader, performanceDataUpdates);
    }

    this.loading.performance.set(false);
  }

  // Displays tooltip if text overflows
  private initializeTooltip() {
    const infoDivs = document.querySelectorAll('.info');

    infoDivs.forEach((infoDiv, index) => {
      const infoText = infoDiv.querySelector('span');
      if (!infoText) return;

      const hasOverflowText = infoText.scrollWidth > infoText.clientWidth;
      if (!hasOverflowText) return;

      infoDiv.id = `info-${index}`;
      new Tooltip({
        content: infoText.textContent as string,
        target: `#info-${index}`,
      }).appendTo(infoDiv as HTMLElement);

      infoDiv.classList.remove('e-control');
    });
  }

  // Define bootstrap classes based on screen size
  private setColumnWidth() {
    const width = document.getElementById('patient-info')?.getBoundingClientRect().width ?? window.innerWidth;

    this.ui.columnClass.set(width < 450 ? 'col-12' : width < 768 ? 'col-4' : width < 1200 ? 'col-3' : 'col-2');
  }

  // Return currency formatted string from value
  protected formatCurrency(amount?: number, locale = 'en-US', currency = 'USD'): string {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
    }).format(amount ?? 0);
  }
}
