// Angular
import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// 3rd Party
import {
  RadioButtonModule,
  ButtonModule,
} from '@syncfusion/ej2-angular-buttons';
import {
  NumericTextBoxModule,
  TextBoxModule,
} from '@syncfusion/ej2-angular-inputs';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { ReductionRequest } from '@models/data-contracts';
import { ApiService } from '@services/api/api.service';
import { BalanceDue } from '@services/financial/financial.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { FinancialInfoComponent } from '@forms/financial-info/financial-info.component';

// Interfaces
interface IFormLabels {
  requested: string;
  settleNow: string;
  newBalanceDue: string;
  totalCourtesyReduction: string;
}

interface IFormData {
  selectedOption: string;
  comment: string;
  newBalanceDue: number | null;
  courtesyReduction: {
    amount: number | null;
    percentage: number | null;
  };
}

@Component({
  selector: 'approve-reduction-request',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    RadioButtonModule,
    NumericTextBoxModule,
    TextBoxModule,
    ButtonModule,
    FinancialInfoComponent,
  ],
  templateUrl: './approve-reduction-request.component.html',
  styleUrl: './approve-reduction-request.component.scss',
})
export class ApproveReductionRequestComponent implements OnInit {
  constructor(
    private api: ApiService,
    private toast: ToastMessageService,
  ) {}

  @Input() reductionRequest: ReductionRequest;
  @Input() balanceDue: BalanceDue | null;
  @Input() caseFileId?: number;
  @Output() submit = new EventEmitter<any>(); // Emit backend response

  ngOnInit() {
    this.formData = {
      selectedOption: 'requested',
      newBalanceDue: null,
      courtesyReduction: {
        amount: null,
        percentage: null
      },
      comment: ''
    };
  }

  // handle form updates when data is passed in
  ngOnChanges(changes: SimpleChanges) {
    if (changes['caseFileId'] ) {
      this.resetForm();
    }
    if (changes['reductionRequest'] || changes['balanceDue']) {
      this.updateFormData();
    }
  }

  // initialize form data
  formData: IFormData;

  formLabels: IFormLabels = {
    requested: '',
    settleNow: '',
    newBalanceDue: `Approve new Balance Due:`,
    totalCourtesyReduction: 'Enter Courtesy Reduction Of:',
  };
  labelKeys = Object.keys(this.formLabels) as (keyof IFormLabels)[];

  resetForm() {
    this.formData = {
      selectedOption: '',
      comment: '',
      newBalanceDue: null,
      courtesyReduction: {
        amount: null,
        percentage: null,
      },
    };
  }

  // Update form with passed in data
  generateFormLabels() {
    this.formLabels.requested = `Approve Requested Value of $${this.reductionRequest?.RequestedAmount} and ${this.reductionRequest?.RequestedPercentage}%`;
    this.formLabels.settleNow = ` Approve Settlenow ℠ offer of $${this.reductionRequest?.AutomatedAmount} and ${this.reductionRequest?.AutomatedPercentage}%`;
  }

  updateFormData() {
    this.formData.comment = this.reductionRequest?.ApprovedComment || '';
    this.generateFormLabels();
  }
  // Adjusts courtesy reduction if amount is inputted
  onReductionAmountChange(newAmount?: number) {
    const amount = newAmount || this.formData.courtesyReduction.amount;
    if (amount != null && this.balanceDue != null) {
      // Calculate percentage from amount
      this.formData.courtesyReduction.percentage =
        (amount / this.balanceDue.amountBilled) * 100;
    }
  }

  // adjusts courtesy reduction if percentage is inputted
  onReductionPercentageChange() {
    const percentage = this.formData.courtesyReduction.percentage;
    if (percentage != null && this.balanceDue != null) {
      // Calculate amount from percentage
      this.formData.courtesyReduction.amount =
        (percentage / 100) * this.balanceDue.amountBilled;
    }
  }
  
  onFinancialReductionChange(event: {amount: number | null, percentage: number | null}) {
    if (this.formData.selectedOption === 'totalCourtesyReduction') {
      this.formData.courtesyReduction.amount = event.amount;
      this.formData.courtesyReduction.percentage = event.percentage;
    }
  }



  onSubmit() {
    let amount = null;
    let percentage = null;
    // Create final result from form fields based on selected option
    switch (this.formData.selectedOption) {
      case 'requested': {
        amount = this.reductionRequest?.RequestedAmount;
        percentage = this.reductionRequest?.RequestedPercentage;
        break;
      }
      case 'settleNow': {
        amount = this.reductionRequest?.AutomatedAmount;
        percentage = this.reductionRequest?.AutomatedPercentage;
        break;
      }
      case 'newBalanceDue': {
        if (this.balanceDue && this.formData.newBalanceDue) {
          amount = this.balanceDue?.amountBilled - this.formData.newBalanceDue;
          percentage = (amount / this.balanceDue.amountBilled) * 100;
        }
        break;
      }
      case 'totalCourtesyReduction': {
        amount = this.formData.courtesyReduction.amount;
        percentage = this.formData.courtesyReduction.percentage;
        break;
      }
    }
    // adjust the request for the backend
    this.reductionRequest.Status = 'Approved';
    this.reductionRequest.ApprovedAmount = amount;
    this.reductionRequest.ApprovedPercentage = percentage;
    this.reductionRequest.ApprovedComment = this.formData.comment;

    this.api
      .basicPatch(
        `${APIEndpoints.ReductionRequests}/${this.reductionRequest?.Id}`,
        this.reductionRequest,
      )
      .then(() => {
        // On success, close the modal
        this.submit.emit();
      })
      .catch((error: any) => {
        // Handle error, e.g., show an error message
        this.toast.showError(
          'There was an Error approving the Reduction Request',
        );
        console.error(
          `API error from PATCH at: /${APIEndpoints.ReductionRequests}/${this.reductionRequest?.Id}`,
          error,
        );
      });
  }
}
