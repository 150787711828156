import { Injectable, inject } from '@angular/core';
import { DataManager, Query, Predicate } from '@syncfusion/ej2-data';
import { APIEndpoints } from '@models/api/Endpoints';
import { UserPreferencesService } from '@app/shared/services/user/user-preferences.service';
import { AuthenticatedServiceBase } from '@core/auth/auth.base';
import {
  ListWorkItemStatus,
  ListWorkItemPriority,
  ListWorkItemType,
  ListWorkItemCategory,
  WorkItem,
  WorkItemUpdateDTO,
} from '@shared/models/data-contracts';

interface ODataResponse<T> {
  result: T[];
  count: number;
}

interface ODataSingleResponse<T> {
  result: T;
}

interface ApiResponse<T> {
  value: T[];
}

interface PriorityData {
  Id: number;
  Name: string;
  color_hex: string;
}

interface EnhancedError extends Error {
  context?: {
    service: string;
    method: string;
    originalError: any;
  };
}

@Injectable({
  providedIn: 'root',
})
export class WorkItemsService extends AuthenticatedServiceBase {
  protected override readonly endpoint = APIEndpoints.WorkItems;
  private statusesCache: any[] = [];
  private prioritiesCache: any[] = [];
  private usersCache: any[] = [];
  private typesCache: any[] = [];
  private categoriesCache: any[] = [];
  private providersCache: any[] = [];

  constructor(private userPrefs: UserPreferencesService) {
    super();
  }

  getWorkItems(): DataManager {
    return this.api.getOdata(APIEndpoints.WorkItems);
  }

  getStatusesDataManager(): DataManager {
    return this.api.getOdata(APIEndpoints.ListWorkItemStatuses);
  }

  getPrioritiesDataManager(): DataManager {
    return this.api.getOdata(APIEndpoints.ListWorkItemPriorities);
  }

  getTypesDataManager(): DataManager {
    return this.api.getOdata(APIEndpoints.ListWorkItemTypes);
  }

  getCategoriesDataManager(): DataManager {
    return this.api.getOdata(APIEndpoints.ListWorkItemCategories);
  }

  getUsersDataManager(): DataManager {
    return this.api.getOdata(APIEndpoints.Users);
  }

  getCaseFilesDataManager() {
    return this.api.getOdata(APIEndpoints.Casefiles);
  }

  getProvidersDataManager(): DataManager {
    return this.api.getOdata(APIEndpoints.Providers);
  }

  // Cached data getters
  async getStatuses(): Promise<ListWorkItemStatus[]> {
    if (this.statusesCache.length === 0) {
      const query = new Query()
        .where('IsDefault', 'equal', true)
        .where('IsActive', 'equal', true)
        .sortBy('DisplayOrder', 'ascending');

      const response = (await this.api
        .getOdata(APIEndpoints.ListWorkItemStatuses)
        .executeQuery(query)) as unknown as ODataResponse<ListWorkItemStatus>;

      this.statusesCache = response.result;
    }
    return this.statusesCache;
  }

  async getPriorities(): Promise<ListWorkItemPriority[]> {
    if (this.prioritiesCache.length === 0) {
      const response = (await this.getPrioritiesDataManager().executeQuery(
        new Query()
          .select(['Id', 'Name', 'Description', 'ColorHex', 'DisplayOrder', 'PriorityWeight', 'IsActive'])
          .where('IsActive', 'equal', true)
          .sortBy('DisplayOrder', 'ascending')
      )) as unknown as ODataResponse<ListWorkItemPriority>;
      this.prioritiesCache = (response as any).result;
    }
    return this.prioritiesCache;
  }

  async getUsers(): Promise<any[]> {
    if (this.usersCache.length === 0) {
      const response = await this.getUsersDataManager().executeQuery(new Query().select(['Id', 'Name']));
      this.usersCache = (response as any).result;
    }
    return this.usersCache;
  }

  async getTypes(): Promise<ListWorkItemType[]> {
    if (this.typesCache.length === 0) {
      const response = await this.getTypesDataManager().executeQuery(
        new Query()
          .select(['Id', 'Name', 'Description', 'DisplayOrder', 'CategoryId', 'IsActive', 'IsDefault'])
          .where('IsActive', 'equal', true)
          .sortBy('DisplayOrder', 'ascending')
      );
      this.typesCache = (response as any).result;
    }
    return this.typesCache;
  }

  async getCategories(): Promise<ListWorkItemCategory[]> {
    if (this.categoriesCache.length === 0) {
      const response = await this.getCategoriesDataManager().executeQuery(
        new Query()
          .select(['Id', 'Name', 'Description', 'DisplayOrder', 'ParentCategoryId', 'IsActive'])
          .where('IsActive', 'equal', true)
          .sortBy('DisplayOrder', 'ascending')
      );
      this.categoriesCache = (response as any).result;
    }
    return this.categoriesCache;
  }

  async getProviders(): Promise<any[]> {
    if (this.providersCache.length === 0) {
      const response = await this.getProvidersDataManager().executeQuery(
        new Query().select(['Id', 'Name']).where('IsActive', 'equal', true).sortBy('Name', 'ascending')
      );
      this.providersCache = (response as any).result;
    }
    return this.providersCache;
  }

  // CRUD Operations
  async createWorkItem(workItemData: any): Promise<any> {
    try {
      // Extract xref data
      const { XrefWorkItemCaseFiles, XrefWorkItemProviders, ...workItem } = workItemData;

      // Create work item first
      const response = await this.api
        .basicPost(`${APIEndpoints.WorkItems}`, workItem)
        .then(res => (res && 'json' in res ? res.json() : res));

      const workItemId = response.Id;

      // Create provider relationship first
      if (XrefWorkItemProviders?.length) {
        console.log('Creating provider relationship:', XrefWorkItemProviders[0]);
        const providerResult = await this.api.fetchRequest(`odata${APIEndpoints.XrefWorkItemProviders}`, 'POST', {
          WorkItemId: workItemId,
          ProviderId: XrefWorkItemProviders[0].ProviderId,
        });
        console.log('Provider xref result:', providerResult);
      }

      // Create case file relationships
      if (XrefWorkItemCaseFiles?.length) {
        await this.addWorkItemCaseFiles(
          workItemId,
          XrefWorkItemCaseFiles.map((xref: any) => xref.CaseFileId)
        );
      }

      return response;
    } catch (error) {
      console.error('Error creating work item:', error);
      throw error;
    }
  }

  private async addWorkItemCaseFiles(workItemId: number, caseFileIds: number[]): Promise<void> {
    await Promise.all(
      caseFileIds.map(caseFileId =>
        this.api.fetchRequest(`odata${APIEndpoints.XrefWorkItemCaseFiles}`, 'POST', {
          WorkItemId: workItemId,
          CaseFileId: caseFileId,
        })
      )
    );
  }

  private async removeWorkItemCaseFiles(workItemId: number, caseFileIds: number[]): Promise<void> {
    // First get the xref records for this work item
    const predicates = caseFileIds
      .map(id => new Predicate('CaseFileId', 'equal', id))
      .reduce((acc, pred) => acc.or(pred));

    const query = new Query().where('WorkItemId', 'equal', workItemId).where(predicates);

    const response = await this.api.getOdata(APIEndpoints.XrefWorkItemCaseFiles).executeQuery(query);

    const xrefs = (response as any).result;

    // Then delete using the xref IDs
    await Promise.all(
      xrefs.map((xref: any) =>
        this.api.fetchRequest(`odata${APIEndpoints.XrefWorkItemCaseFiles}(${xref.Id})`, 'DELETE', null)
      )
    );
  }

  private async addWorkItemProvider(workItemId: number, providerId: number): Promise<void> {
    await this.api.fetchRequest(`odata${APIEndpoints.XrefWorkItemProviders}`, 'POST', {
      WorkItemId: workItemId,
      ProviderId: providerId,
    });
  }

  private async removeWorkItemProvider(workItemId: number, providerId: number): Promise<void> {
    // First get the xref record for this work item and provider
    const query = new Query().where('WorkItemId', 'equal', workItemId).where('ProviderId', 'equal', providerId);

    const response = await this.api.getOdata(APIEndpoints.XrefWorkItemProviders).executeQuery(query);

    const xref = (response as any).result[0];
    if (!xref) return;

    await this.api.fetchRequest(`odata${APIEndpoints.XrefWorkItemProviders}(${xref.Id})`, 'DELETE', null);
  }

  async updateWorkItem(id: number, workItemData: any): Promise<any> {
    const {
      XrefWorkItemCaseFiles,
      XrefWorkItemProviders,
      originalCaseFileIds = [],
      originalProviderId,
      ...workItem
    } = workItemData;

    console.log('Update Work Item - Starting with:', {
      XrefWorkItemCaseFiles,
      originalCaseFileIds,
      XrefWorkItemProviders,
      originalProviderId,
    });

    // Update basic work item info
    const response = await this.api
      .basicPatch(`${APIEndpoints.WorkItems}(${id})`, workItem)
      .then(res => (res && 'json' in res ? res.json() : res));

    // Handle case files changes
    const newCaseFileIds = (XrefWorkItemCaseFiles || [])
      .map((x: any) => Number(x.CaseFileId || x.CaseFile?.Id))
      .filter((id: number) => id);

    console.log('Case Files Comparison:', {
      originalIds: originalCaseFileIds,
      newIds: newCaseFileIds,
    });

    // Convert to sets for comparison
    const newSet = new Set(newCaseFileIds);
    const originalSet = new Set(originalCaseFileIds);
    const hasChanges =
      newCaseFileIds.length !== originalCaseFileIds.length || newCaseFileIds.some((id: number) => !originalSet.has(id));

    console.log('Should update case files?', hasChanges);

    // Only proceed if there are actual changes
    if (hasChanges) {
      const caseFilesToRemove = originalCaseFileIds.filter((id: number) => !newCaseFileIds.includes(id));
      const caseFilesToAdd = newCaseFileIds.filter((id: number) => !originalSet.has(id));

      console.log('Changes to make:', {
        caseFilesToRemove,
        caseFilesToAdd,
        workItemId: id,
      });

      if (caseFilesToRemove.length > 0) {
        await this.removeWorkItemCaseFiles(id, caseFilesToRemove);
      }

      if (caseFilesToAdd.length > 0) {
        await this.addWorkItemCaseFiles(id, caseFilesToAdd);
      }
    }

    // Handle provider changes
    const newProviderId = XrefWorkItemProviders?.[0]?.ProviderId;
    if (newProviderId !== originalProviderId) {
      if (originalProviderId) {
        await this.removeWorkItemProvider(id, originalProviderId);
      }
      if (newProviderId) {
        await this.addWorkItemProvider(id, newProviderId);
      }
    }

    return response;
  }

  async bulkUpdate(ids: number[], update: WorkItemUpdateDTO): Promise<boolean> {
    try {
      const dataManager = this.getWorkItems();

      // Process updates sequentially
      for (const id of ids) {
        (await dataManager.update('id', { id, ...update })) as Promise<WorkItem>;
      }

      return true;
    } catch (error) {
      console.error('Error in bulk update:', error);
      return false;
    }
  }

  getBaseQuery(dateOrDashboard?: Date | boolean): Query {
    let userId = this.userPrefs.userId();
    const selectedDate = dateOrDashboard instanceof Date ? dateOrDashboard : new Date();
    const startOfMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1);
    const endOfMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0);

    return new Query()
      .expand([
        'XrefWorkItemCaseFiles($select=CaseFileId;$expand=CaseFile($select=Id,FileNumber))',
        'Status($select=Name)',
        'Priority($select=Name)',
        'Type($select=Name)',
        'Category($select=Name)',
        'AssignedToNavigation($select=Name)',
      ])
      .where('AssignedTo', 'equal', userId)
      .where('DueDate', 'greaterThanOrEqual', startOfMonth)
      .where('DueDate', 'lessThanOrEqual', endOfMonth);
  }

  getOverdueWorkItemsQuery(includeExpanded: boolean = false): Query {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set to start of today

    const query = new Query()
      .where('DueDate', 'lessthan', today) // Use Date object directly
      .where('Status/Name', 'notequal', 'Completed');

    if (includeExpanded) {
      query.expand([
        'Type($select=Name)',
        'Category($select=Name)',
        'Priority($select=Name)',
        'Status($select=Name)',
        'AssignedToNavigation($select=Name)',
        'XrefWorkItemCaseFiles($select=CaseFileId,WorkItemId;$expand=CaseFile($select=FileNumber))',
      ]);
    }

    return query;
  }

  getUpcomingWorkItemsQuery(forDashboard: boolean = false): Query {
    return this.getBaseQuery(forDashboard)
      .where('DueDate', 'greaterThanOrEqual', new Date())
      .where('status', 'notEqual', 'Completed');
  }

  getWorkItemsByEntityQuery(entityType: string, entityId: number): Query {
    const xrefTable = `xrefWorkItem${entityType}`;
    const entityField = `${entityType.toLowerCase()}Id`;

    return new Query()
      .expand([xrefTable])
      .where(`${xrefTable}.${entityField}`, 'equal', entityId)
      .sortBy('DueDate', 'asc');
  }

  async getDefaultStatus() {
    const statuses = await this.getStatuses();
    return statuses.find(s => s.IsDefault) || statuses[0];
  }

  async getDefaultPriority() {
    const priorities = await this.getPriorities();
    return priorities.find(p => p.IsDefault) || priorities[0];
  }

  async getDefaultType(categoryId: number): Promise<any> {
    try {
      const types = await this.getTypes();
      const categoryTypes = types.filter(type => type.CategoryId === categoryId);
      return categoryTypes.find(type => type.IsDefault === true) || categoryTypes[0];
    } catch (error) {
      console.error('Error getting default type:', error);
      return null;
    }
  }

  async getDefaultCategory() {
    const categories = await this.getCategories();
    return categories.find(c => c.IsDefault) || categories[0];
  }

  // Add method to get all defaults at once
  async getNewWorkItemDefaults() {
    const category = await this.getDefaultCategory();
    const [status, priority, type] = await Promise.all([
      this.getDefaultStatus(),
      this.getDefaultPriority(),
      this.getDefaultType(category?.Id || 0),
    ]);

    return {
      StatusId: status?.Id || 7,
      PriorityId: priority?.Id || 5,
      TypeId: type?.Id || 77,
      CategoryId: category?.Id || 8,
    };
  }

  async completeWorkItem(workItemId: number): Promise<any> {
    // Get completed status
    const statusQuery = new Query().select(['Id', 'Name']).where('IsActive', 'equal', true);

    const statusResult = (await this.api.getOdata(APIEndpoints.ListWorkItemStatuses).executeQuery(statusQuery)) as any;

    const completedStatus = statusResult.result.find((status: any) => status.Name.toLowerCase().includes('complete'));

    if (!completedStatus) {
      throw new Error('Could not find completed status');
    }

    // Update work item with completed status
    return this.api.basicPatch(`${APIEndpoints.WorkItems}(${workItemId})`, {
      StatusId: completedStatus.Id,
    });
  }

  async save(data: any): Promise<any> {
    return this.api.fetchRequest('/WorkItems', 'POST', data);
  }

  getTodaysTasksQuery(): Query {
    const userId = this.userPrefs.userId();
    return new Query()
      .expand([
        'XrefWorkItemCaseFiles($select=CaseFileId;$expand=CaseFile($select=Id,FileNumber))',
        'Status($select=Name)',
        'Priority($select=Name)',
        'Type($select=Name)',
        'Category($select=Name)',
        'AssignedToNavigation($select=Name)',
      ])
      .where('AssignedTo', 'equal', userId)
      .where('DueDate', 'equal', new Date().toISOString().split('T')[0]);
  }

  async getWorkItemDetails(workItemId: number) {
    // 1. Get base work item
    const workItemQuery = new Query()
      .select([
        'Id',
        'Title',
        'Description',
        'DueDate',
        'AssignedTo',
        'Status',
        'Priority',
        'Type',
        'Category',
        'AssignedToNavigation',
      ])
      .expand([
        'Status($select=Id,Name)',
        'Priority($select=Id,Name)',
        'Type($select=Id,Name)',
        'Category($select=Id,Name)',
        'AssignedToNavigation($select=Id,Name)',
      ]);
    const response = (await this.api
      .getOdata(`${APIEndpoints.WorkItems}(${workItemId})`)
      .executeQuery(workItemQuery)) as unknown as ODataSingleResponse<WorkItem>;
    const workItemResponse = response;

    // 2. Get providers xref
    const providersResponse = (await this.api.fetchRequest(
      `odata${APIEndpoints.XrefWorkItemProviders}?$filter=WorkItemId eq ${workItemId}&$expand=Provider($select=Id,Name,IsActive)`,
      'GET'
    )) as ApiResponse<any>;

    // 3. Get case files xref
    const caseFilesResponse = (await this.api.fetchRequest(
      `odata${APIEndpoints.XrefWorkItemCaseFiles}?$filter=WorkItemId eq ${workItemId}&$expand=CaseFile($select=Id,FileNumber)`,
      'GET'
    )) as ApiResponse<any>;

    return {
      ...workItemResponse.result,
      XrefWorkItemProviders: providersResponse.value,
      XrefWorkItemCaseFiles: caseFilesResponse.value,
    };
  }

  async getWorkItemsData(query: Query): Promise<ODataResponse<WorkItem>> {
    const response = (await this.api
      .getOdata(APIEndpoints.WorkItems)
      .executeQuery(query)) as unknown as ODataResponse<WorkItem>;
    return response;
  }

  async getPriorityColors(): Promise<ODataResponse<PriorityData>> {
    try {
      const dataManager = this.api.getOdata('/ListWorkItemPriorities');
      const response = (await dataManager.executeQuery(new Query())) as unknown as ODataResponse<PriorityData>;
      if (!response || !response.result) {
        throw new Error('Invalid response from priority colors query');
      }
      return response;
    } catch (error: any) {
      // Add query context to error
      error.queryDetails = {
        endpoint: '/ListWorkItemPriorities',
        operation: 'getPriorityColors',
        timestamp: new Date().toISOString(),
      };
      throw error;
    }
  }

  // Replace DataManager methods with direct API calls
  async getMonthlyWorkItems(userId: number): Promise<WorkItem[]> {
    const today = new Date();
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    const response = await this.api.fetchRequest(
      `odata${APIEndpoints.WorkItems}?$expand=XrefWorkItemCaseFiles($select=CaseFileId;$expand=CaseFile($select=Id,FileNumber)),Status($select=Name),Priority($select=Name),Type($select=Name),Category($select=Name),AssignedToNavigation($select=Name)&$filter=AssignedTo eq ${userId} and DueDate ge ${startOfMonth.toISOString()} and DueDate le ${endOfMonth.toISOString()}&$orderby=DueDate desc`,
      'GET'
    );

    return response.value;
  }

  async getTodaysTasks(userId: number): Promise<WorkItem[]> {
    const today = new Date().toISOString().split('T')[0];

    const response = await this.api.fetchRequest(
      `odata${APIEndpoints.WorkItems}?$expand=XrefWorkItemCaseFiles($select=CaseFileId;$expand=CaseFile($select=Id,FileNumber)),Status($select=Name),Priority($select=Name),Type($select=Name),Category($select=Name),AssignedToNavigation($select=Name)&$filter=AssignedTo eq ${userId} and DueDate eq ${today}`,
      'GET'
    );

    return response.value;
  }
}
