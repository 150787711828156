// Angular
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
// import $ from 'jquery';

// 3rd party
import { TreeGridModule, TreeGridComponent, PageSettingsModel, SortSettingsModel, EditSettingsModel, EditService, ToolbarService, PageService, Column } from '@syncfusion/ej2-angular-treegrid';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { CommandModel, RowSelectEventArgs } from '@syncfusion/ej2-angular-grids';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { treeData, treeDataSample, workflowData } from './sample-data/treegrid';
import { GlobalsService } from '@services/globals/globals.service';
import { ApiService } from '@services/api/api.service';
import { SvgComponent } from '@ui/svg/svg.component';

@Component({
  selector: 'app-workflow-builder',
  standalone: true,
  imports: [
    SvgComponent, 
    TreeGridModule, 
    DropDownListModule
  ],
  templateUrl: './workflow-builder.component.html',
  styleUrl: './workflow-builder.component.scss',
  providers: [
    EditService, 
    ToolbarService, 
    PageService
  ]
})
export class WorkflowBuilderComponent implements OnInit {

  constructor(
    private globals: GlobalsService,
    private api: ApiService
  ) { 
    this.users = this.api.getOdata(APIEndpoints.Users);
  }

  // treegrid
  @ViewChild('treegrid')
  treegrid: TreeGridComponent;
  treegridData: Object[] = [];  
  treeGridEditSettings: EditSettingsModel =  { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Dialog' };
  sortSettings?: SortSettingsModel;
  pageSettings?: PageSettingsModel;
  toolbar: Object[] = ['ExpandAll', 'CollapseAll', 'Add', 'Edit', 'Update', 'Cancel', 'Delete'];
  filterSettings: Object = { hierarchyMode: 'Parent' };
  formatOptions?: Object = { format: 'y/M/d', type: 'date' };
  dropData?: string[] = ['true', 'false'];
  first: boolean = true;
  users: DataManager;
  userNames: Query;
  userFields: Object = { text: 'Username', value: 'Id' };
  rowSelectedLevel: number;
  commands: CommandModel[];

  // treelist kendo example
  @ViewChild('treelist')
  workflowData: any = workflowData;
  data: any = treeData;
  sampleData: any = treeDataSample;
  currentName: string = '';
  currentWfId: string = '0';
  oldWfName: string = '';
  newRow: boolean = false;
  removedRows: object[] = [];
  rowRemoved: boolean = false;
  saveChangesError: boolean = false;
  completionConditionsData: object; // {!! $completionConditionsJson !!};
  stepTypesData: object; // {!! $stepTypesJson !!};
  assignToData: object; // {!! $assignToDataJson !!};
  stepNamesData: object; // {!! $stepNamesJson !!};
  logo: string = 'assets/logos/casecompass-logo_solid.svg';

  ngOnInit(): void {
    this.userNames = new Query().select('Username,Id');
    this.formatData();
  }

  ngAfterViewInit(): void {
    this.treegridData = this.prepareData((this.wfddl as any).value);
    this.commands = [
      { type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } },
      { buttonOption: { iconCss: 'e-icons e-plus', cssClass: 'e-flat', click: this.insert.bind(this) } }
    ];

    setTimeout((() => {
      this.rowShading();
    }), 400)
  }

  /** 
   * Custom functions for workflow builder
   */
  prepareData(workflowId: number): Object[] {
    var data: Object[] = [];
    var returnStatement: any;
    if (!workflowId || typeof workflowId !== 'number') {
      console.log('Please enter valid string');
      return data;
    } else {
      for (let i = 0; i < treeData.length; i++) {
        if (workflowId === (treeData[i] as any).workflowId) {
          data.push((treeData[i] as any).steps as Object[]);
        }
      }
      if (data.length !== 0) {
        // console.log(data.flat());
        return data.flat();
      } else {
        returnStatement = 'No workflow found with id: ' + workflowId;
        // console.log(returnStatement);
        return data;
      }
    }
  }

  onWfChange(args: any): void {
    this.treegridData = this.prepareData(args.value);
    this.wfHeader = args.itemData.name;
    this.rowShadingApp();
  }

  onChange(args: any): void {
    /** Event will trigger when you have change the value in dropdown column */
    // console.log(args as DetailDataBoundEventArgs);
  }

  rowSelected(args: RowSelectEventArgs) {
    if (args.data && (args.data as any).level) {
      this.rowSelectedLevel = (args.data as any).level;
    }
  }

  insert() {
    var defaultStepSettings = {
      id: this.treegridData.length + 1,
      parentId: this.rowSelectedLevel - 1, // temporary until logic is added to retrieve data of selected row
      StepName: 'Newly Added Child',
      Assignment: 'Default',
      Optional: false,
    };

    this.treegrid.addRecord(defaultStepSettings, this.rowSelectedLevel); // call addRecord method with data and index of parent record as parameters
  }

  // Action on #treegrid begins
  actionBegin(args: any): void {
    // console.log(args as EditEventArgs);

    if (args.requestType == "add") {
      args.data.CompletionCondition = 'none';
      var index = args.index;
      // console.log(args);
      this.treegrid.selectRow(index); // select the newly added row to scroll to it
      this.insert();
    }

    if ((args.requestType === 'beginEdit' || args.requestType === 'add')) {
      this.rowShadingApp();

      for (const cols of (this.treegrid as TreeGridComponent).grid.columns) {
        if ((cols as Column).field === null) {
          (cols as Column).visible = false;
        }
      }
    }
  }

  // Action on #treegrid resolves
  actionComplete(args: any): void {
    // console.log(args as SaveEventArgs);

    if ((args.requestType === 'beginEdit' || args.requestType === 'add')) {
      const dialog = args.dialog;
      // console.log(dialog);
      dialog.header = args.requestType === 'beginEdit' ? 'Edit \"' + args.rowData['StepName'] + '\" Step' : 'Add New Step';
    }

    if (args.requestType == 'save') {
      // Add coloring for yes/no routes
      this.rowShadingApp();

      var index = args.index;
      this.treegrid.selectRow(index); // select the newly added row to scroll to it

      for (const cols of (this.treegrid as TreeGridComponent).grid.columns) {
        if ((cols as Column).field === null) {
          (cols as Column).visible = false;
        }
      }
    }
  }

  gridDataBound(args: any) {
    this.rowShadingApp();
    // console.log(args);
  }

  // Function occurs after data is ready
  rowDataBound(args: any) {
  
    (args.row as HTMLElement).setAttribute('treegrid-level', args.data.level);
    (args.row as HTMLElement).setAttribute('stepid', args.data.id);

    if (args.data.StepType === 'End') {
      args.row.classList.add('end');
    }

    if (args.data.StepType === 'Branch') {
      args.row.classList.add('branch');
    }

    if (args.data.StepType !== 'End') {
      
    }

    this.setYesNoStart(args);
  }

  setYesNoStart(args: any) {
    if (args.data.parentItem && args.data.parentItem.StepType === 'Branch') {
      if (this.first === true) {
        args.row.classList.add('yes-route-start');
        this.first = false;
      } else {
        args.row.classList.add('no-route-start');
      }
    }
  }

  // Return FriendlyName array from fetched data
  displayOptionalValues(field: string, data: any): any {

    // console.log('data: ', (data as any));
    // console.log('data[field]: ', (data as any)[field]);

    if ((data as any)[field] === false) {
      return 'No';
    } else if ((data as any)[field] === true) {
      return 'Yes';
    }
  }

  /** 
   * Converting Atlas's workflow builder functions
   */
  @ViewChild('wfddl', { static: false }) wfddl: ElementRef;
  wfHeader: string;
  wfList: any[];
  wfListFields: Object = {text: 'name', value: 'id'};
  wfListPlaceholder: string = 'Select Workflow';
  wfListDataNextID: number = 0;
  initialSetup() {
    if (document.readyState === 'complete' || document.readyState === 'interactive') {
      this.formatData();
    }
  }

  formatData() {

    var workflowData = this.workflowData;
    // console.log("workflow data: ", workflowData);

    var data: any = [];
    var workflowList: any = [];

    data = workflowData;
    // convert the top level of workflowData to an array with keys = data[i].workflowId
    //var oldData = data;
    
    data = Object.values(data);
    var newData = [];
    for (var i = 0; i < data.length; i++) {
      newData[data[i].workflowId] = data[i];
      let wfListObj = {
        id: data[i].workflowId,
        name: data[i].name
      }
      workflowList.push(wfListObj);
    }
    data = newData;
    this.wfList = workflowList;

    // get first index of data array
    var firstIndex = Object.keys(data)[0];
    // console.log("first index: " + firstIndex);
    // set global variable for current workflow id
    var currentWfId = firstIndex;

    this.currentWfId = currentWfId;
    // console.log(data[firstIndex]);
    this.data = data[firstIndex].metadata;
    this.wfHeader = data[firstIndex].name;
    // this.wfListDataNextID = data[currentWfId].BranchChild.length + 1;
  }

  getIndexById(id: string | number | null): number | null {
    let idx: number | null,
    l: number = this.data[this.currentWfId].metadata.length;

    for (let j = 0; j < l; j++) {
      if (this.data[this.currentWfId].metadata[j].id === id) {
        return j;
      }
    }

    return null;
  }

  observeEditing() {}

  newStep() {
    // click event handler for the Add New Child button
    // $(" #newchild").click(function(e){
    //   // note when adding children, if ID is autoincremented and original parent id is greater than child id, then eventually once child id gets to original parent id, an infinite loop will occur
    //   // this may require some additional logic to prevent this from happening
    //   // change the viewModel model BranchChild default value to Yes
    //   var treelist = $("#treelist").data("kendoTreeList");
    //   // get the selected row and save it to a variable
    //   var selectedRow = treelist.select();
    //   // get id of selected row
    //   var selectedRowId = treelist.dataItem(selectedRow).id;
      
    //   //viewModel.datasource.options.schema.model.fields.BranchChild.defaultValue.value = "No";
    //   //console.log(viewModel.datasource.options.schema.model.fields.BranchChild.defaultValue.value);
    //   //treelist.setDataSource(viewModel.datasource);
    //   //rebuild the treelist to apply the changes to the datasource
    //   //treelist.dataSource.read();
    //   //reselect the row that was selected before the rebuild

    //   // get completion condition of selected row
    //   var selectedRowCC = treelist.dataItem(selectedRow).CompletionCondition.value;
    //   var hasBranchingCC = false;
    //   // if completion condition contains question mark, then show branchchild-container div in edit/add dialog
    //   if (selectedRowCC.includes("?")) {
    //       hasBranchingCC = true;
    //   }
    //   // if hasbranching is true check to see if the selected row has two children
    //   if (hasBranchingCC) {
    //       // get the children of the selected row
    //       var children = treelist.dataSource.data().filter(function (obj) {
    //           return obj.parentId === selectedRowId;
    //       });
    //       // if the selected row has two children then show toastr error message and exit from click handler
    //       if (children.length == 2) {
    //           toastr.error("Branching Completion Condition is Complete, Unable to Add New Child!");
    //           return;
    //       }
    //   }
    //   // get a list of all uid's from the treelist
    //   var treeList = $("#treelist").data("kendoTreeList");
    //   // save changes to prevent infinite loop from new row that kendo considers non existent currently
    //   // newRow being set to true prevents the incompleteBranchingCC function from being called in saveChanges
    //   newRow = true;
    //   treeList.saveChanges();
      
    //   //newRow = false;
    //   // iterate over current metadata and remove any objects that have duplicate ids
    //   // this is a band-aid fix because there is a strange interaction in the treelist that is causing duplicate objects to be created
    //   var currentMetadata = data[currentWfId].metadata;
    //   var uniqueMetadata = [];
    //   var uniqueIds = [];
    //   for (var i = 0; i < currentMetadata.length; i++) {
    //       if (uniqueIds.indexOf(currentMetadata[i].id) === -1) {
    //           uniqueMetadata.push(currentMetadata[i]);
    //           uniqueIds.push(currentMetadata[i].id);
    //       }
    //   }
    //   // set the metadata to the unique metadata
    //   data[currentWfId].metadata = uniqueMetadata;
    //   console.log("Newchild metadata: ");
    //   console.log(data[currentWfId].metadata);
      
    //   var uidList = treeList.dataSource._data.map(function (a) { return a.uid; });
    //   // handler body
    //   // add new child to selected row

    //   var selectedRow = treeList.select();
    //   var selectedDataItem = treeList.dataItem(selectedRow);
    //   //console.log("selectedDataItem: " + selectedDataItem.uid);
    //   treeList.addRow("#treelist tbody tr[data-uid='" + selectedDataItem.uid + "']");
    //   // get list of all current uid's from treelist
    //   var newUidList = treeList.dataSource._data.map(function (a) { return a.uid; });
    //   // get the new uid by comparing the old uid list to the new uid list
    //   var newUid = newUidList.filter(function (x) { return uidList.indexOf(x) < 0 })[0];
    //   // select new row
    //   treeList.select(treeList.tbody.find(">tr[data-uid=" + newUid + "]"));
    //   /*
    //   //get selected row
    //   var selectedRow = treeList.select();
    //   //set branchchild value to yes
    //   treeList.dataItem(selectedRow).BranchChild.value = "Yes";
    //   */
    //   // trigger the change event manually since the row was selected programmatically
    //   viewModel.set("selected", {});
    //   // get the data item for the new row
    //   var model = treeList.dataItem(treeList.select());
      
    //   //viewModel.set("hasChanges", false);
    //   viewModel.set("selected", model);
    //   viewModel.show();

    //   // trigger the change event manually since the row was selected programmatically
    //   treeList.trigger("change");

    //   // enable completion condition and optional fields as they will be disabled if the user just created a new workflow
    //   $("#complex-completion-list").data("kendoDropDownList").enable(true);
    //   $("#complex-completion-modifier").data("kendoNumericTextBox").enable(true);
    //   $("#edit-optional").data("kendoDropDownList").enable(true);
    //   // enable step type field
    //   $("#edit-drop-list").data("kendoDropDownList").enable(true);
    //   // enable assign to field
    //   $("#edit-assign-to").data("kendoDropDownList").enable(true);

    //   // get the id of the parent of the selected row
    //   var parentRow = viewModel.selected.parentId;
    //   // query data.metadata for the parent row
    //   var parentCC = data[currentWfId].metadata.filter(function (obj) {
    //       return obj.id === parentRow;
    //   })[0].CompletionCondition.value;
    //   // if completion condition contains question mark, then show branchchild-container div in edit/add dialog
    //   if (parentCC.includes("?")) {
    //       $("#branchchild-container").show();
    //   } else {
    //       $("#branchchild-container").hide();
    //   }
    // });
  }

  editStep() {
    // // click event handler for the Edit button
    // $("#edit-step").click(function(e){
    //   newRow = false;
    //   // call the show function from the viewModel
    //   // disable button directly without viewModel
    //   $(this).attr("disabled", true);

    //   // get the id of the parent of the selected row
    //   var parentRow = viewModel.selected.parentId;
      
    //   if (parentRow) {
    //       console.log("currentWfId: " + currentWfId);
    //       console.log("data: ");
    //       console.log(data);
    //       // query data.metadata for the parent row
    //       var parentCC = data[currentWfId].metadata.filter(function (obj) {
    //           return obj.id === parentRow;
    //       })[0].CompletionCondition.value;
          
    //       // if completion condition contains question mark, then show branchchild-container div in edit/add dialog
    //       if (parentCC.includes("?")) {
    //           $("#branchchild-container").show();
    //       } else {
    //           $("#branchchild-container").hide();
    //       }
    //   }else{
    //       // return from click handler and inform user that you cannot edit the first row
    //       toastr.error("You cannot edit the first row!");
    //       return;
    //       $("#branchchild-container").hide();
    //   }
      
    //   viewModel.set("modalType", "Edit");
    //   viewModel.show();

    //   // if step type is end workflow or trigger, disable completion condition and optional fields
    //   if (viewModel.selected.StepType.value == "End Workflow") {
          
    //       // disable step name field
    //       $("#choose-step-list").data("kendoDropDownList").enable(false);
    //       $("#edit-drop-list").data("kendoDropDownList").dataSource.filter({});
    //       $("#edit-drop-list").data("kendoDropDownList").enable(false);
    //       $("#complex-completion-list").data("kendoDropDownList").enable(false);
    //       $("#complex-completion-modifier").data("kendoNumericTextBox").enable(false);
    //       $("#edit-optional").data("kendoDropDownList").enable(false);
          
    //       // diable assign to field
    //       $("#edit-assign-to").data("kendoDropDownList").enable(false);
          
    //   } else {
    //       $("#choose-step-list").data("kendoDropDownList").enable(true);
    //       $("#edit-drop-list").data("kendoDropDownList").dataSource.filter({ field: "value", operator: "neq", value: "Trigger" });
    //       $("#complex-completion-list").data("kendoDropDownList").enable(true);
    //       $("#complex-completion-modifier").data("kendoNumericTextBox").enable(true);
    //       $("#edit-optional").data("kendoDropDownList").enable(true);
    //       // enable assign to field
    //       $("#edit-assign-to").data("kendoDropDownList").enable(true);
          
    //   }
        
    // });
  }

  editingWorkflow() {
    // initialize the dropdownlist for the edit dialog
    // $("#edit-drop-list").kendoDropDownList({
    //   dataTextField: "value",
    //   dataValueField: "id",
    //   dataSource: stepTypesData,
    //   select: function(e){
          
    //       // if the selected step type is End Workflow then disable the completion condition, optional, and assign to fields and set their values to N/A no and N/A
    //       if (this.dataItem(e.item.index()).value == "End Workflow" || this.dataItem(e.item.index()).value == "Start New Workflow") {
    //           $("#complex-completion-list").data("kendoDropDownList").enable(false);
    //           $("#complex-completion-modifier").data("kendoNumericTextBox").enable(false);
    //           $("#edit-optional").data("kendoDropDownList").enable(false);
    //           // diable assign to field
    //           $("#edit-assign-to").data("kendoDropDownList").enable(false);
    //           // use datasource.data property to set the value of each dropdown list
    //           $("#complex-completion-list").data("kendoDropDownList").select(2);
    //           $("#complex-completion-modifier").data("kendoNumericTextBox").value(0);
    //           $("#edit-optional").data("kendoDropDownList").select(1);
              
    //           // set assign to field to N/A
    //           $("#edit-assign-to").data("kendoDropDownList").select(0);

    //       } else {
    //           $("#complex-completion-list").data("kendoDropDownList").enable(true);
    //           $("#complex-completion-modifier").data("kendoNumericTextBox").enable(true);
    //           $("#edit-optional").data("kendoDropDownList").enable(true);
    //           // enable step type field
    //           $("#edit-drop-list").data("kendoDropDownList").enable(true);
    //           // enable assign to field
    //           $("#edit-assign-to").data("kendoDropDownList").enable(true);
    //       }
          
    //   }
      
    // });

    // initialize kendo drop list for branching child in edit dialog
    // $("#edit-branch-list").kendoDropDownList({
    // dataTextField: "value",
    // dataValueField: "value",
    // dataSource: [
    //     { value: "N/A" },
    //     { value: "Yes" },
    //     { value: "No" },
    // ],
    // select:
    //   function(e) {
    //       // function to set branching child value of parent's child row to opposite of selected branching child value
    //       // this is correctly setting the sister row value, but the original row's branchchild value does not change
    //       // get the selected parent
    //       // if newRow is true then parent id is selected row Id, otherwise parent id is selected row parent id 
    //       if (newRow) {
    //           var parentId = viewModel.selected.id;
    //       } else {
    //           var parentId = viewModel.selected.parentId;
    //       }
    //       console.log("viewModel.selected: "); console.log(viewModel.selected);
    //       //var parentId = viewModel.selected.parentId;
    //       var currentId = viewModel.selected.id;
    //       // get the selected branching child value
    //       var selectedBranchingChild = this.dataItem(e.item.index()).value;
    //       // find if parent has another child that does not match current uid
    //       var parentChildren = data[currentWfId].metadata.filter(function (obj) {
    //           return obj.parentId === parentId && obj.id != currentId;
    //       });
    //       // if parent children has a length of 1 then set the branching child value of the parent's child row to the opposite of the selected branching child value
    //       if (parentChildren.length == 1 && newRow == false && selectedBranchingChild != "N/A") {
    //           // set the branching child value of the parent's child row to the opposite of the selected branching child value
    //           parentChildren[0].BranchChild.value = selectedBranchingChild == "Yes" ? "No" : "Yes";

    //           // no idea why I have to do this, but something about the above code breaks the initial select change event
    //           var originalChild = data[currentWfId].metadata.filter(function (obj) {
    //               return obj.parentId === parentId && obj.id == currentId;
    //           });
    //           originalChild[0].BranchChild.value = selectedBranchingChild;

    //           // update treelist with new branching child value
    //           $("#treelist").data("kendoTreeList").dataSource.data(data[currentWfId].metadata);
    //           // sync the treelist
    //           $("#treelist").data("kendoTreeList").dataSource.sync();
    //           toastr.success("Branching Child Value Updated!  Sister Child Value Set to " + (selectedBranchingChild == "Yes" ? "No" : "Yes"));
    //       }     
    //   }
          
    // });
  }

  addingWorkflow() {
    // initialize kendo button for add new workflow
    // $("#add-new-workflow").kendoButton({
    //   click: function(e) {
    //       // disable add new workflow button
    //       $("#add-new-workflow").data("kendoButton").enable(false);
    //       // disable workflow list dropdown
    //       $("#workflow-list").data("kendoDropDownList").enable(false);
    //       // disable cancel changes button
    //       $(".k-grid-cancel-changes").attr("disabled", true);
    //       // enable add new workflow button
    //       $("#add-new-workflow").data("kendoButton").enable(false);
    //       // enable workflow list dropdown
    //       $("#workflow-list").data("kendoDropDownList").enable(false);

    //       var treeList = $("#treelist").data("kendoTreeList");
          
          
    //       $("#workflow-name").text("Please Enter Workflow Name");     

    //       // build new workflow metadata
    //       var newWorkflowMetadata = new Array();
    //       // TODO make this as table driven as possible with default values
    //       newWorkflowMetadata[0] = 
    //           {
    //               "id": 1,
    //               "StepName": { id: 12, value: "Start Workflow" },
    //               "StepType": { id: 3, value: "Trigger" },
    //               "parentId": null,
    //               "AssignTo": { id: 1, value: "N/A" },
    //               "CompletionCondition": { id: 7, value: "N/A" },
    //               "Optional": { value: "No" },
    //               "Modifier": null,
    //               "BranchChild": { value: "" },
    //           };
          
    //       treeList.dataSource.data(newWorkflowMetadata);
    //       // select the first row of the treelist
    //       treeList.select(treeList.tbody.find(">tr:first"));
    //       // trigger the change event manually since the row was selected programmatically
    //       treeList.trigger("change");

    //       // set the current workflow id to the selected workflow id
    //       //currentWfId = selectedWorkflowId;
          
    //       // diable all buttons in the first row of the treelist
    //       $("#treelist tbody tr:first-child button").attr("disabled", true);
    //       // select first row
    //       treeList.select(treeList.tbody.find(">tr:first"));
    //       // call treeList change event manually since row was selected programmatically
    //       treeList.trigger("change");
    //       // enable edit and add buttons
    //       $("#edit-step, #newchild").attr("disabled", false);

    //       currentName = "Please Enter Workflow Name";
    //       // set workflow name to New Workflow
    //       $("#workflow-name").text(currentName);
          
    //       // set edit name input to current workflow name
    //       $("#edit-wf-name-textbox").val(currentName);

    //       //iterate over data array and return a list of all data.workflowId's
    //       var workflowIds = Object.keys(data).map(function(key) {
    //           return data[key].workflowId;
    //       });
    //       // get the largest workflow id
    //       var lastKey = Math.max.apply(null, workflowIds);
    //       currentWfId = lastKey + 1;
    //       var metadata = $("#treelist").data("kendoTreeList").dataSource.data();
    //       // add new workflow to data array
         
    //       data[currentWfId] = {
    //           "workflowId": null,
    //           "name": currentName,
    //           "status": "New Workflow",
    //           "metadata": metadata
    //       };
          
    //       //BIG TODO need to account for inactive workflows in the data return from DB when assigning workflow id
    //       // I think this is already taken care of?????

    //       // update selectWorkflowData with new workflow if new workflow is not already in selectWorkflowData
    //       if (selectWorkflowData.filter(function(e) { return e.WorkflowID == currentWfId; }).length == 0) {
    //           // check if current workflow name already exists in selectWorkflowData, if so append (i) where i is the number of repeat occurrences
    //           var i = 1;
    //           while (selectWorkflowData.filter(function(e) { return e.WorkflowName == currentName; }).length > 0) {
    //               // remove (i-1) from currentName
    //               currentName = currentName.replace(" (" + (i-1) + ")", "");
    //               currentName = currentName + " (" + i + ")";
    //               i++;
    //               // update #workflow-name with new workflow name
    //               $("#workflow-name").text(currentName);
    //           }
    //           selectWorkflowData.push({WorkflowID: currentWfId, WorkflowName: currentName});
    //       }
    //       //console.log("Add new workflow selct wf data: "); console.log(selectWorkflowData);
          
    //       //console.log("Add new workflow selct wf data: "); console.log(selectWorkflowData);
    //       // update the dropdownlist datasource with the new workflow
    //       dropDataSource.data(selectWorkflowData);
    //       // update the dropdownlist with the new workflow
    //       $("#workflow-list").data("kendoDropDownList").text(currentName);

    //       // set has changes to true
    //       viewModel.set("hasChanges", true);
          

    //   },
    //   themeColor: "info"
    // });
  }

  editingWorkflowName() {
    // // intitialize kendo button for edit-name
    // $("#edit-name").kendoButton({
    //   icon: "edit",
    //   click: function(e) {
    //       // set oldwfname
    //       oldWfName = $("#workflow-name").text();
    //       // edit workflow name
    //       var dialog = $("#edit-name-dialog").data("kendoDialog");
    //       // set value of textbox to current workflow name
    //       $("#edit-wf-name-textbox").val($("#workflow-name").text());
    //       dialog.open().center();

    //   }
    // });
  }

  deletingWorkflow() {
    // initialize kendo dialog for delete workflow
    // $("#delete-workflow-dialog").kendoDialog({
    //   width: "450px",
    //   title: "Delete Workflow",
    //   closable: false,
    //   modal: true,
    //   content: $("#delete-workflow-dialog").html(),
    //   actions: [
    //       { text: 'Delete', primary: true, action: function(e) {
    //           // delete workflow
    //           // get the selected workflow id
    //           var dataItem = $("#workflow-list").data("kendoDropDownList").dataItem();
              
    //           var selectedWorkflowId = dataItem.WorkflowID;
    //           // get the selected workflow name
    //           var selectedWorkflowName = dataItem.WorkflowName;
    //           // remove workflow from data array
    //           delete data[selectedWorkflowId];
              
    //           //console.log("data: "); console.log(data);
    //           var url = "workflow-builder/delete/" + selectedWorkflowName;
    //           $.ajax({
    //               url: url,
    //               method: 'POST',
    //               success: function(responseData) {
                      
    //                   if (responseData.success) {
    //                       toastr.success(responseData.success);
    //                       // get the selected workflow id
    //                       var dataItem = $("#workflow-list").data("kendoDropDownList").dataItem();
                          
    //                       var selectedWorkflowId = dataItem.WorkflowID;

    //                       //remove deleted workflow from drop data source
    //                       selectWorkflowData = selectWorkflowData.filter(function(e) { return e.WorkflowID != selectedWorkflowId; });
    //                       console.log("selectWorkflowData: "); console.log(selectWorkflowData);
    //                       // update the dropdownlist datasource
    //                       dropDataSource.data(selectWorkflowData);
    //                       console.log("dropDataSource: "); console.log(dropDataSource);
                          
    //                       // programatically select the first item in the dropdownlist
    //                       $("#workflow-list").data("kendoDropDownList").select(0);
    //                       // trigger the change event manually since the row was selected programmatically
    //                       $("#workflow-list").data("kendoDropDownList").trigger("change");
    //                       // get selected workflow id
    //                       var selectedWorkflowId = $("#workflow-list").data("kendoDropDownList").value();
                          
    //                       // get the selected workflow name
    //                       var selectedWorkflowName = $("#workflow-list").data("kendoDropDownList").text();
                          
    //                       // set the workflow name to the selected workflow name
    //                       $("#workflow-name").text(selectedWorkflowName);
    //                       // set the current name to the selected workflow name
    //                       currentName = selectedWorkflowName;
    //                       // get the metadata for the selected workflow
                          
    //                       // get selected workflow metadata where workflowId = selectedWorkflowId
    //                       var selectedWorkflowMetadata = data.filter(function(e) { return e.workflowId == selectedWorkflowId; })[0].metadata;
    //                       //var selectedWorkflowMetadata = data[selectedWorkflowId].metadata;
    //                       // set the datasource of the treelist to the metadata of the selected workflow
    //                       var treeList = $("#treelist").data("kendoTreeList");
    //                       treeList.dataSource.data(selectedWorkflowMetadata);
    //                       // select the first row of the treelist
    //                       treeList.select(treeList.tbody.find(">tr:first"));
    //                       // trigger the change event manually since the row was selected programmatically
    //                       treeList.trigger("change");

    //                       // set the current workflow id to the selected workflow id
    //                       currentWfId = selectedWorkflowId;

    //                       //set oldwfname to empty string
    //                       oldWfName = '';
    //                   }else{
    //                       toastr.error(responseData.error);
    //                   }

    //                   e.sender.close();
    //                   rowShading();
    //                   // enable add new workflow button
    //                   $("#add-new-workflow").data("kendoButton").enable(true);
    //                   // enable workflow list dropdown
    //                   $("#workflow-list").data("kendoDropDownList").enable(true);
    //                   // enable save and cancel changes button
    //                   $(".k-grid-save-changes").attr("disabled", false);
    //                   $(".k-grid-cancel-changes").attr("disabled", false);
    //               },
    //               error: function(responseData) {
    //                   $(".k-grid-save-changes").attr("disabled", false);
    //                   toastr.error("Error, Unable to Save Workflow!" + responseData.error);

    //               }
    //           });
    //       }},
    //       { text: 'Cancel', action: function(e) {
    //           e.sender.close();
    //       }}
    //   ],
    //   visible: false,
    // });
  }

  savingChanges() {}

  cancelChanges() {}

  incompleteBranchingCC() {
    // function to check all current metadata for a completion conditon with question mark
      // and check if it has exactly two child steps
    //   function incompleteBranchingCC() {
    //     // get current data from treelist
    //     var currentData = $("#treelist").data("kendoTreeList").dataSource.data();
    //     // get all completion conditions with question mark
    //     var completionConditions = currentData.filter(function(e) { return e.CompletionCondition.value.includes("?"); });
    //     // for each completionConditions check if it has exactly two child steps
    //     var complexCompletionConditions = [];
    //     for (var i = 0; i < completionConditions.length; i++) {
    //         // get the id of the current completion condition
    //         var currentId = completionConditions[i].id;
    //         // get the number of child steps for the current completion condition
    //         var numChildSteps = currentData.filter(function(e) { return e.parentId == currentId; }).length;
    //         // if the number of child steps is not equal to 2 then add the completion condition to the complexCompletionConditions array
    //         if (numChildSteps != 2) {
    //             complexCompletionConditions.push(completionConditions[i]);
    //         }
    //     }
    //     console.log("complexCompletionConditions: "); console.log(complexCompletionConditions);
    //     // if there are any completion conditions with question mark that have exactly two child steps then return true
    //     if (complexCompletionConditions.length > 0) {
    //         // select the first row in complexCompletionConditions
    //         var treeList = $("#treelist").data("kendoTreeList");
    //         treeList.select(treeList.tbody.find(">tr[data-uid=" + complexCompletionConditions[0].uid + "]"));
    //         saveChangesError = true;
    //         return true;
            
    //     } else {
    //         saveChangesError = false;
    //         return false;
    //     }
    // }
  }

  rowShadingApp() {
    const treeTables = document.querySelectorAll('ejs-treegrid .e-gridcontent');

    treeTables.forEach((treeTable) => {
      let rows = treeTable.querySelectorAll('tr');
      let currentRowLevel: number; 

      rows.forEach((row, index, array) => {
        const nextElement = index < array.length - 1 ? array[index + 1] : null;

        if (row.classList.contains('yes-route-start')) {
          row.classList.add('yes-route');
          let currentIndex = Array.from(rows).indexOf(row); // Get the index of the current row
      
          // Loop through rows starting from the next row after the current row
          let nextIndex = currentIndex + 1;
          while (nextIndex < rows.length) {
            let nextElement = rows[nextIndex];
            if (!nextElement?.classList.contains('end')) {
              nextElement?.classList.add('yes-route');
              nextIndex++;
            } else {
              break; // Exit the loop if the next element is an 'end'
            }
          }
        }

        if (row.classList.contains('no-route-start')) {
          row.classList.add('no-route');
          let currentIndex = Array.from(rows).indexOf(row); // Get the index of the current row
      
          // Loop through rows starting from the next row after the current row
          let nextIndex = currentIndex + 1;
          while (nextIndex < rows.length) {
            let nextElement = rows[nextIndex];
            if (!nextElement?.classList.contains('end')) {
              nextElement?.classList.add('no-route');
              nextIndex++;
            } else {
              break; // Exit the loop if the next element is an 'end'
            }
          }
        }

      });
    });
  }

  rowShading() {
    // Robert's implementation
    // console.log(this.treegrid.flatData)
    var wfTable = document.getElementById('treeGrid_gridcontrol_content_table');
    // console.log(wfTable);
    var rows = wfTable?.querySelectorAll('tbody tr');
    var allBranchingIds = [];
    var allYesChildIds = [];
    var allNoChildIds = [];
    // if (rows) {
    //   Array.from(rows).forEach((el, i) => {
    //     var e = el as HTMLElement;
    //     var currentStepId = Number(e.getAttribute('stepid'));
    //     var currentDataItem = this.treegrid.flatData[currentStepId];
    //     console.log(currentDataItem);
    //     if (currentDataItem === null) {
    //       return;
    //     }

    //     var parentId = (currentDataItem as any).parentId;

    //     var currentCCBranching = (currentDataItem as any).CompletionCondition.value.includes("?");

    //     var currentStepType = (currentDataItem as any).StepType.value;

    //     if (currentCCBranching) {
          
    //       allBranchingIds.push(currentStepId);
    //     }
    //   });
    // }
    // rows.each(function(i, el){
    //     // if i is even, set row color to white
    //     if (i % 2 == 0) {
    //         $(el).css("background-color", "white");
    //     }else{
    //         $(el).css("background-color", "#fCfCfC");
    //     }
    //     // remove background image
    //     $(el).css("background-image", "");
    //     // remove background size
    //     $(el).css("background-size", "");
        
    //     // get the current row uid
    //     var currentUid = $(el).attr("data-uid");
    //     // get the current row data item
    //     var currentDataItem = $("#treelist").data("kendoTreeList").dataSource.getByUid(currentUid);
    //     // if currentDataItem is null then do nothing
    //     if (currentDataItem == null) {
    //         return;
    //     }
    //     // get the current row level
    //     var parentId = currentDataItem.parentId;

    //     // set the row color to a progressively darker shade of grey for each level of the treelist
    //     //$(el).css("background-color", "rgb(" + (255 - (parentId * 5)) + "," + (255 - (parentId * 5)) + "," + (255 - (parentId * 5)) + ")");
    //     // get completion condition of current row
    //     var currentCCBranching = currentDataItem.CompletionCondition.value.includes("?");
    //     // if true, get the id of current row
    //     var currentId = currentDataItem.id;

    //     // get step type of current row
    //     var currentStepType = currentDataItem.StepType.value;
        
    //     // add id of current row to allBranchingIds
        
    //     if (currentCCBranching) {

    //         //$(el).css("background-color", "#f1f1f1");
    //         //$(el).css("background-image", "repeating-linear-gradient(45deg, #e1e1e1 0, #e1e1e1 1.5px, #f1f1f1 0, #f1f1f1 50%)");
            
    //         //$(el).css("background-size", "15px 15px");
            
    //         allBranchingIds.push(currentId);
    //     }
        

    //     // if current row's setp type is End Workflow then set unique pattern
    //     if (currentStepType == "End Workflow" || currentStepType == "Start New Workflow") {
    //         $(el).css("background-color", "#FD9B9B");
    //         $(el).css("background-image", "repeating-linear-gradient(45deg, #e1e1e1 0, #e1e1e1 1.5px, #f1f1f1 0, #f1f1f1 50%)");
            
    //         $(el).css("background-size", "15px 15px");
    //         return;
    //     }
    
    //     // if current row's parentId is in allBranchingIds then set the row color light green if branch child of current row is yes, otherwise set row color to light red
    //     if (allBranchingIds.includes(parentId)) {
    //         // get the current row branch child value
    //         var currentBranchChild = currentDataItem.BranchChild.value;
    //         // if current branch child value is yes then set row color to light green, otherwise set row color to light red
    //         if (currentBranchChild == "Yes") {
    //             $(el).css("background-color", "rgb(222, 227, 255)");
    //             allYesChildIds.push(currentId);
    //         } else if (currentBranchChild == "No") {
    //             $(el).css("background-color", "rgb(255, 219, 219)");
    //             allNoChildIds.push(currentId);
    //         }
    //         return;
    //     }

    //     // if current row's parentId is in allYesChildIds then set the row color to light green
    //     if (allYesChildIds.includes(parentId)) {
    //         $(el).css("background-color", "rgb(222, 227, 255)");
    //         allYesChildIds.push(currentId);
    //         return;
    //     }
    //     // if current row's parentId is in allNoChildIds then set the row color to light red
    //     if (allNoChildIds.includes(parentId)) {
    //         $(el).css("background-color", "rgb(255, 219, 219)");
    //         allNoChildIds.push(currentId);
    //         return;
    //     }

    //     // TODO Figure out how to shade rows that have multiple branching parents
    

        

    // });

    // // on click of delete row set viewmodel has changes to true
    // $(".k-grid-remove-command").click(function(e){
    //     console.log("delete row clicked");
    //     viewModel.set("hasChanges", true);
    //     viewModel.trigger("change");
    //     rowRemoved = true;
    // });
  }

  getAllChildren() {
    // function to get all children of parent row id 
    // and any children of those children
    //   function getAllChildren(parentId) {
    //     // get all children of parent row id
    //     var children = data[currentWfId].metadata.filter(function (obj) {
    //         return obj.parentId === parentId;
    //     });
    //     // if children is empty then return empty array
    //     if (children.length == 0) {
    //         return [];
    //     }
    //     // get all children of children
    //     var allChildren = [];
    //     for (var i = 0; i < children.length; i++) {
    //         allChildren.push(children[i]);
    //         allChildren = allChildren.concat(getAllChildren(children[i].id));
    //     }
    //     return allChildren;
    // }
  }
}
