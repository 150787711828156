// Angular
import { Component, ViewChild, AfterViewInit, Output, EventEmitter, WritableSignal, signal, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

// 3rd Party
import { MenuItemModel, MenuEventArgs, MenuModule, Menu, MenuAnimationSettingsModel } from '@syncfusion/ej2-angular-navigations';
import { FontAwesomeModule, IconDefinition } from '@fortawesome/angular-fontawesome';
import {
  faBriefcase,
  faHospitalUser,
  faStopwatch,
  faCalendarDays,
  faBuildingColumns,
  faDiagramPredecessor,
  faInbox,
  faMoneyCheckDollar,
  faCalendarCheck,
  faNotesMedical,
  faHospital,
  faUsers,
  faIdCard,
  faCircleHalfStroke,
  faWandMagicSparkles,
  faUserGear,
  faRightFromBracket,
  faAddressBook,
  faGear,
  faFileCircleExclamation,
  faMap
} from '@fortawesome/free-solid-svg-icons';

// Internal
import { GlobalsService } from '@services/globals/globals.service';
import { UserPreferencesService } from '@services/user/user-preferences.service';
import { ThemeService } from '@services/theme/theme.service';
import { FileViewService } from '@root/src/app/shared/services/file-view/file-view.service';
import { TooltipAllModule } from '@syncfusion/ej2-angular-popups';

export interface MenuItemModelWithFA extends MenuItemModel {
  faIcon?: IconDefinition,
  items?: MenuItemModelWithFA[],
  top?: boolean
}

@Component({
  selector: 'app-main-nav',
  standalone: true,
  imports: [
    MenuModule,
    FontAwesomeModule,
    TooltipAllModule
  ],

  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent implements AfterViewInit {

  constructor(
    private router: Router,
    private user: UserPreferencesService,
    private theme: ThemeService,
    public globals: GlobalsService,
    private fileView: FileViewService,
  ) {
    this.isLoggedIn$ = this.user.isLoggedIn;
  }

  @ViewChild('mainNav') mainNav: Menu;
  @Input('isSidebarOpen') isSidebarOpen: boolean;
  @Input() isSideBarSmall: boolean = false;
  @Output() toggleDarkModeLogo = new EventEmitter<string>();
  isLoggedIn$: Observable<boolean>;
  layoutMode: WritableSignal<string> = signal('');
  animationSettings: MenuAnimationSettingsModel = { effect: 'SlideDown', duration: 2000 };
  TooltipAnimation: Object = {
    open: { effect: 'ZoomIn', duration: 400, delay: 100 },
    close: { effect: 'ZoomOut', duration: 200, delay: 0 }
  };
  mainNavItems: MenuItemModelWithFA[] = [
    { text: 'My Files', id: 'my-files', faIcon: faAddressBook, top: true },
    {
      text: 'Case Files', id: 'case-files', faIcon: faBriefcase, top: true, items:
        [
          { text: 'List All Files', id: 'case-files', faIcon: faBriefcase },
          { separator: true }, { text: 'Patients', id: 'patients', faIcon: faHospitalUser },
          { separator: true },
          { text: 'Assign Tasks', id: 'assignments', faIcon: faStopwatch },
        ]
    },
    { text: 'Scheduler', id: 'scheduler', faIcon: faCalendarDays, top: true },
    { text: 'Law Firms', id: 'law-firms', faIcon: faBuildingColumns, top: true },
    { text: 'Reductions', id: 'reduction-requests', faIcon: faDiagramPredecessor, top: true },
    { text: 'Intakes', id: 'file-intake-report', faIcon: faInbox, top: true },
    {
      text: 'Accounting', id: 'accounting', faIcon: faMoneyCheckDollar, top: true, items:
        [
          { text: 'Invoice Processing', id: 'remittance', faIcon: faFileCircleExclamation },
        ]
    },

    {
      text: 'Providers', id: 'providers', faIcon: faHospital, top: true, items:
        [
          { text: 'List All Providers', id: 'providers', faIcon: faHospital },
          { separator: true },
          { text: 'Fee Schedules', id: 'fee-schedules', faIcon: faCalendarCheck },
          { separator: true },
          { text: 'Procedure Codes', id: 'procedure-codes', faIcon: faNotesMedical },
          { separator: true },
          { text: 'Provider Map', id: 'provider-map', faIcon: faMap }
        ]
    },
    {
      text: 'Users', id: 'users', faIcon: faUsers, top: true, items: [
        { text: 'List All Users', id: 'users', faIcon: faUsers },
        { separator: true },
        { text: 'User Roles', id: 'users-roles', faIcon: faIdCard },
      ]
    },
    {
      text: 'Settings', id: 'profile', faIcon: faGear, top: true, items: [
        { text: 'Toggle Dark Mode', id: 'toggle-dark-mode', faIcon: faCircleHalfStroke },
        { separator: true },
        { text: 'Toggle App Mode', id: 'toggle-app-mode', faIcon: faWandMagicSparkles },
        { separator: true },
        { text: 'Profile Settings', id: 'profile', faIcon: faUserGear },
        { separator: true },
        { text: 'Logout', id: '/', faIcon: faRightFromBracket }
      ]
    }
  ];

  ngOnInit() {
    this.user.appLayout$.subscribe((layout) => {
      this.layoutMode.set(layout);
    });
  }

  ngAfterViewInit(): void {
    this.user.isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';

    if (this.globals.isResponsive) {
      this.mainNav.items.unshift({ id: 'close', iconCss: 'e-icons e-close' });
    }
  }

  // Adjust orientation for layout type
  onCreated() {

    if (this.layoutMode() === 'on') {
      this.mainNav.orientation = 'Vertical';
    } else {
      this.mainNav.orientation = 'Horizontal';
    }
  }

  // Add active style to current page's associated menu item
  beforeItemRender(args: MenuEventArgs): void {

    if (args.element && args.item.id) {
      const el = args.element;
      const path = window.location.pathname.replace(/\//g, '');

      if (path === args.item.id) {
        el.classList.add('active');
      }
    }
  }

  select(args: MenuEventArgs) {
    const liElement = args.element.closest('li');
    var toolbarItems = Array.from(document.getElementsByClassName('e-menu-item'));

    switch (args.item.id) {
      case 'my-files': this.fileView.toggleLeftColumn(); break;
      case 'toggle-dark-mode': args.event?.stopPropagation(); this.user.toggleDarkMode(); break;
      case 'toggle-app-mode': args.event?.stopPropagation(); this.user.toggleLayoutMode(); break;
      default:
        this.goToPage(args.item.id as string);

        // Remove all active classes
        for (let i = 0; i < toolbarItems.length; i++) {
          toolbarItems[i].classList.remove('active');
        }

        // Add active class to clicked element
        liElement?.classList.add('active');
        break;
    }
  }

  // Navigate to page and remove active class from other toolbar items
  goToPage(pageName: string) {

    // Closes mobile nav
    if (pageName === 'close') {
      this.mainNav.close();
    } else {
      this.router.navigate(['/' + pageName]);
    }
  }
}