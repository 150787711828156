import { Component, Input } from '@angular/core';
import { DropdownType } from '@models/components/providers-grid.model';
import { ApiService } from '@services/api/api.service';
import { Query, ReturnOption } from '@syncfusion/ej2-data';
import { ComboBoxModule } from '@syncfusion/ej2-angular-dropdowns';
import { FormGroup } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-dropdown-single',
  standalone: true,
  imports: [ComboBoxModule, ReactiveFormsModule],
  templateUrl: './dropdown-single.component.html',
  styleUrl: './dropdown-single.component.scss'
})

export class DropdownSingleComponent {
  constructor(private api: ApiService) { }
  //Textbox 
  @Input() id = "Id"
  @Input() placeholder: string = "";
  @Input() name = "";
  @Input() public form: FormGroup;

  //Textbox data
  @Input() endpoint: string;
  @Input() idName: string = "Id";
  @Input() descriptionName: string = "Description";
  @Input() defaultValue: any;

  source: DropdownType[];
  fields: object = { text: 'description', value: 'id' };

  ngOnInit(): void {
    this.source = this.getDropdown(this.endpoint, this.idName, this.descriptionName)
  }

  getDropdown(endpoint: string, idName: string, descriptionName: string): DropdownType[] {
    let result: DropdownType[] = [];
    this.api.getOdata(endpoint).executeQuery(new Query())
      .then((e: ReturnOption) => {
        (e.result as object[]).forEach((element) => {
          let res = element as any
          let newTable: DropdownType = {
            id: res[idName],
            description: res[descriptionName],
          }
          result.push(newTable);
        });
      });
    return result;
  }
}
