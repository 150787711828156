<!-- Main container for the work item form -->
<ng-template #formContent>
  <div id="work-item-form">
    <loading-spinner [loading]="loading()" />
    
    <form [formGroup]="workItemForm" [class.hidden]="loading()" class="work-item-form">
      <!-- Hidden title field -->
      <div class="form-group required" style="display: none;">
        <span class="input-icon">
          <fa-icon [icon]="formIcons.title" />
        </span>
        <ejs-textbox formControlName="Title" placeholder="Title *" cssClass="e-outline" />
      </div>

      <!-- Main sections row -->
      <div class="form-row">
        <!-- Left side - Classification -->
        <div class="form-col">
          <div class="classification-section">
            <div class="section-header">
              <fa-icon [icon]="formIcon" />
              <span>Classification</span>
            </div>

            <!-- Status -->
            <div class="form-group required">
              <span class="input-icon">
                <fa-icon [icon]="formIcons.status" />
              </span>
              <ejs-dropdownlist formControlName="StatusId" [dataSource]="statusesDataManager"
                [fields]="{ text: 'Name', value: 'Id' }" placeholder="Status *" cssClass="e-outline" />
            </div>

            <!-- Category -->
            <div class="form-group required">
              <span class="input-icon">
                <fa-icon [icon]="formIcons.category" />
              </span>
              <ejs-dropdownlist formControlName="CategoryId" [dataSource]="categoriesDataManager"
                [fields]="{ text: 'Name', value: 'Id' }" placeholder="Category *" cssClass="e-outline" />
            </div>

            <!-- Type -->
            <div class="form-group required sub-field">
              <span class="input-icon">
                <fa-icon [icon]="formIcons.type" />
              </span>
              <ejs-dropdownlist formControlName="TypeId" [dataSource]="filteredTypes"
                [fields]="{ text: 'Name', value: 'Id' }" placeholder="Type *" cssClass="e-outline" />
            </div>

            <!-- Priority -->
            <div class="form-group required">
              <span class="input-icon">
                <fa-icon [icon]="formIcons.priority" />
              </span>
              <ejs-dropdownlist formControlName="PriorityId" [dataSource]="prioritiesDataManager"
                [fields]="{ text: 'Name', value: 'Id' }" placeholder="Priority *" cssClass="e-outline" />
            </div>
          </div>
        </div>

        <!-- Right side - Details -->
        <div class="form-col">
          <div class="details-section">
            <div class="section-header">
              <fa-icon [icon]="formIcons.description" />
              <span>Details</span>
            </div>

            <!-- Due Date -->
            <div class="form-group">
              <span class="input-icon">
                <fa-icon [icon]="formIcons.dueDate" />
              </span>
              <ejs-datepicker formControlName="DueDate" placeholder="Due Date" cssClass="e-outline" />
            </div>

            <!-- Assigned To -->
            <div class="form-group">
              <span class="input-icon">
                <fa-icon [icon]="formIcons.assignedTo" />
              </span>
              <ejs-dropdownlist formControlName="AssignedTo" [dataSource]="usersDataManager"
                [fields]="{ text: 'Name', value: 'Id' }" placeholder="Assigned To" cssClass="e-outline" />
            </div>

            <!-- Provider field -->
            <div class="form-group required" *ngIf="showProviderField">
              <span class="input-icon">
                <fa-icon [icon]="formIcons.provider" />
              </span>
              <ejs-dropdownlist formControlName="ProviderId" [dataSource]="providersDataManager"
                [fields]="{ text: 'Name', value: 'Id' }" placeholder="Provider *" cssClass="e-outline" />
            </div>

            <!-- Case Files -->
            <div class="form-group" *ngIf="!inCaseFileContext">
              <span class="input-icon">
                <fa-icon [icon]="formIcons.caseFiles" />
              </span>
              <ejs-multiselect [dataSource]="caseFilesDataManager" [fields]="{ text: 'FileNumber', value: 'Id' }"
                [value]="selectedCaseFiles" (change)="onCaseFilesChange($event)" placeholder="Select Case Files"
                cssClass="e-outline">
              </ejs-multiselect>
            </div>
          </div>
        </div>
      </div>

      <!-- Notes section - Full width -->
      <div class="form-group">
        <span class="input-icon">
          <fa-icon [icon]="formIcons.description" />
        </span>
        <ejs-textbox formControlName="Description" placeholder="Notes" multiline="true" cssClass="e-outline" />
      </div>
    </form>
  </div>
</ng-template>


<!-- Direct form for grid -->
<div *ngIf="!displayButtons">
  <ng-container *ngTemplateOutlet="formContent"></ng-container>
</div> 