import { Injectable, inject } from '@angular/core';
import { ProcedureCode } from '@models/data-contracts';
import { AuthenticatedServiceBase } from '@core/auth/auth.base';
import { Query } from '@syncfusion/ej2-data';

@Injectable({ providedIn: 'root' })
export class ProcedureCodeService extends AuthenticatedServiceBase {
  protected override readonly endpoint = this.APIEndpoints.ProcedureCodes;

  constructor() {
    super();
  }

  async updateProcedureCode(code: ProcedureCode): Promise<void> {
    await this.api.basicPatch(`${this.APIEndpoints.ProcedureCodes}(${code.Id})`, code);
  }

  async getModalityTypes(query: Query): Promise<any> {
    return this.api.getOdata(this.APIEndpoints.ModalityTypes).executeQuery(query);
  }
}
