import { Injectable, isDevMode } from '@angular/core';
import { CloudWatchLogs } from '@aws-sdk/client-cloudwatch-logs';
import config from '@app/config';

export interface LogEntry {
    message: string;
    level: 'error' | 'warn' | 'info' | 'debug';
    timestamp?: string;
    context?: any;
    error?: any;
}

/**
 * @internal
 * Internal logging service - do not import directly.
 * Use NotificationService for user messages or
 * ErrorHandlingService (via Base class) for error handling.
 *
 * Features:
 * - Technical error logging
 * - AWS CloudWatch integration
 * - Development console logging
 * - Structured log entries
 * - Graceful fallback to console logging
 */
@Injectable({ providedIn: 'root' })
export class LoggingService {
    private cloudWatch?: CloudWatchLogs;
    private readonly isDev = isDevMode();
    private readonly awsConfig = config.aws;

    constructor() {
        this.initializeCloudWatch();
    }

    private initializeCloudWatch() {
        if (this.awsConfig?.cloudwatch?.logGroup) {
            try {
                this.cloudWatch = new CloudWatchLogs({
                    region: this.awsConfig.region || 'us-west-2'
                });
                console.info('CloudWatch logging initialized');
            } catch (error) {
                console.warn('CloudWatch initialization failed');
            }
        } else {
            console.info('CloudWatch not configured, using console logging');
        }
    }

    async log(entry: LogEntry): Promise<void> {
        const timestamp = new Date().toISOString();

        // Always log to console in dev mode
        if (this.isDev || !this.cloudWatch) {
            if (entry.level === 'error' && entry.error) {
                // Error details already logged by ErrorHandlingService
                return;
            }
            console[entry.level](entry.message, entry.context);
            return;
        }

        // Only attempt CloudWatch logging if properly initialized
        if (this.cloudWatch && this.awsConfig?.cloudwatch?.logGroup) {
            try {
                await this.cloudWatch.putLogEvents({
                    logGroupName: this.awsConfig.cloudwatch.logGroup,
                    logStreamName: `${this.isDev ? 'dev' : 'prod'}-${this.awsConfig.cloudwatch.logStream}`,
                    logEvents: [{
                        timestamp: Date.now(),
                        message: JSON.stringify(entry)
                    }]
                });
            } catch (error) {
                console.warn('CloudWatch logging failed, falling back to console:', error);
                console[entry.level](entry.message, entry.context || '');
            }
        }
    }

    // Convenience methods
    info(message: string, context?: any): void {
        this.log({ message, level: 'info', context });
    }

    warn(message: string, context?: any): void {
        this.log({ message, level: 'warn', context });
    }

    debug(message: string, context?: any): void {
        this.log({ message, level: 'debug', context });
    }
}
