// Angular
import { Component, ViewChild } from '@angular/core';
import { ReactiveFormsModule, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MapGeocoder } from '@angular/google-maps';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';

// 3rd Party
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { TextBoxModule, NumericTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { ListViewModule } from '@syncfusion/ej2-angular-lists';
import { ButtonModule, CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { Query, ReturnOption } from '@syncfusion/ej2-data';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { ApiService } from '@services/api/api.service';
import { Spacer } from "@ui/spacer/spacer.component";
import { DropdownSingleComponent } from '@ui/dropdown-single/dropdown-single.component';
import { DropdownMultiComponent } from '@ui/dropdown-multi/dropdown-multi.component';
import { AddAddressComponent } from '../add-address/add-address.component';

@Component({
  selector: 'add-provider-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    DialogModule,
    TextBoxModule,
    NumericTextBoxModule,
    ListViewModule,
    CheckBoxModule,
    DatePickerModule,
    DropdownSingleComponent,
    DropdownMultiComponent,
    ButtonModule,
    Spacer,
    AddAddressComponent
  ],
  templateUrl: './add-provider.component.html',
  styleUrl: './add-provider.component.scss'
})

export class AddProviderForm {
  constructor(
    private api: ApiService,
    private geocoder: MapGeocoder,
    private fb: FormBuilder
  ) { }

  // For dropdowns
  providerTypes = APIEndpoints.ProviderTypes;
  providerPriorities = APIEndpoints.ProviderPriorities;
  states = APIEndpoints.States;
  users = APIEndpoints.Users;
  defaultState: any;

  //Address
  @ViewChild('AddressForm') addressForm: AddAddressComponent;

  /**
   * Form Groups
   * Fields made to match models in data-contracts
   *  - @see {@link @models/data-contracts} 
   */
  providerFormGroup: FormGroup = this.fb.group({
    Name: [null, [Validators.required]],
    ProviderType: [null],
    TaxId: [null],
    Notes: [null],
    NotesImportant: [null],
    CareCoordinator: [null],
    ProviderRelationsManager: [null],
    SignedPurchaseAgreement: [null],
    ReimbursementRate: [null],
    ReimbursementRateEffectiveDate: [null],
    InNetwork: [false, [Validators.required]],
    PriorityId: [null],
    AddressId: [null],
    Latitude: [0],
    Longitude: [0],
    SignedHipaa: [null],
    SignedHipaaDate: [null],
    SignedW9: [null],
    SignedW9Date: [null],
    W9Name: [null, [Validators.required]],
    SpanishSpeaking: [null],
    ReferringProvider: [null],
    VirtualProvider: [null],
    AlternateId: [null],
    Active: [true],
    WebsiteUrl: [null],
    PhoneId: [null],
    FaxId: [null],
    CheckpointEligible: [null],
    HoldsOwnAR: [null],
  });

  phoneFormGroup: FormGroup = this.fb.group({
    ObjectId: [null],
    ObjectType: [null],
    PhoneType: [null],
    PhoneNumber: [null],
    Extension: [null],
  });

  faxFormGroup: FormGroup = this.fb.group({
    ObjectId: [null],
    ObjectType: [null],
    PhoneType: [null],
    PhoneNumber: [null],
    Extension: [null],
  });

  ngOnInit() {
    this.getDefaultState();
  }

  // Formats the provider info into a database friendly format
  getProviderObj = () => Object.fromEntries(Object.entries(this.providerFormGroup.controls).map(([key, value]) => [key, value.value]));
  // Formats the address info into a database friendly format
  getAddressObj = () => Object.fromEntries(Object.entries(this.addressForm.addressFormGroup.controls).map(([key, value]) => [key, value.value]));

  // Adds a provider to the database
  async addProvider() {

    try {
      // Get long/Lat
      let address = this.addressForm.addressFormGroup.value.Address + " " + this.addressForm.addressFormGroup.value.Address2 + " " + this.addressForm.addressFormGroup.value.City;
      let coder = await firstValueFrom(this.geocoder.geocode({ address: address }));
      this.providerFormGroup.value.Latitude = coder.results[0].geometry.location.lat();
      this.providerFormGroup.value.Longitude = coder.results[0].geometry.location.lng();

      // Create address record and get id
      const res = await this.api.basicPost(APIEndpoints.Addresses, this.getAddressObj());
      const data = await (res as Response).json();
      this.providerFormGroup.value.AddressId = data["Id"];

      // Create provider obj
      await this.api.basicPost(APIEndpoints.Providers, this.getProviderObj());

    } catch (error) {
      console.log(error)
    }
  }

  //Gets a default state for the states dropdown
  getDefaultState() {
    this.api.getOdata(APIEndpoints.ConfigVariables).executeQuery(new Query().where('VariableName', 'equal', 'default_state', true))
      .then((e: ReturnOption) => {
        (e.result as object[]).forEach((element) => {
          let res = element as any
          this.defaultState = res["VariableValue"]
        });
      });
  }
}
