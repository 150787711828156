import { Invoice, Deposit } from '@models/data-contracts';

export interface AuditLogSection {
    sectionHeader: string;
    logs: any[];
}

export enum GridCommand {
    Edit = 'Edit',
    Logs = 'Logs'
}

export const ERRORS = {
    XIRR: {
        MISSING_DATA: {
            message: 'Missing required data for XIRR calculation',
            technical: 'Missing deposits or invoices for XIRR calculation'
        },
        CALCULATION_FAILED: {
            message: 'Unable to calculate financial performance',
            technical: 'XIRR calculation failed'
        }
    },
    AUDIT: {
        LOAD_FAILED: {
            message: 'Unable to load audit history',
            technical: 'Failed to load audit logs'
        },
        DIALOG: {
            NOT_INITIALIZED: {
                message: 'Unable to show audit logs',
                technical: 'Logs dialog not initialized'
            },
            DISPLAY_ERROR: {
                message: 'Unable to display audit logs',
                technical: 'Error displaying logs dialog'
            },
            CLOSE_ERROR: {
                message: 'Unable to close audit logs',
                technical: 'Error closing logs dialog'
            }
        }
    }
} as const;
