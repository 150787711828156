// Angular
import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';

// 3rd Party
import { DataManager, Query } from '@syncfusion/ej2-data';
import { CellSaveEventArgs } from '@syncfusion/ej2-angular-treegrid';
import { GridModel, RowSelectEventArgs, CommandClickEventArgs, EditEventArgs} from '@syncfusion/ej2-angular-grids';
import { DropDownList, DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { GlobalsService } from '@services/globals/globals.service';
import { ApiService } from '@services/api/api.service';
import { GridTemplateComponent } from '@grids/grid-template/grid-template.component';

// Models
import { User, Role, XrefUsersRole } from '@models/data-contracts';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { AddUserComponent } from '@components/forms/user/add/add-user.component';

@Component({
  selector: 'users-grid',
  standalone: true,
  imports: [
    CommonModule,
    DropDownListModule,
    GridTemplateComponent,
    DialogModule,
    AddUserComponent,
  ],
  templateUrl: './users-grid.component.html',
  styleUrls: ['./users-grid.component.scss']
})
export class UsersGridComponent implements OnInit {
  
  constructor(
    private api: ApiService,
    private router: Router,
    private globals: GlobalsService,
    private toastMessage: ToastMessageService
  ) { }

  // Grid variables
  @ViewChild('usersGrid') usersGrid: GridTemplateComponent;
  @ViewChild('XrefUsersRolesEditTemplate', { static: true }) XrefUsersRolesEditTemplate!: string;
  @ViewChild('addUserDialog') addUserDialog: DialogComponent;
  usersGridSettings: GridModel;
  gridCurrentRow: RowSelectEventArgs;
  isAdmin: boolean = false;
  showAddUserDialog = false;
  showEditUserDialog: boolean = false;
  
  // Initialize user roles variables
  @ViewChild('rolesDD') rolesDD: DropDownList;
  rolesData: DataManager = this.api.getOdata(APIEndpoints.Roles);

  // Add dialog template in the component
  @ViewChild('editUserDialog') editUserDialog: any;
  selectedUser: User | null = null;

  mode: 'add' | 'edit' = 'add';

  showAddUserForm = false;

  ngOnInit(): void {
    this.usersGridSettings = {
      dataSource: this.api.getOdata(APIEndpoints.Users),
      query: new Query().expand('XrefUsersRoles($expand=Role),XrefPhoneUsers($expand=Phone)'),
      columns: [
        { field: 'Id', isPrimaryKey: true, visible: false, showInColumnChooser: false },
        { field: 'Username', allowEditing: false },
        { field: 'Name', allowEditing: false },
        { field: 'Email', allowEditing: false },
        { field: 'Title' },
        { field: 'XrefUsersRoles', headerText: 'Role', allowFiltering: false, allowSorting: false, editTemplate: this.XrefUsersRolesEditTemplate, 
          valueAccessor: (field: string, data: Object): string[] => this.roleValueAccessor(field, data) },
        { 
          type: 'commands', 
          headerText: 'Actions', 
          allowSorting: false, 
          visible: this.globals.isResponsive ? false : true 
        }
      ],
      toolbar: ['Add User'],
      editSettings: { 
        allowEditing: false
      },
      actionComplete: ($event: any) => this.onActionComplete($event),
      toolbarClick: ($event: any) => this.onToolbarClick($event),
      commandClick: ($event: CommandClickEventArgs) => this.onCommandClick($event)
    };
  }

  // Update display of roles by retrieving the RoleName from the data
  roleValueAccessor(field: string, data: object): string[] {
    var returnedData: string[] = [];

    // Check if data[field] is defined and is an array
    if (Array.isArray((data as any)[field])) {
      returnedData = (data as any)[field].map(
        (XrefUsersRoles: { Role: { RoleName: string } }) => {
          return XrefUsersRoles.Role.RoleName;
        }
      );
    } else {
      // Handle the case where data[field] is not an array
      returnedData = [(data as any)[field]?.Role?.RoleName || ''];
    }

    return returnedData;
  }

  onCreated() {
    this.usersGrid.grid.toolbar = this.usersGrid.settings.toolbar as any[];
    this.usersGrid.insertToolbarItem({ 
        text: 'Add User', 
        tooltipText: 'Add User', 
        prefixIcon: 'e-icons e-add', 
        align: 'Right',
        cssClass: 'e-primary'
    });
  }

  onActionComplete(args: EditEventArgs) {
    
    if (args.requestType === 'beginEdit') {
      const dialog = (args as any).dialog;
      dialog.header = `Editing ${(args.rowData as any)?.Username}`;
    }
  }

  onToolbarClick(args: any) {
    if (args.item.text === 'Add User') {
      this.mode = 'add';
      this.selectedUser = null;
      this.showAddUserDialog = true;
      this.showAddUserForm = true;
      if (this.addUserDialog) {
        this.addUserDialog.show();
        this.addUserDialog.header = 'Add User';
      }
    }
  }

  onCommandClick(commandClickArgs: CommandClickEventArgs) {
    if (commandClickArgs.commandColumn?.title === 'Edit') {
      this.mode = 'edit';
      this.selectedUser = commandClickArgs.rowData as User;
      this.showAddUserDialog = true;
      this.showAddUserForm = true;
      if (this.addUserDialog) {
        this.addUserDialog.show();
        this.addUserDialog.header = 'Edit User';
      }
    }
  }

  // Capture changes made to user's role
  rolesDDChanged(args: any): void {

    if (args.isInteracted) {
      var selectedRows = this.usersGrid.grid?.getSelectedRowIndexes();

      if (this.usersGrid.grid?.editSettings.mode === 'Batch') {

        if (selectedRows && selectedRows.length === 1) {
          var currentCell = this.usersGrid.grid?.getCellFromIndex(selectedRows[0], this.usersGrid.grid?.getColumnIndexByField('XrefUsersRoles'));
          this.usersGrid.grid.updateCell(selectedRows[0], 'XrefUsersRoles', args.value);
          currentCell.innerHTML = args.itemData.RoleName;
        }
      }
    }
  }

  handleCloseForm(refresh: boolean = false): void {
    this.showAddUserDialog = false;
    this.showAddUserForm = false;
    this.mode = 'add';
    this.selectedUser = null;
  }

  handleAddUser(): void {
    this.mode = 'add';
    this.selectedUser = null;
    this.showAddUserForm = true;
  }

  handleEditUser(user: User): void {
    this.mode = 'edit';
    this.selectedUser = user;
    this.showAddUserForm = true;
  }
}
