<div id="case-file-form" [class]="formClasses.formContainer" #caseFileFormContainer>
    <!-- Add loading overlay -->
    <div *ngIf="loadingStatus().loading" class="loading-overlay">
        <div class="loading-content">
            <div class="spinner"></div>
            <div class="loading-message">{{ loadingStatus().message }}</div>
        </div>
    </div>

    <h2 [class]="formClasses.mainTitle" *ngIf="displayMainTitle">
        <fa-icon [icon]="caseFileIcons.form" />
        <span>{{formMainTitle ?? 'Case File'}}</span>
    </h2>

    <form [formGroup]="caseFileForm" [class]="formClasses.form" (ngSubmit)="onSubmit()">

        <section [class]="formClasses.section + ' ' + formClasses.subForm + ' patient-section w-100'">
            <h2 [class]="formClasses.subFormTitle">
                <fa-icon [icon]="caseFileIcons.patient" /> Patient
            </h2>


            <!-- Check that event binding is correct -->
            <patient-form-component
                #patientFormComp
                [patient]="patientData"
                [displayButtons]="false"
                [displayMainTitle]="false"
                [showPatientSelect]="submitType === 'POST'"
                (patientSelected)="onPatientSelected($event)"
            ></patient-form-component>
        </section>

        <section [class]="formClasses.section + ' ' + formClasses.subForm + ' law-firm-section'">
            <h2 class="form-title"><fa-icon [icon]="caseFileIcons.lawFirm" /> Law Firm</h2>
            <div [class]="formClasses.group">
                <div [class]="formClasses.inputContainer + ' ' + formClasses.oneCol"
                    [class.error]="caseFileForm.get('LawFirmId')?.errors && caseFileForm.get('LawFirmId')?.touched">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.lawFirmId" />
                        <ejs-tooltip
                            *ngIf="caseFileForm.get('LawFirmId')?.errors && caseFileForm.get('LawFirmId')?.touched"
                            [content]="getErrorMessage('LawFirmId')" />
                    </span>
                    <ejs-dropdownlist formControlName="LawFirmId" floatLabelType="Auto" [placeholder]="'Law Firm'"
                        [class]="formClasses.input" [dataSource]="lawFirms" [query]="lawFirmsQuery"
                        [allowFiltering]="true" [fields]="{ text: 'Name', value: 'Id', Id: 'Id' }"
                        [value]="caseFileForm.get('LawFirmId')?.value" (change)="onLawFirmChange($event)" />
                    <ng-container
                        *ngIf="caseFileForm.get('LawFirmId')?.invalid && caseFileForm.get('LawFirmId')?.touched">
                        <span [class]="formClasses.error">{{getErrorMessage('LawFirmId')}}</span>
                    </ng-container>
                </div>

                <div *ngIf="displayFields.includes('LawFirmContact')"
                    [class]="formClasses.inputContainer + ' ' + formClasses.threeCol"
                    [class.error]="caseFileForm.get('LawFirmContact')?.errors && caseFileForm.get('LawFirmContact')?.touched">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.lawFirmContact" />
                        <ejs-tooltip
                            *ngIf="caseFileForm.get('LawFirmContact')?.errors && caseFileForm.get('LawFirmContact')?.touched"
                            [content]="getErrorMessage('LawFirmContact')" />
                    </span>
                    <ejs-dropdownlist formControlName="LawFirmContact" floatLabelType="Auto"
                        [placeholder]="'Law Firm Contact'" [class]="formClasses.input" [dataSource]="lawFirmContacts"
                        [query]="lawFirmContactsQuery" [fields]="{ text: 'ContactName', value: 'Id' }"
                        [allowFiltering]="true" [filterBarPlaceholder]="'Search contacts...'" [showClearButton]="true"
                        [value]="caseFileForm.get('LawFirmContact')?.value" />
                    <ng-container
                        *ngIf="caseFileForm.get('LawFirmContact')?.invalid && caseFileForm.get('LawFirmContact')?.touched">
                        <span [class]="formClasses.error">{{getErrorMessage('LawFirmContact')}}</span>
                    </ng-container>
                </div>

                <div *ngIf="displayFields.includes('Attorney')"
                    [class]="formClasses.inputContainer + ' ' + formClasses.threeCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.attorney" />
                    </span>
                    <ejs-dropdownlist formControlName="Attorney" floatLabelType="Auto" [placeholder]="'Attorney'"
                        [class]="formClasses.input" [dataSource]="attorneys" [query]="attorneysQuery"
                        [allowFiltering]="true" [fields]="{ text: 'ContactName', value: 'Id', Id: 'Id' }"
                        [value]="caseFileForm.get('Attorney')?.value" />
                </div>

                <div *ngIf="displayFields.includes('LawFirmFileNumber')"
                    [class]="formClasses.inputContainer + ' ' + formClasses.threeCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.lawFirmFileNumber" />
                    </span>
                    <ejs-textbox formControlName="LawFirmFileNumber" floatLabelType="Auto" placeholder="Law Firm File #"
                        [class]="formClasses.input" />
                </div>

                <div [class]="formClasses.inputContainer + ' ' + formClasses.threeCol"
                    [class.error]="caseFileForm.get('StatuteOfLimitations')?.errors && caseFileForm.get('StatuteOfLimitations')?.touched">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.statuteOfLimitations" />
                    </span>
                    <ejs-datepicker formControlName="StatuteOfLimitations" floatLabelType="Auto"
                        [class]="formClasses.input" [placeholder]="'Statute Of Limitations'" [format]="'yyyy-MM-dd'"
                        [value]="caseFileForm.get('StatuteOfLimitations')?.value" (focus)="onDatePickerFocus($event)"
                        (change)="onDateOnlyChange($event, 'StatuteOfLimitations')" />
                    <ng-container
                        *ngIf="caseFileForm.get('StatuteOfLimitations')?.invalid && caseFileForm.get('StatuteOfLimitations')?.touched">
                        <span [class]="formClasses.error">{{getErrorMessage('StatuteOfLimitations')}}</span>
                    </ng-container>
                </div>
            </div>
        </section>

        <section [class]="formClasses.section + ' ' + formClasses.subForm + ' case-file-section'">
            <h2 class="form-title"><fa-icon [icon]="caseFileIcons.caseFile" /> Case File</h2>

            <div [class]="formClasses.group">

                <div [class]="formClasses.inputContainer + ' ' + formClasses.threeCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.inTreatment" />
                    </span>
                    <ejs-checkbox formControlName="InTreatment" label="In Treatment?" floatLabelType="Auto"
                        [checked]="caseFileForm.get('InTreatment')?.value" />
                </div>

                <div [class]="formClasses.inputContainer + ' ' + formClasses.threeCol"
                    *ngIf="caseFileForm.get('FileNumber')?.value"
                    style="display: none;">
                    <ejs-textbox formControlName="FileNumber" [class]="formClasses.input" />
                </div>

                <div *ngIf="displayFields.includes('RecordStatusId')"
                    [class]="formClasses.inputContainer + ' ' + formClasses.threeCol"
                    [class.error]="caseFileForm.get('RecordStatusId')?.errors && caseFileForm.get('RecordStatusId')?.touched">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.recordStatus" />
                        <ejs-tooltip
                            *ngIf="caseFileForm.get('RecordStatusId')?.errors && caseFileForm.get('RecordStatusId')?.touched"
                            [content]="getErrorMessage('RecordStatusId')" />
                    </span>
                    <ejs-dropdownlist formControlName="RecordStatusId" placeholder="Record Status" floatLabelType="Auto"
                        [class]="formClasses.input" [dataSource]="recordStatuses" [query]="recordStatusesQuery"
                        [allowFiltering]="true" [fields]="{ text: 'Description', value: 'Id', Id: 'Id' }"
                        [value]="caseFileForm.get('RecordStatusId')?.value" />
                    <ng-container
                        *ngIf="caseFileForm.get('RecordStatusId')?.invalid && caseFileForm.get('RecordStatusId')?.touched">
                        <span [class]="formClasses.error">{{getErrorMessage('RecordStatusId')}}</span>
                    </ng-container>
                </div>

                <div [class]="formClasses.inputContainer + ' ' + formClasses.threeCol"
                    [class.error]="caseFileForm.get('FileOpened')?.errors && caseFileForm.get('FileOpened')?.touched"
                    style="opacity: 0.8;">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.fileOpened" />
                        <ejs-tooltip
                            *ngIf="caseFileForm.get('FileOpened')?.errors && caseFileForm.get('FileOpened')?.touched"
                            [content]="getErrorMessage('FileOpened')" />
                    </span>
                    <ejs-datepicker formControlName="FileOpened" placeholder="File Opened (Auto)" floatLabelType="Auto"
                        [format]="'yyyy-MM-dd'" [class]="formClasses.input"
                        [value]="caseFileForm.get('FileOpened')?.value ?? todaysDate"
                        (focus)="onDatePickerFocus($event)" (change)="onDateOnlyChange($event, 'FileOpened')" />
                    <ng-container
                        *ngIf="caseFileForm.get('FileOpened')?.invalid && caseFileForm.get('FileOpened')?.touched">
                        <span [class]="formClasses.error">{{getErrorMessage('FileOpened')}}</span>
                    </ng-container>
                </div>

                <div *ngIf="displayFields.includes('CaseManager')"
                    [class]="formClasses.inputContainer + ' ' + formClasses.threeCol"
                    [class.error]="caseFileForm.get('CaseManager')?.errors && caseFileForm.get('CaseManager')?.touched">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.caseManager" />
                        <ejs-tooltip
                            *ngIf="caseFileForm.get('CaseManager')?.errors && caseFileForm.get('CaseManager')?.touched"
                            [content]="getErrorMessage('CaseManager')" />
                    </span>
                    <ejs-dropdownlist formControlName="CaseManager" placeholder="Case Manager" floatLabelType="Auto"
                        [class]="formClasses.input" [dataSource]="users" [query]="caseManagersQuery"
                        [allowFiltering]="true" [fields]="{ text: 'Name', value: 'Id', Id: 'Id' }"
                        [value]="caseFileForm.get('CaseManager')?.value"
                        (beforeOpen)="beforeCaseManagerDrpDwnOpen($event)" />
                    <ng-container
                        *ngIf="caseFileForm.get('CaseManager')?.invalid && caseFileForm.get('CaseManager')?.touched">
                        <span [class]="formClasses.error">{{getErrorMessage('CaseManager')}}</span>
                    </ng-container>
                </div>

                <div *ngIf="displayFields.includes('MarketManager')"
                    [class]="formClasses.inputContainer + ' ' + formClasses.threeCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.marketManager" />
                    </span>
                    <ejs-dropdownlist formControlName="MarketManager" placeholder="Market Manager" floatLabelType="Auto"
                        [class]="formClasses.input" [dataSource]="users" [query]="marketManagersQuery"
                        [allowFiltering]="true" [fields]="{ text: 'Name', value: 'Id', Id: 'Id' }"
                        [value]="caseFileForm.get('MarketManager')?.value"
                        (beforeOpen)="beforeMarketManagerDrpDwnOpen($event)" />
                </div>

                <div [class]="formClasses.inputContainer + ' ' + formClasses.threeCol"
                    [class.error]="caseFileForm.get('PAndL')?.errors && caseFileForm.get('PAndL')?.touched">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.pAndL" />
                        <ejs-tooltip *ngIf="caseFileForm.get('PAndL')?.errors && caseFileForm.get('PAndL')?.touched"
                            [content]="getErrorMessage('PAndL')" />
                    </span>
                    <ejs-dropdownlist formControlName="PAndL" floatLabelType="Auto" [placeholder]="'P & L *'"
                        [class]="formClasses.input" [dataSource]="pAndL" [query]="pAndLQuery" [allowFiltering]="true"
                        [fields]="{ text: 'Description', value: 'Id', Id: 'Id' }"
                        [value]="caseFileForm.get('PAndL')?.value" />
                    <ng-container *ngIf="caseFileForm.get('PAndL')?.invalid && caseFileForm.get('PAndL')?.touched">
                        <span [class]="formClasses.error">{{getErrorMessage('PAndL')}}</span>
                    </ng-container>
                </div>

                <div *ngIf="displayFields.includes('ReferringPhysician')"
                    [class]="formClasses.inputContainer + ' ' + formClasses.threeCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.referringPhysician" />
                    </span>
                    <ejs-dropdownlist formControlName="ReferringPhysician" floatLabelType="Auto"
                        [placeholder]="'Referring Physician'" [class]="formClasses.input" [dataSource]="contacts"
                        [query]="referringPhysiciansQuery" [fields]="{ text: 'ContactName', value: 'Id', Id: 'Id' }"
                        [value]="caseFileForm.get('ReferringPhysician')?.value" />
                </div>

                <div *ngIf="displayFields.includes('ReferralSource')"
                    [class]="formClasses.inputContainer + ' ' + formClasses.threeCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.referralSource" />
                    </span>
                    <ejs-dropdownlist formControlName="ReferralSource" floatLabelType="Auto"
                        [placeholder]="'Referral Source'" [class]="formClasses.input" [dataSource]="referralSources"
                        [query]="referralSourcesQuery" [fields]="{ text: 'Description', value: 'Id', Id: 'Id' }"
                        [value]="caseFileForm.get('ReferralSource')?.value" />
                </div>

                <div *ngIf="displayFields.includes('InitialTimeFrame')"
                    [class]="formClasses.inputContainer + ' ' + formClasses.threeCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.initialTimeFrame" />
                    </span>
                    <ejs-dropdownlist formControlName="InitialTimeFrame" floatLabelType="Auto"
                        [placeholder]="'Initial Time Frame'" [class]="formClasses.input"
                        [dataSource]="initialTimeFrames" [allowFiltering]="true"
                        [fields]="{ text: 'Description', value: 'Description', Id: 'Description' }"
                        [value]="caseFileForm.get('InitialTimeFrame')?.value"/>
                </div>

                <div *ngIf="displayFields.includes('Statuser')"
                    [class]="formClasses.inputContainer + ' ' + formClasses.threeCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.statuser" />
                    </span>
                    <ejs-dropdownlist formControlName="Statuser" floatLabelType="Auto" [placeholder]="'Statuser'"
                        [class]="formClasses.input" [dataSource]="users" [query]="statusersQuery"
                        [allowFiltering]="true" [fields]="{ text: 'Name', value: 'Id', Id: 'Id' }"
                        [value]="caseFileForm.get('Statuser')?.value" />
                </div>

                <div *ngIf="displayFields.includes('ResponsibleParty')"
                    [class]="formClasses.inputContainer + ' ' + formClasses.threeCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.responsibleParty" />
                    </span>
                    <ejs-textbox formControlName="ResponsibleParty" floatLabelType="Auto"
                        placeholder="Responsible Party" [class]="formClasses.input" />
                </div>

                <div *ngIf="displayFields.includes('CaseTypeId')"
                    [class]="formClasses.inputContainer + ' ' + formClasses.threeCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.caseType" />
                    </span>
                    <ejs-dropdownlist formControlName="CaseTypeId" floatLabelType="Auto" [placeholder]="'Case Type'"
                        [class]="formClasses.input" [dataSource]="caseTypes" [query]="caseTypesQuery"
                        [allowFiltering]="true" [fields]="{ text: 'Description', value: 'Id', Id: 'Id' }"
                        [value]="caseFileForm.get('CaseTypeId')?.value" />
                </div>

                <div [class]="formClasses.inputContainer + ' ' + formClasses.threeCol"
                    [class.error]="caseFileForm.get('DateOfLoss')?.errors && caseFileForm.get('DateOfLoss')?.touched">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.dateOfLoss" />
                        <ejs-tooltip
                            *ngIf="caseFileForm.get('DateOfLoss')?.errors && caseFileForm.get('DateOfLoss')?.touched"
                            [content]="getErrorMessage('DateOfLoss')" />
                    </span>
                    <ejs-datepicker formControlName="DateOfLoss" placeholder="Date Of Loss" floatLabelType="Auto"
                        [class]="formClasses.input" [format]="'yyyy-MM-dd'"
                        [value]="caseFileForm.get('DateOfLoss')?.value" (focus)="onDatePickerFocus($event)"
                        (change)="onDateOnlyChange($event, 'DateOfLoss')" />
                    <ng-container
                        *ngIf="caseFileForm.get('DateOfLoss')?.invalid && caseFileForm.get('DateOfLoss')?.touched">
                        <span [class]="formClasses.error">{{getErrorMessage('DateOfLoss')}}</span>
                    </ng-container>
                </div>

                <div *ngIf="displayFields.includes('FileSecuredBy')"
                    [class]="formClasses.inputContainer + ' ' + formClasses.threeCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.fileSecuredBy" />
                    </span>
                    <ejs-dropdownlist formControlName="FileSecuredBy" floatLabelType="Auto"
                        [placeholder]="'File Secured By'" [class]="formClasses.input" [dataSource]="users"
                        [query]="usersQuery" [allowFiltering]="true" [fields]="{ text: 'Name', value: 'Id', Id: 'Id' }"
                        [value]="caseFileForm.get('FileSecuredBy')?.value" />
                </div>

                <div *ngIf="displayFields.includes('FileGroupId')"
                    [class]="formClasses.inputContainer + ' ' + formClasses.threeCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.fileGroupId" />
                    </span>
                    <ejs-dropdownlist formControlName="FileGroupId" floatLabelType="Auto" [placeholder]="'File Group'"
                        [class]="formClasses.input" [dataSource]="fileGroups" [query]="fileGroupsQuery"
                        [allowFiltering]="true" [fields]="{ text: 'Description', value: 'Id', Id: 'Id' }"
                        [value]="caseFileForm.get('FileGroupId')?.value" />
                </div>

                <div *ngIf="displayFields.includes('XrefCaseFileCompanionCaseCaseFiles')"
                    [class]="formClasses.inputContainer + ' ' + formClasses.threeCol">
                    <ejs-multiselect
                        id="companionCases"
                        formControlName="XrefCaseFileCompanionCaseCaseFiles"
                        [dataSource]="companionCases"
                        [query]="companionCasesQuery"
                        [fields]="{ text: 'FileNumber', value: 'Id' }"
                        floatLabelType="Auto"
                        [placeholder]="'Companion Cases'"
                        [allowFiltering]="companionCasesSettings.allowFiltering"
                        [filterBarPlaceholder]="companionCasesSettings.filterBarPlaceholder"
                        [showSelectAll]="companionCasesSettings.showSelectAll"
                        [mode]="companionCasesSettings.mode"
                        [enableSelectionOrder]="companionCasesSettings.enableSelectionOrder"
                        (filtering)="onFiltering($event)">
                    </ejs-multiselect>
                </div>

                <div *ngIf="displayFields.includes('ExcludeFromBalanceDue')"
                    [class]="formClasses.inputContainer + ' ' + formClasses.threeCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.excludeFromBalanceDue" />
                    </span>
                    <ejs-checkbox formControlName="ExcludeFromBalanceDue" floatLabelType="Auto"
                        label="Exclude From Balance Due?"
                        [checked]="caseFileForm.get('ExcludeFromBalanceDue')?.value" />
                </div>

                <div *ngIf="displayFields.includes('IsSurgical')"
                    [class]="formClasses.inputContainer + ' ' + formClasses.threeCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.isSurgical" />
                    </span>
                    <ejs-checkbox formControlName="IsSurgical" label="Surgical?" floatLabelType="Auto"
                        [checked]="caseFileForm.get('IsSurgical')?.value" (change)="onIsSurgicalChange($event)" />
                </div>

                <div *ngIf="displayFields.includes('ClientHasCheckpointLimitations')"
                    [class]="formClasses.inputContainer + ' ' + formClasses.threeCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.clientHasCheckpointLimitations" />
                    </span>
                    <ejs-checkbox formControlName="ClientHasCheckpointLimitations"
                        label="Client Has Checkpoint Limitations?" floatLabelType="Auto"
                        [checked]="caseFileForm.get('ClientHasCheckpointLimitations')?.value" />
                </div>

                <div *ngIf="displayFields.includes('Comments')"
                    [class]="formClasses.inputContainer + ' ' + formClasses.threeCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="caseFileIcons.comments" />
                    </span>
                    <ejs-textbox formControlName="Comments" floatLabelType="Auto" placeholder="Quick Comment"
                        floatLabelType="Auto" [class]="formClasses.input" multiline="true" />
                </div>

            </div>

        </section>

        <section [class]="formClasses.section + ' col-12'" *ngIf="displayButtons">
            <div [class]="formClasses.actions">
                <button ejs-button [isPrimary]="true" type="submit">Submit</button>
                <button ejs-button type="button" (click)="formCancelled.emit()">Cancel</button>
            </div>
        </section>

    </form>

    <!-- Comments section -->
    <div class="case-file-section">
        <div class="section-header">
            <fa-icon [icon]="caseFileIcons.comments"></fa-icon>
            <span>Comments</span>
        </div>

        <comment-threads-grid *ngIf="caseFileSignal()?.Id" [entityType]="'CaseFile'"
            [entityId]="caseFileSignal()?.Id || 0">
        </comment-threads-grid>
    </div>

</div>
