// Angular
import { Component, Input, Output, EventEmitter, ViewChild, signal, WritableSignal } from '@angular/core';
import { ReactiveFormsModule, Validators, FormBuilder } from '@angular/forms';
import { CommonModule } from '@angular/common';
// 3rd Party
import { DropDownList,  } from '@syncfusion/ej2-dropdowns';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { TextBoxModule, NumericTextBoxModule, MaskedTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { faBuilding, faHashtag, faMoneyBill, faNoteSticky, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// Internal
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { policyLimits } from '@models/global-vars';
import { Insurance, Contact } from '@models/data-contracts';
import { AddressFormComponent } from '../address-form/address-form.component';
import { PhoneFormComponent } from '../phone-number-form/phone-number-form.component';
import { APIEndpoints } from '@models/api/Endpoints';
import { ApiService } from '@services/api/api.service';
import { ContactFormComponent } from '../contact-form/contact-form.component';

@Component({
  selector: 'casefile-insurance-policy-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    DropDownListModule,
    TextBoxModule,
    NumericTextBoxModule,
    ButtonModule,
    MaskedTextBoxModule,
    CommonModule,
    ContactFormComponent,
    FontAwesomeModule
  ],
  templateUrl: './casefile-insurance-policy-form.component.html',
  styleUrl: './casefile-insurance-policy-form.component.scss'
})
export class CasefileInsurancePolicyFormComponent {

  constructor(
    private toast: ToastMessageService,
    private fb: FormBuilder,
    private api: ApiService
  ) {}

  @Input() includeSubmit: boolean = false;
  @Output() result = new EventEmitter<any>();
  @ViewChild('faxCountryCode') faxCountryCode: DropDownList;
  @ViewChild('phoneCountryCode') phoneCountryCode: DropDownList;
  @ViewChild('addressForm') addressForm: AddressFormComponent;
  @ViewChild('adjusterPhoneForm') adjusterPhoneForm: PhoneFormComponent;
  @ViewChild('adjusterFaxForm') adjusterFaxForm: PhoneFormComponent;
  @ViewChild('adjusterContact') adjusterContact: ContactFormComponent;
  @Input() caseFileId: number;

  showContactForm = false;
  contacts :any[] = [];

  
  ngOnInit() {
    this.getContacts();
    this.addressSignal.set(this.insuranceForm.get('Address')?.value);
    this.adjusterPhoneSignal.set(this.insuranceForm.get('AdjusterPhone')?.value);
    this.adjusterFaxSignal.set(this.insuranceForm.get('ClaimAdjusterFaxNumber')?.value);
  }


  // Main insurance form
  insuranceForm = this.fb.group({
    InsuranceCompany: [null as number | null, Validators.required],
    ClaimNumber: [null as string | null, Validators.required],
    PolicyLimits: [null as number | null, Validators.required],
    ClaimAdjusterId: [null as number | null],
    ClaimAdjusterName: [null as string | null],
    XrefAddressContact: [null as any],
    insuranceTypes: [null as number | null, Validators.required],
    Note: [null as string | null],
  });

  // Phone forms
  phoneFormGroup = this.fb.group({
    Id: [null as number | null],
    PhoneType: [null as number | null],
    PhoneNumber: [null as string | null],
    Extension: [null as string | null],
    CreatedBy: [null as number | null],
    CreatedDate: [null as Date | null],
    UpdatedBy: [null as number | null],
    UpdatedDate: [null as Date | null],
    IsActive: [true as boolean | null]
  });

  faxFormGroup = this.fb.group({
    Id: [null as number | null],
    PhoneType: [null as number | null],
    PhoneNumber: [null as string | null],
    Extension: [null as string | null],
    CreatedBy: [null as number | null],
    CreatedDate: [null as Date | null],
    UpdatedBy: [null as number | null],
    UpdatedDate: [null as Date | null],
    IsActive: [true as boolean | null]
  });

  insuranceCompanies: DataManager | any[] = this.api.getOdata(APIEndpoints.InsuranceCompanies);
  insuranceTypes: DataManager | any[] = this.api.getOdata(APIEndpoints.TypesOfInsurances);
  insuraceTypesFields: any = { text: 'description', value: 'id' };
  countryCodesFields: any = { text: 'description', value: 'code', iconCSS: 'icon' };
  policyLimits: DataManager | any[] = policyLimits;

  @ViewChild('statesDrpDwn') statesDrpDwn: DropDownList;

  // Create computed signals that watch the form controls
  readonly adjusterPhoneSignal: WritableSignal<any> = signal(() => this.insuranceForm.get('AdjusterPhone')?.value);
  readonly adjusterFaxSignal: WritableSignal<any> = signal(() => this.insuranceForm.get('ClaimAdjusterFaxNumber')?.value);
  readonly addressSignal: WritableSignal<any> = signal(() => this.insuranceForm.get('Address')?.value);

  // Create a signal for the contact
  adjusterContactSignal = signal<Contact | undefined>(undefined);

  icons = {
    faBuilding,
    faHashtag,
    faMoneyBill,
    faNotes: faNoteSticky,
    faUser
  };

  formClasses = {
    inputContainer: 'cc-input-container',
    icon: 'cc-input-icon',
    input: 'cc-input'
  };


  // Get contacts
  // Because there is no xref insurance contacts, I was told to get all contacts with contact type 5 (Claim Adjuster)
  getContacts() {
    this.api.getOdata(APIEndpoints.Contacts)
      .executeQuery(new Query().where('ContactType', 'equal', 5))
      .then((res: any) => {
        this.contacts = res.result;
      });
  }

  // Select a contact and get the address
  onContactSelect(event: any) {
    if (event.itemData) {
        // Update the contact signal with the selected contact
        this.adjusterContactSignal.set(event.itemData);
        
        // Update form values
        this.insuranceForm.patchValue({
            ClaimAdjusterId: event.itemData.Id,
            ClaimAdjusterName: event.itemData.ContactName
        });
        try {
          this.api.getOdata(APIEndpoints.XrefAddressContacts)
            .executeQuery(new Query()
            .expand('Address')
            .where('ContactId', 'equal', event.itemData.Id)).then((res: any) => {
              this.insuranceForm.patchValue({
                XrefAddressContact: [{ AddressId: res.result[0].Address.Id }]
              }); 
          });
        } catch (error) {
          this.toast.showError('Failed to get contact address');
        }
    }
  }

  // Show the add contact form
  onClaimAdjusterClick(event: any) {
    event.stopPropagation();
    event.preventDefault();
    this.showContactForm = !this.showContactForm;
  }

  // Submit the form
  async onSubmit(event: any) {
    event.stopPropagation();
    
    try {
      let contactResponse: any;
      
      if (this.showContactForm) {
        // If showing contact form, submit it and get new contact data
        contactResponse = await this.adjusterContact.onSubmit();
        if (!contactResponse || !contactResponse.Id) {
          this.toast.showError('Failed to create contact');
          return;
        }
      } else {
        // Use the existing selected contact data
        contactResponse = {
          Id: this.insuranceForm.value.ClaimAdjusterId,
          ContactName: this.insuranceForm.value.ClaimAdjusterName,
          XrefAddressContacts: this.insuranceForm.value.XrefAddressContact[0].Address.Id
        };
      }

      const insurancePayload = {
        CaseFileId: this.caseFileId,
        InsuranceCompany: this.insuranceForm.value.InsuranceCompany || '',
        ClaimNumber: this.insuranceForm.value.ClaimNumber || '',
        TypesOfInsurance: this.insuranceForm.value.insuranceTypes || '',
        PolicyLimits: this.insuranceForm.value.PolicyLimits || '',
        ClaimAdjusterContactId: contactResponse.Id,
        ClaimAdjusterName: contactResponse.ContactName || '',
        XrefAddressContact: contactResponse.XrefAddressContacts || [],
        Note: this.insuranceForm.value.Note || ''
      };

      const insuranceRes = await this.api.basicPost(APIEndpoints.Insurances, insurancePayload);
      const insuranceData = insuranceRes;
      
      this.toast.showSuccess('Insurance policy created successfully');
      this.result.emit(insuranceData);
      return insuranceData;

    } catch (error) {
      this.toast.showError('Failed to save form data');
      return undefined;
    }
  }

  // Helper functions
  handleAddressFormResult(result: any) {
    if (result === 'error') {
      this.toast.showError('Failed to save address');
    }
  }

  handlePhoneFormResult(result: any) {
    if (result === 'error') {
      this.toast.showError('Failed to save phone number');
    }
  }

  getFormControl(path: string) {
    return this.insuranceForm.get(path)?.value;
  }
}
