import * as i0 from '@angular/core';
import { Directive, ContentChildren, ContentChild, Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import { ComplexBase, setValue, ArrayBase, ComponentBase, Template, ComponentMixins } from '@syncfusion/ej2-angular-base';
import { __decorate } from 'tslib';
import { Diagram, HierarchicalTree, MindMap, RadialTree, ComplexHierarchicalTree, DataBinding, Snapping, PrintAndExport, BpmnDiagrams, SymmetricLayout, ConnectorBridging, UndoRedo, LayoutAnimation, DiagramContextMenu, LineRouting, ConnectorEditing, LineDistribution, Ej1Serialization, FlowchartLayout, SymbolPalette, Overview } from '@syncfusion/ej2-diagrams';
const _c0 = ["annotationTemplate"];
const _c1 = ["nodeTemplate"];
const _c2 = ["userHandleTemplate"];
export * from '@syncfusion/ej2-diagrams';
import { CommonModule } from '@angular/common';
let input$9 = ['addInfo', 'id', 'lock', 'objects', 'visible', 'zIndex'];
let outputs$c = [];
/**
 * Layers Directive
 * ```html
 * <e-layers>
 * <e-layer></e-layer>
 * </e-layers>
 * ```
 */
class LayerDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$c);
    this.directivePropList = input$9;
  }
}
LayerDirective.ɵfac = function LayerDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || LayerDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
LayerDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: LayerDirective,
  selectors: [["e-layer"]],
  inputs: {
    addInfo: "addInfo",
    id: "id",
    lock: "lock",
    objects: "objects",
    visible: "visible",
    zIndex: "zIndex"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LayerDirective, [{
    type: Directive,
    args: [{
      selector: 'e-layers>e-layer',
      inputs: input$9,
      outputs: outputs$c,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, null);
})();
/**
 * Layer Array Directive
 * @private
 */
class LayersDirective extends ArrayBase {
  constructor() {
    super('layers');
  }
}
LayersDirective.ɵfac = function LayersDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || LayersDirective)();
};
LayersDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: LayersDirective,
  selectors: [["e-layers"]],
  contentQueries: function LayersDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, LayerDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LayersDirective, [{
    type: Directive,
    args: [{
      selector: 'ej-diagram>e-layers',
      queries: {
        children: new ContentChildren(LayerDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
let input$8 = ['action', 'cursor'];
let outputs$b = [];
/**
 * Cursor Maps Directive
 * ```html
 * <e-cusrsormaps>
 * <e-cursormap></e-cursormap>
 * </e-cursormaps>
 * ```
 */
class CustomCursorDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$b);
    this.directivePropList = input$8;
  }
}
CustomCursorDirective.ɵfac = function CustomCursorDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || CustomCursorDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
CustomCursorDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: CustomCursorDirective,
  selectors: [["e-cursormap"]],
  inputs: {
    action: "action",
    cursor: "cursor"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CustomCursorDirective, [{
    type: Directive,
    args: [{
      selector: 'e-cursormaps>e-cursormap',
      inputs: input$8,
      outputs: outputs$b,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, null);
})();
/**
 * CustomCursor Array Directive
 * @private
 */
class CustomCursorsDirective extends ArrayBase {
  constructor() {
    super('customcursor');
  }
}
CustomCursorsDirective.ɵfac = function CustomCursorsDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || CustomCursorsDirective)();
};
CustomCursorsDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: CustomCursorsDirective,
  selectors: [["e-cursormaps"]],
  contentQueries: function CustomCursorsDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, CustomCursorDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CustomCursorsDirective, [{
    type: Directive,
    args: [{
      selector: 'ej-diagram>e-cursormaps',
      queries: {
        children: new ContentChildren(CustomCursorDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
let input$7 = ['alignment', 'cornerRadius', 'displacement', 'fill', 'handleStrokeColor', 'handleStrokeWidth', 'height', 'iconStrokeColor', 'iconStrokeWidth', 'id', 'offset', 'padding', 'pathData', 'tooltip', 'visibility', 'width'];
let outputs$a = [];
/**
 * Connectors Directive
 * ```html
 * <e-connectors>
 * <e-connector>
 * <e-connector-fixeduserhandles>
 * <e-connector-fixeduserhandle>
 * </e-connector-fixeduserhandle>
 * </e-connector-fixeduserhandles>
 * </e-connector>
 * </e-connectors>
 * ```
 */
class ConnectorFixedUserHandleDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$a);
    this.directivePropList = input$7;
  }
}
ConnectorFixedUserHandleDirective.ɵfac = function ConnectorFixedUserHandleDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ConnectorFixedUserHandleDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
ConnectorFixedUserHandleDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ConnectorFixedUserHandleDirective,
  selectors: [["e-connector-fixeduserhandle"]],
  inputs: {
    alignment: "alignment",
    cornerRadius: "cornerRadius",
    displacement: "displacement",
    fill: "fill",
    handleStrokeColor: "handleStrokeColor",
    handleStrokeWidth: "handleStrokeWidth",
    height: "height",
    iconStrokeColor: "iconStrokeColor",
    iconStrokeWidth: "iconStrokeWidth",
    id: "id",
    offset: "offset",
    padding: "padding",
    pathData: "pathData",
    tooltip: "tooltip",
    visibility: "visibility",
    width: "width"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ConnectorFixedUserHandleDirective, [{
    type: Directive,
    args: [{
      selector: 'e-connector>e-connector-fixeduserhandles>e-connector-fixeduserhandle',
      inputs: input$7,
      outputs: outputs$a,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, null);
})();
/**
 * ConnectorFixedUserHandle Array Directive
 * @private
 */
class ConnectorFixedUserHandlesDirective extends ArrayBase {
  constructor() {
    super('fixeduserhandles');
  }
}
ConnectorFixedUserHandlesDirective.ɵfac = function ConnectorFixedUserHandlesDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ConnectorFixedUserHandlesDirective)();
};
ConnectorFixedUserHandlesDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ConnectorFixedUserHandlesDirective,
  selectors: [["e-connector-fixeduserhandles"]],
  contentQueries: function ConnectorFixedUserHandlesDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, ConnectorFixedUserHandleDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ConnectorFixedUserHandlesDirective, [{
    type: Directive,
    args: [{
      selector: 'e-connector>e-connector-fixeduserhandles',
      queries: {
        children: new ContentChildren(ConnectorFixedUserHandleDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
let input$6 = ['addInfo', 'alignment', 'annotationType', 'constraints', 'content', 'displacement', 'dragLimit', 'height', 'horizontalAlignment', 'hyperlink', 'id', 'margin', 'offset', 'rotateAngle', 'rotationReference', 'segmentAngle', 'style', 'template', 'tooltip', 'type', 'verticalAlignment', 'visibility', 'width'];
let outputs$9 = [];
/**
 * Connectors Directive
 * ```html
 * <e-connectors>
 * <e-connector>
 * <e-connector-annotations>
 * <e-connector-annotation>
 * </e-connector-annotation>
 * </e-connector-annotations>
 * </e-connector>
 * </e-connectors>
 * ```
 */
class ConnectorAnnotationDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$9);
    this.directivePropList = input$6;
  }
}
ConnectorAnnotationDirective.ɵfac = function ConnectorAnnotationDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ConnectorAnnotationDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
ConnectorAnnotationDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ConnectorAnnotationDirective,
  selectors: [["e-connector-annotation"]],
  inputs: {
    addInfo: "addInfo",
    alignment: "alignment",
    annotationType: "annotationType",
    constraints: "constraints",
    content: "content",
    displacement: "displacement",
    dragLimit: "dragLimit",
    height: "height",
    horizontalAlignment: "horizontalAlignment",
    hyperlink: "hyperlink",
    id: "id",
    margin: "margin",
    offset: "offset",
    rotateAngle: "rotateAngle",
    rotationReference: "rotationReference",
    segmentAngle: "segmentAngle",
    style: "style",
    template: "template",
    tooltip: "tooltip",
    type: "type",
    verticalAlignment: "verticalAlignment",
    visibility: "visibility",
    width: "width"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ConnectorAnnotationDirective, [{
    type: Directive,
    args: [{
      selector: 'e-connector>e-connector-annotations>e-connector-annotation',
      inputs: input$6,
      outputs: outputs$9,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, null);
})();
/**
 * ConnectorAnnotation Array Directive
 * @private
 */
class ConnectorAnnotationsDirective extends ArrayBase {
  constructor() {
    super('annotations');
  }
}
ConnectorAnnotationsDirective.ɵfac = function ConnectorAnnotationsDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ConnectorAnnotationsDirective)();
};
ConnectorAnnotationsDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ConnectorAnnotationsDirective,
  selectors: [["e-connector-annotations"]],
  contentQueries: function ConnectorAnnotationsDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, ConnectorAnnotationDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ConnectorAnnotationsDirective, [{
    type: Directive,
    args: [{
      selector: 'e-connector>e-connector-annotations',
      queries: {
        children: new ContentChildren(ConnectorAnnotationDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
let input$5 = ['addInfo', 'allowNodeOverlap', 'annotations', 'bezierSettings', 'bridgeSpace', 'connectionPadding', 'connectorSpacing', 'constraints', 'cornerRadius', 'dragSize', 'excludeFromLayout', 'fixedUserHandles', 'flip', 'flipMode', 'hitPadding', 'id', 'margin', 'maxSegmentThumb', 'ports', 'previewSize', 'segmentThumbShape', 'segmentThumbSize', 'segments', 'shape', 'sourceDecorator', 'sourceID', 'sourcePadding', 'sourcePoint', 'sourcePortID', 'style', 'symbolInfo', 'targetDecorator', 'targetID', 'targetPadding', 'targetPoint', 'targetPortID', 'tooltip', 'type', 'visible', 'wrapper', 'zIndex'];
let outputs$8 = [];
/**
 * Connectors Directive
 * ```html
 * <e-connectors>
 * <e-connector></e-connector>
 * </e-connectors>
 * ```
 */
class ConnectorDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    this.tags = ['fixedUserHandles', 'annotations'];
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$8);
    this.directivePropList = input$5;
  }
}
ConnectorDirective.ɵfac = function ConnectorDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ConnectorDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
ConnectorDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ConnectorDirective,
  selectors: [["e-connector"]],
  contentQueries: function ConnectorDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, ConnectorFixedUserHandlesDirective, 5);
      i0.ɵɵcontentQuery(dirIndex, ConnectorAnnotationsDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childFixedUserHandles = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childAnnotations = _t.first);
    }
  },
  inputs: {
    addInfo: "addInfo",
    allowNodeOverlap: "allowNodeOverlap",
    annotations: "annotations",
    bezierSettings: "bezierSettings",
    bridgeSpace: "bridgeSpace",
    connectionPadding: "connectionPadding",
    connectorSpacing: "connectorSpacing",
    constraints: "constraints",
    cornerRadius: "cornerRadius",
    dragSize: "dragSize",
    excludeFromLayout: "excludeFromLayout",
    fixedUserHandles: "fixedUserHandles",
    flip: "flip",
    flipMode: "flipMode",
    hitPadding: "hitPadding",
    id: "id",
    margin: "margin",
    maxSegmentThumb: "maxSegmentThumb",
    ports: "ports",
    previewSize: "previewSize",
    segmentThumbShape: "segmentThumbShape",
    segmentThumbSize: "segmentThumbSize",
    segments: "segments",
    shape: "shape",
    sourceDecorator: "sourceDecorator",
    sourceID: "sourceID",
    sourcePadding: "sourcePadding",
    sourcePoint: "sourcePoint",
    sourcePortID: "sourcePortID",
    style: "style",
    symbolInfo: "symbolInfo",
    targetDecorator: "targetDecorator",
    targetID: "targetID",
    targetPadding: "targetPadding",
    targetPoint: "targetPoint",
    targetPortID: "targetPortID",
    tooltip: "tooltip",
    type: "type",
    visible: "visible",
    wrapper: "wrapper",
    zIndex: "zIndex"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ConnectorDirective, [{
    type: Directive,
    args: [{
      selector: 'e-connectors>e-connector',
      inputs: input$5,
      outputs: outputs$8,
      queries: {
        childFixedUserHandles: new ContentChild(ConnectorFixedUserHandlesDirective),
        childAnnotations: new ContentChild(ConnectorAnnotationsDirective)
      }
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, null);
})();
/**
 * Connector Array Directive
 * @private
 */
class ConnectorsDirective extends ArrayBase {
  constructor() {
    super('connectors');
  }
}
ConnectorsDirective.ɵfac = function ConnectorsDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ConnectorsDirective)();
};
ConnectorsDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ConnectorsDirective,
  selectors: [["e-connectors"]],
  contentQueries: function ConnectorsDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, ConnectorDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ConnectorsDirective, [{
    type: Directive,
    args: [{
      selector: 'ej-diagram>e-connectors',
      queries: {
        children: new ContentChildren(ConnectorDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
let input$4 = ['cornerRadius', 'fill', 'handleStrokeColor', 'handleStrokeWidth', 'height', 'iconStrokeColor', 'iconStrokeWidth', 'id', 'margin', 'offset', 'padding', 'pathData', 'tooltip', 'visibility', 'width'];
let outputs$7 = [];
/**
 * Nodes Directive
 * ```html
 * <e-nodes>
 * <e-node>
 * <e-node-fixeduserhandles>
 * <e-node-fixeduserhandle>
 * </e-node-fixeduserhandle>
 * </e-node-fixeduserhandles>
 * </e-node>
 * </e-nodes>
 * ```
 */
class NodeFixedUserHandleDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$7);
    this.directivePropList = input$4;
  }
}
NodeFixedUserHandleDirective.ɵfac = function NodeFixedUserHandleDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || NodeFixedUserHandleDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
NodeFixedUserHandleDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: NodeFixedUserHandleDirective,
  selectors: [["e-node-fixeduserhandle"]],
  inputs: {
    cornerRadius: "cornerRadius",
    fill: "fill",
    handleStrokeColor: "handleStrokeColor",
    handleStrokeWidth: "handleStrokeWidth",
    height: "height",
    iconStrokeColor: "iconStrokeColor",
    iconStrokeWidth: "iconStrokeWidth",
    id: "id",
    margin: "margin",
    offset: "offset",
    padding: "padding",
    pathData: "pathData",
    tooltip: "tooltip",
    visibility: "visibility",
    width: "width"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NodeFixedUserHandleDirective, [{
    type: Directive,
    args: [{
      selector: 'e-node>e-node-fixeduserhandles>e-node-fixeduserhandle',
      inputs: input$4,
      outputs: outputs$7,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, null);
})();
/**
 * NodeFixedUserHandle Array Directive
 * @private
 */
class NodeFixedUserHandlesDirective extends ArrayBase {
  constructor() {
    super('fixeduserhandles');
  }
}
NodeFixedUserHandlesDirective.ɵfac = function NodeFixedUserHandlesDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || NodeFixedUserHandlesDirective)();
};
NodeFixedUserHandlesDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: NodeFixedUserHandlesDirective,
  selectors: [["e-node-fixeduserhandles"]],
  contentQueries: function NodeFixedUserHandlesDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, NodeFixedUserHandleDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NodeFixedUserHandlesDirective, [{
    type: Directive,
    args: [{
      selector: 'e-node>e-node-fixeduserhandles',
      queries: {
        children: new ContentChildren(NodeFixedUserHandleDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
let input$3 = ['addInfo', 'annotationType', 'constraints', 'content', 'dragLimit', 'height', 'horizontalAlignment', 'hyperlink', 'id', 'margin', 'offset', 'rotateAngle', 'rotationReference', 'style', 'template', 'tooltip', 'type', 'verticalAlignment', 'visibility', 'width'];
let outputs$6 = [];
/**
 * Nodes Directive
 * ```html
 * <e-nodes>
 * <e-node>
 * <e-node-annotations>
 * <e-node-annotation>
 * </e-node-annotation>
 * </e-node-annotations>
 * </e-node>
 * </e-nodes>
 * ```
 */
class NodeAnnotationDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$6);
    this.directivePropList = input$3;
  }
}
NodeAnnotationDirective.ɵfac = function NodeAnnotationDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || NodeAnnotationDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
NodeAnnotationDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: NodeAnnotationDirective,
  selectors: [["e-node-annotation"]],
  inputs: {
    addInfo: "addInfo",
    annotationType: "annotationType",
    constraints: "constraints",
    content: "content",
    dragLimit: "dragLimit",
    height: "height",
    horizontalAlignment: "horizontalAlignment",
    hyperlink: "hyperlink",
    id: "id",
    margin: "margin",
    offset: "offset",
    rotateAngle: "rotateAngle",
    rotationReference: "rotationReference",
    style: "style",
    template: "template",
    tooltip: "tooltip",
    type: "type",
    verticalAlignment: "verticalAlignment",
    visibility: "visibility",
    width: "width"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NodeAnnotationDirective, [{
    type: Directive,
    args: [{
      selector: 'e-node>e-node-annotations>e-node-annotation',
      inputs: input$3,
      outputs: outputs$6,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, null);
})();
/**
 * NodeAnnotation Array Directive
 * @private
 */
class NodeAnnotationsDirective extends ArrayBase {
  constructor() {
    super('annotations');
  }
}
NodeAnnotationsDirective.ɵfac = function NodeAnnotationsDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || NodeAnnotationsDirective)();
};
NodeAnnotationsDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: NodeAnnotationsDirective,
  selectors: [["e-node-annotations"]],
  contentQueries: function NodeAnnotationsDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, NodeAnnotationDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NodeAnnotationsDirective, [{
    type: Directive,
    args: [{
      selector: 'e-node>e-node-annotations',
      queries: {
        children: new ContentChildren(NodeAnnotationDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
let input$2 = ['addInfo', 'connectionDirection', 'constraints', 'height', 'horizontalAlignment', 'id', 'inEdges', 'margin', 'offset', 'outEdges', 'pathData', 'shape', 'style', 'tooltip', 'verticalAlignment', 'visibility', 'width'];
let outputs$5 = [];
/**
 * Nodes Directive
 * ```html
 * <e-nodes>
 * <e-node>
 * <e-node-ports>
 * <e-node-port>
 * </e-node-port>
 * </e-node-ports>
 * </e-node>
 * </e-nodes>
 * ```
 */
class PortDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$5);
    this.directivePropList = input$2;
  }
}
PortDirective.ɵfac = function PortDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || PortDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
PortDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: PortDirective,
  selectors: [["e-node-port"]],
  inputs: {
    addInfo: "addInfo",
    connectionDirection: "connectionDirection",
    constraints: "constraints",
    height: "height",
    horizontalAlignment: "horizontalAlignment",
    id: "id",
    inEdges: "inEdges",
    margin: "margin",
    offset: "offset",
    outEdges: "outEdges",
    pathData: "pathData",
    shape: "shape",
    style: "style",
    tooltip: "tooltip",
    verticalAlignment: "verticalAlignment",
    visibility: "visibility",
    width: "width"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PortDirective, [{
    type: Directive,
    args: [{
      selector: 'e-node>e-node-ports>e-node-port',
      inputs: input$2,
      outputs: outputs$5,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, null);
})();
/**
 * Port Array Directive
 * @private
 */
class PortsDirective extends ArrayBase {
  constructor() {
    super('ports');
  }
}
PortsDirective.ɵfac = function PortsDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || PortsDirective)();
};
PortsDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: PortsDirective,
  selectors: [["e-node-ports"]],
  contentQueries: function PortsDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, PortDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PortsDirective, [{
    type: Directive,
    args: [{
      selector: 'e-node>e-node-ports',
      queries: {
        children: new ContentChildren(PortDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
let input$1 = ['addInfo', 'annotations', 'backgroundColor', 'borderColor', 'borderWidth', 'branch', 'children', 'collapseIcon', 'columnIndex', 'columnSpan', 'columns', 'constraints', 'container', 'data', 'dragSize', 'excludeFromLayout', 'expandIcon', 'fixedUserHandles', 'flip', 'flipMode', 'height', 'horizontalAlignment', 'id', 'isExpanded', 'layoutInfo', 'margin', 'maxHeight', 'maxWidth', 'minHeight', 'minWidth', 'offsetX', 'offsetY', 'padding', 'pivot', 'ports', 'previewSize', 'rotateAngle', 'rowIndex', 'rowSpan', 'rows', 'shadow', 'shape', 'style', 'symbolInfo', 'tooltip', 'verticalAlignment', 'visible', 'width', 'wrapper', 'zIndex'];
let outputs$4 = [];
/**
 * Nodes Directive
 * ```html
 * <e-nodes>
 * <e-node></e-node>
 * </e-nodes>
 * ```
 */
class NodeDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    this.tags = ['fixedUserHandles', 'annotations', 'ports'];
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$4);
    this.directivePropList = input$1;
  }
}
NodeDirective.ɵfac = function NodeDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || NodeDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
NodeDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: NodeDirective,
  selectors: [["e-node"]],
  contentQueries: function NodeDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, NodeFixedUserHandlesDirective, 5);
      i0.ɵɵcontentQuery(dirIndex, NodeAnnotationsDirective, 5);
      i0.ɵɵcontentQuery(dirIndex, PortsDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childFixedUserHandles = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childAnnotations = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childPorts = _t.first);
    }
  },
  inputs: {
    addInfo: "addInfo",
    annotations: "annotations",
    backgroundColor: "backgroundColor",
    borderColor: "borderColor",
    borderWidth: "borderWidth",
    branch: "branch",
    children: "children",
    collapseIcon: "collapseIcon",
    columnIndex: "columnIndex",
    columnSpan: "columnSpan",
    columns: "columns",
    constraints: "constraints",
    container: "container",
    data: "data",
    dragSize: "dragSize",
    excludeFromLayout: "excludeFromLayout",
    expandIcon: "expandIcon",
    fixedUserHandles: "fixedUserHandles",
    flip: "flip",
    flipMode: "flipMode",
    height: "height",
    horizontalAlignment: "horizontalAlignment",
    id: "id",
    isExpanded: "isExpanded",
    layoutInfo: "layoutInfo",
    margin: "margin",
    maxHeight: "maxHeight",
    maxWidth: "maxWidth",
    minHeight: "minHeight",
    minWidth: "minWidth",
    offsetX: "offsetX",
    offsetY: "offsetY",
    padding: "padding",
    pivot: "pivot",
    ports: "ports",
    previewSize: "previewSize",
    rotateAngle: "rotateAngle",
    rowIndex: "rowIndex",
    rowSpan: "rowSpan",
    rows: "rows",
    shadow: "shadow",
    shape: "shape",
    style: "style",
    symbolInfo: "symbolInfo",
    tooltip: "tooltip",
    verticalAlignment: "verticalAlignment",
    visible: "visible",
    width: "width",
    wrapper: "wrapper",
    zIndex: "zIndex"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NodeDirective, [{
    type: Directive,
    args: [{
      selector: 'e-nodes>e-node',
      inputs: input$1,
      outputs: outputs$4,
      queries: {
        childFixedUserHandles: new ContentChild(NodeFixedUserHandlesDirective),
        childAnnotations: new ContentChild(NodeAnnotationsDirective),
        childPorts: new ContentChild(PortsDirective)
      }
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, null);
})();
/**
 * Node Array Directive
 * @private
 */
class NodesDirective extends ArrayBase {
  constructor() {
    super('nodes');
  }
}
NodesDirective.ɵfac = function NodesDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || NodesDirective)();
};
NodesDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: NodesDirective,
  selectors: [["e-nodes"]],
  contentQueries: function NodesDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, NodeDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NodesDirective, [{
    type: Directive,
    args: [{
      selector: 'ej-diagram>e-nodes',
      queries: {
        children: new ContentChildren(NodeDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
const inputs$2 = ['addInfo', 'annotationTemplate', 'backgroundColor', 'bridgeDirection', 'commandManager', 'connectorDefaults', 'connectors', 'constraints', 'contextMenuSettings', 'customCursor', 'dataSourceSettings', 'diagramSettings', 'drawingObject', 'enableConnectorSplit', 'enablePersistence', 'enableRtl', 'fixedUserHandleTemplate', 'getConnectorDefaults', 'getCustomCursor', 'getCustomProperty', 'getCustomTool', 'getDescription', 'getNodeDefaults', 'height', 'historyManager', 'layers', 'layout', 'locale', 'mode', 'nodeDefaults', 'nodeTemplate', 'nodes', 'pageSettings', 'rulerSettings', 'scrollSettings', 'segmentThumbShape', 'segmentThumbSize', 'selectedItems', 'serializationSettings', 'setNodeTemplate', 'snapSettings', 'tool', 'tooltip', 'updateSelection', 'userHandleTemplate', 'width'];
const outputs$3 = ['animationComplete', 'click', 'collectionChange', 'commandExecute', 'connectionChange', 'contextMenuBeforeItemRender', 'contextMenuClick', 'contextMenuOpen', 'created', 'dataLoaded', 'doubleClick', 'dragEnter', 'dragLeave', 'dragOver', 'drop', 'elementDraw', 'expandStateChange', 'fixedUserHandleClick', 'historyChange', 'historyStateChange', 'keyDown', 'keyUp', 'load', 'loaded', 'mouseEnter', 'mouseLeave', 'mouseOver', 'mouseWheel', 'onFixedUserHandleMouseDown', 'onFixedUserHandleMouseEnter', 'onFixedUserHandleMouseLeave', 'onFixedUserHandleMouseUp', 'onImageLoad', 'onUserHandleMouseDown', 'onUserHandleMouseEnter', 'onUserHandleMouseLeave', 'onUserHandleMouseUp', 'positionChange', 'propertyChange', 'rotateChange', 'scrollChange', 'segmentChange', 'segmentCollectionChange', 'selectionChange', 'sizeChange', 'sourcePointChange', 'targetPointChange', 'textEdit'];
const twoWays$2 = [''];
/**
 * Diagram Component
 * ```html
 * <ej-diagram></ej-diagram>
 * ```
 */
let DiagramComponent = class DiagramComponent extends Diagram {
  constructor(ngEle, srenderer, viewContainerRef, injector) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.tags = ['layers', 'customCursor', 'connectors', 'nodes'];
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    try {
      let mod = this.injector.get('DiagramsHierarchicalTree');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('DiagramsMindMap');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('DiagramsRadialTree');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('DiagramsComplexHierarchicalTree');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('DiagramsDataBinding');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('DiagramsSnapping');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('DiagramsPrintAndExport');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('DiagramsBpmnDiagrams');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('DiagramsSymmetricLayout');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('DiagramsConnectorBridging');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('DiagramsUndoRedo');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('DiagramsLayoutAnimation');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('DiagramsDiagramContextMenu');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('DiagramsLineRouting');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('DiagramsConnectorEditing');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('DiagramsLineDistribution');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('DiagramsEj1Serialization');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('DiagramsFlowchartLayout');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    this.registerEvents(outputs$3);
    this.addTwoWay.call(this, twoWays$2);
    setValue('currentInstance', this, this.viewContainerRef);
    this.context = new ComponentBase();
  }
  ngOnInit() {
    this.context.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.context.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.context.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.tagObjects[0].instance = this.childLayers;
    if (this.childCustomCursor) {
      this.tagObjects[1].instance = this.childCustomCursor;
    }
    if (this.childConnectors) {
      this.tagObjects[2].instance = this.childConnectors;
    }
    if (this.childNodes) {
      this.tagObjects[3].instance = this.childNodes;
    }
    this.context.ngAfterContentChecked(this);
  }
};
DiagramComponent.ɵfac = function DiagramComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DiagramComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector));
};
DiagramComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DiagramComponent,
  selectors: [["ejs-diagram"]],
  contentQueries: function DiagramComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c0, 5);
      i0.ɵɵcontentQuery(dirIndex, _c1, 5);
      i0.ɵɵcontentQuery(dirIndex, _c2, 5);
      i0.ɵɵcontentQuery(dirIndex, LayersDirective, 5);
      i0.ɵɵcontentQuery(dirIndex, CustomCursorsDirective, 5);
      i0.ɵɵcontentQuery(dirIndex, ConnectorsDirective, 5);
      i0.ɵɵcontentQuery(dirIndex, NodesDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.annotationTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.nodeTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.userHandleTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childLayers = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childCustomCursor = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childConnectors = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childNodes = _t.first);
    }
  },
  inputs: {
    addInfo: "addInfo",
    annotationTemplate: "annotationTemplate",
    backgroundColor: "backgroundColor",
    bridgeDirection: "bridgeDirection",
    commandManager: "commandManager",
    connectorDefaults: "connectorDefaults",
    connectors: "connectors",
    constraints: "constraints",
    contextMenuSettings: "contextMenuSettings",
    customCursor: "customCursor",
    dataSourceSettings: "dataSourceSettings",
    diagramSettings: "diagramSettings",
    drawingObject: "drawingObject",
    enableConnectorSplit: "enableConnectorSplit",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    fixedUserHandleTemplate: "fixedUserHandleTemplate",
    getConnectorDefaults: "getConnectorDefaults",
    getCustomCursor: "getCustomCursor",
    getCustomProperty: "getCustomProperty",
    getCustomTool: "getCustomTool",
    getDescription: "getDescription",
    getNodeDefaults: "getNodeDefaults",
    height: "height",
    historyManager: "historyManager",
    layers: "layers",
    layout: "layout",
    locale: "locale",
    mode: "mode",
    nodeDefaults: "nodeDefaults",
    nodeTemplate: "nodeTemplate",
    nodes: "nodes",
    pageSettings: "pageSettings",
    rulerSettings: "rulerSettings",
    scrollSettings: "scrollSettings",
    segmentThumbShape: "segmentThumbShape",
    segmentThumbSize: "segmentThumbSize",
    selectedItems: "selectedItems",
    serializationSettings: "serializationSettings",
    setNodeTemplate: "setNodeTemplate",
    snapSettings: "snapSettings",
    tool: "tool",
    tooltip: "tooltip",
    updateSelection: "updateSelection",
    userHandleTemplate: "userHandleTemplate",
    width: "width"
  },
  outputs: {
    animationComplete: "animationComplete",
    click: "click",
    collectionChange: "collectionChange",
    commandExecute: "commandExecute",
    connectionChange: "connectionChange",
    contextMenuBeforeItemRender: "contextMenuBeforeItemRender",
    contextMenuClick: "contextMenuClick",
    contextMenuOpen: "contextMenuOpen",
    created: "created",
    dataLoaded: "dataLoaded",
    doubleClick: "doubleClick",
    dragEnter: "dragEnter",
    dragLeave: "dragLeave",
    dragOver: "dragOver",
    drop: "drop",
    elementDraw: "elementDraw",
    expandStateChange: "expandStateChange",
    fixedUserHandleClick: "fixedUserHandleClick",
    historyChange: "historyChange",
    historyStateChange: "historyStateChange",
    keyDown: "keyDown",
    keyUp: "keyUp",
    load: "load",
    loaded: "loaded",
    mouseEnter: "mouseEnter",
    mouseLeave: "mouseLeave",
    mouseOver: "mouseOver",
    mouseWheel: "mouseWheel",
    onFixedUserHandleMouseDown: "onFixedUserHandleMouseDown",
    onFixedUserHandleMouseEnter: "onFixedUserHandleMouseEnter",
    onFixedUserHandleMouseLeave: "onFixedUserHandleMouseLeave",
    onFixedUserHandleMouseUp: "onFixedUserHandleMouseUp",
    onImageLoad: "onImageLoad",
    onUserHandleMouseDown: "onUserHandleMouseDown",
    onUserHandleMouseEnter: "onUserHandleMouseEnter",
    onUserHandleMouseLeave: "onUserHandleMouseLeave",
    onUserHandleMouseUp: "onUserHandleMouseUp",
    positionChange: "positionChange",
    propertyChange: "propertyChange",
    rotateChange: "rotateChange",
    scrollChange: "scrollChange",
    segmentChange: "segmentChange",
    segmentCollectionChange: "segmentCollectionChange",
    selectionChange: "selectionChange",
    sizeChange: "sizeChange",
    sourcePointChange: "sourcePointChange",
    targetPointChange: "targetPointChange",
    textEdit: "textEdit"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 0,
  vars: 0,
  template: function DiagramComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
__decorate([Template()], DiagramComponent.prototype, "annotationTemplate", void 0);
__decorate([Template()], DiagramComponent.prototype, "nodeTemplate", void 0);
__decorate([Template()], DiagramComponent.prototype, "userHandleTemplate", void 0);
DiagramComponent = __decorate([ComponentMixins([ComponentBase])], DiagramComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DiagramComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-diagram',
      inputs: inputs$2,
      outputs: outputs$3,
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush,
      queries: {
        childLayers: new ContentChild(LayersDirective),
        childCustomCursor: new ContentChild(CustomCursorsDirective),
        childConnectors: new ContentChild(ConnectorsDirective),
        childNodes: new ContentChild(NodesDirective)
      }
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }];
  }, {
    annotationTemplate: [{
      type: ContentChild,
      args: ['annotationTemplate']
    }],
    nodeTemplate: [{
      type: ContentChild,
      args: ['nodeTemplate']
    }],
    userHandleTemplate: [{
      type: ContentChild,
      args: ['userHandleTemplate']
    }]
  });
})();

/**
 * NgModule definition for the Diagram component.
 */
class DiagramModule {}
DiagramModule.ɵfac = function DiagramModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DiagramModule)();
};
DiagramModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: DiagramModule
});
DiagramModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DiagramModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [DiagramComponent, LayerDirective, LayersDirective, CustomCursorDirective, CustomCursorsDirective, ConnectorFixedUserHandleDirective, ConnectorFixedUserHandlesDirective, ConnectorAnnotationDirective, ConnectorAnnotationsDirective, ConnectorDirective, ConnectorsDirective, NodeFixedUserHandleDirective, NodeFixedUserHandlesDirective, NodeAnnotationDirective, NodeAnnotationsDirective, PortDirective, PortsDirective, NodeDirective, NodesDirective],
      exports: [DiagramComponent, LayerDirective, LayersDirective, CustomCursorDirective, CustomCursorsDirective, ConnectorFixedUserHandleDirective, ConnectorFixedUserHandlesDirective, ConnectorAnnotationDirective, ConnectorAnnotationsDirective, ConnectorDirective, ConnectorsDirective, NodeFixedUserHandleDirective, NodeFixedUserHandlesDirective, NodeAnnotationDirective, NodeAnnotationsDirective, PortDirective, PortsDirective, NodeDirective, NodesDirective]
    }]
  }], null, null);
})();
const HierarchicalTreeService = {
  provide: 'DiagramsHierarchicalTree',
  useValue: HierarchicalTree
};
const MindMapService = {
  provide: 'DiagramsMindMap',
  useValue: MindMap
};
const RadialTreeService = {
  provide: 'DiagramsRadialTree',
  useValue: RadialTree
};
const ComplexHierarchicalTreeService = {
  provide: 'DiagramsComplexHierarchicalTree',
  useValue: ComplexHierarchicalTree
};
const DataBindingService = {
  provide: 'DiagramsDataBinding',
  useValue: DataBinding
};
const SnappingService = {
  provide: 'DiagramsSnapping',
  useValue: Snapping
};
const PrintAndExportService = {
  provide: 'DiagramsPrintAndExport',
  useValue: PrintAndExport
};
const BpmnDiagramsService = {
  provide: 'DiagramsBpmnDiagrams',
  useValue: BpmnDiagrams
};
const SymmetricLayoutService = {
  provide: 'DiagramsSymmetricLayout',
  useValue: SymmetricLayout
};
const ConnectorBridgingService = {
  provide: 'DiagramsConnectorBridging',
  useValue: ConnectorBridging
};
const UndoRedoService = {
  provide: 'DiagramsUndoRedo',
  useValue: UndoRedo
};
const LayoutAnimationService = {
  provide: 'DiagramsLayoutAnimation',
  useValue: LayoutAnimation
};
const DiagramContextMenuService = {
  provide: 'DiagramsDiagramContextMenu',
  useValue: DiagramContextMenu
};
const LineRoutingService = {
  provide: 'DiagramsLineRouting',
  useValue: LineRouting
};
const ConnectorEditingService = {
  provide: 'DiagramsConnectorEditing',
  useValue: ConnectorEditing
};
const LineDistributionService = {
  provide: 'DiagramsLineDistribution',
  useValue: LineDistribution
};
const Ej1SerializationService = {
  provide: 'DiagramsEj1Serialization',
  useValue: Ej1Serialization
};
const FlowchartLayoutService = {
  provide: 'DiagramsFlowchartLayout',
  useValue: FlowchartLayout
};
/**
 * NgModule definition for the Diagram component with providers.
 */
class DiagramAllModule {}
DiagramAllModule.ɵfac = function DiagramAllModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DiagramAllModule)();
};
DiagramAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: DiagramAllModule
});
DiagramAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [HierarchicalTreeService, MindMapService, RadialTreeService, ComplexHierarchicalTreeService, DataBindingService, SnappingService, PrintAndExportService, BpmnDiagramsService, SymmetricLayoutService, ConnectorBridgingService, UndoRedoService, LayoutAnimationService, DiagramContextMenuService, LineRoutingService, ConnectorEditingService, LineDistributionService, Ej1SerializationService, FlowchartLayoutService],
  imports: [[CommonModule, DiagramModule], DiagramModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DiagramAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, DiagramModule],
      exports: [DiagramModule],
      providers: [HierarchicalTreeService, MindMapService, RadialTreeService, ComplexHierarchicalTreeService, DataBindingService, SnappingService, PrintAndExportService, BpmnDiagramsService, SymmetricLayoutService, ConnectorBridgingService, UndoRedoService, LayoutAnimationService, DiagramContextMenuService, LineRoutingService, ConnectorEditingService, LineDistributionService, Ej1SerializationService, FlowchartLayoutService]
    }]
  }], null, null);
})();
let input = ['expanded', 'height', 'iconCss', 'id', 'symbols', 'title'];
let outputs$2 = [];
/**
 * Palette Directive
 * ```html
 * <e-palettes><e-palette></e-palette><e-palettes>
 * ```
 */
class PaletteDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$2);
    this.directivePropList = input;
  }
}
PaletteDirective.ɵfac = function PaletteDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || PaletteDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
PaletteDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: PaletteDirective,
  selectors: [["e-palette"]],
  inputs: {
    expanded: "expanded",
    height: "height",
    iconCss: "iconCss",
    id: "id",
    symbols: "symbols",
    title: "title"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PaletteDirective, [{
    type: Directive,
    args: [{
      selector: 'e-palettes>e-palette',
      inputs: input,
      outputs: outputs$2,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, null);
})();
/**
 * Palette Array Directive
 * @private
 */
class PalettesDirective extends ArrayBase {
  constructor() {
    super('palettes');
  }
}
PalettesDirective.ɵfac = function PalettesDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || PalettesDirective)();
};
PalettesDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: PalettesDirective,
  selectors: [["e-palettes"]],
  contentQueries: function PalettesDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, PaletteDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PalettesDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-symbolpalette>e-palettes',
      queries: {
        children: new ContentChildren(PaletteDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
const inputs$1 = ['accessKey', 'allowDrag', 'connectorDefaults', 'enableAnimation', 'enablePersistence', 'enableRtl', 'enableSearch', 'expandMode', 'filterSymbols', 'getConnectorDefaults', 'getNodeDefaults', 'getSymbolInfo', 'getSymbolTemplate', 'height', 'ignoreSymbolsOnSearch', 'locale', 'nodeDefaults', 'palettes', 'symbolDragSize', 'symbolHeight', 'symbolInfo', 'symbolMargin', 'symbolPreview', 'symbolWidth', 'width'];
const outputs$1 = ['paletteExpanding', 'paletteSelectionChange'];
const twoWays$1 = [''];
/**
 * SymbolPalette Component
 * ```html
 * <ej-symbol-palette></ej-symbol-palette>
 * ```
 */
let SymbolPaletteComponent = class SymbolPaletteComponent extends SymbolPalette {
  constructor(ngEle, srenderer, viewContainerRef, injector) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.tags = ['palettes'];
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    try {
      let mod = this.injector.get('DiagramsBpmnDiagrams');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    this.registerEvents(outputs$1);
    this.addTwoWay.call(this, twoWays$1);
    setValue('currentInstance', this, this.viewContainerRef);
    this.context = new ComponentBase();
  }
  ngOnInit() {
    this.context.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.context.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.context.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.tagObjects[0].instance = this.childPalettes;
    this.context.ngAfterContentChecked(this);
  }
};
SymbolPaletteComponent.ɵfac = function SymbolPaletteComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || SymbolPaletteComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector));
};
SymbolPaletteComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: SymbolPaletteComponent,
  selectors: [["ejs-symbolpalette"]],
  contentQueries: function SymbolPaletteComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, PalettesDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childPalettes = _t.first);
    }
  },
  inputs: {
    accessKey: "accessKey",
    allowDrag: "allowDrag",
    connectorDefaults: "connectorDefaults",
    enableAnimation: "enableAnimation",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    enableSearch: "enableSearch",
    expandMode: "expandMode",
    filterSymbols: "filterSymbols",
    getConnectorDefaults: "getConnectorDefaults",
    getNodeDefaults: "getNodeDefaults",
    getSymbolInfo: "getSymbolInfo",
    getSymbolTemplate: "getSymbolTemplate",
    height: "height",
    ignoreSymbolsOnSearch: "ignoreSymbolsOnSearch",
    locale: "locale",
    nodeDefaults: "nodeDefaults",
    palettes: "palettes",
    symbolDragSize: "symbolDragSize",
    symbolHeight: "symbolHeight",
    symbolInfo: "symbolInfo",
    symbolMargin: "symbolMargin",
    symbolPreview: "symbolPreview",
    symbolWidth: "symbolWidth",
    width: "width"
  },
  outputs: {
    paletteExpanding: "paletteExpanding",
    paletteSelectionChange: "paletteSelectionChange"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 0,
  vars: 0,
  template: function SymbolPaletteComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
SymbolPaletteComponent = __decorate([ComponentMixins([ComponentBase])], SymbolPaletteComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SymbolPaletteComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-symbolpalette',
      inputs: inputs$1,
      outputs: outputs$1,
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush,
      queries: {
        childPalettes: new ContentChild(PalettesDirective)
      }
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }];
  }, null);
})();

/**
 * NgModule definition for the SymbolPalette component.
 */
class SymbolPaletteModule {}
SymbolPaletteModule.ɵfac = function SymbolPaletteModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || SymbolPaletteModule)();
};
SymbolPaletteModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: SymbolPaletteModule
});
SymbolPaletteModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SymbolPaletteModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [SymbolPaletteComponent, PaletteDirective, PalettesDirective],
      exports: [SymbolPaletteComponent, PaletteDirective, PalettesDirective]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the SymbolPalette component with providers.
 */
class SymbolPaletteAllModule {}
SymbolPaletteAllModule.ɵfac = function SymbolPaletteAllModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || SymbolPaletteAllModule)();
};
SymbolPaletteAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: SymbolPaletteAllModule
});
SymbolPaletteAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, SymbolPaletteModule], SymbolPaletteModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SymbolPaletteAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, SymbolPaletteModule],
      exports: [SymbolPaletteModule],
      providers: []
    }]
  }], null, null);
})();
const inputs = ['enablePersistence', 'enableRtl', 'height', 'locale', 'sourceID', 'width'];
const outputs = ['created'];
const twoWays = [''];
/**
 * Overview Component
 * ```html
 * <ej-overview></ej-overview>
 * ```
 */
let OverviewComponent = class OverviewComponent extends Overview {
  constructor(ngEle, srenderer, viewContainerRef, injector) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.tags = [''];
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    this.registerEvents(outputs);
    this.addTwoWay.call(this, twoWays);
    setValue('currentInstance', this, this.viewContainerRef);
    this.context = new ComponentBase();
  }
  ngOnInit() {
    this.context.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.context.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.context.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.context.ngAfterContentChecked(this);
  }
};
OverviewComponent.ɵfac = function OverviewComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OverviewComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector));
};
OverviewComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OverviewComponent,
  selectors: [["ejs-overview"]],
  inputs: {
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    height: "height",
    locale: "locale",
    sourceID: "sourceID",
    width: "width"
  },
  outputs: {
    created: "created"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 0,
  vars: 0,
  template: function OverviewComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
OverviewComponent = __decorate([ComponentMixins([ComponentBase])], OverviewComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OverviewComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-overview',
      inputs: inputs,
      outputs: outputs,
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }];
  }, null);
})();

/**
 * NgModule definition for the Overview component.
 */
class OverviewModule {}
OverviewModule.ɵfac = function OverviewModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OverviewModule)();
};
OverviewModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: OverviewModule
});
OverviewModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OverviewModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [OverviewComponent],
      exports: [OverviewComponent]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the Overview component with providers.
 */
class OverviewAllModule {}
OverviewAllModule.ɵfac = function OverviewAllModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OverviewAllModule)();
};
OverviewAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: OverviewAllModule
});
OverviewAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, OverviewModule], OverviewModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OverviewAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, OverviewModule],
      exports: [OverviewModule],
      providers: []
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { BpmnDiagramsService, ComplexHierarchicalTreeService, ConnectorAnnotationDirective, ConnectorAnnotationsDirective, ConnectorBridgingService, ConnectorDirective, ConnectorEditingService, ConnectorFixedUserHandleDirective, ConnectorFixedUserHandlesDirective, ConnectorsDirective, CustomCursorDirective, CustomCursorsDirective, DataBindingService, DiagramAllModule, DiagramComponent, DiagramContextMenuService, DiagramModule, Ej1SerializationService, FlowchartLayoutService, HierarchicalTreeService, LayerDirective, LayersDirective, LayoutAnimationService, LineDistributionService, LineRoutingService, MindMapService, NodeAnnotationDirective, NodeAnnotationsDirective, NodeDirective, NodeFixedUserHandleDirective, NodeFixedUserHandlesDirective, NodesDirective, OverviewAllModule, OverviewComponent, OverviewModule, PaletteDirective, PalettesDirective, PortDirective, PortsDirective, PrintAndExportService, RadialTreeService, SnappingService, SymbolPaletteAllModule, SymbolPaletteComponent, SymbolPaletteModule, SymmetricLayoutService, UndoRedoService };
