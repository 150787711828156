// Angular
import { Component, ViewChild, Output, EventEmitter, OnInit, WritableSignal, signal, inject, effect, SimpleChanges } from '@angular/core';
import { distinctUntilChanged, Subscription } from 'rxjs';

// 3rd Party
import { Predicate, Query } from '@syncfusion/ej2-data';
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';
import { CommandModel, GridModel, CommandClickEventArgs, DetailRowService, valueAccessor } from '@syncfusion/ej2-angular-grids';

// Utils
import { formatBoolean } from '@utils';

// Models
import { APIEndpoints } from '@models/api/Endpoints';
import { ErrorSeverity } from '@core/error/error.types';
import { Surgery } from '@shared/models/data-contracts';
import { SetGridDataArgs } from '@shared/components/base-grid/models/grid.models';

// Services
import { ApiService } from '@services/api/api.service';
import { FileHubService } from '@features/file-hub/services/file-hub.service';
import { ProviderTypeService } from './services/provider-type/provider-type.service';
import { SurgeryDetailService } from './services/surgery-detail/surgery-detail.service';
import { BaseGridHooks } from '@shared/components/base-grid/services/hooks.service';
import { BaseGridService } from '@shared/components/base-grid/services/state.service';

// Components
import { ComponentBase } from '@core/base/component.base';
import { BaseGridComponent } from '@shared/components/base-grid/base-grid.component';
import { SurgeryFormComponent } from '../../forms/surgery-form/surgery-form.component';
import { SURGERIES_GRID_DEFAULTS, SurgeriesGridService } from './services/surgeries-grid.service';

interface SurgeryProgress {
  selectedSegments: boolean[];
  currentStep: number;
}

// Error constants
const ERRORS = {
  NO_CASE_FILE: {
    message: 'No case file selected',
    technical: 'Attempt to load surgeries with no case file selected'
  },
  LOAD_SURGERIES: {
    message: 'Unable to load surgeries',
    technical: 'Error loading surgeries from API'
  },
  UPDATE_SURGERIES: {
    message: 'Unable to update surgeries',
    technical: 'Error updating surgeries during state change'
  },
  DELETE_SURGERY: {
    message: 'Unable to delete surgery',
    technical: 'Error deleting surgery'
  }
};

@Component({
  selector: 'surgeries-grid',
  standalone: true,
  imports: [
    DialogModule,
    BaseGridComponent,
    SurgeryFormComponent
  ],
  templateUrl: './surgeries-grid.component.html',
  styleUrl: './surgeries-grid.component.scss',
  providers: [
    DetailRowService,
    BaseGridHooks,
    BaseGridService
  ],
})
export class SurgeriesGridComponent extends ComponentBase {
  // Injectables
  protected readonly service = inject(SurgeriesGridService);
  protected readonly fileHub = inject(FileHubService);
  protected readonly state = this.service.state;

  // Decorator properties
  @ViewChild('addSurgeryDialog') addSurgeryDialog!: DialogComponent;
  @ViewChild('surgeriesGrid') surgeriesGrid: BaseGridComponent;
  @ViewChild('editSurgeryDialog') editSurgeryDialog!: DialogComponent;
  @ViewChild('deleteConfirmDialog') deleteConfirmDialog!: DialogComponent;
  @ViewChild('customDetailTemplate', { static: true }) customDetailTemplate!: string;
  @Output() surgerySelected = new EventEmitter<any>();

  // Signal properties
  surgerySignal = signal<Surgery | undefined>(undefined);
  selectedSurgerySignal: WritableSignal<any> = signal(undefined);

  // Public properties
  providerTypeMap: { [key: number]: string } = {};
  addSurgeryDialogVisibility: boolean = false;
  providerTypeFields: Object;
  editSurgeryDialogVisibility: boolean = false;
  selectedSurgery: any;
  currentProgress: SurgeryProgress;
  formattedDBData: any[] = [];
  selectedRows: any[] = [];
  batchChangedRecords: any[] = [];
  testDialogVisible: boolean = false;
  addSurgeryConfig = {
    header: 'Add Surgery',
    width: '600px'
  };
  editSurgeryConfig = {
    header: 'Edit Surgery',
    width: '600px'
  };
  deleteConfirmDialogVisibility: boolean = false;
  deleteConfirmConfig = {
    header: 'Confirm Delete',
    width: '400px'
  };
  readonly addSurgeryButtons = [
    { click: this.closeDialog.bind(this), buttonModel: { content: 'Cancel', cssClass: 'e-outline' } },
  ];
  editSurgeryButtons: Object[] = [
    { click: this.closeEditDialog.bind(this), buttonModel: { content: 'Cancel', cssClass: 'e-outline' } },
  ];
  deleteConfirmButtons: Object[] = [
    { click: this.closeDeleteDialog.bind(this), buttonModel: { content: 'Cancel', cssClass: 'e-outline' } },
    { click: this.confirmDelete.bind(this), buttonModel: { content: 'Delete', cssClass: 'e-danger', isPrimary: true } }
  ];
  settings: SetGridDataArgs = {
    endpoint: undefined,
    query: undefined,
    name: 'Surgeries',
    useRoundedEdges: true,
    gridSettings: {
        columns: [
          { field: 'Id' },
          { field: 'CaseFileId', visible: false },
          { field: 'SurgeryType.Description', headerText: 'Surgery Type', allowEditing: false },
          { field: 'Provider.Name', headerText: 'Provider', allowEditing: false, visible: false },
          { field: 'Surgeon', headerText: 'Surgeon', allowEditing: false },
          { field: 'SurgeryDate', headerText: 'Surgery Date', format: 'MM/dd/yyyy', type: 'date' },
          { field: 'SurgeryCostEstimate', headerText: 'Surgery Cost Estimate', type: 'number', format: 'C2' },
          { field: 'MissingInvoices', headerText: 'Missing Invoices', valueAccessor: (field: string, data: any) => formatBoolean(data[field]) },
          { field: 'ConfirmedInvoices', headerText: 'Invoices Confirmed', valueAccessor: (field: string, data: any) => formatBoolean(data[field]) },
          { field: 'ConfirmedById', visible: false },
          { field: 'ConfirmedBy.ContactName', headerText: 'Confirmed By', allowEditing: false },
          { type: 'commands' }
        ],
        rowHeight: 45,
        gridLines: 'Both',
        childGrid: {
          queryString: 'surgeryId'
        },
        toolbarClick: ($event: any) => this.customToolbarClick($event),
        commandClick: ($event: CommandClickEventArgs) => this.commandClick($event),
        rowSelected: ($event: any) => this.onSurgerySelect($event),
        detailDataBound: ($event: any) => this.onDetailDataBound($event)
    }
  };

  // Private properties
  private surgeryProgressMap: Map<number, SurgeryProgress> = new Map();
  private caseFileSubscription: Subscription;

  // Default page settings for pagination
  detailTemplate: string = `
  <div class="detail-container">
    <table class="detail-table">
      <thead>
        <tr>
          <th>Surgery Details</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Provider</td>
          <td>\${provider}</td>
        </tr>
        <tr>
          <td>Surgery Type</td>
          <td>\${surgeryType}</td>
        </tr>
        <tr>
          <td>Surgery Date</td>
          <td>\${surgeryDate}</td>
        </tr>
        <tr>
          <td>Cost Estimate</td>
          <td>\${surgeryCostEstimate}</td>
        </tr>
      </tbody>
    </table>
  </div>`;

  constructor(
    private api: ApiService,
    private providerTypeService: ProviderTypeService,
    private surgeryDetailService: SurgeryDetailService
  ) {
    super();

    effect(() => {
      const caseFileId = this.fileHub.caseFileId;

      if (caseFileId) {
        // Create a new settings object to trigger ngOnChanges
        const newPredicate = new Predicate('CaseFileId', 'equal', caseFileId);
        this.settings = {
          ...this.settings,
          endpoint: SURGERIES_GRID_DEFAULTS.ENDPOINT,
          query: SURGERIES_GRID_DEFAULTS.QUERY.where(newPredicate)
        };
      }
    });
  }

  ngOnDestroy() {
    if (this.caseFileSubscription) {
      this.caseFileSubscription.unsubscribe();
    }
  }

  onGridDataReceived(data: Surgery[]) {
    if (data?.length) {
      console.log('Grid data received:', data);
      const surgery = data[0];  
      this.service.setSurgeries(data);
      this.surgerySelected.emit({
        surgery: surgery,
        progress: this.surgeryProgressMap.get(surgery.Id as number) || { selectedSegments: [], currentStep: 0 }
      });
    }
  }
  
  onAddFormSubmitted() {
    this.addSurgeryDialogVisibility = false;
  }

  onEditFormSubmitted() {
    this.editSurgeryDialogVisibility = false;
  }

  getCommentValues(field: string, data: object): string[] {
    return (data as any)[field] ? (data as any)[field as string] : [''];
  }

  commandClick(commandClickArgs: CommandClickEventArgs) {
    const rowData = commandClickArgs.rowData as any;

    if (commandClickArgs.commandColumn?.buttonOption?.iconCss === 'e-icons e-edit') {
      commandClickArgs.cancel = true;
      this.editSurgeryDialogVisibility = true;
      this.surgerySignal.set(rowData as Surgery);

      if (this.surgerySignal() && this.editSurgeryDialog) {
        this.editSurgeryDialog.show();
      }
    } else if (commandClickArgs.commandColumn?.buttonOption?.iconCss === 'e-icons e-delete') {
      this.selectedSurgery = rowData;
      this.selectedSurgerySignal.set(rowData);
      this.deleteConfirmDialogVisibility = true;
    }
  }

  onSurgerySelect(event: any): void {
    const surgeryId = event.data.id;
    const progress = this.surgeryProgressMap.get(surgeryId) || {
      selectedSegments: Array(5).fill(false),
      currentStep: 0
    };
    this.surgerySelected.emit({
      surgery: event.data,
      progress: progress
    });
  }

  updateSurgeryProgress(surgeryId: number, progress: SurgeryProgress): void {
    this.surgeryProgressMap.set(surgeryId, progress);
  }

  getFirstSurgery(): any {
    const surgeries = this.surgeriesGrid.grid.getCurrentViewRecords();
    if (surgeries && surgeries.length > 0) {
      const surgery = surgeries[0] as any;
      return {
        surgery: surgery,
        progress: this.surgeryProgressMap.get(surgery.id) || { selectedSegments: [], currentStep: 0 }
      };
    }
    return null;
  }

  getProviderTypeSums(data: any): string {
    let totalSum = 0;

    if (data.InvoiceRows) {
      data.InvoiceRows.forEach((row: any) => {
        totalSum += row.TotalDueProvider || 0;
      });
    }

    return `$${totalSum.toLocaleString('en-US', { minimumFractionDigits: 2 })}`;
  }

  async onDetailDataBound(args: any) {
    const rowData = args.data;
    const detailDiv = document.createElement('div');
    detailDiv.setAttribute('id', `detail-div-${rowData.id}`);

    const invoices = await this.surgeryDetailService.getInvoicesForSurgery(rowData.CaseFileId);
    const surgeryDate = new Date(rowData.SurgeryDate);
    const filteredInvoices = this.surgeryDetailService.filterInvoicesByDate(invoices.result, surgeryDate);

    const groupedInvoices = this.groupInvoicesByProviderType(filteredInvoices, surgeryDate);
    const tableRows = this.generateTableRows(groupedInvoices);
    const amountBilled = this.calculateTotalSum(groupedInvoices);

    detailDiv.innerHTML = this.generateDetailContent(tableRows, amountBilled);
    ((args.detailElement as HTMLElement).querySelector('.custom-details') as HTMLElement).appendChild(detailDiv);
  }

  private groupInvoicesByProviderType(invoices: any[], surgeryDate: Date): any {
    const allProviderTypes = this.providerTypeService.getAllProviderTypes();
    const groupedInvoices = invoices.reduce((acc: any, invoice: any) => {
      const providerTypeId = invoice.SurgicalInvoiceId;
      if (!acc[providerTypeId]) {
        acc[providerTypeId] = {
          providerTypeDescription: this.providerTypeService.getProviderTypeDescription(providerTypeId),
          providerName: invoice.Provider?.Name || 'Unknown',
          amountBilled: 0,
          invoiceDate: invoice.InvoiceDate ? new Date(invoice.InvoiceDate).toLocaleDateString() : 'N/A',
          isDateMismatch: false
        };
      }
      acc[providerTypeId].amountBilled += invoice.InvoiceRows.reduce((sum: number, row: any) => sum + (row.AmountBilled || 0), 0);

      if (invoice.InvoiceDate && new Date(invoice.InvoiceDate).toLocaleDateString() !== surgeryDate.toLocaleDateString()) {
        acc[providerTypeId].isDateMismatch = true;
      }

      return acc;
    }, {});

    // Ensure all provider types are included
    allProviderTypes.forEach((type: any) => {
      if (!groupedInvoices[type.id]) {
        groupedInvoices[type.id] = {
          providerTypeDescription: type.description,
          providerName: 'N/A',
          amountBilled: 0,
          invoiceDate: 'N/A',
          isDateMismatch: false
        };
      }
    });

    return groupedInvoices;
  }

  private generateTableRows(groupedInvoices: any): string {
    let tableRows = '';
    for (const key in groupedInvoices) {
      const item = groupedInvoices[key];
      const rowStyle = item.isDateMismatch
        ? 'background-color: rgba(255, 193, 7, 0.1) !important; border-color: rgba(255, 193, 7, 0.2) !important;'
        : '';
      tableRows += `
        <tr style="${rowStyle}">
          <td style="width: 15%;">${item.providerTypeDescription}</td>
          <td style="width: 45%;">${item.providerName}</td>
          <td style="width: 25%;">${item.invoiceDate}</td>
          <td style="width: 15%;">${item.amountBilled.toLocaleString('en-US', { minimumFractionDigits: 2 })}</td>
        </tr>
      `;
    }
    return tableRows;
  }

  private calculateTotalSum(groupedInvoices: any): number {
    return Object.values(groupedInvoices).reduce((sum: number, item: any) => sum + item.amountBilled, 0);
  }

  private generateDetailContent(tableRows: string, amountBilled: number): string {
    return `
      <div class="detail-container">
        <table class="detail-table" style="width: 50%;">
          <thead>
            <tr>
              <th>Provider Type</th>
              <th>Provider</th>
              <th>Date of Service</th>
              <th>Sum of Invoices</th>
            </tr>
          </thead>
          <tbody>
            ${tableRows}
            <tr class="total-row">
              <td colspan="3"><b>Total</b></td>
              <td><b>$${amountBilled.toLocaleString('en-US', { minimumFractionDigits: 2 })}</b></td>
            </tr>
          </tbody>
        </table>
      </div>`;
  }

  closeDialog(): void {
    this.addSurgeryDialogVisibility = false;
  }

  customToolbarClick(args: any): void {
    if (args.item.text === 'Add') {
      args.cancel = true;
      this.addSurgeryDialogVisibility = true;

      if (this.addSurgeryDialog) {
        this.addSurgeryDialog.show();
      }
    }

    if (args.item.id === 'EditSurgery') {
      this.testDialogVisible = true;
    }
  }

  closeEditDialog(): void {
    this.editSurgeryDialogVisibility = false;
  }

  closeDeleteDialog(): void {
    this.deleteConfirmDialogVisibility = false;
    this.selectedSurgery = null;
    this.selectedSurgerySignal.set(undefined);
  }

  async confirmDelete(): Promise<void> {
    if (!this.selectedSurgery) return;

    return this.withErrorHandling(async () => {
      await this.api.deleteOdata(`${APIEndpoints.Surgeries}/${this.selectedSurgery.id}`);
      this.deleteConfirmDialogVisibility = false;
      this.notification.success('Surgery deleted successfully', 'SurgeriesGridComponent.confirmDelete');
    }, 'SurgeriesGridComponent.confirmDelete', ERRORS.DELETE_SURGERY.message);
  }

  /**
   * Helper method for the withErrorHandling pattern
   */
  private async withErrorHandling<T>(
    operation: () => Promise<T>,
    context: string,
    userMessage: string
  ): Promise<T | undefined> {
    try {
      return await operation();
    } catch (error) {
      this.handleError(error, {
        context,
        userMessage,
        severity: ErrorSeverity.Error
      });
      return undefined;
    }
  }
}
