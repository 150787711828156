// Angular
import { Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { ReactiveFormsModule, FormControl, FormGroup } from '@angular/forms';
import { CommonModule } from '@angular/common';

// 3rd Party
import { DataManager } from '@syncfusion/ej2-data';
import { MultiSelectComponent } from '@syncfusion/ej2-angular-dropdowns';
import { FormValidators } from '@syncfusion/ej2-angular-inputs';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { ApiService } from '@services/api/api.service';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';

@Component({
  selector: 'add-role',
  standalone: true,
  imports: [
    CommonModule, 
    ReactiveFormsModule,
    ButtonModule, 
    TextBoxModule, 
    MultiSelectModule
  ],
  templateUrl: './add-role.component.html',
  styleUrl: './add-role.component.scss'
})
export class AddRoleComponent {

  constructor(
    private api: ApiService
  ) {}

  @Input() rolesGrid: GridComponent;

  // Settings for custom edit of user rights
  @ViewChild('addRoleFormRightsMultiSelect') addRoleFormRightsMultiSelect: MultiSelectComponent
  waterMark: string = 'Select';
  rightsListData: DataManager = this.api.getOdata(APIEndpoints.Rights);
  rightsFields: Object = { text: 'FriendlyName', value: 'Id' }
  editRightsForm: FormGroup = new FormGroup({
    'editRoleForm_Rights': new FormControl('', [FormValidators.required]),
  });
  addRoleForm: FormGroup = new FormGroup({
    'addRoleForm_RoleName': new FormControl('', [FormValidators.required]),
    'addRoleForm_Rights': new FormControl('', [FormValidators.required]),
    'addRoleForm_Description':new FormControl(''),
  });

  // Settings for adding roles
  @ViewChild('editRightsMultiSelect') editRightsMultiSelect: MultiSelectComponent;
  editRightsArray: object[];

  // Capture form for adding new roles
  addRoleSubmit(): void {
    const rolesData: DataManager = this.api.getOdata(APIEndpoints.Roles);

    if (this.addRoleForm.valid) {
      var rightsArray: any = [];

      for (let i = 0; i < this.addRoleForm.value.addRoleForm_Rights.length; i++) {
        var tmpObj = { 
          RightId: this.addRoleForm.value.addRoleForm_Rights[i]
        };
        rightsArray.push(tmpObj);
      }

      const role = {
        RoleName: this.addRoleForm.value.addRoleForm_RoleName,
        Description: this.addRoleForm.value.addRoleForm_Description,
        XrefRolesRights: rightsArray
      }
      
      rolesData.insert(role);
      this.rolesGrid.refresh();
    } else {
      alert('Invalid form');

      Object.keys(this.addRoleForm.controls).forEach(field => {
        const control = this.addRoleForm.get(field);
        control?.markAsTouched({ onlySelf: true });
      });
    }
  }
}
