// Angular
import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { ReactiveFormsModule, FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';

// 3rd Party
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';

// Internal
import { AutoFormBuilderModel } from '@models/components/auto-form-builder.model';
import { ApiService } from '@services/api/api.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';


export interface FormBuilderModel {
  fieldName: string,
  fieldLabel?: string,
  fieldType?: string,
  dataSource?: any,
  required?: boolean,
  value?: any;
}

@Component({
  selector: 'auto-form-builder',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule
  ],
  templateUrl: './auto-form-builder.component.html',
  styleUrl: './auto-form-builder.component.scss'
})
export class AutoFormBuilderComponent implements OnInit {

  constructor (
    private api: ApiService,
    private toast: ToastMessageService,
  ) {}

  @Input() model: any;
  @Input() hideSubmit: boolean;
  @Output() result = new EventEmitter<any>();
  automatedForm: FormGroup;
  errorMsg: string;

  ngOnInit(): void {
    if (this.model) {
      this.automatedForm = this.createFormGroup();
    } else {
      this.toast.showError('No Model Provided for Auto-Form-Builder');
    }
  }

  createFormGroup(): FormGroup {
    const formGroup: any = {};
  
    for (const [key, value] of Object.entries(this.model)) {
      
      if (this.model.hasOwnProperty(key) && key !== 'Id' && key !== 'CreatedAt' && key !== 'CreatedBy' && key !== 'UpdatedAt' && key !== 'UpdatedBy') {
        formGroup[key] = new FormControl('', this.getValidatorsForField(value));
      }
    }
    
    return new FormGroup(formGroup);
  }

  getValidatorsForField(fieldValue: any) {
    return fieldValue !== undefined ? [Validators.required] : null;
  }

  fieldErrorChecking(fieldName: any): boolean {
    const fieldErrs = this.automatedForm.get(fieldName)?.errors;
    const fieldTouched = this.automatedForm.get(fieldName)?.touched;

    if ((fieldErrs && fieldErrs !== null) && fieldTouched) {
      if ((fieldErrs as any).required) {
        this.errorMsg = 'This field required.';
      }
      return true;
    } else {
      return false;
    }
  }

  onSubmit(event: any) {
    event.stopPropagation();
    
    if (this.automatedForm.valid) {
      this.result.emit(this.automatedForm.value);
      return this.automatedForm.value;
    } else {
      this.toast.showError('Some fields may be INVALID. Please revise and re-submit.');
    }
  }
}
