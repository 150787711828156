// Angular
import { Component, ViewEncapsulation, Output, EventEmitter, Input, WritableSignal, signal, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, } from '@angular/forms';
import { APIEndpoints } from '@root/src/app/shared/models/api/Endpoints';
import { ApiService } from '@root/src/app/shared/services/api/api.service';
import { Query } from '@syncfusion/ej2-data';

/**
 * This component was converted from the vue-body-part-selector
 * https://github.com/HamadaFMahdi/vue-body-part-selector/tree/master?tab=readme-ov-file
 */
@Component({
  selector: 'body-part-selector',
  standalone: true,
  imports: [],
  templateUrl: './body-part-selector.component.html',
  styleUrl: './body-part-selector.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class BodyPartSelectorComponent {

  constructor (private api: ApiService) {}

  // Decorators
  @ViewChild('svgElement') svgElement!: ElementRef;
  @Input() existingInjuries: any[] = [];
  @Output() injuries = new EventEmitter<any>();

  // Signals
  masterList: WritableSignal<any[]> = signal([]);
  
  // Public
  selection: any = {};
  injury: FormGroup;

  // Lifecycle
  ngOnInit() {
    this.getMasterList();
  }

  pathClicked(event: any) {
    event.stopPropagation();
    let bodyPart = event.target.getAttribute('id') as string;
    let bodyPartId = Number(event.target.getAttribute('partId'));
    let severity = event.target.getAttribute('severity') as string;

    if(bodyPart) {
      // Find matching body part from master list
      const matchingPart = this.masterList().find(item => 
        item.Description.toLowerCase() === bodyPart.toLowerCase()
      );
      
      // Calculate next severity state
      if (severity === 'none') {
        severity = 'minor';
      } else if (severity === 'minor') {
        severity = 'severe';
      } else {
        severity = 'none';
      }

      // Get all elements with the same bodyPart ID
      const svg = event.target.ownerSVGElement;
      const matchingElements = svg.querySelectorAll(`path[id="${bodyPart}"]`);

      // Update severity for all matching elements
      matchingElements.forEach((element: Element) => {
        element.setAttribute('severity', severity.toString());
      });

      // Update selection object
      if (severity !== 'none') {
        this.selection[bodyPart] = {
          severity: severity,
          bodyPartId: matchingPart?.Id
        };
      } else {
        delete this.selection[bodyPart];
      }
    }

    this.injuries.emit(this.selection);
  }

  getInjuries() {
    this.injuries.emit(this.selection);
  }

  getMasterList() {
    this.api.getOdata(`${APIEndpoints.BodyParts}`)
      .executeQuery(new Query().select('Id,Description'))
      .then((response: any) => {
        console.log(response.result);
        this.masterList.set(response.result);
        if(this.existingInjuries.length > 0) this.mapExistingInjuries();
      })
  }

  mapExistingInjuries() {
    this.existingInjuries.forEach((injury: any) => {
      const matchingPart = this.masterList().find(item => 
        item.Id === injury.BodyPartId
      );
      
      if (matchingPart) {
        // Convert description to match new ID format
        const elementId = matchingPart.Description;
        // Get all matching paths instead of just one
        const targetPaths = this.svgElement.nativeElement.querySelectorAll(`#${elementId}`);
        targetPaths.forEach((targetPath: any) => {
          if (targetPath) {
            targetPath.setAttribute('severity', injury.Severity);
            this.selection[elementId] = {
              severity: injury.Severity,
              bodyPartId: injury.BodyPartId
            };
          }
        });
      }
    });
  }
}