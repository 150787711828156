import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LawFirmService } from './law-firm.service';
import { LawFirm } from '@root/src/app/shared/models/data-contracts';
import { Query } from '@syncfusion/ej2-data';
import { ComponentBase } from '@core/base/component.base';

interface LawFirmState {
  lawFirms: { [id: number]: LawFirm };
  loading: { [id: number]: boolean };
  errors: { [id: number]: any };
  userNames: { [id: number]: string };
}

// Add error constants
const ERRORS = {
  LAW_FIRM_STORE: {
    FETCH_FAILED: {
      message: 'Unable to load law firm data',
      technical: 'Failed to fetch law firm from API'
    },
    USER_FETCH_FAILED: {
      message: 'Unable to load user data',
      technical: 'Failed to fetch user details'
    }
  }
} as const;

@Injectable({
  providedIn: 'root'
})
export class LawFirmStore extends ComponentBase {
  private state: LawFirmState = {
    lawFirms: {},
    loading: {},
    errors: {},
    userNames: {}
  };

  private store = new BehaviorSubject<LawFirmState>(this.state);

  constructor(private lawFirmService: LawFirmService) {
    super();
  }

  // Selectors
  getLawFirm(id: number): Observable<any> {
    return this.store.pipe(
      map(state => state.lawFirms[id])
    );
  }

  isLoading(id: number): Observable<boolean> {
    return this.store.pipe(
      map(state => state.loading[id] ?? false)
    );
  }

  getUserName(id: number): Observable<string> {
    return this.store.pipe(
      map(state => state.userNames[id])
    );
  }

  // Actions
  async fetchLawFirm(id: number, query: Query): Promise<void> {
    // Set loading state
    this.updateState({
      loading: { ...this.state.loading, [id]: true },
      errors: { ...this.state.errors, [id]: null }
    });

    try {
      const lawFirm = await this.lawFirmService.getLawFirmById(id, query);
      this.updateState({
        lawFirms: { ...this.state.lawFirms, [id]: lawFirm },
        loading: { ...this.state.loading, [id]: false }
      });
    } catch (error) {
      this.handleError(error, {
        context: 'LawFirmStore.fetchLawFirm',
        userMessage: ERRORS.LAW_FIRM_STORE.FETCH_FAILED.message,
        severity: this.ErrorSeverity.Error,
        technicalDetails: { id, query, error }
      });

      this.updateState({
        loading: { ...this.state.loading, [id]: false },
        errors: { ...this.state.errors, [id]: error }
      });
    }
  }

  async fetchUserName(userId: number): Promise<void> {
    if (!userId || this.state.userNames[userId]) return;

    try {
      const userName = await this.lawFirmService.getUserById(userId);
      this.updateState({
        userNames: { ...this.state.userNames, [userId]: userName }
      });
    } catch (error) {
      this.handleError(error, {
        context: 'LawFirmStore.fetchUserName',
        userMessage: ERRORS.LAW_FIRM_STORE.USER_FETCH_FAILED.message,
        severity: this.ErrorSeverity.Error,
        technicalDetails: { userId, error }
      });

      this.updateState({
        errors: { ...this.state.errors, [`user_${userId}`]: error }
      });
    }
  }

  private updateState(newState: Partial<LawFirmState>): void {
    this.state = { ...this.state, ...newState };
    this.store.next(this.state);
  }
}