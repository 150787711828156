// Angular
import { Component, signal, ViewEncapsulation, WritableSignal, computed, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
// 3rd PartY
import { DashboardLayoutModule } from '@syncfusion/ej2-angular-layouts';
import { Query } from '@syncfusion/ej2-data';
import { GridModel } from '@syncfusion/ej2-grids';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faComments, faHistory } from '@fortawesome/free-solid-svg-icons';
import { CommentThreadsGridComponent } from '@shared/features/comments/components/threads/comment-threads-grid/comment-threads-grid.component';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { Appointment, CaseFile, Invoice } from '@models/data-contracts';
import { ApiService } from '@services/api/api.service';
import { FileHubService } from '@root/src/app/features/file-hub/services/file-hub.service';
import { GridTemplateComponent } from '@grids/grid-template/grid-template.component';
import { LoadingModule } from '@modules/loading.module';
import { TabCardComponent } from '@fileHubTabs/tab-card/tab-card.component';
import { RecentActivityGridComponent } from '../../../recent-activity-grid/recent-activity-grid.component';
import { CommentsGridComponent } from '../../../comments-grid/comments-grid.component';
import { HomeTabService } from '../services/home-tab-service';
export interface InvoiceSummary {
  Id?: number,
  ProviderName?: string | null,
  DOS?: Date | string | null,
  AmountBilled?: number | null,
  AmountPaid?: number | null
}

interface CountData {
  IsActive: number;
  Total: number;
}

@Component({
  selector: 'home-tab',
  standalone: true,
  templateUrl: './home-tab.component.html',
  styleUrl: './home-tab.component.scss',
  imports: [
    CommonModule,
    DashboardLayoutModule,
    GridModule,
    GridTemplateComponent,
    TabCardComponent,
    LoadingModule,
    FontAwesomeModule,
    CommentThreadsGridComponent,
    RecentActivityGridComponent,
    CommentsGridComponent,
  ],
  encapsulation: ViewEncapsulation.None
})
export class HomeTab implements OnInit {
  // Count data
  workItemsCountData: CountData = { IsActive: 0, Total: 0 };
  appointmentsCountData: CountData = { IsActive: 0, Total: 0 };
  invoicesCountData: { Providers: number; Total: number } = { Providers: 0, Total: 0 };

  // Loading state
  loadingHomeTabData: WritableSignal<boolean> = signal(false);
  loadingCardData: WritableSignal<boolean> = signal(false);
  loadingActivityData: WritableSignal<boolean> = signal(false);

  // Grid settings
  recentActivityGrid: GridModel | null = null;
  invoiceSummaryGrid: GridModel = {
    dataSource: [],
    columns: []
  };
  invoiceSummaryGridData: InvoiceSummary[] = [];
  financialSummaryData: any;
  
  icons = {
    comments: faComments,
    activity: faHistory
  };

  // Track if we have a valid case file ID
  protected caseFileId = signal<number>(0);

  constructor(
    private api: ApiService,
    private homeTabService: HomeTabService,
    public fileHub: FileHubService
  ) {
    // Subscribe to case file changes and only update when we have a valid ID
    this.fileHub.caseFile$.subscribe(file => {
      if (file && file.Id && file.Id > 0) {
        this.caseFileId.set(file.Id);
      }
    });
  }

  // Use computed for template binding
  protected readonly readyCaseFileId = computed(() => {
    const id = this.caseFileId();
    return id > 0 ? id : 0;
  });

  ngOnInit() {
    // Wait for case file to be set
    if (this.fileHub.caseFile?.Id) {
      this.initializeHometTab();
    } else {
      // Add a listener for when the case file becomes available
      this.fileHub.caseFile$.subscribe(file => {
        if (file && file.Id && file.Id > 0) {
          this.initializeHometTab();
        }
      });
    }
  }

  ngAfterViewInit() {
    // Only check data if we have a case file
    if (this.fileHub.caseFile?.Id) {
      
    }
  }

  // Calls all other functions in order to properly ensure data is set up and ready.
  async initializeHometTab() {
    try {
      if (!this.fileHub.caseFile?.Id) {
        return;
      }
      
      // Set all loading states to true initially
      this.loadingHomeTabData.set(true);
      this.loadingCardData.set(true);
      this.loadingActivityData.set(true);
      
      this.caseFileId.set(this.fileHub.caseFile.Id);
      
      try {
        // Fetch work items count and store the result - this is quick
        this.workItemsCountData = await this.homeTabService.fetchWorkItemsCount(this.fileHub.caseFile.Id);
      } catch (countError) {
        console.error('Error fetching work items count:', countError);
        this.workItemsCountData = { IsActive: 0, Total: 0 };
      } finally {
        // Always set card data loading to false, even if there was an error
        this.loadingCardData.set(false);
      }
      
      // Properly await the other data loading to ensure error handling
      await this.loadOtherData().catch(error => {
        console.error('Error in loadOtherData from initializeHometTab:', error);
        // Make sure loading states are cleared if there's an error
        this.loadingActivityData.set(false);
        this.loadingHomeTabData.set(false);
      });
      
      return;
    } catch (error) {
      console.error('Error initializing home tab:', error);
      // Reset all loading states
      this.loadingHomeTabData.set(false);
      this.loadingCardData.set(false);
      this.loadingActivityData.set(false);
      return error;
    } finally {
      // Add a fail-safe to ensure loading states are cleared
      setTimeout(() => {
        if (this.loadingHomeTabData() || this.loadingCardData() || this.loadingActivityData()) {
          console.warn('Detected stuck loading state, forcing reset');
          this.loadingHomeTabData.set(false);
          this.loadingCardData.set(false);
          this.loadingActivityData.set(false);
        }
      }, 10000); // 10 second fail-safe
    }
  }

  // Separate method to load other data that takes longer
  async loadOtherData() {
    try {
      await this.addHomeTabDataToCaseFile();
      
      // Initialize the count data if not already set by API
      if (!this.invoicesCountData || (this.invoicesCountData.Providers === 0 && this.invoicesCountData.Total === 0)) {
        this.setInvoicesCountData(this.fileHub.caseFile?.Invoices || []);
      }
      
      if (!this.appointmentsCountData || (this.appointmentsCountData.IsActive === 0 && this.appointmentsCountData.Total === 0)) {
        this.setAppointmentsCountData(this.fileHub.caseFile?.Appointments || []);
      }
      
      this.setRecentActivityGrid();
      this.setInvoiceSummaryGrid();
      
      // Set activity loading to false when completed
      this.loadingActivityData.set(false);
      
      // Finally set the main loading to false
      this.loadingHomeTabData.set(false);
      
      return true; // Signal successful completion
    } catch (error) {
      console.error('Error loading other tab data:', error);
      this.loadingActivityData.set(false);
      this.loadingHomeTabData.set(false);
      throw error; // Re-throw to allow proper handling in the caller
    } finally {
      // Extra safeguard
      this.loadingActivityData.set(false);
      this.loadingHomeTabData.set(false);
    }
  }

  async addHomeTabDataToCaseFile() {
    try {
      const endpoint = `${APIEndpoints.Casefiles}(${this.fileHub.caseFileId})`;
      const expandString = 'Invoices($expand=InvoiceRows,InvoicePayments,Provider),' +
                          'Appointments,XrefWorkItemCaseFiles($expand=WorkItem),' +
                          'XrefCommentThreadCaseFiles($expand=CommentThread($expand=Comments($expand=Type,CreatedByNavigation)))';
      const selectString = 'Invoices,Appointments,XrefWorkItemCaseFiles,XrefCommentThreadCaseFiles';
      const homeTabQuery = new Query().expand(expandString).select(selectString);

      // Add type assertion to resolve TypeScript error
      const response = await this.api.getOdata(endpoint).executeQuery(homeTabQuery) as any;
      
      if (response && response.result && response.result.length > 0) {
        const file = response.result[0] as CaseFile;
        if (!this.fileHub.caseFile?.Invoices) this.fileHub.updateCaseFile('Invoices', file.Invoices);
        if (!this.fileHub.caseFile?.Appointments) this.fileHub.updateCaseFile('Appointments', file.Appointments);
        if (!this.fileHub.caseFile?.XrefWorkItemCaseFiles) this.fileHub.updateCaseFile('XrefWorkItemCaseFiles', file.XrefWorkItemCaseFiles);
        if (!this.fileHub.caseFile?.XrefCommentThreadCaseFiles) {
          this.fileHub.updateCaseFile('XrefCommentThreadCaseFiles', file.XrefCommentThreadCaseFiles);
        }
      }
      
      return true;
    } catch (error) {
      console.error('Error in addHomeTabDataToCaseFile:', error);
      throw error; // Re-throw to ensure proper error chain
    }
  }

  setRecentActivityGrid() {
    this.recentActivityGrid = {
      dataSource: this.fileHub.caseFile ? this.api.getOdata(`${APIEndpoints.Casefiles}(${this.fileHub.caseFile.Id})/RecentActivity`)
      .executeQuery(new Query()
      .select('Category,Action,ModifiedBy,ModifiedDate')
      .sortBy('ModifiedDate', 'descending')
      .take(15)) : [],
      columns: [
        { field: 'Category', headerText: 'Category' },
        { field: 'Action', headerText: 'Actions' },
        { field: 'ModifiedBy', headerText: 'Modified By' },
        { field: 'ModifiedDate', headerText: 'Modified Date', isPrimaryKey: true },
      ]
    };
  }

  setInvoiceSummaryGrid() {
      this.invoiceSummaryGrid = {
      dataSource: this.fileHub.caseFile && this.fileHub.caseFile.Invoices ? this.setInvoiceSummaryData(this.fileHub.caseFile.Invoices) : [],
      columns: [
        { field: 'Id' },
        { field: 'ProviderName', headerText: 'Provider' },
        { field: 'DOS', headerText: 'Date of Service' },
        { field: 'AmountBilled', headerText: 'Billed', format: 'C2', editType: 'numeric' },
        { field: 'AmountPaid', headerText: 'Paid', format: 'C2', editType: 'numeric' }
      ]
    }
  }

  setInvoicesCountData(invoices: Invoice[]) {
    const providerIds = new Set<number>(); // A Set can hold any unique values for Provider Ids

    // Add  providerIds to Set so they can easily be counted
    invoices.forEach((invoice: any) => {
      if (invoice.Provider?.Id != null) {
        providerIds.add(invoice.Provider.Id);
      }
    });

    this.invoicesCountData = {
      Total: invoices.length,
      Providers: providerIds.size
    }
  }

  setAppointmentsCountData(appointments: any[]) {
    this.appointmentsCountData = {
      IsActive: appointments.filter((appointment: any) => appointment.IsCompleted !== true).length,
      Total: appointments.length
    }
  }

  setInvoiceSummaryData(invoices: Invoice[]) {
    return invoices.map((invoice: Invoice) => {
      return {
        Id: invoice.Id,
        ProviderName: invoice.Provider?.Name,
        DOS: invoice.InvoiceDate,
        AmountBilled: invoice.InvoiceRows?.reduce((sum, row) => sum + (row?.AmountBilled as number), 0) || 0,
        AmountPaid: invoice.InvoicePayments?.reduce((sum, payment) => sum + (payment?.AmountPaid as number), 0) || 0,
      };
    });
  }

  setWorkItemsCountData(xrefWorkItems: any[] | null | undefined) {
    if (!xrefWorkItems || !Array.isArray(xrefWorkItems)) {
      this.workItemsCountData = { IsActive: 0, Total: 0 };
      return;
    }

    const workItems = xrefWorkItems.map(xref => xref.WorkItem);
    const total = workItems.length;
    const active = workItems.filter(item => item.Status !== 'Completed').length;

    this.workItemsCountData = { IsActive: active, Total: total };
  }

  // This method can be removed since we're now using the service's implementation
  // Alternatively, you could refactor it to use the service
  async fetchWorkItemsCount() {
    try {
      if (!this.fileHub.caseFileId) {
        console.warn('No case file ID available for work item count');
        return;
      }
      
      this.workItemsCountData = await this.homeTabService.fetchWorkItemsCount(this.fileHub.caseFileId);
    } catch (error) {
      console.error('Error fetching work item counts:', error);
      this.workItemsCountData = { IsActive: 0, Total: 0 };
    }
  }
}
