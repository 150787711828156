// Angular
import { Component, Input, Output, EventEmitter, computed, WritableSignal, signal, effect, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

// 3rd Party
import { CheckBoxModule, ButtonModule, ChangeEventArgs } from '@syncfusion/ej2-angular-buttons';

// Internal
// import { Modality } from '@models/components/scheduler';
import { defaultModalityColors, UpdatedResourceEvent } from '@models/components/scheduler.model';
import { ListModality, ListModalityType } from '@models/data-contracts';
import { LoadingModule } from '@modules/loading.module';
import { SchedulerService } from '@services/scheduler/scheduler.service';
import { SchedulerSignalsService } from '@root/src/app/shared/services/scheduler/scheduler-signals.service';

@Component({
  selector: 'scheduler-modalities',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    CheckBoxModule,
    LoadingModule
  ],
  templateUrl: './scheduler-modalities.component.html',
  styleUrl: './scheduler-modalities.component.scss'
})
export class SchedulerModalitiesComponent {
  @Output() clickedModalityType = new EventEmitter<UpdatedResourceEvent>();

  constructor(
    private scheduler: SchedulerService,
    public schedulerSignals: SchedulerSignalsService
  ) {
    // Access the signal value with proper function call
    effect(() => {
      const modalities = this.schedulerSignals.data.Modalities();
      const modalityTypes = this.schedulerSignals.data.ModalityTypes();
      
      if (modalities?.length && modalityTypes?.length) {
        this.setModalityTypeColors();
      }
    });
  }

  // Fix: Update computed signals to properly access values
  protected readonly loading = computed(() => 
    this.schedulerSignals.loading.Modalities() || 
    this.schedulerSignals.loading.ModalityTypes()
  );

  protected readonly modalities = computed(() => 
    this.schedulerSignals.data.Modalities()
  );

  protected readonly modalityTypes = computed(() => 
    this.schedulerSignals.data.ModalityTypes()
  );

  // Update the template binding
  modalityColors: { [key: number]: string } = {};

  // Generate default colors for modality types
  private setModalityTypeColors() {
    const types = this.modalityTypes();
    const mods = this.modalities();
    
    if (!types) return;

    types.forEach((modalityType: ListModalityType) => {
      if (!modalityType.Id) return;
      
      const color = defaultModalityColors[String(modalityType.Description)] || 'gray';
      this.modalityColors[modalityType.Id] = color;

      // Update ModalityType colors
      modalityType.Color = color;
      modalityType.ListModalities?.forEach(m => m.Color = color);

      // Update Modality colors 
      mods?.filter(m => m.ModalityTypeId === modalityType.Id)
          .forEach(m => m.Color = color);
    });
  }

  // Optionally, you can create a method to get color by Id if needed
  getModalityColorById(id: number): string | undefined {
    return this.modalityColors[id]; // Return the color for the given Id
  }
  
  // Emit UpdatedResourceEvent
  onModalityTypeChange(args: ChangeEventArgs, modalityType: ListModalityType) {
    const selected: UpdatedResourceEvent = { 
      type: 'Modalities', 
      event: args, 
      selection: modalityType 
    };
    this.clickedModalityType.emit(selected);
    this.scheduler.updateResources(selected);
  }

  // Add trackBy function
  trackByModalityId(index: number, modalityType: ListModalityType): number {
    return modalityType.Id ?? -1;
  }
}
