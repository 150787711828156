// Angular
import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
  computed,
  effect,
  inject,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';

// Models
import { APIEndpoints } from '@models/api/Endpoints';
export { APIEndpoints };

// 3rd Party
import {
  GridAllModule,
  GridComponent,
  ColumnChooserService,
  FilterService,
  ToolbarService,
  PageService,
  EditService,
  SortService,
  GroupService,
  AggregateService,
  SearchService,
} from '@syncfusion/ej2-angular-grids';

// Core
import { BaseGrid } from '@core/base/grid.base';

// Models
import { SetGridDataArgs, FilteringOptions } from './models/grid.models';

// Services
import { BaseGridHooks } from './services/hooks.service';
import { BaseGridService } from './services/state.service';
import { AuditLogService } from '@services/audit-logs/audit-log-service';

@Component({
  selector: 'base-grid',
  standalone: true,
  imports: [CommonModule, GridAllModule],
  templateUrl: './base-grid.component.html',
  styleUrls: ['./base-grid.component.scss'],
  providers: [
    AggregateService,
    GroupService,
    SortService,
    EditService,
    ToolbarService,
    PageService,
    FilterService,
    ColumnChooserService,
    SearchService,
    AuditLogService,
    BaseGridService,
    BaseGridHooks
  ],
  encapsulation: ViewEncapsulation.None,
})
export class BaseGridComponent extends BaseGrid {
  protected readonly services = inject(BaseGridService);

  // Decorator Properties
  @Input() settings: SetGridDataArgs;
  @Input() customFiltering: FilteringOptions;
  @Output() gridData: EventEmitter<any> = new EventEmitter();
  @ViewChild('grid') grid!: GridComponent;

  constructor() {
    super();

    effect(() => {
      // Skip if already loading or grid not initialized
      const loading = this.services.loadingData();
      if (loading || !this.grid) return;

      // Apply data changes directly to grid
      const data = this.services.gridData();
      if (data !== undefined) {
        this.grid.dataSource = data ?? [];
        this.gridData.emit(data?.result);
      }

      // Apply toolbar changes directly to grid
      const toolbar = this.services.toolbar();
      if (toolbar !== undefined) {
        this.grid.toolbar = toolbar;
      }
    });
  }

  // Base method for showing audit logs that child components can use or override
  protected async showAuditLogs(id: number, entityType: string) {
    try {
      const logs = await this.audits.getAuditLogs(id, entityType);
      return logs ? this.audits.mapAuditDataToLogFormat(logs) : [];
    } catch (error) {
      this.notify('Failed to load audit logs', this.NotificationSeverity.Warning);
      return [];
    }
  }

  ngOnInit(): void {
    // Only set grid data if it hasn't been set yet and we're not already loading
    if (!this.services.gridData() && !this.services.loadingData()) {
      this.services.setGridData(this.settings);
      // this.services.getData(this.settings.endpoint, this.settings.query, this.settings.pageSettings);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['settings'] && !changes['settings'].firstChange) {
      // this.services.setGridData(this.settings);
      // this.services.getData(this.settings.endpoint, this.settings.query, this.settings.pageSettings);
    }
  }

  ngOnDestroy(): void {
    // Destroy grid component and its resources
    if (this.grid) {
      this.grid.destroy();
      this.services.resetState();
    }

    // Reset service state
    this.services.resetState();
  }
}
