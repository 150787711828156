import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import config from '@app/config';

@Injectable({ providedIn: 'root' })
export class HttpService {
    protected readonly BACKEND_URL: string = config.backendUrl;

    constructor(protected http: HttpClient) { }

    public get<T>(url: string, options?: { headers?: HttpHeaders }): Observable<T> {
        return this.http.get<T>(`${this.BACKEND_URL}${url}`, options);
    }

    public post<T>(url: string, body: any, options?: { headers?: HttpHeaders }): Observable<T> {
        return this.http.post<T>(`${this.BACKEND_URL}${url}`, body, options);
    }

    // Add other HTTP methods as needed
}
