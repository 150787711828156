<ejs-dialog #Modal width="1000px" header="Change Account Password" [isModal]="true" [showCloseIcon]='true'
    [visible]="false" (beforeOpen)="beforeModalOpened($event)">
    <ng-template #content>
        <p>Scan QR code with your authenticator app or enter the code</p>
        <!-- <qrcode [width]="256" [qrdata]="setupCode"></qrcode> -->
        <p>{{setupCode}}</p>
        <form [formGroup]="formGroup" (ngSubmit)="handleTOTPsetup()" class="d-flex flex-column search-form">
            <p>Verify Setup!</p>
            <ejs-switch onLabel="Yes" offLabel="No" [name]="" [checked]="isCompleted"
                (change)="isCompleted = !isCompleted"></ejs-switch>
            @if(isCompleted) {
            <p>Enter a code using your authenticator to verify setup</p>
            <ejs-textbox id="setupCode" type="string" required formControlName="code"
                placeholder="Enter your TOTP Code">
            </ejs-textbox>
            <spacer-element></spacer-element>
            <div class="form-submit">
                <button ejs-button type="submit" isPrimary="true">Submit</button>
            </div>
            }
        </form>
    </ng-template>
</ejs-dialog>