// Angular
import { Component, signal, ViewEncapsulation, WritableSignal, computed, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
// 3rd PartY
import { DashboardLayoutModule } from '@syncfusion/ej2-angular-layouts';
import { Query } from '@syncfusion/ej2-data';
import { GridModel } from '@syncfusion/ej2-grids';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faComments, faHistory } from '@fortawesome/free-solid-svg-icons';
import { CommentThreadsGridComponent } from '@shared/features/comments/components/threads/comment-threads-grid/comment-threads-grid.component';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { Appointment, CaseFile, Invoice } from '@models/data-contracts';
import { ApiService } from '@services/api/api.service';
import { FileHubService } from '@root/src/app/features/file-hub/services/file-hub.service';
import { GridTemplateComponent } from '@grids/grid-template/grid-template.component';
import { LoadingModule } from '@modules/loading.module';
import { TabCardComponent } from '@fileHubTabs/tab-card/tab-card.component';
import { RecentActivityGridComponent } from '../../recent-activity-grid/recent-activity-grid.component';
import { CommentsGridComponent } from '../../comments-grid/comments-grid.component';

export interface InvoiceSummary {
  Id?: number,
  ProviderName?: string | null,
  DOS?: Date | string | null,
  AmountBilled?: number | null,
  AmountPaid?: number | null
}

interface CountData {
  IsActive: number;
  Total: number;
}

@Component({
  selector: 'home-tab',
  standalone: true,
  templateUrl: './home-tab.component.html',
  styleUrl: './home-tab.component.scss',
  imports: [
    CommonModule,
    DashboardLayoutModule,
    GridModule,
    GridTemplateComponent,
    TabCardComponent,
    LoadingModule,
    FontAwesomeModule,
    CommentThreadsGridComponent,
    RecentActivityGridComponent,
    CommentsGridComponent
  ],
  encapsulation: ViewEncapsulation.None
})
export class HomeTab implements OnInit {
  // Count data
  workItemsCountData: CountData = { IsActive: 0, Total: 0 };
  appointmentsCountData: CountData = { IsActive: 0, Total: 0 };
  invoicesCountData: { Providers: number; Total: number } = { Providers: 0, Total: 0 };

  // Loading state
  loadingHomeTabData: WritableSignal<boolean> = signal(false);

  // Grid settings
  recentActivityGrid: GridModel | null = null;
  invoiceSummaryGrid: GridModel = {
    dataSource: [],
    columns: []
  };
  invoiceSummaryGridData: InvoiceSummary[] = [];
  financialSummaryData: any;
  
  icons = {
    comments: faComments,
    activity: faHistory
  };

  // Track if we have a valid case file ID
  protected caseFileId = signal<number>(0);

  constructor(
    private api: ApiService,
    public fileHub: FileHubService
  ) {
    // Subscribe to case file changes and only update when we have a valid ID
    this.fileHub.caseFile$.subscribe(file => {
      if (file && file.Id && file.Id > 0) {
        this.caseFileId.set(file.Id);
      }
    });
  }

  // Use computed for template binding
  protected readonly readyCaseFileId = computed(() => {
    const id = this.caseFileId();
    return id > 0 ? id : 0;
  });

  ngOnInit() {
    // Wait for case file to be set
    if (this.fileHub.caseFile?.Id) {
      console.log('Case file ready:', this.fileHub.caseFile.Id);
    }
  }

  ngAfterViewInit() {
    // Only check data if we have a case file
    if (this.fileHub.caseFile?.Id) {
      this.initializeHomtTab();
    }
  }

  // Calls all other functions in order to properly ensure data is set up and ready.
  async initializeHomtTab() {
    try {
      if (this.fileHub.caseFile?.Id) {
        this.loadingHomeTabData.set(true);
        this.caseFileId.set(this.fileHub.caseFile.Id);
        this.addHomeTabDataToCaseFile();
        this.setRecentActivityGrid();
        this.setInvoiceSummaryGrid();
        this.setInvoicesCountData(this.fileHub.caseFile.Invoices as Invoice[]); 
        this.setInvoiceSummaryData(this.fileHub.caseFile.Invoices as Invoice[]);
        this.setAppointmentsCountData(this.fileHub.caseFile.Appointments as Appointment[]);
        this.setWorkItemsCountData(this.fileHub.caseFile.XrefWorkItemCaseFiles);
      }

      return;

    } catch (error) {
      console.error('Error in initializeHomtTab:', error);
      return error;

    } finally {
      this.loadingHomeTabData.set(false);
      return;
    }
  }

  async addHomeTabDataToCaseFile() {
    
    try {
      const endpoint = `${APIEndpoints.Casefiles}(${this.fileHub.caseFileId})`;
      const expandString = 'Invoices($expand=InvoiceRows,InvoicePayments,Provider),' +
                          'Appointments,XrefWorkItemCaseFiles($expand=WorkItem),' +
                          'XrefCommentThreadCaseFiles($expand=CommentThread($expand=Comments($expand=Type,CreatedByNavigation)))';
      const selectString = 'Invoices,Appointments,XrefWorkItemCaseFiles,XrefCommentThreadCaseFiles';
      const homeTabQuery = new Query().expand(expandString).select(selectString);

      return await this.api.getOdata(endpoint).executeQuery(homeTabQuery).then((res: any) => {
        const file = res.result[0] as CaseFile;
        if (!this.fileHub.caseFile?.Invoices) this.fileHub.updateCaseFile('Invoices', file.Invoices);
        if (!this.fileHub.caseFile?.Appointments) this.fileHub.updateCaseFile('Appointments', file.Appointments);
        if (!this.fileHub.caseFile?.XrefWorkItemCaseFiles) this.fileHub.updateCaseFile('XrefWorkItemCaseFiles', file.XrefWorkItemCaseFiles);
        if (!this.fileHub.caseFile?.XrefCommentThreadCaseFiles) {
          this.fileHub.updateCaseFile('XrefCommentThreadCaseFiles', file.XrefCommentThreadCaseFiles);
        }
        
        return;
      });
      
    } catch (error) {
      console.error('Error in addHomeTabDataToCaseFile:', error);
      return error;

    } finally {
      this.loadingHomeTabData.set(false);
      return;
    }
  }

  setRecentActivityGrid() {
    this.recentActivityGrid = {
      dataSource: this.fileHub.caseFile ? this.api.getOdata(`${APIEndpoints.Casefiles}(${this.fileHub.caseFile.Id})/RecentActivity`)
      .executeQuery(new Query()
      .select('Category,Action,ModifiedBy,ModifiedDate')
      .sortBy('ModifiedDate', 'descending')
      .take(15)) : [],
      columns: [
        { field: 'Category', headerText: 'Category' },
        { field: 'Action', headerText: 'Actions' },
        { field: 'ModifiedBy', headerText: 'Modified By' },
        { field: 'ModifiedDate', headerText: 'Modified Date', isPrimaryKey: true },
      ]
    };
  }

  setInvoiceSummaryGrid() {
      this.invoiceSummaryGrid = {
      dataSource: this.fileHub.caseFile && this.fileHub.caseFile.Invoices ? this.setInvoiceSummaryData(this.fileHub.caseFile.Invoices) : [],
      columns: [
        { field: 'Id' },
        { field: 'ProviderName', headerText: 'Provider' },
        { field: 'DOS', headerText: 'Date of Service' },
        { field: 'AmountBilled', headerText: 'Billed', format: 'C2', editType: 'numeric' },
        { field: 'AmountPaid', headerText: 'Paid', format: 'C2', editType: 'numeric' }
      ]
    }
  }

  setInvoicesCountData(invoices: Invoice[]) {
    const providerIds = new Set<number>(); // A Set can hold any unique values for Provider Ids

    // Add  providerIds to Set so they can easily be counted
    invoices.forEach((invoice: any) => {
      if (invoice.Provider?.Id != null) {
        providerIds.add(invoice.Provider.Id);
      }
    });

    this.invoicesCountData = {
      Total: invoices.length,
      Providers: providerIds.size
    }
  }

  setAppointmentsCountData(appointments: any[]) {
    this.appointmentsCountData = {
      IsActive: appointments.filter((appointment: any) => appointment.IsCompleted !== true).length,
      Total: appointments.length
    }
  }

  setInvoiceSummaryData(invoices: Invoice[]) {
    return invoices.map((invoice: Invoice) => {
      return {
        Id: invoice.Id,
        ProviderName: invoice.Provider?.Name,
        DOS: invoice.InvoiceDate,
        AmountBilled: invoice.InvoiceRows?.reduce((sum, row) => sum + (row?.AmountBilled as number), 0) || 0,
        AmountPaid: invoice.InvoicePayments?.reduce((sum, payment) => sum + (payment?.AmountPaid as number), 0) || 0,
      };
    });
  }

  setWorkItemsCountData(xrefWorkItems: any[] | null | undefined) {
    if (!xrefWorkItems || !Array.isArray(xrefWorkItems)) {
      this.workItemsCountData = { IsActive: 0, Total: 0 };
      return;
    }

    const workItems = xrefWorkItems.map(xref => xref.WorkItem);
    const total = workItems.length;
    const active = workItems.filter(item => item.Status !== 'Completed').length;

    this.workItemsCountData = { IsActive: active, Total: total };
  }
}
