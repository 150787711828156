// Angular
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

// Servuces
import { ApiService } from '@services/api/api.service';
import { ErrorHandlingService } from '@core/error/error.service';

type FormResult = {
  status?: 'success' | 'warning' | 'error';
  message?: string;
} | Error | Response | undefined;
@Injectable({
  providedIn: 'root'
})
export class FormCrudService {

  constructor(
    private api: ApiService,
    private errorHandling: ErrorHandlingService
  ) { }


  // General catch all for submiting forms
  async submitForm(form: FormGroup, endpoint: string, submitType: 'POST' | 'PATCH' | 'DELETE' = 'PATCH'): Promise<any> {

    try {

      // Check for endpoint first
      if (!form || !form.value) {
        return this.errorHandling.handleError('No form found', {
        context: 'FormCrudService.submitForm',
        userMessage: 'No form found'
      });
    }

    if (!endpoint) {
      return this.errorHandling.handleError('No endpoint found', {
        context: 'FormCrudService.submitForm',
        userMessage: 'No endpoint found'
      });
    }
    
    switch (submitType) {
      case 'POST': return await this.postRequest(form, endpoint);
      case 'PATCH': return await this.patchRequest(form, endpoint);
      case 'DELETE': return await this.deleteRequest(endpoint);
      default: return await this.patchRequest(form, endpoint);
    }

    } catch (error) {
      return this.errorHandling.handleError(error, {
        context: 'FormCrudService.submitForm',
        userMessage: 'Error submitting form'
      });
    }
  } 

  // Helper function to create new data
  async postRequest(form: FormGroup, endpoint: string) {
    if (!endpoint) {
      return this.errorHandling.handleError('No endpoint found for form', {
        context: 'FormCrudService.postRequest',
        userMessage: 'No endpoint found for form'
      });
    }

    try {
      return await this.api.fetchRequest(endpoint, 'POST', form.value);
    } catch (error) {
      return this.errorHandling.handleError(`Error creating data at ${endpoint}`, {
        context: 'FormCrudService.postRequest',
        userMessage: 'Error creating data at endpoint',
        technicalDetails: {
          error
        }
      });
    }
  }

  // Helper function to handle our PATCH request
  async patchRequest(form: FormGroup, endpoint: string) {
    if (!endpoint) {
      return this.errorHandling.handleError('Endpoint is required', {
        context: 'FormCrudService.patchRequest',
        userMessage: 'Endpoint is required'
      });
    }

    try {
      return await this.api.fetchRequest(endpoint, 'PATCH', form.value);
    } catch (error) {
      return this.errorHandling.handleError(`Error updating data at ${endpoint}`, {
        context: 'FormCrudService.patchRequest',
        userMessage: 'Error updating data at endpoint',
        technicalDetails: {
          error
        }
      });
    }
  }

  // Helper function to handle our DELETE request
  async deleteRequest(endpoint: string) {
    if (!endpoint) {
      return this.errorHandling.handleError('Endpoint is required', {
        context: 'FormCrudService.deleteRequest',
        userMessage: 'Endpoint is required'
      });
    }

    try {
      return await this.api.fetchRequest(endpoint, 'PATCH', { IsActive: false });
    } catch (error) {
      return this.errorHandling.handleError(`Error deactivating data at ${endpoint}`, {
        context: 'FormCrudService.deleteRequest',
        userMessage: 'Error deactivating data at endpoint',
        technicalDetails: {
          error
        }
      });
    }
  }
}