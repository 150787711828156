<div id="providers-grid">
    <div *ngIf="providers?.dataSource">
        <grid-template #providersGrid [settings]="providers" [name]="'Providers'" [useRoundedEdges]="true" />
    </div>

    <ng-template #nameTemplate let-data>
        <a class="link" (click)="onProviderClicked(data)">
            <span>{{data.Name}}</span>
        </a>
    </ng-template>

    <!-- Audit Logs Modal -->
    <ejs-dialog #logsDialog 
        [header]="'Provider Logs'" 
        [buttons]="logsDialogButtons"
        [target]="'body'"
        [isModal]="true"
        [visible]="false"
        [showCloseIcon]="true">
        <ng-template #content>
            <div class="dialog-content logs-dialog-content">
                <app-audit-logs 
                    [logs]="auditLogs"
                    [loading]="auditLogsLoading">
                </app-audit-logs>
            </div>
        </ng-template>
    </ejs-dialog>

    <!-- Provider Form Modal -->
    <ejs-dialog #providerDialog 
        [visible]="false"
        [isModal]="true"
        [showCloseIcon]="true"
        [closeOnEscape]="true"
        [buttons]="providerDialogButtons">
        <provider-form #providerFormComponent [provider]="selectedProvider" [displayButtons]="false" />
    </ejs-dialog>

    <!-- Bulk Edit Providers Component -->
    <app-bulk-edit-providers #bulkEditProvidersComponent></app-bulk-edit-providers>
</div>
