import { Injectable } from '@angular/core';
import { Query } from '@syncfusion/ej2-data';
import { AuthenticatedServiceBase } from '@core/auth/auth.base';

export interface CountData {
  IsActive: number;
  Total: number;
}

@Injectable({
  providedIn: 'root'
})
export class HomeTabService extends AuthenticatedServiceBase {
  protected override readonly endpoint = this.APIEndpoints.WorkItems;

  /**
   * Fetches work item counts for a specific case file
   * @param caseFileId The case file ID to get work items for
   * @returns Promise with count data for active and total work items
   */
  async fetchWorkItemsCount(caseFileId: number): Promise<CountData> {
    try {
      if (!caseFileId) {
        console.warn('No case file ID provided for work item count');
        return { IsActive: 0, Total: 0 };
      }
      
      // Query to get minimal data - just ID and Status name
      const query = new Query()
        .select('Id,Status')
        .expand(['Status($select=Name)'])
        .where('XrefWorkItemCaseFiles/any(x: x/CaseFileId eq ' + caseFileId + ')', 'equal', true);
      
      const response = await this.api.getOdata(this.endpoint).executeQuery(query) as any;
      
      if (response && response.result) {
        const workItems = response.result;
        const total = workItems.length;
        const active = workItems.filter((item: any) => 
          item.Status?.Name !== 'Completed'
        ).length;
        
        return { IsActive: active, Total: total };
      } else {
        return { IsActive: 0, Total: 0 };
      }
    } catch (error) {
      console.error('Error fetching work item counts:', error);
      return { IsActive: 0, Total: 0 };
    }
  }
}
