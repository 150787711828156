// Angular
import { Component, ViewChild, Output, EventEmitter, OnInit, WritableSignal, signal } from '@angular/core';

// 3rd Party
import { CommandModel, GridModel, CommandClickEventArgs } from '@syncfusion/ej2-angular-grids';
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';
import { DetailRowService } from '@syncfusion/ej2-angular-grids';
import { Query } from '@syncfusion/ej2-data';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { ApiService } from '@services/api/api.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { GridTemplateComponent } from '@grids/grid-template/grid-template.component';
import { FileHubService } from '@root/src/app/features/file-hub/services/file-hub.service';
import { Surgery } from '@root/src/app/shared/models/data-contracts';
import { formatBoolean } from '@root/src/app/utils';
import { SurgeryFormComponent } from '../../forms/surgery-form/surgery-form.component';
import { ProviderTypeService } from '@root/src/app/components/grids/surgeries-grid/services/provider-type/provider-type.service';
import { SurgeryDetailService } from '@root/src/app/components/grids/surgeries-grid/services/surgery-detail/surgery-detail.service';

interface SurgeryProgress {
  selectedSegments: boolean[];
  currentStep: number;
}

@Component({
  selector: 'surgeries-grid',
  standalone: true,
  imports: [
    DialogModule,
    GridTemplateComponent,
    SurgeryFormComponent
  ],
  templateUrl: './surgeries-grid.component.html',
  styleUrl: './surgeries-grid.component.scss',
  providers: [DetailRowService],
})
export class SurgeriesGridComponent implements OnInit {
  constructor(
    private api: ApiService,
    private toast: ToastMessageService,
    private fileHub: FileHubService,
    private providerTypeService: ProviderTypeService,
    private surgeryDetailService: SurgeryDetailService
  ) {}

  @Output() surgerySelected = new EventEmitter<any>();
  providerTypeMap: { [key: number]: string } = {};

  @ViewChild('addSurgeryDialog') addSurgeryDialog!: DialogComponent;
  @ViewChild('surgeriesGrid') surgeriesGridTemplate: GridTemplateComponent;
  surgerySignal = signal<Surgery | undefined>(undefined);

  addSurgeryDialogVisibility: boolean = false;
  providerTypeFields: Object;
  caseFileId: number | undefined = this.fileHub.caseFileId;

  @ViewChild('editSurgeryDialog') editSurgeryDialog!: DialogComponent;
  editSurgeryDialogVisibility: boolean = false;

  @ViewChild('deleteConfirmDialog') deleteConfirmDialog!: DialogComponent;

  surgeriesGrid: any;
  selectedSurgery: any;
  selectedSurgerySignal: WritableSignal<any> = signal(undefined);
  currentProgress: SurgeryProgress;

  surgeriesGridTemplateSettings: GridModel;
  formattedDBData: any[] = [];
  selectedRows: any[] = [];
  batchChangedRecords: any[] = [];
  surgeriesGridTemplateManageColumnCommands: CommandModel[] = [
    { buttonOption: { iconCss: 'e-icons e-edit', cssClass: 'e-flat' } },
    { buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat e-danger' } }
  ];
  readonly addSurgeryButtons = [
    { click: this.closeDialog.bind(this), buttonModel: { content: 'Cancel', cssClass: 'e-outline' } },
  ];
  editSurgeryButtons: Object[] = [
    { click: this.closeEditDialog.bind(this), buttonModel: { content: 'Cancel', cssClass: 'e-outline' } },
  ];
  deleteConfirmButtons: Object[] = [
    { click: this.closeDeleteDialog.bind(this), buttonModel: { content: 'Cancel', cssClass: 'e-outline' } },
    { click: this.confirmDelete.bind(this), buttonModel: { content: 'Delete', cssClass: 'e-danger', isPrimary: true } }
  ];

  readonly surgeriesGridDefaultToolbar: string[] = ['Add'];
  detailTemplate: string = `
  <div class="detail-container">
    <table class="detail-table">
      <thead>
        <tr>
          <th>Surgery Details</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Provider</td>
          <td>\${provider}</td>
        </tr>
        <tr>
          <td>Surgery Type</td>
          <td>\${surgeryType}</td>
        </tr>
        <tr>
          <td>Surgery Date</td>
          <td>\${surgeryDate}</td>
        </tr>
        <tr>
          <td>Cost Estimate</td>
          <td>\${surgeryCostEstimate}</td>
        </tr>
      </tbody>
    </table>
  </div>`;

  private surgeryProgressMap: Map<number, SurgeryProgress> = new Map();
  public testDialogVisible: boolean = false;

  public addSurgeryConfig = {
    header: 'Add Surgery',
    width: '600px'
  };

  public editSurgeryConfig = {
    header: 'Edit Surgery',
    width: '600px'
  };

  public deleteConfirmDialogVisibility: boolean = false;

  public deleteConfirmConfig = {
    header: 'Confirm Delete',
    width: '400px'
  };

  ngOnInit() {
    this.loadSurgeries();
    
    const providerTypes = this.providerTypeService.getAllProviderTypes();
    providerTypes.forEach((type: any) => {
      this.providerTypeMap[type.id] = type.description;
    });

    this.surgeriesGridTemplateSettings = {
      columns: [
        { field: 'id', visible: false },
        { field: 'caseFileId', visible: false },
        { field: 'surgeryType.Description', headerText: 'Surgery Type', allowEditing: false },
        { field: 'provider', headerText: 'Provider', allowEditing: false, visible: false },
        { field: 'surgeon', headerText: 'Surgeon', allowEditing: false },
        {
          field: 'surgeryDate',
          headerText: 'Surgery Date',
          format: 'MM/dd/yyyy',
          type: 'date'
        },
        { field: 'surgeryCostEstimate', headerText: 'Surgery Cost Estimate', type: 'number', format: 'C2' },
        {
          field: 'missingInvoices',
          headerText: 'Missing Invoices',
          width: 125,
          valueAccessor: (field: string, data: any) => formatBoolean(data[field])
        },
        {
          field: 'invoicesConfirmed',
          headerText: 'Invoices Confirmed',
          width: 125,
          valueAccessor: (field: string, data: any) => formatBoolean(data[field])
        },
        { field: 'confirmedBy', headerText: 'Confirmed By' },
        {
          type: 'commands',
          commands: this.surgeriesGridTemplateManageColumnCommands,
          headerText: 'Actions',
          width: 85
        },
      ],
      toolbarClick: ($event: any) => this.customToolbarClick($event),
      commandClick: ($event: CommandClickEventArgs) => this.commandClick($event),
      rowSelected: ($event: any) => {
        this.onSurgerySelect($event);
      },
      selectionSettings: {
        type: 'Single',
        mode: 'Row',
        checkboxOnly: false,
        persistSelection: false,
        enableToggle: false,
        allowColumnSelection: false
      },
      allowGrouping: false,
      enableHover: true,
      allowTextWrap: true,
      enableAltRow: true,
      rowHeight: 45,
      toolbar: this.surgeriesGridDefaultToolbar,
      gridLines: 'Both',
      detailTemplate: '#detailtemplate',
      detailDataBound: this.onDetailDataBound.bind(this),
      childGrid: {
        queryString: 'surgeryId'
      }
    };
  }

  ngAfterViewInit() {
    this.surgeriesGridTemplate.grid.dataBound.subscribe(() => {
      const firstSurgery = this.getFirstSurgery();
      if (firstSurgery) {
        this.selectedSurgery = firstSurgery.surgery;
        this.selectedSurgerySignal.set(firstSurgery.surgery);
        this.surgeryProgressMap.set(firstSurgery.surgery.id, firstSurgery.progress);
        this.currentProgress = firstSurgery.progress;
        this.surgerySelected.emit(firstSurgery);
      }
    });
  }

  onAddFormSubmitted() {
    this.addSurgeryDialogVisibility = false;
    this.surgeriesGridTemplate.refresh();
    this.loadSurgeries();
  }

  onEditFormSubmitted() {
    this.editSurgeryDialogVisibility = false;
    this.surgeriesGridTemplate.refresh();
    this.loadSurgeries();
  }

  getCommentValues(field: string, data: object): string[] {
    return (data as any)[field] ? (data as any)[field as string] : [''];
  }

  commandClick(commandClickArgs: CommandClickEventArgs) {
    const rowData = commandClickArgs.rowData as any;

    if (commandClickArgs.commandColumn?.buttonOption?.iconCss === 'e-icons e-edit') {
      commandClickArgs.cancel = true;
      this.editSurgeryDialogVisibility = true;
      this.surgerySignal.set(rowData as Surgery);

      if (this.surgerySignal() && this.editSurgeryDialog) {
        this.editSurgeryDialog.show();
      }
    } else if (commandClickArgs.commandColumn?.buttonOption?.iconCss === 'e-icons e-delete') {
      this.selectedSurgery = rowData;
      this.selectedSurgerySignal.set(rowData);
      this.deleteConfirmDialogVisibility = true;
    }
  }

  onSurgerySelect(event: any): void {
    const surgeryId = event.data.id;
    const progress = this.surgeryProgressMap.get(surgeryId) || {
      selectedSegments: Array(5).fill(false),
      currentStep: 0
    };
    this.surgerySelected.emit({
      surgery: event.data,
      progress: progress
    });
  }

  updateSurgeryProgress(surgeryId: number, progress: SurgeryProgress): void {
    this.surgeryProgressMap.set(surgeryId, progress);
  }

  getFirstSurgery(): any {
    const surgeries = this.surgeriesGridTemplate.grid.getCurrentViewRecords();
    if (surgeries && surgeries.length > 0) {
      const surgery = surgeries[0] as any;
      return {
        surgery: surgery,
        progress: this.surgeryProgressMap.get(surgery.id) || { selectedSegments: [], currentStep: 0 }
      };
    }
    return null;
  }

  getProviderTypeSums(data: any): string {
    let totalSum = 0;

    if (data.InvoiceRows) {
      data.InvoiceRows.forEach((row: any) => {
        totalSum += row.TotalDueProvider || 0;
      });
    }

    return `$${totalSum.toLocaleString('en-US', { minimumFractionDigits: 2 })}`;
  }

  async onDetailDataBound(args: any): Promise<void> {
    const rowData = args.data;
    const detailDiv = document.createElement('div');
    detailDiv.setAttribute('id', `detail-div-${rowData.id}`);

    const invoices = await this.surgeryDetailService.getInvoicesForSurgery(this.caseFileId!);
    const surgeryDate = new Date(rowData.SurgeryDate);
    const filteredInvoices = this.surgeryDetailService.filterInvoicesByDate(invoices.result, surgeryDate);

    const groupedInvoices = this.groupInvoicesByProviderType(filteredInvoices, surgeryDate);
    const tableRows = this.generateTableRows(groupedInvoices);
    const amountBilled = this.calculateTotalSum(groupedInvoices);

    detailDiv.innerHTML = this.generateDetailContent(tableRows, amountBilled);
    ((args.detailElement as HTMLElement).querySelector('.custom-details') as HTMLElement).appendChild(detailDiv);
  }

  private groupInvoicesByProviderType(invoices: any[], surgeryDate: Date): any {
    const allProviderTypes = this.providerTypeService.getAllProviderTypes();
    const groupedInvoices = invoices.reduce((acc: any, invoice: any) => {
      const providerTypeId = invoice.SurgicalInvoiceId;
      if (!acc[providerTypeId]) {
        acc[providerTypeId] = {
          providerTypeDescription: this.providerTypeService.getProviderTypeDescription(providerTypeId),
          providerName: invoice.Provider?.Name || 'Unknown',
          amountBilled: 0,
          invoiceDate: invoice.InvoiceDate ? new Date(invoice.InvoiceDate).toLocaleDateString() : 'N/A',
          isDateMismatch: false
        };
      }
      acc[providerTypeId].amountBilled += invoice.InvoiceRows.reduce((sum: number, row: any) => sum + (row.AmountBilled || 0), 0);

      if (invoice.InvoiceDate && new Date(invoice.InvoiceDate).toLocaleDateString() !== surgeryDate.toLocaleDateString()) {
        acc[providerTypeId].isDateMismatch = true;
      }

      return acc;
    }, {});

    // Ensure all provider types are included
    allProviderTypes.forEach((type: any) => {
      if (!groupedInvoices[type.id]) {
        groupedInvoices[type.id] = {
          providerTypeDescription: type.description,
          providerName: 'N/A',
          amountBilled: 0,
          invoiceDate: 'N/A',
          isDateMismatch: false
        };
      }
    });

    return groupedInvoices;
  }

  private generateTableRows(groupedInvoices: any): string {
    let tableRows = '';
    for (const key in groupedInvoices) {
      const item = groupedInvoices[key];
      const rowStyle = item.isDateMismatch
        ? 'background-color: rgba(255, 193, 7, 0.1) !important; border-color: rgba(255, 193, 7, 0.2) !important;'
        : '';
      tableRows += `
        <tr style="${rowStyle}">
          <td style="width: 15%;">${item.providerTypeDescription}</td>
          <td style="width: 45%;">${item.providerName}</td>
          <td style="width: 25%;">${item.invoiceDate}</td>
          <td style="width: 15%;">${item.amountBilled.toLocaleString('en-US', { minimumFractionDigits: 2 })}</td>
        </tr>
      `;
    }
    return tableRows;
  }

  private calculateTotalSum(groupedInvoices: any): number {
    return Object.values(groupedInvoices).reduce((sum: number, item: any) => sum + item.amountBilled, 0);
  }

  private generateDetailContent(tableRows: string, amountBilled: number): string {
    return `
      <div class="detail-container">
        <table class="detail-table" style="width: 50%;">
          <thead>
            <tr>
              <th>Provider Type</th>
              <th>Provider</th>
              <th>Date of Service</th>
              <th>Sum of Invoices</th>
            </tr>
          </thead>
          <tbody>
            ${tableRows}
            <tr class="total-row">
              <td colspan="3"><b>Total</b></td>
              <td><b>$${amountBilled.toLocaleString('en-US', { minimumFractionDigits: 2 })}</b></td>
            </tr>
          </tbody>
        </table>
      </div>`;
  }

  private async loadSurgeries() {
    if (!this.caseFileId) {
      this.toast.showError('No case file selected');
      return;
    }

    const query = new Query()
      .where('caseFileId', 'equal', this.caseFileId).expand('SurgeryType');

    try {
      const response: any = await this.api.getOdata(APIEndpoints.Surgeries)
        .executeQuery(query);

      if (this.surgeriesGridTemplate?.grid) {
        this.surgeriesGridTemplate.grid.dataSource = response.result;
        this.surgeriesGridTemplate.grid.refresh();
      }
    } catch (error) {
      this.toast.showError('Error loading surgeries');
      console.error('Error loading surgeries:', error);
    }
  }

  closeDialog(): void {
    this.addSurgeryDialogVisibility = false;
  }

  customToolbarClick(args: any): void {
    if (args.item.text === 'Add') {
      args.cancel = true;
      this.addSurgeryDialogVisibility = true;

      if (this.addSurgeryDialog) {
        this.addSurgeryDialog.show();
      }
    }

    if (args.item.id === 'EditSurgery') {
      this.testDialogVisible = true;
    }
  }

  closeEditDialog(): void {
    this.editSurgeryDialogVisibility = false;
  }

  closeDeleteDialog(): void {
    this.deleteConfirmDialogVisibility = false;
    this.selectedSurgery = null;
    this.selectedSurgerySignal.set(undefined);
  }

  async confirmDelete(): Promise<void> {
    const currentValue = this.selectedSurgerySignal();
    this.selectedSurgery = currentValue;
    if (this.selectedSurgery) {
      try {
        await this.api.deleteOdata(`${APIEndpoints.Surgeries}/${this.selectedSurgery.Id}`);
        await this.loadSurgeries();
        this.toast.showSuccess('Surgery deleted successfully');
        this.closeDeleteDialog();
      } catch (error) {
        this.toast.showError('Error deleting surgery');
        console.error('Error deleting surgery:', error);
      }
    }
  }
}