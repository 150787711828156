import { formatDate } from '@angular/common';

/**
 * Safely formats a date string to MM/DD/YYYY format without timezone conversion issues.
 * Extracts the date parts directly from the string instead of creating a Date object.
 */
export function safeFormatDateToMMDDYYYY(dateStr: string | null | undefined): string {
  if (!dateStr) return 'N/A';

  try {
    // Extract the YYYY-MM-DD part
    const parts = dateStr.split('T')[0].split('-');
    if (parts.length !== 3) return 'N/A';

    // Format as MM/DD/YYYY
    return `${parts[1]}/${parts[2]}/${parts[0]}`;
  } catch {
    return 'N/A';
  }
}

export function capitalizeFirstLetter(text: string): string {
  return text
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

export function formatBoolean(value: number | boolean): string {
  if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No';
  }
  return value === 1 ? 'Yes' : 'No';
}
