// Angular
import { Component, computed, effect, EventEmitter, HostListener, Input, Output, Signal,  } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

// 3rd Party
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { TextBoxAllModule, MaskedTextBoxAllModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownListAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { TooltipAllModule } from '@syncfusion/ej2-angular-popups';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// Models
import { Surgery } from '@models/data-contracts';
import { APIEndpoints } from '@models/api/Endpoints';

// Services
import { FormCrudService } from '@services/forms/form-crud.service';
import { ApiService } from '@services/api/api.service';
import { ToastMessageService } from '@root/src/app/shared/services/toast-message/toast-message.service';
import { Query } from '@syncfusion/ej2-data';
import { DatePickerAllModule } from '@syncfusion/ej2-angular-calendars';

// Type & Interface setup
type SurgeryFormControls = {
  [K in keyof Surgery]: FormControl<Surgery[K] | null>;
};

export type FormResult = {
  status: 'success' | 'warning' | 'error';
  message: string;
} | undefined;

@Component({
  selector: 'surgery-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    TextBoxAllModule,
    MaskedTextBoxAllModule, 
    DropDownListAllModule,
    TooltipAllModule,
    FontAwesomeModule,
    DatePickerAllModule,
  ],
  templateUrl: './surgery-form.component.html',
  styleUrls: ['./surgery-form.component.scss']
})
export class SurgeryFormComponent {

  constructor(
    private formCRUD: FormCrudService,
    private api: ApiService,
    private toast: ToastMessageService
  ) { 

     effect((onCleanup) => {
      const { currentValue } = this.formState();
      if (currentValue) this.surgeryForm.patchValue(currentValue, { emitEvent: false });
      this.loadingForm = false;

      // Cleanup previous subscriptions
      onCleanup(() => this.surgeryForm.reset());
    });
  }

  // Decorator variables
  providerTypes: any;
  providerTypeFields: Object;
  surgeryTypes: any;
  surgeryTypeFields: Object;
  @Input() caseFileId?: number;
  @Input() surgery!: Signal<Surgery | undefined>;
  @Input() submitType?: 'POST' | 'PATCH' | 'DELETE';
  @Input() displayFields: string[] = ['SurgeryDate', 'Surgeon', 'Provider', 'SurgeryType', 'SurgeryCostEstimate'];
  @Input() displayButtons: boolean = false;
  @Output() formResult: EventEmitter<FormResult> = new EventEmitter<FormResult>();  

  @Output() formSubmitted = new EventEmitter<void>();
  @Output() formCancelled = new EventEmitter<void>();


  async getProviderTypes() {
    const query = new Query();
    this.providerTypes = this.api.getOdata(APIEndpoints.ProviderTypes);

    this.providerTypes.executeQuery(query).then((result: any) => {
        this.providerTypes = result.result;
    }).catch((error: any) => {
        console.error('Error fetching provider types:', error);
    });
    this.providerTypeFields = { text: 'Description', value: 'Id' };
  }

  getSurgeryTypes() {
    const query = new Query();
    this.surgeryTypes = this.api.getOdata(APIEndpoints.SurgeryTypes);

    this.surgeryTypes.executeQuery(query).then((result: any) => {
        this.surgeryTypes = result.result;
    }).catch((error: any) => {
        console.error('Error fetching surgery types:', error);
    });
    this.surgeryTypeFields = { text: 'Description', value: 'Id' };
  }

  // Form state
  protected readonly formState = computed(() => {
    const currentSurgery = this.surgery();
    return {
      isValid: this.surgeryForm.valid,
      hasChanges: currentSurgery !== this.surgeryForm.value,
      currentValue: currentSurgery
    };
  });

  // Use computed for form validation messages
  protected readonly errorMessages = computed(() => {
    const errors: Record<string, string> = {};
    Object.keys(this.surgeryForm.controls).forEach(key => {
      const control = this.surgeryForm.get(key);
      if (control?.errors) errors[key] = this.getErrorMessage(key);
    });
    return errors;
  });

  // Public variables
  loadingForm: boolean = true;
  errorMessage: string = '';
  surgeryHTMLElement: Element | null = null;
  surgeryForm = new FormGroup<SurgeryFormControls>({
    'Id': new FormControl(undefined),
    'CaseFileId': new FormControl(undefined),
    'SurgeryDate': new FormControl(undefined, [Validators.required]),
    'Surgeon': new FormControl(undefined, [Validators.required]),
    'ProviderId': new FormControl(undefined, [Validators.required]),
    'SurgeryTypeId': new FormControl(undefined, [Validators.required]),
    'SurgeryCostEstimate': new FormControl(undefined, [Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/)])
  });

  formClasses = {
    formContainer: 'cc-form-container',
    form: 'cc-form flex-column',
    section: 'cc-form-section',
    group: 'cc-form-group row',
    inputContainer: 'cc-input-container col-12',
    label: 'cc-label',
    input: 'cc-input',
    icon: 'cc-input-icon',
    error: 'cc-input-error',
    actions: 'cc-form-actions'
  };

  ngOnInit() {
    this.getProviderTypes();
    this.getSurgeryTypes();
    
  }

  ngAfterViewInit() {
    this.surgeryHTMLElement = document.querySelector('surgery-form');
    this.updateWidth(this.surgeryHTMLElement?.clientWidth || window.innerWidth);
    setTimeout(() => this.watchInputElements(), 500);
  }

  // On form submit
  async onSubmit() {
    let submitResults: any;
    if (this.surgeryForm.invalid) throw new Error('Form is invalid');

    const submitType = this.submitType;
    const endpoint = this.submitType == 'POST'? `${APIEndpoints.Surgeries}` : `${APIEndpoints.Surgeries}/${this.surgery()?.Id}`;

    // Check for valid form first
    if (this.surgeryForm.invalid) {
      submitResults = 'error';
      this.loadingForm = false;
      this.formResult.emit(submitResults);
      return submitResults;
    }

    // Check if form has changes
    if (this.surgeryForm.pristine) {
      submitResults = 'no-change';
      this.loadingForm = false;
      this.formResult.emit(submitResults);
      return submitResults;
    }
    console.log('SURGERY FORM', this.surgeryForm.value);
    console.log('SURGERY SUBMIT TYPE', submitType);

    this.surgeryForm.patchValue({
      CaseFileId: this.caseFileId
    });

    
    return await this.formCRUD.submitForm(this.surgeryForm, `odata${endpoint}`, submitType).then((res) => {
      if (res instanceof Error) throw new Error('Failed to create surgery');
   
      this.formSubmitted.emit();
      return res;
    })
  
  }

  // Perform updates on input elements
  watchInputElements() {
    document.querySelectorAll(`.cc-input-container`).forEach((inputContainer) => {
      const container = inputContainer as HTMLElement;

      container.querySelectorAll('input, span').forEach((input) => {
        input.addEventListener('focus', () => inputContainer.classList.add('focus'));
        input.addEventListener('blur', () => inputContainer.classList.remove('focus'));
      });
    });
  }

  // Switches form to 2 columns when parent is larger 1600px
  updateWidth(containerWidth: number) {

    if (containerWidth > 768) {
      this.formClasses.form = this.formClasses.form.replace('flex-column', 'flex-row flex-wrap');
    }  else {
      this.formClasses.form = this.formClasses.form.replace('flex-row', 'flex-column');
    }
  }

  // Update the layout on window resize
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    const containerWidth = this.surgeryHTMLElement?.clientWidth || window.innerWidth;
    this.updateWidth(containerWidth);
  }
  

  // Returns appropriate error message for form control
  getErrorMessage(controlName: string): string {
    let message = '';
    const control = this.surgeryForm.get(controlName);
    if (control?.errors) {
      if (control.errors['required']) message = 'This field is required';
      if (control.errors['pattern']) message = 'Invalid cost estimate (numeric value required)';
      if (control.errors['serverError']) message = control.errors['serverError'].message;
    };
    this.errorMessage = message;
    return message;
  }
}