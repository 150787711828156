import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { TabModule } from '@syncfusion/ej2-angular-navigations';
import { AccordionModule } from '@syncfusion/ej2-angular-navigations';
import { AccountDetailsComponent } from '@components/shared/account-details/account-details.component';
import { ActivatedRoute } from '@angular/router';
import { ContactsTabComponent } from '@components/shared/account-details/tabs/contacts-tab/contacts-tab.component';
import { SpecialInstructionsTabComponent } from '@components/shared/account-details/tabs/special-instructions-tab/special-instructions-tab.component';
import { TabItem } from '@components/shared/account-details/tabs/tabs.interface';
import { Subscription } from 'rxjs';
import { LawFirmStore } from '@components/features/law-firm/data/law-firm.store';
import { APIEndpoints } from '@shared/models/api/Endpoints';
import { FormGroup } from '@angular/forms';
import { editLawFirmForm } from '@shared/constants/form-groups/law-firm';
import { lawFirmQuery } from '@shared/queries/law-firm-queries';
import { LoadingModule } from '@shared/modules/loading.module';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-law-firm-detail',
  standalone: true,
  imports: [
    CommonModule,
    BrowserModule,
    TabModule,
    AccordionModule,
    AccountDetailsComponent,
    LoadingModule
  ],
  templateUrl: './law-firm-detail.component.html',
  styleUrls: ['./law-firm-detail.component.scss'],
  providers: [DatePipe]
})
export class LawFirmDetailComponent implements OnInit, OnDestroy {

  APIEndpoints = APIEndpoints; // Public variable needed by HTML
  lawFirmData: any;
  expandableDetails: Array<{ label: string, value: any }> = [];
  isLoading = true;
  lawFirmDetails: Array<Array<{ label: string, value: any }>> = [];
  lawFirmForm: FormGroup = editLawFirmForm;
  caseManagerName$ = new BehaviorSubject<string>('N/A');
  marketManagerName$ = new BehaviorSubject<string>('N/A');
  tabs: TabItem[] = [
    {
      id: 'contacts',
      text: 'Contacts',
      component: ContactsTabComponent
    },
    {
      id: 'specialInstructions',
      text: 'Special Instructions',
      component: SpecialInstructionsTabComponent
    }
  ];

  private isLoading$ = new Subscription();
  private lawFirm$ = new Subscription();
  private userNames$ = new Subscription();

  constructor(
    private datePipe: DatePipe,
    private lawFirmStore: LawFirmStore,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    const id = Number(this.route.snapshot.paramMap.get('id'));

    this.isLoading$ = this.lawFirmStore.isLoading(id)
      .subscribe(loading => this.isLoading = loading);

    this.lawFirm$ = this.lawFirmStore.getLawFirm(id)
      .subscribe(lawFirm => {
        if (lawFirm) {
          this.lawFirmData = lawFirm;

          // Fetch user names when law firm data arrives
          if (lawFirm.CaseManager) {
            this.lawFirmStore.fetchUserName(lawFirm.CaseManager);
            this.userNames$.add(
              this.lawFirmStore.getUserName(lawFirm.CaseManager)
                .subscribe(name => {
                  if (name) this.caseManagerName$.next(name);
                })
            );
          }

          if (lawFirm.MarketManager) {
            this.lawFirmStore.fetchUserName(lawFirm.MarketManager);
            this.userNames$.add(
              this.lawFirmStore.getUserName(lawFirm.MarketManager)
                .subscribe(name => {
                  if (name) this.marketManagerName$.next(name);
                })
            );
          }

          this.setupLawFirmDetails();
        }
      });

    const query = lawFirmQuery;
    this.lawFirmStore.fetchLawFirm(id, query);
  }

  ngOnDestroy() {
    this.isLoading$.unsubscribe();
    this.lawFirm$.unsubscribe();
    this.userNames$.unsubscribe();
  }

  private setupLawFirmDetails() {
    const lawFirmData = this.lawFirmData;
    const address = this.lawFirmData?.XrefAddressLawfirms?.[0]?.Address ?? {};
    const stateName = address.StateNavigation?.Name ?? 'N/A';
    const phone = this.lawFirmData?.XrefPhoneLawfirms?.[0]?.Phone ?? {};

    this.expandableDetails = [
      { label: 'Name', value: lawFirmData?.Name ?? 'N/A' },
      { label: 'Phone', value: phone?.PhoneNumber ?? 'N/A' },
      { label: 'City', value: address.City ?? 'N/A' },
      { label: 'Market Manager', value: this.marketManagerName$.value },
      { label: 'Case Manager', value: this.caseManagerName$.value }
    ];

    this.lawFirmDetails = [
      [
        { label: 'Account Type', value: address.ObjectType ?? 'N/A' },
        { label: 'Name', value: lawFirmData?.Name ?? 'N/A' },
        { label: 'Main Phone', value: phone.PhoneNumber ?? 'N/A' },
        { label: 'Main Fax', value: lawFirmData?.MainFax ?? 'N/A' },
        { label: 'Created At', value: this.datePipe.transform(lawFirmData?.CreatedAt, 'MM/dd/yyyy') ?? 'N/A' }
      ],
      [
        { label: 'Address', value: `${address.Address1 ?? ''}, ${address.Address2 ?? ''}`.trim() },
        { label: 'City', value: address.City ?? 'N/A' },
        { label: 'State', value: stateName },
        { label: 'Zip', value: address.Zip ?? 'N/A' },
        { label: 'Modified At', value: this.datePipe.transform(lawFirmData?.UpdatedAt, 'MM/dd/yyyy') ?? 'N/A' }
      ],
      [
        { label: 'Website', value: lawFirmData?.WebsiteUrl ?? 'N/A' },
        { label: 'Abbreviation', value: lawFirmData?.Abbreviation ?? 'N/A' },
        { label: 'Rating', value: lawFirmData?.Rating ?? 'N/A' },
        { label: 'Market Manager', value: this.marketManagerName$.value },
        { label: 'Case Manager', value: this.caseManagerName$.value }
      ]
    ];
  }
}