<ejs-dialog #Modal width="1200px" header="Bulk Edit Remittances" [isModal]="true" [showCloseIcon]='true'
    [visible]="false" (beforeOpen)="beforeModalOpened($event)">
    <ng-template #content>
        <form [formGroup]="editForm" (ngSubmit)="submit()" class="d-flex flex-column search-form">
            <div class="row">
                <div class="col-6">
                    <app-dropdown-single id="id" name="PaymentMethod" placeholder="Payment Method"
                        [endpoint]=paymentMethod [form]="editForm"></app-dropdown-single>
                </div>
                <div class="col-6">
                    <app-dropdown-single id="id" name="PaymentStatus" placeholder="Payment Status"
                        [endpoint]=paymentStatus [form]="editForm"></app-dropdown-single>
                </div>
                <div class="col-6">
                    <ejs-datepicker required id="DatePaid" formControlName="DatePaid" floatLabelType="Auto"
                        placeholder="Date Paid"></ejs-datepicker>
                </div>
                <div class="col-6">
                    <ejs-textbox required id="TransactionNumber" formControlName="TransactionNumber"
                        floatLabelType="Auto" placeholder="Transaction Number"></ejs-textbox>
                </div>
                <div class="col-12">
                    <ejs-textbox required id="InvoiceNotes" formControlName="InvoiceNotes" floatLabelType="Auto"
                        placeholder="Invoice Notes"></ejs-textbox>
                </div>
            </div>
            <div class="col-12"><spacer-element></spacer-element></div>
            <div class="col-10"> </div>
            <div class="col-2 form-submit ">
                <button ejs-button class="e-primary e-flat" type="submit" isPrimary="true">Submit</button>
            </div>
        </form>
    </ng-template>
</ejs-dialog>