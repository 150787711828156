import { Injectable } from '@angular/core';
import { Query } from '@syncfusion/ej2-data';
import { BaseGridService } from '@shared/components/base-grid/services/state.service';
import { APIEndpoints } from '@models/api/Endpoints';
import { ApiService } from '@services/api/api.service';
import { FinancialService } from '@services/financial/financial.service';

interface ODataResponse<T> {
  result: T[];
  count?: number;
  '@odata.count'?: number;
}

@Injectable({
  providedIn: 'root'
})
export class ReductionRequestsService extends BaseGridService {
  constructor(
    protected override api: ApiService,
    private financial: FinancialService
  ) {
    super();
  }

  getReductionRequestsEndpoint() {
    return this.api.getOdata(APIEndpoints.ReductionRequests);
  }

  getReductionRequestsQuery(skip: number, top: number) {
    return new Query()
      .where('Status', 'notEqual', 'Accepted')
      .where('Status', 'notEqual', 'Approved')
      .skip(skip)
      .take(top);
  }

  /**
   * Fetches reduction requests with pagination
   * @param pageSettings Pagination settings
   * @returns Reduction requests with count
   */
  async getReductionRequests(pageSettings: { skip: number; take: number } = { skip: 0, take: 20 }) {
    const query = new Query()
      .where('Status', 'notEqual', 'Accepted')
      .where('Status', 'notEqual', 'Approved')
      .skip(pageSettings.skip)
      .take(pageSettings.take)
      .requiresCount();

    const response = (await this.api.getOdata(APIEndpoints.ReductionRequests).executeQuery(query)) as unknown as ODataResponse<any>;

    if (!response || !response.result) throw new Error('Failed to fetch reduction requests.');

    const count = response.count || response['@odata.count'] || 0;

    return {
      result: response.result,
      count: count,
      aggregates: null,
      groupDs: null,
    };
  }

  /**
   * Fetches reduction requests with advanced filtering, sorting and pagination
   * @param state Grid state containing filters, sorting and pagination
   * @returns Reduction requests with count
   */
  async fetchWithStateChange(state: any) {
    if (!state.action) return null;

    const pageSettings = {
      skip: state.skip || 0,
      take: state.take || 20,
    };

    const query = new Query()
      .where('Status', 'notEqual', 'Accepted')
      .where('Status', 'notEqual', 'Approved')
      .skip(pageSettings.skip)
      .take(pageSettings.take)
      .requiresCount();

    // Apply filtering if present
    if (state.where) {
      query.where(state.where);
    }

    // Handle sorting
    if (state.sorted?.length) {
      state.sorted.forEach((sort: any) => {
        query.sortBy(sort.name, sort.direction.toLowerCase());
      });
    }

    const response = (await this.api.getOdata(APIEndpoints.ReductionRequests).executeQuery(query)) as unknown as ODataResponse<any>;
    const count = response.count || response['@odata.count'] || 0;

    return {
      result: response.result,
      count: count,
    };
  }

  // Separate form data API call
  getReductionRequestFormData(caseFileId: number) {
    return this.financial.getBalanceDue(caseFileId);
  }

  // Cache management
  private cache = new Map<string, any>();

  getCachedData(key: string) {
    return this.cache.get(key);
  }

  setCachedData(key: string, data: any) {
    this.cache.set(key, data);
  }
}
