import { Injectable } from '@angular/core';
import { DataManager, ODataV4Adaptor, Query } from '@syncfusion/ej2-data';
import { ApiService } from '@services/api/api.service';
import { APIEndpoints } from '@models/api/Endpoints';
import { EntityType } from '@shared/models/entity-types';
import { Comment, CommentCreateDTO, CommentThread } from '@shared/models/data-contracts';
import { UserPreferencesService } from '@services/user/user-preferences.service';

@Injectable({
  providedIn: 'root'
})
export class CommentsService {
  private readonly endpoint = APIEndpoints.Comments;
  private readonly dataManager: DataManager;
  private typesCache: any[] = [];

  constructor(
    private api: ApiService,
    private userPreferences: UserPreferencesService,
    private user: UserPreferencesService
  ) {}

  getComments(entityType: string, entityId: number) {
    if (!entityType || !entityId) {
      console.error('Invalid entity type or ID:', entityType, entityId);
      return Promise.resolve({ result: [] });
    }

    // Normalize entity type for xref table name
    const normalizedType = entityType.charAt(0).toUpperCase() + entityType.slice(1);
    const xrefTable = `XrefComments${normalizedType}s`;
    const idField = `${normalizedType}Id`;

    // Build OData query using dynamic xref relationship
    const query = new Query()
      .expand([
        'Type',
        'CreatedByNavigation',
        xrefTable
      ])
      .where(`${xrefTable}/any(x: x.${idField} eq ${entityId})`);

    console.log('Fetching comments with query:', query);
    return this.api.getOdata(APIEndpoints.Comments)
      .executeQuery(query);
  }

  getCommentTypes(): DataManager {
    return this.api.getOdata(APIEndpoints.ListCommentTypes);
  }

  async addComment(comment: CommentCreateDTO): Promise<Comment> {
    return this.api.fetchRequest(
      `odata${this.endpoint}`,
      'POST',
      comment
    );
  }

  async updateComment(id: number, updates: Partial<Comment>): Promise<Comment> {
    console.log('updating comment...');
    return this.api.fetchRequest(
      `odata${APIEndpoints.Comments}\(${id}\)`,
      'PATCH',
      updates
    );
  }

  async linkCommentToEntity(commentId: number, entityType: EntityType, entityId: number): Promise<void> {
    await this.api.fetchRequest(
      `odata${APIEndpoints[`XrefComments${entityType}s`]}`,
      'POST',
      {
        CommentId: commentId,
        [`${entityType}Id`]: entityId
      }
    );
  }

  async bulkUpdateComments(ids: number[], updates: Partial<Comment>): Promise<void> {
    await this.api.fetchRequest(
      `odata${this.endpoint}/BulkUpdate`,
      'POST',
      {
        ids,
        updates
      }
    );
  }

  async bulkUpdateCommentThreads(ids: number[], updates: Partial<CommentThread>): Promise<void> {
    await this.api.fetchRequest(
      `odata${APIEndpoints.CommentThreads}/BulkUpdate`,
      'POST',
      {
        ids,
        updates
      }
    );
  }

  getCurrentUserId(): number {
    return this.userPreferences.userId() || 0;
  }

  async createCommentThread(data: any): Promise<any> {
    return this.api.fetchRequest(
      `odata${APIEndpoints.CommentThreads}`,
      'POST',
      data
    );
  }

  async updateCommentThread(id: number, updates: Partial<CommentThread>): Promise<CommentThread> {
    return this.api.fetchRequest(
      `odata${APIEndpoints.CommentThreads}\(${id}\)`,
      'PATCH',
      updates
    );
  }
} 