import { Component, Input } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'base-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule
  ],
  templateUrl: './base-dialog.component.html',
  styleUrls: ['./base-dialog.component.scss']
})
export abstract class BaseDialogComponent {
  @Input() title: string = '';
  @Input() submitLabel: string = 'Submit';
  @Input() isSubmitDisabled: boolean = false;

  constructor(protected dialogRef: MatDialogRef<BaseDialogComponent>) {}

  close(): void {
    this.dialogRef.close();
  }

  cancel(): void {
    this.close();
  }

  abstract submit(): void;
} 
