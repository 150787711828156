// Angular
import { CommonModule } from '@angular/common';
import { Component, signal, ViewChild, DestroyRef, inject, computed, OnInit, AfterViewInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';

// 3rd Party
import { ListViewComponent, ListViewModule } from '@syncfusion/ej2-angular-lists';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faXmark, faXmarkCircle, faXmarksLines, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { MenuModule } from '@syncfusion/ej2-angular-navigations';

// Internal
import { FileViewService, OpenFile } from '@services/file-view/file-view.service';
import { UserPreferencesService } from '@services/user/user-preferences.service';
import { ErrorHandlingService } from '@core/error/error.service';

@Component({
  selector: 'app-open-file-menu',
  standalone: true,
  imports: [
    ListViewModule,
    CommonModule,
    FontAwesomeModule,
    RouterModule,
    MenuModule
  ],
  templateUrl: './open-file-menu.component.html',
  styleUrls: ['./open-file-menu.component.scss']
})
export class OpenFileMenuComponent implements OnInit, AfterViewInit {
  private userPrefs = inject(UserPreferencesService);
  private errorHandling = inject(ErrorHandlingService);
  private destroyRef = inject(DestroyRef);

  constructor(
    private router: Router,
    private fileView: FileViewService,
  ) {}

  // Decorators
  @ViewChild('casefilelist') caseFileList: ListViewComponent;
  @ViewChild('textbox') textbox: any;

  // Signals
  list = this.fileView.openFiles;

  // Public
  toggleLeftColumn = this.fileView.toggleLeftColumn;
  showColumn = this.fileView.showLeftColumn;
  closeIcon = faXmarkCircle;
  deleteIcon = faTrashAlt;

  // Use computed for menu items instead of effect
  protected menuItems = computed(() => {
    try {
      return this.userPrefs.isLoggedIn.value ? [
        {
          text: 'Open File',
          id: 'openFile',
          iconCss: 'e-icons e-file-open'
        },
        {
          text: 'Recent Files',
          id: 'recentFiles',
          iconCss: 'e-icons e-time'
        }
      ] : [];
    } catch (error) {
      this.errorHandling.handleError(error, 'OpenFileMenuComponent.menuItems');
      return [];
    }
  });

  ngOnInit(): void {
    // Subscribe to list changes
    this.destroyRef.onDestroy(() => {
      this.updateList();
    });
  }

  ngAfterViewInit(): void {
    this.updateList();
    this.selectListItem();
  }

  private updateList(): void {
    if (this.caseFileList) {
      this.caseFileList.dataSource = this.list();
      this.caseFileList.dataBind();
    }
  }

  // Handles searching and data binding from search
  handleSearch(): void {

    // Gets search terms from input text box
    const input_value = this.textbox.nativeElement.value;
    const column = this.isCaseNumber(input_value) ? 'id' : 'name';
    const predicate = this.isCaseNumber(input_value) ? input_value.toUpperCase() : input_value

    // Binds the search results to the list
    const query = new Query().where(column, 'contains', predicate, true);
    const data = new DataManager(this.list()).executeLocal(query);
    this.caseFileList.dataSource = input_value ? data : this.list().slice();
    this.caseFileList.dataBind();
  }

  // Determines if a string is a case number or a name
  isCaseNumber = (value: string) => value.split('').some((char) => char >= '0' && char <= '9');

  // Deletes list item on click
  deleteItem(args: any): void {
    args.preventDefault();
    args.stopPropagation();

    const listItem = args.target.closest('.list-item');
    const itemId = listItem?.id;

    if (itemId) {
      // Update the signal which will trigger the effect
      this.list.update(prev => {
        const filtered = prev.filter((item: any) => item.id !== itemId);
        // Save to preferences after filtering
        this.userPrefs.saveLocalUserPreference('openFiles', filtered);
        return filtered;
      });
    }
  }

  // Selects a list item on click and applies styles
  onClick(event: Event): void {
    //Tries to find a list item
    const clickedElement = event.target as HTMLElement;
    const listItem = clickedElement.closest('li');
    clickedElement.classList.add('e-active');

    // Applies style to a list item if it exists
    if (listItem) {
      const childElement = listItem.querySelector('.e-list-wrapper');
      if (childElement) childElement.classList.add('e-active');
      this.caseFileList.selectItem(listItem);
      this.toggleLeftColumn();
    }
  }

  // Selects item from url, apply styles, applys hash
  async onSelect(event: any): Promise<void> {
    try {
      const selectedItem = event.item;
      if (selectedItem?.id) {
        const selectedItems = this.caseFileList.getSelectedItems() as any;
        const { hash } = window.location;

        if (selectedItems && selectedItems.data.id !== this.fileView.getUrlInfo().middle) {
          await this.navigateToFile('/case-files/hub', { fileNumber: `${selectedItems.data.id || selectedItems.data.data[selectedItems.data.field]?.id}` }, hash.replace('#', ''));
        }
      }
    } catch (error) {
      this.errorHandling.handleError(error, 'OpenFileMenuComponent.onSelect');
    }
  }

  // Selects list item on load (Needs a timeout or caseFileList is undefined)
  selectListItem(): void {
    if (this.list().length > 1) {
      const selectedFile = this.getSelectedFile();
      setTimeout(() => {
        if (selectedFile) {
          const selectedElement = Array.from(this.caseFileList.element.querySelectorAll('li'))
            .find((element: any) => element.querySelector('.e-list-content')?.textContent === selectedFile.id);
          if (selectedElement) { this.caseFileList.selectItem(selectedElement); }
        }
      }, 0);
    }
  }

  // Helpers
  getSelectedFile(): OpenFile | null {
    const foundFile = this.list()?.find((file: OpenFile) => file.id === this.fileView.getUrlInfo().middle);
    return foundFile ?? null;
  }

  async navigateToFile(url: string, params: { [key: string]: any }, fragment?: string): Promise<void> {
    const queryString = new URLSearchParams(params).toString();
    const fullUrl = `${url}?${queryString}${fragment ? '#' + fragment : ''}`;

    await this.router.navigateByUrl(fullUrl, {
      replaceUrl: true,
      skipLocationChange: false
    });
  }
}
