    <div id="dashboard">
    
    <ejs-dashboardlayout #dashboardLayout 
        [columns]="columns"
        [cellSpacing]="cellSpacing" 
        [cellAspectRatio]="cellAspectRatio" 
        [allowDragging]="false"
        [allowFloating]="true"
        mediaQuery="max-width: 1100px">

        <e-panels>
            @for (card of cards; track card; let i = $index) {
                <e-panel 
                    [sizeX]="i % 2 === 0 ? 2 : 1" 
                    [sizeY]="1" 
                    [row]="card.row" 
                    [col]="card.column">
                    <div>
                        <ng-template #content>
                        @if (card?.templateSettings) {
                            <ng-container *ngTemplateOutlet="card.templateSettings?.template || null;  context: { $implicit: card }"></ng-container>
                        }
                        @if(card?.gridSettings) {
                            <card [cardSettings]="card"></card>
                        }
                        </ng-template>
                    </div>
                </e-panel>
            }
        </e-panels>
    </ejs-dashboardlayout>

</div>

<ng-template #tasksTemplate>
    <!-- Your tasks template content goes here -->
    <!-- For example: -->
    <div>
      <div class="task-filters">
        <button (click)="setActiveTaskFilter('all')">All</button>
        <button (click)="setActiveTaskFilter('overdue')">Overdue ({{getTaskCount('overdue')}})</button>
        <button (click)="setActiveTaskFilter('upcoming')">Upcoming ({{getTaskCount('upcoming')}})</button>
      </div>
    </div>
  </ng-template>