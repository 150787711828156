<div id="providers-grid" class="providers-grid-container">
    <base-grid #providersGrid [settings]="providersGridSettings">
        <!-- Provider Form Modal -->
        <div class="dialog-container">
            <ejs-dialog #providerDialog 
                [visible]="false"
                [isModal]="true"
                [showCloseIcon]="true"
                [closeOnEscape]="true"
                [buttons]="providerDialogButtons">
                <ng-template #content>
                    <ng-container>
                        <provider-form #providerFormComponent 
                            [provider]="selectedProvider"
                            [displayButtons]="false">
                        </provider-form>
                    </ng-container>
                </ng-template>
            </ejs-dialog>
        </div>

        <!-- Audit Logs Modal -->
        <div class="dialog-container">
            <ejs-dialog #logsDialog 
                [header]="'Provider Logs'"
                [buttons]="logsDialogButtons"
                [target]="'#providers-grid'"
                [isModal]="true"
                [visible]="false"
                [width]="'600px'"
                [showCloseIcon]="true">
                <ng-template #content>
                    <div class="dialog-content logs-dialog-content">
                        <app-audit-logs 
                            [logs]="auditLogs"
                            [loading]="auditLogsLoading">
                        </app-audit-logs>
                    </div>
                </ng-template>
            </ejs-dialog>
        </div>

        <!-- Bulk Edit Providers Component -->
        <app-bulk-edit-providers #bulkEditProvidersComponent></app-bulk-edit-providers>
    </base-grid>
</div>
