import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LawFirm } from '@models/data-contracts';

@Component({
  selector: 'bulk-edit-law-firms',
  standalone: true,
  imports: [CommonModule],
  template: ''
})
export class BulkEditLawFirmsComponent {
  selectedRows: LawFirm[] = [];
  
  showModal() {
    // TODO: Implement modal display logic
  }
} 