// Angular
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

// 3rd Party
import { TooltipAllModule } from '@syncfusion/ej2-angular-popups';

// Internal
import { Theme } from '@models/services/user-preferences.model';
import { ThemeService } from '@services/theme/theme.service';
import { UserPreferencesService } from '@services/user/user-preferences.service';
import { ThemeColorPickerComponent } from './theme-color-picker/theme-color-picker.component';

@Component({
  selector: 'theme-picker',
  standalone: true,
  imports: [
    TooltipAllModule,
    ThemeColorPickerComponent,
    CommonModule,
],
  templateUrl: './theme-picker.component.html',
  styleUrl: './theme-picker.component.scss'
})
export class ThemePickerComponent {

  constructor(
    private user: UserPreferencesService,
    private theme: ThemeService
  ) {}

  themes: any[] = this.theme.themes;

  selectTheme(theme: Theme) {

    // Apply the new theme
    switch (theme.Id) {
      case 'default': this.theme.resetTheme(); break;
      default: this.theme.setTheme(theme.PrimaryColor, theme.SecondaryColor, theme.TertiaryColor); break;
    }

    // Save and update theme
    this.user.saveTheme(theme.Id);
    this.user.getLogo({ theme: theme.Id });
  }

  bodyGradient(color1: string, color2: string) {
    return `background: linear-gradient(to right, ${color1}, ${color2});`;
  }
}
