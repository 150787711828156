import { Injectable } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { WritableSignal, signal, computed } from '@angular/core';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { APIEndpoints } from '@models/api/Endpoints';
import { AuthenticatedServiceBase } from '@core/auth/auth.base';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import {
  FeeSchedule,
  ProcedureCode,
  XrefFeeScheduleProcedureCode,
  XrefFeeScheduleProcedureCodeCreateDTO,
  XrefFeeScheduleProcedureCodeUpdateDTO,
  XrefFeeScheduleProcedureCodeDeleteDTO,
  XrefFeeScheduleProcedureCodeSyncFusionDTO,
  ProviderSyncFusionDTO,
  Provider,
  ProviderUpdateDTO,
  ProviderCreateDTO,
  ProviderDeleteDTO
} from '@models/data-contracts';

interface ODataResponse<T> {
  result: T[];
  count?: number;
}

interface ODataSingleResponse<T> {
  result: T;
}

interface BulkCptCodeChanges {
  cptCodeChanges: XrefFeeScheduleProcedureCodeUpdateDTO[],
  cptCodeAdd: XrefFeeScheduleProcedureCodeCreateDTO[],
  cptCodeDelete: XrefFeeScheduleProcedureCodeDeleteDTO[]
}

interface BulkProviderChanges {
  providerChanges: ProviderUpdateDTO[],
  providerAdd: ProviderCreateDTO[],
  providerDelete: ProviderDeleteDTO[]
}

interface ProcedureCodeChange {
  ProcedureCodeId: number;
  BilledAmount: number;
  SplitInvoice: boolean;
  FrontEndReimbursmentRate: number;
  BackEndReimbursmentRate: number;
  Id?: number;  // Only present for existing codes
}

@Injectable({
  providedIn: 'root'
})
export class FeeScheduleService extends AuthenticatedServiceBase {
  protected override readonly endpoint = APIEndpoints.FeeSchedules;
  private _procedureCodes = signal<any[]>([]);

  // Add these methods
  

  constructor(
    private toast: ToastMessageService
  ) {
    super();
  }

  procedureCodes = computed(() => this._procedureCodes());



  async getProcedureCodes(): Promise<void> {
    try {
      const response = await this.api.getOdata(APIEndpoints.ProcedureCodes).executeQuery(new Query());
      const typedResponse = response as unknown as ODataResponse<any>;
      if (typedResponse && typedResponse.result) {
        this._procedureCodes.set(typedResponse.result);
      }
    } catch (error) {
      console.error('Error loading procedure codes:', error);
      this.toast.showError('Failed to load procedure codes');
    }
  }

  async getFeeSchedule(id: string): Promise<any> {
    try {
      const response = await this.api.getOdata(`${APIEndpoints.FeeSchedules}(${id})?$expand=ProcedureCodes`).executeQuery(new Query());
      const typedResponse = response as unknown as ODataSingleResponse<any>;
      return typedResponse.result;
    } catch (error) {
      console.error('Error loading fee schedule:', error);
      this.toast.showError('Failed to load fee schedule');
      throw error;
    }
  }

  async createFeeSchedule(formData: Partial<FeeSchedule>, procedureCodesForm: FormGroup, providers: any[]): Promise<void> {
    try {
      // Create the fee schedule first
      const response = await this.api.fetchRequest('odata' + APIEndpoints.FeeSchedules, 'POST', formData);
      const feeSchedule = response as FeeSchedule;

      // For create, all procedure codes go to Added
      const procedureCodeBatch: XrefFeeScheduleProcedureCodeSyncFusionDTO = {
        Action: 'Create',
        Added: procedureCodesForm.get('ProcedureCodes')?.value
          .filter((entry: any) => entry.Code !== null)
          .map((entry: any) => ({
            FeeScheduleId: feeSchedule.Id,
            ProcedureCodeId: entry.Code,
            BilledAmount: entry.BilledAmount,
            SplitInvoice: entry.SplitInvoice,
            FrontEndReimbursmentRate: (entry.FrontEndReimbursmentRate || 0) / 100,
            BackEndReimbursmentRate: (entry.BackEndReimbursmentRate || 0) / 100
          })),
        Changed: [],
        Deleted: []
      };

      await this.api.fetchRequest('odata' + APIEndpoints.XrefFeeScheduleProcedureCodes + '/batch', 'POST', procedureCodeBatch);

      // Create provider associations
      if (providers.length > 0) {
        const providerBatch: ProviderSyncFusionDTO = {
          Action: 'Create',
          Added: [],
          Changed: providers.map(provider => ({
            Id: provider.Id,
            FeeScheduleId: feeSchedule.Id
          })),
          Deleted: []
        };

        await this.api.fetchRequest('odata' + APIEndpoints.ProvidersBulk, 'POST', providerBatch);
      }

      this.toast.showSuccess('Fee Schedule created successfully');
    } catch (error) {
      this.toast.showError('Error creating Fee Schedule');
      throw error;
    }
  }

  async updateFeeSchedule(
    formData: Partial<FeeSchedule>,
    cptCodeBatchData: BulkCptCodeChanges,
    providerBatchData: BulkProviderChanges
  ): Promise<void> {
    try {
      console.log(formData);
      console.log(cptCodeBatchData);
      // Update the fee schedule using PATCH
      await this.api.fetchRequest(`odata${APIEndpoints.FeeSchedules}(${formData.Id})`, 'PATCH', {
        Id: formData.Id,
        Name: formData.Name
      });

      // Handle provider associations using batch
      const providerBatch: ProviderSyncFusionDTO = {
        Action: 'Update',
        Added: [],
        Changed: providerBatchData.providerChanges,
        Deleted: []
      };

      let shouldUpdateProviders: Boolean = false;
      if (providerBatch.Changed != undefined && providerBatch.Changed != null) {
        if (providerBatch.Changed.length > 0) {
          shouldUpdateProviders = true;
        }
      }

      if (shouldUpdateProviders) {
        await this.api.fetchRequest('odata' + APIEndpoints.ProvidersBulk, 'POST', providerBatch);
      }

      // Use tracked changes for procedure codes
      const procedureCodeBatch: XrefFeeScheduleProcedureCodeSyncFusionDTO = {
        Action: 'Update',
        Added: cptCodeBatchData.cptCodeAdd,
        Changed: cptCodeBatchData.cptCodeChanges,
        Deleted: cptCodeBatchData.cptCodeDelete
      };

      let shouldUpdateCpt: Boolean = false;
      if (procedureCodeBatch.Added != undefined && procedureCodeBatch.Added != null) {
        if (procedureCodeBatch.Added.length > 0) {
          shouldUpdateCpt = true;
        }
      }

      if (procedureCodeBatch.Changed != undefined && procedureCodeBatch.Changed != null) {
        if (procedureCodeBatch.Changed.length > 0) {
          shouldUpdateCpt = true;
        }
      }

      if (procedureCodeBatch.Deleted != undefined && procedureCodeBatch.Deleted != null) {
        if (procedureCodeBatch.Deleted.length > 0) {
          shouldUpdateCpt = true;
        }
      }

      if (shouldUpdateCpt) {
        await this.api.fetchRequest('odata' + APIEndpoints.XrefFeeScheduleProcedureCodes + '/batch', 'POST', procedureCodeBatch);
      }

      this.toast.showSuccess('Fee Schedule updated successfully');
    } catch (error) {
      console.error('Error updating fee schedule:', error);
      this.toast.showError('Error updating Fee Schedule');
      throw error;
    }
  }
}
