import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { WorkItemsService } from '../../services/work-items.service';
import { APIEndpoints } from '@models/api/Endpoints';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { TextBoxAllModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownListAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { DatePickerAllModule } from '@syncfusion/ej2-angular-calendars';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { signal } from '@angular/core';
import { effect } from '@angular/core';
import { EventEmitter, Output } from '@angular/core';
import { FormCrudService } from '@services/forms/form-crud.service';
import { faClipboardList, faHeading, faAlignLeft, faListCheck, faExclamation, faLayerGroup, faFolder, faCalendarAlt, faUserTie, faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { LoadingSpinnerComponent } from '@shared/components/loading-spinner/loading-spinner.component';
import { Query } from '@syncfusion/ej2-data';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { FormBuilder } from '@angular/forms';
import { MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';

@Component({
  selector: 'work-item-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    TextBoxAllModule,
    DropDownListAllModule,
    DatePickerAllModule,
    FontAwesomeModule,
    LoadingSpinnerComponent,
    DialogModule,
    MultiSelectModule
  ],
  templateUrl: './work-item-form.component.html',
  styleUrls: ['./work-item-form.component.scss']
})
export class WorkItemFormComponent implements OnInit {
  @Input() workItem = signal<any>(undefined);
  @Input() displayButtons = true;  // Add back this input
  @Input() visible = false;  // Control dialog visibility
  @Output() visibleChange = new EventEmitter<boolean>();  // Two-way binding
  @Output() formSubmitted = new EventEmitter<void>();
  @Output() formCancelled = new EventEmitter<void>();
  @Input() inCaseFileContext = false;
  @Input() caseFileId?: number;

  loading = signal(true);
  workItemForm: FormGroup;

  formClasses = {
    form: 'cc-form flex-column',
    inputContainer: 'cc-input-container',
    actions: 'cc-form-actions',
    icon: 'cc-input-icon'
  };

  formIcon = faClipboardList;
  formIcons = {
    title: faHeading,
    description: faAlignLeft,
    status: faListCheck,
    priority: faExclamation,
    type: faLayerGroup,
    category: faFolder,
    dueDate: faCalendarAlt,
    assignedTo: faUserTie,
    caseFiles: faFolderOpen,
    provider: faUserTie
  };

  statusesDataManager = this.workItemsService.getStatusesDataManager();
  prioritiesDataManager = this.workItemsService.getPrioritiesDataManager();
  typesDataManager = this.workItemsService.getTypesDataManager();
  categoriesDataManager = this.workItemsService.getCategoriesDataManager();
  usersDataManager = this.workItemsService.getUsersDataManager();
  providersDataManager = this.workItemsService.getProvidersDataManager();

  submitting = false;

  // Dialog settings
  public dialogSettings = {
    header: 'Add New Task',
    width: '600px',
    isModal: true,
    showCloseIcon: true,
    closeOnEscape: true,
    buttons: [
      { click: () => this.formCancelled.emit(), buttonModel: { content: 'Cancel', cssClass: 'e-flat' }},
      { click: () => this.onSubmit(), buttonModel: { content: 'Save', isPrimary: true }}
    ]
  };

  caseFiles: any[] = [];
  selectedCaseFiles: number[] = [];
  caseFilesDataManager: any;

  showProviderField = false;

  originalCaseFileIds: number[] = [];
  originalProviderId?: number;

  // Add new property to store filtered types
  filteredTypes: any[] = [];

  constructor(
    private workItemsService: WorkItemsService,
    private formCrud: FormCrudService,
    private fb: FormBuilder
  ) {
    this.initForm();
    this.caseFilesDataManager = this.workItemsService.getCaseFilesDataManager();
    
    // Watch for category changes and update types
    this.workItemForm.get('CategoryId')?.valueChanges.subscribe(async categoryId => {
      if (categoryId) {
        await this.checkProviderVisibility(categoryId);
        await this.updateTypesByCategory(categoryId);
      } else {
        // Clear types when no category is selected
        this.filteredTypes = [];
        this.workItemForm.get('TypeId')?.setValue(null);
      }
    });

    // Move effect logic to separate method
    effect(() => {
      const item = this.workItem();
      if (item) {
        this.updateFormWithWorkItem(item);
      }
    });
  }

  private initForm() {
    this.workItemForm = this.fb.group({
      Id: new FormControl<number | null>(null),
      Title: new FormControl('', Validators.required),
      Description: new FormControl(''),
      StatusId: new FormControl<number | null>(null, Validators.required),
      PriorityId: new FormControl<number | null>(null, Validators.required),
      TypeId: new FormControl<number | null>(null, Validators.required),
      CategoryId: new FormControl<number | null>(null, Validators.required),
      AssignedTo: new FormControl<number | null>(null),
      DueDate: new FormControl(null),
      caseFileIds: [[]],
      ProviderId: [null]
    });
  }

  private async initDataManagers() {
    // Initialize data managers and wait for them to load
    await Promise.all([
      this.statusesDataManager.executeQuery(new Query()),
      this.prioritiesDataManager.executeQuery(new Query()),
      this.typesDataManager.executeQuery(new Query()),
      this.categoriesDataManager.executeQuery(new Query()),
      this.usersDataManager.executeQuery(new Query()),
      this.caseFilesDataManager.executeQuery(new Query())
        .then((response: any) => {
          this.caseFiles = response.result;
        }),
      this.providersDataManager.executeQuery(new Query())
    ]);
  }

  async ngOnInit() {
    await this.initDataManagers();
    
    const item = this.workItem();
    if (item) {
      const formValues = {
        Id: item.Id,
        Title: item.Title,
        Description: item.Description,
        StatusId: Number(item.Status?.Id || item.StatusId),
        PriorityId: Number(item.Priority?.Id || item.PriorityId),
        TypeId: Number(item.Type?.Id || item.TypeId),
        CategoryId: Number(item.Category?.Id || item.CategoryId),
        AssignedTo: item.AssignedTo,
        DueDate: item.DueDate ? new Date(item.DueDate) : null,
        caseFileIds: item.XrefWorkItemCaseFiles?.map((x: any) => x.CaseFile?.Id || x.CaseFileId) || [],
        ProviderId: Number(item.XrefWorkItemProviders?.[0]?.Provider?.Id || 
                         item.XrefWorkItemProviders?.[0]?.ProviderId)
      };

      this.workItemForm.patchValue(formValues);
      
      await this.checkProviderVisibility(formValues.CategoryId);
    } else {
      // Set defaults for new work item
      await this.setDefaultValues();
    }

    this.loading.set(false);
  }

  async setDefaultValues() {
    try {
      this.loading.set(true);
      this.showProviderField = false;
      const [defaultStatus, defaultPriority, defaultCategory] = await Promise.all([
        this.workItemsService.getDefaultStatus(),
        this.workItemsService.getDefaultPriority(),
        this.workItemsService.getDefaultCategory()
      ]);

      this.workItemForm.patchValue({
        StatusId: defaultStatus?.Id,
        PriorityId: defaultPriority?.Id,
        CategoryId: defaultCategory?.Id
      });
    } catch (error) {
      console.error('Error setting default values:', error);
    } finally {
      this.loading.set(false);
    }
  }

  onCaseFilesChange(event: any) {
    this.workItemForm.patchValue({ caseFileIds: event.value });
  }

  async onSubmit() {
    if (this.submitting) return;
    
    console.log('Form Values:', this.workItemForm.value);
    if (this.workItemForm.valid) {
      try {
        this.submitting = true;
        const formValue = this.workItemForm.value;
        const id = this.workItemForm.get('Id')?.value;

        console.log('Submitting with ID:', id);
        let result;
        if (id) {
          // Edit case
          formValue.originalCaseFileIds = this.originalCaseFileIds;
          formValue.originalProviderId = this.originalProviderId;
          console.log('Updating work item with data:', {
            ...formValue,
            XrefWorkItemCaseFiles: formValue.caseFileIds?.map((id: number) => ({ CaseFileId: id })) || [],
            XrefWorkItemProviders: formValue.ProviderId ? [{ ProviderId: formValue.ProviderId }] : []
          });
          try {
            result = await this.workItemsService.updateWorkItem(id, {
              ...formValue,
              XrefWorkItemCaseFiles: formValue.caseFileIds?.map((id: number) => ({ CaseFileId: id })) || [],
              XrefWorkItemProviders: formValue.ProviderId ? [{ ProviderId: formValue.ProviderId }] : []
            });
            console.log('Update result:', result);
          } catch (error) {
            console.error('Error in updateWorkItem:', error);
            throw error;
          }
        } else {
          // Add case (keep existing logic)
          result = await this.workItemsService.createWorkItem({
            ...formValue,
            XrefWorkItemCaseFiles: formValue.caseFileIds?.map((id: number) => ({ CaseFileId: id })) || [],
            XrefWorkItemProviders: formValue.ProviderId ? [{ ProviderId: formValue.ProviderId }] : []
          });
        }

        this.formSubmitted.emit();
        return result;
      } catch (error) {
        console.error('Work item form submission error:', error);
        return null;
      } finally {
        this.submitting = false;
      }
    }
    return null;
  }

  private async checkProviderVisibility(categoryId: number) {
    if (!categoryId) return;
    
    try {
      const categories = await this.workItemsService.getCategories();
      const selectedCategory = categories.find(c => c.Id === categoryId);
      
      // Set visibility based on category name - include both Provider Management and Provider Agreement
      const showProviderField = selectedCategory?.Name === 'Provider Management' || 
                              selectedCategory?.Name === 'Provider Agreement';
      this.showProviderField = showProviderField;
      
      // If provider field should not be visible, clear the provider value
      if (!showProviderField) {
        this.workItemForm.get('ProviderId')?.setValue(null);
      }
    } catch (error) {
      console.error('Error checking provider visibility:', error);
    }
  }

  private async updateFormWithWorkItem(item: any) {
    // Handle case files
    if (item.XrefWorkItemCaseFiles?.length) {
      const caseFileIds = item.XrefWorkItemCaseFiles
        .map((x: any) => Number(x.CaseFile?.Id || x.CaseFileId))
        .filter((id: number) => id);
      this.selectedCaseFiles = caseFileIds;
      this.originalCaseFileIds = [...caseFileIds];
    }

    // Store original provider ID
    this.originalProviderId = item.XrefWorkItemProviders?.[0]?.ProviderId;

    // Prepare form values
    const formValues = {
      Id: item.Id,
      Title: item.Title,
      Description: item.Description,
      StatusId: Number(item.Status?.Id || item.StatusId),
      PriorityId: Number(item.Priority?.Id || item.PriorityId),
      TypeId: Number(item.Type?.Id || item.TypeId),
      CategoryId: Number(item.Category?.Id || item.CategoryId),
      AssignedTo: item.AssignedTo,
      DueDate: item.DueDate ? new Date(item.DueDate) : null,
      caseFileIds: item.XrefWorkItemCaseFiles?.map((x: any) => x.CaseFile?.Id || x.CaseFileId) || [],
      ProviderId: Number(item.XrefWorkItemProviders?.[0]?.Provider?.Id || 
                       item.XrefWorkItemProviders?.[0]?.ProviderId)
    };

    // Update form and check provider visibility
    this.workItemForm.patchValue(formValues);
    await this.checkProviderVisibility(formValues.CategoryId);
  }

  async setDefaultCategory(categoryName: string) {
    try {
      const categories = await this.workItemsService.getCategories();
      const category = categories.find(c => c.Name === categoryName);
      if (category) {
        this.workItemForm.patchValue({ CategoryId: category.Id });
      }
    } catch (error) {
      console.error('Error setting default category:', error);
    }
  }

  // Add new method to update types based on category
  private async updateTypesByCategory(categoryId: number) {
    try {
      const currentTypeId = this.workItemForm.get('TypeId')?.value;
      
      // Get all types
      const types = await this.workItemsService.getTypes();
      
      // Filter types by category
      this.filteredTypes = types.filter(type => type.CategoryId === categoryId);
      
      // Only reset type if it doesn't belong to the new category
      if (currentTypeId && !this.filteredTypes.some(type => type.Id === currentTypeId)) {
        this.workItemForm.get('TypeId')?.setValue(null);
      }
      
    } catch (error) {
      console.error('Error updating types by category:', error);
    }
  }
} 