import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApiDeduplicationService {
  // Keep pending requests but remove the cache
  private pendingRequests = new Map<string, Promise<any>>();

  async getOrFetchData<T>(cacheKey: string, fetchFn: () => Promise<T>): Promise<T> {
    // Only check for pending requests, no cache check
    if (this.pendingRequests.has(cacheKey)) {
      return this.pendingRequests.get(cacheKey) as Promise<T>;
    }

    // Create new request
    const request = fetchFn()
      .then(result => {
        this.pendingRequests.delete(cacheKey);
        return result;
      })
      .catch(error => {
        this.pendingRequests.delete(cacheKey);
        throw error;
      });

    this.pendingRequests.set(cacheKey, request);
    return request;
  }

  // Keep this method for clearing pending requests if needed
  clearPendingRequests(key?: string) {
    if (key) {
      this.pendingRequests.delete(key);
    } else {
      this.pendingRequests.clear();
    }
  }
} 