import { CanActivateFn, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { UserPreferencesService } from '@services/user/user-preferences.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(
    private settings: UserPreferencesService,
    private router: Router,
  ) { }

  canActivate: CanActivateFn = async (route, state) => {
    try {
      await this.settings.getUserSession().then((result) => {
        if ((result as any).userSub === undefined) {
          this.router.navigate(['/']);
          console.log(Error("User not logged in"))
          return false
        }
        return true;
      })
      return true;
    } catch (err) {
      this.router.navigate(['/']);
      console.log(err)
      return false
    }
  }
}