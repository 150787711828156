// Angular
import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

// 3rd Party
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';
import { Query } from '@syncfusion/ej2-data';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { Deposit } from '@models/data-contracts';
import { ApiService } from '@services/api/api.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { AddDepositForm } from '@forms/add-forms/add-deposit/add-deposit.component';
import { BulkEditDepositsForm } from '@root/src/app/components/forms/bulk-edit-forms/bulk-edit-deposits/bulk-edit-deposits.component';
import { BaseGridComponent } from '@shared/components/base-grid/base-grid.component';
import { BaseGridService } from '@shared/components/base-grid/services/state.service';
import { SetGridDataArgs } from '@shared/components/base-grid/models/grid.models';
import { BaseGridHooks } from '@shared/components/base-grid/services/hooks.service';
import { ComponentBase } from '@core/base/component.base';
import { ErrorSeverity } from '@core/error/error.types';
import { BaseGrid } from '@core/base/grid.base';
import { DepositsService } from '../../services/deposits.service';

// Error constants
const ERRORS = {
  BULK_EDIT_SELECTION: {
    message: 'Please select at least two deposits to bulk edit.',
    technical: 'Attempted bulk edit with insufficient selection'
  }
};

@Component({
  selector: 'deposits-grid',
  standalone: true,
  imports: [
    CommonModule,
    DialogModule,
    BaseGridComponent,
    AddDepositForm,
    BulkEditDepositsForm
  ],
  providers: [
    BaseGridHooks,
    BaseGridService,
    DepositsService
  ],
  templateUrl: './deposits-grid.component.html',
  styleUrl: './deposits-grid.component.scss'
})
export class DepositsGridComponent extends BaseGrid {
  // ViewChild references
  @ViewChild('depositsGrid') depositsGrid!: BaseGridComponent;
  @ViewChild('addDepositDialog') addDepositDialog!: DialogComponent;
  @ViewChild('newDepositForm') newDepositForm!: AddDepositForm;
  @ViewChild('bulkEditForm') bulkEditForm!: BulkEditDepositsForm;
  @ViewChild('bulkEditDepositsDialog') bulkEditDepositsDialog!: DialogComponent;

  // Getter for endpoint to ensure type safety
  get depositsEndpoint(): string {
    return this.depositsService.ENDPOINT;
  }

  // Grid settings
  readonly depositsGridSettings: SetGridDataArgs = {
    endpoint: this.depositsService.ENDPOINT,
    name: 'Deposits',
    useRoundedEdges: true,
    bulkEditing: true,
    query: new Query().expand(this.depositsService.DEFAULT_EXPAND),
    gridSettings: {
      columns: [
        { type: 'checkbox' },
        { field: 'Id' },
        { field: 'CaseFile.FileNumber', headerText: 'File Number', allowEditing: false },
        { field: 'CaseFile.Patient.Lastname', headerText: 'Client Last Name', allowEditing: false },
        { field: 'CaseFile.Patient.Firstname', headerText: 'Client First Name', allowEditing: false },
        { field: 'DepositDate', headerText: 'Date Received', editType: 'datePickerEdit' },
        { field: 'DepositAmount', headerText: 'Amount' },
        { field: 'FinalCheck', headerText: 'Final Check' },
        { field: 'Notes' },
        {
          type: 'commands',
          headerText: 'Actions',
          commands: [
            { title: 'View', buttonOption: { iconCss: 'e-icons e-eye', cssClass: 'e-flat' } },
            { title: 'Edit', type: 'Edit', buttonOption: { iconCss: 'e-icons e-edit', cssClass: 'e-flat' } }
          ]
        }
      ],
      toolbarClick: ($event: any) => this.onToolbarClick($event),
      commandClick: ($event: any) => this.onCommandClick($event)
    }
  };

  // Dialog buttons
  readonly addDepositButtons: Object[] = [
    { click: this.closeDialog.bind(this), buttonModel: { content: 'Cancel', cssClass: 'e-outline' } },
    { click: this.clearNewDepositForm.bind(this), buttonModel: { content: 'Reset', isPrimary: false } },
    { click: this.onNewDepositSubmit.bind(this), buttonModel: { content: 'Submit', isPrimary: true, cssClass: 'e-primary' } }
  ];

  readonly bulkUpdateDepositsButtons: Object[] = [
    { click: this.closeDialog.bind(this), buttonModel: { content: 'Cancel', cssClass: 'e-outline' } },
    { click: this.clearBulkEditForm.bind(this), buttonModel: { content: 'Reset', isPrimary: false } },
    { click: this.onBulkEditSubmit.bind(this), buttonModel: { content: 'Submit', isPrimary: true, cssClass: 'e-primary' } }
  ];

  constructor(
    protected override api: ApiService,
    private toast: ToastMessageService,
    protected override router: Router,
    private gridService: BaseGridService,
    private depositsService: DepositsService
  ) {
    super();
  }

  onToolbarClick(args: any) {
    if (args.item.text === 'Add') {
      args.cancel = true;
      this.addDepositDialog.show();
    }

    if (args.item.id === 'BulkEdit') {
      args.cancel = true;
      this.getSelected();
    }
  }

  onCommandClick(args: any) {
    const data = args?.rowData;
    const btn = args.commandColumn?.buttonOption;

    if (data !== undefined) {
      this.depositsService.handleCommandClick(data, btn?.iconCss);
    }
  }

  closeDialog() {
    if (this.addDepositDialog) this.addDepositDialog.hide();
    if (this.bulkEditDepositsDialog) this.bulkEditDepositsDialog.hide();
  }

  clearNewDepositForm() {
    if (this.newDepositForm) {
      const form = this.newDepositForm.depositForm();
      form.reset();
    }
  }

  async onNewDepositSubmit() {
    if (!this.newDepositForm) return;

    const form = this.newDepositForm.depositForm();
    if (form.valid) {
      try {
        await this.depositsService.submitNewDeposit(form.value);
        this.closeDialog();
      } catch (error) {
        console.error('Error submitting new deposit:', error);
      }
    } else {
      console.log(form);
      this.toast.showError('Form invalid.');
    }
  }

  clearBulkEditForm() {
    if (this.bulkEditForm) {
      const form = this.bulkEditForm.bulkEditDepositsForm();
      form.reset();
    }
  }

  getSelected() {
    const selectedRecords = this.depositsGrid.grid.getSelectedRecords();
    if (selectedRecords && selectedRecords.length > 1) {
      this.bulkEditDepositsDialog.show();
    } else {
      this.handleError(new Error('Insufficient selection'), {
        context: 'DepositsGridComponent.getSelected',
        userMessage: ERRORS.BULK_EDIT_SELECTION.message,
        severity: ErrorSeverity.Warning
      });
    }
  }

  async onBulkEditSubmit() {
    if (!this.bulkEditForm) return;

    const form = this.bulkEditForm.bulkEditDepositsForm();
    if (form.valid) {
      try {
        const selectedRecords = this.depositsGrid.grid.getSelectedRecords();
        await this.depositsService.submitBulkEdit(selectedRecords, form.value);
        form.reset();
        this.closeDialog();
      } catch (error) {
        console.error('Error updating deposits:', error);
      }
    } else {
      console.error(form.errors);
      this.toast.showError('Please enter a valid form.');
    }
  }

  async caseFileNavigation(url: string, params: { [key: string]: any }, fragment?: string): Promise<void> {
    const { hash, ...queryParams } = params;
    const queryString = new URLSearchParams(queryParams).toString();
    const fullUrl = `${url}?${queryString}`;
    const fragmentHash = hash ? `#${hash}` : '';

    await this.router.navigateByUrl(fullUrl + fragmentHash, {
      replaceUrl: true,
      skipLocationChange: false
    });
  }
}
