import { Component, computed, inject } from '@angular/core';
import { Router } from '@angular/router';

import { Base } from './base';
import { ApiService } from '@services/api/api.service';
import { AuditLogService } from '@services/audit-logs/audit-log-service';

import { BaseGridHooks } from '@shared/components/base-grid/services/hooks.service';
import { BaseGridService } from '@shared/components/base-grid/services/state.service';
import { BaseGridComponent } from '@shared/components/base-grid/base-grid.component';

export abstract class BaseGrid extends Base {
  /**
   * Injections for BaseGrid
   *
   * Ensures each service is available for each instance of the BaseGridComponent
   */
  protected readonly api = inject(ApiService);
  protected readonly router = inject(Router);
  protected readonly audits = inject(AuditLogService);
  protected readonly hooks = inject(BaseGridHooks);

  /**
   * Constructor for BaseGrid
   *
   * Provides default functionality while allowing derived classes to override
   * by injecting their own dependencies.
   */
  constructor(
    protected baseGridComponent?: BaseGridComponent
  ) {
    super();
  }
}
