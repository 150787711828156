import { Component, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentBase } from '@core/base/component.base';
import { DialogComponent, DialogAllModule } from '@syncfusion/ej2-angular-popups';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-balance-statement-email',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    DialogAllModule
  ],
  templateUrl: './balance-statement-email.component.html',
  styleUrl: './balance-statement-email.component.scss'
})
export class BalanceStatementEmailComponent extends ComponentBase {
  @ViewChild('emailDialog') emailDialog: DialogComponent;

  fromEmail: string = '';
  toEmail: string = '';
  ccEmail: string = '';

  show(boxId: string, caseFileId: number, createdBy: string, createdByEmail: string,
    lawFirmEmail: string, caseManager: string, caseManagerEmail: string, description: string): void {
    this.fromEmail = createdByEmail;
    this.toEmail = lawFirmEmail;
    this.ccEmail = caseManagerEmail;
    this.emailDialog.show();
  }
}
