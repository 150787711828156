// Angular
import { Component, computed, effect, Input, signal, SimpleChanges, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';

// 3rd Party
import { GridModel } from '@syncfusion/ej2-angular-grids';

// Services
import { RecentActivityService } from '../../services/recent-activity.service';

// Components
import { LoadingModule } from '@modules/loading.module';
import { GridTemplateComponent } from '@components/grids/grid-template/grid-template.component';

@Component({
  selector: 'recent-activity-grid',
  standalone: true,
  imports: [
    CommonModule,
    LoadingModule,
    GridTemplateComponent
  ],
  templateUrl: './recent-activity-grid.component.html',
  styleUrl: './recent-activity-grid.component.scss'
})
export class RecentActivityGridComponent {

  // Decorators 
  @Input() caseFileId: number | undefined;

  // Signals
  protected readonly loadingData = computed(() => this.recentActivityGridService.loading.RecentActivity());
  
  // Public
  recentActivityGridSettings: GridModel;
  
  constructor(
    private recentActivityGridService: RecentActivityService
  ) {}

  // Ensure there is a case file ID before initializing the grid
  ngOnChanges(changes: SimpleChanges) {
    if (changes['caseFileId'] && this.caseFileId) {
      this.initializeGrid();
    }
  }

  async initializeGrid() {

    try {

      // Ensure there is a case file ID before initializing the grid
      if (!this.caseFileId) {
        const error = new Error('No case file ID provided');
        console.error(error);
        throw error;
      }

      const settings = await this.recentActivityGridService.notesTasksCombined(this.caseFileId).then((result: any) => {
        this.recentActivityGridSettings = {
          dataSource: result,
          sortSettings: { columns: [{ field: 'Date', direction: 'Descending' } ]},
          columns: [
            { field: 'Id' },
            { field: 'Date', headerText: 'Date' },
            { field: 'Activity', headerText: 'Activity' },
            { field: 'Title', headerText: 'Title' },
            { field: 'Notes', headerText: 'Notes' },
            { field: 'Owner', headerText: 'Owner' },
            { field: 'Category', headerText: 'Category' },
            { field: 'Type', headerText: 'Type' },
            { field: 'Status', headerText: 'Status' },
            { field: 'DueDate', headerText: 'Due Date' }, 
            { type: 'commands', headerText: 'Actions' }
          ]
        };
        this.recentActivityGridService.loading.RecentActivity.set(false);
        this.recentActivityGridService.data.RecentActivity.set(result);
      });

      return settings;

    } catch (error) {
      console.error('RecentActivityGrid: Error loading data', error);
      return error;

    } finally {
      this.recentActivityGridService.loading.RecentActivity.set(false);
      return;
    }
  }
}
