import { Injectable } from '@angular/core';
import { ApiService } from '@services/api/api.service';
import { Query } from '@syncfusion/ej2-data';
import { APIEndpoints } from '@models/api/Endpoints';

@Injectable({
  providedIn: 'root'
})
export class SurgeryDetailService {
  constructor(private api: ApiService) {}

  async getInvoicesForSurgery(caseFileId: number): Promise<any> {
    const query = new Query()
      .where('CaseFileId', 'equal', caseFileId)
      .expand(['InvoiceRows', 'Provider']);

    const response = await this.api.getOdata(APIEndpoints.Invoices).executeQuery(query);
    return response;
  }

  filterInvoicesByDate(invoices: any[], surgeryDate: Date): any[] {
    return invoices.filter((item: any) => {
      if (!item.InvoiceDate) return false;
      const invoiceDate = new Date(item.InvoiceDate);
      const timeDifference = Math.abs(invoiceDate.getTime() - surgeryDate.getTime());
      const dayDifference = timeDifference / (1000 * 3600 * 24);
      return dayDifference <= 1;
    });
  }

  calculateTotalDueProvider(invoiceRows: any[]): number {
    return invoiceRows.reduce((sum: number, row: any) => sum + (row.TotalDueProvider || 0), 0);
  }
} 