<base-form [displayButtons]="false">

  <div class="row">

    <div class="col-12">
      <form-field [icon]="icons.type" [control]="form.get('typeId')!" fieldName="Type">
        <ejs-dropdownlist
          formControlName="typeId"
          placeholder="Type *"
          [dataSource]="commentsService.getCommentTypes()"
          [fields]="{ text: 'Description', value: 'Id' }"
          cssClass="cc-input">
        </ejs-dropdownlist>
      </form-field>
    </div>

    <div class="col-12">
      <form-field [icon]="icons.comment" [control]="form.get('content')!" fieldName="Comment">
        <ejs-textbox
          formControlName="content"
          placeholder="Comment *"
          multiline="true"
          cssClass="cc-input">
        </ejs-textbox>
      </form-field>
    </div>
  </div>

</base-form>
