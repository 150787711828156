<div class="section financial-section">
  <div class="row">
    <div class="col-12 col-md-7 col-xl-5 position-relative">
      <tab-card class="tab-card" [heading]="'Financial Performance'">
        <div class="loading-container" *ngIf="loading()">
          <loading-overlay [loading]="loading" />
        </div>

        <div class="content-container" *ngIf="!loading() && performanceData(); else noData">
          <div class="performance-data d-flex flex-row flex-wrap">
            <div class="item d-flex flex-nowrap">
              <label>Total Billed Cost:</label>
              <span>{{performanceData()?.TotalBilledCost | currency}}</span>
            </div>
            <div class="item d-flex flex-nowrap">
              <label>Total Payments:</label>
              <span>{{performanceData()?.TotalPaymentReceived | currency}}</span>
            </div>
            <div class="item d-flex flex-nowrap">
              <label>Settlement Cost:</label>
              <span>{{performanceData()?.ActualSettlementCost | currency}}</span>
            </div>
            <div class="item d-flex flex-nowrap">
              <label>Balance Due:</label>
              <span>{{performanceData()?.BalanceDue | currency}}</span>
            </div>
            <div class="item d-flex flex-nowrap">
              <label>Authorized Amount:</label>
              <span>{{performanceData()?.AuthorizedAmount | currency}}</span>
            </div>
            <div class="item d-flex flex-nowrap">
              <label>Days Open:</label>
              <span>{{performanceData()?.DaysOpen}}</span>
            </div>
            <div class="item d-flex flex-nowrap">
              <label>Settlement Value:</label>
              <span>{{performanceData()?.FullSettlementValue | currency}}</span>
            </div>
            <div class="item d-flex flex-nowrap">
              <label>Profit:</label>
              <span>{{performanceData()?.Profit | currency}}</span>
            </div>
            <div class="item d-flex flex-nowrap">
              <label>Non-SOCAL Invoices:</label>
              <span>{{performanceData()?.NonSOCALInvoices ? (performanceData()?.NonSOCALInvoices | currency) : 'Unknown'}}</span>
            </div>
            <div class="item d-flex flex-nowrap">
              <label>ROIC:</label>
              <span>{{performanceData()?.ROIC}}</span>
            </div>
            <div class="item d-flex flex-nowrap">
              <label>XIRR:</label>
              <span>{{xirrValue !== null ? xirrValue.toFixed(4) : 'Insufficient transactions'}}</span>
            </div>
            <div class="item d-flex flex-nowrap">
              <label>Final Balance Generated?:</label>
              <span>{{performanceData()?.FinalBalanceDueGenerated}}</span>
            </div>
            <div class="item item-reduction">
              <div class="row d-flex flex-nowrap">
                <div class="col-6 d-flex">
                  <label>Reduction:</label>
                  <span>{{performanceData()?.CourtesyReduction | currency}}</span>
                </div>
                <div class="col-6 d-flex flex-row flex-nowrap justify-content-between">
                  <label>(or %):</label>
                  <span>{{performanceData()?.CourtesyReductionPercent}}</span>
                </div>
              </div>
            </div>
            <div class="item d-flex flex-nowrap">
              <label>Final FSV:</label>
              <span>{{performanceData()?.FinalFSV | currency}}</span>
            </div>
          </div>
        </div>

        <ng-template #noData>
          <div class="no-data" *ngIf="!loading()">
            No performance data available
          </div>
        </ng-template>
      </tab-card>
    </div>
  </div>
</div>
