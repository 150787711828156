// Angular
import { computed, inject, Injectable, signal, WritableSignal } from '@angular/core';

// 3rd Party
import { DataUtil, Query, Predicate, DataManager } from '@syncfusion/ej2-data';
import {
  GridComponent,
  GridModel,
  ColumnModel,
  SortSettingsModel,
  DataResult,
  CommandModel,
  DataStateChangeEventArgs,
  SearchSettingsModel,
  SortDescriptorModel,
} from '@syncfusion/ej2-angular-grids';

// Services
import { GridInitializationService } from './initialization.service';
import { BaseService } from './base.service';
import { BaseGridSignals } from './signals.service';

// Models
import {
  PredicateOperator,
  CustomToolbarItem,
  FieldHandler,
  FilterFieldOptions,
  GridQueryOptions,
  FilteringOptions,
  GridStates,
  SetGridDataArgs,
  GridDataResults,
  ToolbarItem,
  GridSearchSettings,
  ToolbarState,
  ODataResponse,
  DefaultGridProperties
} from '../models/grid.models';

/**
 * Base Grid Service
 *
 * Provides centralized state management and data operations for the Base Grid Component.
 * Handles data fetching, filtering, sorting, pagination, and error management.
 *
 * @implements {BaseService}
 */
@Injectable({
  providedIn: 'root',
})
export class BaseGridService extends BaseService {
  // Injections
  private readonly initialization = inject(GridInitializationService);
  protected readonly signals = inject(BaseGridSignals);

  // Computed Signals
  public readonly toolbar = this.signals.toolbar;
  public readonly gridData = this.signals.gridData;
  public readonly loadingData = this.signals.loadingData;
  public readonly currentQuery = this.signals.currentQuery;
  public readonly grid = this.signals.grid;
  public readonly pagination = this.signals.pagination;

  // Getters
  override get endpoint() {
    return this.signals.endpoint();
  }

  get pageSize() {
    return this.signals.pageSize();
  }

  private isPaginationInProgress = false;

  constructor() {
    super();
  }

  /**
   * Methods
   */
  async setGridData(args: SetGridDataArgs) {
    await this.initialization.initializeGrid(args);
    await this.getData(args.endpoint, args.query, args.pageSettings);
  }

  async getData(endpoint?: string, query?: Query, state?: DataStateChangeEventArgs) {
    try {
      // Check for required settings
      if (!endpoint) throw new Error('endpoint is required');

      // Set loading state for data
      this.signals.setLoading(true);

      // Build query with pagination
      const pageSize = state?.take ?? this.pageSize;
      const skip = state?.skip ?? 0;

      query = query ?? this.currentQuery() ?? new Query();

      // Clear existing pagination queries
      query.queries = query.queries.filter((q: any) => !q.fn || (q.fn !== 'onSkip' && q.fn !== 'onTake'));

      // Apply pagination
      query.skip(skip)
        .take(pageSize)
        .requiresCount();

      // Fetch data and build results
      const response = await (this.api.getOdata(endpoint).executeQuery(query)) as unknown as ODataResponse<any>;
      const count = response['@odata.count'] || response.count || 0;
      const transformData = this.signals.gridSetupArgs()?.transformData;
      const transformedData = transformData ? transformData(response.result) : response.result;

      // Return results
      const result =  {
        result: transformedData,
        count: count
      };

      // Update state
      this.signals.setEndpoint(endpoint);
      this.signals.setQuery(query);
      this.signals.setPagination(skip, pageSize);
      this.signals.setTotalRecords(count);
      this.grid()!.dataSource = result;

      return result;

    } catch (error) {
      console.error(error);
      this.handleError(error, {
        context: 'BaseGridService.fetchData',
        userMessage: 'Error fetching data',
        severity: this.ErrorSeverity.Error
      });
      throw error;
    } finally {
      this.signals.setLoading(false);
    }
  }

  async handleDataStateChanges(state: DataStateChangeEventArgs, endpoint?: string, query?: Query) {
    try {
      if (!state.action || this.loadingData()) return;

      // Get pagination settings from state
      const pageSettings = {
        skip: state.skip ?? 0,
        take: state.take ?? this.pageSize
      };

      // Create new query or use existing
      let updatedQuery = query ?? new Query();

      // Handle filtering first
      updatedQuery = this.handleFiltering(state, updatedQuery);

      // Handle search
      if (state.search && state.search.length > 0) {
        const searchSettings = state.search[0];
        // Get searchable fields from grid settings
        const searchableFields = this.signals.gridSetupArgs()?.searchableFields;
        // Use search fields from settings or searchable fields from grid settings
        const searchFields = searchSettings.fields || searchableFields || ['Id'];
        const searchValue = searchSettings.key || '';

        // Clear existing search queries
        updatedQuery.queries = updatedQuery.queries.filter((q: any) => !q.fn || q.fn !== 'onSearch');

        const searchPredicates = searchFields.map((field: string) => {
          const formattedField = field.replace(/\./g, '/');
          // Get column definition to check type
          const column = this.grid()?.columns.find(col =>
            typeof col === 'object' && 'field' in col && col.field === field
          ) as ColumnModel | undefined;

          // For numeric fields, use equal to match the exact number
          if (column?.type === 'number' || field === 'Id') {
            const numValue = Number(searchValue);
            if (!isNaN(numValue)) {
              return new Predicate(formattedField, 'equal', numValue, false);
            }
            return null; // Skip invalid numeric values
          }

          // For text fields, use contains
          return new Predicate(formattedField, 'contains', searchValue, true);
        }).filter((pred): pred is Predicate => pred !== null); // Remove any null predicates

        // Add search predicates to the existing query
        if (searchPredicates.length > 0) {
          updatedQuery = updatedQuery.where(Predicate.or(...searchPredicates));
        }
      }

      // Handle sorting
      if (state.sorted?.length) {
        updatedQuery = this.handleSorting(state, updatedQuery);
      } else if (!state.where || state.where.length === 0) {
        // Only apply default sort if there are no filters
        updatedQuery = this.handleDefaultSort(updatedQuery);
      }

      // Execute data fetch with updated query and state
      const result = await this.getData(endpoint, updatedQuery, state);

      // Update state with new data
      this.signals.setQuery(updatedQuery);
      this.signals.setResult(result);
      this.signals.setPagination(pageSettings.skip, pageSettings.take);
      this.signals.setTotalRecords(result.count);
      if (endpoint) {
        this.signals.setEndpoint(endpoint);
      }

    } catch (error) {
      console.error(error);
      this.handleError(error, {
        context: 'BaseGridService.handleDataStateChanges',
        userMessage: 'Error handling data state changes',
        severity: this.ErrorSeverity.Error
      });
      throw error;
    }
  }

  private handleFiltering(state: DataStateChangeEventArgs, query: Query, options: FilteringOptions = {}): Query {
    try {
      // Clear existing where clauses if no filters are present
      if (!state.where || state.where.length === 0) {
        // Clear only filter-related queries
        query.queries = query.queries.filter((q: any) => !q.fn || (q.fn !== 'onWhere' && q.fn !== 'onSortBy'));
        return query;
      }

      let mainPredicate: Predicate | null = null;

      state.where.forEach(filter => {
        filter.predicates?.forEach((pred: any) => {
          let currentPredicate: Predicate;
          const fieldHandler = options.fields?.[pred.field];

          if (fieldHandler) {
            currentPredicate = fieldHandler.handler(pred);
          } else {
            const field = pred.field.replace(/\./g, '/');
            currentPredicate = new Predicate(
              field,
              pred.operator || options.defaultOperator || 'contains',
              pred.value,
              options.caseSensitive ? false : pred.ignoreCase
            );
          }

          mainPredicate = mainPredicate ? mainPredicate.and(currentPredicate) : currentPredicate;
        });
      });

      if (mainPredicate) {
        query = query ?? this.currentQuery() ?? new Query();
        // Clear only filter-related queries
        query.queries = query.queries.filter((q: any) => !q.fn || (q.fn !== 'onWhere' && q.fn !== 'onSortBy'));
        query.where(mainPredicate);
      }
    } catch (error) {
      console.error(error);
      this.handleError(error, {
        context: 'BaseGridService.handleFiltering',
        userMessage: 'Error handling filtering',
        severity: this.ErrorSeverity.Error
      });
    }

    return query;
  }

  private handleSorting(state: DataStateChangeEventArgs, query: Query): Query {
    try {
      if (state.sorted?.length) {
        // Clear any existing sort parameters
        if (query.params) {
          delete (query as any).params['$orderby'];
        }

        // Apply new sorting
        state.sorted.forEach((sort: any) => {
          query.sortBy(sort.name, sort.direction.toLowerCase());
        });

        // Set orderby parameter manually
        if (!query.params) (query as any).params = {};
        (query as any).params['$orderby'] = state.sorted
          .map((s: any) => `${s.name} ${s.direction.toLowerCase() === 'ascending' ? 'asc' : 'desc'}`)
          .join(',');
      }
    } catch (error) {
      console.error(error);
      this.handleError(error, {
        context: 'BaseGridService.handleSorting',
        userMessage: 'Error handling sorting',
        severity: this.ErrorSeverity.Error
      });
    }

    return query;
  }

  private handleDefaultSort(query: Query): Query {
    try {
      // Clear any existing sort parameters
      if (query.params) {
        delete (query as any).params['$orderby'];
      }

      // Apply default sort
      query.sortBy('Id desc');

      // Set default orderby parameter
      if (!query.params) (query as any).params = {};
      (query as any).params['$orderby'] = 'Id desc';
    } catch (error) {
      console.error(error);
      this.handleError(error, {
        context: 'BaseGridService.handleDefaultSort',
        userMessage: 'Error applying default sort',
        severity: this.ErrorSeverity.Error
      });
    }

    return query;
  }

  async refreshData(): Promise<void> {
    try {
      const endpoint = this.endpoint;
      const query = this.currentQuery();
      const transformData = this.signals.gridSetupArgs()?.transformData;

      if (!endpoint || !query) throw new Error('Endpoint or query not set');

      // Add a small delay to prevent rapid consecutive calls
      await new Promise(resolve => setTimeout(resolve, 100));

      const result = (await this.getOdata().executeQuery(query)) as any;

      // Transform data if callback provided in setup args
      const transformedData = transformData ? transformData(result.result) : result.result;

      this.signals.setResult({
        result: transformedData,
        count: result['@odata.count'] ?? result.count ?? this.signals.totalRecords() ?? 0,
        aggregates: null,
        groupDs: null,
      });

      // Refresh grid if component is available
      if (this.grid()) this.grid()?.refresh();
    } catch (error) {
      this.signals.setError('refreshData', error);
      throw error;
    }
  }

  disableTimeZoneFn() {
    try {
      if (this.signals.disableTimeZone()) {
        (DataUtil as any).serverTimezoneOffset = 0;
        this.signals.setDisableTimeZone(false);
      }
    } catch (error) {
      this.signals.setError('disableTimeZone', error);
      throw error;
    }
  }

  updateUIState(width: number) {
    try {
      this.signals.setIsResponsive(width < 768);
      const gridId = this.grid()?.element.id;
      if (gridId) {
        this.signals.setGridId(gridId);
      }
    } catch (error) {
      this.signals.setError('updateUIState', error);
      throw error;
    }
  }

  filterToolbarItems(items: CustomToolbarItem[], excludeIds: string[]): ToolbarItem[] {
    return items
      .filter(item => {
        const id = typeof item === 'string' ? item : item.id;
        return !excludeIds.includes(id);
      })
      .map(item => {
        if (typeof item === 'string') {
          return {
            id: item,
            text: item,
            tooltipText: item
          };
        }
        return item;
      });
  }

  insertToolbarItem(item: string | ToolbarItem): void {
    if (this.grid()) {
      const toolbar = [...this.toolbar()];
      const insertPosition = Math.max(0, toolbar.length - 1);
      toolbar.splice(insertPosition, 0, item);
      this.signals.setToolbar(toolbar);
      this.signals.updateGridSettings((settings: GridModel | undefined) => ({
        ...settings,
        toolbar: toolbar,
      }));
    }
  }

  toggleActiveRecords(settings: GridModel, grid: GridComponent): void {
    if (this.signals.loadingData()) return;
    try {
      grid = grid ?? this.grid();
      if (!grid) return;

      // Show loading animation
      grid.showSpinner();
      this.signals.setLoading(true);
      
      // Toggle the ShowActive state
      const newShowActive = !this.signals.showActive();
      this.signals.setShowActive(newShowActive);
      
      // Get current pagination settings
      const pagination = this.signals.pagination();
      
      // Start with existing query or create new one
      const query = this.currentQuery() ?? new Query();
      
      // Clear any existing queries except pagination and expand
      query.queries = query.queries.filter((q: any) => 
        !q.fn || (q.fn !== 'onWhere' && q.fn !== 'onSkip' && q.fn !== 'onTake')
      );
      
      // Add IsActive filter
      query.where('IsActive', 'equal', newShowActive)
        .skip(pagination.skip)
        .take(pagination.take)
        .requiresCount();
      
      // Update the data query state
      this.signals.setQuery(query);
      
      // Update the grid settings with new query and command icons
      if (grid) {
        const commandColumn = grid.columns.find(col => (col as any).type === 'commands') as ColumnModel | undefined;

        if (commandColumn && commandColumn?.commands) {
          commandColumn.commands = commandColumn.commands.map(cmd => {
            if (cmd.type === 'None' && cmd.title === 'Toggle IsActive') {
              return {
                ...cmd,
                buttonOption: {
                  ...cmd.buttonOption,
                  iconCss: newShowActive ? 'e-icons e-circle-check' : 'e-icons e-circle-close'
                }
              };
            }
            return cmd;
          });
          grid.refreshColumns();
        }

        this.signals.updateGridSettings(settings => {
          if (!settings) return settings;
          return {
            ...settings,
            query,
          };
        });
      }

      // Update toolbar item text
      if (grid.toolbar) {
        const updatedToolbar = grid.toolbar.map(item => {
          if (typeof item === 'object' && 'id' in item && item.id === 'toggle-active') {
            return {
              ...item,
              text: newShowActive ? 'View Inactive' : 'View Active'
            } as ToolbarItem;
          }
          return item;
        }) as (string | ToolbarItem)[];
        
        // Update both grid toolbar and settings
        grid.toolbar = updatedToolbar;
        this.signals.setToolbar(updatedToolbar as CustomToolbarItem[]);
        this.signals.updateGridSettings(settings => ({
          ...settings,
          toolbar: updatedToolbar
        }));

        grid.refreshHeader();
      }

      // Refresh the grid data with new query
      this.getData(this.endpoint, query, pagination);
    } catch (error) {
      // Ensure loading animation is hidden on error
      grid?.hideSpinner();
      this.signals.setLoading(false);
      this.signals.setError('toggleActiveRecords', error);
      throw error;
    } finally {
      // Ensure loading state is cleared
      this.signals.setLoading(false);
      grid?.hideSpinner();
    }
  }

  updateEditState(mode: 'add' | 'edit' | 'delete' | undefined, record?: any) {
    try {
      this.signals.setEditMode(mode);
      this.signals.setEditRecord(record);
    } catch (error) {
      this.signals.setError('updateEditState', error);
      throw error;
    }
  }

  refreshGrid() {
    try {
      this.grid()?.refresh();
    } catch (error) {
      this.signals.setError('refreshGrid', error);
      throw error;
    }
  }

  clearErrors() {
    try {
      this.signals.clearErrors();
    } catch (error) {
      this.signals.setError('clearErrors', { message: 'Failed to clear errors', error });
      throw error;
    }
  }

  setError(key: string, error: any) {
    try {
      this.signals.setError(key, error);
    } catch (error) {
      this.signals.setError('setError', { message: 'Failed to set error', error });
      throw error;
    }
  }

  joinFieldsHandler(fields: string[]): FieldHandler {
    return {
      type: 'join',
      handler: (pred: any) => {
        return fields.reduce((acc: Predicate | null, field: string) => {
          const predicate = new Predicate(field.replace(/\./g, '/'), 'contains', pred.value, pred.ignoreCase);
          return acc ? acc.or(predicate) : predicate;
        }, null) as Predicate;
      },
    };
  }

  createBooleanFieldHandler(): FieldHandler {
    return {
      type: 'boolean',
      handler: (pred: any) => {
        const field = pred.field.replace(/\./g, '/');
        return new Predicate(field, 'equal', pred.value === true || pred.value === 'true', pred.ignoreCase);
      },
    };
  }

  addBulkEditToolbarItem(grid?: GridComponent) {
    try {
      grid = grid ?? this.grid();
      if (!grid) return;

      const selectedRecords = grid.getSelectedRecords();
      const currentToolbar = grid.toolbar ?? this.toolbar();
      const updatedToolbar = [...currentToolbar];

      // Remove bulk edit button if it exists
      const bulkEditIndex = updatedToolbar.findIndex(item =>
        typeof item === 'object' && (item as ToolbarItem).id === 'BulkEdit'
      );
      if (bulkEditIndex !== -1) {
        updatedToolbar.splice(bulkEditIndex, 1);
      }

      // Add bulk edit button if multiple records are selected
      if (selectedRecords && selectedRecords.length > 1) {
        const bulkEditButton = {
          text: 'Bulk Edit',
          tooltipText: 'Bulk Edit',
          id: 'BulkEdit',
          prefixIcon: 'e-properties-1',
        };
        updatedToolbar.push(bulkEditButton);
      }

      // Update grid toolbar
      grid.toolbar = updatedToolbar;

      // Update toolbar state
      this.signals.setToolbar(updatedToolbar as CustomToolbarItem[]);

      // Update grid settings
      this.signals.updateGridSettings(settings => ({
        ...settings,
        toolbar: updatedToolbar
      }));

    } catch (error) {
      console.error('Error updating bulk edit toolbar', error);
      this.handleError(error, {
        userMessage: 'Error updating bulk edit toolbar',
        context: 'BaseGridService',
        severity: this.ErrorSeverity.Error,
      });
      throw error;
    }
  }

  /**
   * Resets all service state to initial values
   * Used during component cleanup to prevent memory leaks
   */
  resetState(): void {
    try {
      this.signals.resetAll();
    } catch (error) {
      this.signals.setError('resetState', { message: 'Failed to reset grid state', error });
      // Not throwing here as this is called during cleanup
    }
  }
}
