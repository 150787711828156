// Angular
import { Component, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

// Syncfusion
import { GridComponent, CommandModel, GridModel, CommandClickEventArgs, RowSelectEventArgs, RowSelectingEventArgs, RowDeselectingEventArgs, EditEventArgs } from '@syncfusion/ej2-angular-grids';
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';

// 3rd Party
import { Query } from '@syncfusion/ej2-data';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { Deposit } from '@models/data-contracts';
import { GlobalsService } from '@services/globals/globals.service';
import { ApiService } from '@services/api/api.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { GridTemplateModule } from '@modules/grid-template.module';
import { GridTemplateComponent } from '@grids/grid-template/grid-template.component';
import { BulkEditDepositsForm } from '@root/src/app/components/forms/bulk-edit-forms/bulk-edit-deposits/bulk-edit-deposits.component';
import { ToolbarClickEventArgs } from '@syncfusion/ej2-richtexteditor';
import { AddDepositForm } from '../../forms/add-forms/add-deposit/add-deposit.component';


@Component({
  selector: 'deposits-grid',
  standalone: true,
  imports: [
    DialogModule,
    GridTemplateModule,
    DialogModule,
    AddDepositForm,
    BulkEditDepositsForm
  ],
  templateUrl: './deposits-grid.component.html',
  styleUrl: './deposits-grid.component.scss'
})
export class DepositsGridComponent {

  constructor (
    private api: ApiService,
    private globals: GlobalsService,
    private toast: ToastMessageService,
    private router: Router
  ) { }  

  @ViewChild('depositsGrid') depositsGrid: GridTemplateComponent;
  depositsGridSettings: GridModel;
  gridSelectedRows: number[];
  actionCommands: CommandModel[] = [
    { title: 'View', buttonOption: { iconCss: 'e-icons e-eye', cssClass: 'e-flat' } }, 
    { title: 'Edit', type: 'Edit', buttonOption: { iconCss: 'e-icons e-edit', cssClass: 'e-flat' } },
    { title: 'Delete', buttonOption: { iconCss: 'e-icons e-trash', cssClass: 'e-flat' } }
  ];

  // Custom dialogs for adding & bulk editing files
  @ViewChild('addDepositDialog', { static: true}) addDepositDialog: DialogComponent;
  @ViewChild('newDepositForm') newDepositForm: any;
  @ViewChild('bulkEditForm') bulkEditForm: any;
  @ViewChild('caseFileTemplate', { static: true }) caseFileTemplate!: string;
  addDepositDialogVisibility: Boolean = false;
  modalTarget: string = '#deposits-grid';
  formattedDBData: Deposit[] = [];
  batchChangedRecords: any[] = [];
  addDepositButtons: Object[] = [
    { click: this.closeDialog.bind(this), buttonModel: { content: 'Cancel', cssClass: 'e-outline' } },
    { click: this.clearNewDepositForm.bind(this), buttonModel: { content: 'Reset', isPrimary: false } },
    { click: this.onNewDepositSubmit.bind(this), buttonModel: { content: 'Submit', isPrimary: true, cssClass: 'e-primary' } }
  ];
  @ViewChild('bulkEditDepositsDialog') bulkEditDepositsDialog: DialogComponent;
  bulkEditDepositsDialogVisibility: Boolean = false;
  bulkUpdateDepositsButtons: Object[] = [
    { click: this.closeDialog.bind(this), buttonModel: { content: 'Cancel', cssClass: 'e-outline' } },
    { click: this.clearBulkEditForm.bind(this), buttonModel: { content: 'Reset', isPrimary: false } },
    { click: this.onBulkEditSubmit.bind(this), buttonModel: { content: 'Submit', isPrimary: true, cssClass: 'e-primary' } }
  ];
  
  ngOnInit() {
    this.depositsGridSettings = {
      dataSource: this.api.getOdata(APIEndpoints.Deposits),
      query: new Query().expand(['DepositToAccountNavigation', 'CaseFile($expand=Patient)']),
      pageSettings: { pageSize: 15 },
      toolbar: ['ColumnChooser'],
      columns : [
        { type: 'checkbox' },
        { field: 'Id' },
        { field: 'CaseFile.FileNumber', headerText: 'File Number', allowEditing: false },
        { field: 'CaseFile.Patient.Lastname', headerText: 'Client Last Name', allowEditing: false },
        { field: 'CaseFile.Patient.Firstname', headerText: 'Client First Name', allowEditing: false },
        { field: 'DepositDate', headerText: 'Date Received', editType: 'datePickerEdit' },
        { field: 'DepositAmount', headerText: 'Amount' },
        { field: 'FinalCheck', headerText: 'Final Check' },
        { field: 'Notes' },
        { type: 'commands', commands: this.actionCommands }
      ],
      toolbarClick: ($event: ToolbarClickEventArgs) => this.onToolbarClick($event),
      commandClick: ($event: CommandClickEventArgs) => this.commandClick($event),
      rowSelecting: ($event: RowSelectingEventArgs) => this.onRowSelecting($event),
      actionComplete: ($event: any) => this.onActionComplete($event)
    }
  }

  // Add logic for rendering modals
  onToolbarClick(args: any) {
    
    if (args.item.id === 'BulkEdit') {
      this.bulkEditDepositsDialogVisibility = true;
      setTimeout(() => { this.bulkEditDepositsDialog.show() });
    }
  }

  // Add logic for navigating to individual case files
  commandClick(commandClickArgs: CommandClickEventArgs) {
    const data: any = commandClickArgs?.rowData;
    const btn = commandClickArgs.commandColumn?.buttonOption;

    if (data !== undefined) {
      const viewItem: boolean | undefined = btn?.iconCss?.includes('e-eye');
      const deleteItem: boolean | undefined = btn?.iconCss?.includes('e-trash');

      if (viewItem) {
        
        if (data.CaseFile && data.CaseFile.FileNumber) {
          this.caseFileNavigation('/case-files/hub', { fileNumber: `${data.CaseFile.FileNumber}`, hash: 'Financial' });
          // window.open(`${window.location.origin}/case-files/${data.CaseFile.FileNumber}#Financial`, '_blank');
        } else {
          this.toast.showError('Unable to open file. No File Number associated with Deposit.')
        }
        
      } else if (deleteItem) {
        console.log(commandClickArgs);
      }

    } else {
      console.error('commandClickArgs: ', commandClickArgs);
      this.toast.showError('No data found.');
    }
  }

  // Add button for bulk editing deposits
  onRowSelecting(args: RowSelectingEventArgs) {

    if (args.rowIndexes) {
      const gridName = this.depositsGrid.grid.toolbar[0];
      const newToolbarItem = { text: 'Bulk Edit', tooltipText: 'Bulk Edit', id: 'BulkEdit' };
      const toolbarHasBulkEditBtn = this.depositsGrid.grid.toolbar.some((item: any) => item.id === 'BulkEdit');
      this.gridSelectedRows = args.rowIndexes;
      
      if (args.rowIndexes.length > 1) {

        if (!toolbarHasBulkEditBtn) {
          this.depositsGrid.grid.toolbar = [gridName, newToolbarItem, 'ColumnChooser'];
        }

      } else {

        if (toolbarHasBulkEditBtn) {
          this.depositsGrid.grid.toolbar = [gridName, 'ColumnChooser'];
        }
      }
    }
  }

  onActionComplete(args: any) {

    if (args.requestType === 'save') {
      console.log(args.data.DepositDate, args.data, args);

      // args.data.DepositDate = this.globals.convertDateTimeToDateOnly(args.data.DepositDate)
    }
  }

  // Close both modal windows
  closeDialog() {
    // this.addDepositDialog.hide();
    this.bulkEditDepositsDialog.hide();
  }

  // Set height of modals
  beforeOpening(args: any) {
    args.maxHeight = '80vh';
  }

  // Set fields to blank
  clearNewDepositForm() {
    (this.newDepositForm.newDepositForm as FormGroup).reset();
  }

  // Add new file to grid
  onNewDepositSubmit(args: any) {
    let form = this.newDepositForm.newDepositForm;

    if (form.valid) {
      // this.formattedDBData.push(this.mapFormToDeposit(form.value));
      this.depositsGrid?.grid.editModule.addRecord(form.value);
      this.closeDialog();
    } else {
      console.log(form);
      this.toast.showError('Form invalid.');
    }
  }

  // Set bulk editing fields to blank
  clearBulkEditForm() {
    (this.bulkEditForm.bulkEditDepositsForm as FormGroup).reset();
  }

  // Apply bulk changes to data
  onBulkEditSubmit(args: any) {
    let form = this.bulkEditForm.bulkEditDepositsForm;

    if (form.valid) {

      this.gridSelectedRows.forEach((row) => {
        const rowData: any = this.depositsGrid.grid.getRowInfo(this.depositsGrid.grid.getRowByIndex(row)).rowData;
        const dateOnly = new Date(form.value.DepositDate).toISOString().slice(0, 10);
        form.value.DepositDate = dateOnly;

        this.api.fetchRequest(`odata${APIEndpoints.Deposits}/${rowData.Id}`, 'PATCH', form.value);
      });

      this.closeDialog();
    } else {
      console.log((this.bulkEditForm.bulkEditDepositsForm as FormGroup).errors);
      alert('Please enter a valid form.');
    }

    this.depositsGrid.grid.refresh();
  }

  // Update array of selected row Indexes
  removeNumberFromArray(numbers: number[], target: number) {
    const index = numbers.findIndex(num => num === target);
    
    return index !== -1 ? numbers.splice(index, 1) : numbers;
  }

  async caseFileNavigation(url: string, params: { [key: string]: any }, fragment?: string): Promise<void> {
    // Remove hash from params if it exists
    const { hash, ...queryParams } = params;
    const queryString = new URLSearchParams(queryParams).toString();
    const fullUrl = `${url}?${queryString}`;
    const fragmentHash = hash ? `#${hash}` : '';
    
    await this.router.navigateByUrl(fullUrl + fragmentHash, { 
      replaceUrl: true,
      skipLocationChange: false
    });
  }
}
