<!-- Fee Schedule Form Content -->
<form [formGroup]="formGroup">
  <div class="form-fields">
    <!-- Name field -->
    <mat-form-field appearance="outline">
      <mat-label>Name</mat-label>
      <input matInput formControlName="Name" required>
      <mat-error *ngIf="formGroup.get('Name')?.errors?.['required']">Name is required</mat-error>
    </mat-form-field>

    <!-- Providers section -->
    <div class="providers-container">
      <h3>Providers</h3>
      <div class="provider-selection">
        <!-- Available Providers -->
        <div class="available-providers">
          <h4>Available Providers</h4>
          <mat-form-field appearance="outline" class="search-field">
            <mat-label>Search Providers</mat-label>
            <input matInput [formControl]="providerSearchControl" placeholder="Type to search...">
            <mat-icon matSuffix>search</mat-icon>
            <button mat-icon-button matSuffix *ngIf="providerSearchControl.value" 
                    (click)="providerSearchControl.setValue('')">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <mat-list>
            <mat-list-item *ngFor="let provider of filteredAvailableProviders$ | async">
              <span matListItemTitle>{{ provider.Name }}</span>
              <button mat-stroked-button color="primary" matListItemMeta (click)="onProviderSelectionChange(provider, true)">
                Add
              </button>
            </mat-list-item>
            <mat-list-item *ngIf="(filteredAvailableProviders$ | async)?.length === 0" class="no-results">
              <span matListItemTitle class="no-results-text">No providers found</span>
            </mat-list-item>
          </mat-list>
        </div>
        
        <!-- Selected Providers -->
        <div class="selected-providers">
          <h4>Selected Providers</h4>
          <mat-list>
            <mat-list-item *ngFor="let provider of selectedProviders$ | async">
              <span matListItemTitle>{{ provider.Name }}</span>
              <button mat-stroked-button color="warn" matListItemMeta (click)="onProviderSelectionChange(provider, false)">
                Remove
              </button>
            </mat-list-item>
          </mat-list>
        </div>
      </div>
    </div>

    <!-- CPT Codes section -->
    <div class="cpt-codes-container">
      <div class="cpt-codes-header">
        <h3>CPT Codes</h3>
        <button mat-stroked-button color="primary" (click)="addProcedureCode()">
          <mat-icon>add</mat-icon> Add CPT Code
        </button>
      </div>

      <div class="table-container">
        <table mat-table [dataSource]="dataSource" matSort class="cpt-codes-table">
          <!-- Code Column -->
          <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>CPT Code</th>
            <td mat-cell *matCellDef="let row">
              <mat-form-field appearance="outline" class="code-select">
                <mat-select [(value)]="row.code" (selectionChange)="onCptCodeChange($event, row)">
                  <mat-option *ngFor="let code of availableCptCodes$ | async" [value]="code.Id">
                    {{code.Description}}
                  </mat-option>
                  <mat-option *ngIf="row.code !== null && row.code !== undefined" [value]="row.code">
                    {{getCptCodeDescription(row.code)}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </ng-container>

          <!-- Billed Amount Column -->
          <ng-container matColumnDef="billedAmount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Billed Amount</th>
            <td mat-cell *matCellDef="let row">
              <mat-form-field appearance="outline">
                <input matInput type="number" [value]="row.billedAmount" 
                       (input)="onBilledAmountChange($event, row)" 
                       min="0">
              </mat-form-field>
            </td>
          </ng-container>

          <!-- Front End Rate Column -->
          <ng-container matColumnDef="frontEndRate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Front End Rate (%)</th>
            <td mat-cell *matCellDef="let row">
              <mat-form-field appearance="outline">
                <input matInput type="number" [value]="row.frontEndRate"
                       (input)="onFrontEndRateChange($event, row)" 
                       min="0" max="100">
              </mat-form-field>
            </td>
          </ng-container>

          <!-- Back End Rate Column -->
          <ng-container matColumnDef="backEndRate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Back End Rate (%)</th>
            <td mat-cell *matCellDef="let row">
              <mat-form-field appearance="outline">
                <input matInput type="number" [value]="row.backEndRate"
                       (input)="onBackEndRateChange($event, row)"
                       [readonly]="!row.splitInvoice"
                       min="0" max="100">
              </mat-form-field>
            </td>
          </ng-container>

          <!-- Split Invoice Column -->
          <ng-container matColumnDef="splitInvoice">
            <th mat-header-cell *matHeaderCellDef>Split Invoice</th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox [checked]="row.splitInvoice"
                           (change)="row.splitInvoice = $event.checked; updateFormArrayFromTable()">
              </mat-checkbox>
            </td>
          </ng-container>

          <!-- Actions Column -->
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row; let i = index">
              <button mat-icon-button color="warn" (click)="removeProcedureCodeEntry(i)"
                      [disabled]="dataSource.data.length === 1">
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <!-- Row shown when no data matches the filter -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="6">No CPT codes found</td>
          </tr>
        </table>
      </div>

      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of CPT codes"></mat-paginator>
    </div>
  </div>
</form>
