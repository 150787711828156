import { Injectable } from '@angular/core';
import { ApiService } from '@services/api/api.service';
import { Query } from '@syncfusion/ej2-data';
import { APIEndpoints } from '@models/api/Endpoints';

export interface ProviderType {
  id: number;
  description: string;
  active: number;
  default: number;
  ordering: number;
  created_at: string;
  updated_at: string;
}


@Injectable({
  providedIn: 'root'
})
export class ProviderTypeService {
  private providerTypeMap: { [key: number]: string } = {};
  private providerTypes: ProviderType[] = [];

  constructor(private api: ApiService) {
    this.initializeProviderTypes();
  }

  private async initializeProviderTypes(): Promise<void> {
    try {
      const response: any = await this.api.getOdata(APIEndpoints.SurgicalInvoices)
        .executeQuery(new Query());
      
      this.providerTypes = response.result.map((type: any) => ({
        id: type.Id,
        description: type.Description,
        active: type.IsActive,
        default: type.Default,
        ordering: type.Ordering,
        created_at: type.CreatedAt,
        updated_at: type.UpdatedAt
      }));

      // Initialize the map
      this.providerTypes.forEach(type => {
        this.providerTypeMap[type.id] = type.description;
      });
    } catch (error) {
      console.error('Error loading provider types:', error);
    }
  }

  getProviderTypeDescription(id: number): string {
    return this.providerTypeMap[id] || 'Unknown';
  }

  getAllProviderTypes(): ProviderType[] {
    return this.providerTypes;
  }

  async getProviderTypesByProviderId(providerId: number): Promise<number[]> {
    try {
      const query = new Query()
        .expand('ProviderType($select=Id,Description)')
        .where('ProviderId', 'equal', providerId);

      const response: any = await this.api.getOdata(APIEndpoints.XrefProviderProviderTypes).executeQuery(query);
      return response.result.map((xref: any) => xref.ProviderType.Id);
    } catch (error) {
      console.error('Error fetching provider types:', error);
      return [];
    }
  }
} 
