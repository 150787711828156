// Angular
import { Injectable, WritableSignal, inject, signal } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationExtras, Params, Router } from '@angular/router';
import { BehaviorSubject, filter, Observable, Subject, takeUntil } from 'rxjs';

// 3rd Party
import { Query } from '@syncfusion/ej2-data';
import { faUserDoctor } from '@fortawesome/free-solid-svg-icons';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { Tab, tabs } from './hub.model';
import { CaseFile } from '@models/data-contracts';
import { PerformanceData } from '@features/financial/models/financial-performance.model';
import { PhoneNumber } from '@models/components/provider-map.model';
import { TabComponent } from '@syncfusion/ej2-angular-navigations';
import { FinancialPerformanceService } from '@features/financial/services/financial-performance.service';
import { AuthenticatedServiceBase } from '@core/auth/auth.base';
import { GlobalsService } from '@services/globals/globals.service';

enum FileHubErrorType {
  MISSING_FILE_NUMBER = 'MISSING_FILE_NUMBER',
  INVALID_FILE_NUMBER = 'INVALID_FILE_NUMBER',
  CASE_FILE_NOT_FOUND = 'CASE_FILE_NOT_FOUND',
  FAILED_TO_LOAD = 'FAILED_TO_LOAD',
  NO_PATIENT_DATA = 'NO_PATIENT_DATA',
  INVALID_PATIENT_ID = 'INVALID_PATIENT_ID',
  FAILED_TO_INITIALIZE = 'FAILED_TO_INITIALIZE'
}

interface ServiceError extends Error {
  type: FileHubErrorType;
  context: string;
  technical: string;
  userMessage: string;
}

const ERRORS = {
  FILE_HUB: {
    MISSING_FILE_NUMBER: {
      message: 'File number is required to load case file',
      technical: 'Missing fileNumber parameter'
    },
    INVALID_FILE_NUMBER: {
      message: 'Invalid file number format',
      technical: 'File number validation failed'
    },
    CASE_FILE_NOT_FOUND: {
      message: 'Case file not found - please verify the file number',
      technical: 'No case file found for provided file number'
    },
    FAILED_TO_LOAD: {
      message: 'Unable to load case file',
      technical: 'Failed to load case file data'
    },
    NO_PATIENT_DATA: {
      message: 'Patient information not available',
      technical: 'No patient data found for case file'
    },
    INVALID_PATIENT_ID: {
      message: 'Invalid patient information',
      technical: 'Invalid patient ID format'
    },
    FAILED_TO_INITIALIZE: {
      message: 'Unable to initialize case file',
      technical: 'Failed to initialize related case file data'
    }
  }
} as const;

const defaultQuery = new Query()
  .select([
    // Core properties
    'Id', 'FileNumber', 'PatientId', 'Patient', 'PAndL', 'FileOpened', 'DateOfLoss',

    // Law Firm related
    'LawFirmId', 'LawFirm', 'LawFirmContact', 'LawFirmContactNavigation',
    'LawFirmFileNumber', 'Attorney', 'AttorneyNavigation',

    // Staff related
    'CaseManager', 'CaseManagerNavigation', 'MarketManager', 'MarketManagerNavigation',
    'Statuser', 'FileSecuredBy',

    // Case details
    'FileGroupId', 'CaseType', 'CaseTypeId', 'StatusingGroup', 'StatusingGroupNavigation',
    'ReferralSource', 'ReferralSourceNavigation', 'ReferringPhysician',
    'ResponsibleParty', 'InitialTimeFrame', 'StatuteOfLimitations',

    // Boolean flags
    'InTreatment', 'IsSurgical', 'ExcludeFromBalanceDue', 'ClientHasCheckpointLimitations',

    // Additional fields
    'Comments', 'FileRating', 'RecordStatusId', 'BoxPublicFolder'
  ])
  .expand([
    'Patient($expand=XrefAddressPatients($expand=Address($expand=StateNavigation)))',
    'PAndLNavigation',
    'CaseManagerNavigation',
    'MarketManagerNavigation',
    'StatusingGroupNavigation',
    'LawFirmContactNavigation',
    'AttorneyNavigation'
  ]);
interface ODataResponse<T> {
  result: T[];
  count?: number;
}

@Injectable({ providedIn: 'root' })
export class FileHubService extends AuthenticatedServiceBase {
  // Injectables and readonly properties
  protected override readonly endpoint = this.APIEndpoints.Casefiles;
  protected readonly route = inject(ActivatedRoute);
  protected readonly defaultQuery = new Query()
    .expand([
      'Patient($expand=XrefAddressPatients($expand=Address($expand=StateNavigation)))',
      'PAndLNavigation',
      'CaseManagerNavigation',
      'MarketManagerNavigation',
      'StatusingGroupNavigation',
    ])
    .select([
      'Id', 'InTreatment', 'IsSurgical', 'InitialTimeFrame', 'StatuteOfLimitations', 'FileNumber', 'PatientId', 'Patient', 'PAndL', 'FileOpened', 'DateOfLoss',
      'LawFirmId', 'LawFirm', 'LawFirmContact', 'LawFirmContactNavigation',
      'LawFirmFileNumber', 'Attorney', 'AttorneyNavigation',
      'CaseManager', 'CaseManagerNavigation', 'MarketManager', 'MarketManagerNavigation',
      'Statuser', 'FileSecuredBy', 'ResponsibleParty', 'FileGroupId', 'IsSurgical', 'InitialTimeFrame', 'StatuteOfLimitations', 'ExcludeFromBalanceDue', 'ClientHasCheckpointLimitations', 'RecordStatusId',
    ])

  constructor(
    private router: Router,
    private financialPerformance: FinancialPerformanceService,
    private globals: GlobalsService
  ) {
    super();
  }

  // Signals & Variables
  private caseFileSignal: WritableSignal<CaseFile | undefined> = signal(undefined);
  private caseFileIdSignal: WritableSignal<number | undefined> = signal(undefined);
  private tabFragmentSignal: WritableSignal<string | undefined> = signal(undefined);
  private activeTabIDSignal: WritableSignal<string | undefined> = signal(undefined);
  private performanceDataSignal: WritableSignal<PerformanceData | undefined> = signal(undefined);
  private caseFileSubject = new BehaviorSubject<CaseFile | undefined>({});
  public caseFile$: Observable<CaseFile | undefined> = this.caseFileSubject.asObservable();
  public reloadSignal: WritableSignal<boolean | undefined> = signal(undefined);
  public tabs = tabs;
  private reloadTrigger = new Subject<void>();
  public reload$ = this.reloadTrigger.asObservable();
  public authLimitPercentage: number | undefined = undefined;
  public destroy$ = new Subject<void>();

  state: any = {
    caseFile: this.caseFileSignal,
    caseFileId: this.caseFileIdSignal,
    fileNumber: signal<string | undefined>(undefined),
    tabFragment: this.tabFragmentSignal,
    activeTabID: this.activeTabIDSignal,
    performanceData: this.performanceDataSignal,
    caseFileSubject: this.caseFileSubject,
    caseFile$: this.caseFile$,
    reloadSignal: this.reloadSignal,
    tabs: this.tabs,
    reloadTrigger: this.reloadTrigger,
    reload$: this.reload$,
    authLimitPercentage: this.authLimitPercentage,
    destroy$: this.destroy$,
  }

  /**
   * Getters
   */
  get caseFile(): CaseFile | undefined {
    return this.caseFileSignal();
  }

  get caseFileId(): number | undefined {
    return this.caseFileIdSignal();
  }

  get performanceData(): PerformanceData | undefined {
    return this.performanceDataSignal();
  }

  get getCaseFile$(): CaseFile | undefined {
    return this.caseFileSubject.getValue();
  }

  get tabFragment(): string | undefined {
    return this.tabFragmentSignal();
  }

  get activeTab(): string | undefined {
    return this.activeTabIDSignal();
  }

  get reload(): boolean | undefined {
    return this.reloadSignal();
  }

  get fileNumber(): string | undefined {
    return this.state.fileNumber();
  }

  /**
   * Setters
   */

  triggerReload() {
    this.reloadTrigger.next();
  }

  // Updates case file signal and subject
  updateCaseFile(key: string, value: any) {
    const updatedCaseFile = { ...this.caseFileSignal(), [key]: value };
    const updatedCaseFile$ = { ...this.caseFileSubject.value, [key]: value };
    this.caseFileSignal.set(updatedCaseFile);
    this.caseFileSubject.next(updatedCaseFile$);
  }

  // Updates active tab ID signal
  updateActiveTabString(tab: string) {
    this.activeTabIDSignal.set(tab);
  }

  // Gets route params
  getRouteParams() {
    let paramMapArray: any;
    this.route.queryParamMap.subscribe(params => paramMapArray = params);
    return paramMapArray ?? undefined;
  }

  // Gets route param value
  getRouteParamValue(paramName: string): string | undefined | null {
    let params = new URL(document.location.toString()).searchParams;
    return params.get(paramName);
  }

  // Gets fragment string from current route, ex: #Home
  getRouteFragment() {
    return this.route.fragment.subscribe((fragment: string | null) => {
      if (fragment) this.tabFragmentSignal.set(fragment);
      return fragment;
    });
  }

  // Adds string as fragment to URL, ex: #Home
  addFragmentToURL(fragmentString: string) {
    this.router.navigate([], { fragment: fragmentString, queryParamsHandling: 'merge' });
  }

  // Tracks changes to query params
  handleQueryChange(paramsObj: Params) {
    const queryParams: Params = paramsObj;

    this.router.navigate([],
      {
        relativeTo: this.route,
        queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      }
    );
  }

  // Subscribe to hash changes in the URL
  hashChanges() {
    this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe(() => {
        const fragment = this.router.routerState.snapshot.root.fragment;
        if (fragment) this.activeTabIDSignal.set(fragment);
      });
  }

  // Update URL with hashtag
  setFragmentToSelectedTab(fragment: string) {
    const queryParams = this.router.routerState.snapshot.root.queryParams;
    const navigationExtras: NavigationExtras = {
      fragment: fragment,
      queryParams: queryParams,
    };

    this.router.navigate([], navigationExtras);
  }

  private createError(error: unknown, type: FileHubErrorType, context: string): ServiceError {
    return {
      type,
      name: 'FileHubError',
      message: error instanceof Error ? error.message : 'Unknown error',
      context,
      technical: error instanceof Error ? error.message : 'Unknown error',
      userMessage: ERRORS.FILE_HUB[type]?.message || 'Unknown error'
    } as ServiceError;
  }

  async checkQueryParams(): Promise<void> {

    try {
      this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe(async params => {
        if (!this.globals.objHasKey(params, 'fileNumber')) throw new Error('Missing required fileNumber parameter');

        if (params && params['fileNumber']) this.state.fileNumber.set(params['fileNumber']);
      });

    } catch (error) {
      this.createError(error, FileHubErrorType.FAILED_TO_LOAD, 'FileHubService.checkQueryParams');
      console.error(error);
      throw error;
    }
  }

  async initializeCaseFileFromRoute(): Promise<void> {

    try {
      await this.checkQueryParams();
      const rawFileNumber = this.getRouteParamValue('fileNumber');
      rawFileNumber?.replace(/,/g, '').trim();

      if (!rawFileNumber) {
        throw this.createError(
          new Error('Invalid file number'),
          FileHubErrorType.INVALID_FILE_NUMBER,
          'FileHubService.initializeCaseFileFromRoute'
        );
      }

      await this.fetchCaseFileFromFileNumber(rawFileNumber).then(caseFile => {
        if (caseFile) {
          this.caseFileSignal.set(caseFile);
          this.caseFileIdSignal.set(caseFile.Id);
          this.caseFileSubject.next(caseFile);
          this.initializeRelatedData();
        }
        return caseFile;
      });

    } catch (error) {
      this.handleError(error, {
        context: 'FileHubService.initializeCaseFileFromRoute',
        userMessage: ERRORS.FILE_HUB.FAILED_TO_LOAD.message
      });
      console.error(error);
      throw error;
    }
  }

  async fetchCaseFileFromFileNumber(fileNumber: string): Promise<CaseFile> {

    try {
      if (!fileNumber) throw new Error(ERRORS.FILE_HUB.MISSING_FILE_NUMBER.technical);

      const query = this.defaultQuery.where('FileNumber', 'equal', fileNumber);
      const response = await this.getOdata().executeQuery(query) as unknown as ODataResponse<CaseFile>;
      return response.result[0];

    } catch (error) {
      this.handleError(error, {
        context: 'FileHubService.fetchCaseFileFromFileNumber',
        userMessage: ERRORS.FILE_HUB.FAILED_TO_LOAD.message
      });
      console.error(error);
      throw error;
    }
  }

  // Initializes Case File signal and subject
  async setCaseFile(param: any | undefined = undefined): Promise<void> {
    try {
      const rawFileNumber = param?.fileNumber ?? this.getRouteParamValue('fileNumber');
      if (!rawFileNumber) {
        throw new Error(ERRORS.FILE_HUB.MISSING_FILE_NUMBER.technical);
      }

      const fileNumber = String(rawFileNumber).replace(/,/g, '').trim();
      if (!fileNumber) {
        throw this.createError(
          new Error('Invalid file number'),
          FileHubErrorType.INVALID_FILE_NUMBER,
          'FileHubService.setCaseFile'
        );
      }

      const query = this.defaultQuery.where('FileNumber', 'equal', fileNumber);
      const response = await this.getOdata().executeQuery(query) as unknown as ODataResponse<CaseFile>;

      if (!response?.result?.[0]) {
        throw this.createError(
          new Error(`No case file found for ${fileNumber}`),
          FileHubErrorType.CASE_FILE_NOT_FOUND,
          'FileHubService.setCaseFile'
        );
      }

      const file = response.result[0];
      this.caseFileSignal.set(file);
      this.caseFileIdSignal.set(file.Id);
      this.caseFileSubject.next(file);

      await this.initializeRelatedData();
    } catch (error) {
      this.handleError(error, {
        context: 'FileHubService.setCaseFile',
        userMessage: ERRORS.FILE_HUB.FAILED_TO_LOAD.message
      });
      throw error;
    }
  }

  private async initializeRelatedData(): Promise<void> {
    try {
      await Promise.all([
        this.authLimitPercentage ?? this.financialPerformance.getAuthLimitWarning(),
        this.setCaseManagerDetails(),
        this.setPAndLDetails(),
        this.setStatuserDetails(),
        this.setPerformanceData()
      ]);
      // Initialize tabs after other data is loaded
      this.initializeTabs();
    } catch (error) {
      throw this.createError(
        error,
        FileHubErrorType.FAILED_TO_INITIALIZE,
        'FileHubService.initializeRelatedData'
      );
    }
  }

  async setPatient(): Promise<void> {
    if (!this.caseFile?.Id) {
      throw this.createError(
        new Error('No case file loaded'),
        FileHubErrorType.NO_PATIENT_DATA,
        'FileHubService.setPatient'
      );
    }

    if (!this.caseFile?.Patient) {
      const caseFileId = Number(this.caseFile.Id);
      if (isNaN(caseFileId)) {
        throw this.createError(
          new Error('Invalid case file ID'),
          FileHubErrorType.INVALID_PATIENT_ID,
          'FileHubService.setPatient'
        );
      }

      const endpoint = `${APIEndpoints.Casefiles}(${caseFileId})`;
      const query = new Query()
        .expand('Patient($expand=XrefAddressPatients($expand=Address),XrefPhonePatients($expand=Phone))')
        .select('Patient');

      const response = await this.getOdata()
        .executeQuery(query) as unknown as ODataResponse<CaseFile>;

      if (!response?.result?.[0]?.Patient) {
        throw this.createError(
          new Error('No patient data found'),
          FileHubErrorType.NO_PATIENT_DATA,
          'FileHubService.setPatient'
        );
      }

      this.caseFileSignal.update((file) => ({
        ...file,
        Patient: response.result[0].Patient
      }));
    }
  }

  // Select tab on page load
  setTabOnLoad(tabComponent: TabComponent) {
    if (!tabComponent) return;

    // Check for URL fragment first
    const selectedTab = this.tabs.find((tab) => tab.HeaderText === this.tabFragment);
    if (selectedTab) {
      const tabIndex = this.tabs.indexOf(selectedTab);
      tabComponent.select(tabIndex);
      this.activeTabIDSignal.set(selectedTab.HeaderText);
      return;
    }

    // Fallback to localStorage
    const localStorageTab = JSON.parse(localStorage.getItem(`tab${tabComponent.element.id}`) ?? '{}');
    if (localStorageTab?.selectedItem) {
      tabComponent.select(localStorageTab.selectedItem);
      this.activeTabIDSignal.set(this.tabs[localStorageTab.selectedItem].HeaderText);
      this.addFragmentToURL(this.tabs[localStorageTab.selectedItem].HeaderText);
      return;
    }

    // Fallback to Home tab
    tabComponent.select(0);
    this.activeTabIDSignal.set('Home');
    this.addFragmentToURL('Home');
  }

  // Initializes performance data signal
  async setPerformanceData(): Promise<void> {
    if (!this.caseFile?.Id) return;

    try {
      const performance = await this.financialPerformance.getPerformanceData(this.caseFile.Id);
      this.performanceDataSignal.set(performance);
    } catch (error) {
      console.error('Error fetching performance data:', error);
      throw this.createError(
        error,
        FileHubErrorType.FAILED_TO_LOAD,
        'FileHubService.setPerformanceData'
      );
    }
  }

  /**
   * Helper Functions
   */

  // Quick reference for displaying toast error when Case File data is missing
  showNoCaseFileError(message?: string) {
    const defaultMsg = `<strong>No Case File</strong>`;
    const errMsg = message ? `${defaultMsg}<p>${message}</p>` : defaultMsg;
    throw new Error(errMsg);
  }

  async getPatientPhoneNumber(patientId: number): Promise<PhoneNumber | undefined> {
    try {
      const endpoint = `${APIEndpoints.Phones}`;
      // Ensure patientId is treated as a number
      const numericPatientId = Number(patientId);
      if (isNaN(numericPatientId)) {
        throw this.createError(
          new Error('Invalid patient ID'),
          FileHubErrorType.INVALID_PATIENT_ID,
          'FileHubService.getPatientPhoneNumber'
        );
      }

      const expandFilter = `XrefPhonePatients($filter=PatientId eq ${numericPatientId})`;
      const query = new Query()
        .expand(expandFilter);

      const response: any = await this.getOdata()
        .executeQuery(query);

      // Find the first phone that has a matching XrefPhonePatient
      const matchingPhone = response?.result?.find((phone: any) =>
        phone.XrefPhonePatients?.length > 0
      );

      return matchingPhone ?? undefined;
    } catch (error) {
      console.error('Error fetching patient phone number:', error);
      throw this.createError(
        error,
        FileHubErrorType.FAILED_TO_LOAD,
        'FileHubService.getPatientPhoneNumber'
      );
    }
  }

  // Adds Surgical tab to tabs array
  addSurgicalTabToTabs() {
    // First check if surgical tab already exists to avoid duplicates
    const existingSurgicalTab = this.tabs.find(tab => tab.Id === 'surgical');
    if (existingSurgicalTab) return; // Tab already exists, no need to add it again

    const surgicalTab: Tab = {
      Id: 'surgical',
      HeaderText: 'Surgical',
      faIcon: faUserDoctor
    }

    this.tabs.push(surgicalTab);
  }

  // Removes Surgical tab from tabs array
  removeSurgicalTab() {
    const surgicalTabIndex = this.tabs.findIndex(tab => tab.Id === 'surgical');
    if (surgicalTabIndex !== -1) {
      this.tabs.splice(surgicalTabIndex, 1);
    }
  }

  // Initialize tabs based on case file data
  initializeTabs() {
    if (this.caseFile?.IsSurgical) {
      this.addSurgicalTabToTabs();
    } else {
      this.removeSurgicalTab();
    }
  }

  // Update tabs when case file is updated
  updateTabsOnCaseFileChange(updatedCaseFile: CaseFile) {
    if (updatedCaseFile) {
      this.caseFileSignal.set(updatedCaseFile);
      this.caseFileSubject.next(updatedCaseFile);
      // Update tabs based on the updated IsSurgical status
      if (updatedCaseFile.IsSurgical) {
        this.addSurgicalTabToTabs();
      } else {
        this.removeSurgicalTab();
      }
    }
  }

  async setCaseManagerDetails() {
    if (!this.caseFile?.CaseManager) return;

    try {
      const query = new Query()
        .where('Id', 'equal', this.caseFile.CaseManager)
        .select('Id,Name');

      const response = await this.api.getOdata(this.APIEndpoints.Users)
        .executeQuery(query) as unknown as ODataResponse<any>;

      if (response?.result?.[0]) {
        this.updateCaseFile('CaseManagerNavigation', response.result[0]);
      }
    } catch (error) {
      this.handleError(error, {
        context: 'FileHubService.setCaseManagerDetails',
        userMessage: 'Failed to load case manager details'
      });
    }
  }

  async setPAndLDetails() {
    if (!this.caseFile?.PAndL) return;

    try {
      const query = new Query()
        .where('Id', 'equal', this.caseFile.PAndL)
        .select('Id,Description');

      const response = await this.api.getOdata(this.APIEndpoints.PandLs)
        .executeQuery(query) as unknown as ODataResponse<any>;

      if (response?.result?.[0]) {
        this.updateCaseFile('PAndLNavigation', response.result[0]);
      }
    } catch (error) {
      this.handleError(error, {
        context: 'FileHubService.setPAndLDetails',
        userMessage: 'Failed to load P&L details'
      });
    }
  }

  async setStatuserDetails() {
    if (!this.caseFile?.StatusingGroup) return;

    try {
      const query = new Query()
        .where('Id', 'equal', this.caseFile.StatusingGroup)
        .select('Id,Description');

      const response = await this.api.getOdata(this.APIEndpoints.StatusingGroups)
        .executeQuery(query) as unknown as ODataResponse<any>;

      if (response?.result?.[0]) {
        this.updateCaseFile('StatusingGroupNavigation', response.result[0]);
      }
    } catch (error) {
      this.handleError(error, {
        context: 'FileHubService.setStatuserDetails',
        userMessage: 'Failed to load statuser details'
      });
    }
  }

  async getCaseFileWithRetry(): Promise<CaseFile> {
    let retries = 0;
    const maxRetries = 3;
    const delay = 100;

    while (retries < maxRetries) {
      const file = this.caseFileSignal();
      if (file?.Id) {
        return file;
      }
      await new Promise(resolve => setTimeout(resolve, delay));
      retries++;
    }
    throw new Error(ERRORS.FILE_HUB.CASE_FILE_NOT_FOUND.technical);
  }

  async getCaseFileData(query: Query): Promise<ODataResponse<CaseFile>> {
    try {
      const endpoint = `${this.APIEndpoints.Casefiles}?${query.params}`;
      const response = await this.api.fetchRequest(endpoint, 'GET');
      return response;
    } catch (error) {
      this.handleError(error, {
        context: 'FileHubService.getCaseFileData',
        userMessage: 'Failed to load case file data',
        technicalDetails: {
          query: query.params,
          endpoint: this.APIEndpoints.Casefiles,
          caseFileId: this.caseFile?.Id,
          fileNumber: this.caseFile?.FileNumber,
          timestamp: new Date().toISOString()
        }
      });
      throw error;
    }
  }
}
