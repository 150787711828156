// Angular
import { Component, computed, effect, ElementRef, EventEmitter, HostListener, Input, Output, Signal, signal, ViewChild, WritableSignal, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

// Core Services
import { ErrorHandlingService } from '@core/error/error.service';
import { ErrorSeverity } from '@core/error/error.types';
import { FileHubService } from '@features/file-hub/services/file-hub.service';

// 3rd Party
import { ButtonModule, CheckBoxAllModule } from '@syncfusion/ej2-angular-buttons';
import { TextBoxAllModule, MaskedTextBoxAllModule } from '@syncfusion/ej2-angular-inputs';
import { DatePickerAllModule, DatePickerComponent, FocusEventArgs } from '@syncfusion/ej2-angular-calendars';
import { DropDownListAllModule, FilteringEventArgs, MultiSelectAllModule, MultiSelectComponent } from '@syncfusion/ej2-angular-dropdowns';
import { TooltipAllModule } from '@syncfusion/ej2-angular-popups';
import { Query, Predicate, DataManager, ODataV4Adaptor } from '@syncfusion/ej2-data';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faNotesMedical, faHospitalUser, faFolderOpen, faMinus, faLink, faCalendarXmark, faToggleOff, faLayerGroup, faUsersRectangle, faCalendarDays,
  faUser, faUserDoctor, faUserPlus, faBuilding, faFileInvoiceDollar, faUserInjured, faToggleOn, faStethoscope, faClock, faUserShield, faStar,
  faFileSignature, faFileLines, faGavel, faHashtag, faCheckSquare, faComments, faIdCard, faUserTag, faChartLine,
  faBarsProgress,
  faHouseMedicalCircleXmark,
  faBriefcaseMedical,
  faBalanceScale,
  faEye,
  faEyeSlash
} from '@fortawesome/free-solid-svg-icons';

// Models
import { CaseFile, Patient } from '@models/data-contracts';
import { APIEndpoints } from '@models/api/Endpoints';
//import { caseFileTimeFrame } from '@models/global-vars';

// Services
import { FormCrudService } from '@services/forms/form-crud.service';
import { ApiService } from '@services/api/api.service';
import { DataCacheService } from '@services/data-cache/data-cache.service';

// Components
import { PatientFormComponent } from '@root/app/features/patients/components/patient-form/patient-form.component';
import { CommentThreadsGridComponent } from '@shared/features/comments/components/threads/comment-threads-grid/comment-threads-grid.component';

const ERRORS = {
  LOAD_FAILED: {
    message: 'Failed to load form data',
    technical: 'Error loading form data'
  },
  SUBMIT_FAILED: {
    message: 'Failed to submit form',
    technical: 'Error submitting form'
  },
  CANCEL_FAILED: {
    message: 'Failed to cancel form',
    technical: 'Error canceling form'
  },
  REMOVE_COMPANION_CASES_FAILED: {
    message: 'Failed to remove companion cases',
    technical: 'Error removing companion cases'
  },
  ADD_COMPANION_CASES_FAILED: {
    message: 'Failed to add companion cases',
    technical: 'Error adding companion cases'
  },
  GET_PATIENT_FAILED: {
    message: 'Unable to load patient information',
    technical: 'Error loading patient information'
  }
};

type CaseFileFormComponentControls = {
  [K in keyof CaseFile]: FormControl<CaseFile[K] | null>;
};

export class FormWarning {
  constructor(public message: string, public type: 'warning' | 'info' = 'warning') { }
}

@Component({
  selector: 'case-file-form-component',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    TextBoxAllModule,
    MaskedTextBoxAllModule,
    DropDownListAllModule,
    MultiSelectAllModule,
    DatePickerAllModule,
    TooltipAllModule,
    CheckBoxAllModule,
    FontAwesomeModule,
    PatientFormComponent,
    CommentThreadsGridComponent
  ],
  templateUrl: './case-file-form.component.html',
  styleUrl: './case-file-form.component.scss'
})
export class CaseFileFormComponent {

  // Constructor
  constructor(
    private formCRUD: FormCrudService,
    private api: ApiService,
    private errorHandling: ErrorHandlingService,
    private dataCache: DataCacheService,
    private fileHub: FileHubService
  ) {
    // Set up effects
    effect(() => {
      const { currentValue } = this.formState();
      if (currentValue) {
        this.caseFileForm.patchValue(currentValue);
        this.getPatient();
      }
    });

    this.initializeDropdownData();
  }

  // Decorator variabls
  @Input() isLawFirm?: boolean;
  @Input() submitType?: 'POST' | 'PATCH' | 'DELETE';
  @Input() displayButtons: boolean = true;
  @Input() formMainTitle?: string;
  @Input() displayMainTitle = true;
  @Input() displayPatientsSection = true;
  @Input() displayFields: string[] = [
    'Id', 'CaseType', 'CaseTypeId', 'Comments', 'DateOfLoss', 'ExcludeFromBalanceDue', 'XrefCaseFileCompanionCaseCaseFiles',
    'FileGroupId', 'FileNumber', 'FileOpened', 'FileRating', 'FileSecuredBy', 'InitialTimeFrame', 'IsActive', 'IsSurgical', 'InTreatment', 'LawFirmContact',
    'LawFirmFileNumber', 'LawFirmId', 'MarketManager', 'CaseManager', 'PAndL', 'PatientId', 'ReferralSource', 'ReferringPhysician', 'ResponsibleParty', 'Statuser',
    'StatuteOfLimitations', 'StatusingGroup', 'RecordStatusId'
  ];
  @Output() formSubmitted = new EventEmitter<void>();
  @Output() formCancelled = new EventEmitter<void>();
  @ViewChild('caseFileFormContainer') caseFileFormContainer: ElementRef;
  @ViewChild('patientFormComp') patientFormComp!: PatientFormComponent;

  // State variables
  protected readonly caseFileSignal = signal<CaseFile | undefined>(undefined);
  protected readonly formState = computed(() => {
    const currentValue = this.caseFileSignal();
    const formValue = this.caseFileForm.value;

    return {
      isValid: this.caseFileForm.valid,
      hasChanges: JSON.stringify(currentValue) !== JSON.stringify(formValue),
      currentValue: currentValue
    };
  });
  protected readonly errorMessages = computed(() => {
    const errors: Record<string, string> = {};

    // Get case file form errors
    Object.keys(this.caseFileForm.controls).forEach(key => {
      const control = this.caseFileForm.get(key);
      if (control?.errors) errors[`${key}`] = this.getErrorMessage(key);
    });

    // Only check sub-forms if they exist and have been touched
    if (this.patientFormComp?.patientForm?.touched) {
      Object.keys(this.patientFormComp.patientForm.controls).forEach(key => {
        const control = this.patientFormComp.patientForm.get(key);
        if (control?.errors && control.touched) {
          errors[`${key}`] = this.patientFormComp.getErrorMessage(key);
        }
      });

      if (this.patientFormComp.addressFormComp?.addressForm?.touched) {
        Object.keys(this.patientFormComp.addressFormComp.addressForm.controls).forEach(key => {
          const control = this.patientFormComp.addressFormComp.addressForm.get(key);
          if (control?.errors && control.touched) {
            errors[`${key}`] = this.patientFormComp.addressFormComp.getErrorMessage(key);
          }
        });
      }
    }

    return errors;
  });

  // Public variables
  patientData: WritableSignal<Patient | undefined> = signal(undefined);
  loadingForm: boolean = true;
  caseFileHTMLElement: Element | null = null;
  errorMessage: string = '';
  recordStatuses: any;
  recordStatusesQuery: Query;
  contacts: any;
  caseManagers: any;
  caseManagersQuery: Query;
  marketManagers: any;
  marketManagersQuery: Query;
  pAndL: any;
  pAndLQuery: Query;
  referralSources: any;
  referralSourcesQuery: Query;
  referringPhysicians: any;
  referringPhysiciansQuery: Query;
  initialTimeFrames: any;
  statusers: any;
  statusersQuery: Query;
  statusingGroups: any;
  statusingGroupsQuery: Query;
  caseTypes: any;
  caseTypesQuery: Query;
  users: any;
  usersQuery: Query;
  fileGroups: any;
  fileGroupsQuery: Query;
  companionCases: any;
  companionCasesQuery: Query;
  lawFirms: any;
  lawFirmsQuery: Query;
  lawFirmContacts: any;
  lawFirmContactsQuery: Query;
  lawFirmDefaultCaseManager: any;
  lawFirmDefaultMarketManager: any;
  lawFirmDefaultStatuser: any;
  attorneys: any;
  attorneysQuery: Query;
  patients: any;
  patientFormVisibility: boolean = false;
  addPatientsButtonText: string = 'Show Patient Info';
  todaysDate: Date;
  caseFileForm = new FormGroup<CaseFileFormComponentControls>({
    Id: new FormControl<CaseFile['Id']>(undefined),
    PatientId: new FormControl<CaseFile['PatientId']>(undefined),
    PAndL: new FormControl<CaseFile['PAndL']>(undefined, [Validators.required]),
    FileNumber: new FormControl<CaseFile['FileNumber']>(''),
    FileOpened: new FormControl<CaseFile['FileOpened']>(undefined, [Validators.required]),
    DateOfLoss: new FormControl<CaseFile['DateOfLoss']>(undefined, [Validators.required]),
    InTreatment: new FormControl<CaseFile['InTreatment']>(true),
    LawFirmId: new FormControl<CaseFile['LawFirmId']>(undefined, [Validators.required]),
    Attorney: new FormControl<CaseFile['Attorney']>(undefined),
    CaseManager: new FormControl<CaseFile['CaseManager']>(undefined, [Validators.required]),
    CaseType: new FormControl<CaseFile['CaseType']>(undefined),
    CaseTypeId: new FormControl<CaseFile['CaseTypeId']>(1),
    ClientHasCheckpointLimitations: new FormControl<CaseFile['ClientHasCheckpointLimitations']>(undefined),
    Comments: new FormControl<CaseFile['Comments']>(undefined),
    ExcludeFromBalanceDue: new FormControl<CaseFile['ExcludeFromBalanceDue']>(undefined),
    FileGroupId: new FormControl<CaseFile['FileGroupId']>(undefined),
    FileRating: new FormControl<CaseFile['FileRating']>(undefined),
    FileSecuredBy: new FormControl<CaseFile['FileSecuredBy']>(undefined),
    InitialTimeFrame: new FormControl<CaseFile['InitialTimeFrame']>(undefined),
    IsSurgical: new FormControl<CaseFile['IsSurgical']>(undefined),
    LawFirmContact: new FormControl<CaseFile['LawFirmContact']>(undefined, [Validators.required]),
    LawFirmFileNumber: new FormControl<CaseFile['LawFirmFileNumber']>(undefined),
    MarketManager: new FormControl<CaseFile['MarketManager']>(undefined),
    ReferralSource: new FormControl<CaseFile['ReferralSource']>(undefined),
    ReferringPhysician: new FormControl<CaseFile['ReferringPhysician']>(undefined),
    ResponsibleParty: new FormControl<CaseFile['ResponsibleParty']>(undefined),
    Statuser: new FormControl<CaseFile['Statuser']>(undefined),
    StatuteOfLimitations: new FormControl<CaseFile['StatuteOfLimitations']>(undefined),
    StatusingGroup: new FormControl<CaseFile['StatusingGroup']>(undefined),
    RecordStatusId: new FormControl<CaseFile['RecordStatusId']>(1, [Validators.required]),
    XrefCaseFileCompanionCaseCaseFiles: new FormControl<any>(undefined)
  });
  caseFileIcons = {
    form: faNotesMedical,
    caseFile: faBriefcaseMedical,
    default: faMinus,
    patient: faHospitalUser,
    fileOpened: faFolderOpen,
    recordStatus: faBarsProgress,
    caseManager: faIdCard,
    marketManager: faUserTag,
    pAndL: faChartLine,
    referringPhysician: faUserDoctor,
    responsibleParty: faUser,
    statuteOfLimitations: faCalendarDays,
    statuser: faUser,
    statusingGroup: faUsersRectangle,
    attorney: faGavel,
    caseNumber: faHashtag,
    caseType: faFileLines,
    clientHasCheckpointLimitations: faUserShield,
    comments: faComments,
    companionCases: faLink,
    dateOfLoss: faCalendarXmark,
    excludeFromBalanceDue: faHouseMedicalCircleXmark,
    fileGroupId: faLayerGroup,
    fileNumber: faHashtag,
    fileRating: faStar,
    fileSecuredBy: faUserShield,
    initialTimeFrame: faClock,
    inTreatment: faStethoscope,
    active: faToggleOn,
    isSurgical: faUserInjured,
    lawFirmContact: faBuilding,
    lawFirmFileNumber: faFileInvoiceDollar,
    lawFirmId: faBuilding,
    patientId: faUserPlus,
    referralSource: faHospitalUser,
    lawFirm: faBalanceScale
  }
  formClasses = {
    formContainer: 'cc-form-container',
    form: window.innerWidth <= 768 ? 'cc-form flex-column' : 'cc-form flex-row flex-wrap',
    mainTitle: 'cc-main-title',
    subForm: 'cc-sub-form',
    subFormTitle: 'cc-sub-form-title',
    section: 'cc-form-section',
    group: 'cc-form-group row',
    inputContainer: 'cc-input-container',
    label: 'cc-label',
    input: 'cc-input',
    floadInput: 'cc-float-input',
    icon: 'cc-input-icon',
    error: 'cc-input-error',
    actions: 'cc-form-actions',
    oneCol: 'col-12',
    twoCol: window.innerWidth <= 768 ? 'col-12' : 'col-6',
    threeCol: window.innerWidth <= 768 ? 'col-12' : window.innerWidth > 768 && window.innerWidth <= 992 ? 'col-6' : 'col-4'
  };

  // Add the icons to the component
  patientFormIcons = {
    show: faEye,
    hide: faEyeSlash
  };

  // Add back the loading status signal
  loadingStatus: WritableSignal<{ loading: boolean, message: string }> = signal({
    loading: false,
    message: ''
  });

  // Add required fields
  protected readonly requiredFields: (keyof CaseFile)[] = [
    'FileOpened',
    'DateOfLoss',
    'LawFirmId',
    'PAndL',
    'CaseManager',
    'LawFirmContact',
    'RecordStatusId'
  ];

  // Update the Input decorator to use the setter pattern
  @Input() set caseFile(value: CaseFile | undefined) {

    this.caseFileSignal.set(value);

    // Set submitType based on whether we have an existing case file with an ID
    this.submitType = value?.Id ? 'PATCH' : 'POST';

    // For edit mode, use the actual InTreatment value from the case file
    // For add mode, the default true value from the FormControl initialization will be used
    if (value?.Id) {
      this.caseFileForm.get('InTreatment')?.setValue(value.InTreatment);
    }
  }

  // Add a property to track if companion cases are loaded
  private companionCasesLoaded = false;

  // Add property to track initial companion cases
  private initialCompanionCaseIds: number[] = [];

  // Add a property to track the timeout
  private filterTimeout: any = null;

  // Modify the loadCompanionCasesData method
  private loadCompanionCasesData() {
    // Skip if already loaded
    if (this.companionCasesLoaded) {
      return;
    }

    // Create optimized endpoint with query parameters
    // only take first 10 files
    const optimizedEndpoint = `${APIEndpoints.Casefiles}?$select=Id,FileNumber&$top=10&$orderby=FileNumber desc`;

    // Use the optimized endpoint directly - this returns a properly configured DataManager
    this.companionCases = this.api.getOdata(optimizedEndpoint);

    // Create a query object for dynamic filtering
    this.companionCasesQuery = new Query();

    // Mark as loaded
    this.companionCasesLoaded = true;
  }

  // Update the filtering handler with debounce and event cancellation
  onFiltering(e: FilteringEventArgs) {
    // Prevent the default filtering behavior
    e.preventDefaultAction = true;

    // Clear any existing timeout
    if (this.filterTimeout) {
      clearTimeout(this.filterTimeout);
    }

    // Set a new timeout to delay the filter operation
    this.filterTimeout = setTimeout(() => {
      if (e.text.length > 0) {
        // Create a custom query that uses 'contains' explicitly
        const searchQuery = new Query()
          .select(['Id', 'FileNumber'])
          .where('FileNumber', 'contains', e.text, true) // true for case-insensitive
          .take(50);

        // Update data source with new query
        e.updateData(this.api.getOdata(APIEndpoints.Casefiles), searchQuery);
      } else {
        // Clear filter - revert to limited dataset
        e.updateData(this.companionCases, this.companionCasesQuery);
      }

      // Clear the timeout reference
      this.filterTimeout = null;
    }, 300); // 300ms delay
  }

  // Lifecycle hooks
  ngOnInit() {
    // Other initialization logic if needed
  }

  ngAfterViewInit() {
    this.caseFileHTMLElement = this.caseFileFormContainer.nativeElement;
    this.todaysDate = new Date();
    this.updateWidth(this.caseFileHTMLElement?.clientWidth ?? window.innerWidth);
    this.watchInputElements();
  }

  // Returns appropraite error message for form control
  getErrorMessage(controlName: string): string {
    let message = '';
    const control = this.caseFileForm.get(controlName);
    if (control?.errors) {
      if (control.errors['required']) message = `${controlName} is required`;
      if (control.errors['email']) message = 'Invalid email format';
      if (control.errors['invalidPhone']) message = 'Invalid phone number (10 digits required)';
      if (control.errors['serverError']) message = control.errors['serverError'].message;
    }
    return message;
  }

  // Hit the API to create or update a case file
  async onSubmit() {
    try {
      // Instead, just let the form submission proceed and rely on the patient save
      // to set the PatientId appropriately later in this method

      const currentCaseFileId = this.caseFileForm.get('Id')?.value;
      const submitType = currentCaseFileId ? 'PATCH' : 'POST';
      const endpoint = currentCaseFileId ? `${APIEndpoints.Casefiles}/${currentCaseFileId}` : APIEndpoints.Casefiles;
      this.patientFormComp.errorMessage;
      this.patientFormComp.addressFormComp.errorMessage;

      this.loadingStatus.set({ loading: true, message: 'Validating form...' });

      // Validate all forms before proceeding
      const formErrors = this.validateAllForms();
      if (formErrors) {
        throw new Error(formErrors);
      }

      // First save the case file data (for new cases) or update it (for existing cases)
      // This ensures we have a valid caseFileId for companion cases

      // Save patient information
      this.loadingStatus.set({ loading: true, message: 'Saving file information...' });
      const patientResult = await this.patientFormComp.onSubmit();
      if (patientResult instanceof Error) throw patientResult;
      if (!patientResult.Id) throw new Error('Failed to create patient');

      // Update PatientId
      this.caseFileForm.patchValue({ PatientId: patientResult.Id });

      // Submit case file
      const submitResult = await this.formCRUD.submitForm(
        this.caseFileForm,
        `odata${endpoint}`,
        submitType
      );

      // Now that we have saved the case file (and have an ID), handle companion cases
      const savedCaseFileId = submitResult.Id || currentCaseFileId;

      // Handle companion cases with the saved case file ID
      const xrefCaseFileCompanionCases = this.caseFileForm.get('XrefCaseFileCompanionCaseCaseFiles')?.value;

      // Ensure we have a valid array to work with (convert single value to array if needed)
      const companionCasesArray = xrefCaseFileCompanionCases ?
        (Array.isArray(xrefCaseFileCompanionCases) ? xrefCaseFileCompanionCases : [xrefCaseFileCompanionCases]) :
        [];

      if (savedCaseFileId) {
        // Handle both cases - when companionCasesArray is empty AND when it has values
        // Convert current companion case values to numbers for consistent comparison
        const currentCompanionCaseIds = companionCasesArray.map((cc: any) =>
          Number(typeof cc === 'object' ? cc.Id : cc)
        );

        // Special check for empty array case
        const allCasesRemoved = this.initialCompanionCaseIds.length > 0 && currentCompanionCaseIds.length === 0;
        const isNewCase = submitType === 'POST';
        const hasChanges = isNewCase || allCasesRemoved || !this.arraysEqual(currentCompanionCaseIds, this.initialCompanionCaseIds);


        if (hasChanges) {

          // Determine which cases to remove and which to add
          const casesToRemove = this.initialCompanionCaseIds.filter(id => !currentCompanionCaseIds.includes(id));
          const casesToAdd = currentCompanionCaseIds.filter(id => !this.initialCompanionCaseIds.includes(id));

          // First, delete specific companion cases that were removed
          if (casesToRemove.length > 0) {
            this.loadingStatus.set({ loading: true, message: 'Removing unselected companion cases...' });

            // Load the existing XRef records to get their IDs
            try {
              const response = await this.api.fetchRequest(
                `odata${APIEndpoints.XrefCaseFileCompanionCases}?$select=Id,CaseFileId,CompanionCaseId&$filter=CaseFileId eq ${savedCaseFileId}`
              );

              if (response && Array.isArray(response.value)) {
                // Get records that need to be deleted
                const recordsToDelete = response.value.filter((item: any) =>
                  casesToRemove.includes(Number(item.CompanionCaseId))
                );

                // Delete each record individually
                for (const record of recordsToDelete) {
                  try {
                    await this.api.fetchRequest(
                      `odata${APIEndpoints.XrefCaseFileCompanionCases}(${record.Id})`,
                      'DELETE'
                    );
                  } catch (error) {
                    this.errorHandling.handleError(error, {
                      context: 'CaseFileFormComponent.onSubmit',
                      userMessage: ERRORS.REMOVE_COMPANION_CASES_FAILED.message,
                      severity: ErrorSeverity.Error
                    });
                  }
                }
              }
            } catch (error) {
              this.errorHandling.handleError(error, {
                context: 'CaseFileFormComponent.onSubmit',
                userMessage: ERRORS.REMOVE_COMPANION_CASES_FAILED.message,
                severity: ErrorSeverity.Error
              });
            }
          }

          // Then add new companion cases
          if (casesToAdd.length > 0) {
            this.loadingStatus.set({ loading: true, message: 'Adding new companion cases...' });

            for (const companionCaseId of casesToAdd) {

              try {
                await this.api.fetchRequest(
                  `odata${APIEndpoints.XrefCaseFileCompanionCases}`,
                  'POST',
                  {
                    CaseFileId: savedCaseFileId,
                    CompanionCaseId: companionCaseId
                  }
                );
              } catch (error) {
                this.errorHandling.handleError(error, {
                  context: 'CaseFileFormComponent.onSubmit',
                  userMessage: ERRORS.ADD_COMPANION_CASES_FAILED.message,
                  severity: ErrorSeverity.Error
                });
              }
            }
          }

        }
      }

      // Notify the FileHubService about the updated case file to show/hide the Surgical tab
      if (submitResult) {
        this.fileHub.updateTabsOnCaseFileChange(submitResult);
      }

      // Notify the parent component that the form was submitted
      this.formSubmitted.emit();
      return submitResult;

    } catch (error) {
      this.errorHandling.handleError(error, {
        context: 'CaseFileFormComponent.onSubmit',
        userMessage: ERRORS.SUBMIT_FAILED.message,
        severity: ErrorSeverity.Error
      });
      return new Error(error instanceof Error ? error.message : 'An unexpected error occurred');
    } finally {
      this.loadingStatus.set({ loading: false, message: '' });
    }
  }

  markAllFieldsAsTouched(): void {
    Object.values(this.caseFileForm.controls).forEach(control => {
      control.markAsTouched();
      control.markAsDirty();
      control.updateValueAndValidity();
    });
  }

  // Perform updates on input elements
  watchInputElements() {
    document.querySelectorAll(`.cc-input-container`).forEach((inputContainer) => {
      const container = inputContainer as HTMLElement;

      container.querySelectorAll('input, span, textarea').forEach((input) => {
        input.addEventListener('focus', () => inputContainer.classList.add('focus'));
        input.addEventListener('blur', () => inputContainer.classList.remove('focus'));
      });
    });
  }

  // Switches form to 2 columns when parent is larger 1600px
  updateWidth(containerWidth: number) {

    if (containerWidth <= 768) {
      this.formClasses.form = this.formClasses.form.replace('flex-column', 'flex-row flex-wrap');
      this.formClasses.twoCol = this.formClasses.twoCol.replace('col-12', 'col-6');
    } else if (containerWidth > 768 && containerWidth <= 992) {
      this.formClasses.form = this.formClasses.form.replace('flex-row', 'flex-column');
      this.formClasses.twoCol = this.formClasses.twoCol.replace('col-6', 'col-12');
    } else {
      this.formClasses.form = this.formClasses.form.replace('flex-row', 'flex-column');
      this.formClasses.twoCol = this.formClasses.twoCol.replace('col-6', 'col-12');
    }
  }

  // Update the layout on window resize
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    const containerWidth = this.caseFileHTMLElement?.clientWidth || window.innerWidth;
    this.updateWidth(containerWidth);
  }

  async getPatient() {
    const patientId = this.formState().currentValue?.Patient?.Id as number;
    if (!patientId) throw new Error('Patient Id required to fetch address');

    const endpoint = `${APIEndpoints.Patients}(${patientId})`;
    const expandString = 'XrefAddressPatients($expand=Address($select=Id,Address1,Address2,City,State,Zip,AddressType))';
    const query = new Query().expand(expandString);

    try {
      await this.api.getOdata(endpoint).executeQuery(query).then((res: any) => {
        if (res instanceof Error || !res.result[0]) throw new Error('Failed to fetch patient address');
        this.patientData.set((res as any).result[0] as Patient);
      });

      return this.patientData();
    } catch (error) {
      this.errorHandling.handleError(error, {
        context: 'CaseFileFormComponent.getPatient',
        userMessage: ERRORS.GET_PATIENT_FAILED.message,
        severity: ErrorSeverity.Warning
      });
      return error;
    }
  }

  onDatePickerFocus(focusEvent: FocusEventArgs) {
    if (!(focusEvent.model instanceof DatePickerComponent)) return;
    focusEvent.model.show();
  }

  onDateOnlyChange(args: any, controlName: string) {
    args.value = new Date(args.value).toISOString().split('T')[0];
    this.caseFileForm.get(controlName)?.setValue(args.value);

    // Auto calculate SOL when DOL changes
    if (controlName === 'DateOfLoss') {
      // TODO: Get state ID from law firm
      const stateId = 1; // Placeholder - need to get actual state ID
      const sol = this.calculateStatuteOfLimitations(args.value, stateId);
      this.caseFileForm.get('StatuteOfLimitations')?.setValue(sol);
    }
  }

  // Modify onCompanionCasesCreated to avoid loading during grid init
  onCompanionCasesCreated(event: any) {
    // Don't load data here, it will be loaded in onDialogOpen

    // Still handle existing companion cases for edit mode
    const caseFileId = this.caseFileForm.get('Id')?.value;
    if (caseFileId) {
      this.loadCompanionCases(caseFileId);
    }
  }

  // Add method to calculate Statute of Limitations
  calculateStatuteOfLimitations(dateOfLoss: string, stateId: number) {
    // TODO: Implement calculation based on state rules
    // This will need state-specific offset data and calculation logic
    // For now, return a placeholder date 2 years from DOL
    const dol = new Date(dateOfLoss);
    dol.setFullYear(dol.getFullYear() + 2);
    return dol.toISOString().split('T')[0];
  }

  // Update the patient selection handler
  onPatientSelected(event: any) {
    if (event.value === null || event.value === undefined || event.value === 0) {
      this.caseFileForm.get('PatientId')?.setValue(null, { emitEvent: false });
      this.patientData.set(undefined);
      this.patientFormComp.addressFormComp.addressForm.patchValue({ AddressType: 2 });
    } else {
      // Log patient ID to confirm it's coming through correctly

      // Explicitly set PatientId as a number
      this.caseFileForm.get('PatientId')?.setValue(Number(event.value), { emitEvent: true });

      // Set patient data for display
      this.patientData.set(event.patient);

      // Force validation update on the PatientId control
      this.caseFileForm.get('PatientId')?.updateValueAndValidity();
      this.caseFileForm.get('PatientId')?.markAsDirty();
    }
  }

  @Input() deferDropdownLoading: boolean = true;
  async initializeDropdownData() {
    try {
      // Define cache keys for reference data
      const cacheKeys = {
        recordStatuses: 'reference:recordStatuses',
        pAndL: 'reference:pAndL',
        referralSources: 'reference:referralSources',
        caseTypes: 'reference:caseTypes',
        statusingGroups: 'reference:statusingGroups',
        fileGroups: 'reference:fileGroups',
        lawFirms: 'reference:lawFirms',
        attorneys: 'reference:attorneys',
        users: 'reference:users',
        contacts: 'reference:contacts',
        initialTimeFrames: 'reference:initialTimeFrames'
      };

      // Use cached data if available, otherwise fetch fresh data
      this.recordStatuses = this.dataCache.get(cacheKeys.recordStatuses) ||
        this.api.getOdata(APIEndpoints.RecordStatuses);
      if (!this.dataCache.has(cacheKeys.recordStatuses)) {
        this.dataCache.set(cacheKeys.recordStatuses, this.recordStatuses);
      }

      this.pAndL = this.dataCache.get(cacheKeys.pAndL) ||
        this.api.getOdata(APIEndpoints.PandLs);
      if (!this.dataCache.has(cacheKeys.pAndL)) {
        this.dataCache.set(cacheKeys.pAndL, this.pAndL);
      }

      this.referralSources = this.dataCache.get(cacheKeys.referralSources) ||
        this.api.getOdata(APIEndpoints.ReferralSources);
      if (!this.dataCache.has(cacheKeys.referralSources)) {
        this.dataCache.set(cacheKeys.referralSources, this.referralSources);
      }

      this.caseTypes = this.dataCache.get(cacheKeys.caseTypes) ||
        this.api.getOdata(APIEndpoints.CaseTypes);
      if (!this.dataCache.has(cacheKeys.caseTypes)) {
        this.dataCache.set(cacheKeys.caseTypes, this.caseTypes);
      }

      this.statusingGroups = this.dataCache.get(cacheKeys.statusingGroups) ||
        this.api.getOdata(APIEndpoints.StatusingGroups);
      if (!this.dataCache.has(cacheKeys.statusingGroups)) {
        this.dataCache.set(cacheKeys.statusingGroups, this.statusingGroups);
      }

      this.fileGroups = this.dataCache.get(cacheKeys.fileGroups) ||
        this.api.getOdata(APIEndpoints.FileGroups);
      if (!this.dataCache.has(cacheKeys.fileGroups)) {
        this.dataCache.set(cacheKeys.fileGroups, this.fileGroups);
      }

      this.lawFirms = this.dataCache.get(cacheKeys.lawFirms) ||
        this.api.getOdata(APIEndpoints.Lawfirms);
      if (!this.dataCache.has(cacheKeys.lawFirms)) {
        this.dataCache.set(cacheKeys.lawFirms, this.lawFirms);
      }

      // These might change more often, so shorter TTL could be appropriate
      this.attorneys = this.dataCache.get(cacheKeys.attorneys) ||
        this.api.getOdata(APIEndpoints.Contacts);
      if (!this.dataCache.has(cacheKeys.attorneys)) {
        this.dataCache.set(cacheKeys.attorneys, this.attorneys);
      }

      this.users = this.dataCache.get(cacheKeys.users) ||
        this.api.getOdata(APIEndpoints.Users);
      if (!this.dataCache.has(cacheKeys.users)) {
        this.dataCache.set(cacheKeys.users, this.users);
      }

      this.contacts = this.dataCache.get(cacheKeys.contacts) ||
        this.api.getOdata(APIEndpoints.Contacts);
      if (!this.dataCache.has(cacheKeys.contacts)) {
        this.dataCache.set(cacheKeys.contacts, this.contacts);
      }

      this.lawFirmContacts = this.dataCache.get(cacheKeys.contacts) ||
        this.api.getOdata(APIEndpoints.Contacts);
      // lawFirmContacts uses same source as contacts

      this.initialTimeFrames = this.dataCache.get(cacheKeys.initialTimeFrames) ||
        this.api.getOdata(APIEndpoints.InitialTimeFrames);
      if (!this.dataCache.has(cacheKeys.initialTimeFrames)) {
        this.dataCache.set(cacheKeys.initialTimeFrames, this.initialTimeFrames);
      }

      // Set up queries
      this.lawFirmsQuery = new Query().select('Id,Name,MarketManager,CaseManager');
      this.attorneysQuery = new Query()
        .select('Id,ContactName,ContactTitle')
        .where('ContactType', 'equal', 1);
      this.usersQuery = new Query().select('Id,Name');
      this.companionCasesQuery = new Query().select('Id,FileNumber');

      // Initialize law firm contacts based on selected law firm
      const lawFirmId = this.caseFileForm.get('LawFirmId')?.value;
      this.lawFirmContacts = this.api.getOdata(APIEndpoints.Contacts);

      this.lawFirmContactsQuery = new Query()
        .select('Id,ContactName,ContactTitle,ContactType,XrefLawFirmContacts')
        .expand('XrefLawFirmContacts')
        .where(`XrefLawFirmContacts/any(x: x/LawFirmId eq ${lawFirmId})`, 'equal', true);

      this.caseManagersQuery = new Query()
        .select('Id,Name')
        .expand('XrefUsersRoles');

      this.marketManagersQuery = new Query()
        .select('Id,Name')
        .expand('XrefUsersRoles');

      this.statusersQuery = new Query()
        .select('Id,Name')
        .expand('XrefUsersRoles');

      this.referringPhysiciansQuery = new Query()
        .select('Id,ContactName,ContactTitle,ContactType');

      // Initialize the recordStatusesQuery if not already done
      this.recordStatusesQuery = new Query().select('Id,Description').where('IsActive', 'equal', true);

    } catch (error) {
      this.errorHandling.handleError(error, {
        context: 'CaseFileFormComponent.initializeDropdownData',
        userMessage: ERRORS.LOAD_FAILED.message,
        severity: ErrorSeverity.Warning
      });
      // Keep the existing toast notification as a user-friendly immediate feedback
    }
  }

  // Add helper methods
  private validateAllForms(): string | null {
    // Only validate the case file form by default
    this.markAllFieldsAsTouched();
    this.caseFileForm.updateValueAndValidity();

    // Collect errors from all controls
    const errorMessages: string[] = [];

    // Main form errors
    Object.entries(this.caseFileForm.controls).forEach(([controlName, control]) => {
      if (control.errors) {
        const controlErrors = Object.entries(control.errors)
          .map(([errorType, errorValue]) => {
            switch (errorType) {
              case 'required':
                return `<b>Case File - ${controlName}</b>: is required`;
              case 'email':
                return `<b>Case File - ${controlName}</b>: must be a valid email`;
              case 'min':
                return `<b>Case File - ${controlName}</b>: must be at least ${errorValue.min}`;
              case 'max':
                return `<b>Case File - ${controlName}</b>: must be at most ${errorValue.max}`;
              default:
                return `<b>Case File - ${controlName}</b>: has error: ${errorType}`;
            }
          });
        errorMessages.push(...controlErrors);
      }
    });

    // Patient form errors
    this.patientFormComp.markAllFieldsAsTouched();
    this.patientFormComp.patientForm.updateValueAndValidity();

    Object.entries(this.patientFormComp.patientForm.controls).forEach(([controlName, control]) => {
      if (control.errors) {
        const controlErrors = Object.entries(control.errors)
          .map(([errorType, errorValue]) => {
            switch (errorType) {
              case 'required':
                return `<b>Patient - ${controlName}</b>: is required`;
              case 'email':
                return `<b>Patient - ${controlName}</b>: must be a valid email`;
              case 'min':
                return `<b>Patient - ${controlName}</b>: must be at least ${errorValue.min}`;
              case 'max':
                return `<b>Patient - ${controlName}</b>: must be at most ${errorValue.max}`;
              default:
                return `<b>Patient - ${controlName}</b>: has error: ${errorType}`;
            }
          });
        errorMessages.push(...controlErrors);
      }
    });

    // Address form errors
    this.patientFormComp.addressFormComp.markAllFieldsAsTouched();
    this.patientFormComp.addressFormComp.addressForm.updateValueAndValidity();

    Object.entries(this.patientFormComp.addressFormComp.addressForm.controls).forEach(([controlName, control]) => {
      if (control.errors) {
        const controlErrors = Object.entries(control.errors)
          .map(([errorType, errorValue]) => {
            switch (errorType) {
              case 'required':
                return `<b>Address - ${controlName}</b>: is required`;
              case 'email':
                return `<b>Address - ${controlName}</b>: must be a valid email`;
              case 'min':
                return `<b>Address - ${controlName}</b>: must be at least ${errorValue.min}`;
              case 'max':
                return `<b>Address - ${controlName}</b>: must be at most ${errorValue.max}`;
              default:
                return `<b>Address - ${controlName}</b>: has error: ${errorType}`;
            }
          });
        errorMessages.push(...controlErrors);
      }
    });

    if (errorMessages.length === 0) {
      return null;
    }

    // Format error messages with HTML
    return `<span style="font-size: 1.125em;">Please check the following fields:</span><br>${errorMessages.join('<br>')}`;
  }

  onLawFirmChange(event: any) {
    // Clear the currently selected contact when law firm changes
    this.caseFileForm.get('LawFirmContact')?.setValue(null);

    if (event && event.itemData) {
      const lawFirmId = event.itemData.Id;

      const predicate = new Predicate(`XrefLawFirmContacts/any(x: x/LawFirmId eq ${lawFirmId})`, 'equal', true);

      // Update form values using patchValue to update specific fields
      this.caseFileForm.patchValue({
        CaseManager: event.itemData.CaseManager,
        MarketManager: event.itemData.MarketManager
      });

      // Update the query for law firm contacts
      this.lawFirmContactsQuery = new Query()
        .expand('XrefLawFirmContacts')
        .select('Id,ContactName,ContactTitle,ContactType')
        .where(predicate);

      // Reset caseManagersQuery
      this.caseManagersQuery = new Query()
        .select('Id,Name')
        .expand('XrefUsersRoles');

      // Reset marketManagerQuery
      this.marketManagersQuery = new Query()
        .select('Id,Name')
        .expand('XrefUsersRoles');
    }
  }

  // Query list of users if role === Case Manager
  beforeCaseManagerDrpDwnOpen(event: any) {
    this.caseManagersQuery = new Query()
      .select('Id,Name')
      .expand('XrefUsersRoles')
      .where(`XrefUsersRoles/any(x: x/RoleId eq 3)`, 'equal', true);
  }

  // Query list of users if role === Market Manager
  beforeMarketManagerDrpDwnOpen(event: any) {
    this.marketManagersQuery = new Query()
      .select('Id,Name')
      .expand('XrefUsersRoles')
      .where(`XrefUsersRoles/any(x: x/RoleId eq 4)`, 'equal', true);
  }


  // Update reset method
  private resetForms(): void {
    if (this.caseFileForm) {
      this.caseFileForm.reset();

      // Reset to default values - add explicit null/undefined for fields that aren't resetting
      this.caseFileForm.patchValue({
        InTreatment: true,
        RecordStatusId: 1,
        FileOpened: new Date().toISOString().split('T')[0],
        CaseTypeId: 1,
        ExcludeFromBalanceDue: false,
        IsSurgical: false,
        XrefCaseFileCompanionCaseCaseFiles: [],
        // Explicitly reset these fields
        ReferralSource: null,
        InitialTimeFrame: null,
        FileGroupId: null
      });
    }

    if (this.patientFormComp?.patientForm) {
      this.patientFormComp.patientForm.reset();
    }

    if (this.patientFormComp?.addressFormComp?.addressForm) {
      this.patientFormComp.addressFormComp.addressForm.reset();
    }

    // Reset any other component state that needs to be cleared
    this.initialCompanionCaseIds = [];
  }

  // Modified loadCompanionCases to better handle the selection
  async loadCompanionCases(caseFileId: number): Promise<void> {
    try {
      const response = await this.api.fetchRequest(
        `odata${APIEndpoints.XrefCaseFileCompanionCases}?$select=Id,CaseFileId,CompanionCaseId&$expand=CompanionCase($select=Id,FileNumber)&$filter=CaseFileId eq ${caseFileId}`
      );

      if (response && Array.isArray(response.value)) {

        // Store the initial companion case IDs
        this.initialCompanionCaseIds = response.value.map((item: any) => Number(item.CompanionCaseId));

        // Extract companion case IDs and set them to the form control
        const companionCases = response.value.map((item: any) => item.CompanionCaseId);
        this.caseFileForm.get('XrefCaseFileCompanionCaseCaseFiles')?.setValue(companionCases);
      } else {
        // Reset to empty array if no companion cases
        this.initialCompanionCaseIds = [];
        this.caseFileForm.get('XrefCaseFileCompanionCaseCaseFiles')?.setValue([]);
      }

      return Promise.resolve();
    } catch (error) {
      this.errorHandling.handleError(error, {
        context: 'CaseFileFormComponent.loadCompanionCases',
        userMessage: ERRORS.LOAD_FAILED.message,
        severity: ErrorSeverity.Warning
      });

      return Promise.reject(error);
    }
  }

  // Update onDialogOpen to aggressively reset problematic fields
  public onDialogOpen() {
    // Show loading overlay at the beginning
    this.loadingStatus.set({ loading: true, message: 'Loading...' });

    // Reset the caseFileSignal for ADD form to prevent old data from showing
    if (this.submitType === 'POST') {
      this.caseFileSignal.set(undefined);
      this.resetForms();

      // Directly reset the stubborn fields with a slight delay to ensure it takes effect
      setTimeout(() => {
        // Case file specific fields
        this.caseFileForm.get('ReferralSource')?.setValue(null);
        this.caseFileForm.get('InitialTimeFrame')?.setValue(null);
        this.caseFileForm.get('FileGroupId')?.setValue(null);
        this.caseFileForm.get('PAndL')?.setValue(null);

        // IMPORTANT: Always set FileOpened to today's date
        const today = new Date().toISOString().split('T')[0];
        this.caseFileForm.get('FileOpened')?.setValue(today);

        // If patient form is loaded, reset its stubborn fields too
        if (this.patientFormComp?.patientForm) {
          this.patientFormComp.patientForm.get('Gender')?.setValue(null);
          this.patientFormComp.patientForm.get('Language')?.setValue(null);
        }
      }, 100);

      // Also reset patient data if needed
      this.patientData.set(undefined);
    } else if (this.submitType === 'PATCH' && this.caseFileSignal()) {
      // For edit mode: make sure FileOpened is set if it's null/empty
      setTimeout(() => {
        const caseFileData = this.caseFileSignal();
        if (!caseFileData) {
          this.loadingStatus.set({ loading: false, message: '' });
          return;
        }

        // If FileOpened is empty, set it to today's date
        if (!caseFileData.FileOpened) {
          const today = new Date().toISOString().split('T')[0];
          this.caseFileForm.get('FileOpened')?.setValue(today);
        }

        // Rest of the existing code...
      }, 500);
    }

    // Load companion cases data and initialize dropdown data
    this.loadCompanionCasesData();
    this.initializeDropdownData();

    // For edit mode, add a delay to ensure data is loaded before patching
    if (this.submitType === 'PATCH' && this.caseFileSignal()) {
      setTimeout(() => {
        const caseFileData = this.caseFileSignal();
        if (!caseFileData) {
          this.loadingStatus.set({ loading: false, message: '' });
          return;
        }

        // Step 1: Force law firm selection first to trigger dependent data loading
        const lawFirmId = caseFileData.LawFirmId;
        if (lawFirmId) {
          // Set just the law firm ID first
          this.caseFileForm.get('LawFirmId')?.setValue(lawFirmId);
          // Trigger the law firm change to load dependent data
          // But don't hide the loading state in onLawFirmChange during form initialization
          const predicate = new Predicate(`XrefLawFirmContacts/any(x: x/LawFirmId eq ${lawFirmId})`, 'equal', true);

          // Update form values using patchValue to update specific fields
          this.caseFileForm.patchValue({
            CaseManager: caseFileData.CaseManager,
            MarketManager: caseFileData.MarketManager
          });

          // Update the query for law firm contacts
          this.lawFirmContactsQuery = new Query()
            .expand('XrefLawFirmContacts')
            .select('Id,ContactName,ContactTitle,ContactType')
            .where(predicate);

          // Reset queries
          this.caseManagersQuery = new Query()
            .select('Id,Name')
            .expand('XrefUsersRoles');

          this.marketManagersQuery = new Query()
            .select('Id,Name')
            .expand('XrefUsersRoles');
        }

        // Step 2: Add a small additional delay to ensure law firm-dependent data is loaded
        setTimeout(() => {
          // Now patch all remaining form values
          const patchValues = this.extractAllRelevantProperties(caseFileData);

          // Apply all values to the form
          this.caseFileForm.patchValue(patchValues);

          // Step 3: Load companion cases if applicable
          const caseFileId = caseFileData.Id;
          if (caseFileId) {
            // IMPORTANT: Always hide loading state only AFTER companion cases are loaded
            this.loadCompanionCases(caseFileId).finally(() => {
              // Use finally to ensure loading is hidden regardless of success/failure
              setTimeout(() => {
                this.loadingStatus.set({ loading: false, message: '' });
              }, 300);
            });
          } else {
            // No companion cases to load, we can hide the loading state
            setTimeout(() => {
              this.loadingStatus.set({ loading: false, message: '' });
            }, 300);
          }
        }, 800); // Extended delay to ensure law firm-dependent data is loaded
      }, 500); // Initial delay for dropdown data
    } else {
      // For add form
      if (this.submitType === 'POST' && this.patientFormComp) {
        this.patientFormComp.onDialogOpen();

        // Reset all form values to defaults for a new case file
        this.caseFileForm.patchValue({
          Id: undefined,
          PatientId: undefined,
          FileNumber: '',
          InTreatment: true,
          RecordStatusId: 1,
          FileOpened: new Date().toISOString().split('T')[0],
          // Clear all other fields
          LawFirmId: undefined,
          LawFirmContact: undefined,
          CaseManager: undefined,
          MarketManager: undefined,
          PAndL: undefined,
          DateOfLoss: undefined,
          Attorney: undefined,
          CaseType: undefined,
          CaseTypeId: 1,
          Comments: undefined,
          ExcludeFromBalanceDue: false,
          FileGroupId: undefined,
          FileRating: undefined,
          FileSecuredBy: undefined,
          InitialTimeFrame: undefined,
          IsSurgical: false,
          LawFirmFileNumber: undefined,
          ReferralSource: undefined,
          ReferringPhysician: undefined,
          ResponsibleParty: undefined,
          Statuser: undefined,
          StatuteOfLimitations: undefined,
          StatusingGroup: undefined,
          XrefCaseFileCompanionCaseCaseFiles: []
        });
      }

      // For add form, wait a bit longer before hiding loading
      setTimeout(() => {
        this.loadingStatus.set({ loading: false, message: '' });
      }, 800);
    }
  }

  // Helper method to compare arrays
  private arraysEqual(array1: number[], array2: number[]): boolean {
    // Quick length check first
    if (array1.length !== array2.length) return false;

    // Sort both arrays for comparison
    const sorted1 = [...array1].sort((a, b) => a - b);
    const sorted2 = [...array2].sort((a, b) => a - b);

    // Compare each element
    return sorted1.every((value, index) => value === sorted2[index]);
  }

  // Add this method to handle multiselect changes
  onCompanionCasesChange(event: any) {
    if (event.value) {
      this.caseFileForm.get('XrefCaseFileCompanionCaseCaseFiles')?.setValue(event.value);
    } else {
      this.caseFileForm.get('XrefCaseFileCompanionCaseCaseFiles')?.setValue([]);
    }
  }

  // Update ngOnChanges to also load companion cases
  ngOnChanges(changes: SimpleChanges) {

    // Check if case file data was provided
    if (changes['caseFile'] && changes['caseFile'].currentValue) {
      const caseFileData = changes['caseFile'].currentValue;

      // Extract IDs from both direct properties and nested objects
      const extractedData = this.extractAllRelevantProperties(caseFileData);

      // Initialize companion cases data
      this.loadCompanionCasesData();

      // Initialize if we have a case file ID
      if (extractedData.Id) {

        // Initialize dropdown data first
        this.initializeDropdownData().then(() => {
          // First set law firm ID and trigger change event if available
          if (extractedData.LawFirmId) {
            setTimeout(() => {
              this.caseFileForm.get('LawFirmId')?.setValue(extractedData.LawFirmId);

              // Trigger the law firm change handler
              this.onLawFirmChange({
                itemData: { Id: extractedData.LawFirmId }
              });

              // Patch the form after law firm data loaded
              setTimeout(() => {

                // Explicitly set boolean values to ensure they're properly set
                this.caseFileForm.get('InTreatment')?.setValue(!!extractedData.InTreatment);
                this.caseFileForm.get('IsSurgical')?.setValue(!!extractedData.IsSurgical);
                this.caseFileForm.get('ExcludeFromBalanceDue')?.setValue(!!extractedData.ExcludeFromBalanceDue);
                this.caseFileForm.get('ClientHasCheckpointLimitations')?.setValue(!!extractedData.ClientHasCheckpointLimitations);

                // Now patch all values
                this.caseFileForm.patchValue(extractedData);

                // Load companion cases after form is patched
                if (extractedData.Id) {
                  this.loadCompanionCases(extractedData.Id);
                }
              }, 200);
            }, 300);
          } else {
            // If no law firm ID, just patch the form directly

            // Explicitly set boolean values
            this.caseFileForm.get('InTreatment')?.setValue(!!extractedData.InTreatment);
            this.caseFileForm.get('IsSurgical')?.setValue(!!extractedData.IsSurgical);
            this.caseFileForm.get('ExcludeFromBalanceDue')?.setValue(!!extractedData.ExcludeFromBalanceDue);
            this.caseFileForm.get('ClientHasCheckpointLimitations')?.setValue(!!extractedData.ClientHasCheckpointLimitations);

            // Patch all other values
            this.caseFileForm.patchValue(extractedData);

            // Load companion cases
            if (extractedData.Id) {
              this.loadCompanionCases(extractedData.Id);
            }
          }
        });
      }
    }
  }

  // Simplified property extraction - extract IDs from navigation properties without fallbacks
  private extractAllRelevantProperties(caseFileData: any): any {
    const result: any = { ...caseFileData };

    // Extract IDs from navigation objects using a consistent pattern
    const navigationMappings = [
      { nav: 'MarketManagerNavigation', prop: 'MarketManager' },
      { nav: 'LawFirm', prop: 'LawFirmId' },
      { nav: 'LawFirmContactNavigation', prop: 'LawFirmContact' },
      { nav: 'CaseManagerNavigation', prop: 'CaseManager' },
      { nav: 'AttorneyNavigation', prop: 'Attorney' },
      { nav: 'ReferralSourceNavigation', prop: 'ReferralSource' },
      { nav: 'ReferringPhysicianNavigation', prop: 'ReferringPhysician' }
    ];

    // Process all navigation mappings
    navigationMappings.forEach(({ nav, prop }) => {
      if ((!result[prop] || result[prop] === 0) && result[nav]?.Id) {
        result[prop] = result[nav].Id;
      }
    });

    // Process boolean fields consistently
    const processBooleanField = (fieldName: string) => {
      const value = result[fieldName];

      // Only convert string/number values, don't set defaults if undefined
      if (value !== null && value !== undefined) {
        if (typeof value === 'string') {
          // Handle string values like "true", "1", "yes", etc.
          result[fieldName] = ['true', '1', 'yes', 'y'].includes(value.toLowerCase());
        } else if (typeof value === 'number') {
          // Handle numeric values (1 = true, 0 = false)
          result[fieldName] = value !== 0;
        }
        // Boolean already handled correctly
      }
    };

    // Process all boolean fields
    const booleanFields = [
      'InTreatment',
      'IsSurgical',
      'ExcludeFromBalanceDue',
      'ClientHasCheckpointLimitations'
    ];

    booleanFields.forEach(field => processBooleanField(field));

    return result;
  }

  // Add in the component class
  companionCasesSettings = {
    allowFiltering: true,
    showSelectAll: true,
    enableSelectionOrder: true,
    mode: 'Contains',
    filterBarPlaceholder: 'Search companion cases...'
  };

  // Handle when the IsSurgical checkbox changes
  onIsSurgicalChange(event: any) {
    const isSurgical = event.checked;
    // Only update UI tabs if this is an existing case file (has an ID)
    if (this.caseFileSignal()?.Id) {
      const updatedCaseFile = {
        ...this.caseFileSignal(),
        IsSurgical: isSurgical
      };
      // Update the FileHub service to show/hide the Surgical tab immediately
      this.fileHub.updateTabsOnCaseFileChange(updatedCaseFile);
    }
  }

}
