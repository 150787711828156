import { Component, ViewChild } from '@angular/core';
import { APIEndpoints } from '@root/src/app/shared/models/api/Endpoints';
import { GridTemplateModule } from '@root/src/app/shared/modules/grid-template.module';
import { ApiService } from '@root/src/app/shared/services/api/api.service';
import { GridModel } from '@syncfusion/ej2-grids';
import { BulkEditRemittancesComponent } from '../../forms/bulk-edit-forms/bulk-edit-remittances/bulk-edit-remittances.component';
import { GridTemplateComponent } from '../grid-template/grid-template.component';
import { GlobalsService } from '@root/src/app/shared/services/globals/globals.service';

@Component({
  selector: 'app-remittance-grid',
  standalone: true,
  imports: [
    GridTemplateModule,
    BulkEditRemittancesComponent
  ],
  templateUrl: './remittance-grid.component.html',
  styleUrl: './remittance-grid.component.scss'
})
export class RemittanceGridComponent {

  public remittance: GridModel;
  @ViewChild('Modal') modal: BulkEditRemittancesComponent;
  @ViewChild('Grid') grid: GridTemplateComponent;

  constructor(private api: ApiService, private globals: GlobalsService) { }

  ngOnInit() {
    this.remittance = {
      dataSource: this.api.getOdata(APIEndpoints.Remittances),
      toolbar: this.globals.isResponsive ? ['Add', 'Delete', 'Update', 'Cancel'] :
        [{ text: 'Bulk Edit', tooltipText: 'Bulk Edit', id: 'editRemittances' }, 'ColumnChooser'],
      columns: [
        { type: 'checkbox' },
        { field: 'Id', headerText: 'Id', isPrimaryKey: true, visible: false },
        { field: 'FileNumber', headerText: 'File Number' },
        { field: 'PatientName', headerText: 'Patient Name' },
        { field: 'InternalInvoiceNumber', headerText: 'Invoice Number', },
        { field: 'DateOfService', headerText: 'Date Of Service' },
        { field: 'CptCode', headerText: 'CPT Code' },
        { field: 'AmountBilled', headerText: 'Amount Billed' },
        { field: 'ReimbursementRate', headerText: 'Reimbursement Rate' },
        { field: 'TotalDueProvider', headerText: 'Total Due Provider' },
        { field: 'PaymentMethod', headerText: 'Payment Method' },
        { field: 'PaymentStatus', headerText: 'Payment Status' },
        { field: 'AmountPaid', headerText: 'Amount Paid' },
        { field: 'DatePaid', headerText: 'Date Paid' },
        { field: 'TransactionNumber', headerText: 'Transaction Number' },
        { field: 'InvoiceNotes', headerText: 'Invoice Notes' },
      ],
      toolbarClick: ($event: any) => this.customToolbarClick($event)
    }
  }

  customToolbarClick(args: any) {
    if (args.item.id === 'editRemittances') {
      this.modal.showModal(this.grid.getSelectedRows());
    }
  }

}