import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import mondaySdk from 'monday-sdk-js';
import config from '@app/config';
import { HttpClient } from '@angular/common/http';
import { DragDropModule, CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { APIEndpoints } from '@models/api/Endpoints';

interface WorkItem {
  id: string;
  name: string;
  status?: string;
  priority?: string;
  owner?: string;
  dueDate?: string;
  notes?: string;
  files?: string[];
  timeline?: string;
  lastUpdated?: string;
  tags?: string;
  caseFile?: string;
  editing: {
    [key: string]: boolean;
  };
}

interface SortState {
  column: keyof WorkItem | '';
  direction: 'asc' | 'desc';
}

interface CaseFile {
  id: string;
  caseFileNumber: string;
  patientName: string;
}

interface Column {
  field: keyof WorkItem;
  header: string;
  width: number;
  visible: boolean;
  order: number;
}

interface MondayColumn {
  id: string;
  title: string;
  type: string;
  settings_str: string;
}

@Component({
  selector: 'work-items-monday',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    DragDropModule,
    MatMenuModule,
    MatButtonModule,
    MatCheckboxModule
  ],
  templateUrl: './work-items-monday.component.html',
  styleUrls: ['./work-items-monday.component.scss']
})
export class WorkItemsMondayComponent implements OnInit {
  public boardName: string = '';
  public workItems: WorkItem[] = [];
  public sortState: SortState = {
    column: '',
    direction: 'asc'
  };

  private monday = mondaySdk();
  protected readonly Object = Object;

  private statusMap: { [key: string]: any } = {};
  private priorityMap: { [key: string]: any } = {};
  private priorityIndexToLabel: { [key: string]: string } = {};

  private titleMap: { [key: string]: string[] } = {
    'status': ['Status', 'status'],
    'priority': ['Priority', 'priority'],
    'owner': ['Owner', 'person'],
    'dueDate': ['Due Date', 'date'],
    'notes': ['Notes', 'text'],
    'files': ['Files', 'file'],
    'timeline': ['Timeline', 'timeline'],
    'lastUpdated': ['Last Updated', 'date'],
    'tags': ['Tags', 'tags'],
    'caseFile': ['Case File', 'text']
  };

  public columnMap: { [key: string]: string } = {};

  public caseFiles: any[] = [];

  public columns: Column[] = [
    { field: 'name', header: 'Name', width: 200, visible: true, order: 0 },
    { field: 'status', header: 'Status', width: 120, visible: true, order: 1 },
    { field: 'priority', header: 'Priority', width: 120, visible: true, order: 2 },
    { field: 'owner', header: 'Owner', width: 120, visible: true, order: 3 },
    { field: 'dueDate', header: 'Due Date', width: 120, visible: true, order: 4 },
    { field: 'notes', header: 'Notes', width: 200, visible: false, order: 5 },
    { field: 'files', header: 'Files', width: 120, visible: false, order: 6 },
    { field: 'timeline', header: 'Timeline', width: 120, visible: false, order: 7 },
    { field: 'lastUpdated', header: 'Last Updated', width: 120, visible: false, order: 8 },
    { field: 'tags', header: 'Tags', width: 120, visible: false, order: 9 },
    { field: 'caseFile', header: 'Case File', width: 120, visible: true, order: 10 }
  ];

  public resizingColumn: Column | null = null;
  private startX: number = 0;
  private startWidth: number = 0;

  constructor(private http: HttpClient) {
    this.monday.setToken(config.mondayApiToken);
  }

  async ngOnInit() {
    await this.initializeColumnMap();
    await this.initializeBoard();
    await this.syncCaseFileColumn();
  }

  private async initializeColumnMap() {
    try {
      const query = `query {
        boards(ids: ${config.mondayBoardId}) {
          columns {
            id
            title
            type
            settings_str
          }
        }
      }`;

      const response = await this.monday.api(query);
      const columns = response.data.boards[0].columns as MondayColumn[];

      columns.forEach(column => {
        const settings = column.settings_str ? JSON.parse(column.settings_str) : {};

        if (column.type === 'color') {
          if (column.title.toLowerCase().includes('status')) {
            this.statusMap[column.id] = settings.labels;
          } else if (column.title.toLowerCase().includes('priority')) {
            this.priorityMap[column.id] = settings.labels;
            Object.keys(settings.labels).forEach((index) => {
              this.priorityIndexToLabel[index] = settings.labels[index].text;
            });
          }
        }

        for (const [key, [title, type]] of Object.entries(this.titleMap)) {
          if (column.title.toLowerCase() === title.toLowerCase() && column.type === type) {
            this.columnMap[key] = column.id;
          }
        }
      });

    } catch (error) {
      console.error('Error initializing column map:', error);
    }
  }

  public get priorityLabels(): string[] {
    return Object.values(this.priorityIndexToLabel);
  }

  async saveField(workItem: WorkItem, field: string) {
    try {
      workItem.editing[field] = false;
      const columnId = this.columnMap[field];

      if (!columnId) {
        console.error(`No column ID found for field: ${field}`);
        return;
      }

      let value = (workItem as any)[field];

      // Special handling for different field types
      if (field === 'priority') {
        // Convert priority label back to index
        const index = Object.entries(this.priorityIndexToLabel)
          .find(([_, label]) => label === value)?.[0];
        if (index) {
          value = index;
        }
      }

      const mutation = `mutation {
        change_column_value(
          board_id: ${config.mondayBoardId},
          item_id: ${workItem.id},
          column_id: "${columnId}",
          value: ${JSON.stringify(JSON.stringify(value))}
        ) {
          id
        }
      }`;

      await this.monday.api(mutation);

    } catch (error) {
      console.error('Error saving field:', error);
    }
  }

  private async initializeBoard() {
    try {
      const query = `query {
        boards(ids: ${config.mondayBoardId}) {
          name
          items {
            id
            name
            column_values {
              id
              text
              value
            }
          }
        }
      }`;

      const response = await this.monday.api(query);
      const board = response.data.boards[0];

      this.boardName = board.name;

      this.workItems = board.items.map((item: any) => {
        const workItem: WorkItem = {
          id: item.id,
          name: item.name,
          editing: {}
        };

        item.column_values.forEach((column: any) => {
          for (const [field, columnId] of Object.entries(this.columnMap)) {
            if (column.id === columnId) {
              let value = column.text;

              // Special handling for different field types
              if (field === 'priority' && column.value) {
                const index = JSON.parse(column.value).index;
                value = this.priorityIndexToLabel[index] || value;
              }

              (workItem as any)[field] = value;
            }
          }
        });

        return workItem;
      });

    } catch (error) {
      console.error('Error initializing board:', error);
    }
  }

  private async syncCaseFileColumn() {
    try {
      const response = await this.http.get(`/api${APIEndpoints.CaseFilesTableData}`).toPromise();
      if (response && Array.isArray(response)) {
        this.caseFiles = response.map(file => ({
          id: file.Id,
          caseFileNumber: file.FileNumber,
          patientName: `${file.Patient?.Firstname || ''} ${file.Patient?.Lastname || ''}`.trim()
        }));

        // Update case file references in Tasks
        this.workItems.forEach(workItem => {
          if (workItem.caseFile) {
            const caseFile = this.caseFiles.find(cf => cf.caseFileNumber === workItem.caseFile);
            if (caseFile) {
              workItem.caseFile = `${caseFile.caseFileNumber} - ${caseFile.patientName}`;
            }
          }
        });
      }
    } catch (error) {
      console.error('Error syncing case file column:', error);
    }
  }

  startEditing(workItem: WorkItem, field: string) {
    workItem.editing[field] = true;
  }

  cancelEdit(workItem: WorkItem, field: string) {
    workItem.editing[field] = false;
  }

  get sortedWorkItems(): WorkItem[] {
    if (!this.sortState.column) {
      return this.workItems;
    }

    return [...this.workItems].sort((a, b) => {
      const aValue = a[this.sortState.column as keyof WorkItem];
      const bValue = b[this.sortState.column as keyof WorkItem];

      if (!aValue && !bValue) return 0;
      if (!aValue) return 1;
      if (!bValue) return -1;

      const comparison = aValue.toString().localeCompare(bValue.toString());
      return this.sortState.direction === 'asc' ? comparison : -comparison;
    });
  }

  sort(column: keyof WorkItem) {
    if (this.sortState.column === column) {
      this.sortState.direction = this.sortState.direction === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortState.column = column;
      this.sortState.direction = 'asc';
    }
  }

  onColumnDrop(event: CdkDragDrop<string[]>) {
    if (event.previousIndex === event.currentIndex) {
      return;
    }

    const columns = [...this.columns];
    moveItemInArray(columns, event.previousIndex, event.currentIndex);

    // Update order property
    columns.forEach((column, index) => {
      column.order = index;
    });

    this.columns = columns;
  }

  startResize(event: MouseEvent, column: Column) {
    this.resizingColumn = column;
    this.startX = event.pageX;
    this.startWidth = column.width;

    const mouseMoveHandler = (e: MouseEvent) => {
      if (this.resizingColumn) {
        const diff = e.pageX - this.startX;
        this.resizingColumn.width = Math.max(50, this.startWidth + diff);
      }
    };

    const mouseUpHandler = () => {
      this.resizingColumn = null;
      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);
    };

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  }

  toggleColumnVisibility(column: Column) {
    column.visible = !column.visible;
  }

  get visibleColumns(): Column[] {
    return this.columns.filter(col => col.visible).sort((a, b) => a.order - b.order);
  }
}
