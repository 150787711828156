<div id="add-procedure-code">
    <form class="add-procedure-form" [formGroup]="newProcedureCodeForm" (ngSubmit)="$event.preventDefault(); onSubmit()">
        <!-- Main form row with two columns -->
        <div class="form-row">
            <!-- Left column -->
            <div class="form-col">
                <h4>Add Single Procedure Code</h4>
                <div class="form-group">
                    <span class="input-icon">
                        <i class="fas fa-hashtag"></i>
                    </span>
                    <ejs-textbox formControlName="Code" 
                                placeholder="Procedure Code"
                                [enabled]="!hasUploadedFile">
                    </ejs-textbox>
                </div>

                    <div class="form-group">
                        <span class="input-icon">
                            <i class="fas fa-x-ray"></i>
                        </span>
                        <ejs-dropdownlist formControlName="Modality" 
                                        [dataSource]='modalityData' 
                                        [fields]='modalityFields'
                                        placeholder="Modality"
                                        [enabled]="!hasUploadedFile">
                        </ejs-dropdownlist>
                    </div>

                    <div class="form-group">
                        <span class="input-icon">
                            <i class="fas fa-align-left"></i>
                        </span>
                        <ejs-textbox formControlName="Description" 
                                    placeholder="Description"
                                    [enabled]="!hasUploadedFile">
                        </ejs-textbox>
                    </div>
                </div>

                <!-- Right column -->
                <div class="form-col">
                    <div class="upload-section">
                        <div class="section-header">
                            <i class="fas fa-file-csv"></i>
                            <span>Upload From CSV File</span>
                        </div>

                    <div class="file-upload-container">
                        <input type="file" class="upload" accept=".csv" (change)="onFileUpload($event)">
                        <a href="/src/assets/files/sample-proc-codes.csv" download>Download Sample File</a>
                    </div>
                </div>
            </div>
        </div>

        <!-- Moved buttons outside of the columns -->
        <div class="form-actions">
            <button ejs-button 
                    cssClass="e-primary" 
                    [disabled]="(hasUploadedFile && !hasValidFile) || (!hasUploadedFile && newProcedureCodeForm.invalid)"
                    type="submit">
                Submit
            </button>
            <button ejs-button cssClass="e-outline" type="button" (click)="cancel()">Cancel</button>
        </div>
    </form>
</div>