import { Injectable } from '@angular/core';
import { AuthenticatedServiceBase } from '@core/auth/auth.base';
import { Query, ReturnOption } from '@syncfusion/ej2-data';
import { BalanceStatement, GenerationResult } from '../models/balance-statement.model';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, from } from 'rxjs';

interface ODataResponse<T> {
    result: T[];
    count?: number;
}

/**
 * Service for managing balance statements
 * Handles generation, retrieval, and document operations for balance statements
 */
@Injectable({ providedIn: 'root' })
export class BalanceStatementService extends AuthenticatedServiceBase {
    protected override readonly endpoint = this.APIEndpoints.BalanceStatements;

    constructor() {
        super();
    }

    /**
     * Checks if a balance statement exists for a case file
     */
    async checkExists(caseFileId: number): Promise<boolean> {
        if (!caseFileId) return false;
        const query = new Query()
            .where('CaseFileId', 'equal', caseFileId)
            .select('Id')
            .take(1);

        try {
            const response = await this.getOdata()
                .executeQuery(query) as unknown as ODataResponse<any>;
            return response?.result?.length > 0;
        } catch (error) {
            if (error instanceof HttpErrorResponse && error.status === 404) {
                return false;
            }
            throw error;
        }
    }

    /**
     * Gets a single balance statement by case file ID
     */
    async getBalanceStatement(caseFileId: number): Promise<BalanceStatement | null> {
        const query = new Query()
            .where('CaseFileId', 'equal', caseFileId)
            .select([
                'Id',
                'CaseFileId',
                'StatementDate',
                'TotalBilled',
                'BalanceDue',
                'Final',
                'CreatedAt',
                'BoxId'
            ].join(','))
            .expand('CreatedByNavigation,CaseFile')
            .take(1);

        const response = await this.getOdata()
            .executeQuery(query) as unknown as ODataResponse<BalanceStatement>;

        if (!response?.result?.[0]) return null;

        // Map display fields
        const statement = response.result[0];
        return {
            ...statement,
            caseFileNumber: statement.CaseFile?.FileNumber || 'N/A',
            generatedDate: statement.StatementDate
        };
    }

    /**
     * Gets all balance statements for a case file
     */
    async getAll(caseFileId: number): Promise<BalanceStatement[]> {
        const query = new Query()
            .expand('CreatedByNavigation,CaseFile($expand=LawFirmContactNavigation,CaseManagerNavigation,Patient)')
            .where('CaseFileId', 'equal', caseFileId)
            .select([
                'Id',
                'CaseFileId',
                'StatementDate',
                'TotalBilled',
                'PartialPaymentsReceived',
                'CourtesyReduction',
                'BalanceDue',
                'Final',
                'Voided',
                'BoxId',
                'PandlId',
                'CreatedBy',
                'CreatedAt'
            ].join(','))
            .sortBy('StatementDate', 'descending');

        const response = await this.getOdata()
            .executeQuery(query) as unknown as ODataResponse<BalanceStatement>;

        // Map display fields for each statement
        return (response?.result || []).map(statement => ({
            ...statement,
            caseFileNumber: statement.CaseFile?.FileNumber || 'N/A',
            generatedDate: statement.StatementDate
        }));
    }

    /**
     * Generates a new balance statement
     */
    generate(isDraft: boolean, pandls: number[]): Observable<GenerationResult> {
        return from(
            this.api.fetchRequest('api/balance-statement/generate', 'POST', {
                isDraft,
                pandls
            })
        );
    }

    /**
     * Gets Box link for a balance statement document
     */
    async fetchBoxLink(statement: BalanceStatement): Promise<string> {
        const response = await this.api.fetchRequest('api/balance-statement/get-link', 'POST', {
            statementId: statement.Id
        });
        return response as string;
    }

    /**
     * Gets PDF bytes for a balance statement
     */
    async fetchPdf(statementId: number): Promise<string> {
        const response = await this.api.fetchRequest('api/balance-statement/get-bytes', 'POST', {
            statementId
        });
        return response as string;
    }

    /**
     * Gets CSV data for a balance statement
     */
    async fetchCsv(statementId: number): Promise<string> {
        const response = await this.api.fetchRequest('api/balance-statement/get-rows', 'POST', {
            statementId
        });
        return response as string;
    }

    /**
     * Gets P&L mapping data
     * @deprecated Use PandLService instead
     */
    async getPandLData(endpoint: string): Promise<{ [key: number]: string }> {
        const response = await this.api.getOdata(endpoint)
            .executeQuery(new Query()) as ReturnOption;

        const pAndLMap: { [key: number]: string } = {};
        const result = (response as any).result || [];

        result.forEach((item: any) => {
            pAndLMap[item.Id] = item.Description;
        });

        return pAndLMap;
    }
}
