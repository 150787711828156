// Angular
import { Component, ViewChild, TemplateRef, signal, effect, computed } from '@angular/core';
import { FormGroup, FormsModule, FormBuilder } from '@angular/forms';
import { CommonModule } from '@angular/common';

// 3rd Party
import { DataManager, Query } from '@syncfusion/ej2-data';
import { ToolbarClickEventArgs } from '@syncfusion/ej2-richtexteditor';
import { DropDownList, DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { DialogAllModule, DialogComponent } from '@syncfusion/ej2-angular-popups';
import { CommandClickEventArgs, DetailDataBoundEventArgs, EditEventArgs, Grid, GridComponent, GridModel, RowSelectingEventArgs, ColumnChooserService, RowDeselectingEventArgs, RowSelectEventArgs, RowDeselectEventArgs } from '@syncfusion/ej2-angular-grids';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { CombinedInvoice } from '@models/components/accounting.model';
import { Invoice} from '@models/data-contracts';
import { ApiService } from '@services/api/api.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { LoadingModule } from '@modules/loading.module';
import { GlobalsService } from '@services/globals/globals.service';
import { AddNewInvoiceForm } from '@components/forms/add-forms/add-new-invoice-form/add-new-invoice-form.component';
import { BulkEditInvoicesForm } from '@components/forms/bulk-edit-forms/bulk-edit-invoices/bulk-edit-invoices.component';
import { BaseGridComponent } from '@shared/components/base-grid/base-grid.component';
import { BaseGridService } from '@shared/components/base-grid/services/state.service';
import { SetGridDataArgs } from '@shared/components/base-grid/models/grid.models';
import { BaseGridHooks } from '@shared/components/base-grid/services/hooks.service';
import { InvoicesService } from '../../services/invoices.service';
import { BaseGrid } from '@core/base/grid.base';

// Add error constants
const ERRORS = {
  INVOICES: {
    LOAD_FAILED: {
      message: 'Failed to load invoices',
      technical: 'Error loading invoices from API'
    },
    NO_INVOICE: {
      message: 'Unable to open Invoice.<br />No Invoice found.',
      technical: 'No Invoice found'
    },
    SUBMIT_FAILED: {
      message: 'Failed to submit invoice',
      technical: 'Error submitting invoice to API'
    }
  }
};

interface InvoiceEditData {
  InvoiceId?: number;
  InternalInvoiceNumber?: string;
  Notes?: string;
  ProviderId?: number;
  ProviderInvoiceNumber?: string;
  InvoiceRow?: {
    Id?: number;
    AmountBilled?: number;
    SettlementValue?: number;
    TotalDueProvider?: number;
  };
  InvoicePayment?: {
    Id?: number;
    AmountPaid?: number;
    BalanceDue?: number;
    DatePaid?: string;
    PaymentStatus?: number;
  };
  InvoicePaymentPaymentStatus?: number;
}

@Component({
  selector: 'invoices-grid',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    DialogAllModule,
    DropDownListModule,
    LoadingModule,
    AddNewInvoiceForm,
    BulkEditInvoicesForm,
    BaseGridComponent
  ],
  providers: [
    ColumnChooserService,
    BaseGridHooks,
    BaseGridService
  ],
  templateUrl: './invoices-grid.component.html',
  styleUrl: './invoices-grid.component.scss'
})
export class InvoicesGridComponent extends BaseGrid {
  // ViewChild references
  @ViewChild('providersTemplate', { static: true }) providersTemplate!: string;
  @ViewChild('paymentStatusesEditTemplate', { static: true, read: TemplateRef }) paymentStatusesEditTemplate!: string;
  @ViewChild('addInvoiceDialog', { static: true}) addInvoiceDialog: DialogComponent;
  @ViewChild('editInvoiceDialog', { static: true}) editInvoiceDialog: DialogComponent;
  @ViewChild('bulkEditInvoicesDialog') bulkEditInvoicesDialog: DialogComponent;
  @ViewChild('newInvoiceForm') newInvoiceForm: AddNewInvoiceForm;
  @ViewChild('bulkEditForm') bulkEditForm: any;
  @ViewChild('providersDropdown') providersDropdown: DropDownList;
  @ViewChild('paymentStatusDropdown') paymentStatusDropdown: DropDownList;
  @ViewChild('grid') grid: GridComponent;

  // State management
  providersData: DataManager;
  paymentStatuses: DataManager;
  proceduresData: DataManager;
  loading = signal(true);

  // Add a property to track programmatic refreshes
  private isRefreshing = false;

  // Dialog buttons
  addInvoiceButtons: Object[] = [
    { click: this.closeDialog.bind(this), buttonModel: { content: 'Cancel', cssClass: 'e-outline' } },
    { click: this.clearNewInvoiceForm.bind(this), buttonModel: { content: 'Reset', isPrimary: false } },
    { click: this.onNewInvoiceSubmit.bind(this), buttonModel: { content: 'Submit', isPrimary: true, cssClass: 'e-primary' } }
  ];

  bulkUpdateInvoicesButtons: Object[] = [
    { click: this.closeDialog.bind(this), buttonModel: { content: 'Cancel', cssClass: 'e-outline' } },
    { click: this.clearBulkEditForm.bind(this), buttonModel: { content: 'Reset', isPrimary: false } },
    { click: this.onBulkEditSubmit.bind(this), buttonModel: { content: 'Submit', isPrimary: true, cssClass: 'e-primary' } }
  ];

  // Grid settings
  settings: SetGridDataArgs = {
    endpoint: APIEndpoints.Invoices,
    name: 'Invoices',
    useRoundedEdges: true,
    bulkEditing: true,
    query: new Query().expand('CaseFile($expand=Patient),InvoiceRows,InvoicePayments($expand=PaymentMethodNavigation,PaymentStatusNavigation),Provider'),
    gridSettings: {
      showColumnChooser: true,
      allowPaging: true,
      pageSettings: {
        pageSize: 20,
        pageSizes: true,
        pageCount: 5,
        enableQueryString: true
      },
      columns: [
        { type: 'checkbox' },
        { field: 'Id' },
        { field: 'CaseFile.FileNumber', headerText: 'File #', allowEditing: false },
        { field: 'CaseFile.Patient.Firstname', headerText: 'Client First Name', allowEditing: false },
        { field: 'CaseFile.Patient.Lastname', headerText: 'Client Last Name', allowEditing: false },
        { field: 'InternalInvoiceNumber', headerText: 'INV #', allowEditing: false },
        { field: 'Provider.Name', headerText: 'Provider', editTemplate: this.providersTemplate, editType: 'dropdownedit' },
        { field: 'Notes', headerText: 'Notes' },
        { type: 'commands', headerText: 'Actions' }
      ],
      toolbarClick: ($event: ToolbarClickEventArgs) => this.onToolbarClick($event),
      commandClick: ($event: CommandClickEventArgs) => this.onCommandClick($event),
      rowSelecting: ($event: RowSelectingEventArgs) => this.onRowSelecting($event),
      rowSelected: ($event: RowSelectEventArgs) => this.onRowSelected($event),
      rowDeselected: ($event: RowDeselectEventArgs) => this.onRowDeselected($event),
      rowDeselecting: ($event: RowDeselectingEventArgs) => this.onRowDeselecting($event),
      detailDataBound: ($event: DetailDataBoundEventArgs) => this.onDetailDataBound($event),
      actionBegin: ($event: EditEventArgs) => this.onActionBegin($event),
      actionComplete: ($event: EditEventArgs) => this.onActionComplete($event),
      dataStateChange: ($event: any) => this.onDataStateChange($event)
    }
  };

  // Add computed property for selected row data that ensures non-null value
  readonly selectedRowData = computed(() => this.invoicesService.selectedInvoiceValue());

  constructor(
    private toast: ToastMessageService,
    public globals: GlobalsService,
    public invoicesService: InvoicesService
  ) {
    super();

    // Subscribe to dialog visibility changes
    effect(() => {
      const visibility = this.invoicesService.dialogVisibilityValue();
      if (visibility.addInvoice) this.addInvoiceDialog?.show();
      else this.addInvoiceDialog?.hide();
      if (visibility.editInvoice) this.editInvoiceDialog?.show();
      else this.editInvoiceDialog?.hide();
      if (visibility.bulkEdit) this.bulkEditInvoicesDialog?.show();
      else this.bulkEditInvoicesDialog?.hide();
    });
  }

  ngOnInit() {
    this.getData().then(result => {
      this.settings = {
        ...this.settings,
        gridSettings: {
          ...this.settings.gridSettings,
          dataSource: result
        }
      };
      this.loading.set(false);
    });
  }

  // Fetch and set data for proper grid actions
  async getData(pageSettings: { skip: number; take: number } = { skip: 0, take: 20 }) {
    try {
      const result = await this.invoicesService.getInvoices(pageSettings);
      return result;
    } catch (error) {
      this.handleError(error, {
        context: 'InvoicesGridComponent.getData',
        userMessage: ERRORS.INVOICES.LOAD_FAILED.message,
        severity: this.ErrorSeverity.Error
      });
      throw error;
    }
  }

  // Update onDataStateChange to use the service
  async onDataStateChange(state: any) {
    // Skip if this is a refresh triggered by our code
    if (this.isRefreshing) {
      return;
    }

    if (!state.action) return;

    try {
      const response = await this.invoicesService.fetchWithStateChange(state);

      if (this.settings?.gridSettings && response) {
        // Apply the data without refreshing
        this.settings.gridSettings.dataSource = {
          result: response.result,
          count: response.count,
        };
      }

      // Don't use refresh for paging - it resets the sort
      if (state.action.requestType === 'paging' && this.grid && response) {
        // Instead of refresh(), use direct data binding
        this.grid.dataSource = {
          result: response.result,
          count: response.count,
        };

        // Use the setProperties method which is supported in the API
        this.grid.setProperties({
          dataSource: {
            result: response.result,
            count: response.count,
          }
        });
      }
    } catch (error) {
      this.handleError(error, {
        context: 'InvoicesGridComponent.onDataStateChange',
        userMessage: ERRORS.INVOICES.LOAD_FAILED.message,
        severity: this.ErrorSeverity.Error
      });
    }
  }

  onActionBegin(args: EditEventArgs) {
    if (args.requestType === 'beginEdit') {
      this.paymentStatuses = this.invoicesService.getPaymentStatuses();
      this.providersData = this.invoicesService.getProvidersData();
      this.proceduresData = this.invoicesService.getProcedureCodes();
    }

    if (args.requestType === 'save' && 'data' in args) {
      const data = args.data as InvoiceEditData;
      data.ProviderId = this.providersDropdown.value as number;
      data.InvoicePaymentPaymentStatus = this.paymentStatusDropdown.value as number;
    }
  }

  onActionComplete(args: EditEventArgs) {
    if (args.requestType === 'save' && 'data' in args && 'previousData' in args) {
      const data = args.data as InvoiceEditData;
      const previousData = args.previousData as InvoiceEditData;
      const dataChanged = JSON.stringify(data) !== JSON.stringify(previousData);

      if (dataChanged === true) {
        if (data.InvoicePayment?.DatePaid) {
          data.InvoicePayment.DatePaid = this.globals.formatDateForBackend(data.InvoicePayment.DatePaid);
        }

        this.invoicesService.updateInvoice(data);
      }
    }
  }

  onToolbarClick(args: ToolbarClickEventArgs) {
    if (args.item.text === 'Add') {
      args.cancel = true;
      this.invoicesService.setDialogVisibility('addInvoice', true);
    } else if (args.item.text === 'Edit') {
      args.cancel = true;
      this.invoicesService.setDialogVisibility('editInvoice', true);
    } else if (args.item.id === 'BulkEdit') {
      this.invoicesService.setDialogVisibility('bulkEdit', true);
    }
  }

  onCommandClick(commandClickArgs: CommandClickEventArgs) {
    const data: any = commandClickArgs?.rowData;
    const btn = commandClickArgs.commandColumn?.buttonOption;

    if (data !== undefined) {
      const viewItem: boolean | undefined = btn?.iconCss?.includes('e-eye');
      const deleteItem: boolean | undefined = btn?.iconCss?.includes('e-trash');

      if (viewItem) {
        window.open(`${window.location.origin}/case-files/${data.FileNumber}#Financial`, '_blank');
      } else if (commandClickArgs.commandColumn?.title === 'Edit') {
        commandClickArgs.cancel = true;
        this.invoicesService.setSelectedInvoice(data as Invoice);
        this.editInvoiceDialog.show();
      } else if (deleteItem) {
        console.log(commandClickArgs);
      }
    } else {
      console.error('commandClickArgs: ', commandClickArgs);
      this.toast.showError('No data found.');
    }
  }

  onRowSelected(args: RowSelectEventArgs) {
    this.invoicesService.setSelectedInvoice(args.data as Invoice);
  }

  onRowSelecting(args: RowSelectingEventArgs) {
    // Handle row selection if needed
  }

  onRowDeselected(args: RowDeselectEventArgs) {
    // Handle row deselection if needed
  }

  onRowDeselecting(args: RowDeselectingEventArgs) {
    // Handle row deselecting if needed
  }

  onDetailDataBound(e: DetailDataBoundEventArgs) {
    let detailDiv = document.createElement('div');
    detailDiv.setAttribute('id', 'detail-div');

    let grid1Div = document.createElement('div');
    grid1Div.setAttribute('id', 'grid1-div');
    grid1Div.setAttribute('style', 'width: auto;');
    let grid1Header = document.createElement('h4');
    grid1Header.innerHTML = 'Invoice Rows';
    grid1Header.setAttribute('style', 'margin: 0;');
    let detail1 = new Grid({
      dataSource: (e.data as any).InvoiceRows,
      editSettings:  { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Dialog' },
      toolbar: [{ template: '<span class="h4">Invoice Rows</span>', align: 'Left' }],
      allowFiltering: false,
      columns: [
        { type: 'checkbox', width: 50 },
        { field: 'Id', isPrimaryKey: true, visible: false },
        { field: 'DateOfService', headerText: 'Service Date', editType: 'datepickeredit',  type: 'date', format: 'yyyy-MM-dd' },
        { field: 'ProcedureCode.Description', headerText: 'Procedure', editType: 'dropdownedit' },
        { field: 'ReimbursementRate', headerText: 'Purchase Rate', editType: 'numericedit', format: 'P' },
        { field: 'AmountBilled', headerText: 'Billed Amount', format: 'C2', editType: 'numericEdit' },
        { field: 'TotalDueProvider', headerText: 'Provider Amount', format: 'C2', editType: 'numericEdit' },
        { field: 'SettlementValue', headerText: 'Settlement', format: 'C2', editType: 'numericEdit' },
      ],
      actionComplete: ($event) => this.onInvoiceRowsGridActionComplete($event)
    });

    if ((e.data as any).InvoiceRows.length > 0) {
      detail1.appendTo(grid1Div as HTMLElement);
    } else {
      grid1Header.innerHTML = 'No Additional Rows';
      grid1Div.appendChild(grid1Header);
    }

    let grid2Div = document.createElement('div');
    grid2Div.setAttribute('id', 'grid2-div');
    grid2Div.setAttribute('style', 'width: auto;');
    let grid2Header = document.createElement('h4');
    grid2Header.innerHTML = 'Invoice Payments';
    grid2Header.setAttribute('style', 'margin: 0;');
    let detail2 = new Grid({
      dataSource: (e.data as any).InvoicePayments,
      editSettings:  { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Dialog' },
      toolbar: [{ template: '<span class="h4">Invoice Payments</span>'}],
      allowFiltering: false,
      columns: [
        { type: 'checkbox', width: 50 },
        { field: 'Id', isPrimaryKey: true, visible: false },
        { field: 'DatePaid', headerText: 'Payment Date', editType: 'datepickeredit', type: 'date', format: 'yyyy-MM-dd' },
        { field: 'BalanceDue', headerText: 'Balance', format: 'C2', editType: 'numericEdit' },
        { field: 'AmountPaid', headerText: 'Amount Paid', format: 'C2', editType: 'numericEdit' },
        { field: 'PaymentMethodNavigation.Description', headerText: 'Method', editType: 'dropdownedit' },
        { field: 'PaymentStatusNavigation.Description', headerText: 'Status', editType: 'dropdownedit' }
      ],
      actionComplete: ($event) => this.onInvoicePaymentsGridActionComplete($event)
    });

    if ((e.data as any).InvoicePayments.length > 0) {
      detail2.appendTo(grid2Div as HTMLElement);
    } else {
      grid2Header.innerHTML = 'No Payments';
      grid2Div.appendChild(grid2Header);
    }

    detailDiv.appendChild(grid1Div);
    detailDiv.appendChild(grid2Div);

    ((e.detailElement as HTMLElement).querySelector('.custom-details') as HTMLElement).appendChild(detailDiv);
  }

  onInvoiceRowsGridActionComplete(args: any) {
    if (args.requestType === 'save') {
      this.invoicesService.updateInvoiceRow(args.rowData.Id, args.data);
    }
  }

  onInvoicePaymentsGridActionComplete(args: any) {
    if (args.requestType === 'save') {
      this.invoicesService.updateInvoicePayment(args.rowData.Id, args.data);
    }
  }

  closeDialog() {
    this.invoicesService.closeAllDialogs();
  }

  beforeOpening(args: any) {
    args.maxHeight = '85vh';
  }

  clearNewInvoiceForm() {
    this.invoicesService.resetInvoiceForm(this.newInvoiceForm.invoiceForm);
  }

  onNewInvoiceSubmit(args: any) {
    let form = this.newInvoiceForm.invoiceForm;

    if (this.invoicesService.submitNewInvoice(form)) {
      this.settings = {
        ...this.settings,
        query: this.settings.query
      };
      this.closeDialog();
    } else {
      this.toast.showError('Form invalid.');
    }
  }

  clearBulkEditForm() {
    this.invoicesService.resetBulkEditForm(this.bulkEditForm.bulkEditInvoicesForm);
  }

  onBulkEditSubmit(args: any) {
    let form = this.bulkEditForm.bulkEditInvoicesForm;

    if (this.invoicesService.submitBulkEdit(form)) {
      this.settings = {
        ...this.settings,
        query: this.settings.query
      };
      this.closeDialog();
    } else {
      alert('Please enter a valid form.');
    }
  }
}
