<div id="file-hub-header">

    <div class="header-info">
        @if(!isDataLoaded()) { <loading-overlay [loading]="loadingHeader" />} 
        @else {
            <div class="d-flex align-items-end">
                <h1>{{headerData().fileNumber}} / {{headerData().patient?.Firstname}} {{headerData().patient?.Lastname}}</h1>
                <span class="h4">{{headerData().comments}}</span>
            </div>
        }
    </div>
    
    <div class="file-info">
        <ejs-accordion (expanded)="expanded($event)">
            <e-accordionitems>
                <e-accordionitem>
                    <ng-template #header>
                        <div class="row">
                            <div class="col-12 col-md-1">
                                <div class="d-flex flex-column patient-info">
                                    <div class="d-flex flex-wrap">
                                        <span><label>File Status:</label></span>
                                        <span><status [status]="fileHub.caseFile?.RecordStatus?.Description ?? null" /></span>
                                    </div>
                                    <div class="d-flex justify-content-between patient-info-buttons">
                                        <ejs-tooltip content="Copy Patient Demographics">
                                            <button ejs-button 
                                                cssClass="action-button" 
                                                (click)="handleCopyPatientDemographics($event)">
                                                <fa-icon [icon]="faCopy"></fa-icon>
                                            </button>
                                        </ejs-tooltip>
                                        <ejs-tooltip content="Send Auth Request">
                                            <button ejs-button 
                                                cssClass="action-button" 
                                                (click)="emailAuthRequest()">
                                                <fa-icon [icon]="faEnvelope"></fa-icon>
                                            </button>
                                        </ejs-tooltip>
                                    </div>
                                </div>
                            </div>
        
                            <div class="col-12 col-md-11">
                                @if (!isDataLoaded() || !headerData().patient) { <loading-overlay [loading]="loadingPatient" /> } 
                                @else { <patient-info /> }
                            </div>
                        </div>
                    </ng-template>
    
                    <ng-template #content>
                        <div class="patient-info-expanded">
                            <div class="row">
                                @for (item of data.additionalInfo(); track item.value) {
                                    <div class="col {{ui.columnClass()}}">
                                        <div class="info">
                                            <label class="info-label">{{item.label}}: </label>
                                            <span class="info-text">{{item.value ?? 'N/A'}}</span>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </ng-template>
                </e-accordionitem>
            </e-accordionitems>
        </ejs-accordion>
    </div>
    

</div>