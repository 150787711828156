// Angular
import { Component, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormBuilder, FormControl, FormGroup, FormArray, Validators } from '@angular/forms';

// 3rd Party
import { GridModel } from '@syncfusion/ej2-grids';
import { DataManager, Query, ReturnOption } from '@syncfusion/ej2-data';
import { TextBoxModule, NumericTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { ButtonModule, CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { FeeSchedule, ProcedureCode } from '@models/data-contracts';
import { ApiService } from '@services/api/api.service';
import { GlobalsService } from '@services/globals/globals.service';
import { CustomAssignmentsEditorComponent } from '@grids/custom-assignments-editor/custom-assignments-editor.component';

interface medicareLocality { id: number; localityName: string; workRate: number; practiceRate: number; malpracticeRate: number; conversionFactor: number; }
interface medicareCptCode { id: number; code: string; facilityRvu: number; malpracticeRvu: number; nonFacilityRvu: number; physicianWorkRvu: number }

export const sample = [{ Id: 0, Name: 'Add Providers to the list.' }];

@Component({
  selector: 'add-fee-schedule-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    CheckBoxModule,
    DropDownListModule,
    TextBoxModule,
    NumericTextBoxModule,
    CustomAssignmentsEditorComponent
  ],
  templateUrl: './add-fee-schedule-form.component.html',
  styleUrl: './add-fee-schedule-form.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class AddFeeScheduleForm {

  constructor(
    private globals: GlobalsService,
    private api: ApiService,
    private fb: FormBuilder
  ) { }

  //Fee Schedule
  availableProvidersGridSettings: GridModel;
  assignedProvidersGridSettings: GridModel;
  newFeeScheduleForm: FormGroup;
  attachedProcedureCodesForm: FormGroup;
  assignedProviders: DataManager = new DataManager(sample); // TEMP: Updated data needed after fee schedules are updated in DB
  cptCodesData: object[] = [];
  cptCodeRepeaterVisibile: boolean = false;
  cptCodeBtnText: string = 'Add CPT Codes';

  //Medicare
  medicareCptCodesData: medicareCptCode[] = [];
  medicareLocalitiesData: medicareLocality[] = [];
  medicareParticipating = [{ name: "Participating" }, { name: "Non Participating" }, { name: "Limiting" }]
  medicareFormGroup: FormGroup = new FormGroup({
    localityId: new FormControl<number | null>(null, [Validators.required]),
    cptCodeId: new FormControl<number | null>(null, [Validators.required]),
    multiplier: new FormControl<number | null>(null, [Validators.required]),
    facility: new FormControl<boolean | null>(null, [Validators.required]),
    participating: new FormControl<string | null>(null, [Validators.required]),
  }, {});

  ngOnInit(): void {

    this.api.getOdata(APIEndpoints.ProcedureCodes)
      .executeQuery(new Query())
      .then((e: ReturnOption) => { this.cptCodesData = e.result as object[]; this.getMedicareProcedureCodes() });
    this.getMedicareLocalities()

    this.newFeeScheduleForm = new FormGroup({
      Name: new FormControl<FeeSchedule['Name']>('', [Validators.required]),
      Providers: new FormControl<FeeSchedule['Providers']>([]),
      XrefFeeScheduleProcedureCodes: new FormControl<FeeSchedule['XrefFeeScheduleProcedureCodes']>([]),
    });

    this.availableProvidersGridSettings = {
      dataSource: this.api.getOdata(APIEndpoints.Providers),
      allowPaging: false,
      selectionSettings: { mode: 'Row', type: 'Multiple' },
      editSettings: { allowEditing: false, allowAdding: true, allowDeleting: true },
      columns: [
        { field: 'Id' },
        { type: 'checkbox', width: 25, textAlign: 'Center', allowFiltering: false },
        { field: 'Name', allowFiltering: true }
      ]
    };

    this.assignedProvidersGridSettings = {
      dataSource: this.assignedProviders,
      allowPaging: false,
      selectionSettings: { mode: 'Row', type: 'Multiple' },
      editSettings: { allowEditing: false, allowAdding: true, allowDeleting: true },
      columns: [
        { field: 'Id' },
        { type: 'checkbox', width: 25, textAlign: 'Center', allowFiltering: false },
        { field: 'Name', allowFiltering: true }
      ],
    };

    this.attachedProcedureCodesForm = this.fb.group({
      entries: this.fb.array([])
    });
  }

  //Gets data for medicare cpt codes
  getMedicareProcedureCodes() {
    this.api.getOdata(APIEndpoints.MedicareCptCodes)
      .executeQuery(new Query())
      .then((e: ReturnOption) => {
        {
          let res = e.result as object[];
          res.forEach(element => {
            let elem = element as any;
            let code = (this.cptCodesData.find(x => (x as any).Id === elem.CptCode) as any).ProcedureCodeName ?? 'Error';
            this.medicareCptCodesData.push(
              {
                id: elem.Id,
                code: code,
                facilityRvu: elem.FacilityRvu,
                malpracticeRvu: elem.MalpracticeRvu,
                nonFacilityRvu: elem.NonFacilityRvu,
                physicianWorkRvu: elem.PhysicianWorkRvu,
              }
            )
          });
        }
      });
  }

  //Gets data for medicare localities
  getMedicareLocalities() {
    this.api.getOdata(APIEndpoints.MedicareLocalities)
      .executeQuery(new Query())
      .then((e: ReturnOption) => {
        {
          let res = e.result as object[];
          res.forEach(element => {
            let elem = element as any;
            this.medicareLocalitiesData.push(
              {
                id: elem.Id,
                localityName: elem.LocalityName,
                workRate: elem.WorkRate,
                practiceRate: elem.PracticeRate,
                malpracticeRate: elem.MalpracticeRate,
                conversionFactor: elem.ConversionFactor
              }
            )
          });
        }
      });
  }

  /**
   * Add Fee Schedule form functions
   */
  toggleProcedureCodeRepeater() {
    this.cptCodeRepeaterVisibile = !this.cptCodeRepeaterVisibile;
    this.cptCodeBtnText = this.cptCodeRepeaterVisibile ? 'Hide CPT Codes' : 'Add CPT Codes';

    if (this.entries.length < 1) {
      this.addEntry();
    }
  }

  onSubmit() {
    if (this.newFeeScheduleForm.valid && this.attachedProcedureCodesForm.valid) {
      this.newFeeScheduleForm.value.XrefFeeScheduleMasterCptcode = this.attachedProcedureCodesForm.value.entries as ProcedureCode[];
      console.log(this.newFeeScheduleForm.value);
      alert('Form Accepted!\nFinal functionality pending database updates with valid data.');
    }
  }

  /**
   * Add CTP form functions
   */
  get entries(): FormArray {
    return this.attachedProcedureCodesForm.get('entries') as FormArray;
  }

  addEntry(): void {
    const entryForm = this.fb.group({
      Code: new FormControl<ProcedureCode['ProcedureCodeName']>(''),
      Description: new FormControl<ProcedureCode['Description']>(''),
      // Line Items below need fetch updated 
      // ReimbursementRate: new FormControl<ProcedureCode['ReimbursementRate']>(0),
      // BilledAmount: new FormControl<ProcedureCode['BilledAmount']>(0),
      // DueAmount: new FormControl<ProcedureCode['DueAmount']>(0),
      // SplitInvoice: new FormControl<ProcedureCode['SplitInvoice']>(false),
      IsMedicare: new FormControl<any>(false),
    });
    this.entries.push(entryForm);
  }

  removeEntry(): void {
    this.entries.removeAt(this.entries.length - 1);
  }

  changedProcedureCode(args: any) {
    this.newFeeScheduleForm.value.XrefFeeScheduleMasterCptcode = this.attachedProcedureCodesForm.value.entries as ProcedureCode[];
  }

  //Submits the medicare billing rates
  submitMedicare(index: number) {
    let locality = this.medicareLocalitiesData.find((x) => (x as any).id === this.medicareFormGroup.value.localityId) as any;
    let cptCode = this.medicareCptCodesData.find((x) => (x as any).id === this.medicareFormGroup.value.cptCodeId) as any;
    let practiceRvu = 0
    if (this.medicareFormGroup.value.facility) {
      practiceRvu = cptCode.facilityRvu;
    }
    else {
      practiceRvu = cptCode.nonFacilityRvu;
    }
    this.entries.controls[index].patchValue({ BilledAmount: this.calculateFee(cptCode.physicianWorkRvu, practiceRvu, cptCode.malpracticeRvu, locality.workRate, locality.practiceRate, locality.malpracticeRate, locality.conversionFactor, this.medicareFormGroup.value.multiplier) })
  }

  //Gets the billing amount based on medicare rates
  calculateFee(workRvu: number, practiceRvu: number, malpracticeRvu: number, workModifier: number, practiceModifier: number, malpracticeModifier: number, conversionFactor: number, multiplier: number) {
    let baseFee = ((workRvu * workModifier) + (practiceRvu * practiceModifier) + (malpracticeRvu * malpracticeModifier)) * conversionFactor * multiplier
    if (this.medicareFormGroup.value.participating === "Participating") {
      return baseFee * .95
    }
    if (this.medicareFormGroup.value.participating === "Limiting") {
      return baseFee * .95 * 1.15
    }
    if (this.medicareFormGroup.value.participating === "Non Participating") {
      return baseFee
    }
    return baseFee
  }
}
