// Angular
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Services
import { AuthenticatedServiceBase } from '@core/auth/auth.base';
import { ErrorConfig, ErrorSeverity } from '@core/error/error.types';

/**
 * Base service class for grid services
 * Provides common functionality and state management
 */
@Injectable({
  providedIn: 'root'
})
export class BaseService extends AuthenticatedServiceBase {
  constructor() {
    super();
  }

  get endpoint(): string {
    return '';
  }
} 