// Angular
import { Component, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CommonModule } from '@angular/common';

// Syncfusion
import { GridComponent, CommandModel, GridModel, CommandClickEventArgs } from '@syncfusion/ej2-angular-grids';
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';
import { faPenToSquare, faTrash, faFileLines, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Query } from '@syncfusion/ej2-data';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { CaseFile, CaseFileTask } from '@models/data-contracts';
import { GlobalsService } from '@services/globals/globals.service';
import { ApiService } from '@services/api/api.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { GridTemplateModule } from '@modules/grid-template.module';
import { BulkEditCaseFilesForm } from '@root/src/app/components/forms/bulk-edit-forms/bulk-edit-case-file/bulk-edit-case-file.component';
import { GridTemplateComponent } from '@grids/grid-template/grid-template.component';
import { CaseFileForm } from "@forms/case-file-form/case-file-form.component";
import { UserPreferencesService } from '@services/user/user-preferences.service';
import { AuditLogsComponent } from '@ui/audit-logs/audit-logs.component';
import { AuditLogService } from '@root/src/app/shared/services/audit-logs/audit-log-service';

@Component({
  selector: 'case-files-grid',
  standalone: true,
  imports: [
    CommonModule,
    DialogModule,
    GridTemplateModule,
    BulkEditCaseFilesForm,
    CaseFileForm,
    AuditLogsComponent
],
  templateUrl: './case-files-grid.component.html',
  styleUrl: './case-files-grid.component.scss'
})
export class CaseFilesGridComponent {

  constructor (
    private api: ApiService,
    private globals: GlobalsService,
    private toast: ToastMessageService,
    private user: UserPreferencesService,
    private auditLogService: AuditLogService
  ) { }  

  // Initialize variables for caseFilesGrid
  @ViewChild('caseFilesGrid') caseFilesGrid: GridComponent;
  @ViewChild('caseFiles') caseFiles: GridTemplateComponent;
  isResponsive: Boolean = this.globals.isResponsive;
  caseFilesGridSettings: GridModel;
  faPenToSquare: IconDefinition = faPenToSquare;
  faTrash: IconDefinition = faTrash;
  faFileLines: IconDefinition = faFileLines;
  // Add sample log data
  sampleLogData = [
    {
      type: 'updated',
      user: 'Scott White',
      dateTime: '2024-12-10T14:46:01.696817Z',
      oldValues: {
        "FileNumber": 1,
        "CaseNumber": "Test",
        "ProviderType": 0,
        // ... rest of old values
      },
      newValues: {
        "FileNumber": 1,
        "CaseNumber": "Test",
        "ProviderType": 0,
        // ... rest of new values
      }
    },
    {
      type: 'updated',
      user: 'Jacob Bruen',
      dateTime: '2024-12-10T14:46:01.696817Z',
      oldValues: {
        "FileNumber": 1,
        "CaseNumber": "Test",
        "ProviderType": 0,
        // ... rest of old values
      },
      newValues: {
        "FileNumber": 1,
        "CaseNumber": "Test",
        "ProviderType": 0,
        // ... rest of new values
      }
    }
    // Add more sample entries as needed
  ];
  auditLogs: any[] = [];
  auditLogsLoading = false;
  caseFilesGridDefaultToolbar: any[] = [
    { template: `<span class="h2">Case Files</span>` },
    { text: 'Add New Case File', tooltipText: 'Add File', id: 'AddFile' },
    'Cancel', 
    'ColumnChooser'
  ];
  caseFilesGridEditCaseFileToolbar: any[] = [
    { template: `<span class="h2">Case Files</span>` },
    { text: 'Add New Case File', tooltipText: 'Add File', id: 'AddFile' }, 
    { text: 'Edit Case File', tooltipText: 'Edit File', id: 'EditFile'}, 
    'Cancel', 
    'ColumnChooser'
  ];
  caseFilesGridBulkEditToolbar: any[] = [
    { template: `<span class="h2">Case Files</span>` },
    { text: 'Add New Case File', tooltipText: 'Add File', id: 'AddFile' }, 
    { text: 'Bulk Edit Files', tooltipText: 'Bulk Edit', id: 'BulkEdit' }, 
    'Cancel', 
    'ColumnChooser'
  ]
  caseFilesGridActionsColumnCommands: CommandModel[] = [
    { type: 'Edit', title: 'Edit', buttonOption: { iconCss: 'e-icons e-edit', cssClass: 'e-flat' }  },
    { type: 'None', title: 'Logs', buttonOption: { iconCss: 'e-icons e-description', cssClass: 'e-flat' } },
  ];

  // Custom dialogs for adding & bulk editing files
  @ViewChild('addCaseFileDialog', { static: true}) addCaseFileDialog: DialogComponent;
  @ViewChild('newFileForm') newFileForm: CaseFileForm;
  @ViewChild('editFileForm') editFileForm: CaseFileForm;
  @ViewChild('bulkEditForm') bulkEditForm: BulkEditCaseFilesForm;
  @ViewChild('fileNumberDisplayTemplate', { static: true }) fileNumberDisplayTemplate!: string;
  @ViewChild('lawFirmTemplate', { static: true }) lawFirmTemplate!: string;
  addCaseFileDialogVisibility: Boolean = false;
  modalTarget: string = '#case-files-grid';
  formattedDBData: CaseFile[] = [];
  selectedRecords: any[] = [];
  batchChangedRecords: any[] = [];
  addCaseFileButtons: Object[] = [
    { click: this.closeDialog.bind(this), buttonModel: { content: 'Cancel', cssClass: 'e-outline' } },
    { click: this.clearCaseFileForm.bind(this), buttonModel: { content: 'Reset', isPrimary: false } },
    { click: this.onNewCaseFileSubmit.bind(this), buttonModel: { content: 'Submit', isPrimary: true, cssClass: 'e-primary' } }
  ];

  // Custom dialog for bulk editing files
  @ViewChild('bulkEditCaseFilesDialog') bulkEditCaseFilesDialog: DialogComponent;
  bulkEditCaseFilesDialogVisibility: Boolean = false;
  bulkUpdateCaseFilesButtons: Object[] = [
    { click: this.closeDialog.bind(this), buttonModel: { content: 'Cancel', cssClass: 'e-outline' } },
    { click: this.clearBulkEditForm.bind(this), buttonModel: { content: 'Reset', isPrimary: false } },
    { click: this.onBulkEditSubmit.bind(this), buttonModel: { content: 'Submit', isPrimary: true, cssClass: 'e-primary' } }
  ];

  // Single edit form
  @ViewChild('editCaseFileDialog', { static: true}) editCaseFileDialog: DialogComponent;
  editCaseFileDialogVisibility: Boolean = false;
  editCaseFileFile: CaseFile;
  editCaseFileButtons: object[] = [
    { click: this.closeDialog.bind(this), buttonModel: { content: 'Cancel', cssClass: 'e-outline' } },
    { click: this.clearCaseFileForm.bind(this), buttonModel: { content: 'Reset', isPrimary: false } },
    { click: this.onEditCaseFileSubmit.bind(this), buttonModel: { content: 'Submit', isPrimary: true, cssClass: 'e-primary' } }
  ];

  @ViewChild('logsDialog', { static: true }) logsDialog: DialogComponent;
  logsDialogVisibility: Boolean = false;
  logsDialogButtons: Object[] = [
    { click: this.closeLogsDialog.bind(this), buttonModel: { content: 'Close', cssClass: 'e-flat' } }
  ];

  ngOnInit() {
    this.getData();
    this.caseFilesGridSettings = {
      dataSource: this.api.getOdata(APIEndpoints.Casefiles),
      query: new Query().expand('CaseFileTasks,LawFirmContactNavigation,AttorneyNavigation,CaseManagerNavigation,LawFirm,MarketManagerNavigation,PAndLNavigation,ReferringPhysicianNavigation,ReferralSourceNavigation,Patient($expand=Address($expand=StateNavigation))'),
      toolbar: this.globals.isResponsive ? ['Add', 'Update', 'Cancel'] : this.caseFilesGridDefaultToolbar,
      columns: [
        { type: 'checkbox' },
        { field: 'Id' },
        { field: 'FileNumber', headerText: 'File Number', allowEditing: false, template: this.fileNumberDisplayTemplate },
        { field: 'Patient', headerText: 'Client Name', valueAccessor: (field: string, data: Object): string[] => this.getPatientName(field, data) },
        { field: 'Patient.Dob', headerText: 'DOB', format: 'yMd' },
        { field: 'Patient.Minor', headerText: 'Minor', visible: false },
        { field: 'ResponsibleParty', headerText: 'Responsible Party', visible: false },
        { field: 'Patient.Address.StateNavigation.Description', headerText: 'State' },
        { field: 'Patient.Address.County', headerText: 'County', visible: false },
        { field: 'LawFirm.Name', headerText: 'Law Firm', template: this.lawFirmTemplate },
        { field: 'LawFirmContactNavigation.ContactName', headerText: 'Law Firm Contact', visible: false },
        { field: 'AttorneyNavigation.ContactName', headerText: 'Attorney', visible: false },
        { field: 'FileOpened', headerText: 'File Opened', allowEditing: false, format: 'yMd' },
        { field: 'InTreatment', headerText: 'In Treatment' },
        { field: 'IsSurgical', headerText: 'Surgical', visible: false },
        { field: 'CaseManagerNavigation.Name', headerText: 'Case Manager', visible: false },
        { field: 'CaseFileTasks', headerText: 'Next Date of Activity', type: 'date', editType: 'datepickeredit', format: 'yMd', valueAccessor: (field: string, data: object): string => this.getNextDateOfActivity(field, data) },
        { field: 'Status', headerText: 'Record Status', visible: false },
        { field: 'ReferralSourceNavigation.Description', headerText: 'Referral Source', visible: false },
        { field: 'DateOfLoss', headerText: 'Date of Loss', visible: false },
        { field: 'AdditionalDatesOfLoss', headerText: 'Additional Dates of Loss', visible: false },
        { field: 'MarketManagerNavigation.Name', headerText: 'Market Manager', visible: false },
        { field: 'Patient.Email', headerText: 'Client Email', visible: false },
        { field: 'Patient.Phone', headerText: 'Client Phone', visible: false },
        { field: 'Patient.Language', headerText: 'Client Language', visible: false },
        { field: 'LawFirmContactNavigation.Email', headerText: 'Law Firm Contact Email', visible: false },
        { field: 'Comments', headerText: 'Comments', allowFiltering: false, allowSorting: false, valueAccessor: (field: string, data: object): string[] => this.getCommentValues(field, data), visible: false },
        { field: 'PAndLNavigation.Description', headerText: 'P&L', visible: false },
        { field: 'LawFirmFileNumber', headerText: 'Law Firm File Number', visible: false },
        { field: 'BalanceDue', headerText: 'Balance Due', visible: false },
        { field: 'Statuser', headerText: 'Statuser', visible: false },
        { field: 'SelectFiles', headerText: 'Select Files', visible: false },
        { type: 'commands', commands: this.caseFilesGridActionsColumnCommands, headerText: 'Actions' }
      ],
      dataBound: ($event: any) => this.onDataBound($event),
      toolbarClick: ($event: any) => this.onCustomToolbarClick($event),
      rowSelected: ($event: any) => this.onRowSelected($event),
      rowSelecting: ($event: any) => this.onRowSelecting($event),
      rowDeselected: ($event: any) => this.onRowDeselected($event),
      rowDeselecting: ($event: any) => this.onRowDeselecting($event),
      commandClick: ($event: CommandClickEventArgs) => this.onCommandClick($event)
    }
  }

  // Ensures foreign key fields have data ready for display
  async getData() {
  await this.api.getOdata(`${APIEndpoints.Casefiles}`).executeQuery(new Query().expand('CaseFileTasks')).then((res: any) => {
      const caseFiles = ((res as any).result as CaseFile[]);
    });
  }

  getPatientName(field: string, data: object): string[] {
    const patient = (data as any)[field];
    return [patient.Firstname, patient.Lastname];
  }

  getNextDateOfActivity(field: string, data: object): string {
    const tasks = (data as any)[field];
    
    // If tasks is undefined or empty, return an empty string
    if (!tasks || tasks.length === 0)  return '';
  
    try {
      const mostRecentTask = tasks.reduce((latest: CaseFileTask, task: CaseFileTask) => {
        // Ensure both tasks have a valid NextDateOfActivity
        if (!latest?.NextDateOfActivity) return task;
        if (!task?.NextDateOfActivity) return latest;
  
        return new Date(task.NextDateOfActivity) > new Date(latest.NextDateOfActivity) ? task : latest;
      }, tasks[0]);
  
      // Check if mostRecentTask and its NextDateOfActivity exist
      if (mostRecentTask?.NextDateOfActivity) return new Date(mostRecentTask.NextDateOfActivity).toLocaleDateString();
      
      // If no valid NextDateOfActivity, return an empty string
      return '';

    } catch (error) {
      console.error('Error in getNextDateOfActivity:', error);
      return '';
    }
  }

  // Value accessor for displaying strings in comments column
  getCommentValues(field: string, data: object): string[] {
    if ((data as any)[field]) {
      return ((data as any)[field as string]);
    } else {
      return [''];
    }
  }

  onDataBound(args: any) {
    this.caseFilesGrid = this.caseFiles.grid;
  }

  // Add logic for rendering modals
  onCustomToolbarClick(args: any) {
    
    if (args.item.id === 'AddFile') {
      this.addCaseFileDialogVisibility = true;
      this.clearBulkEditForm();
      if (this.addCaseFileDialog) this.addCaseFileDialog.show();
    } 

    if (args.item.id === 'EditFile') {
      this.editCaseFileFile = this.caseFilesGrid.getSelectedRecords()[0] as CaseFile;
      this.editCaseFileDialogVisibility = true;
      if (this.editCaseFileDialog) this.editCaseFileDialog.show();
    } 
    
    if (args.item.id === 'BulkEdit') {
      this.bulkEditCaseFilesDialogVisibility = true;
      if (this.bulkEditCaseFilesDialog) this.bulkEditCaseFilesDialog.show();
    }
  }

  // Add logic for editing individual case files
  async onCommandClick(commandClickArgs: CommandClickEventArgs) {
    const file: CaseFile | undefined = (commandClickArgs.rowData as CaseFile);

    if (file) {

      if (commandClickArgs.commandColumn?.type === 'Edit') {
        commandClickArgs.cancel = true;
        this.editCaseFileFile = commandClickArgs.rowData as CaseFile;
        
        if (file.FileNumber) {
          this.editCaseFileDialogVisibility = true;
          if (this.editCaseFileDialog) this.editCaseFileDialog.show();
        }

      } else if (commandClickArgs.commandColumn?.type === 'None' && commandClickArgs.commandColumn?.title === 'Logs') {
        commandClickArgs.cancel = true;
        
        if (file.Id) {
          this.auditLogsLoading = true;
          this.showLogsDialog();
          await this.auditLogService.getAuditLogs(file.Id, 'CaseFile').then((logs) => {
            this.auditLogs = this.auditLogService.mapAuditDataToLogFormat(logs);
            this.auditLogsLoading = false;
          });
        }
      } else {
        commandClickArgs.cancel = true;
        this.caseFiles.onCommandClick(commandClickArgs);
      }

    } else {
      console.error('commandClickArgs: ', commandClickArgs);
      this.toast.showError('Unable to open Case File.<br />No Case Number found.');
    }
  }

  // New method to show audit logs dialog
  showLogsDialog() {
    this.logsDialogVisibility = true;
    if (this.logsDialog) {
        this.logsDialog.show();
    }
  }

  // Add logic to open case file when case number clicked
  caseNumberClick(data: any) {
    const openFiles = this.user.getLocalUserPreference('openFiles') ?? [];

    if (data.CaseNumber) {
      const foundFile = openFiles.find((obj: any) => obj['id'] === data.CaseNumber) as any;
      
      if (!foundFile) {
        openFiles.push({id: data.CaseNumber, name: `${data.Patient.Firstname} ${data.Patient.Lastname}`});
        this.user.saveLocalUserPreference('openFiles', openFiles);
      }

      window.open(`${window.location.href}/hub?fileNumber=${data.CaseNumber}`, '_blank'); // TEMPORARY: opens link in new tab for testing
    } else {
      this.toast.showError('No Case Number found.')
    }
  }

  onRowSelected(args: any) {
    this.selectedRecords = this.caseFilesGrid.getSelectedRecords();
    this.updateToolbar();
  }

  // Detect selection of multiple rows
  onRowSelecting(args: any) {
    this.selectedRecords = this.caseFilesGrid.getSelectedRecords();
    this.updateToolbar();
  }

  onRowDeselected(args: any) {
    this.selectedRecords = this.caseFilesGrid.getSelectedRecords();
    this.updateToolbar();
  }

  // Occurs as rows are deselected
  onRowDeselecting(args: any) {
    this.selectedRecords = this.caseFilesGrid.getSelectedRecords();
    this.updateToolbar();
  }

  // Set toolbar according to number of selected rows
  updateToolbar() {
    
    if (this.selectedRecords && this.selectedRecords.length) {
      const length = this.selectedRecords.length;

      if (length === 1) {
        this.caseFilesGrid.toolbar = this.caseFilesGridEditCaseFileToolbar;
      } else {
        this.caseFilesGrid.toolbar = this.caseFilesGridBulkEditToolbar;
      }

    } else {
      this.caseFilesGrid.toolbar = this.caseFilesGridDefaultToolbar;
    }
  }

  // Close both modal windows
  closeDialog() {
    if (this.addCaseFileDialog) this.addCaseFileDialog.hide(); this.addCaseFileDialogVisibility = false;
    if (this.editCaseFileDialog) this.editCaseFileDialog.hide(); this.editCaseFileDialogVisibility = false; 
    if (this.bulkEditCaseFilesDialog) this.bulkEditCaseFilesDialog.hide(); this.bulkEditCaseFilesDialogVisibility = false;
    if (this.logsDialog) this.logsDialog.hide(); this.logsDialogVisibility = false;
  }

  // Set height of modals
  beforeOpening(args: any) {
    args.maxHeight = '100%';
  }

  // Set fields to blank
  clearCaseFileForm() {
    if (this.newFileForm) this.newFileForm.caseFileForm.reset();
    if (this.editFileForm) this.editFileForm.caseFileForm.reset();
  }

  // Add new file to grid
  onNewCaseFileSubmit(args: any) {
    let caseFileForm = this.newFileForm.caseFileForm;
    caseFileForm.markAllAsTouched();

    if (caseFileForm.valid) {
      this.formattedDBData.push(this.mapFormToCaseFile(caseFileForm.value));
      this.caseFilesGrid?.editModule.addRecord(caseFileForm.value);
      this.closeDialog();
    } else {
      this.toast.showError('Form invalid.');
    }
  }

  onEditCaseFileSubmit(args: any) {
    let caseFileForm = this.editFileForm.caseFileForm;
    caseFileForm.markAllAsTouched();

    if (caseFileForm.valid && this.editCaseFileFile) {
      console.log(caseFileForm.value);
      try {
        this.api.fetchRequest(`odata${APIEndpoints.Casefiles}/${this.editCaseFileFile.Id}`, 'PATCH', caseFileForm.value).then((res: any) => {
          console.log(res);
          if (res.ok) {
            this.caseFilesGrid.refresh();
            this.closeDialog();
          }
        });
      } catch (error) {
        console.error('Error in onEditCaseFileSubmit:', error);
      }
      
      // Close the dialog
      this.closeDialog();
    } else {
      this.toast.showError('Form invalid.');
    }
  }

  // Set bulk editing fields to blank
  clearBulkEditForm() {
    (this.bulkEditForm.bulkEditCaseFilesForm as FormGroup).reset();
  }

  // Apply bulk changes to data
  onBulkEditSubmit(args: any) {
    const comp = this;
    let form = this.bulkEditForm.bulkEditCaseFilesForm;

    if (form.valid) {

      this.selectedRecords.forEach((record) => {
        for (const [key, value] of Object.entries(this.bulkEditForm.bulkEditCaseFilesForm.value)) {
          const updatedValue: any = value;
          if (value !== null) {
            this.caseFilesGrid.updateCell(record, key, updatedValue);
          }
        }
      })
      this.closeDialog();
      this.mapBulkEditChanges();
    } else {
      console.log((this.bulkEditForm.bulkEditCaseFilesForm as FormGroup).errors);
      alert('Please enter a valid form.');
    }
  }

  // Function to map form values to an object of type CaseFile
  mapFormToCaseFile(caseFile: CaseFile): CaseFile {

    // When PatientId !== 0, existing Patient selected and new info should be removed 
    if (caseFile.PatientId !== 0) {
      delete caseFile.Patient;
    } else {

      // When PatientId === 0, new entry expected
      if (caseFile.Patient) {

        // When AddressId !== 0, existing Address selected and new info should be removed 
        if (caseFile.Patient?.AddressId !== 0) {
          delete caseFile.Patient?.Address;
        }
  
        if (caseFile.Patient?.Dob) {
          caseFile.Patient.Dob = this.globals.formatDateForBackend(caseFile.Patient.Dob);
        }
      }
    }

    // Convert CompanionCases array to string for backend csv parsing
    if (caseFile.CompanionCases) {
      caseFile.CompanionCases = caseFile.CompanionCases.toString();
    }

    if (caseFile.FileOpened) {
      caseFile.FileOpened = this.globals.formatDateForBackend(caseFile.FileOpened);
    }

    if (caseFile.DateOfLoss) {
      caseFile.DateOfLoss = this.globals.formatDateForBackend(caseFile.DateOfLoss);
    }

    if (caseFile.StatuteOfLimitations) {
      caseFile.StatuteOfLimitations = this.globals.formatDateForBackend(caseFile.StatuteOfLimitations);
    }

    return caseFile;
  }

  // Ensure data is properly formatted before batchSave()
  mapBulkEditChanges() {
    let changedRecords: any[] = []

    for (const [key, value] of Object.entries(this.bulkEditForm.bulkEditCaseFilesForm.value)) {
      if (value !== null) {
        let newObj = { [key]: value };
        changedRecords.push(newObj);
      }
    }
  }

  closeLogsDialog() {
    if (this.logsDialog) {
        this.logsDialog.hide();
        this.logsDialogVisibility = false;
    }
  }
}
