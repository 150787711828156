<div id="work-items-grid">
    <div *ngIf="workItems?.dataSource">
        <grid-template 
            #workItemsGrid 
            [settings]="workItems" 
            [name]="'Tasks'" 
            [useRoundedEdges]="true"
            (toolbarClick)="customToolbarClick($event)"
            (commandClick)="onCommandClick($event)"
            (created)="onCreated()"
            (dataBound)="onDataBound($event)"
            (dataStateChange)="onPageChange($event)">
        </grid-template>
    </div>

    <ng-template #nameTemplate let-data>
        <a class="link" (click)="onWorkItemClick(data)">
            <span>WI #{{data.Id}}</span>
        </a>
    </ng-template>

    <ng-template #caseFilesTemplate let-data>
        <span *ngFor="let xref of data.XrefWorkItemCaseFiles; let last = last">
            <a class="link" (click)="onCaseFileClick(xref.CaseFile)">
                {{xref.CaseFile?.FileNumber}}
            </a>{{!last ? ', ' : ''}}
        </span>
    </ng-template>

    <ng-template #providerTemplate let-data>
        <span style="color: rgb(var(--color-sf-primary)); font-weight: bold">
            {{data.XrefWorkItemProviders}}
        </span>
    </ng-template>

    <ng-template #dueDateTemplate let-data>
        <span [style]="isOverdue(data.DueDate) ? 'color: rgb(200, 50, 50); font-weight: bold' : ''">
            {{data.DueDate | date:'MM/dd/yyyy'}}
        </span>
    </ng-template>

    <ng-template #priorityColorTemplate let-data>
        <div class="e-checkbox-wrapper e-css" [style.background-color]="getPriorityColor(data)">
            <input type="checkbox" class="e-checkbox" [id]="'checkbox_' + data.Id" />
            <span class="e-checkbox-wrapper" aria-checked="false">
                <span class="e-frame"></span>
            </span>
        </div>
    </ng-template>

    <!-- Add dialog template -->
    <ejs-dialog 
        #descriptionDialog 
        [visible]="false"
        [header]="'Notes'"
        [buttons]="descriptionDialogButtons"
        [isModal]="true"
        [width]="'800px'"
        [showCloseIcon]="true">
        <ng-template #content>
            <div class="description-dialog-content">
                <pre style="white-space: pre-wrap; line-height: 1.6; font-family: inherit;">{{selectedWorkItem()?.Description}}</pre>
            </div>
        </ng-template>
    </ejs-dialog>

    <!-- Update description template to show preview and click handler -->
    <ng-template #descriptionTemplate let-data>
        <div class="description-preview" (click)="showDescription(data)">
            {{data.Description}}
        </div>
    </ng-template>

    <!-- Work Item Form Modal -->
    <ejs-dialog 
        #workItemsDialog 
        [visible]="false"
        [header]="''"
        [buttons]="workItemDialogButtons"
        [isModal]="true"
        [width]="'1000px'"
        [showCloseIcon]="true">
        <ng-template #content>
            <work-item-form 
                #workItemFormComponent 
                [workItem]="selectedWorkItem" 
                [inCaseFileContext]="inCaseFileContext"
                [caseFileId]="caseFileId"
                [displayButtons]="false"
                (formSubmitted)="submitWorkItemForm()"
                (formCancelled)="cancelWorkItemForm()">
            </work-item-form>
        </ng-template>
    </ejs-dialog>

    <!-- Bulk Edit Component -->
    <bulk-edit-work-items
        #bulkEditWorkItemsComponent
        [selectedItems]="selectedWorkItems"
        (completed)="onBulkEditComplete($event)">
    </bulk-edit-work-items>

    <!-- Complete Task Confirmation Dialog -->
    <ejs-dialog 
        #completeTaskDialog 
        [visible]="false"
        [header]="'Complete Task'"
        [isModal]="true"
        [width]="'450px'"
        [showCloseIcon]="true"
        [buttons]="completeTaskButtons">
        <ng-template #content>
            <div class="confirm-dialog-content">
                <div class="confirm-message">
                    Do you wish to mark this task as completed?
                </div>
            </div>
        </ng-template>
    </ejs-dialog>

    <!-- Already Completed Dialog -->
    <ejs-dialog 
        #alreadyCompletedDialog 
        [visible]="false"
        [header]="'Task Already Complete'"
        [isModal]="true"
        [width]="'450px'"
        [showCloseIcon]="true"
        [buttons]="alreadyCompletedButtons">
        <ng-template #content>
            <div class="confirm-dialog-content">
                <div class="confirm-message">
                    This task is already complete, please use edit to make further changes
                </div>
            </div>
        </ng-template>
    </ejs-dialog>
</div>  