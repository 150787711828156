export const FINANCIAL_ERRORS = {
    CASE_FILE_MISSING: {
        message: 'Case file information not available',
        technical: 'Case file ID is undefined'
    },
    LOAD_FAILED: {
        message: 'Unable to load financial data',
        technical: 'Failed to load financial data from API'
    }
} as const;
