import { Injectable } from '@angular/core';
import { ToastUtility } from '@syncfusion/ej2-notifications';

@Injectable({
  providedIn: 'root'
})
export class ToastMessageService {
  
  private readonly defaultConfig = {
    position: { X: 'Right', Y: 'Top' },
    timeOut: 5000,
    showCloseButton: true,
    animation: { show: { effect: 'FadeIn' }, hide: { effect: 'FadeOut' } }
  };

  private readonly icons = {
    error: '<i class="fas fa-exclamation-circle me-2"></i>',
    warning: '<i class="fas fa-exclamation-triangle me-2"></i>',
    success: '<i class="fas fa-check-circle me-2"></i>',
    info: '<i class="fas fa-info-circle me-2"></i>',
    neutral: '<i class="fas fa-info me-2"></i>'
  };

  showError(message: string): void {
    this.showToast({
      title: `${this.icons.error} Error`,
      content: message,
      cssClass: 'e-toast-danger toast-custom',
    });
  }

  showWarning(message: string): void {
    this.showToast({
      title: `${this.icons.warning} Warning`,
      content: message,
      cssClass: 'e-toast-warning toast-custom',
    });
  }

  showSuccess(message: string): void {
    this.showToast({
      title: `${this.icons.success} Success`,
      content: message,
      cssClass: 'e-toast-success toast-custom',
    });
  }

  showInfo(message: string): void {
    this.showToast({
      title: `${this.icons.info} Info`,
      content: message,
      cssClass: 'e-toast-info toast-custom',
    });
  }

  showNeutral(message: string): void {
    this.showToast({
      title: `${this.icons.neutral} Notice`,
      content: message,
      cssClass: 'e-toast-neutral toast-custom',
    });
  }

  private showToast(config: any): void {
    ToastUtility.show({
      ...this.defaultConfig,
      ...config,
      cssClass: `${config.cssClass} e-toast-clickable`
    });
  }
}
