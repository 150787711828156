// Angular
import { Component, OnInit, signal, ViewChild, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';

// 3rd Party
import { Query } from '@syncfusion/ej2-data';
import { CommandClickEventArgs, CommandModel, GridModel, RowSelectEventArgs } from '@syncfusion/ej2-grids';
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';

// Internal
import { GlobalsService } from '@services/globals/globals.service';
import { ApiService } from '@services/api/api.service';
import { APIEndpoints } from '@models/api/Endpoints';
import { FeeSchedule } from '@models/data-contracts';
import { GridTemplateComponent } from '@grids/grid-template/grid-template.component';
import { AddFeeScheduleForm } from '@root/src/app/components/forms/add-forms/add-fee-schedule-form/add-fee-schedule-form.component';
import { LoadingModule } from '@root/src/app/shared/modules/loading.module';
@Component({
  selector: 'fee-schedule-grid',
  standalone: true,
  imports: [
    CommonModule,
    DialogModule,
    GridTemplateComponent,
    AddFeeScheduleForm,
    LoadingModule
  ],
  templateUrl: './fee-schedule-grid.component.html',
  styleUrl: './fee-schedule-grid.component.scss'
})

export class FeeScheduleGridComponent implements OnInit {
  
  constructor(
    private globals: GlobalsService,
    private api: ApiService,
  ) {}

  // Decorator variables
  @ViewChild('feeSchedulesGrid') feeSchedulesGrid: GridTemplateComponent;
  @ViewChild('addFeeScheduleDialog', { static: true}) addFeeScheduleDialog: DialogComponent;
  @ViewChild('addFeeScheduleForm') addFeeScheduleForm: any;

  // Signals
  loading: WritableSignal<boolean> = signal(true);

  // Public
  feeSchedulesGridSettings: GridModel;
  providersEditorVisible: boolean = false;
  assignedProvidersIds: any;
  isAddFeeScheduleDialogVisible: boolean = false;
  existingFeeSchedule: WritableSignal<FeeSchedule | null> = signal(null);
  commands: CommandModel[] = [
    { buttonOption: { iconCss: 'e-icons e-edit', cssClass: 'e-flat' }, title: 'Edit' }, 
  ];
  

  ngOnInit(): void {
    this.setGridData();
  }

  async setGridData() {
    const endpoint = `${APIEndpoints.FeeSchedules}`;
    const query = new Query().expand(["XrefFeeScheduleProcedureCodes($expand=ProcedureCode)","Providers"]);
    
    await this.api.getOdata(endpoint).executeQuery(query).then((res: any) => {
      const data = res.result.map((schedule: any) => {
        return {
          ...schedule,
          TotalProcedureCodes: schedule.XrefFeeScheduleProcedureCodes.length,
          TotalProviders: schedule.Providers.length
        }
      });
      this.feeSchedulesGridSettings = {
        dataSource: data,
        toolbar: this.globals.isResponsive ? 
          [{ text: 'Add Fee Schedule', tooltipText: 'Add Schedule', id: 'AddSchedule' }, 'Edit', 'Update', 'Cancel', 'Delete'] : 
          [{ text: 'Add Fee Schedule', tooltipText: 'Add Schedule', id: 'AddSchedule' }, 'Update', 'Cancel', 'Delete', 'ColumnChooser'],
        columns: [
          { field: 'Id', isPrimaryKey: true, visible: false },
          { field: 'Name' },
          { field: 'TotalProcedureCodes', headerText: 'Total Procedure Codes'},
          { field: 'TotalProviders', headerText: 'Total Providers'},
          { type: 'commands', commands: this.commands, headerText: 'Actions', visible: this.globals.isResponsive ? false : true }
        ],
        toolbarClick: ($event: Event) => this.onToolbarClick($event),
        commandClick: ($event: CommandClickEventArgs) => this.commandClick($event)
      }
    });

    this.loading.set(false);
  }

  // Return total items as string to display in feeSchedulesGrid
  countFieldItems(field: string, data: object): string[] {
    // Ensure data exists
    if ((data as any)[field]) {
      const items = (data as any)[field];      
      return [items.length.toString()];
    } else {
      return ['0'];
    }
  }

  // Update height before modal opened
  beforeOpenAddFeeScheduleDialog(args: any) {
    args.maxHeight = '85vh';
  }

  // Add logic for custom Add Fee Schedule button
  onToolbarClick(args: any): void {
    if (args.item.id === 'AddSchedule') {
      this.addFeeScheduleDialog.show();
    } 
  }

   // Add logic for actions on individual rows
  commandClick(commandClickArgs: CommandClickEventArgs) {
    const rowData: FeeSchedule | undefined = (commandClickArgs?.rowData as FeeSchedule);
    if (rowData && rowData.Id) {
      const editFeeScheduleClicked: boolean | undefined = commandClickArgs.commandColumn?.buttonOption?.iconCss?.includes('e-edit');
      if (editFeeScheduleClicked) {
        this.existingFeeSchedule.set(rowData);
        this.addFeeScheduleDialog.show();
      }
    }
  }

  handleSubmitComplete(): void {
    // Handle invoice form
    if (this.isAddFeeScheduleDialogVisible) {
      this.existingFeeSchedule.set(null);
      this.addFeeScheduleDialog.hide();
      this.isAddFeeScheduleDialogVisible = false;
    }
    // Refresh grid data
    this.setGridData();
  }
}
