// Angular
import { 
  Component, 
  ViewChild, 
  ElementRef, 
  Input, 
  ContentChild, 
  TemplateRef, 
  HostListener, 
  Output,
  EventEmitter} from '@angular/core';
import { CommonModule } from '@angular/common';

// 3rd Party
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';

@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule
  ],
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})

export class ModalComponent {
  @ViewChild('modal') modal!: ElementRef;
  @Input() openModalLabel: string | null = null;
  @Input() btnClass: string | null = null;
  @Input() btnVisibile: boolean | null = true;
  @Input() modalContent: any | null = null;
  @Input() isPrimary: boolean | null = false;
  @ContentChild(TemplateRef) modalContentTemplate!: TemplateRef<any>;
  
  isModalOpen: boolean = false;
  @Output() modalStatusChange = new EventEmitter<boolean>();

  openModal(e: Event): void {
    e.preventDefault();
    this.isModalOpen = true;
    this.modalStatusChange.emit(this.isModalOpen)
  }

  closeModal(): void {
    this.isModalOpen = false;
    this.modalStatusChange.emit(this.isModalOpen)
  }

  preventDefault(e: Event): void {
    e.preventDefault();
  }

  @HostListener('document:keydown', ['$event'])
  onKeyPress(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.closeModal();
    }
  }
}