<div id="contacts-tab">
  
  <basic-grid #contactsGrid [gridSettings]="contactsGridSettings" />

  <ejs-dialog #contactDialog
    [visible]="false"
    [isModal]="true"
    [showCloseIcon]="true"
    [closeOnEscape]="true"
    [buttons]="contactDialogButtons"
    (close)="contactDialog.hide()"
    
    >
    <contact-form-component 
      #contactFormComponent 
      [isLawFirm]="isLawFirm" 
      [selectedAccountId]="selectedAccountId" 
      [contact]="selectedContact" 
      [displayButtons]="false" 
      [displayTitle]="false"
      (formSubmitted)="contactDialog.hide();" />
  </ejs-dialog>

</div>