<form [formGroup]="addressFormGroup" class="row search-form">
    <div class="col-6">
        <ejs-textbox required id="address" formControlName="Address" floatLabelType="Auto" placeholder="Address" />
    </div>
    <div class="col-6">
        <ejs-textbox required id="address2" formControlName="Address2" floatLabelType="Auto"
            placeholder="Address Line 2" />
    </div>
    <div class="col-5">
        <ejs-textbox required id="city" formControlName="City" floatLabelType="Auto" placeholder="City">
        </ejs-textbox>
    </div>
    <div class="col-3">
        <ejs-textbox required id="county" formControlName="County" floatLabelType="Auto" placeholder="County">
        </ejs-textbox>
    </div>
    <div class="col-2">
        <app-dropdown-single id="state" name="State" placeholder="State" [endpoint]=states descriptionName="Code"
            [form]="addressFormGroup" [defaultValue]="defaultState"></app-dropdown-single>
    </div>
    <div class="col-2">
        <ejs-textbox required id="zip" formControlName="Zip" floatLabelType="Auto" placeholder="Zip">
        </ejs-textbox>
    </div>
</form>