// Angular
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Query } from '@syncfusion/ej2-data';

// 3rd Party
import { ToastMessageService } from '@services/toast-message/toast-message.service';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { Deposit } from '@models/data-contracts';
import { ApiService } from '@services/api/api.service';
import { BaseGridService } from '@shared/components/base-grid/services/state.service';
import { ComponentBase } from '@core/base/component.base';
import { ErrorSeverity } from '@core/error/error.types';

// Interfaces
interface DepositFormData {
  DepositDate: string;
  DepositAmount: number;
  FinalCheck: boolean;
  Notes?: string;
  [key: string]: any;
}

interface CaseFileNavigationParams {
  fileNumber: string;
  hash?: string;
}

@Injectable({
  providedIn: 'root'
})
export class DepositsService extends ComponentBase {
  // Injections
  private readonly api = inject(ApiService);
  private readonly router = inject(Router);
  private readonly toast = inject(ToastMessageService);
  private readonly gridService = inject(BaseGridService);

  // Public constants for grid configuration
  public readonly ENDPOINT = APIEndpoints.Deposits;
  public readonly DEFAULT_EXPAND = ['DepositToAccountNavigation', 'CaseFile($expand=Patient)'];

  // Error Messages
  private readonly ERROR_MESSAGES = {
    BULK_EDIT_SELECTION: {
      message: 'Please select at least two deposits to bulk edit.',
      technical: 'Attempted bulk edit with insufficient selection'
    },
    FORM_INVALID: 'Form invalid.',
    UPDATE_FAILED: 'Failed to update deposits.',
    NAVIGATION_FAILED: 'Unable to open file. No File Number associated with Deposit.',
    NO_DATA: 'No data found.'
  };

  /**
   * Grid Operations
   */
  async refreshGridData(): Promise<void> {
    try {
      await this.gridService.refreshData();
    } catch (error) {
      this.handleError(error, {
        context: 'DepositsService.refreshGridData',
        userMessage: 'Failed to refresh grid data',
        severity: ErrorSeverity.Error
      });
      throw error;
    }
  }

  /**
   * Form Operations
   */
  async submitNewDeposit(formData: DepositFormData): Promise<void> {
    try {
      await this.api.fetchRequest(`odata${this.ENDPOINT}`, 'POST', formData);
      await this.refreshGridData();
    } catch (error) {
      this.handleError(error, {
        context: 'DepositsService.submitNewDeposit',
        userMessage: 'Failed to create new deposit',
        severity: ErrorSeverity.Error
      });
      throw error;
    }
  }

  async submitBulkEdit(records: Deposit[], formData: DepositFormData): Promise<void> {
    if (records.length < 2) {
      this.handleError(new Error(this.ERROR_MESSAGES.BULK_EDIT_SELECTION.technical), {
        context: 'DepositsService.submitBulkEdit',
        userMessage: this.ERROR_MESSAGES.BULK_EDIT_SELECTION.message,
        severity: ErrorSeverity.Warning
      });
      return;
    }

    try {
      const dateOnly = new Date(formData.DepositDate).toISOString().slice(0, 10);
      const updateData = { ...formData, DepositDate: dateOnly };

      await Promise.all(records.map(row =>
        this.api.fetchRequest(`odata${this.ENDPOINT}/${row.Id}`, 'PATCH', updateData)
      ));

      await this.refreshGridData();
    } catch (error) {
      this.handleError(error, {
        context: 'DepositsService.submitBulkEdit',
        userMessage: this.ERROR_MESSAGES.UPDATE_FAILED,
        severity: ErrorSeverity.Error
      });
      throw error;
    }
  }

  /**
   * Data Operations
   */
  async getDepositById(id: number): Promise<Deposit> {
    try {
      return await this.api.fetchRequest(`odata${this.ENDPOINT}/${id}`, 'GET');
    } catch (error) {
      this.handleError(error, {
        context: 'DepositsService.getDepositById',
        userMessage: 'Failed to fetch deposit',
        severity: ErrorSeverity.Error
      });
      throw error;
    }
  }

  async updateDeposit(id: number, data: DepositFormData): Promise<void> {
    try {
      await this.api.fetchRequest(`odata${this.ENDPOINT}/${id}`, 'PATCH', data);
      await this.refreshGridData();
    } catch (error) {
      this.handleError(error, {
        context: 'DepositsService.updateDeposit',
        userMessage: 'Failed to update deposit',
        severity: ErrorSeverity.Error
      });
      throw error;
    }
  }

  /**
   * Navigation Operations
   */
  async navigateToCaseFile(params: CaseFileNavigationParams): Promise<void> {
    try {
      const { hash, ...queryParams } = params;
      const queryString = new URLSearchParams(queryParams).toString();
      const fullUrl = `/case-files/hub?${queryString}`;
      const fragmentHash = hash ? `#${hash}` : '';

      await this.router.navigateByUrl(fullUrl + fragmentHash, {
        replaceUrl: true,
        skipLocationChange: false
      });
    } catch (error) {
      this.handleError(error, {
        context: 'DepositsService.navigateToCaseFile',
        userMessage: 'Failed to navigate to case file',
        severity: ErrorSeverity.Error
      });
      throw error;
    }
  }

  /**
   * Validation Operations
   */
  validateDepositForm(formData: DepositFormData): boolean {
    return !!(
      formData.DepositDate &&
      formData.DepositAmount &&
      typeof formData.FinalCheck === 'boolean'
    );
  }

  /**
   * Error Handling
   */
  handleCommandClick(data: any, iconCss: string): void {
    if (!data) {
      this.toast.showError(this.ERROR_MESSAGES.NO_DATA);
      return;
    }

    const viewItem = iconCss?.includes('e-eye');
    if (viewItem) {
      if (data.CaseFile?.FileNumber) {
        this.navigateToCaseFile({ fileNumber: data.CaseFile.FileNumber, hash: 'Financial' });
      } else {
        this.toast.showError(this.ERROR_MESSAGES.NAVIGATION_FAILED);
      }
    }
  }
}
