<div class="content">
    <h4>Single Procedure Code</h4>
    <form class="row" [formGroup]="newProcedureCodeForm" (ngSubmit)="onSubmit()">

        <div class="form-group col-12 required">
            <ejs-textbox formControlName="Code" floatLabelType="Auto" placeholder="Procedure Code"></ejs-textbox>
        </div>

        <div class="form-group col-12">
            <ejs-dropdownlist formControlName="Modality" [dataSource]='modalityData' [fields]='modalityFields'
                floatLabelType="Auto" placeholder="Modality"></ejs-dropdownlist>
        </div>

        <div class="form-group col-12 required">
            <ejs-textbox formControlName="Description" floatLabelType="Auto" placeholder="Description"></ejs-textbox>
        </div>
        <spacer-element></spacer-element>
        <h4>Upload Procedure Codes From CSV File</h4>

        <div class="form-group col-12">
            <!--<ejs-uploader #defaultupload [asyncSettings]='path' [autoUpload]='false' allowedExtensions = '.xlsx,.csv'></ejs-uploader>-->
            <input type="file" class="upload" accept=".csv" (change)="uploadListener($event.target)">
            <a href="assets/files/sample-proc-codes.csv">Download Sample File</a>
        </div>

        <div class="col-10"> </div>
        <div class="col-2 form-submit ">
            <button ejs-button class="e-primary e-flat" type="submit" isPrimary="true"
                [disabled]="newProcedureCodeForm.invalid">Submit</button>
        </div>
    </form>
</div>