import { Injectable } from '@angular/core';
import { LoggingService } from './logging.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastMessageService } from '@services/toast-message/toast-message.service';

export interface ErrorDetails {
  userMessage?: string;
  technicalDetails?: any;
  severity: 'error' | 'warning' | 'info';
  source?: string;
  errorId?: string;
}

/**
 * Central error handling service for Case Compass.
 *
 * Responsibilities:
 * - Processes errors into user-friendly messages
 * - Generates unique error IDs for tracking
 * - Logs technical details via LoggingService
 * - Shows user-facing messages via ToastMessageService
 *
 * Flow:
 * 1. Error occurs in component/service
 * 2. Error passed to handleError() with context
 * 3. Error processed into ErrorDetails
 * 4. Technical details logged
 * 5. User-friendly message displayed
 *
 * @example
 * // In a component:
 * try {
 *   await this.saveData();
 * } catch (error) {
 *   this.errorHandling.handleError(error, 'MyComponent.saveData');
 * }
 */
@Injectable({ providedIn: 'root' })
export class ErrorHandlingService {
  constructor(
    private toast: ToastMessageService,
    private loggingService: LoggingService
  ) { }

  /**
   * Central error handling method
   */
  handleError(error: any, context?: string): void {
    const errorDetails = this.processError(error, context);

    // Log technical details
    this.loggingService.log({
      message: errorDetails.userMessage || 'An error occurred',
      level: 'error',
      context: {
        source: errorDetails.source,
        errorId: errorDetails.errorId,
        technicalDetails: errorDetails.technicalDetails
      },
      error: error
    });

    // Show user-friendly message
    this.toast.showError(`${errorDetails.userMessage || 'An error occurred'} (Error ID: ${errorDetails.errorId})`);
  }

  private processError(error: any, context?: string): ErrorDetails {
    const errorId = this.generateErrorId();

    // Handle HTTP errors
    if (error instanceof HttpErrorResponse) {
      return {
        userMessage: this.getUserMessageForHttpError(error),
        technicalDetails: {
          status: error.status,
          statusText: error.statusText,
          url: error.url,
          error: error.error
        },
        severity: 'error',
        source: context,
        errorId
      };
    }

    // Handle other errors
    return {
      userMessage: 'An unexpected error occurred. Our team has been notified.',
      technicalDetails: {
        message: error.message,
        stack: error.stack,
        originalError: error
      },
      severity: 'error',
      source: context,
      errorId
    };
  }

  private getUserMessageForHttpError(error: HttpErrorResponse): string {
    switch (error.status) {
      case 400:
        return 'The request was invalid. Please try again.';
      case 402:
        return 'Please log in to continue.';
      case 401:
        return 'You do not have permission to perform this action.';
      case 404:
        return 'The requested resource was not found.';
      case 500:
      default:
        return 'An unexpected error occurred. Our team has been notified.';
    }
  }

  private generateErrorId(): string {
    return `ERR-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
  }
}
