<div id="bulk-edit-deposits">

    <form id="bulk-edit-case-files-form" [formGroup]="bulkEditDepositsForm" #formDir="ngForm" class="d-flex flex-wrap mt-4 row">
        <div class="col-12 col-md-6">
            <label>Date Paid</label>
        </div>
        <div class="col-12 col-md-6">
            <ejs-datepicker formControlName="DepositDate"></ejs-datepicker>
        </div>
        <div class="col-12 col-md-6">
            <label>Notes</label>
        </div>
        <div class="col-12 col-md-6">
            <ejs-textbox [multiline]="true" formControlName="Notes"></ejs-textbox>
        </div>
    </form>

</div>