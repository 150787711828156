// Angular
import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

// 3rd Party
import { TabAllModule, TabAnimationSettingsModel, TabItemModel } from '@syncfusion/ej2-angular-navigations';
import { faFileInvoiceDollar, faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule, IconDefinition } from '@fortawesome/angular-fontawesome';

// Internal
import { InvoicesGridComponent } from '@root/app/features/accounting/features/invoices/components/invoices-grid/invoices-grid.component';
import { DepositsGridComponent } from '@root/app/features/accounting/features/deposits/components/deposits-grid/deposits-grid.component';

export interface TabItemWithIcon extends TabItemModel {
  faIcon: IconDefinition;
}

@Component({
  selector: 'accounting',
  standalone: true,
  imports: [
    TabAllModule,
    InvoicesGridComponent,
    DepositsGridComponent,
    FontAwesomeModule,
    CommonModule,
  ],
  templateUrl: './accounting.component.html',
  styleUrl: './accounting.component.scss'
})
export class AccountingComponent {

  constructor () {}

  @ViewChild('invoicesGrid', { static: true }) invoicesGrid!: string;
  @ViewChild('depositsGrid', { static: true }) depositsGrid!: string;
  faFileInvoiceDollar = faFileInvoiceDollar;
  faMoneyBill = faMoneyBill;
  tabItems: TabItemWithIcon[] = [
    { id: 'invoices', header: { text: 'Invoices' }, content: this.invoicesGrid, faIcon: this.faFileInvoiceDollar },
    { id: 'deposits', header: { text: 'Deposits' }, content: this.depositsGrid, faIcon: this.faMoneyBill }
  ];

}
