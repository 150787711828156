// Angular
import { Component, ViewChild, ViewEncapsulation, Input, effect, computed, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

// 3rd Party
import { AccordionModule } from '@syncfusion/ej2-angular-navigations';

// Models
import { Appointment, CaseFile } from '@models/data-contracts';

// Services
import { SchedulerService } from '@services/scheduler/scheduler.service';
import { SchedulerSignalsService } from '@root/src/app/shared/services/scheduler/scheduler-signals.service';

// Components
import { SchedulerLocationsComponent } from './scheduler-locations/scheduler-locations.component';
import { SchedulerModalitiesComponent } from './scheduler-modalities/scheduler-modalities.component';
import { SchedulerCalendarComponent } from './scheduler-calendar/scheduler-calendar.component';
import { SchedulerAppointmentsListComponent } from './scheduler-appointments-list/scheduler-appointments-list.component';
import { ComponentBase } from '@root/app/core';

const ERRORS = {
  CALENDAR: {
    DATE_RANGE_CALCULATION_FAILED: {
      message: 'Failed to calculate date range',
      technical: 'Error calculating date range'
    },
    INITIALIZATION_FAILED: {
      message: 'Failed to initialize calendar',
      technical: 'Error initializing calendar data'
    },
    APPOINTMENT_SELECTION_FAILED: {
      message: 'Failed to select appointment',
      technical: 'Error selecting appointment in calendar'
    },
    FETCH_APPOINTMENTS_FAILED: {
      message: 'Failed to load appointments',
      technical: 'Error fetching appointments for date range'
    },
    VIEW_CHANGE_FAILED: {
      message: 'Failed to change calendar view',
      technical: 'Error handling calendar view change'
    }
  }
}

@Component({
  selector: 'scheduler',
  standalone: true,
  imports: [
    CommonModule,
    AccordionModule,
    SchedulerLocationsComponent,
    SchedulerModalitiesComponent,
    SchedulerCalendarComponent,
    SchedulerAppointmentsListComponent
  ],  
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SchedulerComponent extends ComponentBase {
  
  constructor(
    public schedulerSignals: SchedulerSignalsService
  ) { 
    super();
    
    effect(() => {
      const locations = this.schedulerSignals.data.Locations();
      const modalities = this.schedulerSignals.data.Modalities();
      
      if (locations && modalities) {
        this.loadLocations = signal(locations.length <= 1 ? false : true);
        this.loadModalities = signal(modalities.length <= 1 ? false : true);
        this.schedulerClass = this.loadLocations() && this.loadModalities() ? 'col-12 col-md-10 calendar-content' : 'col-12 calendar-content';
      }
    });
  }

  // Decorator variables
  @Input() appointments: Appointment[];
  @Input() caseFile: CaseFile | undefined;
  @Input() excludeFields: string[] = [];
  @Input() includeAppointmentsList: boolean = false;
  @ViewChild('locationsComponent') locationsComponent: SchedulerLocationsComponent;
  @ViewChild('modalitiesComponent') modalitiesComponent: SchedulerModalitiesComponent;
  @ViewChild('scheduleFilter') scheduleFilter: AccordionModule;
  @ViewChild('schedulerCalendarComponent') schedulerCalendarComponent: SchedulerCalendarComponent;

  // Signals
  protected readonly locations = computed(() => this.schedulerSignals.data.Locations());
  protected readonly modalities = computed(() => this.schedulerSignals.data.Modalities());
  protected readonly modalityTypes = computed(() => this.schedulerSignals.data.ModalityTypes());

  // Public properties
  schedulerClass: string = 'col-12 calendar-content';
  loadLocations = signal(false);
  loadModalities = signal(false);

  // Add initialization flag
  private isInitialized = false;

  ngOnInit() {
    if (!this.isInitialized) {
      this.initializeCalendarData();
    }
  }
  
  // Add method to calculate date range for current view
  calculateDateRange() {
    try {
      if (this.schedulerCalendarComponent?.calendar) {
        const calendar = this.schedulerCalendarComponent.calendar;
        const currentView = calendar.currentView;
        const currentDate = calendar.selectedDate || new Date();
        
        let startDate = new Date(currentDate);
        let endDate = new Date(currentDate);
        
        // Adjust date range based on current view
        if (currentView === 'Day') {
          // Just use the current date
        } else if (currentView === 'Week' || currentView === 'WorkWeek') {
          // Get start of week and end of week
          const dayOfWeek = startDate.getDay();
          startDate.setDate(startDate.getDate() - dayOfWeek);
          endDate.setDate(endDate.getDate() + (6 - dayOfWeek));
        } else if (currentView === 'Month') {
          // Get start of month and end of month
          startDate.setDate(1);
          endDate.setMonth(endDate.getMonth() + 1);
          endDate.setDate(0);
        }
        
        // Add buffer days to ensure we have appointments just outside the visible range
        startDate.setDate(startDate.getDate() - 7); // one week before
        endDate.setDate(endDate.getDate() + 7); // one week after
        
        return { startDate, endDate };
      }
      return null;
    } catch (error) {
      this.handleError(error, {
        context: 'SchedulerComponent.calculateDateRange',
        userMessage: ERRORS.CALENDAR.DATE_RANGE_CALCULATION_FAILED.message,
        severity: this.ErrorSeverity.Error
      });
      return null;
    }
  }
  
  async initializeCalendarData() {
    try {
      if (this.isInitialized) {
        return; // Skip if already initialized
      }
      
      // Load dependencies first
      await Promise.all([
        this.schedulerSignals.fetchLocations(),
        this.schedulerSignals.fetchModalityTypes(),
        this.schedulerSignals.fetchModalities(),
      ]);
      
      // Initial loading of appointments for current date
      const today = new Date();
      const startDate = new Date(today);
      startDate.setDate(startDate.getDate() - 7); // 1 week before
      const endDate = new Date(today);
      endDate.setDate(endDate.getDate() + 21); // 3 weeks ahead
      
      // Get caseFileId if available
      const caseFileId = this.caseFile?.Id;
      
      // Pass caseFileId parameter if it exists
      await this.schedulerSignals.fetchAppointments(startDate, endDate, caseFileId);
      
      // Make sure the calendar refreshes after initial data load
      setTimeout(() => {
        if (this.schedulerCalendarComponent) {
          this.schedulerCalendarComponent.refreshAppointments();
        }
      }, 100); // Small delay to ensure component is initialized
      
      // Mark as initialized
      this.isInitialized = true;
    } catch (error) {
      this.handleError(error, {
        context: 'SchedulerComponent.initializeCalendarData',
        userMessage: ERRORS.CALENDAR.INITIALIZATION_FAILED.message,
        severity: this.ErrorSeverity.Error
      });
    }
  }

  // Add method to handle appointment selection
  onAppointmentSelected(appointment: Appointment) {
    try {
      if (this.schedulerCalendarComponent) {
        const selectedDate = new Date(appointment.StartDatetime as Date);
        this.schedulerCalendarComponent.selectedDate = selectedDate;
      }
    } catch (error) {
      this.handleError(error, {
        context: 'SchedulerComponent.onAppointmentSelected',
        userMessage: ERRORS.CALENDAR.APPOINTMENT_SELECTION_FAILED.message,
        severity: this.ErrorSeverity.Error
      });
    }
  }

  // In the template, add (viewChanged)="onViewChanged($event)" to the scheduler-calendar component
  onViewChanged(dateRange: {startDate: Date, endDate: Date}) {
    try {
      // Get caseFileId if available
      const caseFileId = this.caseFile?.Id;
      
      // Fetch appointments for the new date range
      this.schedulerSignals.fetchAppointments(dateRange.startDate, dateRange.endDate, caseFileId)
        .then(() => {
          // Force refresh in the calendar component after data is loaded
          if (this.schedulerCalendarComponent) {
            this.schedulerCalendarComponent.refreshAppointments();
          }
        })
        .catch((error) => {
          this.handleError(error, {
            context: 'SchedulerComponent.onViewChanged.fetchAppointments',
            userMessage: ERRORS.CALENDAR.FETCH_APPOINTMENTS_FAILED.message,
            severity: this.ErrorSeverity.Error
          });
        });
    } catch (error) {
      this.handleError(error, {
        context: 'SchedulerComponent.onViewChanged',
        userMessage: ERRORS.CALENDAR.VIEW_CHANGE_FAILED.message,
        severity: this.ErrorSeverity.Error
      });
    }
  }
}