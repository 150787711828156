// Angular
import {
  Component,
  Inject,
  Input,
  signal,
  SimpleChanges,
  WritableSignal,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormsModule } from '@angular/forms';

// 3rd Party
import {
  NumericTextBoxModule,
  TextBoxModule,
} from '@syncfusion/ej2-angular-inputs';

// Internal
import {
  BalanceDue,
  FinancialData,
  FinancialService,
} from '@services/financial/financial.service';
import { LoadingModule } from '@modules/loading.module';
import { FinancialPerformanceService } from '@services/financial/financial-performance.service';
import { PerformanceData } from '@models/components/financial-performance.model';

interface FormData {
  roic: number;
  reductionAmount: number;
  reductionPct: number;
}
@Component({
  selector: 'financial-info',
  standalone: true,
  imports: [
    TextBoxModule, 
    LoadingModule, 
    NumericTextBoxModule, 
    FormsModule
  ],
  templateUrl: './financial-info.component.html',
  styleUrl: './financial-info.component.scss',
})
export class FinancialInfoComponent {
  
  constructor(
    @Inject(FinancialService) private financial: FinancialService,
    private fPerformance: FinancialPerformanceService
  ) {}

  @Input() caseFileId?: number;
  @Input() balanceDue?: BalanceDue;
  @Input() isReductionPage?: boolean;
  @Input() parentSelectedOption?: string;
  isLoading: WritableSignal<any> = signal(true);
  financialData?: FinancialData;
  financialPerformanceData: PerformanceData;
  formData: FormData = {
    roic: 0,
    reductionAmount: 0,
    reductionPct: 0,
  };

  @Output() reductionChange = new EventEmitter<{amount: number | null, percentage: number | null}>();

  // for financial tab
  ngOnInit() {
    if(!this.isReductionPage) {
      this.fPerformance.returnPerformanceDataFetch(this.caseFileId as number).then((performanceData: PerformanceData) => {
        this.financialPerformanceData = performanceData;
        this.isLoading.set(false);
      });
    }
  }


  // only for approve-reduction-request form
  ngOnChanges(changes: SimpleChanges) {
      if (changes['caseFileId'] && this.isReductionPage) {
        if (this.caseFileId) {
          this.financial
          .finReductionPercent(this.formData.reductionPct, this.caseFileId)
          .then((response) => {
            this.financialData = response;
            if (this.financialData.roic) {
              this.formData.roic = this.financialData.roic;
            }
            this.isLoading.set(false);
          });
        }
    }
  }

  onReductionAmountChange() {
    if (this.caseFileId) {
      if (this.balanceDue?.amountBilled) {
        this.formData.reductionPct = (this.formData.reductionAmount / this.balanceDue?.amountBilled) * 100;
      }

      this.financial
        .finReductionAmount(this.formData.reductionAmount, this.caseFileId)
        .then((response) => {
          this.financialData = response;
          this.formData.roic = this.financialData?.roic || this.financialData?.roi;
          this.reductionChange.emit({
            amount: this.formData.reductionAmount,
            percentage: this.formData.reductionPct
          });
        });
    }
  }

  onReductionPercentChange() {
    if (this.caseFileId) {
      if (this.balanceDue?.amountBilled) {
        this.formData.reductionAmount = (this.formData.reductionPct / 100) * this.balanceDue.amountBilled;
      }

      this.financial
        .finReductionPercent(this.formData.reductionPct, this.caseFileId)
        .then((response) => {
          this.financialData = response;
          if (this.financialData?.roic) {
            this.formData.roic = this.financialData.roic;
          }
          this.reductionChange.emit({
            amount: this.formData.reductionAmount,
            percentage: this.formData.reductionPct
          });
          this.isLoading.set(false);
        });
    }
  }

  onRoicChange() {

    if (this.caseFileId) {
      this.financial
        .finRoicValue(this.formData.roic, this.caseFileId)
        .then((response) => {
          this.financialData = response;
          console.log(response)
          if (this.financialData?.roic) this.formData.roic = this.financialData.roic;
          if (this.financialData?.courtesyReduction) this.formData.reductionAmount = this.financialData.courtesyReduction
          if (this.financialData?.courtesyReductionPct)this.formData.reductionPct = this.financialData.courtesyReductionPct
        });
    }
  }
}
