import { Injectable } from '@angular/core';
import { Observable, from, firstValueFrom } from 'rxjs';
import { Query } from '@syncfusion/ej2-data';
import { PerformanceData, DEFAULT_PERFORMANCE_DATA } from '../models/financial-performance.model';
import { CaseFile, Deposit, Invoice, InvoicePayment, InvoiceRow } from '@models/data-contracts';
import { countDays } from '@shared/utils/date.utils';
import { AuthenticatedServiceBase } from '@core/auth/auth.base';
import { FINANCIAL_ERRORS } from '../constants/errors';

interface ODataResponse<T> {
    result: T[];
    count?: number;
}

interface CashFlowDates {
    Date: string;
    Amount: number;
}

@Injectable({
    providedIn: 'root'
})
export class FinancialPerformanceService extends AuthenticatedServiceBase {
    protected override readonly endpoint = this.APIEndpoints.FinancialPerformance;

    // Add a cache property to avoid repeated calls for auth limit percentage
    private authLimitWarningCache: number | undefined = undefined;
    private authLimitWarningLoaded: boolean = false;

    constructor() {
        super();
    }

    calculatePerformanceData(caseFile: CaseFile, invoices: Invoice[], deposits: Deposit[]): Required<PerformanceData> {
        if (!caseFile || !invoices || !deposits) {
            return { ...DEFAULT_PERFORMANCE_DATA };
        }

        let performance = { ...DEFAULT_PERFORMANCE_DATA } as Required<PerformanceData>;

        // Calculate from invoices
        invoices?.forEach((invoice: Invoice) => {
            invoice.InvoiceRows?.forEach((invoiceRow: InvoiceRow) => {
                performance.TotalBilledCost += invoiceRow.AmountBilled as number;
                performance.FullSettlementValue += invoiceRow.SettlementValue as number;
            });

            invoice.InvoicePayments?.forEach((invoicePayment: InvoicePayment) => {
                performance.ActualSettlementCost += invoicePayment.AmountPaid as number;
            });
        });

        // Calculate from deposits
        deposits?.forEach((deposit: Deposit, index: number) => {
            performance.TotalPaymentReceived += deposit.DepositAmount as number;
            performance.CourtesyReduction += deposit.CourtesyReduction as number;
            performance.FinalBalanceDueGenerated = index === deposits.length - 1 ? deposit.FinalCheck as boolean : false;
        });

        // Calculate derived values
        performance.DaysOpen = countDays(caseFile.FileOpened)?.toString() ?? 'Unknown';
        performance.FinalFSV = performance.FullSettlementValue - performance.CourtesyReduction;
        performance.BalanceDue = performance.FinalFSV - performance.TotalPaymentReceived;
        performance.Profit = performance.FinalFSV - performance.ActualSettlementCost;
        performance.ROIC = performance.ActualSettlementCost !== 0
            ? (performance.Profit / performance.ActualSettlementCost).toString()
            : 'Unknown';

        return performance;
    }

    async getPerformanceData(caseFileId: number): Promise<Required<PerformanceData>> {
        const endpoint = `${this.APIEndpoints.Casefiles}(${caseFileId})`;
        const query = new Query()
            .expand('Invoices($expand=InvoiceRows($expand=ProcedureCode),InvoicePayments),Deposits,SignedAuthorizations')
            .select('Invoices,Deposits,SignedAuthorizations');

        const response = await this.api.getOdata(endpoint).executeQuery(query) as unknown as ODataResponse<CaseFile>;
        const file = response.result[0];

        if (!file) {
            return { ...DEFAULT_PERFORMANCE_DATA };
        }

        const performance = this.calculatePerformanceData(
            file,
            file.Invoices || [],
            file.Deposits || []
        );

        // Add authorization amount
        const signedAuthAmount = file.SignedAuthorizations
            ?.filter((auth: any) => auth.AuthType === 'File Authorization Limit')
            ?.reduce((sum: number, auth: any) => sum + (Number(auth.Amount) || 0), 0) || 0;

        performance.AuthorizedAmount = signedAuthAmount;

        return performance;
    }

    calculateDetailedPerformance(caseFileId: number): Promise<Required<PerformanceData>> {
        // Implementation of calculateDetailedPerformance method
        throw new Error('Method not implemented');
    }

    async calculateXIRRFromCaseFile(caseFile: CaseFile): Promise<number | null> {
        try {
            if (!caseFile?.Deposits || !caseFile?.Invoices) {
                return null;
            }

            // Need at least one positive and one negative cash flow for XIRR
            const deposits = caseFile.Deposits
                .filter(d => d.DepositAmount && d.DepositDate)
                .map(d => ({
                    Date: d.DepositDate!,
                    Amount: Number(d.DepositAmount)  // Positive cash flow
                }));

            const payments = caseFile.Invoices.flatMap(invoice =>
                invoice.InvoicePayments?.filter(p => p.AmountPaid && p.DatePaid)
                    .map(p => ({
                        Date: p.DatePaid!,
                        Amount: -Number(p.AmountPaid)  // Negative cash flow
                    })) || []
            );

            // XIRR requires both positive and negative cash flows
            if (deposits.length === 0 || payments.length === 0) {
                return null;
            }

            // Send array directly, not wrapped in an object
            const response = await this.api.fetchRequest(
                'api/financial/xirr',
                'POST',
                [...deposits, ...payments].sort((a, b) =>
                    new Date(a.Date).getTime() - new Date(b.Date).getTime()
                )
            );

            return response as number;

        } catch (error) {
            this.handleError(error, {
                context: 'FinancialPerformanceService.calculateXIRRFromCaseFile',
                userMessage: FINANCIAL_ERRORS.LOAD_FAILED.message
            });
            return null;
        }
    }

    async getAuthLimitWarning(): Promise<number | undefined> {
        // If we've already loaded the value, return it from cache
        if (this.authLimitWarningLoaded) {
            return this.authLimitWarningCache;
        }

        try {
            const query = new Query()
                .where('VariableName', 'equal', 'auth_limit_percentage')
                .select('VariableName,VariableValue');

            const response: any = await this.api.getOdata(this.APIEndpoints.ConfigVariables).executeQuery(query);

            if (!response || !response.result) {
                throw new Error('Invalid response when fetching auth limit percentage');
            }

            // Set the cache value
            this.authLimitWarningCache = response?.result?.[0]?.VariableValue
                ? parseInt(response.result[0].VariableValue.replace('%', ''))
                : undefined;

            // Mark as loaded
            this.authLimitWarningLoaded = true;

            return this.authLimitWarningCache;
        } catch (error) {
            this.handleError(error, {
                context: 'FinancialPerformanceService.getAuthLimitWarning',
                userMessage: FINANCIAL_ERRORS.LOAD_FAILED.message,
                technicalDetails: {
                    endpoint: this.APIEndpoints.ConfigVariables,
                    variable: 'auth_limit_percentage',
                    timestamp: new Date().toISOString()
                }
            });
            return undefined;
        }
    }

    async getDetailedPerformanceData(caseFileId: number): Promise<Required<PerformanceData>> {
        try {
            if (!caseFileId) {
                throw new Error(FINANCIAL_ERRORS.CASE_FILE_MISSING.technical);
            }

            const endpoint = `${this.APIEndpoints.Casefiles}(${caseFileId})`;
            const query = new Query()
                .expand('Invoices($expand=InvoiceRows($expand=ProcedureCode),InvoicePayments),Deposits,SignedAuthorizations')
                .select('Invoices,Deposits,SignedAuthorizations');

            const response = await this.api.getOdata(endpoint).executeQuery(query) as unknown as ODataResponse<CaseFile>;
            const file = response.result[0];

            if (!file) {
                return { ...DEFAULT_PERFORMANCE_DATA };
            }

            const performance = this.calculatePerformanceData(file, file.Invoices || [], file.Deposits || []);
            return performance;
        } catch (error) {
            this.handleError(error, {
                context: 'FinancialPerformanceService.getDetailedPerformanceData',
                userMessage: FINANCIAL_ERRORS.LOAD_FAILED.message
            });
            // Return default data on error
            return { ...DEFAULT_PERFORMANCE_DATA };
        }
    }
}
