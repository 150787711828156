import { Component } from '@angular/core';
import { Appointment } from '@models/data-contracts';
import { SignalRService } from '@services/api/signal-r.service';

@Component({
  selector: 'app-test2',
  standalone: true,
  imports: [],
  templateUrl: './test2.component.html',
  styleUrl: './test2.component.scss'
})
export class Test2Component {

  public selectedAppointment: Appointment = {
    CaseFileId: 38,
    ProviderId: 1,
    StartDatetime: new Date("2024-05-13T13:30:00.000Z"),
    EndDatetime: new Date("2024-05-13T14:00:00.000Z"),
    LocationId: 2,
    ModalityId: 1,
    RecurrenceRule: "FREQ=WEEKLY;INTERVAL=1;BYDAY=WE;",
    RecurrenceException: "",
    IsAllDay: false,
    AppointmentStatusId: 3,
    AppointmentTypeId: 1,
    ProcedureCodeId: 2,
    Title: "Rome Weekly ElectroTherapy",
    Notes: "",
    Block: false,
    Timezone: "",
    ManagerOverride: false,
    CancellationReason: ""
  };

  public selectedAppointment2: Appointment = {
    CaseFileId: 38,
    ProviderId: 1,
    StartDatetime: new Date("2024-05-13T13:30:00.000Z"),
    EndDatetime: new Date("2024-05-13T14:00:00.000Z"),
    LocationId: 2,
    ModalityId: 1,
    RecurrenceRule: "FREQ=WEEKLY;INTERVAL=1;BYDAY=WE;",
    RecurrenceException: "",
    IsAllDay: false,
    AppointmentStatusId: 3,
    AppointmentTypeId: 1,
    ProcedureCodeId: 2,
    Title: "Rome Weekly ElectroTherapy",
    Notes: "",
    Block: false,
    Timezone: "",
    ManagerOverride: false,
    CancellationReason: ""
  };

  constructor(private signalR: SignalRService) { }

  ngOnInit(): void {
    console.log('Starting SignalR connection');
    this.signalR.startAppointmentHubConnection();
    this.signalR.addReceiveMessageListener((message) => {
      console.log(message);
    });

  }

  public requestDateLock() {
    this.signalR.requestDateLockForAppointment(this.selectedAppointment).then((response) => {
      console.log(response);
    }).catch(err => console.error(err.toString()));
  }

  public requestDateLock2() {
    this.signalR.requestDateLockForAppointment(this.selectedAppointment2).then((response) => {
      console.log(response);
    }).catch(err => console.error(err.toString()));
  }

  public checkDateLock() {
    this.signalR.checkIfDateIsLockedForAppointment(this.selectedAppointment).then((response) => {
      //Code to handle this goes here.
      console.log(response);
    }).catch(err => console.error(err.toString()));   
  }

  public checkDateLock2() {
    this.signalR.checkIfDateIsLockedForAppointment(this.selectedAppointment2).then((response) => {
      console.log(response);
    }).catch(err => console.error(err.toString()));   
  }

}
