<div id="custom-editor">

    <div class="row mt-0">

        <div class="col-12 col-md-5">
            <h4 class="m-0" *ngIf="showAvailableHeader"><strong>Available</strong></h4>
            <grid-template #availableGrid [settings]="availableGridSettings" />
        </div>
    
        <div class="col-12 col-md-2 d-flex justify-content-center align-items-center">
            
            <div class="row grid-actions">
                <div class="col-6">
                    <button ejs-button cssClass="e-outline" [isPrimary]="false" (click)="addSelectionToAssignedGrid()" iconCss="e-icons e-chevron-left-double"></button>
                </div>
                <div class="col-6">
                    <button ejs-button cssClass="e-outline" [isPrimary]="false" (click)="removeSelectionFromAssignedGrid()" iconCss="e-icons e-chevron-right-double"></button>
                </div>
                <!-- <div class="col-12">
                    <button ejs-button [isPrimary]="true">Submit</button>
                </div> -->
            </div>
        </div>
    
        <div class="col-12 col-md-5">
            <h4 class="m-0" *ngIf="showAssignedHeader"><strong>Assigned</strong></h4>
            <grid-template #assignedGrid [settings]="assignmentsGridSettings" />
        </div>
    
    </div>
    
</div>