import { computed, Injectable, signal } from '@angular/core';

// 3rd Party
import { Query } from '@syncfusion/ej2-data';

// Models
import { APIEndpoints } from '@models/api/Endpoints';
import { Comment, CommentThread, WorkItem } from '@models/data-contracts';

// Services
import { ApiService } from '@services/api/api.service';
import { caseFileForm } from '@root/src/app/shared/constants/form-groups/case-file';

export interface RecentActivityGridData {
  Date?: string | Date;
  Activity?: string;
  Title?: string;
  Notes?: string;
  Owner?: string;
  Category?: string;
  Type?: string;
  Status?: string;
  DueDate?: string | Date;
}

@Injectable({
  providedIn: 'root'
})
export class RecentActivityService {

   // State
   state: any = {
    Loading: {
      WorkItems: signal<boolean>(true),
      CommentThreads: signal<boolean>(true),
      RecentActivity: signal<boolean>(true),
    },
    Data: {
      WorkItems: signal<WorkItem[] | undefined>(undefined),
      Comments: signal<Comment[] | undefined>(undefined),
      CommentThreads: signal<CommentThread[] | undefined>(undefined),
      RecentActivity: signal<RecentActivityGridData[] | undefined>(undefined),
    },
    Errors: signal<Record<string, any>>({})
  };

  // Computed Signals
  readonly workItemState = computed(() => ({ 
    isLoading: this.loading.WorkItems,
    data: this.data.WorkItems,
    error: this.errors()['WorkItems']
  }));
  readonly commentState = computed(() => ({
    isLoading: this.loading.Comments,
    data: this.data.Comments,
    error: this.errors()['Comments']
  }));
  readonly commentThreadState = computed(() => ({
    isLoading: this.loading.CommentThreads,
    data: this.data.CommentThreads,
    error: this.errors()['CommentThreads']
  }));

  // Getters
  get loading() { return this.state.Loading; };
  get data() { return this.state.Data; };
  get errors() { return this.state.Errors; };

  constructor(private api: ApiService) { }

  async fetchWorkItems(caseFileId?: number) {
    
    try {

      // Verify case file Id dependency
      if (!caseFileId) {
        const error = new Error('No case file ID provided');
        console.error(error);
        throw error;
      }

      this.loading.WorkItems.set(true);
      const endpoint = APIEndpoints.WorkItems;
      const query = new Query().expand(['Type($select=Name)', 'Category($select=Name)', 'Priority($select=Name)', 'AssignedToNavigation($select=Name)', 'Category', 'Type', 'Status', 'XrefWorkItemCaseFiles']).where('XrefWorkItemCaseFiles/any(x: x/CaseFile/Id eq ' + caseFileId + ')', 'equal', true).take(10);

      const workItems = await this.api.getOdata(endpoint).executeQuery(query).then((res: any) => {
        return res.result.map((workItem: WorkItem) => {
          return {
            Date: workItem.CreatedAt,
            Activity: 'Task',
            Title: workItem.Title,
            Notes: workItem.Description,  
            Owner: workItem.AssignedToNavigation?.Name,
            Category: workItem.Category?.Name,
            Type: workItem.Type?.Name,
            Status: workItem.Status?.Name,
            DueDate: workItem.DueDate
          };
          });
        });

      this.loading.WorkItems.set(false);
      this.data.WorkItems.set(workItems);
      return this.data.WorkItems();

    } catch (error) {
      console.error('RecentActivityService: Error fetching work items', error);

    } finally {
      this.loading.WorkItems.set(false);
    }
  }

  async fetchComments(caseFileId?: number) {
    
    try {

      this.loading.Comments.set(true);
      const endpoint = APIEndpoints.Comments;
      const query = caseFileId ? 
        new Query().expand(['CreatedByNavigation($select=Name),UpdatedByNavigation($select=Name),Type,XrefCommentCaseFiles']).where('XrefCommentCaseFiles/any(x: x/CaseFile/Id eq ' + caseFileId + ')', 'equal', true).take(10) :  
        new Query().expand(['CreatedByNavigation($select=Name),UpdatedByNavigation($select=Name),Type']).take(10);

      const notes = await this.api.getOdata(endpoint).executeQuery(query).then((res: any) => {
        return res.result.map((note: Comment) => {
          return {  
            Date: note.CreatedAt,
            Activity: 'Comment',
            Notes: note.CommentText,
            Owner: note.CreatedByNavigation?.Name,
            Type: note.Type,
          };
        }); 
      });

      this.loading.Comments.set(false);
      this.data.Comments.set(notes);
      return this.data.Comments();

    } catch (error) {
      console.error('RecentActivityService: Error fetching comments', error);

    } finally {
      this.loading.Comments.set(false);
    }
  }

  async fetchCommentThreads(caseFileId?: number) {
    
    try {

      this.loading.CommentThreads.set(true);
      const endpoint = APIEndpoints.CommentThreads;
      const query = caseFileId ? 
        new Query().expand(['CreatedByNavigation($select=Name),UpdatedByNavigation($select=Name),XrefCommentThreadCaseFiles']).where('XrefCommentThreadCaseFiles/any(x: x/CaseFile/Id eq ' + caseFileId + ')', 'equal', true).take(10) :
        new Query().expand(['CreatedByNavigation($select=Name),UpdatedByNavigation($select=Name)']).take(10); 

      const commentThreads = await this.api.getOdata(endpoint).executeQuery(query).then((res: any) => {
        return res.result.map((commentThread: CommentThread) => {  
          return {
            Date: commentThread.CreatedAt,
            Activity: 'Note',
            Title: commentThread.Description,
            Owner: commentThread.CreatedByNavigation?.Name,
            Status: commentThread.Status,
          };
        });
      });

      this.loading.CommentThreads.set(false);
      this.data.CommentThreads.set(commentThreads);
      return this.data.CommentThreads();

    } catch (error) {
      console.error('RecentActivityService: Error fetching comment threads', error);
    } finally {
      this.loading.CommentThreads.set(false);
    }
  }

  async notesTasksCombined(caseFileId?: number) {
    
    try {
      this.loading.RecentActivity.set(true);
      const workItems = await this.fetchWorkItems(caseFileId);
      const commentThreads = await this.fetchCommentThreads(caseFileId);
      const combinedData = [...workItems, ...commentThreads];
      const recentActivity = combinedData.map((item: any) => {
        return {
          Date: item.Date,
          Activity: item.Activity,
          Title: item.Title,
          Notes: item.Notes,
          Owner: item.Owner,
          Category: item.Category,
          Type: item.Type,
          Status: item.Status,
          DueDate: item.DueDate,
        };
      });

      this.loading.RecentActivity.set(false);
      this.data.RecentActivity.set(recentActivity);
      return this.data.RecentActivity();

    } catch (error) {
      console.error('RecentActivityService: Error fetching notes and tasks', error);
    } finally {
      this.loading.RecentActivity.set(false);
    }
  }
}
