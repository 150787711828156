<div id="patients-grid">

    <loading-overlay [loading]="loading" *ngIf="!patients; else patientsGrid" />
    <ng-template #patientsGrid> 
        <grid-template #patientsGridComponent [settings]="patients" [name]="'Patients Grid'" [useRoundedEdges]="true" />
    </ng-template>

    <ng-template #nameTemplate let-data>
        <a class="link" (click)="onPatientNameClick(data)">
            <span class="capitalize">{{data.Firstname}} {{data.Lastname}}</span>
        </a>
    </ng-template>

    <ejs-dialog #patientDialog 
        [visible]="false"
        [buttons]="patientDialogButtons"
        [isModal]="true">
        <patient-form-component #patientFormComponent [patient]="patient" [displayButtons]="false" />
    </ejs-dialog>
</div>  