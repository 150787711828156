// Angular
import { Injectable, signal, WritableSignal } from '@angular/core';

// 3rd Party
import { Query } from '@syncfusion/ej2-data';

// Internal
import { ApiService } from '@services/api/api.service';
import { APIEndpoints } from '@models/api/Endpoints';
import { Invoice, Deposit, ReductionRequest, CaseFile, InvoiceRow, InvoicePayment } from '../../models/data-contracts';
import { ToastMessageService } from '@services/toast-message/toast-message.service';

export interface XirrResult {
  success: boolean;
  value?: string | null;
}
export interface FinancialData {
  totalBilledCost: number;
  fullSettlementValue: number;
  actualSettlementCost: number;
  fullSettlementValueWshcNo: number;
  courtesyReduction: number;
  amountAuthorized: number;
  totalPaidAmt: number;
  totalPaymentsReceived: number;
  daysOpen: number;
  finalFsv: number;
  profit: number;
  roi: number;
  annualizedRoi: number;
  balanceDue: number;
  xirr: XirrResult['value'];
  finalCheck?: Deposit;
  splitAsv?: number;
  courtesyReductionPct?: number;
  splitPayments?: number;
  roic?: number;
}
export interface BalanceDue {
  amountBilled: number;
  totalDueProvider: number;
}
@Injectable({
  providedIn: 'root',
})

// Closely based off of Atlas functions with the same names

// Please Be Very Carefull Modifying any functions in this file
// They have been thouroughly tested with Atlas data
// and produce the same results as the corresponding Atlas functions
export class FinancialService {
  
  constructor(
    private api: ApiService, 
    private toast: ToastMessageService
  ) {}

  private readonly MAX_ITERATIONS: number = 128;
  private readonly ACCURACY_THRESHOLD: number = 1.0e-6;

  // State
  public selectedCaseId: WritableSignal<number | null> = signal(null);
  private caseFile: CaseFile;
  private invoices: Invoice[];
  private deposits: Deposit[];
  private reductionRequests: ReductionRequest;
  private finalCheck: Deposit;
  private queries = {
    caseFile: new Query(),
    invoice: new Query(),
    deposit: new Query(),
    reduction: new Query(),
  };

  // Queries
  private generateQueries() {
    const caseId = this.selectedCaseId();
    this.queries.caseFile = new Query().where('Id', 'equal', caseId);
    this.queries.invoice = new Query()
      .where('CaseFileId', 'equal', caseId)
      .expand(['InvoiceRows', 'InvoicePayments']);
    this.queries.deposit = new Query().where('CaseFileId', 'equal', caseId);
    this.queries.reduction = new Query()
      .where('CaseFileId', 'equal', caseId)
      .where('Status', 'equal', 'Accepted')
      .where('Status', 'equal', 'Approved');
  }

  // Data Fetching
  public async setCaseIdAndFetch(newCaseId: number): Promise<void> {
    const currentCaseId = this.selectedCaseId();
    if (newCaseId !== currentCaseId) {
      this.selectedCaseId.set(newCaseId);
      this.generateQueries();
      try {
        const caseFileResponse: any = await this.api
          .getOdata(APIEndpoints.Casefiles)
          .executeQuery(this.queries.caseFile);
        this.caseFile = caseFileResponse.result[0];
        const invoiceResponse: any = await this.api
          .getOdata(APIEndpoints.Invoices)
          .executeQuery(this.queries.invoice);
        this.invoices = invoiceResponse.result;
        const depositResponse: any = await this.api
          .getOdata(APIEndpoints.Deposits)
          .executeQuery(this.queries.deposit);
        this.deposits = depositResponse.result;
        const reductionRequestsResponse: any = await this.api
          .getOdata(APIEndpoints.ReductionRequests)
          .executeQuery(this.queries.reduction);
        this.reductionRequests = reductionRequestsResponse.result;
        this.finalCheck = this.deposits.filter(
          (deposit) => deposit.FinalCheck
        )[0];
      } catch (error) {
        this.toast.showError('Error fetching Fincncial Data');
      }
    }
  }

  initializeFinancialData(): FinancialData {
    return {
      totalBilledCost: 0,
      fullSettlementValue: 0,
      actualSettlementCost: 0,
      fullSettlementValueWshcNo: 0,
      courtesyReduction: 0,
      amountAuthorized: 100000, // Set a default value
      totalPaidAmt: 0,
      totalPaymentsReceived: 0,
      daysOpen: 0,
      finalFsv: 0,
      profit: 0,
      roi: 0,
      annualizedRoi: 0,
      roic: 0,
      balanceDue: 0,
      xirr: '',
    };
  }

  /**
   *
   * @param rate
   * @param cashFlows
   * @param dates
   * @returns Net Present Value (NPV) given a rate, cash flows, and dates
   */
  private XNPV(
    rate: number,
    cashFlows: number[],
    dates: Date[]
  ): number | null {
    if (!Array.isArray(cashFlows) || !Array.isArray(dates)) return null;
    if (cashFlows.length !== dates.length) return null;

    let npv: number = 0.0;
    for (let i = 0; i < cashFlows.length; i++) {
      const daysDifference: number = this.calculateDaysDifference(
        dates[0],
        dates[i]
      );
      npv += cashFlows[i] / Math.pow(1 + rate, daysDifference / 365);
    }
    return isFinite(npv) ? npv : null;
  }

  /**
   *
   * @param startDate
   * @param endDate
   * @returns the number of days between two dates
   */
  private calculateDaysDifference(startDate: Date, endDate: Date): number {
    const millisecondsPerDay = 86400000; // Number of milliseconds in a day
    return Math.round(
      (endDate.getTime() - startDate.getTime()) / millisecondsPerDay
    );
  }

  /**
   *
   * @param cashFlows
   * @param dates
   * @param initialGuess
   * @returns Internal Rate of Return (IRR) given cash flows, dates, and an initial guess
   */
  public XIRR(
    cashFlows: number[],
    dates: Date[],
    initialGuess: number = 0.1
  ): number | null {
    if (!Array.isArray(cashFlows) || !Array.isArray(dates)) return null;
    if (cashFlows.length !== dates.length) return null;

    // Define initial rate guesses
    let lowerRate: number = 0.0;
    let upperRate: number = initialGuess;

    // Calculate NPV for the initial rate guesses
    let npvAtLowerRate: number = this.XNPV(lowerRate, cashFlows, dates) ?? 0;
    let npvAtUpperRate: number = this.XNPV(upperRate, cashFlows, dates) ?? 0;

    // Iterate to find a bracket where NPV changes sign
    for (let iteration = 0; iteration < this.MAX_ITERATIONS; iteration++) {
      if (npvAtLowerRate * npvAtUpperRate < 0.0) break;
      if (Math.abs(npvAtLowerRate) < Math.abs(npvAtUpperRate)) {
        npvAtLowerRate =
          this.XNPV(
            (lowerRate += 1.6 * (lowerRate - upperRate)),
            cashFlows,
            dates
          ) ?? 0;
      } else {
        npvAtUpperRate =
          this.XNPV(
            (upperRate += 1.6 * (upperRate - lowerRate)),
            cashFlows,
            dates
          ) ?? 0;
      }
    }

    // Check if a sign change was found
    if (npvAtLowerRate * npvAtUpperRate > 0.0) return null;

    // Perform binary search to find the IRR
    let npvAtCurrentRate: number = this.XNPV(lowerRate, cashFlows, dates) ?? 0;
    let currentRate: number;
    let rateStep: number;

    if (npvAtCurrentRate < 0.0) {
      currentRate = lowerRate;
      rateStep = upperRate - lowerRate;
    } else {
      currentRate = upperRate;
      rateStep = lowerRate - upperRate;
    }

    for (let iteration = 0; iteration < this.MAX_ITERATIONS; iteration++) {
      rateStep *= 0.5;
      let midpointRate: number = currentRate + rateStep;
      let npvAtMidpoint: number =
        this.XNPV(midpointRate, cashFlows, dates) ?? 0;

      if (npvAtMidpoint <= 0.0) currentRate = midpointRate;
      if (
        Math.abs(npvAtMidpoint) < this.ACCURACY_THRESHOLD ||
        Math.abs(rateStep) < this.ACCURACY_THRESHOLD
      )
        return midpointRate;
    }
    return null;
  }

  // helper to deruce invoices to balance dues
  private invoicesToBalances = (invoices: Invoice[]) =>
    invoices?.reduce(
      (accumulator: BalanceDue, invoice: Invoice) => {
        if (invoice.InvoiceRows) {
          invoice.InvoiceRows.forEach((row: InvoiceRow) => {
            accumulator.totalDueProvider += row.TotalDueProvider as number;
            accumulator.amountBilled += row.AmountBilled as number;
          });
        }
        return accumulator;
      },
      { amountBilled: 0, totalDueProvider: 0 } // Initial accumulator values
    );

  // get balance due from reduction request
  //  change to get it from invoices and invoice rows
  public async getBalanceDue(caseFileId?: number): Promise<BalanceDue | null> {
    // this input is case file database id
    if (caseFileId !== this.selectedCaseId()) {
      const query = new Query()
        .where('CaseFileId', 'equal', caseFileId)
        .expand('InvoiceRows');

      try {
        const response: any = await this.api
          .getOdata(APIEndpoints.Invoices)
          .executeQuery(query);
        const invoices: Invoice[] = response.result;
        return this.invoicesToBalances(invoices);
      } catch (error) {
        this.toast.showError('Error fetching balance due');
        return null;
      }
    } else {
      return this.invoicesToBalances(this.invoices);
    }
  }

  /**
   *
   * @param fileNumber
   * @param reductionAmount
   * @returns Xirr on a single file
   */
  public async getXirrSingle(
    caseFileId: number,
    reductionAmount = 0.0
  ): Promise<XirrResult> {
    // const query = new Query().where('CaseFileId', 'equal', caseFileId);
    try {
      await this.setCaseIdAndFetch(caseFileId);
      let fileDeposits = this.deposits;
      // Fetch and format invoices
      let fileInvoices = this.invoices;

      // Fetch balance due
      const { totalDueProvider } = this.invoicesToBalances(fileInvoices);
      let balanceDue = totalDueProvider;

      const amountsArray: number[] = [];
      const datesArray: Date[] = [];

      // If invoices are present, handle them
      if (fileInvoices.length > 0) {
        fileInvoices.forEach((invoice) => {
          // if (invoice.InvoiceRows) {
          //   invoice.InvoiceRows.forEach((row) => {
          //     // Add amount billed and date of service to arrays
          //     datesArray.push(new Date(row.DateOfService));
          //     amountsArray.push(
          //       row.TotalDueProvider > 0
          //         ? -row.TotalDueProvider
          //         : -row.AmountBilled
          //     );
          //   });
          // }
          if (invoice.InvoicePayments) {
            invoice.InvoicePayments.forEach((payment) => {
              datesArray.push(new Date(payment.DatePaid as string));
              amountsArray.push(payment.AmountPaid as number);
            });
          }
        });
      }
      // If deposits are present, handle them
      fileDeposits.forEach((deposit) => {
        datesArray.push(new Date(deposit.DepositDate as string)); // Adjust according to your deposit date property
        if (deposit.DepositAmount) amountsArray.push(deposit.DepositAmount);
      });

      if (fileInvoices.length === 0) {
        if (reductionAmount !== 0.0) {
          balanceDue -= reductionAmount;
        }
        // If there are no invoices
        if (fileDeposits.length === 0 || balanceDue !== 0) {
          const currentDate = new Date();
          datesArray.push(currentDate); // Current date as placeholder
          amountsArray.push(balanceDue);
        } else {
          // No need to add balance due as no invoices or deposits
          // Directly add balance due as the final cash flow
          const currentDate = new Date();
          datesArray.push(currentDate); // Current date as placeholder
          amountsArray.push(-balanceDue);
        }
      }

      // Calculate XIRR
      const xirr = this.XIRR(amountsArray, datesArray, 0.1);
      const xirrFormat = typeof xirr === 'number' ? xirr.toFixed(4) : xirr;

      return {
        success:
          typeof xirr === 'number' &&
          !isNaN(Number(xirrFormat)) &&
          Number(xirrFormat) !== 0,
        value: typeof xirr === 'number' ? xirrFormat : null,
      };
    } catch (error) {
      console.error('Error fetching data or calculating XIRR', error);
      return { success: false };
    }
  }

  /**
   *
   * @param operatingIncome
   * @param taxRate
   * @param investedCapital
   * @returns Return on invested capital
   */
  public calculateROIC(
    operatingIncome: number,
    taxRate: number,
    investedCapital: number
  ): number {
    if (investedCapital <= 0) {
      throw new Error('Invested Capital must be greater than zero.');
    }

    // Calculate NOPAT
    const NOPAT = operatingIncome * (1 - taxRate);

    // Calculate ROIC
    const ROIC = NOPAT / investedCapital;
    return ROIC;
  }

  /**
   * Calculates financial details based on the provided file number.
   * @param fileNumber The file number to be used for calculations.
   * @returns An object containing various calculated financial metrics.
   */
  async doCalculation(
    caseFileId: number,
    reductionAmount?: number,
    splitAsv?: number
  ): Promise<FinancialData> {
    const data = this.initializeFinancialData();

    try {
      await this.setCaseIdAndFetch(caseFileId);
      // Fetch FileEntry
      const caseFile = this.caseFile;
      // Fetch ReductionRequests
      const reductionRequest = this.reductionRequests;
      // Fetch Deposit
      const finalCheck = this.finalCheck;
      data.finalCheck = finalCheck;

      if (caseFile) {
        // Fetch ProviderInvoices
        const invoices = this.invoices;

        for (const invoice of invoices) {
          const rows = invoice?.InvoiceRows || [];
          data.totalBilledCost += rows.reduce(
            (sum, row) => sum + (row.TotalDueProvider || 0),
            0
          );

          data.fullSettlementValue += rows.reduce(
            (sum, row) => sum + (row.SettlementValue || 0),
            0
          );
          // data.fullSettlementValueWshcNo += rows.filter(row => row.WshcInv === 'No').reduce((sum, row) => sum + (row.SettlementValue || 0), 0);
          data.actualSettlementCost += (invoice.InvoicePayments || []).reduce(
            (sum, payment) => sum + (payment.AmountPaid || 0),
            0
          );
        }

        let combinedAsv: number;
        if (!data.finalCheck) {
          combinedAsv = splitAsv
            ? data.actualSettlementCost + splitAsv
            : data.actualSettlementCost;
        } else {
          combinedAsv = data.actualSettlementCost;
        }
        data.actualSettlementCost = parseFloat(combinedAsv.toFixed(2));

        // Get Courtesy Reduction
        const courtesyReduction =
          reductionAmount !== undefined
            ? reductionAmount
            : reductionRequest?.ApprovedAmount ?? 0;
        data.courtesyReduction = parseFloat(courtesyReduction.toFixed(2));
        // Get authorized amount
        // const authorizedAmountQuery = new Query().where('FileNumber', 'equal', fileNumber).where('AuthType', 'like', 'File Authorization Limit');
        // const authorizedAmountResponse: any = await this.api.getOdata(APIEndpoints.SignedAuthorizations).executeQuery(authorizedAmountQuery);
        // data.amountAuthorized = authorizedAmountResponse.result.reduce((sum, auth) => sum + (auth.Amount || 0), 0);
        // data.amountAuthorized = data.amountAuthorized || 0;

        // Get deposits and sum amounts
        const deposits = this.deposits;

        const totalAmount = deposits.reduce(
          (sum, deposit) => sum + (deposit.DepositAmount || 0),
          0
        );
        // const totalAmountNoWriteoff = deposits.filter(deposit => deposit.DepositToAccount !== 'Write-Off').reduce((sum, deposit) => sum + (deposit.DepositAmount || 0), 0);

        data.totalPaidAmt = totalAmount;
        data.totalPaymentsReceived = totalAmount;
        // data.totalPaymentsReceivedNoWriteoff = totalAmountNoWriteoff;

        // Calculate days open
        // when no final check then use today
        if (caseFile.CreatedAt) {
          const finalCheckDate = new Date(finalCheck?.CreatedAt ?? Date.now());
          const fileOpenedDate = new Date(caseFile?.CreatedAt);
          const millisecondsPerDay = 1000 * 60 * 60 * 24;
          data.daysOpen = Math.floor(
            Math.abs(finalCheckDate.getTime() - fileOpenedDate.getTime()) /
              millisecondsPerDay
          );
        }

        // data.courtesyReduction = parseFloat(data.courtesyReduction || '0.00');

        data.finalFsv =
          data.fullSettlementValue -
          data.courtesyReduction -
          data.fullSettlementValueWshcNo;

        data.profit = data.finalFsv - data.actualSettlementCost;

        data.roi =
          data.actualSettlementCost !== 0
            ? (data.profit / data.actualSettlementCost) * 100
            : 0;

        data.annualizedRoi =
          data.roi !== 0 && data.daysOpen > 0
            ? (data.roi / data.daysOpen) * 365
            : 0;

        data.balanceDue = parseFloat(
          (data.finalFsv - data.totalPaymentsReceived).toFixed(2)
        );

        // Get XIRR
        const xirrResult: XirrResult = await this.getXirrSingle(
          caseFileId,
          data.courtesyReduction
          // invoices,
          // deposits
        );
        data.xirr = xirrResult.success ? xirrResult.value : '';
      }
    } catch (error) {
      console.error('Error in doCalculation', error);
    }
    return data;
  }

  /**
   * Calculates financial metrics based on a percentage reduction.
   * @param percent The percentage reduction to apply.
   * @param caseFileId backend id for the case file.
   * @returns An object containing various calculated financial metrics.
   */
  async finReductionPercent(
    percent: number,
    caseFileId: number
  ): Promise<FinancialData> {
    // let updatedData = this.initializeFinancialData();
    let data = await this.doCalculation(caseFileId);
    try {

      const reduction = parseFloat(percent.toFixed(2));
      let splitAsv = 0;
      let splitDue = 0;

      if (!data.finalCheck) {
        const splitAsvData = await this.getSplitBase(caseFileId);
        splitAsv = splitAsvData.billed;
        splitDue = splitAsvData.due;
        splitAsv = (splitAsv * (1 - reduction / 100)) / 2 - splitDue;
      }

      const asv = data.actualSettlementCost;
      const fsv = data.fullSettlementValue;

      if (splitAsv < 0) {
        splitAsv = 0;
      }

      const asvCombined = splitAsv + asv;
      const bd = fsv * (reduction / 100) - data.totalPaymentsReceived;
      const reductionAmount = parseFloat((fsv * (reduction / 100)).toFixed(2));

      data = await this.doCalculation(
        caseFileId,
        reductionAmount,
        splitAsv
      );

      const roic =
        asvCombined === 0
          ? 0
          : parseFloat((data.finalFsv / asvCombined).toFixed(2));

      data.courtesyReductionPct = parseFloat(reduction.toFixed(2));
      data.splitPayments = parseFloat(splitAsv.toFixed(2));
      data.roic = roic;
    } catch (error) {
      console.error('Error in finReductionPercent', error);
    }
    return data;
  }

  // Placeholder for getSplitBase method
  // will be taken care of by backend
  private async getSplitBase(
    caseFileId: number
  ): Promise<{ billed: number; due: number }> {

  if (this.invoices.length > 0) {
    let fullBilled = 0.0;
    let fullTotalDue = 0.0;
    for (const inv of this.invoices) {
      if(inv.InvoiceRows) {
        inv.InvoiceRows.forEach(row => {
          if(row.AmountBilled) fullBilled += row.AmountBilled;
          if(row.TotalDueProvider)fullTotalDue += row.TotalDueProvider;
        });
      }
    }
    return { billed: Number(fullBilled.toFixed(4)), due: Number(fullTotalDue.toFixed(4)) };
  } else {
    return { billed: 0.0000, due: 0.0000 };
  }
}
    // still need to port from Atlas

  /**
   * Calculates financial metrics based on a fixed reduction amount.
   * @param amount The fixed reduction amount to apply.
   * @param caseFileId the backend id for the casefile
   * @returns An object containing various calculated financial metrics.
   */
  async finReductionAmount(
    amount: number,
    caseFileId: number
  ): Promise<FinancialData> {

    let data = await this.doCalculation(caseFileId);
    try {

      const asv = data.actualSettlementCost;
      const fsv = data.fullSettlementValue;
      // const ffsv = data.finalFsv;
      const reductionAmount = parseFloat(amount.toFixed(2));
      const reductionPercent = fsv === 0 ? 0 : reductionAmount / fsv;

      let splitAsv = 0;
      let splitDue = 0;

      if (!data.finalCheck) {
        const splitAsvData = await this.getSplitBase(caseFileId);
        splitAsv = splitAsvData.billed;
        splitDue = splitAsvData.due;
        splitAsv = (splitAsv * (1 - reductionPercent)) / 2 - splitDue;
      }

      // const bd = fsv * (1 - reductionPercent) - data.totalPaymentsReceived;

      if (splitAsv < 0) {
        splitAsv = 0;
      }

      const asvCombined = splitAsv + asv;

      data = await this.doCalculation(
        caseFileId,
        reductionAmount,
        splitAsv
      );

      const roic =
        asvCombined === 0
          ? 0
          : parseFloat((data.finalFsv / asvCombined).toFixed(2));

      data.courtesyReductionPct = parseFloat(
        (reductionPercent * 100).toFixed(2)
      );
      data.splitPayments = parseFloat(splitAsv.toFixed(2));
      data.roic = parseFloat(roic.toFixed(2));
    } catch (error) {
      console.error('Error in finReductionAmount', error);
    }

    return data;
  }

  /**
   * Calculates reduction amount and percentage based on a given ROIC value.
   * @param roic The ROIC value to use for calculations.
   * @param caseFileId the backend id for the casefile
   * @returns An object containing various calculated financial metrics.
   */

  async finRoicValue(
    roic: number,
    caseFileId: number
  ): Promise<FinancialData> {
    let data = await this.doCalculation(caseFileId);
    // let data = this.initializeFinancialData();
    try {
  
      let splitAsv = 0;
      let splitDue = 0;
  
      if (!data.finalCheck) {
        const splitAsvData = await this.getSplitBase(caseFileId);
        splitAsv = splitAsvData.billed;
        splitDue = splitAsvData.due;
      }
  
      const asv = data.actualSettlementCost;
      const fsv = data.fullSettlementValue;
      let reduction = 0;
      let reductionAmount = 0;
      roic = parseFloat(roic.toString());
      let roicGuess = 0;
      let asvCombined = 0;
      let splitAsvCalc = 0;
  
      while ((roicGuess >= roic || roicGuess === 0) && roic !== 0) {
        reduction += 0.000001;
  
        if (!data.finalCheck) {
          splitAsvCalc = ((splitAsv * (1 - Number(reduction.toFixed(4)))) / 2) - splitDue;
          if (splitAsvCalc < 0) {
            splitAsvCalc = 0;
          }
          asvCombined = splitAsvCalc + asv;
        } else {
          asvCombined = asv;
        }
  
        if (fsv === 0 || asvCombined === 0) {
          roicGuess = 0;
        } else {
          roicGuess = (fsv * (1 - reduction)) / asvCombined;
        }
      }
  
      reductionAmount = Number((fsv * Number(reduction.toFixed(4))).toFixed(2));
      data = await this.doCalculation(
        caseFileId,
        reductionAmount,
        splitAsvCalc
      );
  
      data.courtesyReductionPct = Number((reduction * 100).toFixed(2));
      data.splitPayments = Number(splitAsvCalc.toFixed(2));
      data.roic = Number(roicGuess.toFixed(2));
      return data;
    } catch (error) {
      console.error('Error in finRoicValue', error);
    }
    return data;
  }
  
}
