
<div id="404" class="page-not-found">
    <div class="d-flex flex-row">
        <div class="logo">
            <app-svg class="svg-logo" [path]="logo"></app-svg>
        </div>
        <div>
            <h1>Error 404<span>!</span></h1>
            <h3>Page Not Found</h3>
            <button ejs-button class="e-primary e-flat" type="submit" onclick = "document.location='dashboard'">Home</button>
        </div>
    </div>
</div>