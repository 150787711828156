import { Component, Input, } from '@angular/core';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { SwitchModule } from '@syncfusion/ej2-angular-buttons';

@Component({
  selector: 'item-view-helper',
  standalone: true,
  imports: [
    TextBoxModule, 
    SwitchModule
  ],
  templateUrl: './item-view-helper.component.html',
  styleUrl: './item-view-helper.component.scss'
})
export class ItemViewHelperComponent {
  @Input() isEditing: boolean;
  @Input() data: any;
  @Input() isBoolean: boolean
  @Input() isStatus: boolean;
}
