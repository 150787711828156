// Angular
import { Injectable, signal, WritableSignal } from '@angular/core';

// 3rd Party
import { AuthSession, fetchAuthSession } from '@aws-amplify/auth';
import { ScheduleComponent, ResourcesModel, Timezone, Schedule, EventSettingsModel, ActionEventArgs } from '@syncfusion/ej2-angular-schedule';

// Internal
import config from '@app/config';
import { APIEndpoints } from '@models/api/Endpoints';
import { UpdatedResourceEvent } from '@models/components/scheduler.model';
import { KeyValuePair } from '@services/globals/globals.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { SignalRService } from '@services/api/signal-r.service';
import { UserPreferencesService } from '../user/user-preferences.service';
import { SchedulerSignalsService } from './scheduler-signals.service';
import { Appointment } from '@models/data-contracts';
import { AuthenticatedServiceBase } from '@core/auth/auth.base'

@Injectable({
  providedIn: 'root'
})
export class SchedulerService extends AuthenticatedServiceBase {

  // Add implementation of abstract endpoint property
  protected endpoint = APIEndpoints.Appointments;

  constructor(
    private signalR: SignalRService,
    private toast: ToastMessageService,
    private user: UserPreferencesService,
    private schedulerSignals: SchedulerSignalsService
  ) { 
    super();
  }

  calendarSignal: WritableSignal<ScheduleComponent | undefined> = signal(undefined);
  calendarStateSignal: WritableSignal<string> = signal('');
  resourceCollectionsSignal: WritableSignal<ResourcesModel[] | undefined> = signal(undefined);

  get calendar(): ScheduleComponent | undefined {
    return this.calendarSignal();
  }

  get resourceCollections(): ResourcesModel[] | undefined {
    return this.resourceCollectionsSignal();
  }

  get calendarState(): string {
    return this.calendarStateSignal();
  }

  async batchSaveSchedule(args: any, timezone: Timezone) {
    args.cancel = true;
    let result: any = undefined;

    await fetchAuthSession().then(async (session: AuthSession) => {
      const token = session.tokens?.idToken?.toString() as string;
      const changed = args.changedRecords.map((record: any) => this.mapEventForDB(record, timezone));
      const added = args.addedRecords.map((record: any) => this.mapEventForDB(record, timezone));
      const deleted = args.deletedRecords.map((record: any) => this.mapEventForDB(record, timezone));

      let data = {
        action: 'batch',
        Changed: changed,
        Added: added,
        Deleted: deleted,
      };

      result = await this.sendXMLData(token, data);
      return result;

    }).catch((err: Error) => {
      console.error(err);
      result = err;
      return result;
    });

    return result;
  }

  // Async function to detect XMLHttpRequest  errors
  async sendXMLData(token: string, data: any): Promise<void> {
    try {
      return await new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = () => {
          if (xhr.status >= 200 && xhr.status < 300) {
            resolve(xhr.response);
          } else {
            reject(new Error(`HTTP error: ${xhr.status} ${xhr.statusText}`));
          }
        };
        xhr.onerror = () => reject(new Error('Network error'));
        xhr.open('POST', `${config.backendUrl}odata/Appointments/batch`);
        xhr.setRequestHeader('Content-type', 'application/json');
        xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        xhr.send(JSON.stringify(data));
      });
    } catch (err) {
      console.error('Error:', err);
      this.toast.showError('Failed to update event: ' + (err as any).toString());
    }
  }

  // Hub interactions
  receiveLockedDatesSignal(message: any, comp: ScheduleComponent) {
    let elements: any;
    const messageObj = JSON.parse(message);

    if (messageObj.attribute === 'id') {
      elements = document.getElementById(messageObj.value);
    } else if (messageObj.attribute === 'class') {
      elements = document.querySelectorAll(`.${messageObj.value}`);
    } else {
      elements = document.querySelectorAll(`[${messageObj.attribute}="${messageObj.value}"]`);
    }

    if (messageObj.action === 'add') {
      elements.forEach((el: Element) => {
        el.classList.add('editing');
        this.signalR.addLoadingIndicatorToElement(el, messageObj.requestingUser);
      });
    } else if (messageObj.action === 'remove') {
      elements.forEach((el: Element) => {
        el.parentElement?.classList.remove('editing');
        el.parentElement?.classList.remove('inactive-bg');
        el.remove();

        this.schedulerSignals.fetchAppointments();

        if (el.parentElement?.tagName === 'TD') {
          setTimeout(() => {
            comp.refreshLayout();
            comp.refreshEvents();
          }, 500);
        }
      });
    }
  }

  // Maps data for Syncfusion Scheduler component
  mapEventForSyncfusion(appointment: Appointment, timezone: Timezone): any {
    // Check if appointment is valid
    if (!appointment) {
      return null;
    }

    // Create a safe version of the appointment that handles nulls
    const safeAppointment = {
      Id: appointment.Id,
      Subject: this.getAppointmentSubject(appointment),
      StartDatetime: appointment.StartDatetime ? new Date(appointment.StartDatetime) : new Date(),
      EndDatetime: appointment.EndDatetime ? new Date(appointment.EndDatetime) : new Date(),
      // Safely access nested objects with optional chaining
      LocationId: appointment.LocationId ?? appointment.Location?.Id ?? 1,
      ModalityId: appointment.ModalityId ?? appointment.Modality?.Id ?? 1,
      // Handle null ProcedureCode safely
      ProcedureCodeId: appointment.ProcedureCodeId ?? appointment.ProcedureCode?.Id,
      ProcedureCode: appointment.ProcedureCode ?? { Id: null, Description: 'No Procedure' },
      // Other fields with null safety
      CaseFileId: appointment.CaseFileId ?? appointment.CaseFile?.Id,
      CaseFile: appointment.CaseFile ?? null,
      AppointmentTypeId: appointment.AppointmentTypeId,
      AppointmentStatusId: appointment.AppointmentStatusId ?? 5, // Default to status 5 if null
      ProviderId: appointment.ProviderId,
      IsCompleted: appointment.IsCompleted ?? false,
      Block: appointment.Block ?? false,
      Location: appointment.Location ?? null,
      Modality: appointment.Modality ?? null
    };

    // Map to Syncfusion format
    return {
      Id: safeAppointment.Id,
      Subject: safeAppointment.Subject,
      StartTime: safeAppointment.StartDatetime,
      EndTime: safeAppointment.EndDatetime,
      // Add the rest of the appointment properties
      LocationId: safeAppointment.LocationId,
      ModalityId: safeAppointment.ModalityId,
      ProcedureCodeId: safeAppointment.ProcedureCodeId,
      ProcedureCode: safeAppointment.ProcedureCode,
      CaseFileId: safeAppointment.CaseFileId,
      CaseFile: safeAppointment.CaseFile,
      AppointmentTypeId: safeAppointment.AppointmentTypeId,
      AppointmentStatusId: safeAppointment.AppointmentStatusId,
      ProviderId: safeAppointment.ProviderId,
      IsCompleted: safeAppointment.IsCompleted,
      Block: safeAppointment.Block,
      Location: safeAppointment.Location,
      Modality: safeAppointment.Modality
    };
  }

  // Helper function to get the appointment subject safely
  private getAppointmentSubject(appointment: Appointment): string {
    if (!appointment) return 'New Appointment';
    
    if (appointment.CaseFile?.FileNumber && appointment.CaseFile?.Patient) {
      const patient = appointment.CaseFile.Patient;
      const firstName = patient.Firstname ? patient.Firstname.charAt(0).toUpperCase() + patient.Firstname.slice(1) : '';
      const lastName = patient.Lastname ? patient.Lastname.charAt(0).toUpperCase() + patient.Lastname.slice(1) : '';
      return `${appointment.CaseFile.FileNumber} - ${firstName} ${lastName}`;
    }
    
    return appointment.Title || 'New Appointment';
  }

  // Maps scheduler data so it corresponds to model stored in DB
  mapEventForDB(data: any, timezone?: Timezone) {

    const appointment = {
      Id: data.Id,
      CaseFileId: data.CaseFileId,
      ProviderId: data.ProviderId,
      StartDatetime: data.StartTime,
      EndDatetime: data.EndTime,
      Timezone: data.StartTimezone ?? data.EndTimezone ?? timezone?.getLocalTimezoneName(),
      RecurrenceRule: data.RecurrenceRule,
      RecurrenceException: data.RecurrenceException,
      IsAllDay: data.IsAllDay,
      ProcedureCodeId: data.ProcedureCodeId,
      Title: data.Subject,
      Notes: data.Description,
      ManagerOverride: data.ManagerOverride,
      CancellationReason: data.CancellationReason,
      Block: data.IsBlock,
      IsCompleted: data.IsCompleted,
      AppointmentTypeId: data.AppointmentTypeId,
      AppointmentStatusId: data.AppointmentStatusId,
      ModalityId: data.ModalityId,
      LocationId: data.LocationId,
    };

    return appointment;
  }

  // Ensure required fields are present before saving
  validateAppointment(data: any) {
    const requiredFields = ['StartTime', 'EndTime', 'ProviderId', 'ModalityId', 'LocationId', 'ProcedureCodeId', 'AppointmentTypeId', 'CaseFileId', 'StartTimezone', 'EndTimezone'];
    const errors: string[] = [];
    let message: string | boolean = '';

    const missingFields = requiredFields.filter((field: string) => !data[field] || [0, ''].includes(data[field]));
    if (missingFields.length > 0) {
      errors.push(`${missingFields.map(field => `- ${field}`).join('<br>')}`);
    }

    if (errors.length > 0) {
      message = `<p style="font-weight: 600; font-size: 1.05rem;">Invalid Fields</p>${errors.join('<br>')}`;
    } else {
      message = false;
    }
    return message;
  }

  // Adds or removes resource to calendar when selecting locations or modalities
  updateResources(args: UpdatedResourceEvent) {

    // Exit if missing dependencies
    if (!this.schedulerSignals.calendar.Component()) return;

    // Prepare data and update resources
    const resourceCollection = this.schedulerSignals.calendar.Component()?.getResourceCollections().find((collection: any) => collection.name === args.type);
    const modalities = this.schedulerSignals.data['Modalities']();
    const matchingResources = modalities?.filter((modality: any) => modality.ModalityTypeId === args.selection.Id) ?? [];
    if (args.event.checked === true) this.addResource(args, matchingResources);
    else this.removeResource(args, matchingResources, resourceCollection);

    // Update signals
    this.schedulerSignals.calendar.Component.set(this.schedulerSignals.calendar.Component());
    this.schedulerSignals.calendar.Resources.set(this.schedulerSignals.calendar.Component()?.getResourceCollections());
  }

  private addResource(args: UpdatedResourceEvent, matchingResources: any[]) {

    if (args.type === 'Modalities') {
      matchingResources?.forEach(resource => {
        if (resource.Id) this.schedulerSignals.calendar.Component()?.addResource(resource, args.type, resource.Id);
        else console.error('Unable to add resource. No Id found', resource);
      });

    } else {
      if (args.selection.Id) this.schedulerSignals.calendar.Component()?.addResource(args.selection, args.type, args.selection.Id);
      else console.error('Unable to add resource. Missing Id', args.selection);
    }
  }

  // Removes resource from calendar
  private removeResource(args: UpdatedResourceEvent, matchingResources: any[], resourceCollection: any) {

    if (args.type === 'Modalities') {
      if (matchingResources.length > 0) {
        matchingResources.forEach(resource => {
          if (resource.Id) this.schedulerSignals.calendar.Component()?.removeResource(Number(resource.Id), 'Modalities');
          else console.error('Unable to remove resource. No Id found', resource);
        });
      } else {
        console.error('Unable to remove resource. No matching resources found', matchingResources)
      };

    } else {
      const resourceData = resourceCollection?.dataSource.find((item: any) => item['Id'] === args.selection.Id);
      if (resourceData && args.selection.Id) this.schedulerSignals.calendar.Component()?.removeResource(args.selection.Id, args.type);
      else console.error('Unable to remove resource.', resourceData, args.selection);
    }
  }

  // Adds new component to editor window when editing appointnments
  addComponentToEditorWindow(element: Element, component: any, attrName?: string, containerClass?: string) {
    const containerDiv: HTMLDivElement = document.createElement('div');
    const inputElement: HTMLInputElement = document.createElement('input');

    containerDiv.className = containerClass !== undefined ? containerClass : 'component-container';
    inputElement.className = 'e-field';
    inputElement.setAttribute('name', attrName as string);
    containerDiv.appendChild(inputElement);
    component.appendTo(inputElement);
    element.appendChild(containerDiv);
  }

  // Get resource data associated with appointment
  getResourceData(data: KeyValuePair, comp: ScheduleComponent): KeyValuePair {
    const resources: ResourcesModel = comp!.getResourceCollections()[1];
    const resourceData: KeyValuePair = (resources.dataSource as Object[])
      .filter((resource: any) => (resource)['Id'] === data['ModalityId'])[0] as KeyValuePair;
    return resourceData;
  }

  // Get colors associated with appointment's modality
  getEventHeaderStyles(data: KeyValuePair, comp: ScheduleComponent): Object {
    const primaryColor = getComputedStyle(document.body).getPropertyValue('--color-sf-primary').trim().replace(/\s*,\s*/g, ', ');

    if (data['elementType'] === 'cell') {
      return { 'align-items': 'center', 'color': '#919191' };
    } else {
      const resourceData: KeyValuePair | undefined = this.getResourceData(data, comp) ?? undefined;
      let resourceColor = resourceData?.['Color'] as string ?? `rgba(${primaryColor})`;
      const rgbaColor = resourceColor !== `rgba(${primaryColor})` ? this.hexToRgba(resourceColor, 0.33) : `rgba(${primaryColor})`; // Convert to rgba with 33% opacity

      return {
        'background': rgbaColor,
        'border-left': `8px solid ${resourceColor}` // Use rgba for border-left
      };
    }
  }

  // Rempve styles associated with Modality
  removeHeaderStyles(): Object {
    return { 'background': 'rgba(var(--color-sf-primary), 0.08)', 'border-left': 'none' };
  }

  // Remove animated elements
  removeUserEditingElements() {
    let userEditingElements = document.querySelectorAll('.user-editing');

    // Remove animating elements
    if (userEditingElements.length > 0) {
      this.signalR.broadcastHTMLElement(userEditingElements[0], 'class', 'remove');
    }
  }

  fetchInstanceType() {
    const query: string = '?$select=VariableValue&$filter=VariableName eq \'instance_type\'';
    return this.api.fetchRequest(`odata${APIEndpoints.ConfigVariables}${query}`);
  }

  fetchInstanceName() {
    const query: string = '?$select=VariableValue&$filter=VariableName eq \'instance_name\'';
    return this.api.fetchRequest(`odata${APIEndpoints.ConfigVariables}${query}`);
  }

  // Helper function to convert hex to rgba
  private hexToRgba(hex: string, alpha: number): string {
    // Remove the hash at the start if it's there
    hex = hex.replace(/^#/, '');

    // Parse r, g, b values
    let r: number, g: number, b: number;
    if (hex.length === 3) {
      r = parseInt(hex[0] + hex[0], 16);
      g = parseInt(hex[1] + hex[1], 16);
      b = parseInt(hex[2] + hex[2], 16);
    } else {
      r = parseInt(hex.substring(0, 2), 16);
      g = parseInt(hex.substring(2, 4), 16);
      b = parseInt(hex.substring(4, 6), 16);
    }

    // Return the rgba string
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
}
