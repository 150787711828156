<!-- Modal to open on custom function -->
<ejs-dialog #Modal width="1000px" header="Change Account Password" [isModal]="true" [showCloseIcon]='true'
    [visible]="false" (beforeOpen)="beforeModalOpened($event)">
    <ng-template #content>
        <form [formGroup]="formGroup" (ngSubmit)="changePassword()" class="d-flex flex-column search-form">
            <div class="row">

                <div class="col-12">
                    <p> Confirm your current password and enter valid new one to change your password. </p>
                </div>

                <div class="col-4">
                    <ejs-textbox id="password1" type="password" placeholder="Enter new password" required
                        #password1Input formControlName="password1" floatLabelType="Auto"></ejs-textbox>
                    @if(formGroup.get('password1')?.errors && formGroup.get('password1')?.touched){
                    @if(formGroup.get('password1')?.errors?.['passwordLength'])
                    { <p> Password must be at least 8 characters </p> }
                    @else if (formGroup.get('password1')?.errors?.['passwordUppercase'])
                    { <p> Password must have at least 1 uppercase letter </p> }
                    @else if (formGroup.get('password1')?.errors?.['passwordLowercase'])
                    { <p> Password must have at least 1 lowercase letter </p> }
                    @else if (formGroup.get('password1')?.errors?.['passwordNumber'])
                    { <p> Password must have at least 1 number </p> }
                    @else if (formGroup.get('password1')?.errors?.['passwordSpecialCharacter'])
                    { <p> Password must have at least 1 special character </p> }
                    @else if (formGroup.get('password1')?.errors?.['required'])
                    { <p> Entry required </p> }
                    }
                </div>

                <div class="col-4">
                    <ejs-textbox id="old-password" type="password" placeholder="Enter current password" required
                        formControlName="oldPassword" floatLabelType="Auto"></ejs-textbox>
                    @if(formGroup.get('oldPassword')?.errors && formGroup.get('oldPassword')?.touched){
                    @if(formGroup.get('oldPassword')?.errors?.['required']){ <p> Unable to verify current password </p>
                    }
                    }
                </div>

                <div class="col-4">
                    <ejs-textbox id="password2" type="password" placeholder="Confirm new password" required
                        #password2Input formControlName="password2" floatLabelType="Auto"></ejs-textbox>
                    @if(formGroup.errors && formGroup.get('password2')?.touched){
                    @if(formGroup.errors['doNotMatch']){ <p> Passwords do not match </p> }
                    }
                </div>

                <div class="col-4 form-submit ">
                    <button ejs-button type="submit" isPrimary="true">Submit</button>
                </div>

            </div>
        </form>
    </ng-template>
</ejs-dialog>