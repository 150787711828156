// Angular
import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';

// 3rd Party
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { NumericTextBoxModule,TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { RichTextEditorModule } from '@syncfusion/ej2-angular-richtexteditor';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { SignedAuthorization } from '@models/data-contracts';
import { ApiService } from '@services/api/api.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { Query } from '@syncfusion/ej2-data';
import { NgIf } from '@angular/common';

@Component({
  selector: 'add-authorizations',
  standalone: true,
  imports: [
    FormsModule,
    ButtonModule,
    TextBoxModule,
    DropDownListModule,
    DatePickerModule,
    NumericTextBoxModule,
    RichTextEditorModule,
    NgIf
  ],
  templateUrl: './add-authorizations.component.html',
  styleUrl: './add-authorizations.component.scss',
})
export class AddAuthorizationsComponent {
  constructor(
    private api: ApiService,
    private toast: ToastMessageService,
  ) {}
  @Input() caseId: number | undefined;
  @Input() selectedAuthorization: SignedAuthorization | null;
  @Input() authType: string | null;
  @Output() submit = new EventEmitter<any>(); // Emit backend response

  public formData: SignedAuthorization;

  public userId: number = JSON.parse(localStorage.getItem('userId') || "null");

  // Rich text editor configuration
  public rteConfig = {
    height: 200,
    toolbarSettings: {
      items: [
        'Bold', 'Italic', 'Underline', '|',
        'Formats', 'Alignments', '|',
        'OrderedList', 'UnorderedList', '|',
        'SourceCode'
      ]
    },
    format: {
      width: 'auto'
    }
  };

  initFormData = (authorization?: SignedAuthorization | null): SignedAuthorization => {
    return {
      CaseFileId: authorization?.CaseFileId || this.caseId,
      DateSigned: authorization?.DateSigned || new Date().toISOString(),
      Amount: authorization?.Amount || undefined,
      AuthType: authorization?.AuthType || this.authType || null,
      ProviderId: authorization?.ProviderId,
      Notes: authorization?.Notes || null,
      CreatedBy: this.userId,
      UpdatedBy: this.userId
    };
  };

  ngOnInit() {
    // Initialize the form data with the current authType
    this.formData = this.initFormData();

    // Fetch auth types and providers data
    this.api.getOdata(APIEndpoints.AuthTypes).executeQuery(this.authTypeQuery).then((res: any)=> {
      this.authTypeList = res?.result?.map((item: any) => item.Description);
    });
    this.api.getOdata(APIEndpoints.Providers).executeQuery(this.providerQuery).then((res: any)=> {
      this.providerList = res?.result;
    });
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    // Initialize or update form when inputs change
    if (simpleChanges['caseId'] || simpleChanges['authType'] || simpleChanges['selectedAuthorization']) {
      this.formData = this.initFormData(this.selectedAuthorization);

      // If authType changed and we're not editing an existing authorization, update the form data
      if (simpleChanges['authType'] && !this.selectedAuthorization) {
        this.formData.AuthType = this.authType;
      }
    }
  }

  // setup for drop downs

  public providerQuery = new Query()
  .select(['name', 'id'])
  .where('IsActive', 'equal', true)
  ;
  public providerList: any[] = [];

  public providerFields = {
    text: 'Name',
    value: 'Id',
  };

  public authTypeQuery = new Query()
    // .select(['Description', 'Id'])
    .where('IsActive', 'equal', true);

  public authTypeList: string[];


  closeForm() {
    this.submit.emit();
    this.formData = this.initFormData();
  }
      // Iterate through each key in the object, Format it for backend
  // CreatedAt needs different format
  formatDates(formObj: any): any {
    for (const key in formObj) {
      if (formObj.hasOwnProperty(key)) {
        const value = formObj[key];
        if (value instanceof Date) {
          let dateStr = value.toISOString();
          key === 'CreatedAt'
            ? // For "CreatedAt", use the full ISO string (not sliced)
              (formObj[key] = dateStr)
            : // For other keys, slice the ISO string to "YYYY-MM-DD" format
              (formObj[key] = dateStr.slice(0, 10));
        }
      }
    }
    return formObj;
  }

  onSubmit() {
    // Validate amount is greater than 0. TODO: Add validation to the Material form
    if (!this.formData.Amount || this.formData.Amount <= 0) {
      this.toast.showWarning('Amount must be greater than 0');
      return;
    }

    if (this.selectedAuthorization?.Id) {  // Only PATCH if we have a valid ID
      console.log('Updating authorization:', this.formData);
      this.api.basicPatch(
        `${APIEndpoints.SignedAuthorizations}(${this.selectedAuthorization.Id})`,
        this.formatDates(this.formData)
      )
      .then((response) => {
        if (!response || response.status >= 400) {
          this.toast.showError('There was a problem updating the authorization');
        } else {
          const successMessage = this.formData.AuthType === 'Provider Funding Authorization' ?
            'Provider Funding successfully updated' : 'File Authorization successfully updated';
          this.toast.showSuccess(successMessage);
        }
        this.submit.emit();
      })
      .catch(error => {
        this.toast.showError('Failed to update: ' + (error.message || 'Unknown error'));
        this.submit.emit();
      });
    } else {
      // If no ID, this is a new authorization - always POST
      this.api
        .basicPost(APIEndpoints.SignedAuthorizations, this.formatDates(this.formData))
        .then((response) => {
          if (!response || response.status >= 400) {
            this.toast.showError('There was a problem creating the authorization');
          } else {
            const successMessage = this.formData.AuthType === 'Provider Funding Authorization' ?
              'Provider Funding successfully created' : 'File Authorization successfully created';
            this.toast.showSuccess(successMessage);
          }
          this.submit.emit();
        })
        .catch(error => {
          this.toast.showError('Failed to create: ' + (error.message || 'Unknown error'));
          this.submit.emit();
        });
    }
  }
}
