<div id="comment-threads-grid">
    <grid-template 
        [settings]="gridSettings"
        [name]="'Comments'">
    </grid-template>

    <!-- Add Comment Thread Dialog -->
    <ejs-dialog #threadDialog 
        [header]="'Add New Note'"
        [isModal]="true"
        [visible]="false"
        [showCloseIcon]="true"
        [width]="800"
        [buttons]="threadDialogButtons">
        <thread-form
            #threadFormComponent
            [entityType]="entityType"
            [entityId]="entityId"
            [commentThread]="selectedThread"
            (formSubmitted)="submitThreadForm()"
            (formCancelled)="cancelThreadForm()">
        </thread-form>
    </ejs-dialog>
</div>  