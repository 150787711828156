<ejs-grid #grid
  [id]="settings.id ? 'base-grid-' + settings.id : 'base-grid'"
  [class]="settings.useRoundedEdges ? 'base-grid rounded-edges' : 'base-grid'"
  [height]="settings.gridSettings?.height ?? '100%'"
  [dataSource]="settings.gridSettings?.dataSource ?? services.gridData()"
  [toolbar]="settings.gridSettings?.toolbar ?? services.toolbar()"
  [allowGrouping]="settings.gridSettings?.allowGrouping ?? false"
  [groupSettings]="settings.gridSettings?.groupSettings"
  [allowPaging]="settings.gridSettings?.allowPaging ?? true"
  [allowSorting]="settings.gridSettings?.allowSorting ?? true"
  [allowFiltering]="settings.gridSettings?.allowFiltering ?? true"
  [allowResizing]="settings.gridSettings?.allowResizing ?? true"
  [allowReordering]="settings.gridSettings?.allowReordering ?? true"
  [selectionSettings]="settings.gridSettings?.selectionSettings ?? { type: 'Multiple', mode: 'Row' }"
  [showColumnChooser]="settings.gridSettings?.showColumnChooser ?? true"
  [columnChooserSettings]="settings.gridSettings?.columnChooserSettings ?? { operator: 'contains', ignoreAccent: true }"
  [pageSettings]="settings.gridSettings?.pageSettings ?? { pageSize: 20, pageSizes: [20, 50, 100, 500], pageCount: 5, enableQueryString: true }"
  [filterSettings]="settings.gridSettings?.filterSettings ?? { type: 'FilterBar' }"
  [loadingIndicator]="settings.gridSettings?.loadingIndicator ?? { indicatorType: 'Shimmer' }"
  [editSettings]="settings.gridSettings?.editSettings ?? { allowEditOnDblClick: false, allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Dialog' }"
  [allowPdfExport]="settings.gridSettings?.allowPdfExport ?? true"
  [allowExcelExport]="settings.gridSettings?.allowExcelExport ?? true"
  [sortSettings]="settings.gridSettings?.sortSettings ?? { columns: [{ field: 'Id', direction: 'Descending' }] }"
  [searchSettings]="settings.gridSettings?.searchSettings ?? { fields: settings.searchableFields ?? ['Id'], operator: 'contains', key: '', ignoreCase: true, highlight: true }"
  [columns]="settings.gridSettings?.columns"
  [rowRenderingMode]="settings.gridSettings?.rowRenderingMode"
  [enableAdaptiveUI]="settings.gridSettings?.enableAdaptiveUI"
  [enablePersistence]="settings.gridSettings?.enablePersistence"
  [aggregates]="settings.gridSettings?.aggregates"
  (dataBound)="hooks.onDataBound($event, settings.gridSettings ?? {})"
  (load)="hooks.onLoad($event, settings.gridSettings ?? {}, grid)"
  (created)="hooks.onCreated($event, settings.gridSettings ?? {})"
  (actionBegin)="hooks.onActionBegin($event, settings.gridSettings ?? {})"
  (actionComplete)="hooks.onActionComplete($event, settings.gridSettings ?? {})"
  (toolbarClick)="hooks.onToolbarClick($event, settings.gridSettings ?? {}, grid)"
  (rowSelected)="hooks.onRowSelected($event, settings.gridSettings ?? {})"
  (rowSelecting)="hooks.onRowSelecting($event, settings.gridSettings ?? {})"
  (rowDeselected)="hooks.onRowDeselected($event, settings.gridSettings ?? {})"
  (rowDeselecting)="hooks.onRowDeselecting($event, settings.gridSettings ?? {})"
  (dataStateChange)="hooks.onDataStateChange($event, settings, grid, customFiltering)"
  (rowDataBound)="hooks.onRowDataBound($event, settings.gridSettings ?? {})"
  (cellEdit)="hooks.onCellEdit($event, settings.gridSettings ?? {})"
  (cellSave)="hooks.onCellSave($event, settings.gridSettings ?? {})"
  (commandClick)="hooks.onCommandClick($event, settings.gridSettings ?? {})"
  (queryCellInfo)="hooks.onQueryCellInfo($event)"
  (detailDataBound)="hooks.onDetailDataBound($event, settings.gridSettings ?? {})"
  (beforeCopy)="hooks.onBeforeCopy($event, settings.gridSettings ?? {})">

  <ng-container *ngIf="settings.gridSettings?.detailDataBound">
    <ng-template #detailTemplate let-data>
        <div class="custom-details"></div>
    </ng-template>
  </ng-container>
</ejs-grid>

<ng-content></ng-content>