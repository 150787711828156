<div id="test" class="test">
    
    <section class="page-header">
        <h1>Test Page</h1>
    </section>
    
    <section id="test-content" class="test-content page-content">
        
        <div class="test test1">
            <div class="test-header">
                <h2>Updated Grids</h2>
            </div>
            <div class="test-content">
                <app-workflow-builder id="workflow-builder-1" ></app-workflow-builder>
            </div>
            <div class="test-content">
                <button ejs-button cssClass="e-flat header-btn" (click)="hideVisibility($event)">
                    <h3>Case Files - Standard</h3>
                    <span class="e-icons e-eye-slash"></span>
                </button>
                <app-workflow ></app-workflow>
            </div>
        </div>
        <!-- <div class="test test1">
            <div class="test-header">
                <h2>Workflows</h2>
            </div>
            <div class="test-content">
                <app-workflow-builder id="workflow-builder-1" ></app-workflow-builder>
            </div>
            <div class="test-content">
                <button ejs-button cssClass="e-flat header-btn" (click)="hideVisibility($event)">
                    <h3>Diagram</h3>
                    <span class="e-icons e-eye-slash"></span>
                </button>
                <app-workflow ></app-workflow>
            </div>
        </div> -->

    </section>
</div>