import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticatedServiceBase } from '@core/auth/auth.base';
import { HttpService } from '@core/http/http.service';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

export interface ProcessedComment {
  id: string;
  author: string;
  type: string;
  created_at: string | null;
  last_modified: string | null;
  parent_comment_id: string | null;
  processed_content: {
    summary: string;
    key_points: string[];
    dates: string[];
    actions: string[];
    contacts: string[];
    category: string;
  };
  raw_content: string;
}

export interface CaseStatus {
  case_id: string;
  status: string;
  last_modified: string;
  patient_name: string;
  law_firm_name: string;
  case_manager_name: string;
}

export interface CaseStatistics {
  total_cases: number;
  active_cases: number;
  closed_cases: number;
  average_case_duration: number;
  cases_by_status: { [key: string]: number };
}

export interface CaseAssignment {
  case_id: string;
  case_name: string;
  status: string;
  assigned_to: string;
  assigned_date: string;
}

export interface AgentResponse {
  message: string;
  data?: any;
  status: 'success' | 'error';
}

@Injectable({
  providedIn: 'root',
})
export class AiAgentService extends AuthenticatedServiceBase {
  protected override readonly endpoint = 'api/ai-agent';
  private readonly http = inject(HttpService);

  constructor() {
    super();
  }

  private getHttpOptions(params?: { [key: string]: string }): { headers: HttpHeaders; params?: HttpParams } {
    const options: { headers: HttpHeaders; params?: HttpParams } = {
      headers: new HttpHeaders(this.getAuthHeaders()),
    };

    if (params) {
      options.params = new HttpParams({ fromObject: params });
    }

    return options;
  }

  getCaseStatus(caseId: string): Observable<CaseStatus> {
    return this.http
      .post<CaseStatus>(
        `${this.endpoint}`,
        {
          parameters: [
            { name: 'case_id', value: caseId },
            { name: 'action', value: 'get_case_status' },
          ],
        },
        this.getHttpOptions()
      )
      .pipe(
        catchError(error =>
          this.handleError(error, {
            userMessage: 'Failed to get case status',
            context: 'AiAgentService.getCaseStatus',
          })
        )
      );
  }

  getCaseComments(caseId: string): Observable<{ case_id: string; comments: ProcessedComment[] }> {
    return this.http
      .post<{ case_id: string; comments: ProcessedComment[] }>(
        `${this.endpoint}`,
        {
          parameters: [
            { name: 'case_id', value: caseId },
            { name: 'action', value: 'get_case_comments' },
          ],
        },
        this.getHttpOptions()
      )
      .pipe(
        catchError(error =>
          this.handleError(error, {
            userMessage: 'Failed to get case comments',
            context: 'AiAgentService.getCaseComments',
          })
        )
      );
  }

  searchComments(
    caseId: string,
    searchTerm: string
  ): Observable<{ case_id: string; search_term: string; comments: ProcessedComment[] }> {
    return this.http
      .post<{ case_id: string; search_term: string; comments: ProcessedComment[] }>(
        `${this.endpoint}`,
        {
          parameters: [
            { name: 'case_id', value: caseId },
            { name: 'action', value: 'search_comments' },
            { name: 'search_term', value: searchTerm },
          ],
        },
        this.getHttpOptions()
      )
      .pipe(
        catchError(error =>
          this.handleError(error, {
            userMessage: 'Failed to search comments',
            context: 'AiAgentService.searchComments',
          })
        )
      );
  }

  getCaseAssignments(assignedTo: string): Observable<{ assigned_to: string; cases: CaseAssignment[] }> {
    return this.http
      .post<{ assigned_to: string; cases: CaseAssignment[] }>(
        `${this.endpoint}`,
        {
          parameters: [{ name: 'assigned_to', value: assignedTo }],
        },
        this.getHttpOptions()
      )
      .pipe(
        catchError(error =>
          this.handleError(error, {
            userMessage: 'Failed to get case assignments',
            context: 'AiAgentService.getCaseAssignments',
          })
        )
      );
  }

  getCaseStatistics(): Observable<{ statistics: CaseStatistics }> {
    return this.http
      .post<{ statistics: CaseStatistics }>(
        `${this.endpoint}`,
        {
          parameters: [],
        },
        this.getHttpOptions()
      )
      .pipe(
        catchError(error =>
          this.handleError(error, {
            userMessage: 'Failed to get case statistics',
            context: 'AiAgentService.getCaseStatistics',
          })
        )
      );
  }

  sendPrompt(prompt: string, caseId: string): Observable<AgentResponse> {
    return this.http
      .post<AgentResponse>(
        `${this.endpoint}/prompt`,
        {
          case_id: caseId,
          prompt: prompt,
          functionName: 'arn:aws:lambda:us-west-2:193874396980:function:case-companion-basic-us-west-2-193874396980',
        },
        this.getHttpOptions()
      )
      .pipe(
        catchError(error =>
          this.handleError(error, {
            userMessage: 'Failed to process prompt',
            context: 'AiAgentService.sendPrompt',
          })
        )
      );
  }
}
