// Angular
import { Component, computed, Input, signal, WritableSignal, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Subject, Subscription, takeUntil } from 'rxjs';

// 3rd Party
import { FontAwesomeModule, IconDefinition } from '@fortawesome/angular-fontawesome';
import {
  faCopy,
  faEnvelope,
  faEdit,
  faNoteSticky,
  faLocationDot,
  faFax,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import { Tooltip, TooltipAllModule } from '@syncfusion/ej2-angular-popups';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { Query } from '@syncfusion/ej2-data';
import { DialogAllModule, DialogComponent } from '@syncfusion/ej2-angular-popups';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { CaseFile, Contact, LawFirm, Patient, User } from '@models/data-contracts';
import { ApiService } from '@services/api/api.service';
import { FileHubService } from '@features/file-hub/services/file-hub.service';
import { LoadingModule } from '@modules/loading.module';
import { StatusComponent } from '@ui/status/status.component';
import { PatientInfoComponent } from '@ui/patient-info/patient-info.component';
import { AccordionAllModule, ExpandEventArgs } from '@syncfusion/ej2-angular-navigations';
import { PerformanceData } from '@features/financial/models/financial-performance.model';
import { CommonModule } from '@angular/common';
import { CaseFileFormComponent } from '@root/app/features/case-files/components/case-file-form/case-file-form.component';
import { ContactsService } from '@services/contacts/contacts.service';
import { capitalizeFirstLetter, safeFormatDateToMMDDYYYY, formatBoolean } from '@app/utils';
import { ComponentBase } from '@core/base/component.base';

const ERRORS = {
  LOAD_FAILED: {
    message: 'Failed to load case file data',
    technical: 'Error loading case file data'
  },
  SUBMIT_FAILED: {
    message: 'Failed to update case file',
    technical: 'Error updating case file'
  },
  EDIT_FAILED: {
    message: 'Failed to edit case file',
    technical: 'Error editing case file'
  },
  LOAD_CONTACT_FAILED: {
    message: 'Failed to load contact details',
    technical: 'Error loading contact details'
  },
  COPY_FAILED: {
    message: 'Failed to copy patient demographics to clipboard',
    technical: 'Error copying patient demographics to clipboard'
  },
};

interface AdditionalInfoItem {
  id: string;
  label: string;
  value: any;
  clickable?: boolean;
  onClick?: () => void;
}

@Component({
  selector: 'hub-header',
  standalone: true,
  imports: [
    FontAwesomeModule,
    TooltipAllModule,
    ButtonModule,
    StatusComponent,
    PatientInfoComponent,
    AccordionAllModule,
    CommonModule,
    DialogAllModule,
    CaseFileFormComponent,
    LoadingModule,
  ],
  templateUrl: './file-hub-header.component.html',
  styleUrl: './file-hub-header.component.scss',
})
export class FileHubHeaderComponent extends ComponentBase implements OnInit, OnDestroy {
  constructor(
    private api: ApiService,
    public fileHub: FileHubService,
    public contactsService: ContactsService
  ) {
    super();
  }

  // Signals
  public loadingHeader = signal(true);
  public loadingPatient = signal(true);
  protected readonly headerData = signal<{
    fileNumber: string | null;
    comments: string | null;
    patient: Patient | null;
    lawFirm: LawFirm | null;
    caseManager: User | null;
    marketManager: User | null;
  }>({
    fileNumber: null,
    comments: null,
    patient: null,
    lawFirm: null,
    caseManager: null,
    marketManager: null,
  });
  protected readonly loading = {
    patient: signal(true),
  };
  protected readonly loadingContact = signal(false);
  protected capitalizeFirstLetter = capitalizeFirstLetter;
  protected readonly data = {
    header: signal<Array<{ id: string; label: string; value: any }>>([]),
    additionalInfo: signal<Array<AdditionalInfoItem>>([]),
    phoneNumber: signal('Loading...'),
  };
  protected readonly ui = {
    columnClass: signal('col-3'),
    expandText: signal('Show Additional Info'),
  };
  protected readonly isDataLoaded = computed(() => !this.loadingHeader() && !this.loadingPatient());
  protected readonly shouldReload = computed(() => this.fileHub.reload);
  protected readonly isPerformanceLoaded = computed(() =>
    !!this.fileHub.performanceData
  );

  // Private variables
  private watchReload?: Subscription;
  private destroy$ = new Subject<void>();

  // Public variables
  patient?: Patient;
  caseFileSignal: WritableSignal<CaseFile | undefined> = signal(undefined);
  faCopy: IconDefinition = faCopy;
  faEnvelope: IconDefinition = faEnvelope;
  faEdit: IconDefinition = faEdit;
  faPhone = faPhone;
  faFax = faFax;
  faLocationDot = faLocationDot;
  faNotes = faNoteSticky;

  @ViewChild('contactDialog') contactDialog!: DialogComponent;
  protected selectedContact = signal<Contact | null>(null);
  public contactDialogButtons: object[] = [
    {
      click: () => {
        this.contactDialog.hide();
        this.selectedContact.set(null);
      },
      buttonModel: {
        content: 'Close',
        cssClass: 'e-outline',
      },
    },
  ];

  async onContactClick(type: 'law-firm-contact' | 'attorney') {
    try {
      this.loadingContact.set(true);
      this.contactDialog.show();
      const contact =
        type === 'law-firm-contact'
          ? this.fileHub.caseFile?.LawFirmContactNavigation
          : this.fileHub.caseFile?.AttorneyNavigation;
      const query = new Query().expand([
        'XrefPhoneContacts($expand=Phone($expand=PhoneTypeNavigation))',
        'XrefAddressContacts($expand=Address($expand=StateNavigation))',
      ]);

      if (contact?.Id) {
        const contactDetails = await this.contactsService.getContactById(contact.Id, query);
        if (contactDetails) {
          // Sort phone contacts by type so phone appears before fax
          contactDetails.XrefPhoneContacts?.sort((a, b) => (a.Phone?.PhoneType ?? 0) - (b.Phone?.PhoneType ?? 0));
          this.selectedContact.set(contactDetails);
        }
      }

      return this.selectedContact();
    } catch (error) {
      this.handleError(error, {
        context: 'FileHubHeaderComponent.onContactClick',
        userMessage: 'Failed to load contact details'
      });
      return error;
    } finally {
      this.loadingContact.set(false);
      return;
    }
  }

  makePhoneCall(phoneNumber: string) {
    window.location.href = `tel:${phoneNumber}`;
  }

  sendEmail(email: string) {
    try {
      window.location.href = `mailto:${email}`;
    } catch (error) {
      this.handleError(error, {
        context: 'FileHubHeaderComponent.sendEmail',
        userMessage: 'Failed to open email client'
      });
    }
  }

  // Edit Case File Dialog
  @ViewChild('editCaseFileDialog', { static: true }) editCaseFileDialog: DialogComponent;
  @ViewChild('editFileForm') editFileForm: CaseFileFormComponent;
  editCaseFileButtons: object[] = [
    { click: this.closeDialog.bind(this), buttonModel: { content: 'Cancel', cssClass: 'e-outline' } },
    {
      click: this.onEditCaseFileSubmit.bind(this),
      buttonModel: { content: 'Submit', isPrimary: true, cssClass: 'e-primary' },
    },
  ];

  // Watch for reloads
  ngOnInit() {
    this.watchReload = this.fileHub.reload$.subscribe(() => {
      this.loadingHeader.set(true);
      this.loadingPatient.set(true);
      this.checkData();
    });

    // Subscribe to caseFile$ to detect performance data changes
    this.fileHub.caseFile$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
      });

    this.checkData();
  }

  ngOnDestroy() {
    if (this.watchReload) {
      this.watchReload.unsubscribe();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }

  // Ensure data dependencies are loaded at component level
  async checkData() {
    if (!this.fileHub.caseFile) return;

    try {
      this.addHeaderDataToCaseFile();
    } catch (error) {
      this.handleError(error, {
        context: 'FileHubHeaderComponent.checkData',
        userMessage: 'Failed to load case file data'
      });
    } finally {
      this.loadingHeader.set(false);
      this.loadingPatient.set(false);
    }
  }

  // Fetch and update case file with header data
  private async addHeaderDataToCaseFile() {
    this.loadingHeader.set(true);
    this.loadingPatient.set(true);

    try {
      const endpoint = `${APIEndpoints.Casefiles}(${this.fileHub.caseFileId})`;
      const expandString = `Patient($expand=XrefAddressPatients($expand=Address($expand=StateNavigation)),XrefPhonePatients($expand=Phone)),CaseManagerNavigation,MarketManagerNavigation,LawFirm,PAndLNavigation,LawFirmContactNavigation,AttorneyNavigation,ReferralSourceNavigation,RecordStatus($select=Description),ReferringPhysicianNavigation,StatusingGroupNavigation`;
      const selectString = `Patient,LawFirm,CaseManagerNavigation,MarketManagerNavigation,PAndL,PAndLNavigation,LawFirmContactNavigation,AttorneyNavigation,ReferralSourceNavigation,RecordStatus,ReferringPhysicianNavigation,StatusingGroupNavigation,DateOfLoss`;
      const hubHeaderQuery = new Query().expand(expandString).select(selectString);
      const res = await this.api
        .getOdata(endpoint)
        .executeQuery(hubHeaderQuery)
        .then(r => r);
      const file = (res as any).result[0] as CaseFile;

      // Update signal in file hub header service
      this.headerData.update(current => ({
        ...current,
        fileNumber: this.fileHub.caseFile?.FileNumber ?? null,
        comments: this.fileHub.caseFile?.Comments ?? null,
        patient: file.Patient ?? null,
        lawFirm: file.LawFirm ?? null,
        pAndL: file.PAndLNavigation ?? null,
        caseManager: file.CaseManagerNavigation ?? null,
        marketManager: file.MarketManagerNavigation ?? null,
        dateOfLoss: file.DateOfLoss ?? null,
      }));

      // Update global state with all new data
      const updates: Partial<CaseFile> = {
        Patient: file.Patient,
        LawFirm: file.LawFirm,
        CaseManagerNavigation: file.CaseManagerNavigation,
        MarketManagerNavigation: file.MarketManagerNavigation,
        PAndLNavigation: file.PAndLNavigation,
        LawFirmContactNavigation: file.LawFirmContactNavigation,
        AttorneyNavigation: file.AttorneyNavigation,
        ReferralSourceNavigation: file.ReferralSourceNavigation,
        ReferringPhysicianNavigation: file.ReferringPhysicianNavigation,
        RecordStatus: file.RecordStatus,
        DateOfLoss: file.DateOfLoss,
      };

      // Update each property in the global caseFile signal
      Object.entries(updates).forEach(([key, value]) => {
        if (value !== undefined) this.fileHub.updateCaseFile(key, value);
      });

      // Update global state only if needed
      if (!this.fileHub.caseFile?.Patient) {
        this.fileHub.updateCaseFile('Patient', file.Patient);
      }

      this.loadingPatient.set(false);

      return this.headerData();
    } catch (error) {
      this.handleError(error, {
        context: 'FileHubHeaderComponent.addHeaderDataToCaseFile',
        userMessage: 'Failed to load case file data'
      });
      throw error; // Re-throw to be handled by caller
    } finally {
      this.loadingHeader.set(false);
      return;
    }
  }

  // Copies patient demographics to clipboard
  handleCopyPatientDemographics(args: any) {
    const patient = this.fileHub.caseFile?.Patient;

    const formattedText = `Patient Information:
      Name: ${patient?.Firstname} ${patient?.Lastname}
      Date of Birth: ${safeFormatDateToMMDDYYYY(patient?.Dob)}
      Gender: ${patient?.Gender || 'Not provided'}
      Language: ${patient?.Language || 'Not provided'}
      Email: ${patient?.Email || 'Not provided'}
      Minor: ${patient?.Minor ? 'Yes' : 'No'}
      Phone: ${patient?.Minor ? 'On file' : 'Not provided'}
      Date Of Loss: ${safeFormatDateToMMDDYYYY(this.fileHub.caseFile?.DateOfLoss)}
      File Details:
      File #: ${this.fileHub.caseFile?.FileNumber}
      Law Firm: ${this.fileHub.caseFile?.LawFirm?.Name}
      Liability:
      Notes: ${this.fileHub.caseFile?.Comments || 'None'}`;

    navigator.clipboard
      .writeText(formattedText)
      .then(() => {
        this.notify(`Copied patient demographics to clipboard.`);
      })
      .catch(err => {
        this.handleError(err, {
          context: 'FileHubHeaderComponent.handleCopyPatientDemographics',
          userMessage: 'Failed to copy patient demographics to clipboard'
        });
      });
  }

  // Generates email with file information
  emailAuthRequest() {
    if (this.fileHub.caseFile !== undefined) {
      const email = 'fileauth@wshcgroup.com';
      const subject = this.fileHub.caseFile.FileNumber as string;
      const body = `File #: ${this.fileHub.caseFile.FileNumber}
        URL: ${window.location.href}
        Name: ${this.fileHub.caseFile.Patient?.Firstname} ${this.fileHub.caseFile?.Patient?.Lastname}
        Law Firm ${this.fileHub.caseFile.LawFirm?.Name}
        Liability:
        MVA or Premise:
        UM/UIM:
        Specials:
        Auth Amount Requested:
        Note Regarding Request: `;
      const href = `${email}?subject=${subject}&body=${body}`;
      const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      window.location.href = mailtoLink;
    }
  }

  // Expanded state for additional info accordion
  expanded(args: ExpandEventArgs) {
    if (!this.fileHub.caseFile) return;
    this.mapAdditionalInfoData();
    this.initializeTooltip();
    this.ui.expandText.set(args.isExpanded ? 'Hide Additional Info' : 'Show Additional Info');
  }

  // Displays tooltip if text overflows
  private initializeTooltip() {
    const infoDivs = document.querySelectorAll('.info');

    infoDivs.forEach((infoDiv, index) => {
      const infoText = infoDiv.querySelector('span');
      if (!infoText) return;

      const hasOverflowText = infoText.scrollWidth > infoText.clientWidth;
      if (!hasOverflowText) return;

      infoDiv.id = `info-${index}`;
      new Tooltip({
        content: infoText.textContent as string,
        target: `#info-${index}`,
      }).appendTo(infoDiv as HTMLElement);

      infoDiv.classList.remove('e-control');
    });
  }

  // Then modify the mapAdditionalInfoData method to use this function
  private mapAdditionalInfoData() {
    const lawFirmContact = this.fileHub.caseFile?.LawFirmContactNavigation;
    const lawFirmContactName = lawFirmContact ? capitalizeFirstLetter(lawFirmContact.ContactName ?? '') : '';

    const recordStatus = this.fileHub.caseFile?.RecordStatus?.Description ?? 'Unknown';
    const patientAddress = this.fileHub.caseFile?.Patient?.XrefAddressPatients?.[0]?.Address;

    this.data.additionalInfo.set([
      { id: 'record-status', label: 'Record Status', value: recordStatus },
      {
        id: 'in-treatment',
        label: 'In Treatment',
        value: formatBoolean(this.fileHub.caseFile?.InTreatment ?? false),
      },
      {
        id: 'law-firm-contact',
        label: 'Law Firm Contact',
        value: lawFirmContactName,
        clickable: true,
        onClick: () => this.onContactClick('law-firm-contact'),
      },
      {
        id: 'attorney',
        label: 'Attorney',
        value: capitalizeFirstLetter(this.fileHub.caseFile?.AttorneyNavigation?.ContactName ?? ''),
        clickable: true,
        onClick: () => this.onContactClick('attorney'),
      },
      { id: 'language', label: 'Language', value: this.fileHub.caseFile?.Patient?.Language },
      {
        id: 'statute-of-limitations',
        label: 'Statute of Limitations',
        value: safeFormatDateToMMDDYYYY(this.fileHub.caseFile?.StatuteOfLimitations),
      },
      {
        id: 'client-address-1',
        label: 'Client Address 1',
        value: this.fileHub.caseFile?.Patient?.XrefAddressPatients?.[0]?.Address?.Address1,
      },
      {
        id: 'client-address-2',
        label: 'Client Address 2',
        value: this.fileHub.caseFile?.Patient?.XrefAddressPatients?.[0]?.Address?.Address2,
      },
      { id: 'gender', label: 'Gender', value: this.fileHub.caseFile?.Patient?.Gender },
      {
        id: 'client-city',
        label: 'Client City',
        value: this.fileHub.caseFile?.Patient?.XrefAddressPatients?.[0]?.Address?.City,
      },
      {
        id: 'client-state',
        label: 'Client State',
        value: this.fileHub.caseFile?.Patient?.XrefAddressPatients?.[0]?.Address?.StateNavigation?.Name,
      },
      {
        id: 'client-zip',
        label: 'Client Zip',
        value: this.fileHub.caseFile?.Patient?.XrefAddressPatients?.[0]?.Address?.Zip,
      },
      {
        id: 'client-county',
        label: 'Client County',
        value: this.fileHub.caseFile?.Patient?.XrefAddressPatients?.[0]?.Address?.County,
      },
      {
        id: 'referral-source',
        label: 'Referral Source',
        value: this.fileHub.caseFile?.ReferralSourceNavigation?.Description,
      },
      {
        id: 'state-legal-characteristics',
        label: 'State Legal Characteristics',
        value: this.fileHub.caseFile?.Patient?.XrefAddressPatients?.[0]?.Address?.StateNavigation?.Description,
      },
    ]);
    this.loading.patient.set(false);
  }

  closeDialog() {
    if (this.editCaseFileDialog) this.editCaseFileDialog.hide();
  }

  onEditCaseFileSubmit(args: any) {
    // @ts-ignore: Method exists at runtime
    (this.editFileForm as any).onSubmit().then((result: any) => {
      if (result instanceof Error) {
        this.handleError(result, {
          context: 'FileHubHeaderComponent.onEditCaseFileSubmit',
          userMessage: 'Failed to update case file',
          severity: this.ErrorSeverity.Error
        });
        throw new Error(result.message);
      }

      if (result && (result.ok || result.Id)) {
        this.closeDialog();
        this.notify('Case File successfully updated. Reloading page...');

        // Add a small delay before reloading to allow the toast to be visible
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    });
  }

  showEditCaseFileDialog() {
    this.caseFileSignal.set(this.fileHub.caseFile);
    this.editCaseFileDialog.show();

    // Add a slight delay to ensure the dialog is rendered before calling onDialogOpen
    setTimeout(() => {
      if (this.editFileForm) {
        this.editFileForm.onDialogOpen();
      }
    }, 100);
  }
}
