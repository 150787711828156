<div id="box-sign-tab">

    <tab-card [heading]="'Sign Requests'">
        <div style="height: 20px;"></div>
        <ng-container *ngIf="loadingBoxSign(); else signContent">
            <loading-overlay [loading]="loadingBoxSign" />
        </ng-container>

        <ng-template #signContent>
            <ng-container *ngIf="fileHub.caseFile && fileHub.caseFile.FileNumber; else noData">
                <box-sign
                    [caseFileId]="fileHub.caseFile.Id"
                    [fileNumber]="fileHub.caseFile.FileNumber"
                    (folder)="getData($event)"
                    (template)="getData($event)"
                    (formValues)="getData($event)">
                </box-sign>
            </ng-container>
            <ng-template #noData>
                <no-data [warningText]="'Unable to retrieve Sign Requests without Case File Id & File Number'" />
            </ng-template>
        </ng-template>
    </tab-card>

</div>
