// Angular
import { Component, OnInit, ViewEncapsulation, effect } from '@angular/core';
import { CommonModule } from '@angular/common';

// 3rd Party
import { Browser } from '@syncfusion/ej2-base';
import { DashboardLayoutModule } from '@syncfusion/ej2-angular-layouts';

// Services
import { ApiService } from '@services/api/api.service';
import { UserPreferencesService } from '@services/user/user-preferences.service';
import { DashboardQueriesService } from '@root/src/app/shared/services/dashboard-queries/dashboard-queries.service';
import { DashboardCardsService } from '@root/src/app/shared/services/dashboard-cards/dashboard-cards.service';
import { WorkItemsService } from '@features/work-items/services/work-items.service';

// Models & Constants
import { APIEndpoints } from '@models/api/Endpoints';
import { CustomCardModel } from '@models/components/card.model';
import { CardComponent } from '@ui/card/card.component';
import { Query } from '@syncfusion/ej2-data';

interface DashboardState {
  activeFilter: 'all' | 'upcoming' | 'overdue';
}

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [DashboardLayoutModule, CardComponent, CommonModule],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class DashboardComponent implements OnInit {
  constructor(
    private api: ApiService,
    private user: UserPreferencesService,
    private queries: DashboardQueriesService,
    private cardService: DashboardCardsService,
    private workItems: WorkItemsService
  ) {
    this.today.setHours(0, 0, 0, 0);
    
    // Move initialization logic to constructor effect
    effect(() => {
      const userId = this.user.userId();
      if (userId) {
        const workItems = this.cardService.createWorkItemsCard((args) => this.handleWorkItemsToolbarClick(args));
        this.cards = [workItems, ...this.cards];
        this.initializeCards();
      }
    });
  }

  // Lifecycle hook - initializes dashboard
  async ngOnInit(): Promise<void> {
    await this.user.initializeUserId();
    await this.loadWorkItems();
  }

  // Responsive layout configuration
  readonly cellSpacing: number[] = [20, 15];
  readonly columns: number = Browser.isDevice ? 1 : 3;
  readonly cellAspectRatio: number = Browser.isDevice ? 1 : 1.5;

  // Core state management
  private readonly today = new Date();

  private state: DashboardState = {
    activeFilter: 'all'
  };

  cards: CustomCardModel[] = [];

  // Replace the card creation methods with service calls
    private async initializeCards(): Promise<void> {
      this.cardService.createWorkItemsCard((args) => this.handleWorkItemsToolbarClick(args))
      const [appointments, caseFiles] = await Promise.all([  
        this.cardService.createAppointmentsCard(),  
        this.cardService.createCaseFilesCard(),

      ])
      await this.loadWorkItems();
      this.cards = [...this.cards, appointments, caseFiles];
  }

  private handleWorkItemsToolbarClick(args: any): void {
    const workItemsCard = this.cards.find((card) => card.cardId === 'work-items');
    if (!workItemsCard?.gridSettings) return;

    const userId = this.user.userId();
    if (!userId) return;

    let isCalendarView = false;
    try {
      isCalendarView = workItemsCard.showCalendarView?.() ?? false;
    } catch (error) {
      console.error('Error accessing calendar view signal:', error);
      isCalendarView = false;
    }

    // Extract the filter type from button text
    const buttonText = args.item.text;
    console.log('Button clicked:', buttonText); // Debug the actual button text

    const filterType =
      buttonText.includes('All')  // Change from exact match to includes
        ? 'All'
        : buttonText.includes('Overdue')
          ? 'Overdue'
          : buttonText.includes('Upcoming')
            ? 'Upcoming'
            : null;

    console.log('Filter type:', filterType); // Debug the detected filter type

    // Get appropriate query and update grid
    if (filterType === 'Overdue') {
      const query = this.workItems.getOverdueWorkItemsQuery(true).where('AssignedTo', 'equal', userId);
      this.workItems.getWorkItems()
        .executeQuery(query)
        .then((result: any) => {
          workItemsCard.gridSettings!.dataSource = result.result;
          this.state.activeFilter = this.getFilterTypeFromText(buttonText);
        });
    } else if (filterType === 'All') {
      console.log('All');
      // Get all non-completed tasks
      const query = new Query()
        .expand([
          'XrefWorkItemCaseFiles($select=CaseFileId;$expand=CaseFile($select=FileNumber))',
          'Status($select=Name)',
          'Priority($select=Name)',
          'Type($select=Name)',
          'Category($select=Name)',
          'AssignedToNavigation($select=Name)'
        ])
        .where('AssignedTo', 'equal', userId)
        .where('Status/Name', 'notequal', 'Completed');
      
      this.workItems.getWorkItems()
        .executeQuery(query)
        .then((result: any) => {
          workItemsCard.gridSettings!.dataSource = result.result;
          this.state.activeFilter = this.getFilterTypeFromText(buttonText);
        });
    } else {
      // For upcoming, use existing logic
      (isCalendarView 
        ? this.workItems.getMonthlyWorkItems(userId)
        : this.workItems.getTodaysTasks(userId))
      .then((result: any) => {
        workItemsCard.gridSettings!.dataSource = result;
        this.state.activeFilter = this.getFilterTypeFromText(buttonText);
      });
    }
  }

  setActiveWorkItemFilter(filter: 'all' | 'overdue' | 'upcoming'): void {
    this.state.activeFilter = filter;
    const workItemsCard = this.cards.find((card) => card.cardId === 'work-items');
    if (!workItemsCard?.gridSettings) return;
    const userId = this.user.userId();
    if (!userId) return;

    // Safely check calendar view with error handling
    let isCalendarView = false;
    try {
      isCalendarView = workItemsCard.showCalendarView?.() ?? false;
    } catch (error) {
      console.error('Error accessing calendar view signal:', error);
      // Default to grid view on error
      isCalendarView = false;
    }

    const filterMap = {
      all: () => isCalendarView ? this.workItems.getBaseQuery() : this.workItems.getTodaysTasksQuery(),
      overdue: () => this.workItems.getOverdueWorkItemsQuery(),
      upcoming: () => this.workItems.getUpcomingWorkItemsQuery(),
    };

    workItemsCard.gridSettings.query = filterMap[filter]();
  }

  getWorkItemCount(filter: 'overdue' | 'upcoming'): number {
    const workItemsCard = this.cards.find((card) => card.cardId === 'work-items');
    if (!workItemsCard?.gridSettings?.dataSource) return 0;

    const items = workItemsCard.gridSettings.dataSource as any[];
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return items.filter(item => {
      const dueDate = new Date(item.DueDate);
      if (filter === 'overdue') {
        return dueDate < today && item.Status !== 'Completed';
      } else {
        return dueDate >= today && item.Status !== 'Completed';
      }
    }).length;
  }

  private getFilterTypeFromText(text: string): 'all' | 'upcoming' | 'overdue' {
    if (text.includes('Upcoming')) return 'upcoming';
    if (text.includes('Overdue')) return 'overdue';
    return 'all';
  }

  async loadWorkItems() {
    const userId = this.user.userId();
    if (!userId) return;

    const workItemsCard = this.cards.find((card) => card.cardId === 'work-items');
    if (!workItemsCard) return;

    try {
      workItemsCard.isLoading = true;
      const isCalendarView = workItemsCard.showCalendarView?.() ?? false;
      const items = isCalendarView ?
        await this.workItems.getMonthlyWorkItems(userId) :
        await this.workItems.getTodaysTasks(userId);

      if (workItemsCard.gridSettings) {
        workItemsCard.gridSettings.dataSource = items;

        // Get overdue count
        const overdueQuery = this.workItems.getOverdueWorkItemsQuery(true)
          .where('AssignedTo', 'equal', userId);
        const overdueResult = await (this.workItems.getWorkItems()
          .executeQuery(overdueQuery) as unknown) as { result: any[] };

        // Count upcoming tasks from initial data
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const upcomingCount = items.filter((item: any) => 
          new Date(item.DueDate) >= today && item.Status.Name !== 'Completed'
        ).length;

        // Count all non-completed tasks from today
        const allCount = items.filter((item: any) => 
          new Date(item.DueDate) >= today && item.Status.Name !== 'Completed'
        ).length;

        const toolbar = workItemsCard.gridSettings.toolbar as string[];
        
        // Update all counts
        const allIndex = toolbar.findIndex(item => item.includes('All'));
        const overdueIndex = toolbar.findIndex(item => item.includes('Overdue'));
        const upcomingIndex = toolbar.findIndex(item => item.includes('Upcoming'));

        if (allIndex !== -1) {
          toolbar[allIndex] = 'All';  // No count for All
        }
        if (overdueIndex !== -1) {
          toolbar[overdueIndex] = `${overdueResult.result?.length || 0} Overdue`;
        }
        if (upcomingIndex !== -1) {
          toolbar[upcomingIndex] = `${upcomingCount} Upcoming`;
        }

        workItemsCard.gridSettings.toolbar = [...toolbar];
        workItemsCard.gridSettings = { ...workItemsCard.gridSettings };
      }

      if (workItemsCard.scheduleSettings?.eventSettings) {
        workItemsCard.scheduleSettings.eventSettings.dataSource = items;
      }
      
      workItemsCard.isLoading = false;
    } catch (error) {
      console.error('Error loading work items:', error);
      workItemsCard.isLoading = false;
    }
  }

  // Call loadWorkItems in ngOnInit and when view changes
}
