import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { ErrorHandlingService } from './error-handling.service';
import { NotificationService } from './notification.service';
import { CognitoService } from '@services/auth/cognito.service';

@Injectable()
export abstract class AuthenticatedBaseService extends BaseService {
    protected readonly cognito: CognitoService;

    constructor(
        http: HttpClient,
        errorHandling: ErrorHandlingService,
        notification: NotificationService,
        cognito: CognitoService
    ) {
        super(http, errorHandling, notification);
        this.cognito = cognito;
    }

    protected getAuthHeaders(): { [key: string]: string } {
        return {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.cognito.getUserIdToken()
        };
    }
}
