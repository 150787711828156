<div *ngIf="isLoading">
  <loading-overlay />
</div>

<app-account-details
  *ngIf="lawFirmData"
  [expandableDetails]="expandableDetails"
  [details]="lawFirmDetails"
  [tabs]="tabs"
  [isLoading]="isLoading"
  [entityType]="APIEndpoints.Lawfirms"
  [entityId]="lawFirmData.Id"
  [payload]="lawFirmData"
  >
</app-account-details>