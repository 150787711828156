<!-- Address Form Start -->
<div id="address-form" [class]="formClasses.formContainer">
    <h2 [class]="formClasses.mainTitle" *ngIf="displayTitle">
        <fa-icon [icon]="addressIcons.form" />
        <span>{{formMainTitle ?? 'Address'}}</span>
    </h2>
    
    <form [formGroup]="addressForm" [class]="formClasses.form" (ngSubmit)="onSubmit()">

        <section [class]="formClasses.section + ' w-100'">
            
            <div [class]="formClasses.group">

                <div *ngIf="displayFields.includes('Address1')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol" [class.error]="addressForm.get('Address1')?.errors && addressForm.get('Address1')?.touched">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="addressIcons.address1" />
                        <ejs-tooltip *ngIf="addressForm.get('Address1')?.errors && addressForm.get('Address1')?.touched" [content]="getErrorMessage('Address1')" />
                    </span>
                    <ejs-textbox formControlName="Address1" 
                        placeholder="Address 1" 
                        floatLabelType="Auto"
                        [class]="formClasses.input"
                        [value]="addressForm.get('Address1')?.value" />
                    <ng-container *ngIf="addressForm.get('Address1')?.invalid && addressForm.get('Address1')?.touched">
                        <span [class]="formClasses.error">{{errorMessage}}</span>
                    </ng-container>
                </div>
            
                <div *ngIf="displayFields.includes('Address2')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol" [class.error]="addressForm.get('Address2')?.errors && addressForm.get('Address2')?.touched">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="addressIcons.address2" />
                        <ejs-tooltip *ngIf="addressForm.get('Address2')?.errors && addressForm.get('Address2')?.touched" [content]="getErrorMessage('Address2')" />
                    </span>
                    <ejs-textbox [class]="formClasses.input" formControlName="Address2" placeholder="Address 2" />
                    <ng-container *ngIf="addressForm.get('Address2')?.invalid && addressForm.get('Address2')?.touched">
                        <span [class]="formClasses.error">{{getErrorMessage('Address2')}}</span>
                    </ng-container>
                </div>
            
                <div *ngIf="displayFields.includes('City')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol" [class.error]="addressForm.get('City')?.errors && addressForm.get('City')?.touched">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="addressIcons.city" />
                        <ejs-tooltip *ngIf="addressForm.get('City')?.errors && addressForm.get('City')?.touched" [content]="getErrorMessage('City')" />
                    </span>
                    <ejs-textbox [class]="formClasses.input" formControlName="City" placeholder="City" />
                    <ng-container *ngIf="addressForm.get('City')?.invalid && addressForm.get('City')?.touched">
                        <span [class]="formClasses.error">{{getErrorMessage('City')}}</span>
                    </ng-container>
                </div>
            
                <div *ngIf="displayFields.includes('State')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol" [class.error]="addressForm.get('State')?.errors && addressForm.get('State')?.touched">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="addressIcons.state" />
                        <ejs-tooltip *ngIf="addressForm.get('State')?.errors && addressForm.get('State')?.touched" [content]="getErrorMessage('State')" />
                    </span>
                    <ejs-dropdownlist formControlName="State" 
                        [placeholder]="'State'" 
                        [class]="formClasses.input"
                        [dataSource]="states"
                        [fields]="{ text: 'Name', value: 'Id' }"
                        [value]="addressForm.get('State')?.value" />
                </div>
            
                <div *ngIf="displayFields.includes('Zip')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol" [class.error]="addressForm.get('Zip')?.errors && addressForm.get('Zip')?.touched">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="addressIcons.zip" />
                    </span>
                    <ejs-maskedtextbox formControlName="Zip" 
                        [class]="formClasses.input"
                        placeholder="Zip"
                        [mask]="'99999'" />
                    <ng-container *ngIf="addressForm.get('Zip')?.invalid && addressForm.get('Zip')?.touched">
                        <span [class]="formClasses.error">Required</span>
                    </ng-container>
                </div>
            
                <div *ngIf="displayFields.includes('County')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol" [class.error]="addressForm.get('County')?.errors && addressForm.get('County')?.touched">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="addressIcons.county" />
                        <ejs-tooltip *ngIf="addressForm.get('County')?.errors && addressForm.get('County')?.touched" [content]="getErrorMessage('County')" />
                    </span>
                    <ejs-textbox [class]="formClasses.input" formControlName="County" placeholder="County" />
                    <ng-container *ngIf="addressForm.get('County')?.invalid && addressForm.get('County')?.touched">
                        <span [class]="formClasses.error">{{getErrorMessage('County')}}</span>
                    </ng-container>
                </div>

                <div *ngIf="displayFields.includes('AddressType')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol" [class.error]="addressForm.get('AddressType')?.errors && addressForm.get('AddressType')?.touched">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="addressIcons.addressType" />
                        <ejs-tooltip *ngIf="addressForm.get('AddressType')?.errors && addressForm.get('AddressType')?.touched" [content]="getErrorMessage('AddressType')" />
                    </span>
                    <ejs-dropdownlist formControlName="AddressType" 
                        floatLabelType="Auto"
                        [placeholder]="'Address Type'" 
                        [class]="formClasses.input"
                        [dataSource]="addressTypes"
                        [value]="addressTypeValue"
                        [fields]="{ text: 'Description', value: 'Id', id: 'Id' }"
                        (created)="onAddressTypesCreated($event)"
                        (change)="onAddressTypeChange($event)" />
                </div>
            </div>
    
        </section>

        <section [class]="formClasses.section + ' col-12'" *ngIf="displayButtons">
            <div [class]="formClasses.actions">
                <button ejs-button type="button">Cancel</button>
                <button ejs-button [isPrimary]="true" type="submit">Submit</button>
            </div>
        </section>

    </form>

</div>
<!-- Address Form End -->