// Angular
import { CommonModule, DatePipe } from '@angular/common';
import { Component, signal, WritableSignal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// Models
import { APIEndpoints } from '@root/src/app/shared/models/api/Endpoints';

// Services
import { PatientsService } from '@features/patients/services/patients.service';
// Components
import { AccountDetailsComponent } from '@components/shared/account-details/account-details.component';

@Component({
  selector: 'app-patient-detail',
  standalone: true,
  imports: [
    CommonModule,
    AccountDetailsComponent
  ],
  templateUrl: './patient-detail.component.html',
  styleUrl: './patient-detail.component.scss',
  providers: [
    DatePipe
  ]
})
export class PatientDetailComponent {

  // Signals
  patientData: WritableSignal<any> = signal(undefined);
  isLoading: WritableSignal<boolean> = signal(true);

  // Public Properties
  route: ActivatedRoute;
  entityType = APIEndpoints.Patients;
  expandableDetails: Array<{ label: string, value: any }> = [];
  patientDetails: Array<Array<{ label: string, value: any }>> = [];

  constructor(
    private datePipe: DatePipe,
    private patientsService: PatientsService,
    route: ActivatedRoute
  ) {
    this.route = route;
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.getPatientById(parseInt(params['id']));
    });
  }

  private getPatientById(id: number) {
    this.isLoading.set(true);
    this.patientsService.getPatientById(id)
      .then((response: any) => {
        this.patientData.set(response.result[0]);
        const { expandableDetails, patientDetails } = this.patientsService.transformPatientData(response.result[0]);
        this.expandableDetails = expandableDetails;
        this.patientDetails = patientDetails;
      })
      .catch((error: any) => {
        console.error('Error fetching law firm:', error);
      })
      .finally(() => {
        this.isLoading.set(false);
      })
  }
}
