// Angular
import { Component, Input, signal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';

// 3rd Party
import { Query } from '@syncfusion/ej2-data';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { CaseFile } from '@models/data-contracts';
import { ApiService } from '@services/api/api.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { GlobalsService } from '@services/globals/globals.service';
import { FileHubService } from '@root/src/app/features/file-hub/services/file-hub.service';
import { LoadingModule } from '@modules/loading.module';
import { TabCardComponent } from '@root/src/app/features/file-hub/components/file-hub-tabs/tab-card/tab-card.component';
import { NoDataComponent } from '@ui/no-data/no-data.component';
import { ComplianceComponent } from '@forms/compliance/compliance.component';

@Component({
  selector: 'compliance-tab',
  standalone: true,
  imports: [
    CommonModule,
    TabCardComponent,
    LoadingModule,
    // NoDataComponent,
    ComplianceComponent
  ],
  templateUrl: './compliance-tab.component.html',
  styleUrl: './compliance-tab.component.scss'
})
export class ComplianceTab {

  /**
   * Main functionality can be found in the FileHubService.
   * @see {@link FileHubService} for more info
   */
  constructor(
    private api: ApiService,
    private globals: GlobalsService,
    public fileHub: FileHubService
  ) {}

  loadingComplianceData: WritableSignal<boolean> = signal(true);
  caseFileCompliance: any;
  noComplianceDataText: string = `No compliance info for Case File`;

  ngAfterViewInit() {
    this.setActiveFileData();
  }

  // Verifies data is available
  async checkData() {
    
    if (!this.fileHub.caseFile) {
      this.fileHub.showNoCaseFileError('Case File Id # needed to populate Compliance data.');
    } else {
      await this.setActiveFileData();
      this.loadingComplianceData.set(false);
    }
  }

  async setActiveFileData() {

    try {
      const endpoint = `${APIEndpoints.CaseFileCompliances}`;
      const query = new Query().where('CaseFileId', 'equal', this.fileHub.caseFile?.Id);
      const response = await this.api.getOdata(endpoint).executeQuery(query) as any;
      
      if (response?.result?.length > 0) {
        this.caseFileCompliance = response.result[0];

      } else {
        console.log('No compliance data found in response');
      }

      this.noComplianceDataText = !this.caseFileCompliance 
        ? `No compliance info for Case File #${this.fileHub.caseFile?.FileNumber}.` 
        : this.noComplianceDataText;

      return this.caseFileCompliance;

    } catch (error) {
      console.error('Error in setActiveFileData:', error);
      return error;

    } finally {
      this.loadingComplianceData.set(false);
      return;
    }
  }
}
