//TODO: Refactor to use the BaseFormComponent
// CURRENT FIXES ARE NOT FINAL, JUST TO MAKE THE BUILDS HAPPY

import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faComment, faList } from '@fortawesome/free-solid-svg-icons';
import { Comment } from '@shared/models/data-contracts';
import { EntityType } from '@shared/models/entity-types';
import { BaseFormComponent } from '@root/src/app/shared/components/base-form/base-form.component';
import { BaseFormHooks } from '@root/src/app/shared/components/base-form/base-form.hooks';
import { FormFieldComponent } from '@root/app/shared/components/form-field/form-field.component';
import { CommentsService } from '@shared/features/comments/services/comments.service';

@Component({
  selector: 'comment-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    TextBoxModule,
    DropDownListModule,
    FontAwesomeModule,
    FormFieldComponent,
    BaseFormComponent
  ],
  providers: [
    BaseFormHooks
  ],
  templateUrl: './comment-form.component.html'
})
export class CommentFormComponent extends BaseFormComponent {

  // Decorators
  @Input() comment?: Comment;
  @Input() entityType!: EntityType;
  @Input() override entityId!: number;
  @Input() threadId?: number;
  @Output() formSubmitted = new EventEmitter<void>();

  // Public
  icons = {
    type: faList,
    comment: faComment
  };
  fb: any;

  constructor(
    fb: FormBuilder,
    protected commentsService: CommentsService
  ) {
    super();
    this.form = fb.group({
      typeId: ['', Validators.required],
      content: ['', [Validators.required, Validators.minLength(1)]]
    });
  }

  override async onSubmit(): Promise<void> {
    if (this.hooks.isValid(this.initialFormValue, this.form.value)) {
      this.formSubmitted.emit();
    }
  }
}
