import { Injectable } from '@angular/core';
import { LoggingService } from '@core/internal/logging/logging.service';
import { NotificationService } from '@core/notification/notification.service';
import { NotificationSeverity } from '@core/enums/notification-severity.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorDetails, ErrorConfig, ErrorSeverity } from './error.types';

const ERROR_MESSAGES = {
  HTTP: {
    400: 'The request was invalid. Please try again.',
    401: 'Please log in to continue.',
    403: 'You do not have permission to perform this action.',
    404: 'The requested resource was not found.',
    500: 'An unexpected error occurred. Our team has been notified.'
  },
  DEFAULT: 'An unexpected error occurred. Our team has been notified.'
} as const;

/**
 * Central error handling service for Case Compass.
 *
 * Responsibilities:
 * - Processes errors into user-friendly messages
 * - Generates unique error IDs for tracking
 * - Logs technical details via LoggingService
 * - Shows user-facing messages via NotificationService
 *
 * Flow:
 * 1. Error occurs in component/service
 * 2. Error passed to handleError() with context
 * 3. Error processed into ErrorDetails
 * 4. Technical details logged
 * 5. User-friendly message displayed
 *
 * Use error handling for:
 * ❌ Failed API calls
 * ❌ Runtime exceptions
 * ❌ Critical system errors
 * ❌ Any error that needs tracking/logging
 *
 * Example:
 * try {
 *   await this.api.saveData();
 * } catch (error) {
 *   this.errorHandling.handleError(error, {
 *     context: 'MyComponent.saveData',
 *     userMessage: 'Failed to save data'
 *   });
 * }
 */
@Injectable({ providedIn: 'root' })
export class ErrorHandlingService {
  constructor(
    private logging: LoggingService,
    private notification: NotificationService
  ) { }

  private processError(error: any, context?: string): ErrorDetails {
    const errorId = this.generateErrorId();

    // Handle HTTP errors
    if (error instanceof HttpErrorResponse) {
      return {
        userMessage: this.getUserMessageForHttpError(error),
        technicalDetails: {
          name: error.name,
          status: error.status,
          statusText: error.statusText,
          url: error.url,
          error: error.error,
          message: error.message,
          stack: (error as any).stack
        },
        severity: ErrorSeverity.Error,
        source: context,
        errorId
      };
    }

    // Handle other errors
    return {
      userMessage: ERROR_MESSAGES.DEFAULT,
      technicalDetails: {
        name: error.name,
        message: error.message,
        stack: error.stack,
        originalError: error,
        response: error.response,
        raw: error
      },
      severity: ErrorSeverity.Error,
      source: context,
      errorId
    };
  }

  private getUserMessageForHttpError(error: HttpErrorResponse): string {
    return ERROR_MESSAGES.HTTP[error.status as keyof typeof ERROR_MESSAGES.HTTP] || ERROR_MESSAGES.DEFAULT;
  }

  private generateErrorId(): string {
    return `ERR-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
  }

  // Single public error handling method
  handleError(error: any, contextOrConfig: string | ErrorConfig): void {
    const config = typeof contextOrConfig === 'string' ? {
      context: contextOrConfig,
      userMessage: ERROR_MESSAGES.DEFAULT
    } : contextOrConfig;

    const severity = config.severity || ErrorSeverity.Error;

    const errorDetails = this.processError(error, config.context);
    const errorId = errorDetails.errorId;

    // Single consolidated error log
    console.group(`🔴 Error [${errorId}] - ${config.context}`);
    console.error({
      userMessage: config.userMessage,
      technical: errorDetails.technicalDetails?.message || error.message,
      stack: error.stack
    });
    console.groupEnd();

    // Map error severity to logging level
    const loggingLevel = severity === ErrorSeverity.Error ? 'error' :
      severity === ErrorSeverity.Warning ? 'warn' : 'info';

    // Log and notify
    this.logging.log({
      level: loggingLevel,
      context: config.context,
      message: config.userMessage,
      error: errorDetails
    });

    // Map severity to notification method
    switch (severity) {
      case ErrorSeverity.Error:
        this.notification.error(`${config.userMessage} (ID: ${errorId})`);
        break;
      case ErrorSeverity.Warning:
        this.notification.warn(`${config.userMessage} (ID: ${errorId})`);
        break;
      default:
        this.notification.info(`${config.userMessage} (ID: ${errorId})`);
    }
  }
}
