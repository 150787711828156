<!-- Law Firm Form Start -->
<div id="law-firm-form" [class]="formClasses.formContainer">
    <!-- Add loading overlay -->
    <div *ngIf="loadingStatus().loading" class="loading-overlay">
        <div class="loading-content">
            <div class="spinner"></div>
            <div class="loading-message">{{ loadingStatus().message }}</div>
        </div>
    </div>

    <form [formGroup]="lawFirmForm" [class]="formClasses.form" (ngSubmit)="onSubmit()">

        <section [class]="formClasses.section + ' ' + formClasses.twoCol + ' law-firm-section'">
            
            <div [class]="formClasses.group">

                <div *ngIf="displayFields.includes('Name')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol" [class.error]="lawFirmForm.get('Name')?.errors && lawFirmForm.get('Name')?.touched">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="faUser" />
                        <ejs-tooltip *ngIf="lawFirmForm.get('Name')?.errors && lawFirmForm.get('Name')?.touched" [content]="getErrorMessage('Name')" />
                    </span>
                    <ejs-textbox [class]="formClasses.input" formControlName="Name" placeholder="Law Firm Name" />
                    <ng-container *ngIf="lawFirmForm.get('Name')?.invalid && lawFirmForm.get('Name')?.touched">
                        <span [class]="formClasses.error">{{errorMessage}}</span>
                    </ng-container>
                </div>

                <div *ngIf="displayFields.includes('Abbreviation')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol" [class.error]="lawFirmForm.get('Abbreviation')?.errors && lawFirmForm.get('Abbreviation')?.touched">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="faIdCard" />
                        <ejs-tooltip *ngIf="lawFirmForm.get('Abbreviation')?.errors && lawFirmForm.get('Abbreviation')?.touched" [content]="getErrorMessage('Abbreviation')" />
                    </span>
                    <ejs-textbox [class]="formClasses.input" formControlName="Abbreviation" placeholder="Abbreviation" />
                    <ng-container *ngIf="lawFirmForm.get('Abbreviation')?.invalid && lawFirmForm.get('Abbreviation')?.touched">
                        <span [class]="formClasses.error">{{getErrorMessage('Abbreviation')}}</span>
                    </ng-container>
                </div>

                <div *ngIf="displayFields.includes('MarketManager')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol" [class.error]="lawFirmForm.get('MarketManager')?.errors && lawFirmForm.get('MarketManager')?.touched">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="faUserTag" />
                        <ejs-tooltip *ngIf="lawFirmForm.get('MarketManager')?.errors && lawFirmForm.get('MarketManager')?.touched" [content]="getErrorMessage('MarketManager')" />
                    </span>
                    <ejs-dropdownlist formControlName="MarketManager" 
                        [placeholder]="'Market Manager'" 
                        [class]="formClasses.input"
                        [dataSource]="marketManagersDataSource" 
                        [fields]="fields" 
                        [query]="marketManagersQuery" 
                        [value]="lawFirmForm.get('MarketManager')?.value" />
                    <ng-container *ngIf="lawFirmForm.get('MarketManager')?.invalid && lawFirmForm.get('MarketManager')?.touched">
                        <span [class]="formClasses.error">{{getErrorMessage('MarketManager')}}</span>
                    </ng-container>
                </div>
                
                <div *ngIf="displayFields.includes('CaseManager')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol" [class.error]="lawFirmForm.get('CaseManager')?.errors && lawFirmForm.get('CaseManager')?.touched">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="faUserTag" />
                        <ejs-tooltip *ngIf="lawFirmForm.get('CaseManager')?.errors && lawFirmForm.get('CaseManager')?.touched" [content]="getErrorMessage('CaseManager')" />
                    </span>
                    <ejs-dropdownlist formControlName="CaseManager" 
                        [placeholder]="'Case Manager'" 
                        [class]="formClasses.input"
                        [dataSource]="caseManagersDataSource" 
                        [fields]="fields" 
                        [query]="caseManagersQuery" 
                        [value]="lawFirmForm.get('CaseManager')?.value" />
                    <ng-container *ngIf="lawFirmForm.get('CaseManager')?.invalid && lawFirmForm.get('CaseManager')?.touched">
                        <span [class]="formClasses.error">{{getErrorMessage('CaseManager')}}</span>
                    </ng-container>
                </div>

                <div *ngIf="displayFields.includes('DocumentLink')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="faComments" />
                    </span>
                    <ejs-textbox [class]="formClasses.input" formControlName="DocumentLink" placeholder="Document Link" [type]="'textarea'" [multiline]="false" />
                </div>

                <div *ngIf="displayFields.includes('Website')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="faComments" />
                    </span>
                    <ejs-textbox [class]="formClasses.input" formControlName="Website" placeholder="Website" [type]="'textarea'" [multiline]="false" />
                </div>
                <div *ngIf="displayFields.includes('Rating')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="faComments" />
                    </span>
                    <ejs-textbox [class]="formClasses.input" formControlName="Rating" placeholder="Rating" [type]="'textarea'" [multiline]="false" />
                </div>
                <div *ngIf="displayFields.includes('XrefPhoneLawfirms')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol"></div>
                    <phone-form #phoneFormComp [phone]="lawFirmPhone"  [displayFields]="['PhoneNumber', 'PhoneType']" [displayButtons]="false"></phone-form>
                </div>
        </section>

        <section [class]="formClasses.section + ' ' + formClasses.twoCol + ' ' + formClasses.subForm + ' address-section'">
            <address-form #addressFormComp [address]="lawFirmAddress" 
                [displayFields]="['Address1', 'Address2', 'City', 'State', 'Zip', 'County', 'AddressType']" 
                [requiredFields]="['Address1', 'City', 'State', 'Zip', 'AddressType']"
                [displayButtons]="false" />
        </section>
        
        <section [class]="formClasses.section + ' col-12'">
            <div [class]="formClasses.actions">
                <button ejs-button [isPrimary]="true" type="submit">Submit</button>
                <button ejs-button type="button" (click)="formCancelled.emit()" >Cancel</button>
            </div>
        </section>

    </form>

</div>
<!-- Law Firm Form End -->