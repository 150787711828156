<div id="authorizations-tab">
  <tab-card [heading]="'File Authorizations'" [loadingContent]="loadingAuthorizationsData">
    <ng-container *ngIf="fileAuthorizationsGridSettings">
      <base-grid #fileAuthGrid [settings]="fileAuthorizationsGridSettings"></base-grid>
    </ng-container>
  </tab-card>
  <div class="spacer"></div>
  <tab-card [heading]="'Provider Funding'" [loadingContent]="loadingAuthorizationsData">
    <ng-container *ngIf="providerFundingGridSettings">
      <base-grid #providerFundingGrid [settings]="providerFundingGridSettings"></base-grid>
    </ng-container>
  </tab-card>
</div>

<ejs-dialog
  id="addAuthorizations"
  #addAuthorizations
  [header]="dialogHeader"
  [showCloseIcon]="true"
  [isModal]="true"
  [closeOnEscape]="true"
  [visible]="addAuthorizationsVisibility"
  (close)="onClose()"
>
  <add-authorizations
    [caseId]="fileHub.caseFile?.Id"
    [selectedAuthorization]="selectedAuthorization()"
    [authType]="authType"
    (submit)="onSubmit()"
  ></add-authorizations>
</ejs-dialog>

<!-- Audit Logs Dialog -->
<ejs-dialog
  id="logsDialog"
  #logsDialog
  header="Audit Logs"
  [showCloseIcon]="true"
  [isModal]="true"
  [closeOnEscape]="true"
  [visible]="logsDialogVisibility"
  [buttons]="logsDialogButtons"
  (beforeOpen)="beforeOpeningLogsDialog($event)"
>
  <ng-container *ngIf="auditLogsLoading">
    <div class="loading-container">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!auditLogsLoading">
    <app-audit-logs [logs]="auditLogs"></app-audit-logs>
  </ng-container>
</ejs-dialog>
