<ejs-dialog #emailDialog [visible]="false" [isModal]="true" [width]="500">
    <ng-template #header>
        Send Balance Statement Email
    </ng-template>
    <ng-template #content>
        <div class="email-form">
            <div class="form-group">
                <label>From:</label>
                <input type="text" class="form-control" [(ngModel)]="fromEmail" readonly>
            </div>
            <div class="form-group">
                <label>To:</label>
                <input type="text" class="form-control" [(ngModel)]="toEmail">
            </div>
            <div class="form-group">
                <label>CC:</label>
                <input type="text" class="form-control" [(ngModel)]="ccEmail">
            </div>
        </div>
    </ng-template>
</ejs-dialog>
