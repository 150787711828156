<form [formGroup]="automatedForm" #formDir="ngForm" class="row" (ngSubmit)="onSubmit($event)">

    @for (item of automatedForm.controls|keyvalue; track $index) {

        @if (item.key) {
            <div class="col-12 col-md-6">
                <label>{{item.key}}</label>
                <ejs-textbox formControlName="{{item.key}}"></ejs-textbox>

                @if (fieldErrorChecking(item.key)) {
                    <span class="e-error">{{errorMsg}}</span>
                }
            </div>
        }
    }

   @if (hideSubmit === false) {
        <div class="form-submit col-12 d-flex justify-content-end">
            <button ejs-button [isPrimary]="true" type="submit">Submit</button>
        </div>
   }
</form>