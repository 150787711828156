import { Injectable, inject } from '@angular/core';
import { Query } from '@syncfusion/ej2-data';
import { APIEndpoints } from '@models/api/Endpoints';
import { ApiService } from '@services/api/api.service';

@Injectable({
  providedIn: 'root',
})
export class UserRolesService {
  private readonly api = inject(ApiService);

  getMarketManagers() {
    return {
      dataSource: this.api.getOdata(APIEndpoints.Users),
      query: new Query()
        .select('Id,Name')
        .expand('XrefUsersRoles($expand=Role($select=RoleName))')
        .where("XrefUsersRoles/any(x: x/Role/RoleName eq 'Market Manager')", 'equal', true),
    };
  }

  getCaseManagers() {
    return {
      dataSource: this.api.getOdata(APIEndpoints.Users),
      query: new Query()
        .select('Id,Name')
        .expand('XrefUsersRoles($expand=Role($select=RoleName))')
        .where("XrefUsersRoles/any(x: x/Role/RoleName eq 'Case Manager')", 'equal', true),
    };
  }

  getStatusGroups() {
    return {
      dataSource: this.api.getOdata(APIEndpoints.StatusingGroups),
      query: new Query().select('Id,Description'),
    };
  }
}
