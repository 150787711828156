import { Component, Input, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { Query } from '@syncfusion/ej2-data';
import { WorkItemsService } from '../../services/work-items.service';
import { DialogComponent, DialogAllModule } from '@syncfusion/ej2-angular-popups';
import { WorkItem } from '@shared/models/data-contracts';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastMessageService } from '@services/toast-message/toast-message.service';

@Component({
  selector: 'bulk-edit-work-items',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DropDownListModule,
    DatePickerModule,
    TextBoxModule,
    CheckBoxModule,
    DialogAllModule
  ],
  template: `
    <ejs-dialog 
      #bulkEditDialog 
      [visible]="false"
      [buttons]="dialogButtons"
      [isModal]="true"
      [showCloseIcon]="true"
      width="500px"
      header="Bulk Edit Tasks">
      <ng-template #content>
        <form [formGroup]="form">
          <div class="form-row">
            <div class="form-group">
              <ejs-checkbox formControlName="updateStatus" label="Update Status"></ejs-checkbox>
              <ejs-dropdownlist
                *ngIf="form.get('updateStatus')?.value"
                formControlName="statusId"
                [dataSource]="statusesDataManager"
                [fields]="{ text: 'Name', value: 'Id' }"
                [query]="statusQuery">
              </ejs-dropdownlist>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group">
              <ejs-checkbox formControlName="updatePriority" label="Update Priority"></ejs-checkbox>
              <ejs-dropdownlist
                *ngIf="form.get('updatePriority')?.value"
                formControlName="priorityId"
                [dataSource]="prioritiesDataManager"
                [fields]="{ text: 'Name', value: 'Id' }"
                [query]="priorityQuery">
              </ejs-dropdownlist>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group">
              <ejs-checkbox formControlName="updateAssignedTo" label="Update Assigned To"></ejs-checkbox>
              <ejs-dropdownlist
                *ngIf="form.get('updateAssignedTo')?.value"
                formControlName="assignedTo"
                [dataSource]="usersDataManager"
                [fields]="{ text: 'DisplayName', value: 'Id' }"
                [query]="userQuery">
              </ejs-dropdownlist>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group">
              <ejs-checkbox formControlName="updateDueDate" label="Update Due Date"></ejs-checkbox>
              <ejs-datepicker
                *ngIf="form.get('updateDueDate')?.value"
                formControlName="dueDate">
              </ejs-datepicker>
            </div>
          </div>
        </form>
      </ng-template>
    </ejs-dialog>
  `,
  styles: [`
    .form-row {
      display: flex;
      gap: 1rem;
      margin-bottom: 1rem;
    }
    .form-group {
      flex: 1;
    }
    ejs-dropdownlist, ejs-datepicker {
      margin-top: 0.5rem;
    }
  `]
})
export class BulkEditWorkItemsComponent implements OnDestroy {
  @Input() selectedItems: WorkItem[] = [];
  @Output() completed = new EventEmitter<boolean>();

  @ViewChild('bulkEditDialog') bulkEditDialog!: DialogComponent;

  form: FormGroup;
  statusQuery = new Query()
    .select(['Id', 'Name'])
    .where('IsActive', 'equal', true)
    .sortBy('DisplayOrder');

  priorityQuery = new Query()
    .select(['Id', 'Name'])
    .where('IsActive', 'equal', true)
    .sortBy('DisplayOrder');

  userQuery = new Query()
    .select(['Id', 'DisplayName'])
    .where('IsActive', 'equal', true);

  dialogButtons: Object[] = [
    { 
      click: () => this.cancel(),
      buttonModel: { 
        content: 'Cancel', 
        cssClass: 'e-outline' 
      }
    },
    { 
      click: () => this.submit(),
      buttonModel: { 
        content: 'Submit', 
        isPrimary: true 
      }
    }
  ];

  private destroy$ = new Subject<void>();

  statusesDataManager = this.workItemsService.getStatusesDataManager();
  prioritiesDataManager = this.workItemsService.getPrioritiesDataManager();
  usersDataManager = this.workItemsService.getUsersDataManager();

  constructor(
    private fb: FormBuilder,
    public workItemsService: WorkItemsService,
    private toast: ToastMessageService
  ) {
    this.initForm();
    this.setupFormSubscriptions();
  }

  private initForm() {
    this.form = this.fb.group({
      // Field update toggles
      updateStatus: [false],
      updatePriority: [false],
      updateAssignedTo: [false],
      updateDueDate: [false],

      // Field values
      statusId: [{ value: '', disabled: true }],
      priorityId: [{ value: '', disabled: true }],
      assignedTo: [{ value: '', disabled: true }],
      dueDate: [{ value: null, disabled: true }]
    });
  }

  private setupFormSubscriptions() {
    // Update status subscription
    this.form.get('updateStatus')?.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(checked => {
        const control = this.form.get('statusId');
        checked ? control?.enable() : control?.disable();
        if (checked) control?.setValidators(Validators.required);
        else control?.clearValidators();
        control?.updateValueAndValidity();
      });

    // Update priority subscription
    this.form.get('updatePriority')?.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(checked => {
        const control = this.form.get('priorityId');
        checked ? control?.enable() : control?.disable();
        if (checked) control?.setValidators(Validators.required);
        else control?.clearValidators();
        control?.updateValueAndValidity();
      });

    // Update assigned to subscription
    this.form.get('updateAssignedTo')?.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(checked => {
        const control = this.form.get('assignedTo');
        checked ? control?.enable() : control?.disable();
      });

    // Update due date subscription
    this.form.get('updateDueDate')?.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(checked => {
        const control = this.form.get('dueDate');
        checked ? control?.enable() : control?.disable();
      });
  }

  async onSubmit(): Promise<boolean> {
    if (this.form.valid && this.selectedItems.length > 0) {
      try {
        const formData = this.form.value;
        const updateData = Object.fromEntries(
          Object.entries({
            statusId: formData.updateStatus ? formData.statusId : undefined,
            priorityId: formData.updatePriority ? formData.priorityId : undefined,
            assignedTo: formData.updateAssignedTo ? formData.assignedTo : undefined,
            dueDate: formData.updateDueDate ? formData.dueDate : undefined
          }).filter(([_, v]) => v !== undefined)
        );

        return await this.workItemsService.bulkUpdate(
          this.selectedItems.map(item => item.Id).filter((id): id is number => id !== undefined),
          updateData
        );
      } catch (error) {
        console.error('Error in onSubmit:', error);
        return false;
      }
    }
    return false;
  }

  show(): void {
    this.form.reset({
      updateStatus: false,
      updatePriority: false,
      updateAssignedTo: false,
      updateDueDate: false
    });
    this.bulkEditDialog?.show();
  }

  async submit(): Promise<void> {
    try {
      if (await this.onSubmit()) {
        this.toast.showSuccess('Tasks updated successfully');
        this.form.reset({
          updateStatus: false,
          updatePriority: false,
          updateAssignedTo: false,
          updateDueDate: false
        });
        this.bulkEditDialog.hide();
        this.completed.emit(true);
      } else {
        this.toast.showError('Failed to update Tasks');
        this.completed.emit(false);
      }
    } catch (error) {
      console.error('Error in submit:', error);
      this.toast.showError('Error updating Tasks');
      this.completed.emit(false);
    }
  }

  cancel(): void {
    this.bulkEditDialog.hide();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
} 