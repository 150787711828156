// Angular
import { Component, ViewChild, ViewEncapsulation } from "@angular/core";

// 3rd Party
import {
  NodeModel,
  ConnectorModel,
  LayoutModel,
  Diagram,
  DiagramTools,
  DataSourceModel,
  SnapConstraints,
  SnapSettingsModel,
  TreeInfo } from "@syncfusion/ej2-diagrams";
import { DataManager, Query } from "@syncfusion/ej2-data";
import { ShapeStyleModel, DiagramModule } from "@syncfusion/ej2-angular-diagrams";

export interface EmployeeInfo {
  Name: string;
  Role: string;
  color: string;
}
export interface DataInfo {
  [key: string]: string;
}

@Component({
  selector: 'app-workflow',
  standalone: true,
  imports: [
    DiagramModule
  ],
  templateUrl: './workflow.component.html',
  styleUrl: './workflow.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class WorkflowComponent {
  @ViewChild("diagram") layout?: LayoutModel;
  dataSourceSettings?: DataSourceModel;
  data: Object[] = [
    {
      Name: "Elizabeth",
      Role: "Director"
    },
    {
      Name: "Christina",
      ReportingPerson: "Elizabeth",
      Role: "Manager"
    },
    {
      Name: "Yoshi",
      ReportingPerson: "Christina",
      Role: "Lead"
    },
    {
      Name: "Philip",
      ReportingPerson: "Christina",
      Role: "Lead"
    },
    {
      Name: "Yang",
      ReportingPerson: "Elizabeth",
      Role: "Manager"
    },
    {
      Name: "Roland",
      ReportingPerson: "Yang",
      Role: "Lead"
    },
    {
      Name: "Yvonne",
      ReportingPerson: "Yang",
      Role: "Lead"
    }
  ];

  ngOnInit(): void {
    this.layout = {
      type: "OrganizationalChart"
    };
    this.dataSourceSettings = {
      id: "Name",
      parentId: "ReportingPerson",
      dataManager: new DataManager(this.data as JSON[])
    };
  }

  nodeDefaults(node: NodeModel): NodeModel {
    let codes: Object = {
      Director: "rgb(0, 139,139)",
      Manager: "rgb(30, 30,113)",
      Lead: "rgb(0, 100,0)"
    };
    node.width = 70;
    node.height = 30;
    node.annotations = [
      { content: (node.data as EmployeeInfo).Name, style: { color: "white" } }
    ];
    ((node as NodeModel).style as ShapeStyleModel).fill = (codes as any)[(node.data as EmployeeInfo).Role] as string;
    return node;
  }

  connectorDefaults(connector: ConnectorModel): ConnectorModel {
    connector.type = "Orthogonal";
    connector.cornerRadius = 7;
    return connector;
  }

  nodeDefaultsRemoteData(obj: NodeModel): NodeModel {
    obj.width = 80;
    obj.height = 40;
    //Initialize shape
    obj.shape = { type: 'Basic', shape: 'Rectangle' };
    obj.style = { fill: '#048785', strokeColor: 'Transparent' };
    return obj;
  };

  dataRemoteData: Object = {
    id: 'EmployeeID', parentId: 'ReportsTo',
    dataManager: new DataManager(
      { url: 'http://mvc.syncfusion.com/Services/Northwnd.svc/', crossDomain: true },
      new Query().from('Employees').select('EmployeeID,ReportsTo,FirstName').take(9),
    ),
    //binds the external data with node
    doBinding: (nodeModel: NodeModel, data: DataInfo, diagram: Diagram) => {
      nodeModel.annotations = [{
        /* tslint:disable:no-string-literal */
        content: data['FirstName'],
        style: { color: 'white' }
      }];
    }
  };

  connDefaultsRemoteData(connector: ConnectorModel): void {
    connector.type = 'Orthogonal';
    
    if (connector.style?.strokeColor && connector.targetDecorator) {
      connector.style.strokeColor = '#048785';
      connector.targetDecorator.shape = 'None';
    }
  };

  toolRemoteData: DiagramTools = DiagramTools.ZoomPan;
  snapSettingsRemoteData: SnapSettingsModel = { constraints: SnapConstraints.None };

  layoutRemoteData: Object = {
    type: 'HierarchicalTree', margin: { left: 0, right: 0, top: 100, bottom: 0 },
    verticalSpacing: 40,
    getLayoutInfo: (node: NodeModel, options: TreeInfo) => {
      if (node.style) {
        if (options.level === 3) {
          node.style.fill = '#3c418d';
        }
        if (options.level === 2) {
          node.style.fill = '#108d8d';
          options.type = 'Center';
          options.orientation = 'Horizontal';
        }
        if (options.level === 1) {
          node.style.fill = '#822b86';
        }
      }
    }
  };
}
