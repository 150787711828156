// Angular
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

// 3rd Party
import { registerLicense } from '@syncfusion/ej2-base';
import Tracker from '@openreplay/tracker';
import trackerAssist from '@openreplay/tracker-assist';

// Internal
import config from '@root/config.json';
import { AppModule } from './app/app.module';

// Register Syncfusion license
registerLicense(config.secrets.SYNCFUSION_KEY);
enableProdMode();

// OpenReplay
if (config.secrets.OPENREPLAY_KEY) {
  const tracker = new Tracker({
    projectKey: config.secrets.OPENREPLAY_KEY,
  });
  tracker.start();
  tracker.use(trackerAssist());
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
