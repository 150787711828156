<div id="reduction-requests">

  <grid-template #grid [settings]="gridSettings" [name]="'Reduction Requests'" [useRoundedEdges]="true" />

  <div class="modal-container">
    <ejs-dialog id="modal" #modal
      [showCloseIcon]="true"
      [isModal]="true"
      [closeOnEscape]="true"
      header="Edit Request"
      [visible]="modalVisibility"
      (close)="closeDialog()"
      (beforeOpen)="beforeOpening($event)">
      <approve-reduction-request
        *ngIf="caseFileId"
        [reductionRequest]="selectedRowData"
        [balanceDue]="balanceDue"
        [caseFileId]="caseFileId"
        (close)="closeDialog()"
        (submit)="onBackendResponse()"/>
    </ejs-dialog>
  </div>

</div>