import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { Contact } from '@root/src/app/shared/models/data-contracts';
import { DropDownListAllModule, DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-select-contact',
  standalone: true,
  imports: [
    DropDownListAllModule,
    CommonModule,
  ],        
  template: `
     <div *ngIf="showSelector">
       <ejs-dropdownlist 
        #dropdownlist
        [dataSource]="contacts" 
        [fields]="{ text: 'ContactName', value: 'Id' }"
        placeholder="Select an existing contact"
        [allowFiltering]="true"
        [filterBarPlaceholder]="'Search contacts...'"
        [filterType]="'Contains'"
        (change)="selectContact($event.itemData)">
      </ejs-dropdownlist>
     </div>
  `
})
export class SelectContactComponent implements OnChanges {
  @Input() contacts: Contact[] = [];
  @Input() showSelector: boolean = true;
  @Output() contactSelected = new EventEmitter<Contact>();
  @ViewChild('dropdownlist') dropdownlist!: DropDownListComponent;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['contacts'] && this.contacts) {
    }
  }

  selectContact(contact: Contact) {
    if (contact) {
      this.contactSelected.emit(contact);
    }
  }

  clearSelection() {
    if (this.dropdownlist) {
      this.dropdownlist.value = null;
      this.dropdownlist.text = '';
      this.dropdownlist.refresh();
    }
  }
}