// Angular
import { Component, OnInit, signal, ViewChild, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
// 3rd Party
import { GridModel } from '@syncfusion/ej2-grids';
import { GridAllModule } from '@syncfusion/ej2-angular-grids';
import { DataManager, Predicate, Query } from '@syncfusion/ej2-data';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { ApiService } from '@services/api/api.service';
import { GridTemplateModule } from '@modules/grid-template.module';
import { CognitoService } from '@root/src/app/shared/services/auth/cognito.service';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { Contact } from '@root/src/app/shared/models/data-contracts';
import { LoadingModule } from '@root/src/app/shared/modules/loading.module';

@Component({
  selector: 'law-firms-grid',
  standalone: true,
  imports: [
    CommonModule,
    GridTemplateModule,
    GridAllModule,
    DialogModule,
    LoadingModule
  ],
  templateUrl: './law-firms-grid.component.html',
  styleUrl: './law-firms-grid.component.scss',
})
export class LawFirmsGridComponent implements OnInit {

  constructor(
    private api: ApiService,
    public cognito: CognitoService
  ) { }

  // Initialize grid variables
  @ViewChild('nameTemplate', { static: true }) nameTemplate!: string;
  @ViewChild('caseFileTemplate', { static: true }) caseFileTemplate!: string;
  loadingData: WritableSignal<boolean> = signal(true);
  lawFirms: GridModel;
  marketMangers: DataManager;
  caseManagers: DataManager;
  viewLawFirmAlert: boolean = false;

  ngOnInit(): void {
    this.getData().then(() => {
      this.lawFirms = {
        dataSource: this.api.getOdata(APIEndpoints.Lawfirms),
        query: new Query().expand('Address($expand=StateNavigation),CaseManagerNavigation,MarketManagerNavigation'),
        columns: [
          { field: 'Id' },
          { field: 'Abbreviation', headerText: 'Abbreviation' },
          { field: 'Name', template: this.nameTemplate },
          { field: 'Address.Address1', headerText: 'Address' },
          { field: 'Address.City', headerText: 'City' },
          { field: 'Address.StateNavigation.Name', headerText: 'State' },
          { field: 'Address.Zip', headerText: 'Zip' },
          { field: 'MarketManager', headerText: 'Market Manager', foreignKeyValue: 'ContactName', foreignKeyField: 'Id', dataSource: this.marketMangers, editType: 'dropdownedit' },
          { field: 'CaseManager', headerText: 'Case Manager', foreignKeyValue: 'ContactName', foreignKeyField: 'Id', dataSource: this.caseManagers, editType: 'dropdownedit' },
          { type: 'commands', headerText: 'Actions' },
        ]
      };
    });
  }

  async getData() {
    // Create a Predicate for the OR condition
    const predicate = new Predicate('ContactTitle', 'equal', 'Market Manager')
      .or('ContactTitle', 'equal', 'Case Manager');
  
    // Use the Predicate in the Query
    const response = await this.api
      .getOdata(APIEndpoints.Contacts)
      .executeQuery(new Query().where(predicate))
      .then((response: any) => response.result);
  
    // Filter the results for specific titles
    this.marketMangers = response.filter((contact: Contact) => contact.ContactTitle === 'Market Manager');
    this.caseManagers = response.filter((contact: Contact) => contact.ContactTitle === 'Case Manager');

    this.loadingData.set(false);
  }

  nameClick(data: any) {
    this.viewLawFirmAlert = true;
  }
}