import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Query } from '@syncfusion/ej2-data';

// Internal
import { FileHubService } from '@root/src/app/features/file-hub/services/file-hub.service';
import { WorkItemsGridComponent } from '@features/work-items/components/work-items-grid/work-items-grid.component';

@Component({
  selector: 'work-items-tab',
  standalone: true,
  imports: [CommonModule, WorkItemsGridComponent],
  template: `
    <div class="work-items-container">
      <work-items-grid
        #workItemsList
        [initialQuery]="initialQuery"
        [inCaseFileContext]="true"
        [caseFileId]="fileHub.caseFileId"
        [defaultProviderAgreement]="true">
      </work-items-grid>
    </div>
  `,
  styles: [`
    .work-items-container {
      padding: 20px;
      height: calc(100vh - 200px);
    }
  `]
})
export class WorkItemsTabComponent implements OnInit {
  @ViewChild('workItemsList') workItemsList!: WorkItemsGridComponent;
  initialQuery?: Query;

  constructor(public fileHub: FileHubService) {}

  ngOnInit() {
    if (!this.fileHub.caseFileId) {
      this.fileHub.showNoCaseFileError('Cannot load Tasks without a case file.');
      return;
    }

    this.initialQuery = new Query()
      .expand([
        'Type($select=Name)',
        'Category($select=Name)', 
        'Priority($select=Name)',
        'Status($select=Name)',
        'AssignedToNavigation($select=Name)',
        'XrefWorkItemCaseFiles($select=CaseFileId,WorkItemId;$expand=CaseFile($select=FileNumber))'
      ])
      .where('XrefWorkItemCaseFiles/any(x: x/CaseFileId eq ' + this.fileHub.caseFileId + ')', 'equal', true);
  }
} 