// Angular
import { Component, signal, computed } from '@angular/core';
import { CommonModule } from '@angular/common';

// 3rd Party
import { OnInit, OnDestroy } from '@angular/core';

// Core
import { ComponentBase } from '@core/base/component.base';

// Services
import { FileHubService } from './services/file-hub.service';
import { FinancialPerformanceService } from '@features/financial/services/financial-performance.service';
import { BaseFormHooks } from '@shared/components/base-form/base-form.hooks';

// Components
import { LoadingModule } from '@modules/loading.module';
import { FileHubHeaderComponent } from './components/file-hub-header/file-hub-header.component';
import { FileHubTabsComponent } from './components/file-hub-tabs/file-hub-tabs.component';

@Component({
  selector: 'file-hub',
  standalone: true,
  imports: [
    FileHubHeaderComponent,
    FileHubTabsComponent,
    LoadingModule,
    CommonModule,
  ],
  providers: [BaseFormHooks],
  templateUrl: './file-hub.component.html',
  styleUrl: './file-hub.component.scss',
})
export class FileHubComponent extends ComponentBase implements OnInit, OnDestroy {
  // State management using signals
  protected readonly loading = signal<boolean>(true);
  protected readonly caseFile = computed(() => this.fileHub.caseFile);
  protected readonly hasError = signal<boolean>(false);
  protected readonly destroy$ = this.fileHub.destroy$;

  constructor(
    private readonly fileHub: FileHubService,
    private readonly financialPerformance: FinancialPerformanceService
  ) {
    super();
  }

  ngOnInit() {
    this.initializeComponent();
  }

  ngOnDestroy(): void {
    this.fileHub.destroy$.next();
    this.fileHub.destroy$.complete();
  }

  private async initializeComponent(): Promise<void> {
    await this.fileHub.initializeCaseFileFromRoute();
    this.loadAuthLimitData();
    this.loading.set(false);
  }

  async loadAuthLimitData(): Promise<void> {
    this.financialPerformance.getAuthLimitWarning().then(percentage => {
      this.fileHub.authLimitPercentage = percentage;
    }).catch(error => {
      // Log the error but don't block the UI since this is non-critical
      this.handleError(error, {
        context: 'FileHubComponent.initializeComponent.getAuthLimitWarning',
        userMessage: 'Warning: Unable to load authorization limit data'
      });
    });
  }
}
