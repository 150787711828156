import { Injectable, inject } from '@angular/core';
import { Observable, from } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { NetworkStatus } from '@models/components/provider-map.model';
import { Query } from '@syncfusion/ej2-data';
import { AuthenticatedServiceBase } from '@core/auth/auth.base';
import { APIEndpoints } from '@shared/models/api/Endpoints';

@Injectable({
  providedIn: 'root'
})
export class NetworkStatusService extends AuthenticatedServiceBase {
  protected override readonly endpoint = this.APIEndpoints.ProviderPriorities;

  constructor() {
    super();
  }

  getNetworkStatuses(): Observable<NetworkStatus[]> {
    const query = new Query();
    const dataManager = this.api.getOdata(this.endpoint);
    return from(dataManager.executeQuery(query)).pipe(
      map((response: any) => response.result as NetworkStatus[]),
      catchError(error => this.handleError(error, {
        context: 'NetworkStatusService.getNetworkStatuses',
        userMessage: 'Failed to get network statuses'
      }))
    );
  }
}
