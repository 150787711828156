<!-- Modal to open on custom add function -->
<ejs-dialog #Modal width="1000px" header="Bulk Edit Tasks" [isModal]="true" [showCloseIcon]='true' [visible]="false"
    (beforeOpen)="beforeModalOpened($event)">
    <ng-template #content>
        <form [formGroup]="editForm" (ngSubmit)="onSubmit()" class="d-flex flex-column search-form">
            <div class="row">
                <div class="col-6">
                    <app-dropdown-single id="task_type" name="task_type" placeholder="Task Type" [endpoint]=taskType
                        [form]="editForm"></app-dropdown-single>
                </div>
                <div class="col-6">
                    <app-dropdown-multi id="remind" name="remind" placeholder="Assign" [endpoint]=users
                        descriptionName="Name" [form]="editForm"></app-dropdown-multi>
                </div>
                <div class="col-6">
                    <ejs-datepicker required id="next_date_of_activity" floatLabelType="Auto"
                        placeholder="Next Date Of Activity" formControlName="next_date_of_activity"></ejs-datepicker>
                </div>
                <div class="col-6">
                    <app-dropdown-single id="next_activity_type" name="next_activity_type"
                        placeholder="Next Activity Type" [endpoint]=nextActivityType descriptionName="NextActivityType1"
                        [form]="editForm"></app-dropdown-single>
                </div>
                <div class="col-6">
                    <ejs-numerictextbox id="priority" formControlName="priority"
                        placeholder="Priority Level"></ejs-numerictextbox>
                </div>
                <div class="col-10"> </div>
                <div class="col-2 form-submit">
                    <button ejs-button class="e-primary e-flat" type="submit" isPrimary="true">Submit</button>
                </div>
            </div>
        </form>
    </ng-template>
</ejs-dialog>