<!-- Comment Thread Form Start -->
<div id="comment-thread-form" [class]="formClasses.formContainer">

    <form [formGroup]="form" [class]="formClasses.form">

        <section [class]="formClasses.section + ' w-100'">

            <div [class]="formClasses.group">
                <!-- Title Input -->
                <div [class]="formClasses.inputContainer + ' ' + formClasses.twoCol" [class.error]="form.get('Title')?.errors && form.get('Title')?.touched">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="icons.title" />
                    </span>
                    <ejs-textbox 
                        formControlName="Title" 
                        placeholder="Title *"
                        [class]="formClasses.input"
                        floatLabelType="Auto">
                    </ejs-textbox>
                    <ng-container *ngIf="form.get('Title')?.invalid && form.get('Title')?.touched">
                        <span [class]="formClasses.error">Title is required</span>
                    </ng-container>
                </div>

                <!-- Status Dropdown -->
                <div [class]="formClasses.inputContainer + ' ' + formClasses.twoCol" [class.error]="form.get('Status')?.errors && form.get('Status')?.touched">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="icons.status" />
                    </span>
                    <ejs-dropdownlist
                        formControlName="Status"
                        [dataSource]="['Open', 'Closed']"
                        placeholder="Status *"
                        [class]="formClasses.input"
                        floatLabelType="Auto">
                    </ejs-dropdownlist>
                    <ng-container *ngIf="form.get('Status')?.invalid && form.get('Status')?.touched">
                        <span [class]="formClasses.error">Status is required</span>
                    </ng-container>
                </div>

                <!-- Description Input -->
                <div [class]="formClasses.inputContainer" [class.error]="form.get('Description')?.errors && form.get('Description')?.touched">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="icons.description" />
                    </span>
                    <ejs-textbox 
                        formControlName="Description" 
                        placeholder="Description"
                        floatLabelType="Auto"
                        [class]="formClasses.input"
                        multiline="true">
                    </ejs-textbox>
                </div>
            </div>
        </section>
    </form>
</div>
<!-- Comment Thread Form End -->