<div id="add-user-role">

    <form #addUserRoleFrom id="add-user-role-form" [formGroup]="addRoleForm" #formDir="ngForm" class="row d-flex flex-column" novalidate="" (ngSubmit)="addRoleSubmit()">

        <div class="form-group required">
            <label for="check">New Role Title</label>
            <div class="input-container">
                <ejs-textbox id="check" formControlName="addRoleForm_RoleName"></ejs-textbox>
                <div *ngIf="addRoleForm.get('addRoleForm_RoleName')?.errors?.['required'] && addRoleForm.get('addRoleForm_RoleName')?.touched" class="e-error">
                    <span>Required</span>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label>New Role Description</label>
            <div class="input-container">
                <ejs-textbox [multiline]="true" formControlName="addRoleForm_Description"></ejs-textbox>
            </div>
        </div>

        <div class="form-group required">
            <label>New Role Rights</label>
            <div class="input-container">
                <ejs-multiselect #addRoleFormRightsMultiSelect 
                    id="multiselect-rights-add" 
                    formControlName="addRoleForm_Rights"  
                    [dataSource]="rightsListData" 
                    [fields]="rightsFields"
                    [showDropDownIcon]="true"
                    placeholder="Select">
                </ejs-multiselect>
                <div *ngIf="addRoleForm.get('addRoleForm_Rights')?.errors?.['required'] && addRoleForm.get('addRoleForm_Rights')?.touched" class="e-error">
                    <span>Required</span>
                </div>
            </div>
        </div>

        <div class="form-group form-submission">
            <div class="row">
                <div class="col-6">
                    <button ejs-button id="resetbtn" type="reset" cssClass="e-outline">Clear</button>
                </div>
                <div class="col-6">
                    <button ejs-button id="validateSubmit" type="submit">Add Role</button>
                </div>
            </div>
        </div>

    </form>
    
</div>