// Angular
import { Component, ViewChild } from '@angular/core';

// 3rd Party
import { GridModel } from '@syncfusion/ej2-grids';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { ApiService } from '@services/api/api.service';
import { GlobalsService } from '@services/globals/globals.service';
import { GridTemplateModule } from '@modules/grid-template.module';
import { BulkEditTasksComponent } from '../../forms/bulk-edit-forms/bulk-edit-tasks/bulk-edit-tasks.component';

@Component({
  selector: 'app-tasks-grid',
  standalone: true,
  imports: [GridTemplateModule, BulkEditTasksComponent],
  templateUrl: './tasks-assignment-grid.component.html',
  styleUrl: './tasks-assignment-grid.component.scss'
})
export class TasksAssignmentGridComponent {

  constructor(
    private api: ApiService,
    private globals: GlobalsService
  ) { 
    console.log(APIEndpoints.XrefUsersCaseFileTaskExpanded)
  }

  @ViewChild('Modal') modal: BulkEditTasksComponent;

  // Initialize grid variables
  public gridSettings: GridModel;

  ngOnInit(): void {
    this.gridSettings = {
      dataSource: this.api.getOdata(APIEndpoints.XrefUsersCaseFileTaskExpanded),
      toolbar: this.globals.isResponsive ? ['Add', 'Delete', 'Update', 'Cancel'] :
        [ 'Add', { text: 'Edit', tooltipText: 'Edit Task', id: 'EditTask' }, 'Delete', 'Update', 'Cancel', 'ColumnChooser'],
      columns: [
        { type: 'checkbox', textAlign: 'Center', allowFiltering: false },
        { field: 'Id', headerText: 'ID', isPrimaryKey: true, visible: false },
        { field: 'User.Name', headerText: 'Assign' },
        { field: 'CaseFileTask.CaseFile.FileNumber', headerText: 'Case File', template: '<div ><a href="./case-files/${CaseFileTask.CaseFile.FileNumber}" target="_blank">${CaseFileTask.CaseFile.FileNumber}</a></div>' },
        { field: 'CaseFileTask.TaskName', headerText: 'Task' },
        { field: 'CaseFileTask.TaskType.Description', headerText: 'Task Type' },
        { field: 'CaseFileTask.Comments', headerText: 'Comments' },
        { field: 'CaseFileTask.NextActivityType.NextActivityType1', headerText: 'Next Activity Type' },
        { field: 'CaseFileTask.Priority', headerText: 'Priority Level' },
        { field: 'CaseFileTask.NextDateOfActivity', headerText: 'Due date' },
      ],
      toolbarClick: ($event: any) => this.customToolbarClick($event)
    }
  }

  // Add custom functionality when grid toolbar is clicked
  customToolbarClick(args: any) {
    if (args.item.id === 'EditTask') {
      this.modal.showModal();
    }
  }
}