export class AppError extends Error {
    constructor(
        public readonly userMessage: string,
        public readonly technical: string | Record<string, unknown>,
        public readonly context: string,
        public readonly originalError?: Error
    ) {
        super(typeof technical === 'string' ? technical : JSON.stringify(technical));
        this.name = 'AppError';

        // Capture stack at creation point
        Error.captureStackTrace(this, AppError);

        // If we have original error, prepend its stack
        if (originalError?.stack) {
            this.stack = `${this.stack}\nCaused by: ${originalError.stack}`;
        }
    }
}
