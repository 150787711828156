// Angular
import { Component, ViewChild } from '@angular/core';

// 3rd Party
import { UploaderComponent } from '@syncfusion/ej2-angular-inputs';
import { ButtonComponent, ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { UploaderModule } from '@syncfusion/ej2-angular-inputs';

// Internal
import { ThemeService } from '@services/theme/theme.service';
import { UserPreferencesService } from '@services/user/user-preferences.service';
import { ThemePickerComponent } from "./theme-picker/theme-picker.component";
import { ApiService } from '@root/src/app/shared/services/api/api.service';

@Component({
  selector: 'profile-appearance',
  standalone: true,
  imports: [
    ButtonModule,
    UploaderModule,
    ThemePickerComponent,
  ],
  templateUrl: './profile-appearance.component.html',
  styleUrl: './profile-appearance.component.scss'
})
export class ProfileAppearanceComponent {
  @ViewChild('darkModeBtn') darkModeBtn: ButtonComponent;
  @ViewChild('LogoUploader') uploadObj: UploaderComponent;

  constructor(
    private theme: ThemeService,
    private user: UserPreferencesService,
    private api: ApiService
  ) { }

  ngAfterViewInit(): void {
    // console.log((document.getElementsByClassName('e-file-upload-btn')[0] as HTMLElement));
    // (document.getElementsByClassName('e-file-upload-btn')[0] as HTMLElement).onclick = (args) => this.uploadFile();
  }

  // Toggle appearance of app
  toggleDarkMode() {
    this.user.toggleDarkMode();
  }

  handleClearThemeClick = () => this.theme.resetTheme();

  uploadFile(): void {
    const file = (this.uploadObj as UploaderComponent).getFilesData()[0].rawFile as Blob
    const reader = new FileReader();
    reader.readAsDataURL(file);
    this.uploadObj.clearAll()

    reader.onload = () => {
      console.log(reader.result);
      this.api.basicPost('api/upload-picture/logo', reader.result, false)
    };

    reader.onerror = (error) => {
      console.error('Error converting file to base64:', error);
    };
  }
}
