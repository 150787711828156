// Angular
import { Injectable, computed, signal } from '@angular/core';
import { FormGroup } from '@angular/forms';

// 3rd Party
import { DataManager, Query, Predicate } from '@syncfusion/ej2-data';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { Invoice } from '@models/data-contracts';
import { ApiService } from '@services/api/api.service';
import { GlobalsService } from '@services/globals/globals.service';

interface DialogVisibility {
  addInvoice: boolean;
  editInvoice: boolean;
  bulkEdit: boolean;
}

interface InvoiceEditData {
  InvoiceId?: number;
  InternalInvoiceNumber?: string;
  Notes?: string;
  ProviderId?: number;
  ProviderInvoiceNumber?: string;
  InvoiceRow?: {
    Id?: number;
    AmountBilled?: number;
    SettlementValue?: number;
    TotalDueProvider?: number;
  };
  InvoicePayment?: {
    Id?: number;
    AmountPaid?: number;
    BalanceDue?: number;
    DatePaid?: string;
    PaymentStatus?: number;
  };
  InvoicePaymentPaymentStatus?: number;
}

interface ODataResponse<T> {
  result: T[];
  count?: number;
  '@odata.count'?: number;
}

@Injectable({
  providedIn: 'root'
})
export class InvoicesService {
  // State
  private readonly selectedInvoice = signal<Invoice | null>(null);
  private readonly dialogVisibility = signal<DialogVisibility>({
    addInvoice: false,
    editInvoice: false,
    bulkEdit: false
  });

  // Computed state
  readonly selectedInvoiceValue = this.selectedInvoice;
  readonly dialogVisibilityValue = computed(() => this.dialogVisibility());

  constructor(
    private api: ApiService,
    private globals: GlobalsService
  ) {}

  // Data fetching with pagination
  async getInvoices(pageSettings: { skip: number; take: number } = { skip: 0, take: 20 }) {
    const query = new Query()
      .expand('CaseFile($expand=Patient),InvoiceRows,InvoicePayments($expand=PaymentMethodNavigation,PaymentStatusNavigation),Provider')
      .skip(pageSettings.skip)
      .take(pageSettings.take)
      .requiresCount();

    const response = (await this.api.getOdata(APIEndpoints.Invoices).executeQuery(query)) as unknown as ODataResponse<any>;

    if (!response || !response.result) {
      throw new Error('Failed to fetch invoices.');
    }

    const count = response.count || response['@odata.count'] || 0;

    return {
      result: response.result,
      count: count,
      aggregates: null,
      groupDs: null,
    };
  }

  // Handle grid state changes
  async fetchWithStateChange(state: any) {
    if (!state.action) return null;

    const pageSettings = {
      skip: state.skip || 0,
      take: state.take || 20,
    };

    // Base query with expansion
    const query = new Query()
      .expand('CaseFile($expand=Patient),InvoiceRows,InvoicePayments($expand=PaymentMethodNavigation,PaymentStatusNavigation),Provider')
      .skip(pageSettings.skip)
      .take(pageSettings.take)
      .requiresCount();

    // Apply filtering
    if (state.where) {
      let mainPredicate: Predicate | null = null;

      state.where.forEach((filter: any) => {
        filter.predicates?.forEach((pred: any) => {
          let currentPredicate: Predicate;
          const field = pred.field.replace(/\./g, '/');
          currentPredicate = new Predicate(field, pred.operator, pred.value, pred.ignoreCase);
          mainPredicate = mainPredicate ? mainPredicate.and(currentPredicate) : currentPredicate;
        });
      });

      if (mainPredicate) {
        query.where(mainPredicate);
      }
    }

    // Clear any existing sort to prevent multiple $orderby parameters
    query.queries = query.queries.filter((q: any) => !q.fn || q.fn !== 'onSortBy');

    // Handle sorting
    if (state.sorted?.length) {
      state.sorted.forEach((sort: any) => {
        query.sortBy(sort.name, sort.direction.toLowerCase());
      });
    }

    // Execute the query
    const response = (await this.api.getOdata(APIEndpoints.Invoices).executeQuery(query)) as unknown as ODataResponse<any>;
    const count = response.count || response['@odata.count'] || 0;

    return {
      result: response.result,
      count: count,
    };
  }

  // Data fetching for dropdowns
  getPaymentStatuses(): DataManager {
    return this.api.getOdata(APIEndpoints.PaymentStatuses);
  }

  getProvidersData(): DataManager {
    return this.api.getOdata(APIEndpoints.Providers);
  }

  getProcedureCodes(): DataManager {
    return this.api.getOdata(APIEndpoints.ProcedureCodes);
  }

  // Data updates
  async updateInvoice(data: InvoiceEditData): Promise<void> {
    if (!data.InvoiceId) return;

    const invoiceObj = {
      InternalInvoiceNumber: data.InternalInvoiceNumber,
      Notes: data.Notes,
      ProviderId: data.ProviderId,
      ProviderInvoiceNumber: data.ProviderInvoiceNumber
    };

    await this.api.fetchRequest(`odata${APIEndpoints.Invoices}(${data.InvoiceId})`, 'PATCH', invoiceObj);
  }

  async updateInvoiceRow(rowId: number, data: any): Promise<void> {
    const cleanedData = { ...data };
    delete cleanedData.CreatedAt;
    delete cleanedData.CreatedBy;
    delete cleanedData.ProcedureCode;
    cleanedData.DateOfService = this.globals.formatDateForBackend(cleanedData.DateOfService);

    await this.api.fetchRequest(`odata${APIEndpoints.InvoiceRows}(${rowId})`, 'PATCH', cleanedData);
  }

  async updateInvoicePayment(paymentId: number, data: any): Promise<void> {
    const cleanedData = { ...data };
    delete cleanedData.CreatedAt;
    delete cleanedData.CreatedBy;
    delete cleanedData.PaymentMethodNavigation;
    delete cleanedData.PaymentStatusNavigation;
    cleanedData.DatePaid = this.globals.formatDateForBackend(cleanedData.DatePaid);

    await this.api.fetchRequest(`odata${APIEndpoints.InvoicePayments}(${paymentId})`, 'PATCH', cleanedData);
  }

  // Form operations
  submitNewInvoice(form: FormGroup): boolean {
    if (!form.valid) return false;
    // Add form submission logic here
    return true;
  }

  submitBulkEdit(form: FormGroup): boolean {
    if (!form.valid) return false;
    // Add bulk edit submission logic here
    return true;
  }

  resetInvoiceForm(form: FormGroup): void {
    form.reset();
  }

  resetBulkEditForm(form: FormGroup): void {
    form.reset();
  }

  // Dialog state management
  setDialogVisibility(dialog: keyof DialogVisibility, visible: boolean): void {
    this.dialogVisibility.update(current => ({
      ...current,
      [dialog]: visible
    }));
  }

  closeAllDialogs(): void {
    this.dialogVisibility.set({
      addInvoice: false,
      editInvoice: false,
      bulkEdit: false
    });
  }

  setSelectedInvoice(invoice: Invoice | null): void {
    this.selectedInvoice.set(invoice);
  }
}