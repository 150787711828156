// Angular
import { Component, computed, signal, ViewChild, WritableSignal, effect } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { CommonModule } from '@angular/common';

// Syncfusion
import { GridComponent, CommandModel, GridModel, CommandClickEventArgs } from '@syncfusion/ej2-angular-grids';
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';
import { Query, Predicate } from '@syncfusion/ej2-data';

// Models
import { APIEndpoints } from '@models/api/Endpoints';
import { CaseFile } from '@models/data-contracts';

// Services
import { AuditLogService } from '@services/audit-logs/audit-log-service';
import { FileViewService } from '@services/file-view/file-view.service';
import { CaseFileService } from '@features/case-files/services/case-file.service';

// Modules
import { LoadingModule } from '@modules/loading.module';

// Components
import { ComponentBase } from '@core/base/component.base';
import { BulkEditCaseFilesForm } from '@forms/bulk-edit-forms/bulk-edit-case-file/bulk-edit-case-file.component';
import { GridTemplateComponent } from '@grids/grid-template/grid-template.component';
import { AuditLogsComponent } from '@ui/audit-logs/audit-logs.component';
import { CaseFileFormComponent } from '@root/app/features/case-files/components/case-file-form/case-file-form.component';

// Add error constants
const ERRORS = {
  CASE_FILES: {
    LOAD_FAILED: {
      message: 'Failed to load case files',
      technical: 'Error loading case files from API'
    },
    NO_CASE_NUMBER: {
      message: 'Unable to open Case File.<br />No Case Number found.',
      technical: 'No Case Number found in Case File'
    },
    SUBMIT_FAILED: {
      message: 'Failed to submit case file',
      technical: 'Error submitting case file to API'
    }
  },
  TEMPLATE: {
    LOAD_FAILED: {
      message: 'Error loading template data',
      technical: 'Failed to load template info'
    }
  },
  AUDIT_LOGS: {
    NO_LOGS_FOUND: {
      message: 'No audit logs found',
      technical: 'No audit logs found in API'
    },
    DISPLAY_ERROR: {
      message: 'Error displaying logs dialog',
      technical: 'Error displaying logs dialog'
    },
    CLOSE_ERROR: {
      message: 'Error closing logs dialog',
      technical: 'Error closing logs dialog'
    }
  }
};
// Add interface at top of file
interface ODataResponse {
  result: any[];
  '@odata.count': number;
}

@Component({
  selector: 'case-files-grid',
  standalone: true,
  imports: [
    CommonModule,
    DialogModule,
    GridTemplateComponent,
    BulkEditCaseFilesForm,
    CaseFileFormComponent,
    AuditLogsComponent,
    LoadingModule,
  ],
  templateUrl: './case-files-grid.component.html',
  styleUrl: './case-files-grid.component.scss',
})
export class CaseFilesGridComponent extends ComponentBase {
  constructor(
    private auditLogService: AuditLogService,
    private router: Router,
    private fileView: FileViewService,
    private caseFileService: CaseFileService
  ) {
    super();
  } 

  

  // Initialize variables for caseFilesGrid
  @ViewChild('caseFilesGrid') caseFilesGrid: GridComponent;
  @ViewChild('caseFiles') caseFiles: GridTemplateComponent;
  @ViewChild('fileNumberDisplayTemplate', { static: true }) fileNumberDisplayTemplate!: string;
  @ViewChild('lawFirmDisplayTemplate', { static: true }) lawFirmDisplayTemplate!: string;
  caseFilesGridSettings: GridModel;
  auditLogs: any[] = [];
  auditLogsLoading = false;
  caseFilesGridDefaultToolbar: any[] = ['Add', 'ColumnChooser'];
  caseFileSignal: WritableSignal<CaseFile | undefined> = signal(undefined);
  caseFileData = computed(() => this.caseFileSignal());
  loading = signal(true);
  caseFilesGridActionsColumnCommands: CommandModel[] = [
    { type: 'Edit', title: 'Edit', buttonOption: { iconCss: 'e-icons e-edit', cssClass: 'e-flat' } },
    { type: 'None', title: 'Logs', buttonOption: { iconCss: 'e-icons e-description', cssClass: 'e-flat' } },
  ];

  // Adding a new case file
  @ViewChild('addCaseFileDialog', { static: true }) addCaseFileDialog: DialogComponent;
  @ViewChild('newFileForm') newFileForm: CaseFileFormComponent;
  emptyCaseFileObj: object;
  addCaseFileDialogVisibility: Boolean = false;
  selectedRecords: any[] = [];
  addCaseFileButtons: Object[] = [
    { click: this.closeDialog.bind(this), buttonModel: { content: 'Cancel', cssClass: 'e-outline', isPrimary: false } },
    {
      click: this.onNewCaseFileSubmit.bind(this),
      buttonModel: { content: 'Submit', cssClass: 'e-primary', isPrimary: true },
    },
  ];

  // Custom dialog for bulk editing files
  @ViewChild('bulkEditCaseFilesDialog') bulkEditCaseFilesDialog: DialogComponent;
  @ViewChild('bulkEditForm') bulkEditForm: BulkEditCaseFilesForm;
  bulkEditCaseFilesDialogVisibility: Boolean = false;
  bulkUpdateCaseFilesButtons: Object[] = [
    { click: this.closeDialog.bind(this), buttonModel: { content: 'Cancel', cssClass: 'e-outline' } },
    {
      click: this.onBulkEditSubmit.bind(this),
      buttonModel: { content: 'Submit', isPrimary: true, cssClass: 'e-primary' },
    },
  ];

  // Editing a single case file
  @ViewChild('editCaseFileDialog', { static: true }) editCaseFileDialog: DialogComponent;
  @ViewChild('editFileForm') editFileForm: CaseFileFormComponent;
  editCaseFileDialogVisibility: Boolean = false;
  editCaseFile: CaseFile;
  editCaseFileButtons: object[] = [
    { click: this.closeDialog.bind(this), buttonModel: { content: 'Cancel', cssClass: 'e-outline' } },
    {
      click: this.onEditCaseFileSubmit.bind(this),
      buttonModel: { content: 'Submit', isPrimary: true, cssClass: 'e-primary' },
    },
  ];

  @ViewChild('logsDialog', { static: true }) logsDialog: DialogComponent;
  logsDialogVisibility: Boolean = false;
  logsDialogButtons: Object[] = [
    { click: this.closeLogsDialog.bind(this), buttonModel: { content: 'Close', cssClass: 'e-flat' } },
  ];

  // Add a property to track programmatic refreshes
  private isRefreshing = false;

  ngOnInit() {
    this.getData().then(result => {
      this.caseFilesGridSettings = {
        dataSource: result,
        allowPaging: true,
        pageSettings: {
          pageSize: 16,
          pageSizes: true,
          pageCount: 5,
          enableQueryString: true,
          totalRecordsCount: result.count,
        },
        allowSorting: true,
        sortSettings: {
          columns: [{ field: 'FileOpened', direction: 'Descending' }]
        },
        selectionSettings: {
          checkboxOnly: true,
          persistSelection: true
        },
        dataStateChange: ($event: any) => this.onDataStateChange($event),
        toolbar: this.caseFilesGridDefaultToolbar,
        columns: [
          { type: 'checkbox' },
          { field: 'Id' },
          {
            field: 'FileNumber',
            headerText: 'File Number',
            allowEditing: false,
            template: this.fileNumberDisplayTemplate,
          },
          { field: 'Patient.FullName', headerText: 'Client Name' },
          { field: 'Patient.Dob', headerText: 'DOB', format: 'yMd' },
          { field: 'Patient.Minor', headerText: 'Minor', visible: false },
          { field: 'ResponsibleParty', headerText: 'Responsible Party', visible: false },
          { field: 'Patient.XrefAddressPatients.Address.StateNavigation.Name', headerText: 'State' },
          { field: 'Patient.XrefAddressPatients.Address.County', headerText: 'County', visible: false },
          { field: 'LawFirm.Name', headerText: 'Law Firm', template: this.lawFirmDisplayTemplate },
          { field: 'LawFirmContactNavigation.ContactName', headerText: 'Law Firm Contact', visible: false },
          { field: 'AttorneyNavigation.ContactName', headerText: 'Attorney', visible: false },
          { field: 'FileOpened', headerText: 'File Opened', allowEditing: false, format: 'yMd' },
          { 
            field: 'InTreatment', 
            headerText: 'In Treatment',
            valueAccessor: (field: string, data: object): string => {
              return (data as any)[field] === true ? 'Yes' : 'No';
            }
          },
          { field: 'IsSurgical', headerText: 'Surgical', visible: false },
          { field: 'CaseManagerNavigation.Name', headerText: 'Case Manager', visible: false },
          { field: 'Status', headerText: 'Record Status', visible: false },
          { field: 'ReferralSourceNavigation.Description', headerText: 'Referral Source', visible: false },
          { field: 'DateOfLoss', headerText: 'Date of Loss', visible: false },
          { field: 'AdditionalDatesOfLoss', headerText: 'Additional Dates of Loss', visible: false },
          { field: 'MarketManagerNavigation.Name', headerText: 'Market Manager', visible: false },
          { field: 'Patient.Email', headerText: 'Client Email', visible: false },
          { field: 'Patient.Phone', headerText: 'Client Phone', visible: false },
          { field: 'Patient.Language', headerText: 'Client Language', visible: false },
          { field: 'LawFirmContactNavigation.Email', headerText: 'Law Firm Contact Email', visible: false },
          {
            field: 'Comments',
            headerText: 'Comments',
            allowFiltering: false,
            allowSorting: false,
            valueAccessor: (field: string, data: object): string[] => this.getCommentValues(field, data),
            visible: false,
          },
          { field: 'PAndLNavigation.Description', headerText: 'P&L', visible: false },
          { field: 'LawFirmFileNumber', headerText: 'Law Firm File Number', visible: false },
          { field: 'BalanceDue', headerText: 'Balance Due', visible: false },
          { field: 'Statuser', headerText: 'Statuser', visible: false },
          { field: 'SelectFiles', headerText: 'Select Files', visible: false },
          { type: 'commands', commands: this.caseFilesGridActionsColumnCommands, headerText: 'Actions' },
          { field: 'CreatedAt', headerText: 'Created', visible: false, allowEditing: false, format: 'yMd' },
          { field: 'UpdatedAt', headerText: 'Updated', visible: false, allowEditing: false, format: 'yMd' },
        ],
        created: () => this.onCreated(),
        dataBound: ($event: any) => this.onDataBound($event),
        toolbarClick: ($event: any) => this.onCustomToolbarClick($event),
        rowSelected: ($event: any) => this.onRowSelected($event),
        rowSelecting: ($event: any) => this.onRowSelecting($event),
        rowDeselected: ($event: any) => this.onRowDeselected($event),
        rowDeselecting: ($event: any) => this.onRowDeselecting($event),
        commandClick: ($event: CommandClickEventArgs) => this.onCommandClick($event),
      };
    });
  }

  // Fetch and set data for proper grid actions - Now using the service
  async getData(pageSettings: { skip: number; take: number } = { skip: 0, take: 16 }) {
    try {
      const result = await this.caseFileService.getCaseFiles(pageSettings);
      this.loading.set(false);
      return result;
    } catch (error) {
      this.handleError(error, {
        context: 'CaseFilesGridComponent.getData',
        userMessage: ERRORS.CASE_FILES.LOAD_FAILED.message,
        severity: this.ErrorSeverity.Error
      });
      throw error;
    }
  }

  // Ensures foreign key fields have data ready for display
  getPatientName(field: string, data: object): string {
    const patient = (data as any)[field];
    if (!patient?.Firstname && !patient?.Lastname) return '';
    return `${patient.Firstname || ''} ${patient.Lastname || ''}`.trim();
  }

  onLawFirmClick(lawFirm: any) {
    this.router.navigate(['/law-firm-detail', lawFirm.Id]).then(() => {
      window.location.reload();
    });
  }

  // Value accessor for displaying strings in comments column
  getCommentValues(field: string, data: object): string[] {
    if ((data as any)[field]) {
      return (data as any)[field as string];
    } else {
      return [''];
    }
  }

  onCreated() {
    this.caseFilesGridDefaultToolbar = this.caseFiles.settings.toolbar as any[];
  }

  onDataBound(args: any) {
    this.caseFilesGrid = this.caseFiles.grid;
  }

  // Add logic for rendering modals
  onCustomToolbarClick(args: any) {
    if (args.item.text === 'Add') {
      args.cancel = true;
      this.caseFileSignal.set(undefined);
      this.clearBulkEditForm();
      this.addCaseFileDialog.show();

      // Improve initialization of dropdown data when dialog opens
      setTimeout(() => {
        if (this.newFileForm) {
          // Call the dedicated method for dialog initialization
          this.newFileForm.onDialogOpen();
        }
      }, 100);
    }

    if (args.item.id === 'BulkEdit') {
      this.bulkEditCaseFilesDialogVisibility = true;
      if (this.bulkEditCaseFilesDialog) this.bulkEditCaseFilesDialog.show();
    }
    if (args.item.id === 'BulkOpen') {
      this.handleBulkOpen();
    }
  }

  // Add logic for editing individual case files
  onCommandClick(commandClickArgs: CommandClickEventArgs) {
    const file: CaseFile = commandClickArgs.rowData as CaseFile;

    if (!file) {
      this.handleError(new Error(ERRORS.CASE_FILES.NO_CASE_NUMBER.message), {
        context: 'CaseFilesGridComponent.onCommandClick',
        userMessage: ERRORS.CASE_FILES.NO_CASE_NUMBER.message,
        severity: this.ErrorSeverity.Error
      });
      return;
    } else if (commandClickArgs.commandColumn?.type === 'Edit') {
      commandClickArgs.cancel = true;
      this.editCaseFile = commandClickArgs.rowData as CaseFile;
      this.caseFileSignal.set(this.editCaseFile);
      if (file.FileNumber && this.caseFileData() && this.editCaseFileDialog) {
        this.editCaseFileDialog.show();

        // Improve initialization of dropdown data when dialog opens
        setTimeout(() => {
          if (this.editFileForm) {
            // Call the dedicated method for dialog initialization
            this.editFileForm.onDialogOpen();
          }
        }, 100);
      }
      return;
    } else if (commandClickArgs.commandColumn?.title === 'Logs') {
      commandClickArgs.cancel = true;

      if (file.Id) {
        this.auditLogsLoading = true;
        this.showLogsDialog();

        return this.auditLogService.getAuditLogs(file.Id, 'CaseFile').then((result: any) => {
          if (!result || !result.length || result.length === 0) {
            this.handleError(new Error(ERRORS.AUDIT_LOGS.NO_LOGS_FOUND.message), {
              context: 'CaseFilesGridComponent.onCommandClick',
              userMessage: ERRORS.AUDIT_LOGS.NO_LOGS_FOUND.message,
              severity: this.ErrorSeverity.Error
            });
            return;
          }

          this.auditLogs = this.auditLogService.mapAuditDataToLogFormat(result);
          this.auditLogsLoading = false;
          return;
        });
      }

      return;
    } else {
      commandClickArgs.cancel = true;
      this.caseFiles.onCommandClick(commandClickArgs);
      return;
    }
  }

  // New method to show audit logs dialog
  showLogsDialog() {
    try {
      this.logsDialogVisibility = true;
      if (this.logsDialog) {
        this.logsDialog.show();
      } else {
        this.handleError(new Error(ERRORS.AUDIT_LOGS.NO_LOGS_FOUND.message), {
          context: 'CaseFilesGridComponent.showLogsDialog',
          userMessage: ERRORS.AUDIT_LOGS.NO_LOGS_FOUND.message,
          severity: this.ErrorSeverity.Error
        });
      }
    } catch (error) {
      this.handleError(error, {
        context: 'CaseFilesGridComponent.showLogsDialog',
        userMessage: ERRORS.AUDIT_LOGS.DISPLAY_ERROR.message,
        severity: this.ErrorSeverity.Error
      });
    }
  }

  // Update caseNumberClick to use signal
  caseNumberClick(data: any) {
    if (!data.FileNumber) {
      this.handleError(new Error(ERRORS.CASE_FILES.NO_CASE_NUMBER.message), {
        context: 'CaseFilesGridComponent.caseNumberClick',
        userMessage: ERRORS.CASE_FILES.NO_CASE_NUMBER.message,
        severity: this.ErrorSeverity.Error
      });
      return;
    }

    // Use service signal directly
    this.fileView.openFiles.update(files => {
      const fileExists = files.some((file: any) => file.id === data.FileNumber);

      if (!fileExists) {
        files.push({
          id: data.FileNumber,
          name: data.Patient ? `${data.Patient.Firstname} ${data.Patient.Lastname}` : 'Unknown Patient',
        });
      }

      return [...files];
    });

    // Navigate to the case file
    this.router
      .navigate(['/case-files/hub'], {
        queryParams: { fileNumber: data.FileNumber },
      })
      .then(() => {
        // We need to ensure any form components are properly initialized
        // This fixes issue with clicking on file numbers not opening the view case file form
      });
  }

  handleBulkOpen() {
    const selectedRecords = this.caseFilesGrid.getSelectedRecords();
    selectedRecords.forEach((record: CaseFile, index) => {
      if (index === 0) {
        this.caseNumberClick(record);
      } else if (record?.FileNumber) {
        const name = record?.Patient?.Firstname + ' ' + record?.Patient?.Lastname;
        const fileNumber = record?.FileNumber;
        this.fileView.saveOpenFile(name, fileNumber);
      }
    });
  }

  onRowSelected(args: any) {
    this.selectedRecords = this.caseFilesGrid.getSelectedRecords();
    this.updateToolbar();
  }

  // Detect selection of multiple rows
  onRowSelecting(args: any) {
    this.selectedRecords = this.caseFilesGrid.getSelectedRecords();
    this.updateToolbar();
  }

  onRowDeselected(args: any) {
    this.selectedRecords = this.caseFilesGrid.getSelectedRecords();
    this.updateToolbar();
  }

  // Occurs as rows are deselected
  onRowDeselecting(args: any) {
    this.selectedRecords = this.caseFilesGrid.getSelectedRecords();
    this.updateToolbar();
  }

  // Set toolbar according to number of selected rows
  updateToolbar() {
    if (this.selectedRecords && this.selectedRecords.length) {
      const length = this.selectedRecords.length;

      if (length === 1) {
        this.caseFilesGrid.toolbar = this.caseFilesGridDefaultToolbar;
      } else if (length > 1) {
        const bulkEditButton = {
          text: 'Bulk Edit',
          tooltipText: 'Bulk Edit',
          id: 'BulkEdit',
          prefixIcon: 'e-properties-1',
        };
        const buttonExists = this.caseFilesGrid.toolbar.some(button => (button as any).id === bulkEditButton.id);
        if (!buttonExists) this.caseFiles.insertToolbarItem(bulkEditButton); // make sure button is not already in toolbar

        const bulkOpenButton = {
          text: 'Bulk Open',
          tooltipText: 'Bulk Open',
          id: 'BulkOpen',
          prefixIcon: 'e-icons e-folder-open',
        };
        const openExists = this.caseFilesGrid.toolbar.some(button => (button as any).id === bulkOpenButton.id);
        if (!openExists) this.caseFiles.insertToolbarItem(bulkOpenButton); // make sure button is not already in toolbar
      }
    } else {
      this.caseFilesGrid.toolbar = this.caseFilesGridDefaultToolbar;
    }
  }

  // Close both modal windows
  async closeDialog() {
    if (this.addCaseFileDialog) this.addCaseFileDialog.hide();
    if (this.editCaseFileDialog) this.editCaseFileDialog.hide();
    this.editCaseFileDialogVisibility = false;
    if (this.bulkEditCaseFilesDialog) this.bulkEditCaseFilesDialog.hide();
    this.bulkEditCaseFilesDialogVisibility = false;
    if (this.logsDialog) this.logsDialog.hide();
    this.logsDialogVisibility = false;
  }

  // Set fields to blank
  clearCaseFileForm() {
    if (this.newFileForm) {
      this.newFileForm.caseFileForm.reset();
      this.newFileForm.patientFormComp.patientForm.reset();
      this.newFileForm.patientFormComp.addressFormComp.addressForm.reset();
    }
    if (this.editFileForm) {
      this.editFileForm.caseFileForm.reset();
      this.editFileForm.patientFormComp.patientForm.reset();
      this.editFileForm.patientFormComp.addressFormComp.addressForm.reset();
    }
  }

  // Update the onNewCaseFileSubmit method with the flag
  onNewCaseFileSubmit(args: any) {
    // @ts-ignore: Method exists at runtime
    this.newFileForm.onSubmit().then((result: any) => {
      if (result && (result.ok || result.Id)) {
        const fileNumber = result.FileNumber;
        const successMessage = `Case File successfully submitted. <br />
          <a href="/case-files/hub?fileNumber=${fileNumber}#Home">Go to Case File</a>`;
        this.closeDialog();
        this.notify(successMessage);
        
        // Set flag before refreshing
        this.isRefreshing = true;
        
        // Simple but effective refresh approach using the service
        this.getData().then(data => {
          if (this.caseFilesGridSettings) {
            this.caseFilesGridSettings.dataSource = data;
            if (this.caseFilesGrid) {
              this.caseFilesGrid.refresh();
              
              // Reset flag after a short delay to ensure all events are processed
              setTimeout(() => {
                this.isRefreshing = false;
              }, 100);
            }
          }
        });
      }

      if (result instanceof Error) {
        this.handleError(result, {
          context: 'CaseFilesGridComponent.onNewCaseFileSubmit',
          userMessage: ERRORS.CASE_FILES.SUBMIT_FAILED.message,
          severity: this.ErrorSeverity.Error
        });
      }
    });
  }

  // Similarly for edit submission
  onEditCaseFileSubmit(args: any) {
    // @ts-ignore: Method exists at runtime
    this.editFileForm.onSubmit().then((result: any) => {
      if (result && (result.ok || result.Id)) {
        this.closeDialog();
        this.notify('Case File successfully updated.');
        
        // Set flag before refreshing
        this.isRefreshing = true;
        
        // Simple but effective refresh approach using the service
        this.getData().then(data => {
          if (this.caseFilesGridSettings) {
            this.caseFilesGridSettings.dataSource = data;
            if (this.caseFilesGrid) {
              this.caseFilesGrid.refresh();
              
              // Reset flag after a short delay to ensure all events are processed
              setTimeout(() => {
                this.isRefreshing = false;
              }, 100);
            }
          }
        });
      }

      if (result instanceof Error) {
        this.handleError(result, {
          context: 'CaseFilesGridComponent.onEditCaseFileSubmit',
          userMessage: ERRORS.CASE_FILES.SUBMIT_FAILED.message,
          severity: this.ErrorSeverity.Error
        });
      }
    });
  }

  // Set bulk editing fields to blank
  clearBulkEditForm() {
    if (this.bulkEditForm) (this.bulkEditForm.bulkEditCaseFilesForm as FormGroup).reset();
  }

  // Apply bulk changes to data
  onBulkEditSubmit(args: any) {
    const comp = this;
    let form = this.bulkEditForm.bulkEditCaseFilesForm;

    if (form.valid) {
      this.selectedRecords.forEach(record => {
        for (const [key, value] of Object.entries(this.bulkEditForm.bulkEditCaseFilesForm.value)) {
          const updatedValue: any = value;
          if (value !== null) {
            this.caseFilesGrid.updateCell(record, key, updatedValue);
          }
        }
      });
      this.closeDialog();
      this.mapBulkEditChanges();
    } else {
      alert('Please enter a valid form.');
    }
  }

  // Ensure data is properly formatted before batchSave()
  mapBulkEditChanges() {
    let changedRecords: any[] = [];

    for (const [key, value] of Object.entries(this.bulkEditForm.bulkEditCaseFilesForm.value)) {
      if (value !== null) {
        let newObj = { [key]: value };
        changedRecords.push(newObj);
      }
    }
  }

  closeLogsDialog() {
    try {
      if (this.logsDialog) {
        this.logsDialog.hide();
        this.logsDialogVisibility = false;
        this.auditLogs = []; // Clear the logs when closing
      }
    } catch (error) {
      this.handleError(error, {
        context: 'CaseFilesGridComponent.closeLogsDialog',
        userMessage: ERRORS.AUDIT_LOGS.CLOSE_ERROR.message,
        severity: this.ErrorSeverity.Error
      });
    }
  }

  // Update onDataStateChange to use the service
  async onDataStateChange(state: any) {
    // Skip if this is a refresh triggered by our code
    if (this.isRefreshing) {
      return;
    }
    
    if (!state.action) return;
    
    try {
      const response = await this.caseFileService.fetchWithStateChange(state);
      
      if (this.caseFilesGridSettings && response) {
        // Apply the data without refreshing
        this.caseFilesGridSettings.dataSource = {
          result: response.result,
          count: response.count,
        };
      }

      // Don't use refresh for paging - it resets the sort
      if (state.action.requestType === 'paging' && this.caseFilesGrid && response) {
        // Instead of refresh(), use direct data binding
        this.caseFilesGrid.dataSource = {
          result: response.result,
          count: response.count,
        };
        
        // Use the setProperties method which is supported in the API
        this.caseFilesGrid.setProperties({
          dataSource: {
            result: response.result,
            count: response.count,
          }
        });
      }
    } catch (error) {
      this.handleError(error, {
        context: 'CaseFilesGridComponent.onDataStateChange',
        userMessage: ERRORS.CASE_FILES.LOAD_FAILED.message,
        severity: this.ErrorSeverity.Error
      });
    }
  }
}
