// Angular
import { Component, ViewChild, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

// Syncfusion
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';
import { GridComponent, GridModel, EditService, RowSelectEventArgs, GridAllModule } from '@syncfusion/ej2-angular-grids';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { Role, XrefRolesRight } from '@models/data-contracts';
import { GlobalsService } from '@services/globals/globals.service';
import { AddRoleComponent } from '@forms/add-forms/add-role/add-role.component';
import { TabCardComponent } from '@root/src/app/features/file-hub/components/file-hub-tabs/tab-card/tab-card.component';
import { BaseGridComponent } from '@shared/components/base-grid/base-grid.component';
import { BaseGridService } from '@shared/components/base-grid/services/state.service';
import { SetGridDataArgs } from '@shared/components/base-grid/models/grid.models';
import { BaseGridHooks } from '@shared/components/base-grid/services/hooks.service';
import { ErrorSeverity } from '@core/error/error.types';
import { ComponentBase } from '@core/base/component.base';
import { RolesService } from '@shared/features/roles/services/roles.service';

// Error constants
const ERRORS = {
  LOAD_RIGHTS: {
    message: 'Failed to load rights data. Please try again.',
    technical: 'Error loading rights data for role'
  },
  UPDATE_RIGHTS: {
    message: 'Failed to update role rights. Please try again.',
    technical: 'Error updating rights for role'
  }
};

@Component({
  selector: 'roles-grid',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    DialogModule,
    GridAllModule,
    AddRoleComponent,
    TabCardComponent,
    BaseGridComponent
  ],
  providers: [
    EditService,
    BaseGridHooks,
    BaseGridService,
    RolesService
  ],
  templateUrl: './roles-grid.component.html',
  styleUrls: ['./roles-grid.component.scss']
})
export class RolesGridComponent extends ComponentBase {

  constructor(
    private globals: GlobalsService,
    private gridService: BaseGridService,
    private rolesService: RolesService
  ) {
    super();

    this.showRoleAdder = window.location.pathname === '/users-roles' ? true : false;

    this.rolesGridSettings = {
      endpoint: APIEndpoints.Roles,
      name: 'User Roles',
      useRoundedEdges: true,
      query: this.rolesService.getRolesGridQuery(),
      transformData: (data: any[]) => this.rolesService.transformRolesForGrid(data),
      gridSettings: {
        toolbar: [{ text: 'Edit Rights', tooltipText: 'Edit Rights', id: 'EditRights', disabled: true }],
        selectionSettings: { mode: 'Row', type: 'Single' },
        columns: [
          { field: 'Id', isPrimaryKey: true, showInColumnChooser: false, visible: false },
          { field: 'RoleName', headerText: 'Role' },
          { field: 'Description' },
          { field: 'Rights', headerText: 'Rights', allowSorting: false, allowFiltering: false, allowEditing: false },
          { field: 'CreatedAt', headerText: 'Created', type: 'datetime', format: 'MMM dd, yyyy hh:mm a' },
        ],
        toolbarClick: ($event: any): void => this.toolbarClickHandler($event),
        rowSelected: ($event: RowSelectEventArgs): void => this.onRowSelected($event)
      }
    };

    this.availableRightsGridSettings = {
      dataSource: this.rolesService.getRightsDataManager(),
      columns: [
        { field: 'Id', isPrimaryKey: true, visible: false },
        { type: 'checkbox', width: 50, textAlign: 'Center', allowFiltering: false },
        { field: 'FriendlyName', headerText: 'Available Rights', allowFiltering: true},
      ],
      rowDataBound: ($event: any): void => this.customizeAvailableRightsBackgroundColor($event)
    };

    this.assignedRightsGridSettings = {
      dataSource: undefined,
      editSettings: {
        allowEditing: false,
        allowAdding: true,
        allowDeleting: true,
        showConfirmDialog: false,
        mode: 'Batch'
      },
      columns: [
        { field: 'RightId', isPrimaryKey: true, visible: false },
        { type: 'checkbox', width: 50, textAlign: 'Center', allowFiltering: false },
        { field: 'Friendly_name', headerText: 'Assigned Rights', allowFiltering: true},
      ]
    };
  }

  // Decorator Variables
  @Input() showRoleEditor: boolean;
  @Input() showRoleAdder: boolean = false;
  @ViewChild('usersRolesGridComponent') usersRolesGridComponent: BaseGridComponent;
  @ViewChild('availableRightsGrid') availableRightsGrid: GridComponent;
  @ViewChild('assignedRightsGrid') assignedRightsGrid: GridComponent;
  @ViewChild('emptyRightsTemplate', { static: true }) emptyRightsTemplate: string;
  @ViewChild('editRoleDialog') editRoleDialog: DialogComponent;

  // General variables
  isResponsive: boolean = this.globals.isResponsive;

  // Variables for roles
  roles: GridComponent;
  rolesGridSettings: SetGridDataArgs;
  selectedRole: Role;
  editRoleDialogHeader: string = 'Edit Rights';

  // Variables for availableRightsGrid
  availableRightsGridSettings: GridModel;
  availableRightsGridCurrentSelection: object[];

  // Variables for assignedRightsGrid
  assignedRights: number[];
  assignedRightsGridSettings: GridModel;
  assignedRightsGridCurrentSelection: object[];

  toolbarClickHandler(args: any): void {
    if (args.item.id === 'EditRights') {
      this.editRoleDialog.show();
    }
  }

  customizeAvailableRightsBackgroundColor(args: any): void {
    if (!args.row || !args.data || !this.assignedRights) return;

    args.row.classList.remove('assigned-right');

    if (this.assignedRights.includes(args.data.Id)) {
      args.row.classList.add('assigned-right');
    }
  }

  dialogClose(): void {
    this.editRoleDialog.hide();
  }

  refreshGrids(): void {
    this.gridService.refreshData();
    this.availableRightsGrid.refresh();
    this.assignedRightsGrid.refresh();
  }

  onRowSelected(rowArgs: RowSelectEventArgs): void {
    const rowData = rowArgs?.data as Role;

    if (rowData?.Id) {
      this.gridService.grid()?.toolbarModule.enableItems(['EditRights'], true);
      this.assignedRightsGridSettings.dataSource = this.rolesService.getRoleRightsQuery(rowData.Id);
      this.selectedRole = rowData;
      this.editRoleDialogHeader = `Edit Rights for: ${rowData.RoleName}`;

      this.rolesService.getRoleRights(rowData.Id)
        .then(assignedRights => {
          this.assignedRights = assignedRights;

          if (this.availableRightsGrid) {
            this.availableRightsGrid.getRows().forEach(row => {
              const rowData = this.availableRightsGrid.getRowInfo(row).rowData;
              this.customizeAvailableRightsBackgroundColor({ row, data: rowData });
            });
          }
        })
        .catch(error => {
          this.handleError(error, {
            context: 'RolesGridComponent.onRowSelected',
            userMessage: this.rolesService.getErrors().LOAD_RIGHTS.message,
            severity: ErrorSeverity.Error
          });
        });

      this.availableRightsGrid.getRows().forEach(row => {
        const rowData = this.availableRightsGrid.getRowInfo(row).rowData;
        this.customizeAvailableRightsBackgroundColor({ row, data: rowData });
      });
      this.availableRightsGrid.refresh();
    }
  }

  beforeOpenEditRightsDialog(args: any) {
    args.maxHeight = '85vh';
    this.editRoleDialog.width = '75vw';
  }

  addSelectedRoleToAssignedRightsGrid() {
    this.availableRightsGridCurrentSelection = this.availableRightsGrid.getSelectedRecords();

    const selectedRoleId = this.selectedRole.Id;
    if (!selectedRoleId) throw new Error('Missing selected role ID to add assigned rights.');
    const currentData = this.assignedRightsGrid.getCurrentViewRecords();

    const newRecords = this.availableRightsGridCurrentSelection.map((selected: any) => ({
      RoleId: selectedRoleId,
      RightId: selected.Id,
      Friendly_name: selected.FriendlyName
    }));

    this.assignedRightsGrid.setProperties({
      dataSource: [...currentData, ...newRecords]
    });

    this.availableRightsGrid.clearSelection();
    this.availableRightsGrid.getRows().forEach(row => {
      const rowData = this.availableRightsGrid.getRowInfo(row).rowData;
      this.customizeAvailableRightsBackgroundColor({ row, data: rowData });
    });
    this.assignedRightsGrid.refresh();
    this.availableRightsGrid.refresh();
  }

  removeSelectedRoleToAssignedRightsGrid() {
    this.assignedRightsGridCurrentSelection = this.assignedRightsGrid.getSelectedRecords();
    const currentData = this.assignedRightsGrid.getCurrentViewRecords();

    const idsToRemove = new Set(this.assignedRightsGridCurrentSelection.map((right: any) => right.RightId));
    const updatedData = currentData.filter((right: any) => !idsToRemove.has(right.RightId));

    this.assignedRightsGrid.setProperties({
      dataSource: updatedData
    });

    this.assignedRightsGrid.clearSelection();
    this.availableRightsGrid.getRows().forEach(row => {
      const rowData = this.availableRightsGrid.getRowInfo(row).rowData;
      this.customizeAvailableRightsBackgroundColor({ row, data: rowData });
    });
    this.assignedRightsGrid.refresh();
    this.availableRightsGrid.refresh();
  }

  async submitRoleChanges() {
    const roleId = this.selectedRole.Id;
    if (!roleId) throw new Error('Missing selected role ID to update rights.');

    const payload: XrefRolesRight[] = this.assignedRightsGrid.getCurrentViewRecords().map((right: any) => ({ RightId: right.RightId }));

    try {
      await this.rolesService.updateRoleRights(roleId, payload);
      await this.gridService.refreshData();
    } catch (error) {
      this.handleError(error, {
        context: 'RolesGridComponent.submitRoleChanges',
        userMessage: this.rolesService.getErrors().UPDATE_RIGHTS.message,
        severity: ErrorSeverity.Error
      });
    } finally {
      this.editRoleDialog.hide();
      this.gridService.updateEditState(undefined);
    }
  }
}
