<div class="row">
  <div class="form-item col-12 col-sm-6">
    <ejs-datepicker [(ngModel)]="formData.DateSigned" placeholder="Date Signed" floatLabelType="Auto"/>
  </div>
  <div class="form-item col-12 col-sm-6">
    <ejs-numerictextbox
      placeholder="Amount"
      [(ngModel)]="formData.Amount"
      [value]="formData.Amount"
      floatLabelType="Auto"
    />
  </div>
  <div class="form-item col-12 col-sm-6">
    <ejs-dropdownlist #authTypeDropdown
      id='authTypeDD'
      allowFiltering="true"
      placeholder="Select AuthType"
      [dataSource]='authTypeList'
      [(ngModel)]="formData.AuthType"
      floatLabelType="Auto"
    />
  </div>
  <div class="form-item col-12 col-sm-6">
    <div>
      <!-- Only show provider dropdown for Provider Funding Authorization -->
      <div class="form-group" *ngIf="formData.AuthType === 'Provider Funding Authorization'">
        <label>Select Provider</label>
        <ejs-dropdownlist
          [dataSource]="providerList"
          [(ngModel)]="formData.ProviderId"
          [fields]="providerFields"
          [value]="formData.ProviderId"
          placeholder="Select Provider">
        </ejs-dropdownlist>
      </div>
    </div>
  </div>
  <div class="form-item col-12">
    <label>Notes</label>
    <ejs-richtexteditor
      placeholder="Notes"
      [(ngModel)]="formData.Notes"
      [height]="rteConfig.height"
      [toolbarSettings]="rteConfig.toolbarSettings"
      [format]="rteConfig.format">
    </ejs-richtexteditor>
  </div>
  <div class="col-2 form-submit">
    <button ejs-button class="e-primary e-flat" ejs-button type="submit" isPrimary="true" (click)="onSubmit()">Submit</button>
  </div>
</div>
