<div id="contacts-tab">
  
  <grid-template #contactsGrid [settings]="contactsGridSettings" [name]="'Contacts'" [useRoundedEdges]="true"></grid-template>


  <ejs-dialog #contactDialog
    [visible]="false"
    [isModal]="true"
    [showCloseIcon]="true"
    [closeOnEscape]="true"
    [buttons]="contactDialogButtons"
    (close)="contactDialog.hide()"
    
    >
    <contact-form-component 
      #contactFormComponent 
      [isLawFirm]="isLawFirm" 
      [selectedAccountId]="selectedAccountId" 
      [contact]="selectedContact" 
      [displayButtons]="false" 
      (formSubmitted)="contactDialog.hide();" />
  </ejs-dialog>

</div>