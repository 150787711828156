<div id="file-hub">
    <ng-container *ngIf="loading(); else ready">
        <div class="loading-container d-flex justify-content-center align-items-center">
            <loading-overlay [loading]="loading" />
        </div>
    </ng-container>

    <ng-template #ready>
        <hub-header />
        <hub-tabs />
    </ng-template>
</div>
