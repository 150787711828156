import { Component, Input, signal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentBase } from '@app/core/base/component.base';
import { LoadingSpinnerComponent } from '@app/shared/components/loading-spinner/loading-spinner.component';
import { BalanceStatementService } from '../../../services/balance-statement.service';
import { BalanceStatement } from '../../../models/balance-statement.model';

const ERRORS = {
  LOAD_FAILED: {
    message: 'Unable to load balance statement',
    technical: 'Failed to load balance statement data'
  }
} as const;

@Component({
  selector: 'app-view-balance-statement',
  standalone: true,
  imports: [CommonModule, LoadingSpinnerComponent],
  template: `
    <div class="view-balance-statement">
      <loading-spinner [loading]="loading()" />

      <ng-container *ngIf="!loading() && data()">
        <div class="statement-header">
          <h2>Balance Statement</h2>
          <div class="case-info">
            <p>Case File: {{data()?.caseFileNumber ?? 'N/A'}}</p>
            <p>Generated: {{(data()?.generatedDate | date) ?? 'N/A'}}</p>
          </div>
        </div>
      </ng-container>

      <div *ngIf="!loading() && !data()" class="no-data">
        No balance statement available
      </div>
    </div>
  `,
  styles: [`
    .view-balance-statement {
      padding: 1rem;
    }
  `]
})
export class ViewBalanceStatementComponent extends ComponentBase {
  @Input() caseFileId: number;
  loading: WritableSignal<boolean> = signal(true);
  data: WritableSignal<BalanceStatement | null> = signal(null);

  constructor(private balanceService: BalanceStatementService) {
    super();
  }

  async ngOnInit() {
    try {
      const result = await this.balanceService.getBalanceStatement(this.caseFileId);
      this.data.set(result);
    } catch (error) {
      this.handleError(error, {
        context: 'ViewBalanceStatement.ngOnInit',
        userMessage: ERRORS.LOAD_FAILED.message
      });
    } finally {
      this.loading.set(false);
    }
  }
}
