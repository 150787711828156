import { Injectable } from '@angular/core';

interface CachedItem<T> {
  data: T;
  timestamp: number;
}

@Injectable({
  providedIn: 'root',
})
export class DataCacheService {
  private cache: Map<string, CachedItem<any>> = new Map();
  
  // Default TTL: 5 minutes
  private defaultTTL: number = 300000; 
  
  // Reference data TTL: 15 minutes for more stable data
  private referenceTTL: number = 900000;
  
  // Frequently changing data TTL: 1 minute
  private shortTTL: number = 60000;

  constructor() {
    console.log('DataCacheService initialized');
  }

  /**
   * Store data in the cache with optional custom TTL
   * @param key The cache key
   * @param data The data to cache
   * @param customTTL Optional custom TTL in milliseconds
   */
  set<T>(key: string, data: T, customTTL?: number): void {
    console.log(`Caching data for key: ${key}`);
    this.cache.set(key, {
      data,
      timestamp: Date.now(),
    });
  }

  /**
   * Retrieve data from the cache using appropriate TTL based on key prefix
   * @param key The cache key
   * @returns The cached data or null if not found/expired
   */
  get<T>(key: string): T | null {
    if (!this.cache.has(key)) {
      console.log(`Cache miss for key: ${key}`);
      return null;
    }

    const item = this.cache.get(key);
    if (!item) return null;

    // Select TTL based on key prefix
    let ttl = this.defaultTTL;
    
    if (key.startsWith('reference:')) {
      ttl = this.referenceTTL;
    } else if (key.startsWith('changing:')) {
      ttl = this.shortTTL;
    }

    // Check if item has expired
    if (Date.now() - item.timestamp > ttl) {
      console.log(`Cache expired for key: ${key}`);
      this.cache.delete(key);
      return null;
    }

    console.log(`Cache hit for key: ${key}`);
    return item.data as T;
  }

  /**
   * Check if the cache has a valid entry for a key
   * @param key The cache key
   * @returns True if valid cache entry exists
   */
  has(key: string): boolean {
    if (!this.cache.has(key)) return false;

    const item = this.cache.get(key);
    if (!item) return false;

    return Date.now() - item.timestamp <= this.defaultTTL;
  }

  /**
   * Clear a specific cache entry
   * @param key The cache key to clear
   */
  clear(key: string): void {
    this.cache.delete(key);
  }

  /**
   * Clear all cache entries
   */
  clearAll(): void {
    this.cache.clear();
  }
}
