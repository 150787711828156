// Angular
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ReactiveFormsModule, FormControl, FormGroup } from '@angular/forms';
import { CommonModule } from '@angular/common';

// 3rd Party
import { Query, ReturnOption } from '@syncfusion/ej2-data';
import { DropDownListModule, MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
import { CheckBoxModule } from '@syncfusion/ej2-angular-buttons';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { BulkEditModel } from '@models/components/bulk-edit-case-file.model';
import { GlobalsService } from '@services/globals/globals.service';
import { ApiService } from '@services/api/api.service';

@Component({
  selector: 'bulk-edit-case-files-form',
  standalone: true,
  imports: [
    ReactiveFormsModule, 
    CommonModule,
    DropDownListModule, 
    MultiSelectModule,
    CheckBoxModule
  ],
  templateUrl: './bulk-edit-case-file.component.html',
  styleUrl: './bulk-edit-case-file.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class BulkEditCaseFilesForm implements OnInit {

  constructor(
    private globals: GlobalsService,
    private api: ApiService
  ) {
    this.users = this.api.getOdata(APIEndpoints.Users);
    this.api.getOdata(APIEndpoints.Users).executeQuery(new Query().select('Id,Name')).then((e: ReturnOption) => { this.clients = e.result as object[]}).catch((e) => true);
    this.lawFirms = this.api.getOdata(APIEndpoints.Lawfirms);
  }

  @Input() showFormButtons: Boolean = false;
  @Input() caseFileIds: number[]

  users: any;
  clients: any;
  usersFields: Object = { text: 'Name', value: 'Id' };
  languages: string[] = this.globals.languages;
  lawFirms: any;
  lawFirmsQuery = new Query().select('Id,Name').where('Active', 'equal', true);
  lawFirmsFields: Object = { text: 'Name', value: 'Id' };
  attorneysQuery = new Query().select('Id,Name,Title').where('Title', 'contains', 'Engineer');
  caseFiles: any;
  caseFilesQuery = new Query().select('Id,FileNumber');
  caseFilesFields: Object = { text: 'FileNumber', value: 'Id' };
  treeDataFields: Object = { text: 'name', value: 'workflowId' };
  initialTimeFrame: string[] = this.globals.caseFileTimeFrame;
  currentStatus: object[] = this.globals.caseFileCurrentStatus;
  caseManagersQuery: Query = new Query().select('Id,Name,Title').where('Title', 'contains', 'cog');
  marketManagersQuery: Query = new Query().select('Id,Name,Title').where('Title', 'contains', 'cog');
  statusersQuery: Query = new Query().select('Id,Name,Title').where('Title', 'contains', 'Software');
  genders: string[] = this.globals.genders;
  caseTypes: string[] = this.globals.caseFilesCaseTypes;
  pAndL: object[] = this.globals.listPAndL;
  referrals: any = this.globals.referralSources;
  referralsFields: Object = { groupBy: 'group', text: 'label', value: 'value' };
  keyValueFields: Object = { text: 'key', value: 'value' };
  fileStatus: object[] = this.globals.caseFileStatuses;
  fileGroups: object[] = this.globals.caseFilesFileGroups;
  fileRating: object[] = this.globals.caseFilesRatings;
  fileRatingFields: object = { text: 'Value', value: 'Value' }
  bulkEditCaseFilesForm: FormGroup = new FormGroup({
    IsActive: new FormControl<BulkEditModel['IsActive'] | null>(null),
    CaseManager: new FormControl<BulkEditModel['CaseManager'] | null>(null),
    CaseType: new FormControl<BulkEditModel['CaseType'] | null>(null),
    ExcludeFromBalanceDue: new FormControl<BulkEditModel['ExcludeFromBalanceDue'] | null>(null),
    FileGroup: new FormControl<BulkEditModel['FileGroup'] | null>(null),
    FileRating: new FormControl<BulkEditModel['FileRating'] | null>(null),
    FileStatus: new FormControl<BulkEditModel['FileStatus'] | null>(null),
    InTreatment: new FormControl<BulkEditModel['InTreatment'] | null>(null),
    MarketManager: new FormControl<BulkEditModel['MarketManager'] | null>(null),
    PAndL: new FormControl<BulkEditModel['PAndL'] | null>(null),
    ReferralSource: new FormControl<BulkEditModel['ReferralSource'] | null>(null),
    ReferringPhysician: new FormControl<BulkEditModel['ReferringPhysician'] | null>(null),
    Statuser: new FormControl<BulkEditModel['Statuser'] | null>(null),
    StatusingGroup: new FormControl<BulkEditModel['StatusingGroup'] | null>(null),
    Status: new FormControl<BulkEditModel['Status'] | null>(null)
  });

  ngOnInit() {

  }
}
