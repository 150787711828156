// Angular
import { Component, ViewEncapsulation, ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';

// 3rd party 
import awsExports from '@src/aws-exports';
import { Amplify } from 'aws-amplify';
import { Hub } from 'aws-amplify/utils';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { getCurrentUser, rememberDevice } from '@aws-amplify/auth';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { CheckBox } from '@syncfusion/ej2-buttons';
import { ButtonModule, CheckBoxModule } from '@syncfusion/ej2-angular-buttons';

// Internal
import { UserPreferencesService } from '@services/user/user-preferences.service';
import { GlobalsService } from '@services/globals/globals.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { SvgComponent } from '@ui/svg/svg.component';

@Component({
  selector: 'app-amplify-authenticator',
  standalone: true,
  imports: [
    CommonModule, 
    AmplifyAuthenticatorModule, 
    SvgComponent, 
    ButtonModule, 
    CheckBoxModule
  ],
  templateUrl: './amplify-authenticator.component.html',
  styleUrl: './amplify-authenticator.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class AmplifyAuthenticatorComponent implements OnInit {

  constructor(
    private user: UserPreferencesService,
    private toast: ToastMessageService,
    public authenticator: AuthenticatorService, 
    public router: Router,
    public globals: GlobalsService
  ) {
    Amplify.configure(awsExports);

    Hub.listen('auth', (data) => {
      const { payload } = data;
      this.onAuthEvent(payload);
    });
  }
  public canRemember: boolean;

  @ViewChild('rememberCheck') rememberCheck: CheckBox;
  logo: string = 'assets/logos/casecompass-logo_solid.svg';
  formFields = { setupTotp: { QR: { totpIssuer: "Case Compass MFA" } } };
  currentDate: Date = new Date();

  ngOnInit(): void {
    this.onAuthChange(false);
  }

  // Update observables on login and logout
  onAuthEvent(payload: any) {
    if (payload.event === 'signedIn') {
      this.user.isLoggedIn = true;
      this.handleRememberDevice();
      this.router.navigate(['/dashboard']);
    } else if (payload.event === 'signedOut') {
      this.user.isLoggedIn = false;
      this.router.navigate(['/']);
    }
  }

  // Show message when clicked to verify
  handleCheck() {
    if (this.rememberCheck?.checked === true) {
      this.toast.showSuccess('Device Will Be Remembered For 30 Days');
    }
  }

  // Call method from @aws-amplify to remember device
  async handleRememberDevice() {

    if (this.rememberCheck?.checked === true) {
      // localStorage.setItem("rememberedDate", new Date().toISOString());
      try {
        await rememberDevice();
      } catch (error) {
        this.toast.showError(`There was an Error remembering your device${JSON.stringify(error)}`);
      }
    }
  }

  // Detect changes to authorization status from Amplify
  onAuthChange(showLog: boolean) {
    this.authenticator.subscribe((authState) => {

      if (authState.route === 'confirmSignIn' && showLog === true) {
        console.log(authState);
      }
    });
  }
}
