
// Angular
import { computed, effect, Injectable, signal } from '@angular/core';

// 3rd Party
import { Query } from '@syncfusion/ej2-data';

// Models
import { APIEndpoints } from '@models/api/Endpoints';
import { XrefCommentCaseFile } from '@models/data-contracts';

// Services
import { ApiService } from '@services/api/api.service';
import { CommentsService } from '@shared/features/comments/services/comments.service';

@Injectable({
  providedIn: 'root'
})
export class CommentsGridService {

   // State
   state: any = {
    Loading: {
     Comments: signal<boolean>(true),
    },
    Data: {
      Comments: signal<Comment[] | undefined>(undefined),
    },
    Errors: signal<Record<string, any>>({})
  };

  // Computed Signals
  readonly workItemState = computed(() => ({ 
    isLoading: this.loading.WorkItems,
    data: this.data.WorkItems,
    error: this.errors()['Comments']
  }));

  // Getters
  get loading() { return this.state.Loading; };
  get data() { return this.state.Data; };
  get errors() { return this.state.Errors; };

  constructor(
    private api: ApiService,
    private commentsService: CommentsService
  ) { }

  async fetchComments(caseFileId?: number) {
    
    try {

      this.loading.Comments.set(true);
      const endpoint = APIEndpoints.XrefCommentCaseFiles;
      const query = new Query().expand(['Comment($expand=Type)']).where('CaseFileId', 'equal', caseFileId).take(25);

      const comments = await this.api.getOdata(endpoint).executeQuery(query).then((res: any) => {
        return res.result.map((xrefComment: XrefCommentCaseFile) => {
          return {  
            Id: xrefComment.Comment?.Id,
            Type: xrefComment.Comment?.Type?.Description,
            Comment: xrefComment.Comment?.CommentText,
            Date: xrefComment.Comment?.CreatedAt,
          };
        }); 
      });

      this.loading.Comments.set(false);
      this.data.Comments.set(comments);
      return this.data.Comments();

    } catch (error) {
      console.error('RecentActivityService: Error fetching comments', error);
      return error;

    } finally {
      this.loading.Comments.set(false);
      return;

    }
  }

  async submitCommentForm(formValue: any, caseFileId: number) {

    try {
      const formId = formValue.Id;
      const result = formId ? await this.commentsService.updateComment(formId, formValue) : await this.commentsService.addComment(formValue);

      // Only update Xref if new comment
      if (result && !formId) {
        await this.api.fetchRequest(`odata${APIEndpoints.XrefCommentCaseFiles}`, 'POST', { CommentId: result.Id, CaseFileId: caseFileId });
      }

      return result;

    } catch (error) {
      console.error('Error creating note:', error);
      return error;

    } finally {
      this.loading.Comments.set(false);
      return;
    }
  }

  cancelCommentForm(form: any) {
    form.reset();
  }
}
