<div id="user-profile">

    <ejs-tab id="_ejs-component-userProfileTabs"
        [items]="profileTabs" 
        [enablePersistence]="true" 
        [animation]="animationSettings"
        (selecting)="onTabSelecting($event)" 
        (selected)="onTabSelected($event)" />

    <ng-template #generalSettings let-data>
        <div class="profile-tab">
            <profile-general />
        </div>
    </ng-template>

    <ng-template #appearanceSettings let-data>
        <div class="profile-tab">
            <profile-appearance />
        </div>
    </ng-template>

    <div class="save d-flex justify-content-center">
        <button class="e-control e-btn" (click)="handleSaveUserPreferencesClick()">Save All User Settings</button>
    </div>
</div>