import { Component, Inject, OnInit, ViewEncapsulation, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule, MatPaginator } from '@angular/material/paginator';
import { MatSortModule, MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { provideAnimations } from '@angular/platform-browser/animations';
import { ApiService } from '@services/api/api.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { FeeScheduleService } from '@components/forms/fee-schedule-form/data/fee-schedule.service';
import { APIEndpoints } from '@models/api/Endpoints';
import { BehaviorSubject, Observable, map, Subscription, combineLatest, startWith } from 'rxjs';
import { FeeSchedule, ProcedureCode, XrefFeeScheduleProcedureCode, Provider as ApiProvider, XrefFeeScheduleProcedureCodeUpdateDTO, XrefAddressContactCreateDTO, XrefFeeScheduleProcedureCodeCreateDTO } from '@models/data-contracts';
import { CustomAssignmentsEditorComponent } from '@grids/custom-assignments-editor/custom-assignments-editor.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { BaseFormMaterialComponent } from './base-form-material.component';

interface Provider extends ApiProvider {
  Id: number;
  Name: string;
  FeeScheduleId?: number;
}

export interface FeeScheduleDialogData {
  title: string;
  submitLabel: string;
  formData?: FeeSchedule;
  id?: number;
  providers: Provider[];
  cptCodes: ProcedureCode[];
}

interface CptCodeTableRow {
  id: number | null;
  code: number | null;
  description: string;
  billedAmount: number;
  frontEndRate: number;
  backEndRate: number;
  splitInvoice: boolean;
}

@Component({
  selector: 'app-fee-schedule-dialog',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    ScrollingModule,
    CustomAssignmentsEditorComponent,
    OverlayModule
  ],
  providers: [
    provideAnimations()
  ],
  templateUrl: './fee-schedule-dialog.component.html',
  styleUrls: ['./fee-schedule-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FeeScheduleDialogComponent extends BaseFormMaterialComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['code', 'billedAmount', 'frontEndRate', 'backEndRate', 'splitInvoice', 'actions'];
  dataSource = new MatTableDataSource<CptCodeTableRow>([]);

  // Form Groups
  override formGroup: FormGroup;
  private initialSelectedProviders: Provider[] = [];
  private formSubscription: Subscription;
  override hasChanges = false;

  // Data Streams
  private providersSubject = new BehaviorSubject<Provider[]>([]);
  providers$ = this.providersSubject.asObservable();
  
  private selectedProvidersSubject = new BehaviorSubject<Provider[]>([]);
  selectedProviders$ = this.selectedProvidersSubject.asObservable();
  
  availableProviders$: Observable<Provider[]>;
  
  private cptCodesSubject = new BehaviorSubject<ProcedureCode[]>([]);
  cptCodes$ = this.cptCodesSubject.asObservable();
  availableCptCodes$: Observable<ProcedureCode[]>;

  providerSearchControl = new FormControl('');
  filteredAvailableProviders$: Observable<Provider[]>;
  private searchSubscription: Subscription;

  constructor(
    private dialogRef: MatDialogRef<FeeScheduleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FeeScheduleDialogData,
    private fb: FormBuilder,
    private api: ApiService,
    private toast: ToastMessageService,
    private service: FeeScheduleService
  ) {
    super();
    this.initializeForms();
    this.initializeData();
    this.setupProviderStreams();
    this.setupCptCodeStreams();
    this.setupFormChangeTracking();
    this.setupProviderSearch();
  }

  private initializeData(): void {
    // Initialize the subjects with the data from the grid
    const providers = this.data?.providers || [];
    const cptCodes = this.data?.cptCodes || [];
    
    this.providersSubject.next(providers);
    this.cptCodesSubject.next(cptCodes);
    
    // If editing, set selected providers
    if (this.data.formData?.Providers) {
      const selectedProviders = this.data.formData.Providers.map(p => ({
        ...p,
        Id: p.Id as number,
        Name: p.Name as string
      })) as Provider[];
      this.selectedProvidersSubject.next(selectedProviders);
      this.initialSelectedProviders = [...selectedProviders];
    } else {
      this.selectedProvidersSubject.next([]);
      this.initialSelectedProviders = [];
    }
  }

  private setupProviderStreams() {
    // Available providers are those not in selected providers
    this.availableProviders$ = this.providers$.pipe(
      map(allProviders => {
        if (!allProviders) return [];
        const selectedIds = new Set((this.selectedProvidersSubject.value || []).map(p => p?.Id));
        return allProviders.filter(provider => provider && !selectedIds.has(provider.Id));
      })
    );
  }

  private setupCptCodeStreams(): void {
    // Available CPT codes are those not already selected in any row
    this.availableCptCodes$ = this.dataSource.connect().pipe(
      map(tableData => {
        // Get all selected codes
        const selectedCodes = new Set(tableData
          .map(row => row.code)
          .filter((code): code is number => code !== null && code !== undefined));

        // Return all CPT codes that aren't selected in any row
        return this.data.cptCodes.filter(code => {
          if (!code.Id) return false;
          return !selectedCodes.has(code.Id);
        });
      })
    );
  }

  private setupFormChangeTracking(): void {
    this.formSubscription = this.formGroup.valueChanges.subscribe(() => {
      this.checkForChanges();
    });

    // Track provider changes
    this.selectedProvidersSubject.subscribe(() => {
      this.checkForChanges();
    });
  }

  override checkForChanges(): void {
    if (!this.data.formData) {
      // For new records, just check if the form is valid
      this.hasChanges = this.isFormValid();
      return;
    }

    // Check for form changes
    const formChanged = JSON.stringify(this.initialFormValue) !== JSON.stringify(this.formGroup.value);
    
    // Check for provider changes
    const currentProviders = this.selectedProvidersSubject.value;
    const providersChanged = JSON.stringify(this.initialSelectedProviders) !== JSON.stringify(currentProviders);

    // Set hasChanges if either the form or providers have changed
    this.hasChanges = formChanged || providersChanged;
  }

  private setupProviderSearch(): void {
    // Combine the available providers stream with the search input
    this.filteredAvailableProviders$ = combineLatest([
      this.availableProviders$,
      this.providerSearchControl.valueChanges.pipe(startWith(''))
    ]).pipe(
      map(([providers, searchTerm]) => {
        if (!searchTerm || !providers) {
          return providers || [];
        }
        const search = searchTerm.toLowerCase().trim();
        return providers.filter(provider => 
          provider?.Name?.toLowerCase().includes(search)
        );
      })
    );
  }

  ngOnInit() {
    if (this.data) {
      let f = this.data.formData as FeeSchedule;
      this.loadExistingData(f);
    }
  }

  ngOnDestroy() {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
    if (this.dataSource) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  private initializeForms(): void {
    this.formGroup = this.fb.group({
      Id: [0],
      Name: ['', [Validators.required]],
      ProcedureCodes: this.fb.array([])
    });

    // Add initial empty entry for new fee schedule
    if (!this.data.formData) {
      this.addProcedureCodeEntry();
    }
  }

  private loadExistingData(feeSchedule: FeeSchedule): void {
    this.formGroup.patchValue({
      Id: feeSchedule.Id,
      Name: feeSchedule.Name
    });

    // Set selected providers
    if (feeSchedule.Providers && feeSchedule.Providers.length > 0) {
      const selectedProviders = feeSchedule.Providers.map(p => ({
        ...p,
        Id: p.Id as number,
        Name: p.Name as string
      })) as Provider[];
      this.selectedProvidersSubject.next(selectedProviders);
      this.initialSelectedProviders = [...selectedProviders];
    } else {
      this.selectedProvidersSubject.next([]);
      this.initialSelectedProviders = [];
    }

    if (feeSchedule.XrefFeeScheduleProcedureCodes) {
      this.clearProcedureCodes();
      feeSchedule.XrefFeeScheduleProcedureCodes.forEach(cpt => {
        this.addProcedureCodeEntry(cpt);
      });
    }

    // Store initial form value for change detection
    this.initialFormValue = this.formGroup.value;
    this.updateTableFromFormArray();
  }

  private createProcedureCodeFormGroup(code?: XrefFeeScheduleProcedureCode): FormGroup {
    return this.fb.group({
      Id: [code?.Id ?? null],
      Code: [code?.ProcedureCodeId ?? null, [Validators.required]],
      Description: [code?.ProcedureCode?.Description ?? ''],
      IsMedicare: [code?.ProcedureCode?.McptId ? true : false],
      BilledAmount: [code?.BilledAmount ?? 0, [Validators.required, Validators.min(0)]],
      SplitInvoice: [code?.SplitInvoice ?? false],
      FrontEndReimbursmentRate: [
        code?.FrontEndReimbursmentRate ?? 0,
        [Validators.required, Validators.min(0), Validators.max(100)]
      ],
      BackEndReimbursmentRate: [
        code?.BackEndReimbursmentRate ?? 0,
        [Validators.min(0), Validators.max(100)]
      ]
    });
  }

  get procedureCodeEntries(): FormArray {
    return this.formGroup.get('ProcedureCodes') as FormArray;
  }

  addProcedureCodeEntry(code?: XrefFeeScheduleProcedureCode): void {
    const newGroup = this.createProcedureCodeFormGroup(code);
    this.procedureCodeEntries.push(newGroup);
    this.updateTableFromFormArray();
  }

  removeProcedureCodeEntry(index: number): void {
    this.procedureCodeEntries.removeAt(index);
    this.updateTableFromFormArray();
  }

  clearProcedureCodes(): void {
    while (this.procedureCodeEntries.length) {
      this.procedureCodeEntries.removeAt(0);
    }
    this.updateTableFromFormArray();
  }

  onSplitInvoiceChange(event: { checked: boolean }, index: number): void {
    const control = this.procedureCodeEntries.at(index);
    if (!event.checked) {
      control.patchValue({ BackEndReimbursmentRate: 0 });
    }
    
    // Update validators
    const backEndControl = control.get('BackEndReimbursmentRate');
    if (event.checked) {
      backEndControl?.addValidators([Validators.required]);
    } else {
      backEndControl?.removeValidators([Validators.required]);
    }
    backEndControl?.updateValueAndValidity();
  }

  onProviderSelectionChange(provider: Provider, isAdding: boolean): void {
    const currentSelected = this.selectedProvidersSubject.value;
    
    if (isAdding) {
      // Add to selected list if not already there
      if (!currentSelected.some(p => p.Id === provider.Id)) {
        this.selectedProvidersSubject.next([...currentSelected, provider]);
        // Force a refresh of the providers stream to update availableProviders$
        this.providersSubject.next(this.providersSubject.value);
      }
    } else {
      // Remove from selected list
      this.selectedProvidersSubject.next(currentSelected.filter(p => p.Id !== provider.Id));
      // Force a refresh of the providers stream to update availableProviders$
      this.providersSubject.next(this.providersSubject.value);
    }
  }

  override isFormValid(): boolean {
    return this.formGroup.valid && this.procedureCodeEntries.length > 0;
  }

  private prepareCptCodeChanges() {
    const entries = this.procedureCodeEntries.value;
    const existingIds = new Set((this.data.formData?.XrefFeeScheduleProcedureCodes || []).map(cpt => cpt.Id));
    
    return {
      cptCodeAdd: entries.filter((entry: any) => !entry.Id).map((entry: any) => ({
        ProcedureCodeId: entry.Code,
        FeeScheduleId: this.data.formData?.Id,
        BilledAmount: entry.BilledAmount,
        SplitInvoice: entry.SplitInvoice,
        FrontEndReimbursmentRate: entry.FrontEndReimbursmentRate,
        BackEndReimbursmentRate: entry.BackEndReimbursmentRate
      })),
      cptCodeChanges: entries.filter((entry: any) => entry.Id).map((entry: any) => ({
        Id: entry.Id,
        FeeScheduleId: this.data.formData?.Id,
        ProcedureCodeId: entry.Code,
        BilledAmount: entry.BilledAmount,
        SplitInvoice: entry.SplitInvoice,
        FrontEndReimbursmentRate: entry.FrontEndReimbursmentRate,
        BackEndReimbursmentRate: entry.BackEndReimbursmentRate
      })),
      cptCodeDelete: Array.from(existingIds).filter(id => 
        !entries.some((entry: any) => entry.Id === id)
      ).map(id => ({ Id: id }))
    };
  }

  private compareProviders() {
    const existingProviderIds = new Set((this.data.formData?.Providers || []).map((p: any) => p.Id));
    const currentProviderIds = new Set(this.selectedProvidersSubject.value.map(p => p.Id));
    
    return {
      providerChanges: [
        // Providers that were removed
        ...Array.from(existingProviderIds)
          .filter(id => !currentProviderIds.has(id))
          .map(id => ({ Id: id, FeeScheduleId: null })),
        
        // Providers that were added
        ...Array.from(currentProviderIds)
          .filter(id => !existingProviderIds.has(id))
          .map(id => ({ Id: id, FeeScheduleId: this.data.formData?.Id }))
      ],
      providerAdd: [],
      providerDelete: []
    };
  }

  async submit(): Promise<void> {
    if (!this.canSubmit()) {
      this.toast.showError('Please fill in all required fields');
      return;
    }

    try {
      const formData = {
        ...this.formGroup.value,
        Providers: this.selectedProvidersSubject.value
      };

      if (this.data.formData) {
        await this.service.updateFeeSchedule(
          formData,
          this.prepareCptCodeChanges(),
          this.compareProviders()
        );
      } else {
        await this.service.createFeeSchedule(
          formData,
          this.formGroup,
          this.selectedProvidersSubject.value
        );
      }
      
      this.dialogRef.close(true);
    } catch (error) {
      console.error('Error saving fee schedule:', error);
      this.toast.showError('Error saving fee schedule');
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

  addProcedureCode(): void {
    this.addProcedureCodeEntry();
  }

  removeProcedureCode(index: number): void {
    this.removeProcedureCodeEntry(index);
  }

  override canSubmit(): boolean {
    if (this.data.formData) {
      // Editing: require changes AND valid form
      return this.hasChanges && this.isFormValid();
    }
    // Creating: just require valid form
    return this.isFormValid();
  }

  private initializeTableDataSource(): void {
    this.dataSource = new MatTableDataSource<CptCodeTableRow>([]);
  }

  private updateTableFromFormArray(): void {
    const tableData: CptCodeTableRow[] = this.procedureCodeEntries.controls.map(control => {
      const group = control as FormGroup;
      return {
        id: group.get('Id')?.value,
        code: group.get('Code')?.value,
        description: group.get('Description')?.value || '',
        billedAmount: group.get('BilledAmount')?.value || 0,
        frontEndRate: group.get('FrontEndReimbursmentRate')?.value || 0,
        backEndRate: group.get('BackEndReimbursmentRate')?.value || 0,
        splitInvoice: group.get('SplitInvoice')?.value || false
      };
    });
    this.dataSource.data = tableData;
    // Force refresh of available CPT codes
    this.setupCptCodeStreams();
  }

  updateFormArrayFromTable(): void {
    // Get current form array length
    const currentLength = this.procedureCodeEntries.length;
    const newData = this.dataSource.data;

    // Update or add form groups as needed
    newData.forEach((row, index) => {
      if (index < currentLength) {
        // Update existing form group
        const group = this.procedureCodeEntries.at(index) as FormGroup;
        group.patchValue({
          Id: row.id,
          Code: row.code,
          Description: row.description,
          BilledAmount: row.billedAmount,
          SplitInvoice: row.splitInvoice,
          FrontEndReimbursmentRate: row.frontEndRate,
          BackEndReimbursmentRate: row.backEndRate
        });
      } else {
        // Add new form group
        const formGroup = this.createProcedureCodeFormGroup({
          Id: row.id,
          ProcedureCodeId: row.code,
          BilledAmount: row.billedAmount,
          SplitInvoice: row.splitInvoice,
          FrontEndReimbursmentRate: row.frontEndRate,
          BackEndReimbursmentRate: row.backEndRate,
          ProcedureCode: {
            Description: row.description
          }
        } as XrefFeeScheduleProcedureCode);
        this.procedureCodeEntries.push(formGroup);
      }
    });

    // Remove extra form groups if needed
    while (this.procedureCodeEntries.length > newData.length) {
      this.procedureCodeEntries.removeAt(this.procedureCodeEntries.length - 1);
    }
  }

  private getCptCodeName(code: number | null): string | undefined {
    const cptCodes = this.data.cptCodes || [];
    const foundCode = cptCodes.find(c => c.Id === code);
    return foundCode?.Description || undefined;
  }

  onBilledAmountChange(event: Event, row: CptCodeTableRow): void {
    const value = (event.target as HTMLInputElement).valueAsNumber;
    row.billedAmount = isNaN(value) ? 0 : value;
    this.updateFormArrayFromTable();
  }

  onFrontEndRateChange(event: Event, row: CptCodeTableRow): void {
    const value = (event.target as HTMLInputElement).valueAsNumber;
    row.frontEndRate = isNaN(value) ? 0 : value;
    this.updateFormArrayFromTable();
  }

  onBackEndRateChange(event: Event, row: CptCodeTableRow): void {
    const value = (event.target as HTMLInputElement).valueAsNumber;
    row.backEndRate = isNaN(value) ? 0 : value;
    this.updateFormArrayFromTable();
  }

  onCptCodeChange(event: any, row: CptCodeTableRow): void {
    const selectedCode = this.data.cptCodes.find(code => code.Id === event.value);
    if (selectedCode) {
      row.code = selectedCode.Id ?? null;
      row.description = selectedCode.Description || '';
    }
    this.updateFormArrayFromTable();
  }

  getCptCodeDescription(codeId: number | null): string {
    if (codeId === null) return '';
    const code = this.data.cptCodes.find(c => c.Id === codeId);
    return code?.Description || '';
  }
}


