// Angular
import { Component, Input, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';

// 3rd Party
import { DataManager, Query } from '@syncfusion/ej2-data';
import { DialogAllModule } from '@syncfusion/ej2-angular-popups';
import { DropDownListModule, MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
import { TextBoxModule, NumericTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { ButtonModule, SwitchModule } from '@syncfusion/ej2-angular-buttons';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { ApiService } from '@app/shared/services/api/api.service';
import { CasefileInsurancePolicyFormComponent } from '@forms/casefile-insurance-policy-form/casefile-insurance-policy-form.component';
import { GlobalsService } from '@services/globals/globals.service';
import { Intake } from '@models/data-contracts';
import { ToastMessageService } from '@app/shared/services/toast-message/toast-message.service';
import { BodyPartSelectorComponent } from '@ui/body-part-selector/body-part-selector.component';
import { BaseFormComponent } from '@root/app/shared/components/base-form/base-form.component';
import { FormCrudService } from '@app/shared/services/forms/form-crud.service';

@Component({
  selector: 'casefile-intake-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    SwitchModule,
    DropDownListModule,
    MultiSelectModule,
    TextBoxModule,
    NumericTextBoxModule,
    BodyPartSelectorComponent,
    CasefileInsurancePolicyFormComponent,
    DialogAllModule,
  ],
  templateUrl: './casefile-intake-form.component.html',
  styleUrl: './casefile-intake-form.component.scss',
})
export class CasefileIntakeFormComponent extends BaseFormComponent {
  constructor(
    private api: ApiService,
    private toast: ToastMessageService,
    private globals: GlobalsService,
    private formCRUD: FormCrudService
  ) {
    super();
  }

  // Inputs
  @Input() caseFileId: number;
  @Input() existingIntake: Intake | null = null;
  @Input() existingInsurances: any[] = [];
  @Input() existingAccidentDetails: any[] = [];
  @Input() existingInjuries: any[] = [];

  // Data
  accidentInfo: DataManager;
  accidentInfoQuery: Query = new Query();
  accidentDetails: DataManager;
  accidentDetailsQuery: Query = new Query();
  settlementTimeframes: DataManager;
  intakeForm: FormGroup;
  insuranceFormResults: any;
  insurancePolicyFormVisible: boolean = false;
  toggleInsurancePolicyBtnText: string = 'Add Insurance Policy';
  toggleInsurancePolicyBtnIconClass: string = 'e-icons e-circle-add';

  override ngOnInit() {
    this.initializeForm();
    this.formGroup = this.intakeForm; // Set the formGroup property used by BaseFormComponent
    super.ngOnInit();
    this.getAccidentInfo();
    this.getAccidentDetails();
    this.getSettlementTimeframes();
  }

  override ngOnChanges(changes: SimpleChanges) {
    if (
      (changes['existingIntake'] && this.existingIntake) ||
      (changes['existingInsurances'] && this.existingInsurances) ||
      (changes['existingAccidentDetails'] && this.existingAccidentDetails) ||
      (changes['existingInjuries'] && this.existingInjuries)
    ) {
      this.initializeForm();
      this.formGroup = this.intakeForm;
    }
    super.ngOnChanges(changes);
  }

  initializeForm() {
    this.intakeForm = new FormGroup({
      Id: new FormControl<number | null>(this.existingIntake?.Id || null),
      CaseFileId: new FormControl<Intake['CaseFileId']>(this.caseFileId, [Validators.required]),
      AccidentInfo: new FormControl<Intake['AccidentInfo']>(this.existingIntake?.AccidentInfo || undefined),
      AccidentInfoId: new FormControl<Intake['AccidentInfoId']>(this.existingIntake?.AccidentInfoId || undefined, [Validators.required]),
      AccidentDetails: new FormControl<any[]>(this.existingAccidentDetails.map((x: any) => x.AccidentDetailId) || undefined),
      AnticipatedSettlementCode: new FormControl<any>((this.existingIntake as any)?.AnticipatedSettlementCode || '',[Validators.required]),
      IntakeComments: new FormControl<Intake['IntakeComments']>(this.existingIntake?.IntakeComments || '', [Validators.required,]),
      MedicalCosts: new FormControl<Intake['MedicalCosts']>(this.existingIntake?.MedicalCosts || undefined, [Validators.required]),
      PropertyDamage: new FormControl<Intake['PropertyDamage']>(this.existingIntake?.PropertyDamage || undefined, [Validators.required]),
      PreviousAssistance: new FormControl<Intake['PreviousAssistance']>(this.existingIntake?.PreviousAssistance || false, [Validators.required]),
      GapInTreatment: new FormControl<Intake['GapInTreatment']>(this.existingIntake?.GapInTreatment || false, [Validators.required]),
      ClearLiability: new FormControl<Intake['ClearLiability']>(this.existingIntake?.ClearLiability || false, [Validators.required]),
      PriorRepresentation: new FormControl<Intake['PriorRepresentation']>(this.existingIntake?.PriorRepresentation || false, [Validators.required]),
      LimitsSearch: new FormControl<Intake['LimitsSearch']>(this.existingIntake?.LimitsSearch || false, [Validators.required]),
      Insurance: new FormArray([]),
      Injury: new FormControl<any[]>([], []),
    });
  }

  // Form Data
  getAccidentInfo() {
    this.api
      .getOdata(APIEndpoints.AccidentInfo)
      .executeQuery(new Query())
      .then((response: any) => {
        this.accidentInfo = response.result;
      });
  }

  // Form Data
  getAccidentDetails() {
    this.api
      .getOdata(APIEndpoints.AccidentDetails)
      .executeQuery(new Query())
      .then((response: any) => {
      this.accidentDetails = response.result;
    });
  }

  // Form Data
  getSettlementTimeframes() {
    this.api.getOdata(APIEndpoints.ListSettlementTimeframe)
      .executeQuery(new Query())
      .then((response: any) => {
        this.settlementTimeframes = response.result;
    });
  }

  // Insurance Form Visibility
  toggleInsurancePolicy(event: any) {
    this.insurancePolicyFormVisible = !this.insurancePolicyFormVisible;

    if (this.insurancePolicyFormVisible === true) {
      this.toggleInsurancePolicyBtnText = 'Hide Insurance Policy Form';
      this.toggleInsurancePolicyBtnIconClass = 'e-icons e-circle-remove';
    } else {
      this.toggleInsurancePolicyBtnText = 'Add Insurance Policy';
      this.toggleInsurancePolicyBtnIconClass = 'e-icons e-circle-add';
    }
  }

  getInjuryData(injuries: any) {
    // Convert injuries object to array and format for the form
    const injuryArray: any[] = Object.entries(injuries).map(([_, value]: [string, any]) => ({
      BodyPartId: value.bodyPartId,
      Severity: value.severity,
    }));

    // Set the value directly instead of using patchValue
    this.intakeForm.get('Injury')?.setValue(injuryArray);
  }

  // Insurance Policy Results
  handleInsuranceResult(result: any) {
    if (result) {
      this.existingInsurances.push(result);
      this.toggleInsurancePolicy(null); // Close the dialog after successful submission
    }
  }

  override async onSubmit() {
    const submitType = this.existingIntake?.Id ? 'PATCH' : 'POST';

    // Outer try-catch for the main form submission
    try {
      // Mark all fields as touched to trigger validation
      this.globals.markControlsTouched(this.intakeForm);

      if (!this.intakeForm.valid) {
        this.toast.showError('Please fill in all required fields');
        return false;
      }

      const currentIntakeId = this.existingIntake?.Id;
      const endpoint = currentIntakeId ? `${APIEndpoints.Intakes}(${currentIntakeId})` : APIEndpoints.Intakes;

      // Submit main form
      const intakeResult = await this.formCRUD.submitForm(this.intakeForm, `odata${endpoint}`, submitType);

      if (!intakeResult?.Id) {
        this.toast.showError('Failed to save intake');
        return false;
      }

      // Nested try-catch for saving related XRef data
      try {
          await Promise.all([
            this.saveAccidentDetailsXRefs(intakeResult.Id),
            this.saveInjuryXRefs(intakeResult.Id)
          ]);

          // Show success message only after all operations succeed
          this.toast.showSuccess(`Intake successfully ${submitType === 'PATCH' ? 'updated' : 'created'}`);
          return true;

      } catch (xrefError) {
        this.toast.showError('Intake saved, but failed to save some related details. Please check and try again.');
        // Consider the operation partially successful as the main entity was saved
        return true;
      }

    } catch (mainError) {
      this.toast.showError(`Failed to ${submitType === 'PATCH' ? 'update' : 'create'} intake. Please try again.`);
      return false;
    }
  }

  // Manage Accident Details XRefs
  private async saveAccidentDetailsXRefs(intakeId: number) {
    const existingXRefIds = this.existingAccidentDetails.map(x => x.AccidentDetailId);
    const selectedDetailIds = this.intakeForm.value.AccidentDetails || [];

    const detailsToAdd = selectedDetailIds.filter((id: number) => !existingXRefIds.includes(id));
    const xrefsToRemove = this.existingAccidentDetails.filter(
      xref => !selectedDetailIds.includes(xref.AccidentDetailId)
    );

    const promises = [
      ...this.createNewAccidentXRefs(intakeId, detailsToAdd),
      ...this.deleteRemovedAccidentXRefs(xrefsToRemove),
    ];

    if (promises.length) {
      await Promise.all(promises);
    }
  }

  // Accident Details XRefs
  private createNewAccidentXRefs(intakeId: number, detailsToAdd: number[]): Promise<any>[] {
    return detailsToAdd.map((detailId: number) => {
      const payload = {
        IntakeId: intakeId,
        AccidentDetailId: detailId,
      };
      return this.api.basicPost(APIEndpoints.XrefIntakeAccidents, payload)
        .catch(error => {
          throw error;
        });
    });
  }

  private deleteRemovedAccidentXRefs(xrefsToRemove: any[]): Promise<any>[] {
    return xrefsToRemove.map((xref: any) => {
      return this.api.deleteOdata(`${APIEndpoints.XrefIntakeAccidents}(${xref.Id})`)
        .catch(error => {
          throw error;
        });
    });
  }

  // Manage Injury XRefs
  private async saveInjuryXRefs(intakeId: number) {
    // Get injuries directly from form control
    const selectedInjuries = this.intakeForm.get('Injury')?.value || [];
    const existingXRefIds = this.existingInjuries?.map(x => x.BodyPartId) || [];

    // Filter injuries to add - those not in existing injuries
    const injuriesToAdd = selectedInjuries.filter((injury: any) => {
      return !existingXRefIds.includes(injury.BodyPartId);
    });

    // Filter injuries to remove - those in existing but not in selected
    const xrefsToRemove = this.existingInjuries?.filter((xref: any) => {
      const isSelected = selectedInjuries.some((injury: any) => injury.BodyPartId === xref.BodyPartId);
      return !isSelected;
    }) || [];

    // Filter injuries to update - those that exist but have different severity
    const xrefsToUpdate = selectedInjuries.filter((injury: any) => {
      const existingInjury = this.existingInjuries?.find(x => x.BodyPartId === injury.BodyPartId);
      const needsUpdate = existingInjury && existingInjury.Severity !== injury.Severity;
      return needsUpdate;
    });

    const promises = [];

    if (injuriesToAdd.length > 0) {
      promises.push(...this.createNewInjuryXRefs(intakeId, injuriesToAdd));
    }

    if (xrefsToRemove.length > 0) {
      promises.push(...this.deleteRemovedInjuryXRefs(xrefsToRemove));
    }

    if (xrefsToUpdate.length > 0) {
      promises.push(...this.updateExistingInjuryXRefs(xrefsToUpdate));
    }

    if (promises.length > 0) {
      await Promise.all(promises);
    }
  }

  // Injury XRefs
  private createNewInjuryXRefs(intakeId: number, injuriesToAdd: any[]): Promise<any>[] {
    return injuriesToAdd.map((injury: any) => {
      const payload = {
        CaseFileId: this.caseFileId,
        BodyPartId: injury.BodyPartId,
        Severity: injury.Severity,
      };
      return this.api.basicPost(APIEndpoints.XrefIntakeInjury, payload)
        .catch(error => {
          throw error;
        });
    });
  }

  private deleteRemovedInjuryXRefs(xrefsToRemove: any[]): Promise<any>[] {
    return xrefsToRemove.map((xref: any) => {
      return this.api.deleteOdata(`${APIEndpoints.XrefIntakeInjury}(${xref.Id})`)
        .catch(error => {
          throw error;
        });
    });
  }

  private updateExistingInjuryXRefs(injuriesToUpdate: any[]): Promise<any>[] {
    return injuriesToUpdate.map((injury: any) => {
      const existingXRef = this.existingInjuries?.find(x => x.BodyPartId === injury.BodyPartId);
      return this.api.patchOdata(`${APIEndpoints.XrefIntakeInjury}(${existingXRef?.Id})`, {
        Id: existingXRef?.Id,
        CaseFileId: this.caseFileId,
        BodyPartId: injury.BodyPartId,
        Severity: injury.Severity,
      })
      .catch(error => {
        throw error;
      });
    });
  }
}
