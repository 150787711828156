import { Component, Input, signal, ViewChild, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridTemplateComponent } from '@grids/grid-template/grid-template.component';
import { DialogAllModule, DialogComponent } from '@syncfusion/ej2-angular-popups';
import { GridModel } from '@syncfusion/ej2-grids';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { EntityType } from '@shared/models/entity-types';
import { Query, Predicate } from '@syncfusion/ej2-data';
import { APIEndpoints } from '@models/api/Endpoints';
import { ApiService } from '@services/api/api.service';
import { BaseGridComponent } from '@shared/components/base-grid/base-grid.component';
import { Comment } from '@shared/models/data-contracts';
import { CommentsService } from '@shared/features/comments/services/comments.service';
import { ThreadFormComponent } from '../comment-thread-form/comment-thread-form.component';

interface CommentThread {
  Id: number;
  Title: string;
  Description?: string;
  Status: string;
  CreatedBy: number;
  CreatedAt: Date;
  UpdatedBy?: number;
  UpdatedAt?: Date;
  CreatedByNavigation: {
    Id: number;
    Name: string;
  };
  UpdatedByNavigation?: {
    Id: number;
    Name: string;
  };
  IsActive: boolean;
  Comments?: Comment[];
}

// Threads Grid (main grid showing threads)
@Component({
  selector: 'comment-threads-grid',
  standalone: true,
  imports: [
    CommonModule,
    DialogAllModule,
    GridTemplateComponent,
    BaseGridComponent,
    ThreadFormComponent
  ],
  templateUrl: './comment-threads-grid.component.html'
})
export class CommentThreadsGridComponent extends BaseGridComponent implements OnInit {
  @Input() entityType!: EntityType;
  @Input() entityId!: number;
  @ViewChild('threadDialog') threadDialog!: DialogComponent;
  @ViewChild('threadFormComponent') threadFormComponent!: ThreadFormComponent;
  
  selectedThread = signal<CommentThread | undefined>(undefined);

  readonly threadDialogButtons = [
    { 
      click: () => {
        console.log('Cancel clicked');
        this.cancelThreadForm();
      },
      buttonModel: { content: 'Cancel', cssClass: 'e-outline' }
    },
    { 
      click: () => {
        this.submitThreadForm();
      },
      buttonModel: { content: 'Save', isPrimary: true }
    }
  ] as const;
  
  override gridSettings: GridModel = {
    columns: [
      { field: 'CreatedAt', headerText: 'Date', type: 'date', format: 'MM/dd/yyyy', width: 120 },
      { field: 'Status', headerText: 'Status', width: 100 },
      { field: 'Id', headerText: '#', isPrimaryKey: true, width: 70 },
      { field: 'Title', headerText: 'Title', width: 250 },
      { field: 'Description', headerText: 'Description', width: 400 },
      { field: 'CreatedByNavigation.Name', headerText: 'Created By', width: 150 },
      { field: 'Comments.length', headerText: 'Replies', width: 90, textAlign: 'Center' },
      { 
        type: 'commands', 
        headerText: 'Actions',
        width: 100,
        commands: [
          { type: 'Edit', buttonOption: { iconCss: 'e-icons e-edit', cssClass: 'e-flat' } }
        ]
      }
    ],
    allowPaging: true,
    pageSettings: { pageSize: 10 },
    allowSorting: true,
    sortSettings: { 
      columns: [
        { field: 'CreatedAt', direction: 'Descending' }
      ] 
    },
    dataSource: this.api.getOdata(APIEndpoints.CommentThreads),
    toolbarClick: ($event: any) => {
      if ($event.item.id === 'add') {
        this.selectedThread.set(undefined);
        this.threadDialog.header = 'Add New Note';
        this.threadFormComponent?.form.reset();
        this.threadDialog.show();
      }
    },
    commandClick: ($event: any) => {
      if ($event.commandColumn.type === 'Edit') {
        $event.cancel = true;
        this.selectedThread.set($event.rowData);
        this.threadDialog.header = 'Edit Note';
        this.threadDialog.show();
      }
    }
  };

  constructor(
    private api: ApiService,
    private toast: ToastMessageService,
    private commentsService: CommentsService
  ) {
    super();
  }

  ngOnInit() {
    if (!this.entityId) return;
    
    const query = new Query()
      .expand(['CreatedByNavigation', 'Comments'])
      .where('XrefCommentThreadCaseFiles/any(x: x/CaseFileId eq ' + this.entityId + ')', 'equal', true);

    this.gridSettings = {
      ...this.gridSettings,
      query
    };
  }

  async submitThreadForm(): Promise<void> {
    console.log(this.threadFormComponent?.form.value);
    if (this.threadFormComponent?.form.valid) {
      try {
        const formValue = this.threadFormComponent.form.value;
        const formId = this.threadFormComponent.form.get('Id')?.value;
        const result = formId ? await this.commentsService.updateCommentThread(formId, formValue) : await this.commentsService.createCommentThread(formValue);

        // Only update Xref if new comment
        if (result && !formId) {
          await this.api.fetchRequest(
            `odata${APIEndpoints.XrefCommentThreadCaseFiles}`,
            'POST',
            {
              CommentThreadId: result.Id,
              CaseFileId: this.entityId
            }
          );
        }

        this.threadDialog.hide();
        this.selectedThread.set(undefined);
        this.gridSettings.dataSource = this.api.getOdata(APIEndpoints.CommentThreads);
        this.toast.showSuccess('Note created successfully');
      } catch (error) {
        console.error('Error creating note:', error);
        this.toast.showError('Failed to create note');
      }
    }
  }

  cancelThreadForm(): void {
    this.threadDialog.hide();
  }
}