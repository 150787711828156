<div class="row setting">
    <div class="col-5">
        <label>Username</label>
    </div>
    <div class="col-7">
        <ejs-textbox [value]="username" [enabled]="false"></ejs-textbox>
    </div>
    <div class="col-5">
        <label>Email</label>
    </div>
    <div class="col-7">
        <ejs-textbox [value]="userEmail" [enabled]="false"></ejs-textbox>
    </div>
    <div class="col-5">
        <label>Phone</label>
    </div>
    <div class="col-7">
        <ejs-maskedtextbox mask="+0 (000)000-0000" [value]="userPhoneNumber" type="tel"
            [enabled]="false"></ejs-maskedtextbox>
    </div>
    <div class="col-5">
        <label>Email Verified?</label>
    </div>
    <div class="col-7">
        <ejs-checkbox [checked]="userEmailVerified" [disabled]="true"></ejs-checkbox>
    </div>
    <div class="col-5">
        <label>Phone Number Verified?</label>
    </div>
    <div class="col-7">
        <ejs-checkbox [checked]="userPhoneNumberVerified" [disabled]="true"></ejs-checkbox>
    </div>
    <div class="col-5">
        <label>Forget this Device?</label>
    </div>
    <div class="col-7 device">
        <div class="row">
            <div class="col-8">
                <ejs-checkbox [checked]="this.isRemembered"
                    (change)="this.isRemembered = !this.isRemembered"></ejs-checkbox>
            </div>
            <div class="col-4">
                <div class="info" [innerHTML]="this.add30Days()"></div>
            </div>
        </div>
    </div>
    <div class="col-5">
        <label>TOTP MFA?</label>
    </div>
    <div class="col-7">
        <ejs-checkbox [checked]="this.isTOTP" (change)="changeTOTP()"></ejs-checkbox>
    </div>
    <div class="col-5">
        <label>Upload Profile Picture?</label>
    </div>
    <div class="col-7">
        <div id='droparea' #drop>
            Drop files here to upload or browse
            <ejs-uploader [autoUpload]="false" [multiple]="false" uploadUrl="your-upload-url" [dropArea]="drop"
                height="100">
            </ejs-uploader>
        </div>
        <!--can add these methods (beforeUpload)="onBeforeUpload($event)" (success)="onUploadSuccess($event)" (failure)="onUploadFailure($event)" -->
    </div>
    <div class="col-5">Change Password?</div>
    <div class="col-7">
        <app-change-password #ChangePassword></app-change-password>
        <button ejs-button [isPrimary]="false" cssClass="e-outline" (click)=ChangePassword.showModal()>Change</button>
    </div>
    <div class="col-5">Add Authenticator App?</div>
    <div class="col-7">
        <app-change-mfa-preference #Mfa></app-change-mfa-preference>
        <button ejs-button [isPrimary]="false" cssClass="e-outline" (click)=Mfa.showModal()>Add</button>
    </div>
    <div class="col-11"></div>
    <div class="col-1 save-container">
        <button class="e-control e-btn" (click)="this.onSaveClick()">Save</button>
    </div>
</div>