<form class="row mt-0 flex-row" [formGroup]="insuranceForm" (submit)="onSubmit($event)">
    <div class="cc-form-section">
        <div class="cc-form-group row">
            <div class="cc-input-container col-12 col-md-6">
                <span class="cc-input-icon">
                    <fa-icon [icon]="icons.faBuilding" />
                </span>
                <label>Insurance Company</label>
                <ejs-dropdownlist
                    formControlName="InsuranceCompany"
                    [dataSource]="insuranceCompanies" 
                    [fields]="{ text: 'Name', value: 'Id'}"
                    [allowFiltering]="true"
                    [filterBarPlaceholder]="'Search insurance company...'"
                    [ignoreAccent]="true"
                />
            </div>

            <div class="cc-input-container col-12 col-md-6">
                <span class="cc-input-icon">
                    <fa-icon [icon]="icons.faHashtag" />
                </span>
                <label>Claim Number</label>
                <ejs-textbox formControlName="ClaimNumber" />
            </div>

            <div class="cc-input-container col-12 col-md-6 policy-limits">
                <span class="cc-input-icon">
                    <fa-icon [icon]="icons.faMoneyBill" />
                </span>
                <label>Policy Limits</label>
                <ejs-dropdownlist formControlName="PolicyLimits" [dataSource]="policyLimits" />
            </div>

            <div class="cc-input-container col-12 col-md-6 policy-limits">
                <span class="cc-input-icon">
                    <fa-icon [icon]="icons.faMoneyBill" />
                </span>
                <label>Insurance Types</label>
                <ejs-dropdownlist 
                    formControlName="insuranceTypes" 
                    [dataSource]="insuranceTypes" 
                    [fields]="{ text: 'Description', value: 'Id'}"
                    [allowFiltering]="true"
                    [filterBarPlaceholder]="'Search insurance type...'"
                    [ignoreAccent]="true"
                />
            </div>


            <div class="cc-input-container col-12 col-md-6">
                <span class="cc-input-icon">
                    <fa-icon [icon]="icons.faNotes" />
                </span>
                <label>Notes</label>
                <ejs-textbox formControlName="Note" [multiline]="true" />
            </div>
        </div>
    </div>

    <div class="col-12">
        <h2 class="form-title">
            <fa-icon [icon]="icons.faUser" /> Claim Adjuster Information
        </h2>
        <div *ngIf="!showContactForm" class="cc-input-container col-12">
            <label>Select Existing Adjuster</label>
            <ejs-dropdownlist
                [dataSource]="contacts"
                [fields]="{ text: 'ContactName', value: 'Id' }"
                [allowFiltering]="true"
                [filterBarPlaceholder]="'Search contacts...'"
                (change)="onContactSelect($event)"
                formControlName="ClaimAdjusterId">
            </ejs-dropdownlist>
        </div>
        <button ejs-button class="e-primary e-flat claim-adjuster-button" (click)="onClaimAdjusterClick($event)"> 
            {{ showContactForm ? 'Cancel' : 'Add NewClaim Adjuster' }}
        </button>

            <contact-form-component
                #adjusterContact   
                *ngIf="showContactForm" 
                [contact]="adjusterContactSignal"
                [displayButtons]="false"
                [displayFields]="[
                    'ContactName', 
                    'ContactEmail', 
                    'ContactPhone',
                    'ContactFax',
                    'XrefAddressContacts',
                    'XrefPhoneContacts'
                ]">
            </contact-form-component>
        </div>
    <div class="submit-container">
        <button ejs-button class="e-primary e-flat" type="submit" isPrimary="true">Submit</button>
    </div>
</form>
