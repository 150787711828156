<div id="users-grid">

    <grid-template #users [settings]="usersGridSettings" [name]="'Users'"></grid-template>

    <ejs-dialog
        #addUserDialog
        [visible]="showAddUserDialog"
        [header]="'Add New User'"
        [isModal]="true"
        [width]="400"
        (close)="showAddUserDialog = false">
        <add-user [grid]="users.grid" (closeForm)="handleCloseForm()"></add-user>
    </ejs-dialog>

    <!-- create dropdown form for editing user roles -->
    <ng-template #XrefUsersRolesEditTemplate let-data>
        <label class="custom-template-label">Role</label>
        <ejs-multiselect #rolesMS
            [showDropDownIcon]="true"
            [fields]="{ text: 'RoleName', value: 'Id' }"
            [dataSource]="rolesData"
            (created)="rolesMSCreated(rolesMS, data.XrefUsersRoles)"
            (select)="rolesMSChanged($event)">
        </ejs-multiselect>
    </ng-template>

    <!-- Create dropdown form for editing user roles -->
    <ng-template #XrefUsersRightsEditTemplate let-data>
        <label class="custom-template-label">Rights</label>
        <ejs-multiselect #rightsMS
            [showDropDownIcon]="true"
            [fields]="{ text: 'FriendlyName', value: 'Id' }"
            [dataSource]="rightsData"
            (created)="rightsMSCreated(rightsMS, data.XrefUsersRights)"
            (select)="rightsMSChanged($event)">
        </ejs-multiselect>
    </ng-template>

    <ejs-dialog
        #editUserDialog
    [visible]="showEditUserDialog"
    [header]="'Edit User'"
    [isModal]="true"
    [width]="400"
    [showCloseIcon]="true"
        [closeOnEscape]="true"
        (close)="showEditUserDialog = false">
        <div>This page is under development</div>
        <!-- <user-edit [grid]="users.grid"></user-edit> -->
    </ejs-dialog>

    
</div>