import { Injectable } from '@angular/core';
import config from '@environments/config.json';

type ConfigSettings = typeof config.environment.settings;

@Injectable({ providedIn: 'root' })
export class ConfigService {
  private config = config;

  get<T extends keyof ConfigSettings>(key: T): ConfigSettings[T] {
    return this.config?.environment?.settings?.[key];
  }

  // Add method for AWS config
  getAws() {
    return this.config?.environment?.settings?.aws;
  }
} 