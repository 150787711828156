// Angular
import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
  OnInit,
  OnDestroy,
  signal,
  WritableSignal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, FormArray, ReactiveFormsModule } from '@angular/forms';

// 3rd Party
import { Predicate, Query } from '@syncfusion/ej2-data';
import { ButtonModule, SwitchModule } from '@syncfusion/ej2-angular-buttons';
import { TextBoxModule, NumericTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { Invoice, InvoiceRow, InvoicePayment, ProcedureCode, Provider, CaseFile } from '@models/data-contracts';
import { ApiService } from '@services/api/api.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { FileHubService } from '@root/src/app/features/file-hub/services/file-hub.service';
import { Subject, takeUntil } from 'rxjs';
import { LoadingModule } from '@root/src/app/shared/modules/loading.module';

enum WarningType {
  Closed = 'closed',
  Important = 'notesImportant',
  SingleAgreement = 'singleAgreement',
  SignedLien = 'signedLien',
  NoProcedure = 'NoProcedure',
}

interface Warnings {
  [WarningType.Closed]: boolean | null;
  [WarningType.Important]: boolean | null;
  [WarningType.SingleAgreement]: boolean | null;
  [WarningType.SignedLien]: boolean | null;
  [WarningType.NoProcedure]: boolean | null;
}

@Component({
  selector: 'add-invoice-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    SwitchModule,
    TextBoxModule,
    DropDownListModule,
    DatePickerModule,
    NumericTextBoxModule,
    LoadingModule,
  ],
  templateUrl: './add-invoice.component.html',
  styleUrl: './add-invoice.component.scss',
})
export class AddInvoiceForm implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  invoiceForm: FormGroup;
  WarningType = WarningType;

  @Input() invoice: Invoice | null = null;
  @Input() hideHeader: boolean = false;
  @Input() hideWarnings: boolean = false;
  @Output() submit = new EventEmitter<any>();
  @Output() close = new EventEmitter<any>();
  @Output() warningsChanged = new EventEmitter<boolean>();

  @ViewChild('providerDropdown') providerDropdown: any;

  // Data Sources
  providerList: any[] = [];
  paymentStatuses = this.api.getOdata(APIEndpoints.PaymentStatuses);
  paymentMethods = this.api.getOdata(APIEndpoints.PaymentMethods);
  surgicalProviderTypes = this.api.getOdata(APIEndpoints.SurgicalInvoices);

  // State
  selectedProvider: Provider | null = null;
  procedureCodes: ProcedureCode[] = [];
  showSurgicalFields: boolean = false;
  
  // Queries
  expandProviderQuery = 'feeSchedule($expand=XrefFeeScheduleProcedureCodes($select=ProcedureCodeId))';

  warnings: Warnings = {
    [WarningType.Closed]: null,
    [WarningType.Important]: null,
    [WarningType.SingleAgreement]: null,
    [WarningType.SignedLien]: null,
    [WarningType.NoProcedure]: null,
  };

  isLoading: WritableSignal<boolean> = signal(false);

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private toast: ToastMessageService,
    public fileHub: FileHubService
  ) {
    this.initForm();
  }

  async ngOnInit() {
    if (!this.fileHub.caseFile?.IsActive) this.warnings[WarningType.Closed] = true;
    if (this.fileHub.caseFile?.IsSurgical) this.showSurgicalFields = true;

    // Fetch providers
    try {
      const query = this.invoice ? 
        new Query().select(['Name', 'Id']) :
        new Query().select(['Name', 'Id']).where('IsActive', 'equal', true);

      const response: any = await this.api.getOdata(APIEndpoints.Providers).executeQuery(query);
      this.providerList = response.result;

      // If editing, set provider after list is loaded
      if (this.invoice?.ProviderId) {
        this.invoiceForm.patchValue({ ProviderId: this.invoice.ProviderId });
      }
    } catch (error) {
      console.error('Error loading providers:', error);
      this.toast.showError('Error loading providers');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['invoice']) {
      this.resetForm();
    }
    if (changes['invoice'] && this.invoice) {
      this.initializeEditForm();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.resetForm();
  }

  private initForm(): void {
    this.invoiceForm = this.fb.group({
      ProviderInvoiceNumber: [''],
      InternalInvoiceNumber: [''],
      InvoiceDate: [''],
      LockInvoice: [0],
      Notes: [''],
      SurgicalInvoiceId: [null],
      SplitInvoice: [0],
      SplitInvoiceId: [null],
      InvoiceTransferred: [0],
      CaseFileId: [0],
      ProviderId: [0],
      PaymentStatus: [null],
      InvoiceRows: this.fb.array([]),
      InvoicePayments: this.fb.array([])
    });
        // Subscribe to PaymentStatus changes
    this.invoiceForm.get('PaymentStatus')?.valueChanges
    .pipe(takeUntil(this.destroy$))
    .subscribe(status => {
      if (status) {
        this.updatePaymentStatuses(status);
      }
    });
  }

  // Add method to update all payment statuses
private updatePaymentStatuses(status: number): void {
  const payments = this.invoicePayments;
  payments.controls.forEach(payment => {
    payment.patchValue({ PaymentStatus: status }, { emitEvent: false });
  });
}

  get invoiceRows(): FormArray {
    return this.invoiceForm.get('InvoiceRows') as FormArray;
  }

  get invoicePayments(): FormArray {
    return this.invoiceForm.get('InvoicePayments') as FormArray;
  }

  private createInvoiceRow(): FormGroup {
    return this.fb.group({
      DateOfService: [''],
      ProcedureCodeId: [0],
      AmountBilled: [0],
      ReimbursementRate: [0],
      TotalDueProvider: [0],
      SettlementValue: [0]
    });
  }

  private createInvoicePayment(): FormGroup {
    return this.fb.group({
      DatePaid: [''],
      BalanceDue: [0],
      AmountPaid: [0],
      PaymentStatus: [this.invoiceForm.get('PaymentStatus')?.value || 0],
      PaymentMethod: [0],
      TransactionNumber: ['']
    });
  }

  addInvoiceRow(): void {
    this.invoiceRows.push(this.createInvoiceRow());
  }

  addInvoicePayment(): void {
    this.invoicePayments.push(this.createInvoicePayment());
  }

  deleteInvoiceRow(index: number): void {
    this.invoiceRows.removeAt(index);
  }

  deleteInvoicePayment(index: number): void {
    this.invoicePayments.removeAt(index);
  }

  initializeEditForm(): void {
    if (!this.invoice) return;
    this.isLoading.set(true);

    const firstPaymentStatus = this.invoice.InvoicePayments?.[0]?.PaymentStatus;

    // Set the form data
    this.invoiceForm.patchValue({
      ...this.invoice,
      ProviderId: this.invoice.ProviderId,
      PaymentStatus: firstPaymentStatus || null
    });

    // Clear existing arrays
    while (this.invoiceRows.length) {
      this.invoiceRows.removeAt(0);
    }
    while (this.invoicePayments.length) {
      this.invoicePayments.removeAt(0);
    }

    // Set invoice rows
    if (this.invoice.InvoiceRows) {
      this.invoice.InvoiceRows.forEach(row => {
        const formattedRow = {
          ...row,
          DateOfService: row.DateOfService ? new Date(row.DateOfService).toISOString().split('T')[0] : ''
        };
        this.invoiceRows.push(this.fb.group(formattedRow));
      });
    }

    // Set invoice payments
    if (this.invoice.InvoicePayments) {
      this.invoice.InvoicePayments.forEach(payment => {
        const formattedPayment = {
          ...payment,
          DatePaid: payment.DatePaid ? new Date(payment.DatePaid).toISOString().split('T')[0] : ''
        };
        this.invoicePayments.push(this.fb.group(formattedPayment));
      });
    }

    // Load provider data
    if (this.invoice.ProviderId) {
      this.onProviderChange(null, this.invoice.ProviderId).finally(() => {
        this.isLoading.set(false);
      });
    } else {
      this.isLoading.set(false);
    }
  }

  async onProviderChange(args?: any, providerId?: number): Promise<void> {
    const id = providerId ?? args?.value;
    if (!id) return;

    this.invoiceForm.patchValue({ ProviderId: id });
    
    const query = new Query()
      .where('Id', 'equal', id)
      .expand(this.expandProviderQuery);

    try {
      const providerResponse: any = await this.api
        .getOdata(`${APIEndpoints.Providers}(${id})`)
        .executeQuery(query);

      this.selectedProvider = providerResponse.result[0];
      if (!this.selectedProvider?.InNetwork) this.warnings['singleAgreement'] = true;
      if (this.selectedProvider?.NotesImportant) this.warnings['notesImportant'] = true;
      if (this.selectedProvider) await this.fetchProcedureCodeData(this.selectedProvider);
    } catch (error) {
      console.error('Error loading provider data:', error);
      this.toast.showError('Error loading provider data');
    }
  }

  async fetchProcedureCodeData(provider: Provider): Promise<void> {
    const procedureCodeIds = this.getProcedureCodes(provider);
    if (procedureCodeIds.length) {
      const predicate = this.generatePredicate(procedureCodeIds);

      try {
        const procedureCodesResponse: any = await this.api
          .getOdata(APIEndpoints.ProcedureCodes)
          .executeQuery(new Query().where(predicate));

        this.procedureCodes = procedureCodesResponse.result;
        this.procedureCodes.forEach(code => this.formatProcedureCodes(code));
      } catch (error) {
        console.error('Error loading procedure codes:', error);
        this.toast.showError('Error loading procedure codes');
      }
    } else {
      this.warnings['NoProcedure'] = true;
    }
  }

  formatProcedureCodes(procedureCode: ProcedureCode): void {
    if (procedureCode.ProcedureCodeName && procedureCode.Description) {
      procedureCode.ProcedureCodeName = `${procedureCode.ProcedureCodeName} - ${procedureCode.Description}`;
    }
  }

  getProcedureCodes = (provider: Provider): (number | undefined)[] =>
    provider?.FeeSchedule?.XrefFeeScheduleProcedureCodes?.map(
      (xref) => xref.ProcedureCodeId
    ) || [];

  generatePredicate(ids: (number | undefined)[]): Predicate {
    const validIds = ids.filter((id): id is number => id !== undefined);
    let predicate = new Predicate('Id', 'equal', validIds[0]);
    for (let i = 1; i < validIds.length; i++) {
      predicate = predicate.or('Id', 'equal', validIds[i]);
    }
    return predicate;
  }

  onProcedureCodeChange(args: any, rowIndex: number): void {
    if (args.itemData) {
      const row = this.invoiceRows.at(rowIndex);
      row.patchValue({ ProcedureCodeId: args.itemData.Id });
    }
  }

  hideWarning(warning: WarningType): void {
    this.warnings[warning] = false;
    this.isFormHidden(); // Emit warning change event
  }

  resetWarnings(): void {
    for (const key in WarningType) {
      if (Object.prototype.hasOwnProperty.call(this.warnings, key)) {
        this.warnings[key as keyof Warnings] = null;
      }
    }
  }

  isFormHidden(): boolean {
    const hasWarnings = !!(
      this.warnings[WarningType.Closed] ||
      this.warnings[WarningType.Important] ||
      this.warnings[WarningType.SingleAgreement] ||
      this.warnings[WarningType.SignedLien] ||
      this.warnings[WarningType.NoProcedure]
    );
    this.warningsChanged.emit(hasWarnings);
    return hasWarnings;
  }

  resetForm(): void {
    this.initForm();
    this.selectedProvider = null;
    this.procedureCodes = [];
    this.resetWarnings();
  }

  closeForm(): void {
    this.resetForm();
    this.close.emit();
  }

  onSubmit(): void {
    if (this.invoiceForm.valid) {
      const formValue = this.invoiceForm.value;

      delete formValue.PaymentStatus;

      const invoiceData: Invoice = {
        ...this.formatDates(formValue),
        CaseFileId: this.fileHub.caseFile?.Id || 0,
      };

      if (this.invoice?.Id) {
        invoiceData.Id = this.invoice.Id;
      }

      this.createInvoice(invoiceData);
    }
  }

  createInvoice(formInvoice: Invoice): void {
    const endpoint = this.invoice ? 
      `odata${APIEndpoints.Invoices}(${this.invoice?.Id})` : 
      `odata` + APIEndpoints.Invoices;
    
    const method = this.invoice ? 'PATCH' : 'POST';

    this.api
      .fetchRequest(endpoint, method, formInvoice)
      .then((response) => {
        if(response?.ok || response?.status === 201 || response?.status === 204) {
          this.toast.showSuccess(`Invoice successfully ${this.invoice ? 'updated' : 'created'}`);
          this.submit.emit();
        }
      })
      .catch((error) => {
        console.error(error);
        this.toast.showError("There was a problem submitting the form");
      });
  }

  formatDates(formObj: any): any {
    if (Array.isArray(formObj)) {
      return formObj.map(item => this.formatDates(item));
    }

    const formatted = { ...formObj };
    for (const key in formatted) {
      if (formatted.hasOwnProperty(key)) {
        const value = formatted[key];
        
        // Handle nested arrays (InvoiceRows and InvoicePayments)
        if (Array.isArray(value)) {
          formatted[key] = value.map(item => {
            const formattedItem = { ...item };
            // Format DateOfService in InvoiceRows
            if (formattedItem.DateOfService) {
              formattedItem.DateOfService = new Date(formattedItem.DateOfService)
                .toISOString().split('T')[0];
            }
            // Format DatePaid in InvoicePayments
            if (formattedItem.DatePaid) {
              formattedItem.DatePaid = new Date(formattedItem.DatePaid)
                .toISOString().split('T')[0];
            }
            return formattedItem;
          });
        }
        // Handle regular date fields
        else if (value instanceof Date) {
          formatted[key] = value.toISOString().split('T')[0];
        }
      }
    }
    return formatted;
  }

  // Add helper method to check if field should be disabled
  isFieldDisabled(field: string | null = null): boolean {
    // If invoice is locked, everything except LockInvoice switch is disabled
    if (this.invoiceForm.get('LockInvoice')?.value && field !== 'LockInvoice') {
      return true;
    }
    // Handle specific fields based on existing rules
    switch (field) {
      case 'provider':
      case 'settlement':
      case 'balance':
        // These fields should be disabled when editing
        return this.invoice !== null;
      default:
        // All other fields follow default behavior
        return false;
    }
  }
}
