import { Injectable, isDevMode } from '@angular/core';
import { CloudWatchLogs } from '@aws-sdk/client-cloudwatch-logs';
import { ConfigService } from '@core/services/config.service';

export interface LogEntry {
  message: string;
  level: 'error' | 'warn' | 'info' | 'debug';
  timestamp?: string;
  context?: any;
  error?: any;
}

/**
 * Centralized logging service that integrates with AWS CloudWatch.
 * 
 * Features:
 * - Technical error logging
 * - AWS CloudWatch integration
 * - Development console logging
 * - Structured log entries
 * - Graceful fallback to console logging
 * 
 * @example
 * // Log an error
 * this.loggingService.log({
 *   message: 'Operation failed',
 *   level: 'error',
 *   context: { source: 'MyComponent.save' }
 * });
 */
@Injectable({ providedIn: 'root' })
export class LoggingService {
  private cloudWatch?: CloudWatchLogs;
  private readonly isDev = isDevMode();
  private readonly awsConfig = this.config.getAws();

  constructor(private config: ConfigService) {
    // Only initialize CloudWatch if properly configured
    if (this.awsConfig?.cloudwatch?.logGroup) {
      try {
        this.cloudWatch = new CloudWatchLogs({
          region: this.awsConfig.region || 'us-west-2'
        });
        console.info('CloudWatch logging initialized');
      } catch (error) {
        console.warn('CloudWatch initialization failed, falling back to console logging');
      }
    } else {
      console.info('CloudWatch not configured, using console logging');
    }
  }

  async log(entry: LogEntry): Promise<void> {
    const timestamp = new Date().toISOString();
    const logData = { ...entry, timestamp };

    // Always log to console in dev mode or if CloudWatch not configured
    if (this.isDev || !this.cloudWatch) {
      console[entry.level](entry.message, entry.context || '');
      return;
    }

    // Only attempt CloudWatch logging if properly initialized
    if (this.cloudWatch && this.awsConfig?.cloudwatch) {
      try {
        await this.cloudWatch.putLogEvents({
          logGroupName: this.awsConfig.cloudwatch.logGroup,
          logStreamName: `${this.isDev ? 'dev' : 'prod'}-${this.awsConfig.cloudwatch.logStream}`,
          logEvents: [{
            timestamp: Date.now(),
            message: JSON.stringify(logData)
          }]
        });
      } catch (error) {
        console.warn('CloudWatch logging failed, falling back to console:', error);
        console[entry.level](entry.message, entry.context || '');
      }
    }
  }

  // Convenience methods
  info(message: string, context?: any): void {
    this.log({ message, level: 'info', context });
  }

  warn(message: string, context?: any): void {
    this.log({ message, level: 'warn', context });
  }

  debug(message: string, context?: any): void {
    this.log({ message, level: 'debug', context });
  }
} 