// Angular
import { Component, WritableSignal, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

// Internal
import { FileHubService } from '@root/src/app/features/file-hub/services/file-hub.service';
import { GlobalsService } from '@services/globals/globals.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { LoadingModule } from '@modules/loading.module';
import { FileHubHeaderComponent } from './components/file-hub-header/file-hub-header.component';
import { FileHubTabsComponent } from './components/file-hub-tabs/file-hub-tabs.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'file-hub',
  standalone: true,
  imports: [
    FileHubHeaderComponent,
    FileHubTabsComponent,
    LoadingModule,
    CommonModule,
  ],
  templateUrl: './file-hub.component.html',
  styleUrl: './file-hub.component.scss',
})
export class FileHubComponent {

  constructor(
    public fileHub: FileHubService,
    private route: ActivatedRoute,
    private globals: GlobalsService,
    private toast: ToastMessageService
  ) {}

  private watchQueryParams: Subscription;
  loadingCaseFile: WritableSignal<boolean> = signal(true);

  ngOnInit() {
    this.initializeComponent();
  }

  private async initializeComponent() {

    try {
      this.checkData();
      this.fileHub.getRouteFragment();
      this.fileHub.getAuthLimitWarning();

    } catch (error) {
      console.error('FileHubComponent: Error initializing component', error);

    } finally {
      this.loadingCaseFile.set(false);
    }
  }

  ngOnDestroy() {
    if (this.watchQueryParams) {
      this.watchQueryParams.unsubscribe();
    }
  }

  async checkData() {

    try {

      this.watchQueryParams = this.route.queryParams.subscribe(async param => {
        const caseFileNumberExists: boolean = this.globals.objHasKey(param, 'fileNumber');
        this.loadingCaseFile.set(true);
        if (!caseFileNumberExists) {
          this.toast.showError(`Error: Current URL(${window.location.href}) missing query parameter, \"<strong>fileNumber</strong>\". Unable to generate data.`);
          return;
        }
        
        await this.fileHub.setCaseFile(param);
        this.fileHub.triggerReload();
      });

    } catch (error) {
      console.error('FileHubComponent: Error checking data', error);
    }
  }
}
