<div id="accounting">

    <ejs-tab id="_ejs-component-accountingTab" [items]="tabItems" [enablePersistence]="true">

        <e-tabitems>
            @for(tab of tabItems; track tab.id) {
                <e-tabitem [header]="tab" [id]="tab.id" [tabIndex]="$index">
                    <ng-template #headerTemplate>
                        <div class="tab-header">
                            <fa-icon [icon]="tab.faIcon" />
                            <label>{{tab.header?.text}}</label>
                        </div>
                    </ng-template>

                    <ng-template #content let-data>
                        <div class="tab-contents">
                            @switch (tab.id) {
                                @case ('invoices') { <invoices-grid #invoicesGrid /> }
                                @case ('deposits') { <deposits-grid #depositsGrid/> }
                            }
                        </div>
                    </ng-template>
                </e-tabitem>
            }
        </e-tabitems>

    </ejs-tab>
</div>