// Angular
import { Injectable, ViewChild, WritableSignal, signal } from '@angular/core';
import { Query } from '@syncfusion/ej2-data';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { Base } from '@core/base/base';
import { Provider } from '@models/data-contracts';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { ProviderFormComponent } from '@root/app/components/forms/provider-form/provider-form.component';

@Injectable({
  providedIn: 'root'
})
export class ProvidersGridService extends Base {

  @ViewChild('providerDialog') providerDialog!: DialogComponent;
  @ViewChild('providerFormComponent') providerFormComponent!: ProviderFormComponent;

  showActive: WritableSignal<boolean> = signal(true);
  selectedProvider: WritableSignal<Provider | undefined> = signal(undefined);
  selectedRows: WritableSignal<any[]> = signal([]);
  auditLogs: any[] = [];
  auditLogsLoading = false;

  // Public constants for grid configuration
  public readonly ENDPOINT = APIEndpoints.Providers;
  public readonly DEFAULT_QUERY = new Query()
    .expand(['XrefAddressProviders($expand=Address($expand=StateNavigation))', 'XrefProviderProviderTypes($expand=ProviderType)'])
    .where('IsActive', 'equal', true);
}
