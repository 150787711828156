// Angular
import { Component, ViewChild, Input, ViewEncapsulation, WritableSignal } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { GridAllModule } from '@syncfusion/ej2-angular-grids';
import { TabCardComponent } from '@features/file-hub/components/file-hub-tabs/tab-card/tab-card.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TextAreaModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';

// 3rd Party
import { IconDefinition } from '@fortawesome/angular-fontawesome';
import { faSquareCheck, faSquareXmark, faPenToSquare, faTrash, faFileLines } from '@fortawesome/free-solid-svg-icons';
import { DataManager, Query, DataUtil } from '@syncfusion/ej2-data';
import { DialogComponent, Tooltip } from '@syncfusion/ej2-angular-popups';
import { ExcelExportProperties, PageEventArgs, PdfExportProperties, RowDataBoundEventArgs } from '@syncfusion/ej2-grids';
import {
  GridComponent,
  GridModel,
  ColumnModel,
  EditEventArgs,
  AggregateService,
  GroupService,
  SortService,
  EditService,
  ToolbarService,
  PageService,
  FilterService,
  ColumnChooserService,
  CommandClickEventArgs,
  CommandModel,
  CommandButtonType
} from '@syncfusion/ej2-angular-grids';

// Internal
import config from '@app/config';
import { languages } from '@models/global-vars';
import { GlobalsService } from '@services/globals/globals.service';
import { CognitoService } from '@services/auth/cognito.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { ApiService } from '@services/api/api.service';
import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ErrorHandler } from '@angular/core';

@Component({
  selector: 'grid-template',
  standalone: true,
  imports: [CommonModule, GridAllModule, TabCardComponent, FontAwesomeModule, TextAreaModule, DropDownListModule],
  templateUrl: './grid-template.component.html',
  styleUrls: ['./styles/all.scss'],
  providers: [
    AggregateService,
    GroupService,
    SortService,
    EditService,
    ToolbarService,
    PageService,
    FilterService,
    ColumnChooserService
  ],
  encapsulation: ViewEncapsulation.None
})
export class GridTemplateComponent extends ErrorHandler {

  // Add default page settings
  defaultPageSettings = {
    pageSize: 16,
    pageCount: 5,
    enableQueryString: true,
    pageSizes: [20, 50, 100, 500],
    currentPage: 1
  };

  constructor(
    public globals: GlobalsService,
    private cognito: CognitoService,
    private toast: ToastMessageService,
    private title: Title,
    private api: ApiService,
    private fb: FormBuilder
  ) {
    super();
  }

  // General variables
  isResponsive: boolean = this.globals.isResponsive;
  faSquareCheck: IconDefinition = faSquareCheck;
  faSquareXmark: IconDefinition = faSquareXmark;
  faPenToSquare: IconDefinition = faPenToSquare;
  faTrash: IconDefinition = faTrash;
  faFileLines: IconDefinition = faFileLines;
  languages: string[] = languages;
  customTemplateLabelString: string;
  customTemplateDropdownData: any;
  customTemplateDropdownFields: any;
  customTemplateDropdownValue: any;
  customTemplateCheckboxValue: any;
  contactsDropDownDataSource: any;
  marketManagerDropDownDataSource: any;
  marketManagerDropDownQuery: any;
  caseManagerDropDownDataSource: any;
  caseManagerDropDownQuery: any;
  statusingUserDropDownDataSource: any;
  statusingUserDropDownQuery: any;
  contactsDropDownFields: Object = { id: 'Id', value: 'Id', text: 'ContactName' };

  // Input variables
  @Input() name: string;
  @Input() settings: GridModel;
  @Input() showSave: boolean = false;
  @Input() useRoundedEdges: boolean = false;
  @Input() headingName: string = '';
  @Input() hideExports: boolean = false;
  @Input() showActive: boolean = true;

  // Grid variables
  @ViewChild('grid') grid: GridComponent;
  @ViewChild('addRecordDialog') addRecordDialog!: DialogComponent;
  @ViewChild('editRecordDialog') editRecordDialog!: DialogComponent;
  @ViewChild('yesNoTemplate', { static: true }) yesNoTemplate!: string;
  @ViewChild('isFinalTemplate') isFinalTemplate!: string;
  @ViewChild('statusTemplate', { static: true }) statusTemplate!: string;
  @ViewChild('activeDisplayTemplate', { static: true }) activeDisplayTemplate!: string;
  @ViewChild('ratingTemplate', { static: true }) ratingTemplate!: string;
  @ViewChild('fileRatingTemplate', { static: true }) fileRatingTemplate!: string;
  @ViewChild('websiteLinkTemplate', { static: true }) websiteLinkTemplate!: string;
  @ViewChild('isDefaultTemplate', { static: true }) isDefaultTemplate!: string;
  @ViewChild('caseFileTemplate', { static: true }) caseFileTemplate!: string;
  @ViewChild('languageTemplate', { static: true }) languageTemplate!: string;
  @ViewChild('notesTemplate', { static: true }) notesTemplate!: string;
  @ViewChild('commentsTemplate', { static: true }) commentsTemplate!: string;
  @ViewChild('caseManagerDropDownTemplate', { static: true }) caseManagerDropDownTemplate!: string;
  @ViewChild('marketManagerDropDownTemplate', { static: true }) marketManagerDropDownTemplate!: string;
  @ViewChild('statusingUserDropDownTemplate', { static: true }) statusingUserDropDownTemplate!: string;
  @ViewChild('activeEditTemplate', { static: true }) activeEditTemplate!: string;
  @ViewChild('defaultActionsColumn', { static: true }) defaultActionsColumn!: string;
  @ViewChild('marketManagerDropDown') marketManagerDropDown: DropDownListComponent;
  @ViewChild('statusingUserDropDown') statusingUserDropDown: DropDownListComponent;
  @ViewChild('caseManagerDropDown') caseManagerDropDown: DropDownListComponent;

  columns: ColumnModel[];
  columnsWithValueAccessor: ColumnModel[] = [];
  dataSourceUrl: string | undefined;
  emptyFormTemplate: WritableSignal<FormGroup>;
  editFormData: any;
  editFormType: 'add' | 'edit' | 'delete' = 'add';
  editFormHeader: string;
  gridParentElement: any;
  defaultMobileToolbar: any[] = [
    'Add',
    'CsvExport',
    'ExcelExport',
    'PdfExport',
    'Print',
    'ColumnChooser'
  ];
  exportToolbar: any[] = [
    { text: 'Copy', tooltipText: 'Copy', id: 'copy', align: 'Right', prefixIcon: 'e-copy' },
    { text: 'CSV Export', tooltipText: 'CSV Export', id: 'csvExport', align: 'Right', prefixIcon: 'e-export-csv' },
    { text: 'Excel Export', tooltipText: 'Excel Export', id: 'excelExport', align: 'Right', prefixIcon: 'e-excelexport' },
    { text: 'PDF Export', tooltipText: 'PDF Export', id: 'pdfExport', align: 'Right', prefixIcon: 'e-pdfexport' },
    { text: 'Print', tooltipText: 'Print', id: 'print', align: 'Right', prefixIcon: 'e-print' },
  ]
  defaultToolbar: any[] = [
    'Add',
    'ColumnChooser'
  ]
  defaultActionsColumnCommands: CommandModel[] = [
    { type: 'Edit', title: 'Edit', buttonOption: { iconCss: 'e-icons e-edit', cssClass: 'e-flat' } },
    { type: 'Delete', title: 'Toggle IsActive', buttonOption: { iconCss: 'e-icons e-circle-check', cssClass: 'e-flat' } },
  ];

  // IsActive flag support
  private hasIsActiveColumn: boolean = false;
  private timezoneFlag = true;  // Add flag to ensure we only set this once

  ngOnInit(): void {
    // Apply default page settings if none provided
    if (!this.settings.pageSettings) {
      this.settings.pageSettings = this.defaultPageSettings;
    }

    // If the dataSource is a DataManager, configure timezone handling
    if (this.settings.dataSource instanceof DataManager) {
      const dataManager = this.settings.dataSource as DataManager;
      (dataManager as any).timeZoneHandling = false;
      (dataManager as any).serverTimezoneOffset = 0;
    }

    // Check if grid has IsActive column
    this.hasIsActiveColumn = this.settings.columns?.some(col =>
      typeof col === 'object' && 'field' in col && col.field === 'IsActive'
    ) || false;

    // If no toolbar is specified in settings, use the default
    if (!this.settings.toolbar) {
      this.settings.toolbar = this.getDefaultToolbarItems();
    }

    // Initialize the grid with settings
    this.initializeGrid();
  }

  // Occurs after HTML has been rendered to ensure templates are available
  ngAfterViewInit(): void {
    // this.setGridId();
    this.gridParentElement = this.grid.element.parentElement;

    // Iterate through grid columns and render default templates
    this.grid.columns.forEach((column: ColumnModel) => {

      if (column.type === 'checkbox') {
        column.allowFiltering = false;
        column.textAlign = 'Center';
        column.visible = !this.globals.isResponsive;
      } else if (column.type === 'commands') {
        column.commands = column.commands ?? this.defaultActionsColumnCommands;
      }

      if (column.field === 'Id' || column.field === 'id') {
        column.isPrimaryKey = true;
        column.visible = false;
      } else if (column.field === 'InTreatment') {
        column.template = this.yesNoTemplate;
        column.editType = 'booleanedit';
      }
      else if (column.field === 'Final') {
        column.template = this.isFinalTemplate;
        column.editType = 'booleanedit';
      }
      else if (column.field === 'Status') {
        column.template = this.statusTemplate;
      } else if (column.field === 'IsActive') {
        column.template = this.activeDisplayTemplate;
        column.editType = 'booleanEdit';
      } else if (column.field === 'FileRating') {
        column.template = this.fileRatingTemplate;
      } else if (column.field === 'Rating') {
        column.template = this.ratingTemplate;
      } else if (column.field === 'Website') {
        column.template = this.websiteLinkTemplate;
      } else if (column.field === 'IsDefault') {
        column.template = this.isDefaultTemplate;
      } else if (column.field === 'Language') {
        column.editType = 'dropDownEdit';
      } else if (column.field === 'Minor') {
        column.editType = 'booleanedit';
      } else if (column.field === 'CreatedBy' || column.field === 'UpdatedBy') {
        column.allowEditing = false;
      } else if (column.field === 'CreatedAt' || column.field === 'UpdatedAt' || column.field === 'CreatedDate' || column.field === 'UpdatedDate') {
        column.type = 'date';
        column.format = 'M/d/y';
        column.allowEditing = false;
      } else if (column.field?.includes('Dob') || column.field?.includes('Date') || column.field === 'FileOpened' || column.field === 'DueDate') {
        column.type = 'date';
        column.editType = 'datepickeredit';
        column.format = 'M/d/y';
      } else if (column.field?.includes('Amount')) {
        column.editType = 'numericEdit';
      } else if (column.field === 'TotalDueProvider' || column.field === 'SettlementValue' || column.field === 'AmountBilled' || column.field === 'AmountPaid' || column.field === 'Amount') {
        column.format = 'C2';
        column.editType = 'numericEdit';
      }

      // Update the consolidated date handling with debug logs
      if (column.type === 'date' || 
          column.field?.includes('Dob') || 
          column.field?.includes('Date') || 
          column.field === 'FileOpened' || 
          column.field === 'DueDate' ||
          column.field === 'CreatedAt' || 
          column.field === 'UpdatedAt' || 
          column.field === 'CreatedDate' || 
          column.field === 'UpdatedDate') {
        
        column.type = 'date';
        column.format = 'M/d/y';
        column.valueAccessor = (field: string, data: any): string => {
          
          // Handle nested fields (e.g., 'Patient.Dob')
          const value = field.includes('.') ? 
            field.split('.').reduce((obj, key) => obj?.[key], data) :
            data[field];
          
          if (!value) {
            return '';
          }
          
          try {
            const date = new Date(value);
            if (isNaN(date.getTime())) {
              return '';
            }
            
            const formatted = new Intl.DateTimeFormat('en-US', {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
              timeZone: 'UTC'
            }).format(date);
            return formatted;
          } catch (error) {
            this.handleError(error);
            return '';
          }
        };
      }

      if (column.valueAccessor && column.field) {
        this.columnsWithValueAccessor.push(column);
      }
    });
  }

  ngOnDestroy(): void {
    this.grid.destroy();
  }

  setGridId() {
    const grids = document.querySelectorAll('ejs-grid');
    const title = this.name ?? this.title.getTitle().trim().toLowerCase();

    grids.forEach((grid, index) => {
      if (grid.id === this.grid.element.id) {
        this.grid.element.id = `_ejs-component-${title}Grid-${index}`;
      }
    });
  }

  setRatingClass(rating?: string): string | undefined {
    if (rating) {
      if (rating.includes('Good')) {
        return 'good';
      } else if (rating.includes('Bad') || rating.includes('Delinquent')) {
        return 'bad';
      } else if (rating.includes('Warning') || rating.includes('Near') || rating.includes('Caution')) {
        return 'caution';
      } else {
        return 'neutral';
      }
    } else {
      return undefined;
    }
  }

  // Occurs when data is saved to grid && grid's edit mode is 'Batch'
  onGridBatchSave(args: any): void {

    if (this.settings.beforeBatchSave) {
      this.settings.beforeBatchSave(args);
    } else {
      // Default behavior for saving updated data on the grid
      const comp = this;
      const xhr = new XMLHttpRequest();
      const batchURL = (this.grid.dataSource as DataManager).dataSource.url;
      let data = {
        action: 'batch',
        Changed: args.batchChanges.changedRecords,
        Added: args.batchChanges.addedRecords,
        Deleted: args.batchChanges.deletedRecords,
      };

      // Execute save
      args.cancel = true;

      xhr.open('POST', batchURL + '/batch');
      xhr.setRequestHeader('Content-type', 'application/json');
      xhr.setRequestHeader('Authorization', 'Bearer ' + this.cognito.getUserIdToken());
      xhr.send(JSON.stringify(data));

      // Refresh grid after save
      xhr.onload = function (result) {
        comp.grid?.refresh();
      };
    }
  }

  // Enables pager interactions
  addPagerClasses() {
    if (this.grid?.pageSettings.pageSizes) {
      document.querySelector('.e-adaptive')?.classList.add('e-pager-pagesizes');
    } else {
      document.querySelector('.e-adaptive')?.classList.remove('e-pager-pagesizes');
    }
  }

  onGridCreated(args: any) {
    // Disable timezone conversion globally
    if (this.timezoneFlag) {
      (DataUtil as any).serverTimezoneOffset = 0;
      this.timezoneFlag = false;
    }

    if ((this.grid.dataSource as DataManager).dataSource) {
      this.dataSourceUrl = (this.grid.dataSource as DataManager).dataSource.url?.replace(config.backendUrl, '').replace(/\/+/g, '/').replace('odataodata', 'odata');
    }

    if (this.settings?.created) {
      this.settings.created(args);
    }
  }

  // Occurs at the start of the grid loading
  onGridLoad(args: any): void {

    if (this.settings?.load) {
      this.settings.load(args);
    } else {
      // Placeholder
    }

    this.addPagerClasses();
    this.grid.adaptiveDlgTarget = document.querySelector('#grid-template') as HTMLElement;
  }

  // Occurs on any beginning action for the grid
  onGridActionBegin(args: PageEventArgs) {
    this.updateSearchLogicUsingContains(args);
    if (args.requestType === 'sorting') this.updateSortingLogic(args);

    if (this.settings?.actionBegin) {
      this.settings.actionBegin(args);
    }
  }

  // Ensures columns with valueAccessor can be sorted
  updateSortingLogic(args: any) {
    if (!args.columnName) this.grid.sortSettings = { columns: [{ field: 'Id', direction: 'Descending' }] };
  }

  // Update the condition for filtering to include all options that contain query string
  updateSearchLogicUsingContains(args: any) {

    if (args.requestType == "filtering") {
      args.columns.forEach((col: any) => {
        col.operator = 'contains';
      });
    }
  }

  // Occurs on any ending action
  adjustEditDialog(args: EditEventArgs) {

    if ((args.requestType === 'beginEdit' || args.requestType === 'add')) {
      const dialog = (args as any).dialog;
      dialog.showCloseIcon = false;
      dialog.width = '90vw';
      // dialog.height = '85vh';
      this.removeLabelClassesFromEditDialogFields(args);
    }
  }

  removeLabelClassesFromEditDialogFields(args: any) {

    if ((args.requestType === 'beginEdit' || args.requestType === 'add')) {
      const dialog = args.dialog;
      const dialogContent = dialog.contentEle;

      if (dialogContent) {
        const labels = dialogContent.querySelectorAll('label');

        labels.forEach((labelElement: HTMLElement) => {
          labelElement.classList.remove('e-float-text');
          labelElement.classList.remove('e-label-top');
        })
      }
    }
  }

  // Detects if parent elemnet is responsive and returns string for rendering mode
  setGridRowRenderingMode(): string {

    if (this.gridParentElement && this.gridParentElement.offsetWidth < 600) {
      return 'Vertical';
    } else {
      return 'Horizontal';
    }
  }

  // Detects if parent elemnet is responsive and returns boolean for adaptive UI
  setGridAdaptiveUI(): boolean {
    let result: boolean = false;
    if (this.gridParentElement && !this.settings.enableAdaptiveUI) {

      if (this.gridParentElement.parentElement.offsetWidth < 1000) {
        this.grid.toolbar = this.settings.toolbar ? this.settings.toolbar : this.defaultMobileToolbar;
        result = true;
      }
    }
    return result;
  }

  isGridParentResponsive(): any {
    const parentWidth = this.grid.element.parentElement?.offsetWidth;

    if (parentWidth && parentWidth < 768) {
      this.grid.rowRenderingMode = 'Vertical';
      this.grid.enableAdaptiveUI = true;
    } else {
      this.grid.rowRenderingMode = 'Horizontal';
      this.grid.enableAdaptiveUI = false;
    }
  }

  onGridActionComplete(args: any) {

    if (this.settings?.actionComplete) {
      this.settings.actionComplete(args);
    }
  }

  // Occurs whenever the buttons above the grid are clicked
  onGridToolbarClick(args: any) {
    const fileName = this.name ? this.name.replace(' ', '') : this.grid.element.id;

    if (this.settings?.toolbarClick) {
      this.settings.toolbarClick(args);
    }

    if (args.item.id === 'copy') {
      const selectedRowIndexes = this.grid.getSelectedRowIndexes().length > 0 ?
        this.grid.getSelectedRowIndexes() :
        this.grid.getRows().map((row: any) => parseInt(row.getAttribute('aria-rowindex')) - 1);
      this.grid.selectRows(selectedRowIndexes);
      setTimeout(() => { this.grid.copy(true); }); // set brief timeout to ensure rows selected
    } else if (args.item.id === 'pdfExport') {
      const pdfExportProperties: PdfExportProperties = { fileName: `${fileName}.pdf` };
      this.grid.pdfExport(pdfExportProperties);
    } else if (args.item.id === 'excelExport') {
      const excelExportProperties: ExcelExportProperties = { fileName: `${fileName}.xlsx` };
      this.grid.excelExport(excelExportProperties);
    } else if (args.item.id === 'csvExport') {
      const csvExportProperties: ExcelExportProperties = { fileName: `${fileName}.csv` };
      this.grid.csvExport(csvExportProperties);
    } else if (args.item.id === 'print') {
      this.grid.print();
    }
  }

  onBeforeCopy(args: any) {

    if (this.settings?.beforeCopy) {
      this.settings.beforeCopy(args);
    }

    this.copyDataToClipboard(args.data); // Separate copy functions for improved error handling
  }

  async copyDataToClipboard(data: any) {

    if (!data) {
      this.toast.showError('No data to copy');
      return;
    };

    try {
      await navigator.clipboard.writeText(data);
      this.toast.showSuccess('Copied rows to clipboard');
    } catch (error) {
      this.toast.showError('Failed to copy to clipboard');
      console.error('Error copying to clipboard:', error);
    }
  }

  // Occurs whenever a row is clicked
  onGridRowSelected(args: any) {

    if (this.settings?.rowSelected) {
      this.settings.rowSelected(args);
    } else {
      // placeholder
    }
  }

  // Occurs while rows are being selected
  onGridRowSelecting(args: any) {

    if (this.settings?.rowSelecting) {
      this.settings.rowSelecting(args);

    } else {
      // placeholder
    }
  }

  // Occurs after rows deselected
  onGridRowDeselected(args: any) {

    if (this.settings?.rowDeselected) {
      this.settings.rowDeselected(args);

    } else {
      // placeholder
    }
  }

  // Occurs while rows are de-selected
  onGridRowDeselecting(args: any) {

    if (this.settings?.rowDeselecting) {
      this.settings.rowDeselecting(args);
    } else {
      // placeholder
    }
  }

  onGridDataStateChange(args: any) {

    if (this.settings?.dataStateChange) {
      this.settings.dataStateChange(args);
    } else {
      // placeholder
    }
  }

  // Occurs while rows are de-selected
  onGridRowDataBound(args: RowDataBoundEventArgs) {

    if (this.settings?.rowDataBound) {
      this.settings.rowDataBound(args);
    } else {
      // placeholder
    }
  }

  onGridCellEdit(args: any) {

    if (this.settings?.cellEdit) {
      this.settings.cellEdit(args);
    } else {
      // placeholder
    }
  }

  onGridCellSave(args: any) {
    if (this.settings?.cellSave) {
      this.settings.cellSave(args);
    } else {
      // Remove system-controlled fields
      if (args.data) {
        delete args.data.CreatedDate;
        delete args.data.UpdatedDate;
        delete args.data.CreatedBy;
        delete args.data.UpdatedBy;
      }
    }
  }

  async onCommandClick(commandClickArgs: CommandClickEventArgs) {
    const column = commandClickArgs.commandColumn;
    const type = column?.type;
    const title = column?.title;
    const data = commandClickArgs.rowData as any;

    if (this.settings?.commandClick) {
      this.settings.commandClick(commandClickArgs);

    } else {

      if (type === 'None' || type === 'Delete') {
        commandClickArgs.cancel = true;

        if (type === 'Delete') {
          this.handleDeleteCommandClick(commandClickArgs.rowData);
        }
      } else if (type === 'Edit' && data) {
        this.grid.beginEdit();
      }
    }
  }
  onQueryCellInfo(args: any) {
    // Add null check before accessing cell data
    if (!args.cell || !args.data) return;

    if ((args.column.field === 'Comments' || args.column.field === 'Notes') && args.data[args.column.field]) {
      let tooltip: Tooltip = new Tooltip({
        content: args.data[args.column.field].toString()
      }, args.cell);
    } else if (args.column.field === 'LawFirm.Name') {
      // Add null check for LawFirm data
      if (!args.data['LawFirm']) return;

      const lawFirm = args.data['LawFirm'];
      const abbreviation = lawFirm['Abbreviation'];
      const specialInstructions = lawFirm['Notes'] ? lawFirm['Notes'] : 'None';
      let tooltip: Tooltip = new Tooltip({
        content: `<div><b>${lawFirm['Name']}</b></div> Law Firm Abbreviation: (${abbreviation})</div><div>Phone: </div><div>Main Fax: </div><div> Special Instructions: ${specialInstructions}</div>`
      }, args.cell);
    }

    if (this.settings?.queryCellInfo) {
      this.settings.queryCellInfo(args);
    }
  }

  onDetailDataBound(args: any) {
    if (this.settings?.detailDataBound) {
      this.settings.detailDataBound(args);
    } else {
      // placeholder
    }
  }

  onDataBound(args: any) {

    if (this.settings?.dataBound) {
      this.settings.dataBound(args);
    } else {
      // placeholder
    }
  }

  getSelectedRows = () => this.grid.getSelectedRecords();

  handleDeleteCommandClick(data: any, event?: Event) {
    let url = (this.grid.dataSource as DataManager).dataSource.url?.split('?')[0];

    if (data) {
      const updatedData = { IsActive: !data.IsActive };

      if (this.globals.objHasKey(data, 'IsActive')) {
        if (url && data.Id) {
          url = url.replace(config.backendUrl, '').replace(/\/+/g, '/'); // Normalize multiple slashes
          this.api.fetchRequest(`${url}/${data.Id}`.replace(/\/+/g, '/'), 'PATCH', updatedData).then((res: any) => {
            if (res.ok) this.grid.refreshColumns();
          });
        } else {
          this.toast.showError('No URL or ID found');
        }
      } else {
        this.toast.showError('Record does not have IsActive property');
      }
    } else {
      this.toast.showError('No data found');
    }
  }

  handleViewLogCommandClick(args: Event, data: any) {
    args.preventDefault();
  }

  insertToolbarItem(item: string | object): void {

    if (this.grid) {
      const toolbar = [...this.grid.toolbar];
      const insertPosition = Math.max(0, toolbar.length - 1);
      toolbar.splice(insertPosition, 0, item);
      this.grid.toolbar = toolbar;
      this.grid.refreshHeader();
    }
  }

  refresh() {
    this.grid.refresh()
  }

  closeDialog() {
    if (this.addRecordDialog) this.addRecordDialog.hide();
    if (this.editRecordDialog) this.editRecordDialog.hide();
  }

  private getDefaultToolbarItems(): any[] {
    const items = [
      { text: 'Add', id: 'add', tooltipText: 'Add new record' },
      { type: 'Separator' },
      'Search',
      'ColumnChooser'
    ];

    return items;
  }

  onToolbarClick(args: any) {
    if (args.item.id === 'toggleActive' || args.item.id === 'showActive') {
      this.showActive = !this.showActive;

      // Get the data source URL
      const dataManager = this.grid.dataSource as DataManager;
      let url = dataManager.dataSource.url?.split('?')[0];

      if (url) {
        url = url.replace(config.backendUrl, '').replace(/\/+/g, '/'); // Normalize multiple slashes

        // Update the query parameter directly in the URL
        const params = new URLSearchParams();
        params.append('$filter', `Active eq ${this.showActive}`);

        // Create a new DataManager with the updated URL
        this.grid.dataSource = new DataManager({
          url: `${url}?${params.toString()}`,
          adaptor: dataManager.adaptor,
          crossDomain: true,
          headers: (dataManager.dataSource as any).headers
        });

        // Update toolbar text
        if (this.settings.toolbar && Array.isArray(this.settings.toolbar)) {
          const updatedToolbar = this.settings.toolbar.map(item => {
            if (item && typeof item === 'object' && item.id === 'toggleActive') {
              return {
                text: this.showActive ? 'View Inactive' : 'View Active',
                id: 'toggleActive',
                tooltipText: this.showActive ? 'Switch to viewing inactive records' : 'Switch to viewing active records',
                prefixIcon: 'e-icons e-eye'
              };
            }
            return item;
          });
          this.settings.toolbar = updatedToolbar;
          this.grid.toolbar = updatedToolbar;
        }

        // Refresh the grid
        this.grid.refresh();
      }
    } else if (this.settings.toolbarClick) {
      this.settings.toolbarClick(args);
    }
  }

  initializeGrid() {
    // Add IsActive/Inactive toggle if grid has IsActive column
    if (this.hasIsActiveColumn) {
      const activeToggle = {
        text: this.showActive ? 'View Inactive' : 'View IsActive',
        id: 'toggleActive',
        tooltipText: this.showActive ? 'Switch to viewing inactive records' : 'Switch to viewing active records',
        prefixIcon: 'e-icons e-eye'
      };
      this.settings.toolbar = this.settings.toolbar ? [activeToggle, ...this.settings.toolbar as any[]] : [activeToggle];

      // Set up initial query with IsActive filter
      const query = new Query().where('IsActive', 'equal', this.showActive);
      this.settings.query = query;
      if (this.grid) {
        this.grid.query = query;
      }
    }

    if (!this.hideExports && this.settings.toolbar) {
      this.settings.toolbar = [...this.settings.toolbar as any[], ...this.exportToolbar];
    }

    if (this.name && this.settings.toolbar) {
      const titleItem = { template: `<span class="h2">${this.name}</span>` };
      this.settings.toolbar = [titleItem, ...this.settings.toolbar as any[]];
    }

    // Check if IsActive column exists and add delete command
    if (!this.settings.commandClick && this.columns?.some(col => col.field === 'IsActive')) {
      const commandsColumn = this.columns.find(col => col.type === 'commands');
      if (commandsColumn) {
        const deleteCommand: CommandModel = {
          type: 'Delete' as CommandButtonType,
          title: 'Toggle IsActive',
          buttonOption: {
            iconCss: 'e-icons e-circle-check',
            cssClass: 'e-flat'
          }
        };
        commandsColumn.commands = this.defaultActionsColumnCommands.concat(deleteCommand);
      }
    }
  }
}

