// Angular
import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

// 3rd Party
import { forgetDevice, updateMFAPreference } from '@aws-amplify/auth';
import { ToastUtility } from '@syncfusion/ej2-angular-notifications';
import { ButtonComponent, ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { MenuModule } from '@syncfusion/ej2-angular-navigations';
import { UploaderModule } from '@syncfusion/ej2-angular-inputs';
import { TextBoxModule, NumericTextBoxModule, MaskedTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { CheckBoxModule } from '@syncfusion/ej2-angular-buttons';

// Internal
import { GlobalsService } from '@services/globals/globals.service';
import { UserPreferencesService } from '@services/user/user-preferences.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { ModalComponent } from '@ui/modal/modal.component';
import { ChangeMfaPreferenceComponent } from './change-mfa-preference/change-mfa-preference.component';
import { ChangePasswordComponent } from './change-password/change-password.component';

@Component({
  selector: 'profile-general',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    MenuModule,
    UploaderModule,
    TextBoxModule,
    MaskedTextBoxModule,
    NumericTextBoxModule,
    CheckBoxModule,
    ChangeMfaPreferenceComponent,
    ChangePasswordComponent
  ],
  templateUrl: './profile-general.component.html',
  styleUrl: './profile-general.component.scss'
})
export class ProfileGeneralComponent {
  constructor(
    private toast: ToastMessageService,
    private globals: GlobalsService,
    private userPreferences: UserPreferencesService
  ) { }

  // User variables for display
  userSession: any;
  username: string;
  userEmail: string;
  userEmailVerified: string;
  userPhoneNumber: string;
  userPhoneNumberVerified: string;
  isRemembered = false;
  isTOTP = false;
  rememberedUntil = this.add30Days();

  // Interactive elements
  @ViewChild('darkModeBtn') darkModeBtn!: ButtonComponent;

  ngOnInit(): void {
    this.isTOTP = (localStorage.getItem("isTOTP") === "true");
    this.setUserFields();
  }

  // Get current user session to display account attribtues
  async setUserFields() {
    try {
      await this.userPreferences.getUserSession().then((result) => {
        const tokens = result.tokens;
        this.username = tokens.idToken.payload['cognito:username'] as string;
        this.userEmail = tokens.idToken.payload['email'] as string;
        this.userEmailVerified = tokens.idToken.payload['email_verified'] as string;
        this.userPhoneNumber = tokens.idToken.payload['phone_number'] as string;
        this.userPhoneNumberVerified = tokens.idToken.payload['phone_number_verified'] as string;
      });
    } catch (err) {
      this.toast.showError('Error fetching user session. Check console log for details');
      console.log(err);
    }
  }

  add30Days() {
    const timestamp = localStorage.getItem("rememberedDate");
    if (!timestamp) return null;
    const newDate = new Date(timestamp);
    newDate.setDate(newDate.getDate() + 30);
    return `Device remembered until ${newDate.toLocaleDateString().slice(0, -2)}`;
  }

  changeTOTP() {
    this.isTOTP = !this.isTOTP
  }

  async updateTOTP() {
    try {
      await updateMFAPreference({ sms: 'PREFERRED' });
      this.isTOTP = true;
    } catch (err) {
      let message = 'Unknown Error'
      if (err instanceof Error) message = err.message
      ToastUtility.show({
        title: 'Something Went Wrong',
        content: message,
        cssClass: 'e-toast-danger',
        position: { X: 'Center', Y: 'Top' },
        timeOut: 3000,
      });
      this.isTOTP = false;
    }
  }
  async updateSMS() {
    try {
      await updateMFAPreference({ totp: 'PREFERRED' });
      this.isTOTP = false;
    } catch (err) {
      let message = 'Unknown Error'
      if (err instanceof Error) message = err.message
      ToastUtility.show({
        title: 'Something Went Wrong',
        content: message,
        cssClass: 'e-toast-danger',
        position: { X: 'Center', Y: 'Top' },
        timeOut: 3000,
      });
      this.isTOTP = true;
    }
  }


  onSaveClick() {
    this.userPreferences.patchUserPreferences();
  }
}
