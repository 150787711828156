// Angular
import { Component, ViewChild, ViewEncapsulation, Input, effect, computed, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

// 3rd Party
import { AccordionModule } from '@syncfusion/ej2-angular-navigations';

// Models
import { Appointment, CaseFile } from '@models/data-contracts';

// Services
import { SchedulerService } from '@services/scheduler/scheduler.service';
import { SchedulerSignalsService } from '@root/src/app/shared/services/scheduler/scheduler-signals.service';

// Components
import { SchedulerLocationsComponent } from './scheduler-locations/scheduler-locations.component';
import { SchedulerModalitiesComponent } from './scheduler-modalities/scheduler-modalities.component';
import { SchedulerCalendarComponent } from './scheduler-calendar/scheduler-calendar.component';
import { SchedulerAppointmentsListComponent } from './scheduler-appointments-list/scheduler-appointments-list.component';


@Component({
  selector: 'scheduler',
  standalone: true,
  imports: [
    CommonModule,
    AccordionModule,
    SchedulerLocationsComponent,
    SchedulerModalitiesComponent,
    SchedulerCalendarComponent,
    SchedulerAppointmentsListComponent
  ],  
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SchedulerComponent {
  
  constructor(
    public schedulerSignals: SchedulerSignalsService
  ) { 
    effect(() => {
      const locations = this.schedulerSignals.data.Locations();
      const modalities = this.schedulerSignals.data.Modalities();

      if (locations && modalities) {
        this.loadLocations = signal(locations.length <= 1 ? false : true);
        this.loadModalities = signal(modalities.length <= 1 ? false : true);
        this.schedulerClass = this.loadLocations() && this.loadModalities() ? 'col-12 col-md-10 calendar-content' : 'col-12 calendar-content';
      }
    });
  }

  // Decorator variables
  @Input() appointments: Appointment[];
  @Input() caseFile: CaseFile | undefined;
  @Input() excludeFields: string[] = [];
  @Input() includeAppointmentsList: boolean = false;
  @ViewChild('locationsComponent') locationsComponent: SchedulerLocationsComponent;
  @ViewChild('modalitiesComponent') modalitiesComponent: SchedulerModalitiesComponent;
  @ViewChild('scheduleFilter') scheduleFilter: AccordionModule;
  @ViewChild('schedulerCalendarComponent') schedulerCalendarComponent: SchedulerCalendarComponent;

  // Signals
  protected readonly locations = computed(() => this.schedulerSignals.data.Locations());
  protected readonly modalities = computed(() => this.schedulerSignals.data.Modalities());
  protected readonly modalityTypes = computed(() => this.schedulerSignals.data.ModalityTypes());

  // Public properties
  schedulerClass: string = 'col-12 calendar-content';
  loadLocations = signal(false);
  loadModalities = signal(false);

  ngOnInit() {
    this.initializeCalendarData();
  }
  
  async initializeCalendarData() {
    // Load dependencies first, then appointments
    await Promise.all([
      this.schedulerSignals.fetchLocations(),
      this.schedulerSignals.fetchModalityTypes(),
      this.schedulerSignals.fetchModalities(),
      this.schedulerSignals.fetchAppointments()
    ]);
  }

  // Add method to handle appointment selection
  onAppointmentSelected(appointment: Appointment) {
    if (this.schedulerCalendarComponent) {
      const selectedDate = new Date(appointment.StartDatetime as Date);
      this.schedulerCalendarComponent.selectedDate = selectedDate;
    }
  }
}