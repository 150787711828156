<div class="audit-logs">
  @if (loading) {
    <loading-overlay [loading]="loadingMessage"></loading-overlay>
  } @else {
    <table class="e-table">
      <thead>
        <tr>
          <th>Action</th>
          <th>Type</th>
          <th>User</th>
          <th>Date/Time</th>
        </tr>
      </thead>
      <tbody>
        @for (log of logs; track $index) {
          <tr class="log-row" 
              [class.expanded]="isRowExpanded($index)"
              (click)="toggleRow($index)">
            <td>
              <span class="e-icons">{{isRowExpanded($index) ? '-' : '+'}}</span>
            </td>
            <td>{{log.type}}</td>
            <td>{{log.user}}</td>
            <td>{{log.dateTime | date:'MM-dd-yyyy h:mm a'}}</td>
          </tr>
          @if (isRowExpanded($index)) {
            <tr>
              <td colspan="4">
                <table class="changes-table">
                  <thead>
                    <tr>
                      <th>Property</th>
                      <th>Old Value</th>
                      <th>New Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    @for (change of getChangedProperties(log.oldValues, log.newValues); track change.property) {
                      <tr>
                        <td>{{change.property}}</td>
                        <td><pre>{{formatValue(change.oldValue)}}</pre></td>
                        <td><pre>{{formatValue(change.newValue)}}</pre></td>
                      </tr>
                    }
                  </tbody>
                </table>
              </td>
            </tr>
          }
        }
      </tbody>
    </table>
  }
</div>