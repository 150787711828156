// Angular
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

// 3rd Party
import { DataManager, Query, Predicate } from '@syncfusion/ej2-data';
import { CommandClickEventArgs, CommandModel, GridModel, RowSelectEventArgs } from '@syncfusion/ej2-grids';
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';

// Internal
import { GlobalsService } from '@services/globals/globals.service';
import { ApiService } from '@services/api/api.service';
import { APIEndpoints } from '@models/api/Endpoints';
import { FeeSchedule } from '@models/data-contracts';
import { GridTemplateComponent } from '@grids/grid-template/grid-template.component';
import { GridTemplateModule } from '@modules/grid-template.module';
import { CustomAssignmentsEditorComponent } from '@grids/custom-assignments-editor/custom-assignments-editor.component';
import { AddFeeScheduleForm } from '@root/src/app/components/forms/add-forms/add-fee-schedule-form/add-fee-schedule-form.component';

@Component({
  selector: 'fee-schedule-grid',
  standalone: true,
  imports: [
    DialogModule,
    GridTemplateModule,
    CustomAssignmentsEditorComponent,
    AddFeeScheduleForm
  ],
  templateUrl: './fee-schedule-grid.component.html',
  styleUrl: './fee-schedule-grid.component.scss'
})

export class FeeScheduleGridComponent implements OnInit {
  
  constructor(
    public globals: GlobalsService,
    private api: ApiService,
    private router: Router
  ) {}

  // General variables
  public customEditorVisible: boolean = false;

  // Grid variables
  @ViewChild('feeSchedulesGrid') public feeSchedulesGrid: GridTemplateComponent;
  @ViewChild('providersGrid') public providersGrid: GridTemplateComponent;
  @ViewChild('assignedProvidersGrid') public assignedProvidersGrid: GridTemplateComponent;
  @ViewChild('customProvidersEditor') public customProvidersEditor: CustomAssignmentsEditorComponent;
  public feeSchedulesGridSettings: GridModel;
  public availableProvidersGridSettings: GridModel;
  public assignedProvidersGridSettings: GridModel;
  public attachedProvidersData: DataManager = this.api.getOdata(APIEndpoints.Providers);
  public attachedProvidersPredicate: Predicate = new Predicate('Name', 'isnotnull', true);
  public providersEditorVisible: boolean = false;
  public assignedProvidersIds: any;
  public commands: CommandModel[] = [
    { buttonOption: { iconCss: 'e-icons e-copy', cssClass: 'e-flat' }, title: 'Clone' }, 
    { buttonOption: { iconCss: 'e-icons e-open-link', cssClass: 'e-flat' }, title: 'Open' }, 
  ];

  // Dialog variables
  @ViewChild('addFeeScheduleDialog', { static: true}) public addFeeScheduleDialog: DialogComponent;
  @ViewChild('addFeeScheduleForm') public addFeeScheduleForm: any;
  public isAddFeeScheduleDialogVisible: boolean = false;

  ngOnInit(): void {
    this.feeSchedulesGridSettings = {
      dataSource: this.api.getOdata(`${APIEndpoints.FeeSchedules}`),
      // dataSource: this.api.getOdata(APIEndpoints.FeeSchedules), // TEMP DISABLED: need updated list of FeeSchedules before re-activating
      allowFiltering: false,
      toolbar: this.globals.isResponsive ? 
        [{ text: 'Add Fee Schedule', tooltipText: 'Add Schedule', id: 'AddSchedule' }, 'Edit', 'Update', 'Cancel', 'Delete'] : 
        [{ text: 'Add Fee Schedule', tooltipText: 'Add Schedule', id: 'AddSchedule' }, 'Update', 'Cancel', 'Delete', 'ColumnChooser'],
      columns: [
        { field: 'Id', isPrimaryKey: true, visible: false },
        { field: 'Name' },
        // { field: 'XrefCPTCodes', headerText: 'Total CPTs', valueAccessor: (field: string, data: Object): string[] => this.countFieldItems(field, data) },
        // { field: 'XrefProviders', headerText: 'Total Providers', valueAccessor: (field: string, data: Object): string[] => this.countFieldItems(field, data)  },
        { field: 'CreatedAt', headerText: 'Created Date' },
        { field: 'CreatedBy', headerText: 'Created By' },
        { field: 'UpdatedAt', headerText: 'Modified Date' },
        { field: 'UpdatedBy', headerText: 'Modified By' },
        { type: 'commands', commands: this.commands, headerText: 'Actions', visible: this.globals.isResponsive ? false : true }
      ],
      toolbarClick: ($event: Event) => this.onToolbarClick($event),
      rowSelected: ($event: RowSelectEventArgs) => this.feeSchedulesGridRowSelected($event),
      commandClick: ($event: CommandClickEventArgs) => this.commandClick($event)
    };

    this.availableProvidersGridSettings = {
      dataSource: this.api.getOdata(APIEndpoints.Providers),
      query: new Query(),
      pageSettings: { pageSize: 7, pageCount: 2 },
      allowFiltering: true,
      allowPaging: false,
      selectionSettings: { mode: 'Row', type: 'Multiple' },
      editSettings: { allowEditing: false, allowAdding: true, allowDeleting: true },
      columns: [
        { field: 'Id' }, 
        { type: 'checkbox', width: 25, textAlign: 'Center', allowFiltering: false },
        { field: 'Name', allowFiltering: true }
      ]
    };

    this.assignedProvidersGridSettings = {
      dataSource: this.api.getOdata(APIEndpoints.Providers),
      query: new Query(),
      pageSettings: { pageSize: 7, pageCount: 2 },
      allowFiltering: true,
      allowPaging: false,
      selectionSettings: { mode: 'Row', type: 'Multiple' },
      editSettings: { allowEditing: false, allowAdding: true, allowDeleting: true },
      columns: [
        { field: 'Id' }, 
        { type: 'checkbox', width: 25, textAlign: 'Center', allowFiltering: false },
        { field: 'Name', allowFiltering: true }
      ],
    };
  }

  // Return total items as string to display in feeSchedulesGrid
  countFieldItems(field: string, data: object): string[] {

    // Ensure data exists
    if ((data as any)[field]) {
      const items = (data as any)[field];      
      return [items.length.toString()];
    } else {
      return ['0'];
    }
  }

  // Update height before modal opened
  beforeOpenAddFeeScheduleDialog(args: any) {
    args.maxHeight = '85vh';
  }

  // Add logic for custom Add Fee Schedule button
  onToolbarClick(args: any): void {

    if (args.item.id === 'AddSchedule') {
      this.addFeeScheduleDialog.show();
    } 
  }

  // Update grids for custom editing
  feeSchedulesGridRowSelected(rowArgs: RowSelectEventArgs) {
    const rowData = rowArgs?.data as any;

    // Ensure necessary data exists for clicked provider
    if (rowData?.XrefProviders) {
      this.providersEditorVisible = true;
      this.assignedProvidersIds = rowData.XrefProviders.map((provider: any) => provider.Id);

      rowData.XrefProviders.forEach((provider: any, index: number) => {

        if (index === 0) {
          this.attachedProvidersPredicate = new Predicate('Id', 'equal', provider.ProviderId);
        } else {
          this.attachedProvidersPredicate = this.attachedProvidersPredicate.or('Id', 'equal', provider.ProviderId);
        }
      });

      // this.attachedProvidersGrid.grid.query = new Query().where(this.attachedProvidersPredicate);
      this.customProvidersEditor.availableGrid.grid.refresh();
    }
  }

   // Add logic for actions on individual rows
  commandClick(commandClickArgs: CommandClickEventArgs) {
    const rowData: FeeSchedule | undefined = (commandClickArgs?.rowData as FeeSchedule);

    if (rowData && rowData.Id) {
      const cloneRowClicked: boolean | undefined = commandClickArgs.commandColumn?.buttonOption?.iconCss?.includes('e-copy');
      const openFeeScheduleClicked: boolean | undefined = commandClickArgs.commandColumn?.buttonOption?.iconCss?.includes('e-open-link');

      if (cloneRowClicked) {
        this.cloneRow(commandClickArgs);
      }

      if (openFeeScheduleClicked) {
        this.router.navigate([`${this.router.url}/${rowData.Id}`]);
      }
    }

  }

  // Clones rowData and appends new row
  cloneRow(rowArgs: any) {
    const rowData = rowArgs?.rowData as any;

    if (rowData) {
      this.feeSchedulesGrid.grid.addRecord(rowData);
    }
  }
}
