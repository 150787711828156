// Angular
import { Component, Output, EventEmitter, Input, ViewChild, WritableSignal, signal } from '@angular/core';
import { ReactiveFormsModule, FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ComponentBase } from '@core/base/component.base';
import { inject } from '@angular/core';

// 3rd Party
import { GridModule, PageService, SortService, FilterService, GroupService } from '@syncfusion/ej2-angular-grids';
import { GridModel } from '@syncfusion/ej2-grids';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { createSpinner } from '@syncfusion/ej2-angular-popups';
import { showSpinner, hideSpinner } from '@syncfusion/ej2-popups';
import { ChangeEventArgs, DropDownListModule, DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { TextBoxModule, TextBoxComponent, MaskedTextBoxModule, NumericTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { RichTextEditorAllModule, ToolbarService, LinkService, ImageService, HtmlEditorService } from '@syncfusion/ej2-angular-richtexteditor';
import { Query } from '@syncfusion/ej2-data';

// Models
import config from '@app/config';
import { states } from '@models/global-vars';
import { BoxSignTags } from '@models/components/box-sign.model';
import { APIEndpoints, BoxEndpoints } from '@models/api/Endpoints';

// Services
import { BoxService } from '@services/box/box.service';
import { FileHubService } from '@features/file-hub/services/file-hub.service';
import { ApiService } from '@services/api/api.service';

// Components
import { LoadingModule } from '@modules/loading.module';
import { RichTextEditorComponent } from '@components/ui/custom-rich-text-editor/rich-text-editor.component';

// Add error constants
const ERRORS = {
  PROVIDERS: {
    LOAD_FAILED: {
      message: 'Failed to load providers',
      technical: 'Error loading providers from API'
    }
  },
  CONTACTS: {
    LOAD_FAILED: {
      message: 'Failed to load provider contacts',
      technical: 'Error loading provider contacts from API'
    }
  },
  TEMPLATE: {
    LOAD_FAILED: {
      message: 'Error loading template data',
      technical: 'Failed to load template info'
    }
  },
  VALIDATION: {
    REQUIRED_FIELDS: {
      message: 'All fields required!',
      technical: 'Form validation failed - missing required fields'
    }
  }
};

@Component({
  selector: 'box-sign',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    DropDownListModule,
    TextBoxModule,
    RichTextEditorAllModule,
    DatePickerModule,
    MaskedTextBoxModule,
    NumericTextBoxModule,
    ButtonModule,
    GridModule,
    CommonModule,
    RichTextEditorComponent,
    LoadingModule
  ],
  templateUrl: './box-sign.component.html',
  styleUrl: './box-sign.component.scss',
  providers: [
    ToolbarService,
    LinkService,
    ImageService,
    HtmlEditorService,
    PageService,
    SortService,
    FilterService,
    GroupService
  ]
})
export class BoxSignComponent extends ComponentBase {

  // Decorator properties
  @Input() caseFileId: any;
  @Input() fileNumber?: string | null;
  @Output() folder = new EventEmitter<any>();
  @Output() template = new EventEmitter<any>();
  @Output() formValues = new EventEmitter<any>();
  @ViewChild('signTemplates') signTemplates: DropDownListComponent;
  @ViewChild('emailSubject') emailSubject: TextBoxComponent;
  @ViewChild('emailMessage') emailMessage: RichTextEditorComponent;

  // Signals
  selectedTemplate: WritableSignal<any> = signal({});
  selectedTemplateInfo: WritableSignal<any> = signal({});
  loadingData: WritableSignal<any> = signal(false);

  // Public
  caseFileData: any;
  patientData: any;
  lawFirmData: any;
  folders: any;
  templatesList: any;
  requestsList: any;
  providersList: any;
  contactsList: any;
  caseManagersList: any;
  statesList: any;
  requestsGridLoading: boolean = true;
  requestsGrid: GridModel;
  selectedTemplateSignRequest: any;
  config = config;
  boxSignForm: FormGroup = new FormGroup({
    boxSignTemplateId: new FormControl(undefined, [Validators.required]),
    emailSubject: new FormControl(undefined, [Validators.required]),
    emailMessage: new FormControl(undefined, [Validators.required]),
    fileNumber: new FormControl(undefined, [Validators.required]),
  });
  richTextEditorTools: object = {
    type: 'MultiRow',
    items: [
      'Bold', 'Italic', 'Underline', 'StrikeThrough',
      'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
      'LowerCase', 'UpperCase', '|',
      'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
      'Outdent', 'Indent', '|',
      'CreateLink', 'Image', '|', 'ClearFormat', 'Print',
      'SourceCode', 'FullScreen', '|', 'Undo', 'Redo'
    ]
  };

  // Constructor
  constructor(
    private fileHub: FileHubService,
    private boxService: BoxService,
    private api: ApiService
  ) {
    super();
  }

  // Lifecycle Hooks
  ngOnInit() {
    this.getStates();
    this.getTemplates();
    this.loadSignRequests();
    this.loadCaseFileData();
    this.getProviders();
    this.getCaseManagers();
  }

  // Methods
  async loadCaseFileData() {
    const query = new Query()
      .expand('Patient($expand=XrefAddressPatients($expand=Address($expand=StateNavigation)),XrefPhonePatients($expand=Phone)),AttorneyNavigation,CaseManagerNavigation,LawFirmContactNavigation,PAndLNavigation,StatusingGroupNavigation,RecordStatus');

    try {
      const response: any = await this.api.getOdata(`${APIEndpoints.Casefiles}(${this.caseFileId})`).executeQuery(query);

      if (response?.result?.[0]) {
        const caseFileData = response.result[0];
        this.caseFileData = caseFileData;

        // Update each property individually
        Object.entries(caseFileData).forEach(([key, value]) => {
          this.fileHub.updateCaseFile(key, value);
        });

        // Store local references
        this.patientData = this.fileHub.caseFile?.Patient;
        this.lawFirmData = {
          attorney: this.fileHub.caseFile?.AttorneyNavigation,
          caseManager: this.fileHub.caseFile?.CaseManagerNavigation,
          contact: this.fileHub.caseFile?.LawFirmContactNavigation
        };

      } else {
        throw new Error('No case file data found');
      }
    } catch (error) {
      console.error('Error loading case file data:', error);
    }
  }

  async getTemplates() {
    try {
      await this.api.fetchRequest(`${BoxEndpoints.Templates}/by-case-id/${this.caseFileId}`, undefined, undefined, undefined, false)
        .then((templates: any) => this.templatesList = templates)
        .catch((error: Error) => {
          this.handleError(error, {
            context: 'BoxSignComponent.getTemplates',
            userMessage: 'Failed to load sign templates'
          });
        });
    } catch (error) {
      this.handleError(error, {
        context: 'BoxSignComponent.getTemplates',
        userMessage: 'Failed to load sign templates'
      });
    }
  }

  async getProviders() {
    try {
      const query = new Query()
        .expand('XrefProviderContacts($expand=Contact),CareCoordinatorNavigation')
        .where('IsActive', 'equal', true)
        .sortBy('Name', 'ascending');

      const response: any = await this.api.getOdata(APIEndpoints.Providers).executeQuery(query);

      this.providersList = response.result.map((provider: any) => ({
        ...provider,
        CareCoordinatorName: provider.CareCoordinatorNavigation?.Name || ''
      }));
    } catch (error) {
      this.handleError(error, {
        context: 'BoxSignComponent.loadProviders',
        userMessage: ERRORS.PROVIDERS.LOAD_FAILED.message
      });
    }
  }

  // Add method to handle provider selection and load contacts
  async onProviderChange(event: any) {
    const providerId = event.value;
    if (providerId) {
      // Clear existing provider contact value
      const requestDataForm = this.boxSignForm.get('requestData') as FormGroup;
      const providerContactControl = requestDataForm?.get('provider_contact');
      if (providerContactControl) {
        providerContactControl.setValue('');
      }

      // Update care coordinator value if the field exists
      const careCoordinatorControl = requestDataForm?.get('care_coordinator');
      if (careCoordinatorControl) {
        const selectedProvider = this.providersList?.find((p: any) => p.Id === providerId);
        careCoordinatorControl.setValue(selectedProvider?.CareCoordinatorName || '');
      }

      // Load contacts for selected provider with proper expansion
      try {
        const query = new Query()
          .where('Id', 'equal', providerId)
          .expand('XrefProviderContacts($expand=Contact)');

        const response: any = await this.api.getOdata(APIEndpoints.Providers).executeQuery(query);
        if (response?.result?.[0]?.XrefProviderContacts) {
          this.contactsList = response.result[0].XrefProviderContacts
            .map((xref: any) => ({
              Id: xref.Contact.Id,
              ContactName: xref.Contact.ContactName,
              ContactEmail: xref.Contact.ContactEmail
            }))
            .filter((contact: any) => contact.ContactName && contact.ContactEmail)
            .sort((a: any, b: any) => a.ContactName.localeCompare(b.ContactName));
        }
      } catch (error) {
        this.handleError(error, {
          context: 'BoxSignComponent.onProviderChange',
          userMessage: ERRORS.CONTACTS.LOAD_FAILED.message
        });
      }
    } else {
      this.contactsList = null;
    }
  }

  getContacts() {
    this.contactsList = this.api.getOdata(APIEndpoints.Contacts);
  }

  getStates() {
    this.statesList = states;
  }

  // Emit changes when Sign Template selected
  async emitDropDownSelect(args: ChangeEventArgs) {
    this.loadingData.set(true);
    try {
      // Reset form first
      this.boxSignForm.reset();

      // Emit template data
      this.template.emit(args.itemData);
      this.selectedTemplate.set(args.itemData);

      // Ensure states are loaded
      if (!this.statesList) {
        this.getStates();
      }

      const templateInfo = await this.api.fetchRequest(`${BoxEndpoints.Templates}/info/${this.selectedTemplate().id}`);
      if (!templateInfo) {
        throw new Error('No template info returned');
      }
      this.selectedTemplateInfo.set(templateInfo);

      // Build form with the template info
      this.boxSignForm = this.buildForm((templateInfo as any).boxSignTags || []);

      // Set the file number in the form
      if (this.fileNumber) {
        this.boxSignForm.patchValue({
          fileNumber: this.fileNumber
        });
      }

    } catch (templateError) {
      console.error('Error loading template info:', templateError);
      this.handleError(templateError, {
        context: 'BoxSignComponent.emitDropDownSelect',
        userMessage: 'Failed to load template information. Please try again.'
      });
      // Reset template selection on error
      this.selectedTemplate.set({});
      this.selectedTemplateInfo.set({});
    } finally {
      this.loadingData.set(false);
    }
  }

  initializeSpinner(elementId: string) {

    createSpinner({
      target: document.getElementById(elementId) as any
    });

    showSpinner((document as any).getElementById(elementId));
  }

  endSpiner(elementId: string) {
    hideSpinner((document as any).getElementById(elementId));
  }

  noRecordSpinner(args: any) {

    if (args.name === 'beforeOpen') {

      // Check for populated values and set timeout to ensure #noRecordsTemplate visible
      if (!this.templatesList) {
        setTimeout(() => {
          this.initializeSpinner('no-record');
        }, 1);
      } else {
        setTimeout(() => {
          this.endSpiner('no-record');
        }, 1)
      }
    }
  }

  async submitForm(args: SubmitEvent) {
    this.loadingData.set(true);

    this.boxSignForm.patchValue({
      boxSignTemplateId: this.signTemplates.value,
      emailSubject: this.emailSubject.value,
      emailMessage: this.selectedTemplate().emailBody,
      fileNumber: this.fileNumber
    });

    this.markAllFieldsAsTouched();

    if (this.boxSignForm.invalid) {
      this.handleError(new Error('Form validation failed'), {
        context: 'BoxSignComponent.submitForm',
        userMessage: ERRORS.VALIDATION.REQUIRED_FIELDS.message
      });
      this.loadingData.set(false);
      return;
    }

    this.formValues.emit(this.boxSignForm.value);

    if (this.fileNumber) {
      try {
        await this.boxService.postNewSignRequest(this.boxSignForm.value);
        // Reload sign requests to show the new one
        this.loadSignRequests();
        this.loadingData.set(false);
      } catch (error) {
        this.handleError(error, {
          context: 'BoxSignComponent.submitForm',
          userMessage: 'Failed to create sign request. Please try again.'
        });
        this.loadingData.set(false);
      }
    } else {
      const fileNumberError = !this.fileNumber ? `File Number missing.<br>` : ''
      const errorMsg = `
        <strong>SIGN TEMPLATE ERROR</strong>
        <br>
        ${fileNumberError}
      `;
      this.handleError(new Error('Sign template validation failed'), {
        context: 'BoxSignComponent.submitForm',
        userMessage: errorMsg
      });
      this.loadingData.set(false);
    }
  }

  markAllFieldsAsTouched(): void {
    // Mark top-level controls
    Object.values(this.boxSignForm.controls).forEach((control: any) => {
      control.markAsTouched();
      control.markAsDirty();
      control.updateValueAndValidity();

      // If this is the requestData group, mark its controls too
      if (control instanceof FormGroup) {
        Object.values(control.controls).forEach((nestedControl: any) => {
          nestedControl.markAsTouched();
          nestedControl.markAsDirty();
          nestedControl.updateValueAndValidity();
        });
      }
    });
  }

  clearForm() {
    this.boxSignForm.reset();
  }

  removeUnderscoreFromString(sourceString: string) {
    return sourceString.replace(/_/g, ' ');
  }

  async getCaseManagers() {
    try {
      const query = new Query()
        .select('Id,Name')
        .expand('XrefUsersRoles')
        .where(`XrefUsersRoles/any(x: x/RoleId eq 3)`, 'equal', true)
        .sortBy('Name', 'ascending');

      const response: any = await this.api.getOdata(APIEndpoints.Users).executeQuery(query);
      this.caseManagersList = response.result;
    } catch (error) {
      this.handleError(error, {
        context: 'BoxSignComponent.getCaseManagers',
        userMessage: 'Failed to load case managers'
      });
    }
  }

  buildForm(dataArray: BoxSignTags[]) {
    // Start with existing form structure
    const boxSignForm: any = {
      boxSignTemplateId: new FormControl(this.signTemplates?.value || null, [Validators.required]),
      emailSubject: new FormControl(this.selectedTemplate()?.emailSubject || '', [Validators.required]),
      emailMessage: new FormControl(this.selectedTemplate()?.emailBody || '', [Validators.required]),
      fileNumber: new FormControl(this.fileNumber || '', [Validators.required])
    };

    // Build request data controls
    const requestData: any = {};
    for (const item of dataArray) {
      let defaultValue = '';
      // Pre-populate based on tag ID
      switch (item.tagId) {
        case 'client_dol':  // Date of Loss
          const dol = this.fileHub.caseFile?.DateOfLoss ? new Date(this.fileHub.caseFile.DateOfLoss) : null;
          defaultValue = dol ? dol.toLocaleDateString('en-US') : '';
          break;
        case 'date_of_authorization':
          const today = new Date();
          defaultValue = today.toLocaleDateString('en-US');
          break;
        case 'amount_authorized':
          defaultValue = '';
          break;
        case 'case_manager':
          defaultValue = String(this.fileHub.caseFile?.CaseManagerNavigation?.Id || '');
          break;
        case 'case_manager_email':
          defaultValue = this.fileHub.caseFile?.CaseManagerNavigation?.Email || '';
          break;
        case 'file_number':
          defaultValue = this.fileNumber || '';
          break;

        // Patient/Client Data
        case 'client_name':
          const firstName = this.fileHub.caseFile?.Patient?.Firstname || '';
          const lastName = this.fileHub.caseFile?.Patient?.Lastname || '';
          defaultValue = `${firstName} ${lastName}`.trim();
          break;
        case 'client_first_name':
          defaultValue = this.fileHub.caseFile?.Patient?.Firstname || '';
          break;
        case 'client_last_name':
          defaultValue = this.fileHub.caseFile?.Patient?.Lastname || '';
          break;
        case 'client_email':
          defaultValue = this.fileHub.caseFile?.Patient?.Email || '';
          break;
        case 'client_phone_number':
          const phone = this.fileHub.caseFile?.Patient?.XrefPhonePatients?.[0]?.Phone?.PhoneNumber || '';
          defaultValue = phone.replace(/\D/g, ''); // Remove non-digits
          break;
        case 'client_dob':
          const dob = this.fileHub.caseFile?.Patient?.Dob ? new Date(this.fileHub.caseFile.Patient.Dob) : null;
          defaultValue = dob ? dob.toLocaleDateString('en-US') : '';
          break;
        case 'client_address1':
          defaultValue = this.fileHub.caseFile?.Patient?.XrefAddressPatients?.[0]?.Address?.Address1 || '';
          break;
        case 'client_address2':
          defaultValue = this.fileHub.caseFile?.Patient?.XrefAddressPatients?.[0]?.Address?.Address2 || '';
          break;
        case 'client_city':
          defaultValue = this.fileHub.caseFile?.Patient?.XrefAddressPatients?.[0]?.Address?.City || '';
          break;
        case 'client_state':
          defaultValue = String(this.fileHub.caseFile?.Patient?.XrefAddressPatients?.[0]?.Address?.StateNavigation?.Code || '');
          break;
        case 'client_zip':
          defaultValue = this.fileHub.caseFile?.Patient?.XrefAddressPatients?.[0]?.Address?.Zip || '';
          break;

        // Provider Data - User Selected
        case 'provider':
          // Keep provider dropdown as is, but add change event handler in template
          requestData[item.tagId] = new FormControl(defaultValue, this.getValidatorsForField(true));
          break;
        case 'provider_contact':
          // Initialize provider contact with empty value - will be populated on provider selection
          requestData[item.tagId] = new FormControl(defaultValue, this.getValidatorsForField(true));
          break;
        case 'care_coordinator':
          // Get the selected provider's care coordinator name
          const selectedProvider = this.providersList?.find((p: any) => p.Id === this.boxSignForm.get('requestData')?.get('provider')?.value);
          defaultValue = selectedProvider?.CareCoordinatorName || '';
          requestData[item.tagId] = new FormControl(defaultValue, this.getValidatorsForField(true));
          break;

        // Law Firm Data
        case 'attorney_email':
          defaultValue = this.fileHub.caseFile?.AttorneyNavigation?.ContactEmail || '';
          break;
      }

      if (!requestData[item.tagId]) {
        requestData[item.tagId] = new FormControl(defaultValue, this.getValidatorsForField(true));
      }
    }

    // Add requestData to form structure
    boxSignForm['requestData'] = new FormGroup(requestData);

    // Create new form with all controls
    return new FormGroup(boxSignForm);
  }

  getValidatorsForField(required: boolean) {
    return required === true ? [Validators.required] : null;
  }

  loadSignRequests() {
    this.requestsGridLoading = true;
    this.api.fetchRequest(`${BoxEndpoints.SignRequests}/${this.fileNumber}`, undefined, undefined, undefined, false)
      .then((response: any) => {
        if (response) {
          this.requestsGrid = {
            dataSource: response,
            allowPaging: true,
            allowSorting: true,
            allowFiltering: true,
            filterSettings: { type: 'Excel' },
            pageSettings: { pageSize: 10 },
            hierarchyPrintMode: 'All',
            childGrid: {
              allowPaging: true,
              pageSettings: { pageSize: 5 },
              columns: [
                { field: 'signerEmail', headerText: 'Email', width: 200 },
                { field: 'signerRole', headerText: 'Role', width: 150 },
                { field: 'order', headerText: 'Order', width: 100 },
                { field: 'hasViewedDocument', headerText: 'Viewed', width: 100 },
                { field: 'hasSignedDocument', headerText: 'Signed', width: 100 },
                { field: 'dateSigned', headerText: 'Date Signed', width: 150, type: 'date', format: 'MM/dd/yyyy' }
              ]
            },
            columns: [
              { type: 'expand', width: 50 },
              { field: 'requestId', headerText: 'Request ID', visible: false },
              { field: 'requestName', headerText: 'Document Name', width: 400 },
              {
                field: 'createdAt',
                headerText: 'Created',
                width: 150,
                type: 'date',
                format: 'MM/dd/yyyy'
              },
              {
                field: 'status',
                headerText: 'Status',
                width: 150,
                template: (args: any) => {
                  const statusClass = this.getStatusClass(args.status);
                  return `<span class="${statusClass}">${args.status || ''}</span>`;
                }
              }
            ]
          };
        }
      })
      .catch((error: Error) => {
        console.error('Error loading sign requests:', error);
        this.handleError(error, {
          context: 'BoxSignComponent.loadSignRequests',
          userMessage: 'Failed to load previous sign requests'
        });
      })
      .finally(() => {
        this.requestsGridLoading = false;
      });
  }

  getStatusClass(status: string): string {
    if (!status) return 'text-secondary';

    switch (status.toLowerCase()) {
      case 'completed':
        return 'text-success';
      case 'expired':
        return 'text-danger';
      case 'pending':
        return 'text-warning';
      default:
        return 'text-secondary';
    }
  }
}
