<div id="recent-activity-grid">
    <ng-container *ngIf="loadingData(); else ready">
        <loading-overlay [loading]="loadingData" [text]="'Loading recent activity...'" />
    </ng-container>
    
    <ng-template #ready>
        <grid-template #recentActivityGrid 
            [name]="'Recent Activity'"
            [settings]="recentActivityGridSettings" />
    </ng-template>
</div> 