// Angular
import { Component, computed, effect, EventEmitter, HostListener, Input, Output, Signal, signal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

// 3rd Party
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { TextBoxAllModule, MaskedTextBoxAllModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownListAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { TooltipAllModule } from '@syncfusion/ej2-angular-popups';
import { Query } from '@syncfusion/ej2-data';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faPhone, faTableList } from '@fortawesome/free-solid-svg-icons';

// Models
import { Phone } from '@models/data-contracts';
import { APIEndpoints } from '@models/api/Endpoints';

// Services
import { ApiService } from '@services/api/api.service';
import { FormCrudService } from '@services/forms/form-crud.service';

// Components
import { ComponentBase } from '@core/base/component.base';

// Type & Interface setup
type PhoneFormControls = {
  [K in keyof Phone]: FormControl<Phone[K] | null>;
};

// Add error constants at the top
const ERRORS = {
  PHONE_FORM: {
    FORM_PRISTINE: {
      message: 'No changes',
      technical: 'Form detected no changes to save'
    },
    FORM_VALIDATION_FAILED: {
      message: 'Please check form for errors',
      technical: 'Form validation failed'
    },
    FORM_SUBMIT_FAILED: {
      message: 'Unable to save phone number',
      technical: 'Form submission failed'
    }
  }
} as const;

export interface PhoneFormResult {
  status: 'success' | 'error' | 'no-change';
  message?: string;
  data?: Phone;
}

@Component({
  selector: 'phone-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    TextBoxAllModule,
    MaskedTextBoxAllModule,
    DropDownListAllModule,
    TooltipAllModule,
    FontAwesomeModule
  ],
  templateUrl: './phone-number-form.component.html',
  styleUrl: './phone-number-form.component.scss'
})
export class PhoneFormComponent extends ComponentBase {

  constructor(
    private api: ApiService,
    private formCRUD: FormCrudService
  ) {
    super();

    // Use effect to react to phone signal changes
    effect(() => {
      const currentPhone = this.phone();

      if (currentPhone) {
        // Update form with new values
        this.phoneForm.patchValue(currentPhone, { emitEvent: false });
      } else {
        // Reset form if phone is undefined
        this.phoneForm.reset({}, { emitEvent: false });
      }
    });
  }

  // Decorator variables
  @Input() phone!: WritableSignal<Phone | undefined>;
  @Input() submitType?: 'POST' | 'PATCH' | 'DELETE';
  @Input() title: string = 'Phone';
  @Input() displayFields: string[] = ['PhoneNumber', 'PhoneType'];
  @Input() displayButtons: boolean = true;
  @Input() displayTitle?: boolean;
  @Input() formMainTitle?: string;
  @Input() requiredFields: string[] = [];
  @Output() formSubmitted = new EventEmitter<void>();
  @Output() formResult = new EventEmitter<PhoneFormResult>();

  // Form state
  protected readonly formState = computed(() => {
    const currentPhone = this.phone ? this.phone() : this.phoneForm.value;
    return {
      isValid: this.phoneForm.valid,
      hasChanges: currentPhone !== this.phoneForm.value,
      currentValue: currentPhone
    };
  });

  // Use computed for form validation messages
  protected readonly errorMessages = computed(() => {
    const errors: Record<string, string> = {};
    Object.keys(this.phoneForm.controls).forEach(key => {
      const control = this.phoneForm.get(key);
      if (control?.errors) errors[key] = this.getErrorMessage(key);
    });
    return errors;
  });

  // Public variables
  loadingForm: boolean = true;
  errorMessage: string = '';
  phoneHTMLElement: Element | null = null;
  phoneTypes: any;
  phoneTypesQuery: Query;
  phoneTypeValue: any;
  phoneTypeLabel: string = 'Phone Number';
  phoneForm = new FormGroup<PhoneFormControls>({
    Id: new FormControl<Phone['Id']>(undefined),
    PhoneNumber: new FormControl<Phone['PhoneNumber']>(undefined, [Validators.required, this.validateTenDigitPhone()]),
    PhoneType: new FormControl<Phone['PhoneType']>(undefined, [Validators.required])
  });
  phoneIcons = {
    form: faPhone,
    phoneNumber: faPhone,
    phoneType: faTableList
  }
  formClasses = {
    formContainer: 'cc-form-container',
    form: window.innerWidth <= 768 ? 'cc-form flex-column' : 'cc-form flex-row flex-wrap',
    mainTitle: 'cc-main-title',
    subForm: 'cc-sub-form',
    section: 'cc-form-section',
    group: 'cc-form-group row',
    inputContainer: 'cc-input-container',
    label: 'cc-label',
    input: 'cc-input',
    icon: 'cc-input-icon',
    error: 'cc-input-error',
    errorMessage: 'cc-error-message',
    actions: 'cc-form-actions',
    twoCol: window.innerWidth <= 768 ? 'col-12' : 'col-6',
    threeCol: window.innerWidth <= 768 ? 'col-12' : window.innerWidth <= 768 && window.innerWidth <= 992 ? 'col-6' : 'col-4'
  };

  // Lifecycle hooks
  ngAfterViewInit() {
    this.phoneHTMLElement = document.querySelector('phone-form');
    this.updateWidth(this.phoneHTMLElement?.clientWidth || window.innerWidth);
    this.watchInputElements();
  }

  // On form submit
  async onSubmit(): Promise<boolean | Phone> {
    let phoneResult;

    try {
      const submitType = this.submitType || (this.phoneForm.get('Id')?.value ? 'PATCH' : 'POST');
      const endpoint = this.phoneForm.get('Id')?.value ? `${APIEndpoints.Phones}/${this.phoneForm.get('Id')?.value}` : APIEndpoints.Phones;

      // Check if user made any changes first - exit if none
      if (this.phoneForm.pristine) {
        this.notify('No phone number changes.', this.NotificationSeverity.Warning);
        return false;
      }

      // Check validity
      this.markAllFieldsAsTouched();
      if (this.phoneForm.invalid) {
        this.handleError(ERRORS.PHONE_FORM.FORM_VALIDATION_FAILED, {
          context: 'PhoneForm.onSubmit',
          userMessage: ERRORS.PHONE_FORM.FORM_VALIDATION_FAILED.message,
          severity: this.ErrorSeverity.Error
        });
        return false;
      }

      // Submit form
      phoneResult = await this.formCRUD.submitForm(this.phoneForm, `odata${endpoint}`, submitType);
      return phoneResult as Phone;

    } catch (error) {
      this.handleError(ERRORS.PHONE_FORM.FORM_SUBMIT_FAILED, {
        context: 'PhoneForm.onSubmit',
        userMessage: ERRORS.PHONE_FORM.FORM_SUBMIT_FAILED.message,
        severity: this.ErrorSeverity.Error
      });
      throw error;
    }
  }

  markAllFieldsAsTouched(): void {
    Object.values(this.phoneForm.controls).forEach(control => {
      control.markAsTouched();
      control.markAsDirty();
      control.updateValueAndValidity();
    });
  }

  // Update the layout on window resize
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    const containerWidth = this.phoneHTMLElement?.clientWidth || window.innerWidth;
    this.updateWidth(containerWidth);
  }

  // Switches form to 2 columns when parent is larger 1600px
  updateWidth(containerWidth: number) {

    if (containerWidth > 576 && containerWidth < 768) {
      this.formClasses.form = this.formClasses.form.replace('flex-column', 'flex-row flex-wrap');
      this.formClasses.twoCol = this.formClasses.twoCol.replace('col-12', 'col-6');
      this.formClasses.threeCol = this.formClasses.threeCol.replace('col-12', 'col-6');
    } else if (containerWidth > 768) {
      this.formClasses.form = this.formClasses.form.replace('flex-column', 'flex-row flex-wrap');
      this.formClasses.twoCol = this.formClasses.twoCol.replace('col-12', 'col-6');
      this.formClasses.threeCol = this.formClasses.threeCol.replace('col-12', 'col-4');
    } else {
      this.formClasses.form = this.formClasses.form.replace('flex-row', 'flex-column');
      this.formClasses.twoCol = this.formClasses.twoCol.replace('col-6', 'col-12');
      this.formClasses.threeCol = this.formClasses.threeCol.replace('col-4', 'col-12');
    }
  }

  // Perform updates on input elements
  watchInputElements() {
    document.querySelectorAll(`.cc-input-container`).forEach((inputContainer) => {
      const container = inputContainer as HTMLElement;

      container.querySelectorAll('input, span').forEach((input) => {
        input.addEventListener('focus', () => inputContainer.classList.add('focus'));
        input.addEventListener('blur', () => inputContainer.classList.remove('focus'));
      });
    });
  }

  // Returns appropriate error message for form control
  getErrorMessage(controlName: string): string {
    const control = this.phoneForm.get(controlName);
    if (control?.errors) {
      if (control.errors['required']) return 'This field is required';
      if (control.errors['phoneLength']) return 'Enter valid phone number';
    }
    return '';
  }

  async onPhoneTypesCreated(args: any) {
    const endpoint = APIEndpoints.PhoneTypes;
    const query = new Query();

    await this.api.getOdata(endpoint).executeQuery(query).then((res: any) => {
      const types = res.result;

      this.phoneTypes = types;

      const currentPhoneType = this.phoneForm.get('PhoneType')?.value;

      if (currentPhoneType) {
        const description = types.find((type: any) => type.Id === currentPhoneType)?.Description;
        this.phoneTypeLabel = description ? `${description} Number` : 'Phone Number';
        this.phoneTypeValue = currentPhoneType;
      }
    });
  }

  onPhoneTypeChange(args: any) {
    if (!args || !this.phoneTypes) return;

    const phoneType = args.value;
    const phoneTypeDesc = this.phoneTypes.find((type: any) => type.Id === phoneType)?.Description;
    this.phoneTypeLabel = phoneTypeDesc ? `${phoneTypeDesc} Number` : 'Phone Number';
  }

  validateTenDigitPhone(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) return null;

      // Remove any non-digit characters
      const digits = control.value.replace(/\D/g, '');
      return digits.length === 10 ? null : { phoneLength: true };
    };
  }
}
