<div id="comments-grid">
    <ng-container *ngIf="loadingData(); else ready">
        <loading-overlay [loading]="loadingData" [text]="'Loading comments...'" />
    </ng-container>

    <ng-template #ready>
        <basic-grid [gridSettings]="commentsGridSettings" />

            <!-- Comment Form Dialog -->
        <ejs-dialog #formDialog
            [isModal]="true"
            [visible]="false"
            [showCloseIcon]="true"
            [width]="800"
            [buttons]="dialogButtons">
            <comment-form #commentsFormComponent
                [entityType]="'CaseFile'"
                [entityId]="caseFileId"
                (formSubmitted)="submitCommentForm()"
                (formCancelled)="cancelCommentForm()">
            </comment-form>
        </ejs-dialog>
    </ng-template>
</div>
