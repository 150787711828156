import { Component, ViewChild } from '@angular/core';
import { confirmSignIn, setUpTOTP } from '@aws-amplify/auth';
import { FormValidators, TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Dialog } from '@syncfusion/ej2-popups';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { ButtonModule, SwitchModule } from '@syncfusion/ej2-angular-buttons';
import { Spacer } from "../../../../ui/spacer/spacer.component";

@Component({
  selector: 'app-change-mfa-preference',
  standalone: true,
  imports: [
    TextBoxModule,
    ReactiveFormsModule,
    DialogModule,
    SwitchModule,
    ButtonModule,
    Spacer
  ],
  templateUrl: './change-mfa-preference.component.html',
  styleUrl: './change-mfa-preference.component.scss'
})
export class ChangeMfaPreferenceComponent {
  formGroup: FormGroup = new FormGroup({ code: new FormControl<string>('', [FormValidators.required]), }, {});
  setupCode: string;
  isCompleted: boolean;

  @ViewChild('Modal') modal: Dialog;

  ngOnInit(): void {
    this.setupTOTPCode();
  }

  async setupTOTPCode() {
    const totpSetupDetails = await setUpTOTP();
    this.setupCode = totpSetupDetails.sharedSecret
  }

  handleTOTPsetup() {
    confirmSignIn({
      challengeResponse: this.formGroup.value.code
    });
  }

  showModal() {
    this.modal.show();
  }

  beforeModalOpened(args: any) {
    args.maxHeight = '85vh';
  }
}
