import { Injectable, OnDestroy } from '@angular/core';
import { fetchAuthSession } from '@aws-amplify/auth';
import { ToastMessageService } from '@services/toast-message/toast-message.service';

@Injectable({
  providedIn: 'root'
})
export class TokenRefreshService implements OnDestroy {
  private refreshInterval: any;
  private readonly REFRESH_INTERVAL = 25 * 60 * 1000; 

  constructor(
    private toast: ToastMessageService
  ) {
    this.startTokenRefresh();
  }

  ngOnDestroy() {
    this.stopTokenRefresh();
  }

  private startTokenRefresh() {
    // Initial refresh
    this.refreshToken();
    
    // Set up periodic refresh
    this.refreshInterval = setInterval(() => {
      this.refreshToken();
    }, this.REFRESH_INTERVAL);
  }

  private stopTokenRefresh() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  }

  private async refreshToken() {
    console.log('Refreshing token...');
    try {
      await fetchAuthSession({ forceRefresh: true });
    } catch (error) {
      console.error('Token refresh failed:', error);
      // Only show error if it's not a normal session expiration
      if (error instanceof Error && !error.message.includes('session expired')) {
        this.toast.showError('Session refresh failed. You may need to log in again.');
      }
    }
  }
} 