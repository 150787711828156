<!-- Contact Form Start -->
<div id="contact-form" [class]="formClasses.formContainer">
    <!-- Add loading overlay -->
    <div *ngIf="loadingStatus().loading" [class]="'loading-overlay ' + loadingStatus().action">
        <div [class]="'loading-content ' + loadingStatus().action">
            <div class="spinner"></div>
            <div class="loading-message">{{ loadingStatus().message }}</div>
        </div>
    </div>

    <h2 [class]="formClasses.mainTitle" *ngIf="displayTitle">
        <fa-icon [icon]="contactIcons.form" />
        <span>{{formMainTitle ?? 'Contact Information'}}</span>
    </h2>

    <div class="d-flex align-items-center gap-3 mb-4">
        <app-select-contact 
            *ngIf="contactList()"
            [contacts]="contactList()"
            [showSelector]="!contact() && !isNewContact"
            (contactSelected)="onContactSelected($event)"
            [class]="formClasses.inputContainer" />
        
        <button ejs-button 
            type="button" 
            [isPrimary]="showForm"
            (click)="showForm = !showForm">
            {{showForm ? 'Hide Form' : 'Show Form'}}
        </button>
    </div>

    <div [class.d-none]="!showForm">
        <form [formGroup]="contactForm" [class]="formClasses.form" (ngSubmit)="onSubmit()">

            <section [class]="formClasses.section + ' ' + formClasses.twoCol + ' contact-section'"
                     [ngClass]="{'centered-section': !isLawFirm}">

                <div [class]="formClasses.group">
                    
                    <div *ngIf="displayFields.includes('ContactName')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol" [class.error]="contactForm.get('ContactName')?.errors && contactForm.get('ContactName')?.touched">
                        <span [class]="formClasses.icon">
                            <fa-icon [icon]="contactIcons.contactName" />
                            <ejs-tooltip *ngIf="contactForm.get('ContactName')?.errors && contactForm.get('ContactName')?.touched" [content]="getErrorMessage('ContactName')" />
                        </span>
                        <ejs-textbox formControlName="ContactName"
                            placeholder="Contact Name"
                            floatLabelType="Auto"
                            [class]="formClasses.input" />
                            <ng-container *ngIf="contactForm.get('ContactName')?.invalid && contactForm.get('ContactName')?.touched">
                                <span [class]="formClasses.error">{{getErrorMessage('ContactName')}}</span>
                            </ng-container>
                        </div>

                    <div *ngIf="displayFields.includes('ContactType')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol" [class.error]="contactForm.get('ContactType')?.errors && contactForm.get('ContactType')?.touched">
                        <span [class]="formClasses.icon" [ngClass]="{'error-icon': contactForm.get('ContactType')?.errors && contactForm.get('ContactType')?.touched}">
                            <fa-icon [icon]="contactIcons.contactType" />
                        </span>
                        <ejs-dropdownlist formControlName="ContactType" 
                            placeholder="Contact Type"
                            floatLabelType="Auto"
                            [class]="formClasses.input"
                            [dataSource]="contactTypes"
                            [fields]="{ text: 'Description', value: 'Id' }"
                            [value]="contactForm.get('ContactType')?.value" />
                            <ng-container *ngIf="contactForm.get('ContactType')?.invalid && contactForm.get('ContactType')?.touched">
                                <span [class]="formClasses.error">{{getErrorMessage('ContactType')}}</span>
                            </ng-container>
                    </div>

                    <div *ngIf="displayFields.includes('ContactEmail')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol" [class.error]="contactForm.get('ContactEmail')?.errors && contactForm.get('ContactEmail')?.touched">
                        <span [class]="formClasses.icon">
                            <fa-icon [icon]="contactIcons.contactEmail" />
                            <ejs-tooltip *ngIf="contactForm.get('ContactEmail')?.errors && contactForm.get('ContactEmail')?.touched" [content]="getErrorMessage('ContactEmail')" />
                        </span>
                        <ejs-textbox formControlName="ContactEmail"
                            placeholder="Email"
                            floatLabelType="Auto"
                            [class]="formClasses.input" />
                            <ng-container *ngIf="contactForm.get('ContactEmail')?.invalid && contactForm.get('ContactEmail')?.touched">
                                <span [class]="formClasses.error">{{getErrorMessage('ContactEmail')}}</span>
                            </ng-container>
                    </div>

                    <div *ngIf="displayFields.includes('ContactTitle')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol">
                        <span [class]="formClasses.icon">
                            <fa-icon [icon]="contactIcons.contactTitle" />
                        </span>
                        <ejs-textbox formControlName="ContactTitle"
                            placeholder="Title"
                            floatLabelType="Auto"
                            [class]="formClasses.input" />
                        </div>

                    <div *ngIf="displayFields.includes('Notes')" [class]="formClasses.inputContainer">
                        <span [class]="formClasses.icon">
                            <fa-icon [icon]="contactIcons.contactNotes" />
                        </span>
                        <ejs-textbox formControlName="Notes"
                            placeholder="Notes"
                            floatLabelType="Auto"
                            [multiline]="true"
                            [class]="formClasses.input" />
                    </div>

                    <section [class]="formClasses.section + formClasses.subForm + ' phones-section'">
                        <div *ngFor="let phone of contactPhones(); let i = index" [class]="formClasses.listItem">
                            <phone-form [phone]="returnPhoneSignal(phone)" [displayButtons]="false" [displayTitle]="false" />
                            <button ejs-button type="button" [class]="formClasses.removeButton" (click)="removePhone(i)">
                                <fa-icon [icon]="contactIcons.remove" />
                            </button>
                        </div>

                        <div [class]="formClasses.actions"> 
                            <button ejs-button type="button" [class]="formClasses.addButton" (click)="addNewPhone()">Add Phone Number</button>
                        </div>
                    </section>

                </div>
            </section>

            <section *ngIf="isLawFirm" [class]="formClasses.section + ' ' + formClasses.twoCol + ' ' + formClasses.subForm + ' address-section'">
                <address-form #addressFormComp 
                    [address]="contactAddress" 
                    [displayFields]="['Address1', 'Address2', 'City', 'State', 'Zip', 'County', 'AddressType']" 
                    [displayButtons]="false"   />
            </section>
            
            <section [class]="formClasses.section + ' col-12'" *ngIf="displayButtons">
                <div [class]="formClasses.actions">
                    <button ejs-button type="button" (click)="formCancelled.emit()">Cancel</button>
                    <button ejs-button [isPrimary]="true" type="submit">Submit</button>
                </div>
            </section>

        </form>
    </div>

</div>
<!-- Contact Form End -->