// Angular
import { Component } from '@angular/core';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';

// Internal
import { SvgComponent } from '@ui/svg/svg.component';

@Component({
  selector: 'app-page-not-found',
  standalone: true,
  imports: [SvgComponent, ButtonModule],
  templateUrl: './page-not-found.component.html',
  styleUrl: './page-not-found.component.scss'
})
export class PageNotFoundComponent {
  public logo: string = 'assets/logos/casecompass-logo_solid.svg';
  constructor() { }
}