// Angular
import { Component, Input, ViewChild, signal, computed } from '@angular/core';
import { ReactiveFormsModule, FormsModule, FormControl, FormGroup } from '@angular/forms';
import { CommonModule } from '@angular/common';

// 3rd Party
import { FormValidators } from '@syncfusion/ej2-angular-inputs';
import { AccordionModule } from '@syncfusion/ej2-angular-navigations';
import { ButtonModule, CheckBoxAllModule } from '@syncfusion/ej2-angular-buttons';
import { MultiSelectAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { firstValueFrom } from 'rxjs';

// Internal
import { ComponentBase } from '@core/base/component.base';
import { DropdownMultiComponent } from "@components/ui/dropdown-multi/dropdown-multi.component";
import { BalanceDueGridComponent } from "@features/financial/components/balance-statement/due/balance-due-grid.component";
import { FileHubService } from '@app/features/file-hub/services/file-hub.service';
import { BalanceStatementService } from '@features/financial/services/balance-statement.service';

interface GenerationResult {
  status: string;
  boxLink?: string;
  error?: string;
}

@Component({
  selector: 'app-generate-balance-statement',
  templateUrl: './generate-balance-statement.component.html',
  styleUrl: './generate-balance-statement.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    AccordionModule,
    CheckBoxAllModule,
    MultiSelectAllModule,
    ReactiveFormsModule,
    ButtonModule,
    DropdownMultiComponent,
    BalanceDueGridComponent,
  ]
})
export class GenerateBalanceStatementComponent extends ComponentBase {
  // State management with signals
  protected readonly loading = signal(false);
  protected readonly pandls = signal<number[]>([]);
  protected readonly hasValidFolder = computed(() =>
    Boolean(this.fileHub.caseFile?.BoxPublicFolder)
  );

  @ViewChild('BalanceDueGrid') balanceDueGrid!: BalanceDueGridComponent;
  @Input() caseFileId!: number;

  // Form configuration
  protected readonly formGroup = new FormGroup({
    pandls: new FormControl<number[]>([], [FormValidators.required])
  });

  protected readonly floatLabelType = 'Never';
  protected final = false;

  constructor(
    private balanceStatementService: BalanceStatementService,
    private fileHub: FileHubService,
  ) {
    super();
  }

  /**
   * Validates form and folder before generation
   */
  private validateGeneration(): boolean {
    if (!this.formGroup.valid) {
      throw new Error('Please select at least one P&L');
    }

    if (!this.hasValidFolder()) {
      throw new Error('No Box Public Folder found for this case file');
    }

    const selectedPandls = this.formGroup.value.pandls;
    if (!selectedPandls?.length) {
      throw new Error('Please select at least one P&L');
    }

    return true;
  }

  /**
   * Generates balance statements for the selected P&Ls
   */
  async generateBalanceStatement(): Promise<void> {
    // Validate before proceeding
    this.validateGeneration();

    this.loading.set(true);

    try {
      const result = await firstValueFrom(
        this.balanceStatementService.generate(
          !this.final,
          this.formGroup.value.pandls!
        )
      ) as GenerationResult;

      // Handle successful generation
      if (result.status === 'Done') {
        // Ensure grid refreshes
        await this.balanceDueGrid.refresh();

        // Show success with link if available
        if (result.boxLink) {
          this.notify(`
            Balance statement generated successfully
            <a href="${result.boxLink}" target="_blank" class="toast-link">
              <i class="fa fa-external-link"></i>
              View balance statement
            </a>
          `);
        } else {
          this.notify('Balance statement generated successfully');
        }
      } else {
        throw new Error(result.error || 'Failed to generate balance statement: Unexpected result status');
      }
    } finally {
      this.loading.set(false);
    }
  }
}
