// Angular
import {
  Component,
  computed,
  effect,
  EventEmitter,
  HostListener,
  Input,
  Output,
  Signal,
  signal,
  SimpleChange,
  SimpleChanges,
  ViewChild,
  WritableSignal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

// 3rd Party
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { TextBoxAllModule, MaskedTextBoxAllModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownListAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { TooltipAllModule } from '@syncfusion/ej2-angular-popups';
import { Query, Predicate } from '@syncfusion/ej2-data';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faUser,
  faIdCard,
  faUserTag,
  faComments,
  faMapLocationDot,
  faUsersRectangle,
  faGlobe,
  faStar,
} from '@fortawesome/free-solid-svg-icons';

// Models
import { Address, LawFirm, Phone } from '@models/data-contracts';
import { APIEndpoints } from '@models/api/Endpoints';

// Services
import { ApiService } from '@services/api/api.service';
import { FormCrudService } from '@services/forms/form-crud.service';
import { LawFirmFormService } from '../services/law-firm-form.service';

// Components
import { ComponentBase } from '@core/base/component.base';
import { AddressFormComponent } from '@components/forms/address-form/address-form.component';
import { PhoneFormComponent } from '@components/forms/phone-number-form/phone-number-form.component';

// Add error constants at the top
const ERRORS = {
  LAW_FIRM_FORM: {
    ADDRESS_FETCH_FAILED: {
      message: 'Unable to load law firm address',
      technical: 'Failed to fetch address from API',
    },
    PHONE_FETCH_FAILED: {
      message: 'Unable to load phone information',
      technical: 'Failed to fetch phone details',
    },
    FORM_SUBMIT_FAILED: {
      message: 'Unable to save law firm',
      technical: 'Form submission failed',
    },
    FORM_VALIDATION_FAILED: {
      message: 'Please check form for errors',
      technical: 'Form validation failed',
    },
    LAYOUT_UPDATE_FAILED: {
      message: 'Unable to update form layout',
      technical: 'Failed to update form layout on resize',
    },
    INIT_FAILED: {
      message: 'Unable to initialize form',
      technical: 'Failed to initialize form data',
    },
    FORM_PRISTINE: {
      message: 'No changes',
      technical: 'Form detected no changes to save',
    },
  },
} as const;

@Component({
  selector: 'law-firm-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    TextBoxAllModule,
    MaskedTextBoxAllModule,
    DropDownListAllModule,
    TooltipAllModule,
    FontAwesomeModule,
    AddressFormComponent,
    PhoneFormComponent,
  ],
  templateUrl: './law-firm-form.component.html',
  styleUrl: './law-firm-form.component.scss',
})
export class LawFirmFormComponent extends ComponentBase {
  // Decorator variables
  @Input() selectedAccountId?: number;
  @Input() isLawFirm?: boolean;
  @Input() lawFirm!: Signal<LawFirm | undefined>;
  @Input() submitType?: 'POST' | 'PATCH' | 'DELETE';
  @Input() hideActions: boolean = false;
  @Input() displayFields: string[] = [
    'Name',
    'Abbreviation',
    'MarketManager',
    'CaseManager',
    'StatusGroupId',
    'DocumentLink',
    'BoxFolderId',
    'Website',
    'WebsiteUrl',
    'Rating',
    'NotesImportant',
    'XrefPhoneLawfirms',
  ];
  @ViewChild('phoneFormComp') phoneFormComp!: PhoneFormComponent;
  @ViewChild('addressFormComp') addressFormComp!: AddressFormComponent;

  // States
  loadingStatus: WritableSignal<{
    loading: boolean;
    action: 'loading' | 'submitting' | 'done';
    message: string;
  }> = signal({
    loading: false,
    action: 'done',
    message: '',
  });
  protected readonly formState = computed(() => {
    const currentLawFirm = this.lawFirm();

    return {
      isValid: this.lawFirmForm.valid,
      hasChanges: currentLawFirm !== this.lawFirmForm.value,
      currentValue: currentLawFirm,
    };
  });

  // Public variables
  selectedLawFirm = signal<LawFirm | undefined>(undefined);
  lawFirmAddress = signal<Address | undefined>(undefined);
  lawFirmPhone = signal<Phone | undefined>(undefined);
  loadingForm: boolean = true;
  errorMessage: string = '';
  lawFirmHTMLElement: Element | null = null;
  fields: Object = { text: 'Name', value: 'Id' };
  formIcons = {
    faUser: faUser,
    faIdCard: faIdCard,
    faUserTag: faUserTag,
    faComments: faComments,
    faMapLocationDot: faMapLocationDot,
    faUsersRectangle: faUsersRectangle,
    faGlobe: faGlobe,
    faStar: faStar,
  };
  formClasses = {
    formContainer: 'cc-form-container',
    form: 'cc-form flex-column',
    subForm: 'cc-sub-form',
    section: 'cc-form-section',
    group: 'cc-form-group row',
    inputContainer: 'cc-input-container',
    inputContainerFullWidth: 'cc-input-container col-12',
    label: 'cc-label',
    input: 'cc-input',
    icon: 'cc-input-icon',
    error: 'cc-input-error',
    actions: 'cc-form-actions',
    twoCol: window.innerWidth <= 768 ? 'col-12' : 'col-6',
    threeCol: window.innerWidth <= 768 ? 'col-12' : 'col-4',
  };
  lawFirmForm = new FormGroup({
    Id: new FormControl<number | null>(null),
    Name: new FormControl<string | null>(null, Validators.required),
    Abbreviation: new FormControl<string | null>(null),
    MarketManager: new FormControl<number | null>(null, Validators.required),
    CaseManager: new FormControl<number | null>(null, Validators.required),
    StatusGroupId: new FormControl<number | null>(null),
    BoxFolderId: new FormControl<string | null>(null),
    Notes: new FormControl<string | null>(null),
    Website: new FormControl<string | null>(null),
    WebsiteUrl: new FormControl<string | null>(null),
    Rating: new FormControl<string | null>(null),
    IsActive: new FormControl<boolean | null>(null),
    NotifyContactOnApptDone: new FormControl<boolean | null>(null),
    NotesImportant: new FormControl<number | null>(0),
    TaxId: new FormControl<string | null>(''),
    AlternateId: new FormControl<string | null>(''),
  });

  // Replace existing managers properties with computed values
  readonly marketManagers = this.lawFirmFormService.marketManagers;
  readonly caseManagers = this.lawFirmFormService.caseManagers;

  constructor(
    private formCRUD: FormCrudService,
    private api: ApiService,
    private lawFirmFormService: LawFirmFormService
  ) {
    super();
    effect(() => {
      const lawFirm = this.lawFirm();
      if (lawFirm) {
        this.lawFirmForm.patchValue(lawFirm);
      } else {
        this.clearForm();
      }
    });

    // Single effect to handle form updates
    effect(onCleanup => {
      const { currentValue } = this.formState();

      // Watch for changes
      if (currentValue) this.updateFormStatesEffect();

      // Cleanup
      onCleanup(() => {
        this.clearForm();
        // New signal needed in effect() hook
        this.loadingStatus = signal({ loading: false, action: 'done', message: '' });
      });
    });
  }

  ngOnInit() {
    this.lawFirmFormService.loadFormData();
  }

  ngAfterViewInit() {
    this.lawFirmHTMLElement = document.querySelector('law-firm-form');
    this.updateWidth(this.lawFirmHTMLElement?.clientWidth || window.innerWidth);
    this.watchInputElements();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedAccountId'] || changes['lawFirm']) {
      this.updateFormStatesEffect();
    }
  }

  // Update states
  async updateFormStatesEffect() {
    try {
      // New signal needed to update in effect() hook - using.set() causes runtime error
      this.loadingStatus = signal({ loading: true, action: 'loading', message: 'Loading Form Data...' });

      await this.getLawFirmAddress();
      await this.getPhoneLawFirmDetails();

      this.lawFirmForm.patchValue(
        {
          ...this.formState().currentValue!,
        },
        { emitEvent: false }
      );
    } catch (error) {
      this.handleError(error, {
        context: 'LawFirmForm.updateFormStatesEffect',
        userMessage: 'Failed to load form data',
        severity: this.ErrorSeverity.Error,
      });
    } finally {
      // New signal needed to update in effect() hook - using.set() causes runtime error
      this.loadingStatus = signal({ loading: false, action: 'done', message: '' });
    }
  }

  // Perform updates on input elements
  watchInputElements() {
    document.querySelectorAll(`.cc-input-container`).forEach(inputContainer => {
      const container = inputContainer as HTMLElement;

      container.querySelectorAll('input, span, textarea').forEach(input => {
        input.addEventListener('focus', () => inputContainer.classList.add('focus'));
        input.addEventListener('blur', () => inputContainer.classList.remove('focus'));
      });
    });
  }

  // Switches form to 2 columns when parent is larger 1600px
  updateWidth(containerWidth: number) {
    try {
      if (containerWidth > 576 && containerWidth < 768) {
        this.formClasses.form = this.formClasses.form.replace('flex-column', 'flex-row flex-wrap');
        this.formClasses.twoCol = this.formClasses.twoCol.replace('col-12', 'col-6');
        this.formClasses.threeCol = this.formClasses.threeCol.replace('col-12', 'col-6');
      } else if (containerWidth > 768) {
        this.formClasses.form = this.formClasses.form.replace('flex-column', 'flex-row flex-wrap');
        this.formClasses.twoCol = this.formClasses.twoCol.replace('col-12', 'col-6');
        this.formClasses.threeCol = this.formClasses.threeCol.replace('col-12', 'col-4');
      } else {
        this.formClasses.form = this.formClasses.form.replace('flex-row', 'flex-column');
        this.formClasses.twoCol = this.formClasses.twoCol.replace('col-6', 'col-12');
        this.formClasses.threeCol = this.formClasses.threeCol.replace('col-4', 'col-12');
      }
    } catch (error) {
      this.handleError(error, {
        context: 'LawFirmForm.updateWidth',
        userMessage: ERRORS.LAW_FIRM_FORM.LAYOUT_UPDATE_FAILED.message,
        severity: this.ErrorSeverity.Warning,
        technicalDetails: { containerWidth },
      });
    }
  }

  // Update the layout on window resize
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    const containerWidth = this.lawFirmHTMLElement?.clientWidth || window.innerWidth;
    this.updateWidth(containerWidth);
  }

  // On form submit
  async onSubmit(): Promise<boolean> {
    // Set scope variables
    let lawFirmResult, phoneResult, xrefPhoneResult, addressResult, xrefAddressResult;

    try {
      const currentLawFirmId = this.lawFirmForm.get('Id')?.value;
      const currentlawFirmPhoneId = this.phoneFormComp.phoneForm.get('Id')?.value;
      const currentlawFirmAddressId = this.addressFormComp.addressForm.get('Id')?.value;
      const submitType = currentLawFirmId ? 'PATCH' : 'POST';
      const endpoint = currentLawFirmId ? `${APIEndpoints.Lawfirms}/${currentLawFirmId}` : APIEndpoints.Lawfirms;
      const phoneFormHasChanges = !this.phoneFormComp.phoneForm.pristine;
      const addressFormHasChanges = !this.addressFormComp.addressForm.pristine;
      this.loadingStatus.set({ loading: true, action: 'submitting', message: 'Validating form...' });

      // Check if editing existing law firm and no changes - exit if true
      if (
        submitType === 'PATCH' &&
        this.lawFirmForm.pristine &&
        this.phoneFormComp.phoneForm.pristine &&
        this.addressFormComp.addressForm.pristine
      ) {
        this.notify(ERRORS.LAW_FIRM_FORM.FORM_PRISTINE.message, this.NotificationSeverity.Warning);
        this.loadingStatus.set({ loading: false, action: 'done', message: '' });
        return false;
      }

      // Touch all fields for new law firms, touch all fields for existing law firms if changes
      this.markAllFieldsAsTouched();
      if (submitType === 'POST') {
        this.phoneFormComp.markAllFieldsAsTouched();
        this.addressFormComp.markAllFieldsAsTouched();
      } else {
        if (phoneFormHasChanges) this.phoneFormComp.markAllFieldsAsTouched();
        if (addressFormHasChanges) this.addressFormComp.markAllFieldsAsTouched();
      }

      // Validate law firm form - exit if errors
      if (this.lawFirmForm.invalid) {
        this.handleError(ERRORS.LAW_FIRM_FORM.FORM_VALIDATION_FAILED, {
          context: 'LawFirmForm.onSubmit',
          userMessage: ERRORS.LAW_FIRM_FORM.FORM_VALIDATION_FAILED.message,
          severity: this.ErrorSeverity.Warning,
        });
        this.loadingStatus.set({ loading: false, action: 'done', message: '' });
        return false;
      }

      // For new law firms, validate phone and address forms before submitting
      if (submitType === 'POST') {
        if (this.phoneFormComp.phoneForm.invalid) {
          this.handleError(ERRORS.LAW_FIRM_FORM.FORM_VALIDATION_FAILED, {
            context: 'LawFirmForm.onSubmit',
            userMessage: 'Please complete the phone information correctly',
            severity: this.ErrorSeverity.Warning,
          });
          this.loadingStatus.set({ loading: false, action: 'done', message: '' });
          return false;
        }

        if (this.addressFormComp.addressForm.invalid) {
          this.handleError(ERRORS.LAW_FIRM_FORM.FORM_VALIDATION_FAILED, {
            context: 'LawFirmForm.onSubmit',
            userMessage: 'Please complete the address information correctly',
            severity: this.ErrorSeverity.Warning,
          });
          this.loadingStatus.set({ loading: false, action: 'done', message: '' });
          return false;
        }
      }

      // Submit form
      this.loadingStatus.set({ loading: true, action: 'submitting', message: 'Saving law firm...' });
      lawFirmResult = await this.formCRUD.submitForm(this.lawFirmForm, `odata${endpoint}`, submitType);

      // Exit if law firm successful and no other changes
      const exitForm =
        Boolean(lawFirmResult?.Id) &&
        this.phoneFormComp.phoneForm.pristine &&
        this.addressFormComp.addressForm.pristine;
      if (exitForm) {
        this.notify('Successfully submitted law firm.', this.NotificationSeverity.Success);
        return true;
      }

      // Save phone information
      const submitPhone = Boolean(lawFirmResult?.Id) && !this.phoneFormComp.phoneForm.pristine;
      if (submitPhone) {
        this.loadingStatus.set({ loading: true, action: 'submitting', message: 'Saving phone information...' });
        this.phoneFormComp.markAllFieldsAsTouched();
        phoneResult = (await this.phoneFormComp.onSubmit()) as any;
        if (phoneResult === false) return false;
      }

      // Save address information
      const submitAddress = Boolean(lawFirmResult?.Id) && !this.addressFormComp.addressForm.pristine;
      if (submitAddress) {
        this.loadingStatus.set({ loading: true, action: 'submitting', message: 'Saving address information...' });
        this.addressFormComp.markAllFieldsAsTouched();
        addressResult = (await this.addressFormComp.onSubmit()) as any;
        if (addressResult === false) return false;
      }

      // Phone Xref - POST
      const submitXrefPhone = !currentlawFirmPhoneId && Boolean(lawFirmResult?.Id) && Boolean(phoneResult?.Id);
      if (submitXrefPhone) {
        this.loadingStatus.set({ loading: true, action: 'submitting', message: 'Linking phone...' });
        xrefAddressResult = await this.api.fetchRequest(`odata${APIEndpoints.XrefPhoneLawfirms}`, 'POST', {
          PhoneId: phoneResult.Id,
          LawfirmId: lawFirmResult.Id,
        });
      }

      // Address Xref - POST
      const submitXrefAddress = !currentlawFirmAddressId && Boolean(lawFirmResult?.Id) && Boolean(addressResult?.Id);
      if (submitXrefAddress) {
        this.loadingStatus.set({ loading: true, action: 'submitting', message: 'Linking address...' });
        xrefPhoneResult = await this.api.fetchRequest(`odata${APIEndpoints.XrefAddressLawfirms}`, 'POST', {
          AddressId: addressResult.Id,
          LawfirmId: lawFirmResult.Id,
        });
      }

      // Notify and return success
      this.notify('Successfully submitted law firm.', this.NotificationSeverity.Success);
      return true;
    } catch (error) {
      this.handleError(error, {
        context: 'LawFirmForm.onSubmit',
        userMessage: ERRORS.LAW_FIRM_FORM.FORM_SUBMIT_FAILED.message,
        severity: this.ErrorSeverity.Error,
        technicalDetails: {
          formValue: this.lawFirmForm.value,
          error,
        },
      });
      return false;
    } finally {
      this.loadingStatus.set({ loading: false, action: 'done', message: '' });
    }
  }

  // Returns appropriate error message for form control
  getErrorMessage(controlName: string): string {
    try {
      const control = this.lawFirmForm.get(controlName);
      if (!control?.errors) return '';
      if (control.errors['required']) return 'This field is required';
      if (control.errors['email']) return 'Invalid email format';
      if (control.errors['invalidPhone']) return 'Invalid phone number (10 digits required)';
      if (control.errors['serverError']) return control.errors['serverError'].message;

      return 'Invalid value';
    } catch (error) {
      this.handleError(error, {
        context: 'LawFirmForm.getErrorMessage',
        userMessage: 'Unable to get error message',
        severity: this.ErrorSeverity.Warning,
        technicalDetails: { controlName },
      });
      return 'Error validating field';
    }
  }

  markAllFieldsAsTouched(): void {
    Object.values(this.lawFirmForm.controls).forEach(control => {
      control.markAsTouched();
      control.markAsDirty();
      control.updateValueAndValidity();
    });

    // Validate fields if they have been touched
    if (!this.phoneFormComp.phoneForm.pristine) this.phoneFormComp.markAllFieldsAsTouched();
    if (!this.addressFormComp.addressForm.pristine) this.addressFormComp.markAllFieldsAsTouched();
  }

  clearForm() {
    this.lawFirmForm.reset();
    this.phoneFormComp.phoneForm.reset();
    this.addressFormComp.addressForm.reset();
  }

  // Fetches law firm address
  async getLawFirmAddress() {
    try {
      // Set scoped variables
      const endpoint = `${APIEndpoints.XrefAddressLawfirms}`;
      const lawFirmId = this.formState().currentValue?.Id as number;

      // If no lawFirmId found, do nothing and exit
      if (!lawFirmId) return;

      // Set variables for easy legibility
      const predicate = new Predicate('LawFirm/Id', 'equal', lawFirmId);
      const expandString =
        'Address($select=Id,AddressType,Address1,Address2,City,State,Zip,County),LawFirm($select=Id)';
      const query = new Query().expand(expandString).where(predicate);
      const response = await this.api.getOdata(endpoint).executeQuery(query);
      const address = (response as any).result[0]?.Address;

      // Update signals and sub form
      if (!address) this.addressFormComp.addressForm.reset();
      this.lawFirmAddress.set(address);
      this.addressFormComp.address = this.lawFirmAddress;
      return this.lawFirmAddress();
    } catch (error) {
      return this.handleError(error, {
        context: 'LawFirmForm.getLawFirmAddress',
        userMessage: ERRORS.LAW_FIRM_FORM.ADDRESS_FETCH_FAILED.message,
        severity: this.ErrorSeverity.Error,
        technicalDetails: {
          lawFirmId: this.formState().currentValue?.Id,
          error,
        },
      });
    }
  }

  async getPhoneLawFirmDetails() {
    try {
      // Set scoped variables
      const endpoint = `${APIEndpoints.XrefPhoneLawfirms}`;
      const lawfirmId = this.formState().currentValue?.Id as number;

      // If no lawFirmId found, do nothing and exit
      if (!lawfirmId) return;

      // Set new variables for easy legibility
      const predicate = new Predicate('LawFirm/Id', 'equal', lawfirmId);
      const expandString = 'Phone,LawFirm';
      const query = new Query().expand(expandString).where(predicate);
      const response = await this.api.getOdata(endpoint).executeQuery(query);
      const phone = (response as any).result.find((item: any) => item.Lawfirm.Id === lawfirmId)?.Phone;

      // Update signals and sub form
      if (!phone) this.phoneFormComp.phoneForm.reset();
      this.lawFirmPhone.set(phone);
      this.phoneFormComp.phone = this.lawFirmPhone;
      return this.lawFirmPhone();
    } catch (error) {
      return this.handleError(error, {
        context: 'LawFirmForm.getPhoneLawFirmDetails',
        userMessage: ERRORS.LAW_FIRM_FORM.PHONE_FETCH_FAILED.message,
        severity: this.ErrorSeverity.Error,
        technicalDetails: {
          lawFirmId: this.formState().currentValue?.Id,
          error,
        },
      });
    }
  }

  // Update the dropdown handlers
  beforeOpenMarketManagerDrpDwn(event: any) {
    if (!this.marketManagers()) {
      this.lawFirmFormService.loadUsers();
    }
  }

  beforeOpenCaseManagerDrpDwn(event: any) {
    if (!this.caseManagers()) {
      this.lawFirmFormService.loadUsers();
    }
  }
}
