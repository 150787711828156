<div style="height: 2em;"></div>
<div id="users-roles-grid" class="users-roles-grid row">

    @if (rightsEditorVisible) {

        <ejs-dialog #editRoleDialog
            [showCloseIcon]="true"
            [target]="'body'"
            [isModal]="true"
            [closeOnEscape]="true"
            [visible]="rightsEditorVisible"
            [header]="'Rights Editor'"
            [width]= "800"
            (close)="dialogClose()"
            (beforeOpen)="beforeOpenEditRightsDialog($event)">
            <!-- Custom rights editor -->
            <div id="rights-editor" class="rights-editor">
                <!-- Heading for selected role -->
                @if (rightsEditorVisible && rolesSelectedRowName) {
                    <h3>
                        <span>Edit Rights for: {{rolesSelectedRowName}} </span>
                        <span class="text-color_tertiary">(active rights)</span>
                    </h3>
                }
                <div class="row d-flex">
                    <!-- List of available rights -->
                    <div class="col-12 col-md-5 rights-editor-available">  
                        <grid-template #availableRightsGridTemplate [settings]="availableRightsGridSettings"></grid-template>
                    </div>
                    <!-- Custom rights editor actions -->
                    <div class="rights-editor-actions col-12 col-md-2 d-flex justify-content-center align-items-center">
                        <div class="row">
                            <!-- Remove rights from selected role -->
                            <div class="col col-6">
                                <button ejs-button cssClass="e-outline" [isPrimary]="false" (click)="removeSelectedRoleToAssignedRightsGrid()"><span class="e-icons e-chevron-left-double"></span></button>
                            </div>
                            <!-- Add rights from selected role -->
                            <div class="col col-6">
                                <button ejs-button cssClass="e-outline" [isPrimary]="false" (click)="addSelectedRoleToAssignedRightsGrid()"><span class="e-icons e-chevron-right-double"></span></button>
                            </div>
                            <!-- Submit changes to role -->
                            <div class="col col-12">
                                <button ejs-button [isPrimary]="true" (click)="submitRoleChanges()">Submit</button>
                            </div>
                        </div>
                    </div>
                    <!-- List of assigned rights -->
                    <div class="rights-editor-assigned col-12 col-md-5">
                        <grid-template #assignedRightsGridTemplate [settings]="assignedRightsGridSettings"></grid-template>
                    </div>
                </div>
            </div>  
        </ejs-dialog>
    }


    @if (showRoleAdder === true) { 
        
        <div class="col-12 col-sm-5 form-container">
            <h3>Add New Role</h3>
            <add-role [rolesGrid]="roles"></add-role>    
        </div>
        
    }

    <div [class]="showRoleAdder === true ? 'col-12 col-sm-7' : 'col-12'">

        <!-- List of roles -->
        <grid-template #usersRolesGridComponent [settings]="rolesSettings" [name]="'User Roles'"></grid-template>

        @if (rightsEditorVisible === true) {
            
            <!-- Custom rights editor -->
            <div id="rights-editor" class="rights-editor">

                @if (rolesSelectedRowName) {
                     <!-- Heading for selected role -->
                    <h3>
                        <span>Edit Rights for: {{rolesSelectedRowName}} </span>
                        <span class="text-color_tertiary">(active rights highlighted green)</span>
                    </h3>
                }
                
                <div class="row d-flex">

                    <!-- List of available rights -->
                    <div class="col-12 col-md-5 rights-editor-available">  
                        <grid-template #availableRightsGridTemplate [settings]="availableRightsGridSettings"></grid-template>
                    </div>

                    <!-- Custom rights editor actions -->
                    <div class="rights-editor-actions col-12 col-md-2 d-flex justify-content-center align-items-center">

                        <div class="row">

                            <!-- Remove rights from selected role -->
                            <div class="col col-6">
                                <button ejs-button cssClass="e-outline" [isPrimary]="false" (click)="removeSelectedRoleToAssignedRightsGrid()"><span class="e-icons e-chevron-left-double"></span></button>
                            </div>

                            <!-- Add rights from selected role -->
                            <div class="col col-6">
                                <button ejs-button cssClass="e-outline" [isPrimary]="false" (click)="addSelectedRoleToAssignedRightsGrid()"><span class="e-icons e-chevron-right-double"></span></button>
                            </div>

                            <!-- Submit changes to role -->
                            <div class="col col-12">
                                <button ejs-button [isPrimary]="true" (click)="submitRoleChanges()">Submit</button>
                            </div>

                        </div>

                    </div>

                    <!-- List of assigned rights -->
                    <div class="rights-editor-assigned col-12 col-md-5">
                        <grid-template #assignedRightsGridTemplate [settings]="assignedRightsGridSettings"></grid-template>
                    </div>
                </div>
            </div>
        }

        <!-- Custom template if no rights found for role -->
        <ng-template #emptyRightsTemplate>
            <div class='emptyRecordTemplate'>
                <span>No Rights available</span>
            </div>
        </ng-template>
    </div>
</div>