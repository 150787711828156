// Angular
import { Component, ViewEncapsulation } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { OpenFileMenuComponent } from '@ui/open-file-menu/open-file-menu.component';

@Component({
  selector: 'app-content',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    OpenFileMenuComponent
  ],
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MainContent {

  // Used for declaring Id of parent Element - replaces first slash with empty '', and all remaining slashes with underscores, '_'
  public pathName: string = window.location.pathname.replace('/', '').replace(/\//g, '_');
}
