// Angular
import { Component, Input, ViewChild } from '@angular/core';
import { ReactiveFormsModule, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CommonModule } from '@angular/common';

// 3rd Party
import { Query, ReturnOption } from '@syncfusion/ej2-data';
import { CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { CheckBoxSelectionService, DropDownListComponent, DropDownListModule, MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';

// Internal
import * as vars from '@models/global-vars';
import { APIEndpoints } from '@models/api/Endpoints';
import { Patient, CaseFile } from '@root/src/app/shared/models/data-contracts';
import { ApiService } from '@services/api/api.service';
import { GlobalsService } from '@services/globals/globals.service';
import { treeData } from '@features/workflow-builder/sample-data/treegrid';
import { DropdownSingleComponent } from "../../ui/dropdown-single/dropdown-single.component";
import { AddAddressComponent } from '../add-forms/add-address/add-address.component';

@Component({
  selector: 'case-file-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DropDownListModule,
    CheckBoxModule,
    MultiSelectModule,
    TextBoxModule,
    DatePickerModule
],
  templateUrl: './case-file-form.component.html',
  styleUrl: './case-file-form.component.scss',
  providers: [CheckBoxSelectionService]
})
export class CaseFileForm {

  constructor(
    public globals: GlobalsService,
    private api: ApiService,
    private fb: FormBuilder
  ) {
    // Set grid data
    this.users = this.api.getOdata(APIEndpoints.Users);
    this.api.getOdata(APIEndpoints.Lawfirms).executeQuery(new Query().where('Active', 'equal', true)).then((res: ReturnOption) => { this.lawFirms = res.result });
    this.api.getOdata(APIEndpoints.Contacts).executeQuery(new Query()).then((res: ReturnOption) => { this.contacts = res.result });
    this.caseFiles = this.api.getOdata(APIEndpoints.Casefiles);
  }

  @Input() currentCaseFile?: CaseFile;
  @Input() showFormButtons: Boolean = false;
  requirePhysician = false;
  treeData: object[] = treeData;
  showForm: boolean = false;
  contacts: any;
  users: any;
  clients: any;
  lawFirms: any;
  caseFiles: any;
  defaultState: any;
  states = vars.states;

  // Dates
  dateValue: Date = new Date();
  databaseDate: string = this.globals.formatDateForEjsDatepicker(this.dateValue);
  displayDate: string = this.globals.formatDateForEjsDatepicker(this.dateValue);

  // Fields
  lawFirmsFields: object = { text: 'Name', value: 'Id' };
  caseFilesFields: object = { text: 'FileNumber', value: 'Id' };
  referralsFields: object = { groupBy: 'group', text: 'label', value: 'value' };
  keyValueFields: object = { text: 'key', value: 'value' };
  treeDataFields: object = { text: 'name', value: 'workflowId' };
  usersFields: object = { text: 'Name', value: 'Id' };
  clientFields: object = { text: 'Text', value: 'Id'};
  contactsFields: object = { text: 'ContactName', value: 'Id'};

  // Queries
  lawFirmsQuery = new Query().select('Id,Name');
  attorneysQuery = new Query().select('Id,Name,Title').where('Title', 'contains', 'Engineer');
  caseFilesQuery = new Query().select('Id,FileNumber');
  caseManagersQuery: Query = new Query().select('Id,Name,Title').where('Title', 'contains', 'cog');
  marketManagersQuery: Query = new Query().select('Id,Name,Title').where('Title', 'contains', 'cog');
  statusersQuery: Query = new Query().select('Id,Name,Title').where('Title', 'contains', 'Software');

  // Options
  languages: string[] = this.globals.languages;
  initialTimeFrame: string[] = this.globals.caseFileTimeFrame;
  currentStatus: object[] = this.globals.caseFileCurrentStatus;
  genders: string[] = this.globals.genders;
  caseTypes: string[] = this.globals.caseFilesCaseTypes;
  pAndL: object[] = this.globals.listPAndL;
  referrals: any = this.globals.referralSources;
  fileStatus: object[] = this.globals.caseFileStatuses;
  fileGroups: object[] = this.globals.caseFilesFileGroups;

  // Forms
  caseFilePatientAddressForm: FormGroup<any>;
  caseFilePatientForm: FormGroup<any>;
  caseFileForm: FormGroup<any>;

  @ViewChild('clientList')
  clientListDrpDwn: DropDownListComponent;
  clientListPlaceholder: string = 'Select Client';

  @ViewChild('addressList')
  addressListDrpDwn: DropDownListComponent;
  
  ngOnInit() {
    // this.getDefaultState();
    // this.checkPhysician();
    console.log(this.currentCaseFile);
    this.caseFilePatientAddressForm = this.fb.group({
      Id: [this.currentCaseFile?.Patient?.AddressId ?? undefined],
      Address1: [this.currentCaseFile?.Patient?.Address?.Address1 ?? undefined],
      Address2: [this.currentCaseFile?.Patient?.Address?.Address2 ?? undefined],
      AddressType: [this.currentCaseFile?.Patient?.Address?.AddressType ?? undefined],
      City: [this.currentCaseFile?.Patient?.Address?.City ?? undefined],
      County: [this.currentCaseFile?.Patient?.Address?.County ?? undefined],
      State: [this.currentCaseFile?.Patient?.Address?.State ?? undefined],
      Zip: [this.currentCaseFile?.Patient?.Address?.Zip ?? undefined],
    });
    this.caseFilePatientForm = this.fb.group({
      Id: [this.currentCaseFile?.Patient?.Id ?? undefined],
      Active: [this.currentCaseFile?.Active ?? undefined],
      AddressId: [this.currentCaseFile?.Patient?.AddressId ?? undefined],
      Address: this.caseFilePatientAddressForm,
      Dob: [this.currentCaseFile?.Patient?.Dob ?? undefined],
      Email: [this.currentCaseFile?.Patient?.Email ?? undefined],
      Firstname: [this.currentCaseFile?.Patient?.Firstname ?? undefined],
      Gender: [this.currentCaseFile?.Patient?.Gender ?? undefined],
      Language: [this.currentCaseFile?.Patient?.Language ?? undefined],
      Lastname: [this.currentCaseFile?.Patient?.Lastname ?? undefined],
      Minor: [this.currentCaseFile?.Patient?.Minor ?? undefined],
    });
    this.caseFileForm = this.fb.group({
      Id: [this.currentCaseFile?.Id ?? undefined],
      Attorney: [this.currentCaseFile?.Attorney ?? undefined],
      CaseManager: [this.currentCaseFile?.CaseManager ?? undefined],
      CaseNumber: [this.currentCaseFile?.CaseNumber ?? undefined],
      CaseType: [this.currentCaseFile?.CaseType ?? undefined],
      CaseTypeId: [this.currentCaseFile?.CaseTypeId ?? undefined],
      ClientHasCheckpointLimitations: [this.currentCaseFile?.ClientHasCheckpointLimitations ?? undefined],
      Comments: [this.currentCaseFile?.Comments ?? undefined],
      CompanionCases: [this.currentCaseFile?.CompanionCases ?? undefined],
      DateOfLoss: [this.currentCaseFile?.DateOfLoss ?? undefined],
      ExcludeFromBalanceDue: [this.currentCaseFile?.ExcludeFromBalanceDue ?? undefined],
      FileGroupId: [this.currentCaseFile?.FileGroupId ?? undefined],
      FileNumber: [this.currentCaseFile?.FileNumber ?? undefined],
      FileOpened: [this.currentCaseFile?.FileOpened ?? undefined],
      FileRating: [this.currentCaseFile?.FileRating ?? undefined],
      FileSecuredBy: [this.currentCaseFile?.FileSecuredBy ?? undefined],
      InitialTimeFrame: [this.currentCaseFile?.InitialTimeFrame ?? undefined],
      InTreatment: [this.currentCaseFile?.InTreatment ?? undefined],
      Active: [this.currentCaseFile?.Active ?? undefined],
      IsSurgical: [this.currentCaseFile?.IsSurgical ?? undefined],
      LawFirmContact: [this.currentCaseFile?.LawFirmContact ?? undefined],
      LawFirmFileNumber: [this.currentCaseFile?.LawFirmFileNumber ?? undefined],
      LawFirmId: [this.currentCaseFile?.LawFirmId ?? undefined],
      MarketManager: [this.currentCaseFile?.MarketManager ?? undefined],
      PAndL: [this.currentCaseFile?.PAndL ?? undefined],
      PatientId: [this.currentCaseFile?.PatientId ?? undefined],
      Patient: this.caseFilePatientForm,
      ReferralSource: [this.currentCaseFile?.ReferralSource ?? undefined],
      ReferringPhysician: [this.currentCaseFile?.ReferringPhysician ?? undefined],
      ResponsibleParty: [this.currentCaseFile?.ResponsibleParty ?? undefined],
      Statuser: [this.currentCaseFile?.Statuser ?? undefined],
      StatuteOfLimitations: [this.currentCaseFile?.StatuteOfLimitations ?? undefined],
      StatusingGroup: [this.currentCaseFile?.StatusingGroup ?? undefined],
      Status: [this.currentCaseFile?.RecordStatus ?? undefined]
    });
  }

  onClientSelect(e: Event) {
    if ((e as any)['value'] === 0) {
      this.caseFileForm.addControl('Patient', this.caseFilePatientForm);
    }
  }

  onClientAddressSelect(e: Event) {
    if ((e as any)['value'] === 0) {
      this.caseFilePatientForm.addControl('Address', this.caseFilePatientAddressForm);
    }
  }

  formReset = () => console.log('Reset Form');
  formSubmit = () => console.log('formSubmit(): ', this.caseFileForm.value);

  private checkPhysician() {
    this.api.getOdata(APIEndpoints.ConfigVariables).executeQuery(new Query().where('VariableName', 'equal', 'require_referring_physician', true))
      .then((e: ReturnOption) => {
        (e.result as object[]).forEach((element) => {
          this.requirePhysician = (element as any)["VariableValue"]
          if (this.requirePhysician) {
            this.caseFileForm.controls["ReferringPhysician"].setValidators(Validators.required);
          }
        });
      });
  }

  // Gets a default state for the states dropdown
  private getDefaultState() {
    this.api.getOdata(APIEndpoints.ConfigVariables).executeQuery(new Query().where('VariableName', 'equal', 'default_state', true))
      .then((e: ReturnOption) => {
        (e.result as object[]).forEach((element) => {
          let res = element as any
          this.defaultState = res["VariableValue"]
        });
      });
  }

  async onClientListCreated(args: any) {
    
    await this.api.getOdata(APIEndpoints.Patients).executeQuery(new Query().select('Id,Firstname,Lastname,Dob')).then((res: any) => {
      this.clients = res.result.map((client: Patient) => {
        return {
          Id: client.Id,
          Text: `Name: ${client.Firstname} ${client.Lastname}, DOB: ${client.Dob}`
        };
      });
    });
  }

  hideInfoBox(args: Event) {
    const target = args.target as HTMLElement;

    if (target && target.parentElement) {
      const infoBoxContent = target.parentElement.querySelector('.info-box-content') as HTMLElement;
      const infoBoxHeaderIcon = target.querySelector('.e-icons') as HTMLElement;
      
     if (infoBoxContent && infoBoxHeaderIcon) {

      if (infoBoxContent.style.display === 'none') {
        infoBoxHeaderIcon.classList.remove('e-eye-slash');
        infoBoxHeaderIcon.classList.add('e-eye');
        infoBoxContent.style.display = 'block';
      } else {
        infoBoxHeaderIcon.classList.remove('e-eye');
        infoBoxHeaderIcon.classList.add('e-eye-slash');
        infoBoxContent.style.display = 'none';
      }
     }
    }
  }
}
