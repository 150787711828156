<div id="surgical-tab">

  <div class="surgical-progression">
    <tab-card [heading]="'Surgical Progression'">
      <div *ngIf="(loadingSurgicalProgression$ | async) && !(updatingSegments$ | async); else updatingSegments" class="loading-spinner">
        <span>Loading...</span>
      </div>
      <ng-template #updatingSegments>
        <div *ngIf="updatingSegments$ | async" class="updating-spinner">
          <span>Updating...</span>
        </div>
      </ng-template>
      
      <div class="progress-container">
        <div *ngFor="let segment of segments$ | async; let index = index"
             class="progress-segment"
             [class.active]="selectedSegments[index]"
             (click)="onSegmentClick(index)">
          <label>{{ segment.Label }}</label>
        </div>
      </div>
    </tab-card>
  </div>

  <!-- Surgery Progression Dialog -->
  <ejs-dialog #progressionDialog
              [header]="'Update Surgery Progression'"
              [visible]="isDialogVisible"
              [buttons]="(loadingSurgicalProgression$ | async) ? [] : dialogButtons"
              [showCloseIcon]="!(loadingSurgicalProgression$ | async)"
              [isModal]="true"
              [width]="'35%'"
              (overlayClick)="onDialogClose()"
              (close)="onDialogClose()">
    <div class="dialog-content">
      <div *ngIf="loadingSurgicalProgression$ | async; else progressContent" class="dialog-loading">
        <span>Processing...</span>
      </div>
      <ng-template #progressContent>
        {{ dialogBody }}
      </ng-template>
    </div>
  </ejs-dialog>

  <!-- Confirm Undo Dialog -->
  <ejs-dialog #undoDialog
              [header]="'Confirm Undo'"
              [visible]="confirmUndoDialogVisible"
              [buttons]="(loadingSurgicalProgression$ | async) ? [] : confirmUndoButtons"
              [showCloseIcon]="!(loadingSurgicalProgression$ | async)"
              [isModal]="true"
              [width]="'35%'"
              (overlayClick)="onCancelUndo()"
              (close)="onCancelUndo()">
    <div class="dialog-content">
      <div *ngIf="loadingSurgicalProgression$ | async; else confirmContent" class="dialog-loading">
        <span>Processing...</span>
      </div>
      <ng-template #confirmContent>
        {{ confirmUndoDialogBody }}
      </ng-template>
    </div>
  </ejs-dialog>
  
  <surgeries-grid (surgerySelected)="onSurgerySelect($event)"></surgeries-grid>
</div>
