// Angular
import { Component, ViewChild, WritableSignal, signal, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { provideAnimations } from '@angular/platform-browser/animations';
import { BehaviorSubject } from 'rxjs';

// 3rd Party
import { Query, ReturnOption } from '@syncfusion/ej2-data';
import { CommandClickEventArgs, CommandModel, GridModel } from '@syncfusion/ej2-grids';
import { GridComponent } from '@syncfusion/ej2-angular-grids';

// Internal
import { GlobalsService } from '@services/globals/globals.service';
import { ApiService } from '@services/api/api.service';
import { APIEndpoints } from '@models/api/Endpoints';
import { FeeSchedule, ProcedureCode, Provider } from '@models/data-contracts';
import { GridTemplateComponent } from '@grids/grid-template/grid-template.component';
import { LoadingModule } from '@root/src/app/shared/modules/loading.module';
import { Form, NgForm } from '@angular/forms';
import { getInstance } from '@syncfusion/ej2-base';
import { ComponentBase } from '@core/base/component.base';
import { ErrorSeverity } from '@core/error/error.types';
import { NotificationSeverity } from '@core/enums/notification-severity.enum';

// Define ODataResponse interface for typed responses
interface ODataResponse {
  result: any[];
  count?: number;
  '@odata.count'?: number;
  aggregates?: any;
  groupDs?: any;
}

// Error constants
const ERRORS = {
  LOAD_FEE_SCHEDULES: {
    message: 'Unable to load fee schedules',
    technical: 'Error loading fee schedules from API'
  },
  UPDATE_FEE_SCHEDULES: {
    message: 'Unable to update fee schedules',
    technical: 'Error updating fee schedules during state change'
  },
  ADD_EDIT_FEE_SCHEDULE: {
    message: 'Unable to process fee schedule',
    technical: 'Error during fee schedule add/edit operation'
  }
};

import { FeeScheduleDialogComponent } from '../../../shared/components/base-dialog/fee-schedule-dialog.component';
import { DialogService } from '@shared/services/dialog.service';

@Component({
  selector: 'fee-schedule-grid',
  standalone: true,
  imports: [
    CommonModule,
    GridTemplateComponent,
    LoadingModule,
    MatButtonModule,
    MatDialogModule,
    FeeScheduleDialogComponent
  ],
  providers: [
    provideAnimations()
  ],
  templateUrl: './fee-schedule-grid.component.html',
  styleUrl: './fee-schedule-grid.component.scss'
})
export class FeeScheduleGridComponent implements OnInit {
  @ViewChild('feeSchedulesGrid') feeSchedulesGrid: GridComponent;

  loading: WritableSignal<boolean> = signal(true);
  isLoadingData: WritableSignal<boolean> = signal(false);
  feeSchedulesGridSettings: GridModel;
  commands: CommandModel[] = [
    { buttonOption: { iconCss: 'e-icons e-edit', cssClass: 'e-flat' }, title: 'Edit' }
  ];

  private providersSubject = new BehaviorSubject<Provider[]>([]);
  providers$ = this.providersSubject.asObservable();
  
  private cptCodesSubject = new BehaviorSubject<ProcedureCode[]>([]);
  cptCodes$ = this.cptCodesSubject.asObservable();

  constructor(
    private globals: GlobalsService,
    private api: ApiService,
    private dialogService: DialogService
  ) {
    this.setGridData();
  }

  ngOnInit() {
    this.loadBaseData();
  }

  private async loadBaseData(): Promise<void> {
    try {
      this.isLoadingData.set(true);
      // Load CPT Codes
      const cptQuery = new Query()
        .select(['Id', 'ProcedureCodeName', 'ModalityTypeId', 'Description', 'McptId']);
      const cptResponse = await this.api.getOdata(APIEndpoints.ProcedureCodes)
        .executeQuery(cptQuery);
      this.cptCodesSubject.next((cptResponse as any).result || []);

      // Load Providers
      const providerQuery = new Query().select(['Id', 'Name']);
      const providerResponse = await this.api.getOdata(APIEndpoints.Providers)
        .executeQuery(providerQuery);
      const providers = ((providerResponse as any).result || []);
      this.providersSubject.next(providers);
    } catch (error) {
      console.error('Error loading base data:', error);
    } finally {
      this.isLoadingData.set(false);
    }
  }

  async openFeeScheduleDialog(feeSchedule?: FeeSchedule): Promise<void> {
    if (this.isLoadingData()) {
      return; // Prevent opening dialog while loading data
    }

    // Ensure base data is loaded
    if (!this.providersSubject.value.length || !this.cptCodesSubject.value.length) {
      await this.loadBaseData();
    }
    
    const currentProviders = this.providersSubject.value || [];
    const currentCptCodes = this.cptCodesSubject.value || [];
    
    const dialogRef = this.dialogService.openDialog(FeeScheduleDialogComponent, {
      maxHeight: '80vh',
      panelClass: ['fee-schedule-dialog-container', 'fullscreen-dialog'],
      disableClose: true,
      autoFocus: true,
      title: feeSchedule ? 'Edit Fee Schedule' : 'Add Fee Schedule',
      submitLabel: feeSchedule ? 'Save Changes' : 'Create Fee Schedule',
      data: {
        formData: feeSchedule || null,
        providers: currentProviders,
        cptCodes: currentCptCodes
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.setGridData(); // Refresh grid data
      }
    });
  }

  async setGridData() {
    this.loading.set(true);
    try {
      const endpoint = `${APIEndpoints.FeeSchedules}`;
      const query = new Query()
        .select(['Id', 'Name'])
        .expand(['XrefFeeScheduleProcedureCodes($count=true;$top=0)', 'Providers($count=true;$top=0)']);
      
      const res = await this.api.getOdata(endpoint).executeQuery(query) as ReturnOption;
      const feeSchedules = res.result as any[];
      if (!feeSchedules) {
        throw new Error('No data returned from API');
      }

      const data = feeSchedules.map(schedule => ({
        Id: schedule.Id,
        Name: schedule.Name,
        TotalProcedureCodes: schedule.XrefFeeScheduleProcedureCodesCount || 0,
        TotalProviders: schedule.ProvidersCount || 0
      }));
      
      // Create a basic toolbar item that's not disabled
      const toolbarItem = { 
        text: 'Add Fee Schedule', 
        tooltipText: 'Add Schedule', 
        id: 'AddSchedule'
      };
      
      this.feeSchedulesGridSettings = {
        dataSource: data,
        toolbar: this.globals.isResponsive ? 
          [toolbarItem] : 
          [toolbarItem, 'ColumnChooser'],
        columns: [
          { field: 'Id', isPrimaryKey: true, visible: false },
          { field: 'Name' },
          { field: 'TotalProcedureCodes', headerText: 'Total Procedure Codes'},
          { field: 'TotalProviders', headerText: 'Total Providers'},
          { type: 'commands', commands: this.commands, headerText: 'Actions', visible: !this.globals.isResponsive }
        ],
        toolbarClick: (args: any) => this.onToolbarClick(args),
        commandClick: (args: CommandClickEventArgs) => this.commandClick(args)
      };

      if (this.feeSchedulesGrid) {
        this.feeSchedulesGrid.refresh();
      }
    } catch (error) {
      console.error('Error loading fee schedules:', error);
      /*
      this.handleError(error, {
        context: 'FeeScheduleGridComponent.setGridData',
        userMessage: ERRORS.LOAD_FEE_SCHEDULES.message,
        severity: ErrorSeverity.Error
      });
      */
    } finally {
      this.loading.set(false);
      // Ensure isLoadingData is reset
      this.isLoadingData.set(false);
    }
  }

  onToolbarClick(args: any): void {
    if (this.isLoadingData()) {
      return; // Prevent toolbar actions while loading
    }

    switch (args.item.id) {
      case 'AddSchedule':
        this.openFeeScheduleDialog();
        break;
    }
  }

  async commandClick(args: CommandClickEventArgs) {
    if (this.isLoadingData()) {
      return; // Prevent command actions while loading
    }

    if (args.commandColumn?.title === 'Edit') {
      try {
        const feeSchedule = args.rowData as FeeSchedule;
        // Fetch complete fee schedule data including providers and procedure codes
        const endpoint = `${APIEndpoints.FeeSchedules}(${feeSchedule.Id})`;
        const query = new Query()
          .select(['Id', 'Name'])
          .expand(['XrefFeeScheduleProcedureCodes($select=Id,ProcedureCodeId,BilledAmount,SplitInvoice,FrontEndReimbursmentRate,BackEndReimbursmentRate;$expand=ProcedureCode)', 
                  'Providers($select=Id,Name)']);
        
        const res = await this.api.getOdata(endpoint).executeQuery(query) as ReturnOption;
        const fullFeeSchedule = (res.result as any[])[0];
        
        await this.openFeeScheduleDialog(fullFeeSchedule);
      } catch (error) {
        console.error('Error loading fee schedule details:', error);
      }
    }
  }
}
