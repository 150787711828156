<div id="workflow-builder" class="row workflow-builder">
    <div class="col-12 cc-wfbuilder">
        <div class="row">
            <div class="col-12 workflow-header">
                <div class="logo">
                    <app-svg class="svg-logo" [path]="logo"></app-svg>
                </div>
                <h2>Workflow Builder - New</h2>
            </div>
            <div class="col-3 workflow-selector">
                <div class="row">
                    <div class="col-12 workflow-selector-add">
                        <button ejs-button >Add New Workflow</button>
                    </div>
                    <div class="col-12 workflow-selector-dropdown">
                        <label>Select Workflow</label>
                        <ejs-dropdownlist #wfddl 
                            [dataSource]="wfList" 
                            [fields]="wfListFields" 
                            [placeholder]="wfListPlaceholder" 
                            [value]="12"
                            (change)="onWfChange($event)"></ejs-dropdownlist>
                    </div>
                </div>
            </div>
            <div class="col-9 workflow-content">
                <div class="col-12 workflow-content-title">
                    <h3>{{wfHeader}}</h3>
                </div>
                <ejs-treegrid id="treeGrid" #treegrid height="450" childMapping="Children" [treeColumnIndex]="1" allowFiltering="true"
                    [dataSource]="treegridData" 
                    [toolbar]="toolbar" 
                    [filterSettings]="filterSettings"
                    [editSettings]="treeGridEditSettings"
                    [allowRowDragAndDrop]="true"
                    (dataBound)="gridDataBound($event)"
                    (rowDataBound)="rowDataBound($event)"
                    (actionBegin)="actionBegin($event)"
                    (actionComplete)="actionComplete($event)"
                    (rowSelected)="rowSelected($event)">
                    <e-columns>
                        <e-column field="id" headerText="ID" textAlign="Right" width="100px" [isPrimaryKey]="true" [visible]="false" [allowEditing]="false"></e-column>
                        <e-column field="StepName" headerText="Step"></e-column>
                        <e-column field="StepType" headerText="Type" editType="dropdownedit"></e-column>
                        <e-column field="AssignTo" headerText="Assignment" editType="dropdownedit">
                        </e-column>
                        <e-column field="CompletionCondition" headerText="Completion Condition" editType="dropdownedit"></e-column>
                        <e-column field="Optional" headerText="Optional" textAlign="Right" editType="dropdownedit" [valueAccessor]="displayOptionalValues">
                        </e-column>
                        <!-- <e-column headerText="Commands" width="100" [commands]="commands"></e-column> -->
                    </e-columns>
                </ejs-treegrid>
            </div>
        </div>
    </div>

    <!-- <div class="col-12 atlas-wfbuilder">
        <div class="row">
            <div class="col-12 workflow-header">
                <div class="logo">
                    <app-svg class="svg-logo" [path]="logo"></app-svg>
                </div>
                <h2>Workflow Builder - Atlas 2.0</h2>
            </div>
            <div class="col-4 workflow-selector">
                <div class="row">
                    <div class="col-12 workflow-selector-add">
                        <button ejs-button >Add New Workflow</button>
                    </div>
                    <div class="col-12 workflow-selector-dropdown">
                        <ejs-dropdownlist #wfddl [dataSource]="wfList" [fields]="wfListFields" [placeholder]="wfListPlaceholder"></ejs-dropdownlist>
                    </div>
                </div>
            </div>
            <div class="col-8 workflow-content">
                <div class="col-12 workflow-content-title">
                    <h3>{{wfHeader}}</h3>
                </div>
                <ejs-treegrid id="treelist" #treelist childMapping="BranchChild" [treeColumnIndex]="0" allowFiltering="true" 
                    [dataSource]="data"
                    [editSettings]="treeGridEditSettings">
                    <e-columns>
                        <e-column field="StepName.value" headerText="Step Name"></e-column>
                        <e-column field="StepType.value" headerText="Step Type"></e-column>
                        <e-column field="AssignTo.value" headerText="Assign To"></e-column>
                        <e-column field="CompletionCondition.value" headerText="Completion Condition"></e-column>
                        <e-column field="Optional.value" headerText="Optional"></e-column>
                    </e-columns>
                </ejs-treegrid>
            </div>
        </div>
    </div> -->
</div>