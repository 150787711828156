// Angular
import { Component, computed, effect, signal, ViewChild, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';

// 3rd Party
import { ListViewModule, ListViewModel, ListView } from '@syncfusion/ej2-angular-lists'

// Models
import { Appointment } from '@models/data-contracts';

// Services
import { SchedulerSignalsService } from '@services/scheduler/scheduler-signals.service';
import { FileHubService } from '@root/src/app/features/file-hub/services/file-hub.service';

@Component({
  selector: 'scheduler-appointments-list',
  standalone: true,
  imports: [
    CommonModule,
    ListViewModule
  ],
  templateUrl: './scheduler-appointments-list.component.html',
  styleUrl: './scheduler-appointments-list.component.scss'
})
export class SchedulerAppointmentsListComponent {

  constructor(
    public fileHub: FileHubService,
    public schedulerSignals: SchedulerSignalsService
  ) {
    
    // Update list when case file changes
    effect(() => {
      const currentFile = this.caseFile();
      if (currentFile?.Id && currentFile.Appointments?.length) {
        this.list = currentFile.Appointments.map(apt => ({
          id: apt.Id,
          text: this.formatAppointmentTitle(apt),
          tooltip: apt.Notes,
          appointment: apt
        }));
      }
    });
  }

  // Decorators
  @ViewChild('appointmentsList') listView: ListView;

  // Signals
  protected readonly caseFile = computed(() => this.fileHub.caseFile);

  // Public properties
  list: any[] = [];

  // Add output event emitter
  @Output() appointmentSelected = new EventEmitter<Appointment>();

  private formatAppointmentTitle(apt: Appointment): string {
    const date = new Date(apt.StartDatetime as Date);
    const formattedDate = date.toLocaleDateString();
    const title = apt.Title ?? apt.ProcedureCode?.Description ?? this.caseFile()?.FileNumber + ' - ' + this.caseFile()?.Patient?.Firstname + ' ' + this.caseFile()?.Patient?.Lastname;
    return `${formattedDate} - ${title}`;
  }

  // Handle item selection
  onItemSelected(args: any) {
    const selectedItem = this.list.find(item => item.id === args.data.id);
    if (selectedItem?.appointment) this.appointmentSelected.emit(selectedItem.appointment);
  }
}
