import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { WorkItemsService } from '../../services/work-items.service';
import { APIEndpoints } from '@models/api/Endpoints';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { TextBoxAllModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownListAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { DatePickerAllModule } from '@syncfusion/ej2-angular-calendars';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { signal } from '@angular/core';
import { effect } from '@angular/core';
import { EventEmitter, Output } from '@angular/core';
import { FormCrudService } from '@services/forms/form-crud.service';
import { faClipboardList, faHeading, faAlignLeft, faListCheck, faExclamation, faLayerGroup, faFolder, faCalendarAlt, faUserTie, faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { LoadingSpinnerComponent } from '@shared/components/loading-spinner/loading-spinner.component';
import { Query } from '@syncfusion/ej2-data';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { FormBuilder } from '@angular/forms';
import { MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';

@Component({
  selector: 'work-item-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    TextBoxAllModule,
    DropDownListAllModule,
    DatePickerAllModule,
    FontAwesomeModule,
    LoadingSpinnerComponent,
    DialogModule,
    MultiSelectModule,
    RichTextEditorAllModule
  ],
  templateUrl: './work-item-form.component.html',
  styleUrls: ['./work-item-form.component.scss']
})
export class WorkItemFormComponent implements OnInit {
  @Input() workItem = signal<any>(undefined);
  @Input() displayButtons = true;  // Add back this input
  @Input() visible = false;  // Control dialog visibility
  @Output() visibleChange = new EventEmitter<boolean>();  // Two-way binding
  @Output() formSubmitted = new EventEmitter<void>();
  @Output() formCancelled = new EventEmitter<void>();
  @Input() inCaseFileContext = false;
  @Input() caseFileId?: number;

  loading = signal(true);
  workItemForm: FormGroup;

  formClasses = {
    form: 'cc-form flex-column',
    inputContainer: 'cc-input-container',
    actions: 'cc-form-actions',
    icon: 'cc-input-icon'
  };

  formIcon = faClipboardList;
  formIcons = {
    title: faHeading,
    description: faAlignLeft,
    status: faListCheck,
    priority: faExclamation,
    type: faLayerGroup,
    category: faFolder,
    dueDate: faCalendarAlt,
    assignedTo: faUserTie,
    caseFiles: faFolderOpen,
    provider: faUserTie
  };

  statusesDataManager = this.workItemsService.getStatusesDataManager();
  prioritiesDataManager = this.workItemsService.getPrioritiesDataManager();
  typesDataManager = this.workItemsService.getTypesDataManager();
  categoriesDataManager = this.workItemsService.getCategoriesDataManager();
  usersDataManager = this.workItemsService.getUsersDataManager();
  providersDataManager = this.workItemsService.getProvidersDataManager();

  submitting = false;

  // Dialog settings
  public dialogSettings = {
    header: 'Add New Task',
    width: '600px',
    isModal: true,
    showCloseIcon: true,
    closeOnEscape: true,
    beforeClose: () => {
      this.onDialogClose();
      return true;
    },
    buttons: [
      { click: () => this.onCancel(), buttonModel: { content: 'Cancel', cssClass: 'e-flat' }},
      { click: () => this.onSubmit(), buttonModel: { content: 'Save', isPrimary: true }}
    ]
  };

  caseFiles: any[] = [];
  selectedCaseFiles: number[] = [];
  caseFilesDataManager: any;

  showProviderField = false;

  originalCaseFileIds: number[] = [];
  originalProviderId?: number;

  // Add new property to store filtered types
  filteredTypes: any[] = [];

  // Add new properties to hold the data
  caseFilesData: any[] = [];
  providersData: any[] = [];

  rteConfig = {
    height: 200,
    toolbarSettings: {
      items: [
        'Bold', 'Italic', 'Underline', '|',
        'Formats', 'Alignments', '|',
        'OrderedList', 'UnorderedList', '|',
        'SourceCode'
      ]
    },
    format: {
      width: 'auto'
    }
  };

  constructor(
    private workItemsService: WorkItemsService,
    private formCrud: FormCrudService,
    private fb: FormBuilder
  ) {
    this.initForm();
    this.caseFilesDataManager = this.workItemsService.getCaseFilesDataManager();

    // Watch for category changes and update types
    this.workItemForm.get('CategoryId')?.valueChanges.subscribe(async categoryId => {
      if (categoryId && !isNaN(categoryId)) {
        await this.checkProviderVisibility(categoryId);
        await this.updateTypesByCategory(categoryId);
      } else {
        this.filteredTypes = [];
      }
    });

    effect(() => {
      const item = this.workItem();
      if (item?.Id) {
        this.resetForm();
        this.updateFormWithWorkItem(item);
      }
    });
  }

  private initForm() {
    this.workItemForm = this.fb.group({
      Id: [null],
      Title: [null, [Validators.required, Validators.minLength(3)]],
      Description: [null],
      StatusId: [null, [Validators.required]],
      PriorityId: [null, [Validators.required]],
      TypeId: [null, [Validators.required]],
      CategoryId: [null, [Validators.required]],
      AssignedTo: [null, [Validators.required]],
      DueDate: [null, [Validators.required]],
      caseFileIds: [[]],
      ProviderId: [null]
    });
  }

  private async initDataManagers() {
    // Initialize data managers and wait for them to load
    await Promise.all([
      this.statusesDataManager.executeQuery(new Query()),
      this.prioritiesDataManager.executeQuery(new Query()),
      this.typesDataManager.executeQuery(new Query()),
      this.categoriesDataManager.executeQuery(new Query()),
      this.usersDataManager.executeQuery(new Query()),
      //this.caseFilesDataManager.executeQuery(new Query().select(['Id', 'FileNumber']))
        //.then((response: any) => {
          //this.caseFiles = response.result;
        //}),
      //this.providersDataManager.executeQuery(new Query().select(['Id', 'Name']))
    ]);
  }

  async ngOnInit() {
    try {
      await this.initDataManagers();
      if (!this.inCaseFileContext) {
        await this.loadCaseFilesData();
      }
      const item = this.workItem();
      if (item) {
        await this.updateFormWithWorkItem(item);
      } else {
        await this.setDefaultValues();
      }
    } catch (error) {
      console.error('Error in ngOnInit:', error);
    } finally {
      this.loading.set(false);
    }
  }

  async setDefaultValues() {
    try {
      this.loading.set(true);
      this.showProviderField = false;

      const defaults = await this.workItemsService.getNewWorkItemDefaults();

      await this.workItemForm.patchValue(defaults, { emitEvent: false });

      // Manually trigger updates
      await this.checkProviderVisibility(defaults.CategoryId);
      await this.updateTypesByCategory(defaults.CategoryId);

    } catch (error) {
      console.error('Error setting default values:', error);
    } finally {
      this.loading.set(false);
    }
  }

  onCaseFilesChange(event: any) {
    this.workItemForm.patchValue({ caseFileIds: event.value });
  }

  async onSubmit() {
    if (this.submitting) return;

    // Mark all fields as touched and validate
    Object.keys(this.workItemForm.controls).forEach(key => {
      const control = this.workItemForm.get(key);
      control?.markAsTouched();
      control?.markAsDirty();
      control?.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    });

    // Force form validation update
    this.workItemForm.updateValueAndValidity({ onlySelf: false, emitEvent: true });

    const validationDetails = {
      formValid: this.workItemForm.valid,
      formErrors: this.workItemForm.errors,
      controls: Object.keys(this.workItemForm.controls).reduce((acc, key) => {
        const control = this.workItemForm.get(key);
        acc[key] = {
          value: control?.value,
          valid: control?.valid,
          touched: control?.touched,
          dirty: control?.dirty,
          errors: control?.errors,
          hasRequiredValidator: control?.hasValidator(Validators.required)
        };
        return acc;
      }, {} as any)
    };

    console.log('Submit Validation Details:', validationDetails);

    if (!this.workItemForm.valid) {
      console.log('Form submission blocked - Invalid form');
      return null;
    }

    // Additional explicit validation
    const requiredFields = ['Title', 'StatusId', 'PriorityId', 'TypeId', 'CategoryId'];
    const missingFields = requiredFields.filter(field => {
      const control = this.workItemForm.get(field);
      return !control?.value || control.errors;
    });

    if (missingFields.length > 0) {
      console.log('Required fields missing or invalid:', missingFields);
      return null;
    }

    try {
      this.submitting = true;
      const formValue = this.workItemForm.value;
      const id = this.workItemForm.get('Id')?.value;

      console.log('Submitting with ID:', id);
      let result;
      if (id) {
        // Edit case
        formValue.originalCaseFileIds = this.originalCaseFileIds;
        formValue.originalProviderId = this.originalProviderId;
        console.log('Updating work item with data:', {
          ...formValue,
          XrefWorkItemCaseFiles: formValue.caseFileIds?.map((id: number) => ({ CaseFileId: id })) || [],
          XrefWorkItemProviders: formValue.ProviderId ? [{ ProviderId: formValue.ProviderId }] : []
        });
        try {
          result = await this.workItemsService.updateWorkItem(id, {
            ...formValue,
            XrefWorkItemCaseFiles: formValue.caseFileIds?.map((id: number) => ({ CaseFileId: id })) || [],
            XrefWorkItemProviders: formValue.ProviderId ? [{ ProviderId: formValue.ProviderId }] : []
          });
          console.log('Update result:', result);
        } catch (error) {
          console.error('Error in updateWorkItem:', error);
          throw error;
        }
      } else {
        // Add case (keep existing logic)
        result = await this.workItemsService.createWorkItem({
          ...formValue,
          XrefWorkItemCaseFiles: formValue.caseFileIds?.map((id: number) => ({ CaseFileId: id })) || [],
          XrefWorkItemProviders: formValue.ProviderId ? [{ ProviderId: formValue.ProviderId }] : []
        });
      }

      this.formSubmitted.emit();
      return result;
    } catch (error) {
      console.error('Work item form submission error:', error);
      return null;
    } finally {
      this.submitting = false;
    }
  }

  private async checkProviderVisibility(categoryId: number) {
    if (!categoryId) return;

    try {
      const categories = await this.workItemsService.getCategories();
      const selectedCategory = categories.find(c => c.Id === categoryId);

      const showProviderField = selectedCategory?.Name === 'Provider' ||
                              selectedCategory?.Name === 'Provider Agreement';
      this.showProviderField = showProviderField;

      if (showProviderField) {
        // Load providers data only when the field becomes visible
        await this.loadProvidersData();
      }

      const providerControl = this.workItemForm.get('ProviderId');
      if (providerControl) {
        if (showProviderField) {
          providerControl.setValidators([Validators.required]);
        } else {
          providerControl.clearValidators();
          providerControl.setValue(null);
        }
        providerControl.updateValueAndValidity({ emitEvent: false });
      }
    } catch (error) {
      console.error('Error checking provider visibility:', error);
    }
  }

  private async updateFormWithWorkItem(item: any) {
    try {
      console.log('Updating form with work item:', item);

      // Clear any existing data first
      this.filteredTypes = [];

      // Handle case files
      if (item.XrefWorkItemCaseFiles?.length) {
        const caseFileIds = item.XrefWorkItemCaseFiles
          .map((x: any) => Number(x.CaseFile?.Id || x.CaseFileId))
          .filter((id: number) => id);
        console.log('Setting case file IDs:', caseFileIds);
        this.selectedCaseFiles = caseFileIds;
        this.originalCaseFileIds = [...caseFileIds];
      }

      // Store original provider ID
      this.originalProviderId = item.XrefWorkItemProviders?.[0]?.ProviderId;

      // Get the type first to ensure we have its CategoryId
      const typeId = Number(item.Type?.Id || item.TypeId);
      console.log('Type data:', item.Type);

      // Try to get CategoryId from multiple sources
      let categoryId = Number(item.Category?.Id || item.CategoryId);
      if (isNaN(categoryId) && item.Type?.CategoryId) {
        categoryId = Number(item.Type.CategoryId);
        console.log('Using CategoryId from Type:', categoryId);
      }

      if (isNaN(categoryId)) {
        // If still no CategoryId, try to get it from the types list
        const types = await this.workItemsService.getTypes();
        const type = types.find(t => t.Id === typeId);
        if (type) {
          categoryId = Number(type.CategoryId);
          console.log('Found CategoryId from types list:', categoryId);
        }
      }

      if (!isNaN(categoryId)) {
        console.log('Setting initial CategoryId:', categoryId);
        // Set category first and wait for it to be processed
        await this.workItemForm.patchValue({
          CategoryId: categoryId
        }, { emitEvent: false }); // Prevent initial category change event

        // Wait for types to be filtered based on category
        await this.updateTypesByCategory(categoryId);

        // Now set all other form values
        const formValues = {
          Id: item.Id,
          Title: item.Title,
          Description: item.Description,
          StatusId: Number(item.Status?.Id || item.StatusId),
          PriorityId: Number(item.Priority?.Id || item.PriorityId),
          TypeId: typeId,
          AssignedTo: item.AssignedTo,
          DueDate: item.DueDate ? new Date(item.DueDate) : null,
          caseFileIds: item.XrefWorkItemCaseFiles?.map((x: any) => x.CaseFile?.Id || x.CaseFileId) || [],
          ProviderId: Number(item.XrefWorkItemProviders?.[0]?.Provider?.Id ||
                           item.XrefWorkItemProviders?.[0]?.ProviderId)
        };

        console.log('Setting form values:', formValues);
        await this.workItemForm.patchValue(formValues, { emitEvent: false });

        await this.checkProviderVisibility(categoryId);

        // Log final form state
        console.log('Final form values:', this.workItemForm.value);
        console.log('Filtered types available:', this.filteredTypes);
      } else {
        console.error('Could not determine CategoryId from work item:', item);
      }
    } catch (error) {
      console.error('Error updating form with work item:', error);
    }
  }

  private async updateTypesByCategory(categoryId: number) {
    try {
      if (!categoryId || isNaN(categoryId)) {
        this.filteredTypes = [];
        return;
      }

      const types = await this.workItemsService.getTypes();
      const currentTypeId = this.workItemForm.get('TypeId')?.value;

      // Filter by category
      this.filteredTypes = types.filter(type =>
        type.CategoryId === categoryId ||
        (currentTypeId && type.Id === currentTypeId)
      );

    } catch (error) {
      console.error('Error updating types by category:', error);
      this.filteredTypes = [];
    }
  }

  async setDefaultCategory(categoryName: string) {
    try {
      const categories = await this.workItemsService.getCategories();
      const category = categories.find(c => c.Name === categoryName);
      if (category) {
        this.workItemForm.patchValue({ CategoryId: category.Id });
      }
    } catch (error) {
      console.error('Error setting default category:', error);
    }
  }

  // Add new method to reset form
  private resetForm() {
    console.log('Resetting form');
    this.workItemForm.reset();
    this.filteredTypes = [];
    this.selectedCaseFiles = [];
    this.originalCaseFileIds = [];
    this.originalProviderId = undefined;
    this.showProviderField = false;
  }

  private onDialogClose() {
    this.resetForm();
    this.setDefaultValues();
    this.visibleChange.emit(false);
  }

  private onCancel() {
    this.onDialogClose();
    this.formCancelled.emit();
  }

  private async loadCaseFilesData() {
    if (this.caseFilesData.length === 0) {
      const response = await this.caseFilesDataManager
        .executeQuery(new Query().select(['Id', 'FileNumber'])) as unknown as { result: any[] };
      this.caseFilesData = response.result;
    }
  }

  private async loadProvidersData() {
    if (this.providersData.length === 0) {
      const response = await this.providersDataManager
        .executeQuery(new Query().select(['Id', 'Name'])) as unknown as { result: any[] };
      this.providersData = response.result;
    }
  }
}
