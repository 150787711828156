// Angular
import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

// 3rd Party
import { DataManager, Query } from '@syncfusion/ej2-data';
import { RowSelectEventArgs, CommandClickEventArgs, EditEventArgs} from '@syncfusion/ej2-angular-grids';
import { DropDownList, DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { GridTemplateComponent } from '@grids/grid-template/grid-template.component';
import { User } from '@models/data-contracts';
import { AddUserComponent } from '@components/forms/user/add/add-user.component';
import { ComponentBase } from '@core/base/component.base';
import { BaseGridComponent } from '@shared/components/base-grid/base-grid.component';
import { BaseGridService } from '@shared/components/base-grid/services/state.service';
import { SetGridDataArgs } from '@shared/components/base-grid/models/grid.models';
import { BaseGridHooks } from '@shared/components/base-grid/services/hooks.service';
import { UsersService } from '../services/users.service';

@Component({
  selector: 'users-grid',
  standalone: true,
  imports: [
    CommonModule,
    DropDownListModule,
    DialogModule,
    AddUserComponent,
    BaseGridComponent
  ],
  providers: [
    BaseGridHooks,
    BaseGridService,
    UsersService
  ],
  templateUrl: './users-grid.component.html',
  styleUrls: ['./users-grid.component.scss']
})
export class UsersGridComponent extends BaseGridComponent {
  // Grid variables
  @ViewChild('usersGrid') usersGrid: GridTemplateComponent;
  @ViewChild('XrefUsersRolesEditTemplate', { static: true }) XrefUsersRolesEditTemplate!: string;
  @ViewChild('addUserDialog') addUserDialog: DialogComponent;
  @ViewChild('rolesDD') rolesDD: DropDownList;
  @ViewChild('editUserDialog') editUserDialog: any;
  gridCurrentRow: RowSelectEventArgs;
  isAdmin: boolean = false;
  showAddUserDialog = false;
  showEditUserDialog: boolean = false;
  rolesData: DataManager;
  selectedUser: User | null = null;
  mode: 'add' | 'edit' = 'add';
  showAddUserForm = false;

  usersGridSettings: SetGridDataArgs = {
    endpoint: APIEndpoints.Users,
    name: 'Users',
    useRoundedEdges: true,
    query: this.usersService.getUsersGridQuery(),
    transformData: (data: any[]) => this.usersService.transformUsersForGrid(data),
    gridSettings: {
      columns: [
        { field: 'Id', isPrimaryKey: true, visible: false, showInColumnChooser: false },
        { field: 'Username', allowEditing: false },
        { field: 'Name', allowEditing: false },
        { field: 'Email', allowEditing: false },
        { field: 'Title' },
        {
          field: 'RoleNames',
          headerText: 'Role',
          allowFiltering: false,
          allowSorting: false,
          editTemplate: this.XrefUsersRolesEditTemplate
        },
        { type: 'commands' }
      ],
      toolbar: ['Add User'],
      toolbarClick: ($event: any) => this.onToolbarClick($event),
      commandClick: ($event: CommandClickEventArgs) => this.onCommandClick($event)
    }
  };

  constructor(
    private usersService: UsersService
  ) {
    super();
    this.rolesData = this.usersService.getRolesDataManager();
  }

  onCreated() {
    this.usersGrid.grid.toolbar = this.usersGrid.settings.toolbar as any[];
    this.usersGrid.insertToolbarItem({
        text: 'Add User',
        tooltipText: 'Add User',
        prefixIcon: 'e-icons e-add',
        align: 'Right',
        cssClass: 'e-primary'
    });
  }

  onActionComplete(args: EditEventArgs) {
    if (args.requestType === 'beginEdit') {
      const dialog = (args as any).dialog;
      dialog.header = `Editing ${(args.rowData as any)?.Username}`;
    }
  }

  onToolbarClick(args: any) {
    if (args.item.text === 'Add') {
      args.cancel = true;
      this.mode = 'add';
      this.selectedUser = null;
      this.showAddUserDialog = true;
      this.showAddUserForm = true;
      if (this.addUserDialog) {
        this.addUserDialog.show();
        this.addUserDialog.header = 'Add User';
      }
    }
  }

  onCommandClick(commandClickArgs: CommandClickEventArgs) {
    if (commandClickArgs.commandColumn?.type === 'Edit') {
      commandClickArgs.cancel = true;
      this.mode = 'edit';
      this.selectedUser = commandClickArgs.rowData as User;
      this.showAddUserDialog = true;
      this.showAddUserForm = true;
      if (this.addUserDialog) {
        this.addUserDialog.show();
        this.addUserDialog.header = 'Edit User';
      }
    }
  }

  // Capture changes made to user's role
  rolesDDChanged(args: any): void {
    if (args.isInteracted) {
      var selectedRows = this.usersGrid.grid?.getSelectedRowIndexes();

      if (this.usersGrid.grid?.editSettings.mode === 'Batch') {
        if (selectedRows && selectedRows.length === 1) {
          var currentCell = this.usersGrid.grid?.getCellFromIndex(selectedRows[0], this.usersGrid.grid?.getColumnIndexByField('RoleNames'));
          this.usersGrid.grid.updateCell(selectedRows[0], 'RoleNames', args.value);
          currentCell.innerHTML = args.itemData.RoleName;
        }
      }
    }
  }

  handleCloseForm(refresh: boolean = false): void {
    this.showAddUserDialog = false;
    this.showAddUserForm = false;
    this.mode = 'add';
    this.selectedUser = null;
  }

  handleAddUser(): void {
    this.mode = 'add';
    this.selectedUser = null;
    this.showAddUserForm = true;
  }

  handleEditUser(user: User): void {
    this.mode = 'edit';
    this.selectedUser = user;
    this.showAddUserForm = true;
  }
}
