// Angular
import { inject, Injectable } from '@angular/core';
import { BaseFormService } from './base-form.service';
import { FormGroup } from '@angular/forms';

/**
 * Interface representing form data structure
 */
interface FormData {
  [key: string]: unknown;
}

/**
 * Base form hooks service that provides utility methods and hooks for form operations.
 * This service acts as a bridge between the form component and form service.
 */
@Injectable({
  providedIn: 'root'
})
export class BaseFormHooks {
  protected readonly services = inject(BaseFormService);

  /**
   * Resets the form and its state
   *
   * @param formGroup - Form group instance to reset
   */
  resetForm(formGroup: FormGroup): void {
    formGroup.reset();
    this.services.resetState();
    this.onReset();
  }

  /**
   * Initializes the form with provided data
   *
   * @param formGroup - Form group instance to initialize
   * @param data - Data to populate the form
   */
  initializeFormWithData(formGroup: FormGroup, data: FormData): void {
    this.services.setFormData({
      initialData: data
    });
    formGroup.patchValue(data);
  }

  /**
   * Initializes an empty form for create mode
   *
   * @param formGroup - Form group instance to initialize
   */
  initializeEmptyForm(formGroup: FormGroup): void {
    this.services.resetState();
    formGroup.reset();
  }

  /**
   * Hook method for handling form errors
   * Can be overridden by implementing forms
   *
   * @param error - Error to handle
   */
  onError(error: unknown): void {
    this.services.setError('form', error);
  }

  /**
   * Hook method for handling form reset
   * Can be overridden by implementing forms
   */
  onReset(): void {}

  /**
   * Hook method for handling form cancellation
   * Can be overridden by implementing forms
   */
  onCancel(): void {}

  /**
   * Validates if the form data has changed
   *
   * @param initialValue - Initial form value
   * @param currentValue - Current form value
   * @returns boolean indicating if the form has changed
   */
  isValid(initialValue: FormData | null, currentValue: FormData): boolean {
    if (!initialValue) return true;
    return JSON.stringify(initialValue) !== JSON.stringify(currentValue);
  }

  /**
   * Determines if the form can be submitted
   *
   * @param hasChanges - Whether the form has changes
   * @param isValid - Whether the form is valid
   * @param initialValue - Initial form value
   * @returns boolean indicating if the form can be submitted
   */
  canSubmit(hasChanges: boolean, isValid: boolean, initialValue: FormData | null): boolean {
    if (!initialValue) return isValid;
    return hasChanges && isValid;
  }
}
