<div id="invoices-grid" class="invoices-grid">
    <base-grid [settings]="settings">
        <ng-template #providersTemplate let-data>
            <div class="custom-template">
                <label class="form-label">Provider</label>
                <ejs-dropdownlist #providersDropdown
                    [dataSource]="providersData"
                    [fields]="{ value: 'Id', text: 'Name' }"
                    [value]="data.ProviderId" />
            </div>
        </ng-template>

        <ng-template #paymentStatusesEditTemplate let-data>
            <div class="custom-template">
                <label class="form-label">Payment Status</label>
                <ejs-dropdownlist #paymentStatusDropdown
                    [dataSource]="paymentStatuses"
                    [fields]="{ value: 'Id', text: 'Description' }"
                    [value]="data.InvoicePaymentPaymentId" />
            </div>
        </ng-template>

        <ng-template #procedureCodesTemplate let-data>
            <div class="custom-template">
                <label class="form-label">Procedure</label>
                <ejs-dropdownlist #proceduresDropdown
                    [dataSource]="proceduresData"
                    [fields]="{ value: 'Id', text: 'Description' }"
                    [value]="data.Id" />
            </div>
        </ng-template>

        <div class="dialog-container">
            <ejs-dialog id="add-new-invoice-dialog" #addInvoiceDialog
                [showCloseIcon]="true"
                [target]="'body'"
                [isModal]="true"
                [closeOnEscape]="true"
                [visible]="false"
                [buttons]="addInvoiceButtons"
                header="Add Invoice"
                (beforeOpen)="beforeOpening($event)"
                (close)="closeDialog()">
                <invoice-form #newInvoiceForm />
            </ejs-dialog>
        </div>

        <div class="dialog-container">
            <ejs-dialog id="edit-invoice-dialog" #editInvoiceDialog
                [showCloseIcon]="true"
                [target]="'body'"
                [isModal]="true"
                [closeOnEscape]="true"
                [visible]="false"
                [buttons]="addInvoiceButtons"
                header="Edit Invoice"
                (beforeOpen)="beforeOpening($event)"
                (close)="closeDialog()">
                <ng-container *ngIf="invoicesService.selectedInvoiceValue() as invoice">
                    <invoice-form #editInvoiceForm [currentInvoice]="invoice" />
                </ng-container>
            </ejs-dialog>
        </div>

        <div class="dialog-container">
            <ejs-dialog id="bulk-invoice-dialog" #bulkEditInvoicesDialog
                [showCloseIcon]="true"
                [target]="'body'"
                [isModal]="true"
                [closeOnEscape]="true"
                [visible]="false"
                [buttons]="bulkUpdateInvoicesButtons"
                header="Bulk Edit"
                (beforeOpen)="beforeOpening($event)"
                (close)="closeDialog()">
                <bulk-edit-invoices-form #bulkEditForm />
            </ejs-dialog>
        </div>
    </base-grid>
</div>