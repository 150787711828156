// Angular
import { Component, Input, OnInit, AfterViewInit, ViewChild, ViewEncapsulation, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';

// 3rd Party
import { GridModel } from '@syncfusion/ej2-grids';
import { Query, Predicate, DataManager, ReturnOption } from '@syncfusion/ej2-data';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';

// Internal
import { ApiService } from '@services/api/api.service';
import { GlobalsService } from '@services/globals/globals.service';
import { GridTemplateComponent } from '@grids/grid-template/grid-template.component';
import { GridTemplateModule } from '@modules/grid-template.module';

@Component({
  selector: 'custom-assignments-editor',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    GridTemplateModule
  ],
  templateUrl: './custom-assignments-editor.component.html',
  styleUrl: './custom-assignments-editor.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class CustomAssignmentsEditorComponent implements OnInit, AfterViewInit, OnChanges {

  constructor(
    public globals: GlobalsService
  ) {}

  // Inputs
  @Input() availableGridSettings: GridModel;
  @Input() assignmentsGridSettings: GridModel
  @Input() assignmentsGridPredicate: Predicate;
  @Input() assignmentsGridPredicateField: string;
  @Input() assignmentsIds: any;
  @Input() showAvailableHeader: boolean = true;
  @Input() showAssignedHeader: boolean = true;

  // Grids
  @ViewChild('availableGrid') availableGrid: GridTemplateComponent;
  @ViewChild('assignedGrid') assignedGrid: GridTemplateComponent;
  assignmentsData: any;
  availableSelection: object[];
  assignmentsSelection: object[];

  ngOnInit(): void {
    this.availableGridSettings.rowDataBound = ($args: any) => this.customizeAvailableBackgroundColor($args);
  }

  ngAfterViewInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes['assignmentsGridPredicate'] && !changes['assignmentsGridPredicate'].isFirstChange()) {
      this.updateAssignedGrid();
    }
  }

  // Updates data list 
  private updateAssignedGrid(): void {
    const assignmentsQuery = new Query().where(this.assignmentsGridPredicate)

    if (this.assignedGrid.grid) {
      this.assignedGrid.grid.query = assignmentsQuery;
      this.assignedGrid.grid.refresh();
    }
  }

  // Apply a background color to any rows in this.assignedRights
  customizeAvailableBackgroundColor(args: any): void {
    const assignmentsSet = new Set(this.assignmentsIds);

    if (assignmentsSet.has(args.data.Id)) {
      args.row.classList.add('assigned');
    }
  }

  // Add selection from avilableGrid to assignedGrid
  addSelectionToAssignedGrid(): void {
    this.availableSelection = this.availableGrid.grid.getSelectedRecords();
    this.availableSelection.forEach((selected: any) => {
      this.assignedGrid.grid.editModule.addRecord(selected);
    });

    this.availableGrid.grid.refresh();
    this.assignedGrid.grid.refresh();
  }

  // Remove selected role to assignedRightsGrid
  removeSelectionFromAssignedGrid(): void {
    this.assignmentsSelection = this.assignedGrid.grid.getSelectedRecords();
    this.assignmentsSelection.forEach((selected: any) => {
      this.assignedGrid.grid.editModule.deleteRecord(selected);
    });

    this.availableGrid.grid.refresh();
    this.assignedGrid.grid.refresh();
  }
  
  // Finalize changed settings
  submitRoleChanges(): void {
    console.log(this.availableGrid.grid);
    console.log(this.assignedGrid.grid);
    // this.assignedGrid.grid.editSettings.showConfirmDialog = false;
    // this.assignedGrid.grid.editModule.batchSave();
    this.availableGrid.grid.refresh();
    this.assignedGrid.grid.refresh();
  }
}
