// Angular
import { Component, OnInit } from '@angular/core';

// 3rd Party
import { signOut } from 'aws-amplify/auth';
import { ToastMessageService } from '@services/toast-message/toast-message.service';

// Internal
import { AmplifyAuthenticatorComponent } from '@features/authenticator/amplify-authenticator.component';
import { UserPreferencesService } from '@services/user/user-preferences.service';

@Component({
  selector: 'app-root-page',
  standalone: true,
  imports: [AmplifyAuthenticatorComponent],
  templateUrl: './root-page.component.html',
  styleUrl: './root-page.component.scss'
})
export class RootPageComponent implements OnInit {
  
  constructor(
    private user: UserPreferencesService,
    private toast: ToastMessageService
  ) {}

  ngOnInit(): void {
    this.handleSignOut();
  }

  // Sign users out when they navigate to the root
  async handleSignOut() {
    try {
      await signOut();
      this.user.isLoggedIn = false;
      localStorage.removeItem('userPreferences');
    } catch (error) {
      this.toast.showError('Error logging out.')
      console.log('signOut() error: ', error);
    }
  }
}