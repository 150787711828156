import { FormBuilder, FormGroup, Validators } from '@angular/forms';

const fb = new FormBuilder();

export const providerForm: FormGroup = fb.group({
    Name: [null, [Validators.required]],
    ProviderType: [null],
    TaxId: [null],
    Notes: [null],
    CareCoordinator: [null],
    ProviderRelationsManager: [null],
    ReimbursementRate: [null],
    ReimbursementRateEffectiveDate: [null],
    FeeScheduleId: [null],
    InNetwork: [true],
    PriorityId: [null],
    XrefAddressProviders: [null],
    XrefPhoneProviders: [null],
    Latitude: [null],
    Longitude: [null],
    SignedPurchaseAgreement: [0],
    SignedHipaa: [0],
    SignedHipaaDate: [null],
    SignedW9: [0],
    SignedW9Date: [null],
    W9Name: [null],
    SpanishSpeaking: [false],
    CheckpointEligible: [false],
    ReferringProvider: [0],
    HoldsOwnAr: [false],
    VirtualProvider: [0],
    AlternateId: [null],
    IsActive: [true],
    WebsiteUrl: [null],
    NotesImportant: [false],
});

export const providerBulkEditForm: FormGroup = fb.group({
  ReimbursementRate: [null],
  ReimbursementRateEffectiveDate: [null],
  InNetwork: [true],
  SignedPurchaseAgreement: [0],
  SignedHipaa: [0],
  SignedHipaaDate: [null],
  SignedW9: [0],
  IsActive: [true]
});
