import { Injectable, inject } from '@angular/core';
import { Observable, catchError } from 'rxjs';
import { AuthenticatedServiceBase } from '@core/auth/auth.base';
import { HttpService } from '@core/http/http.service';
import { NotificationSeverity } from '@core/enums/notification-severity.enum';
import { HttpHeaders, HttpParams } from '@angular/common/http';

export interface AgentResponse {
  response: string;
  metadata?: any;
}

@Injectable({
  providedIn: 'root',
})
export class AgentService extends AuthenticatedServiceBase {
  protected override readonly endpoint = 'agent';
  private readonly http = inject(HttpService);

  constructor() {
    super();
  }

  private getHttpOptions(params?: { [key: string]: string }): { headers: HttpHeaders; params?: HttpParams } {
    const options: { headers: HttpHeaders; params?: HttpParams } = {
      headers: new HttpHeaders(this.getAuthHeaders()),
    };

    if (params) {
      options.params = new HttpParams({ fromObject: params });
    }

    return options;
  }

  sendMessage(message: string, caseId?: string): Observable<AgentResponse> {
    return this.http.post<AgentResponse>(`${this.endpoint}/chat`, { message, caseId }, this.getHttpOptions()).pipe(
      catchError(error => {
        this.handleError(error, {
          userMessage: 'Failed to send message to agent',
          context: 'AgentService.sendMessage',
        });
        throw error;
      })
    );
  }

  getCaseStatus(caseId: string): Observable<AgentResponse> {
    return this.http.get<AgentResponse>(`${this.endpoint}/case/${caseId}/status`, this.getHttpOptions()).pipe(
      catchError(error => {
        this.handleError(error, {
          userMessage: 'Failed to get case status',
          context: 'AgentService.getCaseStatus',
        });
        throw error;
      })
    );
  }

  analyzeComments(caseId: string): Observable<AgentResponse> {
    return this.http.get<AgentResponse>(`${this.endpoint}/case/${caseId}/comments`, this.getHttpOptions()).pipe(
      catchError(error => {
        this.handleError(error, {
          userMessage: 'Failed to analyze comments',
          context: 'AgentService.analyzeComments',
        });
        throw error;
      })
    );
  }

  searchComments(caseId: string, query: string): Observable<AgentResponse> {
    return this.http.get<AgentResponse>(`${this.endpoint}/case/${caseId}/search`, this.getHttpOptions({ query })).pipe(
      catchError(error => {
        this.handleError(error, {
          userMessage: 'Failed to search comments',
          context: 'AgentService.searchComments',
        });
        throw error;
      })
    );
  }
}
