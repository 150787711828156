// Angular
import { Component, Input, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
// 3rd Party
import { Toolbar } from '@syncfusion/ej2-navigations';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
// Internal
import { FileViewService } from '@root/src/app/shared/services/file-view/file-view.service';

@Component({
  selector: 'bottom-nav',
  standalone: true,
  imports: [
    ToolbarModule,
    ButtonModule
  ],
  templateUrl: './bottom-nav.component.html',
  styleUrl: './bottom-nav.component.scss',
})
export class BottomNavComponent {
  constructor(private fileView: FileViewService) {}
  @Input() items: any[];
  @Input() isCaseFile: boolean;

  @ViewChild('toolbar') public toolbar: Toolbar;
  tabSettings$: BehaviorSubject<any>;
  ngOnInit() {
    if(this.isCaseFile) {
      this.tabSettings$ = this.fileView.tabSettingsSubject;
      this.tabSettings$.subscribe((settings) => {
        this.updateTabs(settings);
      })
    }
  }


  updateTabs(settings: any) {
    this.items = this.fileView.tabs.map((tab) => ({
      ...tab,
      active: settings[tab.text] ?? tab.active,
    }));
  }
}
