// Angular
import { NavigationEnd, Router } from '@angular/router';
import { Component, AfterViewInit, ElementRef, Renderer2, ViewEncapsulation, WritableSignal, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MainContent } from '@layout/main/main.component';
// Internal
import { HeaderContentModule } from '@modules/header.module';
import { UserPreferencesService } from '@services/user/user-preferences.service';
import { FileViewService } from '@services/file-view/file-view.service';
import { GlobalsService } from '@services/globals/globals.service';
import { BottomNavComponent } from '@ui/bottom-nav/bottom-nav.component';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [
    HeaderContentModule,
    MainContent,
    BottomNavComponent,
    CommonModule,
    RouterOutlet
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './layout-wrapper.component.html',
  styleUrl: './layout-wrapper.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class LayoutWrapper implements AfterViewInit {

  constructor(
    private router: Router,
    private element: ElementRef,
    private renderer: Renderer2,
    private user: UserPreferencesService,
    private fileViewService: FileViewService,
    public globals: GlobalsService
  ) { }

  isRoot: boolean;
  layoutMode: WritableSignal<string> = signal('');
  viewingCaseFilesHub: WritableSignal<boolean> = signal(false);
  bottomNavItems: any = this.fileViewService.tabs;

  ngOnInit() {
    this.user.appLayout$.subscribe((layoutMode) => {
      this.layoutMode.set(layoutMode);
    });
    this.checkIfViewingCaseFilesHub();
  }

  ngAfterViewInit(): void {
    this.isRoot = this.router.url === '/';
    this.renderer.addClass(this.element.nativeElement, 'd-flex');
    this.renderer.addClass(this.element.nativeElement, 'position-relative');
    this.renderer.addClass(this.element.nativeElement, 'flex-column'); // .addClass does not accept array values
    this.globals.removeSyncfusionTrialBanner();

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.isRoot = this.router.url === '/';
      }
    });
  }

  checkIfViewingCaseFilesHub() {
    const currentRoute = this.router.url;
    const caseFilesPattern = /\/case-files\/([^\/?#]+)/;
    const match = currentRoute.match(caseFilesPattern);

    if (match && match[1]) {
      this.viewingCaseFilesHub.set(true);
    }
  }
}
