<div id="deposits-grid" class="deposits-grid">
    
    @if (depositsGridSettings) { 
        <grid-template #depositsGrid [settings]="depositsGridSettings" [name]="'Deposits'" /> 
    }
    
    @if(addDepositDialogVisibility) {
        <ejs-dialog id="add-new-deposit-dialog" #addDepositDialog 
            [showCloseIcon]="true"
            [target]="modalTarget"
            [isModal]="true"
            [closeOnEscape]="true"
            [visible]="addDepositDialogVisibility"
            [buttons]="addDepositButtons"
            header="Add Deposit"
            (beforeOpen)="beforeOpening($event)"
            (close)="closeDialog()">
            <add-deposit-form #newDepositForm />
        </ejs-dialog>
    }

    <!-- Bulk Edit -->
    @if (bulkEditDepositsDialogVisibility) {
        <ejs-dialog id="bulk-edit-deposit-dialog" #bulkEditDepositsDialog
            [showCloseIcon]="true"
            [target]="modalTarget"
            [isModal]="true"
            [closeOnEscape]="true"
            [visible]="bulkEditDepositsDialogVisibility"
            [buttons]="bulkUpdateDepositsButtons"
            header="Bulk Edit"
            (beforeOpen)="beforeOpening($event)"
            (close)="closeDialog()">
            <bulk-edit-deposits-form #bulkEditForm />
        </ejs-dialog>
    }
</div>