// Angular
import { computed, inject, Injectable, signal } from '@angular/core';

// 3rd Party
import {
  GridModel,
  RowDataBoundEventArgs,
  EditEventArgs,
  CommandClickEventArgs,
  RowSelectEventArgs,
  DataStateChangeEventArgs,
  CellEditArgs,
  QueryCellInfoEventArgs,
  DetailDataBoundEventArgs,
  BeforeCopyEventArgs,
  GridComponent
} from '@syncfusion/ej2-angular-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import { Tooltip } from '@syncfusion/ej2-angular-popups';
import { Predicate, Query } from '@syncfusion/ej2-data';

// Models
import { CustomToolbarItem, SetGridDataArgs } from '../models/grid.models';

// Services
import { BaseGridService } from './state.service';
import { BaseGridSignals } from './signals.service';
import { AuthenticatedServiceBase } from '@core/auth/auth.base';

/**
 * Custom Constants
 */
// Error Messages
const ERROR_MESSAGES = {
  EXPORT: {
    EXCEL: {
      userMessage: 'Failed to export to Excel',
      context: 'BaseGridHooks.onToolbarClick',
    },
    PDF: {
      userMessage: 'Failed to export to PDF',
      context: 'BaseGridHooks.onToolbarClick',
    },
    PDF_NO_DATA: {
      userMessage: 'No data available to export',
      context: 'BaseGridHooks.onToolbarClick',
    },
    CSV: {
      userMessage: 'Failed to export to CSV',
      context: 'BaseGridHooks.onToolbarClick',
    },
    PRINT: {
      userMessage: 'Failed to print grid',
      context: 'BaseGridHooks.onToolbarClick',
    }
  }
};

/**
 * Class Declaration
 */
@Injectable()
export class BaseGridHooks extends BaseGridService {
  // Injections
  protected readonly services = inject(BaseGridService);
  protected override readonly signals = inject(BaseGridSignals);

  // Signals
  protected readonly gridSettings = computed(() => this.signals.gridSettings());
  protected readonly selectedRecords = signal<any[]>([]);
  protected readonly toolbarState = signal<CustomToolbarItem[]>([]);
  protected readonly bulkEditing = computed(() => this.signals.gridSetupArgs()?.bulkEditing);

  private lastDataStateChange: {
    skip?: number;
    take?: number;
    sorted?: any[];
    where?: any[];
    search?: any[];
  } | null = null;

  constructor() {
    super();
  }

  /**
   * Returns the current date formatted as DD-MM-YYYY
   * @returns {string} Formatted date string
   */
  protected getFormattedDate(): string {
    const date = new Date();
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  }

  onLoad(args: any, settings: GridModel, grid: GridComponent): void {
    try {
      if (settings?.load) {
        settings.load(args);
      }

      this.addPagerClasses(grid);
      const gridTemplate = document.querySelector('#grid-template') as HTMLElement;
      if (gridTemplate) grid.adaptiveDlgTarget = gridTemplate;
      this.signals.setGridComponent(grid);
      this.signals.setToolbar(grid.toolbar as CustomToolbarItem[]);
    } catch (error) {
      this.handleError(error, {
        userMessage: 'Error loading grid',
        context: 'BaseGridHooks',
        severity: this.ErrorSeverity.Error,
      });
      throw error;
    }
  }

  addPagerClasses(grid: GridComponent): void {
    try {
      if (grid?.pageSettings.pageSizes) {
        document.querySelector('.e-adaptive')?.classList.add('e-pager-pagesizes');
      } else {
        document.querySelector('.e-adaptive')?.classList.remove('e-pager-pagesizes');
      }
    } catch (error) {
      this.handleError(error, {
        userMessage: 'Error adding pager classes',
        context: 'BaseGridHooks',
        severity: this.ErrorSeverity.Error,
      });
      throw error;
    }
  }

  onCreated(args: any, settings: GridModel): void {
    try {
      if (settings?.created) {
        settings.created(args);
      }
    } catch (error) {
      this.handleError(error, {
        userMessage: 'Error onCreated',
        context: 'BaseGridHooks',
        severity: this.ErrorSeverity.Error,
      });
      throw error;
    }
  }

  onActionBegin(args: any, settings: GridModel): void {
    try {
      if (settings?.actionBegin) {
        settings.actionBegin(args);
      }
    } catch (error) {
      this.handleError(error, {
        userMessage: 'Error onActionBegin',
        context: 'BaseGridHooks',
        severity: this.ErrorSeverity.Error,
      });
      throw error;
    }
  }

  onActionComplete(args: any, settings: GridModel): void {
    try {
      if (settings?.actionComplete) {
        settings.actionComplete(args);
      }
    } catch (error) {
      this.handleError(error, {
        userMessage: 'Error onActionComplete',
        context: 'BaseGridHooks',
        severity: this.ErrorSeverity.Error,
      });
      throw error;
    }
  }

  onToolbarClick(args: ClickEventArgs, settings: GridModel, grid: GridComponent): void {
    try {
      grid = grid || this.grid();
      const itemText = args.item.text;
      const itemId = args.item.id;
      const selectedRecords = grid.getSelectedRecords();
      const gridName = this.signals.gridName();

      if (itemText === 'Add' || itemId === 'Add') {
        args.cancel = true;
      } else if (itemText === 'Copy' || itemId === 'Copy') {
        try {
          grid.copy();
        } catch (error) {
          this.handleError(error, {
            ...ERROR_MESSAGES.EXPORT.EXCEL,
            severity: this.ErrorSeverity.Error,
          });
        }
      } else if (itemText === 'Excel Export' || itemId === 'ExcelExport') {
        try {
          if (selectedRecords.length > 0) {
            grid.excelExport({
              dataSource: selectedRecords,
              fileName: `${gridName}_${this.getFormattedDate()}.xlsx`
            });
          } else {
            grid?.excelExport({ fileName: `${gridName}_${this.getFormattedDate()}.xlsx` });
          }
        } catch (error) {
          this.handleError(error, {
            ...ERROR_MESSAGES.EXPORT.EXCEL,
            severity: this.ErrorSeverity.Error,
          });
        }
      } else if (itemText === 'PDF Export' || itemId === 'PdfExport') {
        try {
          // Ensure grid has data before attempting export
          if (grid?.dataSource && Array.isArray(grid.dataSource) && grid.dataSource.length > 0) {
            if (selectedRecords.length > 0) {
              grid.pdfExport({
                dataSource: selectedRecords,
                fileName: `${gridName}_${this.getFormattedDate()}.pdf`
              });
            } else {
              grid.pdfExport({ fileName: `${gridName}_${this.getFormattedDate()}.pdf` });
            }
          } else {
            this.handleError(new Error('No data available for PDF export'), {
              ...ERROR_MESSAGES.EXPORT.PDF_NO_DATA,
              severity: this.ErrorSeverity.Warning,
            });
          }
        } catch (error) {
          this.handleError(error, {
            ...ERROR_MESSAGES.EXPORT.PDF,
            severity: this.ErrorSeverity.Error,
          });
        }
      } else if (itemText === 'CSV Export' || itemId === 'CsvExport') {
        try {
          if (selectedRecords.length > 0) {
            grid?.csvExport({
              dataSource: selectedRecords,
              fileName: `${gridName}_${this.getFormattedDate()}.csv`
            });
          } else {
            grid?.csvExport({ fileName: `${gridName}_${this.getFormattedDate()}.csv` });
          }
        } catch (error) {
          this.handleError(error, {
            ...ERROR_MESSAGES.EXPORT.CSV,
            severity: this.ErrorSeverity.Error,
          });
        }
      } else if (itemText === 'Print' || itemId === 'Print') {
        try {
          grid?.print();
        } catch (error) {
          this.handleError(error, {
            ...ERROR_MESSAGES.EXPORT.PRINT,
            severity: this.ErrorSeverity.Error,
          });
        }
      } else if (itemId === 'toggle-active' || itemText === 'View Inactive' || itemText === 'View Active') {
        this.services.toggleActiveRecords(settings, grid);
      }

      if (settings?.toolbarClick) {
        settings.toolbarClick(args);
      }
    } catch (error) {
      this.handleError(error, {
        userMessage: 'Error onToolbarClick',
        context: 'BaseGridHooks',
        severity: this.ErrorSeverity.Error,
      });
      throw error;
    }
  }

  onRowSelected(args: RowSelectEventArgs, settings: GridModel): void {
    try {
      if (settings.rowSelected) {
        settings.rowSelected(args);
      }

      if (this.grid() && this.signals.gridSetupArgs()?.bulkEditing) {
        this.services.addBulkEditToolbarItem(this.grid());
      }
    } catch (error) {
      this.handleError(error, {
        userMessage: 'Error onRowSelected',
        context: 'BaseGridHooks',
        severity: this.ErrorSeverity.Error,
      });
      throw error;
    }
  }

  onRowSelecting(args: RowSelectEventArgs, settings: GridModel): void {
    try {
      if (settings.rowSelecting) {
        settings.rowSelecting(args);
      }

      if (this.grid() && this.signals.gridSetupArgs()?.bulkEditing) {
        this.services.addBulkEditToolbarItem(this.grid());
      }
    } catch (error) {
      this.handleError(error, {
        userMessage: 'Error onRowSelecting',
        context: 'BaseGridHooks',
        severity: this.ErrorSeverity.Error,
      });
      throw error;
    }
  }

  onRowDeselected(args: RowSelectEventArgs, settings: GridModel): void {
    try {
      if (settings.rowDeselected) {
        settings.rowDeselected(args);
      }

      if (this.grid() && this.signals.gridSetupArgs()?.bulkEditing) {
        this.services.addBulkEditToolbarItem(this.grid());
      }
    } catch (error) {
      this.handleError(error, {
        userMessage: 'Error onRowDeselected',
        context: 'BaseGridHooks',
        severity: this.ErrorSeverity.Error,
      });
      throw error;
    }
  }

  onRowDeselecting(args: RowSelectEventArgs, settings: GridModel): void {
    try {
      if (settings.rowDeselecting) {
        settings.rowDeselecting(args);
      }

      if (this.grid() && this.signals.gridSetupArgs()?.bulkEditing) {
        this.services.addBulkEditToolbarItem(this.grid());
      }
    } catch (error) {
      this.handleError(error, {
        userMessage: 'Error onRowDeselecting',
        context: 'BaseGridHooks',
        severity: this.ErrorSeverity.Error,
      });
      throw error;
    }
  }

  onDataStateChange(args: DataStateChangeEventArgs, settings: SetGridDataArgs, grid: GridComponent, customFiltering: any): void {

    try {
      // Skip if already loading or no action
      if (this.loadingData() || !args.action) return;

      // Skip if this is a duplicate state change
      if (this.lastDataStateChange &&
          JSON.stringify(this.lastDataStateChange) === JSON.stringify({
            skip: args.skip,
            take: args.take,
            sorted: args.sorted,
            where: args.where,
            search: args.search
          })) {
        return;
      }

      // Update last state change
      this.lastDataStateChange = {
        skip: args.skip,
        take: args.take,
        sorted: args.sorted,
        where: args.where,
        search: args.search
      };

      // Create base query from settings or new query
      let query = settings.query || new Query();

      // Handle search
      if (args.action.requestType === 'searching') {
        if (args.search && args.search.length > 0) {
          const searchSettings = args.search[0];
          // Use search fields from settings or default to Id
          const searchFields = (searchSettings.fields && searchSettings.fields.length > 0)
            ? searchSettings.fields
            : (settings.searchableFields && settings.searchableFields.length > 0)
              ? settings.searchableFields
              : ['Id'];

          if (searchFields && searchFields.length > 0) {
            const searchValue = searchSettings.key || '';
            const searchPredicates = searchFields.map((field: string) => {
              // For numeric fields (like Id), use exact match
              if (field === 'Id') {
                return new Predicate(field, 'equal', Number(searchValue), true);
              }
              // For text fields, use contains
              return new Predicate(field, 'contains', searchValue, true);
            });
            // Add search predicates to existing query
            query = query.where(Predicate.or(...searchPredicates));
          }
        }
      }

      // Handle other data state changes
      this.services.handleDataStateChanges(args, settings.endpoint, query);
    } catch (error) {
      this.handleError(error, {
        userMessage: 'Error handling data state changes',
        context: 'BaseGridHooks.onDataStateChange',
        severity: this.ErrorSeverity.Error,
      });
      throw error;
    }
  }

  onDataBound(args: any, settings: GridModel): void {
    try {
      if (settings.dataBound) {
        settings.dataBound(args);
      }
    } catch (error) {
      this.handleError(error, {
        userMessage: 'Error onDataBound',
        context: 'BaseGridHooks',
        severity: this.ErrorSeverity.Error,
      });
      throw error;
    }
  }

  onRowDataBound(args: RowDataBoundEventArgs, settings: GridModel): void {
    try {
      if (settings.rowDataBound) {
        settings.rowDataBound(args);
      }
    } catch (error) {
      this.handleError(error, {
        userMessage: 'Error onRowDataBound',
        context: 'BaseGridHooks',
        severity: this.ErrorSeverity.Error,
      });
      throw error;
    }
  }

  onCellEdit(args: CellEditArgs, settings: GridModel): void {
    try {
      if (settings.cellEdit) {
        settings.cellEdit(args);
      }
    } catch (error) {
      this.handleError(error, {
        userMessage: 'Error onCellEdit',
        context: 'BaseGridHooks',
        severity: this.ErrorSeverity.Error,
      });
      throw error;
    }
  }

  onCellSave(args: EditEventArgs, settings: GridModel): void {
    try {
      if (settings.cellSave) {
        settings.cellSave(args);
      }
    } catch (error) {
      this.handleError(error, {
        userMessage: 'Error onCellSave',
        context: 'BaseGridHooks',
        severity: this.ErrorSeverity.Error,
      });
      throw error;
    }
  }

  onCommandClick(args: CommandClickEventArgs, settings: GridModel): void {
    try {
      if (settings.commandClick) {
        settings.commandClick(args);
      }
    } catch (error) {
      this.handleError(error, {
        userMessage: 'Error onCommandClick',
        context: 'BaseGridHooks',
        severity: this.ErrorSeverity.Error,
      });
      throw error;
    }
  }

  onQueryCellInfo(args: QueryCellInfoEventArgs): void {
    try {
      if (!args.cell || !args.data || !args.column?.field) return;

      const field = args.column.field;
      const data = args.data as any;

      if ((field === 'Comments' || field === 'Notes') && data[field]) {
        new Tooltip(
          {
            content: data[field]?.toString() || '',
          },
          args.cell as HTMLElement
        );
      }
    } catch (error) {
      this.handleError(error, {
        userMessage: 'Error onQueryCellInfo',
        context: 'BaseGridHooks',
        severity: this.ErrorSeverity.Error,
      });
      throw error;
    }
  }

  onDetailDataBound(args: DetailDataBoundEventArgs, settings: GridModel): void {
    try {
      if (settings.detailDataBound) {
        settings.detailDataBound(args);
      }
    } catch (error) {
      this.handleError(error, {
        userMessage: 'Error onDetailDataBound',
        context: 'BaseGridHooks',
        severity: this.ErrorSeverity.Error,
      });
      throw error;
    }
  }

  onBeforeCopy(args: BeforeCopyEventArgs, settings: GridModel): void {
    try {
      if (settings.beforeCopy) {
        settings.beforeCopy(args);
      }
    } catch (error) {
      this.handleError(error, {
        userMessage: 'Error onBeforeCopy',
        context: 'BaseGridHooks',
        severity: this.ErrorSeverity.Error,
      });
      throw error;
    }
  }
}
