<div id="edit-users" class="edit-users">

    <div class="d-flex flex-row">
        <h1>{{name}}<span>!</span></h1>
        <h2>{{title}}</h2>
        <p>Modify account</p>
        <app-modal openModalLabel="Change Account Password" btnClass="e-control e-btn">
            <ng-template>
                <h3>Update Password</h3>
                <form [formGroup]="changePasswordFormGroup" (ngSubmit)="changePassword()" class="forgot-password">
                    <div class="form-group">
                        <p>Enter a valid new password and get a confirmation code from the user to change their
                            password.
                        </p>
                    </div>
                    <div class="form-group">
                        <div class="col-12">
                            <label for="password1"><b>New Password</b></label>
                            <ejs-textbox id="password1" type="password" placeholder="Enter a new strong password"
                                required #password1Input formControlName="password1"></ejs-textbox>
                            <div *ngIf="changePasswordFormGroup.get('password1')?.errors && changePasswordFormGroup.get('password1')?.touched"
                                class="e-error">
                                <span
                                    *ngIf="changePasswordFormGroup.get('password1')?.errors?.['passwordLength']">Password
                                    must be at least 8 characters</span>
                                <span
                                    *ngIf="changePasswordFormGroup.get('password1')?.errors?.['passwordUppercase']">Password
                                    must have at least 1 uppercase letter</span>
                                <span
                                    *ngIf="changePasswordFormGroup.get('password1')?.errors?.['passwordLowercase']">Password
                                    must have at least 1 lowercase letter</span>
                                <span
                                    *ngIf="changePasswordFormGroup.get('password1')?.errors?.['passwordNumber']">Password
                                    must have at least 1 number</span>
                                <span
                                    *ngIf="changePasswordFormGroup.get('password1')?.errors?.['passwordSpecialCharacter']">Password
                                    must have at least 1 special character</span>
                                <span *ngIf="changePasswordFormGroup.get('password1')?.errors?.['required']">Entry
                                    required</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-12">
                            <label for="password2"><b>Confirm New Password</b></label>
                            <ejs-textbox id="password2" type="password" placeholder="Confirm new password" required
                                #password2Input formControlName="password2"></ejs-textbox>
                            <div *ngIf="changePasswordFormGroup.errors && changePasswordFormGroup.get('password2')?.touched"
                                class="e-error">
                                <span *ngIf="changePasswordFormGroup?.errors?.['doNotMatch']">Passwords do not
                                    match</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label><b>Confirmation Code</b></label>
                        <ejs-textbox id="confirmation-code" required formControlName="confirmationCode"
                            placeholder="Enter the confirmation code"></ejs-textbox>
                    </div>
                    <div class="form-submit button-container">
                        <button ejs-button type="submit" isPrimary="true">Submit</button>
                        <button ejs-button type="button" isPrimary="false" (click)="handleResetPassword()">Send
                            Confimation Code</button>
                    </div>
                </form>
            </ng-template>
        </app-modal>
    </div>
</div>