// Define error severity separately from notification severity
export enum ErrorSeverity {
    Info = 'info',
    Warning = 'warning',
    Error = 'error',
    Critical = 'critical'
}

export interface ErrorDetails {
    userMessage?: string;
    technicalDetails?: any;
    severity: ErrorSeverity;
    source?: string;
    errorId?: string;
}

export interface ErrorConfig {
    context: string;
    userMessage: string;
    severity?: ErrorSeverity;
    notify?: boolean;
    rethrow?: boolean;
    technicalDetails?: Record<string, unknown>;
}
