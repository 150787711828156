import { Injectable } from "@angular/core";
import { HttpErrorResponse } from '@angular/common/http';
import { AuthenticatedServiceBase } from "@core/auth/auth.base";
import { FileHubService } from "@features/file-hub/services/file-hub.service";
import { Query } from "@syncfusion/ej2-data";
import { CaseFile, Invoice, Deposit } from "@models/data-contracts";

const ERRORS = {
    FINANCIAL: {
        CASE_FILE_MISSING: {
            message: 'Case file information not available',
            technical: 'Case file ID is required for financial data'
        },
        DATA_INVALID: {
            message: 'Financial data is incomplete',
            technical: 'API response missing result'
        }
    }
} as const;

interface ODataResponse<T> {
    result: T[];
    count?: number;
}

@Injectable({ providedIn: 'root' })
export class FinancialService extends AuthenticatedServiceBase {
    protected override readonly endpoint = this.APIEndpoints.Casefiles;

    constructor(private fileHub: FileHubService) {
        super();
    }

    async getFinancialData(caseFileId: number | undefined): Promise<ODataResponse<CaseFile>> {
        if (!caseFileId) {
            this.handleError(new Error(ERRORS.FINANCIAL.CASE_FILE_MISSING.technical), {
                context: 'FinancialService.getFinancialData',
                userMessage: ERRORS.FINANCIAL.CASE_FILE_MISSING.message
            });
            throw new Error(ERRORS.FINANCIAL.CASE_FILE_MISSING.technical);
        }

        const query = new Query()
            .where('Id', 'equal', caseFileId)
            .expand('Invoices($expand=InvoiceRows,InvoicePayments,Provider),Deposits,ReductionRequests')
            .select('Invoices,Deposits,ReductionRequests');

        try {
            const response = await this.getOdata()
                .executeQuery(query) as unknown as ODataResponse<CaseFile>;

            if (!response?.result?.length) {
                throw new Error(ERRORS.FINANCIAL.DATA_INVALID.technical);
            }

            return response;
        } catch (error) {
            this.handleError(error, {
                context: 'FinancialService.getFinancialData',
                userMessage: 'Failed to load financial data',
                technicalDetails: {
                    caseFileId,
                    query: query.params,
                    endpoint: this.endpoint
                }
            });
            throw error;
        }
    }

    getInvoicesDataSource(endpoint: string) {
        return this.api.getOdata(endpoint);
    }

    getDepositsDataSource() {
        return this.api.getOdata(this.APIEndpoints.Deposits);
    }

    async checkBalanceStatementExists(caseFileId?: number): Promise<boolean> {
        if (!caseFileId) return false;

        const query = new Query()
            .where('CaseFileId', 'equal', caseFileId)
            .select('Id')
            .take(1);

        try {
            const response = await this.api.getOdata(this.APIEndpoints.BalanceStatements)
                .executeQuery(query) as unknown as ODataResponse<any>;

            return response?.result?.length > 0;
        } catch (error) {
            if (error instanceof HttpErrorResponse && error.status === 404) {
                return false;
            }
            this.handleError(error, {
                context: 'FinancialService.checkBalanceStatementExists',
                userMessage: 'Failed to check balance statement status',
                technicalDetails: {
                    caseFileId,
                    endpoint: this.APIEndpoints.BalanceStatements,
                    query: query.params
                }
            });
            throw error;
        }
    }

    async getInvoices(caseFileId: number): Promise<Invoice[]> {
        // Implementation needed
        throw new Error("Method not implemented");
    }

    async getDeposits(caseFileId: number): Promise<Deposit[]> {
        // Implementation needed
        throw new Error("Method not implemented");
    }
}
