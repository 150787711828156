// Angular
import { Component, signal, ViewChild, WritableSignal, OnInit, OnDestroy, Inject, Output, EventEmitter } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

// 3rd Party
import { Query } from '@syncfusion/ej2-data';
import { GridModel } from '@syncfusion/ej2-grids';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { CaseFile, SignedAuthorization } from '@models/data-contracts';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { ApiService } from '@services/api/api.service';
import { GlobalsService } from '@services/globals/globals.service';
import { GridTemplateComponent } from '@grids/grid-template/grid-template.component';
import { LoadingModule } from "@modules/loading.module";
import { TabCardComponent } from '@root/src/app/features/file-hub/components/file-hub-tabs/tab-card/tab-card.component';
import { FileHubService } from '@root/src/app/features/file-hub/services/file-hub.service';
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';
import { AddAuthorizationsComponent } from '@forms/add-forms/add-authorizations/add-authorizations.component';
import { CommonModule } from '@angular/common';
import { BaseGridComponent } from '@shared/components/base-grid/base-grid.component';
import { BaseGridService } from '@shared/components/base-grid/services/state.service';
import { SetGridDataArgs } from '@shared/components/base-grid/models/grid.models';
import { BaseGridHooks } from '@shared/components/base-grid/services/hooks.service';
import { ErrorSeverity } from '@core/error/error.types';
import { AuditLogService } from '@services/audit-logs/audit-log-service';
import { AuditLogsComponent } from '@ui/audit-logs/audit-logs.component'; 

// Error constants
const ERRORS = {
  INITIALIZE_DATA: {
    message: 'Failed to load authorizations',
    technical: 'Error initializing authorization data'
  },
  SET_ACTIVE_FILE: {
    message: 'Failed to load authorizations data',
    technical: 'Error setting active file data'
  },
  NO_CASE_FILE: {
    message: 'No case file selected',
    technical: 'Attempted operation without selected case file'
  },
  SUBMIT_AUTHORIZATION: {
    message: 'Failed to save authorization',
    technical: 'Error saving authorization data'
  },
  AUDIT: {
    DIALOG: {
      NOT_INITIALIZED: {
        message: 'Unable to show audit logs',
        technical: 'Audit dialog not initialized'
      },
      DISPLAY_ERROR: {
        message: 'Failed to display audit logs',
        technical: 'Error showing audit dialog'
      },
      CLOSE_ERROR: {
        message: 'Failed to close audit logs',
        technical: 'Error closing audit dialog'
      }
    },
    LOAD_ERROR: {
      message: 'Failed to load audit logs',
      technical: 'Error loading audit logs from service'
    }
  }
};

@Component({
  selector: 'authorizations-tab',
  standalone: true,
  imports: [
    TabCardComponent,
    GridTemplateComponent,
    LoadingModule,
    AddAuthorizationsComponent,
    DialogModule,
    CommonModule,
    BaseGridComponent,
    AuditLogsComponent
  ],
  providers: [
    BaseGridHooks,
    { provide: 'fileAuthGridService', useClass: BaseGridService },
    { provide: 'providerFundingGridService', useClass: BaseGridService }
  ],
  templateUrl: './authorizations-tab.component.html',
  styleUrl: './authorizations-tab.component.scss'
})
export class AuthorizationsTab implements OnInit, OnDestroy {

  /**
   * Main functionality can be found in the FileHubService.
   * @see {@link FileHubService} for more info
   */
  constructor(
    private api: ApiService,
    private toast: ToastMessageService,
    private globals: GlobalsService,
    public fileHub: FileHubService,
    @Inject('fileAuthGridService') public fileAuthGridService: BaseGridService,
    @Inject('providerFundingGridService') public providerFundingGridService: BaseGridService,
    private auditLogService: AuditLogService
  ) {}

  loadingAuthorizationsData: WritableSignal<boolean> = signal(true);
  authorizations: SignedAuthorization;
  fileAuthorizationsGridSettings: SetGridDataArgs;
  providerFundingGridSettings: SetGridDataArgs;
  totalAuthorizationAmount: WritableSignal<number> = signal(0);
  providerFundingTotalAmount: WritableSignal<number> = signal(0);

  selectedAuthorization: WritableSignal<SignedAuthorization | null> = signal(null);

  @ViewChild('addAuthorizations') addAuthorizations: DialogComponent;
  addAuthorizationsVisibility: boolean = false;
  dialogHeader: string = 'Add Authorization';

  @ViewChild('fileAuthGrid') fileAuthGrid: BaseGridComponent;
  @ViewChild('providerFundingGrid') providerFundingGrid: BaseGridComponent;

  // Audit logs functionality
  @ViewChild('logsDialog') logsDialog: any;
  auditLogs: any[] = [];
  auditLogsLoading = false;
  logsDialogVisibility: boolean = false;
  logsDialogButtons: Object[] = [
    { click: this.closeLogsDialog.bind(this), buttonModel: { content: 'Close', cssClass: 'e-flat' } }
  ];

  private destroy$ = new Subject<void>();

  @Output() authorizationAmountChanged = new EventEmitter<number>();

  ngOnInit() {
    // Subscribe to changes in the fileHub service
    this.fileHub.reload$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.initializeData();
    });
  }

  ngOnDestroy() {
    // Clean up both grid services
    this.fileAuthGridService.resetState();
    this.providerFundingGridService.resetState();

    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit() {
    this.initializeData();
  }

  async initializeData() {
    try {
      await this.setActiveFileData();
      if (this.fileHub.caseFile?.Id) {
        this.fileAuthorizationsGridSettings = {
          endpoint: APIEndpoints.SignedAuthorizations,
          useRoundedEdges: true,
          query: new Query()
            .where('CaseFileId', 'equal', this.fileHub.caseFile.Id)
            .where('AuthType', 'equal', "File Authorization Limit"),
          gridSettings: {
            toolbar: ['Add', 'ColumnChooser'],
            columns: [
              { field: 'Id', isPrimaryKey: true, visible: false },
              { field: 'DateSigned', headerText: 'Signed' },
              { field: 'Amount', format: 'C2', editType: 'numeric' },
              { field: 'AuthType', headerText: 'Type' },
              { field: 'Notes', headerText: 'Notes', disableHtmlEncode: false },
              { type: 'commands', headerText: 'Actions', commands: [
                { type: 'Edit', title: 'Edit', buttonOption: { iconCss: 'e-icons e-edit', cssClass: 'e-flat' }  },
                { type: 'None', title: 'Logs', buttonOption: { iconCss: 'e-icons e-description', cssClass: 'e-flat' } }
              ] }
            ],
            aggregates: [{
              columns: [{
                type: 'Sum',
                field: 'Amount',
                format: 'C2',
                footerTemplate: '<div class="total-footer"><strong>Total:</strong> ${Sum}</div>'
              }]
            }],
            toolbarClick: (args: any) => this.onFileAuthGridToolbarClick(args),
            commandClick: (args: any) => this.onCommandClick(args),
            dataBound: () => this.calculateAuthorizationTotal()
          }
        };

        // Create provider funding grid settings
        this.providerFundingGridSettings = {
          endpoint: APIEndpoints.SignedAuthorizations,
          useRoundedEdges: true,
          query: new Query()
            .where('CaseFileId', 'equal', this.fileHub.caseFile.Id)
            .where('AuthType', 'equal', "Provider Funding Authorization")
            .expand('Provider'),
          gridSettings: {
            toolbar: ['Add', 'ColumnChooser'],
            columns: [
              { field: 'Id', isPrimaryKey: true, visible: false },
              { field: 'DateSigned', headerText: 'Signed' },
              { field: 'Amount', format: 'C2', editType: 'numeric' },
              { field: 'AuthType', headerText: 'Type' },
              { field: 'Provider.Name', headerText: 'Provider', allowEditing: false },
              { field: 'Notes', headerText: 'Notes', disableHtmlEncode: false },
              { type: 'commands', headerText: 'Actions', commands: [
                { type: 'Edit', title: 'Edit', buttonOption: { iconCss: 'e-icons e-edit', cssClass: 'e-flat' } },
                { type: 'None', title: 'Logs', buttonOption: { iconCss: 'e-icons e-description', cssClass: 'e-flat' } }
              ] }
            ],
            aggregates: [{
              columns: [{
                type: 'Sum',
                field: 'Amount',
                format: 'C2',
                footerTemplate: '<div class="total-footer"><strong>Total:</strong> ${Sum}</div>'
              }]
            }],
            toolbarClick: (args: any) => this.onProviderFundingGridToolbarClick(args),
            commandClick: (args: any) => this.onCommandClick(args),
            dataBound: () => this.calculateProviderFundingTotal()
          }
        };

        this.setAuthorizationsGrid();
      }
    } catch (error) {
      console.error('Error initializing authorization data:', error);
      this.toast.showError(ERRORS.INITIALIZE_DATA.message);
    } finally {
      this.loadingAuthorizationsData.set(false);
    }
  }

  async setActiveFileData() {
    try {
      // Check for valid case file ID first
      if (!this.fileHub.caseFile?.Id) {
        return;
      }

      const authorizationsExist = this.globals.objHasKey(this.fileHub.caseFile, 'SignedAuthorizations');

      if (!authorizationsExist) {
        const endpoint = `${APIEndpoints.Casefiles}(${this.fileHub.caseFile.Id})`;
        const query = new Query().expand('SignedAuthorizations').select('SignedAuthorizations');

        return await this.api.getOdata(endpoint).executeQuery(query).then((res: any) => {
          const file = res.result[0] as CaseFile;
          this.authorizations = file.SignedAuthorizations as SignedAuthorization;
          this.fileHub.updateCaseFile('SignedAuthorizations', file.SignedAuthorizations);
        });
      }

      return this.fileHub.caseFile?.SignedAuthorizations;

    } catch (error) {
      console.error('Error setting active file data:', error);
      this.toast.showError(ERRORS.SET_ACTIVE_FILE.message);
      return error;
    } finally {
      this.loadingAuthorizationsData.set(false);
      return;
    }
  }

  setAuthorizationsGrid() {
    // Check for valid case file ID first
    if (!this.fileHub.caseFile?.Id) {
      console.error('No case file ID available');
      return;
    }

    // Update the query with the current case file ID for file authorizations grid
    const fileAuthSettings = { ...this.fileAuthorizationsGridSettings };
    fileAuthSettings.query = new Query()
      .where('CaseFileId', 'equal', this.fileHub.caseFile.Id)
      .where('AuthType', 'equal', "File Authorization Limit");

    // Update the query with the current case file ID for provider funding grid
    const providerFundingSettings = { ...this.providerFundingGridSettings };
    providerFundingSettings.query = new Query()
      .where('CaseFileId', 'equal', this.fileHub.caseFile.Id)
      .where('AuthType', 'equal', "Provider Funding Authorization")
      .expand('Provider');

    // Set the grid data using the separate grid services with separate settings objects
    this.fileAuthGridService.setGridData(fileAuthSettings);
    this.providerFundingGridService.setGridData(providerFundingSettings);

    // Calculate totals after grid is initialized
    this.calculateAuthorizationTotal();
    this.calculateProviderFundingTotal();
  }

  calculateAuthorizationTotal() {
    if (this.fileAuthGrid?.grid) {
      const total = this.calculateAmountTotal(this.fileAuthGrid.grid);
      this.totalAuthorizationAmount.set(total);
      this.authorizationAmountChanged.emit(total);
    } else {
      this.totalAuthorizationAmount.set(0);
      this.authorizationAmountChanged.emit(0);
    }
  }

  calculateProviderFundingTotal() {
    if (this.providerFundingGrid?.grid) {
      this.providerFundingTotalAmount.set(this.calculateAmountTotal(this.providerFundingGrid.grid));
    } else {
      this.providerFundingTotalAmount.set(0);
    }
  }

  private calculateAmountTotal(grid: any): number {
      const records = grid.getCurrentViewRecords() as SignedAuthorization[];
      let total = 0;

      for (const item of records) {
        if (item && item.Amount) {
          const amount = Number(item.Amount);
          if (!isNaN(amount)) {
            total += amount;
          }
        }
      }

      return total;
  }

  authType: string | null = null;

  onFileAuthGridToolbarClick(args: any) {
    if (args.item.text === 'Add') {
      args.cancel = true;
      if (!this.fileHub.caseFile?.Id) {
        this.toast.showError(ERRORS.NO_CASE_FILE.message);
        return;
      }

      this.dialogHeader = 'Add Authorization';
      this.selectedAuthorization.set(null);
      this.authType = "File Authorization Limit";
      this.addAuthorizationsVisibility = true;
      this.addAuthorizations.show();
    }
  }

  onProviderFundingGridToolbarClick(args: any) {
    if (args.item.text === 'Add') {
      args.cancel = true;
      if (!this.fileHub.caseFile?.Id) {
        this.toast.showError(ERRORS.NO_CASE_FILE.message);
        return;
      }

      this.dialogHeader = 'Add Provider Funding';
      this.selectedAuthorization.set(null);
      this.authType = "Provider Funding Authorization";
      this.addAuthorizationsVisibility = true;
      this.addAuthorizations.show();
    }
  }

  onCommandClick(args: any) {
    const rowData = args.rowData;
    args.cancel = true;

    if (args.commandColumn.title === 'Edit') {
      this.selectedAuthorization.set(rowData);
      this.dialogHeader = 'Edit Authorization';
      this.addAuthorizationsVisibility = true;
      this.addAuthorizations.show();
    }
    else if (args.commandColumn.title === 'Logs') {
      this.handleLogsCommand(rowData);
    }
  }

  private async handleLogsCommand(authorization: any) {
    this.auditLogsLoading = true;
    try {
      this.showLogsDialog();
      await this.loadAndFormatLogs(authorization);
    } finally {
      this.auditLogsLoading = false;
    }
  }

  showLogsDialog() {
    try {
      this.logsDialogVisibility = true;
      if (this.logsDialog) {
        this.logsDialog.show();
      } else {
        this.toast.showError(ERRORS.AUDIT.DIALOG.NOT_INITIALIZED.message);
        console.error(ERRORS.AUDIT.DIALOG.NOT_INITIALIZED.technical);
      }
    } catch (error) {
      this.toast.showError(ERRORS.AUDIT.DIALOG.DISPLAY_ERROR.message);
      console.error('Error showing logs dialog:', error);
    }
  }

  closeLogsDialog() {
    try {
      if (this.logsDialog) {
        this.logsDialog.hide();
        this.logsDialogVisibility = false;
        this.auditLogs = [];
      }
    } catch (error) {
      this.toast.showError(ERRORS.AUDIT.DIALOG.CLOSE_ERROR.message);
      console.error('Error closing logs dialog:', error);
    }
  }

  beforeOpeningLogsDialog(args: any) {
    args.maxHeight = '85vh';
  }

  private async loadAndFormatLogs(authorization: any) {
    try {
      const authLogs = await this.auditLogService.getAuditLogs(authorization.Id, 'SignedAuthorization');

      this.auditLogs = [
        {
          sectionHeader: 'Authorization Logs',
          logs: this.auditLogService.mapAuditDataToLogFormat(authLogs)
        }
      ].filter(section => section.logs.length > 0);

      if (this.auditLogs.length === 0) {
        this.auditLogs = [{
          sectionHeader: 'No Logs Available',
          logs: []
        }];
      }
    } catch (error) {
      this.closeLogsDialog();
      this.toast.showError(ERRORS.AUDIT.LOAD_ERROR.message);
      console.error('Error loading audit logs:', error);
    }
  }

  async onSubmit() {
    try {
      await this.fileHub.setPerformanceData();
      this.fileHub.updateCaseFile('SignedAuthorizations', this.authorizations);
      this.selectedAuthorization.set(null);

      // Reinitialize both grids with their settings
      this.setAuthorizationsGrid();

      // Refresh data for both grids using their respective services
      this.fileAuthGridService.refreshData();
      this.providerFundingGridService.refreshData();

      // Refresh both grid components
      if (this.fileAuthGrid?.grid) {
        this.fileAuthGrid.grid.refresh();
      }

      if (this.providerFundingGrid?.grid) {
        this.providerFundingGrid.grid.refresh();
      }

      // Recalculate total after grid refresh
      this.calculateAuthorizationTotal();
      this.calculateProviderFundingTotal();
    } catch (error) {
      console.error('Error on submit:', error);
      this.toast.showError(ERRORS.SUBMIT_AUTHORIZATION.message);
      return error;
    } finally {
      this.loadingAuthorizationsData.set(false);
      this.addAuthorizations.hide();
      return;
    }
  }

  onClose() {
    this.addAuthorizations.hide();
    this.selectedAuthorization.set(null);
  }
}
