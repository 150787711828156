// Angular
import { Component, computed, effect, EventEmitter, HostListener, Input, Output, Signal, WritableSignal, signal, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

// 3rd Party
import { ButtonModule, CheckBoxAllModule } from '@syncfusion/ej2-angular-buttons';
import { TextBoxAllModule, MaskedTextBoxAllModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownListAllModule, DropDownListComponent, FilteringEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { DatePickerAllModule, DatePickerComponent, FocusEventArgs } from '@syncfusion/ej2-angular-calendars';
import { TooltipAllModule } from '@syncfusion/ej2-angular-popups';
import { Query, Predicate } from '@syncfusion/ej2-data';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faUser, faVenusMars, faEnvelope, faCake, faBaby, faLanguage, faPhone, faComments, faMapLocationDot, faHospitalUser, faUserInjured, faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';

// Models
import { Patient, Address, Phone } from '@models/data-contracts';
import { APIEndpoints } from '@models/api/Endpoints';
import { genders } from '@models/global-vars';

// Services
import { FormCrudService } from '@services/forms/form-crud.service';
import { ApiService } from '@services/api/api.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';

// Components
import { AddressFormComponent } from '@forms/address-form/address-form.component';
import { EmitType } from '@syncfusion/ej2-base';

// Type & Interface setup
type PatientFormControls = {
  [K in keyof Patient]: FormControl<Patient[K] | null>;
};  

export type PatientFormResult = 'success' | 'warning' | 'error' | undefined;

@Component({
  selector: 'patient-form-component',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    TextBoxAllModule,
    MaskedTextBoxAllModule, 
    DropDownListAllModule,
    TooltipAllModule,
    DatePickerAllModule,
    CheckBoxAllModule,
    FontAwesomeModule,
    AddressFormComponent
  ],
  templateUrl: './patient-form.component.html',
  styleUrl: './patient-form.component.scss' 
})
export class PatientFormComponent {

  constructor(
    private formCRUD: FormCrudService,
    private api: ApiService,
    private toast: ToastMessageService
  ) {
    this.ensurePatientSignal();
    
    // Set up patient data effect
    effect(() => {
      const patient = this.patient();
      if (patient) {
        this.patientForm.patchValue(patient);
        this.patientChange.emit(patient);
        
        if (patient.Id) {
          this.getPatientAddress().then((address) => {
            if (address && this.addressFormComp) {
              this.addressFormComp.addressForm.patchValue(address as Address);
            }
          });
        }

      } else {
        this.resetForms();
      }
    });

    // Initialize all dropdown data
    this.initializeDropdownData();
  }

  // Decorator variabls
  @Input() selectedAccountId?: number;
  @Input() isLawFirm?: boolean;
  @Input() isEditPatient?: boolean;
  @Input() patient!: WritableSignal<Patient | undefined>;
  @Input() submitType?: 'POST' | 'PATCH' | 'DELETE';
  @Input() displayButtons: boolean = true;
  @Input() formMainTitle?: string;
  @Input() displayMainTitle = true;
  @Input() displayForm = this.isEditPatient ? false : true;
  @Input() displayFields: string[] = ['Firstname', 'Lastname', 'Email', 'Dob', 'Gender', 'Language', 'Minor'];
  @Input() selectedPatientId?: number;
  @Output() formSubmitted = new EventEmitter<void>();
  @Output() formCancelled = new EventEmitter<void>();
  @Output() addressResult = new EventEmitter<any>();
  @Output() patientChange = new EventEmitter<Patient>();
  @Output() patientSelected = new EventEmitter<any>();
  @ViewChild('patientsDropdown') patientsDropdown!: DropDownListComponent;
  @ViewChild('addressFormComp') addressFormComp!: AddressFormComponent;
  @ViewChild('dobComp') dobComp!: DatePickerComponent;

  // Form state
  protected readonly formState = computed(() => {
    return {
      isValid: this.patientForm.valid,
      hasChanges: this.patient() !== this.patientForm.value,
      currentValue: this.patient()
    };
  });  
  // Use computed for form validation messages
  protected readonly errorMessages = computed(() => {
    const errors: Record<string, string> = {};
    Object.keys(this.patientForm.controls).forEach(key => {
      const control = this.patientForm.get(key);
      if (control?.errors) errors[key] = this.getErrorMessage(key);
    });
    return errors;
  });
  currentPatient = computed(() => this.patient());

  // Public variables
  patientAddress: WritableSignal<Address | undefined> = signal(undefined);
  patientPhone = signal<Phone | undefined>(undefined);
  genders: string[] = genders;
  languages: any;
  loadingForm: boolean = true;
  errorMessage: string = '';
  patientHTMLElement: Element | null = null;
  patients: any;
  patientsQuery: Query = new Query().select('Id,Firstname,Lastname,Email,Dob,Gender,Language,Minor').expand('XrefAddressPatients($expand=Address($select=Id,AddressType,Address1,Address2,City,State,Zip))');
  showNewPatientForm = false;
  patientForm = new FormGroup<PatientFormControls>({
    Id: new FormControl<Patient['Id']>(undefined),
    Firstname: new FormControl<Patient['Firstname']>(undefined, [Validators.required]),
    Lastname: new FormControl<Patient['Lastname']>(undefined, [Validators.required]),
    Email: new FormControl<Patient['Email']>(undefined, [Validators.required, Validators.email]),
    Dob: new FormControl<Patient['Dob']>(undefined),
    Gender: new FormControl<Patient['Gender']>(undefined),
    Language: new FormControl<Patient['Language']>(undefined),
    Minor: new FormControl<Patient['Minor']>(undefined)
  });
  patientIcons = {
    form: faHospitalUser,
    patient: faUser,
    gender: faVenusMars,
    dob: faCake,
    language: faLanguage,
    minor: faBaby,
    phone: faPhone,
    email: faEnvelope,
    address: faMapLocationDot,
    notes: faComments,
    show: faEye,
    hide: faEyeSlash
  }
  formClasses = {
    formContainer: 'cc-form-container',
    form: 'cc-form flex-column',
    mainTitle: 'cc-main-title',
    subForm: 'cc-sub-form',
    section: 'cc-form-section',
    group: 'cc-form-group row',
    inputContainer: 'cc-input-container',
    inputContainerFullWidth: 'cc-input-container',
    label: 'cc-label',
    input: 'cc-input',
    icon: 'cc-input-icon',
    error: 'cc-input-error',
    actions: 'cc-form-actions',
    oneCol: 'col-12',
    twoCol: window.innerWidth <= 768 ? 'col-12' : 'col-6', 
    threeCol: window.innerWidth <= 768 ? 'col-12' : window.innerWidth > 768 && window.innerWidth <= 992 ? 'col-6' : 'col-4'
  };

  // Add loading signal
  loadingStatus: WritableSignal<{loading: boolean, message: string}> = signal({
    loading: false,
    message: ''
  });

  ngAfterViewInit() {
    this.patientHTMLElement = document.querySelector('patient-form');
    this.addressFormComp.displayFields = ['Address1', 'Address2', 'City', 'State', 'Zip', 'AddressType'];
    this.updateWidth(this.patientHTMLElement?.clientWidth || window.innerWidth);
    this.watchInputElements();
    this.initializeDropdownData();
  }

  // On form submit
  async onSubmit() {

    try {
      const submitType = this.submitType ? this.submitType : this.patientForm.get('Id')?.value ? 'PATCH' : 'POST';
      const endpoint = this.patientForm.get('Id')?.value ? `odata${APIEndpoints.Patients}(${this.patientForm.get('Id')?.value})` : `odata${APIEndpoints.Patients}`;

      this.loadingStatus.set({ loading: true, message: 'Validating form...' });

      let submitResult: any;
      let addressResponse: any;
      let xrefAddressResponse: any;

      // Exit if form is invalid
      if (this.patientForm.invalid || this.addressFormComp.addressForm.invalid) {
        this.toast.showError('Form Invalid');
        return new Error('Form Invalid');
      }

      // Handle address form
      const patientAddressId = this.addressFormComp.addressForm.get('Id')?.value;
      if (!this.addressFormComp.addressForm.pristine) {
        this.loadingStatus.set({ loading: true, message: 'Saving address information...' });
        addressResponse = await this.addressFormComp.onSubmit();
        
        if (addressResponse.error || !addressResponse) {
          this.toast.showError('Error submitting address');
          throw new Error('Error submitting address');
        }
      }

      // Submit main form
      this.loadingStatus.set({ loading: true, message: 'Saving patient information...' });
      submitResult = await this.formCRUD.submitForm(this.patientForm, endpoint, submitType);

      // Handle xref entries if needed
      if (!patientAddressId && addressResponse?.Id && submitResult?.Id) {
        this.loadingStatus.set({ loading: true, message: 'Linking address...' });
        xrefAddressResponse = await this.api.fetchRequest(
          `odata${APIEndpoints.XrefAddressPatients}`, 
          'POST', 
          { AddressId: addressResponse.Id, PatientId: submitResult.Id }
        );
      }

      this.formSubmitted.emit(submitResult);
      return submitResult;

    } catch (error) {
      console.error('Error submitting patient:', error);
      this.toast.showError('Error submitting patient');
      throw error;
    } finally {
      this.loadingStatus.set({ loading: false, message: '' });
    }
  }

  markAllFieldsAsTouched(): void {
    Object.values(this.patientForm.controls).forEach(control => {
      control.markAsTouched();
      control.markAsDirty();
      control.updateValueAndValidity();
    });
  }

  // Perform updates on input elements
  watchInputElements() {
    document.querySelectorAll(`.cc-input-container`).forEach((inputContainer) => {
      const container = inputContainer as HTMLElement;

      container.querySelectorAll('input, span, textarea').forEach((input) => {
        input.addEventListener('focus', () => inputContainer.classList.add('focus'));
        input.addEventListener('blur', () => inputContainer.classList.remove('focus'));
      });
    });
  }

  // Switches form to 2 columns when parent is larger 1600px
  updateWidth(containerWidth: number) {

    if (containerWidth > 768) {
      this.formClasses.form = this.formClasses.form.replace('flex-column', 'flex-row flex-wrap');
      this.formClasses.twoCol = this.formClasses.twoCol.replace('col-12', 'col-6')  ;
    } else {
      this.formClasses.form = this.formClasses.form.replace('flex-row', 'flex-column');
      this.formClasses.twoCol = this.formClasses.twoCol.replace('col-6', 'col-12');
    }
  }

  // Update the layout on window resize
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    const containerWidth = this.patientHTMLElement?.clientWidth || window.innerWidth;
    this.updateWidth(containerWidth);
  }

  // Returns appropraite error message for form control
  getErrorMessage(controlName: string): string {
    let message = '';
    const control = this.patientForm.get(controlName);
    if (control?.errors) {
      if (control.errors['required']) message = 'This field is required';
      if (control.errors['name']) message = 'First and last name are required';
      if (control.errors['email']) message = 'Invalid email format';
      if (control.errors['invalidPhone']) message = 'Invalid phone number (10 digits required)';
      if (control.errors['serverError']) message = control.errors['serverError'].message;
    };
    this.errorMessage = message;
    return message;
  }

  // Fetches patient address
  async getPatientAddress(): Promise<Address | undefined> {
    const patientId = this.patient()?.Id;
    if (!patientId) return undefined;

    const endpoint = `${APIEndpoints.Patients}(${patientId})`;
    const query = new Query()
      .expand('XrefAddressPatients($expand=Address($select=Id,AddressType,Address1,Address2,City,State,Zip))');
    
    try {
      const response = await this.api.getOdata(endpoint).executeQuery(query);
      const patient = (response as any).result[0];

      if (!patient?.XrefAddressPatients?.length) {
        return undefined;
      }

      const address = patient.XrefAddressPatients[0].Address;
      this.patientAddress.set(address);
      
      return address;

    } catch (error) {
      console.error('Error fetching patient address:', error);
      return undefined;
    }
  }

  // Add new async function to initialize all dropdown data
  async initializeDropdownData() {

    try {
      // Initialize data sources
      this.languages = this.api.getOdata(APIEndpoints.Languages);
      this.patients = this.api.getOdata(APIEndpoints.Patients);
      this.patientsQuery = new Query().select('Id,Firstname,Lastname,Email,Dob,Gender,Language,Minor').expand('XrefAddressPatients($expand=Address($select=Id,AddressType,Address1,Address2,City,State,Zip))');

      this.patients.executeQuery(this.patientsQuery).then((res: any) => {
        this.patients = [
          { 
            text: '+ Add New Patient',
            value: 0,
            patient: null
          },
          ...(res as any).result.map((patient: Patient) => ({
            text: `${patient.Firstname} ${patient.Lastname} DOB: ${patient.Dob}`,
            value: patient.Id,
            patient: patient
          }))
        ];

        // If we have a selected patient, find and select it
        this.selectedPatientId = this.patient()?.Id;
      });

    } catch (error) {
      console.error('Error initializing dropdowns:', error);
    }
  }

  // Update signals
  onPatientSelect(args: any) {
    if (args.itemData.value === 0 || args.itemData.value === null) {
      this.patient.set(undefined);
    this.addressFormComp.address.set(undefined);
      this.patientSelected.emit({ value: null, patient: null });
      this.displayForm = true;
    } else {
      this.patient.set(args.itemData.patient);

      if (args.itemData.patient.XrefAddressPatients && args.itemData.patient.XrefAddressPatients.length > 0) {
        this.addressFormComp.address.set(args.itemData.patient.XrefAddressPatients[0].Address);
      } else {
        this.addressFormComp.address.set(undefined);
      }

      this.patientSelected.emit({ 
        value: args.itemData.value, 
        patient: args.itemData.patient 
      });
    }
  }

  onDobChange(args: any) {
    args.value = new Date(args.value).toISOString().split('T')[0];
    this.patientForm.get('Dob')?.setValue(args.value);
  }

  onDobFocus(args: FocusEventArgs): void {
    this.dobComp.show();
  }

  // Add method to handle form resets
  private resetForms(): void {
    this.patientForm.reset({}, { emitEvent: false });
  }

  onDropdownFiltering: EmitType<FilteringEventArgs> = (filterEvent: FilteringEventArgs, data: any) => {
    let query = new Query();
    query = (filterEvent.text !== "") ? query.where("Text", "contains", filterEvent.text, true) : query;
    filterEvent.updateData(data, query);
  };

  // Add method to ensure we have a signal
  private ensurePatientSignal() {
    if (!this.patient) {
      this.patient = signal(undefined);
    }
  }
}
