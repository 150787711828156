// Angular
import { Component, OnInit, ViewEncapsulation, effect } from '@angular/core';
import { CommonModule } from '@angular/common';

// 3rd Party
import { Browser } from '@syncfusion/ej2-base';
import { DashboardLayoutModule } from '@syncfusion/ej2-angular-layouts';

// Services
import { ApiService } from '@services/api/api.service';
import { UserPreferencesService } from '@services/user/user-preferences.service';
import { DashboardQueriesService } from '@root/src/app/shared/services/dashboard-queries/dashboard-queries.service';
import { DashboardCardsService } from '@root/src/app/shared/services/dashboard-cards/dashboard-cards.service';

// Models & Constants
import { APIEndpoints } from '@models/api/Endpoints';
import { CustomCardModel } from '@models/components/card.model';
import { CardComponent } from '@ui/card/card.component';

// Helpers

// Interfaces to define the shape of task-related state
interface TaskCounts {
  overdue: number;
  upcoming: number;
  total: number;
}

interface DashboardState {
  taskCounts: TaskCounts;
  activeTaskFilter: 'all' | 'overdue' | 'upcoming';
}

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [DashboardLayoutModule, CardComponent, CommonModule],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class DashboardComponent implements OnInit {
  constructor(
    private api: ApiService,
    private user: UserPreferencesService,
    private queries: DashboardQueriesService,
    private cardService: DashboardCardsService,
  ) {
    this.today.setHours(0, 0, 0, 0);
    
    // Move initialization logic to constructor effect
    effect(() => {
      const userId = this.user.userId();
      if (userId) {
        this.cards = this.initializeCards();
        this.initializeTaskCounts().catch(console.error);
      }
    });
  }
  // Lifecycle hook - initializes dashboard
  async ngOnInit(): Promise<void> {
    await this.user.initializeUserId();
  }
  // Responsive layout configuration
  readonly cellSpacing: number[] = [20, 15];
  readonly columns: number = Browser.isDevice ? 1 : 3;
  readonly cellAspectRatio: number = Browser.isDevice ? 1 : 1.5;

  // Core state management
  private readonly today = new Date();

  private state: DashboardState = {
    taskCounts: { overdue: 0, upcoming: 0, total: 0 },
    activeTaskFilter: 'all',
  };

  cards: CustomCardModel[];

  activeTaskFilter: 'all' | 'overdue' | 'upcoming' = 'all';

  setActiveTaskFilter(filter: 'all' | 'overdue' | 'upcoming') {
    this.activeTaskFilter = filter;
  }

  // Fetches and initializes task counts from API
  private async initializeTaskCounts(): Promise<void> {
    try {
      const userId = this.user.userId();
      if (!userId) return;

      const tasksEndpoint = this.user.isAdmin()
        ? APIEndpoints.CaseFileTasks
        : APIEndpoints.XrefUsersCaseFileTask;

      const response: any = await this.api
        .getOdata(tasksEndpoint)
        .executeQuery(this.queries.getUserTasksQuery(userId));
      const { result } = response;

      this.updateTaskCounts(result);
      this.updateTasksToolbar();
    } catch (error) {
      console.error('Failed to initialize task counts:', error);
    }
  }

  // Updates task counts based on due dates
  private updateTaskCounts(tasks: any[]): void {
    const dateField = this.user.isAdmin()
      ? 'NextDateOfActivity'
      : 'CaseFileTask.NextDateOfActivity';

    this.state.taskCounts = {
      total: tasks.length,
      overdue: tasks.filter(
        (task) => new Date(this.getTaskDate(task, dateField)) < this.today,
      ).length,
      upcoming: tasks.filter(
        (task) => new Date(this.getTaskDate(task, dateField)) >= this.today,
      ).length,
    };
  }

  private getTaskDate(task: any, field: string): string {
    return field.includes('.')
      ? task.CaseFileTask.NextDateOfActivity
      : task.NextDateOfActivity;
  }

  // Updates the toolbar with current task counts
  private updateTasksToolbar(): void {
    const tasksCard = this.cards.find((card) => card.cardId === 'tasks');
    if (tasksCard?.gridSettings) {
      tasksCard.gridSettings.toolbar = [
        'All',
        `${this.state.taskCounts.overdue} Overdue`,
        `${this.state.taskCounts.upcoming} Upcoming`,
      ];
    }
  }

  // Handles toolbar filter clicks (All/Overdue/Upcoming)
  private handleTasksToolbarClick(args: any): void {
    const tasksCard = this.cards.find((card) => card.cardId === 'tasks');
    if (!tasksCard?.gridSettings) return;
    const userId = this.user.userId();
    if (!userId) return;

    const filterMap = {
      All: () => this.queries.getUserTasksQuery(userId),
      Overdue: () => this.queries.getTasksOverdueQuery(userId),
      Upcoming: () => this.queries.getTasksUpcomingQuery(userId),
    };

    // Extract the filter type (All, Overdue, or Upcoming) from the button text
    const buttonText = args.item.text;
    const filterType =
      buttonText === 'All'
        ? 'All'
        : buttonText.includes('Overdue')
          ? 'Overdue'
          : buttonText.includes('Upcoming')
            ? 'Upcoming'
            : null;

    const queryFunction = filterMap[filterType as keyof typeof filterMap];
    if (queryFunction) {
      tasksCard.gridSettings.query = queryFunction();
      this.state.activeTaskFilter = this.getFilterTypeFromText(buttonText);
    }
  }

  private getFilterTypeFromText(text: string): 'all' | 'overdue' | 'upcoming' {
    if (text.includes('Overdue')) return 'overdue';
    if (text.includes('Upcoming')) return 'upcoming';
    return 'all';
  }

  // Replace the card creation methods with service calls
  private initializeCards(): CustomCardModel[] {
    return [
      this.cardService.createAppointmentsCard(),
      this.cardService.createTasksCard((args) => this.handleTasksToolbarClick(args)),
      this.cardService.createCaseFilesCard(),
      this.cardService.createRoicOverviewCard(),
    ];
  }

  // Public method used by template to display counts
  getTaskCount(type: 'total' | 'overdue' | 'upcoming'): number {
    return this.state.taskCounts[type];
  }

}
