<div id="financial-info">

  <div class="financial-container">

    <div class="loading-container" *ngIf="loading()">
      <loading-overlay [loading]="loading" />
    </div>

    <div *ngIf="performanceData() && !isReductionPage">
      <div class="performance-data d-flex flex-row flex-wrap">
        <div class="item d-flex flex-nowrap"><label>Total Billed Cost:
          </label><span>${{performanceData()?.TotalBilledCost ?? '--'}}</span></div>
        <div class="item d-flex flex-nowrap"><label>Total Payments:
          </label><span>${{performanceData()?.TotalPaymentReceived ?? '--'}}</span></div>
        <div class="item d-flex flex-nowrap"><label>Settlement Cost:
          </label><span>${{performanceData()?.ActualSettlementCost ?? '--'}}</span></div>
        <div class="item d-flex flex-nowrap"><label>Balance Due:
          </label><span>${{performanceData()?.BalanceDue ?? 0}}</span></div>
        <div class="item d-flex flex-nowrap"><label>Authorized Amount:
          </label><span>${{performanceData()?.AuthorizedAmount ?? 0}}</span></div>
        <div class="item d-flex flex-nowrap"><label>Days Open:
          </label><span>{{performanceData()?.DaysOpen ?? '--'}} days</span></div>
        <div class="item d-flex flex-nowrap"><label>Settlement Value:
          </label><span>${{performanceData()?.FullSettlementValue ?? 0}}</span></div>
        <div class="item d-flex flex-nowrap"><label>Profit: </label><span>${{performanceData()?.Profit ?? 0}}</span>
        </div>
        <div class="item d-flex flex-nowrap"><label>Non-SOCAL Invoices:
          </label><span>{{performanceData()?.NonSOCALInvoices ? '$' + performanceData()?.NonSOCALInvoices :
            'Unknonwn'}}</span></div>
        <div class="item d-flex flex-nowrap"><label>ROIC: </label><span>{{performanceData()?.ROIC ?? 'N/A'}}</span>
        </div>
        <div class="item d-flex flex-nowrap">
          <label>XIRR: </label>
          <span>{{performanceData()?.XIRR ?? 'N/A'}}</span>
        </div>
        <div class="item d-flex flex-nowrap"><label>Final Balance Generated?:
          </label><span>{{performanceData()?.FinalBalanceDueGenerated ?? 'No'}}</span></div>
        <div class="item item-reduction d-flex flex-nowrap">
          <div class="row">
            <div class="col-6 d-flex">
              <label>Reduction: </label><span>${{performanceData()?.CourtesyReduction ?? 0}}</span>
            </div>
            <div class="col-6 d-flex flex-row flex-nowrap justify-content-around">
              <label>(or %): </label><span>{{performanceData()?.CourtesyReductionPercent ?? '--'}}%</span>
            </div>
          </div>
        </div>
        <div class="item d-flex flex-nowrap"><label>Final FSV:
          </label><span>${{performanceData()?.FinalFSV ?? 0}}</span></div>
      </div>
    </div>

    <div *ngIf="financialData() && isReductionPage">
      <div class="performance-data d-flex flex-row flex-wrap">
        <div class="item d-flex">
          <div class="col-6"><label>Total Billed Cost: </label>${{financialData()?.totalBilledCost ?? 0}}</div>
          <div class="col-6"><label>Total Payments Recieved: </label>${{financialData()?.totalPaymentsReceived ?? 0}}
          </div>
        </div>
        <div class="item d-flex">
          <div class="col-6"><label>Settlement Cost: </label>${{financialData()?.actualSettlementCost ?? 0}}</div>
          <div class="col-6"><label>Authorized Amount: </label>${{financialData()?.amountAuthorized ?? 0}}</div>
        </div>
        <div class="item d-flex">
          <div class="col-6"><label>Full Settlement Value: </label>${{financialData()?.fullSettlementValue ?? 0}}</div>
          <div class="col-6"><label>Final FSV: </label>${{financialData()?.finalFsv ?? 0}}</div>
        </div>
        <div class="item d-flex">
          <div class="col-6"><label>Total Due Provider: </label>${{balanceDue?.totalDueProvider ?? 0}}</div>
          <div class="col-6"><label>Amount Billed: </label>${{balanceDue?.amountBilled ?? 0}}</div>
        </div>
        <div class="item d-flex">
          <div class="col-6"><label>Days Open: </label>{{financialData()?.daysOpen ?? 0}} days</div>
          <div class="col-6"><label>Profit: </label>${{financialData()?.profit ?? 0}}</div>
        </div>
        <div class="item d-flex flex"><label>XIRR: </label>{{financialData()?.xirr ?? 'N/A'}}</div>
        <div class="col-12">
          <div *ngIf="isFormValid">
            <div [formGroup]="form">
              <ejs-numerictextbox formControlName="roic" [min]="0" format='n2' placeholder="ROIC" floatLabelType="Auto"
                [enabled]="false" />
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="isReductionPage && parentSelectedOption === 'totalCourtesyReduction' && isFormValid">
        <div class="form-row multi" [formGroup]="form">
          <div class="form-column">
            <ejs-numerictextbox formControlName="reductionAmount" (ngModelChange)="onReductionAmountChange()" [min]="0"
              format='c2' placeholder="Reduction Amount" floatLabelType="Auto" />
          </div>
          <br />
          OR
          <div class="form-column">
            <ejs-numerictextbox formControlName="reductionPct" (ngModelChange)="onReductionPercentChange()" [min]="0"
              format='p2' step='0.01' placeholder="Reduction %" floatLabelType="Auto" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
