import { Component, signal, OnInit, OnDestroy } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { TabCardComponent } from '../tab-card/tab-card.component';
import { GridTemplateComponent } from '@grids/grid-template/grid-template.component';
import { LoadingModule } from '@modules/loading.module';
import { FileHubService } from '@features/file-hub/services/file-hub.service';
import { FinancialPerformanceService } from '@features/financial/services/financial-performance.service';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'receivables-tab',
  standalone: true,
  imports: [
    CommonModule,
    TabCardComponent,
    GridTemplateComponent,
    LoadingModule,
    CurrencyPipe
  ],
  templateUrl: './receivables-tab.component.html',
  styleUrls: ['./receivables-tab.component.scss']
})
export class ReceivablesTabComponent implements OnInit, OnDestroy {
  loading = signal(false);
  performanceData = signal<any>(undefined);
  xirrValue: number | null = null;
  private readonly destroy$ = new Subject<void>();

  constructor(
    public fileHub: FileHubService,
    private financialPerformanceService: FinancialPerformanceService
  ) {}

  ngOnInit() {
    // Watch for case file changes
    this.fileHub.caseFile$
      .pipe(
        filter(file => !!file?.Id),
        takeUntil(this.destroy$)
      )
      .subscribe(async () => {
        await this.loadPerformanceData();
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private async loadPerformanceData() {
    if (!this.fileHub.caseFile?.Id) return;

    this.loading.set(true);
    try {
      const data = await this.financialPerformanceService.getDetailedPerformanceData(this.fileHub.caseFile.Id);
      this.performanceData.set(data);
      this.xirrValue = await this.financialPerformanceService.calculateXIRRFromCaseFile(this.fileHub.caseFile);
    } catch (error) {
      console.error('Error loading performance data:', error);
    } finally {
      this.loading.set(false);
    }
  }
}
