    <div id="dashboard">
    
    <ejs-dashboardlayout #dashboardLayout 
        [columns]="columns"
        [cellSpacing]="cellSpacing" 
        [cellAspectRatio]="cellAspectRatio" 
        [allowDragging]="false"
        [allowFloating]="true"
        mediaQuery="max-width: 1100px">

        <e-panels>
            <e-panel 
                *ngFor="let card of cards; let i = index"
                [sizeX]="card.sizeX || (i % 2 === 0 ? 2 : 1)" 
                [sizeY]="card.sizeY || 1" 
                [row]="card.row" 
                [col]="card.column">
                <div>
                    <ng-template #content>
                    <div *ngIf="card?.templateSettings">
                        <ng-container *ngTemplateOutlet="card.templateSettings?.template || null;  context: { $implicit: card }"></ng-container>
                    </div>
                    <div *ngIf="card?.gridSettings">
                        <card [cardSettings]="card">
                            <div *ngIf="card.isLoading" class="loading-spinner">
                                Loading...
                            </div>
                        </card>
                    </div>
                    </ng-template>
                </div>
            </e-panel>
        </e-panels>
    </ejs-dashboardlayout>

</div>

<ng-template #workItemsTemplate>
    <div>
      <div class="work-item-filters">
        <button (click)="setActiveWorkItemFilter('all')">All</button>
        <button (click)="setActiveWorkItemFilter('overdue')">Overdue ({{getWorkItemCount('overdue')}})</button>
        <button (click)="setActiveWorkItemFilter('upcoming')">Upcoming ({{getWorkItemCount('upcoming')}})</button>
      </div>
    </div>
  </ng-template>