import { Injectable } from '@angular/core';
import { Query } from '@syncfusion/ej2-data';
import { UserPreferencesService } from '../user/user-preferences.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardQueriesService {
  constructor(private userService: UserPreferencesService) {}

  private readonly DEFAULT_TAKE_LIMIT = 50;
  private readonly CASEFILE_TAKE_LIMIT = 30;

  getAdminTasksQuery(): Query {
    return new Query()
      .select([
        'Id',
        'TaskDatetime',
        'TaskName',
        'TaskDescription',
        'NextDateOfActivity',
        'UpdatedAt',
        'CompletedAt'
      ])
      .expand(['CaseFile($select=FileNumber)'])
      .where('CompletedAt', 'equal', null)
      .sortBy('UpdatedAt', 'desc')
      .take(this.DEFAULT_TAKE_LIMIT);
  }

  getUserTasksQuery(userId: number): Query {
    if (this.userService.isAdmin()) {
      return this.getAdminTasksQuery();
    }

    return new Query()
      .select(['CaseFileTask'])
      .expand([
        'CaseFileTask($select=Id,TaskDescription,TaskName)',
        'CaseFile($select=FileNumber)',
      ])
      .where('UserId', 'equal', userId)
      .where('CaseFileTask.CompletedAt', 'equal', null)
      .sortBy('CaseFileTask.UpdatedAt', 'desc')
      .take(this.DEFAULT_TAKE_LIMIT);
  }

  getAppointmentsQuery(userId: number): Query {
    const baseQuery = new Query()
      .select([
        'Id',
        'StartDatetime',
        'EndDatetime',
        'ProcedureCodeId',
        'Title',
        'Notes',
        'Block',
        'UpdatedAt'
      ])
      .expand([
        'CaseFile($select=FileNumber;$expand=Patient($select=Firstname, Lastname))',
        'Provider($select=Name)',
        'ProcedureCode($select=Description,ProcedureCodeName;$expand=XrefModalityCptCodes($select=Modality))',
      ])
      .where('StartDatetime', 'greaterThanOrEqual', new Date())
      .sortBy('StartDatetime', 'asc')
      .take(this.DEFAULT_TAKE_LIMIT);

    return this.userService.isAdmin() ? baseQuery : baseQuery.where('UserId', 'equal', userId);
  }

  getCaseFilesQuery(userId: number): Query {
    const baseQuery = new Query()
      .select([
        'Id',
        'FileNumber',
        'DateOfLoss',
        'InTreatment',
        'ResponsibleParty',
        'Statuser',
        'CreatedAt',
        'isSurgical',
        'ReferralSource',
        'LawFirmFileNumber'
      ])
      .expand([
        'Patient($select=Firstname, Lastname, DOB)',
        'LawFirm($select=Name)',
        'CaseFileTasks(select=NextDateOfActivity)',
        'CaseManagerNavigation($select=Name)',
      ])
      .sortBy('UpdatedAt', 'desc')
      .take(this.CASEFILE_TAKE_LIMIT);

    return this.userService.isAdmin() ? baseQuery : baseQuery.where('CaseManager', 'equal', userId);
  }

  getRoicOverviewQuery(): Query {
    return new Query()
      .select([
        'Id',
        'FileNumber',
        'Invoices',
        'Deposits'
      ])
      .expand([
        'Invoices($expand=InvoiceRows,InvoicePayments)',
        'Deposits',
        'Patient($select=Firstname,Lastname)'
      ])
      .where('Active', 'equal', true)
      .take(this.DEFAULT_TAKE_LIMIT);
  }

  // Task filter queries
  getTasksOverdueQuery(userId: number): Query {
    const baseQuery = this.userService.isAdmin() ? this.getAdminTasksQuery() : this.getUserTasksQuery(userId);
    const dateField = this.userService.isAdmin() ? 'NextDateOfActivity' : 'CaseFileTask.NextDateOfActivity';
    
    return baseQuery.where(dateField, 'lessThan', new Date());
  }

  getTasksUpcomingQuery(userId: number): Query {
    const baseQuery = this.userService.isAdmin() ? this.getAdminTasksQuery() : this.getUserTasksQuery(userId);
    const dateField = this.userService.isAdmin() ? 'NextDateOfActivity' : 'CaseFileTask.NextDateOfActivity';
    
    return baseQuery.where(dateField, 'greaterThanOrEqual', new Date());
  }
}