import { Injectable } from '@angular/core';
import { AuthenticatedServiceBase } from '@core/auth/auth.base';
import { Query, Predicate } from '@syncfusion/ej2-data';
import { CaseFile } from '@models/data-contracts';

interface ODataResponse<T> {
  result: T[];
  count?: number;
  '@odata.count'?: number;
}

/**
 * Service for managing case files
 * Handles fetching, creation, and update operations for case files
 */
@Injectable({ providedIn: 'root' })
export class CaseFileService extends AuthenticatedServiceBase {
  protected override readonly endpoint = this.APIEndpoints.Casefiles;

  constructor() {
    super();
  }

  /**
   * Fetches case files with pagination
   * @param pageSettings Pagination settings
   * @returns Case files with count
   */
  async getCaseFiles(pageSettings: { skip: number; take: number } = { skip: 0, take: 16 }) {
    const query = new Query()
      .expand([
        'Patient($expand=XrefAddressPatients($expand=Address($expand=StateNavigation($select=Id,Name))))',
        'LawFirm',
        'CaseManagerNavigation($select=Id,Name)',
        'XrefWorkItemCaseFiles',
      ])
      .sortBy('FileOpened desc')
      .skip(pageSettings.skip)
      .take(pageSettings.take)
      .requiresCount();

    const response = (await this.getOdata().executeQuery(query)) as unknown as ODataResponse<any>;

    if (!response || !response.result) throw new Error('Failed to fetch case files.');

    const caseFiles = this.mapCaseFilesResponse(response.result);
    
    const count = response.count || response['@odata.count'] || 0;

    return {
      result: caseFiles,
      count: count,
      aggregates: null,
      groupDs: null,
    };
  }

  /**
   * Processes case files to add computed properties
   * @param caseFiles Raw case file data from API
   * @returns Processed case files with computed properties
   */
  private mapCaseFilesResponse(caseFiles: any[]) {
    return caseFiles.map((caseFile: any) => {
      const address = caseFile.Patient?.XrefAddressPatients?.find((x: any) => x.Address);
      const fullName = `${caseFile.Patient?.Firstname || ''} ${caseFile.Patient?.Lastname || ''}`.trim();
      return {
        ...caseFile,
        Patient: {
          ...caseFile.Patient,
          FullName: fullName,
          XrefAddressPatients: address ? [address] : [],
        },
      };
    });
  }

  /**
   * Fetches case files with advanced filtering, sorting and pagination
   * @param state Grid state containing filters, sorting and pagination
   * @returns Case files with count
   */
  async fetchWithStateChange(state: any) {
    if (!state.action) return null;
    
    const pageSettings = {
      skip: state.skip || 0,
      take: state.take || 16,
    };

    // Base query with expansion
    const query = new Query()
      .expand([
        'Patient($expand=XrefAddressPatients($expand=Address($expand=StateNavigation($select=Id,Name))))',
        'LawFirm',
        'CaseManagerNavigation($select=Id,Name)',
        'XrefWorkItemCaseFiles',
      ])
      .skip(pageSettings.skip)
      .take(pageSettings.take)
      .requiresCount();

    // Apply filtering
    if (state.where) {
      let mainPredicate: Predicate | null = null;

      state.where.forEach((filter: any) => {
        filter.predicates?.forEach((pred: any) => {
          let currentPredicate: Predicate;

          if (pred.field === 'Patient.FullName') {
            // Special handling for FullName
            const firstnamePred = new Predicate('Patient/Firstname', 'contains', pred.value, pred.ignoreCase);
            const lastnamePred = new Predicate('Patient/Lastname', 'contains', pred.value, pred.ignoreCase);
            currentPredicate = firstnamePred.or(lastnamePred);
          } else {
            // Handle other fields
            const field = pred.field.replace(/\./g, '/');

            // Special handling for boolean fields
            if (pred.field === 'InTreatment') {
              // Convert Yes/No strings to boolean values
              let boolValue: boolean;
              if (typeof pred.value === 'string') {
                if (pred.value.toLowerCase() === 'yes') {
                  boolValue = true;
                } else if (pred.value.toLowerCase() === 'no') {
                  boolValue = false;
                } else {
                  boolValue = pred.value === 'true';
                }
              } else {
                boolValue = !!pred.value;
              }
              
              currentPredicate = new Predicate(
                field,
                'equal',
                boolValue,
                pred.ignoreCase
              );
            } else {
              currentPredicate = new Predicate(field, pred.operator, pred.value, pred.ignoreCase);
            }
          }

          // Combine predicates with AND
          mainPredicate = mainPredicate ? mainPredicate.and(currentPredicate) : currentPredicate;
        });
      });

      if (mainPredicate) {
        query.where(mainPredicate);
      }
    }

    // Clear any existing sort to prevent multiple $orderby parameters
    query.queries = query.queries.filter((q: any) => !q.fn || q.fn !== 'onSortBy');

    // Handle sorting
    if (state.sorted?.length) {
      state.sorted.forEach((sort: any) => {
        query.sortBy(sort.name, sort.direction.toLowerCase());
      });
    } else {
      query.sortBy('FileOpened desc');
    }

    // Always ensure orderby parameter
    if (!(query as any).params) {
      (query as any).params = {};
    }
    (query as any).params['$orderby'] = state.sorted?.length 
      ? state.sorted.map((s: any) => `${s.name} ${s.direction.toLowerCase() === 'ascending' ? 'asc' : 'desc'}`).join(',')
      : 'FileOpened desc';

    // Execute the query
    const response = (await this.getOdata().executeQuery(query)) as unknown as ODataResponse<any>;
    const count = response.count || response['@odata.count'] || 0;

    // Map the response
    const caseFiles = this.mapCaseFilesResponse(response.result);

    return {
      result: caseFiles,
      count: count,
    };
  }
} 