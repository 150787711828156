// Angular
import { Injectable, signal, WritableSignal } from "@angular/core";
import { FormGroup, ValidatorFn } from "@angular/forms";

/**
 * Type representing the basic form state structure
 */
export type FormState = {
  data: any | null;
  loading: boolean;
  error: string | null;
};

/**
 * Type defining validation configuration options
 */
export type ValidationConfig = {
  required?: boolean;
  minLength?: number;
  maxLength?: number;
};

/**
 * Type defining arguments for setting form data
 */
export type SetFormDataArgs = {
  id?: number;
  name?: string;
  initialData?: any;
  validation?: ValidationConfig;
};

/**
 * Interface defining the form configuration structure
 */
export interface FormConfig {
  fields: FormField[];
  validation?: ValidationConfig;
}

/**
 * Interface defining the structure of a form field
 */
export interface FormField {
  name: string;
  type: string;
  label?: string;
  validators?: ValidatorFn[];
  defaultValue?: any;
  options?: any;
}

/**
 * Interface defining the complete form state structure using signals
 */
export interface FormStates {
  Form: {
    SetupArgs: WritableSignal<SetFormDataArgs | undefined>;
    Component: WritableSignal<FormGroup | undefined>;
    Settings: WritableSignal<FormConfig | undefined>;
  };
  Loading: {
    Submit: WritableSignal<boolean>;
    Load: WritableSignal<boolean>;
  };
  Data: {
    InitialData: WritableSignal<any | undefined>;
    CurrentData: WritableSignal<any | undefined>;
    ValidationErrors: WritableSignal<Record<string, string>>;
  };
  UI: {
    FormId: WritableSignal<number | undefined>;
    FormName: WritableSignal<string | undefined>;
    IsSubmitting: WritableSignal<boolean>;
    Mode: WritableSignal<'create' | 'edit' | undefined>;
  };
  Errors: WritableSignal<Record<string, any>>;
}

/**
 * Base Form Service
 *
 * Provides centralized state management for the Base Form Component.
 * Handles form state, validation, error management, and state persistence using Angular signals.
 */
@Injectable({
  providedIn: 'root'
})
export class BaseFormService {
  readonly defaultValidation: ValidationConfig = {
    required: true,
    minLength: 0,
    maxLength: 100
  };

  /** Complete form state structure using signals */
  FormState: FormStates = {
    Form: {
      SetupArgs: signal<SetFormDataArgs | undefined>(undefined),
      Component: signal<FormGroup | undefined>(undefined),
      Settings: signal<FormConfig | undefined>(undefined),
    },
    Loading: {
      Submit: signal<boolean>(false),
      Load: signal<boolean>(false),
    },
    Data: {
      InitialData: signal<any | undefined>(undefined),
      CurrentData: signal<any | undefined>(undefined),
      ValidationErrors: signal<Record<string, string>>({}),
    },
    UI: {
      FormId: signal<number | undefined>(undefined),
      FormName: signal<string | undefined>(undefined),
      IsSubmitting: signal<boolean>(false),
      Mode: signal<'create' | 'edit' | undefined>(undefined),
    },
    Errors: signal<Record<string, any>>({}),
  };

  /**
   * Getters
   */
  get state() {
    return this.FormState;
  }
  get form() {
    return this.FormState.Form;
  }
  get loading() {
    return this.FormState.Loading;
  }
  get data() {
    return this.FormState.Data;
  }
  get ui() {
    return this.FormState.UI;
  }
  get errors() {
    return this.FormState.Errors;
  }

  /**
   * Sets form data and updates related state
   *
   * @param args - Arguments containing form data and configuration
   */
  setFormData(args: SetFormDataArgs): void {
    this.FormState.Form.SetupArgs.set(args);
    this.FormState.Data.InitialData.set(args.initialData);
    this.FormState.UI.Mode.set(args.id ? 'edit' : 'create');
  }

  /**
   * Sets an error in the form state
   *
   * @param key - Key identifying the error
   * @param error - Error object or message
   */
  setError(key: string, error: any): void {
    this.FormState.Errors.update(errors => ({
      ...errors,
      [key]: error
    }));
  }

  /**
   * Clears all form errors
   */
  clearErrors(): void {
    this.FormState.Errors.set({});
  }

  /**
   * Resets the form state to initial values
   */
  resetState(): void {
    this.FormState.Form.SetupArgs.set(undefined);
    this.FormState.Data.InitialData.set(undefined);
    this.FormState.Data.CurrentData.set(undefined);
    this.FormState.Data.ValidationErrors.set({});
    this.FormState.UI.Mode.set(undefined);
    this.FormState.Errors.set({});
  }
}