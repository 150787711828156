import { Injectable, signal, WritableSignal } from '@angular/core';
import { ApiService } from '@services/api/api.service';
import { DashboardQueriesService } from '@services/dashboard-queries/dashboard-queries.service';
import { UserPreferencesService } from '@services/user/user-preferences.service';
import { WorkItemsService } from '@features/work-items/services/work-items.service';
import { APIEndpoints } from '@models/api/Endpoints';
import { CustomCardModel } from '@models/components/card.model';
import { ClickEventArgs } from '@syncfusion/ej2-buttons';
import { Deposit, Invoice, InvoiceRow } from '@models/data-contracts';
import { ColumnModel } from '@syncfusion/ej2-angular-grids';
import { Router } from '@angular/router';
import { ReturnOption } from '@syncfusion/ej2-data';

@Injectable({
  providedIn: 'root'
})
export class DashboardCardsService {
  constructor(
    private api: ApiService,
    private queries: DashboardQueriesService,
    private userPrefs: UserPreferencesService,
    private workItems: WorkItemsService,
    private router: Router
  ) { }

  private getUserId(): number {
    return this.userPrefs.userId() ?? 0;
  }

  isCalendarView: WritableSignal<boolean> = signal(false);

  async createAppointmentsCard(): Promise<CustomCardModel> {
    const dataManager = this.api.getOdata(APIEndpoints.Appointments);
    const query = this.queries.getAppointmentsQuery(this.getUserId());
    const response = await dataManager.executeQuery(query) as ReturnOption;
    
    // Process the data to add computed fields directly
    const processedData = (response.result as any[]).map(item => ({
      ...item,
      FullName: this.concatName(item.CaseFile)
    }));
  
    return {
      cardTitle: 'Upcoming Appointments',
      cardId: 'scheduler',
      row: 0,
      column: 2,
      sizeX: 1,
      gridSettings: {
        dataSource: processedData,
        allowFiltering: false,
        allowPaging: false,
        columns: this.getAppointmentsColumns(),
      }
    };
  }

  createWorkItemsCard(handleToolbarClick: (args: ClickEventArgs) => void): CustomCardModel {
    return {
      cardTitle: 'My Tasks',
      cardId: 'work-items',
      row: 0,
      column: 0,
      sizeX: 2,
      sizeY: 2,
      showCalendarView: this.isCalendarView,
      cardSettingsMenu: [
        {
          iconCss: 'e-icons e-settings',
          items: [
            {
              text: 'Last Updated', items: [
                { text: '1 day', id: 'day-1' },
                { text: '1 week', id: 'day-7' },
                { text: '1 month', id: 'month-1' },
                { text: '3 months', id: 'month-3' },
                { text: '6 months', id: 'month-6' },
                { text: '1 year', id: 'year-1' }
              ]
            },
            { text: 'Select Columns', id: 'select-columns' }
          ]
        }
      ],
      isLoading: true,
      gridSettings: {
        dataSource: [],
        allowFiltering: false,
        allowPaging: true,
        pageSettings: {
          pageSize: 15
        },
        sortSettings: {
          columns: [{ field: 'DueDate', direction: 'Ascending' }]
        },
        toolbar: ['0 Upcoming', '0 Overdue', 'All'],
        toolbarClick: handleToolbarClick,
        columns: this.getWorkItemColumns(),
        commandClick: (args: any) => {
          const data = args.rowData;
          if (data?.XrefWorkItemCaseFiles?.length > 0) {
            const caseFileId = data.XrefWorkItemCaseFiles[0].CaseFileId;
            this.router.navigate(['/case-files/hub'], { 
              queryParams: { fileNumber: data.XrefWorkItemCaseFiles[0].CaseFile?.FileNumber }
            });
          }
        }
      },
      scheduleSettings: {
        currentView: 'Month',
        selectedDate: new Date(),
        views: ['Day', 'Week', 'Month'],
        showHeaderBar: false,
        height: '500px',
        eventSettings: {
          dataSource: [],
          fields: {
            subject: { name: 'Title' },
            startTime: { name: 'DueDate' },
            endTime: { name: 'DueDate' }
          }
        }
      }
    };
  }

  async createCaseFilesCard(): Promise<CustomCardModel> {
    // Fetch and process the data first
    const dataManager = this.api.getOdata(APIEndpoints.Casefiles);
    const query = this.queries.getCaseFilesQuery(this.getUserId());
    const response = await dataManager.executeQuery(query) as ReturnOption;
    
    // Process the data to add computed fields directly
    const processedData = (response.result as any[]).map(item => ({
      ...item,
      FullName: this.concatName(item),
      XrefWorkItemCaseFile: this.getNextWorkItem(item)
    }));
  
    return {
      cardTitle: 'Case Files',
      cardId: 'case-files',
      row: 0,
      column: 2,
      sizeX: 1,
      gridSettings: {
        dataSource: processedData,
        allowFiltering: false,
        allowPaging: false,
        columns: [
          { field: 'Id', isPrimaryKey: true, visible: false },
          { field: 'FileNumber', headerText: 'File Number', visible: true },
          { field: 'FullName', headerText: 'Client Name', visible: true },
          { field: 'Patient.Dob', headerText: 'DOB', visible: true },
          { field: 'LawFirm.Name', headerText: 'Law Firm', visible: true },
          { field: 'RecordStatus.Description', headerText: 'Status', visible: false },
          { field: 'XrefWorkItemCaseFile', headerText: 'Next Work Item', visible: true },
          { field: 'DateOfLoss', headerText: 'Date of Loss', visible: false }
        ]
      }
    };
  }

  createRoicOverviewCard(): CustomCardModel {
    return {
      cardTitle: 'ROIC Overview',
      cardId: 'roic-overview',
      row: 1,
      column: 2,
      gridSettings: {
        dataSource: this.api.getOdata(APIEndpoints.Casefiles),
        query: this.queries.getRoicOverviewQuery(),
        allowFiltering: false,
        allowPaging: false,
        columns: [
          { field: 'Id', isPrimaryKey: true, visible: false },
          { field: 'FileNumber', headerText: 'File', visible: true },
          {
            field: 'FullName',
            headerText: 'Patient',
            visible: true,
            valueAccessor: (field: string, data: any) => this.concatName(data)
          },
          {
            field: 'ROIC',
            headerText: 'ROIC',
            visible: true,
            valueAccessor: (field: string, data: any) => this.calculateRoic(data).formattedRoic
          }
        ],
        sortSettings: {
          // columns: [{ field: 'ROIC', direction: 'Descending' }]
        }
      }
    };
  }

  private getAppointmentsColumns() {
    return this.isCalendarView()
      ?
      [
        { field: 'Id', isPrimaryKey: true, visible: false },
        { field: 'CaseFile.FileNumber', headerText: 'File Number', visible: true },
        {
          field: 'FullName',
          headerText: 'Client Name',
          visible: true,
          valueAccessor: (field: string, data: any) => this.concatName(data.CaseFile)
        },
        { field: 'StartDatetime', headerText: 'Start', visible: true },
        { field: 'Provider.Name', headerText: 'Provider', visible: true }
      ]
      :
      [
        { field: 'Id', isPrimaryKey: true, visible: false },
        { field: 'CaseFile.FileNumber', headerText: 'File Number', visible: true },
        {
          field: 'FullName',
          headerText: 'Client Name',
          visible: true,
          valueAccessor: (field: string, data: any) => this.concatName(data.CaseFile)
        },
        { field: 'ProcedureCode.Modality', headerText: 'Modality', visible: false },
        { field: 'ProcedureCode.Description', headerText: 'Description', visible: true },
        { field: 'StartDatetime', headerText: 'Start', visible: true },
        { field: 'Provider.Name', headerText: 'Provider', visible: true }
      ]
  }

  private getWorkItemColumns(): ColumnModel[] {
    return [
      { field: 'Id', isPrimaryKey: true, visible: false },
      { field: 'Category.Name', headerText: 'Category', visible: true, width: 80 },
      { field: 'Type.Name', headerText: 'Type', visible: true, width: 120 },
      { field: 'Title', headerText: 'Title', visible: false },
      { field: 'Description', headerText: 'Notes', visible: true },
      { field: 'DueDate', headerText: 'Due Date', visible: true, format: 'yMd', width: 100 },
      { field: 'Status.Name', headerText: 'Status', visible: true, width: 60 },
      { field: 'Priority.Name', headerText: 'Priority', visible: false },
      { 
        field: 'XrefWorkItemCaseFiles', 
        headerText: 'Case File', 
        visible: true, 
        width: 80,
        valueAccessor: (field: string, data: any) => {
          if (data.XrefWorkItemCaseFiles?.length > 0) {
            return data.XrefWorkItemCaseFiles[0].CaseFile?.FileNumber;
          }
          return '';
        }
      },
      {
        headerText: 'Actions',
        width: 70,
        textAlign: 'Center',
        type: 'commands',
        commands: [
          { 
            type: 'None',
            buttonOption: { 
              content: 'Go To Task',
              cssClass: 'e-flat'
            }
          }
        ]
      }
    ] as ColumnModel[];
  }

  private getNextWorkItem(data: any): string {
    if (!data.XrefWorkItemCaseFile || !Array.isArray(data.XrefWorkItemCaseFile)) {
      return '';
    }

    const workItems = data.XrefWorkItemCaseFile
      .map((xref: any) => xref.WorkItem)
      .filter((item: any) => item.Status !== 'Completed')
      .sort((a: any, b: any) => new Date(a.DueDate).getTime() - new Date(b.DueDate).getTime());

    if (!workItems.length) {
      return '';
    }

    const nextItem = workItems[0];
    const dueDate = new Date(nextItem.DueDate);

    return `${nextItem.Title} (${dueDate.toLocaleDateString("en-GB").replace(/\//g, '-')})`;
  }

  private capitalize(str: string): string {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  private concatName(data: any): string {
    const firstName = this.capitalize(data?.Patient?.Firstname || '');
    const lastName = this.capitalize(data?.Patient?.Lastname || '');
    return `${firstName} ${lastName}`.trim();
  }

  private calculateRoic(data: any): { roic: number, formattedRoic: string } {
    const invoices = data.Invoices || [];
    const deposits = data.Deposits || [];

    const totalCost = invoices.reduce((accumulator: number, invoice: Invoice) => {
      if (invoice.InvoiceRows) {
        invoice.InvoiceRows.forEach((row: InvoiceRow) => {
          if (row.AmountBilled) {
            accumulator += row.AmountBilled;
          }
        });
      }
      return accumulator;
    }, 0);

    const totalRevenue = deposits.reduce((accumulator: number, deposit: Deposit) => {
      if (deposit.DepositAmount) {
        accumulator += deposit.DepositAmount;
      }
      return accumulator;
    }, 0);

    const roic = totalCost > 0 ? ((totalRevenue - totalCost) / totalCost) * 100 : 0;
    const formattedRoic = new Intl.NumberFormat('en-US', {
      style: 'percent',
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    }).format(roic / 100);

    return { roic, formattedRoic };
  }
}
