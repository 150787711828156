// Angular
import { Component, EventEmitter, Input, Output, signal, ViewChild, WritableSignal } from '@angular/core';
import { ReactiveFormsModule, FormControl, FormGroup, FormsModule, FormBuilder } from '@angular/forms';

// 3rd Party
import { Query } from '@syncfusion/ej2-data';
import { ButtonModule, SwitchModule } from '@syncfusion/ej2-angular-buttons';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { TextBoxModule, NumericTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { DatePickerAllModule } from '@syncfusion/ej2-angular-calendars';
import { Dialog, DialogModule } from '@syncfusion/ej2-angular-popups';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { Deposit } from '@models/data-contracts';
import { ApiService } from '@services/api/api.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { consumerPollProducersForChange } from '@angular/core/primitives/signals';

@Component({
  selector: 'add-deposit-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    ButtonModule,
    SwitchModule,
    DropDownListModule,
    TextBoxModule,
    NumericTextBoxModule,
    DatePickerAllModule,
    DialogModule
  ],
  templateUrl: './add-deposit.component.html',
  styleUrl: './add-deposit.component.scss',
})
export class AddDepositForm {

  constructor(
    private api: ApiService, 
    private toast: ToastMessageService,
    private fb: FormBuilder
  ) { }

  @Input() caseFileId: Number;
  @Input() deposit: Deposit | null = null;
  @Output() submit = new EventEmitter<any>();
  @Output() close = new EventEmitter<void>();
  visibility = false;
  listPandL: any[] = [];
  pAndLQuery = new Query();
  pAndLFields = {
    text: 'Description',
    value: 'Id'
  };
  createdBy = JSON.parse(localStorage.getItem('userId') || "0");
  depositForm: WritableSignal<FormGroup>;
  endpoint = APIEndpoints.Deposits;
  canLockDepositValue: boolean = false;

  ngOnInit() {
    const isLocked = this.deposit?.LockDeposit ?? false;
    const canEditLockDeposit = this.checkLockDepositPermission();

    this.depositForm = signal(this.fb.group({
      DepositDate: [{value: '', disabled: isLocked}],
      DepositAmount: [{value: 0, disabled: isLocked}],
      DepositToAccount: [{value: 0, disabled: isLocked}],
      Notes: [''], // Notes always enabled
      FinalCheck: [{value: false, disabled: isLocked}],
      LockDeposit: [{value: false, disabled: !canEditLockDeposit}]
    }));

    // If we have a deposit, populate the form
    if (this.deposit) {
      this.depositForm().patchValue({
        DepositDate: this.deposit.DepositDate,
        DepositAmount: this.deposit.DepositAmount,
        DepositToAccount: this.deposit.DepositToAccount,
        Notes: this.deposit.Notes,
        FinalCheck: this.deposit.FinalCheck,
        LockDeposit: this.deposit.LockDeposit
      });

      // If deposit becomes locked, disable fields except Notes
      this.depositForm().get('LockDeposit')?.valueChanges.subscribe(locked => {
        if (locked) {
          this.depositForm().get('DepositDate')?.disable();
          this.depositForm().get('DepositAmount')?.disable();
          this.depositForm().get('DepositToAccount')?.disable();
          this.depositForm().get('FinalCheck')?.disable();
        } else {
          this.depositForm().get('DepositDate')?.enable();
          this.depositForm().get('DepositAmount')?.enable();
          this.depositForm().get('DepositToAccount')?.enable();
          this.depositForm().get('FinalCheck')?.enable();
        }
      });
    }

    // Fetch and transform the PandL data
    this.api.getOdata(APIEndpoints.PandLs).executeQuery(this.pAndLQuery).then((response: any) => {
      this.listPandL = response.result;
    });

    // Listen for close event to reset form
    this.close.subscribe(() => {
      this.resetForm();
    });
  }

  private checkLockDepositPermission(): boolean {
    const userRights = JSON.parse(localStorage.getItem('userRights') || '[]');
    const isAdmin = localStorage.getItem('isAdmin');
    const hasPermission = userRights.includes('lockDeposit') || isAdmin;
  
    return hasPermission;
  }

  onSubmit() {
    const formData: any = { 
      CaseFileId: this.caseFileId,
      DepositDate: this.depositForm().get('DepositDate')?.value,
      DepositAmount: this.depositForm().get('DepositAmount')?.value,
      DepositToAccount: this.depositForm().get('DepositToAccount')?.value,
      Notes: this.depositForm().get('Notes')?.value,
      FinalCheck: this.depositForm().get('FinalCheck')?.value,
      LockDeposit: this.depositForm().get('LockDeposit')?.value
    };
    
    if (this.deposit?.Id) {
      // Update existing deposit
      this.api.basicPatch(
        `${APIEndpoints.Deposits}(${this.deposit.Id})`, 
        formData
      ).then((response) => {
        if (response?.status === 400) {
          this.toast.showError("There was a problem updating the deposit");
        } else {
          this.toast.showSuccess("Deposit updated successfully");
          this.submit.emit();
        }
      }).catch((error) => {
        this.toast.showError("Error updating deposit");
        console.error('Error updating deposit:', error);
      });
    } else {
      // Create new deposit
      this.api.basicPost(
        APIEndpoints.Deposits, 
        formData
      ).then((response) => {
        if (response?.status === 400) {
          this.toast.showError("There was a problem submitting the form");
        } else {
          this.toast.showSuccess("Deposit created successfully");
          this.submit.emit();
        }
      }).catch((error) => {
        this.toast.showError("Error creating deposit");
        console.error('Error creating deposit:', error);
      });
    }
  }

  resetForm() {
    this.depositForm().reset({
      DepositDate: '',
      DepositAmount: 0,
      DepositToAccount: 0,
      Notes: '',
      FinalCheck: false,
      LockDeposit: false
    });
  }
}
