// Angular
import { Component, computed, effect, EventEmitter, HostListener, Input, Output, Signal, signal, ViewChild, ViewChildren, QueryList, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

// 3rd Party
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { TextBoxAllModule, MaskedTextBoxAllModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownListAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { DialogComponent, TooltipAllModule } from '@syncfusion/ej2-angular-popups';
import { Query, Predicate } from '@syncfusion/ej2-data';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faUser, faIdCard, faEnvelope, faUserTag, faPhone, faComments, faMapLocationDot, faUsersRectangle, faCircle, faCircleMinus, faCirclePlus } from '@fortawesome/free-solid-svg-icons';

// Models
import { Address, Contact, Phone, XrefPhoneContact } from '@models/data-contracts';
import { APIEndpoints } from '@models/api/Endpoints';
import { ContactTypes } from './contact-types';

// Services
import { FormCrudService } from '@services/forms/form-crud.service';
import { ApiService } from '@services/api/api.service';
import { ToastMessageService } from '@root/src/app/shared/services/toast-message/toast-message.service';

// Components
import { AddressFormComponent } from '../address-form/address-form.component';
import { PhoneFormComponent } from '../phone-number-form/phone-number-form.component';
import { SelectContactComponent } from './select-contact.component';
import { LawFirmService } from '../../features/law-firm/data/law-firm.service';
import { lawFirmQuery } from '@root/src/app/shared/queries/law-firm-queries';
import { providerQuery } from '@root/src/app/shared/queries/provider-queries';
import { ComponentBase } from '@root/src/app/core/base/component.base';

// Type & Interface setup
type ContactFormControls = {
  [K in keyof Contact]: FormControl<Contact[K] | null>;
};

// Add error constants at the top
const ERRORS = {
  CONTACT_FORM: {
    ADDRESS_FETCH_FAILED: {
      message: 'Unable to load address',
      technical: 'Failed to fetch address from API'
    },
    PHONE_FETCH_FAILED: {
      message: 'Unable to load phone information',
      technical: 'Failed to fetch phone details'
    },
    FORM_SUBMIT_FAILED: {
      message: 'Unable to save',
      technical: 'Form submission failed'
    },
    FORM_VALIDATION_FAILED: {
      message: 'Please check form for errors',
      technical: 'Form validation failed'
    },
    LAYOUT_UPDATE_FAILED: {
      message: 'Unable to update form layout',
      technical: 'Failed to update form layout on resize'
    },
    INIT_FAILED: {
      message: 'Unable to initialize form',
      technical: 'Failed to initialize form data'
    },
    FORM_PRISTINE: {
      message: 'No changes',
      technical: 'Form detected no changes to save'
    }
  }
} as const;

@Component({
  selector: 'contact-form-component',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    TextBoxAllModule,
    MaskedTextBoxAllModule, 
    DropDownListAllModule,
    TooltipAllModule,
    FontAwesomeModule,
    AddressFormComponent,
    PhoneFormComponent,
    SelectContactComponent,
  ],
  templateUrl: './contact-form.component.html',
  styleUrl: './contact-form.component.scss'
})
export class ContactFormComponent extends ComponentBase {

  // Decorator variabls
  @Input() isLawFirm?: boolean;
  @Input() selectedAccountId?: number;
  @Input() contact!: Signal<Contact | undefined>;
  @Input() submitType?: 'POST' | 'PATCH' | 'DELETE';
  @Input() displayFields: string[] = ['ContactName', 'ContactType', 'ContactTitle', 'ContactEmail', 'Notes', 'XrefAddressContacts', 'XrefPhoneContacts'];
  @Input() displayButtons: boolean = true;
  @Input() displayTitle: boolean = true;
  @Input() formMainTitle?: string;
  @Output() formSubmitted = new EventEmitter<void>();
  @Output() formCancelled = new EventEmitter<void>();
  @ViewChild('addressFormComp') addressFormComp!: AddressFormComponent;
  @ViewChildren(PhoneFormComponent) phoneForms!: QueryList<PhoneFormComponent>;
  @ViewChild('faxFormComp') faxFormComp!: PhoneFormComponent;

  // States
  protected readonly formState = computed(() => {
    const currentContact = this.contact ? this.contact() : this.contactForm.value;
    return {
      isValid: this.contactForm.valid,
      hasChanges: currentContact !== this.contactForm.value,
      currentValue: currentContact
    };
  }); 
  protected readonly errorMessages = computed(() => {
    const errors: Record<string, string> = {};
    Object.keys(this.contactForm.controls).forEach(key => {
      const control = this.contactForm.get(key);
      if (control?.errors) errors[key] = this.getErrorMessage(key);
    });
    return errors;
  });

  // Public variables
  contactAddress: WritableSignal<Address | undefined> = signal(undefined);
  contactPhones: WritableSignal<Phone[] | undefined> = signal(undefined);
  contactFax: WritableSignal<Phone | undefined> = signal(undefined);
  selectedContact: Signal<Contact | undefined>;
  loadingForm: boolean = true;
  errorMessage: string = '';
  contactHTMLElement: Element | null = null;
  contactTypes: any = ContactTypes;
  contactTypesQuery: Query;
  states: any;
  statesQuery: Query;
  contactForm = new FormGroup<ContactFormControls>({
    Id: new FormControl<Contact['Id']>(undefined),
    ContactType: new FormControl<Contact['ContactType']>(0, Validators.required),
    ContactName: new FormControl<Contact['ContactName']>(undefined, Validators.required),
    ContactTitle: new FormControl<Contact['ContactTitle']>(undefined),
    ContactEmail: new FormControl<Contact['ContactEmail']>(undefined, Validators.required),
    Notes: new FormControl<Contact['Notes']>(undefined)
  });
  contactIcons = {
    form: faUsersRectangle,
    contactName: faUser,
    contactType: faUserTag,
    contactTitle: faIdCard,
    contactEmail: faEnvelope,
    contactPhone: faPhone,
    contactFax: faPhone,
    contactNotes: faComments,
    contactAddress: faMapLocationDot,
    contactList: faUsersRectangle,
    add: faCirclePlus,
    remove: faCircleMinus
  }
  formClasses = {
    formContainer: 'cc-form-container',
    form: 'cc-form flex-column',
    subForm: 'cc-sub-form',
    mainTitle: 'cc-main-title',
    subFormTitle: 'cc-sub-form-title',
    section: 'cc-form-section',
    group: 'cc-form-group row',
    inputContainer: 'cc-input-container',
    inputContainerFullWidth: 'cc-input-container col-12',
    label: 'cc-label',
    input: 'cc-input',
    icon: 'cc-input-icon',
    error: 'cc-input-error',
    actions: 'cc-form-actions',
    twoCol: 'col-12',
    threeCol: 'col-12',
    addButton: 'cc-add-button',
    removeButton: 'cc-remove-button',
    subFormItem: 'cc-sub-form-item',
    listItem: 'cc-list-item'
  };

  protected contactList: WritableSignal<Contact[]> = signal([]);
  protected showForm = true;
  protected isNewContact = false;

  // Add loading status signal
  loadingStatus: WritableSignal<{
    loading: boolean;
    action: 'loading' | 'submitting' | 'done';
    message: string;
  }> = signal({
    loading: false,
    action: 'done',
    message: ''
  });

  constructor(
    private api: ApiService,
    private toast: ToastMessageService,
    private lawFirmService: LawFirmService,
  ) { 
    super();
    
    // Single effect to handle form updates
    effect((onCleanup) => {
      const { currentValue } = this.formState();
      
      if (currentValue) {
        // Existing contact - load their data
        this.contactForm.patchValue(currentValue);
        
        this.getContactPhones();
        if (this.isLawFirm) {
          this.getContactAddress();
        }
      } else if (this.isLawFirm && !this.contact()) {
        // New law firm contact - try to prepopulate address
        this.prepopulateLawFirmAddress();
      }

      this.loadContactList();

      onCleanup(() => this.contactForm.reset());
    });
  }

  ngAfterViewInit() {
    this.contactHTMLElement = document.querySelector('contact-form');
    this.updateWidth(this.contactHTMLElement?.clientWidth || window.innerWidth);
    this.watchInputElements();
  }

  // On form submit
  async onSubmit(): Promise<boolean | Contact> {
    // Set scope variables
    let contactResult: any, phoneResults: any, addressResult: any, xrefPhoneResults: any, xrefAddressResult: any;
    
    try {
      const currentContactId = this.contactForm.get('Id')?.value;
      const submitType = currentContactId ? 'PATCH' : 'POST';
      const endpoint = currentContactId ? `${APIEndpoints.Contacts}/${currentContactId}` : APIEndpoints.Contacts;
      const phoneFormHasChanges = this.phoneForms.length > 0 && !this.phoneForms.first?.phoneForm.pristine;
      const addressFormHasChanges = !this.addressFormComp.addressForm.pristine;
      this.loadingStatus.set({ loading: true, action: 'submitting', message: 'Validating form...' });

      // Check if editing existing record and no changes - exit if true
      if (submitType === 'PATCH' && this.contactForm.pristine && (this.isLawFirm ? this.addressFormComp.addressForm.pristine : true) && !this.contactPhones()) {
        this.notify('No changes to submit', this.NotificationSeverity.Warning);
        this.loadingStatus.set({ loading: false, action: 'done', message: '' });
        return false;
      }

      // Touch all fields for new records, touch all fields for existing records if there are changes
      this.markAllFieldsAsTouched();
      if (submitType === 'POST') {
        this.addressFormComp.markAllFieldsAsTouched();
        if (this.phoneForms.length > 0) this.phoneForms.forEach(phoneForm => phoneForm.phoneForm.markAsTouched());
      } else {
        if (phoneFormHasChanges) this.phoneForms.forEach(phoneForm => phoneForm.phoneForm.markAsTouched());
        if (addressFormHasChanges) this.addressFormComp.addressForm.markAsTouched();
      }

      // Validate forms
      if (this.contactForm.invalid) {
        this.handleError(ERRORS.CONTACT_FORM.FORM_VALIDATION_FAILED, {
          context: 'ContactForm.onSubmit',
          userMessage: ERRORS.CONTACT_FORM.FORM_VALIDATION_FAILED.message,
          severity: this.ErrorSeverity.Warning
        });
        this.loadingStatus.set({ loading: false, action: 'done', message: '' });
        return false;
      }

      // Submit form
      this.loadingStatus.set({ loading: true, action: 'submitting', message: 'Saving contact...' });
      contactResult = await this.api.fetchRequest(`odata${endpoint}`, submitType, this.contactForm.value);

      // Exit if law firm successful and no other changes
      const exitForm = Boolean(contactResult?.Id) && !phoneFormHasChanges && !addressFormHasChanges;
      if (exitForm) {
        this.notify('Successfully submitted contact.', this.NotificationSeverity.Success);
        return true;
      }

      // Save phone information
      const submitPhone = Boolean(contactResult?.Id) && phoneFormHasChanges;
      if (submitPhone) {
        this.loadingStatus.set({ loading: true, action: 'submitting', message: 'Saving phone information...' });
        this.phoneForms.forEach(phoneForm => phoneForm.phoneForm.markAsTouched());
        phoneResults = await Promise.all(this.phoneForms.map(async (phoneForm) => {
          if (!phoneForm.phoneForm.pristine) return await phoneForm.onSubmit() as Phone;
          else return false;
        }));
      }

      // Save address information
      const submitAddress = Boolean(contactResult?.Id) && addressFormHasChanges;
      if (submitAddress) {
        this.loadingStatus.set({ loading: true, action: 'submitting', message: 'Saving address information...' });
        this.addressFormComp.markAllFieldsAsTouched();
        addressResult = await this.addressFormComp.onSubmit() as any;
        if (addressResult === false) return false;
      }

      // Phone Xref - POST
      if (phoneResults?.length > 0) {
        const newPhoneResults = phoneResults.filter((result: Phone | false): result is Phone => result !== false);
        if (newPhoneResults.length > 0) {
          this.loadingStatus.set({ loading: true, action: 'submitting', message: 'Linking phones...' });
          xrefPhoneResults = await Promise.all(newPhoneResults.map(async (phone: Phone) => {
            return await this.api.fetchRequest(
              `odata${APIEndpoints.XrefPhoneContacts}`,
              'POST',
              { PhoneId: phone.Id, ContactId: contactResult.Id }
            );
          }));
        }
      }

      // Address Xref - POST
      const submitXrefAddress = !currentContactId && Boolean(contactResult?.Id) && Boolean(addressResult?.Id);
      if (submitXrefAddress) {
        this.loadingStatus.set({ loading: true, action: 'submitting', message: 'Linking address...' });
        xrefAddressResult = await this.api.fetchRequest(
          `odata${APIEndpoints.XrefAddressContacts}`,
          'POST',
          { AddressId: addressResult.Id, ContactId: contactResult.Id }
        );
      }

      // Notify and return success
      this.notify('Contact submitted successfully', this.NotificationSeverity.Success);
      return true;

    } catch (error) {
      this.handleError(error, {
        context: 'ContactForm.onSubmit',
        userMessage: 'Failed to submit contact form',
        severity: this.ErrorSeverity.Error,
        technicalDetails: { formValue: this.contactForm.value }
      });
      return false;

    } finally {
      this.loadingStatus.set({ loading: false, action: 'done', message: '' });
    }
  }

  markAllFieldsAsTouched(): void {
    Object.values(this.contactForm.controls).forEach(control => {
      control.markAsTouched();
      control.markAsDirty();
      control.updateValueAndValidity();
    });
  }

  // Perform updates on input elements
  watchInputElements() {
    document.querySelectorAll(`.cc-input-container`).forEach((inputContainer) => {
      const container = inputContainer as HTMLElement;

      container.querySelectorAll('input, span, textarea').forEach((input) => {
        input.addEventListener('focus', () => inputContainer.classList.add('focus'));
        input.addEventListener('blur', () => inputContainer.classList.remove('focus'));
      });
    });
  }

  private async createXrefAccountContact(newContactId: number) {
    if (this.selectedAccountId) {
      const xrefEndpoint = this.isLawFirm ? APIEndpoints.XrefLawFirmContacts : APIEndpoints.XrefProviderContacts;
      const xrefPayload = this.isLawFirm
        ? { LawFirmId: this.selectedAccountId, ContactId: newContactId }
        : { ProviderId: this.selectedAccountId, ContactId: newContactId };
  
      const res = await this.api.fetchRequest(`odata${xrefEndpoint}`, 'POST', xrefPayload);
      if (res instanceof Error) throw new Error(`Failed to create xref ${this.isLawFirm ? 'lawfirm' : 'provider'} contact`);
    }
  }

  // Switches form to 2 columns when parent is larger 1600px
  updateWidth(containerWidth: number) {

    if (containerWidth > 1200) {
      this.formClasses.form = this.formClasses.form.replace('flex-column', 'flex-row flex-wrap');
      this.formClasses.twoCol = this.formClasses.twoCol.replace('col-12', 'col-6')  ;
    } else {
      this.formClasses.form = this.formClasses.form.replace('flex-row', 'flex-column');
      this.formClasses.twoCol = this.formClasses.twoCol.replace('col-6', 'col-12');
    }
  }

  // Update the layout on window resize
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    const containerWidth = this.contactHTMLElement?.clientWidth || window.innerWidth;
    this.updateWidth(containerWidth);
  }

  // Returns appropraite error message for form control
  getErrorMessage(controlName: string): string {
    let message = '';
    const control = this.contactForm.get(controlName);
    if (control?.errors) {
      if (control.errors['required']) message = 'This field is required';
      if (control.errors['email']) message = 'Invalid email format';
      if (control.errors['invalidPhone']) message = 'Invalid phone number (10 digits required)';
      if (control.errors['serverError']) message = control.errors['serverError'].message;
    };
    this.errorMessage = message;
    return message;
  }

  // Fetches contact address
  async getContactAddress() {
    const contactId = this.formState().currentValue?.Id;

    if (!contactId) return;

    try {
      const endpoint = `${APIEndpoints.XrefAddressContacts}`;
      const expandString = 'Address($select=Id,AddressType,Address1,Address2,City,State,Zip,AddressType),Contact($select=Id)';
      const predicate = new Predicate('Contact/Id', 'equal', contactId);
      const query = new Query().expand(expandString).where(predicate);
      
      const res: any = await this.api.getOdata(endpoint).executeQuery(query);
      
      const address = res.result.length > 0 ? res.result[0].Address : undefined;
      
      this.contactAddress.set(address);
      if (this.addressFormComp) {
        this.addressFormComp.address = signal(address);
        
        // If no address found, clear the form
        if (!address) {
          this.addressFormComp.addressForm.patchValue({
            Id: null,
            AddressType: null,
            Address1: '',
            Address2: '',
            City: '',
            State: null,
            Zip: ''
          });
        } else {
          this.addressFormComp.addressForm.patchValue(address);
        }
      }

      return this.contactAddress();
    } catch (error) {
      console.error('Error in getContactAddress:', error);
      this.toast.showError('Error fetching contact address');
      throw error;
    }
  }

  // Populates the XrefContactPhones field with the contact's phones
  async getContactPhones() {
    // using this.formState() automatically detects changes to update signals
    const contactId = this.formState().currentValue?.Id;
    if (!contactId) return; // If there is no contactId, there is no need to fetch phones and we can safely exit
    
    try {
      const endpoint = `${APIEndpoints.XrefPhoneContacts}`;
      const expandString = 'Phone($select=Id,PhoneType,PhoneNumber,Extension)';
      const predicate = new Predicate('ContactId', 'equal', contactId);
      const query = new Query().expand(expandString).where(predicate);
      const res: any = await this.api.getOdata(endpoint).executeQuery(query);
      
      // Set phone signal - activates change detection to reset form if no phone is found
      const phones = res.result.length > 0 ? res.result.map((phone: any) => phone.Phone) : undefined;
      this.contactPhones.set(phones);
      this.contactForm.patchValue({ XrefPhoneContacts: phones });

      // Log empty phone record to notify developers
      if (!phones) console.log(`No Phone found for ${this.contactForm.get('ContactName')?.value}`);

      return this.contactPhones();

    } catch (error) {
      console.error('Error fetching contact phone:', error);
      this.toast.showError('Error fetching contact phone');
      throw error;
    }
  }

  resetForms() {
    this.contactForm.reset();
    this.contactPhones.set(undefined);
    if (this.isLawFirm) {
      this.addressFormComp.addressForm.reset();
      this.contactAddress.set(undefined);
    }
  }

  // Helper method to convert object to signal
  returnPhoneSignal(value: Phone | undefined): WritableSignal<Phone | undefined> {
    return signal(value);
  }

  // Add new phone form
  addNewPhone() {
    const currentPhones = this.contactPhones() || [];
    this.contactPhones.set([...currentPhones, {}]);
  }

  // Remove phone form at index
  removePhone(index: number) {
    const currentPhones = this.contactPhones() || [];
    const updatedPhones = currentPhones.filter((_, i) => i !== index);
    this.contactPhones.set(updatedPhones);
  }

  getLawFirmAddress() {
    if (!this.selectedAccountId) throw new Error('No law firm selected');
    const query = lawFirmQuery;
    return this.lawFirmService.getLawFirmById(this.selectedAccountId, query).then((lawFirm) => {
      if (!lawFirm) throw new Error('No law firm found for the specified contact ID');
      return lawFirm.XrefAddressLawfirms?.[0]?.Address ?? null;
    });
  }

  public prepopulateLawFirmAddress() {
    if (this.isLawFirm && 
        this.selectedAccountId && 
        !this.contact() && 
        !this.contactAddress()) {
      this.getLawFirmAddress().then((address) => {
        if (address) {
          // Create new address without ID to ensure a new record
          this.addressFormComp.addressForm.patchValue({
            // Don't include the ID field
            AddressType: address.AddressType,
            Address1: address.Address1,
            Address2: address.Address2,
            City: address.City,
            State: address.State,
            Zip: address.Zip
          }, { emitEvent: false });
          
          // Mark form as dirty since we want this saved
          this.addressFormComp.addressForm.markAsDirty();
        }
      }).catch(error => {
        console.error('Error prepopulating law firm address:', error);
        this.toast.showError('Failed to prepopulate law firm address');
      });
    }
  }

  private async loadContactList() {
    try {
      let endpoint: string;
      let query: Query;

      if (this.isLawFirm) {
        endpoint = APIEndpoints.XrefLawFirmContacts;
        query = new Query()
          .expand('Contact')
          .where('LawFirmId', 'notEqual', this.selectedAccountId);
      } else {
        endpoint = APIEndpoints.XrefProviderContacts;
        query = new Query()
          .expand('Contact')
          .where('ProviderId', 'notEqual', this.selectedAccountId);
      }

      const response = await this.api.getOdata(endpoint)
        .executeQuery(query)
        .then((response: any) => response.result || []);


      const contacts = response
        .map((xref: { Contact: Contact }) => xref.Contact)
        .filter((contact: Contact | null) => contact !== null && contact !== undefined);

      this.contactList.set(contacts);
    } catch (error) {
      console.error('Error loading contacts:', error);
      this.toast.showError('Error loading contact list');
    }
  }

  protected async onContactSelected(contact: Contact) {
    if (!contact) return;

    this.contactForm.patchValue({
      Id: contact.Id,
      ContactType: contact.ContactType,
      ContactName: contact.ContactName,
      ContactTitle: contact.ContactTitle,
      ContactEmail: contact.ContactEmail,
      Notes: contact.Notes
    });

    if (this.selectedAccountId) {
      const xrefEndpoint = this.isLawFirm ? APIEndpoints.XrefLawFirmContacts : APIEndpoints.XrefProviderContacts;
      const xrefPayload = this.isLawFirm
        ? { LawFirmId: this.selectedAccountId, ContactId: contact.Id }
        : { ProviderId: this.selectedAccountId, ContactId: contact.Id };

      try {
        await this.api.fetchRequest(`odata${xrefEndpoint}`, 'POST', xrefPayload);
      } catch (error: any) {
        if (!error.toString().includes('duplicate')) {
          console.error('Error creating contact XRef:', error);
          this.toast.showError('Error linking contact');
        }
      }
    }

    this.getContactPhones();
    if (this.isLawFirm) {
      this.getContactAddress();
    }
  }

}
