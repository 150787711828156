// Angular
import { Component, computed, effect, EventEmitter, HostListener, Input, Output, Signal, signal, WritableSignal, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

// Core Services
import { ErrorHandlingService } from '@core/error/error.service';
import { ErrorSeverity } from '@core/error/error.types';

// 3rd Party
import { EmitType } from '@syncfusion/ej2-base';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { TextBoxAllModule, MaskedTextBoxAllModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownListAllModule, FilteringEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { TooltipAllModule } from '@syncfusion/ej2-angular-popups';
import { Query } from '@syncfusion/ej2-data';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faBuilding, faCity, faHouseUser, faHashtag, faBuildingUser, faFlagUsa, faTableList, faMapLocationDot } from '@fortawesome/free-solid-svg-icons';

// Models
import { Address } from '@models/data-contracts';
import { APIEndpoints } from '@models/api/Endpoints';

// Services
import { ApiService } from '@services/api/api.service';
import { FormCrudService } from '@services/forms/form-crud.service';

// Components
import { ComponentBase } from '@core/base/component.base';

// Type & Interface setup
type AddressFormComponentControls = {
  [K in keyof Address]: FormControl<Address[K] | null>;
};

// Add interface for consistent return type
export interface AddressFormResult {
  status: 'success' | 'error' | 'no-change';
  message?: string;
  data?: any;
}

@Component({
  selector: 'address-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    TextBoxAllModule,
    MaskedTextBoxAllModule,
    DropDownListAllModule,
    TooltipAllModule,
    FontAwesomeModule
  ],
  templateUrl: './address-form.component.html',
  styleUrl: './address-form.component.scss'
})
export class AddressFormComponent extends ComponentBase implements OnInit {

  constructor(
    private formCRUD: FormCrudService,
    private api: ApiService
  ) {
    super();
    // Set up address data effect
    effect(() => {
      const address = this.address();
      if (address) {
        this.addressForm.patchValue(address, { emitEvent: false });
      }
    });
  }

  // States
  protected readonly formState = computed(() => {
    const currentAddress = this.address();
    return {
      isValid: this.addressForm.valid,
      hasChanges: currentAddress !== this.addressForm.value,
      currentValue: currentAddress
    };
  });
  protected readonly errorMessages = computed(() => {
    const errors: Record<string, string> = {};
    Object.keys(this.addressForm.controls).forEach(key => {
      const control = this.addressForm.get(key);
      if (control?.errors) errors[key] = this.getErrorMessage(key);
    });
    return errors;
  });

  // Decorator variabls
  @Input() address!: WritableSignal<Address | undefined>;
  @Input() submitType?: 'POST' | 'PATCH' | 'DELETE';
  @Input() title: string = 'Address';
  @Input() displayFields: string[] = ['Address1', 'Address2', 'City', 'State', 'Zip', 'AddressType'];
  @Input() displayButtons: boolean = true;
  @Input() displayTitle: boolean = true;
  @Input() formMainTitle?: string;
  @Input() requiredFields: string[] = ['Address1', 'Zip', 'AddressType'];
  @Output() formSubmitted = new EventEmitter<void>();
  @Output() formResult = new EventEmitter<any>();

  // Public variables
  loadingForm: boolean = true;
  errorMessage: string = '';
  addressHTMLElement: Element | null = null;
  addressTypes: any;
  states: any;
  addressForm = new FormGroup<AddressFormComponentControls>({
    Id: new FormControl<Address['Id']>(undefined),
    AddressType: new FormControl<Address['AddressType']>(undefined, [Validators.required]),
    Address1: new FormControl<Address['Address1']>(''),
    Address2: new FormControl<Address['Address2']>(''),
    City: new FormControl<Address['City']>(''),
    State: new FormControl<Address['State']>(undefined),
    Zip: new FormControl<Address['Zip']>(''),
    County: new FormControl<Address['County']>('')
  });
  addressIcons = {
    form: faMapLocationDot,
    address1: faHouseUser,
    address2: faBuildingUser,
    addressType: faTableList,
    city: faCity,
    state: faFlagUsa,
    zip: faHashtag,
    county: faBuilding,
  }
  formClasses = {
    formContainer: 'cc-form-container',
    form: 'cc-form flex-column',
    subForm: 'cc-sub-form',
    mainTitle: 'cc-main-title',
    section: 'cc-form-section',
    group: 'cc-form-group row',
    inputContainer: 'cc-input-container',
    label: 'cc-label',
    input: 'cc-input',
    icon: 'cc-input-icon',
    error: 'cc-input-error',
    actions: 'cc-form-actions',
    twoCol: 'col-12',
    threeCol: 'col-12'
  };
  ngAfterViewInit() {
    this.addressHTMLElement = document.querySelector('address-form');
    this.updateWidth(this.addressHTMLElement?.clientWidth || window.innerWidth);
    this.watchInputElements();
  }

  ngOnInit() {
    // Add validators after form is initialized
    this.requiredFields.forEach(field => {
      const control = this.addressForm.get(field);
      if (control) {
        control.addValidators([Validators.required]);
        control.updateValueAndValidity({ emitEvent: false });
      }
    });
  }

  // Update the layout on window resize
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    const containerWidth = this.addressHTMLElement?.clientWidth || window.innerWidth;
    this.updateWidth(containerWidth);
  }

  // Switches form to 2 columns when parent is larger 1600px
  updateWidth(containerWidth: number) {

    if (containerWidth > 576 && containerWidth < 768) {
      this.formClasses.form = this.formClasses.form.replace('flex-column', 'flex-row flex-wrap');
      this.formClasses.twoCol = this.formClasses.twoCol.replace('col-12', 'col-6');
      this.formClasses.threeCol = this.formClasses.threeCol.replace('col-12', 'col-6');
    } else if (containerWidth > 768) {
      this.formClasses.form = this.formClasses.form.replace('flex-column', 'flex-row flex-wrap');
      this.formClasses.twoCol = this.formClasses.twoCol.replace('col-12', 'col-6');
      this.formClasses.threeCol = this.formClasses.threeCol.replace('col-12', 'col-4');
    } else {
      this.formClasses.form = this.formClasses.form.replace('flex-row', 'flex-column');
      this.formClasses.twoCol = this.formClasses.twoCol.replace('col-6', 'col-12');
      this.formClasses.threeCol = this.formClasses.threeCol.replace('col-4', 'col-12');
    }
  }

  // Perform updates on input elements
  watchInputElements() {
    document.querySelectorAll(`.cc-input-container`).forEach((inputContainer) => {
      const container = inputContainer as HTMLElement;

      container.querySelectorAll('input, span').forEach((input) => {
        input.addEventListener('focus', () => inputContainer.classList.add('focus'));
        input.addEventListener('blur', () => inputContainer.classList.remove('focus'));
      });
    });
  }

  // Returns appropraite error message for form control
  getErrorMessage(controlName: string): string {
    let message = '';
    const control = this.addressForm.get(controlName);
    if (control?.errors) {
      if (control.errors['required']) message = 'This field is required';
      if (control.errors['email']) message = 'Invalid email format';
      if (control.errors['invalidPhone']) message = 'Invalid phone number (10 digits required)';
      if (control.errors['serverError']) message = control.errors['serverError'].message;
    };
    this.errorMessage = message;
    return message;
  }

  // Filtering handler for dropdowns
  onDropdownFiltering: EmitType<FilteringEventArgs> = (filterEvent: FilteringEventArgs, data: any) => {
    let query = new Query();
    query = (filterEvent.text !== "") ? query.where("Text", "contains", filterEvent.text, true) : query;
    filterEvent.updateData(data, query);
  };

  async onStatesCreated(args: any) {
    const endpoint = APIEndpoints.States;
    const query = new Query().select(['Id', 'Name']);

    try {
      await this.api.getOdata(endpoint).executeQuery(query).then((res: any) => {
        this.states = res.result;
      });
    } catch (error) {
      this.errorHandling.handleError(error, {
        context: 'AddressFormComponent.onStatesCreated',
        userMessage: 'Failed to load states',
        severity: ErrorSeverity.Warning
      });
    }
  }

  async onAddressTypesCreated(args: any) {
    const endpoint = APIEndpoints.AddressTypes;
    const query = new Query().select(['Id', 'Description']);

    try {
      await this.api.getOdata(endpoint).executeQuery(query).then((res: any) => {
        this.addressTypes = res.result;
      });
    } catch (error) {
      this.errorHandling.handleError(error, {
        context: 'AddressFormComponent.onAddressTypesCreated',
        userMessage: 'Failed to load address types',
        severity: ErrorSeverity.Warning
      });
    }
  }

  // Update the onSubmit method
  async onSubmit(): Promise<boolean | Address> {
    let addressResult;

    try {
      // Set submit type and endpoint
      const submitType = this.submitType || (this.addressForm.get('Id')?.value ? 'PATCH' : 'POST');
      const endpoint = this.addressForm.get('Id')?.value ? `${APIEndpoints.Addresses}/${this.addressForm.get('Id')?.value}` : APIEndpoints.Addresses;

      // Touch all fields
      this.markAllFieldsAsTouched();
    
      // Validate form
      if (this.addressForm.invalid) {
        const errors = Object.keys(this.addressForm.controls)
          .filter(key => this.addressForm.get(key)?.errors)
          .map(key => `${key}: ${this.getErrorMessage(key)}`) 
          .join(', ');
          
        return false;
      }

      // Check for changes - exit if none
      if (this.addressForm.pristine) {
        this.notify('No changes to address', this.NotificationSeverity.Warning);
        return false;
      }

      addressResult = await this.formCRUD.submitForm(this.addressForm, `odata${endpoint}`, submitType);
      this.address.set(addressResult);
      return addressResult as Address;

    } catch (error) {
      console.error('Address form submission error:', error);
      throw error;
    }
  }

  markAllFieldsAsTouched(): void {
    Object.values(this.addressForm.controls).forEach(control => {
      control.markAsTouched();
      control.markAsDirty();
      control.updateValueAndValidity();
    });
  }
}
