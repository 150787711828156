<div class="e-section-control form">
  <div class="form-row">
    <ejs-radiobutton 
      [label]="formLabels.requested" 
      [value]="'requested'" 
      [(ngModel)]="formData.selectedOption"
      name="default"
    ></ejs-radiobutton>
  </div>
  
  <div class="form-row">
    <ejs-radiobutton 
      [label]="formLabels.settleNow" 
      [value]="'settleNow'" 
      [(ngModel)]="formData.selectedOption"
      name="default"
    ></ejs-radiobutton>
  </div>
  
  <div class="form-row">
    <ejs-radiobutton 
      [label]="formLabels.newBalanceDue" 
      [value]="'newBalanceDue'" 
      [(ngModel)]="formData.selectedOption"
      name="default"
    ></ejs-radiobutton>

    @if(formData.selectedOption === "newBalanceDue") {
      &#160;
      $
      <ejs-numerictextbox 
        [(ngModel)]="formData.newBalanceDue"
        [min]="0"
        placeholder="Enter dollar amount"
      ></ejs-numerictextbox>
    }
  </div>
  
  <div class="form-row">
    <ejs-radiobutton 
      [label]="formLabels.totalCourtesyReduction" 
      [value]="'totalCourtesyReduction'" 
      [(ngModel)]="formData.selectedOption"
      name="default"
    ></ejs-radiobutton>
  </div>
  
  @if(formData.selectedOption === "totalCourtesyReduction") {
    <div class="form-row multi">
      <div class="form-column">
        <ejs-numerictextbox 
          [(ngModel)]="formData.courtesyReduction.amount"
          (ngModelChange)="onReductionAmountChange()"
          [min]="0"
          format='c2'
          placeholder="Reduction $"
          floatLabelType="Auto"
          [enabled]="formData.selectedOption === 'totalCourtesyReduction'"
        ></ejs-numerictextbox>
      </div>
    <br/>
    OR
      <div class="form-column">
        <ejs-numerictextbox 
          [(ngModel)]="formData.courtesyReduction.percentage"
          (ngModelChange)="onReductionPercentageChange()"
          [min]="0"
          format='p2'
          step="0.01"
          placeholder="Reduction %"
          floatLabelType="Auto"
          [enabled]="formData.selectedOption === 'totalCourtesyReduction'"
        ></ejs-numerictextbox>
      </div>
    </div>
  }

  <div class="col-12 comments">
    <ejs-textbox 
      [multiline]="true" 
      [(ngModel)]="this.formData.comment"
      placeholder="Add a Comment"
      floatLabelType="Auto" />
  </div>
  
  <financial-info 
    [caseFileId]="caseFileId" 
    [balanceDue]="balanceDue ?? undefined"
    [isReductionPage]="true"
    [parentSelectedOption]="formData.selectedOption"
    (reductionChange)="onFinancialReductionChange($event)"/>
    <button ejs-button [isPrimary]="true" (click)="onSubmit()" style="margin-top: 20px;">Submit</button>
</div>