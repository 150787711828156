import { CommonModule } from "@angular/common";
import { Component, Input, Output, EventEmitter, OnInit, HostListener } from "@angular/core";
import { 
  FormControl, 
  FormGroup, 
  ReactiveFormsModule, 
  Validators
} from "@angular/forms";
import { APIEndpoints } from "@root/src/app/shared/models/api/Endpoints";
import { ApiService } from "@root/src/app/shared/services/api/api.service";
import { ButtonModule } from "@syncfusion/ej2-angular-buttons";
import { DropDownListModule, MultiSelectModule } from "@syncfusion/ej2-angular-dropdowns";
import { GridComponent } from "@syncfusion/ej2-angular-grids";
import { TextBoxModule } from "@syncfusion/ej2-angular-inputs";
import { TooltipModule } from '@syncfusion/ej2-angular-popups';
import { DataManager } from "@syncfusion/ej2-data";
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { GlobalsService } from '@services/globals/globals.service';

interface ApiError {
  code: string;
  message: string;
  field: string | null;
}

interface ApiResponse<T> {
  success: boolean;
  data: T | null;
  errors: ApiError[];
}

/**
 * Component for adding new users to the system
 * Includes form validation and role assignment
 */
@Component({
  selector: 'add-user',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    TextBoxModule,  
    DropDownListModule,
    MultiSelectModule,
    TooltipModule
  ],
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})

export class AddUserComponent implements OnInit {
  @Input() grid: GridComponent;
  @Output() closeForm = new EventEmitter<void>();
  
  // Form group with validation rules
  addUserForm = new FormGroup({
    userName: new FormControl('', Validators.required),
    name: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    title: new FormControl(''),
    phoneNumber: new FormControl('', [Validators.required, this.globals.phoneNumberValidator()]),
    roleId: new FormControl('', Validators.required),
    rightIds: new FormControl('')
  });

  rolesData: DataManager;
  rightsData: DataManager;
  errorMessage: string = '';

  constructor(
    private api: ApiService, 
    private toast: ToastMessageService,
    private globals: GlobalsService
  ) {}

  ngOnInit(): void {
    this.resetFormState();
    this.setRoles();
    this.setRights();
  }

  /**
   * Fetches available roles from the API
   */
  private setRoles(): void {
    this.rolesData = this.api.getOdata(APIEndpoints.Roles);
  }

  /**
   * Fetches available rights from the API
   */
  private setRights(): void {
    this.rightsData = this.api.getOdata(APIEndpoints.Rights);
  }

  /**
   * Handles escape key press to close form
   */
  @HostListener('document:keydown.escape')
  handleEscapeKey(): void {
    this.cancel();
    this.closeForm.emit();
  }

  /**
   * Resets form and clears error messages
   */
  cancel(): void {
    this.addUserForm.reset();
    this.errorMessage = '';
    this.closeForm.emit();
  }

  /**
   * Handles form submission and user creation
   */
  async onSubmit(): Promise<void> {
    if (this.addUserForm.valid) {
      try {
        // First create the Cognito user
        const cognitoResponse = await this.api.basicPost('api/cognito/create-user', {
          email: this.addUserForm.value.email,
          name: this.addUserForm.value.name,
          phoneNumber: this.formatPhoneNumber(this.addUserForm.value.phoneNumber),
          userName: this.addUserForm.value.userName
        }, false) as Response;
        
        let cognitoData;
        try {
          cognitoData = await cognitoResponse.json();
        } catch (e) {
          throw new Error(`Failed to parse Cognito response: ${cognitoResponse.statusText}`);
        }
        
        if (!cognitoResponse.ok || !cognitoData.success) {
          throw new Error(cognitoData.message || `Failed to create Cognito user: ${cognitoResponse.statusText}`);
        }

        // Now create the database user with the Cognito sub
        const userData = {
          username: this.addUserForm.value.userName,
          name: this.addUserForm.value.name,
          email: this.addUserForm.value.email,
          title: this.addUserForm.value.title,
          phoneNumber: this.addUserForm.value.phoneNumber,
          usersub: cognitoData.userSub,
          active: true,
          xrefUsersRoles: this.getRoleIds().map(roleId => ({ roleId })),
          xrefUsersRights: this.addUserForm.value.rightIds ? 
            this.getRightIds().map(rightId => ({ rightId })) : 
            []
        };

        // Insert the user and capture the response
        const rawResponse = await this.api.basicPost(APIEndpoints.Users, userData) as Response;
        let errorMessage = '';

        try {
          const errorData = await rawResponse.clone().json();
          if (!rawResponse.ok) {
            // Check for specific error structure from backend
            if (errorData.errors && errorData.errors.length > 0) {
              errorMessage = errorData.errors[0].message;
            } else if (errorData.message) {
              errorMessage = errorData.message;
            } else {
              errorMessage = `Failed to create database user: ${rawResponse.statusText}`;
            }
            throw new Error(errorMessage);
          }
        } catch (error) {
          throw new Error(`Failed to parse error response: ${error}`);
        }

        const response = await rawResponse.json() as ApiResponse<{id: number}>;
        if (!response.success) {
          throw new Error(response.errors?.[0]?.message || 'Failed to create database user');
        }
        
        // Get the new user's ID from the response
        const newUserId = response?.data?.id;

        this.toast.showSuccess(`  
          User ${userData.name} has been created.
          <a href="/users/${newUserId}" class="toast-link">
            <i class="fa fa-external-link"></i>
            View user profile
          </a>
        `);

        this.resetFormState();
        this.closeForm.emit();
      } catch (error) {
        this.errorMessage = (error as Error).message || 'Failed to create user. Please try again.';
        this.toast.showError(this.errorMessage);
        console.error('Error creating user:', error);
      }
    } else {
      this.markFormGroupTouched(this.addUserForm);
    }
  }

  // Helper method to mark all form controls as touched
  private markFormGroupTouched(formGroup: FormGroup): void {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }

  /**
   * Returns appropriate error message for form validation
   */
  getErrorMessage(controlName: string): string {
    const control = this.addUserForm.get(controlName);
    if (control?.errors) {
      if (control.errors['required']) return 'This field is required';
      if (control.errors['email']) return 'Invalid email format';
      if (control.errors['invalidPhone']) return 'Invalid phone number (10 digits required)';
      if (control.errors['serverError']) return this.errorMessage;
    }
    return '';
  }

  // Add this method to clear form state
  resetFormState(): void {
    this.addUserForm.reset();
    this.errorMessage = '';
  }

  private getRoleIds(): number[] {
    const roleIds = Array.isArray(this.addUserForm.value.roleId) 
      ? this.addUserForm.value.roleId 
      : [this.addUserForm.value.roleId];
    return roleIds.map(Number);
  }

  private getRightIds(): number[] {
    const rightIds = Array.isArray(this.addUserForm.value.rightIds)
      ? this.addUserForm.value.rightIds
      : [this.addUserForm.value.rightIds];
    return rightIds.map(Number);
  }

  /**
   * Formats phone number to E.164 format for Cognito
   */
  private formatPhoneNumber(phone: string | null | undefined): string {
    if (!phone) return '';
    // Remove all non-digit characters
    const digits = phone.replace(/\D/g, '');
    // Ensure 10 digits and add US country code
    if (digits.length === 10) {
      return `+1${digits}`;
    }
    return phone; // Return original if not 10 digits
  }

}
