import { Query } from '@syncfusion/ej2-data';
import { ToolbarItem, ToolbarItems } from '@syncfusion/ej2-angular-grids';
import { ItemModel } from '@syncfusion/ej2-angular-navigations';

// Ensures toolbar items are aligned to the right
export const DefaultToolbar: (ToolbarItems | string | ItemModel | ToolbarItem)[] = [
  'Add',
  'ColumnChooser',
  { text: 'Copy', tooltipText: 'Copy', id: 'copy', align: 'Right', prefixIcon: 'e-copy' },
  { text: 'CSV Export', tooltipText: 'CSV Export', id: 'csvExport', align: 'Right', prefixIcon: 'e-export-csv' },
  { text: 'Excel Export', tooltipText: 'Excel Export', id: 'excelExport', align: 'Right', prefixIcon: 'e-export-excel' },
  { text: 'PDF Export', tooltipText: 'PDF Export', id: 'pdfExport', align: 'Right', prefixIcon: 'e-export-pdf' },
  { text: 'Print', tooltipText: 'Print', id: 'print', align: 'Right', prefixIcon: 'e-print' }
];  

export const defaults = {
  toolbar: DefaultToolbar,
  height: '100%',
  dataSource: [],
  query: new Query(),
  allowPaging: true,
  allowSorting: true,
  allowFiltering: true,
  allowResizing: true,
  allowReordering: true,
  selectionSettings: { type: 'Multiple', mode: 'Row' },
  showColumnChooser: true,
  columnChooserSettings: { operator: 'contains', ignoreAccent: true },
  pageSettings: undefined,
  filterSettings: { type: 'FilterBar' },
  loadingIndicator: { indicatorType: 'Shimmer' },
  editSettings: { allowEditOnDblClick: false, allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Dialog' },
  rowRenderingMode: true,
  enableAdaptiveUI: true,
  enablePersistence: false,
  allowPdfExport: true,
  allowExcelExport: true,
  sortSettings: { columns: [{ field: 'Id', direction: 'Descending' }] }
};