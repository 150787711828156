<div id="body-part-selector" class="d-flex justify-content-center align-items-center">

    <svg #svgElement xmlns="http://www.w3.org/2000/svg" viewBox="0 0 992 1000">
        
        <path id="LeftWrist" severity="none" partId="9" class="body-part path0"
            (click)="pathClicked($event)"
            d="M451.69,484.95c-14.3-1.51-24.55,3.22-33.11,12.64c-9.48-14.9-9.02-18.14,6.22-26.65
                c8.94-4.98,9.21-4.57,16.47,2.87C444.29,476.9,447.17,480.11,451.69,484.95z"/>
        <path id="LeftHand" severity="none" partId="11" class="body-part path1" 
            (click)="pathClicked($event)"
            d="M482.05,508.48c-1.99-1.39-4.05-2.73-6.04-4.03c-4.37-2.85-8.9-5.81-12.71-9.26c-4.55-4.13-9.53-5.9-16.15-5.76
                c-3.74,0.07-7.22,0.43-10.35,1.06c-5.49,1.12-9.98,4.11-12.64,8.43c-2.57,4.18-3.22,9.29-1.82,14.4
                c3.17,11.59,3.54,23.87,1.15,37.56c-0.49,2.78,0,9.79,1.25,11.38c0.44,0.56,1.06,0.9,1.75,0.95c1.23,0.1,2.32-0.81,2.46-2.05
                c0.18-1.57,0.33-3.14,0.48-4.71c0.34-3.56,0.7-7.23,1.37-10.74c0.39-2.04,1.18-4.05,2.03-6.17c2.03,2.1,4.13,4.28,6.11,6.45
                c0.09,0.19,0.18,0.71,0.23,1.02c0.03,0.2,0.07,0.39,0.11,0.59c0.42,2,0.83,4,1.24,6c0.84,4.11,1.7,8.35,2.67,12.52
                c0.38,1.63,1.08,3.09,1.75,4.49c0.27,0.57,0.55,1.14,0.79,1.71c0.37,0.87,1.22,1.41,2.13,1.41c0.17,0,0.35-0.02,0.53-0.06
                c1.1-0.26,1.85-1.26,1.78-2.39c-0.39-6.79-1.48-13.29-2.53-19.57c-0.28-1.68-0.56-3.36-0.83-5.05c-0.48-2.98-0.66-6.25-0.55-9.99
                c0.02-0.26,0.28-0.8,0.53-1.11c0.38,0.1,0.89,0.37,1.04,0.53c0.45,0.71,0.9,1.42,1.36,2.13c2.26,3.51,4.59,7.13,5.92,10.9
                c1.68,4.75,2.88,9.83,4.04,14.74c0.51,2.16,1.04,4.39,1.6,6.59c0.26,1,1.15,1.71,2.18,1.74c1.01,0.05,1.96-0.64,2.27-1.62
                c0.94-3.02,1.13-5.95,0.56-8.69c-0.9-4.41-1.89-8.88-2.84-13.2c-1.12-5.08-2.27-10.34-3.3-15.52c-0.05-0.26,0.03-0.67,0.15-1.13
                c0.5,0.28,0.97,0.56,1.39,0.88c0.08,0.14,0.22,0.47,0.31,0.69c0.14,0.34,0.28,0.69,0.44,1c3.5,6.8,7,13.61,10.5,20.42
                c0.52,1.01,1.69,1.49,2.77,1.15c1.08-0.35,1.75-1.43,1.58-2.55c-0.85-5.66-2.6-10.98-4.3-16.13l-0.37-1.13
                c-0.61-1.84-1.21-3.68-1.82-5.52c-2.43-7.34-4.94-14.92-7.15-22.44c-0.16-0.53,0.05-1.68,0.5-2.6c0.68,0.14,1.66,0.45,2.21,0.8
                c1.66,1.05,3.23,2.24,4.89,3.5c1.61,1.22,3.28,2.49,5.04,3.61c1.41,0.91,3.02,1.32,4.43,1.69c0.55,0.14,1.1,0.28,1.62,0.45
                c1.15,0.37,2.39-0.2,2.85-1.31C485.49,512.54,483.19,509.26,482.05,508.48z"/>
        <path id="RightHand" severity="none" partId="12" class="body-part path2" 
            (click)="pathClicked($event)"
            d="M85.99,499.11c-2.07-4-6.26-6.68-14.46-9.25c-3.01-0.94-10.59-2.86-16.07-0.29
                c-6.58,3.07-12.72,7.18-18.66,11.16c-2.08,1.39-4.23,2.83-6.36,4.2c-1.75,1.13-3.41,2.39-5.01,3.61c-0.75,0.57-1.51,1.15-2.27,1.71
                c-0.82,0.6-1.13,1.69-0.73,2.64c0.39,0.94,1.38,1.5,2.39,1.33c3.23-0.51,5.99-1.62,8.67-2.69c1.37-0.55,2.66-1.07,3.98-1.49
                c1.94-0.62,4.04-0.45,4.61-0.12c0.29,0.63,0.31,2.74-0.22,4.23c-1.27,3.59-2.94,7.16-4.55,10.61c-0.59,1.27-1.19,2.55-1.77,3.83
                c-1.89,4.19-3.8,8.37-5.7,12.56c-1.52,3.36-3.05,6.72-4.57,10.07c-0.46,1.02-0.09,2.22,0.86,2.8c0.95,0.58,2.19,0.36,2.89-0.51
                c2.7-3.41,4.86-7.08,6.94-10.63c0.94-1.6,1.9-3.25,2.9-4.83c0.49-0.78,1.33-1.49,2.28-2.2c0.38,1.42,0.73,2.8,1,4.18
                c-0.05,0.15-0.15,0.4-0.23,0.57c-0.14,0.32-0.29,0.69-0.41,1.1c-1.42,4.94-5.67,21.47-5.67,23.06c0,1.05,0.74,1.96,1.78,2.17
                c1.73,0.34,2.32-0.69,4.27-4.15c1.07-1.9,2.5-4.53,4.14-7.61c2.45-4.61,5.78-11.01,8.29-16.13c2.94,5.02,2.07,10.59,1.15,16.45
                c-0.86,5.47-1.74,11.12,0.35,16.71c0.33,0.87,1.16,1.44,2.07,1.44c0.08,0,0.16,0,0.24-0.01c1.01-0.11,1.81-0.89,1.95-1.89
                c0.07-0.47,0.12-0.94,0.18-1.41c0.12-0.99,0.24-1.93,0.42-2.84c0.39-1.98,0.75-3.97,1.12-5.96c1.02-5.54,2.08-11.27,3.55-16.74
                c0.25-0.91,1.5-1.98,2.71-3.02c0.08-0.07,0.16-0.13,0.23-0.2c0.87,1.09,1.68,2.12,2.03,3.15c0.63,1.88,0.71,4.16,0.79,6.36
                l0.03,0.88c0.1,2.38,0.07,4.83,0.05,7.2c-0.01,1.05-0.02,2.1-0.02,3.15c0,0.8,0.43,1.53,1.12,1.92c0.69,0.39,1.54,0.38,2.23-0.03
                c1.38-0.83,1.69-2.03,4.07-16.08c1.73-10.22,4.11-24.21,6.73-32.34v0C87.81,508,88.02,503.02,85.99,499.11z"/>
        <path id="RightWrist" severity="none" partId="10" class="body-part path3" 
            (click)="pathClicked($event)"
            d="M93.21,484.49c-1.05,2.06-1.45,4.45-1.99,6.17c-9.93-3.56-18.93-6.78-28.91-10.35
                c1.58-3.68,3.64-8.48,5.84-13.61c6.87,2.14,13.49,3.96,19.92,6.26C93.52,474.91,96.29,478.41,93.21,484.49z"/>
        <path id="LeftAnkle" severity="none" partId="24" class="body-part path4"
            (click)="pathClicked($event)"
            d="M348.29,933.35c-0.18,1.84-2.67,3.46-4.12,5.17c-0.1,0.12-0.38,0.09-0.57,0.15
                c-16.73,5.42-22.18,0.55-18.52-16.94c0.31-1.47,3.15-3.16,5-3.42c5.6-0.8,11.29-0.94,18.4-1.45
                C348.48,921.9,348.83,927.66,348.29,933.35z"/>
        <path id="LeftFoot" severity="none" partId="26" class="body-part path5" 
            (click)="pathClicked($event)"
            d="M363.92,996.14c-8.34,1.58-17,1.69-25.53,1.88c-1.82,0.04-4.78-2.03-5.39-3.77
                c-5.59-15.85-10.02-31.94-7.68-48.16c8.6-1.31,16.14-2.45,23.44-3.56c7.74,11.79,15.57,24.03,23.74,36.03
                C378.1,986.79,374.79,994.08,363.92,996.14z"/>
        <path id="RightFoot" severity="none" partId="27" class="body-part path6" 
            (click)="pathClicked($event)"
            d="M204.67,946.53c-2.14,17.83-2.2,35.28-11,50.93c-2.56,4.54-7.4,4.03-11.91,3.41c-3.7-0.52-7.55-0.4-11.1-1.39
                c-5.44-1.5-10.93-3.23-15.92-5.79c-3.12-1.6-3.6-5.17-1.34-8.38c5.88-8.34,11.71-16.69,17.69-24.95c2.96-4.09,5.31-8.33,6.63-12.85
                C185.21,949.68,196.91,948.19,204.67,946.53z"/>
        <path id="RightAnkle" severity="none" partId="25" class="body-part path7" 
            (click)="pathClicked($event)"
            d="M204.47,921.37c0,0.02,0,0.03-0.01,0.05c-0.81,4.5-1.58,9.08-1.6,13.62c-0.01,6.87-1.33,8.78-8.53,9.22
                c-2.41,0.15-10.74-0.21-15.72-0.88c0.42-2.97,0.38-6.06-0.24-9.31c-0.11-0.59-0.23-1.18-0.3-1.78
                c-1.51-11.58-1.52-11.37,10.26-10.98C192.22,921.44,199.54,921.64,204.47,921.37z"/>
        <path id="RightLowerLeg" severity="none" partId="23" class="body-part path8" 
            (click)="pathClicked($event)"
            d="M225.01,808.88c-2.95,17.72-7.24,35.21-10.49,52.88c-2.28,12.4-3.58,24.97-5.6,37.42
                c-0.97,5.93-2.36,11.79-3.57,17.69c-5.86,0.02-15.25,0.17-20.51,0.07c-8.5-0.16-8.6-0.37-9.79-8.6
                c-4.09-28.33-8.87-56.58-12.06-85.01c-1.59-14.16-0.28-28.66-0.28-44.5c10.7,6.93,17.27,18.49,30,16.28
                c10.79-1.88,21.29-5.47,32.51-8.45C225.22,793.53,226.26,801.39,225.01,808.88z"/>
        <path id="LeftLowerLeg" severity="none" partId="22" class="body-part path9" 
            (click)="pathClicked($event)"
            d="M358.56,794.07c-1,16.53-2.18,33.06-3.74,49.55c-1.89,20.09-4.58,40.1-6.18,60.21
                c-0.44,5.58-1.93,8.79-7.33,9.47c-4.74,0.59-9.57,1.04-14.29,0.67c-1.5-0.12-3.66-2.88-4.02-4.73c-2.82-14.28-5.15-28.66-7.85-42.97
                c-3.69-19.6-7.74-39.13-11.28-58.76c-1.54-8.54-2.17-17.25-3.38-27.28c30.26,16.93,38.05,12.55,58.14-10.59
                C358.63,778.71,359.02,786.41,358.56,794.07z"/>
        <path id="LeftKnee" severity="none" partId="20" class="body-part path10" 
            (click)="pathClicked($event)"
            d="M354.49,768.35c-4.35,6.7-9.5,12.79-17.33,16c-6.69,2.75-13.14,2.9-19.6-0.42c-3.37-1.74-7-3.18-9.93-5.49
                c-2.06-1.62-4.04-4.35-4.44-6.84c-0.71-4.49-0.2-9.18-0.2-13.78h0.12c0-3.2,0.37-6.46-0.07-9.6c-1.05-7.49,2.62-11.7,9.1-14.22
                c10.69-4.16,21.55-5.59,32.59-1.53c1.49,0.55,3.27,1.88,3.8,3.27c2.9,7.65,5.88,15.33,7.91,23.23c
                C357.17,761.79,356.14,765.8,354.49,768.35z"/>
        <path id="RightKnee" severity="none" partId="21" class="body-part path11"
            (click)="pathClicked($event)"
            d="M212.02,786.55c-9.58,3.72-19.55,8.84-29.63,2.24c-6.09-3.99-11.32-9.35-16.65-14.39
                c-1.03-0.98-1.14-3.43-0.94-5.12c0.87-7.32,1.74-14.66,3.13-21.9c1.34-7.02,7.77-7.85,13.17-9.58c13.72-4.39,26.14-1.15,38.09,6.23
                c3,1.86,4.41,3.53,4.3,6.89c-0.12,3.99-0.03,7.99-0.03,11.99h-0.1c0,2-0.32,4.06,0.05,5.99
                C225.28,778.5,219.81,783.52,212.02,786.55z"/>
        <path id="RightUpperLeg" severity="none" partId="19" class="body-part path12" 
            (click)="pathClicked($event)"
            d="M254.88,535.44c-1.42,9.14-2.9,18.15-4.21,27.2c-2.69,18.59-5.78,37.14-7.82,55.8
                c-2.8,25.64-4.03,51.46-7.41,77.01c-1.85,13.98-6.72,27.57-10.25,41.33c-0.96,3.74-2.84,5.07-6.5,2.72
                c-2.17-1.4-4.67-2.29-6.93-3.56c-12.65-7.11-25.35-4.13-38.2,0.06c0.38-9.36,1.11-18.07,0.99-26.76
                c-0.23-16.77-0.35-33.57-1.6-50.28c-1.38-18.53-4.13-36.95-5.99-55.46c-1.73-17.29-3.36-34.6-4.35-51.94
                c-0.19-3.4,2.14-7.42,4.34-10.38c9.28-12.45,20.09-23.92,28.14-37.09c7.06-11.56,11.21-24.91,17.4-39.21
                c5.42,5.73,11.34,10.52,15.34,16.57c6.1,9.23,8.86,19.92,10.44,30.99C239.72,522.55,242.82,532.17,254.88,535.44z"/>
        <path id="LeftUpperLeg" severity="none" partId="18" class="body-part path13" 
            (click)="pathClicked($event)"
            d="M356.31,604.54c-3.33,34.19-9.24,68.14-9.76,102.59c-0.1,6.89,0.94,13.8,1.47,20.9
                c-20.2-3.24-31.36-3.02-46.29,7.4c-5.03-10.01-7.88-20.63-9.09-31.47c-2.12-18.86-2.78-37.89-5.07-56.72
                c-2.21-18.22-5.55-36.32-8.67-54.42c-2.65-15.34-5.05-30.78-8.85-45.85c-2.08-8.28-0.41-13.8,4.35-20.78
                c5.86-8.59,8.2-19.51,13.34-28.71c4.9-8.8,11.43-16.7,17.9-25.92c3.7,9.23,6.61,16.81,9.76,24.29c9.08,21.56,22.3,40.02,40.56,54.86
                c1.94,1.57,3.32,5.15,3.22,7.73C358.53,573.82,357.8,589.22,356.31,604.54z"/>
        <path id="LeftHip" severity="none" partId="15" class="body-part path14" 
            (click)="pathClicked($event)"
            d="M359.23,543.61c0.03,0.91-0.4,1.83-1.1,4.76c-7.94-8.49-15.82-15.32-21.68-23.57
                c-9.59-13.49-17.99-27.9-22.49-44.06c-3.28-11.78-6.87-23.53-4.07-36.17c1.08-4.87,3.58-6.86,7.97-7.37
                c9.34-1.07,17.95-3.72,24.95-11.1c-0.69,13.6,4.01,25.64,8.14,38.01c3.39,10.16,5.87,20.88,6.8,31.53
                C359.15,511.55,358.86,527.61,359.23,543.61z"/>
        <path id="Groin" severity="none" partId="17" class="body-part path15" 
            (click)="pathClicked($event)"
            d="M304.85,458.29c0.91,5.05,0.55,9.14-3.25,13.2c-12.14,12.99-21.61,27.68-27.07,44.77
                c-0.79,2.46-2.2,4.71-3.3,7.06c-3.15,6.73-8.75,7.83-15.5,7.38c-6.22-0.42-8.86-4.64-10.38-9.38c-1.76-5.46-2.22-11.32-3.46-16.96
                c-3.3-14.9-10.27-27.75-22.24-37.4c-5.62-4.53-8.42-9.51-7.13-16.96c0.65-3.8-0.29-7.89-0.53-12.29c1.17-0.15,2.51-0.57,3.8-0.45
                c7.29,0.65,14.55,1.69,21.86,2.12c14.93,0.89,29.88,1.69,44.83,2.07c5.11,0.13,10.27-1.04,15.39-1.74c6.73-0.92,8.4,1.23,6.93,7.61
                C303.99,450.8,304.22,454.72,304.85,458.29z"/>
        <path id="RightHip" severity="none" partId="16" class="body-part path16" 
            (click)="pathClicked($event)"
            d="M203.42,474.7c-5.48,11.3-9.31,23.52-15.72,34.23c-6.54,10.94-15.33,20.54-23.14,30.73
                c-0.64-0.36-1.28-0.72-1.91-1.08c-0.32-6.91-0.92-13.82-0.89-20.73c0.08-17.2-0.69-34.52,1.08-51.57
                c1.26-12.13,5.97-23.93,9.37-35.82c0.96-3.36,2.73-6.5,4.45-10.49c2.36,1.81,4.31,3.1,6.01,4.65c5.54,5.08,11.52,8.96,19.15,10.57
                c2.44,0.52,5.5,4.26,5.94,6.92C209.64,453.29,208.51,464.21,203.42,474.7z"/>
        <path id="RightLowerArm" severity="none" partId="8" class="body-part path17" 
            (click)="pathClicked($event)"
            d="M149.2,368.69c-9.52,38.84-25.18,74.28-52.35,102.99c-7.15-2.3-14.57-4.73-22-7.07
                c-3.88-1.22-5.69-2.86-3.51-7.37c4.25-8.82,8.31-17.77,11.77-26.93c3.86-10.24,6.52-20.93,10.51-31.11
                c4.27-10.91,9.49-21.45,14.29-32.14c0.59,0.18,1.17,0.37,1.76,0.56c0.96,2.38,1.75,4.85,2.92,7.13c2.7,5.26,8.71,8.02,14.12,5.75
                c5.87-2.45,11.37-5.79,17.02-8.76C145.6,370.75,147.44,369.68,149.2,368.69z"/>
        <path id="RightUpperArm" severity="none" partId="6" class="body-part path18" 
            (click)="pathClicked($event)"
            d="M181.43,256.22c-1.97,8.66-3.94,17.78-6.15,26.85c-1.37,5.61-3.18,11.11-4.67,16.69
                c-4.97,18.51-9.68,37.09-15.02,55.49c-0.93,3.21-4.04,6.22-6.87,8.35c-4.45,3.35-9.4,6.13-14.39,8.65
                c-3.31,1.68-7.04,3.28-10.63,3.41c-2.29,0.08-6.19-2.17-6.73-4.11c-1.28-4.64-1.66-9.76-1.22-14.58c0.69-7.54,2.98-14.95,3.5-22.49
                c0.94-13.63,7.46-25.23,12.39-37.43c2.57-6.37,3.21-13.87,9.73-18.08c5.68-3.68,11.36-7.38,17.25-10.7
                C166.19,264.02,173.96,260.14,181.43,256.22z"/>
        <path id="Abdomen" severity="none" partId="14" class="body-part path19" 
            (click)="pathClicked($event)"
            d="M329.64,430.16c-15.97,4.53-32.29,6.74-48.86,6.56c-18.74-0.2-37.6,0.35-56.17-1.68
                c-15.38-1.68-31.8-3.45-42.5-17.43c-1.5-1.95-2.9-5.12-2.4-7.27c3.9-16.64,8.28-33.18,12.47-49.57c16.48,8.54,33.65,6.93,51.02-2.45
                c6.26-3.38,13.8-5.63,20.85-5.91c5.54-0.22,11.51,2.8,16.78,5.4c11.22,5.54,23.14,7.47,35.35,7.82c3.76,0.11,7.57-1.69,11.9-2.75
                c0.41,3.53,0.3,7.16,1.3,10.45c3.28,10.68,6.98,21.22,10.47,31.84c0.62,1.89,1.08,3.85,1.5,5.81
                C343.31,420.15,338.76,427.57,329.64,430.16z"/>
        <path id="LeftLowerArm" severity="none" partId="7" class="body-part path20" 
            (click)="pathClicked($event)"
            d="M429.96,462.88c-6.7,4.27-13.66,8.72-21.33,13.61c-16.2-36.43-39.89-69.93-48.77-110.36
                c7.42,4.32,14.29,8.28,21.13,12.3c9.04,5.33,11.75,4.15,14.8-6.16c1.39-4.7,3.16-9.3,4.76-13.95c0.64-0.06,1.28-0.13,1.92-0.2
                C411.68,393.22,420.89,428.31,429.96,462.88z"/>
        <path id="LeftUpperArm" severity="none" partId="5" class="body-part path21" 
            (click)="pathClicked($event)"
            d="M391.8,369c-2.97,8.2-2.94,7.46-10.92,4.35c-19.34-7.52-26.37-22.59-28.4-41.91
                c-1.16-11.05-4.33-21.87-6.31-32.85c-1.37-7.64-2.5-15.34-3.27-23.06c-0.48-4.91-0.09-9.92-0.09-15.89
                c8.72,7.7,17.13,15.22,25.65,22.63c4.07,3.54,8.23,6.98,12.51,10.25c5.05,3.87,6.42,9.3,7.46,15.16
                c2.18,12.37,4.26,24.78,7.22,36.97C397.81,353.56,394.63,361.19,391.8,369z"/>
        <path id="Chest" severity="none" partId="13" class="body-part path22" 
            (click)="pathClicked($event)"
            d="M337.4,316.38c-2.59,10.47-4.93,21.03-6.73,31.66c-1.21,7.14-3.74,10.57-11.07,12
                c-11.02,2.14-21.38-1.03-31.68-4.22c-3.79-1.17-7.53-2.75-11.03-4.65c-10.54-5.71-21.05-4.65-30.95,0.83
                c-10.21,5.64-21.02,9.45-32.41,9.18c-6.98-0.17-13.9-3.93-20.79-6.27c-0.94-0.32-1.83-1.69-2.16-2.76
                c-3.83-12.38-7.68-24.76-11.25-37.22c-3.48-12.16-1.63-24.4,1.65-36.1c4.29-15.27,6.24-30.62,6.69-46.41
                c0.45-15.79,2.05-31.63,13.2-44.23c1.92-2.17,5.96-4.27,8.51-3.78c11.69,2.26,23.24,5.31,34.76,8.38c4.2,1.12,8.2,4.45,12.24,4.37
                c6.32-0.13,12.89-1.53,18.83-3.78c11.37-4.3,22.58-8.77,34.93-9.3c3.27-0.14,7.95-2.22,9.51-0.77c3.64,3.41,6.32,8.18,8.43,12.83
                c6.62,14.53,7.71,30.08,7.41,52.37c1.22,9.91,2.87,26.35,5.41,42.66C342.29,300.14,339.46,308.07,337.4,316.38z"/>
        <path id="LeftShoulder" severity="none" partId="3" class="body-part path23" 
            (click)="pathClicked($event)"
            d="M385.83,291.27c-11.72-10.13-22.43-19.35-33.09-28.64c-3.32-2.88-6.8-5.68-9.57-9.05
                c-1.61-1.95-2.52-4.9-2.75-7.49c-0.95-10.74-0.55-21.69-2.51-32.22c-1.69-9.03-5.87-17.61-9.07-26.34c-0.65-1.8-1.87-3.39-3.3-5.91
                c2.57-0.65,4.61-1.9,6.12-1.44c10.29,3.16,20.89,5.77,30.58,10.3c11.97,5.59,16.73,17.51,18.25,29.48
                c2.71,21.34,3.66,42.91,5.32,64.38C385.96,286.29,385.83,288.27,385.83,291.27z"/>
        <path id="RightShoulder" severity="none" partId="4" class="body-part path24" 
            (click)="pathClicked($event)"
            d="M197.33,182.41c-3.46,7.49-7.56,14.61-10.05,22.25c-2.37,7.28-3.33,15.06-4.42,22.69
                c-0.87,6.12-0.27,12.53-1.75,18.44c-0.86,3.42-3.99,7.04-7.09,8.96c-8.47,5.24-17.53,9.51-26.22,14.41
                c-4.27,2.4-8.23,5.34-12.8,8.35c-0.1-17.86-3.79-35.45,1.64-53.33c5.99-19.76,20.72-29.6,37.92-37.37
                C181.81,183.54,189.02,179.65,197.33,182.41z"/>
        <path id="Neck" severity="none" partId="2" class="body-part path25" 
            (click)="pathClicked($event)"
            d="M326.31,176.88c-16.55,1.38-31.93,3.83-46.6,9.58c-2.41,0.95-4.8,2.01-7.07,3.27
                c-8.17,4.54-16.44,3.15-24.3-0.3c-14.08-6.18-28.9-9.14-43.97-11.29c-2.69-0.38-5.32-1.08-9.25-1.9c6.9-4.58,12.48-8.73,18.47-12.18
                c12.58-7.25,17.27-19.21,18.95-32.42c0.62-4.83-1.99-10.07-2.94-14.32c6.44,5.15,13.17,11.08,20.47,16.2
                c5.43,3.81,11.29,4.44,17.02-0.57c6.24-5.44,12.93-10.37,18.77-15c0.44,8.56,0.72,17.71,1.47,26.81c0.59,7.24,5.5,12.04,11.07,15.68
                c7.67,5.03,15.85,9.3,23.8,13.9C323.34,175,324.45,175.72,326.31,176.88z"/>
        <path id="RightLowerArm" severity="none" partId="8" class="body-part path26" 
            (click)="pathClicked($event)"
            d="M905.44,486.35c3.16-5.53,7.62-10.96,12.64-14.36c3.31-2.24,7-3.42,10.86-3.8
                c-4.94-6.48-9.13-13.96-11.48-21.58c-5.28-17.17-8.52-34.97-12.85-52.45c-1.5-6.1-3.71-12.03-5.88-18.94
                c-10.76,10.68-22.34,17.4-36.68,20.64c1.52,3.54,2.85,7.04,4.47,10.39c12.63,26.17,25.02,52.46,38.08,78.41
                C904.89,485.22,905.16,485.79,905.44,486.35z"/>
        <path id="RightHand" severity="none" partId="12" class="body-part path27" 
            (click)="pathClicked($event)"
            d="M970.7,504.68c-0.15-0.58-0.53-1.06-1.06-1.35c-0.29-0.16-0.59-0.31-0.88-0.47c-0.56-0.3-1.12-0.59-1.66-0.91
                c0,0-0.01,0-0.01-0.01c-11.52-6.86-21.91-15.11-30.9-24.51c-0.28-0.29-0.63-0.5-1.03-0.6c-0.02-0.01-0.04-0.01-0.06-0.01
                c-4.86-1.16-11.06-1.99-15.87,1.93c-4.23,3.45-8.07,8.79-10.01,13.95c-0.31,0.83-0.58,1.71-0.82,2.68c-0.1,0.42-0.08,0.86,0.07,1.27
                c5.97,16.15,7.31,31.67,4.09,47.43c-0.28,1.38-0.21,2.71-0.14,4c0.03,0.51,0.05,1.01,0.05,1.52c0,1.2,0.97,2.17,2.17,2.17
                c0.47,0,0.95-0.01,1.42-0.02c1.09-0.02,1.99-0.84,2.11-1.92c0.15-1.36,0.28-2.73,0.41-4.1c0.29-3.14,0.57-6.11,1.16-9.04
                c0.42-2.05,1.18-4.06,1.99-6.17c1.74,1.95,3.52,3.94,5.23,5.92c0.01,0.05,0.01,0.11,0.02,0.16c0.03,0.21,0.06,0.42,0.11,0.61
                c0.41,1.88,0.82,3.77,1.23,5.65c0.9,4.15,1.83,8.45,2.82,12.67c0.3,1.26,0.8,2.39,1.28,3.48c0.2,0.46,0.41,0.92,0.59,1.38
                c0.33,0.84,1.14,1.37,2.01,1.37c0.1,0,0.2-0.01,0.3-0.02c0.99-0.14,1.76-0.94,1.86-1.93c0.43-4.38-0.23-8.22-0.88-11.93l-0.14-0.83
                c-0.2-1.16-0.41-2.31-0.61-3.47c-0.81-4.54-1.64-9.23-2.12-13.84c-0.05-0.5,0.5-1.67,1.08-2.28c0.57,0.15,1.47,0.6,1.7,0.89
                c2.22,3.05,4.3,6.05,5.36,9.24c1.62,4.88,2.91,10,4.16,14.94c0.53,2.11,1.06,4.22,1.62,6.32c0.28,1.07,1.33,1.75,2.43,1.58
                l0.98-0.16c1.05-0.17,1.83-1.07,1.83-2.14c0-0.44,0.02-0.89,0.05-1.33c0.06-1.12,0.13-2.39-0.14-3.67
                c-0.82-3.92-1.7-7.89-2.55-11.73c-1.16-5.26-2.36-10.7-3.44-16.06c-0.07-0.37-0.01-0.87,0.1-1.42c0.26,0.15,0.47,0.29,0.54,0.39
                c2.08,3.8,4.02,7.78,5.9,11.64c0.81,1.66,1.62,3.32,2.44,4.97c0.46,0.91,0.95,1.79,1.43,2.64c0.19,0.35,0.39,0.69,0.58,1.04
                c0.42,0.77,1.27,1.2,2.13,1.11c0.87-0.09,1.6-0.7,1.84-1.54c0.73-2.49,0.29-4.49-0.24-6.12c-1.06-3.23-2.13-6.46-3.19-9.69
                c-2.75-8.31-5.59-16.91-8.22-25.4c-0.18-0.59,0.03-1.64,0.24-2.66c1.12,0.1,2.17,0.2,2.79,0.56c2.1,1.25,4.07,2.69,6.16,4.22
                c1.43,1.05,2.91,2.13,4.44,3.14c1.06,0.69,2.24,0.69,3.19,0.69c0.01,0,0.01,0,0.02,0c0.26,0,0.52,0,0.76,0.02
                c0.82,0.09,1.64-0.31,2.07-1.03c0.3-0.49,0.59-0.96,0.93-1.5C970.76,505.87,970.86,505.26,970.7,504.68z"/>
        <path id="RightElbow" severity="none" partId="31" class="body-part path28" 
            (click)="pathClicked($event)"
            d="M894.31,372.16c-6.9,9.17-16.95,13.78-27.52,17.14c-4.19,1.33-7.83,0.09-9.09-5.11
                c-2.05-8.51-4.72-16.89-6.45-25.46c-0.5-2.5,0.52-6.12,2.18-8.09c6.76-8.07,15.89-11.74,28.25-12.74c1.04,0.91,3.89,2.26,4.9,4.46
                c3.33,7.24,6.26,14.71,8.75,22.29C896.06,366.85,895.66,370.36,894.31,372.16z"/>
        <path id="RightUpperArm" severity="none" partId="6" class="body-part path29" 
            (click)="pathClicked($event)"
            d="M883.32,329.29c-12.26,5.54-23.49,10.61-35.09,15.85c-4.63-26.1-9.47-52.97-11.17-80.39
                c-1.62-26.22-6.54-51.94-16.44-76.48c-1.25-3.09-2.27-6.27-3.74-10.36c13.52,5.36,26.41,10.02,38.91,15.57
                c10.11,4.5,13.67,14.29,16.14,24.12c1.7,6.75,2.57,13.76,3.23,20.72C878,268.63,880.63,298.96,883.32,329.29z"/>
        <path id="LowerBack" severity="none" partId="29" class="body-part path30" 
            (click)="pathClicked($event)"
            d="M836.79,445.83c-12.34-6.41-24.79-8.76-36.97-7.53c-11.86,1.19-23.39,5.72-35.55,8.91
                c-0.1-1.92-0.28-3.87-0.29-5.81c-0.06-33.24,0.06-66.48-0.3-99.72c-0.07-6.1,2.92-7.87,7.78-9.28c14.15-4.13,28.37-8.14,42.3-12.96
                c6.07-2.1,11.49-6.06,18.21-9.72c-1.24,5.13-2.54,9.29-3.23,13.56c-2.23,13.79-4.53,27.57-6.24,41.43
                c-1.43,11.57,3.41,21.94,7.68,32.3c4.58,11.1,6.41,22.61,5.88,34.61C835.87,435.94,836.48,440.29,836.79,445.83z"/>
        <path id="LowerBack" severity="none" partId="29" class="body-part path31" 
            (click)="pathClicked($event)"
            d="M745.84,446.7c-28.44-10.07-56.17-13.2-85.06,2.92c3.55-10.02,6.27-18.18,9.3-26.23
                c7.8-20.65,15.64-41.47,15.21-63.84c-0.2-10.33-3.46-20.72-6.13-30.87c-2.16-8.21-6.86-15.83-5.02-25.81
                c3.55,2.31,6.79,4.14,9.72,6.36c17,12.87,35.48,22.41,56.83,25.48c4.15,0.6,3.67,3.61,3.72,6.33c0.51,26.23,1,52.46,1.41,78.69
                C745.95,428.48,745.84,437.23,745.84,446.7z"/>
        <path id="LeftUpperArm" severity="none" partId="5" class="body-part path32" 
            (click)="pathClicked($event)"
            d="M691.46,179.1c-1.49,4.7-2.71,7.87-3.48,11.14c-4.65,19.58-10.57,38.99-13.45,58.83
                c-4.6,31.85-9.95,63.44-19.26,94.28c-0.38,1.24-1.17,2.35-2.05,4.09c-10.08-8.3-21.25-11.56-33.77-10.84
                c-1.01,0.05-3.53-3.94-3.12-5.45c2.29-8.45,4.34-17.17,8.2-24.94c7.17-14.44,6.99-29.54,5.32-44.65
                c-1.75-15.79,0.09-30.73,6.73-45.03c4.5-9.69,12.83-15.89,21.74-20.91C668.54,189.86,679.32,185.09,691.46,179.1z"/>
        <path id="LeftElbow" severity="none" partId="30" class="body-part path33" 
            (click)="pathClicked($event)"
            d="M650.57,357.79c-3.49,8.51-6.36,17.27-9.95,25.74c-0.85,2-3.56,4.63-5.36,4.59
                c-10.35-0.25-20.76-0.79-30.25-5.8c-4.39-2.32-5.52-5.12-2.58-9.65c5.19-8.02,10.57-15.93,11.91-25.89
                c0.57-4.29,2.91-4.87,10.83-4.91c8.41,0.25,16.06,2.78,22.92,7.67C651.02,351.63,652.11,354.04,650.57,357.79z"/>
        <path id="LeftHand" severity="none" partId="11" class="body-part path34" 
            (click)="pathClicked($event)"
            d="M592.59,474.21c-0.56-0.54-1.1-1.01-1.65-1.44c-11.33-8.85-23.08-3.63-30.92,1.06
                c-0.34,0.21-0.63,0.5-0.82,0.85c-3.76,6.66-9.1,11.35-16.81,14.74c-5.02,2.2-9.5,5.53-13.84,8.76c-1.88,1.4-3.82,2.84-5.75,4.15
                c-0.97,0.66-1.29,1.94-0.75,2.98c0.98,1.87,2.79,1.36,5.79,0.52c1.79-0.5,4.06-1.2,6.06-1.81c1.09-0.34,2.12-0.65,2.95-0.9
                c4.19-1.24,5.61-0.96,6.09-0.68c0.97,0.56,1.18,0.68-0.39,3.47c-0.32,0.58-0.66,1.18-0.93,1.79c-3.67,8.15-7.45,16.42-11.11,24.41
                c-1.53,3.34-3.06,6.69-4.58,10.03c-0.47,1.03-0.12,2.25,0.83,2.88c0.95,0.63,2.21,0.47,2.97-0.37c2.51-2.74,4.32-5.79,6.08-8.74
                c0.82-1.37,1.59-2.67,2.43-3.94c0.6-0.92,1.61-1.72,2.71-2.55c0.48,1.31,0.88,2.54,0.75,3.47c-0.34,2.55-1.19,5.08-2.08,7.75
                c-0.45,1.34-0.91,2.72-1.31,4.12c-0.86,3.03-1.68,6.12-2.48,9.11c-0.42,1.55-0.83,3.1-1.25,4.65c-0.32,1.18,0.34,2.41,1.51,2.8
                c3.46,1.13,8.27-7.56,8.31-7.65c0.95-1.98,1.88-7.65,2.81-9.63c1.94-4.12,2.1-4.68,4.18-8.79c0.24-0.46,2.51-0.93,3.02-1.43
                c0.42,0.47,0.9,1.04,0.96,1.26c0.11,6.8-0.29,13.87-0.65,20.12c-0.13,2.29-0.43,4.55-0.75,6.94c-0.15,1.12-0.3,2.25-0.43,3.37
                c-0.14,1.24,0.73,2.38,1.97,2.55c0.14,0.02,0.27,0.03,0.4,0.03c1.86,0,2.78-2.01,4.01-5.21c0.91-2.34,1.74-4.97,2.13-6.7
                c0.3-1.36,0.6-2.73,0.89-4.09c1.07-5,2.19-10.17,3.74-15.04c0.19-0.59,1.52-1.5,2.5-2.17c0.03-0.02,0.05-0.04,0.08-0.05
                c0.74,1.02,1.48,2.07,1.59,2.89c0.56,3.87,0.8,7.91,1.03,11.82c0.09,1.57,0.18,3.14,0.29,4.71c0.09,1.22,1.09,2.13,2.34,2.14
                c1.17-0.02,2.77-0.05,3.72-7.78c0.2-1.67,0.39-3.34,0.58-5.01c0.62-5.47,1.26-11.13,2.34-16.57c0.71-3.59,1.72-7.23,2.69-10.76
                c1.51-5.48,3.07-11.14,3.66-16.91c0.81-7.96,2.65-15.35,5.63-22.6C593.49,475.87,593.27,474.87,592.59,474.21z"/>
        <path id="LeftLowerArm" severity="none" partId="7" class="body-part path35" 
            (click)="pathClicked($event)"
            d="M596.86,382.2c-2.34,5.5-4.53,9.96-6.17,14.6c-3.97,11.28-7.38,22.77-11.6,33.95
                c-4.25,11.22-9.06,22.23-13.51,33.37c1.25-0.39,2.52-0.71,3.84-0.95c8.81-1.6,18.58,0.08,25.91,5.37c0.31,0.23,0.61,0.46,0.91,0.69
                c2.11-3.86,4.5-7.63,7.18-11.31c8.23-11.33,16.27-22.92,23.03-35.14c4.64-8.38,6.96-18.05,10.37-27.3
                C622.61,391.5,608.13,393.11,596.86,382.2z"/>
        <path id="Glutes" severity="none" partId="33" class="body-part path36" 
            (click)="pathClicked($event)"
            d="M845.21,533.53c-16.98,7.37-34.11,14.25-53.1,12.39c-7.67-0.75-15.34-2.6-22.68-4.97
                c-3.14-1.01-5.38-4.68-8.11-7.05c-2.01-1.74-4.09-4.46-6.27-4.61c-1.68-0.11-4.55,2.84-5.16,4.94c-1.27,4.35-4.24,6.1-7.97,7.3
                c-21.62,6.94-43.48,8.26-65.61,2.64c-4.46-1.13-8.85-2.51-13.34-3.52c-3.44-0.78-5-2.5-5.11-6.19c-0.31-10.4-0.94-20.79-1.57-33.81
                c0.81-11.41,1.59-25.38,2.96-39.3c0.25-2.49,2.39-5.49,4.53-6.98c15.43-10.72,33.16-11.66,50.78-9.48
                c11.34,1.4,22.59,5.04,33.32,9.13c6.62,2.51,12.02,1.98,18.25-0.25c9.7-3.47,19.47-7.23,29.55-9.06c14.48-2.64,29.29-1.22,41.32,7.7
                c4.92,3.65,8.25,11.56,9.23,18c2.68,17.54,3.69,35.33,5.44,53.01C852.18,528.59,849.89,531.5,845.21,533.53z"/>
        <path id="Spine" severity="none" partId="32" class="body-part path37" 
            (click)="pathClicked($event)"
            d="M757.47,448.4c-0.17,0.82-1.13,2.06-1.72,2.06c-1.05,0-2.6-0.56-3.01-1.36c-0.68-1.34-0.85-3.06-0.88-4.62
                c-0.81-42.68-1.62-85.35-2.34-128.03c-0.91-53.51-1.7-107.01-2.69-160.51c0,0,5.27-4.24,9.93,0c-0.07,0.46-0.13,0.92-0.13,1.38
                c0.18,55.13,0.55,110.26,0.79,165.39c0.17,40.3,0.22,80.6,0.31,120.91C757.73,445.22,757.8,446.86,757.47,448.4z"/>
        <path id="LeftUpperLeg" severity="none" partId="18" class="body-part path38" 
            (click)="pathClicked($event)"
            d="M746.72,545.71c-1.16,8.88-2.16,16.96-3.29,25.02c-1.2,8.52-2.67,17-3.66,25.54
                c-0.07,0.64-36.15,40.26-79.03,0.89l-0.02,0.06c0.21-0.7-1.68-29.62-2.65-43.39c-0.18-2.48-0.03-4.98-0.03-7.06
                c15.41,2.01,29.76,4.4,44.21,5.63C717.09,553.65,731.58,550.61,746.72,545.71z"/>
        <path id="RightUpperLeg" severity="none" partId="19" class="body-part path39" 
            (click)="pathClicked($event)"
            d="M850.38,588.14c-0.09,3.4-0.59,6.8-0.47,10.19c-39.46,37.31-76.2-0.17-76.2-0.17l-0.04,0.07
                c0.25-0.61-2.48-15.06-3.69-21.75c-1.83-10.08-3.42-20.21-5.32-31.56c30.72,13.09,58.77,5.81,86.26-8.29
                c0.33,1.37,0.69,2.12,0.67,2.86C851.22,555.71,850.83,571.93,850.38,588.14z"/>
        <path id="UpperBack" severity="none" partId="28" class="body-part path40" 
            (click)="pathClicked($event)"
            d="M732.26,327.38c-21.48-4.26-39.2-15.61-55.31-29.82c-1.39-1.23-2.43-3.84-2.26-5.69
                c1.04-11.89,2.54-23.74,3.75-35.61c2.43-23.82,8.4-46.79,16.14-69.38c3.04-8.86,6.58-16.8,15.54-21.58
                c4.08-2.17,7.24-6.25,10.39-9.84c0.35-0.4,1.7-1.64,1.75-1.67c19.05-10.8,19.61,2.07,19.61,2.07c-0.2,0.52-0.01,1.19,0,1.79
                c0.86,53.11,1.76,106.22,2.55,159.33C744.6,329.73,744.41,329.79,732.26,327.38z"/>
        <path id="UpperBack" severity="none" partId="28" class="body-part path40" 
            (click)="pathClicked($event)"
            d="M821.64,309.98c-10.67,4.95-21.93,8.7-33.1,12.45c-7.8,2.62-15.91,4.35-25.73,6.97
                c0-18.92,0.13-36.12-0.02-53.31c-0.36-39.5-0.81-78.99-1.42-118.47c-0.01-0.57-0.11-1.13-0.25-1.69c0-0.01,0-0.01,0-0.02
                c6.05-25.79,20.89-5.17,20.89-5.17c0.98,1.78,1.56,4.02,3.01,5.25c5.75,4.94,11.36,10.23,17.82,14.05c4.32,2.56,7,5.55,8.94,9.88
                c6.94,15.41,11.96,31.4,14.71,48.08c1.97,12,3.94,24,5.6,36.04c0.95,6.93,0.94,13.98,1.98,20.89
                C836.11,298.48,833.99,304.26,821.64,309.98z"/>
        <path id="Head" severity="none" partId="1" class="body-part path42" 
            (click)="pathClicked($event)"
            d="M297.03,66.84c0.2-1.84,0.54-3.68,0.59-5.54c0.38-14.33,3.47-28.52-9.5-40.69
                c-12.39-11.62-36.09-14.14-50.18-6.01c-12.09,6.97-16.68,18.69-17.73,31.61c-0.55,6.8,1.28,13.75,1.94,20.68l-0.16,0.01
                c-2.8,0.19-4.84,2.72-4.42,5.5l1.32,8.77c0.26,1.76,1.48,3.23,3.17,3.82l0.97,0.34c3.19,8.48,2.74,19.42,7.75,25.63
                c5.08,6.29,11.59,12.15,19.19,17.1c4.95,3.23,9.86,4.86,15.47,0.75c11.02-8.1,18.95-12.13,25.86-24.24
                c2.04-3.58,1.95-16.35,3.89-19.98l1.68-0.58c1.7-0.59,2.92-2.08,3.17-3.86l1.11-7.88C301.53,69.57,299.62,67.17,297.03,66.84z"/>
    </svg>

    <div class="legend border d-flex flex-column justify-content-center align-items-center">
        <p class="h3">Injury Legend</p>
        <ul>
            <li><span class="white">White: </span> No Injury</li>
            <li><span class="yellow">Yellow: </span> Minor (1 Click)</li>
            <li><span class="red">Red: </span> Severe (2 Clicks)</li>
        </ul>
    </div>

</div>