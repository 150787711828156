import { Injectable } from '@angular/core';
import { ApiService } from '@services/api/api.service';
import { UserPreferencesService } from '@services/user/user-preferences.service';
import { DashboardQueriesService } from '@services/dashboard-queries/dashboard-queries.service';
import { APIEndpoints } from '@models/api/Endpoints';
import { CustomCardModel } from '@models/components/card.model';
import { ClickEventArgs } from '@syncfusion/ej2-buttons';
import { Deposit, Invoice, InvoiceRow } from '@models/data-contracts';

@Injectable({
  providedIn: 'root'
})
export class DashboardCardsService {
  constructor(
    private api: ApiService,
    private user: UserPreferencesService,
    private queries: DashboardQueriesService
  ) {}

  private getUserId(): number {
    return localStorage.getItem('userId') ? parseInt(localStorage.getItem('userId')!) : 0;
  }

  createAppointmentsCard(): CustomCardModel {
    return {
      cardTitle: 'Upcoming Appointments',
      cardId: 'scheduler',
      row: 0,
      column: 0,
      gridSettings: {
        dataSource: this.api.getOdata(APIEndpoints.Appointments),
        query: this.queries.getAppointmentsQuery(this.getUserId()),
        allowFiltering: false,
        allowPaging: false,
        columns: [
          { field: 'Id', isPrimaryKey: true, visible: false },
          { field: 'CaseFile.FileNumber', headerText: 'File Number', visible: true },
          {
            field: 'FullName',
            headerText: 'Client Name',
            visible: true,
            valueAccessor: (field: string, data: any) => this.concatName(data.CaseFile)
          },
          { field: 'ProcedureCode.Modality', headerText: 'Modality', visible: true },
          { field: 'ProcedureCode.Description', headerText: 'Description', visible: true },
          { field: 'StartDatetime', headerText: 'Start', visible: true },
          { field: 'Provider.Name', headerText: 'Provider', visible: true }
        ]
      }
    };
  }

  createTasksCard(handleToolbarClick: (args: ClickEventArgs) => void): CustomCardModel {
    return {
      cardTitle: 'Tasks',
      cardId: 'tasks',
      row: 0,
      column: 2,
      gridSettings: {
        dataSource: this.api.getOdata(
          this.user.isAdmin() ? APIEndpoints.CaseFileTasks : APIEndpoints.XrefUsersCaseFileTask
        ),
        query: this.queries.getUserTasksQuery(this.getUserId()),
        allowFiltering: false,
        allowPaging: false,
        toolbar: ['All', '0 Overdue', '0 Upcoming'],
        toolbarClick: handleToolbarClick,
        columns: this.getTaskColumns()
      }
    };
  }

  createCaseFilesCard(): CustomCardModel {
    return {
      cardTitle: 'Case Files',
      cardId: 'case-files',
      row: 1,
      column: 0,
      gridSettings: {
        dataSource: this.api.getOdata(APIEndpoints.Casefiles),
        query: this.queries.getCaseFilesQuery(this.getUserId()),
        allowFiltering: false,
        allowPaging: false,
        columns: [
          { field: 'Id', isPrimaryKey: true, visible: false },
          { field: 'FileNumber', headerText: 'File', visible: true },
          {
            field: 'FullName',
            headerText: 'Client Name',
            visible: true,
            valueAccessor: (field: string, data: any) => this.concatName(data)
          },
          { field: 'Patient.Dob', headerText: 'DOB', visible: true },
          { field: 'LawFirm.Name', headerText: 'Law Firm', visible: true },
          { field: 'RecordStatus.Description', headerText: 'Status', visible: false },
          {
            field: 'CaseFileTasks',
            headerText: 'Next Activity',
            visible: true,
            valueAccessor: (field: string, data: any) => this.getDateOfNextActivity(field, data)
          },
          { field: 'DateOfLoss', headerText: 'Date of Loss', visible: false }
        ]
      }
    };
  }

  createRoicOverviewCard(): CustomCardModel {
    return {
      cardTitle: 'ROIC Overview',
      cardId: 'roic-overview',
      row: 1,
      column: 2,
      gridSettings: {
        dataSource: this.api.getOdata(APIEndpoints.Casefiles),
        query: this.queries.getRoicOverviewQuery(),
        allowFiltering: false,
        allowPaging: false,
        columns: [
          { field: 'Id', isPrimaryKey: true, visible: false },
          { field: 'FileNumber', headerText: 'File', visible: true },
          {
            field: 'FullName',
            headerText: 'Patient',
            visible: true,
            valueAccessor: (field: string, data: any) => this.concatName(data)
          },
          {
            field: 'ROIC',
            headerText: 'ROIC',
            visible: true,
            valueAccessor: (field: string, data: any) => this.calculateRoic(data).formattedRoic
          }
        ],
        sortSettings: {
          columns: [{ field: 'ROIC', direction: 'Descending' }]
        }
      }
    };
  }

  private getTaskColumns() {
    return this.user.isAdmin()
      ? [
          { field: 'Id', isPrimaryKey: true, visible: false },
          { field: 'CaseFile.FileNumber', headerText: 'File Number', visible: true },
          { field: 'TaskDescription', headerText: 'Description' }
        ]
      : [
          { field: 'CaseFileTask.Id', isPrimaryKey: true, visible: false },
          { field: 'CaseFile.FileNumber', headerText: 'File Number', visible: true },
          { field: 'CaseFileTask.TaskDescription', headerText: 'Description' }
        ];
  }
  
  private getDateOfNextActivity(field: string, data: Record<string, ActivityItem[]>): string {
    if (!data[field] || !Array.isArray(data[field])) {
      console.warn(`No valid data found for field: ${field}`);
      return '';
    }

    const items = data[field];
    const closestDate = items.reduce<Date | null>((closest, item) => {
      if (!item.NextDateOfActivity) return closest;
      const date = new Date(item.NextDateOfActivity);
      return !closest || date > closest ? date : closest;
    }, null);

    if (!closestDate) return '';

    return `${closestDate.toLocaleDateString("en-GB").replace(/\//g, '-')} ${
      closestDate.toLocaleTimeString("en-US", { 
        hour: '2-digit', 
        minute: '2-digit', 
        hour12: true 
      })
    }`;
  }

  private capitalize(str: string): string {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  private concatName(data: any): string {
    const firstName = this.capitalize(data?.Patient?.Firstname || '');
    const lastName = this.capitalize(data?.Patient?.Lastname || '');
    return `${firstName} ${lastName}`.trim();
  }

  private calculateRoic(data: any): { roic: number, formattedRoic: string } {
    const invoices = data.Invoices || [];
    const deposits = data.Deposits || [];

    const totalCost = invoices.reduce((accumulator: number, invoice: Invoice) => {
      if (invoice.InvoiceRows) {
        invoice.InvoiceRows.forEach((row: InvoiceRow) => {
          if (row.AmountBilled) {
            accumulator += row.AmountBilled;
          }
        });
      }
      return accumulator;
    }, 0);

    const totalRevenue = deposits.reduce((accumulator: number, deposit: Deposit) => {
      if (deposit.DepositAmount) {
        accumulator += deposit.DepositAmount;
      }
      return accumulator;
    }, 0);

    const roic = totalCost > 0 ? ((totalRevenue - totalCost) / totalCost) * 100 : 0;
    const formattedRoic = new Intl.NumberFormat('en-US', {
      style: 'percent',
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    }).format(roic / 100);

    return { roic, formattedRoic };
  }

}

interface ActivityItem {
  NextDateOfActivity?: string;
}
