import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileViewService } from '@root/src/app/shared/services/file-view/file-view.service';
import { signal, WritableSignal } from '@angular/core';
import { GlobalsService } from '@services/globals/globals.service';
import { StatusComponent } from '@ui/status/status.component';
import { PatientInfoComponent } from '@ui/patient-info/patient-info.component';
import { ItemRenderComponent } from '@root/src/app/components/features/case-file/item-render/item-render.component';
import { BottomNavComponent } from '@ui/bottom-nav/bottom-nav.component';

@Component({
  selector: 'item-view',
  standalone: true,
  imports: [
    CommonModule,
    StatusComponent,
    ItemRenderComponent, 
    BottomNavComponent
  ],
  templateUrl: './item-view.component.html',
  styleUrls: ['./item-view.component.scss']
})
export class ItemViewComponent implements OnInit {
  constructor(private fileView: FileViewService, private globals: GlobalsService) {}
  @Input() data: any;
  @Input() hasMultiple: boolean;
  openFileData: WritableSignal<any> = signal(null)
  title: WritableSignal<string> = signal("");
  bottomNavItems: any;
  isMobile = this.fileView.isMobileMode


  public isEditing = false;
  public urlInfo = this.fileView.getUrlInfo();

  ngOnInit() {
    if(this.data) {
      this.openFileData.set(this.data)
      this.title.set(this.globals.getName(this.data))
    }

    if(!this.hasMultiple && !this.data){
      this.fileView.fetchData(this.openFileData, this.title);
      this.bottomNavItems = this.fileView.tabs.filter(item => item.text !== "My Files");
    }
  }
}
