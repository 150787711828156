// Angular
import { Component, Output, EventEmitter, Input, ViewChild, WritableSignal, signal } from '@angular/core';
import { ReactiveFormsModule, FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';

// 3rd Party
import { GridModule, PageService, SortService, FilterService, GroupService } from '@syncfusion/ej2-angular-grids';
import { GridModel } from '@syncfusion/ej2-grids';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { createSpinner } from '@syncfusion/ej2-angular-popups';
import { showSpinner, hideSpinner } from '@syncfusion/ej2-popups';
import { ChangeEventArgs, DropDownListModule, DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { TextBoxModule, TextBoxComponent, MaskedTextBoxModule, NumericTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { RichTextEditorAllModule, ToolbarService, LinkService, ImageService, HtmlEditorService } from '@syncfusion/ej2-angular-richtexteditor';
import { Query } from '@syncfusion/ej2-data';

// Internal
import { environment } from '@root/src/environments/environment.production';
import { states } from '@models/global-vars';
import { BoxSignTags } from '@models/components/box-sign.model';
import { APIEndpoints, BoxEndpoints } from '@models/api/Endpoints';
import { BoxService } from '@services/box/box.service';
import { ApiService } from '@services/api/api.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { LoadingModule } from '@modules/loading.module';
import { RichTextEditorComponent } from '../../../ui/custom-rich-text-editor/rich-text-editor.component';
import { FileHubService } from '@root/src/app/features/file-hub/services/file-hub.service';
import { CaseFile } from '@root/src/app/shared/models/data-contracts';

@Component({
  selector: 'box-sign',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    DropDownListModule,
    TextBoxModule,
    RichTextEditorAllModule,
    DatePickerModule,
    MaskedTextBoxModule,
    NumericTextBoxModule,
    ButtonModule,
    GridModule,
    LoadingModule,
    CommonModule,
    RichTextEditorComponent,
  ],
  templateUrl: './box-sign.component.html',
  styleUrl: './box-sign.component.scss',
  providers: [
    ToolbarService,
    LinkService, 
    ImageService, 
    HtmlEditorService,
    PageService,
    SortService,
    FilterService,
    GroupService
  ]
})
export class BoxSignComponent {

  /**
   * ~~ NOTICE ~~
   *  Connection can be tested temporarily by creating a developer token 
   *  here -> https://app.box.com/developers/console/app/2268127/configuration
   *  @see {@link environment}
   *  Copy generated token above and paste into BoxDeveloperToken value 
   *  in both of the files found in @environments/ folder
   */

  constructor (
    private toast: ToastMessageService,
    private box: BoxService,
    private api: ApiService,
    private fileHub: FileHubService
  ) {}
  
  @Input() caseFileId: any;
  @Input() fileNumber?: string | null;
  @Output() folder = new EventEmitter<any>();
  @Output() template = new EventEmitter<any>();
  @Output() formValues = new EventEmitter<any>();
  @ViewChild('signTemplates') signTemplates: DropDownListComponent;
  @ViewChild('emailSubject') emailSubject: TextBoxComponent;
  @ViewChild('emailMessage') emailMessage: RichTextEditorComponent;

  selectedTemplate: WritableSignal<any> = signal({});
  selectedTemplateInfo: WritableSignal<any> = signal({});
  loadingData: WritableSignal<any> = signal(false);
  folders: any;
  templatesList: any;
  requestsList: any;
  providersList: any;
  contactsList: any;
  caseManagersList: any;
  statesList: any;
  requestsGridLoading: boolean = true;
  requestsGrid: GridModel;
  selectedTemplateSignRequest: any;
  environment = environment;
  boxSignForm: FormGroup = new FormGroup({
    boxSignTemplateId: new FormControl(undefined, [Validators.required]),
    emailSubject: new FormControl(undefined, [Validators.required]),
    emailMessage: new FormControl(undefined, [Validators.required]),
    fileNumber: new FormControl(undefined, [Validators.required]),
  });
  richTextEditorTools: object = {
    type: 'MultiRow',
    items: [
      'Bold', 'Italic', 'Underline', 'StrikeThrough',
      'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
      'LowerCase', 'UpperCase', '|',
      'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
      'Outdent', 'Indent', '|',
      'CreateLink', 'Image', '|', 'ClearFormat', 'Print',
      'SourceCode', 'FullScreen', '|', 'Undo', 'Redo'
    ]
  };

  // Add new properties to store data
  caseFileData: any;
  patientData: any;
  lawFirmData: any;

  ngOnInit() {
    this.getStates();
    this.getTemplates();
    this.loadSignRequests();
    this.loadCaseFileData();
    this.getProviders();
  }

  async loadCaseFileData() {
    
    // Create a query that expands all necessary relationships with proper nesting
    const query = new Query()
      .expand('Patient($expand=XrefAddressPatients($expand=Address($expand=StateNavigation)),XrefPhonePatients($expand=Phone)),AttorneyNavigation,CaseManagerNavigation,LawFirmContactNavigation,PAndLNavigation,StatusingGroupNavigation,RecordStatus');

    try {
      const response: any = await this.api.getOdata(`${APIEndpoints.Casefiles}(${this.caseFileId})`).executeQuery(query);

      if (response?.result?.[0]) {
        const caseFileData = response.result[0];
        this.caseFileData = caseFileData;
        
        // Update each property individually
        Object.entries(caseFileData).forEach(([key, value]) => {
          this.fileHub.updateCaseFile(key, value);
        });
        
        // Store local references
        this.patientData = this.fileHub.caseFile?.Patient;
        this.lawFirmData = {
          attorney: this.fileHub.caseFile?.AttorneyNavigation,
          caseManager: this.fileHub.caseFile?.CaseManagerNavigation,
          contact: this.fileHub.caseFile?.LawFirmContactNavigation
        };

      } else {
        throw new Error('No case file data found');
      }
    } catch (error) {
      console.error('Error loading case file data:', error);
    }
  }

  async getTemplates() {
    await this.api.fetchRequest(`${BoxEndpoints.Templates}(${this.caseFileId})`, undefined, undefined, undefined, false).then(templates => this.templatesList = templates );

    // Check if test template exists
    const hasTestTemplate = this.templatesList.some((template: any) => template.id === 35);
    
    // Add test template if it doesn't exist
    if (!hasTestTemplate && !this.environment.production) {
      this.templatesList.push({
        id: 35,
        templateName: "(CaseCompass Test) Varent Single Case Agreement - Established provider - [provider].pdf",
        emailSubject: "Varent Single Case Agreement for [provider]",
        emailBody: "Please find the attached Single Case Agreement for our mutual patient. If you have any questions, please contact your Case Manager. We look forward to working with you! Sincerely, \nVarent\n 855-367-9742",
        boxTemplateId: "fd2289fa-ab8e-4f99-9273-ce24b0200397"
      });
    }
  }

  async getProviders() {
    try {
      const query = new Query()
        .expand('XrefProviderContacts($expand=Contact),CareCoordinatorNavigation')
        .where('IsActive', 'equal', true)
        .sortBy('Name', 'ascending');
      
      const response: any = await this.api.getOdata(APIEndpoints.Providers).executeQuery(query);
      // Transform the data to include care coordinator name
      this.providersList = response.result.map((provider: any) => ({
        ...provider,
        CareCoordinatorName: provider.CareCoordinatorNavigation?.Name || ''
      }));
    } catch (error) {
      console.error('Error loading providers:', error);
      this.toast.showError('Failed to load providers');
    }
  }

  // Add method to handle provider selection and load contacts
  async onProviderChange(event: any) {
    const providerId = event.value;
    if (providerId) {
      // Clear existing provider contact value
      const requestDataForm = this.boxSignForm.get('requestData') as FormGroup;
      const providerContactControl = requestDataForm?.get('provider_contact');
      if (providerContactControl) {
        providerContactControl.setValue('');
      }

      // Update care coordinator value if the field exists
      const careCoordinatorControl = requestDataForm?.get('care_coordinator');
      if (careCoordinatorControl) {
        const selectedProvider = this.providersList?.find((p: any) => p.Id === providerId);
        careCoordinatorControl.setValue(selectedProvider?.CareCoordinatorName || '');
      }
      
      // Load contacts for selected provider with proper expansion
      try {
        const query = new Query()
          .where('Id', 'equal', providerId)
          .expand('XrefProviderContacts($expand=Contact)');
        
        const response: any = await this.api.getOdata(APIEndpoints.Providers).executeQuery(query);
        if (response?.result?.[0]?.XrefProviderContacts) {
          // Transform the data to get just the contacts with their info and sort them
          this.contactsList = response.result[0].XrefProviderContacts
            .map((xref: any) => ({
              Id: xref.Contact.Id,
              ContactName: xref.Contact.ContactName,
              ContactEmail: xref.Contact.ContactEmail
            }))
            .filter((contact: any) => contact.ContactName && contact.ContactEmail) // Only include contacts with name and email
            .sort((a: any, b: any) => a.ContactName.localeCompare(b.ContactName)); // Sort alphabetically by ContactName
        }
      } catch (error) {
        console.error('Error loading provider contacts:', error);
        this.toast.showError('Failed to load provider contacts');
      }
    } else {
      this.contactsList = null;
    }
  }

  getContacts() {
    this.contactsList = this.api.getOdata(APIEndpoints.Contacts);
  }

  getStates() {
    this.statesList = states;
  }

  // Emit changes when Sign Template selected
  async emitDropDownSelect(args: ChangeEventArgs) {
    this.loadingData.set(true);
    try {
      this.boxSignForm.reset();
      this.template.emit(args.itemData);
      this.selectedTemplate.set(args.itemData);

      // Ensure states are loaded
      if (!this.statesList) {
        this.getStates();
      }

      // Load template info
      const templateInfo = await this.api.fetchRequest(`${BoxEndpoints.Templates}/info/${this.selectedTemplate().id}`);
      this.selectedTemplateInfo.set(templateInfo);

      // Build form with the template info
      this.boxSignForm = this.buildForm((templateInfo as any).boxSignTags);
      
    } catch (error) {
      console.error('Error in template selection:', error);
      this.toast.showError('Error loading template data');
    } finally {
      this.loadingData.set(false);
    }
  }

  initializeSpinner(elementId: string) {

    createSpinner({
      target: document.getElementById(elementId) as any
    });

    showSpinner((document as any).getElementById(elementId));
  }

  endSpiner(elementId: string) {
    hideSpinner((document as any).getElementById(elementId));
  }

  noRecordSpinner(args: any) {

    if (args.name === 'beforeOpen') {

      // Check for populated values and set timeout to ensure #noRecordsTemplate visible
      if (!this.templatesList) {
        setTimeout(() => {
          this.initializeSpinner('no-record');
        }, 1);
      } else {
        setTimeout(() => {
          this.endSpiner('no-record');
        }, 1)
      }
    }
  }

  async submitForm(args: SubmitEvent) {
    this.loadingData.set(true);
    
    this.boxSignForm.patchValue({
      boxSignTemplateId: this.signTemplates.value,
      emailSubject: this.emailSubject.value,
      emailMessage: this.selectedTemplate().emailBody,
      fileNumber: this.fileNumber
    });
    
    this.markAllFieldsAsTouched();
    
    if (this.boxSignForm.invalid) {
      this.toast.showError('All fields required!');
      this.loadingData.set(false);
      return;
    }

    this.formValues.emit(this.boxSignForm.value);
    
    if (this.signTemplates.value === 35 && this.fileNumber) {
      await this.box.postNewSignRequest(this.boxSignForm.value);
      this.loadingData.set(false);
    } else {
      const fileNumberError = !this.fileNumber ? `File Number missing.<br>` : ''
      const signTemplateError = this.signTemplates.value !== 35 ? `While testing, we may only submit Sign Requests using the \"<i>(Case Compass Test)</i>\" template. Please click dropdown and select test template before re-submitting.` : '';
      const errorMsg = `
        <strong>SIGN TEMPLATE ERROR</strong>
        <br>
        ${fileNumberError}
        ${signTemplateError}
      `;
      this.toast.showError(errorMsg);
      this.loadingData.set(false);
    }
  }

  markAllFieldsAsTouched(): void {
    // Mark top-level controls
    Object.values(this.boxSignForm.controls).forEach((control: any) => {
        control.markAsTouched();
        control.markAsDirty();
        control.updateValueAndValidity();
        
        // If this is the requestData group, mark its controls too
        if (control instanceof FormGroup) {
            Object.values(control.controls).forEach((nestedControl: any) => {
                nestedControl.markAsTouched();
                nestedControl.markAsDirty();
                nestedControl.updateValueAndValidity();
            });
        }
    });
  }

  clearForm() {
    this.boxSignForm.reset();
  }

  removeUnderscoreFromString(sourceString: string) {
    return sourceString.replace(/_/g, ' ');
  }
  
  buildForm(dataArray: BoxSignTags[]) {
    // Start with existing form structure
    const boxSignForm: any = {
        boxSignTemplateId: new FormControl(this.signTemplates?.value || null, [Validators.required]),
        emailSubject: new FormControl(this.selectedTemplate()?.emailSubject || '', [Validators.required]),
        emailMessage: new FormControl(this.selectedTemplate()?.emailBody || '', [Validators.required]),
        fileNumber: new FormControl(this.fileNumber || '', [Validators.required])
    };
    
    // Build request data controls
    const requestData: any = {};
    for (const item of dataArray) {
        let defaultValue = '';
        // Pre-populate based on tag ID
        switch (item.tagId) {
            case 'client_dol':  // Date of Loss
                const dol = this.fileHub.caseFile?.DateOfLoss ? new Date(this.fileHub.caseFile.DateOfLoss) : null;
                defaultValue = dol ? dol.toLocaleDateString('en-US') : '';
                break;
            case 'date_of_authorization':
                const today = new Date();
                defaultValue = today.toLocaleDateString('en-US');
                break;
            case 'amount_authorized':
                defaultValue = String(this.fileHub.performanceData?.AuthorizedAmount || '');
                break;
            case 'case_manager':
                defaultValue = this.fileHub.caseFile?.CaseManagerNavigation?.Name || '';
                break;
            case 'case_manager_email':
                defaultValue = this.fileHub.caseFile?.CaseManagerNavigation?.Email || '';
                break;

            // Patient/Client Data
            case 'client_name':
                const firstName = this.fileHub.caseFile?.Patient?.Firstname || '';
                const lastName = this.fileHub.caseFile?.Patient?.Lastname || '';
                defaultValue = `${firstName} ${lastName}`.trim();
                break;
            case 'client_first_name':
                defaultValue = this.fileHub.caseFile?.Patient?.Firstname || '';
                break;
            case 'client_last_name':
                defaultValue = this.fileHub.caseFile?.Patient?.Lastname || '';
                break;
            case 'client_email':
                defaultValue = this.fileHub.caseFile?.Patient?.Email || '';
                break;
            case 'client_phone_number':
                const phone = this.fileHub.caseFile?.Patient?.XrefPhonePatients?.[0]?.Phone?.PhoneNumber || '';
                defaultValue = phone.replace(/\D/g, ''); // Remove non-digits
                break;
            case 'client_dob':
                const dob = this.fileHub.caseFile?.Patient?.Dob ? new Date(this.fileHub.caseFile.Patient.Dob) : null;
                defaultValue = dob ? dob.toLocaleDateString('en-US') : '';
                break;
            case 'client_address1':
                defaultValue = this.fileHub.caseFile?.Patient?.XrefAddressPatients?.[0]?.Address?.Address1 || '';
                break;
            case 'client_address2':
                defaultValue = this.fileHub.caseFile?.Patient?.XrefAddressPatients?.[0]?.Address?.Address2 || '';
                break;
            case 'client_city':
                defaultValue = this.fileHub.caseFile?.Patient?.XrefAddressPatients?.[0]?.Address?.City || '';
                break;
            case 'client_state':
                defaultValue = String(this.fileHub.caseFile?.Patient?.XrefAddressPatients?.[0]?.Address?.StateNavigation?.Code || '');
                break;
            case 'client_zip':
                defaultValue = this.fileHub.caseFile?.Patient?.XrefAddressPatients?.[0]?.Address?.Zip || '';
                break;

            // Provider Data - User Selected
            case 'provider':
                // Keep provider dropdown as is, but add change event handler in template
                requestData[item.tagId] = new FormControl(defaultValue, this.getValidatorsForField(true));
                break;
            case 'provider_contact':
                // Initialize provider contact with empty value - will be populated on provider selection
                requestData[item.tagId] = new FormControl(defaultValue, this.getValidatorsForField(true));
                break;
            case 'care_coordinator':
                // Get the selected provider's care coordinator name
                const selectedProvider = this.providersList?.find((p: any) => p.Id === this.boxSignForm.get('requestData')?.get('provider')?.value);
                defaultValue = selectedProvider?.CareCoordinatorName || '';
                requestData[item.tagId] = new FormControl(defaultValue, this.getValidatorsForField(true));
                break;

            // Law Firm Data
            case 'attorney_email':
                defaultValue = this.fileHub.caseFile?.AttorneyNavigation?.ContactEmail || '';
                break;
        }

        if (!requestData[item.tagId]) {
            requestData[item.tagId] = new FormControl(defaultValue, this.getValidatorsForField(true));
        }
    }

    // Add requestData to form structure
    boxSignForm['requestData'] = new FormGroup(requestData);
    
    // Create new form with all controls
    return new FormGroup(boxSignForm);
  }

  getValidatorsForField(required: boolean) {
    return required === true ? [Validators.required] : null;
  }

  loadSignRequests() {
    this.requestsGridLoading = true;
    this.api.fetchRequest(`${BoxEndpoints.SignRequests}/${this.fileNumber}`, undefined, undefined, undefined, false)
      .then(response => {
        if (response) {
          this.requestsGrid = { 
            dataSource: response,
            allowPaging: true,
            allowSorting: true,
            allowFiltering: true,
            filterSettings: { type: 'Excel' },
            pageSettings: { pageSize: 10 },
            hierarchyPrintMode: 'All',
            childGrid: {
              allowPaging: true,
              pageSettings: { pageSize: 5 },
              columns: [
                { field: 'signerEmail', headerText: 'Email', width: 200 },
                { field: 'signerRole', headerText: 'Role', width: 150 },
                { field: 'order', headerText: 'Order', width: 100 },
                { field: 'hasViewedDocument', headerText: 'Viewed', width: 100 },
                { field: 'hasSignedDocument', headerText: 'Signed', width: 100 },
                { field: 'dateSigned', headerText: 'Date Signed', width: 150, type: 'date', format: 'MM/dd/yyyy' }
              ]
            },
            columns: [
              { type: 'expand', width: 50 },
              { field: 'requestId', headerText: 'Request ID', visible: false },
              { field: 'requestName', headerText: 'Document Name', width: 400 },
              { 
                field: 'createdAt', 
                headerText: 'Created',
                width: 150,
                type: 'date',
                format: 'MM/dd/yyyy'
              },
              { 
                field: 'status', 
                headerText: 'Status',
                width: 150,
                template: (args: any) => {
                  const statusClass = this.getStatusClass(args.status);
                  return `<span class="${statusClass}">${args.status || ''}</span>`;
                }
              }
            ]
          };
        }
      })
      .catch(error => {
        console.error('Error loading sign requests:', error);
        this.toast.showError('Failed to load previous sign requests');
      })
      .finally(() => {
        this.requestsGridLoading = false;
      });
  }

  getStatusClass(status: string): string {
    if (!status) return 'text-secondary';
    
    switch(status.toLowerCase()) {
      case 'completed':
        return 'text-success';
      case 'expired':
        return 'text-danger';
      case 'pending':
        return 'text-warning';
      default:
        return 'text-secondary';
    }
  }
}
