// Angular
import { Component, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';

// Syncfusion
import { Query, ReturnOption } from '@syncfusion/ej2-data';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { GridComponent, GridModel, EditService, RowSelectEventArgs } from '@syncfusion/ej2-angular-grids';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { Role } from '@models/data-contracts';
import { GlobalsService } from '@services/globals/globals.service';
import { ApiService } from '@services/api/api.service';
import { GridTemplateModule } from '@modules/grid-template.module';
import { GridTemplateComponent } from '@grids/grid-template/grid-template.component';
import { AddRoleComponent } from '@root/src/app/components/forms/add-forms/add-role/add-role.component';

@Component({
  selector: 'roles-grid',
  standalone: true,
  imports: [
    GridTemplateModule,
    ButtonModule, 
    AddRoleComponent,
    DialogModule
  ],
  templateUrl: './roles-grid.component.html',
  styleUrls: ['./roles-grid.component.scss'],
  providers: [
    EditService
  ],
})
export class RolesGridComponent implements OnInit, AfterViewInit {

  constructor(
    private globals: GlobalsService,
    private api: ApiService
  ) {}

  // General variables
  public isResponsive: boolean = this.globals.isResponsive;
  @Input() showRoleEditor: boolean;
  @Input() showRoleAdder: boolean = false;

  // Variables for roles
  @ViewChild('usersRolesGridComponent') public usersRolesGridComponent: GridTemplateComponent;
  public roles: GridComponent;
  public rolesSettings: GridModel;
  public rolesSelectedRowName: string | any;
  public rightsEditorVisible: boolean = false;

  // Variables for availableRightsGrid
  @ViewChild('availableRightsGridTemplate') public availableRightsGridTemplate: GridTemplateComponent;
  public availableRightsGridSettings: GridModel;
  public availableRightsGridTemplateCurrentSelection: object[];
  
  // Variables for assignedRightsGrid
  @ViewChild('assignedRightsGridTemplate') public assignedRightsGridTemplate: GridTemplateComponent;
  @ViewChild('emptyRightsTemplate', { static: true }) public emptyRightsTemplate: string;
  public assignedRights: any;
  public assignedRightsGridSettings: GridModel;
  public assignedRightsGridTemplateCurrentSelection: object[];
  
  ngOnInit(): void {
    this.showRoleAdder = window.location.pathname === '/users-roles' ? true : false;
    this.rolesSettings = {
      dataSource: this.api.getOdata(APIEndpoints.Roles),
      pageSettings: { pageSize: 25 },
      toolbar: this.isResponsive ? [{ text: 'Edit Rights', tooltipText: 'Edit Rights', id: 'EditRights', disabled: true }, 'Update', 'Cancel', 'ColumnChooser'] : [{ text: 'Edit Rights', tooltipText: 'Edit Rights', id: 'EditRights', disabled: true },'Delete', 'Update', 'Cancel'],
      selectionSettings: { mode: 'Row',  type: 'Single' },
      query: new Query() 
        .expand('XrefRolesRights($expand=Right($select=FriendlyName))')
        .select(['Id, RoleName, Description, CreatedAt, XrefRolesRights']),
      columns: [
        { field: 'Id', isPrimaryKey: true, showInColumnChooser: false, visible: false },
        { field: 'RoleName', headerText: 'Role' },
        { field: 'Description' },
        { field: 'XrefRolesRights', headerText: 'Rights', allowSorting: false, allowFiltering: false, allowEditing: false,
            valueAccessor:  (field: string, data: Object): string[] => this.getRightsNames(field, data) },
        { field: 'CreatedAt', headerText: 'Created', type: 'datetime', format: 'MMM dd, yyyy hh:mm a' },
      ],
      toolbarClick: ($event: any): void => this.toolbarClickHandler($event),
      rowSelected: ($event: RowSelectEventArgs): void => this.toggleRightsEditor($event)
    }

    this.availableRightsGridSettings = {
      dataSource: this.api.getOdata(APIEndpoints.Rights),
      toolbar: undefined,
      height: '250',
      allowPaging: false,
      emptyRecordTemplate: this.emptyRightsTemplate,
      columns: [
        { field: 'Id', isPrimaryKey: true, visible: false },
        { type: 'checkbox', width: 50, textAlign: 'Center', allowFiltering: false },
        { field: 'FriendlyName', headerText: 'Rights List', allowFiltering: true},
      ],
      rowDataBound: ($event: any): void => this.customizeAssignedRightsBackgroundColor($event)
    };

    this.assignedRightsGridSettings = {
      dataSource: undefined,
      toolbar: undefined,
      height: '250',
      allowPaging: false,
      emptyRecordTemplate: this.emptyRightsTemplate,
      selectionSettings: { mode: 'Row',  type: 'Multiple' },
      editSettings: {
        allowEditing: false,
        allowAdding: true,
        allowDeleting: true,
        mode: 'Normal' // set to Normal until Batch editing can be refined
      },
      columns: [
        { field: 'RightId', isPrimaryKey: true, visible: false },
        { type: 'checkbox', width: 50, textAlign: 'Center', allowFiltering: false },
        { field: 'Friendly_name', headerText: 'Assigned Rights', allowFiltering: true},
      ]
    }
  }
  
  ngAfterViewInit(): void {
    // Set reference to grid after HTML has fully been rendered
    this.roles = this.usersRolesGridComponent.grid;
  }
  
  // Custom function needed to retrieve string values from nested arrays in fetched data
  getRightsNames(field: string, data: object): string[] {

    // Ensure data exists
    if ((data as any)[field]) {
      return (data as any)[field as string].map((XrefRolesRights: { Right: { FriendlyName: string } }) => {
        return XrefRolesRights.Right.FriendlyName;
      });
    } else {
      return [];
    }
  }

  toolbarClickHandler(args: any): void {

    // Remove rights editor
    if (args.item.text === 'Delete') {
      this.rightsEditorVisible = false;
    }

    // Open rights editor
    if (args.item.id === 'EditRights') {
      this.rightsEditorVisible = true
    }
  }

  // Apply a background color to any rows in this.assignedRights
  customizeAssignedRightsBackgroundColor(args: any): void {
    const rightsSet = new Set(this.assignedRights);

    if (rightsSet.has(args.data.Id)) {
      args.row.classList.add('assigned-right');
    }
  }

  // Update grids so new data is reflected
  refreshGrids(): void {
    this.roles.refresh();
    this.availableRightsGridTemplate.grid.refresh();
    this.assignedRightsGridTemplate.grid.refresh();
  }

  // Editing rights for individual roles - appears below roles roles
  toggleRightsEditor(rowArgs: RowSelectEventArgs): void {
    const rowData = rowArgs?.data as Role;

    // Ensure necessary data exists for clicked role
    if (rowData?.Id) {
      const assignedRightsData = this.api.getOdata(APIEndpoints.Roles + `(${rowData.Id})/Rights`);
      // this.rightsEditorVisible = true;
      // enable Edit Rights button
      this.roles.toolbarModule.enableItems(['EditRights'], true);
      this.assignedRightsGridSettings.dataSource = assignedRightsData
      this.rolesSelectedRowName = rowData.RoleName;

      // Update public variable this.assignedRights
      assignedRightsData.executeQuery(new Query()).then((e: ReturnOption) => { 
        this.assignedRights = (e.result as object[]).map((right) => (right as any).RightId);
        this.availableRightsGridTemplate?.grid.refresh();

        /**
         * if statement below only works for queries of rights < ~50
         *  - commenting out for now as it is unused
         *  - implemented option that customizes colors if they are assigned to the selected role
         */
        // if (rightIds.length > 0) {
        //   for (let i = 0; i < rightIds.length; i++) {
        //     if (i === 0) {
        //       this.availableRightsGridDataPredicate = new Predicate('Id', 'notequal', rightIds[i]) // initialize predicate on first id
        //     } else {
        //       this.availableRightsGridDataPredicate = this.availableRightsGridDataPredicate.and('Id', 'notequal', rightIds[i]);
        //     }
        //   }

        //   this.availableRightsGridDataQuery = new Query().where(this.availableRightsGridDataPredicate); // issues when assigned rights exceed ~100
        // } else {
        //   this.availableRightsGridDataQuery = new Query();
        // }
      });

      // Refresh available rights grid so asssigned rights are visible
      this.availableRightsGridTemplate?.grid.refresh();
    }
  }

  // Add selected role to assignedRightsGrid
  addSelectedRoleToAssignedRightsGrid(): void {
    this.availableRightsGridTemplateCurrentSelection = this.availableRightsGridTemplate.grid.getSelectedRecords();
    this.availableRightsGridTemplateCurrentSelection.forEach((selected: any) => {
      const rightObj = {
        RightId: selected.Id,
        Friendly_name: selected.FriendlyName
      }
      this.assignedRightsGridTemplate.grid.editModule.addRecord(rightObj);
    });

    this.refreshGrids();
  }
  
  // Update height before modal opened
  beforeOpenEditRightsDialog(args: any) {
    args.maxHeight = '85vh';
  }

  dialogClose(): void {
    this.rightsEditorVisible = false;
  }

  // Remove selected role to assignedRightsGrid
  removeSelectedRoleToAssignedRightsGrid(): void {
    this.assignedRightsGridTemplateCurrentSelection = this.assignedRightsGridTemplate.grid.getSelectedRecords();
    this.assignedRightsGridTemplateCurrentSelection.forEach((selected: any) => {
      this.assignedRightsGridTemplate.grid.editModule.deleteRecord(selected);
    });

    this.refreshGrids();
  }
  
  // Finalize changed settings
  submitRoleChanges(): void {
    this.assignedRightsGridTemplate.grid.editSettings.showConfirmDialog = false;
    this.assignedRightsGridTemplate.grid.editModule.batchSave();
    this.refreshGrids();
  }
}