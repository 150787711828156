import { Injectable, OnDestroy, signal } from '@angular/core';
import { fetchAuthSession } from '@aws-amplify/auth';
import { AuthenticatedServiceBase } from '@core/auth/auth.base';

const ERRORS = {
  TOKEN: {
    REFRESH_FAILED: {
      message: 'Failed to refresh authentication',
      technical: 'Token refresh request failed'
    },
    ENSURE_FAILED: {
      message: 'Authentication check failed',
      technical: 'Failed to ensure valid token exists'
    },
    START_FAILED: {
      message: 'Failed to start token refresh',
      technical: 'Error initializing token refresh cycle'
    }
  }
} as const;

@Injectable({
  providedIn: 'root'
})
export class TokenRefreshService extends AuthenticatedServiceBase {
  protected override readonly endpoint = this.APIEndpoints.Auth;
  private refreshInterval: any;
  private readonly REFRESH_INTERVAL = 25 * 60 * 1000; // 25 minutes
  private isRefreshing = signal(false);

  constructor() {
    super();
    this.startTokenRefresh();
  }

  ngOnDestroy() {
    this.stopTokenRefresh();
  }

  /**
   * Ensures a valid token exists, refreshing if needed
   */
  async ensureValidToken(): Promise<boolean> {
    if (this.isRefreshing()) {
      return true; // Already refreshing
    }

    try {
      this.isRefreshing.set(true);
      const session = await fetchAuthSession();

      if (!session?.tokens?.accessToken) {
        await this.refreshToken();
      }
      return true;
    } catch (error) {
      this.handleError(error, {
        context: 'TokenRefreshService.ensureValidToken',
        userMessage: ERRORS.TOKEN.ENSURE_FAILED.message
      });
      return false;
    } finally {
      this.isRefreshing.set(false);
    }
  }

  /**
   * Manually triggers a token refresh
   */
  async refreshToken(): Promise<boolean> {
    if (this.isRefreshing()) {
      return true; // Already refreshing
    }

    try {
      this.isRefreshing.set(true);
      await fetchAuthSession({ forceRefresh: true });
      return true;
    } catch (error) {
      this.handleError(
        error,
        {
          context: 'TokenRefreshService.refreshToken',
          userMessage: ERRORS.TOKEN.REFRESH_FAILED.message
        }
      );
      return false;
    } finally {
      this.isRefreshing.set(false);
    }
  }

  private async startTokenRefresh() {
    // Initial refresh
    await this.refreshToken().catch(error => {
      this.handleError(error, {
        context: 'TokenRefreshService.startTokenRefresh',
        userMessage: ERRORS.TOKEN.START_FAILED.message
      });
    });

    // Set up periodic refresh
    this.refreshInterval = setInterval(async () => {
      await this.refreshToken().catch(error => {
        this.handleError(error, {
          context: 'TokenRefreshService.startTokenRefresh',
          userMessage: ERRORS.TOKEN.START_FAILED.message
        });
      });
    }, this.REFRESH_INTERVAL);
  }

  private stopTokenRefresh() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  }
}
