import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faComment, faList } from '@fortawesome/free-solid-svg-icons';
import { Comment } from '@shared/models/data-contracts';
import { EntityType } from '@shared/models/entity-types';
import { BaseFormComponent } from '@root/src/app/shared/components/base-form/base-form.component';
import { FormFieldComponent } from '@shared/components/form-field/form-field.component';
import { CommentsService } from '@shared/features/comments/services/comments.service';

@Component({
  selector: 'comment-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    TextBoxModule,
    DropDownListModule,
    FontAwesomeModule,
    BaseFormComponent,
    FormFieldComponent
  ],
  templateUrl: './comment-form.component.html'
})
export class CommentFormComponent extends BaseFormComponent {

  // Decorators
  @Input() comment?: Comment;
  @Input() entityType!: EntityType;
  @Input() entityId!: number;
  @Input() threadId?: number;

  // Public
  icons = {
    type: faList,
    comment: faComment
  };

  constructor(
    fb: FormBuilder,
    protected commentsService: CommentsService
  ) {
    super(fb);
    this.form = this.fb.group({
      typeId: ['', Validators.required],
      content: ['', [Validators.required, Validators.minLength(1)]]
    });
  }

  override onSubmit(): void {
    if (this.form.valid) {
      this.formSubmitted.emit();
    }
  }
} 