// Angular
import { Injectable } from '@angular/core';

// 3rd Party
import { Query } from '@syncfusion/ej2-data';

// Internal
import { APIEndpoints } from '../../models/api/Endpoints';
import { ApiService } from '../api/api.service';
import { Invoice, InvoiceRow, InvoicePayment, Deposit } from '../../models/data-contracts';
import { PerformanceData } from '../../models/components/financial-performance.model';

@Injectable({
  providedIn: 'root'
})
export class FinancialPerformanceService {

  constructor(
    private api: ApiService
  ) { }

  performanceData: PerformanceData = {
    TotalBilledCost: 0,
    TotalPaymentReceived: 0,
    ActualSettlementCost: 0,
    BalanceDue: 0,
    AuthorizedAmount: 0,
    DaysOpen: 'Unknown',
    FullSettlementValue: 0,
    Profit: 'Unknown',
    ROIC: 'Unknown',
    Split2ndPayments: 0,
    XIRR: 0,
    CourtesyReduction: 0,
    CourtesyReductionPercent: 'Unknown',
    FinalBalanceDueGenerated: false,
    FinalFSV: 0
  };

  async returnPerformanceDataFetch(caseFileId: number): Promise<PerformanceData> {
    const endpoint = `${APIEndpoints.Casefiles}/${caseFileId}`;
    const query = new Query().expand(['Invoices($expand=InvoiceRows&$expand=InvoicePayments)', 'Deposits']).select(['Invoices', 'Deposits', 'FileOpened']);
    
    await this.api.getOdata(endpoint).executeQuery(query).then((response: any) => {
      const invoices = (response.result as any[])[0].Invoices as Invoice[];
      const deposits = (response.result as any[])[0].Deposits as Deposit[];
      const fileOpened = (response.result as any[])[0].FileOpened;
      const data: any = {};
      
      invoices.map((invoice: Invoice) => {
        invoice.InvoiceRows?.map((invoiceRow: InvoiceRow) => {
          data.TotalBilledCost += invoiceRow.AmountBilled as number;
          data.FullSettlementValue += invoiceRow.SettlementValue as number;
        });

        invoice.InvoicePayments?.map((invoicePayment: InvoicePayment) => {
          data.ActualSettlementCost += invoicePayment.AmountPaid as number;
        });
      });

      deposits.map((deposit: Deposit, index: number) => {
        data.TotalPaymentReceived += deposit.DepositAmount as number;
        data.CourtesyReduction += deposit.CourtesyReduction as number;
        data.FinalBalanceDueGenerated = index === deposits.length ? deposit.FinalCheck as boolean : false;
      });

      data.DaysOpen = this.countDays(fileOpened);
      data.FinalFSV = data.FullSettlementValue - data.CourtesyReduction;
      data.BalanceDue = data.FinalFSV - data.TotalPaymentReceived;
      data.Profit = data.FinalFSV - data.ActualSettlementCost;
      data.ROIC = data.ActualSettlementCost !== 0 ? (data.Profit / data.ActualSettlementCost) : 'Unknown';
    });

    return this.performanceData;
  }

  returnPerformanceData(invoices: Invoice[], deposits: Deposit[], fileOpened?: string): PerformanceData {
    const data: any = {};
    
    invoices.map((invoice: Invoice) => {
      invoice.InvoiceRows?.map((invoiceRow: InvoiceRow) => {
        data.TotalBilledCost += invoiceRow.AmountBilled as number;
        data.FullSettlementValue += invoiceRow.SettlementValue as number;
      });

      invoice.InvoicePayments?.map((invoicePayment: InvoicePayment) => {
        data.ActualSettlementCost += invoicePayment.AmountPaid as number;
      });
    });

    deposits.map((deposit: Deposit, index: number) => {
      data.TotalPaymentReceived += deposit.DepositAmount as number;
      data.CourtesyReduction += deposit.CourtesyReduction as number;
      data.FinalBalanceDueGenerated = index === deposits.length ? deposit.FinalCheck as boolean : false;
    });

    data.DaysOpen = this.countDays(fileOpened);
    data.FinalFSV = data.FullSettlementValue - data.CourtesyReduction;
    data.BalanceDue = data.FinalFSV - data.TotalPaymentReceived;
    data.Profit = data.FinalFSV - data.ActualSettlementCost;
    data.ROIC = data.ActualSettlementCost !== 0 ? (data.Profit / data.ActualSettlementCost) : 'Unknown';

    return this.performanceData;
  }

  countDays(dateString: string | undefined) {
    const date = dateString !== undefined ? new Date(dateString) : undefined;
    const today = new Date();
    const msInDay = 1000 * 60 * 60 * 24;
    return date !== undefined ? Math.floor((today.getTime() - date.getTime()) / msInDay) : 'Unknown';
  }
}
