<div class="add-invoice">
  <!-- Remove loading bar from top -->
  <ng-container *ngIf="isFormHidden(); else showForm">
    <div [ngSwitch]="true">
      <div *ngSwitchCase="warnings[WarningType.Closed]" class="warning-container">
        <h3>Warning!</h3>
        <p>You are entering an invoice on a closed file, or a file where client is not currently in
        treatment. Please verify this is correct before proceeding.</p>
        <div class="warning-actions">
          <button class="close-button" type="button" ejs-button [isPrimary]="false" (click)="closeForm()">Close</button>
          <button type="button" ejs-button [isPrimary]="true" (click)="hideWarning(WarningType.Closed)">Continue</button>
        </div>
      </div>
      <div *ngSwitchCase="warnings[WarningType.Important]" class="warning-container">
        <h3>Important Notes</h3>
        <p>{{selectedProvider?.Notes}}</p>
        <div class="warning-actions">
          <button class="close-button" type="button" ejs-button [isPrimary]="false" (click)="closeForm()">Close</button>
          <button type="button" ejs-button [isPrimary]="true" (click)="hideWarning(WarningType.Important)">Continue</button>
        </div>
      </div>
      <div *ngSwitchCase="warnings[WarningType.SingleAgreement]" class="warning-container">
        <h3>Warning!</h3>
        <p>This provider is not in network. Do you have a Single Case Agreement for this file?</p>
        <div class="warning-actions">
          <button  class="close-button" type="button" ejs-button [isPrimary]="false" (click)="closeForm()">No</button>
          <button type="button" ejs-button [isPrimary]="true" (click)="hideWarning(WarningType.SingleAgreement)">Yes</button>
        </div>
      </div>
      <div *ngSwitchCase="warnings[WarningType.SignedLien]" class="warning-container">
        <h3>Warning!</h3>
        <p>This case file does not have a signed Lien or LOP</p>
        <div class="warning-actions">
          <button type="button" ejs-button [isPrimary]="false" (click)="hideWarning(WarningType.SignedLien)">Close</button>
        </div>
      </div>
      <div *ngSwitchCase="warnings[WarningType.NoProcedure]" class="warning-container">
        <h3>Warning!</h3>
        <p>This Provider is not part of any Fee Schedule. Please add a Fee Schedule to the Provider. Then re-submit the Invoice.</p>
        <div class="warning-actions">
          <button type="button" ejs-button [isPrimary]="false" (click)="hideWarning(WarningType.NoProcedure)">Close</button>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- Show form when no warnings -->
  <ng-template #showForm>
    <ng-container>
      <form [formGroup]="invoiceForm" (ngSubmit)="onSubmit()" #invoiceFormElement="ngForm" (submit)="$event.preventDefault()">
        <div class="row">
          <div class="form-header">
            <h1>Invoice</h1>
            <div class="loading-indicator" *ngIf="loading()">
              <i class="fas fa-spinner fa-spin"></i>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <ng-container *ngIf="!invoice; else showProviderName">
              <ejs-dropdownlist #providerDropdown
                  id='providerDD'
                  allowFiltering="true"
                  placeholder="Select Provider"
                  [fields]="{
                      text: 'Name',
                      value: 'Id'
                  }"
                  [dataSource]='providerListSignal()'
                  formControlName="ProviderId"
                  (change)='onProviderChange($event)'
                  floatLabelType="Auto"
                  [enabled]="!invoice"
                  [required]="true"
                  [showClearButton]="false"
                  [popupWidth]="600"
              ></ejs-dropdownlist>
              <div *ngIf="invoiceForm.get('ProviderId')?.invalid && invoiceForm.get('ProviderId')?.touched" class="e-error">
                  Provider is required
              </div>
          </ng-container>

          <!-- Show provider name for editing -->
          <ng-template #showProviderName>
              <ejs-textbox
                  [value]="selectedProvider?.Name"
                  placeholder="Provider"
                  floatLabelType="Auto"
                  [readonly]="true"
                  [enabled]="false"
              ></ejs-textbox>
          </ng-template>
          </div>
          <div class="col-12 col-sm-6">
            <ejs-textbox
              placeholder="Provider Invoice Number"
              formControlName="ProviderInvoiceNumber"
              floatLabelType="Auto"
              [enabled]="!isFieldDisabled()"
            ></ejs-textbox>
          </div>
          <div class="col-12 col-sm-6">
            <ejs-textbox
              placeholder="Internal Invoice Number"
              formControlName="InternalInvoiceNumber"
              floatLabelType="Auto"
              [enabled]="!isFieldDisabled()"
            ></ejs-textbox>
          </div>
          <div class="col-12 col-sm-6">
            Provider In Network :
            <ejs-switch
              [checked]="selectedProvider?.InNetwork"
              onLabel="Yes"
              offLabel="No"
              disabled="true"
            ></ejs-switch>
          </div>
          <div class="col-12 col-sm-6">
            <ejs-datepicker
              formControlName="InvoiceDate"
              placeholder="Invoice Date"
              floatLabelType="Auto"
              format="yyyy-MM-dd"
              [enabled]="!isFieldDisabled()"
              [required]="true"
            ></ejs-datepicker>
            <div *ngIf="invoiceForm.get('InvoiceDate')?.invalid && invoiceForm.get('InvoiceDate')?.touched" class="e-error">
              Invoice Date is required
            </div>
          </div>
          <div class="col-12 col-sm-6">
            Lock Invoice :
            <ejs-switch
              formControlName="LockInvoice"
              onLabel="Yes"
              offLabel="No"
            ></ejs-switch>
          </div>
          <div class="col-12 col-sm-6" *ngIf="showSurgicalFields">
            <ejs-dropdownlist
              formControlName="SurgicalInvoiceId"
              placeholder="Select Surgical Type"
              [fields]="{
                value: 'Id',
                text: 'Description'
              }"
              [dataSource]="surgicalProviderTypes"
              floatLabelType="Auto"
              [enabled]="!isFieldDisabled()"
            ></ejs-dropdownlist>
          </div>
          <div class="col-12">
            <ejs-textbox
              [multiline]='true'
              placeholder="Notes"
              formControlName="Notes"
              [enabled]="!isFieldDisabled()"
            ></ejs-textbox>
          </div>

          <div class="form-item add-row-buttons" *ngIf="selectedProvider">
            <div *ngIf="!hasFeeSchedule() && selectedProvider" class="warning-message">
              <i class="fas fa-exclamation-triangle"></i>
              Provider must have a Fee Schedule assigned to add invoice rows and payments
            </div>
          </div>
          <!-- Invoice Rows -->
          <div *ngIf="hasFeeSchedule() && selectedProvider && procedureCodes && procedureCodes.length > 0" class="add-invoice-table-container">
            <div class="header">
              <button type="button" [isPrimary]="true" ejs-button (click)="addInvoiceRow()"
                      [disabled]="isFieldDisabled() || !hasFeeSchedule()" [ngClass]="{'blocked-button': !hasFeeSchedule()}">
                <i class="fas fa-plus"></i>
              </button>
              <h4>Invoice Rows</h4>
            </div>

            <!-- Only show table if there are rows -->
            <ng-container *ngIf="invoiceRows.length > 0">
              <!-- Add error summary -->
              <div class="table-errors" *ngIf="hasInvoiceRowErrors()">
                <ul>
                  <li *ngIf="hasDateOfServiceErrors()">Date of Service is required</li>
                  <li *ngIf="hasProcedureCodeErrors()">Procedure Code is required</li>
                </ul>
              </div>

              <div formArrayName="InvoiceRows">
                <table class="invoice-table">
                  <thead>
                    <tr>
                      <th>Date of Service</th>
                      <th>Procedure Code</th>
                      <th>Amount Billed</th>
                      <th>Purchase Rate</th>
                      <th>T.D.Provider</th>
                      <th>Settlement Value</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let row of invoiceRows.controls; let i = index" [formGroupName]="i">
                      <td>
                        <ejs-datepicker formControlName="DateOfService"
                                       [cssClass]="row.get('DateOfService')?.invalid && row.get('DateOfService')?.touched ? 'e-field invalid-field' : 'e-field'"
                                       [title]="row.get('DateOfService')?.invalid && row.get('DateOfService')?.touched ? 'Date of Service is required' : ''"
                                       placeholder="Date of Service"
                                       floatLabelType="Auto"
                                       format="yyyy-MM-dd"
                                       [enabled]="!isFieldDisabled()"
                                       [required]="true"></ejs-datepicker>
                      </td>
                      <td>
                        <ejs-dropdownlist formControlName="ProcedureCodeId"
                                         [cssClass]="row.get('ProcedureCodeId')?.invalid && row.get('ProcedureCodeId')?.touched ? 'e-field invalid-field' : 'e-field'"
                                         [title]="row.get('ProcedureCodeId')?.invalid && row.get('ProcedureCodeId')?.touched ? 'Procedure Code is required' : ''"
                                         allowFiltering="true"
                                         placeholder="Select a Procedure Code"
                                         [dataSource]='procedureCodesSignal()'
                                         [fields]="{ value: 'Id', text: 'ProcedureCodeName' }"
                                         (change)="onProcedureCodeChange($event, i)"
                                         [enabled]="!isFieldDisabled()"
                                         [required]="true"
                                         [showClearButton]="false"
                                         cssClass="e-field"
                                         floatLabelType="Never"
                                         [popupWidth]="600"></ejs-dropdownlist>
                      </td>
                      <td>
                        <ejs-numerictextbox formControlName="AmountBilled" min="0" placeholder="Amount Billed ($)"
                                           [enabled]="!isFieldDisabled()" floatLabelType="Never"></ejs-numerictextbox>
                      </td>
                      <td>
                        <ejs-numerictextbox formControlName="ReimbursementRate" min="0" max="100"
                                           placeholder="Purchase Rate (%)" format="n2" decimals="2"
                                           [enabled]="!isFieldDisabled()" floatLabelType="Never"></ejs-numerictextbox>
                      </td>
                      <td>
                        <ejs-numerictextbox formControlName="TotalDueProvider" min="0" placeholder="T.D.Provider ($)"
                                           [enabled]="!isFieldDisabled()" floatLabelType="Never"></ejs-numerictextbox>
                      </td>
                      <td>
                        <ejs-numerictextbox formControlName="SettlementValue" min="0" placeholder="Settlement Value ($)"
                                           [enabled]="false" floatLabelType="Never"></ejs-numerictextbox>
                      </td>
                      <td>
                        <button type="button" (click)="deleteInvoiceRow(i)" class="e-icons e-close row-button"
                                [disabled]="isFieldDisabled()"></button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ng-container>
          </div>

          <!-- Invoice Payments -->
          <div *ngIf="hasFeeSchedule() && selectedProvider && procedureCodes && procedureCodes.length > 0" class="add-invoice-table-container">
            <div class="header">
              <button type="button" [isPrimary]="true" ejs-button (click)="addInvoicePayment()"
                      [disabled]="isFieldDisabled() || !hasFeeSchedule()" [ngClass]="{'blocked-button': !hasFeeSchedule()}">
                <i class="fas fa-plus"></i>
              </button>
              <h4>Invoice Payments</h4>
            </div>

            <!-- Only show table if there are payments -->
            <ng-container *ngIf="invoicePayments.length > 0">
              <!-- Add error summary -->
              <div class="table-errors" *ngIf="hasInvoicePaymentErrors()">
                <ul>
                  <li *ngIf="hasDatePaidErrors()">Date Paid is required</li>
                  <li *ngIf="hasPaymentMethodErrors()">Payment Method is required</li>
                  <li *ngIf="!validateTotalPayments()">Amount Paid should be equal to Balance Due</li>
                </ul>
              </div>

              <div formArrayName="InvoicePayments">
                <table class="payments-table">
                  <thead>
                    <tr>
                      <th>Date Paid</th>
                      <th>Balance Due</th>
                      <th>Amount Paid</th>
                      <th>Payment Status</th>
                      <th>Payment Method</th>
                      <th>Check #</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let payment of invoicePayments.controls; let i = index" [formGroupName]="i">
                      <td>
                        <ejs-datepicker formControlName="DatePaid"
                                        [cssClass]="payment.get('DatePaid')?.invalid && payment.get('DatePaid')?.touched ? 'e-field invalid-field' : 'e-field'"
                                        [title]="payment.get('DatePaid')?.invalid && payment.get('DatePaid')?.touched ? 'Date Paid is required' : ''"
                                        placeholder="Date Paid"
                                        format="yyyy-MM-dd"
                                        [enabled]="!isFieldDisabled()"
                                        [required]="true"></ejs-datepicker>
                      </td>
                      <td>
                        <ejs-numerictextbox formControlName="BalanceDue" placeholder="Balance Due ($)"
                                           [enabled]="false" [required]="true" cssClass="e-field"
                                           floatLabelType="Never"></ejs-numerictextbox>
                      </td>
                      <td>
                        <ejs-numerictextbox formControlName="AmountPaid"
                                            placeholder="Amount Paid ($)"
                                            [cssClass]="!validateTotalPayments() ? 'e-field invalid-field' : 'e-field'"
                                            [title]="!validateTotalPayments() ? 'Amount Paid should be equal to Balance Due' : ''"
                                            [enabled]="!isFieldDisabled()"
                                            [required]="true"
                                            floatLabelType="Never"></ejs-numerictextbox>
                      </td>
                      <td>
                        <ejs-dropdownlist formControlName="PaymentStatus"
                                          [cssClass]="payment.get('PaymentStatus')?.invalid && payment.get('PaymentStatus')?.touched ? 'e-field invalid-field' : 'e-field'"
                                          [title]="payment.get('PaymentStatus')?.invalid && payment.get('PaymentStatus')?.touched ? 'Payment Status is required' : ''"
                                          allowFiltering="true"
                                          placeholder="Select Payment Status"
                                          [dataSource]='paymentStatusesSignal()'
                                          [fields]="{ value: 'Id', text: 'Description' }"
                                          [enabled]="!isFieldDisabled()"
                                          [required]="true"
                                          [showClearButton]="false"
                                          floatLabelType="Never"
                                          [popupWidth]="200"></ejs-dropdownlist>
                      </td>
                      <td>
                        <ejs-dropdownlist formControlName="PaymentMethod"
                                          [cssClass]="payment.get('PaymentMethod')?.invalid && payment.get('PaymentMethod')?.touched ? 'e-field invalid-field' : 'e-field'"
                                          [title]="payment.get('PaymentMethod')?.invalid && payment.get('PaymentMethod')?.touched ? 'Payment Method is required' : ''"
                                          allowFiltering="true"
                                          placeholder="Select Payment Method"
                                          [dataSource]='paymentMethodsSignal()'
                                          [fields]="{ value: 'Id', text: 'Description' }"
                                          [enabled]="!isFieldDisabled()"
                                          [required]="true"
                                          [showClearButton]="false"
                                          floatLabelType="Never"
                                          [popupWidth]="200"></ejs-dropdownlist>
                      </td>
                      <td>
                        <ejs-textbox formControlName="TransactionNumber"
                                     placeholder="Check #"
                                     [enabled]="!isFieldDisabled()"
                                     floatLabelType="Never"></ejs-textbox>
                      </td>
                      <td>
                        <button type="button" (click)="deleteInvoicePayment(i)" class="e-icons e-close row-button"
                                [disabled]="isFieldDisabled()"></button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="form-item form-buttons" [ngClass]="{'hidden': isFormHidden()}">
          <button type="button"
                  ejs-button
                  (click)="closeForm()"
                  [disabled]="loading()"
          >Cancel</button>
          <button type="button"
                  [isPrimary]="true"
                  ejs-button
                  (click)="onSubmit()"
                  [disabled]="loading() || !hasFeeSchedule()"
                  [ngClass]="{'blocked-button': !hasFeeSchedule()}"
          >Submit</button>
        </div>
      </form>
    </ng-container>
  </ng-template>
</div>
