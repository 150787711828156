// Angular
import { Component, ViewChild, signal, WritableSignal, TemplateRef, inject } from '@angular/core';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';
import { CommandClickEventArgs, GridModel, Column } from '@syncfusion/ej2-grids';

// Models & Services
import { LawFirm } from '@models/data-contracts';
import { LawFirmsGridService } from '../services/law-firms-grid.service';
import { BaseGridComponent, APIEndpoints } from '@shared/components/base-grid/base-grid.component';
import { SetGridDataArgs } from '@shared/components/base-grid/models/grid.models';
import { AuditLogsComponent } from '@shared/components/audit-logs/audit-logs.component';
import { BaseGridHooks } from '@shared/components/base-grid/services/hooks.service';

// Components
import { LawFirmFormComponent } from '@features/law-firms/law-firm-form/law-firm-form.component';
import { BulkEditLawFirmsComponent } from './bulk-edit-lawfirms/bulk-edit-lawfirms.component';
import { GridComponent } from '@syncfusion/ej2-angular-grids';

// Error constants
const ERRORS = {
  LOAD_AUDIT_LOGS: {
    message: 'Failed to load audit logs. Please try again.',
    technical: 'Error loading audit logs for law firms',
  },
  TOOLBAR_ACTION: {
    message: 'Unable to handle toolbar action',
    technical: 'No toggleActive button found in toolbar.',
  },
  REFRESH_GRID: {
    message: 'Failed to refresh grid',
    technical: 'Error refreshing grid',
  },
  SUBMIT_LAW_FIRM_FORM: {
    message: 'Failed to submit law firm form',
    technical: 'Error submitting law firm form',
  },
  CLOSE_LOGS_DIALOG: {
    message: 'Error closing logs dialog',
    technical: 'Error occurred while closing logs dialog',
  },
  SHOW_ACTIVE_NOT_SET: {
    message: 'Unable to determine active/inactive filter status',
    technical: 'showActive is undefined or null',
  },
};

type DialogMode = 'add' | 'edit' | 'logs';

// Component Metadata
@Component({
  selector: 'law-firms-grid',
  standalone: true,
  imports: [BaseGridComponent, DialogModule, AuditLogsComponent, LawFirmFormComponent, BulkEditLawFirmsComponent],
  providers: [LawFirmsGridService, BaseGridHooks],
  templateUrl: './law-firms-grid.component.html',
})
export class LawFirmsGridComponent extends BaseGridComponent {
  @ViewChild('lawFirmsGrid') lawFirmsGrid: BaseGridComponent;
  @ViewChild('lawFirmDialog') lawFirmDialog: DialogComponent;
  @ViewChild('lawFirmForm') lawFirmForm?: LawFirmFormComponent;
  @ViewChild('bulkEditLawFirmsComponent') bulkEditComponent: BulkEditLawFirmsComponent;

  // Services
  protected readonly lawFirmsGridService = inject(LawFirmsGridService);

  // State
  dialogState = signal<{ isOpen: boolean; mode: DialogMode; title: string }>({
    isOpen: false,
    mode: 'add',
    title: '',
  });
  lawFirmSignal = signal<LawFirm | undefined>(undefined);
  selectedLawFirm: WritableSignal<LawFirm | undefined> = signal(undefined);
  showActive = true;
  auditLogs: any[] = [];
  auditLogsLoading = signal(false);
  logsDialogVisibility = signal(false);

  // Dialog buttons
  logsDialogButtons = [{ click: () => this.closeLogsDialog(), buttonModel: { content: 'Close', cssClass: 'e-flat' } }];

  lawFirmDialogButtons = [
    {
      click: () => this.cancelLawFirmForm(),
      buttonModel: { content: 'Cancel', cssClass: 'e-outline' },
    },
    {
      click: async () => this.submitLawFirmForm(),
      buttonModel: { content: 'Submit', isPrimary: true },
    },
  ];

  // Grid Settings
  override settings: SetGridDataArgs = {
    endpoint: APIEndpoints.Lawfirms,
    name: 'Law Firms',
    useRoundedEdges: true,
    bulkEditing: true,
    activeRecordsToggle: true,
    query: new Query()
      .expand([
        'XrefAddressLawfirms($expand=Address($expand=StateNavigation($select=Id,Name);$select=Id,AddressType,Address1,Address2,City,State,Zip,County,StateNavigation))',
      ])
      .select([
        'Id',
        'Abbreviation',
        'Name',
        'XrefAddressLawfirms',
        'MarketManager',
        'CaseManager',
        'Website',
        'WebsiteUrl',
        'Rating',
        'IsActive',
      ])
      .where('IsActive', 'equal', this.showActive)
      .sortBy('Id', 'desc')
      .sortBy('UpdatedAt'),
    transformData: (data: any[]) => {
      return data.map((lawFirm: LawFirm) => {
        const address =
          lawFirm.XrefAddressLawfirms?.find((x: any) => x.Address?.AddressType === 2)?.Address ||
          lawFirm.XrefAddressLawfirms?.[0]?.Address;
        return {
          ...lawFirm,
          TotalFiles: lawFirm.CaseFiles?.length ?? 0,
          XrefAddressLawfirms: { Address: address },
        };
      });
    },
    gridSettings: {
      columns: [
        { type: 'checkbox', allowFiltering: false },
        { field: 'Id' },
        { field: 'Abbreviation', headerText: 'Abbreviation' },
        {
          field: 'Name',
          headerText: 'Name',
          template: '<a class="link" href="/law-firm-detail/${Id}"><span>${Name}</span></a>',
        },
        {
          field: 'XrefAddressLawfirms.Address.Address1',
          headerText: 'Address',
          allowSorting: false,
          allowFiltering: false,
        },
        { field: 'XrefAddressLawfirms.Address.City', headerText: 'City', allowSorting: false, allowFiltering: false },
        {
          field: 'XrefAddressLawfirms.Address.StateNavigation.Name',
          headerText: 'State',
          allowSorting: false,
          allowFiltering: false,
        },
        { field: 'XrefAddressLawfirms.Address.Zip', headerText: 'Zip', allowSorting: false, allowFiltering: false },
        {
          field: 'MarketManager',
          headerText: 'Market Manager',
          foreignKeyValue: 'Name',
          foreignKeyField: 'Id',
          dataSource: new DataManager([]), // Empty DataManager as placeholder
        },
        {
          field: 'CaseManager',
          headerText: 'Case Manager',
          foreignKeyValue: 'Name',
          foreignKeyField: 'Id',
          dataSource: new DataManager([]), // Empty DataManager as placeholder
        },
        { field: 'IsActive', headerText: 'Is Active' },
        {
          type: 'commands',
          headerText: 'Actions',
          commands: [
            { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
            {
              type: 'Delete',
              title: 'Toggle Active',
              buttonOption: { cssClass: 'e-flat', iconCss: 'e-icons e-circle-check' },
            },
            { type: 'None', title: 'Logs', buttonOption: { iconCss: 'e-icons e-description', cssClass: 'e-flat' } },
          ],
        },
      ],
      toolbarClick: ($event: any) => this.customToolbarClick($event),
      commandClick: ($event: any) => this.onCommandClick($event),
    },
  };

  constructor() {
    super();
  }

  override async ngOnInit() {
    // Initialize managers data first
    const [marketManagers, caseManagers] = await Promise.all([
      this.lawFirmsGridService.getMarketManagers(),
      this.lawFirmsGridService.getCaseManagers(),
    ]);

    // Create DataManager instances once
    const marketManagersDataSource = new DataManager(marketManagers);
    const caseManagersDataSource = new DataManager(caseManagers);

    // Update grid settings with the DataManager instances
    if (this.settings?.gridSettings?.columns) {
      const columns = this.settings.gridSettings.columns as Column[];
      const marketManagerColumn = columns.find(c => (c as Column).field === 'MarketManager') as Column;
      const caseManagerColumn = columns.find(c => (c as Column).field === 'CaseManager') as Column;

      if (marketManagerColumn) {
        marketManagerColumn.dataSource = marketManagersDataSource;
      }
      if (caseManagerColumn) {
        caseManagerColumn.dataSource = caseManagersDataSource;
      }
    }

    // Now initialize the grid
    await super.ngOnInit();
    await this.lawFirmsGridService.loadGridData(this.showActive);
  }

  // Custom clicks on grid's toolbar
  async customToolbarClick(args: any) {
    if (args.item.text === 'Add') {
      this.openAddDialog();
    }

    if (args.item.id === 'BulkEdit') {
      this.handleBulkEdit();
    }

    if (args.item.id === 'toggleActive') {
      this.services.toggleActiveRecords(this.settings.gridSettings as GridModel, this.lawFirmsGrid.grid as GridComponent);
    }

    return false;
  }

  private openAddDialog() {
    this.lawFirmSignal.set(undefined);
    this.dialogState.set({
      isOpen: true,
      mode: 'add',
      title: 'Add Law Firm',
    });
    this.lawFirmDialog.show();
  }

  private handleBulkEdit() {
    const selectedRows = this.getSelected();
    if (selectedRows.length > 0) {
      this.bulkEditComponent.selectedRows = selectedRows;
      this.bulkEditComponent.showModal();
    } else {
      this.notify('Please select at least one law firm to edit', this.NotificationSeverity.Warning);
    }
  }

  // Custom clicks on grid's command("Actions") column
  async onCommandClick(args: CommandClickEventArgs) {
    const lawFirm = args.rowData as LawFirm;

    if (lawFirm?.Id && args.commandColumn?.title === 'Logs') {
      await this.showAuditLogs(lawFirm.Id);
    } else if (args.commandColumn?.type === 'Edit') {
      args.cancel = true;
      this.openEditDialog(lawFirm);
    }
  }

  getSelected(): LawFirm[] {
    return (this.lawFirmsGrid.grid?.getSelectedRows() || []) as LawFirm[];
  }

  openEditDialog(lawFirm: LawFirm): void {
    this.lawFirmSignal.set(lawFirm);
    this.dialogState.set({
      isOpen: true,
      mode: 'edit',
      title: `Edit ${lawFirm.Name}`,
    });
  }

  async submitLawFirmForm() {
    const success = await this.lawFirmForm?.onSubmit();
    if (success) {
      this.dialogState.set({ ...this.dialogState(), isOpen: false });
      if (this.lawFirmsGrid.grid) {
        this.lawFirmsGrid.grid.refresh();
        this.lawFirmSignal.set(undefined);
        this.lawFirmForm?.lawFirmForm.reset();
      }
    }
  }

  closeLogsDialog() {
    this.logsDialogVisibility.set(false);
    this.auditLogs = [];
  }

  cancelLawFirmForm() {
    this.dialogState.set({
      isOpen: false,
      mode: 'add',
      title: 'Add Law Firm',
    });
  }

  closeDialog() {
    this.dialogState.set({
      ...this.dialogState(),
      isOpen: false,
    });
  }

  override async showAuditLogs(id: number, entityType: string = 'LawFirm'): Promise<any[]> {
    this.auditLogsLoading.set(true);
    this.logsDialogVisibility.set(true);
    try {
      const logs = await super.showAuditLogs(id, entityType);
      if (logs.length > 0) {
        this.auditLogs = logs;
      }
      return logs;
    } finally {
      this.auditLogsLoading.set(false);
    }
  }
}
