<div id="patients-grid">
    <base-grid [settings]="settings">
        <ng-template #nameTemplate let-data>
            <a class="link" (click)="onPatientNameClick(data)">
                <span class="capitalize">{{data.Firstname}} {{data.Lastname}}</span>
            </a>
        </ng-template>
    </base-grid>

    <ejs-dialog #patientDialog 
        [visible]="false"
        [buttons]="patientDialogButtons"
        [isModal]="true">
        <patient-form-component #patientFormComponent 
            [patient]="patient" 
            [displayButtons]="false"
            [showPatientSelect]="false" />
    </ejs-dialog>
</div>  