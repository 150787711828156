import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'loading-spinner',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div class="loading-spinner" [class.visible]="loading">
      <div class="spinner"></div>
    </div>
  `,
  styles: [`
    .loading-spinner {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(255,255,255,0.7);
      display: none;
      align-items: center;
      justify-content: center;
      z-index: 1000;

      &.visible {
        display: flex;
      }
    }

    .spinner {
      width: 40px;
      height: 40px;
      border: 3px solid rgb(var(--color-sf-surface-variant));
      border-top: 3px solid rgb(var(--color-sf-primary));
      border-radius: 50%;
      animation: spin 1s linear infinite;
    }

    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `]
})
export class LoadingSpinnerComponent {
  @Input() loading = false;
}