<div id="law-firms-grid">
  <base-grid #lawFirmsGrid [settings]="settings">
    <!-- Audit Logs Modal -->
    <ejs-dialog
      #logsDialog
      [visible]="logsDialogVisibility()"
      [header]="'Law Firm Logs'"
      [buttons]="logsDialogButtons"
      [target]="'body'"
      [isModal]="true"
      [showCloseIcon]="true"
    >
      <ng-template #content>
        <div class="dialog-content logs-dialog-content">
          <app-audit-logs [logs]="auditLogs" [loading]="auditLogsLoading()"> </app-audit-logs>
        </div>
      </ng-template>
    </ejs-dialog>

    <!-- Law Firm Dialog -->
    <ejs-dialog
      id="law-firm-dialog"
      #lawFirmDialog
      [showCloseIcon]="true"
      [target]="'body'"
      [isModal]="true"
      [closeOnEscape]="true"
      [buttons]="lawFirmDialogButtons"
      [visible]="dialogState().isOpen"
      [header]="dialogState().title"
      (close)="closeDialog()"
    >
      <law-firm-form
        #lawFirmForm
        [lawFirm]="lawFirmSignal"
        [isLawFirm]="true"
        [hideActions]="true"
        [submitType]="dialogState().mode === 'add' ? 'POST' : 'PATCH'"
      />
    </ejs-dialog>

    <!-- Bulk Edit Law Firms Component -->
    <bulk-edit-law-firms #bulkEditLawFirmsComponent></bulk-edit-law-firms>
  </base-grid>
</div>
