// Angular
import { Component, computed, effect, EventEmitter, HostListener, Input, Output, Signal, signal, ViewChild, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';


// 3rd Party
import { ButtonModule, CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { TextBoxAllModule, MaskedTextBoxAllModule, NumericTextBoxAllModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownListAllModule, MultiSelectAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { TooltipAllModule } from '@syncfusion/ej2-angular-popups';
import { Query, Predicate } from '@syncfusion/ej2-data';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faUser, faIdCard, faUserTag, faPhone, faComments, faMapLocationDot, faUsersRectangle, faDollarSign, faList, faCheckCircle, faFlag, faFax, faFileContract, faFileShield, faFileInvoiceDollar, faCalendar, faLanguage, faCheckSquare, faLaptop, faToggleOn, faGlobe, faMoneyBillTransfer, faUserMd, faExclamationCircle, faBuilding, faFileCircleExclamation, faBuildingCircleCheck, faAddressBook, faPercent } from '@fortawesome/free-solid-svg-icons';

// Models
import { Address, Phone, Provider } from '@models/data-contracts';
import { APIEndpoints } from '@models/api/Endpoints';

// Services
import { FormCrudService } from '@services/forms/form-crud.service';
import { ApiService } from '@services/api/api.service';
import { ToastMessageService } from '@root/src/app/shared/services/toast-message/toast-message.service';

// Components
import { AddressFormComponent } from '../address-form/address-form.component';
import { PhoneFormComponent } from '../phone-number-form/phone-number-form.component';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';

// Type & Interface setup
type ProviderFormControls = {
  [K in keyof Provider]: FormControl<Provider[K] | null>;
};  

@Component({
  selector: 'provider-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    TextBoxAllModule,
    MaskedTextBoxAllModule, 
    NumericTextBoxAllModule,
    DropDownListAllModule,
    TooltipAllModule,
    FontAwesomeModule,
    AddressFormComponent,
    CheckBoxModule,
    PhoneFormComponent,
    DatePickerModule,
    MultiSelectAllModule,
  ],
  templateUrl: './provider-form.component.html',
  styleUrls: ['./provider-form.component.scss']
})
export class ProviderFormComponent {
  
  constructor(
    private formCRUD: FormCrudService,
    private api: ApiService,
    private toast: ToastMessageService
  ) { 

    // Single effect to handle form updates
    effect((onCleanup) => {
      const { currentValue } = this.formState();

      // Update forms when provider changes
      if (currentValue) {
        this.providerForm.patchValue(currentValue);
        this.getProviderAddress();
        this.getProviderPhone();
      } else {
        this.addressFormComp.addressForm.reset();
        this.phoneFormComp.phoneForm.reset();
      }

      // Cleanup
      onCleanup(() => this.providerForm.reset());
    });
  }

  // Decorator variables
  @Input() selectedAccountId?: number;
  @Input() isProvider?: boolean;
  @Input() provider!: Signal<Provider | undefined>;
  @Input() submitType?: 'POST' | 'PATCH' | 'DELETE';
  @Input() displayButtons: boolean = true;
  @Input() formMainTitle?: string;
  @Input() contactInfoTitle?: string;
  @Output() formSubmitted = new EventEmitter<any>();
  @Output() formCancelled = new EventEmitter<any>();
  @ViewChild('phoneFormComp') phoneFormComp!: PhoneFormComponent;
  @ViewChild('addressFormComp') addressFormComp!: AddressFormComponent;
  @Input() displayFields: string[] = [
    'Id', 'Name', 'ProviderType', 'TaxId', 'NotesImportant', 
    'ReimbursementRate', 'FeeScheduleId', 'InNetwork', 'PriorityId', 
    'XrefAddressProviders', 'XrefPhoneProviders', 'SignedPurchaseAgreement', 
    'SignedHipaa', 'SignedW9', 'SpanishSpeaking', 'CheckpointEligible', 
    'ReferringProvider', 'HoldsOwnAr', 'VirtualProvider', 'WebsiteUrl', 
    'W9Name', 'SignedHipaaDate', 'SignedW9Date', 'XrefProviderProviderTypes'
  ];

  // States
  protected readonly formState = computed(() => {
    const currentProvider = this.provider ? this.provider() : this.providerForm.value;
    return {
      isValid: this.providerForm.valid,
      hasChanges: currentProvider !== this.providerForm.value,
      currentValue: currentProvider
    };
  });
  protected readonly errorMessages = computed(() => {
    const errors: Record<string, string> = {};
    Object.keys(this.providerForm.controls).forEach(key => {
      const control = this.providerForm.get(key);
      if (control?.errors) errors[key] = this.getErrorMessage(key);
    });
    return errors;
  });

  // Public variables
  providerAddress: WritableSignal<Address | undefined> = signal(undefined);
  providerPhone: WritableSignal<Phone | undefined> = signal(undefined);
  loadingForm: boolean = true;
  errorMessage: string = '';
  providerHTMLElement: Element | null = null;
  feeSchedules: Array<{ Id: number, Name: string }> = [];
  feeScheduleFields: Object;
  providerTypeFields: Object;
  providerTypes: any;
  marketManagersQuery: Query;
  caseManagersQuery: Query;
  signedPurchaseAgreementOptions = [
    { label: 'Yes', value: 0 },
    { label: 'No', value: 1 },
    { label: 'SCA Only', value: 2 }
  ];
  yesNoOptions = [
    { label: 'Yes', value: 1 },
    { label: 'No', value: 0 },
  ];
  providerForm = new FormGroup<ProviderFormControls>({
    Id: new FormControl<Provider['Id'] | null>(undefined),
    Name: new FormControl<Provider['Name'] | null>(undefined),
    XrefProviderProviderTypes: new FormControl<Provider['XrefProviderProviderTypes'] | null>(undefined),
    TaxId: new FormControl<Provider['TaxId'] | null>(undefined),
    Notes: new FormControl<Provider['Notes'] | null>(undefined),
    NotesImportant: new FormControl<Provider['NotesImportant'] | null>(false), 
    ReimbursementRate: new FormControl<Provider['ReimbursementRate'] | null>(0),
    FeeScheduleId: new FormControl<Provider['FeeScheduleId'] | null>(undefined),
    InNetwork: new FormControl<Provider['InNetwork'] | null>(false), 
    PriorityId: new FormControl<Provider['PriorityId'] | null>(0),
    XrefAddressProviders: new FormControl<Provider['XrefAddressProviders'] | null>(undefined),
    XrefPhoneProviders: new FormControl<Provider['XrefPhoneProviders'] | null>(undefined),
    SignedPurchaseAgreement: new FormControl<Provider['SignedPurchaseAgreement'] | null>(0),
    SignedHipaa: new FormControl<Provider['SignedHipaa'] | null>(0),
    SignedW9: new FormControl<Provider['SignedW9'] | null>(0),
    SignedHipaaDate: new FormControl<Provider['SignedHipaaDate'] | null>(undefined),
    SignedW9Date: new FormControl<Provider['SignedW9Date'] | null>(undefined),
    SpanishSpeaking: new FormControl<Provider['SpanishSpeaking'] | null>(false), 
    CheckpointEligible: new FormControl<Provider['CheckpointEligible'] | null>(false), 
    ReferringProvider: new FormControl<Provider['ReferringProvider'] | null>(false),
    HoldsOwnAr: new FormControl<Provider['HoldsOwnAr'] | null>(false), 
    WebsiteUrl: new FormControl<Provider['WebsiteUrl'] | null>(undefined),
    W9Name: new FormControl<Provider['W9Name'] | null>(undefined),
    VirtualProvider: new FormControl<Provider['VirtualProvider'] | null>(0), 
    IsActive: new FormControl<Provider['IsActive'] | null>(true)
  });
  providerIcons = {
    form: faBuilding,
    provider: faUser,
    type: faUserTag,
    taxId: faIdCard,
    notes: faComments,
    reimbursementRate: faPercent,
    feeSchedule: faList,
    inNetwork: faBuildingCircleCheck,
    priority: faFlag,
    address: faMapLocationDot,
    fax: faFax,
    phone: faPhone,
    purchaseAgreement: faFileContract,
    hipaa: faFileShield,
    w9: faFileInvoiceDollar,
    calendar: faCalendar,
    spanish: faLanguage,
    checkpoint: faCheckSquare,
    referringProvider: faUserMd,
    holdsAr: faMoneyBillTransfer,
    virtual: faLaptop,
    website: faGlobe,
    active: faToggleOn,
    hipaaDate: faCalendar,
    w9Date: faCalendar,
    w9Name: faFileContract,
    virtualProvider: faLaptop,
    holdsOwnAr: faMoneyBillTransfer,
    notesImportant: faFileCircleExclamation,
    spanishSpeaking: faLanguage,
    checkpointEligible: faCheckSquare,
    contactInfo: faAddressBook
  }
  formClasses = {
    formContainer: 'cc-form-container',
    form: 'cc-form flex-column',
    subForm: 'cc-sub-form',
    mainTitle: 'cc-main-title',
    subFormTitle: 'cc-sub-form-title',
    section: 'cc-form-section',
    group: 'cc-form-group row',
    inputContainer: 'cc-input-container',
    inputContainerFullWidth: 'cc-input-container col-12',
    label: 'cc-label',
    input: 'cc-input',
    icon: 'cc-input-icon',
    error: 'cc-input-error',
    actions: 'cc-form-actions',
    twoCol: 'col-12',
    threeCol: 'col-12'
  };

  // Add loading signals at class level
  loadingStatus: WritableSignal<{loading: boolean, message: string}> = signal({
    loading: false,
    message: ''
  });

  /**
   * Lifecycle hooks
   */
  ngOnInit() {
    this.getProviderTypes();
    this.getFeeSchedules();

    this.providerTypeFields = { 
      text: 'Description',
      value: 'Id'
    };

    // Fetch provider types
    this.api.getOdata(APIEndpoints.ProviderTypes)
      .executeQuery(new Query())
      .then((res: any) => {
        if (res && res.result) {
          this.providerTypes = res.result;
        }
      });
  }

  ngAfterViewInit() {
    this.providerHTMLElement = document.querySelector('provider-form');
    this.updateWidth(this.providerHTMLElement?.clientWidth || window.innerWidth);
    this.watchInputElements();
    if (this.phoneFormComp) this.phoneFormComp.displayTitle = false;
  }

  /**
   * Component methods
   */
  async getProviderTypes() {
    const query = new Query();
    this.providerTypeFields = { text: 'Description', value: 'Id' };
    this.providerTypes = this.api.getOdata(APIEndpoints.ProviderTypes);
    this.providerTypes.executeQuery(query).then((result: any) => {
      this.providerTypes = result.result;
    }).catch((error: any) => {
      console.error('Error fetching provider types:', error);
    });
  }

  // Populates the address form with the provider's address
  async getProviderAddress() {
    // using this.formState() automatically detects changes to update signals
    const providerId = this.formState().currentValue?.Id;
    if (!providerId) {
      this.providerAddress.set(undefined);
      this.addressFormComp.addressForm.reset();
      return; // Exit if no providerId
    }
    
    try {
      const endpoint = `${APIEndpoints.XrefAddressProviders}`;
      const expandString = 'Address($select=Id,AddressType,Address1,Address2,City,State,Zip,AddressType),Provider($select=Id)';
      const predicate = new Predicate('Provider/Id', 'equal', providerId);
      const query = new Query().expand(expandString).where(predicate);
      const res: any = await this.api.getOdata(endpoint).executeQuery(query);
      
      // Set address signal - activates change detection to reset form if no address is found
      const address = res.result.length > 0 ? res.result[0].Address : undefined;
      this.providerAddress.set(address);
      this.addressFormComp.address = signal(address);

      // Log empty address record to notify developers
      if (address === undefined) console.log(`No Address found for ${this.providerForm.get('Name')?.value}`);

      return this.providerAddress();

    } catch (error) {
      console.error('Error fetching provider address:', error);
      this.providerAddress.set(undefined);
      throw error; 
    }
  }

  // Populates the phone with the provider's phone
  async getProviderPhone() {
    // using this.formState() automatically detects changes to update signals
    const providerId = this.formState().currentValue?.Id;
    if (!providerId) {
      this.providerPhone.set(undefined);
      this.phoneFormComp.phoneForm.reset();
      return; // Exit if no providerId
    }
    
    try {
      const endpoint = `${APIEndpoints.XrefPhoneProviders}`;
      const expandString = 'Phone($select=Id,PhoneType,PhoneNumber),Provider($select=Id)';
      const predicate = new Predicate('Provider/Id', 'equal', providerId);
      const query = new Query().expand(expandString).where(predicate);
      const res: any = await this.api.getOdata(endpoint).executeQuery(query);
      
      // Set phone signal - activates change detection to reset form if no phone is found
      const phone = res.result.length > 0 ? res.result[0].Phone : undefined;
      this.providerPhone.set(phone);
      this.phoneFormComp.phone = signal(phone);

      // Log empty phone record to notify developers
      if (!phone) console.log(`No Phone found for ${this.providerForm.get('Name')?.value}`);

      return this.providerPhone();

    } catch (error) {
      console.error('Error fetching provider phone:', error);
      this.toast.showError('Error fetching provider phone');
      throw error;
    }
  }
  
  // On form submit
  async onSubmit() {

    try {
      this.loadingStatus.set({ loading: true, message: 'Validating form...' });

      let submitResult: any;
      let phoneResponse: any;
      let addressResponse: any;
      let xrefProviderTypesResponse: any;
      let xrefAddressResponse: any;
      let xrefPhoneResponse: any;
      
      const submitType = this.submitType ? this.submitType : this.providerForm.get('Id')?.value ? 'PATCH' : 'POST';
      const endpoint = this.providerForm.get('Id')?.value ? 
        `${APIEndpoints.Providers}/${this.providerForm.get('Id')?.value}` : 
        `${APIEndpoints.Providers}`;

      // Exit if form or subforms are invalid
      if (this.providerForm.invalid || this.addressFormComp.addressForm.invalid || this.phoneFormComp.phoneForm.invalid) {
        this.toast.showError('Form Invalid');
        return 'error';
      }

      // Handle phone form
      if (!this.phoneFormComp.phoneForm.pristine) {
        this.loadingStatus.set({ loading: true, message: 'Saving phone information...' });
        phoneResponse = await this.phoneFormComp.onSubmit();
        
        if (phoneResponse.error || !phoneResponse) {
          this.toast.showError('Error submitting phone');
          throw new Error('Error submitting phone');
        }
      }

      // Handle address form
      const providerAddressId = this.addressFormComp.addressForm.get('Id')?.value;
      if (!this.addressFormComp.addressForm.pristine) {
        this.loadingStatus.set({ loading: true, message: 'Saving address information...' });
        addressResponse = await this.addressFormComp.onSubmit();
        
        if (addressResponse.error || !addressResponse) {
          this.toast.showError('Error submitting address');
          throw new Error('Error submitting address');
        }
      }

      // Submit main form
      delete this.providerForm.value.XrefProviderProviderTypes;
      this.loadingStatus.set({ loading: true, message: 'Saving provider information...' });
      submitResult = await this.formCRUD.submitForm(this.providerForm, `odata${endpoint}`, submitType);

      // Handle xref entries if needed
      if (this.providerForm.get('XrefProviderProviderTypes')?.value) {
        this.loadingStatus.set({ loading: true, message: 'Saving provider types...' });
        const providerTypes = this.providerForm.get('XrefProviderProviderTypes')?.value;
        
        if (providerTypes && providerTypes.length > 0) {
          // Create all provider type associations in parallel
          xrefProviderTypesResponse = await Promise.all(
            providerTypes.map((providerType: any) => 
              this.api.fetchRequest(
                `odata${APIEndpoints.XrefProviderProviderTypes}`,
                'POST',
                { 
                  ProviderId: submitResult.Id, 
                  ProviderTypeId: providerType.Id || providerType // Handle both object and ID cases
                }
              )
            )
          );
        }
      }

      if (!providerAddressId && addressResponse?.Id && submitResult?.Id) {
        this.loadingStatus.set({ loading: true, message: 'Linking address...' });
        xrefAddressResponse = await this.api.fetchRequest(
          `odata${APIEndpoints.XrefAddressProviders}`, 
          'POST', 
          { AddressId: addressResponse.Id, ProviderId: submitResult.Id }
        );
      }

      const phoneCurrentId = this.phoneFormComp.phoneForm.get('Id')?.value;
      if (!phoneCurrentId && phoneResponse?.Id && submitResult?.Id) {
        this.loadingStatus.set({ loading: true, message: 'Linking phone...' });
        xrefPhoneResponse = await this.api.fetchRequest(
          `odata${APIEndpoints.XrefPhoneProviders}`, 
          'POST', 
          { PhoneId: phoneResponse.Id, ProviderId: submitResult.Id }
        );
      }

      this.formSubmitted.emit(submitResult);
      return submitResult;

    } catch (error) {
      console.error('Error submitting provider:', error);
      this.toast.showError('Error submitting provider');
      throw error;
    } finally {
      this.loadingStatus.set({ loading: false, message: '' });
    }
  }

  // Adds classes to inputs when focused for style updates
  watchInputElements() {
    document.querySelectorAll(`.cc-input-container`).forEach((inputContainer) => {
      const container = inputContainer as HTMLElement;

      container.querySelectorAll('input, span, textarea').forEach((input) => {
        input.addEventListener('focus', () => inputContainer.classList.add('focus'));
        input.addEventListener('blur', () => inputContainer.classList.remove('focus'));
      });
    });
  }

  // Update the layout on window resize
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    const containerWidth = this.providerHTMLElement?.clientWidth || window.innerWidth;
    this.updateWidth(containerWidth);
  }

  // Switches form to 2 columns when parent is larger 1600px
  updateWidth(containerWidth: number) {

    if (containerWidth > 576 && containerWidth < 768) {
      this.formClasses.form = this.formClasses.form.replace('flex-column', 'flex-row flex-wrap');
      this.formClasses.twoCol = this.formClasses.twoCol.replace('col-12', 'col-6');
      this.formClasses.threeCol = this.formClasses.threeCol.replace('col-12', 'col-6');
    } else if (containerWidth > 768) {
      this.formClasses.form = this.formClasses.form.replace('flex-column', 'flex-row flex-wrap');
      this.formClasses.twoCol = this.formClasses.twoCol.replace('col-12', 'col-6');
      this.formClasses.threeCol = this.formClasses.threeCol.replace('col-12', 'col-4');
    } else {
      this.formClasses.form = this.formClasses.form.replace('flex-row', 'flex-column');
      this.formClasses.twoCol = this.formClasses.twoCol.replace('col-6', 'col-12');
      this.formClasses.threeCol = this.formClasses.threeCol.replace('col-4', 'col-12');
    }
  }

  // Returns appropriate error message for form control
  getErrorMessage(controlName: string): string {
    let message = '';
    const control = this.providerForm.get(controlName);
    if (control?.errors) {
      if (control.errors['required']) message = 'This field is required';
      if (control.errors['email']) message = 'Invalid email format';
      if (control.errors['invalidPhone']) message = 'Invalid phone number (10 digits required)';
      if (control.errors['serverError']) message = control.errors['serverError'].message;
    };
    this.errorMessage = message;
    return message;
  }

  async getFeeSchedules() {
    const endpoint = `${APIEndpoints.FeeSchedules}`;
    const query = new Query().select('Id,Name,IsActive,CreatedAt,UpdatedAt'); // Adjust fields as needed
  
    try {
      const response: any = await this.api.getOdata(endpoint).executeQuery(query);
  
      // Access the 'result' array directly from the response
      this.feeSchedules = response.result.map((item: any) => ({
        Id: item.Id,
        Name: item.Name
      }));
      this.feeScheduleFields = { text: 'Name', value: 'Id' };
    } catch (error) {
      console.error('Error fetching fee schedules:', error);
    }
  }
}
