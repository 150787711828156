<div id="scheduler">

  <div class="row">

    <ng-container *ngIf="loadLocations() || loadModalities()">
      <div class="col-12 col-md-2 filters">
        <div class="e-card categories">
    
          <ejs-accordion #scheduleFilter>
            <e-accordionitems>
              <e-accordionitem [expanded]="true" header="Locations" *ngIf="loadLocations()">
                <ng-template #content>
                  <scheduler-locations #locationsComponent />
                </ng-template>
              </e-accordionitem>
    
              <e-accordionitem [expanded]="true" header="Modality Types" *ngIf="loadModalities()">
                <ng-template #content>
                  <scheduler-modalities #modalitiesComponent />
                </ng-template>
              </e-accordionitem>

              <e-accordionitem [expanded]="true" header="Appointment List" *ngIf="includeAppointmentsList">
                <ng-template #content>
                  <scheduler-appointments-list 
                    #appointmentsListComponent
                    (appointmentSelected)="onAppointmentSelected($event)" />
                </ng-template>
              </e-accordionitem>
            </e-accordionitems>
          </ejs-accordion>
    
        </div>
      </div>
    </ng-container>

    <div [class]="schedulerClass">
      <scheduler-calendar #schedulerCalendarComponent [componentContextId]="'main-appointment-calendar'" [caseFile]="caseFile" [inputAppointments]="appointments" [excludeFields]="excludeFields" />
    </div>

  </div>
  
</div>