// Angular
import { Component, OnInit, ViewEncapsulation } from '@angular/core';

// Internal
import { UserPreferencesService } from '@services/user/user-preferences.service';

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  constructor(private user: UserPreferencesService) {}

  title = 'casecompass-frontend-prototype';

  ngOnInit(): void {
    this.user.applyDarkMode();
    this.user.applyLayoutMode();
    this.user.loadTheme();
  }
}
