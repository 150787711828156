// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Internal
import { AuthGuard } from '@services/auth/auth-guard.guard';
// Internal - @pages
import { DashboardComponent } from '@pages/dashboard/dashboard.component';
import { ProfileComponent } from '@features/profile/profile.component';
import { LayoutWrapper } from '@layout/layout-wrapper.component';
import { RootPageComponent } from '@pages/root/root-page.component';
import { TestPageComponent } from '@pages/test/test.component';
import { Test2Component } from '@pages/test2/test2.component';
import { PageNotFoundComponent } from '@pages/page-not-found/page-not-found.component';
import { EditUsersComponent } from '@pages/edit-users/edit-users.component';
import { ItemViewComponent } from '@features/case-file/item-view/item-view.component';
import { MultiFileComponent } from '@features/case-file/multi-file/multi-file.component';
import { ProcedureCodesComponent } from '@root/app/features/procedure-codes/components/procedure-codes.component';
import { WorkItemsMondayComponent } from '@app/modules/monday-integration/components/work-items-monday/work-items-monday.component';
import { WorkItemsGridComponent } from '@features/work-items/components/work-items-grid/work-items-grid.component';
// Internal - @components
import { SchedulerComponent } from '@features/scheduler/scheduler.component';
import { UsersGridComponent } from '@shared/features/users/components/users-grid.component';
import { RolesGridComponent } from '@shared/features/roles/components/roles-grid/roles-grid.component';
import { CaseFilesGridComponent } from '@features/case-files/components/case-files-grid/case-files-grid.component';
import { LawFirmsGridComponent } from '@features/law-firms/law-firms-grid/law-firms-grid.component';
import { PatientsGridComponent } from '@features/patients/components/patients-grid/patients-grid.component';
import { ProvidersGridComponent } from '@features/providers/components/grid/providers-grid.component';
import { FeeScheduleGridComponent } from '@grids/fee-schedule-grid/fee-schedule-grid.component';
import { ReductionRequestsGridComponent } from '@features/reduction-requests/components/reduction-requests-grid/reduction-requests-grid.component';
import { IntakesGridComponent } from '@grids/intakes-grid/intakes-grid.component';
import { RemittanceReportComponent } from '@features/remittance-report/remittance-report.component';
import { FileHubComponent } from '@features/file-hub/file-hub.component';
import { ProviderMapPageComponent } from '@pages/provider-map-page/provider-map-page.component';
import { LawFirmDetailComponent } from '@features/law-firm/ui/law-firm-detail/law-firm-detail.component';
import { ProviderDetailsComponent } from '@features/providers/provider-details/provider-details.component';
import { PatientDetailComponent } from '@features/patients/components/patients-detail/patient-detail.component';
import { UnauthorizedComponent } from '@components/unauthorized/unauthorized.component';
import { AccountingComponent } from '@features/accounting/components/accounting.component';
import { AiAgentComponent } from '@features/ai-agent/components/ai-agent.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutWrapper,
    children: [
      { title: 'Case Compass', path: '', component: RootPageComponent },
      { title: 'Dashboard', path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
      {
        title: 'AI Agent',
        path: 'agent',
        component: AiAgentComponent,
        canActivate: [AuthGuard],
        data: { rights: ['getCaseFiles'] },
      },
      {
        title: 'AI Agent',
        path: 'agent/:caseId',
        component: AiAgentComponent,
        canActivate: [AuthGuard],
        data: { rights: ['getCaseFiles'] },
      },
      {
        title: 'Remittance',
        path: 'remittance',
        component: RemittanceReportComponent,
        canActivate: [AuthGuard],
        data: { rights: ['getRemittance'] },
      },
      {
        title: 'Scheduler',
        path: 'scheduler',
        component: SchedulerComponent,
        canActivate: [AuthGuard],
        data: { rights: ['getAppointments'] },
      },
      {
        title: 'Provider Map',
        path: 'provider-map',
        component: ProviderMapPageComponent,
        canActivate: [AuthGuard],
        data: { rights: ['getProviders'] },
      },
      {
        title: 'Reduction Requests',
        path: 'reduction-requests',
        component: ReductionRequestsGridComponent,
        canActivate: [AuthGuard],
        data: { rights: ['getReductionRequests'] },
      },
      {
        title: 'Intakes',
        path: 'file-intake-report',
        component: IntakesGridComponent,
        canActivate: [AuthGuard],
        data: { rights: ['getIntakes'] },
      },
      {
        title: 'Procedure Codes',
        path: 'procedure-codes',
        component: ProcedureCodesComponent,
        canActivate: [AuthGuard],
        data: { rights: ['getProcedureCodes'] },
      },
      {
        title: 'Monday Integration',
        path: 'work-items-monday',
        component: WorkItemsMondayComponent,
        canActivate: [AuthGuard],
        data: { rights: ['getMondayIntegration'] },
      },
      {
        title: 'Tasks',
        path: 'tasks',
        component: WorkItemsGridComponent,
        canActivate: [AuthGuard],
        data: { rights: ['getWorkItems'] },
      },
      { title: 'Test', path: 'test', component: TestPageComponent, canActivate: [AuthGuard] },
      { title: 'Test 2', path: 'test2', component: Test2Component, canActivate: [AuthGuard] },
      {
        title: 'Roles',
        path: 'users-roles',
        component: RolesGridComponent,
        canActivate: [AuthGuard],
        data: { rights: ['getRoles'] },
      },
      { title: 'Profile', path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
      {
        title: 'Edit User',
        path: 'edit-user/:id',
        component: EditUsersComponent,
        canActivate: [AuthGuard],
        data: { rights: ['editUsers'] },
      },
      {
        title: 'Users',
        path: 'users',
        canActivate: [AuthGuard],
        data: { rights: ['getUsers'] },
        children: [
          { path: '', component: UsersGridComponent },
          { path: ':id', component: ItemViewComponent },
        ],
      },
      {
        title: 'Case Files',
        path: 'case-files',
        canActivate: [AuthGuard],
        data: { rights: ['getCaseFiles'] },
        children: [
          { path: '', component: CaseFilesGridComponent },
          { path: 'hub', component: FileHubComponent },
          { path: ':FileNumber', component: MultiFileComponent },
        ],
      },
      {
        title: 'Law Firms',
        path: 'law-firms',
        canActivate: [AuthGuard],
        data: { rights: ['getLawFirms'] },
        children: [
          { path: '', component: LawFirmsGridComponent },
          { path: ':id', component: ItemViewComponent },
        ],
      },
      {
        path: 'law-firm-detail/:id',
        component: LawFirmDetailComponent,
        canActivate: [AuthGuard],
        data: { rights: ['getLawFirms'] },
      },
      {
        title: 'Patients',
        path: 'patients',
        canActivate: [AuthGuard],
        data: { rights: ['getPatients'] },
        children: [
          { path: '', component: PatientsGridComponent },
          { path: ':id', component: ItemViewComponent },
        ],
      },
      {
        path: 'patient-detail/:id',
        component: PatientDetailComponent,
        canActivate: [AuthGuard],
        data: { rights: ['getPatients'] },
      },
      {
        title: 'Providers',
        path: 'providers',
        canActivate: [AuthGuard],
        data: { rights: ['getProviders'] },
        children: [
          { path: '', component: ProvidersGridComponent },
          { path: ':id', component: ItemViewComponent },
        ],
      },
      {
        path: 'provider-detail/:id',
        component: ProviderDetailsComponent,
        canActivate: [AuthGuard],
        data: { rights: ['getProviders'] },
      },
      {
        title: 'Fee Schedules',
        path: 'fee-schedules',
        canActivate: [AuthGuard],
        data: { rights: ['getFeeSchedules'] },
        children: [
          { path: '', component: FeeScheduleGridComponent },
          { path: ':id', component: ItemViewComponent },
        ],
      },
      {
        title: 'Accounting',
        path: 'accounting',
        component: AccountingComponent,
        canActivate: [AuthGuard],
        data: {
          rights: ['getInvoices', 'getDeposits'],
        },
      },
    ],
  },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: '**', pathMatch: 'full', component: PageNotFoundComponent },
  { path: 'logout', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
