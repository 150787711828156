// Angular
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation, computed, inject, signal } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SimpleChanges } from '@angular/core';

// Material
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';

// Core
import { ComponentBase } from '@core/base/component.base';

// Services
import { BaseFormService, FormConfig, FormState } from './base-form.service';
import { BaseFormHooks } from './base-form.hooks';

/**
 * Interface representing form data structure
 */
interface FormData {
  [key: string]: unknown;
}

/**
 * Base form component that provides common form functionality and lifecycle management.
 * This component handles form initialization, state management, and cleanup.
 *
 * @implements {OnInit}
 * @implements {OnChanges}
 * @implements {AfterViewInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'base-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatCardModule,
    MatDividerModule,
    MatGridListModule,
    MatButtonModule,
    MatIconModule
  ],
  templateUrl: './base-form.component.html',
  styleUrls: ['./base-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BaseFormComponent extends ComponentBase implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  @Input() config?: FormConfig;
  @Input() initialData?: FormData;
  @Input() mode?: 'create' | 'edit';
  @Input() formGroup: FormGroup;
  @Input() displayButtons = true;
  @ViewChild('form') form!: FormGroup;

  /** Entity ID for edit mode */
  @Input() entityId?: number;

  /** Event emitted when form is submitted */
  @Output() formSubmit = new EventEmitter<FormData>();

  /** Event emitted when form is cancelled */
  @Output() formCancel = new EventEmitter<void>();

  /** Event emitted when form is reset */
  @Output() formReset = new EventEmitter<void>();

  protected readonly hooks = inject(BaseFormHooks);
  protected readonly service = inject(BaseFormService);
  protected readonly state = (computed(() => this.service.state));
  protected initialFormValue: FormData | null = null;

  /**
   * Initializes the form component.
   * Sets up form configuration and initializes form data based on mode and inputs.
   */
  ngOnInit(): void {
    // Set up form configuration if provided
    if (this.config) {
      this.service.setFormData({
        id: this.entityId,
        initialData: this.initialData,
        validation: this.config.validation
      });
    }

    if (this.initialData) {
      // If initialData is provided, use it to initialize the form
      this.initialFormValue = this.initialData;
      this.hooks.initializeFormWithData(this.formGroup, this.initialData);
    } else {
      // Create mode - initialize empty form
      this.hooks.initializeEmptyForm(this.formGroup);
    }
  }

  /**
   * Handles changes to component inputs.
   * Updates form state and data when inputs change.
   *
   * @param changes - SimpleChanges object containing changed properties
   */
  ngOnChanges(changes: SimpleChanges): void {
    // Handle changes to config
    if (changes['config'] && !changes['config'].firstChange) {
      const newConfig = changes['config'].currentValue;
      this.service.setFormData({
        id: this.entityId,
        initialData: this.initialData,
        validation: newConfig.validation
      });
    }

    // Handle changes to initialData
    if (changes['initialData'] && !changes['initialData'].firstChange) {
      const newData = changes['initialData'].currentValue;
      this.initialFormValue = newData;
      this.hooks.initializeFormWithData(this.formGroup, newData);
    }

    // Handle changes to mode
    if (changes['mode'] && !changes['mode'].firstChange) {
      const newMode = changes['mode'].currentValue;
      this.service.FormState.UI.Mode.set(newMode);
    }
  }

  /**
   * Performs post-view initialization.
   * Sets up form references and initializes UI state.
   */
  ngAfterViewInit(): void {
    // Set up form reference in service
    this.service.FormState.Form.Component.set(this.form);

    // Set up form ID if available
    if (this.entityId) {
      this.service.FormState.UI.FormId.set(this.entityId);
    }

    // Initialize form state based on current mode
    const currentMode = this.mode || 'create';
    this.service.FormState.UI.Mode.set(currentMode);
  }

  /**
   * Performs cleanup when component is destroyed.
   * Resets all form state and clears references.
   */
  ngOnDestroy(): void {
    // Reset form state
    this.service.resetState();

    // Reset form group
    if (this.formGroup) {
      this.formGroup.reset();
    }

    // Clear initial form value
    this.initialFormValue = null;

    // Clear form reference from service
    this.service.FormState.Form.Component.set(undefined);

    // Clear UI state
    this.service.FormState.UI.FormId.set(undefined);
    this.service.FormState.UI.FormName.set(undefined);
    this.service.FormState.UI.IsSubmitting.set(false);
    this.service.FormState.UI.Mode.set(undefined);

    // Clear loading states
    this.service.FormState.Loading.Submit.set(false);
    this.service.FormState.Loading.Load.set(false);

    // Clear data states
    this.service.FormState.Data.InitialData.set(undefined);
    this.service.FormState.Data.CurrentData.set(undefined);
    this.service.FormState.Data.ValidationErrors.set({});

    // Clear form settings
    this.service.FormState.Form.SetupArgs.set(undefined);
    this.service.FormState.Form.Settings.set(undefined);

    // Clear any errors
    this.service.FormState.Errors.set({});
  }

  /**
   * Handles form submission
   */
  onSubmit(): void {
    if (this.formGroup.valid) {
      this.formSubmit.emit(this.formGroup.value);
    }
  }

  /**
   * Handles form cancellation
   */
  onCancel(): void {
    this.formCancel.emit();
  }

  /**
   * Handles form reset
   */
  onReset(): void {
    this.formReset.emit();
  }
}