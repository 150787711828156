// Angular
import { Component, Input, signal, SimpleChanges, ViewChild, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';

// 3rd Party
import { AccordionModule, TabModule } from '@syncfusion/ej2-angular-navigations';
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';

// Models
import { Provider, LawFirm, Patient } from '@models/data-contracts';
import { APIEndpoints } from '@models/api/Endpoints';

// Components
import { LawFirmFormComponent } from '../../forms/law-firm-form/law-firm-form.component';
import { ProviderFormComponent } from "../../forms/provider-form/provider-form.component";
import { TabItem } from './tabs/tabs.interface';
import { PatientFormComponent } from '../../forms/patient-form/patient-form.component';
import { ToastMessageService } from '@services/toast-message/toast-message.service';

interface Detail {
  label: string;
  value: string;
}

@Component({
  selector: 'app-account-details',
  standalone: true,
  imports: [
    CommonModule,
    TabModule,
    AccordionModule,
    DialogModule,
    ButtonModule,
    LawFirmFormComponent,
    ProviderFormComponent,
    PatientFormComponent,
    
],
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss'],
  
})
export class AccountDetailsComponent {
  
  @Input() expandableDetails: Detail[] = [];
  @Input() details: Detail[][] = [];
  @Input() tabs: TabItem[] = [];
  @Input() isLoading: boolean = false;
  @Input() entityType: APIEndpoints;
  @Input() entityId!: number;
  @Input() payload: any;
  @ViewChild('editLawFirmDialog') editLawFirmDialog!: DialogComponent;
  @ViewChild('lawFirmFormComp') lawFirmFormComp!: LawFirmFormComponent;
  @ViewChild('editProviderDialog') editProviderDialog!: DialogComponent;
  @ViewChild('providerFormComp') providerFormComp!: ProviderFormComponent;
  @ViewChild('editPatientDialog') editPatientDialog!: DialogComponent;
  @ViewChild('patientFormComp') patientFormComp!: PatientFormComponent;

  constructor(  
    private toast: ToastMessageService
  ) { }
  
  editEndpoint = '';
  isExpanded: boolean = true;
  @ViewChild('editDialog') editDialog!: DialogComponent;

  public editLawFirmDialogVisibility: boolean = false;
  public editProviderDialogVisibility: boolean = false;
  public editPatientDialogVisibility: boolean = false;
  lawFirmSignal: WritableSignal<LawFirm | undefined> = signal<LawFirm | undefined>(undefined);
  providerSignal: WritableSignal<Provider | undefined> = signal<Provider | undefined>(undefined);
  patientSignal: WritableSignal<Patient | undefined> = signal<Patient | undefined>(undefined);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['expandableDetails']) {
      this.expandableDetails = changes['expandableDetails'].currentValue;
    }

    if (changes['details']) {
      this.details = changes['details'].currentValue;
    }

    if (changes['payload']) {
      this.payload = changes['payload'].currentValue;
    }
  }

  getEntityTypeLabel(): string {
    switch (this.entityType) {
        case '/Lawfirms':
            return 'Law Firm';
        case '/Providers':
            return 'Provider';
        case '/Patients':
            return 'Patient';
        default:
            return (this.entityType || '').replace('/', '');
    }
  }

  openEditDialog(): void {
    this.editEndpoint = this.entityType.replace(/^\//, '') + '/odata/' + this.entityId;
    if (this.entityType === APIEndpoints.Lawfirms) {
        this.lawFirmSignal.set(this.payload);
        this.editLawFirmDialog.show();
    } else if (this.entityType === APIEndpoints.Providers) {
        this.providerSignal.set(this.payload);
        this.editProviderDialog.show();
    } else if (this.entityType === APIEndpoints.Patients) {
        this.patientSignal.set(this.payload as Patient);
        this.editPatientDialog.show();
    }
  }

  refreshGrid() {
    if (this.editLawFirmDialog.visible) {
      this.toast.showSuccess('Law Firm updated successfully');
    } else if (this.editProviderDialog.visible) {
      this.toast.showSuccess('Provider updated successfully');
    } else if (this.editPatientDialog.visible) {
      this.toast.showSuccess('Patient updated successfully');
    }

    setTimeout(() => { window.location.reload(); }, 1000);
  }
}