// Angular
import { Component, signal, ViewChild, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

// 3rd Party - Syncfusion
import { CommandClickEventArgs, Column } from '@syncfusion/ej2-grids';
import { DialogComponent, DialogAllModule } from '@syncfusion/ej2-angular-popups';
import { Query } from '@syncfusion/ej2-data';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { ApiService } from '@services/api/api.service';
import { AuditLogService } from '@shared/services/audit-logs/audit-log-service';
import { AuditLogsComponent } from '@ui/audit-logs/audit-logs.component';
import { LoadingModule } from '@modules/loading.module';
import { ProviderFormComponent } from '@components/forms/provider-form/provider-form.component';
import { BulkEditProvidersComponent } from '@forms/bulk-edit-forms/bulk-edit-providers/bulk-edit-providers.component';
import { Provider } from '@models/data-contracts';
import { ComponentBase } from '@core/base/component.base';
import { ErrorSeverity } from '@core/error/error.types';
import { Subject } from 'rxjs';
import { BaseGridComponent } from '@shared/components/base-grid/base-grid.component';
import { BaseGridService } from '@shared/components/base-grid/services/state.service';
import { SetGridDataArgs } from '@shared/components/base-grid/models/grid.models';
import { BaseGridHooks } from '@shared/components/base-grid/services/hooks.service';
import { ProvidersGridService } from '../../services/providers-grid.service';

// Error constants
const ERRORS = {
  LOAD_AUDIT_LOGS: {
    message: 'Failed to load audit logs. Please try again.',
    technical: 'Error loading audit logs for provider'
  },
  CLOSE_LOGS_DIALOG: {
    message: 'Error closing logs dialog',
    technical: 'Error occurred while closing logs dialog'
  },
  BULK_EDIT_SELECTION: {
    message: 'Please select at least two providers to bulk edit.',
    technical: 'Attempted bulk edit with insufficient selection'
  }
};

@Component({
  selector: 'providers-grid',
  standalone: true,
  imports: [
    CommonModule,
    DialogAllModule,
    LoadingModule,
    AuditLogsComponent,
    ProviderFormComponent,
    BulkEditProvidersComponent,
    BaseGridComponent
  ],
  providers: [
    BaseGridHooks,
    BaseGridService
  ],
  templateUrl: './providers-grid.component.html',
  styleUrl: './providers-grid.component.scss'
})
export class ProvidersGridComponent extends ComponentBase {
  // ViewChild references
  @ViewChild('providersGrid') providersGrid!: BaseGridComponent;
  @ViewChild('providerDialog') providerDialog!: DialogComponent;
  @ViewChild('providerFormComponent') providerFormComponent!: ProviderFormComponent;
  @ViewChild('logsDialog') logsDialog: any;
  @ViewChild('bulkEditProvidersComponent') bulkEditProvidersComponent!: BulkEditProvidersComponent;

  // State management
  showActive: WritableSignal<boolean> = signal(true);
  selectedProvider: WritableSignal<Provider | undefined> = signal(undefined);
  auditLogs: any[] = [];
  auditLogsLoading = false;

  // Dialog buttons
  readonly providerDialogButtons: Object[] = [
    { click: this.cancelProviderForm.bind(this), buttonModel: { content: 'Cancel', cssClass: 'e-outline' } },
    { click: this.submitProviderForm.bind(this), buttonModel: { content: 'Save', cssClass: 'e-primary', isPrimary: true } }
  ];

  readonly logsDialogButtons: Object[] = [
    { click: this.closeLogsDialog.bind(this), buttonModel: { content: 'Close', cssClass: 'e-primary' } }
  ];

  // Grid settings
  providersGridSettings: SetGridDataArgs = {
    endpoint: this.providersGridService.ENDPOINT,
    name: 'Providers',
    useRoundedEdges: true,
    activeRecordsToggle: true,
    bulkEditing: true,
    query: this.providersGridService.DEFAULT_QUERY,
    gridSettings: {
      columns: [
        { type: 'checkbox' },
        { field: 'Id', headerText: 'ID' },
        { field: 'Name', headerText: 'Name', template: '<a href="/provider-detail/${Id}" class="link" (click)="onProviderClicked($event)"><span>${Name}</span></a>' },
        { field: 'XrefProviderProviderTypes', headerText: 'Provider Types',
          valueAccessor: (field: any, data: Provider) => {
            return data.XrefProviderProviderTypes?.map(x => x.ProviderType?.Description).filter(Boolean).join(', ') || '';
          }
        },
        { field: 'Notes', headerText: 'Notes' },
        { field: 'SpanishSpeaking', headerText: 'Spanish Speaking', visible: false },
        { field: 'ReimbursementRate', headerText: 'Purchase Rate', template: '<div>${ReimbursementRate}%</div>' },
        { field: 'IsActive', headerText: 'Is Active' },
        {
          field: 'XrefAddressProviders',
          headerText: 'City',
          valueAccessor: (field: any, data: Provider) => data.XrefAddressProviders?.[0]?.Address?.City || ''
        },
        {
          field: 'XrefAddressProviders',
          headerText: 'State',
          valueAccessor: (field: any, data: Provider) => data.XrefAddressProviders?.[0]?.Address?.StateNavigation?.Name || ''
        },
        { field: 'IsActive', headerText: 'Active' },
        { type: 'commands' }
      ],
      toolbarClick: ($event: any) => this.customToolbarClick($event),
      commandClick: ($event: CommandClickEventArgs) => this.onCommandClick($event)
    }
  };

  private destroy$ = new Subject<void>();

  constructor(
    private auditLogService: AuditLogService,
    private router: Router,
    private gridService: BaseGridService,
    private providersGridService: ProvidersGridService
  ) {
    super();
  }

  customToolbarClick(args: any): void {
    if (args.item.text === 'Add') {
      this.selectedProvider.set(undefined);
      setTimeout(() => {
        if (this.providerDialog) {
          this.providerDialog.header = 'Add Provider';
          if (this.providerFormComponent?.addressFormComp) {
            this.providerFormComponent.addressFormComp.displayTitle = false;
          }
          this.providerDialog.show();
        }
      });
    } else if (args.item.text === 'Bulk Edit') {
      this.getSelected();
    } else if (args.item.id === 'toggleActive') {
      this.showActive.set(!this.showActive());
      this.providersGridSettings.query = new Query()
        .expand(['XrefAddressProviders($expand=Address($expand=StateNavigation))', 'XrefProviderProviderTypes($expand=ProviderType)'])
        .where('IsActive', 'equal', this.showActive());

      this.gridService.setGridData(this.providersGridSettings);
    }
  }

  async onCommandClick(args: CommandClickEventArgs): Promise<void> {
    const provider = args.rowData as Provider;

    if (args.commandColumn?.type === 'Edit') {
      args.cancel = true;
      this.selectedProvider.set(provider);
      setTimeout(() => {
        if (this.providerDialog) {
          this.providerDialog.header = `Editing: ${provider.Name}`;
          if (this.providerFormComponent?.addressFormComp) {
            this.providerFormComponent.addressFormComp.displayTitle = false;
          }
          this.providerDialog.show();
        }
      });
    } else if (provider?.Id && args.commandColumn?.title === 'Logs') {
      this.selectedProvider.set(provider);
      this.showLogsDialog();
    }
  }

  showLogsDialog(): void {
    if (!this.selectedProvider()) return;

    this.withErrorHandling(async () => {
      this.auditLogsLoading = true;

      if (this.logsDialog) {
        this.logsDialog.show();
      }

      const provider = this.selectedProvider();
      if (!provider || provider.Id === undefined) {
        throw new Error('No provider selected or provider ID is undefined');
      }

      const logs = await this.auditLogService.getAuditLogs(Number(provider.Id), 'Provider');

      if (!logs) {
        throw new Error('No audit logs returned from service');
      }

      this.auditLogs = this.auditLogService.mapAuditDataToLogFormat(logs);
      this.auditLogsLoading = false;

      return logs;
    }, 'ProvidersGridComponent.showLogsDialog', ERRORS.LOAD_AUDIT_LOGS.message);
  }

  closeLogsDialog(): void {
    this.withErrorHandling(async () => {
      if (this.logsDialog) {
        this.logsDialog.hide();
        this.auditLogs = [];
      }
    }, 'ProvidersGridComponent.closeLogsDialog', ERRORS.CLOSE_LOGS_DIALOG.message);
  }

  onProviderClicked(data: any): void {
    this.router.navigate(['/provider-detail', data.Id]);
  }

  getSelected() {
    const selectedRecords = this.providersGrid.grid.getSelectedRecords();
    if (selectedRecords && selectedRecords.length > 1) {
      this.bulkEditProvidersComponent.selectedRows = selectedRecords;
      this.bulkEditProvidersComponent.showModal();
    } else {
      this.handleError(new Error('Insufficient selection'), {
        context: 'ProvidersGridComponent.getSelected',
        userMessage: ERRORS.BULK_EDIT_SELECTION.message,
        severity: ErrorSeverity.Warning
      });
    }
  }

  async submitProviderForm() {
    await this.providerFormComponent.onSubmit().then(async (res) => {
      if (res && res.Id) {
        this.providersGridSettings.query = new Query()
          .expand(['XrefAddressProviders($expand=Address($expand=StateNavigation))', 'XrefProviderProviderTypes($expand=ProviderType)'])
          .where('IsActive', 'equal', this.showActive());

        this.gridService.setGridData(this.providersGridSettings);
        this.providerDialog.hide();
      }
    });
  }

  cancelProviderForm() {
    this.providerFormComponent.providerForm.reset();
    this.providerFormComponent.addressFormComp.addressForm.reset();
    this.selectedProvider.set(undefined);
    this.providerDialog.hide();
  }

  private async withErrorHandling<T>(
    operation: () => Promise<T>,
    context: string,
    userMessage: string
  ): Promise<T | undefined> {
    try {
      return await operation();
    } catch (error) {
      this.handleError(error, {
        context,
        userMessage,
        severity: ErrorSeverity.Error
      });
      return undefined;
    }
  }
}

