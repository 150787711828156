const esDict = {
  'Account recovery requires verified contact information': 'La recuperación de la cuenta requiere información de contacto verificada',
  'Authenticator App (TOTP)': 'Aplicación de autenticación (TOTP)',
  'Back to Sign In': 'Volver a inicio de sesión',
  'Change Password': 'Cambiar contraseña',
  Changing: 'Cambiando',
  Code: 'Código',
  'Code *': 'Código *',
  'Confirm Email Code': 'Confirmar el código de correo electrónico',
  'Confirm Password': 'Confirmar contraseña',
  'Confirm Sign Up': 'Confirmar registro',
  'Confirm SMS Code': 'Confirmar el código de SMS',
  'Confirm TOTP Code': 'Confirmar código TOTP',
  Confirm: 'Confirmar',
  'Confirmation Code': 'Código de confirmación',
  Confirming: 'Confirmando',
  'Create a new account': 'Crear una cuenta nueva',
  'Create Account': 'Crear cuenta',
  'Creating Account': 'Creando cuenta',
  'Dismiss alert': 'Descartar alerta',
  Email: 'Email',
  'Email Message': 'Mensaje de correo electrónico',
  'Enter your code': 'Ingrese el código',
  'Enter your Email': 'Escriba su Email',
  'Enter your email': 'Escriba su email',
  'Enter your Password': 'Escriba su Contraseña',
  'Enter your phone number': 'Ingrese el número de teléfono',
  'Enter your username': 'Ingrese el nombre de usuario',
  'Forgot your password?': '¿Olvidó su contraseña?',
  'Hide password': 'Ocultar contraseña',
  'It may take a minute to arrive': 'Es posible que tarde un minuto en llegar',
  Loading: 'Cargando',
  'Multi-Factor Authentication': 'Autenticación multifactor',
  'Multi-Factor Authentication Setup': 'Configuración de autenticación multifactor',
  'New password': 'Nueva contraseña',
  or: 'o',
  Password: 'Contraseña',
  'Phone Number': 'Número de teléfono',
  'Resend Code': 'Reenviar código',
  'Reset your password': 'Restablecer su contraseña',
  'Reset your Password': 'Restablecer su Contraseña',
  'Select MFA Type': 'Seleccionar el tipo de MFA',
  'Send code': 'Enviar código',
  'Send Code': 'Enviar código',
  Sending: 'Enviando',
  'Setup Email': 'Configurar correo electrónico',
  'Setup TOTP': 'Configurar TOTP',
  'Show password': 'Mostrar contraseña',
  'Sign in to your account': 'Iniciar sesión en tu cuenta',
  'Sign In with Amazon': 'Iniciar Sesión con Amazon',
  'Sign In with Apple': 'Iniciar Sesión con Apple',
  'Sign In with Facebook': 'Iniciar Sesión con Facebook',
  'Sign In with Google': 'Iniciar Sesión con Google',
  'Sign in': 'Iniciar sesión',
  'Sign In': 'Iniciar Sesión',
  'Signing in': 'Iniciando sesión',
  Skip: 'Omitir',
  Submit: 'Enviar',
  Submitting: 'Enviando',
  'Text Message (SMS)': 'Mensaje de texto (SMS)',
  Username: 'Nombre de usuario',
  'Verify Contact': 'Verificar contacto',
  Verify: 'Verificar',
  'We Emailed You': 'Le hemos enviado un correo electrónico',
  'We Sent A Code': 'Hemos enviado un código',
  'We Texted You': 'Le hemos enviado un mensaje de texto',
  'Your code is on the way. To log in, enter the code we emailed to': 'El código está en camino. Para iniciar sesión, escriba el código que hemos enviado por correo electrónico a',
  'Your code is on the way. To log in, enter the code we sent you': 'El código está en camino. Para iniciar sesión, escriba el código que le hemos enviado',
  'Your code is on the way. To log in, enter the code we texted to': 'El código está en camino. Para iniciar sesión, escriba el código que hemos enviado por mensaje de texto a',
  // Additional translations provided by customers
  'An account with the given email already exists.': 'Ya existe una cuenta con el correo ingresado.',
  'Confirm a Code': 'Confirmar un código',
  'Confirm Sign In': 'Confirmar inicio de sesión',
  'Forgot Password': 'Olvidé mi contraseña',
  'Incorrect username or password.': 'Nombre de usuario o contraseña incorrecta',
  'Enter your Family Name': 'Escriba su apellido',
  'Enter your Given Name': 'Escriba su nombre',
  'Given Name': 'Nombre',
  'Family Name': 'Apellido',
  'Reset Password': 'Restablecer contraseña',
  'Please confirm your Password': 'Confirme su contraseña',
  'Invalid password format': 'Formato de contraseña inválido',
  'Invalid phone number format': 'Formato de número de teléfono inválido',
  'Loading...': 'Cargando...',
  'New Password': 'Nueva contraseña',
  'Resend a Code': 'Reenviar un código',
  'Sign Out': 'Cerrar sesión',
  'Sign Up with Amazon': 'Crear cuenta con Amazon',
  'Sign Up with Apple': 'Crear cuenta con Apple',
  'Sign Up with Facebook': 'Crear cuenta con Facebook',
  'Sign Up with Google': 'Crear cuenta con Google',
  'Sign Up': 'Crear cuenta',
  'User already exists': 'El usuario ya existe',
  'User does not exist': 'El usuario no existe',
  'Username/client id combination not found.': 'El usuario no existe',
  'Username cannot be empty': 'El nombre de usuario no puede estar vacío',
  'Your passwords must match': 'Las contraseñas deben coincidir',
  'Password must have at least 8 characters': 'La contraseña debe tener al menos 8 caracteres',
  'Password did not conform with policy: Password must have uppercase characters': 'La contraseña debe tener al menos un carácter en mayúscula',
  'Password did not conform with policy: Password must have numeric characters': 'La contraseña debe tener al menos un carácter numérico',
  'Password did not conform with policy: Password must have symbol characters': 'La contraseña debe tener al menos un símbolo',
  'Password did not conform with policy: Password must have lowercase characters': 'La contraseña debe tener al menos un carácter en minúsculas',
  'Invalid verification code provided, please try again.': 'Código de verificación no válido, inténtelo de nuevo.',
  'Attempt limit exceeded, please try after some time.': 'Número máximo de intentos excedido, por favor inténtelo de nuevo más tarde.',
  'A network error has occurred.': 'Se ha producido un error de red.'
};
export { esDict };