import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabCardComponent } from '../tab-card/tab-card.component';
import { GridTemplateComponent } from '@grids/grid-template/grid-template.component';
import { FinancialTab } from '../financial-tab/financial-tab.component';

@Component({
  selector: 'payables-tab',
  standalone: true,
  imports: [
    CommonModule,
    TabCardComponent,
    GridTemplateComponent,
    FinancialTab
  ],
  template: `
    <financial-tab></financial-tab>
  `
})
export class PayablesTabComponent {}
