// Angular
import { Component, computed, effect, EventEmitter, HostListener, Input, Output, Signal, signal, ViewChild, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

// 3rd Party
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { TextBoxAllModule, MaskedTextBoxAllModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownListAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { TooltipAllModule } from '@syncfusion/ej2-angular-popups';
import { Query, Predicate } from '@syncfusion/ej2-data';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faUser, faIdCard, faUserTag, faComments, faMapLocationDot, faUsersRectangle } from '@fortawesome/free-solid-svg-icons';

// Models
import { Address, LawFirm, Phone } from '@models/data-contracts';
import { APIEndpoints } from '@models/api/Endpoints';

// Services
import { FormCrudService } from '@services/forms/form-crud.service';
import { ApiService } from '@services/api/api.service';
import { ToastMessageService } from '@root/src/app/shared/services/toast-message/toast-message.service';

// Components
import { AddressFormComponent } from '../address-form/address-form.component';
import { PhoneFormComponent } from '../phone-number-form/phone-number-form.component';



@Component({
  selector: 'law-firm-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    TextBoxAllModule,
    MaskedTextBoxAllModule, 
    DropDownListAllModule,
    TooltipAllModule,
    FontAwesomeModule,
    AddressFormComponent, 
    PhoneFormComponent
  ],
  templateUrl: './law-firm-form.component.html',
  styleUrl: './law-firm-form.component.scss'
})
export class LawFirmFormComponent {
  marketManagersDataSource: any;
  caseManagersDataSource: any;
  fields: Object;
  marketManagersQuery: Query;
  caseManagersQuery: Query;
  constructor(
    private formCRUD: FormCrudService,
    private api: ApiService,
    private toast: ToastMessageService
  ) { 
    // Single effect to handle form updates
    effect((onCleanup) => {
      const { currentValue } = this.formState();
      
      if (currentValue) {
        this.getLawFirmAddress().then((address) => {
          this.getPhoneLawFirmDetails().then((phone) => {
            this.lawFirmForm.patchValue({
              ...this.formState().currentValue!,
              CreatedAt: this.formState().currentValue!.CreatedAt ? Date.now().toString() : null
            }, { emitEvent: false });
            this.addressFormComp.addressForm.patchValue(address as Address, { emitEvent: false });
            this.phoneFormComp.phoneForm.patchValue(phone as Phone, { emitEvent: false });
          });
        });
      }

      // Cleanup
      onCleanup(() => this.lawFirmForm.reset());
    });
  }

  ngOnInit() {
    // Market Managers - using RoleId 72 for Market Manager
    this.marketManagersDataSource = this.api.getOdata(APIEndpoints.Users);
    this.marketManagersQuery = new Query()
      .expand('XrefUsersRoles')
      .select('Id,Name')
      .where(`XrefUsersRoles/any(x: x/RoleId eq 72)`, 'equal', true);

    // Case Managers - using RoleId 71 for Case Manager
    this.caseManagersDataSource = this.api.getOdata(APIEndpoints.Users);
    this.caseManagersQuery = new Query()
      .expand('XrefUsersRoles')
      .select('Id,Name')
      .where(`XrefUsersRoles/any(x: x/RoleId eq 71)`, 'equal', true);

    this.fields = { text: 'Name', value: 'Id', id: 'Id' };
  }

  faUser = faUser;
  faIdCard = faIdCard;
  faUserTag = faUserTag;
  faComments = faComments;
  faMapLocationDot = faMapLocationDot;
  faUsersRectangle = faUsersRectangle;

  formClasses = {
    formContainer: 'cc-form-container',
    form: 'cc-form flex-column',
    subForm: 'cc-sub-form',
    section: 'cc-form-section',
    group: 'cc-form-group row',
    inputContainer: 'cc-input-container',
    inputContainerFullWidth: 'cc-input-container col-12',
    label: 'cc-label',
    input: 'cc-input',
    icon: 'cc-input-icon',
    error: 'cc-input-error',
    actions: 'cc-form-actions',
    twoCol: window.innerWidth <= 768 ? 'col-12' : 'col-6'
  };

  // Decorator variables
  @Input() selectedAccountId?: number;
  @Input() isLawFirm?: boolean;
  @Input() lawFirm!: Signal<LawFirm | undefined>;
  @Input() submitType?: 'POST' | 'PATCH' | 'DELETE';
  @Input() displayFields: string[] = ['Name', 'Abbreviation', 'MarketManager', 'CaseManager', 'StatusGroupId', 'DocumentLink', 'BoxFolderId', 'Website', 'Rating', 'IsActive'];
  @Output() formSubmitted = new EventEmitter<void>();
  @Output() formCancelled = new EventEmitter<void>();
  lawFirmPhone = signal<Phone | undefined>(undefined);
  
  @ViewChild('phoneFormComp') phoneFormComp!: PhoneFormComponent;
  @ViewChild('addressFormComp') addressFormComp!: AddressFormComponent;
  selectedLawFirm: Signal<LawFirm | undefined>;

  // Form state
  protected readonly formState = computed(() => {
    const currentLawFirm = this.lawFirm();
    return {
      isValid: this.lawFirmForm.valid,
      hasChanges: currentLawFirm !== this.lawFirmForm.value,
      currentValue: currentLawFirm
    };
  });

  // Public variables
  lawFirmAddress = signal<Address | undefined>(undefined);
  loadingForm: boolean = true;
  errorMessage: string = '';
  lawFirmHTMLElement: Element | null = null;
  lawFirmForm = new FormGroup({
    Id: new FormControl<number | null>(null),
    Name: new FormControl<string | null>(null, Validators.required),
    Abbreviation: new FormControl<string | null>(null, Validators.required),
    MarketManager: new FormControl<number | null>(null, Validators.required),
    CaseManager: new FormControl<number | null>(null, Validators.required),
    StatusGroupId: new FormControl<number | null>(null),
    DocumentLink: new FormControl<string | null>(null),
    BoxFolderId: new FormControl<string | null>(null),
    Notes: new FormControl<string | null>(null),
    Website: new FormControl<string | null>(null),
    Rating: new FormControl<string | null>(null),
    IsActive: new FormControl<boolean | null>(null),
    NotifyContactOnApptDone: new FormControl<boolean | null>(null),
    CreatedBy: new FormControl<number | null>(null),
    CreatedAt: new FormControl<string | null>(null),
    XrefLawFirmContacts: new FormControl<any[]>([]),
    XrefAddressLawfirms: new FormControl<any[]>([]),
    XrefPhoneLawfirms: new FormControl<any[]>([])
  });

  // Add loading signal
  loadingStatus: WritableSignal<{loading: boolean, message: string}> = signal({
    loading: false,
    message: ''
  });

  markAllFieldsAsTouched(): void {
    Object.values(this.lawFirmForm.controls).forEach(control => {
      control.markAsTouched();
      control.markAsDirty();
      control.updateValueAndValidity();
    });
  }

  // Fetches law firm address
  async getLawFirmAddress() {
    const lawFirmId = this.formState().currentValue?.Id as number;
    if (!lawFirmId) throw new Error('Law Firm Id required to fetch address');  

    const endpoint = `${APIEndpoints.XrefAddressLawfirms}`;
    const expandString = 'Address($select=Id,AddressType,Address1,Address2,City,State,Zip),LawFirm($select=Id)';
    const predicate = new Predicate('LawFirm/Id', 'equal', lawFirmId);
    const query = new Query().expand(expandString).where(predicate);

    try {
      await this.api.getOdata(endpoint).executeQuery(query).then((res) => {
        if (res instanceof Error) throw new Error('Failed to fetch law firm address');
        
        const address = (res as any).result[0]?.Address;
        if (!address) {
          // Set all address fields to empty if address is not found
          this.lawFirmAddress.set({
            AddressType: 0,
            Address1: '',
            Address2: '',
            City: '',
            State: undefined,
            Zip: ''
          });
        } else {
          this.lawFirmAddress.set(address);
        }
        
        this.addressFormComp.address = this.lawFirmAddress;
      });
      
      return this.lawFirmAddress();
    } catch (error) {
      console.error('Error fetching law firm address:', error);
      return error;
    }
  }
  ngAfterViewInit() {
    this.lawFirmHTMLElement = document.querySelector('law-firm-form');
    this.updateWidth(this.lawFirmHTMLElement?.clientWidth || window.innerWidth);
    this.watchInputElements();
  }

  // On form submit
  async onSubmit() {
    try {
      this.loadingStatus.set({ loading: true, message: 'Validating form...' });

      let phoneResponse: any;
      let addressResponse: any;
      const submitType = this.determineSubmitType();
      const endpoint = this.determineEndpoint();
      this.markAllFieldsAsTouched();

      // Exit if form is invalid
      if (this.lawFirmForm.invalid || this.addressFormComp.addressForm.invalid || this.phoneFormComp.phoneForm.invalid) {
        this.toast.showError('Form Invalid');
        return new Error('Form Invalid');
      }

      let lawFirmId = this.lawFirmForm.get('Id')?.value;

      // Handle phone form
      const phoneId = this.phoneFormComp.phoneForm.get('Id')?.value;
      if (!this.phoneFormComp.phoneForm.pristine) {
        this.loadingStatus.set({ loading: true, message: 'Saving phone information...' });
        phoneResponse = await this.phoneFormComp.onSubmit();
        
        if (phoneResponse.error || !phoneResponse) {
          this.toast.showError('Error submitting phone');
          throw new Error('Error submitting phone');
        }
      }

      // Handle address form
      const addressId = this.addressFormComp.addressForm.get('Id')?.value;
      if (!this.addressFormComp.addressForm.pristine) {
        this.loadingStatus.set({ loading: true, message: 'Saving address information...' });
        addressResponse = await this.addressFormComp.onSubmit();
        
        if (addressResponse.error || !addressResponse) {
          this.toast.showError('Error submitting address');
          throw new Error('Error submitting address');
        }
      }

      // Submit main form
      this.loadingStatus.set({ loading: true, message: 'Saving law firm information...' });
      
      if (submitType === 'POST') {
        if (!addressResponse?.Id) throw new Error('Address is required to create a law firm');
        const newLawFirm = await this.handlePostRequest(addressResponse.Id);
        lawFirmId = newLawFirm.Id;
      } else {
        await this.handlePatchRequest(endpoint, submitType);
      }

      // Handle xref entries
      if (!phoneId && phoneResponse?.Id && lawFirmId) {
        this.loadingStatus.set({ loading: true, message: 'Linking phone...' });
        await this.createXrefPhoneLawFirm(phoneResponse.Id, lawFirmId);
      }

      if (!addressId && addressResponse?.Id && lawFirmId) {
        this.loadingStatus.set({ loading: true, message: 'Linking address...' });
        await this.createXrefAddressLawFirm(addressResponse.Id, lawFirmId);
      }

      this.formSubmitted.emit();
      return lawFirmId;

    } catch (error) {
      console.error('Error submitting law firm:', error);
      this.toast.showError('Error submitting law firm');
      throw error;
    } finally {
      this.loadingStatus.set({ loading: false, message: '' });
      this.loadingForm = false;
    }
  }
  
  private determineSubmitType(): 'POST' | 'PATCH' | 'DELETE' {
    return this.submitType ? this.submitType : this.lawFirmForm.get('Id')?.value ? 'PATCH' : 'POST';
  }
  
  private determineEndpoint(): string {
    return this.lawFirmForm.get('Id')?.value ? `${APIEndpoints.Lawfirms}/${this.lawFirmForm.get('Id')?.value}` : `${APIEndpoints.Lawfirms}`;
  }
  
  
  async handlePostRequest(addressId: number) {
   const newLawFirm =  await this.createLawFirm(addressId);
   if (!newLawFirm) throw new Error('Failed to create law firm');

   this.toast.showSuccess('Law Firm created successfully');
   return newLawFirm;
  }

  async createLawFirm(addressId: number) {
    this.lawFirmForm.patchValue({XrefAddressLawfirms: [{AddressId: addressId}], XrefPhoneLawfirms: [{PhoneId: this.lawFirmPhone()?.Id}]});
    const lawFirmResponse = await this.formCRUD.submitForm(this.lawFirmForm, `odata${APIEndpoints.Lawfirms}`, 'POST');
    if (lawFirmResponse instanceof Error) {
      throw new Error(lawFirmResponse.message);
    }
    return lawFirmResponse;
  }
  
  private async handlePatchRequest(endpoint: string, submitType: 'PATCH' | 'DELETE') {
   await this.addressFormComp.onSubmit();
   await this.phoneFormComp.onSubmit();
   await this.formCRUD.submitForm(this.lawFirmForm, `odata${endpoint}`, submitType).then((res) => {
    this.handleFormSubmissionResponse(res);
   });
  }

  private handleFormSubmissionResponse(res: any) {
    if (res instanceof Error) this.toast.showError(res.message as string);
    else if (res instanceof Response && res.ok) this.toast.showSuccess('LawFirm Submitted Successfully');
    else if (res?.status === 'warning') console.warn(res.message as string);
    else if (res?.status === 'success') this.toast.showSuccess(res.message as string);
    else if (res === undefined) console.warn('Not sure what happened there.');
    else if (typeof res === 'object') this.toast.showSuccess('Contact Submitted Successfully');
    else console.log(res);
    this.loadingForm = false;
    this.formSubmitted.emit();
  }

  private async createXrefAddressLawFirm(newAddressId: number, newLawFirmId: number) {
    const crossRefResponse = await this.api.fetchRequest(`odata${APIEndpoints.XrefAddressLawfirms}`, 'POST', { AddressId: newAddressId, LawFirmId: newLawFirmId });
    if (crossRefResponse instanceof Error) {
      throw new Error('Failed to create or update xref address law firm');
    }
  }
  
  private async createXrefPhoneLawFirm(newPhoneId: number, newLawFirmId: number) {
    const crossRefResponse = await this.api.fetchRequest(`odata${APIEndpoints.XrefPhoneLawfirms}`, 'POST', { PhoneId: newPhoneId, LawFirmId: newLawFirmId });
    if (crossRefResponse instanceof Error) {
      throw new Error('Failed to create or update xref phone law firm');
    }
  }

  // Perform updates on input elements
  watchInputElements() {
    document.querySelectorAll(`.cc-input-container`).forEach((inputContainer) => {
      const container = inputContainer as HTMLElement;

      container.querySelectorAll('input, span, textarea').forEach((input) => {
        input.addEventListener('focus', () => inputContainer.classList.add('focus'));
        input.addEventListener('blur', () => inputContainer.classList.remove('focus'));
      });
    });
  }

  // Switches form to 2 columns when parent is larger 1600px
  updateWidth(containerWidth: number) {

    if (containerWidth > 1200) {
      this.formClasses.form = this.formClasses.form.replace('flex-column', 'flex-row flex-wrap');
      this.formClasses.twoCol = this.formClasses.twoCol.replace('col-12', 'col-6')  ;
    } else {
      this.formClasses.form = this.formClasses.form.replace('flex-row', 'flex-column');
      this.formClasses.twoCol = this.formClasses.twoCol.replace('col-6', 'col-12');
    }
  }

  // Update the layout on window resize
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    const containerWidth = this.lawFirmHTMLElement?.clientWidth || window.innerWidth;
    this.updateWidth(containerWidth);
  }

  // Returns appropriate error message for form control
  getErrorMessage(controlName: string): string {
    let message = '';
    const control = this.lawFirmForm.get(controlName);
    if (control?.errors) {
      if (control.errors['required']) message = 'This field is required';
      if (control.errors['email']) message = 'Invalid email format';
      if (control.errors['invalidPhone']) message = 'Invalid phone number (10 digits required)';
      if (control.errors['serverError']) message = control.errors['serverError'].message;
    };
    this.errorMessage = message;
    return message;
  }


async getPhoneLawFirmDetails() {
  const lawfirmId = this.formState().currentValue?.Id as number;
  if (!lawfirmId) throw new Error('Contact Id required to fetch phone law firm details');  
  const endpoint = `${APIEndpoints.XrefPhoneLawfirms}`;
  const expandString = 'Phone,LawFirm';
  const predicate = new Predicate('LawFirm/Id', 'equal', lawfirmId);
  const query = new Query().expand(expandString).where(predicate);
  
  try {
    await this.api.getOdata(endpoint).executeQuery(query).then((res) => {
      if (res instanceof Error) throw new Error('Failed to fetch phone law firm details');
      const phoneLawFirm = (res as any).result.find((item: any) => item.Lawfirm.Id === lawfirmId)?.Phone;
      if (phoneLawFirm) {
        this.lawFirmPhone.set(phoneLawFirm);
      } else {
        throw new Error('No phone law firm details found for the specified contact ID');
      }
    });
    
    return this.lawFirmPhone();
  } catch (error) {
    console.error('Error fetching phone law firm details:', error);
    
    if (this.phoneFormComp) {
      this.phoneFormComp.phoneForm.reset({
        Id: null,
        PhoneType: null,
        PhoneNumber: ''
      });
    }

    return error;
  }
}
}