// Angular
import { 
  Component, 
  Input,
  HostBinding } from '@angular/core';

@Component({
  selector: 'app-svg',
  standalone: true,
  templateUrl: './svg.component.html',
  styleUrls: ['./svg.component.scss']
})
export class SvgComponent {
  @HostBinding('style.-webkit-mask-image')
  private _path!: string;

  @Input()
  public set path(filePath: string) {
    this._path = `url("${filePath}")`;
  }
}