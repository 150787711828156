// Angular
import { Component, signal, ViewChild, WritableSignal, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';

// 3rd Party
import { Query } from '@syncfusion/ej2-data';
import { DialogAllModule, DialogComponent } from '@syncfusion/ej2-angular-popups';
import { AccordionAllModule } from '@syncfusion/ej2-angular-navigations';
import { GridModel, RowSelectEventArgs, CommandClickEventArgs, DetailDataBoundEventArgs, TextAlign, CommandModel } from '@syncfusion/ej2-grids';
import { GridDetailsConfig, onGridDetailDataBound } from '@grids/helpers';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { CaseFile, Deposit, Invoice, ReductionRequest } from '@models/data-contracts';
import { PerformanceData } from '@models/components/financial-performance.model';
import { ApiService } from '@services/api/api.service';
import { GlobalsService } from '@services/globals/globals.service';
import { FileHubService } from '@root/src/app/features/file-hub/services/file-hub.service';
import { TabCardComponent } from '@root/src/app/features/file-hub/components/file-hub-tabs/tab-card/tab-card.component';
import { LoadingModule } from '@modules/loading.module';
import { StatusComponent } from '@ui/status/status.component';
import { GridTemplateComponent } from '@grids/grid-template/grid-template.component';
import { ViewBalanceStatementComponent } from '@features/balance-statement/view-balance-statement/view-balance-statement.component';
import { GenerateBalanceStatementComponent } from '@features/balance-statement/generate-balance-statement/generate-balance-statement.component';
import { TransferInvoiceComponent } from '@forms/transfer-invoice/transfer-invoice.component';
import { AddDepositForm } from "@forms/add-forms/add-deposit/add-deposit.component";
import { AddInvoiceForm } from '@forms/add-forms/add-invoice/add-invoice.component';
import { AuditLogService } from '@services/audit-logs/audit-log-service';
import { AuditLogsComponent } from '@ui/audit-logs/audit-logs.component';
import { NotificationService } from '@core/services/notification.service';
import { FinancialService } from '@services/financial/financial.service';
import { ErrorHandlingService } from '@core/services/error-handling.service';

// Add error constants at top of file
const ERRORS = {
  XIRR: {
    MISSING_DATA: {
      message: 'Missing required data for XIRR calculation',
      technical: 'Missing deposits or invoices for XIRR calculation'
    },
    CALCULATION_FAILED: {
      message: 'Unable to calculate financial performance',
      technical: 'XIRR calculation failed'
    }
  },
  AUDIT: {
    LOAD_FAILED: {
      message: 'Unable to load audit history',
      technical: 'Failed to load audit logs'
    },
    FORMAT_FAILED: {
      message: 'Unable to display audit history',
      technical: 'Failed to format audit logs'
    }
  }
} as const;

@Component({
  selector: 'financial-tab',
  standalone: true,
  imports: [
    CommonModule,
    AccordionAllModule,
    DialogAllModule,
    LoadingModule,
    TabCardComponent,
    StatusComponent,
    GridTemplateComponent,
    ViewBalanceStatementComponent,
    GenerateBalanceStatementComponent,
    TransferInvoiceComponent,
    AddDepositForm,
    AddInvoiceForm,
    AuditLogsComponent
  ],
  templateUrl: './financial-tab.component.html',
  styleUrl: './financial-tab.component.scss'
})
export class FinancialTab implements OnInit, OnDestroy {

  private destroy$ = new Subject<void>();

  /**
   * Main functionality can be found in the FileHubService.
   * @see {@link FileHubService} for more info
   */
  constructor(
    private api: ApiService,
    private globals: GlobalsService,
    public fileHub: FileHubService,
    private auditLogService: AuditLogService,
    private notification: NotificationService,
    private errorHandling: ErrorHandlingService,
    private financialService: FinancialService
  ) { }

  private caseFile: CaseFile | undefined;
  private invoices: Invoice[];
  private deposits: Deposit[];
  loadingFinancialPerformanceData: WritableSignal<boolean> = signal(true);
  editingInvoice: WritableSignal<Invoice | null> = signal(null);
  performanceData?: PerformanceData;
  reductionsData: ReductionRequest[];
  depositsGrid: GridModel;
  invoicesGrid: GridModel;

  @ViewChild('generateBalanceStatement') public generateBalanceStatement: DialogComponent;

  @ViewChild('viewBalanceStatement') public viewBalanceStatement: DialogComponent;
  public viewBalanceStatementVisibility: boolean = false;

  @ViewChild('transferInvoice') public transferInvoice: DialogComponent;
  public transferInvoiceVisibility: boolean = false;

  @ViewChild('addNewInvoice') public addNewInvoice: DialogComponent;
  public addNewInvoiceVisibility: boolean = false;

  @ViewChild('addDeposit') public addDeposit: DialogComponent;
  public addDepositVisibility: boolean = false;

  @ViewChild('logsDialog') logsDialog: any;
  auditLogs: any[] = [];
  auditLogsLoading = false;
  logsDialogVisibility: boolean = false;
  logsDialogButtons: Object[] = [
    { click: this.closeLogsDialog.bind(this), buttonModel: { content: 'Close', cssClass: 'e-flat' } }
  ];

  showFinancialSection: boolean = false; // Default to showing invoices

  // Add property to store XIRR value
  xirrValue: number | null = null;

  // Add this property to track editing state
  editingDeposit: WritableSignal<Deposit | null> = signal(null);

  ngOnInit() {
    // Subscribe to changes in the fileHub service
    this.fileHub.reload$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((sub) => {
      this.addFinancialDataToCaseFile();
      this.calculateXIRR();
    });
  }

  ngAfterViewInit() {
    this.addFinancialDataToCaseFile();
    this.calculateXIRR();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async addFinancialDataToCaseFile(): Promise<void> {
    try {
      this.caseFile = this.fileHub.caseFile;

      // Wait for case file to be available
      if (!this.caseFile) {
        this.loadingFinancialPerformanceData.set(true);
        await new Promise(resolve => setTimeout(resolve, 100)); // Small delay
        return this.addFinancialDataToCaseFile(); // Retry
      }

      // Now check for ID
      if (!this.caseFile.Id) {
        const message = 'Case file loaded but ID not available - please try again';
        this.notification.warn(message);
        this.loadingFinancialPerformanceData.set(false);
        return;
      }

      let expanded: string[] = [];
      let selected: string[] = [];
      const endpoint = `${APIEndpoints.Casefiles}(${this.caseFile.Id})`;
      const query = new Query().expand('Invoices($expand=InvoiceRows,InvoicePayments,Provider),Deposits,ReductionRequests').select('Invoices,Deposits,ReductionRequests');

      if (!this.globals.objHasKey(this.caseFile, 'Invoices')) {
        expanded.push('Invoices');
        selected.push('Invoices');
      }

      if (!this.globals.objHasKey(this.caseFile, 'Deposits')) {
        expanded.push('Deposits');
        selected.push('Deposits');
      }

      if (!this.globals.objHasKey(this.caseFile, 'ReductionRequests')) {
        expanded.push('ReductionRequests');
        selected.push('ReductionRequests');
      }

      return await this.api.getOdata(endpoint).executeQuery(query).then((res: any) => {
        const financialData = res.result[0] as CaseFile;

        this.fileHub.updateCaseFile('Invoices', financialData.Invoices);
        this.fileHub.updateCaseFile('Deposits', financialData.Deposits);
        this.fileHub.updateCaseFile('ReductionRequests', financialData.ReductionRequests);
        this.reductionsData = financialData.ReductionRequests ?? [];
        this.setInvoicesGrid();
        this.setDepositsGrid();
        this.getPerformanceData();
      });

    } catch (error) {
      console.error('Error loading financial data:', error);
      this.loadingFinancialPerformanceData.set(false);
    } finally {
      this.loadingFinancialPerformanceData.set(false);
    }
  }

  async getPerformanceData() {

    try {
      if (!this.fileHub.performanceData) {
        await this.fileHub.setPerformanceData(this.invoices, this.deposits);
        this.performanceData = this.fileHub.performanceData;
      } else {
        this.performanceData = this.fileHub.performanceData;
      }

      return this.performanceData;

    } catch (error) {
      console.error('Error loading performance data:', error);
      this.loadingFinancialPerformanceData.set(false);
      return error;

    } finally {
      this.loadingFinancialPerformanceData.set(false);
      return;
    }
  }

  // Deposits grid
  setDepositsGrid() {
    if (!this.fileHub.caseFile?.Id) return;

    const actionCommands: CommandModel[] = [
      { title: 'Edit', type: 'Edit', buttonOption: { iconCss: 'e-icons e-edit', cssClass: 'e-flat' } }
    ];

    this.depositsGrid = {
      dataSource: this.api.getOdata(APIEndpoints.Deposits),
      toolbar: [{ text: 'Add Deposit', tooltipText: 'Add Deposit', id: 'AddDeposit', align: 'Left' }],
      query: new Query().where('CaseFileId', 'equal', this.fileHub.caseFile.Id),
      editSettings: { allowEditing: false },
      pageSettings: { pageSize: 3, pageSizes: [3, 5, 10] },  // Set to 3 items per page
      rowRenderingMode: 'Horizontal',
      enableAdaptiveUI: false,
      columns: [
        { field: 'Id' },
        { field: 'DepositDate', headerText: 'Date' },
        { field: 'FinalCheck', headerText: 'Final Check' },
        { field: 'Notes', headerText: 'Notes', visible: false },
        { field: 'DepositAmount', headerText: 'Amount', format: 'C2' },
        { field: 'DepositToAccount', headerText: 'P&L', visible: false },
        { field: 'CourtesyReduction', headerText: 'Courtesy Reduction', visible: false },
        { type: 'commands', headerText: 'Actions', commands: actionCommands }
      ],
      toolbarClick: ($event: any) => this.onDepositsGridCustomToolbarClick($event),
      commandClick: ($event: CommandClickEventArgs) => this.onDepositsGridCommandClick($event)
    }

    return;
  }

  onDepositsGridCustomToolbarClick(args: any) {
    if (args.item.id === 'AddDeposit') {
      args.cancel = true;
      this.editingDeposit.set(null); // Clear editing state for new deposit
      this.addDepositVisibility = true;
      if (this.addDeposit) this.addDeposit.show();
    }
  }

  onDepositsGridCommandClick(args: CommandClickEventArgs) {
    if (args.commandColumn?.type === 'Edit') {
      this.editingDeposit.set(args.rowData as Deposit);
      this.addDepositVisibility = true;
      if (this.addDeposit) this.addDeposit.show();
    }
  }

  // Invoices grid
  invoiceRowColumns = [
    { field: 'Id', isPrimaryKey: true, visible: false },
    { field: 'DateOfService', headerText: 'Service Date', editType: 'datepickeredit', type: 'date', format: 'MM/dd/yyyy' },
    { field: 'ProcedureCode.ProcedureCodeName', headerText: 'Procedure Code', editType: 'dropdownedit' },
    { field: 'ReimbursementRate', headerText: 'Purchase Rate', editType: 'numericedit', format: 'P' },
    { field: 'AmountBilled', headerText: 'Billed Amount', format: 'C2', editType: 'numericEdit' },
    { field: 'TotalDueProvider', headerText: 'Provider Amount', format: 'C2', editType: 'numericEdit' },
    { field: 'SettlementValue', headerText: 'Settlement Value', format: 'C2', editType: 'numericEdit' },
    {
      field: 'Invoice.SplitInvoice',
      headerText: 'Split Invoice',
      textAlign: 'Center' as TextAlign,
      headerTextAlign: 'Center' as TextAlign
    },
  ];
  invoicePaymentsColumns = [
    { field: 'Id', isPrimaryKey: true, visible: false },
    { field: 'DatePaid', headerText: 'Payment Date', editType: 'datepickeredit', type: 'date', format: 'MM/dd/yyyy' },
    { field: 'BalanceDue', headerText: 'Balance', format: 'C2', editType: 'numericEdit' },
    { field: 'AmountPaid', headerText: 'Amount Paid', format: 'C2', editType: 'numericEdit' },
    { field: 'PaymentMethodNavigation.Description', headerText: 'Method', editType: 'dropdownedit' },
    { field: 'PaymentStatusNavigation.Description', headerText: 'Status', editType: 'dropdownedit' }
  ];
  invoiceDetailsSettings = [
    {
      header: 'Invoice Details',
      columns: this.invoiceRowColumns,
      dataKey: "InvoiceRows",
      renderMode: ("Vertical" as any),
      allowFiltering: false
    },
    {
      header: 'Payment Details',
      columns: this.invoicePaymentsColumns,
      dataKey: "InvoicePayments",
      renderMode: ("Vertical" as any),
      allowFiltering: false
    }
  ]
  selectedInvoices: WritableSignal<Invoice[] | null> = signal(null); // transfer invoice setup

  setInvoicesGrid() {
    const endpoint = APIEndpoints.Invoices;
    const query = new Query().where('CaseFileId', 'equal', this.fileHub.caseFile?.Id).expand('CaseFile($expand=Patient),InvoiceRows($expand=ProcedureCode),InvoicePayments($expand=PaymentStatusNavigation,PaymentMethodNavigation),Provider');
    this.invoicesGrid = {
      dataSource: this.api.getOdata(endpoint),
      allowPaging: false,
      allowGrouping: true,
      allowSorting: false,
      query: query,
      toolbar: [
        { text: 'Add Invoice', tooltipText: 'Add Invoice', id: 'AddInvoice', align: 'Left' },
        { text: 'Transfer Invoice', tooltipText: 'Transfer', id: 'TransferInvoice', align: 'Left' },
      ],
      showColumnMenu: false,
      groupSettings: {
        columns: ['Provider.Name'],
        showGroupedColumn: false,
        showDropArea: false,
        showToggleButton: false,
        showUngroupButton: false,
        captionTemplate: '${key}'
      },
      columns: [
        { type: 'checkbox', headerText: 'Select', allowFiltering: false, visible: true, width: 35 },
        { field: 'Id', visible: false },
        { field: 'Provider.Id', headerText: 'Provider Id', visible: false, showInColumnChooser: false },
        { field: 'Provider.Name', headerText: 'Provider Name', allowGrouping: true, textAlign: 'Center', headerTextAlign: 'Center' },
        { field: 'InvoiceDate', headerText: 'Date', width: 100, textAlign: 'Center', headerTextAlign: 'Center' },
        { field: 'ProviderInvoiceNumber', headerText: 'Provider Invoice #', width: 150, textAlign: 'Center', headerTextAlign: 'Center' },
        { field: 'SplitInvoice', headerText: 'Split', visible: false },
        { field: 'SplitInvoiceId', headerText: 'Split Id', visible: false },
        { field: 'InvoiceTransferred', headerText: 'Transferred', visible: false },
        { field: 'CaseManagerNavigation.Name', headerText: 'Case Manager', visible: false },
        {
          field: 'AmountBilled',
          headerText: 'Amount Billed',
          type: 'number',
          width: 150,
          format: 'C2',
          textAlign: 'Center',
          headerTextAlign: 'Center',
          valueAccessor: (field: string, data: any) => {
            return data.InvoiceRows?.reduce((sum: number, row: any) => sum + (row.AmountBilled || 0), 0) || 0;
          }
        },
        {
          field: 'TotalDueProvider',
          headerText: 'Total Due',
          type: 'number',
          width: 150,
          format: 'C2',
          textAlign: 'Center',
          headerTextAlign: 'Center',
          valueAccessor: (field: string, data: any) => {
            return data.InvoiceRows?.reduce((sum: number, row: any) => sum + (row.TotalDueProvider || 0), 0) || 0;
          }
        },
        {
          field: 'SettlementValue',
          headerText: 'Settlement Value',
          type: 'number',
          width: 150,
          format: 'C2',
          textAlign: 'Center',
          headerTextAlign: 'Center',
          valueAccessor: (field: string, data: any) => {
            return data.InvoiceRows?.reduce((sum: number, row: any) => sum + (row.SettlementValue || 0), 0) || 0;
          }
        },
        {
          field: 'AmountPaid',
          headerText: 'Amount Paid',
          type: 'number',
          width: 150,
          format: 'C2',
          textAlign: 'Center',
          headerTextAlign: 'Center',
          valueAccessor: (field: string, data: any) => {
            return data.InvoicePayments?.reduce((sum: number, row: any) => sum + (row.AmountPaid || 0), 0) || 0;
          }
        },
        {
          field: 'InvoiceRows.InternalInvoice',
          headerText: 'Internal INV',
          width: 100,
          textAlign: 'Center',
          headerTextAlign: 'Center',
          valueAccessor: (field: string, data: any) => {
            return data.InvoiceRows?.some((row: any) => row.InternalInvoice) ? 'Yes' : 'No';
          }
        },
        {
          field: 'InvoicePayments.FundingAccount',
          headerText: 'Payment Status',
          textAlign: 'Center',
          headerTextAlign: 'Center',
          valueAccessor: (field: string, data: any) => {
            return data.InvoicePayments?.[0]?.PaymentStatusNavigation?.Description || '';
          }
        },
        {
          field: 'InvoicePayments.PaymentMethodNavigation.Name',
          headerText: 'Payment Method',
          textAlign: 'Center',
          headerTextAlign: 'Center',
          valueAccessor: (field: string, data: any) => {
            return data.InvoicePayments?.[0]?.PaymentMethodNavigation?.Description || '';
          }
        },
        {
          field: 'InvoicePayments.CheckNumber',
          headerText: 'Check Number',
          width: 100,
          textAlign: 'Center',
          headerTextAlign: 'Center',
          valueAccessor: (field: string, data: any) => {
            return data.InvoicePayments?.[0]?.TransactionNumber || '-';
          }
        },
        {
          type: 'commands',
          headerText: 'Actions',
          width: 75,  // Add fixed width
          commands: [
            { type: 'Edit', title: 'Edit', buttonOption: { iconCss: 'e-icons e-edit', cssClass: 'e-flat' } },
            { type: 'None', title: 'Logs', buttonOption: { iconCss: 'e-icons e-description', cssClass: 'e-flat' } },
          ]
        }
      ],
      selectionSettings: { checkboxOnly: true },
      rowSelecting: ($event: any) => this.onInvoicesGridRowSelected($event),
      rowDeselecting: ($event: any) => this.onInvoicesGridRowDeselected($event),
      toolbarClick: ($event: any) => this.onInvoicesGridCustomToolbarClick($event),
      detailDataBound: ($event: any) => this.onInvoicesGridDetailDataBound($event, this.invoiceDetailsSettings),
      commandClick: ($event: CommandClickEventArgs) => this.onCommandClick($event),
      dataBound: () => {
        const gridInstance = (document.querySelector('ejs-grid') as any)?.ej2_instances?.[0];
        if (!gridInstance) return;

        const currentViewData = gridInstance.getCurrentViewRecords();

        // Calculate grand totals as before
        const totals = currentViewData.reduce((acc: any, invoice: any) => ({
          amountBilled: acc.amountBilled + (invoice.InvoiceRows?.reduce((sum: number, row: any) =>
            sum + (row.AmountBilled || 0), 0) || 0),
          totalDue: acc.totalDue + (invoice.InvoiceRows?.reduce((sum: number, row: any) =>
            sum + (row.TotalDueProvider || 0), 0) || 0),
          settlementValue: acc.settlementValue + (invoice.InvoiceRows?.reduce((sum: number, row: any) =>
            sum + (row.SettlementValue || 0), 0) || 0),
          amountPaid: acc.amountPaid + (invoice.InvoicePayments?.reduce((sum: number, row: any) =>
            sum + (row.AmountPaid || 0), 0) || 0)
        }), { amountBilled: 0, totalDue: 0, settlementValue: 0, amountPaid: 0 });

        const gridContent = gridInstance.getContent();
        const tables = gridContent.querySelectorAll('.e-table');
        const mainTable = Array.from(tables as NodeListOf<HTMLTableElement>).find((table) =>
          !table.closest('.e-detailrowcollapse') && !table.closest('.e-detailrow')
        );

        if (mainTable) {
          // Remove any existing subtotal and total rows
          mainTable.querySelectorAll('.e-summaryrow').forEach(row => row.remove());

          // Add subtotals for each group
          const groups = mainTable.querySelectorAll('.e-groupcaptionrow');
          groups.forEach((group: Element) => {
            let nextGroup = group.nextElementSibling;
            let groupRows = [];
            while (nextGroup && !nextGroup.classList.contains('e-groupcaptionrow')) {
              if (!nextGroup.classList.contains('e-detailrow')) {
                groupRows.push(nextGroup);
              }
              nextGroup = nextGroup.nextElementSibling;
            }

            // Calculate subtotals for this group
            const groupTotals = groupRows.reduce((acc: any, row: Element) => {
              const rowData = gridInstance.getRowInfo(row).rowData;
              return {
                amountBilled: acc.amountBilled + (rowData.InvoiceRows?.reduce((sum: number, r: any) =>
                  sum + (r.AmountBilled || 0), 0) || 0),
                totalDue: acc.totalDue + (rowData.InvoiceRows?.reduce((sum: number, r: any) =>
                  sum + (r.TotalDueProvider || 0), 0) || 0),
                settlementValue: acc.settlementValue + (rowData.InvoiceRows?.reduce((sum: number, r: any) =>
                  sum + (r.SettlementValue || 0), 0) || 0),
                amountPaid: acc.amountPaid + (rowData.InvoicePayments?.reduce((sum: number, r: any) =>
                  sum + (r.AmountPaid || 0), 0) || 0)
              };
            }, { amountBilled: 0, totalDue: 0, settlementValue: 0, amountPaid: 0 });

            // Create and insert subtotal row
            const subtotalRow = document.createElement('tr');
            subtotalRow.className = 'e-summaryrow';
            subtotalRow.style.fontWeight = 'bold';
            subtotalRow.style.backgroundColor = '#f8f9fa';
            subtotalRow.innerHTML = `
              <td style="width: 35px"></td>                              <!-- checkbox -->
              <td style="display: none"></td>                            <!-- Id -->
              <td style="display: none"></td>                            <!-- ProviderId -->
              <td style="display: none"></td>                            <!-- Provider.Name -->
              <td></td>                                                  <!-- Date -->
              <td></td>                                                  <!-- Provider Invoice # -->
              <td colspan="2" style="text-align: center"></td>          <!-- Combine cells for alignment -->
              <td style="text-align: center">${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(groupTotals.amountBilled)}</td>
              <td style="text-align: center">${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(groupTotals.totalDue)}</td>
              <td style="text-align: center">${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(groupTotals.settlementValue)}</td>
              <td style="text-align: center">${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(groupTotals.amountPaid)}</td>
              <td style="width: 100px"></td>                            <!-- Internal INV -->
              <td></td>                                                  <!-- Funding Account -->
              <td></td>                                                  <!-- Payment Method -->
              <td style="width: 100px"></td>                            <!-- Check Number -->
              <td style="width: 75px"></td>                             <!-- Actions -->
            `;

            // Insert after the last row of the group
            const lastGroupRow = groupRows[groupRows.length - 1];
            if (lastGroupRow) {
              lastGroupRow.after(subtotalRow);
            }
          });

          // Add grand total row at the bottom
          const footerRow = document.createElement('tr');
          footerRow.className = 'e-summaryrow';
          footerRow.style.fontWeight = 'bold';
          footerRow.innerHTML = `
              <td style="width: 35px"></td>                              <!-- checkbox -->
              <td style="display: none"></td>                            <!-- Id -->
              <td style="display: none"></td>                            <!-- ProviderId -->
              <td style="display: none"></td>                            <!-- Provider.Name -->
              <td></td>                                                  <!-- Date -->
              <td></td>                                                  <!-- Provider Invoice # -->
              <td colspan="2" style="text-align: right">Total:</td>     <!-- Combine cells for alignment -->
              <td style="text-align: center">${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totals.amountBilled)}</td>
              <td style="text-align: center">${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totals.totalDue)}</td>
              <td style="text-align: center">${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totals.settlementValue)}</td>
              <td style="text-align: center">${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totals.amountPaid)}</td>
              <td style="width: 100px"></td>                            <!-- Internal INV -->
              <td></td>                                                  <!-- Funding Account -->
              <td></td>                                                  <!-- Payment Method -->
              <td style="width: 100px"></td>                            <!-- Check Number -->
              <td style="width: 75px"></td>                             <!-- Actions -->
            `;

          mainTable.appendChild(footerRow);
        }
      },
      allowSelection: true,
      height: '100%',  // These properties should enable scrolling
      width: '100%',
    }

    return;
  }

  onInvoicesGridCustomToolbarClick(args: any) {

    if (args.item.id === 'TransferInvoice') {
      this.transferInvoiceVisibility = true;
      if (this.transferInvoice) this.transferInvoice.show();
    }

    if (args.item.id === 'AddInvoice') {
      this.addNewInvoiceVisibility = true;
      if (this.addNewInvoice) this.addNewInvoice.show();
    }
  }

  // transfer invoice setup
  onInvoicesGridRowSelected(args: RowSelectEventArgs): void {
    const selectedRow = (args.data as Invoice);
    if (selectedRow) {
      const currentSelections = this.selectedInvoices() ?? [];
      this.selectedInvoices.set([selectedRow, ...currentSelections]);
    }
  }

  // transfer invoice setup
  onInvoicesGridRowDeselected(args: RowSelectEventArgs): void {
    const selectedRowId = (args.data as Invoice).Id;
    if (selectedRowId) {
      const currentSelections = this.selectedInvoices() ?? [];
      this.selectedInvoices.set(currentSelections.filter((row) => row.Id !== selectedRowId))
    }
  }

  // Build additional grid for each invoice row
  onInvoicesGridDetailDataBound(e: DetailDataBoundEventArgs, gridsConfig: GridDetailsConfig[]) {
    return onGridDetailDataBound(e, gridsConfig);
  }

  setInfoClass() {
    let classString: string = 'col';
    const reductionInfoContainer = document.getElementById('reductions-data');

    if (reductionInfoContainer) {
      const containerWidth = reductionInfoContainer.offsetWidth;

      if (containerWidth < 390) {
        classString += ' col-12';
      } else if (containerWidth >= 390 && containerWidth < 1040) {
        classString += ' col-6';
      } else {
        classString += ' col-4';
      }
    }

    return classString;
  }

  toggleSections() {
    this.showFinancialSection = !this.showFinancialSection;
  }

  handleSubmitComplete() {
    this.editingDeposit.set(null);
    this.addDepositVisibility = false;
    this.addFinancialDataToCaseFile();
    this.calculateXIRR();
  }

  handleDialogClose() {
    this.editingDeposit.set(null);
    this.addDepositVisibility = false;
    if (this.addDeposit) {
      this.addDeposit.hide();
    }
  }

  onDialogClose(): void {
    this.editingInvoice.set(null);
    this.addNewInvoiceVisibility = false;
    this.addFinancialDataToCaseFile();
    this.calculateXIRR();
  }


  async onCommandClick(args: CommandClickEventArgs) {

    try {
      const invoice = args.rowData as any;

      if (invoice && invoice.Id && args.commandColumn?.title === 'Edit') {
        args.cancel = true;
        this.editingInvoice.set(invoice);
        this.addNewInvoiceVisibility = true;
        if (this.addNewInvoice) this.addNewInvoice.show();
      }

      if (invoice && invoice.Id && args.commandColumn?.title === 'Logs') {
        try {
          this.auditLogsLoading = true;
          this.showLogsDialog();

          // Get all logs
          try {
            const invoiceLogs = await this.auditLogService.getAuditLogs(invoice.Id, 'Invoice');
            const rowLogs = await this.auditLogService.getAuditLogs(invoice.InvoiceRows[0].Id, 'InvoiceRow');
            const paymentLogs = invoice.InvoicePayments.length > 0 ?
              await this.auditLogService.getAuditLogs(invoice.InvoicePayments[0].Id, 'InvoicePayment') :
              [];

            // Format logs with clear section headers
            try {
              this.auditLogs = [
                {
                  sectionHeader: 'Invoice Header Logs',
                  logs: this.auditLogService.mapAuditDataToLogFormat(invoiceLogs)
                },
                {
                  sectionHeader: 'Invoice Row Logs',
                  logs: this.auditLogService.mapAuditDataToLogFormat(rowLogs)
                },
                {
                  sectionHeader: 'Invoice Payment Logs',
                  logs: this.auditLogService.mapAuditDataToLogFormat(paymentLogs)
                }
              ].filter(section => section.logs.length > 0); // Only show sections that have logs
            } catch (formatError) {
              this.closeLogsDialog();
              this.notification.warn('Error formatting audit log data');
              this.errorHandling.handleError({
                error: formatError,
                context: 'FinancialTab.onCommandClick',
                details: { invoiceId: invoice.Id }
              });
              return;
            }
          } catch (error) {
            this.closeLogsDialog();
            this.errorHandling.handleError({
              error,
              context: 'FinancialTab.onCommandClick',
              details: { invoiceId: invoice.Id }
            });
          }
        } catch (error) {
          this.closeLogsDialog();
          this.notification.warn('Error processing audit logs request');
          console.error('Error in onCommandClick:', error);
        } finally {
          this.auditLogsLoading = false;
        }
      }
    } catch (error) {
      console.error('Error in onCommandClick:', error);
      return error;

    } finally {
      this.loadingFinancialPerformanceData.set(false);
      return;
    }
  }

  showLogsDialog() {
    try {
      this.logsDialogVisibility = true;
      if (this.logsDialog) {
        this.logsDialog.show();
      } else {
        this.notification.warn('Logs dialog not initialized');
      }
    } catch (error) {
      this.notification.warn('Error displaying logs dialog');
      console.error('Error showing logs dialog:', error);
    }
  }

  closeLogsDialog() {
    try {
      if (this.logsDialog) {
        this.logsDialog.hide();
        this.logsDialogVisibility = false;
        this.auditLogs = []; // Clear the logs when closing
      }
    } catch (error) {
      this.notification.warn('Error closing logs dialog');
      console.error('Error closing logs dialog:', error);
    }
  }

  beforeOpening(args: any) {
    args.maxHeight = '85vh';
  }

  // Add property to track warning state
  invoiceFormHasWarnings: boolean = false;

  // Add method to handle warning changes
  onInvoiceWarningsChanged(hasWarnings: boolean) {
    this.invoiceFormHasWarnings = hasWarnings;
    if (this.addNewInvoice) {
      // Adjust dialog width based on warnings
      this.addNewInvoice.width = hasWarnings ? '500px' : '90%';
    }
  }

  // Add method to prepare and calculate XIRR
  private async calculateXIRR(): Promise<number | null> {
    let transactions: { amount: number; date: string }[] = [];

    try {
      if (!this.fileHub.caseFile?.Deposits || !this.fileHub.caseFile?.Invoices) {
        this.errorHandling.handleError({
          error: new Error(ERRORS.XIRR.MISSING_DATA.technical),
          context: 'FinancialTab.calculateXIRR',
          details: {
            hasDeposits: !!this.fileHub.caseFile?.Deposits,
            hasInvoices: !!this.fileHub.caseFile?.Invoices
          }
        });
        this.xirrValue = null;
        return this.xirrValue;
      }

      transactions = [
        ...this.fileHub.caseFile.Deposits.map(deposit => ({
          amount: deposit.DepositAmount ?? 0,
          date: deposit.DepositDate ?? new Date().toISOString()
        })),
        ...this.fileHub.caseFile.Invoices.flatMap(invoice =>
          invoice.InvoicePayments?.map(payment => ({
            amount: -(payment.AmountPaid ?? 0),
            date: payment.DatePaid ?? new Date().toISOString()
          })) || []
        )
      ].filter(transaction => transaction.amount !== 0);

      if (transactions.length < 2) {
        this.notification.warn('Not enough transactions for XIRR calculation');
        this.xirrValue = null;
        return this.xirrValue;
      }

      // Calculate XIRR using the financial service
      this.xirrValue = await this.financialService.calculateXIRR(transactions);
      return this.xirrValue;

    } catch (error) {
      this.errorHandling.handleError({
        error,
        context: 'FinancialTab.calculateXIRR',
        details: {
          caseFileId: this.fileHub.caseFile?.Id,
          transactionCount: transactions?.length
        }
      });
      this.xirrValue = null;
    } finally {
      this.loadingFinancialPerformanceData.set(false);
    }

    return this.xirrValue;
  }

}
