// Angular
import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

// SyncFusion
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import {
  CommandClickEventArgs,
  CommandModel,
  GridModel } from '@syncfusion/ej2-grids';
import { Query } from '@syncfusion/ej2-data';
import { DialogModule } from '@syncfusion/ej2-angular-popups';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { ApiService } from '@services/api/api.service';
import { ApproveReductionRequestComponent } from '@forms/approve-reduction-request/approve-reduction-request.component';
import { ReductionRequest } from '@models/data-contracts';
import { GridTemplateComponent } from '@grids/grid-template/grid-template.component';
import { BalanceDue, FinancialService } from '@services/financial/financial.service';

interface IFormLabels {
  requested: string;
  settleNow: string;
  totalBalanceDue: string;
  totalCourtesyReduction: string;
}
interface IFormData {
  selectedOption: string;
  comment: string;
  balanceDue: string;
  courtesyReduction: {
    amount: number | null;
    percentage: number | null;
  };
}
@Component({
  selector: 'reduction-requests-grid',
  standalone: true,
  imports: [
    CommonModule,
    GridTemplateComponent,
    DialogModule,
    ApproveReductionRequestComponent
  ],
  templateUrl: './reduction-requests-grid.component.html',
  styleUrl: './reduction-requests-grid.component.scss',
})
export class ReductionRequestsGridComponent {
  constructor(
    private api: ApiService,
    private financial: FinancialService
  ) {}

  @ViewChild('modal') public modal: DialogComponent;
  @ViewChild('grid') public reductionsGrid: GridTemplateComponent;

  public gridSettings: GridModel;
  public modalVisibility: Boolean = false;
  public selectedRowData: ReductionRequest;
  public balanceDue: BalanceDue | null;
  public caseFileId?: number;

  ngOnInit() {
    this.gridSettings = {
      dataSource: this.api.getOdata(APIEndpoints.ReductionRequests),
      query: new Query()
        .where('Status', 'notEqual', 'Accepted')
        .where('Status', 'notEqual', 'Approved'),
      columns: [
        {
          type: 'checkbox',
          textAlign: 'Center',
          allowFiltering: false,
          visible: false,
        },
        {
          field: 'Id',
          isPrimaryKey: true,
          visible: false,
          showInColumnChooser: false,
        },
        { field: 'CaseFileId', headerText: 'Case File Id' },
        { field: 'RequestedAmount', headerText: 'Requested $' },
        { field: 'RequestedPercentage', headerText: 'Requested %' },
        { field: 'AutomatedAmount', headerText: 'Automated $' },
        { field: 'AutomatedPercentage', headerText: 'Automated %' },
        { field: 'ApprovedAmount', headerText: 'Approved $' },
        { field: 'ApprovedPercentage', headerText: 'Approved %' },
        { field: 'ApprovedAt', headerText: 'Approved At' },
        { field: 'ApprovedComment', headerText: 'Comment' },
        { field: 'ApprovedByEmail', headerText: 'Email' },
        { field: 'ApprovedByName', headerText: 'Name' },
        { field: 'Status', headerText: 'Status' },
        { field: 'RequesterExplaination', headerText: 'Explaination' },
        { field: 'RequestedByContactId', headerText: 'Requested By' },
        { field: 'GeneratedBySettlenow', headerText: 'Settlenow' },
        { field: 'AutomaticRulesTriggered', headerText: 'Rules Triggered' },
        { type: 'commands', headerText: 'Actions' }
      ],
      toolbar: [],
      commandClick: ($event: CommandClickEventArgs) => this.onCommandClick($event),
      toolbarClick: ($event: any) => this.onToolbarClick($event),
    };
  }

  async onCommandClick(commandClickArgs: CommandClickEventArgs) {
    this.selectedRowData = commandClickArgs?.rowData as ReductionRequest;
    this.caseFileId = this.selectedRowData?.CaseFileId
    commandClickArgs.cancel = true;
    this.modalVisibility = true;


    await this.financial.getBalanceDue(this.selectedRowData.CaseFileId).then(balanceDue => {
      this.balanceDue = balanceDue || null
    })
    this.modal.show();
  }

  async onToolbarClick(args: any) {
    
    if (args.item.text === 'Edit') {
      args.cancel = true;
      this.modalVisibility = true;
      
      await this.financial.getBalanceDue(this.selectedRowData.CaseFileId).then(balanceDue => {
        this.balanceDue = balanceDue || null
        this.modal.show();
      });
    }
  }

  closeDialog() {
    this.selectedRowData = {};
    this.financial.selectedCaseId.set(null);
    this.modal.hide();
  }

  beforeOpening(args: any) {
    args.maxHeight = '85vh';
  }

  // close modal after child form is submitted
  onBackendResponse() {
    this.closeDialog();
    this.reductionsGrid.grid.refresh();
  }
}
