// Angular
import { 
  Component,
  Input,
  ViewChild,
  ViewEncapsulation, 
  WritableSignal, 
  signal,
  Output,
  EventEmitter,
  computed,
  effect
} from '@angular/core';
import { CommonModule } from '@angular/common';

// 3rd Party
import { 
  ScheduleAllModule,
  DayService, 
  WeekService, 
  WorkWeekService, 
  MonthService, 
  AgendaService, 
  EventSettingsModel, 
  GroupModel, 
  ScheduleComponent, 
  ResizeService, 
  DragAndDropService,
  TimelineViewsService,
  PopupOpenEventArgs,
  MonthAgendaService,
  TimelineMonthService,
  Timezone,
  RenderCellEventArgs,
  EventRenderedArgs,
  ExcelExportService,
  ICalendarExportService,
  PrintService,
  RecurrenceEditorAllModule,
  ActionEventArgs,
  ToolbarItemModel } from '@syncfusion/ej2-angular-schedule';
import { AccordionModule } from '@syncfusion/ej2-angular-navigations';
import { createElement, Internationalization } from '@syncfusion/ej2-base';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { Switch } from '@syncfusion/ej2-buttons';
import { CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';
import { FontAwesomeModule, IconDefinition } from '@fortawesome/angular-fontawesome';
import { faCircleUser, faNotesMedical, faBriefcase, faXRay } from '@fortawesome/free-solid-svg-icons';

// Internal
import { Appointment, CaseFile } from '@models/data-contracts';
import { KeyValuePair } from '@services/globals/globals.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { SignalRService } from '@services/api/signal-r.service';
import { SchedulerService } from '@services/scheduler/scheduler.service';
import { CreateSyncfusionComponentsService } from '@services/create-syncfusion-components/create-syncfusion-components.service';
import { ProviderMapComponent } from '@features/provider-map/provider-map.component';
import { LoadingModule } from '@modules/loading.module';
import { LoadingComponent } from '@ui/loading/loading.component';
import { SchedulerSignalsService } from '@services/scheduler/scheduler-signals.service';
import { DOMManipulationService } from '@services/dom-manipulaition/dom-manipulation.service';
import { capitalizeFirstLetter } from '@root/src/app/utils';

@Component({
  selector: 'scheduler-calendar',
  standalone: true,
  imports: [
    CommonModule,
    ScheduleAllModule,
    AccordionModule,
    CheckBoxModule,
    DialogModule,
    ProviderMapComponent,
    LoadingModule,
    RecurrenceEditorAllModule,
    FontAwesomeModule
  ],
  providers: [
    DayService, 
    WeekService, 
    WorkWeekService, 
    MonthService, 
    AgendaService, 
    ResizeService, 
    DragAndDropService, 
    TimelineMonthService,
    TimelineViewsService,
    MonthAgendaService,
    ExcelExportService,
    ICalendarExportService,
    PrintService
  ],
  templateUrl: './scheduler-calendar.component.html',
  styleUrl: './scheduler-calendar.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class SchedulerCalendarComponent {

  constructor (
    private signalR: SignalRService,
    private toast: ToastMessageService,
    private scheduler: SchedulerService,
    private createSyncfusionComponent: CreateSyncfusionComponentsService,
    private DOMManipulation: DOMManipulationService,
    public schedulerSignals: SchedulerSignalsService
  ) {
    effect(() => {
      const locations = this.schedulerSignals.data.Locations();
      const modalities = this.schedulerSignals.data.Modalities();

      if (locations && locations?.length <= 1 && modalities && modalities?.length <= 1) {
        this.calendarGroup = undefined;
      }
    });
  }

  // Decorators
  @Input() componentContextId: string;
  @Input() inputAppointments: Appointment[];
  @Input() caseFile: CaseFile | undefined;
  @Input() excludeFields: string[];
  @Output() appointmentsData = new EventEmitter<any>();
  @Output() newAppointment = new EventEmitter<any>();
  @Output() changedAppointment = new EventEmitter<any>();
  @ViewChild('calendar') calendar: ScheduleComponent;
  @ViewChild('providerMapDialog') providerMapDialog: DialogComponent;

  // Signals
  providerMapVisible = signal(false);
  protected readonly locations = computed(() => this.schedulerSignals.data.Locations());
  protected readonly modalities = computed(() => this.schedulerSignals.data.Modalities());
  protected readonly modalityTypes = computed(() => this.schedulerSignals.data.ModalityTypes());
  readonly isLoading = computed(() => 
    this.schedulerSignals.loading.Locations() || 
    this.schedulerSignals.loading.Modalities() || 
    this.schedulerSignals.loading.Appointments()
  );
  readonly locationResources = computed(() => 
    this.schedulerSignals.data.Locations() || []
  );
  readonly modalityResources = computed(() => 
    this.schedulerSignals.data.Modalities() || []
  );
  readonly hasRequiredData = computed(() => 
    (this.locationResources()?.length > 0) &&
    (this.modalityResources()?.length > 0)
  );

  // Calendar Setup
  persistence: Boolean = true;
  appointments: EventSettingsModel;
  todaysDate: Date = new Date();
  calendarGroup: GroupModel | undefined = { resources: ['Locations', 'Modalities'], byGroupID: true, allowGroupEdit: true, enableCompactView: false };
  allowOverlap: boolean = true;
  scheduleHeight: string = 'auto';
  currentTimezone: Timezone = new Timezone();
  selectedDate: Date = new Date();
  faCircleUser: IconDefinition = faCircleUser;
  faNotesMedical: IconDefinition = faNotesMedical;
  faBriefcase: IconDefinition = faBriefcase;
  faXRay: IconDefinition = faXRay;
  toolbarItems: ToolbarItemModel[] = [
    { name: 'Previous' },
    { name: 'Next' },
    { name: 'DateRangeText' },
    { name: 'NewEvent' },
    { name: 'Today', align: 'Right' },
    { name: 'Views', align: 'Right' }
  ];

  // Appointment Editing
  intl: Internationalization = new Internationalization();
  caseFilesData: WritableSignal<any> = signal(undefined);
  appointmentModality: DropDownList | undefined;
  appointmentPatient: DropDownList | undefined;
  appointmentProcedureCode: DropDownList | undefined;
  appointmentCaseFile: DropDownList | undefined;
  appointmentStatus: DropDownList | undefined;
  appointmentType: DropDownList | undefined;
  appointmentIsBlockSwitch: Switch | undefined;
  appointmentCompleteSwitch: Switch | undefined;
  appointmentProvider: DropDownList | undefined;
  loading: LoadingComponent = new LoadingComponent;
  providerMapDialogButtons: Object[] = [
    { click: this.hideProviderMap.bind(this), buttonModel: { content: 'Cancel', cssClass: 'e-outline' } },
    { click: this.submitProviderSelection.bind(this), buttonModel: { content: 'Select This Provider', isPrimary: true, cssClass: 'e-outline' } },
  ];

  ngOnInit() {
    // this.signalR.startAppointmentHubConnection();
    // this.signalR.addReceiveJSONMessageListener((message) => {
    //   this.scheduler.receiveLockedDatesSignal(message, this.calendar);
    // });
    
    // Ensure each signal is fetched before initializing appointments
    Promise.all([
      this.schedulerSignals.fetchLocations(),
      this.schedulerSignals.fetchModalities(),
      this.schedulerSignals.fetchModalityTypes(),
      this.schedulerSignals.fetchAppointments()
    ]).then(() => {
      this.initializeAppointments();
      this.updateSelectedDateForLocalStorage();
    });
  }
  
  private initializeAppointments() {
    if (!this.inputAppointments && !this.caseFile) {
      // For main calendar view
      const appointments = this.schedulerSignals.data.Appointments();
      if (appointments) {
        const formattedAppointments = appointments.map(appointment => 
          this.scheduler.mapEventForSyncfusion(appointment, this.currentTimezone)
        );
        this.appointments = { dataSource: formattedAppointments };
      }
    } else {
      // For case file specific view
      if (this.inputAppointments?.length === 0) {
        this.toast.showNeutral('No appointments found for file.');
        return;
      }
      const formattedAppointments = this.inputAppointments?.map(appointment => 
        this.scheduler.mapEventForSyncfusion(appointment, this.currentTimezone)
      );
      
      this.appointments = { dataSource: formattedAppointments };
    }
  }

  // Add trackBy for better list rendering
  trackByAppointmentId(index: number, appointment: Appointment): number | undefined {
    return appointment.Id;
  }

  // Set shared signals after data bound to calendar
  onDataBound(args: any) {
    const savedState = localStorage.getItem(`schedule${this.calendar.element.id}`);
    this.schedulerSignals.calendar.Component.set(this.calendar);
    this.schedulerSignals.calendar.State.set(savedState ?? '');
    this.schedulerSignals.calendar.Resources.set(this.calendar.getResourceCollections());
  }

  // Occurs when appointments are added to the calendar
  onRenderCell = async (args: RenderCellEventArgs) => {
    // Alternate opacity values for easier scanability between resources
    let groupNumber = args.element.getAttribute('data-group-index') as string;
    let classString = Number(groupNumber) % 2 === 0 ? 'even' : 'odd';
    args.element.classList.add(classString);
  }

  // Occurs when users attempt edits and on load
  onActionBegin(args: ActionEventArgs): void {


    if (args.requestType === 'eventCreate' || args.requestType === 'eventChange') {
      // Validate event data before proceeding
      if (!this.hasEventData(args)) {
        args.cancel = true;
        return;
      }
      
      const event = args.data as Appointment;
      this.processEvent(args, event);
    }
  }
  
  private hasEventData(args: any): boolean {
    if (!args.data) {
      this.toast.showError('Missing appointment data');
      return false;
    }
    return true;
  }
  
  private processEvent(args: any, event: any) {
    const startTime = event.occurrence ? event.occurrence.StartTime : event.startTime;
    const endTime = event.occurrence ? event.occurrence.EndTime : event.endTime;
  
    if (!this.isSlotAvailable(args, startTime, endTime)) {
      args.cancel = true;
      this.toast.showError('Time slot not available.');
      return;
    }
  }
  
  private isSlotAvailable(args: any, startTime: Date, endTime: Date): boolean {
    return this.allowOverlap || this.calendar?.isSlotAvailable(startTime, endTime);
  }

  // Occurs when users save edits and after load resolves
  onActionComplete(args: ActionEventArgs) {

    // Apply batch saves
    if (args.requestType === 'eventCreated' || args.requestType === 'eventChanged' || args.requestType === 'eventRemoved') {

      this.scheduler.batchSaveSchedule(args, this.currentTimezone).then((res: any) => {
        // No response means everything saved successfully
        if (res instanceof Error) {
          console.error(res);
          this.toast.showError(res.message);
        } else {
          this.toast.showSuccess('Appointment saved successfully');
        }
        
      });
      this.scheduler.removeUserEditingElements();
      this.calendar?.refreshEvents();
    }
  }

  async onPopupOpen(args: PopupOpenEventArgs) {
    const dialogElement: HTMLElement = args.element as HTMLElement;
    const appointment = args.data as Appointment;
    if (!args.data) return;
  
    const dialogHeader: Element = dialogElement.querySelector('.e-dlg-header-content') as Element;
    let editingAppointment: boolean = !!(args.data && (args.data as any).Id);
  
    // Handle different behavior for quick info popup
    if (args.type === 'QuickInfo') this.handleQuickInfoPopup(args);
  
    // Check if user starts editing an appointment
    if (args.type === 'Editor') {
      dialogElement.style.width = '100%';
      this.providerMapVisible.set(true);
      const editorForm: Element = args.element.querySelector('.e-schedule-form') as Element;
      const appointmentTitle: Element = editorForm.querySelector('.e-title-location-row') as Element;
      const resourcesSection: Element = editorForm.querySelector('.e-resources-row') as Element;
  
      if (!editingAppointment) Object.assign((dialogHeader as HTMLElement).style, this.scheduler.removeHeaderStyles());
      this.updateDialogHeader(dialogHeader, appointment, editingAppointment);
      this.prepareEditingAppointment(appointmentTitle, resourcesSection);
      this.addSwitchForBlockingTime(dialogElement, appointment);
      this.addCustomFields(resourcesSection, appointment);
    }
  }

  private handleQuickInfoPopup(args: PopupOpenEventArgs) {
    if (args.target?.tagName === 'TD') {
      this.scheduler.removeUserEditingElements();
      this.signalR.broadcastHTMLElement(args.target, 'data-date', 'add');
    }
  
    this.updateLocationDetails(args);
    this.setSubjectInputValue(args);
  }
  
  private updateLocationDetails(args: PopupOpenEventArgs) {
    const locationDetails = args.element.querySelector('.e-popup-content .e-location .e-location-details') as HTMLElement;
    if (locationDetails && args.data?.['Location']?.Description) {
      locationDetails.innerHTML = args.data['Location'].Description;
    }
  }
  
  private setSubjectInputValue(args: PopupOpenEventArgs) {
    const subjectInput = args.element.querySelector('.e-subject.e-field.e-input') as HTMLInputElement;
    if (subjectInput && this.caseFile?.FileNumber && this.caseFile?.Patient) {
      const { Firstname, Lastname } = this.caseFile.Patient;
      subjectInput.value = `${this.caseFile.FileNumber} - ${capitalizeFirstLetter(Firstname ?? '')} ${capitalizeFirstLetter(Lastname ?? '')}`;
    }
  }

  private updateDialogHeader(dialogHeader: Element, appointment: Appointment, editingAppointment: boolean) {
    if (dialogHeader && appointment['ModalityId'] && editingAppointment) {
      const dialogHeaderStyles = this.scheduler.getEventHeaderStyles(appointment as KeyValuePair, this.calendar);
      Object.assign((dialogHeader as HTMLElement).style, dialogHeaderStyles);
    }
  }

  private prepareEditingAppointment(appointmentTitle: Element, resourcesSection: Element) {
    this.removeDefaultElements(appointmentTitle);
    this.moveDropdowns(appointmentTitle, resourcesSection);
  }

  private removeDefaultElements(appointmentTitle: Element) {
    const locationContainer = appointmentTitle.querySelector('.e-location-container');
    const subjectContainer = appointmentTitle.querySelector('.e-subject-container');
    if (locationContainer) locationContainer.remove();
    if (subjectContainer) subjectContainer.remove();
  }

  private moveDropdowns(appointmentTitle: Element, resourcesSection: Element) {
    const locationDropdown = resourcesSection.querySelector('.e-LocationId-container') as Element;
    const modalityDropdown = resourcesSection.querySelector('.e-ModalityId-container') as Element;

    if (locationDropdown) appointmentTitle.appendChild(locationDropdown);
    if (modalityDropdown) appointmentTitle.appendChild(modalityDropdown);
  }

  private addCustomFields(resourcesSection: Element, appointment: Appointment) {
    const noCustomFields: boolean = resourcesSection.querySelector('.custom-field-row') === null;

    if (noCustomFields) {
      resourcesSection.classList.add('row');
      this.styleResourceSectionChildren(resourcesSection);
      this.addDropDownsForAppointment(resourcesSection, appointment);
    }
  }

  private styleResourceSectionChildren(resourcesSection: Element) {
    Array.from(resourcesSection.children).forEach(child => {
      child.classList.add('col-12', 'col-md-6', 'col-lg-4');
      child.classList.remove('e-resources');

      const customFields = child.querySelectorAll('.custom-field-row');
      customFields.forEach(field => {
          field.classList.add('col-12', 'col-md-6');
      });
    });
  }

  private addSwitchForBlockingTime(dialogElement: Element, appointment: Appointment) {
    if (this.appointmentIsBlockSwitch === undefined) {
      const timeRow = dialogElement.querySelector('.e-all-day-time-zone-row') as Element;
      const isBlockSwitchOptions = { checked: appointment['Block'] as boolean, onLabel: 'Locked', offLabel: 'Time Slot Open', name: 'IsBlock' };
      this.appointmentIsBlockSwitch = this.createSyncfusionComponent.createSwitch(isBlockSwitchOptions);
      this.scheduler.addComponentToEditorWindow(timeRow, this.appointmentIsBlockSwitch, 'isBlock', 'custom-field-container');
    }
  }

  private addDropDownsForAppointment(resourcesSection: Element, appointment: Appointment) {
    if (!this.excludeFields.includes('CaseFileId')) this.addDropDownForCaseFile(resourcesSection, appointment);
    if (!this.excludeFields.includes('AppointmentTypeId')) this.addDropDownForAppointmentType(resourcesSection, appointment);
    if (!this.excludeFields.includes('AppointmentStatusId')) this.addDropDownForAppointmentStatus(resourcesSection, appointment);
    if (!this.excludeFields.includes('ProcedureCodeId')) this.addDropDownForProcedureCode(resourcesSection, appointment);
    if (!this.excludeFields.includes('ProviderId')) this.addDropDownForProvider(resourcesSection, appointment);
  }

  private addDropDownForCaseFile(resourcesSection: Element, appointment: Appointment) {
    this.schedulerSignals.fetchCaseFiles().then((result) => {
      const caseFiles = result?.map((result: any) => {
        return {
          Id: result.Id,
          Text: `CaseFile: ${result.FileNumber}, Type: ${result.CaseType}`
        }
      });
      const caseFilesDrpDownOptions = { dataSource: caseFiles, fields: { text: 'Text', value: 'Id' }, placeholder: 'Case File', value: appointment['CaseFileId'] };
      this.appointmentCaseFile = this.createSyncfusionComponent.createDropDownList(caseFilesDrpDownOptions);
      this.scheduler.addComponentToEditorWindow(resourcesSection, this.appointmentCaseFile, 'CaseFileId', 'custom-field-row col-12 col-md-6 col-lg-4 order-3');
    }); 
  }

  private addDropDownForAppointmentType(resourcesSection: Element, appointment: Appointment) {
    this.schedulerSignals.fetchAppointmentTypes().then((result) => {
      const typeDrpDwnOptions = { dataSource: result as any[], fields: { text: 'Description', value: 'Id' }, placeholder: 'Appointment Type', value: appointment['AppointmentTypeId'] };
      this.appointmentType = this.createSyncfusionComponent.createDropDownList(typeDrpDwnOptions);
      this.scheduler.addComponentToEditorWindow(resourcesSection, this.appointmentType, 'AppointmentTypeId', 'custom-field-row col-12 col-md-6 col-lg-4 order-2');
    });
  }

  private addDropDownForAppointmentStatus(resourcesSection: Element, appointment: Appointment) {
    this.schedulerSignals.fetchAppointmentStatuses().then((result) => {
      const statusDrpDwnOptions = { dataSource: result as any[], fields: { text: 'Description', value: 'Id' }, placeholder: 'Status', value: appointment['AppointmentStatusId'] ?? 1 };
      this.appointmentStatus = this.createSyncfusionComponent.createDropDownList(statusDrpDwnOptions);
      this.scheduler.addComponentToEditorWindow(resourcesSection, this.appointmentStatus, 'AppointmentStatusId', 'custom-field-row col-12 col-md-6 col-lg-4 order-1');
    });
  }

  private async addDropDownForProcedureCode(resourcesSection: Element, appointment: Appointment) {
    
    const modality = await this.schedulerSignals.fetchModality(appointment.ModalityId as number) ?? []; // Await response to determine if modality is present
    this.schedulerSignals.fetchProcedureCodesMatchingModalityType((modality as any)[0]?.ModalityTypeId).then((result) => {
      const procedureCodesDrpDwnOptions = { dataSource: result as any[], fields: { text: 'Description', value: 'Id' }, placeholder: 'Procedure', value: appointment['ProcedureCodeId']};
      this.appointmentProcedureCode = this.createSyncfusionComponent.createDropDownList(procedureCodesDrpDwnOptions);
      this.scheduler.addComponentToEditorWindow(resourcesSection, this.appointmentProcedureCode, 'ProcedureCodeId', 'custom-field-row col-12 col-md-6 col-lg-4 order-4');
    });
  } 

  private async addDropDownForProvider(resourcesSection: Element, appointment: Appointment) {
    await this.schedulerSignals.fetchProviders().then((result) => {
    const providersDrpDwnOptions = { dataSource: result as any[], fields: { text: 'Name', value: 'Id' }, placeholder: 'Provider', value: appointment['ProviderId']};
      this.appointmentProvider = this.createSyncfusionComponent.createDropDownList(providersDrpDwnOptions);
      this.scheduler.addComponentToEditorWindow(resourcesSection, this.appointmentProvider, 'ProviderId', 'custom-field-row col-12 col-md-6 col-lg-4 order-5');
      this.addProviderMapButton(resourcesSection); // Add this to .then() of await so it doesnt appear until after provider list is ready.
    });
  }

  // Update the show/hide methods
  showProviderMap() {
    if (this.providerMapDialog) this.providerMapDialog.show();
  }

  hideProviderMap() {
    if (this.providerMapDialog) this.providerMapDialog.hide();
  }

  // Update the button creation method
  private async addProviderMapButton(resourcesSection: Element) {
    const providerBtnOptions = { 
      className: 'e-btn e-outline', 
      innerHTML: 'Provider Map',
      attrs: { name: 'ProviderBtn', type: 'button', 'data-ripple': 'true' } 
    };
    const addProviderBtn = createElement('button', providerBtnOptions) as HTMLButtonElement;
    addProviderBtn.onclick = () => this.showProviderMap();
    this.DOMManipulation.addBtnToHTMLElement(
      resourcesSection, 
      addProviderBtn, 
      'custom-field-row col-12 col-md-6 col-lg-4 order-6 d-flex align-items-center'
    );
  }

  private updateSelectedDateForLocalStorage() {
    const persistedSettings = JSON.parse(localStorage.getItem('schedule_ejs-scheduler-main-appointment-calendar') ?? '');

    if (persistedSettings !== '') {
      persistedSettings.currentView = 'Day';
      persistedSettings.selectedDate = this.selectedDate;
      localStorage.setItem('schedule_ejs-scheduler-main-appointment-calendar', JSON.stringify(persistedSettings));
    }
  }

  // Occurs whenever a popup modal is closed
  onPopupClose(args: any) {
    this.scheduler.removeUserEditingElements();

    // Ensure popup window is Editor before updating values
    if (args.type === 'Editor') {
      // Set CaseFileId if it exists
      if (this.caseFile?.Id) args.data['CaseFileId'] = this.caseFile?.Id;
      this.providerMapVisible.set(false);
      const validityCheck = this.scheduler.validateAppointment(args.data);
      if (validityCheck) {
        args.cancel = true;
        this.toast.showError(validityCheck);
        return;
      }

      this.clearEditorWindowFields();
      const dialogElement = args.element as HTMLElement;
      const customFields = dialogElement.querySelectorAll('.custom-field-row');
      customFields.forEach(field => field.remove());
    }
  }

  // Add completed styles to completed appointments
  onEventRendered(args: EventRenderedArgs) {

    if (args.data['IsCompleted']) {
      args.element.classList.add('complete');
    } else {
      args.element.classList.add('incomplete');
    }
  }

  // Provider Map items below
  @ViewChild('providerMap') providerMap: ProviderMapComponent;
  markerWindowTxt: string = 'Select this Provider';
  provider: any;

  onProviderSelected(provider: any) {
    this.provider = provider.data;
  }

  submitProviderSelection() {
    (this.appointmentProvider as DropDownList).value = this.provider.id;
    this.providerMapDialog.hide();
  }

  clearEditorWindowFields() {
    if (this.appointmentCaseFile) this.appointmentCaseFile = undefined;
    if (this.appointmentIsBlockSwitch) this.appointmentIsBlockSwitch = undefined;
    if (this.appointmentModality) this.appointmentModality = undefined;
    if (this.appointmentProcedureCode) this.appointmentProcedureCode = undefined;
    if (this.appointmentProvider) this.appointmentProvider = undefined;
  }
}