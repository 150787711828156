// Angular
import { Component, Input } from '@angular/core';
import { ReactiveFormsModule, Validators, FormBuilder } from '@angular/forms';

// 3rd Party
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { TextBoxModule, NumericTextBoxModule } from '@syncfusion/ej2-angular-inputs';

// Internal
import { ApiService } from '@root/src/app/shared/services/api/api.service';
import { Query, ReturnOption } from '@syncfusion/ej2-data';
import { APIEndpoints } from '@root/src/app/shared/models/api/Endpoints';
import { DropdownSingleComponent } from '../../../ui/dropdown-single/dropdown-single.component';

@Component({
  selector: 'app-add-address',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    DialogModule,
    TextBoxModule,
    NumericTextBoxModule,
    DropdownSingleComponent,
  ],
  templateUrl: './add-address.component.html',
  styleUrl: './add-address.component.scss'
})
export class AddAddressComponent {

  constructor(private fb: FormBuilder, private api: ApiService) { }

  @Input() AddressType = "1";
  @Input() ObjectType = "Provider"
  states = APIEndpoints.States;
  defaultState: any;

  addressFormGroup = this.fb.group({
    Address: ['', [Validators.required]],
    Address2: ['',],
    City: ['', [Validators.required]],
    State: [, [Validators.required]],
    County: ['', [Validators.required]],
    Zip: ['', [Validators.required]],
    AddressType: [this.AddressType, [Validators.required]],
    ObjectType: [this.ObjectType, [Validators.required]],
  })

  ngOnInit() {
    this.getDefaultState()
  }

  //Gets a default state for the states dropdown
  getDefaultState() {
    this.api.getOdata(APIEndpoints.ConfigVariables).executeQuery(new Query().where('VariableName', 'equal', 'default_state', true))
      .then((e: ReturnOption) => {
        (e.result as object[]).forEach((element) => {
          let res = element as any
          this.defaultState = res["VariableValue"]
        });
      });
  }
}
