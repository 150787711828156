// Angular
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

// 3rd Party
import { registerLicense } from '@syncfusion/ej2-base';

// Internal
import config from '@config/config.json';
import { AppModule } from '@modules/app.module';


// Register Syncfusion license
registerLicense(config.secrets.SYNCFUSION_KEY);
enableProdMode();

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
