// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// 3rd Party
import { DataManager, ODataV4Adaptor } from '@syncfusion/ej2-data';
import { Fetch } from '@syncfusion/ej2-base';

// Internal
import { environment } from '@environments/environment';
import { AuthenticatedBaseService } from '@core/services/authenticated-base.service';
import { CognitoService } from '@services/auth/cognito.service';
import { ErrorHandlingService } from '@core/services/error-handling.service';
import { NotificationService } from '@core/services/notification.service';

export interface BatchData {
  action: string,
  Changed: any[],
  Added: any[],
  Deleted: any[]
}

@Injectable({
  providedIn: 'root',
})
export class ApiService extends AuthenticatedBaseService {

  public ODATA_BASE_URL: string = environment.BACKENDURL + 'odata';

  constructor(
    http: HttpClient,
    errorHandling: ErrorHandlingService,
    notification: NotificationService,
    cognito: CognitoService
  ) {
    super(http, errorHandling, notification, cognito);
  }

  /**
   * basicFetch
   * @description This method will build the request object for the API calls for the rest of the methods in this service
   * @param {string} url
   * @memberof ApiService
   * @returns Promise - fetch() returns a promise
   */
  async basicFetch(url: string, hasOdata = true) {
    const handleBaseUrl = hasOdata ? this.ODATA_BASE_URL : environment.BACKENDURL;
    return fetch(handleBaseUrl + url, { method: 'GET', headers: this.getAuthHeaders() }).then((res) => res.json());
  }

  /**
   * basicPatch
   * @description Builds a basic patch request that accepts url and data
   * @param {string} url
   * @param data
   * @returns promise
   */
  basicPatch(url: string, data: any, hasOdata = true) {
    const fetchURL = async () => {
      return await fetch(hasOdata ? this.ODATA_BASE_URL + url : environment.BACKENDURL + url, {
        method: 'PATCH',
        headers: this.getAuthHeaders(),
        body: JSON.stringify(data)
      }).catch(error => console.log(error))
    }
    return fetchURL();
  }

  basicPost(url: string, data: any, hasOdata = true) {
    const fetchURL = async () => {
      return await fetch(hasOdata ? this.ODATA_BASE_URL + url : environment.BACKENDURL + url, {
        method: 'POST',
        headers: this.getAuthHeaders(),
        body: JSON.stringify(data)
      }).catch(error => console.log(error))
    }
    return fetchURL();
  }

  /**
   * basicPut
   * @description Builds a basic put request that accepts url and data
   * @param {string} url
   * @param data
   * @returns promise
   */
  basicPut(url: string, data: any) {
    const fetchURL = async () => {
      return await fetch(environment.BACKENDURL + url, {
        method: 'PUT',
        headers: this.getAuthHeaders(),
        body: JSON.stringify(data)
      }).catch(error => console.log(error))
    }
    return fetchURL();
  }

  /**
   * GET
   * @description Fetches data from api endpoint into a DataManager
   * @param {string} url
   * @memberof ApiService
   * @returns DataManager - builds fetched data through ODataV4Adaptor()
   */
  getOdata(endpoint: string): DataManager {
    return new DataManager({
      url: this.ODATA_BASE_URL + endpoint,
      adaptor: new VarentODataAdaptor(this.cognito.getUserIdToken()),
      crossDomain: true,
      headers: [this.getAuthHeaders()]
    });
  }

  private getErrorMessage(error: any): string {
    if (error.error?.message) {
      return error.error.message;
    }
    if (error.message) {
      return error.message;
    }
    if (typeof error.error === 'string') {
      return error.error;
    }
    if (error.status === 0) {
      return 'Unable to connect to server';
    }
    return 'An unexpected error occurred';
  }

  private async executeWithRetry<T>(
    action: () => Promise<T>,
    retries = 3,
    timeout = 30000
  ): Promise<T> {
    for (let i = 0; i < retries; i++) {
      try {
        const timeoutPromise = new Promise((_, reject) =>
          setTimeout(() => reject(new Error('Request timed out')), timeout)
        );
        return await Promise.race([action(), timeoutPromise]) as T;
      } catch (error) {
        if (i === retries - 1) throw error;
        await new Promise(resolve => setTimeout(resolve, 1000 * (i + 1)));
      }
    }
    throw new Error('Max retries reached');
  }

  /**
   * PATCH
   * @description Saves any updated server to odata endpoit
   * @param {string} url
   * @memberof ApiService
   */
  patchOdata(url: string, data: any) {
    return fetch(this.ODATA_BASE_URL + url, { method: 'PATCH', headers: this.getAuthHeaders(), body: JSON.stringify(data) });
  }

  /**
   * POST
   * @description Creates a new record in the odata endpoint
   * @param {string} url
   * @memberof ApiService
   */
  postOdata(url: string, data: any) {
    return fetch(this.ODATA_BASE_URL + url, { method: 'POST', headers: this.getAuthHeaders(), body: JSON.stringify(data) });
  }

  /**
   * DELETE
   * @description Deletes any updated server to odata endpoit
   * @param {string} url
   * @memberof ApiService
   */
  deleteOdata(url: string) {
    return fetch(this.ODATA_BASE_URL + url, { method: 'DELETE', headers: this.getAuthHeaders() });
  }

  /**
   * Fetch for all method types
   * @param url
   * @param method
   * @param body
   * @param headers
   * @returns fetch()
   */
  async fetchRequest(url: string, method?: string, body?: any, headers?: any, hasOdata: boolean = false) {
    const requestUrl = `${hasOdata ? this.ODATA_BASE_URL : environment.BACKENDURL}${url}`
    const requestMethod = method ?? 'GET';
    const requestHeader = headers ?? this.getAuthHeaders();

    try {

      const response = await fetch(requestUrl, {
        method: requestMethod,
        headers: requestHeader,
        body: body ? JSON.stringify(body) : undefined
      });

      // Check if response is ok (status in 200-299 range)
      if (!response.ok) {
        const errorResponse = response.clone();
        const errorText = await errorResponse.text();
        let errorMessage = errorText;

        try {
          const errorJson = JSON.parse(errorText);
          errorMessage = errorJson.message || errorJson.error?.message || errorJson.error || errorText;
        } catch {
          // If JSON parsing fails, use the text as is
          errorMessage = errorText;
        }

        // Log error details
        this.errorHandling.handleError({
          status: response.status,
          statusText: response.statusText,
          url: requestUrl,
          method: requestMethod,
          message: errorMessage,
          body: body
        }, 'ApiService.fetchRequest');

        throw new Error(errorMessage);
      }

      // Only try to parse JSON if we're expecting it
      if (response.headers.get('content-type')?.includes('application/json')) {
        return await response.json();
      }

      return response;
    } catch (error) {
      console.error('API Request Error:', error);
      throw error;
    }
  }

  public async basicDelete(url: string, body?: any): Promise<Response> {
    const options: RequestInit = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    };

    if (body) {
      options.body = JSON.stringify(body);
    }

    const response = await fetch(`${this.ODATA_BASE_URL}${url}`, options);
    return response;
  }

  protected override getAuthHeaders(): { [key: string]: string } {
    return {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.cognito.getUserIdToken()
    };
  }
}

export class VarentODataAdaptor extends ODataV4Adaptor {
  private readonly token: string;

  constructor(token: string) {
    super();
    this.token = token;
  }

  public override beforeSend(
    dm: DataManager,
    request: Request,
    settings: Fetch
  ): void {
    // Don't append - set the header to avoid duplicates
    request.headers.set('Authorization', `Bearer ${this.token}`);
    request.headers.set('Content-Type', 'application/json');

    // Let parent class handle the rest of OData formatting
    super.beforeSend(dm, request, settings);
  }
}
