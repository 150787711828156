<div id="amplify-login" class="amplify-login-authenticator">
  <amplify-authenticator [hideSignUp]="true" [formFields]="formFields">
    <ng-template amplifySlot="header">
      <div class="header">
        <div class="logo">
          <app-svg class="svg-logo" [path]="logo"></app-svg>
        </div>
        <h1>Case Compass</h1>
      </div>
    </ng-template>
  
    <ng-template amplifySlot="sign-in-footer">
        <div class="sign-in-footer d-flex justify-content-center">
          <button ejs-button [isPrimary]="false" cssClass="e-link" (click)="authenticator.toForgotPassword()">
            <span>Forgot Password?</span>
          </button>
        </div>
    </ng-template>
  
    <ng-template amplifySlot="footer">
      <div class="footer d-flex justify-content-center">
        <p>Case Compass<span class="separator">|</span>2024 © All Rights Reserve</p>
      </div>
    </ng-template>
  
    <ng-template amplifySlot="confirm-sign-in-footer">
      <div class="remember-device">
        <ejs-checkbox #rememberCheck label="Remember This Device" (change)="handleCheck()" />
      </div>
    </ng-template>
  
    <ng-template amplifySlot="forgot-password-header">
      <div class="forgot-password-header">
        <h3 class="amplify-heading amplify-heading--3">Enter Information:</h3>
      </div>
    </ng-template>
  
    <ng-container *ngIf="authenticator.authStatus === 'configuring'">
      <div class="info loading">
        <p>Loading...</p>
      </div>
    </ng-container>
  </amplify-authenticator>
</div>