import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BACKENDURL } from '@root/src/environments/environment.production';
import { NetworkStatus } from '@app/shared/models/components/provider-map.model';
import { Query } from '@syncfusion/ej2-data';
import { ApiService } from '@services/api/api.service';
import { AuthenticatedBaseService } from '@core/services/authenticated-base.service';
import { ErrorHandlingService } from '../core/services/error-handling.service';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from '@core/services/notification.service';
import { CognitoService } from '@services/auth/cognito.service';

@Injectable({
  providedIn: 'root'
})
export class NetworkStatusService extends AuthenticatedBaseService {

  constructor(
    private api: ApiService,
    http: HttpClient,
    errorHandling: ErrorHandlingService,
    notification: NotificationService,
    cognito: CognitoService
  ) {
    super(http, errorHandling, notification, cognito);
  }

  getNetworkStatuses(): Observable<NetworkStatus[]> {
    const endpoint = '/ProviderPriorityStatuses';
    const query = new Query();
    return from(this.api.getOdata(endpoint).executeQuery(query)).pipe(
      map(response => response as unknown as NetworkStatus[]),
      catchError(error => this.handleError(error, 'NetworkStatusService.getNetworkStatuses'))
    );
  }
}
