import { secrets } from './environment.secrets';

// Development environment
export const environment = {
    production: false,
    mondayBoardId: '8206260134', // Non-sensitive value ok in git
    aws: {
        region: 'us-west-2',
        cloudwatch: {
            logGroup: 'casecompass-logs-dev',
            logStream: 'frontend-logs-dev'
        }
    },
    BACKENDURL: 'https://localhost:63094/',
    ...secrets // amplifyConfiguration comes from here
};
