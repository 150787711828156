// Angular
import { Component, signal, ViewChild, WritableSignal } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CommonModule, DatePipe } from '@angular/common';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

// 3rd Party
import { DialogAllModule, DialogComponent } from '@syncfusion/ej2-angular-popups';
import { ColumnModel } from '@syncfusion/ej2-angular-grids';

// Models
import { APIEndpoints } from '@models/api/Endpoints';
import { Address, Patient } from '@models/data-contracts';
import { SetGridDataArgs } from '@shared/components/base-grid/models/grid.models';

// Services
import { ApiService } from '@services/api/api.service';
import { PatientsService } from '@features/patients/services/patients.service';

// Components
import { BaseGridComponent } from '@shared/components/base-grid/base-grid.component';
import { PatientFormComponent } from '@features/patients/components/patient-form/patient-form.component';

// Core
import { ComponentBase } from '@core/base/component.base';
import { BaseGridService } from '@shared/components/base-grid/services/state.service';
import { BaseGridHooks } from '@shared/components/base-grid/services/hooks.service';
import { BaseGrid } from '@core/base/grid.base';

interface PatientFormControls {
  Id: FormControl<string | null>;
  Firstname: FormControl<string | null>;
  Lastname: FormControl<string | null>;
  Dob: FormControl<string | null>;
  Gender: FormControl<string | null>;
  Language: FormControl<string | null>;
  Email: FormControl<string | null>;
  Minor: FormControl<boolean | null>;
}
type PatientFormGroup = FormGroup<PatientFormControls>;

@Component({
  selector: 'patients-grid',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    BaseGridComponent,
    DialogAllModule,
    PatientFormComponent
  ],
  providers: [
    BaseGridHooks,
    BaseGridService,
    DatePipe,
    PatientsService
  ],
  templateUrl: './patients-grid.component.html',
  styleUrl: './patients-grid.component.scss'
})
export class PatientsGridComponent extends BaseGrid {
  // Decorator Properties
  @ViewChild('nameTemplate', { static: true }) nameTemplate!: string;
  @ViewChild('patientDialog') patientDialog!: DialogComponent;
  @ViewChild('patientFormComponent') patientFormComponent!: PatientFormComponent & { form: PatientFormGroup };

  // Signals
  patientForm: WritableSignal<PatientFormGroup | undefined> = signal(undefined);
  loading = signal(true);
  patient = signal<Patient | undefined>(undefined);

  // Properties
  visibleAddresses: Address[] = [];
  settings: SetGridDataArgs = {
    endpoint: APIEndpoints.Patients,
    query: this.patientsService.getPatientsGridQuery(),
    name: 'Patients',
    useRoundedEdges: true,
    transformData: (data: any[]) => this.patientsService.transformPatientsForGrid(data),
    gridSettings: {
      columns: [
        { type: 'checkbox', width: 50, allowFiltering: false },
        { field: 'Id' },
        {
          field: 'Lastname',
          headerText: 'Patient Name',
          formatter: (field: string, data: any) => {
          return `${data.Firstname || ''} ${data.Lastname || ''}`.trim();
          }
        },
        { field: 'TotalFiles', headerText: 'Total Files', allowFiltering: false },
        { field: 'XrefAddressPatients.Address.Address1', headerText: 'Address', allowSorting: false, allowFiltering: false },
        { field: 'XrefAddressPatients.Address.City', headerText: 'City', allowSorting: false, allowFiltering: false },
        { field: 'XrefAddressPatients.Address.StateNavigation.Name', headerText: 'State', allowSorting: false, allowFiltering: false },
        { field: 'XrefAddressPatients.Address.Zip', headerText: 'Zip', allowSorting: false, allowFiltering: false },
        {
          field: 'PrimaryPhone', headerText: 'Phone', allowSorting: false, allowFiltering: false,
          formatter: (field: string, data: any) => {
            if (!data.PrimaryPhone) return '';
            const phone = data.PrimaryPhone.replace(/\D/g, '');
            if (phone.length !== 10) return data.PrimaryPhone;
            return `(${phone.substring(0, 3)}) ${phone.substring(3, 6)}-${phone.substring(6)}`;
          }
        },
        { field: 'Dob', headerText: 'Date Of Birth', type: 'date', format: { type: 'date', format: 'MM/dd/yyyy' } },
        { field: 'Gender' },
        { field: 'Language' },
        { field: 'Email' },
        { field: 'Minor' },
        { type: 'commands', headerText: 'Actions' }
      ],
      toolbarClick: (args: any) => this.onToolbarClick(args),
      commandClick: (args: any) => this.onCommandClick(args)
    },
    searchableFields: ['Id', 'Lastname', 'Firstname', 'Email']
  };
  patientDialogButtons: Object[] = [
    { click: this.cancelPatientForm.bind(this), buttonModel: { content: 'Cancel', cssClass: 'e-outline' } },
    { click: this.submitPatientForm.bind(this), buttonModel: { content: 'Submit', isPrimary: true } },
  ];

  constructor(
    private fb: FormBuilder,
    private patientsService: PatientsService
  ) {
    super();
  }

  ngOnInit() {
    this.patientForm = signal(this.fb.group({
      Id: [''],
      Firstname: [''],
      Lastname: [''],
      Dob: [''],
      Gender: [''],
      Language: [''],
      Email: [''],
      Minor: [false]
    }));
  }

  ngAfterViewInit() {
    this.applyColumnTemplates();
  }

  onToolbarClick(args: any) {
    if (args.item.text === 'Add') {
      args.cancel = true;
      this.patient.set(undefined);
      this.patientDialog.show();
    }
  }

  onCommandClick(args: any) {
    if (args.commandColumn.title === 'Edit') {
      args.cancel = true;
      this.patientFormComponent.patient.set(args.rowData);
      this.patientDialog.show();
    }
  }

  applyColumnTemplates() {
    if (!this.settings?.gridSettings?.columns) return;

    const fullnameColumn = this.settings.gridSettings.columns.find(
      (column): column is ColumnModel => typeof column !== 'string' && 'field' in column && column.field === 'Fullname'
    );

    if (fullnameColumn) {
      fullnameColumn.template = this.nameTemplate;
    }
  }

  onPatientNameClick(data: any) {
    this.router.navigate(['/patients', data.Id]);
  }

  async submitPatientForm() {
    const form = this.patientFormComponent?.patientForm;
    const addressForm = this.patientFormComponent?.addressFormComp?.addressForm;
    if (!form) return;

    // Mark all patient form controls as touched to trigger validation
    Object.keys(form.controls).forEach(key => {
      const control = form.get(key);
      if (control) {
        control.markAsTouched();
        control.markAsDirty();
        control.updateValueAndValidity();
      }
    });

    // Mark all address form controls as touched to trigger validation
    if (addressForm) {
      Object.keys(addressForm.controls).forEach(key => {
        const control = addressForm.get(key);
        if (control) {
          control.markAsTouched();
          control.markAsDirty();
          control.updateValueAndValidity();
        }
      });
    }

    // Check if either form is invalid after marking all controls as touched
    if (!form.valid || (addressForm && !addressForm.valid)) {
      this.notify('Please fill in all required fields', this.NotificationSeverity.Warning);
      return;
    }

    try {
      if (this.patientFormComponent) {
        const result = await this.patientFormComponent.onSubmit();
      }

      const formValue = form.value as Partial<Patient>;
      const patientId = this.patient()?.Id;

      if (patientId) {
        await this.patientsService.updatePatient(patientId, formValue);
        this.notify('Patient updated successfully', this.NotificationSeverity.Success);
      } else {
        await this.patientsService.createPatient(formValue);
        this.notify('Patient created successfully', this.NotificationSeverity.Success);
      }

      this.patientDialog.hide();

      // Refresh the grid by updating the settings
      this.settings = {
        ...this.settings,
        query: this.patientsService.getPatientsGridQuery()
      };
    } catch (error) {
      this.handleError(error, {
        context: 'PatientsGridComponent.submitPatientForm',
        userMessage: 'Failed to save patient',
        severity: this.ErrorSeverity.Error
      });
    }
  }

  cancelPatientForm() {
    this.patientFormComponent.resetForms();
    this.patientForm.set(undefined);
    this.patientDialog.hide();
  }
}
