<!-- Provider Form Start -->
<div id="provider-form" [class]="formClasses.formContainer">

    <!-- Add loading overlay -->
    <div *ngIf="loadingStatus().loading" class="loading-overlay">
        <div class="loading-content">
            <div class="spinner"></div>
            <div class="loading-message">{{ loadingStatus().message }}</div>
        </div>
    </div>

    <h2 [class]="formClasses.mainTitle">
        <fa-icon [icon]="providerIcons.form" />
        <span>{{formMainTitle ?? 'General Info'}}</span>
    </h2>

    <form [formGroup]="providerForm" [class]="formClasses.form" (ngSubmit)="onSubmit()" #providerFormElement>

        <section [class]="formClasses.section + ' provider-section'">

            <div [class]="formClasses.group">

                <div *ngIf="displayFields.includes('Name')" [class]="formClasses.inputContainer + ' ' + formClasses.threeCol" [class.error]="(providerForm.get('Name')?.errors && providerForm.get('Name')?.touched) || (providerForm.get('Name')?.errors && providerFormElement['submitted'])">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="providerIcons.provider" />
                        <ejs-tooltip *ngIf="(providerForm.get('Name')?.errors && providerForm.get('Name')?.touched) || (providerForm.get('Name')?.errors && providerFormElement['submitted'])" [content]="getErrorMessage('Name')" />
                    </span>
                    <ejs-textbox formControlName="Name"
                        placeholder="Provider Name *"
                        floatLabelType="Auto"
                        [class]="formClasses.input"
                        [value]="providerForm.get('Name')?.value" />
                    <ng-container *ngIf="(providerForm.get('Name')?.invalid && providerForm.get('Name')?.touched) || (providerForm.get('Name')?.errors && providerFormElement['submitted'])">
                        <span [class]="formClasses.error">{{getErrorMessage('Name')}}</span>
                    </ng-container>
                </div>

                <div *ngIf="displayFields.includes('XrefProviderProviderTypes')" [class]="formClasses.inputContainer + ' ' + formClasses.threeCol" [class.error]="(providerForm.get('XrefProviderProviderTypes')?.errors && providerForm.get('XrefProviderProviderTypes')?.touched) || (providerForm.get('XrefProviderProviderTypes')?.errors && providerFormElement['submitted'])">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="providerIcons.type" />
                        <ejs-tooltip *ngIf="(providerForm.get('XrefProviderProviderTypes')?.errors && providerForm.get('XrefProviderProviderTypes')?.touched) || (providerForm.get('XrefProviderProviderTypes')?.errors && providerFormElement['submitted'])" [content]="getErrorMessage('XrefProviderProviderTypes')" />
                    </span>
                    <ejs-multiselect formControlName="XrefProviderProviderTypes"
                        placeholder="Provider Types *"
                        floatLabelType="Auto"
                        [class]="formClasses.input"
                        [fields]="providerTypeFields"
                        [dataSource]="providerTypes"
                        mode="Box"
                        [showClearButton]="true"
                        [enableSelectionOrder]="true"
                        [value]="providerForm.get('XrefProviderProviderTypes')?.value" />
                    <ng-container *ngIf="(providerForm.get('XrefProviderProviderTypes')?.invalid && providerForm.get('XrefProviderProviderTypes')?.touched) || (providerForm.get('XrefProviderProviderTypes')?.errors && providerFormElement['submitted'])">
                        <span [class]="formClasses.error">{{getErrorMessage('XrefProviderProviderTypes')}}</span>
                    </ng-container>
                </div>

                <div *ngIf="displayFields.includes('TaxId')" [class]="formClasses.inputContainer + ' ' + formClasses.threeCol" >
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="providerIcons.taxId" />
                    </span>
                    <ejs-textbox formControlName="TaxId"
                        placeholder="Tax ID"
                        floatLabelType="Auto"
                        [class]="formClasses.input"
                        [value]="providerForm.get('TaxId')?.value" />
                </div>

                <div *ngIf="displayFields.includes('Notes')" [class]="formClasses.inputContainer + ' ' + formClasses.threeCol" >
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="providerIcons.notes" />
                    </span>
                    <ejs-textbox formControlName="Notes"
                        placeholder="Notes"
                        floatLabelType="Auto"
                        multiline="true"
                        [class]="formClasses.input"
                        [value]="providerForm.get('Notes')?.value" />
                </div>

                <div *ngIf="displayFields.includes('ReimbursementRate')" [class]="formClasses.inputContainer + ' ' + formClasses.threeCol" [class.error]="(providerForm.get('ReimbursementRate')?.errors && providerForm.get('ReimbursementRate')?.touched) || (providerForm.get('ReimbursementRate')?.errors && providerFormElement['submitted'])">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="providerIcons.reimbursementRate" />
                        <ejs-tooltip *ngIf="(providerForm.get('ReimbursementRate')?.errors && providerForm.get('ReimbursementRate')?.touched) || (providerForm.get('ReimbursementRate')?.errors && providerFormElement['submitted'])" [content]="getErrorMessage('ReimbursementRate')" />
                    </span>
                    <ejs-numerictextbox formControlName="ReimbursementRate"
                        placeholder="Purchase Rate"
                        floatLabelType="Auto"
                        [class]="formClasses.input"
                        [value]="providerForm.get('ReimbursementRate')?.value"
                        [min]="0" />
                    <ng-container *ngIf="(providerForm.get('ReimbursementRate')?.invalid && providerForm.get('ReimbursementRate')?.touched) || (providerForm.get('ReimbursementRate')?.errors && providerFormElement['submitted'])">
                        <span [class]="formClasses.error">{{getErrorMessage('ReimbursementRate')}}</span>
                    </ng-container>
                </div>

                <div *ngIf="displayFields.includes('FeeScheduleId')" [class]="formClasses.inputContainer + ' ' + formClasses.threeCol" >
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="providerIcons.feeSchedule" />
                    </span>
                    <ejs-dropdownlist formControlName="FeeScheduleId"
                        placeholder="Fee Schedule"
                        floatLabelType="Auto"
                        [class]="formClasses.input"
                        [fields]="feeScheduleFields"
                        [dataSource]="feeSchedules"
                        [value]="providerForm.get('FeeScheduleId')?.value" />
                </div>

                <div *ngIf="displayFields.includes('PriorityId')" [class]="formClasses.inputContainer + ' ' + formClasses.threeCol" [class.error]="(providerForm.get('PriorityId')?.errors && providerForm.get('PriorityId')?.touched) || (providerForm.get('PriorityId')?.errors && providerFormElement['submitted'])">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="providerIcons.inNetwork" />
                        <ejs-tooltip *ngIf="(providerForm.get('PriorityId')?.errors && providerForm.get('PriorityId')?.touched) || (providerForm.get('PriorityId')?.errors && providerFormElement['submitted'])" [content]="getErrorMessage('PriorityId')" />
                    </span>
                    <ejs-dropdownlist formControlName="PriorityId"
                        placeholder="In Network *"
                        floatLabelType="Auto"
                        [class]="formClasses.input"
                        [dataSource]="priorityStatusOptions"
                        [fields]="{ text: 'label', value: 'value' }"
                        [value]="providerForm.get('PriorityId')?.value" />
                    <ng-container *ngIf="(providerForm.get('PriorityId')?.invalid && providerForm.get('PriorityId')?.touched) || (providerForm.get('PriorityId')?.errors && providerFormElement['submitted'])">
                        <span [class]="formClasses.error">{{getErrorMessage('PriorityId')}}</span>
                    </ng-container>
                </div>

                <div *ngIf="displayFields.includes('SignedPurchaseAgreement')" [class]="formClasses.inputContainer + ' ' + formClasses.threeCol" [class.error]="providerForm.get('SignedPurchaseAgreement')?.errors && providerForm.get('SignedPurchaseAgreement')?.touched">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="providerIcons.purchaseAgreement" />
                        <ejs-tooltip *ngIf="providerForm.get('SignedPurchaseAgreement')?.errors && providerForm.get('SignedPurchaseAgreement')?.touched"
                            [content]="getErrorMessage('SignedPurchaseAgreement')" />
                    </span>
                    <ejs-dropdownlist formControlName="SignedPurchaseAgreement"
                        placeholder="Purchase Agreement Status"
                        floatLabelType="Auto"
                        [class]="formClasses.input"
                        [dataSource]="signedPurchaseAgreementOptions"
                        [fields]="{ text: 'label', value: 'value' }"
                        [value]="providerForm.get('SignedPurchaseAgreement')?.value" />
                </div>

                <div *ngIf="displayFields.includes('SignedHipaa')" [class]="formClasses.inputContainer + ' ' + formClasses.threeCol" [class.error]="providerForm.get('SignedHipaa')?.errors && providerForm.get('SignedHipaa')?.touched">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="providerIcons.hipaa" />
                        <ejs-tooltip *ngIf="providerForm.get('SignedHipaa')?.errors && providerForm.get('SignedHipaa')?.touched" [content]="getErrorMessage('SignedHipaa')" />
                    </span>
                    <ejs-dropdownlist formControlName="SignedHipaa"
                        placeholder="HIPAA Status"
                        floatLabelType="Auto"
                        [class]="formClasses.input"
                        [dataSource]="yesNoOptions"
                        [fields]="{ text: 'label', value: 'value' }"
                        [value]="providerForm.get('SignedHipaa')?.value" />
                </div>

                <div *ngIf="displayFields.includes('SignedHipaaDate')" [class]="formClasses.inputContainer + ' ' + formClasses.threeCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="providerIcons.hipaaDate" />
                    </span>
                    <ejs-datepicker formControlName="SignedHipaaDate"
                        placeholder="HIPAA Signed Date"
                        floatLabelType="Auto"
                        format="yyyy-MM-dd"
                        [class]="formClasses.input"
                        [value]="providerForm.get('SignedHipaaDate')?.value" />
                </div>

                <div *ngIf="displayFields.includes('SignedW9')" [class]="formClasses.inputContainer + ' ' + formClasses.threeCol" [class.error]="providerForm.get('SignedW9')?.errors && providerForm.get('SignedW9')?.touched">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="providerIcons.w9" />
                        <ejs-tooltip *ngIf="providerForm.get('SignedW9')?.errors && providerForm.get('SignedW9')?.touched" [content]="getErrorMessage('SignedW9')" />
                    </span>
                    <ejs-dropdownlist formControlName="SignedW9"
                        placeholder="W9 Status"
                        floatLabelType="Auto"
                        [class]="formClasses.input"
                        [dataSource]="yesNoOptions"
                        [fields]="{ text: 'label', value: 'value' }"
                        [value]="providerForm.get('SignedW9')?.value" />
                </div>

                <div *ngIf="displayFields.includes('SignedW9Date')" [class]="formClasses.inputContainer + ' ' + formClasses.threeCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="providerIcons.w9Date" />
                    </span>
                    <ejs-datepicker formControlName="SignedW9Date"
                        placeholder="W9 Signed Date"
                        floatLabelType="Auto"
                        [class]="formClasses.input"
                        format="yyyy-MM-dd"
                        [value]="providerForm.get('SignedW9Date')?.value" />
                </div>

                <div *ngIf="displayFields.includes('W9Name')" [class]="formClasses.inputContainer + ' ' + formClasses.threeCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="providerIcons.w9Name" />
                    </span>
                    <ejs-textbox formControlName="W9Name"
                        placeholder="W9 Name"
                        floatLabelType="Auto"
                        [class]="formClasses.input"
                        [value]="providerForm.get('W9Name')?.value" />
                </div>

                <div *ngIf="displayFields.includes('ReferringProvider')" [class]="formClasses.inputContainer + ' ' + formClasses.threeCol" [class.error]="(providerForm.get('ReferringProvider')?.errors && providerForm.get('ReferringProvider')?.touched) || (providerForm.get('ReferringProvider')?.errors && providerFormElement['submitted'])">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="providerIcons.referringProvider" />
                        <ejs-tooltip *ngIf="(providerForm.get('ReferringProvider')?.errors && providerForm.get('ReferringProvider')?.touched) || (providerForm.get('ReferringProvider')?.errors && providerFormElement['submitted'])" [content]="getErrorMessage('ReferringProvider')" />
                    </span>
                    <ejs-dropdownlist formControlName="ReferringProvider"
                        placeholder="Referring Provider *"
                        floatLabelType="Auto"
                        [class]="formClasses.input"
                        [dataSource]="yesNoOptions"
                        [fields]="{ text: 'label', value: 'value' }"
                        [value]="providerForm.get('ReferringProvider')?.value" />
                    <ng-container *ngIf="(providerForm.get('ReferringProvider')?.invalid && providerForm.get('ReferringProvider')?.touched) || (providerForm.get('ReferringProvider')?.errors && providerFormElement['submitted'])">
                        <span [class]="formClasses.error">{{getErrorMessage('ReferringProvider')}}</span>
                    </ng-container>
                </div>

                <div *ngIf="displayFields.includes('VirtualProvider')" [class]="formClasses.inputContainer + ' ' + formClasses.threeCol" [class.error]="providerForm.get('VirtualProvider')?.errors && providerForm.get('VirtualProvider')?.touched">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="providerIcons.virtualProvider" />
                        <ejs-tooltip *ngIf="providerForm.get('VirtualProvider')?.errors && providerForm.get('VirtualProvider')?.touched"
                            [content]="getErrorMessage('VirtualProvider')" />
                    </span>
                    <ejs-dropdownlist formControlName="VirtualProvider"
                        placeholder="Virtual Provider"
                        floatLabelType="Auto"
                        [class]="formClasses.input"
                        [dataSource]="yesNoOptions"
                        [fields]="{ text: 'label', value: 'value' }"
                        [value]="providerForm.get('VirtualProvider')?.value" />
                </div>

                <div *ngIf="displayFields.includes('WebsiteUrl')" [class]="formClasses.inputContainer + ' ' + formClasses.threeCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="providerIcons.website" />
                    </span>
                    <ejs-textbox formControlName="WebsiteUrl"
                        placeholder="Website URL"
                        floatLabelType="Auto"
                        [class]="formClasses.input"
                        [value]="providerForm.get('WebsiteUrl')?.value" />
                </div>

                <div *ngIf="displayFields.includes('NotesImportant')" [class]="formClasses.inputContainer + ' ' + formClasses.threeCol" >
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="providerIcons.notesImportant" />
                    </span>
                    <ejs-checkbox formControlName="NotesImportant"
                        label="Notes Important"
                        labelPosition="Before"
                        [value]="providerForm.get('NotesImportant')?.value" />
                </div>

                <div *ngIf="displayFields.includes('SpanishSpeaking')" [class]="formClasses.inputContainer + ' ' + formClasses.threeCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="providerIcons.spanishSpeaking" />
                    </span>
                    <ejs-checkbox formControlName="SpanishSpeaking"
                        label="Spanish Speaking"
                        labelPosition="Before"
                        [value]="providerForm.get('SpanishSpeaking')?.value" />
                </div>

                <div *ngIf="displayFields.includes('CheckpointEligible')" [class]="formClasses.inputContainer + ' ' + formClasses.threeCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="providerIcons.checkpointEligible" />
                    </span>
                    <ejs-checkbox formControlName="CheckpointEligible"
                        label="Checkpoint Eligible"
                        labelPosition="Before"
                        [value]="providerForm.get('CheckpointEligible')?.value" />
                </div>

                <div *ngIf="displayFields.includes('IsActive')" [class]="formClasses.inputContainer + ' ' + formClasses.threeCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="providerIcons.active" />
                    </span>
                    <ejs-checkbox formControlName="IsActive"
                        label="Active"
                        labelPosition="Before"
                        [value]="providerForm.get('IsActive')?.value" />
                </div>

                <div *ngIf="displayFields.includes('HoldsOwnAr')" [class]="formClasses.inputContainer + ' ' + formClasses.threeCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="providerIcons.holdsOwnAr" />
                    </span>
                    <ejs-checkbox formControlName="HoldsOwnAr"
                        label="Holds Own AR"
                        labelPosition="Before"
                        [value]="providerForm.get('HoldsOwnAr')?.value" />
                </div>

            </div>
        </section>

        <section [class]="formClasses.section + ' ' + formClasses.subForm + ' contact-info-section'">
            <h2 [class]="formClasses.subFormTitle">
                <fa-icon [icon]="providerIcons.contactInfo" />
                <span>{{contactInfoTitle ?? 'Contact Info'}}</span>
            </h2>
            <div class="row">
                <div [class]="formClasses.twoCol">
                    <address-form #addressFormComp
                        [address]="providerAddress"
                        [displayFields]="['Address1', 'Address2', 'City', 'State', 'Zip', 'County', 'AddressType']"
                        [requiredFields]="['Address1', 'City', 'State', 'Zip', 'AddressType']"
                        [displayButtons]="false"
                        [displayTitle]="false" />
                </div>
                <div [class]="formClasses.twoCol">
                    <phone-form #phoneFormComp
                        [phone]="providerPhone"
                        [displayFields]="['PhoneNumber', 'PhoneType']"
                        [requiredFields]="['PhoneNumber', 'PhoneType']"
                        [displayButtons]="false" />
                </div>
            </div>
        </section>

        <section [class]="formClasses.section + ' col-12'" *ngIf="displayButtons">
            <div [class]="formClasses.actions">
                <button ejs-button [isPrimary]="true" type="submit">Submit</button>
                <button ejs-button type="button" (click)="formCancelled.emit()">Cancel</button>
            </div>
        </section>

    </form>

</div>
<!-- Provider Form End -->
