import { Injectable } from '@angular/core';
import { Query, DataManager } from '@syncfusion/ej2-data';
import { BaseGridService } from '@shared/components/base-grid/services/state.service';
import { SetGridDataArgs } from '@shared/components/base-grid/models/grid.models';
import { APIEndpoints } from '@models/api/Endpoints';
import { LawFirm } from '@models/data-contracts';

interface ApiResponse {
  result: any[];
}

@Injectable({
  providedIn: 'root',
})
export class LawFirmsGridService extends BaseGridService {
  private _marketManagers: any[] = [];
  private _caseManagers: any[] = [];
  private userCache: { users: any[]; marketManagers: any[]; caseManagers: any[] } | null = null;
  private readonly CACHE_DURATION = 5 * 60 * 1000; // 5 minutes
  private lastUserFetch: number = 0;

  constructor() {
    super();
  }

  async loadGridData(showActive: boolean = true): Promise<any> {
    this.signals.setShowActive(showActive);

    const query = new Query()
      .expand([
        'XrefAddressLawfirms($expand=Address($expand=StateNavigation($select=Id,Name);$select=Id,AddressType,Address1,Address2,City,State,Zip,County,StateNavigation))',
      ])
      .select([
        'Id',
        'Abbreviation',
        'Name',
        'XrefAddressLawfirms',
        'MarketManager',
        'CaseManager',
        'Website',
        'WebsiteUrl',
        'Rating',
        'IsActive',
      ])
      .where('IsActive', 'equal', showActive)
      .sortBy('Id', 'desc')
      .sortBy('UpdatedAt');

    const settings: SetGridDataArgs = {
      endpoint: APIEndpoints.Lawfirms,
      name: 'Law Firms',
      useRoundedEdges: true,
      query,
      transformData: (data: any[]) => {
        return data.map((lawFirm: LawFirm) => {
          const address =
            lawFirm.XrefAddressLawfirms?.find((x: any) => x.Address?.AddressType === 2)?.Address ||
            lawFirm.XrefAddressLawfirms?.[0]?.Address;
          return {
            ...lawFirm,
            TotalFiles: lawFirm.CaseFiles?.length ?? 0,
            XrefAddressLawfirms: { Address: address },
          };
        });
      },
      gridSettings: {
        columns: [
          { type: 'checkbox', allowFiltering: false },
          { field: 'Id' },
          { field: 'Abbreviation', headerText: 'Abbreviation' },
          {
            field: 'Name',
            headerText: 'Name',
            template: '<a class="link" href="/law-firm-detail/${Id}"><span>${Name}</span></a>',
          },
          {
            field: 'XrefAddressLawfirms.Address.Address1',
            headerText: 'Address',
            allowSorting: false,
            allowFiltering: false,
          },
          { field: 'XrefAddressLawfirms.Address.City', headerText: 'City', allowSorting: false, allowFiltering: false },
          {
            field: 'XrefAddressLawfirms.Address.StateNavigation.Name',
            headerText: 'State',
            allowSorting: false,
            allowFiltering: false,
          },
          { field: 'XrefAddressLawfirms.Address.Zip', headerText: 'Zip', allowSorting: false, allowFiltering: false },
          {
            field: 'MarketManager',
            headerText: 'Market Manager',
            foreignKeyValue: 'Name',
            foreignKeyField: 'Id',
            dataSource: new DataManager(this._marketManagers),
          },
          {
            field: 'CaseManager',
            headerText: 'Case Manager',
            foreignKeyValue: 'Name',
            foreignKeyField: 'Id',
            dataSource: new DataManager(this._caseManagers),
          },
          { field: 'IsActive', headerText: 'Is Active' },
          {
            type: 'commands',
            headerText: 'Actions',
            commands: [
              { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
              {
                type: 'Delete',
                title: 'Toggle Active',
                buttonOption: { cssClass: 'e-flat', iconCss: 'e-icons e-circle-check' },
              },
              { type: 'None', title: 'Logs', buttonOption: { iconCss: 'e-icons e-description', cssClass: 'e-flat' } },
            ],
          },
        ],
      },
    };

    return this.setGridData(settings);
  }

  async loadUsers(): Promise<void> {
    try {
      // Check cache first
      const now = Date.now();
      if (this.userCache && now - this.lastUserFetch < this.CACHE_DURATION) {
        this._marketManagers = this.userCache.marketManagers;
        this._caseManagers = this.userCache.caseManagers;
        return;
      }

      this.signals.setLoading(true);
      const usersQuery = new Query()
        .expand(['XrefUsersRoles($select=RoleId)'])
        .select(['Id', 'Name', 'XrefUsersRoles'])
        .where('XrefUsersRoles/any(x: x/RoleId eq 4 or x/RoleId eq 2 or x/RoleId eq 3)', 'equal', true);

      const response = (await this.api.getOdata(APIEndpoints.Users).executeQuery(usersQuery)) as unknown as ApiResponse;
      const users = response.result;

      this._marketManagers = users.filter((user: any) =>
        user.XrefUsersRoles?.some((xRef: any) => xRef.RoleId === 4 || xRef.RoleId === 2)
      );
      this._caseManagers = users.filter((user: any) =>
        user.XrefUsersRoles?.some((xRef: any) => xRef.RoleId === 3 || xRef.RoleId === 2)
      );

      // Update cache
      this.userCache = { users, marketManagers: this._marketManagers, caseManagers: this._caseManagers };
      this.lastUserFetch = now;
    } catch (error) {
      this.setError('loadUsers', error);
      throw error;
    } finally {
      this.signals.setLoading(false);
    }
  }

  async getMarketManagers(): Promise<any[]> {
    if (!this.userCache || Date.now() - this.lastUserFetch >= this.CACHE_DURATION) {
      await this.loadUsers();
    }
    return this._marketManagers;
  }

  async getCaseManagers(): Promise<any[]> {
    if (!this.userCache || Date.now() - this.lastUserFetch >= this.CACHE_DURATION) {
      await this.loadUsers();
    }
    return this._caseManagers;
  }
}
