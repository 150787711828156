import { Injectable } from '@angular/core';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { ErrorHandlingService } from './error-handling.service';
import { LoggingService } from './logging.service';

/**
 * User-facing notification service.
 *
 * Primary interface for components to show messages and handle errors.
 * Delegates error handling to ErrorHandlingService.
 *
 * Usage:
 * - Success/info/warning messages shown directly
 * - Errors passed to error handling system
 *
 * @example
 * // Success message
 * this.notification.success('Data saved successfully');
 *
 * // Error handling
 * try {
 *   await this.saveData();
 *   this.notification.success('Saved successfully');
 * } catch (error) {
 *   this.notification.error(error, 'MyComponent.saveData');
 * }
 */
@Injectable({ providedIn: 'root' })
export class NotificationService {
  constructor(
    private toast: ToastMessageService,
    private logging: LoggingService,
    private errorHandling: ErrorHandlingService
  ) { }

  success(message: string, context?: string, technicalDetails?: any): void {
    // Show user notification
    this.toast.showSuccess(message);

    // Log the success with technical details if provided
    this.logging.log({
      message,
      level: 'info',
      context,
      ...(technicalDetails && { technicalDetails })
    });
  }

  error(error: any, context?: string): void {
    this.errorHandling.handleError(error, context);
  }

  warn(message: string): void {
    this.toast.showWarning(message);
  }

  info(message: string): void {
    this.toast.showInfo(message);
  }
}
