<p>test2 works!</p>
<button ejs-button cssClass="e-flat header-btn" (click)="requestDateLock()">
    <h3>Request Date Lock</h3>
</button>
<button ejs-button cssClass="e-flat header-btn" (click)="checkDateLock()">
    <h3>Check if Date Locked</h3>
</button>

<button ejs-button cssClass="e-flat header-btn" (click)="requestDateLock2()">
    <h3>Request Date Lock 2</h3>
</button>
<button ejs-button cssClass="e-flat header-btn" (click)="checkDateLock2()">
    <h3>Check if Date Locked 2</h3>
</button>

