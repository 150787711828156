// Angular
import { Component, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { CommandClickEventArgs } from '@syncfusion/ej2-grids';

// 3rd Party
import { Query } from '@syncfusion/ej2-data';

// Internal
import { ApiService } from '@services/api/api.service';
import { BaseGridComponent } from '@shared/components/base-grid/base-grid.component';
import { BaseGridService } from '@shared/components/base-grid/services/state.service';
import { BaseGridHooks } from '@shared/components/base-grid/services/hooks.service';
import { SetGridDataArgs } from '@shared/components/base-grid/models/grid.models';
import { BaseGrid } from '@core/base/grid.base';
import { Invoice } from '@models/data-contracts';

// Add enum for commands
enum GridCommand {
  Edit = 'Edit',
  Logs = 'Logs'
}

@Component({
  selector: 'casefile-invoices-grid',
  standalone: true,
  imports: [
    CommonModule,
    BaseGridComponent
  ],
  providers: [
    BaseGridHooks,
    BaseGridService
  ],
  templateUrl: './casefile-invoices-grid.component.html'
})
export class CasefileInvoicesGridComponent extends BaseGrid {
  @Input() set fileNumber(value: number | undefined) {
    if (value) {
      this.invoicesGridSettings.query = new Query()
        .expand('InvoiceRows,Provider,InvoicePayments($expand=PaymentMethodNavigation,PaymentStatusNavigation)')
        .where('CaseFileId', 'equal', value);
    }
  }

  // ViewChild references
  @ViewChild('invoicesGrid') invoicesGrid!: BaseGridComponent;

  // Add output event emitter
  @Output() addInvoice = new EventEmitter<void>();
  @Output() editInvoice = new EventEmitter<Invoice>();
  @Output() viewLogs = new EventEmitter<Invoice>();

  private amountBilledCustomAggregate(data: any): number {
    if (!data) return 0;

    // For group totals
    if (Array.isArray(data)) {
      return data.reduce((total: number, group: any) => {
        return total + group.items.reduce((groupTotal: number, item: any) => {
          const firstRow = item.InvoiceRows && item.InvoiceRows.length > 0 ? item.InvoiceRows[0] : null;
          return groupTotal + (firstRow?.AmountBilled || 0);
        }, 0);
      }, 0);
    }

    // For individual group total
    if (data.items) {
      return data.items.reduce((sum: number, item: any) => {
        const firstRow = item.InvoiceRows && item.InvoiceRows.length > 0 ? item.InvoiceRows[0] : null;
        return sum + (firstRow?.AmountBilled || 0);
      }, 0);
    }

    return 0;
  }

  private totalDueProviderCustomAggregate(data: any): number {
    if (!data) return 0;

    // For group totals
    if (Array.isArray(data)) {
      return data.reduce((total: number, group: any) => {
        return total + group.items.reduce((groupTotal: number, item: any) => {
          return groupTotal + (item.InvoiceRows?.reduce((sum: number, row: any) =>
            sum + (row.TotalDueProvider || 0), 0) || 0);
        }, 0);
      }, 0);
    }

    // For individual group total
    if (data.items) {
      return data.items.reduce((sum: number, item: any) => {
        return sum + (item.InvoiceRows?.reduce((rowSum: number, row: any) =>
          rowSum + (row.TotalDueProvider || 0), 0) || 0);
      }, 0);
    }

    return 0;
  }

  private settlementValueCustomAggregate(data: any): number {
    if (!data) return 0;

    // For group totals
    if (Array.isArray(data)) {
      return data.reduce((total: number, group: any) => {
        return total + group.items.reduce((groupTotal: number, item: any) => {
          return groupTotal + (item.InvoiceRows?.reduce((sum: number, row: any) =>
            sum + (row.SettlementValue || 0), 0) || 0);
        }, 0);
      }, 0);
    }

    // For individual group total
    if (data.items) {
      return data.items.reduce((sum: number, item: any) => {
        return sum + (item.InvoiceRows?.reduce((rowSum: number, row: any) =>
          rowSum + (row.SettlementValue || 0), 0) || 0);
      }, 0);
    }

    return 0;
  }

  private amountPaidCustomAggregate(data: any): number {
    if (!data) return 0;

    // For group totals
    if (Array.isArray(data)) {
      return data.reduce((total: number, group: any) => {
        return total + group.items.reduce((groupTotal: number, item: any) => {
          return groupTotal + (item.InvoicePayments?.reduce((sum: number, payment: any) =>
            sum + (payment.AmountPaid || 0), 0) || 0);
        }, 0);
      }, 0);
    }

    // For individual group total
    if (data.items) {
      return data.items.reduce((sum: number, item: any) => {
        return sum + (item.InvoicePayments?.reduce((paymentSum: number, payment: any) =>
          paymentSum + (payment.AmountPaid || 0), 0) || 0);
      }, 0);
    }

    return 0;
  }

  // Grid settings
  readonly invoicesGridSettings: SetGridDataArgs = {
    endpoint: '/Invoices',
    name: 'Invoices',
    useRoundedEdges: true,
    query: new Query().expand('InvoiceRows,Provider,InvoicePayments($expand=PaymentMethodNavigation,PaymentStatusNavigation)'),
    gridSettings: {
      toolbar: [{ text: 'Add', tooltipText: 'Add Invoice', prefixIcon: 'e-add' }],
      toolbarClick: (args: any) => {
        if (args.item.text === 'Add') {
          this.addInvoice.emit();
        }
      },
      commandClick: (args: CommandClickEventArgs) => this.commandClick(args),
      columns: [
        {
          field: 'Provider.Name',
          headerText: 'Provider',
          allowGrouping: true,
          allowSorting: false,
          allowFiltering: false
        },
        {
          field: 'ProviderInvoiceNumber',
          headerText: 'Provider Invoice #',
          allowSorting: false,
          allowFiltering: true
        },
        {
          field: 'InternalInvoiceNumber',
          headerText: 'Internal Invoice #',
          allowSorting: false,
          allowFiltering: true
        },
        {
          field: 'InvoiceRows',
          headerText: 'Date of Service',
          type: 'date',
          format: 'MM/dd/yyyy',
          valueAccessor: (field: string, data: any): Object => {
            const firstRow = data.InvoiceRows && data.InvoiceRows.length > 0 ? data.InvoiceRows[0] : null;
            return firstRow ? new Date(firstRow.DateOfService) : new Date();
          },
          allowSorting: false,
          allowFiltering: false
        },
        {
          field: 'InvoiceRows',
          headerText: 'Amount Billed ($)',
          type: 'number',
          format: 'C2',
          valueAccessor: (field: string, data: any): number => {
            const firstRow = data.InvoiceRows && data.InvoiceRows.length > 0 ? data.InvoiceRows[0] : null;
            return firstRow?.AmountBilled || 0;
          },
          allowSorting: false,
          allowFiltering: false
        },
        {
          field: 'TotalDueProvider',
          headerText: 'Total Due ($)',
          type: 'number',
          format: 'C2',
          allowSorting: false,
          allowFiltering: false,
          valueAccessor: (field: string, data: any) => {
            return data.InvoiceRows?.reduce((sum: number, row: any) => sum + (row.TotalDueProvider || 0), 0) || 0;
          }
        },
        {
          field: 'SettlementValue',
          headerText: 'Settlement Value ($)',
          type: 'number',
          format: 'C2',
          allowSorting: false,
          allowFiltering: false,
          valueAccessor: (field: string, data: any) => {
            return data.InvoiceRows?.reduce((sum: number, row: any) => sum + (row.SettlementValue || 0), 0) || 0;
          }
        },
        {
          field: 'AmountPaid',
          headerText: 'Amount Paid ($)',
          type: 'number',
          format: 'C2',
          allowSorting: false,
          allowFiltering: false,
          width: 120,
          valueAccessor: (field: string, data: any) => {
            return data.InvoicePayments?.reduce((sum: number, payment: any) =>
              sum + (payment.AmountPaid || 0), 0) || 0;
          }
        },
        {
          field: 'InvoicePayments',
          headerText: 'Date Paid',
          type: 'date',
          format: 'MM/dd/yyyy',
          valueAccessor: (field: string, data: any) => {
            const payment = data.InvoicePayments?.[0];
            return payment ? payment.DatePaid : null;
          },
          allowSorting: false,
          allowFiltering: false
        },
        {
          field: 'InvoicePayments',
          headerText: 'Payment Method',
          valueAccessor: (field: string, data: any) => {
            const payment = data.InvoicePayments?.[0];
            return payment?.PaymentMethodNavigation?.Description || null;
          },
          allowSorting: false,
          allowFiltering: false
        },
        {
          field: 'InvoicePayments',
          headerText: 'Funding Account',
          valueAccessor: (field: string, data: any) => {
            const payment = data.InvoicePayments?.[0];
            return payment?.PaymentStatusNavigation?.Description || null;
          },
          allowSorting: false,
          allowFiltering: false
        },
        {
          field: 'InvoicePayments',
          headerText: 'Check Number',
          valueAccessor: (field: string, data: any) => {
            const payment = data.InvoicePayments?.[0];
            return payment?.TransactionNumber || null;
          },
          allowSorting: false,
          allowFiltering: false
        },
        {
          headerText: 'Actions',
          width: 130,
          commands: [
            {
              type: 'Edit',
              title: 'Edit',
              buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' }
            },
            {
              type: 'None',
              title: 'Logs',
              buttonOption: { cssClass: 'e-flat', iconCss: 'e-description e-icons' }
            }
          ]
        }
      ],
      allowGrouping: true,
      groupSettings: {
        columns: ['Provider.Name'],
        showGroupedColumn: false,
        showDropArea: false,
        showToggleButton: false,
        showUngroupButton: false,
        captionTemplate: '${key}'
      },
      aggregates: [
        {
          columns: [
            {
              field: 'Provider.Name',
              type: 'Custom',
              customAggregate: (data: any) => {
                return 'Total:';
              },
              groupFooterTemplate: '${Custom}',
              footerTemplate: '${Custom}'
            },
            {
              field: 'InvoiceRows',
              type: 'Custom',
              customAggregate: this.amountBilledCustomAggregate.bind(this),
              groupFooterTemplate: '$${Custom}',
              footerTemplate: '$${Custom}'
            },
            {
              field: 'TotalDueProvider',
              type: 'Custom',
              customAggregate: this.totalDueProviderCustomAggregate.bind(this),
              groupFooterTemplate: '$${Custom}',
              footerTemplate: '$${Custom}'
            },
            {
              field: 'SettlementValue',
              type: 'Custom',
              customAggregate: this.settlementValueCustomAggregate.bind(this),
              groupFooterTemplate: '$${Custom}',
              footerTemplate: '$${Custom}'
            },
            {
              field: 'AmountPaid',
              type: 'Custom',
              customAggregate: this.amountPaidCustomAggregate.bind(this),
              groupFooterTemplate: '$${Custom}',
              footerTemplate: '$${Custom}'
            }
          ]
        }
      ]
    }
  };

  constructor(
    protected override api: ApiService,
    protected override router: Router,
    private gridService: BaseGridService
  ) {
    super();
  }

  // Add command click handler
  commandClick(args: CommandClickEventArgs): void {
    const invoice = args.rowData as Invoice;
    if (!invoice?.Id) return;

    args.cancel = true; // Prevent default behavior

    switch (args.commandColumn?.title) {
      case GridCommand.Edit:
        this.editInvoice.emit(invoice);
        break;
      case GridCommand.Logs:
        this.viewLogs.emit(invoice);
        break;
    }
  }
}
