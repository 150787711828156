// Angular
import {
    Component,
    Inject,
    Input,
    signal,
    SimpleChanges,
    Output,
    EventEmitter,
    OnInit,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FormGroup, FormControl } from '@angular/forms';

// 3rd Party
import {
    NumericTextBoxModule,
    TextBoxModule,
} from '@syncfusion/ej2-angular-inputs';

// Internal
import {
    BalanceDue,
    FinancialData,
    FinancialService,
} from '@services/financial/financial.service';
import { LoadingModule } from '@modules/loading.module';
import { FinancialPerformanceService } from '@features/financial/services/financial-performance.service';
import { PerformanceData } from '@features/financial/models/financial-performance.model';
import { ComponentBase } from '@core/base/component.base';

interface FormData {
    roic: number;
    reductionAmount: number;
    reductionPct: number;
}

@Component({
    selector: 'financial-info',
    standalone: true,
    imports: [
        TextBoxModule,
        LoadingModule,
        NumericTextBoxModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
    ],
    templateUrl: './financial-info.component.html',
    styleUrl: './financial-info.component.scss',
})
export class FinancialInfoComponent extends ComponentBase implements OnInit {
    constructor(
        @Inject(FinancialService) private financial: FinancialService,
        private fPerformance: FinancialPerformanceService
    ) {
        super();
    }

    @Input() caseFileId?: number;
    @Input() balanceDue?: BalanceDue;
    @Input() isReductionPage?: boolean;
    @Input() parentSelectedOption?: string;

    protected readonly loading = signal(false);
    protected readonly financialData = signal<FinancialData | undefined>(undefined);
    protected readonly performanceData = signal<PerformanceData | undefined>(undefined);

    protected readonly form = new FormGroup({
        roic: new FormControl(0, { nonNullable: true }),
        reductionAmount: new FormControl(0, { nonNullable: true }),
        reductionPct: new FormControl(0, { nonNullable: true })
    });

    @Output() reductionChange = new EventEmitter<{ amount: number | null, percentage: number | null }>();

    protected get formData(): FormData {
        return this.form.value as FormData;
    }

    protected updateFormValue(value: Partial<FormData>) {
        this.form.patchValue(value, { emitEvent: false });
    }

    protected get isFormValid(): boolean {
        return this.formData !== null &&
            typeof this.formData.roic === 'number' &&
            typeof this.formData.reductionAmount === 'number' &&
            typeof this.formData.reductionPct === 'number';
    }

    ngOnInit(): void {
        if (!this.isReductionPage) {
            this.initializeFinancialData().catch(error => this.handleError(error, {
                context: 'FinancialInfoComponent.ngOnInit',
                userMessage: 'Failed to initialize financial data'
            }));
        }
    }

    private async initializeFinancialData(): Promise<void> {
        this.loading.set(true);
        try {
            if (!this.caseFileId) {
                throw new this.AppError(
                    'Unable to load financial data',
                    {
                        message: 'Case file ID is required',
                        state: {
                            caseFileId: this.caseFileId,
                            formData: this.formData
                        }
                    },
                    'FinancialInfoComponent.initializeFinancialData'
                );
            }
            const data = await this.fPerformance.getPerformanceData(this.caseFileId);
            this.performanceData.set(data);
        } finally {
            this.loading.set(false);
        }
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes['caseFileId'] && this.isReductionPage) {
            try {
                this.loading.set(true);
                if (!this.caseFileId) {
                    throw new this.AppError(
                        'Unable to update financial data',
                        { message: 'Case file ID is required' },
                        'FinancialInfoComponent.ngOnChanges'
                    );
                }
                const response = await this.financial.finReductionPercent(
                    this.formData.reductionPct ?? 0,
                    this.caseFileId
                );
                this.financialData.set(response);
                this.updateFormValue({
                    roic: response?.roic ?? 0
                });
            } catch (error) {
                this.handleError(error, {
                    context: 'FinancialInfoComponent.ngOnChanges',
                    userMessage: 'Failed to update reduction percentage'
                });
            } finally {
                this.loading.set(false);
            }
        }
    }

    async onReductionAmountChange(): Promise<void> {
        try {
            this.loading.set(true);
            if (!this.caseFileId) {
                throw new Error('Case file ID is required');
            }

            const currentFormData = this.formData;
            if (this.balanceDue?.amountBilled) {
                currentFormData.reductionPct =
                    (currentFormData.reductionAmount / this.balanceDue.amountBilled) * 100;
            }

            const response = await this.financial.finReductionAmount(
                currentFormData.reductionAmount,
                this.caseFileId
            );

            this.financialData.set(response);
            this.updateFormValue({
                ...currentFormData,
                roic: response?.roic ?? response?.roi ?? currentFormData.roic
            });

            this.reductionChange.emit({
                amount: currentFormData.reductionAmount,
                percentage: currentFormData.reductionPct
            });
        } catch (error) {
            this.handleError(error, {
                context: 'FinancialInfoComponent.onReductionAmountChange',
                userMessage: 'Failed to update reduction amount'
            });
        } finally {
            this.loading.set(false);
        }
    }

    async onReductionPercentChange(): Promise<void> {
        try {
            this.loading.set(true);
            if (!this.caseFileId) {
                throw new Error('Case file ID is required');
            }

            const currentFormData = this.formData;
            if (this.balanceDue?.amountBilled) {
                currentFormData.reductionAmount =
                    (currentFormData.reductionPct / 100) * this.balanceDue.amountBilled;
            }

            const response = await this.financial.finReductionPercent(
                currentFormData.reductionPct,
                this.caseFileId
            );

            this.financialData.set(response);
            this.updateFormValue({
                ...currentFormData,
                roic: response?.roic ?? currentFormData.roic,
                reductionAmount: response?.courtesyReduction ?? currentFormData.reductionAmount,
                reductionPct: response?.courtesyReductionPct ?? currentFormData.reductionPct
            });

            this.reductionChange.emit({
                amount: currentFormData.reductionAmount,
                percentage: currentFormData.reductionPct
            });
        } catch (error) {
            this.handleError(error, {
                context: 'FinancialInfoComponent.onReductionPercentChange',
                userMessage: 'Failed to update reduction percentage'
            });
        } finally {
            this.loading.set(false);
        }
    }

    async onRoicChange(): Promise<void> {
        try {
            this.loading.set(true);
            if (!this.caseFileId) {
                throw new Error('Case file ID is required');
            }

            const currentFormData = this.formData;
            const response = await this.financial.finRoicValue(
                currentFormData.roic,
                this.caseFileId
            );

            this.financialData.set(response);
            this.updateFormValue({
                ...currentFormData,
                roic: response?.roic ?? currentFormData.roic,
                reductionAmount: response?.courtesyReduction ?? currentFormData.reductionAmount,
                reductionPct: response?.courtesyReductionPct ?? currentFormData.reductionPct
            });
        } catch (error) {
            this.handleError(error, {
                context: 'FinancialInfoComponent.onRoicChange',
                userMessage: 'Failed to update ROIC value'
            });
        } finally {
            this.loading.set(false);
        }
    }
}
