import { Injectable } from '@angular/core';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { ErrorHandlingService } from '@core/error/error.service';
import { LoggingService } from '@core/internal/logging/logging.service';
import { NotificationSeverity } from '@core/enums/notification-severity.enum';

export { NotificationSeverity };

/**
 * User-facing notification service for displaying messages to users.
 *
 * When to use notifications:
 * ✓ Success messages: After successful operations
 *   - Saving data: "Changes saved successfully"
 *   - Completing actions: "Email sent successfully"
 *
 * ✓ Info messages: System state updates
 *   - Status changes: "Processing your request..."
 *   - Updates available: "New version available"
 *
 * ✓ Warning messages: Non-critical issues
 *   - Validation: "Some optional fields are missing"
 *   - Performance: "Large file size may slow upload"
 *
 * Do NOT use for:
 * ❌ API errors (use ErrorHandlingService)
 * ❌ System crashes (use ErrorHandlingService)
 * ❌ Network issues (use ErrorHandlingService)
 *
 * Implementation:
 * - Uses ToastMessageService for UI display
 * - Logs important notifications via LoggingService
 * - Delegates errors to ErrorHandlingService
 *
 * @example
 * // Success after operation
 * await this.saveData();
 * this.notification.success('Changes saved');
 *
 * // Info for status update
 * this.notification.info('Generating report...');
 *
 * // Warning for validation
 * this.notification.warn('Please review highlighted fields');
 */

@Injectable({ providedIn: 'root' })
export class NotificationService {
  constructor(
    private toast: ToastMessageService,
    private logging: LoggingService,
    private errorHandling: ErrorHandlingService
  ) {}

  success(message: string, context?: string, technicalDetails?: any): void {
    // Show user notification
    this.toast.showSuccess(message);

    // Log the success with technical details if provided
    this.logging.log({
      message,
      level: NotificationSeverity.Info,
      context,
      ...(technicalDetails && { technicalDetails }),
    });
  }

  error(error: any, context: string = 'UnknownContext'): void {
    if (typeof error === 'string') {
      this.toast.showError(error);
    } else {
      this.errorHandling.handleError(error, {
        context,
        userMessage: error.message || 'An unexpected error occurred',
      });
    }
  }

  warn(message: string): void {
    this.toast.showWarning(message);
  }

  info(message: string): void {
    this.toast.showInfo(message);
  }

  debug(message: string): void {
    this.logging.log({
      message,
      level: NotificationSeverity.Debug,
    });
  }
}
