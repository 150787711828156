
// Angular
import { 
  Component,
  Input, 
  ViewChild, 
  ViewEncapsulation 
} from '@angular/core';

// 3rd Party
import {
  GridAllModule, 
  ColumnChooserService, 
  CommandColumnService, 
  GridModel, 
  ToolbarService, 
  GridComponent, 
  ExcelExportService, 
  PdfExportService,
  PageService,
  SortService,
  FilterService,
  EditService,
  SearchService,
  GroupService,
  AggregateService
} from '@syncfusion/ej2-angular-grids';
import { CommonModule } from '@angular/common';

// Core
import { Base } from '@core/base/base';

// Models
import { defaults } from './models/basic-grid.model';

@Component({
  selector: 'basic-grid',
  templateUrl: './basic-grid.component.html',
  styleUrls: ['./basic-grid.component.scss'],
  standalone: true,
  imports: [CommonModule, GridAllModule],
  providers: [
    ColumnChooserService,
    ToolbarService,
    CommandColumnService,
    ExcelExportService,
    PdfExportService
  ],
  encapsulation: ViewEncapsulation.None,
})
export  class BasicGridComponent extends Base {

  @Input() name: string;
  @Input() gridSettings: GridModel; 
  @Input() useRoundedEdges: boolean = true;
  @ViewChild('grid') grid: GridComponent;

  // Make public variables for HTML template
  defaults = defaults;
  window = window;

  constructor() {
    super();
  }

  get toolbar() {
    if (this.gridSettings.toolbar?.length === 0) return undefined
    const toolbar = this.gridSettings.toolbar ?? this.defaults.toolbar;
    return this.name ? [{ template: `<span class="h2">${this.name}</span>` }, ...toolbar] : toolbar;
  }

  toolbarClick(args: any) {

    try {
      // Call the toolbarClick event if it exists
      if (this.gridSettings.toolbarClick) this.gridSettings.toolbarClick(args);

      // Scoped variables
      const gridData = this.grid.currentViewData;
      const selectedRows = this.grid.getSelectedRows();
      const dataToExport = selectedRows.length > 0 ? selectedRows : gridData;
      const fileName = this.name 
        ? `${this.name}_${this.getFormattedDate()}` 
        : `cc_grid_export_${this.getFormattedDate()}`;

      if (args.item.id.includes('copy')) {
        // Check if grid data is available
        if (gridData.length <= 0) {
          this.notify('No grid data.', this.NotificationSeverity.Warning);
          return;
        }

        // Select all rows if none
        if (selectedRows.length === 0) this.grid.selectRows(Array.from({length: gridData.length}, (_, i) => i));

        // Copy data
        this.grid.copy();
        setTimeout(() => { this.notify('Data copied to clipboard', this.NotificationSeverity.Success); this.grid.clearSelection(); }, 500);

      } else if (args.item.id.includes('excel')) {
        // Export data to Excel
        this.grid.excelExport({
          dataSource: dataToExport,
          fileName: `${fileName}.xlsx`
        });

      } else if (args.item.id.includes('pdf')) {
        // Export data to PDF
        this.grid.pdfExport({
          dataSource: dataToExport,
          fileName: `${fileName}.pdf`
        });

      } else if (args.item.id.includes('csv')) {
        // Export data to CSV
        this.grid.csvExport({
          dataSource: dataToExport,
          fileName: `${fileName}.csv`
        });

      } else if (args.item.id.includes('print')) {
        // Print data
        this.grid.print();
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  protected getFormattedDate(): string {
    const date = new Date();
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  }
}