// Angular
import { Component, ViewChild, computed, signal } from '@angular/core';

// 3rd Party
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TabModule, TabComponent, SelectEventArgs } from '@syncfusion/ej2-angular-navigations';

// Internal
import { Tab } from '@services/file-hub/hub.model';
import { FileHubService } from '@services/file-hub/file-hub.service';
import { GlobalsService } from '@services/globals/globals.service';
// Tabs
import { HomeTab } from '@fileHub-tabs/home-tab/home-tab.component';
import { AppointmentsTab } from '@fileHub-tabs/appointments-tab/appointments-tab.component';
import { TasksTab } from '@fileHub-tabs/tasks-tab/tasks-tab.component';
import { FinancialTab } from '@fileHub-tabs/financial-tab/financial-tab.component';
import { DocumentsTab } from '@fileHub-tabs/documents-tab/documents-tab.component';
import { AuthorizationsTab } from '@fileHub-tabs/authorizations-tab/authorizations-tab.component';
import { InjuryTab } from '@fileHub-tabs/injury-tab/injury-tab.component';
import { BoxSignTab } from '@fileHub-tabs/box-sign-tab/box-sign-tab.component';
import { ComplianceTab } from '@fileHub-tabs/compliance-tab/compliance-tab.component';
import { SurgicalTab } from '@fileHub-tabs/surgical-tab/surgical-tab.component';
import { SettingsTab } from '@fileHub-tabs/settings-tab/settings-tab.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'hub-tabs',
  standalone: true,
  templateUrl: './file-hub-tabs.component.html',
  styleUrl: './file-hub-tabs.component.scss',
  imports: [
    TabModule,
    FontAwesomeModule,
    TasksTab,
    HomeTab,
    AppointmentsTab,
    FinancialTab,
    DocumentsTab,
    AuthorizationsTab,
    InjuryTab,
    BoxSignTab,
    ComplianceTab,
    SurgicalTab,
    SettingsTab
  ]
})
export class FileHubTabsComponent {

  /**
   * Main functionality can be found in the FileHubService.
   * @see {@link FileHubService} for more info
   */
  constructor(
    private fileHub: FileHubService,
    public globals: GlobalsService
  ) {}

  private watchReload?: Subscription;

  // Signals
  private readonly state = {
    tabs: signal<Tab[]>(this.fileHub.tabs),
    activeTab: signal<string | undefined>(undefined),
    isResponsive: signal(false)
  };

  // Public Variables
  @ViewChild('ejsTab') ejsTab: TabComponent;
  readonly activeTab = computed(() => this.fileHub.activeTab);
  readonly tabs: Tab[] = this.fileHub.tabs;

  ngOnInit() {
    this.watchReload = this.fileHub.reload$.subscribe(() => {
      this.fileHub.updateActiveTabString(''); // Reset tab state
      this.fileHub.setTabOnLoad(this.ejsTab); // Re-initialize tabs
      this.autoFitTabsToToolbar();
      this.setTabContentHeight();
    });
  }

  ngAfterViewInit() {
    // Fires after Tab Component is fully initialized
    this.ejsTab.created.subscribe(() => {
      this.fileHub.setTabOnLoad(this.ejsTab);
      this.autoFitTabsToToolbar();
      this.setTabContentHeight();
    });
  }
  
  // Apply changes based on currently selected tab
  onTabSelected(args: SelectEventArgs) {
    const selectedTab = this.tabs[args.selectedIndex];
    
    if (selectedTab) {
      this.fileHub.updateActiveTabString(selectedTab.HeaderText);
      this.fileHub.addFragmentToURL(selectedTab.HeaderText);
    }
  }

  // Improves performance by only adding unique tab.Ids to the DOM
  trackByTabId(tab: Tab): string { return tab.Id; }

  // Adjusts tab header widths to fill toolbar
  autoFitTabsToToolbar() {
    const tabEl = this.ejsTab?.element;
    if (!tabEl) return;

    const toolbar = tabEl.querySelector('.e-toolbar-items') as HTMLElement;
    if (!toolbar) return;

    const toolbarItems = toolbar.querySelectorAll('.e-toolbar-item');
    if (!toolbarItems) return;

    const toolbarWidth = toolbar.clientWidth;
    toolbarItems.forEach(item => {
      const itemEl = item as HTMLElement;
      const updatedWidth = `${toolbarWidth / toolbarItems.length}px`;
      itemEl.style.width = updatedWidth;
    });
  }

  // Fills remaining height of page with tab content so scrolling only occurs in tab content
  setTabContentHeight() {
    const tabEl = this.ejsTab?.element;
    if (!tabEl) return;

    const tabContent = tabEl.querySelector('.e-content') as HTMLElement;
    if (!tabContent) return;

    // Set overflow on app content
    const appContent = document.querySelector('app-content') as HTMLElement;
    if (appContent) {
      appContent.style.overflowY = 'hidden';
    }

    // Calculate dimensions
    const rect = tabContent.getBoundingClientRect(); // Get dimensions of tab content
    const height = `${document.body.clientHeight - rect.top}px`; // Set height to remaining height of page
    const appContentPadding = appContent ? parseInt(window.getComputedStyle(appContent).padding) * 2 : 0;
    const width = this.globals.isResponsive ? `${document.body.clientWidth - appContentPadding}px` : 'auto'; // Set width to remaining width of page minus appContent padding

    // Apply all styles at once
    Object.assign(tabContent.style, {
      display: 'block',
      height,
      width,
      overflowX: 'hidden',
      overflowY: 'auto'
    });
  }
}
