import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { UserPreferencesService } from '../user/user-preferences.service';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(
    private userPreferences: UserPreferencesService,
    private router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    // Get the current rights from the signal
    const rights = this.userPreferences.userRights();

    // Extract required rights from route data
    const requiredRights = route.data['rights'] as string[];
    const requiredHttpVerbs = route.data['httpVerbs'] as string[];

    if (!requiredRights || requiredRights.length === 0) {
      return of(true); // No rights required
    }

    // Map HTTP verbs to right prefixes
    const verbToPrefix: Record<string, string> = {
      'GET': 'get',
      'POST': 'edit', // POST operations use edit rights
      'PUT': 'edit',  // PUT operations use edit rights
      'DELETE': 'delete',
      'CREATE': 'create' // Special case for creation rights
    };

    // Check if user has all required rights for specified HTTP verbs
    const hasRequiredRights = requiredRights.every(right => {
      // First check if the exact right exists
      if (rights.includes(right)) {
        return true;
      }

      if (!requiredHttpVerbs || requiredHttpVerbs.length === 0) {
        // If no specific HTTP verbs are required, check for any verb permission
        return rights.some(userRight => {
          // Handle special cases like 'createRoles'
          if (userRight === `create${right}`) {
            return true;
          }
          // Handle standard verb-based rights
          return userRight === `get${right}` || 
                 userRight === `edit${right}` || 
                 userRight === `delete${right}`;
        });
      }

      // Check if user has rights for all required HTTP verbs
      return requiredHttpVerbs.every(verb => {
        const prefix = verbToPrefix[verb];
        if (!prefix) {
          console.warn(`Unsupported HTTP verb: ${verb}`);
          return false;
        }

        // For POST/PUT operations, also check if user has create rights
        if ((verb === 'POST' || verb === 'PUT') && rights.includes(`create${right}`)) {
          return true;
        }

        return rights.includes(`${prefix}${right}`);
      });
    });

    if (!hasRequiredRights) {
      this.router.navigate(['/unauthorized']);
      return of(false);
    }

    return of(true);
  }

  // List of all available rights in the system
  private readonly availableRights = [
    // Work Item related rights
    'getWorkItems', 'editWorkItems', 'deleteWorkItems',
    'getWorkItemTypes', 'editWorkItemTypes', 'deleteWorkItemTypes',
    'getWorkItemStatuses', 'editWorkItemStatuses', 'deleteWorkItemStatuses',
    'getWorkItemPriorities', 'editWorkItemPriorities', 'deleteWorkItemPriorities',
    'getWorkItemCategories', 'editWorkItemCategories', 'deleteWorkItemCategories',

    // Case related rights
    'getCaseFiles', 'editCaseFiles', 'deleteCaseFiles',
    'getCaseTypes', 'editCaseTypes', 'deleteCaseTypes',
    'getCaseFileCompliances', 'editCaseFileCompliances', 'deleteCaseFileCompliances',

    // Configuration related rights
    'getConfigurationVariables', 'editConfigurationVariables', 'deleteConfigurationVariables',
    'getConfigureReferralSource', 'editConfigureReferralSource', 'deleteConfigureReferralSource',
    'getConfigurePandL', 'editConfigurePandL', 'deleteConfigurePandL',
    'getConfigureAppointmentTypes', 'editConfigureAppointmentTypes', 'deleteConfigureAppointmentTypes',

    // Patient related rights
    'getPatients', 'editPatients', 'deletePatients',
    'getXrefPhonePatients', 'editXrefPhonePatients', 'deleteXrefPhonePatients',
    'getXrefAddressPatients', 'editXrefAddressPatients', 'deleteXrefAddressPatients',
    'getXrefWorkItemPatients', 'editXrefWorkItemPatients', 'deleteXrefWorkItemPatients',

    // Provider related rights
    'getProviders', 'editProviders', 'deleteProviders',
    'getProviderTypes', 'editProviderTypes', 'deleteProviderTypes',
    'getProviderPriorityStatuses', 'editProviderPriorityStatuses', 'deleteProviderPriorityStatuses',
    'getXrefProviderContacts', 'editXrefProviderContacts', 'deleteXrefProviderContacts',
    'getXrefPhoneProviders', 'editXrefPhoneProviders', 'deleteXrefPhoneProviders',
    'getXrefAddressProviders', 'editXrefAddressProviders', 'deleteXrefAddressProviders',
    'getXrefWorkItemProviders', 'editXrefWorkItemProviders', 'deleteXrefWorkItemProviders',
    'getXrefProviderProviderTypes', 'editXrefProviderProviderTypes', 'deleteXrefProviderProviderTypes',

    // Law Firm related rights
    'getLawFirms', 'editLawFirms', 'deleteLawFirms',
    'getXrefLawFirmContacts', 'editXrefLawFirmContacts', 'deleteXrefLawFirmContacts',
    'getXrefAddressLawfirms', 'editXrefAddressLawfirms', 'deleteXrefAddressLawfirms',
    'getXrefPhoneLawfirms', 'editXrefPhoneLawfirms', 'deleteXrefPhoneLawfirms',
    'getXrefWorkItemLawFirms', 'editXrefWorkItemLawFirms', 'deleteXrefWorkItemLawFirms',

    // Other entity rights
    'getAddresses', 'editAddresses', 'deleteAddresses',
    'getAgreementStatuses', 'editAgreementStatuses', 'deleteAgreementStatuses',
    'getAuthTypes', 'editAuthTypes', 'deleteAuthTypes',
    'getFeeSchedules', 'editFeeSchedules', 'deleteFeeSchedules',
    'getFileRatings', 'editFileRatings', 'deleteFileRatings',
    'getInjuryTypes', 'editInjuryTypes', 'deleteInjuryTypes',
    'getIntakes', 'editIntakes', 'deleteIntakes',
    'getRecordStatuses', 'editRecordStatuses', 'deleteRecordStatuses',
    'getReductionRequests', 'editReductionRequests', 'deleteReductionRequests',
    'getRemittances', 'editRemittances', 'deleteRemittances',
    'getStates', 'editStates', 'deleteStates',
    'getStatusOnBehalves', 'editStatusOnBehalves', 'deleteStatusOnBehalves',
    'getSurgicalProgression', 'editSurgicalProgression', 'deleteSurgicalProgression',
    'getSurgicalInvoices', 'editSurgicalInvoices', 'deleteSurgicalInvoices',
    'getUsers', 'editUsers', 'deleteUsers',
    'getXrefAddressContacts', 'editXrefAddressContacts', 'deleteXrefAddressContacts',
    'getXrefWorkItemCaseFiles', 'editXrefWorkItemCaseFiles', 'deleteXrefWorkItemCaseFiles'
  ];
}