<div id="add-procedure-code">
    <form class="add-procedure-form" [formGroup]="form" (ngSubmit)="onSubmit()">
        <!-- Single column layout -->
        <div class="form-row">
            <div class="form-col full-width">
                <h4>Edit Procedure Code</h4>
                <div class="form-group">
                    <span class="input-icon">
                        <i class="fas fa-hashtag"></i>
                    </span>
                    <ejs-textbox formControlName="Code" placeholder="Procedure Code"></ejs-textbox>
                </div>

                <div class="form-group">
                    <span class="input-icon">
                        <i class="fas fa-x-ray"></i>
                    </span>
                    <ejs-dropdownlist formControlName="Modality" 
                                    [dataSource]='modalityData' 
                                    [fields]='modalityFields'
                                    placeholder="Modality">
                    </ejs-dropdownlist>
                </div>

                <div class="form-group">
                    <span class="input-icon">
                        <i class="fas fa-align-left"></i>
                    </span>
                    <ejs-textbox formControlName="Description" placeholder="Description"></ejs-textbox>
                </div>
            </div>
        </div>

        <!-- Buttons -->
        <div class="form-actions">
            <button ejs-button cssClass="e-primary submit-button" type="submit" [disabled]="form.invalid">
                Submit
            </button>
            <button ejs-button cssClass="e-outline cancel-button" type="button" (click)="cancel()">
                Cancel
            </button>
        </div>
    </form>
</div>