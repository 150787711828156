// Angular
import { Component, Output, EventEmitter } from '@angular/core';
import { ReactiveFormsModule, FormControl, FormGroup } from '@angular/forms';
import { CommonModule } from '@angular/common';

// 3rd Pary
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';

export interface BulkEditDeposits {
  DepositDate?: string,
  Notes?: string
}

@Component({
  selector: 'bulk-edit-deposits-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DatePickerModule,
    TextBoxModule
  ],
  templateUrl: './bulk-edit-deposits.component.html',
  styleUrl: './bulk-edit-deposits.component.scss'
})
export class BulkEditDepositsForm {

  @Output() formValues = new EventEmitter<any>();

  bulkEditDepositsForm: FormGroup = new FormGroup({
    DepositDate: new FormControl<BulkEditDeposits['DepositDate'] | null>(undefined),
    Notes: new FormControl<BulkEditDeposits['Notes'] | null>(undefined)
  });

  onSubmit() {
    this.formValues.emit(this.bulkEditDepositsForm.value);
  }
}
