import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, ReactiveFormsModule, FormBuilder } from '@angular/forms';
import { LoadingSpinnerComponent } from '@root/src/app/shared/components/loading-spinner/loading-spinner.component';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { faAlignLeft, faListCheck, faHeading } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'base-form',
  standalone: true,
  imports: [
    CommonModule,
    LoadingSpinnerComponent,
    ReactiveFormsModule,
    ButtonModule,
  ],
  templateUrl: './base-form.component.html',
  styleUrls: ['./base-form.component.scss']
})
export class BaseFormComponent {
  @Input() displayButtons = true;
  @Output() formSubmitted = new EventEmitter<void>();
  @Output() formCancelled = new EventEmitter<void>();

  form!: FormGroup;
  loading = false;
  formClasses = {
    formContainer: 'cc-form-container',
    form: 'cc-form flex-column',
    subForm: 'cc-sub-form',
    mainTitle: 'cc-main-title',
    section: 'cc-form-section',
    group: 'cc-form-group row',
    inputContainer: 'cc-input-container',
    label: 'cc-label',
    input: 'cc-input',
    icon: 'cc-input-icon',
    error: 'cc-input-error',
    actions: 'cc-form-actions',
    twoCol: 'col-12',
    threeCol: 'col-12'
  };
  threadHTMLElement: Element | null = null;

  constructor(protected fb: FormBuilder) {}

  onSubmit(): void {
    if (this.form?.valid) {
      this.formSubmitted.emit();
    }
  }

  onCancel(): void {
    this.form?.reset();
    this.formCancelled.emit();
  }

  getErrorMessage(field: string): string {
    const control = this.form?.get(field);
    if (!control?.errors) return '';

    if (control.errors['required']) {
      return `${field} is required`;
    }
    if (control.errors['minlength']) {
      return `${field} must be at least ${control.errors['minlength'].requiredLength} characters`;
    }
    return 'Invalid value';
  }
}