import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'base-form-material',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule
  ],
  template: '<ng-content></ng-content>',
  styles: [`
    :host {
      display: block;
      height: 100%;
      overflow: hidden;
    }
  `]
})
export abstract class BaseFormMaterialComponent {
  @Input() formGroup: FormGroup;
  
  protected hasChanges = false;
  protected initialFormValue: any;

  abstract isFormValid(): boolean;
  abstract submit(): Promise<void>;
  
  protected checkForChanges(): void {
    if (!this.initialFormValue) {
      this.hasChanges = this.isFormValid();
      return;
    }

    const formChanged = JSON.stringify(this.initialFormValue) !== JSON.stringify(this.formGroup.value);
    this.hasChanges = formChanged;
  }

  canSubmit(): boolean {
    if (this.initialFormValue) {
      // Editing: require changes AND valid form
      return this.hasChanges && this.isFormValid();
    }
    // Creating: just require valid form
    return this.isFormValid();
  }
} 