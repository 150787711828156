// Angular
import { CommonModule } from '@angular/common';
import { Component, Input, signal, WritableSignal } from '@angular/core';

// Internal
import { LoadingModule } from '@root/src/app/shared/modules/loading.module';

@Component({
  selector: 'tab-card',
  standalone: true,
  imports: [
    LoadingModule,
    CommonModule,
  ],
  templateUrl: './tab-card.component.html',
  styleUrl: './tab-card.component.scss'
})
export class TabCardComponent {

  constructor() { }

  @Input() loadingContent: WritableSignal<boolean | undefined> = signal(undefined);
  @Input() heading: string;
  @Input() data: any;
}
