<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <ng-content></ng-content>

  <div class="form-actions" *ngIf="displayButtons">
    <button mat-button type="button" (click)="onCancel()">
      Cancel
    </button>
    <button mat-button type="button" (click)="onReset()">
      Reset
    </button>
    <button mat-raised-button color="primary" type="submit" [disabled]="!form.valid">
      Submit
    </button>
  </div>
</form>
