<div id="providers-grid">
    <grid-template  #grid [settings]="providers" [name]="'Providers'"></grid-template>
    <!-- <app-add-provider #Modal></app-add-provider> -->
    <ejs-dialog id="add-provider-modal" #addProviderModal
        header="Add Provider"
        target="body"
        [showCloseIcon]="true"
        [isModal]="true"
        [closeOnEscape]="true"
        [visible]="false"
        (close)="closeDialog()"
        (beforeOpen)="beforeOpening($event)">
        <add-provider-form #addProviderForm></add-provider-form>
    </ejs-dialog>
</div>