// Angular
import { 
  Component,
  Input,
  ViewChild,
  ViewEncapsulation, 
  WritableSignal, 
  signal,
  Output,
  EventEmitter,
  computed,
  effect
} from '@angular/core';
import { CommonModule } from '@angular/common';

// 3rd Party
import { 
  ScheduleAllModule,
  DayService, 
  WeekService, 
  WorkWeekService, 
  MonthService, 
  AgendaService, 
  EventSettingsModel, 
  GroupModel, 
  ScheduleComponent, 
  ResizeService, 
  DragAndDropService,
  TimelineViewsService,
  PopupOpenEventArgs,
  MonthAgendaService,
  TimelineMonthService,
  Timezone,
  RenderCellEventArgs,
  EventRenderedArgs,
  ExcelExportService,
  ICalendarExportService,
  PrintService,
  RecurrenceEditorAllModule,
  ActionEventArgs,
  ToolbarItemModel } from '@syncfusion/ej2-angular-schedule';
import { AccordionModule } from '@syncfusion/ej2-angular-navigations';
import { createElement, Internationalization } from '@syncfusion/ej2-base';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { Switch } from '@syncfusion/ej2-buttons';
import { CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';
import { FontAwesomeModule, IconDefinition } from '@fortawesome/angular-fontawesome';
import { faCircleUser, faNotesMedical, faBriefcase, faXRay } from '@fortawesome/free-solid-svg-icons';

// Internal
import { Appointment, CaseFile } from '@models/data-contracts';
import { KeyValuePair } from '@services/globals/globals.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { SignalRService } from '@services/api/signal-r.service';
import { SchedulerService } from '@services/scheduler/scheduler.service';
import { CreateSyncfusionComponentsService } from '@services/create-syncfusion-components/create-syncfusion-components.service';
import { ProviderMapComponent } from '@features/provider-map/provider-map.component';
import { LoadingModule } from '@modules/loading.module';
import { LoadingComponent } from '@ui/loading/loading.component';
import { SchedulerSignalsService } from '@services/scheduler/scheduler-signals.service';
import { DOMManipulationService } from '@services/dom-manipulaition/dom-manipulation.service';
import { capitalizeFirstLetter } from '@root/src/app/utils';
import { ComponentBase } from '@root/app/core';

const ERRORS = {
  CALENDAR: {
    NAVIGATION_FAILED: {
      message: 'Failed to navigate calendar',
      technical: 'Error handling calendar navigation'
    },
    REFRESH_APPOINTMENTS_FAILED: {
      message: 'Failed to refresh appointments',
      technical: 'Error refreshing calendar appointments'
    },
    DATE_RANGE_CALCULATION_FAILED: {
      message: 'Failed to calculate date range',
      technical: 'Error calculating view date range'
    },
    INITIALIZE_APPOINTMENTS_FAILED: {
      message: 'Failed to initialize appointments',
      technical: 'Error initializing appointment data'
    },
    RENDER_CELL_FAILED: {
      message: 'Failed to render calendar cell',
      technical: 'Error during calendar cell rendering'
    },
    EVENT_RENDER_FAILED: {
      message: 'Failed to render appointment',
      technical: 'Error rendering appointment on calendar'
    },
    ACTION_BEGIN_FAILED: {
      message: 'Failed to process calendar action',
      technical: 'Error handling calendar action begin'
    },
    ACTION_COMPLETE_FAILED: {
      message: 'Failed to complete calendar action',
      technical: 'Error handling calendar action completion'
    },
    POPUP_OPEN_FAILED: {
      message: 'Failed to open appointment details',
      technical: 'Error handling calendar popup open'
    },
    POPUP_CLOSE_FAILED: {
      message: 'Failed to close appointment details',
      technical: 'Error handling calendar popup close'
    },
    DATA_BOUND_FAILED: {
      message: 'Failed to bind calendar data',
      technical: 'Error during calendar data binding'
    }
  }
}

@Component({
  selector: 'scheduler-calendar',
  standalone: true,
  imports: [
    CommonModule,
    ScheduleAllModule,
    AccordionModule,
    CheckBoxModule,
    DialogModule,
    ProviderMapComponent,
    LoadingModule,
    RecurrenceEditorAllModule,
    FontAwesomeModule
  ],
  providers: [
    DayService, 
    WeekService, 
    WorkWeekService, 
    MonthService, 
    AgendaService, 
    ResizeService, 
    DragAndDropService, 
    TimelineMonthService,
    TimelineViewsService,
    MonthAgendaService,
    ExcelExportService,
    ICalendarExportService,
    PrintService
  ],
  templateUrl: './scheduler-calendar.component.html',
  styleUrl: './scheduler-calendar.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class SchedulerCalendarComponent extends ComponentBase {

  constructor (
    private signalR: SignalRService,
    private toast: ToastMessageService,
    private scheduler: SchedulerService,
    private createSyncfusionComponent: CreateSyncfusionComponentsService,
    private DOMManipulation: DOMManipulationService,
    public schedulerSignals: SchedulerSignalsService
  ) {
    super();

    effect(() => {
      const locations = this.schedulerSignals.data.Locations();
      const modalities = this.schedulerSignals.data.Modalities();

      // If locations or modalities only have 1 entry or are nonexistant, they are not needed
      if (locations && locations?.length <= 1 && modalities && modalities?.length <= 1) {
        this.calendarGroup = undefined;
      }
    });
  }

  // Decorators
  @Input() componentContextId: string;
  @Input() inputAppointments: Appointment[];
  @Input() caseFile: CaseFile | undefined;
  @Input() excludeFields: string[];
  @Output() appointmentsData = new EventEmitter<any>();
  @Output() newAppointment = new EventEmitter<any>();
  @Output() changedAppointment = new EventEmitter<any>();
  @ViewChild('calendar') calendar: ScheduleComponent;
  @ViewChild('providerMapDialog') providerMapDialog: DialogComponent;

  // Signals
  providerMapVisible = signal(false);
  protected readonly locations = computed(() => this.schedulerSignals.data.Locations());
  protected readonly modalities = computed(() => this.schedulerSignals.data.Modalities());
  protected readonly modalityTypes = computed(() => this.schedulerSignals.data.ModalityTypes());
  readonly isLoading = computed(() => 
    this.schedulerSignals.loading.Locations() || 
    this.schedulerSignals.loading.Modalities() || 
    this.schedulerSignals.loading.Appointments()
  );
  readonly locationResources = computed(() => 
    this.schedulerSignals.data.Locations() || []
  );
  readonly modalityResources = computed(() => 
    this.schedulerSignals.data.Modalities() || []
  );
  readonly hasRequiredData = computed(() => 
    (this.locationResources()?.length > 0) &&
    (this.modalityResources()?.length > 0)
  );

  // Calendar Setup
  persistence: Boolean = true;
  appointments: EventSettingsModel;

  todaysDate: Date = new Date();
  calendarGroup: GroupModel | undefined = { resources: ['Locations', 'Modalities'], byGroupID: true, allowGroupEdit: true, enableCompactView: false };
  allowOverlap: boolean = true;
  scheduleHeight: string = 'auto';
  currentTimezone: Timezone = new Timezone();
  selectedDate: Date = new Date();
  faCircleUser: IconDefinition = faCircleUser;
  faNotesMedical: IconDefinition = faNotesMedical;
  faBriefcase: IconDefinition = faBriefcase;
  faXRay: IconDefinition = faXRay;
  toolbarItems: ToolbarItemModel[] = [
    { name: 'Previous' },
    { name: 'Next' },
    { name: 'DateRangeText' },
    { name: 'NewEvent' },
    { name: 'Today', align: 'Right' },
    { name: 'Views', align: 'Right' }
  ];

  // Appointment Editing
  intl: Internationalization = new Internationalization();
  caseFilesData: WritableSignal<any> = signal(undefined);
  appointmentModality: DropDownList | undefined;
  appointmentPatient: DropDownList | undefined;
  appointmentProcedureCode: DropDownList | undefined;
  appointmentCaseFile: DropDownList | undefined;
  appointmentStatus: DropDownList | undefined;
  appointmentType: DropDownList | undefined;
  appointmentIsBlockSwitch: Switch | undefined;
  appointmentCompleteSwitch: Switch | undefined;
  appointmentProvider: DropDownList | undefined;
  loading: LoadingComponent = new LoadingComponent;
  providerMapDialogButtons: Object[] = [
    { click: this.hideProviderMap.bind(this), buttonModel: { content: 'Cancel', cssClass: 'e-outline' } },
    { click: this.submitProviderSelection.bind(this), buttonModel: { content: 'Select This Provider', isPrimary: true, cssClass: 'e-outline' } },
  ];

  // Add event emitter for view changes
  @Output() viewChanged = new EventEmitter<{startDate: Date, endDate: Date}>();

  // Add an input to know if parent has already initialized
  @Input() parentInitialized = false;

  // Handle view navigation events
  onNavigating(args: any) {
    try {
      if (args.action === 'date' || args.action === 'view') {
        // Use the helper method to calculate date range
        const { startDate, endDate } = this.calculateViewDateRange(
          args.currentDate || new Date(),
          args.currentView || 'Day'
        );
        
        // Emit event to parent component
        this.viewChanged.emit({ startDate, endDate });
        
        // Fetch appointments for the new date range, then refresh the calendar
        this.schedulerSignals.fetchAppointments(startDate, endDate)
          .then(() => {
            // Refresh the appointment data after the fetch completes
            this.refreshAppointments();
          })
          .catch((error) => {
            this.handleError(error, {
              context: 'SchedulerCalendarComponent.onNavigating.fetchAppointments',
              userMessage: ERRORS.CALENDAR.REFRESH_APPOINTMENTS_FAILED.message,
              severity: this.ErrorSeverity.Error
            });
          });
      }
    } catch (error) {
      this.handleError(error, {
        context: 'SchedulerCalendarComponent.onNavigating',
        userMessage: ERRORS.CALENDAR.NAVIGATION_FAILED.message,
        severity: this.ErrorSeverity.Error
      });
    }
  }

  ngOnInit() {
    // Only initialize if parent hasn't done so
    if (!this.parentInitialized) {
      // Call initialization logic here
    }
  }
  
  private initializeAppointments() {
    try {
      if (!this.inputAppointments && !this.caseFile) {
        // For main calendar view
        const appointments = this.schedulerSignals.data.Appointments();
        if (appointments) {
          // Map appointments to Syncfusion format, filtering out any that return null
          const formattedAppointments = appointments
            .map(appointment => {
              try {
                return this.scheduler.mapEventForSyncfusion(appointment, this.currentTimezone);
              } catch (err) {
                this.handleError(err, {
                  context: 'SchedulerCalendarComponent.initializeAppointments.mapEventForSyncfusion',
                  userMessage: 'Failed to format appointment data',
                  severity: this.ErrorSeverity.Warning
                });
                return null;
              }
            })
            .filter(appointment => appointment !== null);
          
          this.appointments = { dataSource: formattedAppointments };
        }
      } else {
        // For case file specific view
        if (this.inputAppointments?.length === 0) {
          this.toast.showNeutral('No appointments found for file.');
          return;
        }
        
        const formattedAppointments = this.inputAppointments
          ?.map(appointment => {
            try {
              return this.scheduler.mapEventForSyncfusion(appointment, this.currentTimezone);
            } catch (err) {
              this.handleError(err, {
                context: 'SchedulerCalendarComponent.initializeAppointments.mapInputAppointment',
                userMessage: 'Failed to format input appointment data',
                severity: this.ErrorSeverity.Warning
              });
              return null;
            }
          })
          .filter(appointment => appointment !== null);
        
        this.appointments = { dataSource: formattedAppointments };
      }
    } catch (error) {
      this.handleError(error, {
        context: 'SchedulerCalendarComponent.initializeAppointments',
        userMessage: ERRORS.CALENDAR.INITIALIZE_APPOINTMENTS_FAILED.message,
        severity: this.ErrorSeverity.Error
      });
      this.appointments = { dataSource: [] };
    }
  }

  // Add trackBy for better list rendering
  trackByAppointmentId(index: number, appointment: Appointment): number | undefined {
    return appointment.Id;
  }

  // Set shared signals after data bound to calendar
  onDataBound(args: any) {
    try {
      const savedState = localStorage.getItem(`schedule${this.calendar.element.id}`);
      this.schedulerSignals.calendar.Component.set(this.calendar);
      this.schedulerSignals.calendar.State.set(savedState ?? '');
      this.schedulerSignals.calendar.Resources.set(this.calendar.getResourceCollections());
    } catch (error) {
      this.handleError(error, {
        context: 'SchedulerCalendarComponent.onDataBound',
        userMessage: ERRORS.CALENDAR.DATA_BOUND_FAILED.message,
        severity: this.ErrorSeverity.Warning
      });
    }
  }

  // Occurs when appointments are added to the calendar
  onRenderCell = async (args: RenderCellEventArgs) => {
    try {
      // Alternate opacity values for easier scanability between resources
      let groupNumber = args.element.getAttribute('data-group-index') as string;
      let classString = Number(groupNumber) % 2 === 0 ? 'even' : 'odd';
      args.element.classList.add(classString);
    } catch (error) {
      this.handleError(error, {
        context: 'SchedulerCalendarComponent.onRenderCell',
        userMessage: ERRORS.CALENDAR.RENDER_CELL_FAILED.message,
        severity: this.ErrorSeverity.Warning
      });
    }
  }

  // Occurs when users attempt edits and on load
  onActionBegin(args: ActionEventArgs): void {
    try {
      if (args.requestType === 'eventCreate' || args.requestType === 'eventChange') {
        // Validate event data before proceeding
        if (!this.hasEventData(args)) {
          args.cancel = true;
          return;
        }
        
        const event = args.data as Appointment;
        this.processEvent(args, event);
      }
    } catch (error) {
      this.handleError(error, {
        context: 'SchedulerCalendarComponent.onActionBegin',
        userMessage: ERRORS.CALENDAR.ACTION_BEGIN_FAILED.message,
        severity: this.ErrorSeverity.Error
      });
      if (args) args.cancel = true;
    }
  }
  
  private hasEventData(args: any): boolean {
    if (!args.data) {
      this.toast.showError('Missing appointment data');
      return false;
    }
    return true;
  }
  
  private processEvent(args: any, event: any) {
    const startTime = event.occurrence ? event.occurrence.StartTime : event.startTime;
    const endTime = event.occurrence ? event.occurrence.EndTime : event.endTime;
  
    if (!this.isSlotAvailable(args, startTime, endTime)) {
      args.cancel = true;
      this.toast.showError('Time slot not available.');
      return;
    }
  }
  
  private isSlotAvailable(args: any, startTime: Date, endTime: Date): boolean {
    return this.allowOverlap || this.calendar?.isSlotAvailable(startTime, endTime);
  }

  // Occurs when users save edits and after load resolves
  onActionComplete(args: ActionEventArgs) {
    try {
      // Apply batch saves
      if (args.requestType === 'eventCreated' || args.requestType === 'eventChanged' || args.requestType === 'eventRemoved') {
        this.scheduler.batchSaveSchedule(args, this.currentTimezone).then((res: any) => {
          // No response means everything saved successfully
          if (res instanceof Error) {
            this.handleError(res, {
              context: 'SchedulerCalendarComponent.onActionComplete.batchSaveSchedule',
              userMessage: 'Failed to save appointment changes',
              severity: this.ErrorSeverity.Error
            });
          } else {
            this.toast.showSuccess('Appointment saved successfully');
          }
        }).catch((error) => {
          this.handleError(error, {
            context: 'SchedulerCalendarComponent.onActionComplete.batchSaveSchedule',
            userMessage: 'Failed to save appointment changes',
            severity: this.ErrorSeverity.Error
          });
        });
        
        this.scheduler.removeUserEditingElements();
        this.calendar?.refreshEvents();
      }
    } catch (error) {
      this.handleError(error, {
        context: 'SchedulerCalendarComponent.onActionComplete',
        userMessage: ERRORS.CALENDAR.ACTION_COMPLETE_FAILED.message,
        severity: this.ErrorSeverity.Error
      });
    }
  }

  // Hook that applies to all popup events on calender, including single click and detailed view of appointments
  async onPopupOpen(args: PopupOpenEventArgs) {
    const dialogElement: HTMLElement = args.element as HTMLElement;
    const appointment = args.data as Appointment;
    if (!args.data) return;
  
    const dialogHeader: Element = dialogElement.querySelector('.e-dlg-header-content') as Element;
    let editingAppointment: boolean = !!(args.data && (args.data as any).Id);
  
    // Handle different behavior for quick info popup
    if (args.type === 'QuickInfo') this.handleQuickInfoPopup(args);
  
    // Check if user starts editing an appointment
    if (args.type === 'Editor') {
      dialogElement.style.width = '100%';
      this.providerMapVisible.set(true);
      const editorForm: Element = args.element.querySelector('.e-schedule-form') as Element;
      const appointmentTitle: Element = editorForm.querySelector('.e-title-location-row') as Element;
      const resourcesSection: Element = editorForm.querySelector('.e-resources-row') as Element;
  
      if (!editingAppointment) Object.assign((dialogHeader as HTMLElement).style, this.scheduler.removeHeaderStyles());
      this.removeGroupFields(editorForm);
      this.updateDialogHeader(dialogHeader, appointment, editingAppointment);
      this.prepareEditingAppointment(appointmentTitle, resourcesSection);
      this.addSwitchForBlockingTime(dialogElement, appointment);
      this.addCustomFields(resourcesSection, appointment);
    }
  }

  // Hook that applies for single clicks on appointments
  private handleQuickInfoPopup(args: PopupOpenEventArgs) {
    if (args.target?.tagName === 'TD') {
      this.scheduler.removeUserEditingElements();
      this.signalR.broadcastHTMLElement(args.target, 'data-date', 'add');
    }
  
    this.updateLocationDetails(args);
    this.setSubjectInputValue(args);
  }
  
  // Replaces Synfusion's default location field with custom data
  private updateLocationDetails(args: PopupOpenEventArgs) {
    const locationDetails = args.element.querySelector('.e-popup-content .e-location .e-location-details') as HTMLElement;
    if (locationDetails && args.data?.['Location']?.Description) {
      locationDetails.innerHTML = args.data['Location'].Description;
    }
  }
  
  // Replaces Syncfusion's default subject field with custom data
  private setSubjectInputValue(args: PopupOpenEventArgs) {
    const subjectInput = args.element.querySelector('.e-subject.e-field.e-input') as HTMLInputElement;
    if (subjectInput && this.caseFile?.FileNumber && this.caseFile?.Patient) {
      const { Firstname, Lastname } = this.caseFile.Patient;
      subjectInput.value = `${this.caseFile.FileNumber} - ${capitalizeFirstLetter(Firstname ?? '')} ${capitalizeFirstLetter(Lastname ?? '')}`;
    }
  }

  // Removes fields that affect filtering
  private removeGroupFields(dialogForm: Element) {
    if (this.calendarGroup === undefined) {
      dialogForm.querySelector('.e-LocationId-container')?.remove();
      dialogForm.querySelector('.e-ModalityId-container')?.remove();
    }
  }

  // Adds header background associated with matching modality
  private updateDialogHeader(dialogHeader: Element, appointment: Appointment, editingAppointment: boolean) {
    if (dialogHeader && appointment['ModalityId'] && editingAppointment) {
      const dialogHeaderStyles = this.scheduler.getEventHeaderStyles(appointment as KeyValuePair, this.calendar);
      Object.assign((dialogHeader as HTMLElement).style, dialogHeaderStyles);
    }
  }

  // Parent fn to call each sub-fn
  private prepareEditingAppointment(appointmentTitle: Element, resourcesSection: Element) {
    this.removeDefaultElements(appointmentTitle);
    this.moveDropdowns(appointmentTitle, resourcesSection);
  }

  // Removes Syncfusion's default elements for their appointment editor
  private removeDefaultElements(appointmentTitle: Element) {
    const locationContainer = appointmentTitle.querySelector('.e-location-container');
    const subjectContainer = appointmentTitle.querySelector('.e-subject-container');
    if (locationContainer) locationContainer.remove();
    if (subjectContainer) subjectContainer.remove();
  }

  // Moves custom fields to top
  private moveDropdowns(appointmentTitle: Element, resourcesSection: Element) {
    const locationDropdown = resourcesSection.querySelector('.e-LocationId-container') as Element;
    const modalityDropdown = resourcesSection.querySelector('.e-ModalityId-container') as Element;

    if (locationDropdown) appointmentTitle.appendChild(locationDropdown);
    if (modalityDropdown) appointmentTitle.appendChild(modalityDropdown);
  }

  // Check for existing custom fields first - prevents duplicates
  private addCustomFields(resourcesSection: Element, appointment: Appointment) {
    const noCustomFields: boolean = resourcesSection.querySelector('.custom-field-row') === null;

    if (noCustomFields) {
      resourcesSection.classList.add('row');
      this.styleResourceSectionChildren(resourcesSection);
      this.addDropDownsForAppointment(resourcesSection, appointment);
    }
  }

  // Updates layout for improved ui
  private styleResourceSectionChildren(resourcesSection: Element) {
    Array.from(resourcesSection.children).forEach(child => {
      child.classList.add('col-12', 'col-md-6', 'col-lg-4');
      child.classList.remove('e-resources');

      const customFields = child.querySelectorAll('.custom-field-row');
      customFields.forEach(field => {
        field.classList.add('col-12', 'col-md-6');
      });
    });
  }

  private addSwitchForBlockingTime(dialogElement: Element, appointment: Appointment) {
    if (this.appointmentIsBlockSwitch === undefined) {
      const timeRow = dialogElement.querySelector('.e-all-day-time-zone-row') as Element;
      const isBlockSwitchOptions = { checked: appointment['Block'] as boolean, onLabel: 'Locked', offLabel: 'Time Slot Open', name: 'IsBlock' };
      this.appointmentIsBlockSwitch = this.createSyncfusionComponent.createSwitch(isBlockSwitchOptions);
      this.scheduler.addComponentToEditorWindow(timeRow, this.appointmentIsBlockSwitch, 'isBlock', 'custom-field-container');
    }
  }

  private addDropDownsForAppointment(resourcesSection: Element, appointment: Appointment) {
    if (!this.excludeFields.includes('CaseFileId')) this.addDropDownForCaseFile(resourcesSection, appointment);
    if (!this.excludeFields.includes('AppointmentTypeId')) this.addDropDownForAppointmentType(resourcesSection, appointment);
    if (!this.excludeFields.includes('AppointmentStatusId')) this.addDropDownForAppointmentStatus(resourcesSection, appointment);
    if (!this.excludeFields.includes('ProcedureCodeId')) this.addDropDownForProcedureCode(resourcesSection, appointment);
    if (!this.excludeFields.includes('ProviderId')) this.addDropDownForProvider(resourcesSection, appointment);
  }

  private addDropDownForCaseFile(resourcesSection: Element, appointment: Appointment) {
    this.schedulerSignals.fetchCaseFiles().then((result) => {
      const caseFiles = result?.map((result: any) => {
        return {
          Id: result.Id,
          Text: `CaseFile: ${result.FileNumber}, Type: ${result.CaseType}`
        }
      });
      const caseFilesDrpDownOptions = { dataSource: caseFiles, fields: { text: 'Text', value: 'Id' }, placeholder: 'Case File', value: appointment['CaseFileId'] };
      this.appointmentCaseFile = this.createSyncfusionComponent.createDropDownList(caseFilesDrpDownOptions);
      this.scheduler.addComponentToEditorWindow(resourcesSection, this.appointmentCaseFile, 'CaseFileId', 'custom-field-row col-12 col-md-6 col-lg-4 order-3');
    }); 
  }

  private addDropDownForAppointmentType(resourcesSection: Element, appointment: Appointment) {
    this.schedulerSignals.fetchAppointmentTypes().then((result) => {
      const typeDrpDwnOptions = { dataSource: result as any[], fields: { text: 'Description', value: 'Id' }, placeholder: 'Appointment Type', value: appointment['AppointmentTypeId'] };
      this.appointmentType = this.createSyncfusionComponent.createDropDownList(typeDrpDwnOptions);
      this.scheduler.addComponentToEditorWindow(resourcesSection, this.appointmentType, 'AppointmentTypeId', 'custom-field-row col-12 col-md-6 col-lg-4 order-2');
    });
  }

  private addDropDownForAppointmentStatus(resourcesSection: Element, appointment: Appointment) {
    this.schedulerSignals.fetchAppointmentStatuses().then((result) => {
      const statusDrpDwnOptions = { dataSource: result as any[], fields: { text: 'Description', value: 'Id' }, placeholder: 'Status', value: appointment['AppointmentStatusId'] ?? 5 };
      this.appointmentStatus = this.createSyncfusionComponent.createDropDownList(statusDrpDwnOptions);
      this.scheduler.addComponentToEditorWindow(resourcesSection, this.appointmentStatus, 'AppointmentStatusId', 'custom-field-row col-12 col-md-6 col-lg-4 order-1');
    });
  }

  private async addDropDownForProcedureCode(resourcesSection: Element, appointment: Appointment) {
    const modality = await this.schedulerSignals.fetchModality(appointment.ModalityId as number) ?? []; // Await response to determine if modality is present
    this.schedulerSignals.fetchProcedureCodesMatchingModalityType((modality as any)[0]?.ModalityTypeId).then((result) => {
      const procedureCodesDrpDwnOptions = { dataSource: result as any[], fields: { text: 'Description', value: 'Id' }, placeholder: 'Procedure', value: appointment['ProcedureCodeId']};
      this.appointmentProcedureCode = this.createSyncfusionComponent.createDropDownList(procedureCodesDrpDwnOptions);
      this.scheduler.addComponentToEditorWindow(resourcesSection, this.appointmentProcedureCode, 'ProcedureCodeId', 'custom-field-row col-12 col-md-6 col-lg-4 order-4');
    });
  } 

  private async addDropDownForProvider(resourcesSection: Element, appointment: Appointment) {
    await this.schedulerSignals.fetchProviders().then((result) => {
    const providersDrpDwnOptions = { dataSource: result as any[], fields: { text: 'Name', value: 'Id' }, placeholder: 'Provider', value: appointment['ProviderId']};
      this.appointmentProvider = this.createSyncfusionComponent.createDropDownList(providersDrpDwnOptions);
      this.scheduler.addComponentToEditorWindow(resourcesSection, this.appointmentProvider, 'ProviderId', 'custom-field-row col-12 col-md-6 col-lg-4 order-5');
      this.addProviderMapButton(resourcesSection); // Add this to .then() of await so it doesnt appear until after provider list is ready.
    });
  }

  showProviderMap() {
    if (this.providerMapDialog) this.providerMapDialog.show();
  }

  hideProviderMap() {
    if (this.providerMapDialog) this.providerMapDialog.hide();
  }

  // Update the button creation method
  private async addProviderMapButton(resourcesSection: Element) {
    const providerBtnOptions = { 
      className: 'e-btn e-outline', 
      innerHTML: 'Provider Map',
      attrs: { name: 'ProviderBtn', type: 'button', 'data-ripple': 'true' } 
    };
    const addProviderBtn = createElement('button', providerBtnOptions) as HTMLButtonElement;
    addProviderBtn.onclick = () => this.showProviderMap();
    this.DOMManipulation.addBtnToHTMLElement(
      resourcesSection, 
      addProviderBtn, 
      'custom-field-row col-12 col-md-6 col-lg-4 order-6 d-flex align-items-center'
    );
  }

  private updateSelectedDateForLocalStorage() {
    const persistedSettings = JSON.parse(localStorage.getItem('schedule_ejs-scheduler-main-appointment-calendar') ?? '');

    if (persistedSettings !== '') {
      persistedSettings.currentView = 'Day';
      persistedSettings.selectedDate = this.selectedDate;
      localStorage.setItem('schedule_ejs-scheduler-main-appointment-calendar', JSON.stringify(persistedSettings));
    }
  }

  // Occurs whenever a popup modal is closed
  onPopupClose(args: any) {
    this.scheduler.removeUserEditingElements();

    // Ensure popup window is Editor before updating values
    if (args.type === 'Editor') {
      // Set CaseFileId if it exists and not on main scheduler
      if (this.caseFile?.Id && window.location.pathname !== '/scheduler') args.data['CaseFileId'] = this.caseFile?.Id;
      // Set modality and location to default if calendar groups dont exist
      if (!this.calendarGroup) args.data['ModalityId'] = 1; args.data['LocationId'] = 1;
      this.providerMapVisible.set(false);
      const validityCheck = this.scheduler.validateAppointment(args.data);
      if (validityCheck) {
        args.cancel = true;
        this.toast.showError(validityCheck);
        return;
      }

      this.clearEditorWindowFields();
      const dialogElement = args.element as HTMLElement;
      const customFields = dialogElement.querySelectorAll('.custom-field-row');
      customFields.forEach(field => field.remove());
    }
  }

  // Add completed styles to completed appointments
  onEventRendered(args: EventRenderedArgs) {
    try {
      if (args.data['IsCompleted']) {
        args.element.classList.add('complete');
      } else {
        args.element.classList.add('incomplete');
      }
    } catch (error) {
      this.handleError(error, {
        context: 'SchedulerCalendarComponent.onEventRendered',
        userMessage: ERRORS.CALENDAR.EVENT_RENDER_FAILED.message,
        severity: this.ErrorSeverity.Warning
      });
    }
  }

  // Provider Map items below
  @ViewChild('providerMap') providerMap: ProviderMapComponent;
  markerWindowTxt: string = 'Select this Provider';
  provider: any;

  onProviderSelected(provider: any) {
    this.provider = provider.data;
  }

  submitProviderSelection() {
    (this.appointmentProvider as DropDownList).value = this.provider.id;
    this.providerMapDialog.hide();
  }

  clearEditorWindowFields() {
    if (this.appointmentCaseFile) this.appointmentCaseFile = undefined;
    if (this.appointmentIsBlockSwitch) this.appointmentIsBlockSwitch = undefined;
    if (this.appointmentModality) this.appointmentModality = undefined;
    if (this.appointmentProcedureCode) this.appointmentProcedureCode = undefined;
    if (this.appointmentProvider) this.appointmentProvider = undefined;
  }

  // Add a method to calculate date range based on current view
  calculateViewDateRange(date: Date, view: string): {startDate: Date, endDate: Date} {
    try {
      const startDate = new Date(date);
      const endDate = new Date(date);
      
      // Adjust date range based on view type
      if (view === 'Day') {
        // Just use the current date
      } else if (view === 'Week' || view === 'WorkWeek') {
        // Get start of week and end of week
        const dayOfWeek = startDate.getDay();
        startDate.setDate(startDate.getDate() - dayOfWeek);
        endDate.setDate(endDate.getDate() + (6 - dayOfWeek));
      } else if (view === 'Month') {
        // Get start of month and end of month
        startDate.setDate(1);
        endDate.setMonth(endDate.getMonth() + 1);
        endDate.setDate(0);
      }
      
      // Add buffer days to ensure we have appointments just outside the visible range
      startDate.setDate(startDate.getDate() - 7); // one week before
      endDate.setDate(endDate.getDate() + 7); // one week after
      
      return { startDate, endDate };
    } catch (error) {
      this.handleError(error, {
        context: 'SchedulerCalendarComponent.calculateViewDateRange',
        userMessage: ERRORS.CALENDAR.DATE_RANGE_CALCULATION_FAILED.message,
        severity: this.ErrorSeverity.Error
      });
      // Return a default date range on error
      const fallbackDate = new Date();
      const fallbackStartDate = new Date(fallbackDate);
      fallbackStartDate.setDate(fallbackStartDate.getDate() - 7);
      const fallbackEndDate = new Date(fallbackDate);
      fallbackEndDate.setDate(fallbackEndDate.getDate() + 7);
      return { startDate: fallbackStartDate, endDate: fallbackEndDate };
    }
  }

  // Update the refreshAppointments method to handle errors during mapping
  refreshAppointments() {
    try {
      // Get the latest appointments from the signal
      const appointments = this.schedulerSignals.data.Appointments();
      
      if (appointments && appointments.length > 0) {
        // Map appointments to Syncfusion format, filtering out any that return null
        const formattedAppointments = appointments
          .map(appointment => {
            try {
              return this.scheduler.mapEventForSyncfusion(appointment, this.currentTimezone);
            } catch (err) {
              this.handleError(err, {
                context: 'SchedulerCalendarComponent.refreshAppointments.mapEventForSyncfusion',
                userMessage: 'Failed to format appointment data',
                severity: this.ErrorSeverity.Warning
              });
              return null;
            }
          })
          .filter(appointment => appointment !== null);
        
        // Update the appointments data source
        this.appointments = { dataSource: formattedAppointments };
        
        // Force the calendar to refresh if it exists
        if (this.calendar) {
          this.calendar.refreshEvents();
        }
      } else {
        // Set empty appointments to clear the calendar
        this.appointments = { dataSource: [] };
        if (this.calendar) {
          this.calendar.refreshEvents();
        }
      }
    } catch (error) {
      this.handleError(error, {
        context: 'SchedulerCalendarComponent.refreshAppointments',
        userMessage: ERRORS.CALENDAR.REFRESH_APPOINTMENTS_FAILED.message,
        severity: this.ErrorSeverity.Error
      });
    }
  }
}