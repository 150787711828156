// Angular Imports
import { Component, OnInit, ViewChild, signal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router'; 

// 3rd Party Imports
import { GridModel } from '@syncfusion/ej2-grids';
import { GridAllModule } from '@syncfusion/ej2-angular-grids';
import { DataManager, Predicate, Query } from '@syncfusion/ej2-data';
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';
import { CommandClickEventArgs } from '@syncfusion/ej2-grids';

// Internal Imports
import { APIEndpoints } from '@models/api/Endpoints';
import { ApiService } from '@services/api/api.service';
import { GridTemplateComponent } from '@grids/grid-template/grid-template.component';
import { CognitoService } from '@root/src/app/shared/services/auth/cognito.service';
import { Contact, LawFirm, Role, User, XrefUsersRole } from '@root/src/app/shared/models/data-contracts';
import { LoadingModule } from '@root/src/app/shared/modules/loading.module';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { AuditLogService } from '@services/audit-logs/audit-log-service';
import { AuditLogsComponent } from '../../../shared/components/audit-logs/audit-logs.component';
import { LawFirmFormComponent } from '../../forms/law-firm-form/law-firm-form.component';
import { BulkEditLawFirmsComponent } from '../bulk-edit-law-firms/bulk-edit-law-firms.component';

interface ODataResponse<T> {
  result: T[];
  count: number;
}

// Component Metadata
@Component({
  selector: 'law-firms-grid',
  standalone: true,
  imports: [
    CommonModule,
    GridTemplateComponent,
    GridAllModule,
    DialogModule,
    LoadingModule,
    AuditLogsComponent,
    LawFirmFormComponent,
    BulkEditLawFirmsComponent
  ],
  providers: [AuditLogService],
  templateUrl: './law-firms-grid.component.html',
  styleUrl: './law-firms-grid.component.scss',
})
export class LawFirmsGridComponent implements OnInit {
  // ViewChild Declarations
  @ViewChild('logsDialog') logsDialog: any;
  @ViewChild('nameTemplate', { static: true }) nameTemplate!: string;
  @ViewChild('caseFileTemplate', { static: true }) caseFileTemplate!: string;
  @ViewChild('lawFirmsGrid') lawFirmsGrid!: GridTemplateComponent;
  @ViewChild('addLawFirmDialog', { static: true }) addLawFirmFormDialog!: DialogComponent;
  @ViewChild('editLawFirmDialog', { static: true }) editLawFirmFormDialog!: DialogComponent;
  @ViewChild('bulkEditLawFirmsComponent') bulkEditLawFirmsComponent: BulkEditLawFirmsComponent;
  @ViewChild('lawFirmDialog') lawFirmDialog: DialogComponent;

  public editDialogVisibility: boolean = false;


  // Properties
  loadingData: WritableSignal<boolean> = signal(true);
  lawFirmSignal = signal<LawFirm | undefined>(undefined);
  lawFirms: GridModel;
  marketMangers: DataManager;
  caseManagers: DataManager;
  viewLawFirmAlert: boolean = false;
  auditLogs: any[] = [];
  auditLogsLoading = false;
  logsDialogVisibility: boolean = false;
  showActive: boolean = true;
  editLawFirm?: LawFirm;
  addLawFirmDialogVisibility = false;
  editLawFirmDialogVisibility = false;
  selectedLawFirm: WritableSignal<LawFirm | undefined> = signal(undefined);
  lawFirmAddress: any;

  // Track selected rows count for toolbar
  private selectedRowCount: number = 0;

  // Button Configurations
  logsDialogButtons: Object[] = [
    { click: this.closeLogsDialog.bind(this), buttonModel: { content: 'Close', cssClass: 'e-flat' } }
  ];

  // Constructor
  constructor(
    private api: ApiService,
    public cognito: CognitoService,
    private router: Router,
    private toast: ToastMessageService,
    private auditLogService: AuditLogService
  ) { }

  // Lifecycle Hooks
  ngOnInit(): void {
    this.getData().then((data: any) => {
      this.lawFirms = {
        dataSource: data,
        columns: [
          { type: 'checkbox', allowFiltering: false },
          { field: 'Id' },
          { field: 'Abbreviation', headerText: 'Abbreviation' },
          { field: 'Name', template: this.nameTemplate },
          { field: 'LawFirmAddress.Address1', headerText: 'Address' },
          { field: 'LawFirmAddress.City', headerText: 'City' },
          { field: 'LawFirmAddress.StateNavigation.Name', headerText: 'State' },
          { field: 'LawFirmAddress.Zip', headerText: 'Zip' },
          { field: 'MarketManager', headerText: 'Market Manager', foreignKeyValue: 'Username', foreignKeyField: 'Id', dataSource: this.marketMangers, editType: 'dropdownedit' },
          { field: 'CaseManager', headerText: 'Case Manager', foreignKeyValue: 'Username', foreignKeyField: 'Id', dataSource: this.caseManagers, editType: 'dropdownedit' },
          { field: 'IsActive', headerText: 'Is Active' },
          { type: 'commands', headerText: 'Actions', commands: [
            { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
            { type: 'Delete', title: 'Toggle Active', buttonOption: { cssClass: 'e-flat', iconCss: 'e-icons e-circle-check' } },
            { type: 'None', title: 'Logs', buttonOption: { iconCss: 'e-icons e-description', cssClass: 'e-flat' } }
          ] },
        ],
        allowPaging: true,
        toolbarClick: ($event: any) => this.customToolbarClick($event),
        commandClick: ($event: any) => this.onCommandClick($event),
        rowSelected: () => this.onRowSelected(),
        rowDeselected: () => this.onRowDeselected(),
      };
    });
  }

  query: Query = new Query()
    .expand(['XrefAddressLawfirms($expand=Address($expand=StateNavigation))'])
    .where('IsActive', 'equal', this.showActive);

  getAddress1(field: string, data: object): string[] {
    const lawFirm = data as any;
    return [lawFirm.XrefAddressLawfirms?.[0]?.Address?.Address1 || ''];
  }
    
  getCity(field: string, data: object): string[] {
    const lawFirm = data as any;
    return [lawFirm.XrefAddressLawfirms?.[0]?.Address?.City || ''];
  }
    
  getState(field: string, data: object): string[] {
    const lawFirm = data as any;
    return [lawFirm.XrefAddressLawfirms?.[0]?.Address?.StateNavigation?.Name || ''];
  }
    
  getZip(field: string, data: object): string[] {
    const lawFirm = data as any;
    return [lawFirm.XrefAddressLawfirms?.[0]?.Address?.Zip || ''];
  }

  customToolbarClick(args: any) {
    if (args.item.id === 'add') {
      this.addLawFirmDialogVisibility = true;
    } else if (args.item.id === 'bulkEditLawFirms') {
      this.getSelected();
    } else if (args.item.id === '"toggleActive"') {
      this.showActive = !this.showActive;
      this.dataStateChange({
        skip: 0,
        take: 16
      });
    }
  }

  async dataStateChange(state: any): Promise<void> {
    this.query = new Query()
      .expand(['XrefAddressLawfirms($expand=Address($expand=StateNavigation))'])
      .where('IsActive', 'equal', this.showActive)
      .page(state.page, state.take);  

    const data = await this.getData();
    
    if (this.lawFirmsGrid) {
      this.lawFirmsGrid.grid.dataSource = {
        result: data.result,
        count: data.count
      };
    }
  }

  async getData() {
    try {
    
      const usersResponse = (await this.api
        .getOdata(APIEndpoints.Users)
        .executeQuery(new Query().expand('XrefUsersRoles')) as any);
      console.log(usersResponse);
      this.marketMangers = new DataManager(usersResponse.result.filter((user: User) => 
        user.XrefUsersRoles?.some((xRefRole: XrefUsersRole) => xRefRole.RoleId === 72)
      ));
      this.caseManagers = new DataManager(usersResponse.result.filter((user: User) => 
        user.XrefUsersRoles?.some((xRefRole: XrefUsersRole) => xRefRole.RoleId === 71)
      ));
      console.log(this.marketMangers);
      console.log(this.caseManagers);

      return this.api.getOdata(APIEndpoints.Lawfirms).executeQuery(this.query).then((res: any) => {
          if (!res || !res.result) throw new Error('Failed to fetch law firms.');
          this.loadingData.set(false);
          return res.result.map((lawFirm: LawFirm) => {
            return {
              ...lawFirm,
              LawFirmAddress: lawFirm.XrefAddressLawfirms?.[0]?.Address
            }
          });
        });
    } catch (error) {
      this.loadingData.set(false);
      this.toast.showError('Failed to load law firms data');
      console.error('Error in getData:', error);
      return [];
    }
  }

  onLawFirmClick(data: any) {
    this.router.navigate(['/law-firm-detail', data.Id]);
  }

  async onCommandClick(args: CommandClickEventArgs) {
    const lawFirm = args.rowData as any;

    if (lawFirm && lawFirm.Id && args.commandColumn?.title === 'Logs') {
      try {
        this.auditLogsLoading = true;
        this.showLogsDialog();
        const logs = await this.auditLogService.getAuditLogs(lawFirm.Id, 'LawFirm');
        
        if (!logs) {
          throw new Error('No audit logs returned from service');
        }

        try {
          this.auditLogs = this.auditLogService.mapAuditDataToLogFormat(logs);
        } catch (formatError) {
          this.closeLogsDialog();
          this.toast.showError('Error formatting audit log data. Please try again.');
          console.error('Error formatting audit logs:', formatError);
          return;
        }
      } catch (error) {
        this.closeLogsDialog();
        this.toast.showError('Failed to load audit logs. Please try again.');
        console.error('Error loading audit logs:', error);
      } finally {
        this.auditLogsLoading = false;
      }
    } else if (args.commandColumn?.type === 'Edit') {
      args.cancel = true;
      this.openEditDialog(lawFirm);
    }
  }

  showLogsDialog() {
    try {
      this.logsDialogVisibility = true;
      if (this.logsDialog) {
        this.logsDialog.show();
      } else {
        throw new Error('Logs dialog not initialized');
      }
    } catch (error) {
      this.toast.showError('Error displaying logs dialog');
      console.error('Error showing logs dialog:', error);
    }
  }

  closeLogsDialog() {
    try {
      if (this.logsDialog) {
        this.logsDialog.hide();
        this.logsDialogVisibility = false;
        this.auditLogs = [];
      }
    } catch (error) {
      this.toast.showError('Error closing logs dialog');
      console.error('Error closing logs dialog:', error);
    }
  }

  beforeOpening(args: any) {
    try {
      args.maxHeight = '100%';
    } catch (error) {
      this.toast.showError('Error configuring dialog');
      console.error('Error in beforeOpening:', error);
    }
  }

  closeDialog(): void {
    this.editDialogVisibility = false;
  }

  openEditDialog(lawFirm: LawFirm): void {
    this.lawFirmSignal.set(lawFirm);
    this.editDialogVisibility = true;
  }

  onRowSelected(): void {
    this.selectedRowCount = this.lawFirmsGrid.getSelectedRows().length;
    if (this.selectedRowCount > 1) {
      this.lawFirmsGrid.insertToolbarItem({ 
        text: 'Bulk Edit', 
        id: 'bulkEditLawFirms', 
        tooltipText: 'Edit multiple selected law firms' 
      });
    }
  }

  onRowDeselected(): void {
    this.selectedRowCount = this.lawFirmsGrid.getSelectedRows().length;
    if (this.selectedRowCount <= 1) {
      const toolbar = this.lawFirmsGrid.grid.toolbar as any[];
      this.lawFirmsGrid.grid.toolbar = toolbar.filter(item => item.id !== 'bulkEditLawFirms');
    }
  }

  getSelected() {
    return this.lawFirmsGrid?.getSelectedRows() || [];
  }

  async onFormSubmitted() {
    this.editDialogVisibility = false;
    this.addLawFirmDialogVisibility = false;
    
    try {
      const data = await this.getData();
      if (this.lawFirmsGrid && this.lawFirmsGrid.grid) {
        this.lawFirmsGrid.grid.dataSource = data;
        this.lawFirmsGrid.refresh();
      }
    } catch (error) {
      console.error('Error refreshing grid:', error);
      this.toast.showError('Error refreshing data');
    }
  }
}