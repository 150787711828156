// Angular
import { Component, Input, Output, ViewEncapsulation, EventEmitter, ViewChild, WritableSignal, signal, OnInit, OnDestroy } from '@angular/core';
import { ReactiveFormsModule, FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable, Subscription, take } from 'rxjs';
import { CommonModule } from '@angular/common';

// 3rd Party
import { DataManager, Query, ReturnOption } from '@syncfusion/ej2-data';
import { createSpinner } from '@syncfusion/ej2-angular-popups';
import { showSpinner, hideSpinner } from '@syncfusion/ej2-popups';
import { DropDownListAllModule, MultiSelectAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { TextBoxModule, MaskedTextBoxModule, NumericTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { SwitchModule, ButtonModule, RadioButtonModule } from '@syncfusion/ej2-angular-buttons';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { LoadingModule } from '@modules/loading.module';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { states, languages, caseFileTimeFrame, countryCodes, fileIntakeStatuses, USDateformat } from '@models/global-vars';
import { IntakeSurvey, LawFirm, Patient, Phone, Address, Contact } from '@models/data-contracts';
import { GlobalsService } from '@services/globals/globals.service';
import { ApiService } from '@services/api/api.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { GridTemplateComponent } from '@grids/grid-template/grid-template.component';
import { BasicGridComponent } from '@shared/components/basic-grid/basic-grid.component';

@Component({
  selector: 'intake-survey-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    DropDownListAllModule,
    MultiSelectAllModule,
    TextBoxModule,
    MaskedTextBoxModule,
    NumericTextBoxModule,
    SwitchModule,
    ButtonModule,
    RadioButtonModule,
    DatePickerModule,
    GridTemplateComponent,
    LoadingModule,
    CommonModule,
    BasicGridComponent
  ],
  templateUrl: './intake-survey-form.component.html',
  styleUrl: './intake-survey-form.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class IntakeSurveyFormComponent implements OnInit, OnDestroy {
  // Properties
  @Input() intakeId: Observable<number>;
  @Output() values = new EventEmitter<any>(); 
  
  public intakeSurveyForm: WritableSignal<FormGroup>;
  public currentIntake: any;
  public hasMatchingCases = signal(false);
  public matchingCasesGrid: any;
  public attachNewPatientToggle = false;

  private intakeIdSubscription: Subscription;

  contacts: DataManager;
  patients: any[];
  lawFirms: any[];
  states = states;
  languages = languages;
  countryCodes = countryCodes;
  pAndLs: any[];
  accidentInfo: any[];
  accidentDetails: any[];
  timeline: any[];
  intakeStatuses = fileIntakeStatuses;
  showActionButtons = true;
  loading: WritableSignal<boolean> = signal(true);
  endpoint: string = APIEndpoints.IntakeSurveys;

  constructor(
    private api: ApiService,
    private toast: ToastMessageService,
    private globals: GlobalsService
  ) {}

  // Lifecycle Hooks
  ngOnInit() {
    this.endpoint = `${APIEndpoints.IntakeSurveys}(${this.intakeId.toString()})`;
    this.initializeForm();
    this.setupSubscriptions();
  }

  ngAfterViewInit() {
    this.initializeSpinner();
  }

  ngOnDestroy() {
    this.intakeIdSubscription?.unsubscribe();
  }

  // Form Initialization
  private initializeForm() {
    this.intakeSurveyForm = signal(new FormGroup({
      // Basic Info
      LawfirmId: new FormControl<IntakeSurvey['LawfirmId']>(undefined),
      LawfirmContactId: new FormControl<IntakeSurvey['LawfirmContactId']>(undefined),
      FileNumber: new FormControl<IntakeSurvey['FileNumber']>(undefined),
      Status: new FormControl<IntakeSurvey['Status']>(undefined),

      // Patient Info
      PatientId: new FormControl<IntakeSurvey['PatientId']>(undefined),
      PatientCheckpointEligible: new FormControl<IntakeSurvey['PatientCheckpointEligible']>(undefined),
      Patient: new FormGroup({
        Firstname: new FormControl(''),
        Lastname: new FormControl(''),
        Dob: new FormControl(null),
        Language: new FormControl(''),
        Address: new FormGroup({
          AddressType: new FormControl(2),
          ObjectType: new FormControl(''),
          Address1: new FormControl(''),
          Address2: new FormControl(''),
          City: new FormControl(''),
          State: new FormControl(0),
          Zip: new FormControl('')
        })
      }),

      // Claim Information
      ClaimDateOfLoss: new FormControl<IntakeSurvey['ClaimDateOfLoss']>(undefined),
      ClaimStatuteOfLimitations: new FormControl<IntakeSurvey['ClaimStatuteOfLimitations']>(undefined),
      ClaimAccidentInfo: new FormControl<IntakeSurvey['ClaimAccidentInfo']>(undefined),
      ClaimOtherAccidentInfo: new FormControl<IntakeSurvey['ClaimOtherAccidentInfo']>(undefined),
      ClaimAdditionalAccidentInfo: new FormControl<IntakeSurvey['ClaimAdditionalAccidentInfo']>(undefined),
      
      // Insurance Information
      InsuranceLiabilityPolicy: new FormControl<IntakeSurvey['InsuranceLiabilityPolicy']>(undefined),
      InsuranceLiabilityPolicyLimit: new FormControl<IntakeSurvey['InsuranceLiabilityPolicyLimit']>(undefined),
      InsuranceUmPolicy: new FormControl<IntakeSurvey['InsuranceUmPolicy']>(undefined),
      InsuranceUmPolicyType: new FormControl<IntakeSurvey['InsuranceUmPolicyType']>(undefined),
      InsuranceUmPolicyLimit: new FormControl<IntakeSurvey['InsuranceUmPolicyLimit']>(undefined),
      InsuranceMedpayPolicy: new FormControl<IntakeSurvey['InsuranceMedpayPolicy']>(undefined),
      InsuranceMedpayPolicyLimit: new FormControl<IntakeSurvey['InsuranceMedpayPolicyLimit']>(undefined),
      
      // Additional Information
      DepositToAccount: new FormControl<IntakeSurvey['DepositToAccount']>(undefined),
      LawfirmClaimFileNumber: new FormControl<IntakeSurvey['LawfirmClaimFileNumber']>(undefined),
      ClaimLiabilityDispute: new FormControl<IntakeSurvey['ClaimLiabilityDispute']>(undefined),
      ClaimPropertyDamage: new FormControl<IntakeSurvey['ClaimPropertyDamage']>(undefined),
      ClaimSpecialDamage: new FormControl<IntakeSurvey['ClaimSpecialDamage']>(undefined),
      InsuranceSettleTerm: new FormControl<IntakeSurvey['InsuranceSettleTerm']>(undefined),
      PreviousAttorneyRepresentation: new FormControl<IntakeSurvey['PreviousAttorneyRepresentation']>(undefined),
      PreviousFunding: new FormControl<IntakeSurvey['PreviousFunding']>(undefined),
      Attachments: new FormControl<IntakeSurvey['Attachments']>(undefined),
      AssistanceType: new FormControl<IntakeSurvey['AssistanceType']>(undefined),
      RequestedTreatment: new FormControl<IntakeSurvey['RequestedTreatment']>(undefined),
      InjuryInfo: new FormControl<IntakeSurvey['InjuryInfo']>(undefined),
      TreatmentGap: new FormControl<IntakeSurvey['TreatmentGap']>(undefined),
      PreExistingCondition: new FormControl<IntakeSurvey['PreExistingCondition']>(undefined),
      HasHealthInsurance: new FormControl<IntakeSurvey['HasHealthInsurance']>(undefined),
      Essay: new FormControl<IntakeSurvey['Essay']>(undefined),
      ProviderContactId: new FormControl<IntakeSurvey['ProviderContactId']>(undefined)
    }));
  }

  private setupSubscriptions() {
    this.intakeIdSubscription = this.intakeId.subscribe(value => {
      this.endpoint = `${APIEndpoints.IntakeSurveys}(${value.toString()})`;
      this.getValues(value);
    });
  }

  // Data Handling
  private async getValues(intakeId: number) {
    this.loading.set(true);
    if (intakeId > 0) {
      try {
        const response = await this.api
          .getOdata(`${APIEndpoints.IntakeSurveys}(${intakeId})?expand=Patient($expand=Address),Lawfirm,LawfirmContact`)
          .executeQuery(new Query());

        if (response) {
          const formData = (response as any).result[0];
          this.currentIntake = formData;

          // Remove navigation properties
          delete formData.Lawfirm;
          delete formData.LawfirmContact;
          delete formData.Provider;
          delete formData.ProviderContact;
          
          // Handle dates
          if (formData.ClaimDateOfLoss) {
            formData.ClaimDateOfLoss = new Date(formData.ClaimDateOfLoss);
          }
          
          // Reset form and patch with new values
          this.onSubmit(intakeId);

          // Only fetch related cases if we have all required fields
          if (this.currentIntake?.ClaimDateOfLoss && 
              this.currentIntake?.Patient?.Id && 
              this.currentIntake?.Lawfirm?.Id) {
              
              try {
                const relatedResponse = await this.api
                  .getOdata(`${APIEndpoints.IntakeSurveys}(${intakeId})/related`)
                  .executeQuery(new Query());
                
                const result = (relatedResponse as any).result;
                if (result?.length > 0) {
                  this.hasMatchingCases.set(true);
                  this.matchingCasesGrid = { 
                    dataSource: new DataManager(result),
                    columns: [
                      { field: 'FileNumber', headerText: 'File Number' },
                      { field: 'PatientId', headerText: 'Patient' },
                      { field: 'DateOfLoss', headerText: 'DOL', ...USDateformat },
                      { field: 'FileOpened', headerText: 'Case Opened', allowEditing: false, ...USDateformat },
                      { field: 'LawFirm.Name', headerText: 'Law Firm' },
                    ]
                  };
                }
              } catch (error) {
                console.error('Error fetching related cases:', error);
              }
          }
        }
      } catch (error) {
        const errorMessage = await this.handleApiError(error, 'fetching intake survey');
        this.toast.showError(errorMessage);
      } finally {
        this.loading.set(false);
      }
    } else {
      this.loading.set(false);
    }
  }

  // Error Handling
  private async handleApiError(error: any, operation: string): Promise<string> {
    console.error(`Error ${operation}:`, error);
    
    let errorMessage = `An error occurred while ${operation}.`;
    
    if (error.error) {
      if (typeof error.error === 'string') {
        errorMessage = error.error;
      } else if (error.error.message) {
        errorMessage = error.error.message;
      }
    }
    
    // Log the error for debugging
    console.error('Detailed error:', {
      message: errorMessage,
      error: error,
      operation: operation
    });
    
    return errorMessage;
  }

  // Form Operations
  public async onSubmit(intakeId: number) {
    this.globals.markControlsTouched(this.intakeSurveyForm());

    if (this.intakeSurveyForm().valid) {
      const formValues = this.intakeSurveyForm().value;
      
      // Extract patient data and remove nested Patient object
      const { Patient, ...restFormData } = formValues;
      
      const formData = { 
        ...restFormData,
        Id: intakeId,
        Status: formValues.Status || 'New',
        UpdatedAt: new Date(),
        PatientId: formValues.PatientId || Patient?.Id
      };

      // Validate form data
      if (!this.validateFormData(formData)) {
        return;
      }

      try {
        let response;

        if (intakeId) {
          // Update existing intake
          response = await this.api.patchOdata(
            `${APIEndpoints.IntakeSurveys}(${intakeId})`,
            formData
          );
        } else {
          // Create new intake
          formData.CreatedAt = new Date();
          response = await this.api.postOdata(APIEndpoints.IntakeSurveys, formData);
        }

        if (response.ok) {
          this.toast.showSuccess('Successfully saved intake survey!');
          this.emitValues();
        } else {
          const errorMessage = await this.handleApiError(response, 'saving intake survey');
          this.toast.showError(errorMessage);
        }
      } catch (error) {
        const errorMessage = await this.handleApiError(error, 'processing form submission');
        this.toast.showError(errorMessage);
        throw error; // Re-throw to be caught by the caller
      }
    } else {
      this.toast.showError('Please fill in all required fields.');
      throw new Error('Form invalid');
    }
  }

  public emitValues() {
    this.values.emit(this.intakeSurveyForm().value);
  }

  public clearForm() {
    this.intakeSurveyForm().reset();
  }

  private validateFormData(data: any): boolean {
    // Validate dates
    if (data.ClaimDateOfLoss) {
      const inputDate = new Date(data.ClaimDateOfLoss);
      if (isNaN(inputDate.getTime())) {
        this.toast.showError('Invalid date format for Claim Date of Loss');
        return false;
      }
      if (inputDate > new Date()) {
        this.toast.showError('Claim Date of Loss cannot be in the future');
        return false;
      }
    }

    // Add other validations as needed
    return true;
  }

  // UI Operations
  public toggleNewPatient(args: any) {
    this.attachNewPatientToggle = args.checked;
  }

  private initializeSpinner() {
    createSpinner({
      target: document.getElementById('intake-survey-form') as any
    });
    showSpinner((document as any).getElementById('intake-survey-form'));
  }
}
