// Angular
import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

// 3rd Party
import { Query } from '@syncfusion/ej2-data';

// Models
import { APIEndpoints } from '@root/src/app/shared/models/api/Endpoints';
import { Patient, XrefPhonePatient, Address, XrefAddressPatient } from '@models/data-contracts';

// Services
import { ApiService } from '@root/src/app/shared/services/api/api.service';

// Interfaces
interface PatientDetail {
  label: string;
  value: any;
}

interface PatientAddress {
  Address1?: string;
  Address2?: string;
  City?: string;
  StateNavigation?: {
    Name?: string;
  };
  Zip?: string;
}

interface PatientPhone {
  PhoneNumber?: string;
}

interface PatientResponse {
  result: Patient[];
}

interface PatientGridItem extends Omit<Patient, 'XrefAddressPatients'> {
  TotalFiles: number;
  PrimaryPhone: string;
  XrefAddressPatients: {
    Address: Address;
  };
}

@Injectable({
  providedIn: 'root',
  deps: [DatePipe]
})
export class PatientsService {
  private readonly detailQuery = new Query().expand('XrefPhonePatients($expand=Phone),XrefAddressPatients($expand=Address($expand=StateNavigation))');

  private readonly gridQuery = new Query()
    .expand('XrefAddressPatients($expand=Address($expand=StateNavigation($select=Id,Name);$select=Id,Address1,City,State,Zip)),CaseFiles($select=Id),XrefPhonePatients($expand=Phone($select=Id,PhoneNumber,PhoneType))')
    .select(['Id', 'Firstname', 'Lastname', 'Dob', 'Gender', 'Language', 'Email', 'Minor', 'XrefAddressPatients', 'XrefPhonePatients'])
    .requiresCount();

  constructor(
    private api: ApiService,
    private datePipe: DatePipe
  ) {}

  getPatientsGridQuery(): Query {
    return this.gridQuery;
  }

  transformPatientsForGrid(data: Patient[]): PatientGridItem[] {
    return data.map((patient: Patient) => {
      const address = patient.XrefAddressPatients?.find((x: XrefAddressPatient) => x.Address?.AddressType === 2)?.Address || patient.XrefAddressPatients?.[0]?.Address || {};
      const primaryPhone = patient.XrefPhonePatients?.find((x: XrefPhonePatient) => x.Phone?.PhoneType === 1)?.Phone || patient.XrefPhonePatients?.[0]?.Phone;

      return {
        ...patient,
        TotalFiles: patient.CaseFiles?.length ?? 0,
        XrefAddressPatients: { Address: address },
        PrimaryPhone: primaryPhone?.PhoneNumber || ''
      } as PatientGridItem;
    });
  }

  async updatePatient(id: number, data: Partial<Patient>): Promise<Patient> {
    try {
      const response = await this.api.patchOdata(`${APIEndpoints.Patients}/${id}`, data);
      return response as unknown as Patient;
    } catch (error) {
      console.error('Error updating patient:', error);
      throw error;
    }
  }

  async createPatient(data: Partial<Patient>): Promise<Patient> {
    try {
      const response = await this.api.postOdata(APIEndpoints.Patients, data);
      return response as unknown as Patient;
    } catch (error) {
      console.error('Error creating patient:', error);
      throw error;
    }
  }

  async getPatientById(id: number): Promise<Patient> {
    try {
      const response = await this.api.getOdata(`${APIEndpoints.Patients}(${id})`).executeQuery(this.detailQuery);
      const typedResponse = response as unknown as PatientResponse;
      return typedResponse.result[0];
    } catch (error) {
      console.error('Error fetching patient:', error);
      throw error;
    }
  }

  transformPatientData(patientData: Patient): {
    expandableDetails: PatientDetail[];
    patientDetails: PatientDetail[][];
  } {
    const address = patientData?.XrefAddressPatients?.[0]?.Address ?? {} as PatientAddress;
    const stateName = address.StateNavigation?.Name || 'N/A';
    const phoneNumber = (patientData?.XrefPhonePatients?.[0] as XrefPhonePatient)?.Phone?.PhoneNumber || 'N/A';
    const patientName = `${patientData?.Firstname || 'N/A'} ${patientData?.Lastname || 'N/A'}`;

    const expandableDetails: PatientDetail[] = [
      { label: 'Name', value: patientName },
      { label: 'Phone', value: phoneNumber },
      { label: 'City', value: address.City ?? 'N/A' },
    ];

    const patientDetails: PatientDetail[][] = [
      [
        { label: 'Name', value: patientName },
        { label: 'Main Phone', value: phoneNumber },
        { label: 'Created At', value: this.datePipe.transform(patientData?.CreatedAt, 'MM/dd/yyyy') ?? 'N/A' },
        { label: 'Modified At', value: this.datePipe.transform(patientData?.UpdatedAt, 'MM/dd/yyyy') ?? 'N/A' }
      ],
      [
        { label: 'Address', value: `${address.Address1 ?? ''}, ${address.Address2 ?? ''}`.trim() },
        { label: 'City', value: address.City ?? 'N/A' },
        { label: 'State', value: stateName },
        { label: 'Zip', value: address.Zip ?? 'N/A' },
      ],
      [
        { label: 'DOB', value: patientData?.Dob ?? 'N/A' },
        { label: 'Gender', value: patientData?.Gender ?? 'N/A' },
        { label: 'Language', value: patientData?.Language ?? 'N/A' },
        { label: 'Email', value: patientData?.Email ?? 'N/A' },
        { label: 'Minor', value: patientData?.Minor ?? 'N/A' },
        { label: 'Is Active', value: patientData?.IsActive ?? 'N/A' },
      ]
    ];

    return {
      expandableDetails,
      patientDetails
    };
  }
}
