// Angular
import { Component, ViewChild } from '@angular/core';

// 3rd Party
import { TabAnimationSettingsModel } from '@syncfusion/ej2-angular-navigations';
import { TabAllModule, TabItemModel } from '@syncfusion/ej2-angular-navigations';

// Internal
import { ProfileGeneralComponent } from "./profile-general/profile-general.component";
import { ProfileAppearanceComponent } from './profile-appearance/profile-appearance.component';
import { UserPreferencesService } from '@services/user/user-preferences.service';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [
    TabAllModule,
    ProfileGeneralComponent,
    ProfileAppearanceComponent
  ],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss'
})
export class ProfileComponent {

  constructor(
    private userPreferences: UserPreferencesService
  ) { }

  @ViewChild('generalSettings', { static: true }) generalSettings!: string;
  @ViewChild('appearanceSettings', { static: true }) appearanceSettings!: string;
  profileTabs: TabItemModel[]
  animationTime: number = 500

  animationSettings: TabAnimationSettingsModel = {
    previous: { effect: 'SlideLeftIn', duration: this.animationTime },
    next: { effect: 'SlideRightIn', duration: this.animationTime }
  };
  public isTransitioning: boolean = false;

  ngOnInit() {
    this.profileTabs = [
      { header: { text: 'General', iconCss: 'e-icons e-edit' }, content: this.generalSettings },
      { header: { text: 'Appearance', iconCss: 'e-icons e-style' }, content: this.appearanceSettings }
    ];
  }

  handleSaveUserPreferencesClick() {
    this.userPreferences.patchUserPreferences();
  }

  onTabSelecting(args: any): void {
    if (this.isTransitioning) {
      args.cancel = true;
    } else {
      this.isTransitioning = true;
    }
  }

  // Called after the tab selection is complete
  onTabSelected(args: any): void {
    setTimeout(() => { this.isTransitioning = false; }, this.animationTime);
  }

}
