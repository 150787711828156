export const getHttpHandlerExtensionConfiguration = runtimeConfig => {
  return {
    setHttpHandler(handler) {
      runtimeConfig.httpHandler = handler;
    },
    httpHandler() {
      return runtimeConfig.httpHandler;
    },
    updateHttpClientConfig(key, value) {
      runtimeConfig.httpHandler?.updateHttpClientConfig(key, value);
    },
    httpHandlerConfigs() {
      return runtimeConfig.httpHandler.httpHandlerConfigs();
    }
  };
};
export const resolveHttpHandlerRuntimeConfig = httpHandlerExtensionConfiguration => {
  return {
    httpHandler: httpHandlerExtensionConfiguration.httpHandler()
  };
};