import { Injectable, computed, signal } from '@angular/core';
import { ApiService } from '@services/api/api.service';
import { APIEndpoints } from '@models/api/Endpoints';
import { Query, Predicate, DataManager } from '@syncfusion/ej2-data';
import { Address, LawFirm, Phone, User, XrefUsersRole } from '@models/data-contracts';
import { ErrorHandlingService } from '@core/error/error.service';
import { ErrorSeverity } from '@core/error/error.types';
import { LawFirmsGridService } from './law-firms-grid.service';

export interface LawFirmFormState {
  lawFirm?: LawFirm;
  address?: Address;
  phone?: Phone;
  loading: boolean;
  submitting: boolean;
  loadingMessage: string;
  marketManagers: User[];
  caseManagers: User[];
}

@Injectable({
  providedIn: 'root',
})
export class LawFirmFormService {
  // State signals
  private state = signal<LawFirmFormState>({
    lawFirm: undefined,
    address: undefined,
    phone: undefined,
    loading: false,
    submitting: false,
    loadingMessage: '',
    marketManagers: [],
    caseManagers: [],
  });

  // Computed values
  readonly address = computed(() => this.state().address);
  readonly phone = computed(() => this.state().phone);
  readonly loading = computed(() => this.state().loading);
  readonly submitting = computed(() => this.state().submitting);
  readonly loadingMessage = computed(() => this.state().loadingMessage);
  readonly marketManagers = computed(() => this.state().marketManagers);
  readonly caseManagers = computed(() => this.state().caseManagers);

  constructor(
    private api: ApiService,
    private errorHandler: ErrorHandlingService,
    private lawFirmsGridService: LawFirmsGridService
  ) {}

  async loadFormData(lawFirmId?: number): Promise<void> {
    this.setLoading(true, 'Loading form data...');

    try {
      // Load managers if needed
      await this.loadUsers();

      if (lawFirmId) {
        await Promise.all([this.loadLawFirmAddress(lawFirmId), this.loadLawFirmPhone(lawFirmId)]);
      }
    } catch (error) {
      this.errorHandler.handleError(error, {
        context: 'LawFirmFormService.loadFormData',
        userMessage: 'Failed to load form data',
        severity: ErrorSeverity.Error,
      });
      throw error;
    } finally {
      this.setLoading(false);
    }
  }

  async loadUsers(): Promise<void> {
    try {
      const usersQuery = new Query()
        .expand(['XrefUsersRoles($select=RoleId)'])
        .select(['Id', 'Name', 'XrefUsersRoles'])
        .where('XrefUsersRoles/any(x: x/RoleId eq 4 or x/RoleId eq 2 or x/RoleId eq 3)', 'equal', true);

      const response = (await this.api.getOdata(APIEndpoints.Users).executeQuery(usersQuery)) as any;
      const users = response.result;

      const marketManagers = users.filter((user: User) =>
        user.XrefUsersRoles?.some(xRef => xRef.RoleId === 4 || xRef.RoleId === 2)
      );
      const caseManagers = users.filter((user: User) =>
        user.XrefUsersRoles?.some(xRef => xRef.RoleId === 3 || xRef.RoleId === 2)
      );

      this.state.update(s => ({
        ...s,
        marketManagers,
        caseManagers,
      }));
    } catch (error) {
      this.errorHandler.handleError(error, {
        context: 'LawFirmsGridService.loadUsers',
        userMessage: 'Failed to load users',
        severity: ErrorSeverity.Error,
      });
      throw error;
    }
  }

  private async loadLawFirmAddress(lawFirmId: number): Promise<void> {
    const query = new Query().expand(['Address($expand=StateNavigation)']).where('LawFirm/Id', 'equal', lawFirmId);
    const response = (await this.api.getOdata(APIEndpoints.XrefAddressLawfirms).executeQuery(query)) as any;
    const address = response.result[0]?.Address;
    this.state.update(s => ({
      ...s,
      address,
    }));
  }

  private async loadLawFirmPhone(lawFirmId: number): Promise<void> {
    const query = new Query().expand(['Phone']).where('LawFirm/Id', 'equal', lawFirmId);
    const response = (await this.api.getOdata(APIEndpoints.XrefPhoneLawfirms).executeQuery(query)) as any;
    const phone = response.result[0]?.Phone;
    this.state.update(s => ({
      ...s,
      phone,
    }));
  }

  async submitForm(formData: any, address: any, phone: any): Promise<boolean> {
    this.setLoading(true, 'Submitting form...');

    try {
      const [lawFirmResult, addressResult, phoneResult] = await Promise.all([
        this.submitLawFirm(formData),
        this.submitAddress(address),
        this.submitPhone(phone),
      ]);

      if (lawFirmResult.Id) {
        await Promise.all([
          this.linkAddress(lawFirmResult.Id, addressResult.Id),
          this.linkPhone(lawFirmResult.Id, phoneResult.Id),
        ]);
      }

      return true;
    } catch (error) {
      this.errorHandler.handleError(error, {
        context: 'LawFirmFormService.submitForm',
        userMessage: 'Failed to submit form',
        severity: ErrorSeverity.Error,
      });
      return false;
    } finally {
      this.setLoading(false);
    }
  }

  private setLoading(loading: boolean, message: string = '') {
    this.state.update(s => ({
      ...s,
      loading,
      loadingMessage: message,
    }));
  }

  private async submitLawFirm(data: any) {
    return this.api.fetchRequest(`odata${APIEndpoints.Lawfirms}`, data.Id ? 'PATCH' : 'POST', data);
  }

  private async submitAddress(data: any) {
    return this.api.fetchRequest(`odata${APIEndpoints.Addresses}`, data.Id ? 'PATCH' : 'POST', data);
  }

  private async submitPhone(data: any) {
    return this.api.fetchRequest(`odata${APIEndpoints.Phones}`, data.Id ? 'PATCH' : 'POST', data);
  }

  private async linkAddress(lawFirmId: number, addressId: number) {
    return this.api.fetchRequest(`odata${APIEndpoints.XrefAddressLawfirms}`, 'POST', {
      AddressId: addressId,
      LawfirmId: lawFirmId,
    });
  }

  private async linkPhone(lawFirmId: number, phoneId: number) {
    return this.api.fetchRequest(`odata${APIEndpoints.XrefPhoneLawfirms}`, 'POST', {
      PhoneId: phoneId,
      LawfirmId: lawFirmId,
    });
  }
}
