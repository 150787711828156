// Angular
import { Component, computed, effect, ElementRef, EventEmitter, HostListener, Input, Output, Signal, signal, ViewChild, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

// 3rd Party
import { ButtonModule, CheckBoxAllModule } from '@syncfusion/ej2-angular-buttons';
import { TextBoxAllModule, MaskedTextBoxAllModule } from '@syncfusion/ej2-angular-inputs';
import { DatePickerAllModule, DatePickerComponent, FocusEventArgs } from '@syncfusion/ej2-angular-calendars';
import { DropDownListAllModule, FilteringEventArgs, MultiSelectAllModule, MultiSelectComponent } from '@syncfusion/ej2-angular-dropdowns';
import { TooltipAllModule } from '@syncfusion/ej2-angular-popups';
import { Query, Predicate } from '@syncfusion/ej2-data';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faNotesMedical, faHospitalUser, faFolderOpen, faMinus, faLink, faCalendarXmark, faToggleOff, faLayerGroup, faUsersRectangle, faCalendarDays,
  faUser, faUserDoctor, faUserPlus, faBuilding, faFileInvoiceDollar, faUserInjured, faToggleOn, faStethoscope, faClock, faUserShield, faStar,
  faFileSignature, faFileLines, faGavel, faHashtag, faCheckSquare, faComments, faIdCard, faUserTag, faChartLine,
  faBarsProgress,
  faHouseMedicalCircleXmark,
  faBriefcaseMedical,
  faBalanceScale,
  faEye,
  faEyeSlash
} from '@fortawesome/free-solid-svg-icons';

// Models
import {  CaseFile, Patient } from '@models/data-contracts';
import { APIEndpoints } from '@models/api/Endpoints';

// Services
import { FormCrudService } from '@services/forms/form-crud.service';
import { ApiService } from '@services/api/api.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';

// Components
import { PatientFormComponent } from '@forms/patient-form/patient-form.component';
import { CommentThreadsGridComponent } from '@shared/features/comments/components/threads/comment-threads-grid/comment-threads-grid.component';

type CaseFileFormControls = {
  [K in keyof CaseFile]: FormControl<CaseFile[K] | null | undefined>;
};

export class FormWarning {
  constructor(public message: string, public type: 'warning' | 'info' = 'warning') {}
}

@Component({
  selector: 'case-file-form-component',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    TextBoxAllModule,
    MaskedTextBoxAllModule,
    DropDownListAllModule,
    MultiSelectAllModule,
    DatePickerAllModule,
    TooltipAllModule,
    CheckBoxAllModule,
    FontAwesomeModule,
    PatientFormComponent,
    CommentThreadsGridComponent
  ],
  templateUrl: './case-file-form.component.html',
  styleUrl: './case-file-form.component.scss'
})
export class CaseFileFormComponent {

  // Constructor
  constructor(
    private formCRUD: FormCrudService,
    private api: ApiService,
    private toast: ToastMessageService
  ) {
    // Ensure caseFile signal exists
    this.ensureCaseFileSignal();

    // Update form values
    effect(() => {
      const { currentValue } = this.formState();
      if (currentValue) {
        this.caseFileForm.patchValue(currentValue);
        this.getPatient();
      } else {
        this.resetForms();
      }
    });

    // Initialize all dropdowns
    this.initializeDropdownData();
  }

  // Decorator variabls
  @Input() isLawFirm?: boolean;
  @Input() caseFile!: WritableSignal<CaseFile | undefined>;
  @Input() submitType?: 'POST' | 'PATCH' | 'DELETE';
  @Input() displayButtons: boolean = true;
  @Input() formMainTitle?: string;
  @Input() displayMainTitle = true;
  @Input() displayPatientsSection = true;
  @Input() displayFields: string[] = [
    'Id', 'CaseType', 'CaseTypeId', 'Comments', 'DateOfLoss', 'ExcludeFromBalanceDue', 'XrefCaseFileCompanionCaseCaseFiles',
    'FileGroupId', 'FileNumber', 'FileOpened', 'FileRating', 'FileSecuredBy', 'InitialTimeFrame', 'IsActive', 'IsSurgical', 'LawFirmContact',
    'LawFirmFileNumber', 'LawFirmId', 'MarketManager', 'CaseManager', 'PAndL', 'PatientId', 'ReferralSource', 'ReferringPhysician', 'ResponsibleParty', 'Statuser',
    'StatuteOfLimitations', 'StatusingGroup'];
  @Output() formSubmitted = new EventEmitter<void>();
  @Output() formCancelled = new EventEmitter<void>();
  @ViewChild('caseFileFormContainer') caseFileFormContainer: ElementRef;
  @ViewChild('patientFormComp') patientFormComp!: PatientFormComponent;

  // State variables
  protected readonly formState = computed(() => {
    const currentValue = this.caseFile?.() ?? undefined;
    const formValue = this.caseFileForm.value;
    
    return {
      isValid: this.caseFileForm.valid,
      hasChanges: JSON.stringify(currentValue) !== JSON.stringify(formValue),
      currentValue: currentValue
    };
  });
  protected readonly errorMessages = computed(() => {
    const errors: Record<string, string> = {};
    
    // Get case file form errors
    Object.keys(this.caseFileForm.controls).forEach(key => {
        const control = this.caseFileForm.get(key);
        if (control?.errors) errors[`${key}`] = this.getErrorMessage(key);
    });

    // Only check sub-forms if they exist and have been touched
    if (this.patientFormComp?.patientForm?.touched) {
      Object.keys(this.patientFormComp.patientForm.controls).forEach(key => {
        const control = this.patientFormComp.patientForm.get(key);
        if (control?.errors && control.touched) {
          errors[`${key}`] = this.patientFormComp.getErrorMessage(key);
        }
      });

      if (this.patientFormComp.addressFormComp?.addressForm?.touched) {
        Object.keys(this.patientFormComp.addressFormComp.addressForm.controls).forEach(key => {
          const control = this.patientFormComp.addressFormComp.addressForm.get(key);
          if (control?.errors && control.touched) {
            errors[`${key}`] = this.patientFormComp.addressFormComp.getErrorMessage(key);
          }
        });
      }
    }

    return errors;
  });

  // Public variables
  patientData: WritableSignal<Patient | undefined> = signal(undefined);
  loadingForm: boolean = true;
  caseFileHTMLElement: Element | null = null;
  errorMessage: string = '';
  recordStatuses: any;
  recordStatusesQuery: Query;
  contacts: any;
  caseManagers: any;
  caseManagersQuery: Query;
  marketManagers: any;
  marketManagersQuery: Query;
  pAndL: any;
  pAndLQuery: Query;
  referralSources: any;
  referralSourcesQuery: Query;
  referringPhysicians: any;
  referringPhysiciansQuery: Query;
  initialTimeFrames: any;
  statusers: any;
  statusersQuery: Query;
  statusingGroups: any;
  statusingGroupsQuery: Query;
  caseTypes: any;
  caseTypesQuery: Query;
  users: any;
  usersQuery: Query;
  fileGroups: any;
  fileGroupsQuery: Query;
  companionCases: any;
  companionCasesQuery: Query;
  lawFirms: any;
  lawFirmsQuery: Query;
  lawFirmContacts: any;
  lawFirmContactsQuery: Query;
  attorneys: any;
  attorneysQuery: Query;
  patients: any;
  patientFormVisibility: boolean = false;
  addPatientsButtonText: string = 'Show Patient Info';
  caseFileForm = new FormGroup<CaseFileFormControls>({
    Id: new FormControl<CaseFile['Id']>(undefined),
    Attorney: new FormControl<CaseFile['Attorney']>(undefined),
    CaseManager: new FormControl<CaseFile['CaseManager']>(undefined),
    CaseType: new FormControl<CaseFile['CaseType']>(undefined),
    CaseTypeId: new FormControl<CaseFile['CaseTypeId']>(1),
    ClientHasCheckpointLimitations: new FormControl<CaseFile['ClientHasCheckpointLimitations']>(undefined),
    Comments: new FormControl<CaseFile['Comments']>(undefined),
    DateOfLoss: new FormControl<CaseFile['DateOfLoss']>(undefined, [Validators.required]),
    ExcludeFromBalanceDue: new FormControl<CaseFile['ExcludeFromBalanceDue']>(undefined),
    FileGroupId: new FormControl<CaseFile['FileGroupId']>(undefined),
    FileOpened: new FormControl<CaseFile['FileOpened']>(undefined, [Validators.required]),
    FileSecuredBy: new FormControl<CaseFile['FileSecuredBy']>(undefined),
    InitialTimeFrame: new FormControl<CaseFile['InitialTimeFrame']>(undefined),
    InTreatment: new FormControl<CaseFile['InTreatment']>(true),
    IsSurgical: new FormControl<CaseFile['IsSurgical']>(undefined),
    LawFirmContact: new FormControl<CaseFile['LawFirmContact']>(undefined),
    LawFirmFileNumber: new FormControl<CaseFile['LawFirmFileNumber']>(undefined),
    LawFirmId: new FormControl<CaseFile['LawFirmId']>(undefined, [Validators.required]),
    MarketManager: new FormControl<CaseFile['MarketManager']>(undefined),
    PAndL: new FormControl<CaseFile['PAndL']>(undefined, [Validators.required]),
    PatientId: new FormControl<CaseFile['PatientId']>(undefined),
    ReferralSource: new FormControl<CaseFile['ReferralSource']>(undefined),
    ReferringPhysician: new FormControl<CaseFile['ReferringPhysician']>(undefined),
    ResponsibleParty: new FormControl<CaseFile['ResponsibleParty']>(undefined),
    Statuser: new FormControl<CaseFile['Statuser']>(undefined),
    StatuteOfLimitations: new FormControl<CaseFile['StatuteOfLimitations']>(undefined),
    StatusingGroup: new FormControl<CaseFile['StatusingGroup']>(undefined),
    RecordStatusId: new FormControl<CaseFile['RecordStatusId']>(1),
    XrefCaseFileCompanionCaseCaseFiles: new FormControl<any>(undefined)
  });
  caseFileIcons = {
    form: faNotesMedical,
    caseFile: faBriefcaseMedical,
    default: faMinus,
    patient: faHospitalUser,
    fileOpened: faFolderOpen,
    recordStatus: faBarsProgress,
    caseManager: faIdCard,
    marketManager: faUserTag,
    pAndL: faChartLine,
    referringPhysician: faUserDoctor,
    responsibleParty: faUser,
    statuteOfLimitations: faCalendarDays,
    statuser: faUser,
    statusingGroup: faUsersRectangle,
    attorney: faGavel,
    caseNumber: faHashtag,
    caseType: faFileLines,
    clientHasCheckpointLimitations: faUserShield,
    comments: faComments,
    companionCases: faLink,
    dateOfLoss: faCalendarXmark,
    excludeFromBalanceDue: faHouseMedicalCircleXmark,
    fileGroupId: faLayerGroup,
    fileNumber: faHashtag,
    fileRating: faStar,
    fileSecuredBy: faUserShield,
    initialTimeFrame: faClock,
    inTreatment: faStethoscope,
    active: faToggleOn,
    isSurgical: faUserInjured,
    lawFirmContact: faBuilding,
    lawFirmFileNumber: faFileInvoiceDollar,
    lawFirmId: faBuilding,
    patientId: faUserPlus,
    referralSource: faHospitalUser,
    lawFirm: faBalanceScale
  }
  formClasses = {
    formContainer: 'cc-form-container',
    form: window.innerWidth <= 768 ? 'cc-form flex-column' : 'cc-form flex-row flex-wrap',
    mainTitle: 'cc-main-title',
    subForm: 'cc-sub-form',
    subFormTitle: 'cc-sub-form-title',
    section: 'cc-form-section',
    group: 'cc-form-group row',
    inputContainer: 'cc-input-container',
    label: 'cc-label',
    input: 'cc-input',
    floadInput: 'cc-float-input',
    icon: 'cc-input-icon',
    error: 'cc-input-error',
    actions: 'cc-form-actions',
    oneCol: 'col-12',
    twoCol: window.innerWidth <= 768 ? 'col-12' : 'col-6',
    threeCol: window.innerWidth <= 768 ? 'col-12' : window.innerWidth > 768 && window.innerWidth <= 992 ? 'col-6' : 'col-4'
  };

  // Add the icons to the component
  patientFormIcons = {
    show: faEye,
    hide: faEyeSlash
  };

  // Add back the loading status signal
  loadingStatus: WritableSignal<{loading: boolean, message: string}> = signal({
    loading: false,
    message: ''
  });

  // Lifecycle hooks
  ngOnInit() {
    this.caseFileForm.patchValue(this.caseFile() as Partial<CaseFile>);
  }

  ngAfterViewInit() {
    this.caseFileHTMLElement = this.caseFileFormContainer.nativeElement;
    this.updateWidth(this.caseFileHTMLElement?.clientWidth ?? window.innerWidth);
    this.watchInputElements();
  }

  // Returns appropraite error message for form control
  getErrorMessage(controlName: string): string {
    let message = '';
    const control = this.caseFileForm.get(controlName);
    if (control?.errors) {
      if (control.errors['required']) message = `${controlName} is required`;
      if (control.errors['email']) message = 'Invalid email format';
      if (control.errors['invalidPhone']) message = 'Invalid phone number (10 digits required)';
      if (control.errors['serverError']) message = control.errors['serverError'].message;
    }
    return message;
  }

  // Hit the API to create or update a case file
  async onSubmit() {
    const currentCaseFileId = this.caseFileForm.get('Id')?.value;
    const submitType = currentCaseFileId ? 'PATCH' : 'POST';
    const endpoint = currentCaseFileId ? `${APIEndpoints.Casefiles}/${currentCaseFileId}` : APIEndpoints.Casefiles;
    this.patientFormComp.errorMessage;
    this.patientFormComp.addressFormComp.errorMessage;

    try {
      this.loadingStatus.set({ loading: true, message: 'Validating form...' });
      console.log(this.loadingStatus().message);

      // Validate all forms before proceeding
      const formErrors = this.validateAllForms();
      if (formErrors) {
        return new Error(formErrors);
      }

      // Check for companion cases first
      const xrefCaseFileCompanionCases = this.caseFileForm.get('XrefCaseFileCompanionCaseCaseFiles')?.value;
      if (xrefCaseFileCompanionCases && xrefCaseFileCompanionCases.length > 0) {
        this.loadingStatus.set({ loading: true, message: 'Saving companion cases...' });
        console.log(this.loadingStatus().message);
        xrefCaseFileCompanionCases.forEach(async (companionCase: any) => {
          this.api.fetchRequest(`odata${APIEndpoints.XrefCaseFileCompanionCases}`, 'POST', { CaseFileId: this.caseFileForm.get('Id')?.value, CompanionCaseId: companionCase.Id });
        });
      }

      // Save patient information
      this.loadingStatus.set({ loading: true, message: 'Saving patient information...' });
      console.log(this.loadingStatus().message);
      const patientResult = await this.patientFormComp.onSubmit();
      if (patientResult instanceof Error) throw patientResult;
      if (!patientResult.Id) throw new Error('Failed to create patient');
      
      // Update PatientId
      this.caseFileForm.patchValue({ PatientId: patientResult.Id });

      // Submit case file
      delete this.caseFileForm.value.XrefCaseFileCompanionCaseCaseFiles; // Remove companion cases because this is handled via xref table
      this.loadingStatus.set({ loading: true, message: 'Saving case file...' });
      console.log(this.loadingStatus().message);
      if (!this.caseFileForm.get('RecordStatusId')?.value) this.caseFileForm.patchValue({ RecordStatusId: 1 });
      const submitResult = await this.formCRUD.submitForm(this.caseFileForm, `odata${endpoint}`, submitType);
      this.formSubmitted.emit();
      return submitResult;

    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An unexpected error occurred';
      return new Error(errorMessage);
    } finally {
      this.loadingStatus.set({ loading: false, message: '' });
    }
  }

  markAllFieldsAsTouched(): void {
    Object.values(this.caseFileForm.controls).forEach(control => {
      control.markAsTouched();
      control.markAsDirty();
      control.updateValueAndValidity();
    });
  }

  // Perform updates on input elements
  watchInputElements() {
    document.querySelectorAll(`.cc-input-container`).forEach((inputContainer) => {
      const container = inputContainer as HTMLElement;

      container.querySelectorAll('input, span, textarea').forEach((input) => {
        input.addEventListener('focus', () => inputContainer.classList.add('focus'));
        input.addEventListener('blur', () => inputContainer.classList.remove('focus'));
      });
    });
  }

  // Switches form to 2 columns when parent is larger 1600px
  updateWidth(containerWidth: number) {

    if (containerWidth <= 768) {
      this.formClasses.form = this.formClasses.form.replace('flex-column', 'flex-row flex-wrap');
      this.formClasses.twoCol = this.formClasses.twoCol.replace('col-12', 'col-6');
    } else if (containerWidth > 768 && containerWidth <= 992) {
      this.formClasses.form = this.formClasses.form.replace('flex-row', 'flex-column');
      this.formClasses.twoCol = this.formClasses.twoCol.replace('col-6', 'col-12');
    } else {
      this.formClasses.form = this.formClasses.form.replace('flex-row', 'flex-column');
      this.formClasses.twoCol = this.formClasses.twoCol.replace('col-6', 'col-12');
    }
  }

  // Update the layout on window resize
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    const containerWidth = this.caseFileHTMLElement?.clientWidth || window.innerWidth;
    this.updateWidth(containerWidth);
  }

  async getPatient() {
    const patientId = this.formState().currentValue?.Patient?.Id as number;
    if (!patientId) throw new Error('Patient Id required to fetch address');

    const endpoint = `${APIEndpoints.Patients}(${patientId})`;
    const expandString = 'XrefAddressPatients($expand=Address($select=Id,Address1,Address2,City,State,Zip,AddressType))';
    const query = new Query().expand(expandString);

    try {
      await this.api.getOdata(endpoint).executeQuery(query).then((res: any) => {
        if (res instanceof Error || !res.result[0]) throw new Error('Failed to fetch patient address');
        this.patientData.set((res as any).result[0] as Patient);
      });

      return this.patientData();
    } catch (error) {
      return error;
    }
  }

  onDatePickerFocus(focusEvent: FocusEventArgs) {
    if (!(focusEvent.model instanceof DatePickerComponent)) return;
    focusEvent.model.show();
  }

  onDateOnlyChange(args: any, controlName: string) {
    args.value = new Date(args.value).toISOString().split('T')[0];
    this.caseFileForm.get(controlName)?.setValue(args.value);

    // Auto calculate SOL when DOL changes
    if (controlName === 'DateOfLoss') {
      // TODO: Get state ID from law firm
      const stateId = 1; // Placeholder - need to get actual state ID
      const sol = this.calculateStatuteOfLimitations(args.value, stateId);
      this.caseFileForm.get('StatuteOfLimitations')?.setValue(sol);
    }
  }

  onCompanionCasesCreated(e: any) {
    this.companionCasesQuery = new Query().select('Id,FileNumber');
    this.companionCases = this.api.getOdata(APIEndpoints.Casefiles);
    const currentValue = this.caseFileForm.get('CompanionCases')?.value;
    if (currentValue && typeof currentValue === 'string') {
      try {
        const parsedArray = JSON.parse(currentValue);
        this.caseFileForm.get('CompanionCases')?.setValue(parsedArray);
      } catch (error) {
        console.error('Error parsing companion cases:', error);
      }
    }
  }

  // Add method to calculate Statute of Limitations
  calculateStatuteOfLimitations(dateOfLoss: string, stateId: number) {
    // TODO: Implement calculation based on state rules
    // This will need state-specific offset data and calculation logic
    // For now, return a placeholder date 2 years from DOL
    const dol = new Date(dateOfLoss);
    dol.setFullYear(dol.getFullYear() + 2);
    return dol.toISOString().split('T')[0];
  }

  // Add method to reset all forms
  resetForms(): void {
    this.caseFileForm.reset();
    this.patientFormComp.patientForm.reset();
    this.patientFormComp.addressFormComp.addressForm.reset();
  }

  // Update the patient selection handler
  onPatientSelected(event: any) {
    if (event.value === null || event.value === undefined || event.value === 0) {
      this.caseFileForm.get('PatientId')?.setValue(null, { emitEvent: false });
      this.patientData.set(undefined);
      this.patientFormComp.addressFormComp.addressForm.patchValue({ AddressType: 2 });
    } else {
      this.caseFileForm.get('PatientId')?.setValue(event.value, { emitEvent: false });
      this.patientData.set(event.patient);
    }
  }

  // Add this new async function
  async initializeDropdownData() {
    try {
      // Initialize all dropdown data sources
      this.recordStatuses = this.api.getOdata(APIEndpoints.RecordStatuses);
      this.pAndL = this.api.getOdata(APIEndpoints.PandLs);
      this.referralSources = this.api.getOdata(APIEndpoints.ReferralSources);
      this.caseTypes = this.api.getOdata(APIEndpoints.CaseTypes);
      this.statusingGroups = this.api.getOdata(APIEndpoints.StatusingGroups);
      this.fileGroups = this.api.getOdata(APIEndpoints.FileGroups);
      this.lawFirms = this.api.getOdata(APIEndpoints.Lawfirms);
      this.attorneys = this.api.getOdata(APIEndpoints.Contacts);
      this.users = this.api.getOdata(APIEndpoints.Users);
      this.contacts = this.api.getOdata(APIEndpoints.Contacts);

      // Set up queries
      this.lawFirmsQuery = new Query().select('Id,Name');
      this.attorneysQuery = new Query()
        .select('Id,ContactName,ContactTitle')
        .where('ContactType', 'equal', 1);
      this.usersQuery = new Query().select('Id,Name');
      this.companionCasesQuery = new Query().select('Id,FileNumber');

      // Initialize law firm contacts based on selected law firm
      const lawFirmId = this.caseFileForm.get('LawFirmId')?.value;
      this.lawFirmContacts = this.api.getOdata(APIEndpoints.Contacts);
      const lawFirmsPredicate = lawFirmId ? new Predicate(`XrefLawFirmContacts/any(x: x/LawFirmId eq ${lawFirmId})`, 'equal', true) : new Predicate('ContactType', 'equal', 2);
      this.lawFirmContactsQuery = new Query().expand('XrefLawFirmContacts').select('Id,ContactName,ContactTitle,ContactType').where(lawFirmsPredicate);

      this.caseManagersQuery = new Query()
        .expand('XrefUsersRoles')
        .select('Id,Name')
        .where(`XrefUsersRoles/any(x: x/RoleId eq 71)`, 'equal', true);

      this.marketManagersQuery = new Query()
        .expand('XrefUsersRoles')
        .select('Id,Name')
        .where(`XrefUsersRoles/any(x: x/RoleId eq 72)`, 'equal', true);

      this.statusersQuery = new Query()
        .select('Id,ContactName,ContactTitle,ContactType')
        .where('ContactTitle', 'contains', 'Statusing');

      this.referringPhysiciansQuery = new Query()
        .select('Id,ContactName,ContactTitle,ContactType')
        .where('ContactType', 'equal', 3);

      this.initialTimeFrames = this.api.getOdata(APIEndpoints.InitialTimeFrames);

    } catch (error) {
      console.error('Error initializing dropdowns:', error);
      this.toast.showError('Error loading form data');
    }
  }

  // Add method to ensure signal exists
  private ensureCaseFileSignal() {
    if (!this.caseFile) {
      this.caseFile = signal(undefined);
    }
  }

  // Add helper methods
  private validateAllForms(): string | null {
    // Only validate the case file form by default
    this.markAllFieldsAsTouched();
    this.caseFileForm.updateValueAndValidity();

    // Only validate sub-forms if they've been interacted with
    if (this.patientFormComp?.patientForm?.touched) {
        this.patientFormComp.markAllFieldsAsTouched();
        this.patientFormComp.patientForm.updateValueAndValidity();

        if (this.patientFormComp.addressFormComp?.addressForm?.touched) {
            this.patientFormComp.addressFormComp.markAllFieldsAsTouched();
            this.patientFormComp.addressFormComp.addressForm.updateValueAndValidity();
        }
    }

    const errors = this.errorMessages();
    
    if (Object.keys(errors).length > 0) {
        const errorMessage = Object.entries(errors)
            .map(([key, value]) => `<b> - ${key}</b>: ${value}`)
            .join('<br>');
        return `<span style="font-size: 1.125em;">Please check the following fields:</span><br>${errorMessage}`;
    }
    return null;
  }

  onLawFirmChange(event: any) {
    const predicate = new Predicate(`XrefLawFirmContacts/any(x: x/LawFirmId eq ${event.value})`, 'equal', true);
    this.lawFirmContactsQuery = new Query().expand('XrefLawFirmContacts').select('Id,ContactName,ContactTitle,ContactType').where(predicate);
  }
}
