// Angular
import { Component } from '@angular/core';

// 3rd Party
import { GridModel } from '@syncfusion/ej2-grids';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { ApiService } from '@services/api/api.service';
import { GridTemplateModule } from '@modules/grid-template.module';

@Component({
  selector: 'patients-grid',
  standalone: true,
  imports: [
    GridTemplateModule
  ],
  templateUrl: './patients-grid.component.html',
  styleUrl: './patients-grid.component.scss'
})
export class PatientsGridComponent {

  constructor(
    private api: ApiService
  ) {
    
  }

  public patients: GridModel;

  ngOnInit() {
    this.patients = {
      dataSource: this.api.getOdata(APIEndpoints.PatientsDataTable),
      columns: [
        { field: 'Id', headerText: 'ID', isPrimaryKey: true, visible: false },
        // { field: 'AddressId', headerText: 'Address Id' },
        { field: 'Firstname', headerText: 'First Name' },
        { field: 'Lastname', headerText: 'Last Name' },
        { field: 'Dob', headerText: 'Date Of Birth' },
        { field: 'Gender' },
        { field: 'Language' },
        { field: 'Email' },
        { field: 'Active' },
        { field: 'Minor' },
      ]
    }
  }
}
