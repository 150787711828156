{
  "secrets": {
    "BOX_DEVELOPER_TOKEN": "ca0pY44Lhfg2WXsZnTZyHcEeCtDioCjM",
    "OPENREPLAY_KEY": "LMuiOpYbV1ypwZMcDKNK",
    "SYNCFUSION_KEY": "ORg4AjUWIQA/Gnt2XFhhQlJHfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTH5QdEJiX39XcnZSQWZbWkd1;Mzc4MjIxM0AzMjM5MmUzMDJlMzAzYjMzMzMzYldHeFRFL1Azb05XVm9iK0RCSEhUbUtwMm5YMmI5Z01XQ0Z3WktIbHJ2QTg9"
  },
  "amplifyConfiguration": {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:584e93b1-beca-40fb-9b1c-f501ff78f4e3",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_kD3g0xRL6",
    "aws_user_pools_web_client_id": "6qoseebakm4rot6ved4m5kicr3",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": ["EMAIL"],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": ["SMS", "TOTP"],
    "aws_cognito_password_protection_settings": {
      "passwordPolicyMinLength": 8,
      "passwordPolicyCharacters": ["REQUIRES_LOWERCASE", "REQUIRES_UPPERCASE", "REQUIRES_NUMBERS", "REQUIRES_SYMBOLS"]
    },
    "aws_cognito_verification_mechanisms": ["EMAIL"]
  },
  "environment": {
    "settings": {
      "aws": {
        "region": "us-west-2",
        "cloudwatch": {
          "logGroup": "casecompass-logs",
          "logStream": "frontend-logs"
        }
      },
      "BoxDeveloperToken": "ca0pY44Lhfg2WXsZnTZyHcEeCtDioCjM",
      "cognitoAppClientId": "6qoseebakm4rot6ved4m5kicr3",
      "cognitoIdendityPoolId": "us-west-2:584e93b1-beca-40fb-9b1c-f501ff78f4e3",
      "cognitoUserPoolId": "us-west-2_kD3g0xRL6",
      "mondayApiToken": "eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjQ1NjAyMTAwMywiYWFpIjoxMSwidWlkIjo3MDU5OTc4MCwiaWFkIjoiMjAyNS0wMS0wOVQyMzozMDoxMS4xOTZaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6MjczNTU1MjYsInJnbiI6InVzZTEifQ.ISF8D2-SoWC0FV5pb3w-pCASznq_0YT9Pe97M11df7k",
      "mondayBoardId": "8206260134",
      "production": true
    },
    "backendUrl": "https://backend.casecompass.us/"
  },
  "environment-development": {
    "settings": {
      "aws": {
        "region": "us-west-2",
        "cloudwatch": {
          "logGroup": "casecompass-logs",
          "logStream": "frontend-logs"
        }
      },
      "BoxDeveloperToken": "ca0pY44Lhfg2WXsZnTZyHcEeCtDioCjM",
      "cognitoAppClientId": "6qoseebakm4rot6ved4m5kicr3",
      "cognitoIdendityPoolId": "us-west-2:584e93b1-beca-40fb-9b1c-f501ff78f4e3",
      "cognitoUserPoolId": "us-west-2_kD3g0xRL6",
      "mondayApiToken": "eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjQ1NjAyMTAwMywiYWFpIjoxMSwidWlkIjo3MDU5OTc4MCwiaWFkIjoiMjAyNS0wMS0wOVQyMzozMDoxMS4xOTZaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6MjczNTU1MjYsInJnbiI6InVzZTEifQ.ISF8D2-SoWC0FV5pb3w-pCASznq_0YT9Pe97M11df7k",
      "mondayBoardId": "8218021211",
      "production": false
    },
    "backendUrl": "https://localhost:63094/"
  }
}
