<div id="invoices-grid" class="invoices-grid">

    <grid-template #invoicesGrid [settings]="invoicesGridSettings" [name]="'Invoices'" />

    <ng-template #providersTemplate let-data>
        <div class="custom-template">
            <label class="form-label">Provider</label>
            <ejs-dropdownlist #providersDropdown
                [dataSource]="providersData" 
                [fields]="{ value: 'Id', text: 'Name' }"
                [value]="data.ProviderId" />
        </div>
    </ng-template>

    <ng-template #paymentStatusesEditTemplate let-data>
        <div class="custom-template">
            <label class="form-label">Payment Status</label>
            <ejs-dropdownlist #paymentStatusDropdown
                [dataSource]="paymentStatuses"
                [fields]="{ value: 'Id', text: 'Description' }"
                [value]="data.InvoicePaymentPaymentId" />
        </div>
    </ng-template>

    <ng-template #procedureCodesTemplate let-data>
        <div class="custom-template">
            <label class="form-label">Procedure</label>
            <ejs-dropdownlist #proceduresDropdown
                [dataSource]="proceduresData" 
                [fields]="{ value: 'Id', text: 'Description' }"
                [value]="data.Id" />
        </div>
    </ng-template>

    <ejs-dialog id="add-new-invoice-dialog" #addInvoiceDialog 
        [showCloseIcon]="true"
        [target]="'body'"
        [isModal]="true"
        [closeOnEscape]="true"
        [visible]="false"
        [buttons]="addInvoiceButtons"
        header="Add Invoice"
        (beforeOpen)="beforeOpening($event)"
        (close)="closeDialog()">
        <add-new-invoice-form #newInvoiceForm />
    </ejs-dialog>

    <ejs-dialog id="bulk-invoice-dialog" #bulkEditInvoicesDialog
        [showCloseIcon]="true"
        [target]="'body'"
        [isModal]="true"
        [closeOnEscape]="true"
        [visible]="false"
        [buttons]="bulkUpdateInvoicesButtons"
        header="Bulk Edit"
        (beforeOpen)="beforeOpening($event)"
        (close)="closeDialog()">
        <bulk-edit-invoices-form #bulkEditForm />
    </ejs-dialog>

</div>