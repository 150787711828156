<div id="add-user-role" [class]="formClasses.formContainer">
    
    <h2 class="form-title"><fa-icon [icon]="addRoleIcons.title" /> Add New Role</h2>

    <form [formGroup]="addRoleForm" [class]="formClasses.form" (ngSubmit)="onSubmit()">

        <section [class]="formClasses.section + ' ' + formClasses.twoCol + ' contact-section'">
            
            <div [class]="formClasses.group">

                <div [class]="formClasses.inputContainer" [class.error]="addRoleForm.get('addRoleForm_RoleName')?.errors && addRoleForm.get('addRoleForm_RoleName')?.touched">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="addRoleIcons.name" />
                    </span>
                    <ejs-textbox [class]="formClasses.input" formControlName="addRoleForm_RoleName" placeholder="New Role Title" />
                    <ng-container *ngIf="addRoleForm.get('addRoleForm_RoleName')?.invalid && addRoleForm.get('addRoleForm_RoleName')?.touched">
                        <span [class]="formClasses.error">This field is required</span>
                    </ng-container>
                </div>

                <div [class]="formClasses.inputContainer">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="addRoleIcons.description" />
                    </span>
                    <ejs-textbox [class]="formClasses.input" formControlName="addRoleForm_Description" placeholder="New Role Description" [multiline]="true" />
                </div>

                <div [class]="formClasses.inputContainer" [class.error]="addRoleForm.get('addRoleForm_Rights')?.errors && addRoleForm.get('addRoleForm_Rights')?.touched">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="addRoleIcons.rights" />
                    </span>
                    <ejs-multiselect formControlName="addRoleForm_Rights"
                        [class]="formClasses.input"
                        [dataSource]="rightsListData" 
                        [fields]="rightsFields"
                        [showDropDownIcon]="true"
                        placeholder="Select">
                    </ejs-multiselect>
                    <ng-container *ngIf="addRoleForm.get('addRoleForm_Rights')?.invalid && addRoleForm.get('addRoleForm_Rights')?.touched">
                        <span [class]="formClasses.error">This field is required</span>
                    </ng-container>
                </div>
            </div>
        </section>

        <section [class]="formClasses.section + ' col-12'" *ngIf="showSubmitButton">
            <div [class]="formClasses.actions">
                <button ejs-button [isPrimary]="true" type="submit">Submit</button>
                <button ejs-button type="button" (click)="formCancelled.emit()">Cancel</button>
            </div>
        </section>
    </form>
</div>