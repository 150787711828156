//Angular
import { Component, Input, ViewChild } from '@angular/core';
import { ReactiveFormsModule, FormControl, FormGroup } from '@angular/forms';

// 3rd Party
import { FormValidators } from '@syncfusion/ej2-angular-inputs';
import { AccordionModule } from '@syncfusion/ej2-angular-navigations'
import { ButtonModule, CheckBoxAllModule } from '@syncfusion/ej2-angular-buttons';
import { MultiSelectAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { firstValueFrom } from 'rxjs';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { ApiService } from '@services/api/api.service';
import { DropdownMultiComponent } from "../../../ui/dropdown-multi/dropdown-multi.component";
import { BalanceDueGridComponent } from "../balance-due-grid/balance-due-grid.component";
import { ToastMessageService } from '@app/shared/services/toast-message/toast-message.service';
import { BalanceStatementService } from '../../../../services/balance-statement.service';
import { FileHubService } from '@root/src/app/features/file-hub/services/file-hub.service';


@Component({
  selector: 'app-generate-balance-statement',
  templateUrl: './generate-balance-statement.component.html',
  styleUrl: './generate-balance-statement.component.scss',
  standalone: true,
  imports: [
    AccordionModule,
    CheckBoxAllModule,
    MultiSelectAllModule,
    ReactiveFormsModule,
    ButtonModule,
    DropdownMultiComponent,
    BalanceDueGridComponent,
  ],
  providers: [BalanceStatementService]
})

export class GenerateBalanceStatementComponent {
  constructor(
    private balanceStatementService: BalanceStatementService,
    private toast: ToastMessageService,
    private fileHub: FileHubService
  ) { }

  @ViewChild('BalanceDueGrid') balanceDueGrid: BalanceDueGridComponent

  //For Loading
  isGeneratingStatement = false;

  //For dropdown
  PandLs = APIEndpoints.PandLs

  @Input() caseFileId: number;
  final: boolean = false;
  formGroup: FormGroup = new FormGroup({ pandls: new FormControl<number[] | null>([], [FormValidators.required]) }, {});
  floatLabelType: string = "Never";

  ngOnInit(): void { }

  /**
   * Generates balance statements for the selected P&Ls
   */
  async GenerateBalanceStatement() {
    if (!this.formGroup.valid) {
      this.toast.showError('Please select at least one P&L');
      return;
    }

    if (!this.fileHub.caseFile?.BoxPublicFolder) {
      this.toast.showError('No Box Public Folder found for this case file');
      return;
    }

    const pandls = this.formGroup.value.pandls;
    if (!pandls || !pandls.length) {
      this.toast.showError('Please select at least one P&L');
      return;
    }

    this.isGeneratingStatement = true;

    try {
      console.log('Starting balance statement generation with:', {
        preliminary: !this.final,
        pandls,
        caseFileId: this.caseFileId
      });

      // Use the new frontend-based generation
      const result = await firstValueFrom(
        this.balanceStatementService.generateForCurrentCaseFile(!this.final, pandls)
      ).catch(err => {
        console.error('Error in generateForCurrentCaseFile:', err);
        throw err;
      });

      console.log('Generation result:', result);

      if (result.status === 'Done') {
        this.balanceDueGrid.refresh();
        if (result.boxLink) {
          this.showSuccessMessage('Balance statement generated successfully.', result.boxLink);
        } else {
          this.toast.showSuccess('Balance statement generated successfully.');
        }
      } else {
        console.warn('Unexpected result:', result);
        throw new Error('Failed to generate balance statement');
      }
    } catch (error) {
      console.error('Balance statement generation error:', error);
      this.handleError(error);
    } finally {
      this.isGeneratingStatement = false;
    }
  }

  private handleError(error: any): void {
    console.error('Full error details:', error);
    let errorMessage: string;

    if (error instanceof Error) {
      errorMessage = error.message;
    } else if (typeof error === 'string') {
      errorMessage = error;
    } else if (error?.error?.message) {
      errorMessage = error.error.message;
    } else if (error?.message) {
      errorMessage = error.message;
    } else {
      errorMessage = 'Failed to generate balance statement. Please try again.';
    }

    this.toast.showError(`Error generating balance statement: ${errorMessage}`);
  }

  private showSuccessMessage(message: string, boxLink: string): void {
    this.toast.showSuccess(`  
      ${message}
      <a href="${boxLink}" target="_blank" class="toast-link">
        <i class="fa fa-external-link"></i>
        View balance statement
      </a>
    `);
  }


}
