// Angular
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormControl, FormGroup } from '@angular/forms';

// 3rd Party
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';

// Internal
import { BulkEditInvoice } from '@models/components/invoices.model';
import { ApiService } from '@services/api/api.service';
import { DataManager } from '@syncfusion/ej2-data';
import { APIEndpoints } from '@models/api/Endpoints';

@Component({
  selector: 'bulk-edit-invoices-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    DatePickerModule,
    DropDownListModule,
    TextBoxModule
  ],
  templateUrl: './bulk-edit-invoices.component.html',
  styleUrl: './bulk-edit-invoices.component.scss'
})
export class BulkEditInvoicesForm {

  constructor (
    private api: ApiService
  ) { }

  paymentMethods: DataManager;
  paymentStatuses: DataManager;

  ngOnInit () {
    this.paymentMethods = this.api.getOdata(APIEndpoints.PaymentMethods);
    this.paymentStatuses = this.api.getOdata(APIEndpoints.PaymentStatuses);
  }

  bulkEditInvoicesForm: FormGroup = new FormGroup({
    DatePaid: new FormControl<BulkEditInvoice['DatePaid'] | null>(null),
    PaymentMethod: new FormControl<BulkEditInvoice['PaymentMethod'] | null>(null),
    PaymentStatus: new FormControl<BulkEditInvoice['PaymentStatus'] | null>(null),
    CheckNumber: new FormControl<BulkEditInvoice['CheckNumber'] | null>(null),
    Notes: new FormControl<BulkEditInvoice['Notes'] | null>(null)
  });
}
