<div #itemView id="item-view" class="item-view">

    <div class="header">

        @if(!hasMultiple && openFileData()) {
            <h1>{{title()}}</h1>
        }

        @if (!hasMultiple && openFileData()?.FileRating) {
            <div class="file-status">
                <span>File Status: <status [status]="openFileData()?.FileRating"></status></span>
            </div>
        }

    </div>

    <div *ngIf="openFileData()">

        <item-render [isEditing]="isEditing" [data]="openFileData()"></item-render>
        
        <div *ngIf="!openFileData()">Loading...</div>
    </div>

    @if(!hasMultiple && isMobile()) {
        <bottom-nav [items]="bottomNavItems"></bottom-nav>
    }

</div>