<div [class]="formClasses.formContainer">

  <loading-spinner [loading]="loading" />

  <form [formGroup]="form" [class.hidden]="loading" (ngSubmit)="onSubmit()" [class]="formClasses.form">

    <ng-content></ng-content>
    
    <div *ngIf="displayButtons" [class]="formClasses.actions">
      <button ejs-button type="button" (click)="onCancel()">Cancel</button>
      <button ejs-button [isPrimary]="true" type="submit">Submit</button>
    </div>

  </form>
  
</div> 