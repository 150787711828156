import { Injectable, signal, WritableSignal } from '@angular/core';
import { ApiService } from '@services/api/api.service';
import { DashboardQueriesService } from '@services/dashboard-queries/dashboard-queries.service';
import { UserPreferencesService } from '@services/user/user-preferences.service';
import { WorkItemsService } from '@features/work-items/services/work-items.service';
import { APIEndpoints } from '@models/api/Endpoints';
import { CustomCardModel } from '@models/components/card.model';
import { ClickEventArgs } from '@syncfusion/ej2-buttons';
import { Deposit, Invoice, InvoiceRow } from '@models/data-contracts';

@Injectable({
  providedIn: 'root'
})
export class DashboardCardsService {
  constructor(
    private api: ApiService,
    private queries: DashboardQueriesService,
    private userPrefs: UserPreferencesService,
    private workItems: WorkItemsService
  ) { }

  private getUserId(): number {
    return this.userPrefs.userId() ?? 0;
  }

  isCalendarView: WritableSignal<boolean> = signal(true);

  createAppointmentsCard(): CustomCardModel {
    return {
      cardTitle: 'Upcoming Appointments',
      cardId: 'scheduler',
      row: 0,
      column: this.isCalendarView() ? 0 : 2,
      sizeX: this.isCalendarView() ? 1 : 2,  // Smaller when calendar view is active
      gridSettings: {
        dataSource: this.api.getOdata(APIEndpoints.Appointments),
        query: this.queries.getAppointmentsQuery(this.getUserId()),
        allowFiltering: false,
        allowPaging: false,
        toolbar: [],
        columns: this.getAppointmentsColumns(),
      }
    };
  }

  createWorkItemsCard(handleToolbarClick: (args: ClickEventArgs) => void): CustomCardModel {
    const dataManager = this.api.getOdata(APIEndpoints.WorkItems);
    const query = !this.isCalendarView() ?
      this.workItems.getTodaysTasksQuery() :
      this.workItems.getBaseQuery(true);

    return {
      cardTitle: 'Tasks',
      cardId: 'work-items',
      row: 0,
      column: this.isCalendarView() ? 0 : 2,
      sizeX: this.isCalendarView() ? 2 : 1,
      showCalendarView: this.isCalendarView,
      cardSettingsMenu: [
        {
          iconCss: 'e-icons e-settings',
          items: [
            {
              text: 'Last Updated', items: [
                { text: '1 day', id: 'day-1' },
                { text: '1 week', id: 'day-7' },
                { text: '1 month', id: 'month-1' },
                { text: '3 months', id: 'month-3' },
                { text: '6 months', id: 'month-6' },
                { text: '1 year', id: 'year-1' }
              ]
            },
            { text: 'Select Columns', id: 'select-columns' },
            { text: 'Toggle Calendar View', id: 'calendar-view' }
          ]
        }
      ],
      gridSettings: {
        dataSource: dataManager,
        query: query,
        allowFiltering: false,
        allowPaging: false,
        toolbar: ['All', '0 Overdue', '0 Upcoming'],
        toolbarClick: handleToolbarClick,
        columns: this.getWorkItemColumns(),
      },
      scheduleSettings: {
        currentView: 'Month',
        selectedDate: new Date(),
        views: ['Day', 'Week', 'Month'],
        showHeaderBar: false,
        height: '500px',
        eventSettings: {
          dataSource: dataManager,
          query: query,
          fields: {
            subject: { name: 'Title' },
            startTime: { name: 'DueDate' },
            endTime: { name: 'DueDate' }
          }
        }
      }
    };
  }

  createCaseFilesCard(): CustomCardModel {
    return {
      cardTitle: 'Case Files',
      cardId: 'case-files',
      row: 1,
      column: 0,
      gridSettings: {
        dataSource: this.api.getOdata(APIEndpoints.Casefiles),
        query: this.queries.getCaseFilesQuery(this.getUserId()),
        allowFiltering: false,
        allowPaging: false,
        toolbar: [],
        columns: [
          { field: 'Id', isPrimaryKey: true, visible: false },
          { field: 'FileNumber', headerText: 'File', visible: true },
          {
            field: 'FullName',
            headerText: 'Client Name',
            visible: true,
            valueAccessor: (field: string, data: any) => this.concatName(data)
          },
          { field: 'Patient.Dob', headerText: 'DOB', visible: true },
          { field: 'LawFirm.Name', headerText: 'Law Firm', visible: true },
          { field: 'RecordStatus.Description', headerText: 'Status', visible: false },
          {
            field: 'XrefWorkItemCaseFile',
            headerText: 'Next Work Item',
            visible: true,
            valueAccessor: (field: string, data: any) => this.getNextWorkItem(data)
          },
          { field: 'DateOfLoss', headerText: 'Date of Loss', visible: false }
        ]
      }
    };
  }

  createRoicOverviewCard(): CustomCardModel {
    return {
      cardTitle: 'ROIC Overview',
      cardId: 'roic-overview',
      row: 1,
      column: 2,
      gridSettings: {
        dataSource: this.api.getOdata(APIEndpoints.Casefiles),
        query: this.queries.getRoicOverviewQuery(),
        allowFiltering: false,
        allowPaging: false,
        toolbar: [],
        columns: [
          { field: 'Id', isPrimaryKey: true, visible: false },
          { field: 'FileNumber', headerText: 'File', visible: true },
          {
            field: 'FullName',
            headerText: 'Patient',
            visible: true,
            valueAccessor: (field: string, data: any) => this.concatName(data)
          },
          {
            field: 'ROIC',
            headerText: 'ROIC',
            visible: true,
            valueAccessor: (field: string, data: any) => this.calculateRoic(data).formattedRoic
          }
        ],
        sortSettings: {
          // columns: [{ field: 'ROIC', direction: 'Descending' }]
        }
      }
    };
  }

  private getAppointmentsColumns() {
    return this.isCalendarView()
      ?
      [
        { field: 'Id', isPrimaryKey: true, visible: false },
        { field: 'CaseFile.FileNumber', headerText: 'File Number', visible: true },
        {
          field: 'FullName',
          headerText: 'Client Name',
          visible: true,
          valueAccessor: (field: string, data: any) => this.concatName(data.CaseFile)
        },
        { field: 'StartDatetime', headerText: 'Start', visible: true },
        { field: 'Provider.Name', headerText: 'Provider', visible: true }
      ]
      :
      [
        { field: 'Id', isPrimaryKey: true, visible: false },
        { field: 'CaseFile.FileNumber', headerText: 'File Number', visible: true },
        {
          field: 'FullName',
          headerText: 'Client Name',
          visible: true,
          valueAccessor: (field: string, data: any) => this.concatName(data.CaseFile)
        },
        { field: 'ProcedureCode.Modality', headerText: 'Modality', visible: true },
        { field: 'ProcedureCode.Description', headerText: 'Description', visible: true },
        { field: 'StartDatetime', headerText: 'Start', visible: true },
        { field: 'Provider.Name', headerText: 'Provider', visible: true }
      ]
  }

  private getWorkItemColumns() {
    return [
      { field: 'Id', isPrimaryKey: true, visible: false },
      { field: 'Title', headerText: 'Title', visible: true },
      { field: 'Description', headerText: 'Description', visible: true },
      { field: 'DueDate', headerText: 'Due Date', visible: true, format: 'yMd' },
      { field: 'Status.Name', headerText: 'Status', visible: true },
      { field: 'Priority.Name', headerText: 'Priority', visible: true },
      { field: 'Category.Name', headerText: 'Category', visible: true },
      {
        field: 'XrefWorkItemCaseFiles',
        headerText: 'Case File',
        visible: true,
        valueAccessor: (field: string, data: any) => {
          if (!data.XrefWorkItemCaseFiles?.length) return '';
          const fileNumber = data.XrefWorkItemCaseFiles[0]?.CaseFile?.FileNumber;
          return fileNumber ? `<a href="./case-files/${fileNumber}" target="_blank">${fileNumber}</a>` : '';
        }
      }
    ];
  }

  private getNextWorkItem(data: any): string {
    if (!data.XrefWorkItemCaseFile || !Array.isArray(data.XrefWorkItemCaseFile)) {
      return '';
    }

    const workItems = data.XrefWorkItemCaseFile
      .map((xref: any) => xref.WorkItem)
      .filter((item: any) => item.Status !== 'Completed')
      .sort((a: any, b: any) => new Date(a.DueDate).getTime() - new Date(b.DueDate).getTime());

    if (!workItems.length) {
      return '';
    }

    const nextItem = workItems[0];
    const dueDate = new Date(nextItem.DueDate);

    return `${nextItem.Title} (${dueDate.toLocaleDateString("en-GB").replace(/\//g, '-')})`;
  }

  private capitalize(str: string): string {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  private concatName(data: any): string {
    const firstName = this.capitalize(data?.Patient?.Firstname || '');
    const lastName = this.capitalize(data?.Patient?.Lastname || '');
    return `${firstName} ${lastName}`.trim();
  }

  private calculateRoic(data: any): { roic: number, formattedRoic: string } {
    const invoices = data.Invoices || [];
    const deposits = data.Deposits || [];

    const totalCost = invoices.reduce((accumulator: number, invoice: Invoice) => {
      if (invoice.InvoiceRows) {
        invoice.InvoiceRows.forEach((row: InvoiceRow) => {
          if (row.AmountBilled) {
            accumulator += row.AmountBilled;
          }
        });
      }
      return accumulator;
    }, 0);

    const totalRevenue = deposits.reduce((accumulator: number, deposit: Deposit) => {
      if (deposit.DepositAmount) {
        accumulator += deposit.DepositAmount;
      }
      return accumulator;
    }, 0);

    const roic = totalCost > 0 ? ((totalRevenue - totalCost) / totalCost) * 100 : 0;
    const formattedRoic = new Intl.NumberFormat('en-US', {
      style: 'percent',
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    }).format(roic / 100);

    return { roic, formattedRoic };
  }
}
