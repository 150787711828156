import { Injectable, inject } from '@angular/core';
import { ServiceBase } from '@core/base/service.base';
import { AuthHeadersService } from '@core/auth/auth-headers.service';
import { APIEndpoints } from '@shared/models/api/Endpoints';

@Injectable()
export abstract class AuthenticatedServiceBase extends ServiceBase {
    protected readonly authHeaders = inject(AuthHeadersService);
    protected abstract override readonly endpoint: string;
    protected readonly APIEndpoints = APIEndpoints;

    constructor() {
        super();
    }

    protected getAuthHeaders(): { [key: string]: string } {
        return this.authHeaders.getAuthHeaders();
    }
}
