import { Component, Input, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { LoadingModule } from '@modules/loading.module';

@Component({
  selector: 'app-audit-logs',
  standalone: true,
  imports: [CommonModule, ButtonModule, LoadingModule],
  templateUrl: './audit-logs.component.html',
  styleUrls: ['./audit-logs.component.scss']
})
export class AuditLogsComponent {
  @Input() logs: any[] = [];
  @Input() loading = false;
  loadingMessage = signal<boolean>(true);
  expandedRows: { [key: number]: boolean } = {};

  toggleRow(index: number) {
    this.expandedRows[index] = !this.expandedRows[index];
  }

  isRowExpanded(index: number): boolean {
    return this.expandedRows[index] === true;
  }

  getChangedProperties(oldValues: any, newValues: any): any[] {
    if (!oldValues || !newValues) return [];

    // Get all unique keys from both objects
    const allKeys = Array.from(new Set([
      ...Object.keys(oldValues),
      ...Object.keys(newValues)
    ]));

    // Create an array of changes for each property
    return allKeys.map(key => ({
      property: this.formatPropertyName(key),
      oldValue: oldValues[key] ?? 'N/A',
      newValue: newValues[key] ?? 'N/A'
    }));
  }

  formatPropertyName(name: string): string {
    // Convert camelCase to Title Case with spaces
    return name
      .replace(/([A-Z])/g, ' $1') // Add space before capital letters
      .replace(/^./, str => str.toUpperCase()); // Capitalize first letter
  }

  formatValue(value: any): string {
    if (value === null || value === undefined) return 'N/A';
    if (typeof value === 'boolean') return value ? 'Yes' : 'No';
    if (typeof value === 'object') return JSON.stringify(value);
    return String(value);
  }
}
