// Angular
import { Component, signal, WritableSignal } from '@angular/core';

// 3rd Party
import { Query } from '@syncfusion/ej2-data';

// Internal
import { Appointment, CaseFile } from '@models/data-contracts';
import { APIEndpoints } from '@models/api/Endpoints';
import { ApiService } from '@services/api/api.service';
import { GlobalsService } from '@services/globals/globals.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { SchedulerComponent } from '@features/scheduler/scheduler.component';
import { TabCardComponent } from '@root/src/app/features/file-hub/components/file-hub-tabs/tab-card/tab-card.component';
import { FileHubService } from '@root/src/app/features/file-hub/services/file-hub.service';
import { LoadingModule } from '@modules/loading.module';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'appointments-tab',
  standalone: true,
  imports: [
    SchedulerComponent,
    TabCardComponent,
    LoadingModule,
    CommonModule,
  ],
  templateUrl: './appointments-tab.component.html',
  styleUrl: './appointments-tab.component.scss'
})
export class AppointmentsTab {

  /**
   * Main functionality can be found in the FileHubService.
   * @see {@link FileHubService} for more info
   */
  constructor(
    public fileHub: FileHubService,
    private api: ApiService,
    private globals: GlobalsService,
    private toast: ToastMessageService
  ) {}

  loadingAppointments: WritableSignal<boolean> = signal(true);
  appointments: Appointment[];

  // Call After View Inititalized to ensure Case File has been fetched
  ngAfterViewInit() {
    this.addAppointmentsDataToCaseFile();
  }

  async addAppointmentsDataToCaseFile() {
    
    try {

      if (!this.globals.objHasKey(this.fileHub.caseFile, 'Appointments')) {
        const endpoint = `${APIEndpoints.Casefiles}(${this.fileHub.caseFile?.Id})`;
        const query = new Query().expand('Appointments($expand=Location($expand=ListModalities),Modality,ProcedureCode,CaseFile($expand=Patient))').select('Appointments');
  
        return await this.api.getOdata(endpoint).executeQuery(query).then((res: any) => {
          const file = res.result[0] as CaseFile;
          if (!this.fileHub.caseFile?.Appointments) this.fileHub.updateCaseFile('Appointments', file.Appointments);
          this.appointments = file.Appointments ?? [];
        });
      }

      return this.fileHub.caseFile?.Appointments;

    } catch (error) {
      console.error('Error adding appointments data to case file:', error);
      this.toast.showError('Failed to load appointments data');
      return error;

    } finally {
      this.loadingAppointments.set(false);
      return;
    }
  }
}
