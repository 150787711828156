<div id="law-firms-grid">

    @if (loadingData()) {
        <loading-overlay [loading]="loadingData" />
    } @else {
        <grid-template [settings]="lawFirms" [name]="'Law Firms'" />
    }

    <ng-template #nameTemplate let-data>
        <a class="link" (click)="nameClick(data)">
            <span>{{data.Name}}</span>
        </a>
    </ng-template>

    <div class="dialog-container" *ngIf="viewLawFirmAlert">
        <ejs-dialog id="view-law-firm-dialog" #viewLawFirmDialog 
            header="View Law Firm"    
            [showCloseIcon]="true"
            [target]="'body'"
            [isModal]="true"
            [closeOnEscape]="true"
            [visible]="viewLawFirmAlert"
            (close)="viewLawFirmAlert = false">
            <p>Viewing account details for a particular entity is currently under construction.</p>
        </ejs-dialog>
    </div>
</div>