// Angular
import { Component, Input, ViewEncapsulation, ViewChild } from '@angular/core';
import { ReactiveFormsModule, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

// 3rd Party
import { FormValidators } from '@syncfusion/ej2-angular-inputs';
import { Query, ReturnOption } from '@syncfusion/ej2-data';
import { CheckBoxSelectionService, DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { UploaderModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';

// Internal
import { BACKENDURL } from '@environments/environment';
import { APIEndpoints } from '@models/api/Endpoints';
import { Address, Patient, CaseFile, FeeSchedule, ProcedureCode } from '@models/data-contracts';
import { GlobalsService } from '@services/globals/globals.service';
import { ApiService } from '@services/api/api.service';
import { CognitoService } from '@services/auth/cognito.service';
import { ProcedureCodesComponent } from '@pages/procedure-codes/procedure-codes.component';
import { Spacer } from "../../../ui/spacer/spacer.component";

@Component({
  selector: 'add-procedure-code-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    DropDownListModule,
    TextBoxModule,
    ButtonModule,
    Spacer
],
  templateUrl: './add-procedure-code-form.component.html',
  styleUrl: './add-procedure-code-form.component.scss'
})
export class AddProcedureCodeFormComponent {
  
  constructor(
    private globals: GlobalsService,
    private api: ApiService,
    private fb: FormBuilder,
    private cognito: CognitoService,
    public procedureCodesComponent: ProcedureCodesComponent
  ) { }



  @Input() showFormButtons: Boolean = false;
  ODATA_BASE_URL: string = BACKENDURL + '/odata';
  newProcedureCodeForm: FormGroup;
  procedureCodesList: ProcedureCode[] = [];
  modalityData: any;
  modalityFields: Object = { text: 'Description', value: 'Description' };
  defaultModality: number;

  ngOnInit(): void { 
    this.newProcedureCodeForm = new FormGroup({
      Code: new FormControl<ProcedureCode['ProcedureCodeName']>(''),
      Modality: new FormControl<ProcedureCode['ModalityTypeId']>(undefined),
      // Modality: new FormControl<ProcedureCode['Modality']>(''),
      Description: new FormControl<ProcedureCode['Description']>(''),
    });

    this.defaultModality = 6;
    this.api.getOdata(APIEndpoints.Modalities).executeQuery(new Query()).then((e: ReturnOption) => { this.modalityData = e.result }).catch((e) => true);
  }


  uploadListener(event: EventTarget | null) {
      // convert event target to html input element
      const target = event as HTMLInputElement;
      const files = target.files;
      if (files && files.length > 0) {
        const file = files.item(0);
        if (file) {
          console.log(file.name);
          console.log(file.size);
          console.log(file.type);
          const reader = new FileReader();
          reader.readAsText(file);
          reader.onload = (e) => {
            const csv = reader.result as string;
            // if csv length is greater than 0
            if (csv.length > 0) {
              // split csv into rows and pair the data per row
              var rows = csv.split('\n');
              // ignore empty strings or anything with a backslash
              var data = rows.map((row) => {
                var rowValues = row.split(',');
                if (rowValues[1] == "") {
                  rowValues[1] = "ALL";
                }
                console.log("rowValues");
                console.log(rowValues[1]);
                // iterate over row values, ignore empty strings and ignore anything with a backslash
                return rowValues.map((value) => {
                  if (value.length > 0 && !value.includes('\\')) {
                    // remove anyhting after \r in the value
                    value = value.split('\r')[0];
                    return value;
                  }
                  else {
                    return null;
                  }
                });
                
              });
              // loop over data and each array in data, if first element of data array is null, remove the array
              for (let i = 0; i < data.length; i++) {
                if (data[i][0] === null || data[i][0] === "") {
                  data.splice(i, 1);
                }
              }
              
              // call getFormatProcedureCodeList which accepts data and returns a list of procedure codes
              // skip any iterations where get format procedure code list returns null
              var procedureCodes = data.filter(function(code) {
                if (code[0] === null || code[0] === "") {
                  return false; // skip
                }
                return true;
              }).map((code) => { return this.getFormatProcedureCodeList(code); }).filter((code) => code !== null);
              this.procedureCodesList = procedureCodes as ProcedureCode[];
              console.log(this.procedureCodesList);
              // clear form code description and modality
              this.newProcedureCodeForm.controls['Code'].setValue('');
              this.newProcedureCodeForm.controls['Description'].setValue('');

            }
          }
        }
      }
  }

  
  getFormatProcedureCode() {
    return {
      "ProcedureCodeName": this.newProcedureCodeForm.value.Code,
      "Modality": this.newProcedureCodeForm.value.Modality,
      "Description": this.newProcedureCodeForm.value.Description
    }

  }

  getFormatProcedureCodeList(data: any) {
    // If data[0] is null or empty, return false
    if (data[0] === null || data[0] === "") {
      return null;
    }
    return {
      "ProcedureCodeName": data[0],
      "Modality": data[1],
      "Description": data[2]
    }
  }

  onSubmit() {
    console.log('Submit Form');
    console.log(this.newProcedureCodeForm.invalid);
    
    // check if procedure code form conains code, modality, and description
    if (this.newProcedureCodeForm.value.Code && this.newProcedureCodeForm.value.Modality && this.newProcedureCodeForm.value.Description) {
      // console log form data
      var procedureCode = this.getFormatProcedureCode();
      const url = '/ProcedureCodes';
      const fetchURL = async () => {
        const headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.cognito.getUserIdToken() };
        return await fetch(this.ODATA_BASE_URL + url, { 
          method: 'POST', 
          headers: headers, 
          body: JSON.stringify(procedureCode)
        }).catch(error => console.log(error))
      }
      // reset form 
      this.newProcedureCodeForm.reset();
      this.procedureCodesComponent.addProcCodeDialog.visible = false;

      return fetchURL();

    // else if procedure codes list is greater than 0
    }else if(this.procedureCodesList.length > 0){
      // call procedure codes prostlist which accepts a list of procedure codes
      const url = '/ProcedureCodes/list';
      const fetchURL = async () => {
        const headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.cognito.getUserIdToken() };
        return await fetch(this.ODATA_BASE_URL + url, { 
          method: 'POST', 
          headers: headers, 
          body: JSON.stringify(this.procedureCodesList)
        }).catch(error => console.log(error))
      }
      // reset form 
      this.newProcedureCodeForm.reset();
      this.procedureCodesComponent.addProcCodeDialog.visible = false;
      return fetchURL();
      


    }else{
      return false;
    }
    
  }


  clickThing(args: any) {
    console.log(args);
  }

}
