<div id="multi-file">

    @if(openFiles?.length > 1) {

        <div class="row">
    
            @if (openFileData()) {
    
                <div class="col-12 header-info">
                    @if(openFileData().CaseNumber && openFileData().Comments) {
                        <div class="d-flex">
                            <h1>{{openFileData()?.CaseNumber}} / {{title()}}</h1>
                            <span class="h4">{{openFileData()?.Comments}}</span>
                        </div>
                    }
                </div>
            
                <div class="col-12 file-info">
                    <div class="e-card">
        
                        @if (loadingFileData() === false) {
                    
                            <div class="row">
                                <div class="col-12 col-md-1">
                                    <div class="d-flex flex-column patient-info">
                                        <span><label>File Status:</label><status [status]="openFileData()?.FileRating" /></span>
                                        <div class="d-flex justify-content-between patient-info-buttons">
                                            <ejs-tooltip content="Copy Patient Demographics">
                                                <button ejs-button cssClass="action-button" (click)="handleCopyPatientDemographics($event)"><fa-icon [icon]="faCopy"></fa-icon></button>
                                            </ejs-tooltip>
                                            <ejs-tooltip content="Send Auth Request">
                                                <button ejs-button cssClass="action-button" (click)="emailAuthRequest()"><fa-icon [icon]="faEnvelope"></fa-icon></button>
                                            </ejs-tooltip>
                                        </div>
                                    </div>
                                </div>
                    
                                <div class="col-12 col-md-11">
                                    @if(openFileData()?.Patient) {
                                        <patient-info [inputData]="openFileData()"></patient-info>
                                    }
                                </div>
                            </div>
    
                        } @else {
                            <loading-overlay [loading]="loadingFileData"></loading-overlay>
                        }
                    </div>
                </div>
            
                <div class="col-12">
                    <section class="multi-file-container">
                        @if(openFileData()) {
                            <item-view [data]="openFileData()" [hasMultiple]="true"></item-view>
                        }
                    </section>
                </div>
            }
    
        </div>
    
    } @else {
        <item-view></item-view>
    }
    
</div>