<div id="surgeries-grid" class="surgeries-grid">

  <base-grid #surgeriesGrid 
    [settings]="settings"
    (gridData)="onGridDataReceived($event)">
  </base-grid>

  <ejs-dialog #addSurgeryDialog 
    [(visible)]="addSurgeryDialogVisibility"
    [header]="addSurgeryConfig.header"
    [width]="addSurgeryConfig.width"
    [isModal]="true"
    [showCloseIcon]="true"
    [buttons]="addSurgeryButtons"
    (close)="closeDialog()
    ">
    <surgery-form [submitType]="'POST'" [caseFileId]="fileHub.caseFile?.Id"
    (formCancelled)="addSurgeryDialogVisibility = false"
    (formSubmitted)="onAddFormSubmitted()"
    ></surgery-form>
  </ejs-dialog>


  <ejs-dialog
    #editSurgeryDialog
    [visible]="editSurgeryDialogVisibility"
    [header]="editSurgeryConfig.header"
    [width]="editSurgeryConfig.width"
    [buttons]="editSurgeryButtons"
    [showCloseIcon]="true"
    (close)="closeEditDialog()"
    >
    <surgery-form [surgery]="surgerySignal" [submitType]="'PATCH'" [caseFileId]="fileHub.caseFile?.Id" (formSubmitted)="onEditFormSubmitted()"></surgery-form>
  </ejs-dialog>

  <ejs-dialog #deleteConfirmDialog 
    [visible]="deleteConfirmDialogVisibility"
    [buttons]="deleteConfirmButtons"
    [header]="deleteConfirmConfig.header"
    [width]="deleteConfirmConfig.width"
    (close)="closeDeleteDialog()">
    <ng-template #content>
      <p>Are you sure you want to delete this surgery? This action cannot be undone.</p>
    </ng-template>
  </ejs-dialog>

</div>