// Angular
import { Injectable, computed, inject, signal } from '@angular/core';

// 3rd Party
import { Query } from '@syncfusion/ej2-data';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { ApiService } from '@services/api/api.service';
import { AuthenticatedServiceBase } from '@core/auth/auth.base';
import { BaseGridService } from '@shared/components/base-grid/services/state.service';

// Interfaces
export interface IProcedureCode {
  Id: number;
  Description: string;
  ModalityType: {
    Id: number;
    Description: string;
  };
  CreatedAt: Date;
}

// Error constants
const ERRORS = {
  REFRESH_GRID: {
    message: 'Failed to refresh grid data. Please try again.',
    technical: 'Error refreshing procedure codes grid data'
  },
  LOAD_DATA: {
    message: 'Failed to load procedure codes data. Please try again.',
    technical: 'Error loading procedure codes data'
  }
};

@Injectable({
  providedIn: 'root'
})
export class ProcedureCodesService extends AuthenticatedServiceBase {
  // Injections
  protected override readonly api = inject(ApiService);
  private readonly gridService = inject(BaseGridService);

  // Required by AuthenticatedServiceBase
  protected override readonly endpoint = APIEndpoints.ProcedureCodes;

  // Signals
  private readonly loadingState = signal<boolean>(false);
  private readonly errorState = signal<Record<string, any>>({});

  // Computed
  readonly isLoading = computed(() => this.loadingState());
  readonly errors = computed(() => this.errorState());

  constructor() {
    super();
  }

  /**
   * Refreshes the grid data
   */
  async refreshGrid(): Promise<void> {
    try {
      this.loadingState.set(true);
      await this.gridService.refreshData();
    } catch (error) {
      this.handleError(error, {
        context: 'ProcedureCodesService.refreshGrid',
        userMessage: ERRORS.REFRESH_GRID.message,
        severity: this.ErrorSeverity.Error
      });
      this.errorState.update(state => ({
        ...state,
        refreshGrid: error
      }));
      throw error;
    } finally {
      this.loadingState.set(false);
    }
  }

  /**
   * Gets a procedure code by ID
   */
  async getProcedureCode(id: number): Promise<IProcedureCode> {
    try {
      this.loadingState.set(true);
      const query = new Query().where('Id', 'equal', id).expand('ModalityType');
      const response = await this.api.getOdata(APIEndpoints.ProcedureCodes).executeQuery(query);
      const data = await response.json();
      return data.value[0];
    } catch (error) {
      this.handleError(error, {
        context: 'ProcedureCodesService.getProcedureCode',
        userMessage: 'Failed to load procedure code details',
        severity: this.ErrorSeverity.Error
      });
      this.errorState.update(state => ({
        ...state,
        getProcedureCode: error
      }));
      throw error;
    } finally {
      this.loadingState.set(false);
    }
  }

  /**
   * Clears all errors
   */
  clearErrors(): void {
    this.errorState.set({});
  }

  /**
   * Resets the service state
   */
  resetState(): void {
    this.loadingState.set(false);
    this.errorState.set({});
  }
}
