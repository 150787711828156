// Organized Angular imports
import { Component, computed, Input, Signal, signal, ViewChild, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup } from '@angular/forms';

// Third-party imports
import { CommandModel, GridModel } from '@syncfusion/ej2-angular-grids';
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';


// Application imports
import { Contact } from '@root/src/app/shared/models/data-contracts';
import { capitalizeFirstLetter } from '@app/utils';
import { GridTemplateComponent } from '@root/src/app/components/grids/grid-template/grid-template.component';
import { ContactFormComponent } from '@root/src/app/components/forms/contact-form/contact-form.component';

@Component({
  selector: 'contacts-tab',
  standalone: true,
  imports: [
    CommonModule,
    DialogModule,
    GridTemplateComponent,
    ContactFormComponent  
  ],
  templateUrl: './contacts-tab.component.html',
  styleUrl: './contacts-tab.component.scss'
})
export class ContactsTabComponent {

  // Decorator variables
  @Input() payload: any;
  @ViewChild('contactsGrid') contactsGrid!: GridTemplateComponent;
  @ViewChild('addContactDialog', { static: true }) addContactDialog!: DialogComponent;
  @ViewChild('editContactDialog', { static: true }) editContactDialog!: DialogComponent;
  @ViewChild('contactDialog') contactDialog!: DialogComponent;
  @ViewChild('contactFormComponent') contactFormComponent!: ContactFormComponent;

  readonly contactsGridActionsColumnCommands: CommandModel[] = [
    { type: 'Edit', title: 'Edit', buttonOption: { iconCss: 'e-icons e-edit', cssClass: 'e-flat' } }
  ];

  readonly commonColumns = [
    { field: 'Id', headerText: 'ID', isPrimaryKey: true, visible: false },
    { field: 'ContactName', headerText: 'Name' },
    { field: 'ContactTitle', headerText: 'Title' },
    { field: 'Phone', headerText: 'Phone' },
    { field: 'ContactEmail', headerText: 'Email' }
  ];
  
  readonly lawFirmSpecificColumns = [
    { field: 'Address', headerText: 'Address' },
    { field: 'city', headerText: 'City' },
    { field: 'state', headerText: 'State' },
    { field: 'zip', headerText: 'Zip' }
  ];
  
  readonly providerSpecificColumns = [
    { field: 'Notes', headerText: 'Notes' }
  ];
  
  readonly actionsColumn = [
    { type: 'commands', commands: this.contactsGridActionsColumnCommands, headerText: 'Actions' }
  ];

  isLawFirm?: boolean;
  selectedAccountId?: number;
  selectedContact: WritableSignal<Contact | undefined> = signal(undefined);
  contactsGridSettings!: GridModel;
  addContactDialogVisibility = false;
  editContactDialogVisibility = false;
  editContactDialogHeader = 'Edit Contact';
  contactDialogButtons: Object[] = [
    { click: this.cancelContactForm.bind(this), buttonModel: { content: 'Cancel', cssClass: 'e-outline' } },
    { click: this.submitContactForm.bind(this), buttonModel: { content: 'Submit', isPrimary: true } },
  ];

  ngOnInit() {
    const contactsData = this.payload?.XrefLawFirmContacts || this.payload?.XrefProviderContacts || [];
    this.selectedAccountId = this.payload?.Id;
    this.isLawFirm = this.payload?.XrefLawFirmContacts ? true : false;
    this.contactsGridSettings = {
      dataSource: contactsData.map((x: { Contact: Contact }) => {
        const phone = x.Contact?.XrefPhoneContacts?.[0]?.Phone?.PhoneNumber;
        const address = x.Contact?.XrefAddressContacts?.[0]?.Address;
        return {
          Id: x.Contact.Id,
          ContactType: x.Contact.ContactType,
          ContactName: capitalizeFirstLetter(x.Contact.ContactName ?? ''),
          ContactTitle: x.Contact.ContactTitle,
          ContactEmail: x.Contact.ContactEmail,
          Phone: phone,
          Address: address?.Address1,
          City: address?.City,
          State: address?.StateNavigation?.Name,
          Zip: address?.Zip,
          Notes: x.Contact.Notes,
        };
      }) || [],
      toolbar: [{text: '+ Add Contact', tooltipText: 'Add Contact', id: 'AddContact', align: 'Left'}],
      columns: this.isLawFirm 
      ? [...this.commonColumns, ...this.lawFirmSpecificColumns, ...this.actionsColumn] 
      : [...this.commonColumns, ...this.providerSpecificColumns, ...this.actionsColumn],

      toolbarClick: (args: any) => this.onToolbarClick(args),
      commandClick: (args: any) => this.onCommandClick(args), 
      editSettings: { allowEditing: false }
    };
  }

 onToolbarClick(args: any): void {
    if (args.item.id === 'AddContact') {
      this.contactDialog.header = `Add Contact`;
      this.selectedContact.set(undefined);
      
      // Reset the form completely before showing dialog
      if (this.contactFormComponent) {
        this.contactFormComponent.resetForms();
        // Wait for reset to complete before showing dialog and prepopulating
        setTimeout(() => {
          this.contactDialog.show();
          if (this.isLawFirm) {
            this.contactFormComponent.prepopulateLawFirmAddress();
          }
        });
      }
    }
  }

  onCommandClick(args: any): void {
    const contact = args.rowData as Contact;

    if (args.commandColumn.type === 'Edit') {
      args.cancel = true;
      this.contactDialog.header = `Editing: ${contact.ContactName}`;
      this.selectedContact.set(contact);
      this.contactDialog.show();
    }
  }

  async submitContactForm() {
    await this.contactFormComponent.onSubmit().then((res) => {
      if (res && res.Id) {
        this.contactDialog.hide();
        this.contactsGrid.refresh();
        window.location.reload();
      }
    });
  }

  cancelContactForm() {
    this.selectedContact.set(undefined);
    this.contactDialog.hide();
  }
}