import { Component, Input, OnInit, WritableSignal, signal } from '@angular/core';
import { GlobalsService } from '@services/globals/globals.service';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { StatusComponent } from '@ui/status/status.component';
import { CommonModule } from '@angular/common';
import { ItemViewHelperComponent } from '../item-view-helper/item-view-helper.component';

@Component({
  selector: 'item-render',
  standalone: true,
  imports: [
    CommonModule,
    DialogModule,
    StatusComponent, 
    ItemViewHelperComponent
  ],
  templateUrl: './item-render.component.html',
  styleUrl: './item-render.component.scss',
})

export class ItemRenderComponent implements OnInit{
  constructor(private globals: GlobalsService) {}

  @Input() data: any;
  @Input() isEditing: boolean;

  openFileData : WritableSignal<any> = signal(null)
  public title: WritableSignal<string> = signal("");

  public keys: string[];

  private readonly hiddenKeys: string[] = [
    'Password',
    'RememberToken',
    'UserPreferences',
    "LastLoginIp",
  ];
  
  ngOnInit(): void {
    this.keys = this.getObjKeys(this.data).filter(
      (key) => !this.hiddenKeys.includes(key)
    );
  }

  public getName(data: any){
    return this.globals.getName(data)
  }
  public formatKey(key: string){
    return this.globals.formatKey(key)
  }
  
  getObjKeys = (value: any) => {
    return this.getDataType(value) === 'object' ? Object.keys(value) : [];
  };



  isBoolean = (key: string, value: any): boolean => {
    return typeof value === 'boolean' || key.toLowerCase().startsWith('is');
  };


  getDataType = (value: any, key?: string): string => {
    if (key?.includes('Rating')) return 'status';
    if (Array.isArray(value)){
      if(!value.length) return 'empty'
      return 'array'   
    }
    // if(type === "object"  && (value instanceof Date)) return 'date'
    return typeof value;
  };

    // Handling Modal
    expanded: { [key: string]: boolean } = {};
    // Method to show the dialog
    show = (key: string) => {
      this.expanded[key] = true;
    };
    // Method to hide the dialog
    hide = (key: string) => {
      this.expanded[key] = false;
    };
    // button toggle
    toggleNested = (key: string): void => {
      this.expanded[key] = !this.expanded[key];
    };
    toggleAppointment = (key: string, index: number): void => {
      const appointmentKey = `${key}_${index}`;
      this.expanded[appointmentKey] = !this.expanded[appointmentKey];
    };

    isNestedObject = (value: any): boolean => {
      return (
        typeof value === 'object' &&
        value !== null &&
        !(value instanceof Date) &&
        !Array.isArray(value)
      );
    };
}

