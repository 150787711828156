// Angular
import { Component, computed, signal, ViewChild, WritableSignal, effect } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { CommonModule } from '@angular/common';

// Syncfusion
import { GridComponent, CommandModel, GridModel, CommandClickEventArgs } from '@syncfusion/ej2-angular-grids';
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';
import { Query, Predicate } from '@syncfusion/ej2-data';

// Models
import { APIEndpoints } from '@models/api/Endpoints';
import { CaseFile } from '@models/data-contracts';

// Services
import { ApiService } from '@services/api/api.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { UserPreferencesService } from '@services/user/user-preferences.service';
import { AuditLogService } from '@services/audit-logs/audit-log-service';
import { FileViewService } from '@services/file-view/file-view.service';

// Modules
import { LoadingModule } from '@modules/loading.module';

// Components
import { BulkEditCaseFilesForm } from '@forms/bulk-edit-forms/bulk-edit-case-file/bulk-edit-case-file.component';
import { GridTemplateComponent } from '@grids/grid-template/grid-template.component';
import { AuditLogsComponent } from '@ui/audit-logs/audit-logs.component';
import { CaseFileFormComponent } from '@root/src/app/components/forms/case-file-form/case-file-form.component';

// Add interface at top of file
interface ODataResponse {
  result: any[];
  '@odata.count': number;
}

@Component({
  selector: 'case-files-grid',
  standalone: true,
  imports: [
    CommonModule,
    DialogModule,
    GridTemplateComponent,
    BulkEditCaseFilesForm,
    CaseFileFormComponent,
    AuditLogsComponent,
    LoadingModule
  ],
  templateUrl: './case-files-grid.component.html',
  styleUrl: './case-files-grid.component.scss'
})
export class CaseFilesGridComponent {
  
  constructor (
    private api: ApiService,
    private toast: ToastMessageService,
    private user: UserPreferencesService,
    private auditLogService: AuditLogService,
    private router: Router,
    private fileView: FileViewService
  ) {
  }  

  // Initialize variables for caseFilesGrid
  @ViewChild('caseFilesGrid') caseFilesGrid: GridComponent;
  @ViewChild('caseFiles') caseFiles: GridTemplateComponent;
  @ViewChild('fileNumberDisplayTemplate', { static: true }) fileNumberDisplayTemplate!: string;
  @ViewChild('lawFirmDisplayTemplate', { static: true }) lawFirmDisplayTemplate!: string;
  caseFilesGridSettings: GridModel;
  auditLogs: any[] = [];
  auditLogsLoading = false;
  caseFilesGridDefaultToolbar: any[] = ['Add', 'ColumnChooser'];
  caseFileSignal: WritableSignal<CaseFile | undefined> = signal(undefined);
  caseFileData = computed(() => this.caseFileSignal());
  loading = signal(true);
  caseFilesGridActionsColumnCommands: CommandModel[] = [
    { type: 'Edit', title: 'Edit', buttonOption: { iconCss: 'e-icons e-edit', cssClass: 'e-flat' }  },
    { type: 'None', title: 'Logs', buttonOption: { iconCss: 'e-icons e-description', cssClass: 'e-flat' } },
  ];

  // Adding a new case file
  @ViewChild('addCaseFileDialog', { static: true}) addCaseFileDialog: DialogComponent;
  @ViewChild('newFileForm') newFileForm: CaseFileFormComponent;
  emptyCaseFileObj: object;
  addCaseFileDialogVisibility: Boolean = false;
  selectedRecords: any[] = [];
  addCaseFileButtons: Object[] = [
    { click: this.closeDialog.bind(this), buttonModel: { content: 'Cancel', cssClass: 'e-outline', isPrimary: false } },
    { click: this.onNewCaseFileSubmit.bind(this), buttonModel: { content: 'Submit', cssClass: 'e-primary', isPrimary: true, } }
  ];

  // Custom dialog for bulk editing files
  @ViewChild('bulkEditCaseFilesDialog') bulkEditCaseFilesDialog: DialogComponent;
  @ViewChild('bulkEditForm') bulkEditForm: BulkEditCaseFilesForm;
  bulkEditCaseFilesDialogVisibility: Boolean = false;
  bulkUpdateCaseFilesButtons: Object[] = [
    { click: this.closeDialog.bind(this), buttonModel: { content: 'Cancel', cssClass: 'e-outline' } },
    { click: this.onBulkEditSubmit.bind(this), buttonModel: { content: 'Submit', isPrimary: true, cssClass: 'e-primary' } }
  ];

  // Editing a single case file
  @ViewChild('editCaseFileDialog', { static: true}) editCaseFileDialog: DialogComponent;
  @ViewChild('editFileForm') editFileForm: CaseFileFormComponent;
  editCaseFileDialogVisibility: Boolean = false;
  editCaseFile: CaseFile;
  editCaseFileButtons: object[] = [
    { click: this.closeDialog.bind(this), buttonModel: { content: 'Cancel', cssClass: 'e-outline' } },
    { click: this.onEditCaseFileSubmit.bind(this), buttonModel: { content: 'Submit', isPrimary: true, cssClass: 'e-primary' } }
  ];

  @ViewChild('logsDialog', { static: true }) logsDialog: DialogComponent;
  logsDialogVisibility: Boolean = false;
  logsDialogButtons: Object[] = [
    { click: this.closeLogsDialog.bind(this), buttonModel: { content: 'Close', cssClass: 'e-flat' } }
  ];

  ngOnInit() {
    this.getData().then((result) => {
      this.caseFilesGridSettings = {
        dataSource: result,
        allowPaging: true,
        pageSettings: { 
          pageSize: 16,
          pageSizes: true,
          pageCount: 5,
          enableQueryString: true,
          totalRecordsCount: result.count
        },
        dataStateChange: ($event: any) => this.onDataStateChange($event),
        toolbar: this.caseFilesGridDefaultToolbar,
        columns: [
          { type: 'checkbox' },
          { field: 'Id' },
          { field: 'FileNumber', headerText: 'File Number', allowEditing: false, template: this.fileNumberDisplayTemplate },
          { field: 'Patient.FullName', headerText: 'Client Name'},
          { field: 'Patient.Dob', headerText: 'DOB', format: 'yMd' },
          { field: 'Patient.Minor', headerText: 'Minor', visible: false },
          { field: 'ResponsibleParty', headerText: 'Responsible Party', visible: false },
          { field: 'Patient.XrefAddressPatients.Address.StateNavigation.Name', headerText: 'State' },
          { field: 'Patient.XrefAddressPatients.Address.County', headerText: 'County', visible: false },
          { field: 'LawFirm.Name', headerText: 'Law Firm', template: this.lawFirmDisplayTemplate },
          { field: 'LawFirmContactNavigation.ContactName', headerText: 'Law Firm Contact', visible: false },
          { field: 'AttorneyNavigation.ContactName', headerText: 'Attorney', visible: false },
          { field: 'FileOpened', headerText: 'File Opened', allowEditing: false, format: 'yMd' },
          { field: 'InTreatment', headerText: 'In Treatment' },
          { field: 'IsSurgical', headerText: 'Surgical', visible: false },
          { field: 'CaseManagerNavigation.Name', headerText: 'Case Manager', visible: false },
          { field: 'Status', headerText: 'Record Status', visible: false },
          { field: 'ReferralSourceNavigation.Description', headerText: 'Referral Source', visible: false },
          { field: 'DateOfLoss', headerText: 'Date of Loss', visible: false },
          { field: 'AdditionalDatesOfLoss', headerText: 'Additional Dates of Loss', visible: false },
          { field: 'MarketManagerNavigation.Name', headerText: 'Market Manager', visible: false },
          { field: 'Patient.Email', headerText: 'Client Email', visible: false },
          { field: 'Patient.Phone', headerText: 'Client Phone', visible: false },
          { field: 'Patient.Language', headerText: 'Client Language', visible: false },
          { field: 'LawFirmContactNavigation.Email', headerText: 'Law Firm Contact Email', visible: false },
          { field: 'Comments', headerText: 'Comments', allowFiltering: false, allowSorting: false, valueAccessor: (field: string, data: object): string[] => this.getCommentValues(field, data), visible: false },
          { field: 'PAndLNavigation.Description', headerText: 'P&L', visible: false },
          { field: 'LawFirmFileNumber', headerText: 'Law Firm File Number', visible: false },
          { field: 'BalanceDue', headerText: 'Balance Due', visible: false },
          { field: 'Statuser', headerText: 'Statuser', visible: false },
          { field: 'SelectFiles', headerText: 'Select Files', visible: false },
          { type: 'commands', commands: this.caseFilesGridActionsColumnCommands, headerText: 'Actions' },
          { field: 'CreatedAt', headerText: 'Created', visible: false, allowEditing: false, format: 'yMd' },
          { field: 'UpdatedAt', headerText: 'Updated', visible: false, allowEditing: false, format: 'yMd' }
        ],
        created: () => this.onCreated(),
        dataBound: ($event: any) => this.onDataBound($event),
        toolbarClick: ($event: any) => this.onCustomToolbarClick($event),
        rowSelected: ($event: any) => this.onRowSelected($event),
        rowSelecting: ($event: any) => this.onRowSelecting($event),
        rowDeselected: ($event: any) => this.onRowDeselected($event),
        rowDeselecting: ($event: any) => this.onRowDeselecting($event),
        commandClick: ($event: CommandClickEventArgs) => this.onCommandClick($event)
      }
    });
  }

  // Fetch and set data for proper grid actions
  async getData(pageSettings: { skip: number, take: number } = { skip: 0, take: 16 }) {
    const endpoint = APIEndpoints.Casefiles;
    const query = new Query()
      .expand(['Patient($expand=XrefAddressPatients($expand=Address($expand=StateNavigation($select=Id,Name))))','LawFirm','CaseManagerNavigation($select=Id,Name)','XrefWorkItemCaseFiles'])
      .sortBy('Id', 'desc')
      .sortBy('UpdatedAt')
      .skip(pageSettings.skip)
      .take(pageSettings.take)
      .requiresCount();

    const response = (await this.api.getOdata(endpoint).executeQuery(query) as unknown) as any;
    
    if (!response || !response.result) throw new Error('Failed to fetch case files.');
    
    const caseFiles = response.result.map((caseFile: any) => { 
      const address = caseFile.Patient?.XrefAddressPatients?.find((x: any) => x.Address);
      const fullName = `${caseFile.Patient?.Firstname} ${caseFile.Patient?.Lastname}`;
      return {
        ...caseFile,
        Patient: {
          ...caseFile.Patient,
          FullName: fullName,
          XrefAddressPatients: address ? [address][0] : []
        }
      };
    });
    
    this.loading.set(false);
    return {
      result: caseFiles,
      count: response.count,
      aggregates: null,
      groupDs: null
    };
  }

  // Ensures foreign key fields have data ready for display
  getPatientName(field: string, data: object): string {
    const patient = (data as any)[field];
    if (!patient?.Firstname && !patient?.Lastname) return '';
    return `${patient.Firstname || ''} ${patient.Lastname || ''}`.trim();
  }

  onLawFirmClick(lawFirm: any) {
    this.router.navigate(['/law-firm-detail', lawFirm.Id])
      .then(() => {
        window.location.reload();
      });
  }

  // Value accessor for displaying strings in comments column
  getCommentValues(field: string, data: object): string[] {
    if ((data as any)[field]) {
      return ((data as any)[field as string]);
    } else {
      return [''];
    }
  }

  onCreated() {
    this.caseFilesGridDefaultToolbar = this.caseFiles.settings.toolbar as any[];
  }

  onDataBound(args: any) {
    this.caseFilesGrid = this.caseFiles.grid;
  }

  // Add logic for rendering modals
  onCustomToolbarClick(args: any) {
    
    if (args.item.text === 'Add') {
      args.cancel = true;
      this.caseFileSignal.set(undefined);
      this.clearBulkEditForm();
      this.addCaseFileDialog.show();
    } 
    
    if (args.item.id === 'BulkEdit') {
      this.bulkEditCaseFilesDialogVisibility = true;
      if (this.bulkEditCaseFilesDialog) this.bulkEditCaseFilesDialog.show();
    }
  }

  // Add logic for editing individual case files
  onCommandClick(commandClickArgs: CommandClickEventArgs) {
    const file: CaseFile = (commandClickArgs.rowData as CaseFile);

    if (!file) {
      this.toast.showError('Unable to open Case File.<br />No Case Number found.');
      return;
      
    } else if (commandClickArgs.commandColumn?.type === 'Edit') {
      commandClickArgs.cancel = true;
      this.editCaseFile = commandClickArgs.rowData as CaseFile;
      this.caseFileSignal.set(this.editCaseFile);
      if (file.FileNumber && this.caseFileData() && this.editCaseFileDialog) this.editCaseFileDialog.show();
      return;

    } else if (commandClickArgs.commandColumn?.title === 'Logs') {
      commandClickArgs.cancel = true;
      
      if (file.Id) {
        this.auditLogsLoading = true;
        this.showLogsDialog();

        return this.auditLogService.getAuditLogs(file.Id, 'CaseFile').then((result: any) => {

          if (!result || !result.length || result.length === 0) {
            this.toast.showError('No audit logs found');
            return;
          }

          this.auditLogs = this.auditLogService.mapAuditDataToLogFormat(result);
          this.auditLogsLoading = false;
          return;
        });
      }

      return;

    } else {
      commandClickArgs.cancel = true;
      this.caseFiles.onCommandClick(commandClickArgs);
      return;
    }
  }

  // New method to show audit logs dialog
  showLogsDialog() {
    try {
      this.logsDialogVisibility = true;
      if (this.logsDialog) {
        this.logsDialog.show();
      } else {
        this.toast.showError('Logs dialog not initialized');
      }
    } catch (error) {
      this.toast.showError('Error displaying logs dialog');
      console.error('Error showing logs dialog:', error);
    }
  }

  // Update caseNumberClick to use signal
  caseNumberClick(data: any) {
    if (!data.FileNumber) {
      this.toast.showError('No File Number found.');
      return;
    }

    // Use service signal directly
    this.fileView.openFiles.update(files => {
      const fileExists = files.some((file: any) => file.id === data.FileNumber);
      
      if (!fileExists) {
        files.push({
          id: data.FileNumber,
          name: data.Patient ? `${data.Patient.Firstname} ${data.Patient.Lastname}` : 'Unknown Patient'
        });
      }
      
      return [...files];
    });

    // Navigate to the case file
    this.router.navigate(['/case-files/hub'], { 
      queryParams: { fileNumber: data.FileNumber }
    });
  }

  onRowSelected(args: any) {
    this.selectedRecords = this.caseFilesGrid.getSelectedRecords();
    this.updateToolbar();
  }

  // Detect selection of multiple rows
  onRowSelecting(args: any) {
    this.selectedRecords = this.caseFilesGrid.getSelectedRecords();
    this.updateToolbar();
  }

  onRowDeselected(args: any) {
    this.selectedRecords = this.caseFilesGrid.getSelectedRecords();
    this.updateToolbar();
  }

  // Occurs as rows are deselected
  onRowDeselecting(args: any) {
    this.selectedRecords = this.caseFilesGrid.getSelectedRecords();
    this.updateToolbar();
  }

  // Set toolbar according to number of selected rows
  updateToolbar() {

    if (this.selectedRecords && this.selectedRecords.length) {
      const length = this.selectedRecords.length;

      if (length === 1) {
        this.caseFilesGrid.toolbar = this.caseFilesGridDefaultToolbar;
      } else if (length > 1) {
        const bulkEditButton = { text: 'Bulk Edit', tooltipText: 'Bulk Edit', id: 'BulkEdit', prefixIcon: 'e-properties-1' };
        const buttonExists = this.caseFilesGrid.toolbar.some(button => (button as any).id === bulkEditButton.id);
        if (!buttonExists) this.caseFiles.insertToolbarItem(bulkEditButton); // make sure button is not already in toolbar
      }

    } else {
      this.caseFilesGrid.toolbar = this.caseFilesGridDefaultToolbar;
    }
  }

  // Close both modal windows
  async closeDialog() {
    if (this.addCaseFileDialog) this.addCaseFileDialog.hide();
    if (this.editCaseFileDialog) this.editCaseFileDialog.hide(); this.editCaseFileDialogVisibility = false; 
    if (this.bulkEditCaseFilesDialog) this.bulkEditCaseFilesDialog.hide(); this.bulkEditCaseFilesDialogVisibility = false;
    if (this.logsDialog) this.logsDialog.hide(); this.logsDialogVisibility = false;
  }

  // Set fields to blank
  clearCaseFileForm() {
    if (this.newFileForm) {
      this.newFileForm.caseFileForm.reset();
      this.newFileForm.patientFormComp.patientForm.reset();
      this.newFileForm.patientFormComp.addressFormComp.addressForm.reset();
    }
    if (this.editFileForm) {
      this.editFileForm.caseFileForm.reset();
      this.editFileForm.patientFormComp.patientForm.reset();
      this.editFileForm.patientFormComp.addressFormComp.addressForm.reset();
    }
  }

  // Add new file to grid
  onNewCaseFileSubmit(args: any) {
    this.newFileForm.onSubmit().then((result: any) => {
      if (result && (result.ok || result.Id)) {
        const fileNumber = result.FileNumber;
        const successMessage = `Case File successfully submitted. <br />
          <a href="/case-files/hub?fileNumber=${fileNumber}#Home">Go to Case File</a>`;
        this.closeDialog();
        this.getData().then((data) => {
          this.caseFiles.grid.dataSource = {
            result: data.result,
            count: data.count
          };
          this.caseFiles.grid.refresh();
        });
      }

      if (result instanceof Error) {
        this.toast.showError(result.message);
      }
    });
  }

  onEditCaseFileSubmit(args: any) {
    this.editFileForm.onSubmit().then((result: any) => {
      if (result && (result.ok || result.Id)) {
        this.closeDialog();
        this.toast.showSuccess('Case File successfully updated.');
        this.getData().then((data) => {
          this.caseFiles.grid.dataSource = {
            result: data.result,
            count: data.count
          };
          this.caseFiles.grid.refresh();
        });
      }

      if (result instanceof Error) {
        this.toast.showError(result.message);
      }
    }); 
  }

  // Set bulk editing fields to blank
  clearBulkEditForm() {
    if (this.bulkEditForm) (this.bulkEditForm.bulkEditCaseFilesForm as FormGroup).reset();
  }

  // Apply bulk changes to data
  onBulkEditSubmit(args: any) {
    const comp = this;
    let form = this.bulkEditForm.bulkEditCaseFilesForm;

    if (form.valid) {

      this.selectedRecords.forEach((record) => {
        for (const [key, value] of Object.entries(this.bulkEditForm.bulkEditCaseFilesForm.value)) {
          const updatedValue: any = value;
          if (value !== null) {
            this.caseFilesGrid.updateCell(record, key, updatedValue);
          }
        }
      })
      this.closeDialog();
      this.mapBulkEditChanges();
    } else {
      console.log((this.bulkEditForm.bulkEditCaseFilesForm as FormGroup).errors);
      alert('Please enter a valid form.');
    }
  }

  // Ensure data is properly formatted before batchSave()
  mapBulkEditChanges() {
    let changedRecords: any[] = []

    for (const [key, value] of Object.entries(this.bulkEditForm.bulkEditCaseFilesForm.value)) {
      if (value !== null) {
        let newObj = { [key]: value };
        changedRecords.push(newObj);
      }
    }
  }

  closeLogsDialog() {
    try {
      if (this.logsDialog) {
        this.logsDialog.hide();
        this.logsDialogVisibility = false;
        this.auditLogs = []; // Clear the logs when closing
      }
    } catch (error) {
      this.toast.showError('Error closing logs dialog');
      console.error('Error closing logs dialog:', error);
    }
  }

  // Rename onPageChange to onDataStateChange to handle all grid state changes
  async onDataStateChange(state: any) {
    if (!state.action) return;

    const pageSettings = {
      skip: state.skip || 0,
      take: state.take || 16
    };

    // Get query from current state
    const query = new Query()
      .expand([
        'Patient($expand=XrefAddressPatients($expand=Address($expand=StateNavigation($select=Id,Name))))',
        'LawFirm',
        'CaseManagerNavigation($select=Id,Name)',
        'XrefWorkItemCaseFiles'
      ])
      .skip(pageSettings.skip)
      .take(pageSettings.take)
      .requiresCount();

    // Handle filtering
    if (state.action.requestType === 'filtering' && state.where) {
      let mainPredicate: Predicate | null = null;

      state.where.forEach((filter: any) => {
        filter.predicates?.forEach((pred: any) => {
          let currentPredicate: Predicate;

          if (pred.field === 'Patient.FullName') {
            // Special handling for FullName
            const firstnamePred = new Predicate(
              'Patient/Firstname',
              'contains',
              pred.value,
              pred.ignoreCase
            );
            const lastnamePred = new Predicate(
              'Patient/Lastname',
              'contains',
              pred.value,
              pred.ignoreCase
            );
            currentPredicate = firstnamePred.or(lastnamePred);
          } else {
            // Handle other fields
            const field = pred.field.replace(/\./g, '/');
            
            // Special handling for boolean fields
            if (pred.field === 'InTreatment') {
              console.log('InTreatment filter value:', {
                rawValue: pred.value,
                convertedValue: pred.value === 'true',
                typeofRaw: typeof pred.value
              });
              
              currentPredicate = new Predicate(
                field,
                'equal',
                pred.value === true || pred.value === 'true',  // Handle both boolean and string 'true'
                pred.ignoreCase
              );
            } else {
              currentPredicate = new Predicate(
                field,
                pred.operator,
                pred.value,
                pred.ignoreCase
              );
            }
          }

          // Combine predicates with AND
          mainPredicate = mainPredicate ? 
            mainPredicate.and(currentPredicate) : 
            currentPredicate;
        });
      });

      if (mainPredicate) {
        query.where(mainPredicate);
      }
    }

    // Handle sorting
    if (state.sorted?.length) {
      state.sorted.forEach((sort: any) => {
        query.sortBy(sort.name, sort.direction.toLowerCase());
      });
    } else {
      query.sortBy('Id', 'desc').sortBy('UpdatedAt');
    }

    const response = (await this.api.getOdata(APIEndpoints.Casefiles).executeQuery(query) as unknown) as any;
    
    // Map the response to include FullName, just like in getData
    const caseFiles = response.result.map((caseFile: any) => { 
      const address = caseFile.Patient?.XrefAddressPatients?.find((x: any) => x.Address);
      const fullName = `${caseFile.Patient?.Firstname} ${caseFile.Patient?.Lastname}`;
      return {
        ...caseFile,
        Patient: {
          ...caseFile.Patient,
          FullName: fullName,
          XrefAddressPatients: address ? [address][0] : []
        }
      };
    });

    if (this.caseFilesGridSettings) {
      this.caseFilesGridSettings.dataSource = {
        result: caseFiles,
        count: response.count
      };
    }

    // Only refresh for paging
    if (state.action.requestType === 'paging' && this.caseFilesGrid) {
      this.caseFilesGrid.refresh();
    }
  }
}
