import config from '@root/config.json';

export const getConfig = () => {
  // Use development config when running locally
  if (window.location.hostname === 'localhost') {
    return {
      ...config['environment-development'].settings,
      backendUrl: config['environment-development'].backendUrl
    };
  }
  return {
    ...config.environment.settings,
    backendUrl: config.environment.backendUrl
  };
};

export default getConfig();