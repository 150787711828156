<div id="patient-info" class="patient-info">

    <div class="row">
        <div *ngIf="loading.patient()">
            <loading-overlay [loading]="loading.patient" />
        </div>
        <div class="{{ui.columnClass()}}" id="tooltipTarget" #tooltipTarget *ngFor="let item of data.header()">
            <div class="info">
                <label class="info-label">{{item.label}}: </label>
                <span *ngIf="!item.value?.isLink">{{ item.value }}</span>
                <a *ngIf="item.value?.isLink" [href]="item.value.href" class="email-link">{{ item.value.text }}</a>
            </div>
        </div>
    </div>
</div>

<!-- Dialogs -->
<ejs-dialog #AuthLimitWarningDialog [visible]="showAuthLimitWarning()" [width]="'500px'" [height]="'200px'">
    <div>
        <h3>Warning!</h3>
        <p>This file is nearing the authorization limit. Please contact your manager to increase the limit.</p>
    </div>
    <div class="dialog-actions">
        <button ejs-button 
            [isPrimary]="true" 
            [cssClass]="'e-primary'" 
            [content]="'OK'" 
            (click)="authLimitWarningDialog.hide()">
        </button>
    </div> 
</ejs-dialog>
