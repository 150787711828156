// Angular
import { Component, signal, WritableSignal } from '@angular/core';

// Internal
import { FileHubService } from '@root/src/app/features/file-hub/services/file-hub.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { LoadingModule } from '@modules/loading.module';
import { TabCardComponent } from '@root/src/app/features/file-hub/components/file-hub-tabs/tab-card/tab-card.component';
import { NoDataComponent } from '@ui/no-data/no-data.component';
import { CommonModule } from '@angular/common';
import { BoxSignComponent } from '@components/features/box/box-sign/box-sign.component';

@Component({
  selector: 'box-sign-tab',
  standalone: true,
  imports: [
    TabCardComponent,
    LoadingModule,
    NoDataComponent,
    CommonModule,
    BoxSignComponent
  ],
  templateUrl: './box-sign-tab.component.html',
  styleUrl: './box-sign-tab.component.scss'
})
export class BoxSignTab {

  /**
   * Main functionality can be found in the FileHubService.
   * @see {@link FileHubService} for more info
   */
  constructor(
    private toast: ToastMessageService,
    public fileHub: FileHubService
  ) { }

  loadingBoxSign: WritableSignal<boolean> = signal(true);

  ngAfterViewInit() {
    this.checkData();
  }

  // Verifies data is available
  async checkData() {

    try {
      if (!this.fileHub.caseFile) {
        const errMsg = `
          <strong>No Case File</strong>
          <p>Unable to populate Sign Requests.</p>
        `;
        this.toast.showError(errMsg);
      } else {
        this.loadingBoxSign.set(false);
      }

      return;

    } catch (error) {
      console.error('Error checking data:', error);
      this.toast.showError('Failed to load box sign data');
      return error;
    } finally {
      this.loadingBoxSign.set(false);
      return;
    }
  }

  // Simple function to return passed data in box sign
  getData(data: any) {
    return data;
  }
}
