// Angular(first)
import { Component, computed, OnInit, signal, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

// 3rd Party(2nd)
import { Query } from '@syncfusion/ej2-data';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';

// Internal last - Services
import { ApiService } from '@services/api/api.service';

// Internal(last) - Models
import { APIEndpoints } from '@models/api/Endpoints';
import { Address, CaseFile, Patient } from '@models/data-contracts';

@Component({
  selector: 'app-test',
  standalone: true,
  imports: [
    CommonModule
],
  templateUrl: './test.component.html',
  styleUrl: './test.component.scss'
})
export class TestPageComponent implements OnInit {
  
  /**
   * Constructor
   */
  constructor(
    private api: ApiService
  ) { }

  /**
   * Declare variables that require a decorator
   */
  @ViewChild('helloWorldElement', {static: true}) helloWorldElement!: string;

  /**
   * Declare public variables
   */
  helloWorldPublic: string;

  /**
   * Declare private variables
   */
  private helloWorldPrivate: string;

  /**
   * OnInit hook
   */
  ngOnInit(): void {
    this.helloWorldPublic = 'Hello World';
  }

  /**
   * AfterViewInit hook
   */
  ngAfterViewInit(): void {
    this.helloWorldElement = 'Hello World';
  }
}
