// Angular
import { Component, ViewChild, WritableSignal, signal } from '@angular/core';
import { CommonModule } from '@angular/common';

// 3rd Party
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';
import { Query } from '@syncfusion/ej2-data';


// Internal
import { SetGridDataArgs } from '@shared/components/base-grid/models/grid.models';
import { AddProcedureCodeFormComponent } from '@components/forms/add-forms/add-procedure-code-form/add-procedure-code-form.component';
import { LoadingModule } from '@shared/modules/loading.module';
import { EditProcedureCodeFormComponent } from '@components/forms/edit-procedure-code/edit-procedure-code-form.component';
import { BaseGridComponent } from '@shared/components/base-grid/base-grid.component';
import { BaseGridHooks } from '@shared/components/base-grid/services/hooks.service';
import { BaseGrid } from '@core/base/grid.base';
import { ProcedureCodesService } from '@features/procedure-codes/services/procedure-codes.service';
import { ApiService } from '@services/api/api.service';
import { APIEndpoints } from '@models/api/Endpoints';

@Component({
  selector: 'app-procedure-codes',
  standalone: true,
  imports: [
    CommonModule,
    DialogModule,
    BaseGridComponent,
    AddProcedureCodeFormComponent,
    EditProcedureCodeFormComponent,
    LoadingModule
  ],
  providers: [
    BaseGridHooks
  ],
  templateUrl: './procedure-codes.component.html',
  styleUrl: './procedure-codes.component.scss'
})
export class ProcedureCodesComponent extends BaseGridComponent {
  // ViewChild references
  @ViewChild('procedureCodesGrid') procedureCodesGrid: BaseGridComponent;
  @ViewChild('addProcCodeDialog') addProcCodeDialog: DialogComponent;

  // Signals
  loadingData: WritableSignal<boolean> = signal(true);
  isAddProcCodeDialogVisible: boolean = false;
  isEditProcCodeDialogVisible: boolean = false;
  selectedProcedureCode: any;
  gridSettings: SetGridDataArgs;

  constructor(
    protected override readonly api: ApiService,
    public readonly procedureCodesService: ProcedureCodesService
  ) {
    super();
    // Initialize grid settings once
    this.gridSettings = this.getGridSettings();
    // Set the grid data using the base grid service
    this.services.setGridData(this.gridSettings);
  }

  /**
   * Gets the grid settings for procedure codes
   */
  private getGridSettings(): SetGridDataArgs {
    return {
      endpoint: APIEndpoints.ProcedureCodes,
      name: 'Procedure Codes',
      useRoundedEdges: true,
      query: new Query().expand('ModalityType'),
      pageSettings: { skip: 0, take: 20 },
      gridSettings: {
        columns: [
          { field: 'Id', headerText: 'ID', isPrimaryKey: true, visible: false },
          { field: 'ProcedureCodeName', headerText: 'Code', width: 120 },
          { field: 'Description', headerText: 'Description', width: 200 },
          { field: 'ModalityType.Description', headerText: 'Modality Type', width: 150 },
          { field: 'CreatedAt', headerText: 'Created At', type: 'date', format: 'M/d/y', width: 120 },
          { field: 'UpdatedAt', headerText: 'Updated At', type: 'date', format: 'M/d/y', width: 120 },
          { type: 'commands', headerText: 'Actions', width: 120, commands: [
            { type: 'Edit', buttonOption: { iconCss: 'e-icons e-edit', cssClass: 'e-flat' } },
            { type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } }
          ]}
        ],
        toolbar: [
          { text: 'Add', tooltipText: 'Add', id: 'Add', prefixIcon: 'e-add' },
          { type: 'Separator' },
          'Search',
          'ColumnChooser',
          { type: 'Separator' },
          { text: 'Excel Export', tooltipText: 'Excel Export', id: 'ExcelExport', prefixIcon: 'e-excelexport' },
          { text: 'PDF Export', tooltipText: 'PDF Export', id: 'PdfExport', prefixIcon: 'e-pdfexport' },
          { text: 'Print', tooltipText: 'Print', id: 'Print', prefixIcon: 'e-print' }
        ],
        allowPaging: true,
        pageSettings: { pageSize: 20, pageSizes: [10, 20, 50, 100] },
        allowSorting: true,
        allowFiltering: true,
        allowExcelExport: true,
        allowPdfExport: true,
        showColumnChooser: true,
        searchSettings: {
          fields: ['Code', 'Description', 'ModalityType.Description'],
          operator: 'contains',
          ignoreCase: true
        },
        toolbarClick: (args: any) => this.onToolbarClick(args),
        commandClick: (args: any) => this.onCommandClick(args)
      }
    };
  }

  onToolbarClick(args: any): void {
    if (args.item.text === 'Add') {
      args.cancel = true;
      this.isAddProcCodeDialogVisible = true;
      if (this.addProcCodeDialog) this.addProcCodeDialog.show();
    }
  }

  onCommandClick(args: any): void {
    if (args.commandColumn.type === 'Edit') {
      this.handleEditClick(args);
    }
  }

  beforeOpenAddProcCodeDialog(args: any) {
    args.maxHeight = '85vh';
    args.width = '50%';
  }

  handleEditClick(args: any) {
    args.cancel = true;
    this.selectedProcedureCode = args.rowData;
    this.isEditProcCodeDialogVisible = true;
    return false;
  }

  beforeOpenEditProcCodeDialog(args: any) {
    args.maxHeight = '85vh';
    args.width = '50%';
  }

  async refreshGrid() {
    try {
      await this.procedureCodesService.refreshGrid();
    } catch (error) {
      this.handleError(error, {
        context: 'ProcedureCodesComponent.refreshGrid',
        userMessage: 'Failed to refresh grid',
        severity: this.ErrorSeverity.Error
      });
    }
  }
}
