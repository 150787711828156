<div id="reduction-requests">

  <grid-template #grid [settings]="gridSettings" [name]="'Reduction Requests'" />

  <ejs-dialog id="modal" #modal
    [showCloseIcon]="true"
    [isModal]="true"
    [closeOnEscape]="true"
    header="Edit Request"
    [visible]="modalVisibility"
    (close)="closeDialog()"
    (beforeOpen)="beforeOpening($event)"
  >
    <approve-reduction-request 
      [reductionRequest]="selectedRowData" 
      [balanceDue]="balanceDue"
      [caseFileId]="this.caseFileId"
      (close)="closeDialog()"
      (submit)="onBackendResponse()"
    />
  </ejs-dialog>
  
</div>