// Angular
import { Component, Input, Signal, computed } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

// 3rd Party
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { DialogModule, TooltipAllModule } from '@syncfusion/ej2-angular-popups';
import { DropDownListAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCalendar, faDollarSign, faFileInvoice, faMoneyBillTransfer } from '@fortawesome/free-solid-svg-icons';

// Models
import { APIEndpoints } from '@models/api/Endpoints';
import { VwRemittance, InvoicePaymentUpdateDTO } from '@models/data-contracts';

// Services
import { ApiService } from '@services/api/api.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';

// Type & Interface setup
type BulkRemittanceFormControls = {
  DatePaid: FormControl<Date | null | undefined>;
  TransactionNumber: FormControl<string | null | undefined>;
  PaymentMethod: FormControl<number | null | undefined>;
  PaymentStatus: FormControl<number | null | undefined>;
};

@Component({
  selector: 'bulk-edit-remittances-form',
  standalone: true,
  imports: [
    CommonModule,
    DialogModule,
    ReactiveFormsModule,
    ButtonModule,
    TextBoxModule,
    DatePickerModule,
    TooltipAllModule,
    DropDownListAllModule,
    FontAwesomeModule
  ],
  providers: [DatePipe],
  templateUrl: './bulk-edit-remittances.component.html',
  styleUrl: './bulk-edit-remittances.component.scss'
})
export class BulkEditRemittancesForm {

  constructor(
    private api: ApiService,
    private datePipe: DatePipe,
    private toast: ToastMessageService
  ) { }

  /**
   * Declare Variables
   */
  // Decorator variables
  @Input() remittances!: Signal<VwRemittance[] | undefined>;
  @Input() submitType?: 'POST' | 'PATCH' | 'DELETE' = 'PATCH';
  @Input() displayFields: string[] = ['DatePaid', 'TransactionNumber', 'PaymentMethod', 'PaymentStatus'];
  @Input() displaySubmit: boolean = false;

  // Public variables
  loadingForm: boolean = true;
  errorMessage: string = '';
  HTMLElement: Element | null = null;
  selectedRows: any;
  paymentMethodData: DataManager;
  paymentMethodQuery: Query;
  paymentStatusData: DataManager;
  paymentStatusQuery: Query;
  bulkEditRemittancesForm: FormGroup = new FormGroup<BulkRemittanceFormControls>({
    DatePaid: new FormControl<Date | null | undefined>(undefined, [Validators.required]),
    TransactionNumber: new FormControl<string | null | undefined>(undefined, [Validators.required]),
    PaymentMethod: new FormControl<number | null | undefined>(undefined, [Validators.required]),
    PaymentStatus: new FormControl<number | null | undefined>(undefined, [Validators.required])
  });
  remittanceIcons = {
    datePaid: faCalendar,
    transactionNumber: faMoneyBillTransfer,
    paymentMethod: faDollarSign,
    paymentStatus: faFileInvoice
  }
  formClasses = {
    formContainer: 'cc-form-container',
    form: 'cc-form flex-column w100 d-flex justify-content-center align-items-center',
    subForm: 'cc-sub-form',
    section: 'cc-form-section w100 d-flex justify-content-center align-items-center',
    group: 'cc-form-group row',
    inputContainer: 'cc-input-container',
    inputContainerFullWidth: 'cc-input-container col-12',
    label: 'cc-label',
    input: 'cc-input',
    icon: 'cc-input-icon',
    error: 'cc-input-error',
    actions: 'cc-form-actions',
    twoCol: 'col-12'
  };

  /**
   * State management
   */
  // Form
  protected readonly formState = computed(() => {
    const currentRemittances = this.remittances();
    return {
      isValid: this.bulkEditRemittancesForm.valid,
      hasChanges: currentRemittances !== this.bulkEditRemittancesForm.value,
      currentValue: currentRemittances
    };
  });

  // Validation messages
  protected readonly errorMessages = computed(() => {
    const errors: Record<string, string> = {};
    Object.keys(this.bulkEditRemittancesForm.controls).forEach(key => {
      const control = this.bulkEditRemittancesForm.get(key);
      if (control?.errors) errors[key] = this.getErrorMessage(key);
    });
    return errors;
  });

  /**
   * Angular Lifecycle Hooks
   */

  // After view renders
  ngAfterViewInit() {
    this.HTMLElement = document.querySelector('bulk-edit-remittances-form');
    this.updateWidth(this.HTMLElement?.clientWidth || window.innerWidth);
    this.watchInputElements();
  }

  /**
   * Declare functions
   */

  // Submit form
  async submit() {
    try {
      let rows = this.remittances();
      const datePaidValue = this.bulkEditRemittancesForm.get('DatePaid')?.value;
      
      if (!rows) {
        this.loadingForm = false;
        this.toast.showError('No rows selected');
        return 'error';
      }

      // Check for valid fields
      if (!this.bulkEditRemittancesForm.valid) {
        this.loadingForm = false;
        this.toast.showError('Form Invalid');
        return 'error';
      }

      // Check if form has changes
      if (this.bulkEditRemittancesForm.pristine) {
        this.loadingForm = false;
        this.toast.showWarning('No changes to submit');
        return 'error';
      }

      const changedPayments: InvoicePaymentUpdateDTO[] = rows.map(row => {
        // Format date if it exists
        let formattedDate: string | undefined;
        if (datePaidValue) {
          // Create date object and handle timezone
          const dateObj = new Date(datePaidValue);
          // Get year, month, and day components
          const year = dateObj.getFullYear();
          // getMonth() returns 0-11, so add 1
          const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
          const day = dateObj.getDate().toString().padStart(2, '0');
          // Format as YYYY-MM-DD without timezone conversion
          formattedDate = `${year}-${month}-${day}`;
        }

        return {
          Id: row.InvoicePaymentId,
          DatePaid: formattedDate,
          TransactionNumber: this.bulkEditRemittancesForm.value.TransactionNumber || undefined,
          PaymentMethod: this.bulkEditRemittancesForm.value.PaymentMethod || undefined,
          PaymentStatus: this.bulkEditRemittancesForm.value.PaymentStatus || undefined
        };
      });

      const syncFusionDTO = {
        Action: "Update",
        Added: [],
        Changed: changedPayments,
        Deleted: []
      };

      const response = await this.api.basicPost(APIEndpoints.InvoicePaymentsBatch, syncFusionDTO);
      
      this.loadingForm = false;
      window.location.reload();
      return 'success';

    } catch (error) {
      console.error('Error updating payments:', error);
      this.loadingForm = false;
      return 'error';
    }
  }

  markAllFieldsAsTouched(): void {
    Object.values(this.bulkEditRemittancesForm.controls).forEach(control => {
      control.markAsTouched();
      control.markAsDirty();
      control.updateValueAndValidity();
    });
  }

  // Clear form
  clearForm = () => {
    this.bulkEditRemittancesForm.reset();
  }
  
  // Set different error messages for each input field
  getErrorMessage(controlName: string): string {
    let message = '';
    const control = this.bulkEditRemittancesForm.get(controlName);
    if (control?.errors) {
      if (control.errors['required']) message = 'This field is required';
      if (control.errors['serverError']) message = control.errors['serverError'].message;
    };
    this.errorMessage = message;
    return message;
  }

  // Changes form to 2 columns when parent is larger 1600px
  updateWidth(containerWidth: number) {

    if (containerWidth > 1200) {
      this.formClasses.form = this.formClasses.form.replace('flex-column', 'flex-row flex-wrap');
      this.formClasses.twoCol = this.formClasses.twoCol.replace('col-12', 'col-6')  ;
    } else {
      this.formClasses.form = this.formClasses.form.replace('flex-row', 'flex-column');
      this.formClasses.twoCol = this.formClasses.twoCol.replace('col-6', 'col-12');
    }
  }

  // Update visual styles on input focus and blur
  watchInputElements() {
    document.querySelectorAll(`.cc-input-container`).forEach((inputContainer) => {
      const container = inputContainer as HTMLElement;

      container.querySelectorAll('input, span, textarea').forEach((input) => {
        input.addEventListener('focus', () => inputContainer.classList.add('focus'));
        input.addEventListener('blur', () => inputContainer.classList.remove('focus'));
      });
    });
  }

  // Get paymnet methods
  onBeforeOpenPaymentMethod = (args: any) => {
    this.paymentMethodData = this.api.getOdata(APIEndpoints.PaymentMethods);
    this.paymentMethodQuery = new Query().select('Id,Description');
  }

  // Get payment statuses
  onBeforeOpenPaymentStatus = (args: any) => {
    this.paymentStatusData = this.api.getOdata(APIEndpoints.PaymentStatuses);
    this.paymentStatusQuery = new Query().select('Id,Description');
  }
}
