import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'form-field',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule],
  template: `
    <div class="cc-input-container">
      <span class="icon">
        <fa-icon [icon]="icon" />
      </span>
      <ng-content></ng-content>
    </div>
  `
})
export class FormFieldComponent {
  @Input() icon: any;
  @Input() label: string;
  @Input() required = false;
  @Input() control!: AbstractControl;
  @Input() fieldName?: string;
}   