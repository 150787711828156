<div class="balance-statement-container">
    <form [formGroup]="formGroup">
        <app-dropdown-multi [formControlName]="'pandls'" [placeholder]="'Select P&Ls'"
            [floatLabelType]="floatLabelType">
        </app-dropdown-multi>

        <div class="controls">
            <ejs-checkbox [(ngModel)]="final" [ngModelOptions]="{standalone: true}" label="Final Statement">
            </ejs-checkbox>

            <button ejs-button [isPrimary]="true" [disabled]="loading() || !formGroup.valid"
                (click)="generateBalanceStatement()">
                <ng-container *ngIf="!loading(); else loadingTpl">
                    Generate Statement
                </ng-container>
                <ng-template #loadingTpl>
                    <i class="fa fa-spinner fa-spin"></i>
                    Generating...
                </ng-template>
            </button>
        </div>
    </form>

    <app-balance-due-grid #BalanceDueGrid [caseFileId]="caseFileId">
    </app-balance-due-grid>
</div>
