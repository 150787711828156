<div id="theme">

    <ul class="d-flex flex-row flex-wrap">

        @for (theme of themes; track $index)  {

            <li>
                <ejs-tooltip [content]="theme.Id"> 
                    <button ejs-button class="theme-button" (click)="selectTheme(theme)">
                        <div class="theme-button-sample {{theme.Id}}" [style]="bodyGradient(theme.PrimaryColor, theme.SecondaryColor)">
                            <div class="menu"></div>
                            <div class="body">
                                <span><strong>{{theme.Title}}</strong></span>
                            </div>
                        </div>
                    </button>
                </ejs-tooltip>
            </li>
        }

    </ul>

    <div id="custom-theme">
        <label>Custom Theme</label>
        <theme-color-picker />
    </div>
</div>