<div #card id="card" class="card d-flex flex-column {{cssClass}}" [ngClass]="{ 'pad-bottom' : !footerString && !footerContentWrapper }">

    <header id="card-header" class="header d-flex justify-content-between align-items-center">
        @if (cardSettings.cardTitle) { <h3>{{cardSettings.cardTitle}}</h3> }
        @else { <h3>Card Settings</h3> }
        <div class="settings-menu">
            <button ejs-button cssClass="e-flat" iconCss="e-icons e-open-link" (click)="expandCard($event)"></button>
            <ejs-menu [items]="cardSettings.cardSettingsMenu ? cardSettings.cardSettingsMenu : defaultSettings.cardSettingsMenu" (select)="settingsMenuClick($event)"></ejs-menu>
        </div>
    </header>

    <main id="card-content" class="content" style="flex-grow: 1;">
        @if(cardSettings.gridSettings) {
            <grid-template #cardGrid [settings]="cardSettings.gridSettings"></grid-template>
            <div *ngIf="gridOverlay" class="overlay"></div>
        }
    </main>
    
    <footer *ngIf="footerContentWrapper || footerString" id="card-footer" class="footer">
        <div *ngIf="footerString" [innerHTML]="footerString"></div>
        <ng-container *ngTemplateOutlet="footerContentWrapper; context: { $implicit: footerContent }"></ng-container>
    </footer>
</div>