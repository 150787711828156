<div id="fee-schedules-grid" class="fee-schedules-grid">

    <grid-template #feeSchedulesGrid [settings]="feeSchedulesGridSettings" [name]="'Fee Schedules'" />

    <custom-assignments-editor #customProvidersEditor 
        [availableGridSettings]="availableProvidersGridSettings"
        [assignmentsGridSettings]="assignedProvidersGridSettings"
        [assignmentsGridPredicate]="attachedProvidersPredicate"
        [assignmentsGridPredicateField]="'Name'"
        [assignmentsIds]="assignedProvidersIds" />

    <ejs-dialog #addFeeScheduleDialog
        [showCloseIcon]="true"
        [target]="'#fee-schedules-grid'"
        [isModal]="true"
        [closeOnEscape]="true"
        [visible]="isAddFeeScheduleDialogVisible"
        [header]="'Add Fee Schedule'"
        (beforeOpen)="beforeOpenAddFeeScheduleDialog($event)">
            <add-fee-schedule-form #addFeeScheduleForm></add-fee-schedule-form>
    </ejs-dialog>

</div>