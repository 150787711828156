import { __decorate } from 'tslib';
import * as i0 from '@angular/core';
import { forwardRef, Component, ChangeDetectionStrategy, NgModule, Directive, ContentChildren, ContentChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { setValue, FormBase, ComponentBase, ComponentMixins, ComplexBase, ArrayBase, Template } from '@syncfusion/ej2-angular-base';
import { Calendar, Islamic, DatePicker, MaskedDateTime, TimePicker, DateRangePicker, DateTimePicker } from '@syncfusion/ej2-calendars';
const _c0 = ["start"];
const _c1 = ["end"];
export * from '@syncfusion/ej2-calendars';
import { CommonModule } from '@angular/common';
var CalendarComponent_1;
const inputs$4 = ['calendarMode', 'cssClass', 'dayHeaderFormat', 'depth', 'enablePersistence', 'enableRtl', 'enabled', 'firstDayOfWeek', 'isMultiSelection', 'keyConfigs', 'locale', 'max', 'min', 'serverTimezoneOffset', 'showTodayButton', 'start', 'value', 'values', 'weekNumber', 'weekRule'];
const outputs$5 = ['focus', 'blur', 'change', 'created', 'destroyed', 'navigated', 'renderDayCell', 'valueChange', 'valuesChange'];
const twoWays$4 = ['value', 'values'];
/**
 * Represents the Essential JS 2 Angular Calendar Component.
 * ```html
 * <ejs-calendar [value]='date'></ejs-calendar>
 * ```
 */
let CalendarComponent = CalendarComponent_1 = class CalendarComponent extends Calendar {
  constructor(ngEle, srenderer, viewContainerRef, injector, cdr) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.cdr = cdr;
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    try {
      let mod = this.injector.get('CalendarsIslamic');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    this.registerEvents(outputs$5);
    this.addTwoWay.call(this, twoWays$4);
    setValue('currentInstance', this, this.viewContainerRef);
    this.formContext = new FormBase();
    this.formCompContext = new ComponentBase();
  }
  registerOnChange(registerFunction) {}
  registerOnTouched(registerFunction) {}
  writeValue(value) {}
  setDisabledState(disabled) {}
  ngOnInit() {
    this.formCompContext.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.formContext.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.formCompContext.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.formCompContext.ngAfterContentChecked(this);
  }
};
CalendarComponent.ɵfac = function CalendarComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || CalendarComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
CalendarComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: CalendarComponent,
  selectors: [["ejs-calendar"]],
  inputs: {
    calendarMode: "calendarMode",
    cssClass: "cssClass",
    dayHeaderFormat: "dayHeaderFormat",
    depth: "depth",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    enabled: "enabled",
    firstDayOfWeek: "firstDayOfWeek",
    isMultiSelection: "isMultiSelection",
    keyConfigs: "keyConfigs",
    locale: "locale",
    max: "max",
    min: "min",
    serverTimezoneOffset: "serverTimezoneOffset",
    showTodayButton: "showTodayButton",
    start: "start",
    value: "value",
    values: "values",
    weekNumber: "weekNumber",
    weekRule: "weekRule"
  },
  outputs: {
    focus: "focus",
    blur: "blur",
    change: "change",
    created: "created",
    destroyed: "destroyed",
    navigated: "navigated",
    renderDayCell: "renderDayCell",
    valueChange: "valueChange",
    valuesChange: "valuesChange"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CalendarComponent_1),
    multi: true
  }]), i0.ɵɵInheritDefinitionFeature],
  decls: 0,
  vars: 0,
  template: function CalendarComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
CalendarComponent = CalendarComponent_1 = __decorate([ComponentMixins([ComponentBase, FormBase])], CalendarComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CalendarComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-calendar',
      inputs: inputs$4,
      outputs: outputs$5,
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => CalendarComponent),
        multi: true
      }],
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, null);
})();

/**
 * NgModule definition for the Calendar component.
 */
class CalendarModule {}
CalendarModule.ɵfac = function CalendarModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || CalendarModule)();
};
CalendarModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: CalendarModule
});
CalendarModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CalendarModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [CalendarComponent],
      exports: [CalendarComponent]
    }]
  }], null, null);
})();
const IslamicService = {
  provide: 'CalendarsIslamic',
  useValue: Islamic
};
/**
 * NgModule definition for the Calendar component with providers.
 */
class CalendarAllModule {}
CalendarAllModule.ɵfac = function CalendarAllModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || CalendarAllModule)();
};
CalendarAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: CalendarAllModule
});
CalendarAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [IslamicService],
  imports: [[CommonModule, CalendarModule], CalendarModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CalendarAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, CalendarModule],
      exports: [CalendarModule],
      providers: [IslamicService]
    }]
  }], null, null);
})();
var DatePickerComponent_1;
const inputs$3 = ['allowEdit', 'calendarMode', 'cssClass', 'dayHeaderFormat', 'depth', 'enableMask', 'enablePersistence', 'enableRtl', 'enabled', 'firstDayOfWeek', 'floatLabelType', 'format', 'fullScreenMode', 'htmlAttributes', 'inputFormats', 'isMultiSelection', 'keyConfigs', 'locale', 'maskPlaceholder', 'max', 'min', 'openOnFocus', 'placeholder', 'readonly', 'serverTimezoneOffset', 'showClearButton', 'showTodayButton', 'start', 'strictMode', 'value', 'values', 'weekNumber', 'weekRule', 'width', 'zIndex'];
const outputs$4 = ['blur', 'change', 'cleared', 'close', 'created', 'destroyed', 'focus', 'navigated', 'open', 'renderDayCell', 'valueChange'];
const twoWays$3 = ['value'];
/**
 * Represents the Essential JS 2 Angular DatePicker Component.
 * ```html
 * <ejs-datepicker [value]='date'></ejs-datepicker>
 * ```
 */
let DatePickerComponent = DatePickerComponent_1 = class DatePickerComponent extends DatePicker {
  constructor(ngEle, srenderer, viewContainerRef, injector, cdr) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.cdr = cdr;
    this.skipFromEvent = true;
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    try {
      let mod = this.injector.get('CalendarsIslamic');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('CalendarsMaskedDateTime');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    this.registerEvents(outputs$4);
    this.addTwoWay.call(this, twoWays$3);
    setValue('currentInstance', this, this.viewContainerRef);
    this.formContext = new FormBase();
    this.formCompContext = new ComponentBase();
  }
  registerOnChange(registerFunction) {}
  registerOnTouched(registerFunction) {}
  writeValue(value) {}
  setDisabledState(disabled) {}
  ngOnInit() {
    this.formCompContext.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.formContext.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.formCompContext.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.formCompContext.ngAfterContentChecked(this);
  }
};
DatePickerComponent.ɵfac = function DatePickerComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DatePickerComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
DatePickerComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DatePickerComponent,
  selectors: [["ejs-datepicker"]],
  inputs: {
    allowEdit: "allowEdit",
    calendarMode: "calendarMode",
    cssClass: "cssClass",
    dayHeaderFormat: "dayHeaderFormat",
    depth: "depth",
    enableMask: "enableMask",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    enabled: "enabled",
    firstDayOfWeek: "firstDayOfWeek",
    floatLabelType: "floatLabelType",
    format: "format",
    fullScreenMode: "fullScreenMode",
    htmlAttributes: "htmlAttributes",
    inputFormats: "inputFormats",
    isMultiSelection: "isMultiSelection",
    keyConfigs: "keyConfigs",
    locale: "locale",
    maskPlaceholder: "maskPlaceholder",
    max: "max",
    min: "min",
    openOnFocus: "openOnFocus",
    placeholder: "placeholder",
    readonly: "readonly",
    serverTimezoneOffset: "serverTimezoneOffset",
    showClearButton: "showClearButton",
    showTodayButton: "showTodayButton",
    start: "start",
    strictMode: "strictMode",
    value: "value",
    values: "values",
    weekNumber: "weekNumber",
    weekRule: "weekRule",
    width: "width",
    zIndex: "zIndex"
  },
  outputs: {
    blur: "blur",
    change: "change",
    cleared: "cleared",
    close: "close",
    created: "created",
    destroyed: "destroyed",
    focus: "focus",
    navigated: "navigated",
    open: "open",
    renderDayCell: "renderDayCell",
    valueChange: "valueChange"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DatePickerComponent_1),
    multi: true
  }]), i0.ɵɵInheritDefinitionFeature],
  decls: 0,
  vars: 0,
  template: function DatePickerComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
DatePickerComponent = DatePickerComponent_1 = __decorate([ComponentMixins([ComponentBase, FormBase])], DatePickerComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DatePickerComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-datepicker',
      inputs: inputs$3,
      outputs: outputs$4,
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => DatePickerComponent),
        multi: true
      }],
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, null);
})();

/**
 * NgModule definition for the DatePicker component.
 */
class DatePickerModule {}
DatePickerModule.ɵfac = function DatePickerModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DatePickerModule)();
};
DatePickerModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: DatePickerModule
});
DatePickerModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DatePickerModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [DatePickerComponent],
      exports: [DatePickerComponent]
    }]
  }], null, null);
})();
const MaskedDateTimeService = {
  provide: 'CalendarsMaskedDateTime',
  useValue: MaskedDateTime
};
/**
 * NgModule definition for the DatePicker component with providers.
 */
class DatePickerAllModule {}
DatePickerAllModule.ɵfac = function DatePickerAllModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DatePickerAllModule)();
};
DatePickerAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: DatePickerAllModule
});
DatePickerAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [MaskedDateTimeService],
  imports: [[CommonModule, DatePickerModule], DatePickerModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DatePickerAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, DatePickerModule],
      exports: [DatePickerModule],
      providers: [MaskedDateTimeService]
    }]
  }], null, null);
})();
var TimePickerComponent_1;
const inputs$2 = ['allowEdit', 'cssClass', 'enableMask', 'enablePersistence', 'enableRtl', 'enabled', 'floatLabelType', 'format', 'fullScreenMode', 'htmlAttributes', 'keyConfigs', 'locale', 'maskPlaceholder', 'max', 'min', 'openOnFocus', 'placeholder', 'readonly', 'scrollTo', 'serverTimezoneOffset', 'showClearButton', 'step', 'strictMode', 'value', 'width', 'zIndex'];
const outputs$3 = ['blur', 'change', 'cleared', 'close', 'created', 'destroyed', 'focus', 'itemRender', 'open', 'valueChange'];
const twoWays$2 = ['value'];
/**
 * Represents the Essential JS 2 Angular TimePicker Component.
 * ```html
 * <ejs-timepicker [value]='dateTime'></ejs-timepicker>
 * ```
 */
let TimePickerComponent = TimePickerComponent_1 = class TimePickerComponent extends TimePicker {
  constructor(ngEle, srenderer, viewContainerRef, injector, cdr) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.cdr = cdr;
    this.skipFromEvent = true;
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    try {
      let mod = this.injector.get('CalendarsMaskedDateTime');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    this.registerEvents(outputs$3);
    this.addTwoWay.call(this, twoWays$2);
    setValue('currentInstance', this, this.viewContainerRef);
    this.formContext = new FormBase();
    this.formCompContext = new ComponentBase();
  }
  registerOnChange(registerFunction) {}
  registerOnTouched(registerFunction) {}
  writeValue(value) {}
  setDisabledState(disabled) {}
  ngOnInit() {
    this.formCompContext.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.formContext.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.formCompContext.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.formCompContext.ngAfterContentChecked(this);
  }
};
TimePickerComponent.ɵfac = function TimePickerComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TimePickerComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
TimePickerComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TimePickerComponent,
  selectors: [["ejs-timepicker"]],
  inputs: {
    allowEdit: "allowEdit",
    cssClass: "cssClass",
    enableMask: "enableMask",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    enabled: "enabled",
    floatLabelType: "floatLabelType",
    format: "format",
    fullScreenMode: "fullScreenMode",
    htmlAttributes: "htmlAttributes",
    keyConfigs: "keyConfigs",
    locale: "locale",
    maskPlaceholder: "maskPlaceholder",
    max: "max",
    min: "min",
    openOnFocus: "openOnFocus",
    placeholder: "placeholder",
    readonly: "readonly",
    scrollTo: "scrollTo",
    serverTimezoneOffset: "serverTimezoneOffset",
    showClearButton: "showClearButton",
    step: "step",
    strictMode: "strictMode",
    value: "value",
    width: "width",
    zIndex: "zIndex"
  },
  outputs: {
    blur: "blur",
    change: "change",
    cleared: "cleared",
    close: "close",
    created: "created",
    destroyed: "destroyed",
    focus: "focus",
    itemRender: "itemRender",
    open: "open",
    valueChange: "valueChange"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TimePickerComponent_1),
    multi: true
  }]), i0.ɵɵInheritDefinitionFeature],
  decls: 0,
  vars: 0,
  template: function TimePickerComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
TimePickerComponent = TimePickerComponent_1 = __decorate([ComponentMixins([ComponentBase, FormBase])], TimePickerComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TimePickerComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-timepicker',
      inputs: inputs$2,
      outputs: outputs$3,
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => TimePickerComponent),
        multi: true
      }],
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, null);
})();

/**
 * NgModule definition for the TimePicker component.
 */
class TimePickerModule {}
TimePickerModule.ɵfac = function TimePickerModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TimePickerModule)();
};
TimePickerModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TimePickerModule
});
TimePickerModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TimePickerModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [TimePickerComponent],
      exports: [TimePickerComponent]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the TimePicker component with providers.
 */
class TimePickerAllModule {}
TimePickerAllModule.ɵfac = function TimePickerAllModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TimePickerAllModule)();
};
TimePickerAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TimePickerAllModule
});
TimePickerAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, TimePickerModule], TimePickerModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TimePickerAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, TimePickerModule],
      exports: [TimePickerModule],
      providers: []
    }]
  }], null, null);
})();
let input = ['end', 'label', 'start'];
let outputs$2 = [];
/**
 * 'e-presets' directive represent a presets of angular daterangepicker
 * It must be contained in a daterangepicker component(`ej-daterangepicker`).
 * ```html
 * <ejs-daterangepicker id='range'>
 *   <e-presets>
 *    <e-preset label='Last Week' [start]=new Date('06/07/2018') [end]= new Date('06/01/2018')></e-preset>
 *    <e-preset label='Last Month' [start]=new Date('06/07/2018') [end]= new Date('05/07/2018')></e-preset>
 *   </e-presets>
 * </ejs-daterangepicker>
 * ```
 */
class PresetDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$2);
    this.directivePropList = input;
  }
}
PresetDirective.ɵfac = function PresetDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || PresetDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
PresetDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: PresetDirective,
  selectors: [["e-preset"]],
  inputs: {
    end: "end",
    label: "label",
    start: "start"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PresetDirective, [{
    type: Directive,
    args: [{
      selector: 'e-presets>e-preset',
      inputs: input,
      outputs: outputs$2,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, null);
})();
/**
 * Preset Array Directive
 * @private
 */
class PresetsDirective extends ArrayBase {
  constructor() {
    super('presets');
  }
}
PresetsDirective.ɵfac = function PresetsDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || PresetsDirective)();
};
PresetsDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: PresetsDirective,
  selectors: [["e-presets"]],
  contentQueries: function PresetsDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, PresetDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PresetsDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-daterangepicker>e-presets',
      queries: {
        children: new ContentChildren(PresetDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
var DateRangePickerComponent_1;
const inputs$1 = ['allowEdit', 'calendarMode', 'cssClass', 'dayHeaderFormat', 'depth', 'enablePersistence', 'enableRtl', 'enabled', 'endDate', 'firstDayOfWeek', 'floatLabelType', 'format', 'fullScreenMode', 'htmlAttributes', 'keyConfigs', 'locale', 'max', 'maxDays', 'min', 'minDays', 'openOnFocus', 'placeholder', 'presets', 'readonly', 'separator', 'serverTimezoneOffset', 'showClearButton', 'start', 'startDate', 'strictMode', 'value', 'weekNumber', 'weekRule', 'width', 'zIndex'];
const outputs$1 = ['blur', 'change', 'cleared', 'close', 'created', 'destroyed', 'focus', 'navigated', 'open', 'renderDayCell', 'select', 'startDateChange', 'endDateChange', 'valueChange'];
const twoWays$1 = ['startDate', 'endDate', 'value'];
/**
 * Represents the Essential JS 2 Angular DateRangePicker Component.
 * ```html
 * <ejs-daterangepicker [startDate]='date' [endDate]='date'></ejs-daterangepicker>
 * ```
 */
let DateRangePickerComponent = DateRangePickerComponent_1 = class DateRangePickerComponent extends DateRangePicker {
  constructor(ngEle, srenderer, viewContainerRef, injector, cdr) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.cdr = cdr;
    this.tags = ['presets'];
    this.skipFromEvent = true;
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    this.registerEvents(outputs$1);
    this.addTwoWay.call(this, twoWays$1);
    setValue('currentInstance', this, this.viewContainerRef);
    this.formContext = new FormBase();
    this.formCompContext = new ComponentBase();
  }
  registerOnChange(registerFunction) {}
  registerOnTouched(registerFunction) {}
  writeValue(value) {}
  setDisabledState(disabled) {}
  ngOnInit() {
    this.formCompContext.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.formContext.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.formCompContext.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.tagObjects[0].instance = this.childPresets;
    this.formCompContext.ngAfterContentChecked(this);
  }
};
DateRangePickerComponent.ɵfac = function DateRangePickerComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DateRangePickerComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
DateRangePickerComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DateRangePickerComponent,
  selectors: [["ejs-daterangepicker"]],
  contentQueries: function DateRangePickerComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c0, 5);
      i0.ɵɵcontentQuery(dirIndex, _c1, 5);
      i0.ɵɵcontentQuery(dirIndex, PresetsDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.start = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.end = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childPresets = _t.first);
    }
  },
  inputs: {
    allowEdit: "allowEdit",
    calendarMode: "calendarMode",
    cssClass: "cssClass",
    dayHeaderFormat: "dayHeaderFormat",
    depth: "depth",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    enabled: "enabled",
    endDate: "endDate",
    firstDayOfWeek: "firstDayOfWeek",
    floatLabelType: "floatLabelType",
    format: "format",
    fullScreenMode: "fullScreenMode",
    htmlAttributes: "htmlAttributes",
    keyConfigs: "keyConfigs",
    locale: "locale",
    max: "max",
    maxDays: "maxDays",
    min: "min",
    minDays: "minDays",
    openOnFocus: "openOnFocus",
    placeholder: "placeholder",
    presets: "presets",
    readonly: "readonly",
    separator: "separator",
    serverTimezoneOffset: "serverTimezoneOffset",
    showClearButton: "showClearButton",
    start: "start",
    startDate: "startDate",
    strictMode: "strictMode",
    value: "value",
    weekNumber: "weekNumber",
    weekRule: "weekRule",
    width: "width",
    zIndex: "zIndex"
  },
  outputs: {
    blur: "blur",
    change: "change",
    cleared: "cleared",
    close: "close",
    created: "created",
    destroyed: "destroyed",
    focus: "focus",
    navigated: "navigated",
    open: "open",
    renderDayCell: "renderDayCell",
    select: "select",
    startDateChange: "startDateChange",
    endDateChange: "endDateChange",
    valueChange: "valueChange"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DateRangePickerComponent_1),
    multi: true
  }]), i0.ɵɵInheritDefinitionFeature],
  decls: 0,
  vars: 0,
  template: function DateRangePickerComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
__decorate([Template()], DateRangePickerComponent.prototype, "start", void 0);
__decorate([Template()], DateRangePickerComponent.prototype, "end", void 0);
DateRangePickerComponent = DateRangePickerComponent_1 = __decorate([ComponentMixins([ComponentBase, FormBase])], DateRangePickerComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DateRangePickerComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-daterangepicker',
      inputs: inputs$1,
      outputs: outputs$1,
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => DateRangePickerComponent),
        multi: true
      }],
      queries: {
        childPresets: new ContentChild(PresetsDirective)
      }
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, {
    start: [{
      type: ContentChild,
      args: ['start']
    }],
    end: [{
      type: ContentChild,
      args: ['end']
    }]
  });
})();

/**
 * NgModule definition for the DateRangePicker component.
 */
class DateRangePickerModule {}
DateRangePickerModule.ɵfac = function DateRangePickerModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DateRangePickerModule)();
};
DateRangePickerModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: DateRangePickerModule
});
DateRangePickerModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DateRangePickerModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [DateRangePickerComponent, PresetDirective, PresetsDirective],
      exports: [DateRangePickerComponent, PresetDirective, PresetsDirective]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the DateRangePicker component with providers.
 */
class DateRangePickerAllModule {}
DateRangePickerAllModule.ɵfac = function DateRangePickerAllModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DateRangePickerAllModule)();
};
DateRangePickerAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: DateRangePickerAllModule
});
DateRangePickerAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, DateRangePickerModule], DateRangePickerModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DateRangePickerAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, DateRangePickerModule],
      exports: [DateRangePickerModule],
      providers: []
    }]
  }], null, null);
})();
var DateTimePickerComponent_1;
const inputs = ['allowEdit', 'calendarMode', 'cssClass', 'dayHeaderFormat', 'depth', 'enableMask', 'enablePersistence', 'enableRtl', 'enabled', 'firstDayOfWeek', 'floatLabelType', 'format', 'fullScreenMode', 'htmlAttributes', 'inputFormats', 'isMultiSelection', 'keyConfigs', 'locale', 'maskPlaceholder', 'max', 'maxTime', 'min', 'minTime', 'openOnFocus', 'placeholder', 'readonly', 'scrollTo', 'serverTimezoneOffset', 'showClearButton', 'showTodayButton', 'start', 'step', 'strictMode', 'timeFormat', 'value', 'values', 'weekNumber', 'weekRule', 'width', 'zIndex'];
const outputs = ['blur', 'change', 'cleared', 'close', 'created', 'destroyed', 'focus', 'navigated', 'open', 'renderDayCell', 'valueChange'];
const twoWays = ['value'];
/**
 * Represents the Essential JS 2 Angular DateTimePicker Component.
 * ```html
 * <ejs-datetimepicker [value]='dateTime'></ejs-datetimepicker>
 * ```
 */
let DateTimePickerComponent = DateTimePickerComponent_1 = class DateTimePickerComponent extends DateTimePicker {
  constructor(ngEle, srenderer, viewContainerRef, injector, cdr) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.cdr = cdr;
    this.skipFromEvent = true;
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    try {
      let mod = this.injector.get('CalendarsIslamic');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('CalendarsMaskedDateTime');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    this.registerEvents(outputs);
    this.addTwoWay.call(this, twoWays);
    setValue('currentInstance', this, this.viewContainerRef);
    this.formContext = new FormBase();
    this.formCompContext = new ComponentBase();
  }
  registerOnChange(registerFunction) {}
  registerOnTouched(registerFunction) {}
  writeValue(value) {}
  setDisabledState(disabled) {}
  ngOnInit() {
    this.formCompContext.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.formContext.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.formCompContext.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.formCompContext.ngAfterContentChecked(this);
  }
};
DateTimePickerComponent.ɵfac = function DateTimePickerComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DateTimePickerComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
DateTimePickerComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DateTimePickerComponent,
  selectors: [["ejs-datetimepicker"]],
  inputs: {
    allowEdit: "allowEdit",
    calendarMode: "calendarMode",
    cssClass: "cssClass",
    dayHeaderFormat: "dayHeaderFormat",
    depth: "depth",
    enableMask: "enableMask",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    enabled: "enabled",
    firstDayOfWeek: "firstDayOfWeek",
    floatLabelType: "floatLabelType",
    format: "format",
    fullScreenMode: "fullScreenMode",
    htmlAttributes: "htmlAttributes",
    inputFormats: "inputFormats",
    isMultiSelection: "isMultiSelection",
    keyConfigs: "keyConfigs",
    locale: "locale",
    maskPlaceholder: "maskPlaceholder",
    max: "max",
    maxTime: "maxTime",
    min: "min",
    minTime: "minTime",
    openOnFocus: "openOnFocus",
    placeholder: "placeholder",
    readonly: "readonly",
    scrollTo: "scrollTo",
    serverTimezoneOffset: "serverTimezoneOffset",
    showClearButton: "showClearButton",
    showTodayButton: "showTodayButton",
    start: "start",
    step: "step",
    strictMode: "strictMode",
    timeFormat: "timeFormat",
    value: "value",
    values: "values",
    weekNumber: "weekNumber",
    weekRule: "weekRule",
    width: "width",
    zIndex: "zIndex"
  },
  outputs: {
    blur: "blur",
    change: "change",
    cleared: "cleared",
    close: "close",
    created: "created",
    destroyed: "destroyed",
    focus: "focus",
    navigated: "navigated",
    open: "open",
    renderDayCell: "renderDayCell",
    valueChange: "valueChange"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DateTimePickerComponent_1),
    multi: true
  }]), i0.ɵɵInheritDefinitionFeature],
  decls: 0,
  vars: 0,
  template: function DateTimePickerComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
DateTimePickerComponent = DateTimePickerComponent_1 = __decorate([ComponentMixins([ComponentBase, FormBase])], DateTimePickerComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DateTimePickerComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-datetimepicker',
      inputs: inputs,
      outputs: outputs,
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => DateTimePickerComponent),
        multi: true
      }],
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, null);
})();

/**
 * NgModule definition for the DateTimePicker component.
 */
class DateTimePickerModule {}
DateTimePickerModule.ɵfac = function DateTimePickerModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DateTimePickerModule)();
};
DateTimePickerModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: DateTimePickerModule
});
DateTimePickerModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DateTimePickerModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [DateTimePickerComponent],
      exports: [DateTimePickerComponent]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the DateTimePicker component with providers.
 */
class DateTimePickerAllModule {}
DateTimePickerAllModule.ɵfac = function DateTimePickerAllModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DateTimePickerAllModule)();
};
DateTimePickerAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: DateTimePickerAllModule
});
DateTimePickerAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, DateTimePickerModule], DateTimePickerModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DateTimePickerAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, DateTimePickerModule],
      exports: [DateTimePickerModule],
      providers: []
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CalendarAllModule, CalendarComponent, CalendarModule, DatePickerAllModule, DatePickerComponent, DatePickerModule, DateRangePickerAllModule, DateRangePickerComponent, DateRangePickerModule, DateTimePickerAllModule, DateTimePickerComponent, DateTimePickerModule, IslamicService, MaskedDateTimeService, PresetDirective, PresetsDirective, TimePickerAllModule, TimePickerComponent, TimePickerModule };
