// Angular
import { CommonModule, DatePipe } from '@angular/common';
import { Component, signal, WritableSignal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// 3rd Party
import { Query } from '@syncfusion/ej2-data';

// Models
import { APIEndpoints } from '@root/src/app/shared/models/api/Endpoints';

// Services
import { ApiService } from '@root/src/app/shared/services/api/api.service';

// Components
import { AccountDetailsComponent } from '../../../shared/account-details/account-details.component';

@Component({
  selector: 'app-patient-detail',
  standalone: true,
  imports: [
    CommonModule,
    AccountDetailsComponent
  ],
  templateUrl: './patient-detail.component.html',
  styleUrl: './patient-detail.component.scss',
  providers: [
    DatePipe
  ]
})
export class PatientDetailComponent {

  // Signals
  patientData: WritableSignal<any> = signal(undefined);
  isLoading: WritableSignal<boolean> = signal(true);

  // Public Properties
  route: ActivatedRoute;
  entityType = APIEndpoints.Patients;
  expandableDetails: Array<{ label: string, value: any }> = [];  
  patientDetails: Array<Array<{ label: string, value: any }>> = [];

  constructor(
    private datePipe: DatePipe,
    private api: ApiService,
    route: ActivatedRoute
  ) {
    this.route = route; 
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.getPatientById(parseInt(params['id']));
    });
  }

  query= new Query().expand('XrefPhonePatients,XrefAddressPatients($expand=Address($expand=StateNavigation))');

  
  private getPatientById(id: number) {
    this.isLoading.set(true);
    this.api.getOdata(`${APIEndpoints.Patients}(${id})`).executeQuery(this.query)
      .then((response: any) => {
        this.patientData.set(response.result[0]);
        this.setupPatientDetails();
      })
      .catch((error: any) => {
        console.error('Error fetching law firm:', error);
      })
      .finally(() => {
        this.isLoading.set(false);
      })
  }

  private setupPatientDetails() {
    const patientData = this.patientData();
    const address = patientData?.XrefAddressPatients?.[0]?.Address ?? {};
    const stateName = address.StateNavigation?.Name || 'N/A';
    const phoneNumber = patientData?.XrefPhonePatients?.[0]?.PhoneNumber || 'N/A';
    const patientName = `${patientData?.Firstname || 'N/A'} ${patientData?.Lastname || 'N/A'}`;

    this.expandableDetails = [
      { label: 'Name', value: patientName },
      { label: 'Phone', value: phoneNumber },
      { label: 'City', value: address.City ?? 'N/A' },
    ];

    this.patientDetails = [
      [
        { label: 'Name', value: patientName },
        { label: 'Main Phone', value: phoneNumber },
        { label: 'Created At', value: this.datePipe.transform(patientData?.CreatedAt, 'MM/dd/yyyy') ?? 'N/A' },
        { label: 'Modified At', value: this.datePipe.transform(patientData?.UpdatedAt, 'MM/dd/yyyy') ?? 'N/A' }
      ],
      [
        { label: 'Address', value: `${address.Address1 ?? ''}, ${address.Address2 ?? ''}`.trim() },
        { label: 'City', value: address.City ?? 'N/A' },
        { label: 'State', value: stateName },
        { label: 'Zip', value: address.Zip ?? 'N/A' },
      ],
      [
        { label: 'DOB', value: patientData?.Dob ?? 'N/A' },
        { label: 'Gender', value: patientData?.Gender ?? 'N/A' },
        { label: 'Language', value: patientData?.Language ?? 'N/A' },
        { label: 'Email', value: patientData?.Email ?? 'N/A' },
        { label: 'Minor', value: patientData?.Minor ?? 'N/A' },
        { label: 'Is Active', value: patientData?.IsActive ?? 'N/A' },
      ]
    ];
  }
}
