<div id="users-roles-grid" class="users-roles-grid row">

    <div class="col-12 col-sm-5 form-container">
        <tab-card [data]="roles">
            <add-role [rolesGrid]="roles" [showSubmitButton]="true" /> 
        </tab-card>
    </div>

    <div class="col-12 col-sm-7">
        <base-grid #usersRolesGridComponent [settings]="rolesGridSettings"></base-grid>

        <!-- Custom rights editor -->
        <ejs-dialog #editRoleDialog
            [showCloseIcon]="true"
            [target]="'body'"
            [isModal]="true"
            [closeOnEscape]="true"
            [visible]="false"
            [header]="editRoleDialogHeader"
            (close)="dialogClose()"
            (beforeOpen)="beforeOpenEditRightsDialog($event)">

            <div id="rights-editor" class="rights-editor">

                <div class="row d-flex">
                    <div class="col-12 col-md-5 rights-editor-available">  
                        <ejs-grid #availableRightsGrid
                            [height]="'250'"
                            [allowPaging]="false" 
                            [dataSource]="availableRightsGridSettings.dataSource" 
                            [columns]="availableRightsGridSettings.columns"
                            [editSettings]="availableRightsGridSettings.editSettings"
                            (rowDataBound)="customizeAvailableRightsBackgroundColor($event)" />
                    </div>
                    <div class="rights-editor-actions col-12 col-md-2 d-flex justify-content-center align-items-center">
                        <div class="row">
                            <div class="col col-6">
                                <button ejs-button cssClass="e-outline" [isPrimary]="false" (click)="removeSelectedRoleToAssignedRightsGrid()"><span class="e-icons e-chevron-left-double"></span></button>
                            </div>
                            <div class="col col-6">
                                <button ejs-button cssClass="e-outline" [isPrimary]="false" (click)="addSelectedRoleToAssignedRightsGrid()"><span class="e-icons e-chevron-right-double"></span></button>
                            </div>
                            <div class="col col-12">
                                <button ejs-button [isPrimary]="true" type="submit" (click)="submitRoleChanges()">Submit</button>
                            </div>
                        </div>
                    </div>
                    <div class="rights-editor-assigned col-12 col-md-5">
                        <ejs-grid #assignedRightsGrid
                            [height]="'250'"
                            [allowPaging]="false" 
                            [dataSource]="assignedRightsGridSettings.dataSource" 
                            [columns]="assignedRightsGridSettings.columns"
                            [editSettings]="assignedRightsGridSettings.editSettings" />
                    </div>
                </div>
            </div>  
        </ejs-dialog>        
    </div>
</div>