<div id="custom-theme-creator">

    <form [formGroup]="formGroup" (ngSubmit)="onSubmit()" class="d-flex flex-column custom-theme-creator-colors">

        <div class="colors d-flex justify-content-between">
            <div class="color-picker">
                <label>Primary</label>
                <ejs-input ejs-colorpicker id="colorpicker"
                    type="color" 
                    formControlName="primaryColor" 
                    mode="Palette" 
                    [value]="formGroup.value.primaryColor"/>
            </div>
    
            <div class="color-picker">
                <label>Secondary</label>
                <ejs-input ejs-colorpicker id="colorpicker"
                    type="color" 
                    formControlName="secondaryColor" 
                    mode="Palette" 
                    [value]="formGroup.value.secondaryColor" />
            </div>
    
            <div class="color-picker">
                <label>Tertiary</label>
                <ejs-input ejs-colorpicker id="colorpicker"
                    type="color" 
                    formControlName="tertiaryColor" 
                    mode="Palette" 
                    [value]="formGroup.value.tertiaryColor"/>
            </div>
        </div>

        <div class="submit d-flex justify-content-end">
            <button ejs-button style="margin-right: 2rem;" (click)="handleClearThemeClick($event)">Clear Theme</button>
            <button ejs-button type="submit" isPrimary="true">Apply Custom Theme</button>
        </div>
        
    </form>
</div>