// Angular
import { Injectable } from '@angular/core';

// 3rd Party
import { Query, DataManager } from '@syncfusion/ej2-data';

// Models
import { APIEndpoints } from '@models/api/Endpoints';
import { User, XrefUsersRole } from '@models/data-contracts';

// Services
import { ApiService } from '@services/api/api.service';

// Interfaces
interface UserResponse {
  result: User[];
}

interface UserGridItem extends User {
  RoleNames: string[];
}

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private readonly detailQuery = new Query()
    .expand('XrefUsersRoles($expand=Role),XrefPhoneUsers($expand=Phone)');

  private readonly gridQuery = new Query()
    .expand('XrefUsersRoles($expand=Role($select=Id,RoleName);$select=Id,RoleId),XrefPhoneUsers($expand=Phone($select=Id,PhoneNumber))')
    .select(['Id', 'Username', 'Name', 'Email', 'Title', 'XrefUsersRoles'])
    .requiresCount();

  constructor(
    private api: ApiService
  ) {}

  getUsersGridQuery(): Query {
    return this.gridQuery;
  }

  getRolesDataManager(): DataManager {
    return this.api.getOdata(APIEndpoints.Roles);
  }

  transformUsersForGrid(data: User[]): UserGridItem[] {
    return data.map((user: User) => {
      const roleNames = user.XrefUsersRoles?.map((xref: XrefUsersRole) => xref.Role?.RoleName) || [];

      return {
        ...user,
        RoleNames: roleNames
      } as UserGridItem;
    });
  }

  async updateUser(id: number, data: Partial<User>): Promise<User> {
    try {
      const response = await this.api.patchOdata(`${APIEndpoints.Users}/${id}`, data);
      return response as unknown as User;
    } catch (error) {
      console.error('Error updating user:', error);
      throw error;
    }
  }

  async createUser(data: Partial<User>): Promise<User> {
    try {
      const response = await this.api.postOdata(APIEndpoints.Users, data);
      return response as unknown as User;
    } catch (error) {
      console.error('Error creating user:', error);
      throw error;
    }
  }

  async getUserById(id: number): Promise<User> {
    try {
      const response = await this.api.getOdata(`${APIEndpoints.Users}(${id})`).executeQuery(this.detailQuery);
      const typedResponse = response as unknown as UserResponse;
      return typedResponse.result[0];
    } catch (error) {
      console.error('Error fetching user:', error);
      throw error;
    }
  }

  async updateUserRoles(userId: number, roleIds: number[]): Promise<void> {
    try {
      // Implementation for updating user roles
      // This will be implemented based on your API requirements
    } catch (error) {
      console.error('Error updating user roles:', error);
      throw error;
    }
  }

  getRolesData(): Query {
    return new Query()
      .select(['Id', 'RoleName'])
      .from('Roles');
  }
}
