<div class="row">
  <div class="form-item col-12 col-sm-6">
    <ejs-datepicker [(ngModel)]="formData.DateSigned" placeholder="Date Signed" floatLabelType="Auto"/>
  </div>
  <div class="form-item col-12 col-sm-6">
    <ejs-numerictextbox 
      placeholder="Amount"
      [(ngModel)]="formData.Amount" 
      [value]="formData.Amount" 
      floatLabelType="Auto"
    />
  </div>
  <div class="form-item col-12 col-sm-6">
    <ejs-dropdownlist #authTypeDropdown 
      id='authTypeDD' 
      allowFiltering="true"
      placeholder="Select AuthType" 
      [dataSource]='authTypeList' 
      [(ngModel)]="formData.AuthType"
      floatLabelType="Auto"
    />
  </div>
  <div class="form-item col-12 col-sm-6">
    <div>
      <ng-container *ngIf="!selectedAuthorization; else showProviderName">
        <!-- Only show provider dropdown for Provider Funding type -->
        <div class="form-group" *ngIf="formData.AuthType === 'Provider Funding'">
          <label>Select Provider</label>
          <ejs-dropdownlist 
            [dataSource]="providerList"
            [(ngModel)]="formData.ProviderId"
            [fields]="providerFields"
            placeholder="Select Provider">
          </ejs-dropdownlist>
        </div>
      </ng-container>
    
      <ng-template #showProviderName>
        <ejs-textbox 
          [value]="selectedAuthorization?.Provider?.Name"
          placeholder="Provider"
          floatLabelType="Auto"
          [readonly]="true"
          [enabled]="false"
        ></ejs-textbox>
      </ng-template>
    </div>
</div>
<div class="form-item col-12">
  <ejs-textbox 
  placeholder="Notes"
  [multiline]="true"
  [(ngModel)]="formData.Notes" 
  floatLabelType="Auto"
  />
</div>
<div class="col-2 form-submit">
  <button ejs-button class="e-primary e-flat" ejs-button type="submit" isPrimary="true" (click)="onSubmit()">Submit</button>
</div>
