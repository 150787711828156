<div id="deposits-grid" class="deposits-grid">
    <base-grid #depositsGrid [settings]="depositsGridSettings">
        <!-- Add Deposit Dialog -->
        <div class="dialog-container">
            <ejs-dialog #addDepositDialog
                [showCloseIcon]="true"
                [target]="'body'"
                [isModal]="true"
                [closeOnEscape]="true"
                [visible]="false"
                [buttons]="addDepositButtons"
                header="Add Deposit"
                (close)="addDepositDialog.hide()">
                <add-deposit-form #newDepositForm />
            </ejs-dialog>
        </div>

        <!-- Bulk Edit Dialog -->
        <div class="dialog-container">
            <ejs-dialog #bulkEditDepositsDialog
                [showCloseIcon]="true"
                [target]="'body'"
                [isModal]="true"
                [closeOnEscape]="true"
                [visible]="false"
                [buttons]="bulkUpdateDepositsButtons"
                header="Bulk Edit"
                (close)="bulkEditDepositsDialog.hide()">
                <bulk-edit-deposits-form #bulkEditForm [endpoint]="depositsEndpoint" />
            </ejs-dialog>
        </div>
    </base-grid>
</div>