/**
 * Test cases data source
 */

export let treeDataSample: Object[] = [
    {
        id: 1,
        WorkflowId: 0,
        StepName: 'Contact Attorney and Confirrrrm Information',
        StepType: 'Trigger',
        AssignTo: 1,
        Modifier: 0,
        Optional: false,
        CompletionCondition: '',
        isInExpandState: true,
        parentId: null,
        Children: [
            { 
                id: 2, 
                StepName: 'Email Documents to Law Firm',
                StepType: 'Automatic',
                Modifier: 0, 
                AssignTo: 2,
                CompletionCondition: 'Final Invoice Entered',
                Optional: false,
                Children: [
                    {
                        id: 3, 
                        StepName: 'Secure Provider Agreements for Client',
                        StepType: 'Branch',
                        Modifier: 0, 
                        AssignTo: 3,
                        CompletionCondition: 'Did Attorney Respond Before 4 Attempts?',
                        Optional: false,
                        Children: [
                            {
                                id: 4, 
                                StepName: 'Schedule Treatment',
                                StepType: 'Automatic',
                                Modifier: 0, 
                                AssignTo: 4,
                                CompletionCondition: '',
                                Optional: true,
                                Children: [
                                    {
                                        id: 5, 
                                        StepName: 'Generate and Email Balance Statement', 
                                        StepType: 'Automatic',
                                        Modifier: 0, 
                                        AssignTo: 6,
                                        CompletionCondition: '',
                                        Optional: false,
                                        Children: [
                                            {
                                                id: 6, 
                                                StepName: 'Generate SMS', 
                                                StepType: 'Automatic',
                                                Modifier: 0, 
                                                AssignTo: 6,
                                                CompletionCondition: '',
                                                Optional: false,
                                                Children: [
                                                    {
                                                        id: 7, 
                                                        StepName: 'Set In Treatment To No',
                                                        StepType: 'End',
                                                        CompletionCondition: 'none'
                                                    }
                                                ]
                                            }
                                        ]
                                    }
                                ],
                            },
                            {
                                id: 8, 
                                StepName: 'Enter Invoices',
                                StepType: 'Automatic',
                                Modifier: 0, 
                                AssignTo: 4,
                                CompletionCondition: '',
                                Optional: false,
                                Children: [
                                    {
                                        id: 9, 
                                        StepName: 'Enter Deposits', 
                                        StepType: 'Automatic',
                                        Modifier: 0, 
                                        AssignTo: 6,
                                        CompletionCondition: '',
                                        Optional: true,
                                        Children: [
                                            {
                                                id: 10, 
                                                StepName: 'Contact Attorney For Status',
                                                StepType: 'End',
                                                CompletionCondition: 'none'
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    }
];

export const treeData: Object[] = [
    {
        workflowId: 12,
        name: 'File Lifecycle',
        status: 'In Progress',
        steps: [
            {
                id: 0,
                StepName: 'Contact Attorney and Confirmmm Information',
                StepType: 'Trigger',
                AssignTo: 'Default',
                Modifier: 0,
                Optional: false,
                CompletionCondition: '',
                isInExpandState: true,
                parentId: null,
                Children: [
                    { 
                        id: 1, 
                        StepName: 'Email Documents to Law Firm',
                        StepType: 'Automatic',
                        Modifier: 0, 
                        AssignTo: 'None',
                        CompletionCondition: 'Final Invoice Entered',
                        Optional: false,
                        parentId: 0,
                        Children: [
                            {
                                id: 2, 
                                StepName: 'Secure Provider Agreements for Client',
                                StepType: 'Branch',
                                Modifier: 0, 
                                AssignTo: 'None',
                                CompletionCondition: 'Did Attorney Respond Before 4 Attempts?',
                                Optional: false,
                                parentId: 1,
                                Children: [
                                    {
                                        id: 3, 
                                        StepName: 'Schedule Treatment',
                                        StepType: 'Automatic',
                                        Modifier: 0, 
                                        AssignTo: 'None',
                                        CompletionCondition: '',
                                        Optional: true,
                                        parentId: 2,
                                        Children: [
                                            {
                                                id: 4, 
                                                StepName: 'Generate and Email Balance Statement', 
                                                StepType: 'Automatic',
                                                Modifier: 0, 
                                                AssignTo: 'None',
                                                CompletionCondition: '',
                                                Optional: false,
                                                parentId: 3,
                                                Children: [
                                                    {
                                                        id: 5, 
                                                        StepName: 'Generate SMS', 
                                                        StepType: 'Automatic',
                                                        Modifier: 0, 
                                                        AssignTo: 'None',
                                                        CompletionCondition: '',
                                                        Optional: false,
                                                        parentId: 4,
                                                        Children: [
                                                            {
                                                                id: 6, 
                                                                StepName: 'Set In Treatment To No',
                                                                StepType: 'End',
                                                                parentId: 5,
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                        ],
                                    },
                                    {
                                        id: 7, 
                                        StepName: 'Enter Invoices',
                                        StepType: 'Automatic',
                                        Modifier: 0, 
                                        AssignTo: 'File Market Manager',
                                        CompletionCondition: '',
                                        Optional: false,
                                        parentId: 2,
                                        Children: [
                                            {
                                                id: 8, 
                                                StepName: 'Enter Deposits', 
                                                StepType: 'Automatic',
                                                Modifier: 0, 
                                                AssignTo: 'File Case Manager',
                                                CompletionCondition: '',
                                                Optional: true,
                                                parentId: 7,
                                                Children: [
                                                    {
                                                        id: 9, 
                                                        StepName: 'Contact Attorney For Status',
                                                        StepType: 'End',
                                                        parentId: 8,
                                                    }
                                                ]
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        workflowId: 54,
        name: 'New Incoming Referral',
        status: 'Inactive',
        steps: [
            {
                id: 0, 
                StepName: 'Start Workflow',
                StepType: 'Trigger',
                Modifier: 0, 
                AssignTo: undefined,
                CompletionCondition: undefined,
                Optional: false,
                Children: [
                    {
                        id: 1, 
                        StepName: 'Send Acknowledgement Email',
                        StepType: 'Automatic',
                        Modifier: 0, 
                        AssignTo: 'Default',
                        CompletionCondition: 'User Marks Task Complete',
                        Optional: false,
                        Children: [
                            {
                                id: 2, 
                                StepName: 'Law Firm INTRO CALL',
                                StepType: 'User Task',
                                Modifier: 0, 
                                AssignTo: 'Default',
                                CompletionCondition: 'User Marks Task Complete',
                                Optional: true,
                                Children: [
                                    {
                                        id: 3, 
                                        StepName: 'Assign Doctor(s) for Initial Consult',
                                        StepType: 'User Task',
                                        Modifier: 0, 
                                        AssignTo: 'File Market Manager',
                                        CompletionCondition: 'User Marks Task Complete',
                                        Optional: true,
                                        Children: [
                                            {
                                                id: 4, 
                                                StepName: 'Email Client Info to Assigned Dr.',
                                                StepType: 'User Task',
                                                Modifier: 0, 
                                                AssignTo: 'File Market Manager',
                                                CompletionCondition: 'User Marks Task Complete',
                                                Optional: true,
                                                Children: [
                                                    {
                                                        id: 4, 
                                                        StepName: 'Move to ANC',
                                                        StepType: 'User Task',
                                                        Modifier: 0, 
                                                        AssignTo: 'File Market Manager',
                                                        CompletionCondition: 'User Marks Task Complete',
                                                        Optional: false,
                                                        Children: [
                                                            {
                                                                id: 4, 
                                                                StepName: 'Move to ANC',
                                                                StepType: 'End',
                                                                Modifier: 0, 
                                                                AssignTo: 'Default',
                                                                CompletionCondition: 'LOP and HIPPA documents Signed',
                                                                Optional: false,
                                                                Children: []
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        workflowId: 57,
        name: 'MEDEVAL EXPEDITED REFERRAL',
        status: 'Done',
        steps: [
            {
                id: 0, 
                StepName: 'Start Workflow',
                StepType: 'Trigger',
                Modifier: 0, 
                AssignTo: 'Default',
                CompletionCondition: '',
                Optional: false,
                Children: [
                    {
                        id: 1, 
                        StepName: 'Send Acknowledgement Email',
                        StepType: 'Automatic',
                        Modifier: 0, 
                        AssignTo: 'Default',
                        CompletionCondition: 'Email Sent',
                        Optional: false,
                        Children: [
                            {
                                id: 2, 
                                StepName: 'Law Firm INTRO CALL',
                                StepType: 'User Task',
                                Modifier: 0, 
                                AssignTo: 'Default',
                                CompletionCondition: 'User Marks Task Complete',
                                Optional: true,
                                Children: [
                                    {
                                        id: 3, 
                                        StepName: 'Assign Doctor(s) for Initial Consult',
                                        StepType: 'Automatic',
                                        Modifier: 0, 
                                        AssignTo: 'Default',
                                        CompletionCondition: 'User Marks Task Complete',
                                        Optional: true,
                                        Children: [
                                            {
                                                id: 4, 
                                                StepName: 'Move to ANC',
                                                StepType: 'End',
                                                Modifier: 0, 
                                                AssignTo: 'Default',
                                                CompletionCondition: 'LOP and HIPPA documents Signed',
                                                Optional: false,
                                                Children: []
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    }
];

export const workflowData = {
    12: {
        workflowId: 12,
        name: 'File Lifecycle',
        status: 'Existing Workflow',
        metadata: [
            {
                id: 1,
                StepName: {
                    id: 1,
                    value: 'Contact Attorney and Confiiirm Information'
                },
                parentId: null,
                StepType: {
                    id: 3,
                    value: 'Trigger'
                },
                AssignTo: {
                    id: 1,
                    value: 'N/A'
                },
                CompletionCondition: {
                    id: 7,
                    value: 'N/A'
                },
                Modifier: null,
                Optional: {
                    value: 'No'
                },
                Children: {
                    value: 'N/A'
                }
            },
            {
                id: 2,
                StepName: {
                    id: 2,
                    value: 'Email Documents to Law Firm'
                },
                parentId: 1,
                StepType: {
                    id: 6,
                    value: 'Automatic'
                },
                AssignTo: {
                    id: 1,
                    value: 'N/A'
                },
                CompletionCondition: {
                    id: 4,
                    value: 'Final Invoice Entered'
                },
                Modifier: 40,
                Optional: {
                    value: 'No'
                },
                Children: {
                    value: 'N/A'
                }
            },
            {
                id: 3,
                StepName: {
                    id: 3,
                    value: 'Secure Provider Agreements for Client'
                },
                parentId: 2,
                StepType: {
                    id: 6,
                    value: 'Automatic'
                },
                AssignTo: {
                    id: 1,
                    value: 'N/A'
                },
                CompletionCondition: {
                    id: 1,
                    value: 'Did Attorney Respond Before [X] Attempts?'
                },
                Modifier: 4,
                Optional: {
                    value: 'No'
                },
                Children: {
                    value: 'N/A'
                }
            },
            {
                id: 4,
                StepName: {
                    id: 4,
                    value: 'Schedule Treatment'
                },
                parentId: 3,
                StepType: {
                    id: 6,
                    value: 'Automatic'
                },
                AssignTo: {
                    id: 1,
                    value: 'N/A'
                },
                CompletionCondition: {
                    id: 7,
                    value: 'N/A'
                },
                Modifier: 40,
                Optional: {
                    value: 'Yes'
                },
                Children: {
                    value: 'Yes'
                }
            },
            {
                id: 5,
                StepName: {
                    id: 5,
                    value: 'Enter Invoices'
                },
                parentId: 3,
                StepType: {
                    id: 6,
                    value: 'Automatic'
                },
                AssignTo: {
                    id: 1,
                    value: 'N/A'
                },
                CompletionCondition: {
                    id: 7,
                    value: 'N/A'
                },
                Modifier: 40,
                Optional: {
                    value: 'No'
                },
                Children: {
                    value: 'No'
                }
            },
            {
                id: 6,
                StepName: {
                    id: 6,
                    value: 'Generate and Email Balance Statement'
                },
                parentId: 4,
                StepType: {
                    id: 6,
                    value: 'Automatic'
                },
                AssignTo: {
                    id: 1,
                    value: 'N/A'
                },
                CompletionCondition: {
                    id: 7,
                    value: 'N/A'
                },
                Modifier: 40,
                Optional: {
                    value: 'No'
                },
                Children: {
                    value: 'N/A'
                }
            },
            {
                id: 7,
                StepName: {
                    id: 7,
                    value: 'Enter Deposits'
                },
                parentId: 5,
                StepType: {
                    id: 6,
                    value: 'Automatic'
                },
                AssignTo: {
                    id: 1,
                    value: 'N/A'
                },
                CompletionCondition: {
                    id: 7,
                    value: 'N/A'
                },
                Modifier: 40,
                Optional: {
                    value: 'Yes'
                },
                Children: {
                    value: 'N/A'
                }
            },
            {
                id: 8,
                StepName: {
                    id: 8,
                    value: 'Set In Treatment To No'
                },
                parentId: 6,
                StepType: {
                    id: 4,
                    value: 'End Workflow'
                },
                AssignTo: {
                    id: 1,
                    value: 'N/A'
                },
                CompletionCondition: {
                    id: 7,
                    value: 'N/A'
                },
                Modifier: 40,
                Optional: {
                    value: 'No'
                },
                Children: {
                    value: 'N/A'
                }
            },
            {
                id: 9,
                StepName: {
                    id: 9,
                    value: 'Contact Attorney For Status'
                },
                parentId: 7,
                StepType: {
                    id: 4,
                    value: 'End Workflow'
                },
                AssignTo: {
                    id: 1,
                    value: 'N/A'
                },
                CompletionCondition: {
                    id: 7,
                    value: 'N/A'
                },
                Modifier: 40,
                Optional: {
                    value: 'No'
                },
                Children: {
                    value: 'N/A'
                }
            }
        ]
    },
    54: {
        workflowId: 54,
        name: 'New Incoming Referral',
        status: 'Existing Workflow',
        metadata: [
            {
                id: 1,
                StepName: {
                    id: 12,
                    value: 'Start Workflow'
                },
                parentId: null,
                StepType: {
                    id: 3,
                    value: 'Trigger'
                },
                AssignTo: {
                    id: 1,
                    value: 'N/A'
                },
                CompletionCondition: {
                    id: 7,
                    value: 'N/A'
                },
                Modifier: null,
                Optional: {
                    value: 'No'
                },
                Children: {
                    value: 'N/A'
                }
            },
            {
                id: 10,
                StepName: {
                    id: 13,
                    value: 'Send Acknowledgement Email'
                },
                parentId: 1,
                StepType: {
                    id: 6,
                    value: 'Automatic'
                },
                AssignTo: {
                    id: 1,
                    value: 'N/A'
                },
                CompletionCondition: {
                    id: 3,
                    value: 'User Marks Task Complete'
                },
                Modifier: null,
                Optional: {
                    value: 'No'
                },
                Children: {
                    value: 'N/A'
                }
            },
            {
                id: 11,
                StepName: {
                    id: 14,
                    value: 'Law Firm INTRO CALL'
                },
                parentId: 10,
                StepType: {
                    id: 1,
                    value: 'User Task'
                },
                AssignTo: {
                    id: 4,
                    value: 'Default Workflow User'
                },
                CompletionCondition: {
                    id: 3,
                    value: 'User Marks Task Complete'
                },
                Modifier: null,
                Optional: {
                    value: 'No'
                },
                Children: {
                    value: 'N/A'
                }
            },
            {
                id: 12,
                StepName: {
                    id: 15,
                    value: 'Assign Doctor(s) for Initial Consult'
                },
                parentId: 11,
                StepType: {
                    id: 1,
                    value: 'User Task'
                },
                AssignTo: {
                    id: 6,
                    value: 'File Market Manager'
                },
                CompletionCondition: {
                    id: 3,
                    value: 'User Marks Task Complete'
                },
                Modifier: null,
                Optional: {
                    value: 'No'
                },
                Children: {
                    value: 'N/A'
                }
            },
            {
                id: 13,
                StepName: {
                    id: 16,
                    value: 'Email Client Info to Assigned Dr.'
                },
                parentId: 12,
                StepType: {
                    id: 1,
                    value: 'User Task'
                },
                AssignTo: {
                    id: 3,
                    value: 'File Case Manager'
                },
                CompletionCondition: {
                    id: 3,
                    value: 'User Marks Task Complete'
                },
                Modifier: null,
                Optional: {
                    value: 'Yes'
                },
                Children: {
                    value: 'N/A'
                }
            },
            {
                id: 14,
                StepName: {
                    id: 18,
                    value: 'LOP/HIPAA Request'
                },
                parentId: 13,
                StepType: {
                    id: 1,
                    value: 'User Task'
                },
                AssignTo: {
                    id: 3,
                    value: 'File Case Manager'
                },
                CompletionCondition: {
                    id: 3,
                    value: 'User Marks Task Complete'
                },
                Modifier: null,
                Optional: {
                    value: 'No'
                },
                Children: {
                    value: 'N/A'
                }
            },
            {
                id: 15,
                StepName: {
                    id: 19,
                    value: 'Move to ANC'
                },
                parentId: 14,
                StepType: {
                    id: 4,
                    value: 'End Workflow'
                },
                AssignTo: {
                    id: 1,
                    value: 'N/A'
                },
                CompletionCondition: {
                    id: 2,
                    value: 'LOP and HIPAA Documents Signed'
                },
                Modifier: null,
                Optional: {
                    value: 'No'
                },
                Children: {
                    value: 'N/A'
                }
            }
        ]
    },
    57: {
        workflowId: 57,
        name: 'MEDEVAL EXPEDITED REFERRAL',
        status: 'Existing Workflow',
        metadata: [
            {
                id: 1,
                StepName: {
                    id: 12,
                    value: 'Start Workflow'
                },
                parentId: null,
                StepType: {
                    id: 3,
                    value: 'Trigger'
                },
                AssignTo: {
                    id: 1,
                    value: 'N/A'
                },
                CompletionCondition: {
                    id: 7,
                    value: 'N/A'
                },
                Modifier: null,
                Optional: {
                    value: 'No'
                },
                Children: {
                    value: 'N/A'
                }
            },
            {
                id: 10,
                StepName: {
                    id: 13,
                    value: 'Send Acknowledgement Email'
                },
                parentId: 1,
                StepType: {
                    id: 6,
                    value: 'Automatic'
                },
                AssignTo: {
                    id: 1,
                    value: 'N/A'
                },
                CompletionCondition: {
                    id: 8,
                    value: 'Email Sent'
                },
                Modifier: null,
                Optional: {
                    value: 'No'
                },
                Children: {
                    value: 'N/A'
                }
            },
            {
                id: 11,
                StepName: {
                    id: 14,
                    value: 'Law Firm INTRO CALL'
                },
                parentId: 10,
                StepType: {
                    id: 1,
                    value: 'User Task'
                },
                AssignTo: {
                    id: 4,
                    value: 'Default Workflow User'
                },
                CompletionCondition: {
                    id: 3,
                    value: 'User Marks Task Complete'
                },
                Modifier: null,
                Optional: {
                    value: 'Yes'
                },
                Children: {
                    value: 'N/A'
                }
            },
            {
                id: 12,
                StepName: {
                    id: 15,
                    value: 'Assign Doctor(s) for Initial Consult'
                },
                parentId: 11,
                StepType: {
                    id: 1,
                    value: 'User Task'
                },
                AssignTo: {
                    id: 4,
                    value: 'Default Workflow User'
                },
                CompletionCondition: {
                    id: 3,
                    value: 'User Marks Task Complete'
                },
                Modifier: null,
                Optional: {
                    value: 'Yes'
                },
                Children: {
                    value: 'N/A'
                }
            },
            {
                id: 13,
                StepName: {
                    id: 19,
                    value: 'Move to ANC'
                },
                parentId: 12,
                StepType: {
                    id: 4,
                    value: 'End Workflow'
                },
                AssignTo: {
                    id: 1,
                    value: 'N/A'
                },
                CompletionCondition: {
                    id: 2,
                    value: 'LOP and HIPAA Documents Signed'
                },
                Modifier: null,
                Optional: {
                    value: 'No'
                },
                Children: {
                    value: 'N/A'
                }
            }
        ]
    },
    58: {
        workflowId: 58,
        name: 'Test',
        status: 'Existing Workflow',
        metadata: [
            {
                id: 1,
                StepName: {
                    id: 12,
                    value: 'Start Workflow'
                },
                parentId: null,
                StepType: {
                    id: 3,
                    value: 'Trigger'
                },
                AssignTo: {
                    id: 1,
                    value: 'N/A'
                },
                CompletionCondition: {
                    id: 7,
                    value: 'N/A'
                },
                Modifier: null,
                Optional: {
                    value: 'No'
                },
                Children: {
                    value: 'N/A'
                }
            }
        ]
    }
};