// Angular
import { Component, ViewChild, computed, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
// 3rd Party
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TabModule, TabComponent, SelectEventArgs } from '@syncfusion/ej2-angular-navigations';

// Internal
import { Tab } from '@root/src/app/features/file-hub/services/hub.model';
import { FileHubService } from '@root/src/app/features/file-hub/services/file-hub.service';
import { GlobalsService } from '@services/globals/globals.service';
// Tabs
import { HomeTab } from '@root/app/features/file-hub/components/file-hub-tabs/home-tab/components/home-tab.component';
import { AppointmentsTab } from '@root/src/app/features/file-hub/components/file-hub-tabs/appointments-tab/appointments-tab.component';
import { WorkItemsTabComponent } from '@root/src/app/features/file-hub/components/file-hub-tabs/work-items-tab/work-items-tab.component';
import { FinancialTab } from '@root/src/app/features/file-hub/components/file-hub-tabs/financial-tab/financial-tab.component';
import { DocumentsTab } from '@root/src/app/features/file-hub/components/file-hub-tabs/documents-tab/documents-tab.component';
import { AuthorizationsTab } from '@root/src/app/features/file-hub/components/file-hub-tabs/authorizations-tab/authorizations-tab.component';
import { InjuryTab } from '@root/src/app/features/file-hub/components/file-hub-tabs/intake-tab/intake-tab.component';
import { BoxSignTab } from '@root/src/app/features/file-hub/components/file-hub-tabs/box-sign-tab/box-sign-tab.component';
import { ComplianceTab } from '@root/src/app/features/file-hub/components/file-hub-tabs/compliance-tab/compliance-tab.component';
import { SettingsTab } from '@root/src/app/features/file-hub/components/file-hub-tabs/settings-tab/settings-tab.component';
import { Subscription } from 'rxjs';
import { SurgicalTabComponent } from './surgical-tab/surgical-tab.component';
import { PayablesTabComponent } from './payables-tab/payables-tab.component';
import { ReceivablesTabComponent } from './receivables-tab/receivables-tab.component';

@Component({
  selector: 'hub-tabs',
  standalone: true,
  templateUrl: './file-hub-tabs.component.html',
  styleUrl: './file-hub-tabs.component.scss',
  imports: [
    CommonModule,
    TabModule,
    FontAwesomeModule,
    WorkItemsTabComponent,
    HomeTab,
    AppointmentsTab,
    FinancialTab,
    DocumentsTab,
    AuthorizationsTab,
    InjuryTab,
    BoxSignTab,
    ComplianceTab,
    SettingsTab,
    SurgicalTabComponent,
    PayablesTabComponent,
    ReceivablesTabComponent
  ]
})
export class FileHubTabsComponent {

  /**
   * Main functionality can be found in the FileHubService.
   * @see {@link FileHubService} for more info
   */
  constructor(
    private fileHub: FileHubService,
    public globals: GlobalsService
  ) {}

  private watchReload?: Subscription;

  // Signals
  private readonly state = {
    tabs: signal<Tab[]>(this.fileHub.tabs),
    activeTab: signal<string | undefined>(undefined),
    isResponsive: signal(false)
  };

  // Public Variables
  @ViewChild('ejsTab') ejsTab: TabComponent;
  readonly activeTab = computed(() => this.fileHub.activeTab);
  readonly tabs: Tab[] = this.fileHub.tabs;

  ngAfterViewInit() {
    // Fires after Tab Component is fully initialized
    this.ejsTab.created.subscribe(() => {
      this.fileHub.setTabOnLoad(this.ejsTab);
      this.autoFitTabsToToolbar();
    });
  }

  // Apply changes based on currently selected tab
  onTabSelected(args: SelectEventArgs) {
    const selectedTab = this.tabs[args.selectedIndex];

    if (selectedTab) {
      this.fileHub.updateActiveTabString(selectedTab.HeaderText);
      this.fileHub.addFragmentToURL(selectedTab.HeaderText);
    }
  }

  // Improves performance by only adding unique tab.Ids to the DOM
  trackByTabId(tab: Tab): Tab { return tab }

  // Adjusts tab header widths to fill toolbar
  autoFitTabsToToolbar() {
    const tabEl = this.ejsTab?.element;
    if (!tabEl) return;

    const toolbar = tabEl.querySelector('.e-toolbar-items') as HTMLElement;
    if (!toolbar) return;

    const toolbarItems = toolbar.querySelectorAll('.e-toolbar-item');
    if (!toolbarItems) return;

    const toolbarWidth = toolbar.clientWidth;
    toolbarItems.forEach(item => {
      const itemEl = item as HTMLElement;
      const updatedWidth = `${toolbarWidth / toolbarItems.length}px`;
      itemEl.style.width = updatedWidth;
    });
  }

  updatePerformanceData() {
      this.fileHub.setPerformanceData();
  }
}
