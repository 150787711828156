// Angular
import { Component, computed, effect, EventEmitter, HostListener, Input, Output, Signal, WritableSignal, signal, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

// Core Services
import { ErrorHandlingService } from '@core/error/error.service';
import { ErrorSeverity } from '@core/error/error.types';

// 3rd Party
import { ButtonModule, CheckBoxAllModule } from '@syncfusion/ej2-angular-buttons';
import { TextBoxAllModule, MaskedTextBoxAllModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownListAllModule, DropDownListComponent, FilteringEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { DatePickerAllModule, DatePickerComponent, FocusEventArgs } from '@syncfusion/ej2-angular-calendars';
import { TooltipAllModule } from '@syncfusion/ej2-angular-popups';
import { Query, Predicate } from '@syncfusion/ej2-data';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faUser, faVenusMars, faEnvelope, faCake, faBaby, faLanguage, faPhone, faComments, faMapLocationDot, faHospitalUser, faUserInjured, faEyeSlash, faEye, faCircleMinus, faCirclePlus } from '@fortawesome/free-solid-svg-icons';

// Models
import { Patient, Address, Phone } from '@models/data-contracts';
import { APIEndpoints } from '@models/api/Endpoints';
import { genders } from '@models/global-vars';

// Services
import { FormCrudService } from '@services/forms/form-crud.service';
import { ApiService } from '@services/api/api.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { DataCacheService } from '@services/data-cache/data-cache.service';

// Components
import { AddressFormComponent } from '@forms/address-form/address-form.component';
import { PhoneFormComponent } from '@forms/phone-number-form/phone-number-form.component';

// Type & Interface setup
type PatientFormControls = {
  [K in keyof Patient]: FormControl<Patient[K] | null>;
};

export type PatientFormResult = 'success' | 'warning' | 'error' | undefined;

@Component({
  selector: 'patient-form-component',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    TextBoxAllModule,
    MaskedTextBoxAllModule,
    DropDownListAllModule,
    TooltipAllModule,
    DatePickerAllModule,
    CheckBoxAllModule,
    FontAwesomeModule,
    AddressFormComponent,
    PhoneFormComponent
  ],
  templateUrl: './patient-form.component.html',
  styleUrl: './patient-form.component.scss'
})
export class PatientFormComponent {
  // Add loading state properties
  isLoading = false;
  loadingMessage = '';

  constructor(
    private formCRUD: FormCrudService,
    private api: ApiService,
    private toast: ToastMessageService,
    private errorHandling: ErrorHandlingService,
    private dataCache: DataCacheService
  ) {
    this.ensurePatientSignal();

    // Set up patient data effect
    effect(() => {
      const patient = this.patient();
      if (patient) {
        this.isLoading = true;
        this.loadingMessage = 'Loading patient data...';
        this.patientForm.patchValue(patient, { emitEvent: false });
        this.patientChange.emit(patient);
        if (patient.Id) {
          this.selectedPatientId = patient.Id;
          this.getPatientAddress().then(address => {
            if (address && this.addressFormComp) {
              this.addressFormComp.address.set(address);
            }
          });
          this.getPatientPhones().then(() => {
            this.isLoading = false;
            this.loadingMessage = '';
          });
        }
      } else {
        // Reset form directly
        this.isLoading = true;
        this.loadingMessage = 'Resetting form...';
        this.patientForm.reset({
          Id: undefined,
          Firstname: '',
          Lastname: '',
          Email: '',
          Dob: undefined,
          Gender: '',
          Language: '',
          Minor: false
        }, { emitEvent: false });
        this.isLoading = false;
        this.loadingMessage = '';
      }
    });

    // Initialize only languages data
    this.initializeLanguagesData();
  }

  // Decorator variabls
  @Input() selectedAccountId?: number;
  @Input() isLawFirm?: boolean;
  @Input() isEditPatient?: boolean;
  @Input() patient!: WritableSignal<Patient | undefined>;
  @Input() submitType?: 'POST' | 'PATCH' | 'DELETE';
  @Input() displayButtons: boolean = true;
  @Input() formMainTitle?: string;
  @Input() displayMainTitle = true;
  @Input() displayForm = this.isEditPatient ? false : true;
  @Input() displayFields: string[] = ['Firstname', 'Lastname', 'Email', 'Dob', 'Gender', 'Language', 'Minor'];
  @Input() selectedPatientId?: number;
  @Input() showPatientSelect: boolean = false;
  @Output() formSubmitted = new EventEmitter<void>();
  @Output() formCancelled = new EventEmitter<void>();
  @Output() addressResult = new EventEmitter<any>();
  @Output() patientChange = new EventEmitter<Patient>();
  @Output() patientSelected = new EventEmitter<any>();
  @ViewChild('patientsDropdown') patientsDropdown!: DropDownListComponent;
  @ViewChild('addressFormComp') addressFormComp!: AddressFormComponent;
  @ViewChild('dobComp') dobComp!: DatePickerComponent;
  @ViewChildren(PhoneFormComponent) phoneForms!: QueryList<PhoneFormComponent>;

  // Form state
  protected readonly formState = computed(() => {
    return {
      isValid: this.patientForm.valid,
      hasChanges: this.patient() !== this.patientForm.value,
      currentValue: this.patient()
    };
  });
  // Use computed for form validation messages
  protected readonly errorMessages = computed(() => {
    const errors: Record<string, string> = {};
    Object.keys(this.patientForm.controls).forEach(key => {
      const control = this.patientForm.get(key);
      if (control?.errors) errors[key] = this.getErrorMessage(key);
    });
    return errors;
  });
  currentPatient = computed(() => this.patient());

  // Public variables
  patientAddress: WritableSignal<Address | undefined> = signal(undefined);
  patientPhones: WritableSignal<Phone[] | undefined> = signal(undefined);
  genders: string[] = genders;
  languages: any;
  errorMessage: string = '';
  patientHTMLElement: Element | null = null;
  patients: any;
  patientsQuery: Query = new Query().select('Id,Firstname,Lastname,Email,Dob,Gender,Language,Minor').expand('XrefAddressPatients($expand=Address($select=Id,AddressType,Address1,Address2,City,State,Zip))');
  showNewPatientForm = false;
  patientForm = new FormGroup<PatientFormControls>({
    Id: new FormControl<Patient['Id']>(undefined),
    Firstname: new FormControl<Patient['Firstname']>('', [Validators.required]),
    Lastname: new FormControl<Patient['Lastname']>('', [Validators.required]),
    Email: new FormControl<Patient['Email']>('', [Validators.required, Validators.email]),
    Dob: new FormControl<Patient['Dob']>(undefined),
    Gender: new FormControl<Patient['Gender']>(''),
    Language: new FormControl<Patient['Language']>(''),
    Minor: new FormControl<Patient['Minor']>(false)
  });
  patientIcons = {
    form: faHospitalUser,
    patient: faUser,
    gender: faVenusMars,
    dob: faCake,
    language: faLanguage,
    minor: faBaby,
    phone: faPhone,
    email: faEnvelope,
    address: faMapLocationDot,
    notes: faComments,
    show: faEye,
    hide: faEyeSlash,
    add: faCirclePlus,
    remove: faCircleMinus
  }
  formClasses = {
    formContainer: 'cc-form-container',
    form: 'cc-form flex-column',
    mainTitle: 'cc-main-title',
    subForm: 'cc-sub-form',
    section: 'cc-form-section',
    group: 'cc-form-group row',
    inputContainer: 'cc-input-container',
    inputContainerFullWidth: 'cc-input-container',
    label: 'cc-label',
    input: 'cc-input',
    icon: 'cc-input-icon',
    error: 'cc-input-error',
    actions: 'cc-form-actions',
    addButton: 'cc-add-button',
    removeButton: 'cc-remove-button',
    listItem: 'cc-list-item',
    oneCol: 'col-12',
    twoCol: window.innerWidth <= 768 ? 'col-12' : 'col-6',
    threeCol: window.innerWidth <= 768 ? 'col-12' : window.innerWidth > 768 && window.innerWidth <= 992 ? 'col-6' : 'col-4'
  };

  // Add this property declaration with the other properties in PatientFormComponent
  addressTypes: any;

  // Add this property to track if patients data is loaded
  patientsDataLoaded: boolean = false;

  // Add this property to track removed phones
  removedPhoneIds: number[] = [];

  ngAfterViewInit() {
    this.patientHTMLElement = document.querySelector('patient-form');
    this.addressFormComp.displayFields = ['Address1', 'Address2', 'City', 'State', 'Zip', 'AddressType'];
    this.updateWidth(this.patientHTMLElement?.clientWidth || window.innerWidth);
    this.watchInputElements();
  }

  // On form submit
  async onSubmit() {
    try {
      const submitType = this.submitType ? this.submitType : this.patientForm.get('Id')?.value ? 'PATCH' : 'POST';
      const endpoint = this.patientForm.get('Id')?.value ? `odata${APIEndpoints.Patients}(${this.patientForm.get('Id')?.value})` : `odata${APIEndpoints.Patients}`;

      this.isLoading = true;
      this.loadingMessage = 'Validating form...';

      let submitResult: any, addressResponse: any, xrefAddressResponse: any;

      // Exit if form is invalid
      if (this.patientForm.invalid || this.addressFormComp.addressForm.invalid) {
        this.markAllFieldsAsTouched();
        this.addressFormComp.markAllFieldsAsTouched();

        const errorMessage = 'Please correct the form errors before submitting';
        this.toast.showError('Form Invalid');

        throw new Error(errorMessage);
      }

      // Validate phone forms if they exist
      if (this.phoneForms && this.phoneForms.length > 0) {
        // Mark all phone forms as touched to trigger validation
        this.phoneForms.forEach(phoneForm => phoneForm.phoneForm.markAsTouched());

        // Check if any phone form is invalid
        const hasInvalidPhone = this.phoneForms.some(phoneForm => phoneForm.phoneForm.invalid);

        if (hasInvalidPhone) {
          const errorMessage = 'Please complete all phone number fields correctly';
          this.toast.showError('Invalid Phone Numbers');
          throw new Error(errorMessage);
        }
      }

      // Handle address form
      const patientAddressId = this.addressFormComp.addressForm.get('Id')?.value;
      if (!this.addressFormComp.addressForm.pristine) {
        this.loadingMessage = 'Saving address information...';
        addressResponse = await this.addressFormComp.onSubmit();

        if (addressResponse.error || !addressResponse) {
          throw new Error('Error submitting address');
        }
      }

      // Submit main form
      this.loadingMessage = 'Saving patient information...';
      submitResult = await this.formCRUD.submitForm(this.patientForm, endpoint, submitType);

      // Handle xref entries if needed
      if (!patientAddressId && addressResponse?.Id && submitResult?.Id) {
        this.loadingMessage = 'Linking address...';
        xrefAddressResponse = await this.api.fetchRequest(
          `odata${APIEndpoints.XrefAddressPatients}`,
          'POST',
          { AddressId: addressResponse.Id, PatientId: submitResult.Id }
        );
      }

      // Handle phone saving (new phones, updated phones, and deleted phones)
      if (submitResult?.Id) {
        await this.handlePhoneSaving(submitResult.Id);
      }

      this.formSubmitted.emit(submitResult);
      return submitResult;

    } catch (error) {
      this.errorHandling.handleError(error, {
        context: 'PatientFormComponent.onSubmit',
        userMessage: 'Failed to save patient information',
        severity: ErrorSeverity.Error
      });
      this.toast.showError('Error submitting patient');
      throw error;
    } finally {
      this.isLoading = false;
      this.loadingMessage = '';
    }
  }

  private async handlePhoneSaving(patientId: number): Promise<void> {
    // Skip if there are no phone forms and no removed phones
    if (this.phoneForms.length === 0 && this.removedPhoneIds.length === 0) {
      return;
    }

    try {
      // Process new and updated phones
      if (this.phoneForms.length > 0) {
        this.isLoading = true;
        this.loadingMessage = 'Saving phone information...';

        // Mark all forms as touched to trigger validation
        this.phoneForms.forEach(phoneForm => phoneForm.phoneForm.markAsTouched());

        // Only process forms that have changes
        const phoneFormsWithChanges = this.phoneForms.filter(form => !form.phoneForm.pristine);

        if (phoneFormsWithChanges.length > 0) {
          const phoneResults = await Promise.all(
            phoneFormsWithChanges.map(phoneForm => phoneForm.onSubmit() as Promise<Phone>)
          );

          // Filter out any failed submissions (would return false or undefined)
          const validPhoneResults = phoneResults.filter((result): result is Phone =>
            Boolean(result) && typeof result === 'object' && 'Id' in result
          );

          // Identify newly created phones that need xrefs
          const newlyCreatedPhones = validPhoneResults.filter(phone => {
            // Check if this is a new phone (didn't have an Id before submission but has one now)
            const originalPhoneForm = phoneFormsWithChanges.find(form =>
              form.phoneForm.value.PhoneNumber === phone.PhoneNumber
            );
            return originalPhoneForm && !originalPhoneForm.phoneForm.value.Id && phone.Id;
          });

          // Create xrefs for new phones
          if (newlyCreatedPhones.length > 0) {
            this.isLoading = true;
            this.loadingMessage = 'Saving phones...';

            await Promise.all(newlyCreatedPhones.map(phone =>
              this.api.fetchRequest(
                `odata${APIEndpoints.XrefPhonePatients}`,
                'POST',
                { PhoneId: phone.Id, PatientId: patientId }
              )
            ));
          }
        }
      }

      // Process removed phones
      if (this.removedPhoneIds.length > 0) {
        this.isLoading = true;
        this.loadingMessage = 'Removing deleted phone numbers...';

        // For each removed phone, find and delete its xref with this patient
        await Promise.all(this.removedPhoneIds.map(async (phoneId) => {
          // First, find the xref record
          const xrefEndpoint = `${APIEndpoints.XrefPhonePatients}`;
          const query = new Query()
            .where('PatientId', 'equal', patientId)
            .where('PhoneId', 'equal', phoneId);

          const response: any = await this.api.getOdata(xrefEndpoint).executeQuery(query);

          if (response?.result?.length > 0) {
            const xrefId = response.result[0].Id;
            // Delete the xref record
            await this.api.fetchRequest(
              `odata${APIEndpoints.XrefPhonePatients}(${xrefId})`,
              'DELETE'
            );
          }
        }));

        // Clear the removed phones array
        this.removedPhoneIds = [];
      }
    } catch (error) {
      this.errorHandling.handleError(error, {
        context: 'PatientFormComponent.handlePhoneSaving',
        userMessage: 'Some phone information could not be saved',
        severity: ErrorSeverity.Warning
      });
      // Continue with form submission even if phone saving fails
    }
  }

  markAllFieldsAsTouched(): void {
    Object.values(this.patientForm.controls).forEach(control => {
      control.markAsTouched();
      control.markAsDirty();
      control.updateValueAndValidity();
    });
  }

  // Perform updates on input elements
  watchInputElements() {
    document.querySelectorAll(`.cc-input-container`).forEach((inputContainer) => {
      const container = inputContainer as HTMLElement;

      container.querySelectorAll('input, span, textarea').forEach((input) => {
        input.addEventListener('focus', () => inputContainer.classList.add('focus'));
        input.addEventListener('blur', () => inputContainer.classList.remove('focus'));
      });
    });
  }

  // Switches form to 2 columns when parent is larger 1600px
  updateWidth(containerWidth: number) {

    if (containerWidth > 1200) {
      this.formClasses.form = this.formClasses.form.replace('flex-column', 'flex-row flex-wrap');
      this.formClasses.twoCol = this.formClasses.twoCol.replace('col-12', 'col-6');
    } else {
      this.formClasses.form = this.formClasses.form.replace('flex-row', 'flex-column');
      this.formClasses.twoCol = this.formClasses.twoCol.replace('col-6', 'col-12');
    }
  }

  // Update the layout on window resize
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    const containerWidth = this.patientHTMLElement?.clientWidth || window.innerWidth;
    this.updateWidth(containerWidth);
  }

  // Returns appropraite error message for form control
  getErrorMessage(controlName: string): string {
    let message = '';
    const control = this.patientForm.get(controlName);
    if (control?.errors) {
      if (control.errors['required']) message = 'This field is required';
      if (control.errors['name']) message = 'First and last name are required';
      if (control.errors['email']) message = 'Invalid email format';
      if (control.errors['invalidPhone']) message = 'Invalid phone number (10 digits required)';
      if (control.errors['serverError']) message = control.errors['serverError'].message;
    };
    this.errorMessage = message;
    return message;
  }

  // Fetches patient address
  async getPatientAddress(): Promise<Address | undefined> {
    const patientId = this.patient()?.Id;
    if (!patientId) return undefined;

    const endpoint = `${APIEndpoints.Patients}(${patientId})`;
    const query = new Query()
      .expand('XrefAddressPatients($expand=Address($select=Id,AddressType,Address1,Address2,City,State,Zip))');

    try {
      const response = await this.api.getOdata(endpoint).executeQuery(query);
      const patient = (response as any).result[0];

      if (!patient?.XrefAddressPatients?.length) {
        return undefined;
      }

      const address = patient.XrefAddressPatients[0].Address;
      this.patientAddress.set(address);

      return address;

    } catch (error) {
      this.errorHandling.handleError(error, {
        context: 'PatientFormComponent.getPatientAddress',
        userMessage: 'Unable to load patient address information',
        severity: ErrorSeverity.Warning
      });
      return undefined;
    }
  }

  // Update initializeDropdownData to check if we need to load patients
  async initializeDropdownData() {
    try {
      // Skip patient data loading if select is not shown
      if (!this.showPatientSelect) {
        this.patientsDataLoaded = true; // Set as loaded so we don't show loading indicator

        // Only load languages and address types
        await this.initializeLanguagesData();

        // Address types rarely change, good candidate for longer caching
        const addressTypesCacheKey = 'reference:addressTypes';
        this.addressTypes = this.dataCache.get(addressTypesCacheKey) ||
          this.api.getOdata(APIEndpoints.AddressTypes);

        if (!this.dataCache.has(addressTypesCacheKey)) {
          this.dataCache.set(addressTypesCacheKey, this.addressTypes);
        }

        return; // Exit early, no need to load patient data
      }

      // Set loading flag at the start
      this.patientsDataLoaded = false;

      // Only show loading overlay if we're not in add form mode or if it's explicitly requested
      const isAddForm = !this.isEditPatient && !this.patient()?.Id;
      if (!isAddForm) {
        this.isLoading = true;
        this.loadingMessage = 'Loading patients...';
      }

      // Cache patient data - this changes more frequently so consider a shorter TTL
      const patientsCacheKey = 'reference:patients';
      const optimizedPatientEndpoint = `${APIEndpoints.Patients}?$select=Id,Firstname,Lastname,Email,Dob,Gender,Language,Minor`;

      // For patients, we might want to always get fresh data or check if cache is very recent
      const usePatientCache = this.dataCache.has(patientsCacheKey);
      this.patients = usePatientCache ?
        this.dataCache.get(patientsCacheKey) :
        this.api.getOdata(optimizedPatientEndpoint);

      if (!usePatientCache) {
        this.dataCache.set(patientsCacheKey, this.patients);
      }

      // After getting patients data, load it into dropdown format
      const patientFields = 'Id,Firstname,Lastname,Email,Dob,Gender,Language,Minor';
      await this.patients.executeQuery(new Query().select(patientFields)).then((res: any) => {
        this.patients = [
          {
            text: '+ Add New Patient',
            value: 0,
            patient: null
          },
          ...(res as any).result.map((patient: Patient) => ({
            text: `${patient.Firstname} ${patient.Lastname} DOB: ${patient.Dob}`,
            value: patient.Id,
            patient: patient
          }))
        ];

        // If we have a selected patient, find and select it
        this.selectedPatientId = this.patient()?.Id;

        // Set the loaded flag to true
        this.patientsDataLoaded = true;

        // Only clear loading status if we set it earlier
        if (!isAddForm) {
          this.isLoading = false;
          this.loadingMessage = '';
        }
      });

      // Address types rarely change, good candidate for longer caching
      const addressTypesCacheKey = 'reference:addressTypes';
      this.addressTypes = this.dataCache.get(addressTypesCacheKey) ||
        this.api.getOdata(APIEndpoints.AddressTypes);

      if (!this.dataCache.has(addressTypesCacheKey)) {
        this.dataCache.set(addressTypesCacheKey, this.addressTypes);
      }

      // Set up queries (existing code)
      this.patientsQuery = new Query().select('Id,Firstname,Lastname,Email,Dob,Gender,Language,Minor').expand('XrefAddressPatients($expand=Address($select=Id,AddressType,Address1,Address2,City,State,Zip))');

    } catch (error) {
      this.patientsDataLoaded = false; // Ensure flag is reset on error
      this.errorHandling.handleError(error, {
        context: 'PatientFormComponent.initializeDropdownData',
        userMessage: 'Failed to load patient form data',
        severity: ErrorSeverity.Warning
      });
    }
  }

  // Update signals
  onPatientSelect(args: any) {
    if (args.itemData.value === 0 || args.itemData.value === null) {
      this.patient.set(undefined);
      this.addressFormComp.address.set(undefined);
      this.patientSelected.emit({ value: null, patient: null });
      this.displayForm = true;
    } else {
      // Get the patient ID value immediately for emitting
      const patientId = args.itemData.value;

      // If the patient object doesn't have address data, fetch it
      if (!args.itemData.patient.XrefAddressPatients) {
        this.isLoading = true;
        this.loadingMessage = 'Loading patient details...';

        // Fetch the complete patient data with address
        this.api.getOdata(`${APIEndpoints.Patients}(${patientId})`)
          .executeQuery(this.patientsQuery)
          .then((response: any) => {
            const fullPatientData = response.result[0];

            // Update the patient data
            this.patient.set(fullPatientData);

            // Set address if available
            if (fullPatientData.XrefAddressPatients && fullPatientData.XrefAddressPatients.length > 0) {
              this.addressFormComp.address.set(fullPatientData.XrefAddressPatients[0].Address);
            } else {
              this.addressFormComp.address.set(undefined);
            }

            // Ensure we're emitting a numeric ID, not an object or string
            this.patientSelected.emit({
              value: Number(patientId),
              patient: fullPatientData
            });

            this.isLoading = false;
            this.loadingMessage = '';
          })
          .catch(error => {
            this.errorHandling.handleError(error, {
              context: 'PatientFormComponent.onPatientSelect',
              userMessage: 'Failed to load complete patient details',
              severity: ErrorSeverity.Warning
            });

            // Still emit the ID we already have
            this.patientSelected.emit({
              value: Number(patientId),
              patient: args.itemData.patient
            });
          });
      } else {
        // We already have the complete data
        this.patient.set(args.itemData.patient);

        // Set address if available
        if (args.itemData.patient.XrefAddressPatients && args.itemData.patient.XrefAddressPatients.length > 0) {
          this.addressFormComp.address.set(args.itemData.patient.XrefAddressPatients[0].Address);
        } else {
          this.addressFormComp.address.set(undefined);
        }

        // Ensure we're emitting a numeric ID, not an object or string
        this.patientSelected.emit({
          value: Number(patientId),
          patient: args.itemData.patient
        });
      }
    }
  }

  onDobChange(args: any) {
    args.value = new Date(args.value).toISOString().split('T')[0];
    this.patientForm.get('Dob')?.setValue(args.value);
  }

  onDobFocus(args: FocusEventArgs): void {
    if (this.dobComp) {
      this.dobComp.show();
    }
  }

  // Update resetForms to handle loading state
  public resetForms(): void {
    this.isLoading = true;
    this.loadingMessage = 'Resetting form...';
    this.selectedPatientId = undefined;
    this.patientForm.reset({}, { emitEvent: false });

    // Reset address form if it exists
    if (this.addressFormComp?.addressForm) {
      this.addressFormComp.addressForm.reset({}, { emitEvent: false });
    }

    // Reset phone data
    this.patientPhones.set(undefined);

    // Clear patient data
    this.patient.set(undefined);

    // Set loading state to false after reset
    setTimeout(() => {
      this.isLoading = false;
      this.loadingMessage = '';
    }, 100);
  }


  // Add method to ensure we have a signal
  private ensurePatientSignal() {
    if (!this.patient) {
      this.patient = signal(undefined);
    }
  }

  // Add filtering handler
  onDropdownFiltering(filterEvent: FilteringEventArgs, data: any) {
    let query = new Query();
    query = (filterEvent.text !== "") ? query.where("text", "contains", filterEvent.text, true) : query;
    filterEvent.updateData(data, query);
  }

  // Add a new method for just languages (lightweight)
  async initializeLanguagesData() {
    try {
      const cacheKey = 'reference:languages';
      if (this.dataCache.has(cacheKey)) {
        this.languages = this.dataCache.get(cacheKey);
      } else {
        const query = new Query().where('IsActive', 'equal', true);
        const dataManager = this.api.getOdata(APIEndpoints.Languages);
        const response: any = await dataManager.executeQuery(query);
        // Add empty option to the languages array
        this.languages = [
          { Id: null, Description: '-- Select Language --', IsActive: true },
          ...response.result
        ];
        this.dataCache.set(cacheKey, this.languages);
      }
    } catch (error) {
      this.errorHandling.handleError(error, {
        context: 'PatientFormComponent.initializeLanguagesData',
        userMessage: 'Failed to load languages data',
        severity: ErrorSeverity.Warning
      });
    }
  }

  // Add a method to be called when a dialog is opened
  public onDialogOpen() {
    // Check if patients data already loaded
    if (!this.patients || (Array.isArray(this.patients) && this.patients.length <= 1)) {
      this.initializeDropdownData();
    }
  }

  // Update getPatientPhones to handle loading state
  async getPatientPhones() {
    const patientId = this.patient()?.Id;
    if (!patientId) return;

    try {
      const endpoint = `${APIEndpoints.XrefPhonePatients}`;
      const expandString = 'Phone($select=Id,PhoneType,PhoneNumber,Extension)';
      const predicate = new Predicate('PatientId', 'equal', patientId);
      const query = new Query().expand(expandString).where(predicate);
      const res: any = await this.api.getOdata(endpoint).executeQuery(query);

      // Set phone signal - activates change detection to reset form if no phone is found
      const phones = res.result.length > 0 ? res.result.map((phone: any) => phone.Phone) : undefined;
      this.patientPhones.set(phones);

      return this.patientPhones();
    } catch (error) {
      this.errorHandling.handleError(error, {
        context: 'PatientFormComponent.getPatientPhones',
        userMessage: 'Unable to load patient phone information',
        severity: ErrorSeverity.Warning
      });
      return undefined;
    }
  }

  // Helper method to convert object to signal
  returnPhoneSignal(value: Phone | undefined): WritableSignal<Phone | undefined> {
    return signal(value);
  }

  // Add new phone form
  addNewPhone() {
    const currentPhones = this.patientPhones() || [];
    this.patientPhones.set([...currentPhones, {}]);
  }

  // Remove phone form at index
  removePhone(index: number) {
    const currentPhones = this.patientPhones() || [];

    // If the phone being removed has an ID, track it for deletion
    const phoneToRemove = currentPhones[index];
    if (phoneToRemove && phoneToRemove.Id) {
      this.removedPhoneIds.push(phoneToRemove.Id);
    }

    const updatedPhones = currentPhones.filter((_, i) => i !== index);
    this.patientPhones.set(updatedPhones);
  }
}
