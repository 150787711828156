// Angular
import { Component, ViewChild, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, FormsModule } from '@angular/forms';
import { Router } from '@angular/router';

// 3rd Party
import { DataManager, Query } from '@syncfusion/ej2-data';
import { ToolbarClickEventArgs } from '@syncfusion/ej2-richtexteditor';
import { DropDownList, DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { DialogAllModule, DialogComponent } from '@syncfusion/ej2-angular-popups';
import { CommandClickEventArgs, CommandModel, DetailDataBoundEventArgs, EditEventArgs, Grid, GridModel, RowSelectingEventArgs, ColumnChooserService } from '@syncfusion/ej2-angular-grids';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { CombinedInvoices, CombinedInvoicesByPayment, CombinedInvoice } from '@models/components/accounting.model';
import { Invoice, InvoiceRow, InvoicePayment, CaseFile, Patient, Provider } from '@models/data-contracts';
import { ApiService } from '@services/api/api.service';
import { GridTemplateModule } from '@modules/grid-template.module';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { GridTemplateComponent } from '../grid-template/grid-template.component';
import { BulkEditInvoicesForm } from '../../forms/bulk-edit-forms/bulk-edit-invoices/bulk-edit-invoices.component';
import { AddInvoiceForm } from '../../forms/add-forms/add-invoice/add-invoice.component';
import { LoadingModule } from '@modules/loading.module';
import { GlobalsService } from '@services/globals/globals.service';
// import { paymentStatusesList } from '@models/global-vars';
import { UserPreferencesService } from '@services/user/user-preferences.service';
import { AddNewInvoiceForm } from '../../forms/add-forms/add-new-invoice-form/add-new-invoice-form.component';


@Component({
  selector: 'invoices-grid',
  standalone: true,
  imports: [
    FormsModule,
    GridTemplateModule,
    DialogAllModule,
    DropDownListModule,
    BulkEditInvoicesForm,
    LoadingModule,
    AddNewInvoiceForm
  ],
  providers: [
    ColumnChooserService  
  ],
  templateUrl: './invoices-grid.component.html',
  styleUrl: './invoices-grid.component.scss'
})
export class InvoicesGridComponent {

  constructor (
    private api: ApiService,
    private toast: ToastMessageService,
    private router: Router,
    private user: UserPreferencesService,
    public globals: GlobalsService
  ) {}

  // @ViewChild('paymentStatusesEditTemplate', { read: TemplateRef }) paymentStatusesEditTemplate: TemplateRef<any>;

  @ViewChild('invoicesGrid') invoicesGrid: GridTemplateComponent;
  gridData: any[] = [];
  invoices: CombinedInvoice[] = [];
  invoicesGridSettings: GridModel;
  gridSelectedIndexes: number[];

  // Custom dialogs for adding & bulk editing files
  @ViewChild('addInvoiceDialog', { static: true}) addInvoiceDialog: DialogComponent;
  @ViewChild('providersTemplate', { static: true }) providersTemplate!: string;
  @ViewChild('paymentStatusesEditTemplate', { static: true, read: TemplateRef }) paymentStatusesEditTemplate!: string;
  @ViewChild('newInvoiceForm') newInvoiceForm: AddNewInvoiceForm;
  @ViewChild('bulkEditForm') bulkEditForm: any;
  @ViewChild('providersDropdown') providersDropdown: DropDownList;
  @ViewChild('paymentStatusDropdown') paymentStatusDropdown: DropDownList;
  addInvoiceDialogVisibility: boolean = false;
  formattedDBData: Invoice[] = [];
  selectedRows: any[] = [];
  providersData: DataManager;
  paymentStatuses: DataManager;
  addInvoiceButtons: Object[] = [
    { click: this.closeDialog.bind(this), buttonModel: { content: 'Cancel', cssClass: 'e-outline' } },
    { click: this.clearNewInvoiceForm.bind(this), buttonModel: { content: 'Reset', isPrimary: false } },
    { click: this.onNewInvoiceSubmit.bind(this), buttonModel: { content: 'Submit', isPrimary: true, cssClass: 'e-primary' } }
  ];
  @ViewChild('bulkEditInvoicesDialog') bulkEditInvoicesDialog: DialogComponent;
  bulkEditInvoicesDialogVisibility: boolean = false;
  bulkUpdateInvoicesButtons: Object[] = [
    { click: this.closeDialog.bind(this), buttonModel: { content: 'Cancel', cssClass: 'e-outline' } },
    { click: this.clearBulkEditForm.bind(this), buttonModel: { content: 'Reset', isPrimary: false } },
    { click: this.onBulkEditSubmit.bind(this), buttonModel: { content: 'Submit', isPrimary: true, cssClass: 'e-primary' } }
  ];

  ngOnInit() {

    this.invoicesGridSettings = {
      dataSource: this.api.getOdata(APIEndpoints.Invoices),
      query: new Query().expand('CaseFile($expand=Patient),InvoiceRows,InvoicePayments($expand=PaymentMethodNavigation,PaymentStatusNavigation),Provider'),
      toolbar: ['Add'],
      showColumnChooser: true,
      columns: [
        { type: 'checkbox' },
        { field: 'Id' },
        { field: 'CaseFile.FileNumber', headerText: 'File #', allowEditing: false },
        { field: 'CaseFile.Patient.Firstname', headerText: 'Client First Name', allowEditing: false },
        { field: 'CaseFile.Patient.Lastname', headerText: 'Client Last Name', allowEditing: false },
        { field: 'InternalInvoiceNumber', headerText: 'INV #', allowEditing: false },
        { field: 'Provider.Name', headerText: 'Provider', editTemplate: this.providersTemplate, editType: 'dropdownedit' },
        { field: 'Notes', headerText: 'Notes' },
        { type: 'commands', headerText: 'Actions' }
      ],
      actionBegin: ($event: any) => this.onMainGridActionBegin($event),
      actionComplete: ($event: any) => this.onMainGridActionComplete($event),
      toolbarClick: ($event: ToolbarClickEventArgs) => this.onMainGridToolbarClick($event),
      commandClick: ($event: CommandClickEventArgs) => this.onMainGridCommandClick($event),
      rowSelecting: ($event: RowSelectingEventArgs) => this.onMainGridRowSelecting($event),
      detailDataBound: ($event: any) => this.onMainGridDetailBound($event),
    }
  }

  // Set data for editing items
  onMainGridActionBegin(args: any) {

    if (args.requestType === 'beginEdit') {
      this.paymentStatuses = this.api.getOdata(APIEndpoints.PaymentStatuses);
      this.providersData = this.api.getOdata(APIEndpoints.Providers);
      this.proceduresData = this.api.getOdata(APIEndpoints.ProcedureCodes);
    }

    if (args.requestType === 'save') {
      args.data.ProviderId = this.providersDropdown.value;
      args.data.InvoicePaymentPaymentStatus = this.paymentStatusDropdown.value;
    }
  }

  // Handle saving to multiple endpoints
  onMainGridActionComplete(args: any) {

    if (args.requestType === 'save') {
      const dataChanged = JSON.stringify(args.data) !== JSON.stringify(args.previousData);

      if (dataChanged === true) {
        args.data.InvoicePayment.DatePaid = args.data.InvoicePayment.DatePaid ? this.globals.formatDateForBackend(args.data.InvoicePayment.DatePaid) : undefined;
        let invoiceObj: any = { 
          InternalInvoiceNumber: args.data.InternalInvoiceNumber,
          Notes: args.data.Notes,
          ProviderId: this.providersDropdown.value,
          ProviderInvoiceNumber: args.data.ProviderInvoiceNumber
        };

        let invoiceRowObj: any = {
          AmountBilled: args.data.InvoiceRow.AmountBilled,
          SettlementValue: args.data.InvoiceRow.SettlementValue,
          TotalDueProvider: args.data.InvoiceRow.TotalDueProvider
        };

        let invoicePaymentObj: any = {
          AmountPaid: args.data.InvoicePayment.AmountPaid,
          BalanceDue: args.data.InvoicePayment.BalanceDue,
          DatePaid: args.data.InvoicePayment.DatePaid,
          PaymentStatus: this.paymentStatusDropdown.value
        };

        if (args.data.InvoiceId) {
          this.api.fetchRequest(`odata${APIEndpoints.Invoices}(${args.data.InvoiceId})`, 'PATCH', invoiceObj);
        } 
        
        if (args.data.InvoiceRow.Id) {
          this.api.fetchRequest(`odata${APIEndpoints.InvoiceRows}/${args.data.InvoiceRow.Id}`, 'PATCH', invoiceRowObj);
        } 
        
        if (args.data.InvoicePayment.Id) {
          this.api.fetchRequest(`odata${APIEndpoints.InvoicePayments}(${args.data.InvoicePayment.Id})`, 'PATCH', invoicePaymentObj);
        }
        
        this.invoicesGrid.grid.refreshColumns();
      }
    }
  }

  // Add logic for rendering modals
  onMainGridToolbarClick(args: ToolbarClickEventArgs) {
    console.log('args: ', args);
    if (args.item.text === 'Add') {
      args.cancel = true;
      this.addInvoiceDialogVisibility = true;
      setTimeout(() => { this.addInvoiceDialog.show() });
    } else if (args.item.id === 'BulkEdit') {
      this.bulkEditInvoicesDialogVisibility = true;
      setTimeout(() => { this.bulkEditInvoicesDialog.show() });
    }

    if (args.item.text === 'Refresh') {
      this.invoicesGrid.grid.refresh();
    }
  }

  // Add logic for navigating to individual case files
  onMainGridCommandClick(commandClickArgs: CommandClickEventArgs) {
    const data: any = commandClickArgs?.rowData;
    const btn = commandClickArgs.commandColumn?.buttonOption;

    if (data !== undefined) {
      const viewItem: boolean | undefined = btn?.iconCss?.includes('e-eye');
      const deleteItem: boolean | undefined = btn?.iconCss?.includes('e-trash');

      if (viewItem) {
        window.open(`${window.location.origin}/case-files/${data.FileNumber}#Financial`, '_blank'); 
      } else if (deleteItem) {
        console.log(commandClickArgs);
      }

    } else {
      console.error('commandClickArgs: ', commandClickArgs);
      this.toast.showError('No data found.');
    }
  }
  
  // Add button for bulk editing invoices
  onMainGridRowSelecting(args: RowSelectingEventArgs) {

    if (args.rowIndexes) {
      const gridName = this.invoicesGrid.grid.toolbar[0];
      const newToolbarItem = { text: 'Bulk Edit', tooltipText: 'Bulk Edit', id: 'BulkEdit' };
      const toolbarHasBulkEditBtn = this.invoicesGrid.grid.toolbar.some((item: any) => item.id === 'BulkEdit');
      this.gridSelectedIndexes = args.rowIndexes;
      
      if (args.rowIndexes.length > 1) {

        if (!toolbarHasBulkEditBtn) {
          this.invoicesGrid.insertToolbarItem(newToolbarItem);
        }

      }
    }
  }

  // Close both modal windows
  closeDialog() {
    this.addInvoiceDialog.hide();
    this.bulkEditInvoicesDialog.hide();
  }

  // Set height of modals
  beforeOpening(args: any) {
    args.maxHeight = '85vh';
  }

  // Set fields to blank
  clearNewInvoiceForm() {
    (this.newInvoiceForm.invoiceForm as FormGroup).reset();
  }

  // Add new file to grid
  onNewInvoiceSubmit(args: any) {
    let form = this.newInvoiceForm.invoiceForm;

    if (form.valid) {
      this.invoicesGrid?.grid.editModule.addRecord(form.value);
      this.closeDialog();
    } else {
      console.log(form);
      this.toast.showError('Form invalid.');
    }
  }

  // Set bulk editing fields to blank
  clearBulkEditForm() {
    (this.bulkEditForm.bulkEditInvoicesForm as FormGroup).reset();
  }

  // Apply bulk changes to data
  onBulkEditSubmit(args: any) {
    let form = this.bulkEditForm.bulkEditInvoicesForm;

    if (form.valid) {

      this.gridSelectedIndexes.forEach((row) => {
        const rowData: any = this.invoicesGrid.grid.getRowInfo(this.invoicesGrid.grid.getRowByIndex(row)).rowData;
        const rowId: number = rowData.Id;
        
        if (form.value.DatePaid) {
          form.value.DatePaid = this.globals.formatDateForBackend(form.value.DatePaid);

          if (rowData.InvoicePayment !== undefined) {
            const updatePayment = { DatePaid: form.value.DatePaid, PaymentMethod: form.value.PaymentMethod, PaymentStatus: form.value.PaymentStatus };
            this.api.fetchRequest(`odata${APIEndpoints.InvoicePayments}(${rowData.InvoicePayment.Id})`, 'PATCH', updatePayment);
          } else {
            const newPayment = { InvoiceId: rowData.Id, DatePaid: form.value.DatePaid, PaymentMethod: form.value.PaymentMethod, PaymentStatus: form.value.PaymentStatus };
            this.api.fetchRequest(`odata${APIEndpoints.InvoicePayments}`, 'POST', newPayment);
          }
        }

        if (form.value.Notes) {
          this.api.fetchRequest(`odata${APIEndpoints.Invoices}(${rowId})`, 'PATCH', { Notes: form.value.Notes });
        }

      });

      this.closeDialog();
      this.invoicesGrid.grid.refreshColumns();

    } else {
      console.log((this.bulkEditForm.bulkEditInvoicesForm as FormGroup).errors);
      alert('Please enter a valid form.');
    }
  }

  // Custom grids for expanded invoice rows
  @ViewChild('procedureCodesTemplate', { static: true }) procedureCodesTemplate!: any;
  @ViewChild('proceduresDropdown') proceduresDropdown: DropDownList;
  @ViewChild('invoiceRowsGrid', { static: true }) invoiceRowsGrid!: any;
  proceduresData: DataManager;
  invoiceRowsGridSettings: GridModel;

  // Add additional grids as detailed elements of each invoice row
  async onMainGridDetailBound(e: DetailDataBoundEventArgs ) {
    let detailDiv = document.createElement('div');
    detailDiv.setAttribute('id', 'detail-div');

    let grid1Div = document.createElement('div');
    grid1Div.setAttribute('id', 'grid1-div');
    grid1Div.setAttribute('style', 'width: auto;');
    let grid1Header = document.createElement('h4');
    grid1Header.innerHTML = 'Invoice Rows';
    grid1Header.setAttribute('style', 'margin: 0;');
    let detail1 = new Grid({
      dataSource: (e.data as any).InvoiceRows,
      editSettings:  { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Dialog' },
      toolbar: [{ template: '<span class="h4">Invoice Rows</span>', align: 'Left' }],
      allowFiltering: false,
      columns: [
        { type: 'checkbox', width: 50 },
        { field: 'Id', isPrimaryKey: true, visible: false },
        { field: 'DateOfService', headerText: 'Service Date', editType: 'datepickeredit',  type: 'date', format: 'yyyy-MM-dd' },
        { field: 'ProcedureCode.Description', headerText: 'Procedure', editType: 'dropdownedit' },
        { field: 'ReimbursementRate', headerText: 'Reimbursement Rate', editType: 'numericedit', format: 'P' },
        { field: 'AmountBilled', headerText: 'Billed Amount', format: 'C2', editType: 'numericEdit' },
        { field: 'TotalDueProvider', headerText: 'Provider Amount', format: 'C2', editType: 'numericEdit' },
        { field: 'SettlementValue', headerText: 'Settlement', format: 'C2', editType: 'numericEdit' },
      ],
      actionBegin: ($event) => this.onInvoiceRowsGridActionBegin($event),
      actionComplete: ($event) => this.onInvoiceRowsGridActionComplete($event)
    });

    if ((e.data as any).InvoiceRows.length > 0) {
      detail1.appendTo(grid1Div as HTMLElement);
    } else {
      grid1Header.innerHTML = 'No Additional Rows';
      grid1Div.appendChild(grid1Header);  
    }

    let grid2Div = document.createElement('div');
    grid2Div.setAttribute('id', 'grid2-div');
    grid2Div.setAttribute('style', 'width: auto;');
    let grid2Header = document.createElement('h4');
    grid2Header.innerHTML = 'Invoice Payments';
    grid2Header.setAttribute('style', 'margin: 0;');
    let detail2 = new Grid({
      dataSource: (e.data as any).InvoicePayments,
      editSettings:  { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Dialog' },
      toolbar: [{ template: '<span class="h4">Invoice Payments</span>'}],
      allowFiltering: false,
      columns: [
        { type: 'checkbox', width: 50 },
        { field: 'Id', isPrimaryKey: true, visible: false },
        { field: 'DatePaid', headerText: 'Payment Date', editType: 'datepickeredit', type: 'date', format: 'yyyy-MM-dd' },
        { field: 'BalanceDue', headerText: 'Balance', format: 'C2', editType: 'numericEdit' },
        { field: 'AmountPaid', headerText: 'Amount Paid', format: 'C2', editType: 'numericEdit' },
        { field: 'PaymentMethodNavigation.Description', headerText: 'Method', editType: 'dropdownedit' },
        { field: 'PaymentStatusNavigation.Description', headerText: 'Status', editType: 'dropdownedit' }
      ],
      actionBegin: ($event) => this.onInvoicePaymentsGridActionBegin($event),
      actionComplete: ($event) => this.onInvoicePaymentsGridActionComplete($event)
    });
    // grid2Div.appendChild(grid2Header);
    if ((e.data as any).InvoicePayments.length > 0) {
      detail2.appendTo(grid2Div as HTMLElement);
    } else {
      grid2Header.innerHTML = 'No Payments';
      grid2Div.appendChild(grid2Header);  
    }

    detailDiv.appendChild(grid1Div);
    detailDiv.appendChild(grid2Div);

    ((e.detailElement as HTMLElement).querySelector('.custom-details') as HTMLElement).appendChild(detailDiv);
  }

  adjustEditDialog(args: EditEventArgs) {
    if ((args.requestType === 'beginEdit' || args.requestType === 'add')) {
      const dialog = (args as any).dialog;
      dialog.showCloseIcon = false;
      dialog.width = '90vw';
      dialog.height = '85vh';
    }
  }

  onInvoiceRowsGridActionBegin(args: any) {
    // console.log(args);
  }

  onInvoiceRowsGridActionComplete(args: any) {
    this.adjustEditDialog(args);

    if (args.requestType === 'save') {
      delete args.data.CreatedAt;
      delete args.data.CreatedBy;
      delete args.data.ProcedureCode;
      args.data.DateOfService = this.globals.formatDateForBackend(args.data.DateOfService);
      this.api.fetchRequest(`odata${APIEndpoints.InvoiceRows}(${args.rowData.Id})`, 'PATCH', args.data);
    }
  }

  onInvoicePaymentsGridActionBegin(args: any) {
    // console.log(args);
  }

  onInvoicePaymentsGridActionComplete(args: any) {
    this.adjustEditDialog(args);

    if (args.requestType === 'save') {
      delete args.data.CreatedAt;
      delete args.data.CreatedBy;
      delete args.data.PaymentMethodNavigation;
      delete args.data.PaymentStatusNavigation;
      args.data.DatePaid = this.globals.formatDateForBackend(args.data.DatePaid);
      this.api.fetchRequest(`odata${APIEndpoints.InvoicePayments}(${args.rowData.Id})`, 'PATCH', args.data);
    }
  }
}
