export const secrets = {
    BoxDeveloperToken: 'ca0pY44Lhfg2WXsZnTZyHcEeCtDioCjM',
    mondayApiToken: 'eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjQ1NjAyMTAwMywiYWFpIjoxMSwidWlkIjo3MDU5OTc4MCwiaWFkIjoiMjAyNS0wMS0wOVQyMzozMDoxMS4xOTZaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6MjczNTU1MjYsInJnbiI6InVzZTEifQ.ISF8D2-SoWC0FV5pb3w-pCASznq_0YT9Pe97M11df7k',
    SYNCFUSION_KEY: 'Ngo9BigBOggjHTQxAR8/V1NMaF5cXmBCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWH1fcnVTRmZeV0N/Wko=',
    amplifyConfiguration: {
        aws_project_region: 'us-west-2',
        aws_cognito_identity_pool_id: 'us-west-2:584e93b1-beca-40fb-9b1c-f501ff78f4e3',
        aws_cognito_region: 'us-west-2',
        aws_user_pools_id: 'us-west-2_kD3g0xRL6',
        aws_user_pools_web_client_id: '6qoseebakm4rot6ved4m5kicr3',
        aws_cognito_signup_attributes: ['EMAIL'],
        aws_cognito_mfa_configuration: 'OPTIONAL',
        aws_cognito_mfa_types: ['SMS', 'TOTP'],
        aws_cognito_password_protection_settings: {
            passwordPolicyMinLength: 8,
            passwordPolicyCharacters: [
                'REQUIRES_LOWERCASE',
                'REQUIRES_UPPERCASE',
                'REQUIRES_NUMBERS',
                'REQUIRES_SYMBOLS'
            ]
        },
        aws_cognito_verification_mechanisms: ['EMAIL']
    }
}; 