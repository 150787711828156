// Angular
import { Component, ViewChild, inject } from '@angular/core';
import { ReactiveFormsModule, FormBuilder } from '@angular/forms';
import { CommonModule } from '@angular/common';

// 3rd Party
import { Dialog } from '@syncfusion/ej2-popups';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { ButtonModule, CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { Query } from '@syncfusion/ej2-data';
import { ComboBoxModule } from '@syncfusion/ej2-angular-dropdowns';

// Internal
import { APIEndpoints } from '@shared/components/base-grid/base-grid.component';
import { ApiService } from '@services/api/api.service';
import { LawFirm, LawFirmUpdateDTO } from '@models/data-contracts';
import { UserRolesService } from '@shared/services/roles/user-roles.service';

@Component({
  selector: 'bulk-edit-law-firms',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, DialogModule, ButtonModule, CheckBoxModule, ComboBoxModule],
  template: `
    <ejs-dialog
      #Modal
      [visible]="false"
      [header]="'Bulk Edit Law Firms'"
      [showCloseIcon]="true"
      [target]="'body'"
      [isModal]="true"
      [width]="500"
      (beforeOpen)="beforeModalOpened($event)"
    >
      <ng-template #content>
        <form [formGroup]="editForm" (ngSubmit)="onSubmit()">
          <div class="form-row">
            <label>Market Manager</label>
            <ejs-combobox
              formControlName="MarketManager"
              [dataSource]="marketManagersDataSource"
              [fields]="{ text: 'Name', value: 'Id' }"
              [query]="marketManagersQuery"
              placeholder="Select Market Manager"
            ></ejs-combobox>
          </div>
          <div class="form-row">
            <label>Case Manager</label>
            <ejs-combobox
              formControlName="CaseManager"
              [dataSource]="caseManagersDataSource"
              [fields]="{ text: 'Name', value: 'Id' }"
              [query]="caseManagersQuery"
              placeholder="Select Case Manager"
            ></ejs-combobox>
          </div>
          <div class="form-row">
            <label>Status Group</label>
            <ejs-combobox
              formControlName="StatusGroupId"
              [dataSource]="statuserDataSource"
              [fields]="{ text: 'Description', value: 'Id' }"
              [query]="statuserQuery"
              placeholder="Select Status Group"
            ></ejs-combobox>
          </div>
          <div class="form-row">
            <ejs-checkbox formControlName="IsActive" label="Is Active"></ejs-checkbox>
          </div>
          <div class="form-actions">
            <button ejs-button type="submit" [isPrimary]="true">Submit</button>
            <button ejs-button type="button" (click)="modal.hide()">Cancel</button>
          </div>
        </form>
      </ng-template>
    </ejs-dialog>
  `,
  styles: [
    `
      .form-row {
        margin-bottom: 1rem;
      }
      .form-row label {
        display: block;
        margin-bottom: 0.5rem;
      }
      .form-actions {
        margin-top: 1.5rem;
        display: flex;
        gap: 1rem;
        justify-content: flex-end;
      }
    `,
  ],
})
export class BulkEditLawFirmsComponent {
  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private userRoles: UserRolesService
  ) {}

  @ViewChild('Modal') modal: Dialog;
  selectedRows: LawFirm[] = [];
  marketManagersDataSource: any;
  caseManagersDataSource: any;
  statuserDataSource: any;
  marketManagersQuery: Query;
  caseManagersQuery: Query;
  statuserQuery: Query;

  // Make APIEndpoints available to template
  protected APIEndpoints = APIEndpoints;

  editForm = this.fb.group({
    MarketManager: [null],
    CaseManager: [null],
    IsActive: [false],
    StatusGroupId: [null],
  });

  showModal() {
    this.modal.show();
  }

  ngOnInit() {
    const { dataSource: marketManagersDs, query: marketManagersQuery } = this.userRoles.getMarketManagers();
    this.marketManagersDataSource = marketManagersDs;
    this.marketManagersQuery = marketManagersQuery;

    const { dataSource: caseManagersDs, query: caseManagersQuery } = this.userRoles.getCaseManagers();
    this.caseManagersDataSource = caseManagersDs;
    this.caseManagersQuery = caseManagersQuery;

    const { dataSource: statuserDs, query: statuserQuery } = this.userRoles.getStatusGroups();
    this.statuserDataSource = statuserDs;
    this.statuserQuery = statuserQuery;
  }

  beforeModalOpened(args: any) {
    args.maxHeight = '85vh';
  }

  submit = async () => {
    try {
      const changedLawFirms: LawFirmUpdateDTO[] = this.selectedRows.map(lawFirm => ({
        Id: lawFirm.Id,
        ...this.getChangedValues(),
      }));

      const syncFusionDTO = {
        Action: 'Update',
        Added: [],
        Changed: changedLawFirms,
        Deleted: [],
      };

      const response = await this.api.basicPost(APIEndpoints.LawfirmsBulk, syncFusionDTO);

      this.modal.hide();
      window.location.reload();
    } catch (error) {
      console.error('Error in submit:', error);
    }
  };

  private getChangedValues(): Partial<LawFirmUpdateDTO> {
    const changes: Partial<LawFirmUpdateDTO> = {};
    const formValues = this.editForm.value;

    // Only include fields that have been changed
    if (formValues.MarketManager !== null) {
      changes.MarketManager = formValues.MarketManager;
    }
    if (formValues.CaseManager !== null) {
      changes.CaseManager = formValues.CaseManager;
    }
    if (formValues.IsActive !== null) {
      changes.IsActive = formValues.IsActive;
    }
    if (formValues.StatusGroupId !== null) {
      changes.StatusGroupId = formValues.StatusGroupId;
    }

    return changes;
  }

  onSubmit() {
    this.submit();
  }
}
