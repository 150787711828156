// Angular
import { Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ReactiveFormsModule, FormControl, FormGroup } from '@angular/forms';
import { CommonModule } from '@angular/common';

// 3rd Party
import { DataManager } from '@syncfusion/ej2-data';
import { MultiSelectComponent } from '@syncfusion/ej2-angular-dropdowns';
import { FormValidators } from '@syncfusion/ej2-angular-inputs';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { ApiService } from '@services/api/api.service';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { faUserShield, faHeading, faFileLines, faUserLock } from '@fortawesome/free-solid-svg-icons';
import { title } from 'process';
import { ToastMessageService } from '@root/src/app/shared/services/toast-message/toast-message.service';

@Component({
  selector: 'add-role',
  standalone: true,
  imports: [
    CommonModule, 
    ReactiveFormsModule,
    FontAwesomeModule,
    ButtonModule, 
    TextBoxModule, 
    MultiSelectModule
  ],
  templateUrl: './add-role.component.html',
  styleUrl: './add-role.component.scss'
})
export class AddRoleComponent {

  constructor(
    private api: ApiService,
    private toast: ToastMessageService
  ) {}

  // Decorator variables
  @Input() rolesGrid: GridComponent;
  @Input() showSubmitButton: boolean = true;
  @Output() formSubmitted = new EventEmitter<void>();
  @Output() formCancelled = new EventEmitter<void>();
  @ViewChild('addRoleFormRightsMultiSelect') addRoleFormRightsMultiSelect: MultiSelectComponent
  @ViewChild('editRightsMultiSelect') editRightsMultiSelect: MultiSelectComponent;

  // Public Variables
  waterMark: string = 'Select';
  rightsListData: DataManager = this.api.getOdata(APIEndpoints.Rights);
  rightsFields: Object = { text: 'FriendlyName', value: 'Id' }
  addRoleHTMLElement: Element | null = null;  
  addRoleForm: FormGroup = new FormGroup({
    'addRoleForm_RoleName': new FormControl('', [FormValidators.required]),
    'addRoleForm_Rights': new FormControl('', [FormValidators.required]),
    'addRoleForm_Description':new FormControl(''),
  });
  addRoleIcons = {
    title: faUserShield,
    name: faHeading,
    description: faFileLines,
    rights: faUserLock,
  }
  formClasses = {
    formContainer: 'cc-form-container',
    form: 'cc-form flex-column',
    subForm: 'cc-sub-form',
    section: 'cc-form-section',
    group: 'cc-form-group row',
    inputContainer: 'cc-input-container col-12',
    inputContainerFullWidth: 'cc-input-container col-12',
    label: 'cc-label',
    input: 'cc-input',
    icon: 'cc-input-icon',
    error: 'cc-input-error',
    actions: 'cc-form-actions',
    twoCol: 'col-12'
  };

  ngAfterViewInit() {
    this.addRoleHTMLElement = document.querySelector('add-role');
  }
  
  editRightsArray: object[];

  // Capture form for adding new roles
  async onSubmit() {
    const rolesData: DataManager = this.api.getOdata(APIEndpoints.Roles);

    if (this.addRoleForm.valid) {
      var rightsArray: any = [];

      for (let i = 0; i < this.addRoleForm.value.addRoleForm_Rights.length; i++) {
        var tmpObj = { 
          RightId: this.addRoleForm.value.addRoleForm_Rights[i]
        };
        rightsArray.push(tmpObj);
      }

      const role = {
        RoleName: this.addRoleForm.value.addRoleForm_RoleName,
        Description: this.addRoleForm.value.addRoleForm_Description,
        XrefRolesRights: rightsArray
      }
      
      this.api.fetchRequest(`odata${APIEndpoints.Roles}`, 'POST', role).then((result) => {
        console.log(result);
      });

      this.addRoleForm.reset();
      this.toast.showSuccess('Role added successfully.');
      
    } else {
      alert('Invalid form');

      Object.keys(this.addRoleForm.controls).forEach(field => {
        const control = this.addRoleForm.get(field);
        control?.markAsTouched({ onlySelf: true });
      });
    }
  }
}
