<div id="grid-template">
    <ng-container *ngIf="useRoundedEdges; else gridContent">
        <tab-card [heading]="headingName">
            <ng-container *ngTemplateOutlet="gridContent"></ng-container>
        </tab-card>
    </ng-container>
    <ng-template #gridContent>
        <ejs-grid #grid
            [height]="settings.height ?? '100%'"
            [allowGrouping]="settings.allowGrouping"
            [groupSettings]="settings.groupSettings"
            [columns]="settings.columns ?? undefined" 
            [dataSource]="settings.dataSource"
            [query]="settings.query ?? undefined"
            [allowPaging]="settings.allowPaging ?? true"
            [allowSorting]="settings.allowSorting ?? true"
            [allowFiltering]="settings.allowFiltering ?? true" 
            [allowResizing]="settings.allowResizing ?? true" 
            [allowReordering]="settings.allowReordering ?? true"
            [selectionSettings]="settings.selectionSettings ?? { type: 'Multiple', mode: 'Row' }"
            [showColumnChooser]="settings.showColumnChooser ?? true"
            [columnChooserSettings]="settings.columnChooserSettings ?? { operator: 'contains', ignoreAccent: true }"
            [pageSettings]="settings.pageSettings ?? undefined" 
            [filterSettings]="settings.filterSettings ?? { type: 'FilterBar' }"
            [loadingIndicator]="settings.loadingIndicator ?? { indicatorType: 'Shimmer' }" 
            [toolbar]="settings.toolbar ?? undefined"
            [editSettings]="settings.editSettings ?? { allowEditOnDblClick: false, allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Dialog' }"
            [rowRenderingMode]="settings.rowRenderingMode ?? setGridRowRenderingMode()"
            [enableAdaptiveUI]="settings.enableAdaptiveUI ?? setGridAdaptiveUI()"
            [enablePersistence]="false"
            [allowPdfExport]="settings.allowPdfExport ?? true"
            [allowExcelExport]="settings.allowExcelExport ?? true"
            [aggregates]="settings.aggregates"
            [sortSettings]="settings.sortSettings ?? { columns: [{ field: 'Id', direction: 'Descending' }] }"
            (load)="onGridLoad($event)"
            (created)="onGridCreated($event)"
            (actionBegin)="onGridActionBegin($event)"
            (actionComplete)="onGridActionComplete($event)"
            (beforeBatchSave)="onGridBatchSave($event)"
            (toolbarClick)="onGridToolbarClick($event)"
            (rowSelected)="onGridRowSelected($event)"
            (rowSelecting)="onGridRowSelecting($event)"
            (rowDeselected)="onGridRowDeselected($event)"
            (rowDeselecting)="onGridRowDeselecting($event)"
            (dataStateChange)="onGridDataStateChange($event)"
            (rowDataBound)="onGridRowDataBound($event)"
            (cellEdit)="onGridCellEdit($event)"
            (cellSave)="onGridCellSave($event)"
            (commandClick)="onCommandClick($event)"
            (queryCellInfo)="onQueryCellInfo($event)"
            (detailDataBound)="onDetailDataBound($event)"
            (dataBound)="onDataBound($event)"
            (beforeCopy)="onBeforeCopy($event)">

            <ng-container *ngIf="settings.columns">
                <e-columns>
                    <ng-container *ngFor="let column of columns; let $index = index">
                        <e-column [field]="column.field" [headerText]="column.headerText" [editType]="column.editType"></e-column>
                    </ng-container>
                </e-columns>
            </ng-container>

            <ng-template #activeDisplayTemplate let-data>  
                <div class="statustxt">
                    <span *ngIf="data.IsActive === true" class="e-activecolor">Active</span>
                    <span *ngIf="data.IsActive !== true" class="neutral">Inactive</span>
                </div>
            </ng-template>

            <ng-template #statusTemplate let-data>                       
                <div class="statustxt">
                    <span *ngIf="data.Status === 1 || data.Status === 'IsActive'" class="status-1">Active</span>
                    <span *ngIf="data.Status === 'File Created'" class="yes">{{data.Status}}</span>
                    <span *ngIf="data.Status === 'Pending'" class="neutral">{{data.Status}}</span>
                    <span *ngIf="data.Status !== 1 && data.Status !== 'IsActive' && data.Status !== 'File Created' && data.Status !== 'Pending'" class="neutral">InActive</span>
                </div>
            </ng-template>

            <ng-template #yesNoTemplate let-data>                       
                <div class="statustxt">
                    <span *ngIf="data.InTreatment === true" class="icon yes"><fa-icon [icon]="faSquareCheck" /></span>
                    <span *ngIf="data.InTreatment !== true" class="icon no"><fa-icon [icon]="faSquareXmark" /></span>
                </div>
            </ng-template> 

            <ng-template #ratingTemplate let-data>
                <div class="statustxt">
                    <span *ngIf="data.Rating === 'Good Standing'" [ngClass]="setRatingClass(data.Rating)">Good</span>
                    <span *ngIf="data.Rating === 'Bad Standing'" [ngClass]="setRatingClass(data.Rating)">Bad</span>
                    <span *ngIf="data.Rating !== 'Good Standing' && data.Rating !== 'Bad Standing'" [ngClass]="setRatingClass(data.Rating)">{{data.Rating}}</span>
                </div>
            </ng-template>

            <ng-template #fileRatingTemplate let-data>
                <div class="statustxt">
                    <span [ngClass]="setRatingClass(data.FileRating)">{{data.FileRating}}</span>
                </div>
            </ng-template>

            <ng-template #websiteLinkTemplate let-data>
                <a *ngIf="data.Website !== null" href="{{data.Website}}">{{data.Website}}</a>
            </ng-template>

            <ng-template #isDefaultTemplate let-data>                       
                <div class="statustxt">
                    <span *ngIf="data.IsDefault === 1" class="yes">Yes</span>
                    <span *ngIf="data.IsDefault !== 1" class="no">No</span>
                </div>
            </ng-template>

            <ng-template #languageTemplate let-data>
                <div class="custom-template">
                    <label class="custom-template-label">Language</label>                
                    <ejs-dropdownlist [dataSource]="languages" [value]="data.Language" />
                </div>
            </ng-template>

            <ng-template #notesTemplate let-data>
                <div class="custom-template">
                    <label class="custom-template-label">Notes</label>
                    <ejs-textarea [value]="data.Notes" />
                </div>
            </ng-template>

            <ng-template #isFinalTemplate let-data>
                <div class="statustxt">
                    <span *ngIf="data.Final === true" class="yes">Yes</span>
                    <span *ngIf="data.Final === false" class="no">No</span>
                </div>
            </ng-template>

            <ng-container *ngIf="settings.detailDataBound">
                <ng-template #detailTemplate let-data>
                    <div class="custom-details"></div>
                </ng-template>
            </ng-container>

            <e-aggregates>
                <!-- Group level aggregates -->
                <e-aggregate>
                    <e-columns>
                        <e-column field="AmountBilled" type="sum" format="C2">
                            <ng-template #groupFooterTemplate let-data>
                                <div style="text-align: right">{{data.Sum | currency}}</div>
                            </ng-template>
                        </e-column>
                        <e-column field="TotalDueProvider" type="sum" format="C2">
                            <ng-template #groupFooterTemplate let-data>
                                <div style="text-align: right">{{data.Sum | currency}}</div>
                            </ng-template>
                        </e-column>
                        <e-column field="SettlementValue" type="sum" format="C2">
                            <ng-template #groupFooterTemplate let-data>
                                <div style="text-align: right">{{data.Sum | currency}}</div>
                            </ng-template>
                        </e-column>
                        <e-column field="AmountPaid" type="sum" format="C2">
                            <ng-template #groupFooterTemplate let-data>
                                <div style="text-align: right">{{data.Sum | currency}}</div>
                            </ng-template>
                        </e-column>
                    </e-columns>
                </e-aggregate>

                <!-- Footer level aggregates (grand totals) -->
                <e-aggregate>
                    <e-columns>
                        <e-column field="AmountBilled" type="sum" format="C2">
                            <ng-template #footerTemplate let-data>
                                <div style="text-align: right">
                                    <span style="font-weight: bold">Total:</span> {{data.Sum | currency}}
                                </div>
                            </ng-template>
                        </e-column>
                        <e-column field="TotalDueProvider" type="sum" format="C2">
                            <ng-template #footerTemplate let-data>
                                <div style="text-align: right">{{data.Sum | currency}}</div>
                            </ng-template>
                        </e-column>
                        <e-column field="SettlementValue" type="sum" format="C2">
                            <ng-template #footerTemplate let-data>
                                <div style="text-align: right">{{data.Sum | currency}}</div>
                            </ng-template>
                        </e-column>
                        <e-column field="AmountPaid" type="sum" format="C2">
                            <ng-template #footerTemplate let-data>
                                <div style="text-align: right">{{data.Sum | currency}}</div>
                            </ng-template>
                        </e-column>
                    </e-columns>
                </e-aggregate>
            </e-aggregates>
        </ejs-grid>
    </ng-template>

    <ng-content />
</div>