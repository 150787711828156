<div id="case-files-grid" class="case-files-grid">
  <div class="loading-container" *ngIf="loading() === true; else doneLoading">
    <loading-overlay [loading]="loading" />
  </div>

  <ng-template #doneLoading>
    <grid-template
      #caseFiles
      [settings]="caseFilesGridSettings"
      name="Case Files"
      [useRoundedEdges]="true"
    ></grid-template>
  </ng-template>

  <ng-template #fileNumberDisplayTemplate let-data>
    <a (click)="caseNumberClick(data)">
      <span class="case-file">{{ data.FileNumber }}</span>
    </a>
  </ng-template>
  <ng-template #lawFirmTemplate let-data>
    <a (click)="onLawFirmClick(data.LawFirm)">
      <span class="law-firm">{{ data.LawFirm.Name }}</span>
    </a>
  </ng-template>

  <!-- Add Case File Modal -->
  <ejs-dialog
    id="add-new-case-file-dialog"
    #addCaseFileDialog
    [showCloseIcon]="true"
    [target]="'body'"
    [isModal]="true"
    [visible]="false"
    [closeOnEscape]="true"
    [buttons]="addCaseFileButtons"
    [header]="'Add New Case File'"
    (close)="closeDialog()"
  >
    <case-file-form-component
      #newFileForm
      [displayButtons]="false"
      [displayMainTitle]="false"
      [formMainTitle]="'Add New Case File'"
      [submitType]="'POST'"
      [deferDropdownLoading]="true"
    />
  </ejs-dialog>

  <!-- Single Edit Case File Modal -->
  <ejs-dialog
    id="edit-case-file-dialog"
    #editCaseFileDialog
    [showCloseIcon]="true"
    [target]="'body'"
    [isModal]="true"
    [visible]="false"
    [closeOnEscape]="true"
    [header]="'Editing Case File #' + caseFileData()?.FileNumber"
    [buttons]="editCaseFileButtons"
    (close)="closeDialog()"
  >
    <case-file-form-component
      #editFileForm
      [caseFile]="caseFileSignal()"
      [displayButtons]="false"
      [displayMainTitle]="false"
      [formMainTitle]="'Editing Case File #' + caseFileData()?.FileNumber"
      [submitType]="'PATCH'"
      [deferDropdownLoading]="true"
    />
  </ejs-dialog>

  <!-- Bulk Edit Case Files Modal -->
  <div class="dialog-container" *ngIf="bulkEditCaseFilesDialogVisibility">
    <ejs-dialog
      id="bulk-edit-case-file-dialog"
      #bulkEditCaseFilesDialog
      [showCloseIcon]="true"
      [target]="'body'"
      [isModal]="true"
      [closeOnEscape]="true"
      [visible]="bulkEditCaseFilesDialogVisibility"
      [buttons]="bulkUpdateCaseFilesButtons"
      header="Bulk Edit"
      (close)="closeDialog()"
    >
      <bulk-edit-case-files-form #bulkEditForm />
    </ejs-dialog>
  </div>

  <!-- Audit Logs Modal -->
  <ejs-dialog
    #logsDialog
    [visible]="logsDialogVisibility"
    [header]="'Case File Logs'"
    [buttons]="logsDialogButtons"
    [target]="'body'"
    [isModal]="true"
    [showCloseIcon]="true"
    [width]="'60%'"
    [height]="'60vh'"
  >
    <ng-template #content>
      <div class="dialog-content logs-dialog-content">
        <app-audit-logs [logs]="auditLogs" [loading]="auditLogsLoading"> </app-audit-logs>
      </div>
    </ng-template>
  </ejs-dialog>
</div>
