import { IconDefinition } from '@fortawesome/angular-fontawesome';
import { faHouse, faCalendar, faListCheck, faDollarSign, faFolderOpen, faBuildingLock, faInbox, faFileSignature, faHouseMedicalCircleCheck, faGear } from '@fortawesome/free-solid-svg-icons';

export interface Tab {
  Id: string,
  HeaderText: string,
  faIcon: IconDefinition,
  visible?: boolean,
  disabled?: boolean
}

export const  tabs: Tab[] = [
  {
    Id: 'home',
    HeaderText: 'Home',
    faIcon: faHouse
  },
  {
    Id: 'intake',
    HeaderText: 'Intake',
    faIcon: faInbox
  },
  {
    Id: 'appointments',
    HeaderText: 'Appointments',
    faIcon: faCalendar
  },
  {
    Id: 'work-items',
    HeaderText: 'Tasks',
    faIcon: faListCheck
  },
  {
    Id: 'payables',
    HeaderText: 'Payables',
    faIcon: faDollarSign
  },
  {
    Id: 'receivables',
    HeaderText: 'Receivables',
    faIcon: faDollarSign
  },
  {
    Id: 'documents',
    HeaderText: 'Documents',
    faIcon: faFolderOpen
  },
  {
    Id: 'authorizations',
    HeaderText: 'Authorizations',
    faIcon: faBuildingLock
  },
  {
    Id: 'box-sign',
    HeaderText: 'Box Sign',
    faIcon: faFileSignature
  },
  {
    Id: 'compliance',
    HeaderText: 'Compliance',
    faIcon: faHouseMedicalCircleCheck
  },
  // {
  //   Id: 'settings',
  //   HeaderText: 'Settings',
  //   faIcon: faGear
  // }
];
