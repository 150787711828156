<!-- Main container for the work item form -->
<ng-template #formContent>
  <div id="work-item-form">
    <loading-spinner [loading]="loading()" />

    <form [formGroup]="workItemForm" [class.hidden]="loading()" class="work-item-form">
      <!-- Hidden title field -->
      <div class="form-group required" style="display: none;">
        <span class="input-icon">
          <fa-icon [icon]="formIcons.title" />
        </span>
        <div class="{{formClasses.inputContainer}}">
          <ejs-textbox formControlName="Title" placeholder="Title *" cssClass="e-outline" />
          <div class="error-message" *ngIf="workItemForm.get('Title')?.touched && workItemForm.get('Title')?.errors">
            <span *ngIf="workItemForm.get('Title')?.errors?.['required']">Title is required</span>
            <span *ngIf="workItemForm.get('Title')?.errors?.['minlength']">Title must be at least 3 characters</span>
          </div>
        </div>
      </div>

      <!-- Main sections row -->
      <div class="form-row">
        <!-- Left side - Classification -->
        <div class="form-col">
          <div class="classification-section">
            <div class="section-header">
              <fa-icon [icon]="formIcon" />
              <span>Classification</span>
            </div>

            <!-- Status -->
            <div class="form-group required">
              <span class="input-icon">
                <fa-icon [icon]="formIcons.status" />
              </span>
              <div class="{{formClasses.inputContainer}}">
                <ejs-dropdownlist formControlName="StatusId" [dataSource]="statusesDataManager"
                  [fields]="{ text: 'Name', value: 'Id' }" placeholder="Status *" cssClass="e-outline" />
                <div class="error-message" *ngIf="workItemForm.get('StatusId')?.touched && workItemForm.get('StatusId')?.errors">
                  <span *ngIf="workItemForm.get('StatusId')?.errors?.['required']">Status is required</span>
                </div>
              </div>
            </div>

            <!-- Category -->
            <div class="form-group required">
              <span class="input-icon">
                <fa-icon [icon]="formIcons.category" />
              </span>
              <div class="{{formClasses.inputContainer}}">
                <ejs-dropdownlist formControlName="CategoryId" [dataSource]="categoriesDataManager"
                  [fields]="{ text: 'Name', value: 'Id' }" placeholder="Category *" cssClass="e-outline" />
                <div class="error-message" *ngIf="workItemForm.get('CategoryId')?.touched && workItemForm.get('CategoryId')?.errors">
                  <span *ngIf="workItemForm.get('CategoryId')?.errors?.['required']">Category is required</span>
                </div>
              </div>
            </div>

            <!-- Type -->
            <div class="form-group required sub-field">
              <span class="input-icon">
                <fa-icon [icon]="formIcons.type" />
              </span>
              <div class="{{formClasses.inputContainer}}">
                <ejs-dropdownlist formControlName="TypeId" [dataSource]="filteredTypes"
                  [fields]="{ text: 'Name', value: 'Id' }" placeholder="Type *" cssClass="e-outline" />
                <div class="error-message" *ngIf="workItemForm.get('TypeId')?.touched && workItemForm.get('TypeId')?.errors">
                  <span *ngIf="workItemForm.get('TypeId')?.errors?.['required']">Type is required</span>
                </div>
              </div>
            </div>

            <!-- Priority -->
            <div class="form-group required">
              <span class="input-icon">
                <fa-icon [icon]="formIcons.priority" />
              </span>
              <div class="{{formClasses.inputContainer}}">
                <ejs-dropdownlist formControlName="PriorityId" [dataSource]="prioritiesDataManager"
                  [fields]="{ text: 'Name', value: 'Id' }" placeholder="Priority *" cssClass="e-outline" />
                <div class="error-message" *ngIf="workItemForm.get('PriorityId')?.touched && workItemForm.get('PriorityId')?.errors">
                  <span *ngIf="workItemForm.get('PriorityId')?.errors?.['required']">Priority is required</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Right side - Details -->
        <div class="form-col">
          <div class="details-section">
            <div class="section-header">
              <fa-icon [icon]="formIcons.description" />
              <span>Details</span>
            </div>

            <!-- Due Date -->
            <div class="form-group">
              <span class="input-icon">
                <fa-icon [icon]="formIcons.dueDate" />
              </span>
              <div class="{{formClasses.inputContainer}}">
                <ejs-datepicker formControlName="DueDate" placeholder="Due Date" cssClass="e-outline" />
                <div class="error-message" *ngIf="workItemForm.get('DueDate')?.touched && workItemForm.get('DueDate')?.errors">
                  <span *ngIf="workItemForm.get('DueDate')?.errors?.['required']">Due Date is required</span>
                </div>
              </div>
            </div>

            <!-- Assigned To -->
            <div class="form-group">
              <span class="input-icon">
                <fa-icon [icon]="formIcons.assignedTo" />
              </span>
              <div class="{{formClasses.inputContainer}}">
                <ejs-dropdownlist formControlName="AssignedTo" [dataSource]="usersDataManager"
                  [fields]="{ text: 'Name', value: 'Id' }" placeholder="Assigned To" cssClass="e-outline" />
                <div class="error-message" *ngIf="workItemForm.get('AssignedTo')?.touched && workItemForm.get('AssignedTo')?.errors">
                  <span *ngIf="workItemForm.get('AssignedTo')?.errors?.['required']">Assigned To is required</span>
                </div>
              </div>
            </div>

            <!-- Provider field -->
            <div class="form-group required" *ngIf="showProviderField">
              <span class="input-icon">
                <fa-icon [icon]="formIcons.provider" />
              </span>
              <div class="{{formClasses.inputContainer}}">
                <ejs-dropdownlist formControlName="ProviderId" [dataSource]="providersData"
                  [fields]="{ text: 'Name', value: 'Id' }" placeholder="Provider *" cssClass="e-outline" />
                <div class="error-message" *ngIf="workItemForm.get('ProviderId')?.touched && workItemForm.get('ProviderId')?.errors">
                  <span *ngIf="workItemForm.get('ProviderId')?.errors?.['required']">Provider is required</span>
                </div>
              </div>
            </div>

            <!-- Case Files -->
            <div class="form-group" *ngIf="!inCaseFileContext">
              <span class="input-icon">
                <fa-icon [icon]="formIcons.caseFiles" />
              </span>
              <div class="{{formClasses.inputContainer}}">
                <ejs-multiselect [dataSource]="caseFilesData" [fields]="{ text: 'FileNumber', value: 'Id' }"
                  [value]="selectedCaseFiles" (change)="onCaseFilesChange($event)" placeholder="Select Case Files"
                  cssClass="e-outline">
                </ejs-multiselect>
                <div class="error-message" *ngIf="workItemForm.get('caseFileIds')?.touched && workItemForm.get('caseFileIds')?.errors">
                  <span *ngIf="workItemForm.get('caseFileIds')?.errors?.['required']">Case Files are required</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Notes section - Full width -->
      <div class="form-group">
        <div class="{{formClasses.inputContainer}}">
          <ejs-richtexteditor formControlName="Description"
                            [height]="rteConfig.height"
                            [toolbarSettings]="rteConfig.toolbarSettings"
                            [format]="rteConfig.format">
          </ejs-richtexteditor>
          <div class="error-message" *ngIf="workItemForm.get('Description')?.touched && workItemForm.get('Description')?.errors">
            <span *ngIf="workItemForm.get('Description')?.errors?.['required']">Description is required</span>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>


<!-- Direct form for grid -->
<div *ngIf="!displayButtons">
  <ng-container *ngTemplateOutlet="formContent"></ng-container>
</div>
