<div class="dialog-container">
  <div mat-dialog-title class="dialog-header">
    <h2>{{ title }}</h2>
    <button mat-icon-button (click)="close()" class="close-button">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-dialog-content class="dialog-content">
    <ng-content></ng-content>
  </mat-dialog-content>

  <mat-dialog-actions align="end" class="dialog-actions">
    <button mat-flat-button (click)="cancel()">Cancel</button>
    <button mat-flat-button color="primary" [disabled]="isSubmitDisabled" (click)="submit()">
      {{ submitLabel }}
    </button>
  </mat-dialog-actions>
</div>
