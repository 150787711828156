<div id="authorizations-tab">
    <tab-card [heading]="'File Authorizations'" [loadingContent]="loadingAuthorizationsData">
        <ng-container *ngIf="fileAuthorizationsGrid">
            <grid-template #grid [settings]="fileAuthorizationsGrid" />
        </ng-container>
    </tab-card>
    <div class="spacer"></div>
    <tab-card [heading]="'Provider Funding'" [loadingContent]="loadingAuthorizationsData">
        <ng-container *ngIf="providerFundingGrid">
            <grid-template #grid [settings]="providerFundingGrid" />
        </ng-container>
    </tab-card>
</div>

<ejs-dialog id="addAuthorizations" #addAuthorizations
    header="Add Authorization"
    [showCloseIcon]="true"
    [isModal]="true"
    [closeOnEscape]="true"
    [visible]="addAuthorizationsVisibility"
    (close)="onClose()">
    <add-authorizations
        [caseId]="fileHub.caseFile?.Id"
        [selectedAuthorization]="selectedAuthorization()"
        [authType]="authType"
        (submit)="onSubmit()"
    ></add-authorizations>  
</ejs-dialog>
