// Angular
import { Component, ViewChild } from '@angular/core';

// 3rd Party
import { GridModel } from '@syncfusion/ej2-grids';
import { DialogComponent, DialogAllModule } from '@syncfusion/ej2-angular-popups';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { ApiService } from '@services/api/api.service';
import { GridTemplateModule } from '@modules/grid-template.module';
import { AddProviderForm } from "@forms/add-forms/add-provider/add-provider.component";

@Component({
  selector: 'providers-grid',
  standalone: true,
  imports: [
    GridTemplateModule,
    AddProviderForm,
    DialogAllModule
],
  templateUrl: './providers-grid.component.html',
  styleUrl: './providers-grid.component.scss'
})
export class ProvidersGridComponent {

  constructor(
    private api: ApiService
  ) { }

  // Providers grid
  providers: GridModel;

  // Add provider form
  @ViewChild('addProviderModal') addProviderModal: DialogComponent;
  @ViewChild('addProviderForm') addProviderForm: AddProviderForm;

  ngOnInit() {
    this.providers = {
      dataSource: this.api.getOdata(APIEndpoints.Providers),
      toolbar: [ { text: 'Add Provider', tooltipText: 'Add File', id: 'AddProvider' }, 'ColumnChooser' ],
      columns: [
        { type: 'checkbox' },
        { field: 'Id', headerText: 'ID', isPrimaryKey: true, visible: false },
        { field: 'Name', headerText: 'Name', template: '<div class="custom-template"><a class="link" href="https://www.google.com/search?q=${Name}" target="_blank">${Name}</a></div>' },
        { field: 'Notes', headerText: 'Notes', },
        { field: 'SpanishSpeaking', headerText: 'Spanish Speaking' },
        { field: 'ReimbursementRate', headerText: 'Reimbursement Rate', template: '<div>${ReimbursementRate}%</div>' },
        { field: 'Active', headerText: 'Active' },
        { type: 'commands', headerText: 'Actions' }
      ],
      toolbarClick: ($event: any) => this.customToolbarClick($event),
    }
  }

  // Add custom functionality when grid toolbar is clicked
  customToolbarClick(args: any) {
    if (args.item.id === 'AddProvider') {
      this.addProviderModal.show();
    }
  }
  
  // Closes the add provider modal
  closeDialog() {
    this.addProviderModal.hide();
  }

  // Sets the max height of the add provider before it opens
  beforeOpening(args: any) {
    args.maxHeight = '85vh';
    args.width = '90vw';
  }
}
