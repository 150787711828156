const defaultTexts = {
  ADD_PROFILE: 'Add your Profile',
  ADD_WEBSITE: 'Add your Website',
  BACK_SIGN_IN: 'Back to Sign In',
  BIRTHDATE: 'Birthdate',
  CHANGE_PASSWORD: 'Change Password',
  CHANGING_PASSWORD: 'Changing',
  CODE: 'Code',
  CODE_ARRIVAL: 'It may take a minute to arrive',
  CODE_EMAILED: 'Your code is on the way. To log in, enter the code we emailed to',
  CODE_SENT: 'Your code is on the way. To log in, enter the code we sent you',
  CODE_TEXTED: 'Your code is on the way. To log in, enter the code we texted to',
  CONFIRM_PASSWORD: 'Confirm Password',
  CONFIRM_PASSWORD_PLACEHOLDER: 'Please confirm your Password',
  CONFIRM_RESET_PASSWORD_HEADING: 'Reset your Password',
  CONFIRM_SIGNUP_HEADING: 'Confirm Sign Up',
  CONFIRM_SMS: 'Confirm SMS Code',
  CONFIRM_EMAIL: 'Confirm Email Code',
  // If challenge name is not returned
  CONFIRM_MFA_DEFAULT: 'Confirm MFA Code',
  CONFIRM_TOTP: 'Confirm TOTP Code',
  CONFIRM: 'Confirm',
  CONFIRMATION_CODE: 'Confirmation Code',
  CONFIRMING: 'Confirming',
  CREATE_ACCOUNT: 'Create Account',
  CREATING_ACCOUNT: 'Creating Account',
  EMAIL_ADDRESS: 'Email',
  EMAIL_OTP: 'Email Message',
  ENTER_BIRTHDATE: 'Enter your Birthdate',
  ENTER_CODE: 'Enter your code',
  ENTER_CONFIRMATION_CODE: 'Enter your Confirmation Code',
  ENTER_EMAIL: 'Enter your Email',
  ENTER_FAMILY_NAME: 'Enter your Family Name',
  ENTER_GIVEN_NAME: 'Enter your Given Name',
  ENTER_MIDDLE_NAME: 'Enter your Middle Name',
  ENTER_NAME: 'Enter your Name',
  ENTER_NICK_NAME: 'Enter your Nickname',
  ENTER_PASSWORD: 'Enter your Password',
  ENTER_PHONE_NUMBER: 'Enter your Phone Number',
  ENTER_PREFERRED_USERNAME: 'Enter your Preferred Username',
  ENTER_USERNAME: 'Enter your username',
  FAMILY_NAME: 'Family Name',
  GIVEN_NAME: 'Given Name',
  FORGOT_PASSWORD: 'Forgot Password?',
  FORGOT_YOUR_PASSWORD: 'Forgot your password?',
  HIDE_PASSWORD: 'Hide password',
  LOADING: 'Loading',
  LOGIN_NAME: 'Username',
  MIDDLE_NAME: 'Middle Name',
  MFA_SETUP_SELECTION: 'Multi-Factor Authentication Setup',
  MFA_SELECTION: 'Multi-Factor Authentication',
  NAME: 'Name',
  NICKNAME: 'Nickname',
  NEW_PASSWORD: 'New password',
  OR: 'or',
  PASSWORD: 'Password',
  PHONE_NUMBER: 'Phone Number',
  PREFERRED_USERNAME: 'Preferred Username',
  PROFILE: 'Profile',
  RESEND_CODE: 'Resend Code',
  RESET_PASSWORD_HEADING: 'Reset your password',
  RESET_PASSWORD: 'Reset Password',
  SEND_CODE: 'Send code',
  SENDING: 'Sending',
  SELECT_MFA_TYPE: 'Select MFA Type',
  SETUP_EMAIL: 'Setup Email',
  SETUP_TOTP: 'Setup TOTP',
  SHOW_PASSWORD: 'Show password',
  SIGN_IN_BUTTON: 'Sign in',
  SIGN_IN_TAB: 'Sign In',
  SIGN_IN_WITH_AMAZON: 'Sign In with Amazon',
  SIGN_IN_WITH_APPLE: 'Sign In with Apple',
  SIGN_IN_WITH_FACEBOOK: 'Sign In with Facebook',
  SIGN_IN_WITH_GOOGLE: 'Sign In with Google',
  SIGN_IN: 'Sign in to your account',
  SIGN_UP_BUTTON: 'Create a new account',
  SIGNING_IN_BUTTON: 'Signing in',
  SKIP: 'Skip',
  SMS_MFA: 'Text Message (SMS)',
  SUBMIT: 'Submit',
  SUBMITTING: 'Submitting',
  SOFTWARE_TOKEN_MFA: 'Authenticator App (TOTP)',
  UPPERCASE_COPY: 'COPY',
  VERIFY_CONTACT: 'Verify Contact',
  VERIFY_HEADING: 'Account recovery requires verified contact information',
  VERIFY: 'Verify',
  WE_EMAILED: 'We Emailed You',
  WE_SENT_CODE: 'We Sent A Code',
  WE_TEXTED: 'We Texted You',
  WEBSITE: 'Website'
};
export { defaultTexts };