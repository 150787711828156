import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-audit-logs',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div>
      <div *ngIf="loading">Loading...</div>
      <div *ngIf="!loading">
        <div *ngFor="let log of logs">
          {{ log | json }}
        </div>
      </div>
    </div>
  `
})
export class AuditLogsComponent {
  @Input() logs: any[] = [];
  @Input() loading: boolean = false;
} 