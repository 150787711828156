// Angular
import {
  Component,
  Input,
  ContentChild,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';

// 3rd Party
import { Query } from '@syncfusion/ej2-data';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { MenuEventArgs, MenuModule } from '@syncfusion/ej2-angular-navigations';
import { ScheduleModule, DayService, WeekService, MonthService } from '@syncfusion/ej2-angular-schedule';

// Models
import { CustomCardModel } from '@models/components/card.model';

// Services
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { WorkItemsService } from '@features/work-items/services/work-items.service';

// Components
import { BasicGridComponent } from '@shared/components/basic-grid/basic-grid.component';
@Component({
  selector: 'card',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    MenuModule,
    ScheduleModule,
    BasicGridComponent
  ],
  providers: [
    DayService,
    WeekService,
    MonthService
  ],
  templateUrl: './card.component.html',
  styleUrl: './card.component.scss'
})
export class CardComponent extends BasicGridComponent {

  constructor(
    private toast: ToastMessageService,
    private workItems: WorkItemsService
  ) {
    super();
  }

  // Inputs
  @Input() footerString?: string;
  @Input() cssClass: string;
  @Input() footerContent?: any = null;
  @Input() cardSettings: CustomCardModel;

  // Card Content
  @ViewChild('card') public card: any;
  @ViewChild('cardGrid') public cardGrid: BasicGridComponent;
  @ContentChild(TemplateRef) footerContentWrapper!: TemplateRef<any>;
  public gridOverlay: boolean = false;
  public now: Date;
  public defaultSettings: CustomCardModel = {
    cardSettingsMenu: [
      {
        iconCss: 'e-icons e-settings',
        items: [
          { text: 'Last Updated', items: [
            { text: '1 day' , id: 'day-1' },
            { text: '1 week' , id: 'day-7' },
            { text: '1 month' , id: 'month-1' },
            { text: '3 months' , id: 'month-3' },
            { text: '6 months' , id: 'month-6' },
            { text: '1 year' , id: 'year-1' }
          ]},
          { text: 'Select Columns', id: 'select-columns' },
        ]
      }
    ],
    gridSettings: {
      dataSource: [{null: null}],
      dataBound: ($event: any) => this.onGridDataBound($event),
      actionComplete: ($event: any) => this.actionComplete($event)
    }
  }

  ngOnInit(): void {
    this.now = new Date();
  }

  // Use router for settings navigation
  public settingsMenuClick(args: MenuEventArgs): void {
    console.log('Menu click:', args.item);

    switch (args.item.id) {
      case 'select-columns':
        this.cardGrid.grid.columnChooserModule.openColumnChooser(0, 0);
        break;
      case 'day-1':
        this.updateGridQueryFromDaysAgo(1);
        break;
      case 'week-1':
        this.updateGridQueryFromDaysAgo(7);
        break;
      case 'month-1':
        this.updateGridQueryFromDaysAgo(30);
        break;
      case 'month-3':
        this.updateGridQueryFromDaysAgo(90);
        break;
      case 'month-6':
        this.updateGridQueryFromDaysAgo(180);
        break;
      case 'year-1':
          this.updateGridQueryFromDaysAgo(360);
          break;
      case 'calendar-view':
        if (!this.cardSettings?.showCalendarView || !this.cardSettings?.gridSettings) return;

        this.cardSettings.showCalendarView.set(!this.cardSettings.showCalendarView());

        // Refresh grid with new query
        if (this.cardSettings.cardId === 'work-items' && this.cardGrid?.grid) {
          const isCalendarView = this.cardSettings.showCalendarView();

          const query = isCalendarView ?
            this.workItems.getBaseQuery(true) :
            this.workItems.getTodaysTasksQuery();

          // Execute query and update grid with response
          const dataManager = this.workItems.getWorkItems();
          dataManager.executeQuery(query).then((response: any) => {
            if (!this.cardGrid?.grid || !this.cardSettings?.gridSettings) return;

            const data = response.actual.value;
            this.cardGrid.grid.dataSource = data;
            this.cardGrid.grid.refresh();
          });
        }
        break;
      default:
        this.toast
        break;
    }
  }

  public actionComplete(args: any) {
    const columns = this.cardGrid.grid.getColumns();

    // hide all but 1st 2
    for (let i = 2; i < columns.length; i++) {
      columns[i].visible = false;
    }

  }

  public onGridDataBound(args: any) {
    let menuArray: any = [];
    let columnNames = this.cardGrid.grid.getColumnFieldNames();

    // Create array of menu items from colums
    for (let i = 0; i < columnNames.length; i++) {
      menuArray.push({ text: columnNames[i], id: columnNames[i] });
    }
  }

  public hideAllButTwoColumns(): void {
    this.cardGrid.grid.getColumns().forEach((col, index) => {

      if (index > 1) {
        col.visible = false;
      }
    });
  }

  public updateGridQueryFromDaysAgo(daysAgo: number): void {
    const milisecondsInOneDay = 24 * 60 * 60 * 1000;
    const timeline = new Date(this.now.getTime() - (daysAgo * milisecondsInOneDay));
    if (this.cardSettings?.gridSettings) {
      this.cardSettings.gridSettings.query = new Query().where('UpdatedAt', 'greaterthanorequal', timeline);
    }
  }

  // Opens new url for card data
  expandCard(args: any) {
    window.open(`${window.location.origin}/${this.cardSettings.cardId}`, '_blank'); // open link in new tab
  }

  // Adjusts grid content to fit within card
  adjustGridContentHeight() {
    if (!this.card?.nativeElement) return;

    const card: Element = this.card.nativeElement;
    const header: HTMLElement | null = card.querySelector('header');
    const main: HTMLElement | null = card.querySelector('main');

    if (header && main) {
      const mainHeight = card.clientHeight - header?.clientHeight - 12;
      main.style.maxHeight = `${mainHeight}px`;

      if (!this.cardGrid?.grid?.element) return;

      const gridEl: HTMLElement = this.cardGrid.grid.element;
      gridEl.style.maxHeight = `${mainHeight}px`;
      const gridHeader = gridEl.querySelector('.e-gridheader');

      if (gridHeader) {
        const gridContent = document.querySelectorAll('.e-gridcontent');
        if (gridContent) {
          gridContent.forEach((el: any) => {
            const element = el as HTMLElement;
            const contentHeight = mainHeight - 46;
            element.style.maxHeight = `${contentHeight}px`;
            element.style.overflowY = 'scroll';
          });
        }
      }
    }
  }

  // Add method to handle grid creation
  onGridCreated(args: any) {
    // Call the original created handler if it exists
    if (this.cardSettings?.gridSettings?.created) {
      this.cardSettings.gridSettings.created(args);
    }
    // Adjust height after grid is created
    this.adjustGridContentHeight();
  }
}

