import { computed, Injectable, signal } from '@angular/core';
import { GridComponent, GridModel, SortSettingsModel, SearchSettingsModel } from '@syncfusion/ej2-angular-grids';
import { Query } from '@syncfusion/ej2-data';
import { GridDataResults, SetGridDataArgs, CustomToolbarItem, DefaultGridProperties } from '../models/grid.models';

@Injectable({
  providedIn: 'root'
})
export class BaseGridSignals {
  // Grid Component Signals
  private readonly _gridComponent = signal<GridComponent | undefined>(undefined);
  private readonly _gridSettings = signal<GridModel | undefined>(undefined);
  private readonly _gridSortSettings = signal<SortSettingsModel | undefined>(undefined);
  private readonly _gridParentElement = signal<HTMLElement | undefined>(undefined);
  private readonly _gridSetupArgs = signal<SetGridDataArgs | undefined>(undefined);

  // Toolbar Signals
  private readonly _toolbar = signal<CustomToolbarItem[]>(DefaultGridProperties.toolbar);
  private readonly _activeRecordsToggle = signal<CustomToolbarItem>(DefaultGridProperties.activeRecordsToggle);

  // Loading Signals
  private readonly _loadingData = signal<boolean>(false);

  // Data Signals
  private readonly _endpoint = signal<string | undefined>(undefined);
  private readonly _query = signal<Query | undefined>(undefined);
  private readonly _pagination = signal<{ skip: number; take: number }>(DefaultGridProperties.pagination);
  private readonly _result = signal<GridDataResults | undefined>(undefined);
  private readonly _editRecord = signal<any | undefined>(undefined);
  private readonly _selectedRecords = signal<any | undefined>(undefined);
  private readonly _totalRecords = signal<number>(0);
  private readonly _pageSize = signal<number>(DefaultGridProperties.pageSize);

  // UI Signals
  private readonly _gridId = signal<string | undefined>(undefined);
  private readonly _gridName = signal<string | undefined>(undefined);
  private readonly _isResponsive = signal<boolean>(false);
  private readonly _showActive = signal<boolean>(true);
  private readonly _editMode = signal<'add' | 'edit' | 'delete' | undefined>(undefined);

  // Other State Signals
  private readonly _disableTimeZone = signal<boolean>(true);
  private readonly _errors = signal<Record<string, any>>({});

  // Search Settings Signal
  private readonly _searchSettings = signal<SearchSettingsModel>({
    key: '',
    fields: ['Id', 'Name'],
    operator: 'contains',
    ignoreCase: true
  });

  // Computed Signals
  public readonly toolbar = computed(() => this._toolbar());
  public readonly gridData = computed(() => this._result());
  public readonly loadingData = computed(() => this._loadingData());
  public readonly currentQuery = computed(() => this._query());
  public readonly grid = computed(() => this._gridComponent());
  public readonly pagination = computed(() => this._pagination());
  public readonly activeRecordsToggle = computed(() => this._activeRecordsToggle());
  
  readonly endpoint = computed(() => this._endpoint() as string);
  readonly pageSize = computed(() => this._pageSize() || 20);
  readonly gridSetupArgs = computed(() => this._gridSetupArgs());
  readonly totalRecords = computed(() => this._totalRecords());
  readonly disableTimeZone = computed(() => this._disableTimeZone());
  readonly showActive = computed(() => this._showActive());
  readonly gridName = computed(() => this._gridName());
  readonly gridSettings = computed(() => this._gridSettings());

  // Grid Component Methods
  setGridComponent(component: GridComponent) {
    this._gridComponent.set(component);
  }

  setGridSettings(settings: GridModel) {
    this._gridSettings.set(settings);
  }

  setGridSortSettings(settings: SortSettingsModel) {
    this._gridSortSettings.set(settings);
  }

  setGridParentElement(element: HTMLElement) {
    this._gridParentElement.set(element);
  }

  setGridSetupArgs(args: SetGridDataArgs) {
    this._gridSetupArgs.set(args);
  }

  // Toolbar Methods
  setToolbar(items: CustomToolbarItem[]) {
    this._toolbar.set(items);
  }

  updateToolbar(items: CustomToolbarItem[]) {
    this._toolbar.update(current => [...current, ...items]);
  }

  // Loading Methods
  setLoading(isLoading: boolean) {
    this._loadingData.set(isLoading);
  }

  // Data Methods
  setEndpoint(endpoint: string) {
    this._endpoint.set(endpoint);
  }

  setQuery(query: Query) {
    this._query.set(query);
  }

  setPagination(skip: number, take: number) {
    this._pagination.set({ skip, take });
  }

  setResult(result: GridDataResults) {
    this._result.set(result);
  }

  setEditRecord(record: any) {
    this._editRecord.set(record);
  }

  setSelectedRecords(records: any[]) {
    this._selectedRecords.set(records);
  }

  setTotalRecords(count: number) {
    this._totalRecords.set(count);
  }

  setPageSize(size: number) {
    this._pageSize.set(size);
  }

  // UI Methods
  setGridId(id: string) {
    this._gridId.set(id);
  }

  setGridName(name: string) {
    this._gridName.set(name);
  }

  setIsResponsive(isResponsive: boolean) {
    this._isResponsive.set(isResponsive);
  }

  setShowActive(showActive: boolean) {
    this._showActive.set(showActive);
  }
  
  setActiveRecordsToggle(toggle: CustomToolbarItem) {
    this._activeRecordsToggle.set(toggle);
  }

  updateActiveRecordsToggle(updater: (toggle: CustomToolbarItem) => CustomToolbarItem) {
    this._activeRecordsToggle.update(updater);
  }

  setEditMode(mode: 'add' | 'edit' | 'delete' | undefined) {
    this._editMode.set(mode);
  }

  // Error Methods
  setError(key: string, error: any) {
    this._errors.update(state => ({ ...state, [key]: error }));
  }

  clearErrors() {
    this._errors.set({});
  }

  // Reset Methods
  resetGridState() {
    this._gridComponent.set(undefined);
    this._gridSettings.set(undefined);
    this._gridSortSettings.set(undefined);
    this._gridParentElement.set(undefined);
    this._gridSetupArgs.set(undefined);
  }

  resetDataState() {
    this._endpoint.set(undefined);
    this._query.set(undefined);
    this._pagination.set(DefaultGridProperties.pagination);
    this._result.set(undefined);
    this._editRecord.set(undefined);
    this._selectedRecords.set(undefined);
    this._totalRecords.set(0);
    this._pageSize.set(DefaultGridProperties.pageSize);
  }

  resetUIState() {
    this._gridId.set(undefined);
    this._gridName.set(undefined);
    this._isResponsive.set(false);
    this._showActive.set(true);
    this._editMode.set(undefined);
  }

  resetAll() {
    this.resetGridState();
    this.resetDataState();
    this.resetUIState();
    this.clearErrors();
    this.setLoading(false);
    this.setToolbar(DefaultGridProperties.toolbar);
  }

  // Setters
  setDisableTimeZone(value: boolean): void {
    this._disableTimeZone.set(value);
  }

  setSearchSettings(settings: SearchSettingsModel): void {
    this._searchSettings.set(settings);
  }

  updateGridSettings(updater: (settings: GridModel | undefined) => GridModel | undefined): void {
    this._gridSettings.update(updater);
  }
} 