import { Injectable } from '@angular/core';
import { AuthenticatedServiceBase } from '@core/auth/auth.base';
import { Query, ReturnOption } from '@syncfusion/ej2-data';

@Injectable({ providedIn: 'root' })
export class PandLService extends AuthenticatedServiceBase {
    protected override readonly endpoint = this.APIEndpoints.PandLs;

    constructor() {
        super();
    }

    /**
     * Gets P&L mapping data
     * @returns Promise of P&L ID to Description mapping
     */
    async getPandLMap(): Promise<{ [key: number]: string }> {
        return this.api.getOdata(this.endpoint)
            .executeQuery(new Query())
            .then((response: ReturnOption) => {
                const pAndLMap: { [key: number]: string } = {};
                const result = (response as any).result || [];
                result.forEach((item: any) => {
                    pAndLMap[item.Id] = item.Description;
                });
                return pAndLMap;
            });
    }
}
