import { Injectable } from '@angular/core';
import { Observable, from, map, switchMap, tap, forkJoin, of, catchError, throwError, retryWhen, concatMap, delay, retry, timer } from 'rxjs';
import { ApiService } from '@services/api/api.service';
import {
  CaseFile,
  ListPandL,
  ReductionRequest,
  Deposit
} from '@app/shared/models/data-contracts';
import { FileHubService } from '@features/file-hub/services/file-hub.service';
import { Query } from '@syncfusion/ej2-data';
import { APIEndpoints } from '@models/api/Endpoints';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { CognitoService } from '@services/auth/cognito.service';
import { AuthenticatedBaseService } from '@core/services/authenticated-base.service';
import { NotificationService } from '@core/services/notification.service';
import { ErrorHandlingService } from '@core/services/error-handling.service';
import { HttpClient } from '@angular/common/http';

interface PandLHeaderData {
  Name?: string;
  XrefAddressPandLs?: Array<{
    Address?: {
      Line1?: string;
      Line2?: string;
      City?: string;
      PostalCode?: string;
      StateNavigation?: {
        Abbreviation?: string;
      };
    };
  }>;
  XrefPhonePandls?: Array<{
    Phone?: {
      Number?: string;
    };
  }>;
  ConfigPandLs?: Array<{
    Logo?: string;
  }>;
}

interface BalanceStatementData {
  caseFile: CaseFile;
  pandls: ListPandL[];
}

interface PdfResult {
  buffer: ArrayBuffer;
  pandlId: number;
}
// Constants for configuration and error messages
const PDF_CONFIG = {
  RETRIES: 3,
  PAGE_WIDTH: 612,  // 8.5" * 72ppi
  PAGE_HEIGHT: 792, // 11" * 72ppi
  MARGIN: 25,
  SCALE: 2,
  DEFAULT_LOGO: 'assets/logos/Varent-Logo-RGB_Mint.png',
  FONTS: {
    FAMILY: 'Arial, sans-serif',
    SIZE: {
      NORMAL: 10,
      HEADER: 12,
      FOOTER: 10
    }
  },
  COLORS: {
    HEADER_BG: '#e8f5e9',
    SUBTOTAL_BG: '#f5f5f5',
    BORDER: '#ddd',
    TEXT: '#000000'
  },
  ERRORS: {
    IMAGE_LOAD: {
      message: 'Failed to load P&L logo image',
      technical: 'Error loading logo image for PDF generation'
    },
    HTML_CONVERT: {
      message: 'Failed to generate PDF document',
      technical: 'Error converting HTML to PDF canvas'
    },
    CANVAS_CONVERT: {
      message: 'Failed to process PDF document',
      technical: 'Error converting canvas to PDF'
    },
    UPLOAD: {
      message: 'Failed to upload PDF document',
      technical: 'Error uploading PDF to server'
    }
  }
} as const;

const ERRORS = {
  MISSING_CASE_FILE: 'Missing case file information',
  INVALID_BALANCE_STATEMENT: 'Invalid balance statement ID',
  MISSING_BOX_ID: 'Missing Box file ID',
  MISSING_USER: 'Missing user information',
  EMAIL_ERROR: 'Error emailing balance statement: ',
  PDF_GENERATION: {
    IMAGE_LOAD: 'Failed to load image for PDF generation',
    HTML_CONVERT: 'Failed to convert HTML to PDF',
    PAGE_RENDER: 'Failed to render PDF page'
  },
  UPLOAD: {
    FAILED: 'Failed to upload PDF',
    RETRY: 'Upload failed, retrying...'
  },
  INVALID_CASE_FILE_ID: 'Invalid case file ID'
} as const;

/**
 * Service for handling balance statement generation, PDF conversion, and distribution
 * Extends AuthenticatedBaseService for secure API access
 */
@Injectable({
  providedIn: 'root'
})
export class BalanceStatementService extends AuthenticatedBaseService {

  constructor(
    private fileHubService: FileHubService,
    private api: ApiService,
    http: HttpClient,
    errorHandling: ErrorHandlingService,
    notification: NotificationService,
    cognito: CognitoService
  ) {
    super(http, errorHandling, notification, cognito);
  }

  // Private helper methods for error handling and notifications
  private handlePdfError(error: Error, context: string): Observable<never> {
    this.errorHandling.handleError(error, `BalanceStatementService.${context}`);
    return throwError(() => error);
  }

  private notifyProgress(message: string, data?: Record<string, any>) {
    this.notification.info(`${message} ${JSON.stringify(data || {})}`);
  }

  /**
   * Generates PDF balance statements for specified P&Ls
   * @param preliminary - If true, adds PRELIMINARY watermark
   * @param pandls - Array of P&L IDs to generate statements for
   * @returns Observable with status and optional Box link
   */
  generateForCurrentCaseFile(preliminary = false, pandls: number[] = []): Observable<{ status: string, boxLink?: string }> {
    this.notifyProgress('Generating balance statement', { pandlCount: pandls.length, preliminary });

    return this.validateAndGetCaseFile().pipe(
      switchMap(caseFile => this.getBalanceStatementData(caseFile.Id || 0, pandls)),
      switchMap(data => this.generatePdfsForPandLs(data, preliminary)),
      catchError(error => this.handlePdfError(error, 'generateForCurrentCaseFile'))
    );
  }

  /**
   * Gets all required data for generating balance statements
   */
  private getBalanceStatementData(caseFileId: number, pandls: number[]): Observable<BalanceStatementData> {
    // Query for P&Ls
    let pandlQuery = new Query()
      .expand('ConfigPandLs, XrefAddressPandLs($expand=Address($expand=StateNavigation)),XrefPhonePandls($expand=Phone)');

    if (pandls.length > 0) {
      // Create a filter string for multiple IDs
      const filterStr = pandls.map(id => `Id eq ${id}`).join(' or ');
      pandlQuery = pandlQuery.addParams('$filter', filterStr);
    } else {
      // Otherwise, get all active P&Ls
      pandlQuery = pandlQuery.where('IsActive', 'equal', true);
    }

    const caseFileQuery = new Query()
      .expand([
        'Invoices($expand=InvoiceRows($expand=ProcedureCode),InvoicePayments, Provider)',
        'LawFirm($expand=XrefAddressLawfirms($expand=Address($expand=StateNavigation)))',
      ])


    return forkJoin({
      caseFile: from(this.api.getOdata(`${APIEndpoints.Casefiles}(${caseFileId})`).executeQuery(caseFileQuery)).pipe(
        map((res: any) => res.result[0] as CaseFile)
      ),
      pandls: from(this.api.getOdata(APIEndpoints.PandLs).executeQuery(pandlQuery)).pipe(
        map((res: any) => res.result as ListPandL[])
      ),

    }).pipe(
      tap(data => {

        if (data.pandls && data.pandls.length > 0) {
          data.caseFile.PAndLNavigation = data.pandls[0];
        }
      })
    );
  }

  private splitContentIntoPages(tableRows: string[], headerHtml: string, totalAmount: number, preliminary: boolean = false): string[] {
    const measureContainer = document.createElement('div');
    measureContainer.style.cssText = `
      position: absolute;
      left: -9999px;
      top: -9999px;
      width: 612px; /* US Letter width in pixels */
      font-family: Arial, sans-serif;
      font-size: 10px;
      line-height: 1.4;
      padding: 0;
      margin: 0;
    `;
    document.body.appendChild(measureContainer);

    try {
      // 1. Measure critical elements with actual rendered styles
      // Header measurement
      measureContainer.innerHTML = headerHtml;
      const headerHeight = measureContainer.offsetHeight;


      // Table header measurement
      measureContainer.innerHTML = `<table style="width: 100%; border-collapse: collapse;">${tableRows[0]}</table>`;
      const tableHeaderHeight = measureContainer.offsetHeight;


      // Total section measurement
      const totalHtml = this.createTotalSection(totalAmount);
      measureContainer.innerHTML = totalHtml;
      const totalHeight = measureContainer.offsetHeight;

      // 2. Page configuration
      const PAGE_HEIGHT = 792; // US Letter height in points (11" * 72ppi)
      const MARGINS = {
        top: 40,    // Increased top margin for first page
        bottom: 40, // Bottom margin including footer
        general: 25, // For subsequent pages
        safety: 20  // Additional safety margin for provider subtotals
      };

      // 3. Measure all rows with proper context
      const rowHeights: number[] = [];
      tableRows.forEach((row, index) => {
        measureContainer.innerHTML = `
          <table style="width: 100%; border-collapse: collapse;">
            <tbody>${row}</tbody>
          </table>
        `;
        const height = measureContainer.offsetHeight;
        rowHeights.push(height);
      });

      // 4. Pagination logic with detailed logging
      const pages: string[] = [];
      let currentIndex = 0;
      let pageNumber = 1;
      let currentProvider = '';
      let providerSubtotalHeight = 28; // Height of provider subtotal row

      while (currentIndex < tableRows.length) {
        let currentHeight = 0;
        const currentRows: string[] = [];
        const isFirstPage = pageNumber === 1;

        // Calculate available space, including safety margin for provider subtotals
        const availableHeight = PAGE_HEIGHT -
          (isFirstPage ? MARGINS.top : MARGINS.general) -
          MARGINS.bottom -
          (pageNumber === 2 ? MARGINS.safety : MARGINS.safety / 2) - // Reduce safety margin after page 2
          (isFirstPage ? headerHeight : 0) -
          tableHeaderHeight;

        // Add header heights for first page
        if (isFirstPage) {
          currentHeight += headerHeight + tableHeaderHeight;
        } else {
          currentHeight += tableHeaderHeight;
        }

        // Row insertion loop
        while (currentIndex < tableRows.length) {
          const rowHeight = rowHeights[currentIndex];
          const isLastRow = currentIndex === tableRows.length - 1;

          // Check if this row contains a provider change that would need a subtotal
          const rowHtml = tableRows[currentIndex];
          const providerMatch = rowHtml.match(/td[^>]*>(.*?)<\/td>/);
          const provider = providerMatch ? providerMatch[1] : '';
          const providerChanged = provider !== currentProvider;

          // Calculate potential height including possible provider subtotal
          const potentialExtraHeight = providerChanged && currentRows.length > 0 ?
            (pageNumber === 2 ? providerSubtotalHeight : providerSubtotalHeight * 0.8) : 0;
          const potentialHeight = currentHeight + rowHeight + potentialExtraHeight +
            (isLastRow ? totalHeight + (providerChanged ? providerSubtotalHeight : 0) : 0);

          const maxHeight = PAGE_HEIGHT - MARGINS.bottom -
            (isFirstPage ? MARGINS.top : MARGINS.general) -
            (pageNumber === 2 ? MARGINS.safety : MARGINS.safety / 2);

          // Check if we need to break the page due to height
          if (potentialHeight > maxHeight) {
            // If we have rows and the provider changed, add subtotal before breaking
            if (currentRows.length > 0 && currentProvider) {
              currentHeight += pageNumber === 2 ? providerSubtotalHeight : providerSubtotalHeight * 0.8;
            }

            // Special case: empty page prevention
            if (currentRows.length === 0) {
              this.notification.warn(`Forcing row ${currentIndex + 1} onto page ${pageNumber}`);

              currentRows.push(tableRows[currentIndex]);
              currentProvider = provider;
              currentIndex++;
            }
            break;
          }

          // Add the row
          currentRows.push(tableRows[currentIndex]);
          currentHeight += rowHeight;

          // If provider changed, add height for previous provider's subtotal
          if (providerChanged && currentProvider) {
            currentHeight += pageNumber === 2 ? providerSubtotalHeight : providerSubtotalHeight * 0.8;
          }

          currentProvider = provider;
          currentIndex++;

          // Add total height if last row
          if (isLastRow) {
            currentHeight += totalHeight + (providerChanged ? providerSubtotalHeight : 0);
          }
        }

        pages.push(this.createPageHtml(
          currentRows,
          headerHtml,
          totalAmount,
          isFirstPage,
          currentIndex >= tableRows.length,
          preliminary
        ));

        pageNumber++;
      }

      return pages;
    } finally {
      document.body.removeChild(measureContainer);
    }
  }

  /**
   * Converts HTML content to PDF with proper formatting and pagination
   * @throws Error if image loading fails or HTML conversion fails
   */
  private async convertHtmlToPdf(caseFile: CaseFile, preliminary: boolean = false): Promise<ArrayBuffer> {
    try {
      const { pages } = await this.generateBalanceStatementHtml(caseFile, preliminary);
      const pdf = new jsPDF('p', 'pt', 'letter');

      // Preload image with proper error handling
      const logoUrl = caseFile.PAndLNavigation?.ConfigPandLs?.[0]?.Logo || PDF_CONFIG.DEFAULT_LOGO;
      let preloadedImage: string | null = null;

      if (logoUrl) {
        try {
          preloadedImage = await this.preloadImage(logoUrl);
        } catch (error) {
          this.errorHandling.handleError(error, 'BalanceStatementService.preloadImage');
          this.notification.warn('Unable to load P&L logo, continuing without it');
        }
      }

      // Process each page
      for (let pageNum = 0; pageNum < pages.length; pageNum++) {
        if (pageNum > 0) pdf.addPage('letter');

        const pageElement = await this.createPageElement(
          pages[pageNum],
          preloadedImage,
          logoUrl
        );

        try {
          // First, update the element style
          if (pageElement.style.position === 'absolute') {
            pageElement.style.position = 'static';
            pageElement.style.left = '0';
          }

          // Then call html2canvas with supported options
          const canvas = await html2canvas(pageElement, {
            width: PDF_CONFIG.PAGE_WIDTH - (PDF_CONFIG.MARGIN * 2),
            useCORS: true,
            allowTaint: true,
            logging: false,
          });

          await this.addCanvasToPdf(pdf, canvas, pageNum, pages.length);
        } catch (error) {
          const enhancedError = new Error(`${PDF_CONFIG.ERRORS.HTML_CONVERT.technical}: ${(error as Error).message}`);
          this.errorHandling.handleError(enhancedError, 'BalanceStatementService.convertHtmlToPdf');
          throw enhancedError;
        } finally {
          if (document.body.contains(pageElement)) {
            document.body.removeChild(pageElement);
          }
        }
      }

      return pdf.output('arraybuffer');
    } catch (error) {
      this.errorHandling.handleError(error, 'BalanceStatementService.convertHtmlToPdf');
      throw error;
    }
  }

  // New helper methods to break down the complexity
  private async preloadImage(logoUrl: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "anonymous";

      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0);
        resolve(canvas.toDataURL('image/png'));
      };

      img.onerror = () => {
        reject(new Error(PDF_CONFIG.ERRORS.IMAGE_LOAD.technical));
      };

      img.src = logoUrl;
    });
  }

  private createPageElement(pageHtml: string, preloadedImage: string | null, logoUrl: string): HTMLElement {
    const div = document.createElement('div');
    div.style.width = `${PDF_CONFIG.PAGE_WIDTH - (PDF_CONFIG.MARGIN * 2)}px`;
    div.style.position = 'absolute';
    div.style.left = '-9999px';
    div.innerHTML = pageHtml;

    if (preloadedImage) {
      const images = div.getElementsByTagName('img');
      for (const img of Array.from(images)) {
        if (img.src === logoUrl) {
          img.src = preloadedImage;
        }
      }
    }

    document.body.appendChild(div);
    return div;
  }

  private async addCanvasToPdf(
    pdf: jsPDF,
    canvas: HTMLCanvasElement,
    pageNum: number,
    totalPages: number
  ): Promise<void> {
    try {
      // Calculate dimensions
      const imgWidth = PDF_CONFIG.PAGE_WIDTH - (PDF_CONFIG.MARGIN * 2);
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Add image
      pdf.addImage(
        canvas.toDataURL('image/png'),
        'PNG',
        PDF_CONFIG.MARGIN,
        PDF_CONFIG.MARGIN,
        imgWidth,
        imgHeight
      );

      // Add footer
      pdf.setFontSize(PDF_CONFIG.FONTS.SIZE.FOOTER);
      pdf.setTextColor(0, 0, 0);
      const footerText = `Page ${pageNum + 1} of ${totalPages}`;
      pdf.text(
        footerText,
        PDF_CONFIG.PAGE_WIDTH - PDF_CONFIG.MARGIN - pdf.getTextWidth(footerText),
        PDF_CONFIG.PAGE_HEIGHT - PDF_CONFIG.MARGIN
      );
    } catch (error) {
      throw new Error(PDF_CONFIG.ERRORS.CANVAS_CONVERT.technical);
    }
  }

  /**
   * Calculates final balance including:
   * - Total billed amount
   * - Courtesy reductions
   * - Payments received
   */
  private createTotalSection(totalAmount: number): string {
    // Get case file from fileHubService
    const caseFile = this.fileHubService.caseFile;
    if (!caseFile) {
      return `
        <div style="margin-top: 15px; margin-left: auto; width: 300px;">
          <table style="width: 100%; border-collapse: collapse; font-size: 10px;">
            <tr>
              <td style="text-align: right; padding: 4px; font-weight: bold;">SUBTOTAL</td>
              <td style="text-align: right; padding: 4px; width: 100px;">$ ${totalAmount.toFixed(2)}</td>
            </tr>
            <tr>
              <td style="text-align: right; padding: 4px; color: blue;">Courtesy Reduction(-)</td>
              <td style="text-align: right; padding: 4px; width: 100px; color: blue;">$ 0.00</td>
            </tr>
            <tr>
              <td style="text-align: right; padding: 4px; color: red;">Payments Rec'd THANK YOU!(-)</td>
              <td style="text-align: right; padding: 4px; width: 100px; color: red;">$ 0.00</td>
            </tr>
            <tr>
              <td style="text-align: right; padding: 4px; font-weight: bold;">BALANCE DUE</td>
              <td style="text-align: right; padding: 4px; width: 100px; background-color: #90EE90;">$ ${totalAmount.toFixed(2)}</td>
            </tr>
          </table>
        </div>
      `;
    }

    // Calculate total deposits (payments received)
    const paymentsReceived = caseFile.Deposits?.reduce((sum: number, deposit: Deposit) =>
      sum + (deposit.DepositAmount || 0), 0) || 0;

    // Calculate total courtesy reductions from approved reduction requests
    const courtesyReduction = caseFile.ReductionRequests?.reduce((sum: number, request: ReductionRequest) => {
      if (request.Status === 'Approved') {
        return sum + (request.ApprovedAmount || 0);
      }
      return sum;
    }, 0) || 0;

    // Calculate final balance
    const subtotal = totalAmount;
    const balanceDue = subtotal - courtesyReduction - paymentsReceived;

    return `
      <div style="margin-top: 15px; margin-left: auto; width: 300px;">
        <table style="width: 100%; border-collapse: collapse; font-size: 10px;">
          <tr>
            <td style="text-align: right; padding: 4px; font-weight: bold;">SUBTOTAL</td>
            <td style="text-align: right; padding: 4px; width: 100px;">$ ${subtotal.toFixed(2)}</td>
          </tr>
          <tr>
            <td style="text-align: right; padding: 4px; color: blue;">Courtesy Reduction(-)</td>
            <td style="text-align: right; padding: 4px; width: 100px; color: blue;">$ ${courtesyReduction.toFixed(2)}</td>
          </tr>
          <tr>
            <td style="text-align: right; padding: 4px; color: red;">Payments Rec'd THANK YOU!(-)</td>
            <td style="text-align: right; padding: 4px; width: 100px; color: red;">$ ${paymentsReceived.toFixed(2)}</td>
          </tr>
          <tr>
            <td style="text-align: right; padding: 4px; font-weight: bold;">BALANCE DUE</td>
            <td style="text-align: right; padding: 4px; width: 100px; background-color: #90EE90;">$ ${balanceDue.toFixed(2)}</td>
          </tr>
        </table>
      </div>
    `;
  }

  /**
   * Creates the HTML content for a single page
   */
  private createPageHtml(
    pageRows: string[],
    headerHtml: string,
    totalAmount: number,
    isFirstPage: boolean,
    isLastPage: boolean,
    preliminary: boolean = false
  ): string {
    return `
      <div style="font-family: Arial, sans-serif; color: #000000; font-size: 10px; position: relative;">
        ${preliminary ? `
          <div style="
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(-45deg);
            font-size: 100px;
            font-weight: bold;
            color: rgba(128, 128, 128, 0.3);
            white-space: nowrap;
            z-index: 1000;
            pointer-events: none;
          ">
            PRELIMINARY
          </div>
        ` : ''}
        ${isFirstPage ? headerHtml : ''}
        <!-- Invoice Details Table -->
        <table style="width: 100%; border-collapse: collapse; font-size: 10px; ${!isFirstPage ? 'margin-top: 15px;' : ''}">
          <thead>
            <tr style="background-color: #e8f5e9;">
              <th style="width: 25%; padding: 6px; text-align: left; border: 1px solid #ddd; font-weight: bold; font-size: 10px;">Provider</th>
              <th style="width: 12%; padding: 6px; text-align: left; border: 1px solid #ddd; font-weight: bold; font-size: 10px;">In Network</th>
              <th style="width: 15%; padding: 6px; text-align: left; border: 1px solid #ddd; font-weight: bold; font-size: 10px;">Service Date</th>
              <th style="width: 33%; padding: 6px; text-align: left; border: 1px solid #ddd; font-weight: bold; font-size: 10px;">CPT/Description</th>
              <th style="width: 15%; padding: 6px; text-align: right; border: 1px solid #ddd; font-weight: bold; font-size: 10px;">Amount($)</th>
            </tr>
          </thead>
          <tbody>
            ${pageRows.join('')}
          </tbody>
        </table>
        ${isLastPage ? this.createTotalSection(totalAmount) : ''}
      </div>
    `;
  }

  /**
   * Generates HTML for a balance statement
   */
  private async generateBalanceStatementHtml(caseFile: CaseFile, preliminary: boolean = false): Promise<{ pages: string[], totalAmount: number }> {
    // Process invoice rows
    let totalBilled = 0;
    const processedRows: any[] = [];
    const providerGroups: { [key: string]: number } = {};

    // Calculate total deposits
    const totalDeposits = caseFile.Deposits?.reduce((sum: number, deposit) => sum + (deposit.DepositAmount || 0), 0) || 0;

    // Calculate total courtesy reductions from approved reduction requests
    const totalCourtesyReductions = caseFile.ReductionRequests?.reduce((sum: number, request: ReductionRequest) => {
      if (request.Status === 'Approved') {
        return sum + (request.ApprovedAmount || 0);
      }
      return sum;
    }, 0) || 0;


    if (caseFile.Invoices) {
      caseFile.Invoices.forEach(invoice => {
        if (invoice.InvoiceRows && Array.isArray(invoice.InvoiceRows)) {
          const providerName = invoice.Provider?.Name ?? "null";
          if (!providerGroups[providerName]) {
            providerGroups[providerName] = 0;
          }

          invoice.InvoiceRows.forEach(row => {
            const amount = row.AmountBilled || 0;
            totalBilled += amount;
            providerGroups[providerName] += amount;
            processedRows.push({
              provider: providerName,
              inNetwork: invoice.Provider?.InNetwork || true,
              date: row.DateOfService,
              code: row.ProcedureCode?.Description || 'N/A',
              amount: amount
            });
          });
        }
      });
    }

    // Sort rows by provider to ensure they're grouped together
    processedRows.sort((a, b) => a.provider.localeCompare(b.provider));

    // Generate the table rows with subtotals
    let currentProvider = '';
    const tableRows = processedRows.map((row, index) => {
      let html = '';

      // Add provider subtotal if provider changes
      if (currentProvider && currentProvider !== row.provider) {
        html += `
          <tr style="background-color: #f5f5f5;">
            <td style="width: 80%; padding: 6px; border: 1px solid #ddd; font-size: 10px; font-weight: bold;" colspan="4">${currentProvider} Subtotal:</td>
            <td style="width: 20%; padding: 6px; text-align: right; border: 1px solid #ddd; font-size: 10px; font-weight: bold;">$${providerGroups[currentProvider].toFixed(2)}</td>
          </tr>
        `;
      }

      currentProvider = row.provider;

      // Add the regular row
      html += `
        <tr>
          <td style="width: 25%; padding: 6px; text-align: left; border: 1px solid #ddd;">${row.provider}</td>
          <td style="width: 12%; padding: 6px; text-align: left; border: 1px solid #ddd;">${row.inNetwork ? 'Yes' : 'No'}</td>
          <td style="width: 15%; padding: 6px; text-align: left; border: 1px solid #ddd;">${row.date}</td>
          <td style="width: 33%; padding: 6px; text-align: left; border: 1px solid #ddd;">${row.code}</td>
          <td style="width: 15%; padding: 6px; text-align: right; border: 1px solid #ddd;">$ ${row.amount.toFixed(2)}</td>
        </tr>
      `;

      // Add final provider subtotal if last row
      if (index === processedRows.length - 1) {
        html += `
          <tr style="background-color: #f5f5f5;">
            <td style="width: 80%; padding: 6px; border: 1px solid #ddd; font-size: 10px; font-weight: bold;" colspan="4">${currentProvider} Subtotal:</td>
            <td style="width: 20%; padding: 6px; text-align: right; border: 1px solid #ddd; font-size: 10px; font-weight: bold;">$${providerGroups[currentProvider].toFixed(2)}</td>
          </tr>
        `
      }

      return html;
    });

    const totalAmount = totalBilled + totalCourtesyReductions;
    return {
      pages: this.splitContentIntoPages(tableRows, '', totalAmount, preliminary),
      totalAmount: totalAmount
    };
  }

  private validateAndGetCaseFile(): Observable<CaseFile> {
    const caseFile = this.fileHubService.caseFile;
    if (!caseFile) {
      const error = new Error('No case file available');
      this.notification.error('Please select a case file before generating balance statements');
      this.errorHandling.handleError({
        error,
        context: 'BalanceStatementService.validateAndGetCaseFile',
        details: { caseFile }
      });
      return throwError(() => error);
    }

    if (!caseFile.Id) {
      const error = new Error(ERRORS.MISSING_CASE_FILE);
      this.notification.error('Invalid case file - missing ID');
      this.errorHandling.handleError({
        error,
        context: 'BalanceStatementService.validateAndGetCaseFile',
        details: { caseFileId: caseFile.Id }
      });
      return throwError(() => error);
    }

    return of(caseFile);
  }

  private generatePdfsForPandLs(data: BalanceStatementData, preliminary: boolean): Observable<{ status: string, boxLink?: string }> {
    const pdfTasks = data.pandls.map(pandl =>
      this.convertHtmlToPdf(data.caseFile, preliminary).then(buffer => ({
        buffer,
        pandlId: pandl.Id ?? 0
      }))
    );

    return forkJoin(pdfTasks).pipe(
      switchMap(pdfResults => this.uploadPdfs(pdfResults)),
      tap(result => {
        if (!result.boxLink) {
          this.notification.success('Balance statements generated successfully');
        }
      })
    );
  }

  private uploadPdfs(pdfResults: PdfResult[]): Observable<{ status: string, boxLink?: string }> {
    const uploadTasks = pdfResults.map(({ buffer, pandlId }) => {
      return this.uploadPdfWithRetry(buffer, pandlId);
    });

    return forkJoin(uploadTasks).pipe(
      map(results => ({
        status: 'Done',
        boxLink: results.find(result => result !== 'Done')
      }))
    );
  }

  private uploadPdfWithRetry(buffer: ArrayBuffer, pandlId: number): Observable<string> {
    const uploadSinglePdf = () => {
      const blob = new Blob([buffer], { type: 'application/pdf' });
      const file = new File([blob], `balance_statement_${pandlId}.pdf`, { type: 'application/pdf' });
      const formData = new FormData();
      formData.append('file', file);

      const baseEndpoint = APIEndpoints.BalanceStatements.replace(/^\/+|\/+$/g, '');
      const endpoint = `${baseEndpoint}/Upload/${pandlId}`;

      return this.api.fetchRequest(
        endpoint,
        'POST',
        formData,
        { 'Accept': 'application/json' }
      );
    };

    return from(uploadSinglePdf()).pipe(
      retry({
        count: PDF_CONFIG.RETRIES,
        delay: (retryCount) => {
          const delay = 1000 * Math.pow(2, retryCount - 1);
          this.notification.info(
            `${PDF_CONFIG.ERRORS.UPLOAD.message} Attempt ${retryCount} of ${PDF_CONFIG.RETRIES}. Next retry in ${delay / 1000}s`
          );
          return timer(delay);
        }
      })
    );
  }
}
