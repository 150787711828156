// Angular
import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-content',
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class MainContent {

  // Used for declaring Id of parent Element - replaces first slash with empty '', and all remaining slashes with underscores, '_'
  public pathName: string = window.location.pathname.replace('/', '').replace(/\//g, '_');
}
