import { Injectable, inject } from '@angular/core';
import { ErrorHandlingService } from '@core/error/error.service';
import { NotificationService } from '@core/notification/notification.service';
import { NotificationSeverity } from '@core/enums/notification-severity.enum';
import { Observable, throwError } from 'rxjs';
import { ErrorConfig, ErrorSeverity } from '@core/error/error.types';
import { AppError } from '@core/error/app.error';

@Injectable()
export abstract class Base {
    protected readonly errorHandling = inject(ErrorHandlingService);
    protected readonly notification = inject(NotificationService);
    protected readonly AppError = AppError;
    protected readonly NotificationSeverity = NotificationSeverity;
    protected readonly ErrorSeverity = ErrorSeverity;

    constructor() { }

    protected handleError(error: unknown, config: ErrorConfig): Observable<never> {
        if (error instanceof AppError) {
            this.errorHandling.handleError(error, config);
            return throwError(() => error);
        }

        const appError = new AppError(
            config.userMessage,
            error instanceof Error ? error.message : 'Unknown error',
            config.context,
            error instanceof Error ? error : undefined
        );

        this.errorHandling.handleError(appError, config);
        return throwError(() => appError);
    }

    protected notify(message: string, severity: NotificationSeverity = NotificationSeverity.Info): void {
        if (!this.notification) return;

        if (severity === NotificationSeverity.Warning) {
            this.notification.warn(message);
        } else if (severity === NotificationSeverity.Success) {
            this.notification.success(message);
        } else {
            this.notification.info(message); // Default to info
        }
    }
}
