// Angular
import { Component, ViewEncapsulation, ViewChild, WritableSignal, signal } from '@angular/core';
import { Router } from '@angular/router';

// 3rd Party
import { SidebarComponent } from '@syncfusion/ej2-angular-navigations';
import { IconDefinition, faAnglesRight, faAnglesLeft } from '@fortawesome/free-solid-svg-icons';

// Internal
import { GlobalsService } from '@services/globals/globals.service';
import { UserPreferencesService } from '@services/user/user-preferences.service';
import { ButtonComponent } from '@syncfusion/ej2-angular-buttons';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class HeaderContent {

  constructor(
    private router: Router,
    private globals: GlobalsService,
    private user: UserPreferencesService
  ) { }

  logo: WritableSignal<string> = signal('');
  layoutMode: WritableSignal<string> = signal('');
  darkMode: any = this.user.darkMode;
  isDesktop: boolean = this.globals.isDesktop;

  // Sidebar
  @ViewChild('sidebar') sidebar: SidebarComponent;
  @ViewChild('togglebtn') togglebtn?: ButtonComponent;
  sideBarIcon: WritableSignal<string> = signal('');
  type: string = 'Push';
  width: string = '250px';
  dockWidth: string = '72px';
  toggleSideBarIcon: IconDefinition;
  sidebarState: any;
  sidebarId: string;
  appContentClass: string = 'sidebar-open';

  ngOnInit(): void {
    this.user.appLogo$.subscribe((logo) => {
      this.logo.set(logo);
    });
    this.user.appLayout$.subscribe((layoutMode) => {
      this.layoutMode.set(layoutMode);
    });
  }

  ngAfterViewInit(): void {
    const sidebarSettings = localStorage.getItem(this.sidebarId) as string;
    this.sidebarState = JSON.parse(sidebarSettings);
    
    if (sidebarSettings && !this.sidebarState.isOpen){
      this.toggleSidebar('');
      this.toggleSidebar('');
    }
  }

  goHome(): void {
    this.router.navigate(['/dashboard']);
  }

  setToggleSidebarIcon() {

    if (this.sidebar && this.sidebar.isOpen) {
      this.toggleSideBarIcon = faAnglesLeft;
    } else {
      this.toggleSideBarIcon = faAnglesRight;
    }
  }

  toggleSidebar(args: any) {
    let logo: string;
    this.sidebarState = JSON.parse(localStorage.getItem(this.sidebarId) as string);

    if (this.sidebar.isOpen === false) {
      this.sidebar.show();
      logo = this.user.getLocalUserPreference('darkMode') === 'on' ? 'assets/logos/varent-logo_white.png' : 'assets/logos/varent-logo.png';
      this.logo.set(logo);
    } else {
      this.sidebar.hide();
      logo = this.user.getLocalUserPreference('darkMode') === 'on' ? 'assets/logos/varent-icon-light.png' : 'assets/logos/varent-icon-dark.png';
      this.logo.set(logo);
    }

    this.toggleAppContentClass();
    this.setToggleSidebarIcon();
    this.user.saveLocalUserPreference(this.sidebarId, this.sidebarState);
  }

  toggleAppContentClass() {
    const appContent = document.querySelector('app-content');
    appContent?.classList.remove('sidebar-closed');
    appContent?.classList.remove('sidebar-open');
    this.appContentClass = this.sidebar.isOpen === true ? 'sidebar-open' : 'sidebar-closed';
    appContent?.classList.add(this.appContentClass);
  }

  onCreated() {
    const sidebar = this.globals.getSyncfusionElementWithId('mainSidebar');
    this.sidebarId = sidebar ? sidebar.id : '_ejs-component-mainSidebar';
    this.sidebar.element.style.visibility = '';
    this.sidebarState = this.user.getLocalUserPreference(this.sidebarId) ?? JSON.parse(localStorage.getItem(this.sidebarId) as string);
    this.setToggleSidebarIcon();
    this.toggleAppContentClass();
  }
}
