// 3rd Party
import { Query, Predicate, DataManager } from '@syncfusion/ej2-data';
import {
  GridComponent,
  GridModel,
  ColumnModel,
  SortSettingsModel,
  DataResult,
  CommandModel,
  SearchSettingsModel,
  SortDescriptorModel,
} from '@syncfusion/ej2-angular-grids';

// Angular
import { WritableSignal } from '@angular/core';

// Re-export Syncfusion types
export { CommandModel, SearchSettingsModel };

/**
 * Types
 */
export type PredicateOperator =
  | 'equal'
  | 'contains'
  | 'startswith'
  | 'endswith'
  | 'lessthan'
  | 'greaterthan'
  | 'lessthanorequal'
  | 'greaterthanorequal';

export type CustomToolbarItem = string | ToolbarItem;

/**
 * Interfaces
 */
export interface FieldHandler {
  type: 'join' | 'boolean' | 'date' | 'custom';
  handler: (pred: any) => Predicate;
}

export interface FilterFieldOptions {
  [key: string]: FieldHandler;
}

export interface GridQueryOptions {
  expand?: string[];
  select?: string[];
  defaultSort?: SortDescriptorModel[];
  defaultPageSize?: number;
}

export interface FilteringOptions {
  fields?: FilterFieldOptions;
  defaultOperator?: PredicateOperator;
  caseSensitive?: boolean;
  defaultSort?: SortDescriptorModel[];
  transformData?: (data: any[]) => any[];
}

export interface GridStates {
  Grid: {
    SetupArgs: WritableSignal<SetGridDataArgs | undefined>;
    BaseQuery: WritableSignal<GridQueryOptions | undefined>;
    ParentElement: WritableSignal<HTMLElement | undefined>;
    Component: WritableSignal<GridComponent | undefined>;
    Settings: WritableSignal<GridModel | undefined>;
    SortSettings: WritableSignal<SortSettingsModel | undefined>;
    Toolbar: WritableSignal<(string | ToolbarItem)[]>;
    SearchSettings: WritableSignal<GridSearchSettings | undefined>;
  };
  Loading: {
    Grid: WritableSignal<boolean>;
    Data: WritableSignal<boolean>;
    Export: WritableSignal<boolean>;
    Edit: WritableSignal<boolean>;
  };
  Data: {
    Endpoint: WritableSignal<string | undefined>;
    Query: WritableSignal<Query | undefined>;
    Pagination: WritableSignal<{ skip: number; take: number } | undefined>;
    Result: WritableSignal<GridDataResults | undefined>;
    EditRecord: WritableSignal<any | undefined>;
    SelectedRecords: WritableSignal<any | undefined>;
    TotalRecords: WritableSignal<number>;
    PageSize: WritableSignal<number>;
  };
  UI: {
    GridId: WritableSignal<string | undefined>;
    GridName: WritableSignal<string | undefined>;
    IsResponsive: WritableSignal<boolean>;
    ShowActive: WritableSignal<boolean>;
    EditMode: WritableSignal<'add' | 'edit' | 'delete' | undefined>;
  };
  DisableTimeZone: WritableSignal<boolean>;
  Errors: WritableSignal<Record<string, any>>;
}

export interface SetGridDataArgs {
  id?: string;
  endpoint?: string;
  name?: string;
  query?: Query;
  useRoundedEdges?: boolean;
  pageSettings?: { skip: number; take: number };
  defaultSort?: { field: string; direction: 'asc' | 'desc' }[];
  gridSettings?: GridModel;
  filterOptions?: FilteringOptions;
  bulkEditing?: boolean;
  activeRecordsToggle?: boolean;
  transformData?: (data: any[]) => any[];
  excludeToolbarItems?: string[];
  searchableFields?: string[];
}

export interface GridDataResults {
  result: any[] | Object | DataManager | DataResult | undefined;
  count: number;
  aggregates?: any;
  groupDs?: any;
}

export interface ToolbarItem {
  id: string;
  text?: string;
  tooltipText?: string;
  align?: 'Left' | 'Right';
  prefixIcon?: string;
  template?: string;
}

export interface GridSearchSettings {
  key: string;
  fields: string[];
  operator: string;
  ignoreCase: boolean;
}

export interface ToolbarState {
  defaultToolbar: ToolbarItem[];
  selectedToolbar: ToolbarItem[];
}

export interface ODataResponse<T> {
  result: T[];
  count?: number;
  '@odata.count'?: number;
}

/**
 * Constants
 */
export const ColumnType = {
  Checkbox: 'checkbox',
  Commands: 'commands',
  Date: 'date',
  Boolean: 'boolean',
  Dropdown: 'dropdown',
  Numeric: 'numeric',
  Default: 'default',
} as const;

export const DATE_FIELDS = [
  'Dob',
  'Date',
  'FileOpened',
  'DueDate',
  'CreatedAt',
  'UpdatedAt',
  'CreatedDate',
  'UpdatedDate',
] as const;

/**
 * Default Grid Properties
 */
export const DefaultGridProperties = {
  mobileToolbar: [
    'Add',
    'CsvExport',
    'ExcelExport',
    'PdfExport',
    'Print',
    'ColumnChooser',
  ] as CustomToolbarItem[],

  pagination: {
    skip: 0,
    take: 20,
  },

  toolbar: [
    'Add',
    'Search',
    'ColumnChooser',
    { text: 'Copy', tooltipText: 'Copy', id: 'copy', align: 'Right', prefixIcon: 'e-copy' },
    { text: 'CSV Export', tooltipText: 'CSV Export', id: 'csvExport', align: 'Right', prefixIcon: 'e-export-csv' },
    {
      text: 'Excel Export',
      tooltipText: 'Excel Export',
      id: 'excelExport',
      align: 'Right',
      prefixIcon: 'e-excelexport',
    },
    { text: 'PDF Export', tooltipText: 'PDF Export', id: 'pdfExport', align: 'Right', prefixIcon: 'e-pdfexport' },
    { text: 'Print', tooltipText: 'Print', id: 'print', align: 'Right', prefixIcon: 'e-print' },
  ] as CustomToolbarItem[],

  actions: [
    { type: 'Edit', title: 'Edit', buttonOption: { iconCss: 'e-icons e-edit', cssClass: 'e-flat' } },
    { type: 'None', title: 'Toggle IsActive', buttonOption: { iconCss: 'e-icons e-circle-check', cssClass: 'e-flat' } }
  ] as CommandModel[],

  pageSize: 20,

  searchSettings: {
    fields: ['Id', 'Name'],
    operator: 'contains',
    ignoreCase: true
  } as SearchSettingsModel,

  activeRecordsToggle: { 
    text: 'View Inactive', 
    tooltipText: 'Toggle Active Records', 
    id: 'toggle-active', 
    align: 'Left' as const 
  } as CustomToolbarItem
} as const; 
