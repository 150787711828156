// Angular
import { Component, signal, ViewChild, WritableSignal, OnInit, OnDestroy, computed, AfterViewInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subject, takeUntil, take, filter } from 'rxjs';
import { CurrencyPipe } from '@angular/common';

// 3rd Party
import { Query } from '@syncfusion/ej2-data';
import { DialogAllModule, DialogComponent } from '@syncfusion/ej2-angular-popups';
import { GridModel, RowSelectEventArgs, CommandClickEventArgs, DetailDataBoundEventArgs, TextAlign, CommandModel } from '@syncfusion/ej2-grids';
import { GridDetailsConfig, onGridDetailDataBound } from '@grids/helpers';

// Constants
import { APIEndpoints } from '@models/api/Endpoints';
import { DATE_FORMATS } from '@core/date/date.constants';

// Models
import { CaseFile, Deposit, Invoice, ReductionRequest } from '@models/data-contracts';

// Feature Components
import { GenerateBalanceStatementComponent } from '@features/financial/components/balance-statement/generate/generate-balance-statement.component';
import { ViewBalanceStatementComponent } from '@features/financial/components/balance-statement/view/view-balance-statement.component';
import { TabCardComponent } from '@features/file-hub/components/file-hub-tabs/tab-card/tab-card.component';
import { AuditLogsComponent } from '@ui/audit-logs/audit-logs.component';

// Forms
import { TransferInvoiceComponent } from '@forms/transfer-invoice/transfer-invoice.component';
import { AddDepositForm } from "@forms/add-forms/add-deposit/add-deposit.component";
import { AddInvoiceForm } from '@forms/add-forms/add-invoice/add-invoice.component';

// Services
import { FileHubService } from '@features/file-hub/services/file-hub.service';
import { AuditLogService } from '@services/audit-logs/audit-log-service';
import { FinancialPerformanceService } from '@features/financial/services/financial-performance.service';
import { FinancialService } from '@features/financial/services/financial.service';

// Core
import { ComponentBase } from '@core/base/component.base';
import { LoadingModule } from '@modules/loading.module';
import { StatusComponent } from '@ui/status/status.component';
import { GridTemplateComponent } from '@grids/grid-template/grid-template.component';
import { AppError } from '@core/error/app.error';

// Error Constants
import { GridCommand } from './models/financial-tab.model';

// Add back the import
import { CasefileInvoicesGridComponent } from '@components/grids/casefile-invoices-grid/casefile-invoices-grid.component';

const LOGS_HEADERS = {
  INVOICE_HEADER: 'Invoice Header Logs',
  INVOICE_ROW_HEADER: 'Invoice Row Logs',
  INVOICE_PAYMENT_HEADER: 'Invoice Payment Logs',
} as const;

const ERRORS = {
  FINANCIAL: {
    CASE_FILE_MISSING: {
      message: 'Case file information not available',
      technical: 'Case file or ID is undefined after retries'
    },
    LOAD_FAILED: {
      message: 'Unable to load financial data',
      technical: 'Failed to load financial data from API'
    },
    DATA_INVALID: {
      message: 'Financial data is incomplete',
      technical: 'Financial data response is invalid or empty'
    }
  },
  AUDIT: {
    DIALOG: {
      NOT_INITIALIZED: {
        message: 'Unable to show audit logs',
        technical: 'Audit dialog not initialized'
      },
      DISPLAY_ERROR: {
        message: 'Failed to display audit logs',
        technical: 'Error showing audit dialog'
      },
      CLOSE_ERROR: {
        message: 'Failed to close audit logs',
        technical: 'Error closing audit dialog'
      }
    }
  }
} as const;

@Component({
  selector: 'financial-tab',
  standalone: true,
  imports: [
    CommonModule,
    DialogAllModule,
    LoadingModule,
    TabCardComponent,
    StatusComponent,
    GridTemplateComponent,
    GenerateBalanceStatementComponent,
    ViewBalanceStatementComponent,
    TransferInvoiceComponent,
    AddDepositForm,
    AddInvoiceForm,
    AuditLogsComponent,
    CasefileInvoicesGridComponent
  ],
  providers: [
    CurrencyPipe
  ],
  templateUrl: './financial-tab.component.html',
  styleUrl: './financial-tab.component.scss'
})
export class FinancialTab extends ComponentBase implements OnInit, OnDestroy, AfterViewInit {

  private readonly destroy$ = new Subject<void>();

  /**
   * Main functionality can be found in the FileHubService.
   * @see {@link FileHubService} for more info
   */
  constructor(
    public fileHub: FileHubService,
    private auditLogService: AuditLogService,
    private financialPerformanceService: FinancialPerformanceService,
    private financialService: FinancialService,
    private currencyPipe: CurrencyPipe
  ) {
    super();
  }

  public readonly loading = signal(false);
  protected readonly editingInvoice = signal<Invoice | null>(null);
  protected readonly editingDeposit = signal<Deposit | null>(null);

  @ViewChild('generateBalanceStatement') generateBalanceStatement: DialogComponent;

  @ViewChild('viewBalanceStatement') public viewBalanceStatement: DialogComponent;
  public viewBalanceStatementVisibility: boolean = false;

  @ViewChild('transferInvoice') public transferInvoice: DialogComponent;
  public transferInvoiceVisibility: boolean = false;

  @ViewChild('addNewInvoice') public addNewInvoice: DialogComponent;
  public addNewInvoiceVisibility: boolean = false;

  @ViewChild('addDeposit') public addDeposit: DialogComponent;
  public addDepositVisibility: boolean = false;

  @ViewChild('logsDialog') logsDialog: any;
  auditLogs: any[] = [];
  auditLogsLoading = false;
  logsDialogVisibility: boolean = false;
  logsDialogButtons: Object[] = [
    { click: this.closeLogsDialog.bind(this), buttonModel: { content: 'Close', cssClass: 'e-flat' } }
  ];

  // Add property to track dialog visibility
  public generateBalanceStatementVisibility: boolean = false;

  ngOnInit() {
    // Wait for case file to be ready before doing anything
    this.fileHub.caseFile$.pipe(
      filter(file => !!file?.Id),  // Only proceed when we have a valid case file
      take(1),  // Take only the first emission to prevent loops
      takeUntil(this.destroy$)
    ).subscribe({
      next: async () => {
        try {
          await this.loadFinancialData(); // Consolidated loading method
        } catch (error) {
          const appError = new AppError(
            ERRORS.FINANCIAL.LOAD_FAILED.message,
            ERRORS.FINANCIAL.LOAD_FAILED.technical,
            'FinancialTab.ngOnInit',
            error instanceof Error ? error : undefined
          );
          this.handleError(appError, {
            context: appError.context,
            userMessage: appError.userMessage
          });
        }
      }
    });
  }

  ngAfterViewInit() {
    // Initialize dialog visibility states
    if (this.generateBalanceStatement) {
      this.generateBalanceStatement.visible = this.generateBalanceStatementVisibility;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async addFinancialDataToCaseFile(): Promise<void> {
    this.loading.set(true);
    try {
      const res = await this.financialService.getFinancialData(this.fileHub.caseFile?.Id);
      const financialData = res.result[0];

      this.fileHub.updateCaseFile('Invoices', financialData.Invoices ?? []);
      this.fileHub.updateCaseFile('Deposits', financialData.Deposits ?? []);
      this.fileHub.updateCaseFile('ReductionRequests', financialData.ReductionRequests ?? []);

    } catch (error) {
      this.handleError(error, {
        context: 'FinancialTab.addFinancialDataToCaseFile',
        userMessage: 'Failed to load financial data'
      });
    } finally {
      this.loading.set(false);
    }
  }

  // Invoices grid
  invoiceRowColumns = [
    { field: 'Id', isPrimaryKey: true, visible: false },
    {
      field: 'DateOfService',
      headerText: 'Service Date',
      type: 'date',
      editType: 'datepickeredit',
      format: 'M/d/y'
    },
    { field: 'ProcedureCode.ProcedureCodeName', headerText: 'Procedure Code', editType: 'dropdownedit' },
    { field: 'ReimbursementRate', headerText: 'Purchase Rate', editType: 'numericedit', format: 'P' },
    { field: 'AmountBilled', headerText: 'Billed Amount', format: 'C2', editType: 'numericEdit' },
    { field: 'TotalDueProvider', headerText: 'Provider Amount', format: 'C2', editType: 'numericEdit' },
    { field: 'SettlementValue', headerText: 'Settlement Value', format: 'C2', editType: 'numericEdit' },
    {
      field: 'Invoice.SplitInvoice',
      headerText: 'Split Invoice',
      textAlign: 'Center' as TextAlign,
      headerTextAlign: 'Center' as TextAlign
    },
  ];
  invoicePaymentsColumns = [
    { field: 'Id', isPrimaryKey: true, visible: false },
    {
      field: 'DatePaid',
      headerText: 'Payment Date',
      type: 'date',
      editType: 'datepickeredit',
      format: 'M/d/y'
    },
    { field: 'BalanceDue', headerText: 'Balance', format: 'C2', editType: 'numericEdit' },
    { field: 'AmountPaid', headerText: 'Amount Paid', format: 'C2', editType: 'numericEdit' },
    { field: 'PaymentMethodNavigation.Description', headerText: 'Method', editType: 'dropdownedit' },
    { field: 'PaymentStatusNavigation.Description', headerText: 'Status', editType: 'dropdownedit' }
  ];
  invoiceDetailsSettings = [
    {
      header: 'Invoice Details',
      columns: this.invoiceRowColumns,
      dataKey: "InvoiceRows",
      renderMode: ("Vertical" as any),
      allowFiltering: false
    },
    {
      header: 'Payment Details',
      columns: this.invoicePaymentsColumns,
      dataKey: "InvoicePayments",
      renderMode: ("Vertical" as any),
      allowFiltering: false
    }
  ]
  selectedInvoices: WritableSignal<Invoice[] | null> = signal(null); // transfer invoice setup

  handleSubmitComplete() {
    this.editingDeposit.set(null);
    this.addDepositVisibility = false;
    this.addFinancialDataToCaseFile();
  }

  handleDialogClose() {
    this.editingDeposit.set(null);
    this.addDepositVisibility = false;
    if (this.addDeposit) {
      this.addDeposit.hide();
    }
  }

  // Separate dialog close from data reload
  onDialogClose(): void {
    this.addNewInvoice?.hide();
    this.editingInvoice.set(null);
  }

  // Add new method for successful submit
  onInvoiceSubmit(): void {
    this.onDialogClose();
    this.addFinancialDataToCaseFile();  // Changed from loadInvoicesData
  }

  async onCommandClick(args: CommandClickEventArgs) {
    try {
      const invoice = args.rowData as any;
      const command = args.commandColumn?.title;

      if (!invoice?.Id) return;

      switch (command) {
        case GridCommand.Edit:
          args.cancel = true;
          this.editingInvoice.set(invoice);
          this.addNewInvoiceVisibility = true;
          if (this.addNewInvoice) this.addNewInvoice.show();
          break;

        case GridCommand.Logs:
          await this.handleLogsCommand(invoice);
          break;
      }
    } catch (error) {
      this.handleError(error, {
        context: 'FinancialTab.onCommandClick',
        userMessage: 'Failed to process grid command'
      });
    } finally {
      this.loading.set(false);
    }
  }

  public async handleLogsCommand(invoice: any) {
    this.auditLogsLoading = true;
    try {
      this.showLogsDialog();
      await this.loadAndFormatLogs(invoice);
    } finally {
      this.auditLogsLoading = false;
    }
  }

  showLogsDialog() {
    try {
      this.logsDialogVisibility = true;
      if (this.logsDialog) {
        this.logsDialog.show();
      } else {
        this.handleError(new Error(ERRORS.AUDIT.DIALOG.NOT_INITIALIZED.technical), {
          context: 'FinancialTab.showLogsDialog',
          userMessage: ERRORS.AUDIT.DIALOG.NOT_INITIALIZED.message
        });
      }
    } catch (error) {
      this.handleError(error, {
        context: 'FinancialTab.showLogsDialog',
        userMessage: ERRORS.AUDIT.DIALOG.DISPLAY_ERROR.message
      });
    }
  }

  closeLogsDialog() {
    try {
      if (this.logsDialog) {
        this.logsDialog.hide();
        this.logsDialogVisibility = false;
        this.auditLogs = [];
      }
    } catch (error) {
      this.handleError(error, {
        context: 'FinancialTab.closeLogsDialog',
        userMessage: ERRORS.AUDIT.DIALOG.CLOSE_ERROR.message
      });
    }
  }

  beforeOpening(args: any) {
    args.maxHeight = '85vh';
  }

  // Add property to track warning state
  invoiceFormHasWarnings: boolean = false;

  // Add method to handle warning changes
  onInvoiceWarningsChanged(hasWarnings: boolean) {
    this.invoiceFormHasWarnings = hasWarnings;
    if (this.addNewInvoice) {
      // Adjust dialog width based on warnings
      this.addNewInvoice.width = hasWarnings ? '500px' : '90%';
    }
  }

  // Update loadFinancialData to remove performance data loading
  private async loadFinancialData(): Promise<void> {
    this.loading.set(true);
    try {
      if (!this.fileHub.caseFile?.Id) {
        throw new Error('Case file ID is required');
      }

      // Load financial data
      await this.addFinancialDataToCaseFile();

    } finally {
      this.loading.set(false);
    }
  }

  private async loadAndFormatLogs(invoice: any) {
    try {
      const promises = [
        this.auditLogService.getAuditLogs(invoice.Id, 'Invoice'),
        this.auditLogService.getAuditLogs(invoice.InvoiceRows[0].Id, 'InvoiceRow'),
        invoice.InvoicePayments.length > 0 ?
          this.auditLogService.getAuditLogs(invoice.InvoicePayments[0].Id, 'InvoicePayment') : []
      ];
      const [invoiceLogs, rowLogs, paymentLogs] = await Promise.all(promises);

      this.auditLogs = [
        {
          sectionHeader: LOGS_HEADERS.INVOICE_HEADER,
          logs: this.auditLogService.mapAuditDataToLogFormat(invoiceLogs)
        },
        {
          sectionHeader: LOGS_HEADERS.INVOICE_ROW_HEADER,
          logs: this.auditLogService.mapAuditDataToLogFormat(rowLogs)
        },
        {
          sectionHeader: LOGS_HEADERS.INVOICE_PAYMENT_HEADER,
          logs: this.auditLogService.mapAuditDataToLogFormat(paymentLogs)
        }
      ].filter(section => section.logs.length > 0);
    } catch (error) {
      this.closeLogsDialog();
      this.handleError(error, {
        context: 'FinancialTab.loadAndFormatLogs',
        userMessage: 'Failed to load audit logs'
      });
    }
  }

  // Update the show method
  async onGenerateBalanceClick() {
    if (!this.fileHub.caseFile?.Id) {
      this.notify('Please select a case file first');
      return;
    }
    this.generateBalanceStatementVisibility = true;
  }

  // Add method for view balance click
  async onViewBalanceClick() {
    try {
      const hasBalanceStatement = await this.financialService.checkBalanceStatementExists(this.fileHub.caseFile?.Id);
      if (!hasBalanceStatement) {
        this.notify('Please generate a balance statement first', this.NotificationSeverity.Info);
        return;
      }
      this.viewBalanceStatementVisibility = true;
    } catch (error) {
      this.handleError(error, {
        context: 'FinancialTab.onViewBalanceClick',
        userMessage: 'Unable to check balance statement status'
      });
    }
  }

  // Update the method signature to handle the correct event type
  public handleEditInvoice(invoice: Invoice): void {
    this.editingInvoice.set(invoice);  // Set the invoice data
    this.addNewInvoiceVisibility = true;
    if (this.addNewInvoice) {
      this.addNewInvoice.show();
    }
  }

  private formatColumnValue(value: number): string {
    return this.currencyPipe.transform(value, 'USD') || '$0.00';
  }

  public handleAddInvoice(): void {
    this.editingInvoice.set(null);  // Ensure we're not in edit mode
    this.addNewInvoiceVisibility = true;
    if (this.addNewInvoice) {
      this.addNewInvoice.show();
    }
  }

}
