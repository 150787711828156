import { Injectable } from '@angular/core';
import { Query } from '@syncfusion/ej2-data';
import { UserPreferencesService } from '@services/user/user-preferences.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardQueriesService {
  constructor(
    private userService: UserPreferencesService
  ) {}

  private readonly DEFAULT_TAKE_LIMIT = 50;
  private readonly CASEFILE_TAKE_LIMIT = 30;

  getAppointmentsQuery(userId: number): Query {
    const baseQuery = new Query()
      .select([
        'Id',
        'StartDatetime',
        'EndDatetime',
        'ProcedureCodeId',
        'Title',
        'Notes',
        'Block',
        'UpdatedAt'
      ])
      .expand([
        'CaseFile($select=FileNumber;$expand=Patient($select=Firstname, Lastname))',
        'Provider($select=Name)',
        'ProcedureCode($select=Description,ProcedureCodeName;$expand=XrefModalityCptCodes($select=Modality))',
      ])
      .where('StartDatetime', 'greaterThanOrEqual', new Date())
      .sortBy('StartDatetime', 'asc')
      .take(this.DEFAULT_TAKE_LIMIT);

    return this.userService.isAdmin() ? baseQuery : baseQuery.where('UserId', 'equal', userId);
  }

  getCaseFilesQuery(userId: number): Query {
    const baseQuery = new Query()
      .select([
        'Id',
        'FileNumber',
        'DateOfLoss',
        'InTreatment',
        'ResponsibleParty',
        'Statuser',
        'CreatedAt',
        'isSurgical',
        'ReferralSource',
        'LawFirmFileNumber'
      ])
      .expand([
        'Patient($select=Firstname, Lastname, DOB)',
        'LawFirm($select=Name)',
        'CaseManagerNavigation($select=Name)',
        'XrefWorkItemCaseFiles($expand=WorkItem($select=Id,Title,Description,DueDate,Status))'
      ])
      .sortBy('UpdatedAt', 'desc')
      .take(this.CASEFILE_TAKE_LIMIT);

    return this.userService.isAdmin() ? baseQuery : baseQuery.where('CaseManager', 'equal', userId);
  }

  getRoicOverviewQuery(): Query {
    return new Query()
      .select([
        'Id',
        'FileNumber',
        'Invoices',
        'Deposits'
      ])
      .expand([
        'Invoices($expand=InvoiceRows,InvoicePayments)',
        'Deposits',
        'Patient($select=Firstname,Lastname)'
      ])
      .where('IsActive', 'equal', true)
      .take(this.DEFAULT_TAKE_LIMIT);
  }
}