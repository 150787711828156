import { Component } from '@angular/core';
import { ProviderMapComponent } from '../../components/features/provider-map/provider-map.component';

@Component({
  selector: 'app-provider-map-page',
  standalone: true,
  imports: [ProviderMapComponent],
  templateUrl: './provider-map-page.component.html',
  styleUrl: './provider-map-page.component.scss'
})
export class ProviderMapPageComponent {
}
