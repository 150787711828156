// Angular
import {
  Injectable
} from '@angular/core';
import { Router } from '@angular/router';
import { ErrorHandlingService } from '@core/error/error.service';
import { NotificationService } from '@core/notification/notification.service';

// 3rd Party
import {
  CognitoUserPool,
  CognitoUserSession,
  CognitoUser,
  AuthenticationDetails,
  IAuthenticationCallback,
  ICognitoUserSessionData,
  ICognitoUserPoolData,
  ICognitoUserData
} from 'amazon-cognito-identity-js';

// Internal
import config from '@root/config.json';
import { GlobalsService } from '@services/globals/globals.service';
import { UserPreferencesService } from '@services/user/user-preferences.service';
import {
  CognitoAuthResponse,
  MFASetupConfig,
  PasswordChangeRequest,
  AuthError
} from './cognito.types';
import { tracker } from '@openreplay/tracker';

interface ExtendedIAuthenticationCallback extends IAuthenticationCallback {
  associateSecretCode: (secretCode: string) => void;
}

@Injectable({
  providedIn: 'root'
})

export class CognitoService {
  private readonly poolData: ICognitoUserPoolData = {
    UserPoolId: config.amplifyConfiguration.aws_user_pools_id,
    ClientId: config.amplifyConfiguration.aws_user_pools_web_client_id,
  };

  private readonly userPool: CognitoUserPool;
  private cognitoUser: CognitoUser | null = null;
  private sessionData: ICognitoUserSessionData | null = null;
  public usernameQuery: string;

  constructor(
    private router: Router,
    private globals: GlobalsService,
    private user: UserPreferencesService,
    private errorHandling: ErrorHandlingService,
    private notification: NotificationService
  ) {
    this.userPool = new CognitoUserPool(this.poolData);
    this.cognitoUser = this.userPool.getCurrentUser();
  }

  /**
   * Helper Functions
   */

  public getCognitoUser(): CognitoUser | undefined {
    const cognitoUserPool = new CognitoUserPool(this.poolData);
    const cognitoUser = cognitoUserPool.getCurrentUser();

    if (cognitoUser) {
      return cognitoUser;
    }

    this.errorHandling.handleError(new Error('No cognitoUser Found'), 'CognitoService.getCognitoUser');
    return undefined;
  }
  
  public getUserIdToken(): string {
    const cognitoUser = this.getCognitoUser();
    let token = '';

    if (!cognitoUser) {
      this.errorHandling.handleError(new Error('No user found'), 'CognitoService.getUserIdToken');
      return 'No user found';
    }

    cognitoUser.getSession((err: any, session: any) => {
      if (err) {
        this.errorHandling.handleError(err, 'CognitoService.getUserIdToken');
        return err.message;
      }
      token = session.getIdToken().getJwtToken();
      return token;
    });
    
    return token;
  }

  public async resendConfirmationCode(user: string): Promise<any> {
    const username = this.globals.removeEmailFromString(user);
    const userData = {
      Username: user,
      Pool: this.userPool,
    };
    const cognitoUser = new CognitoUser(userData);

    if (!cognitoUser) {
      return Promise.reject(cognitoUser);
    }

    return new Promise(async (resolve, reject) => {
      return await cognitoUser.resendConfirmationCode((err: any, result: any) => {
        if (err) {
          this.errorHandling.handleError(err, 'CognitoService.resendConfirmationCode');
          reject(err);
          return err;
        }
        this.notification.success('Confirmation code resent successfully', 'CognitoService.resendConfirmationCode', { result });
        resolve(result);
        return result;
      });
    });
  }

  public resendConfirmation(email: string, password: string) {
    const userData = {
      Username: email,
      Pool: this.userPool,
    };
    const authDetails = new AuthenticationDetails({
      Username: email, Password: password
    });
    const cognitoUser = new CognitoUser(userData);
    this.notification.success('Cognito user', 'CognitoService.resendConfirmation', { cognitoUser });
    cognitoUser.authenticateUser(authDetails, {
      onSuccess: (result) => {
        this.notification.success('Authentication successful', 'CognitoService.resendConfirmation', { result });
      },
      onFailure: (err) => {
        this.errorHandling.handleError(err, 'CognitoService.resendConfirmation');
      }
    });
    cognitoUser.resendConfirmationCode((err: any, result: any) => {
      if (err) {
        this.errorHandling.handleError(err, 'CognitoService.resendConfirmationCode');
        return err;
      } else {
        this.notification.success('Successfully resent confirmation code', 'CognitoService.resendConfirmationCode', { result });
        return result;
      }
    });
  }

  public async getUserSessionData(): Promise<CognitoUserSession | undefined> {
    const cognitoUser = this.getCognitoUser();

    return new Promise<CognitoUserSession | undefined>((resolve, reject) => {
      cognitoUser?.getSession((err: any, session: CognitoUserSession) => {
        if (!err) {
          resolve(session);
        } else {
          this.errorHandling.handleError(err, 'CognitoService.getUserSessionData');
          reject(err);
        }
      });
    });
  };

  public async getUserMFAOptions(): Promise<any | undefined> {
    const cognitoUser = this.getCognitoUser();

    let returnObj: any = {}

    if (cognitoUser) {
      return new Promise((resolve, reject) => {
        return cognitoUser.getSession((err: any, session: any) => {
          if (!err) {
            return cognitoUser.getMFAOptions(async (err, mfaOptions) => {
              if (!err) {
                if (mfaOptions !== undefined) {
                  returnObj.message = 'getMFAOptions Success! mfaOptions shown in result.';
                  returnObj.mfaOptions = mfaOptions;
                  await returnObj;
                  resolve(mfaOptions as any);
                } else {
                  returnObj.message = 'No value found for getMFAOptions.';
                  returnObj.mfaOptions = mfaOptions;
                  await returnObj;
                  console.log(returnObj);
                  resolve(mfaOptions as any);
                }
              } else {
                returnObj.message = 'getMFAOptions FAIL. Error shown in result.';
                returnObj.mfaOptions = err;
                await returnObj;
                this.errorHandling.handleError(err, 'CognitoService.getUserMFAOptions');
                reject(err);
              }
            });
          }
        });
      });
    } else {
      return Promise.reject(undefined);
    }
  }

  public async logCognitoUserData(user: string | undefined, password: string) {
    let username: string;
    let cognitoUser: CognitoUser | undefined;
    let userData: ICognitoUserData;
    let authDetails: AuthenticationDetails;
    const notification = this.notification;
    const errorHandling = this.errorHandling;

    if (user === undefined) {
      cognitoUser = this.getCognitoUser();
      userData = {
        Username: cognitoUser?.getUsername() as string,
        Pool: this.userPool
      }
    } else {
      username = this.globals.removeEmailFromString(user as string);
      userData = {
        Username: username,
        Pool: this.userPool
      }
      cognitoUser = new CognitoUser(userData);
    }

    if (cognitoUser) {
      username = await this.globals.removeEmailFromString(user as string);
      this.notification.success('Cognito user found', 'CognitoService.logCognitoUserData', { cognitoUser });
      authDetails = new AuthenticationDetails({
        Username: username, Password: password
      })
      const getMFA = this.getUserMFAOptions();
      cognitoUser.authenticateUser(authDetails, {
        onSuccess: async function (result) {
          await getMFA;
          notification.success('Authentication successful', 'CognitoService.logCognitoUserData', { result });
          cognitoUser?.getUserData((err, data) => {
            if (err) {
              errorHandling.handleError(err, 'CognitoService.logCognitoUserData');
              return;
            }
            notification.success('User data retrieved', 'CognitoService.logCognitoUserData', { data });
          });
        },
        onFailure: function (err) {
          errorHandling.handleError(err, 'CognitoService.logCognitoUserData');
        }
      })
    } else {
      this.errorHandling.handleError(new Error('No cognito user found'), 'CognitoService.logCognitoUserData');
    }
  }

  /**
   * User Sign In
   */
  public async signIn(email: string, password: string): Promise<CognitoAuthResponse> {
    try {
      const username = this.globals.removeEmailFromString(email);
      const cognitoUser = this.createCognitoUser(username);
      const authDetails = this.createAuthDetails(email, password);

      // Set openreplay user ID for session
      if (tracker) {
        tracker.setUserID(email);
      }

      return await this.authenticateUser(cognitoUser, authDetails);
    } catch (error) {
      return this.handleAuthError('Sign in failed', error as AuthError);
    }
  }

  private handleMFASetup(cognitoUser: CognitoUser): ExtendedIAuthenticationCallback {
    const self = this;
    return {
      associateSecretCode: (secretCode) => {
        const challengeAnswer = prompt('Please input the TOTP code.', '');
        cognitoUser.verifySoftwareToken(challengeAnswer as string, 'My TOTP device', {
          onSuccess: (result) => {
            self.notification.success('MFA setup successful', 'CognitoService.handleMFASetup');
            return result;
          },
          onFailure: (err) => {
            self.errorHandling.handleError(err, 'CognitoService.handleMFASetup');
            return err;
          }
        });
      },
      onSuccess: (session) => {
        self.notification.success('MFA setup completed', 'CognitoService.handleMFASetup');
        return session;
      },
      onFailure: (err) => {
        self.errorHandling.handleError(err, 'CognitoService.handleMFASetup');
        return err;
      }
    };
  }

  private handleMFATypeSelection(cognitoUser: CognitoUser): void {
    const notification = this.notification;
    const errorHandling = this.errorHandling;
    const mfaType = prompt('Please select the MFA method (SMS_MFA or SOFTWARE_TOKEN_MFA)', '');
    cognitoUser.sendMFASelectionAnswer(mfaType as string, {
      onSuccess: (result) => {
        notification.success('MFA type selection successful', 'CognitoService.handleMFATypeSelection', { result });
      },
      onFailure: (err) => {
        errorHandling.handleError(err, 'CognitoService.handleMFATypeSelection');
      }
    });
  }

  private async handleMFAChallenge(
    cognitoUser: CognitoUser,
    challengeName: string,
    challengeParameters: any
  ): Promise<void> {
    const notification = this.notification;
    notification.success('MFA challenge received', 'CognitoService.handleMFAChallenge', { challengeName, challengeParameters });
  }

  async replaceTemporaryPassword(user: string, oldPassword: string, newPassword: string): Promise<any> {
    const username = this.globals.removeEmailFromString(user);

    var PromiseResult = {
      message: '',
      success: false,
      result: {}
    }

    let userData = {
      Username: username,
      Pool: this.userPool
    }

    let authDetails = new AuthenticationDetails({
      Username: username, Password: oldPassword
    })

    let cognitoUser = new CognitoUser(userData);

    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authDetails, {
        onSuccess: (result) => {
          this.notification.success('Authentication successful', 'CognitoService.replaceTemporaryPassword', { result });
          resolve(result);
          return result;
        },
        onFailure: (err) => {
          this.errorHandling.handleError(err, 'CognitoService.replaceTemporaryPassword');
          reject(err);
          return err;
        },
        newPasswordRequired: (_userAttributes, requiredAttributes) => {
          cognitoUser.completeNewPasswordChallenge(newPassword, requiredAttributes, {
            onSuccess: (result) => {
              localStorage.setItem('isLoggedIn', 'true');
              this.sessionData = { IdToken: result.getIdToken(), AccessToken: result.getAccessToken() }
              this.cognitoUser = this.userPool.getCurrentUser();
              PromiseResult.message = 'Update successful';
              PromiseResult.success = true;
              PromiseResult.result = result;
              this.router.navigate(['/dashboard']);
              this.notification.success('Successfully updated', 'CognitoService.replaceTemporaryPassword', PromiseResult);
            },
            onFailure: (err) => {
              this.errorHandling.handleError(err, 'CognitoService.replaceTemporaryPassword');
              PromiseResult.message = 'Update failed.';
              PromiseResult.result = err;
              reject(PromiseResult);
            }
          });
        }
      });
    });
  }

  /**
   * Remember device - prevents mfa authentication if device remembered
   */
  totpLogin(username: string, password: string): void {
    const authData = {
      Username: username,
      Password: password
    };
    const authDetails = new AuthenticationDetails(authData);

    const userData = {
      Username: username,
      Pool: this.userPool
    };
    const cognitoUser = new CognitoUser(userData);

    cognitoUser.authenticateUser(authDetails, {
      onSuccess: (session: CognitoUserSession) => {
        this.notification.success('Authentication successful', 'CognitoService.totpLogin', { session });
      },
      onFailure: (error: any) => {
        this.errorHandling.handleError(error, 'CognitoService.totpLogin');
      },
      mfaRequired: (challengeName: string, challengeParameters: any) => {
        this.notification.success('MFA required', 'CognitoService.totpLogin', { challengeName, challengeParameters });
        // Assume the MFA method is TOTP (Time-based One-Time Password)
        this.setupTOTPMFA(cognitoUser);
      },
      totpRequired: (secretCode) => {
        this.notification.success('TOTP required', 'CognitoService.totpLogin', { secretCode });
        var challengeAnswer = prompt('totpRequired: Please input the TOTP code.', '');
        cognitoUser.sendMFACode(challengeAnswer as string, {
          onSuccess: (session) => {
            this.notification.success('MFA code verified', 'CognitoService.totpLogin', { session });
          },
          onFailure: (err) => {
            this.errorHandling.handleError(err, 'CognitoService.totpLogin');
          }
        }, 'SOFTWARE_TOKEN_MFA');
      },
    });
  }

  setupTOTPMFA(cognitoUser: CognitoUser): void {
    cognitoUser.associateSoftwareToken({
      associateSecretCode: (secretCode: string) => {
        this.notification.success('Secret code:', 'CognitoService.setupTOTPMFA', { secretCode });
        // Here you would typically display the secret code to the user for them to set up their TOTP app
        const totpCode = prompt('Please enter the TOTP code from your authenticator app:');
        if (totpCode) {
          this.verifyTOTP(cognitoUser, totpCode);
        }
      },
      onFailure: (error: any) => {
        this.errorHandling.handleError(error, 'CognitoService.setupTOTPMFA');
      }
    });
  }

  verifyTOTP(cognitoUser: CognitoUser, totpCode: string): void {
    cognitoUser.verifySoftwareToken(totpCode, 'My TOTP Device', {
      onSuccess: (session: CognitoUserSession) => {
        this.notification.success('TOTP verification successful', 'CognitoService.verifyTOTP', { session });
        // Complete the MFA setup
        cognitoUser.sendMFACode(totpCode, {
          onSuccess: (session: CognitoUserSession) => {
            this.notification.success('MFA setup completed', 'CognitoService.verifyTOTP', { session });
            this.router.navigate(['/dashboard']);
          },
          onFailure: (error: any) => {
            this.errorHandling.handleError(error, 'CognitoService.verifyTOTP');
          }
        });
      },
      onFailure: (error: any) => {
        this.errorHandling.handleError(error, 'CognitoService.verifyTOTP');
      }
    });
  }

  /**
   * User forgot password flow
   */
  public async userForgotPassword(user: string, newPassword: string): Promise<any> {
    const username = this.globals.removeEmailFromString(user);

    var PromiseResult = {
      message: '',
      result: {}
    };

    let userData = {
      Username: username,
      Pool: this.userPool
    };

    let cognitoUser = new CognitoUser(userData);

    return new Promise((resolve, reject) => {
      const errorHandling = this.errorHandling;
      const notification = this.notification;
      notification.success('Starting Forgot Password Promise.', 'CognitoService.userForgotPassword', { cognitoUser });
      cognitoUser.forgotPassword({
        onSuccess: function (data) {
          PromiseResult.message = 'CodeDeliveryData from forgotPassword successful';
          PromiseResult.result = data;
          notification.success('PromiseResult', 'CognitoService.userForgotPassword', { PromiseResult });
          resolve(PromiseResult);
          return PromiseResult;
        },
        onFailure: function (err) {
          errorHandling.handleError(err, 'CognitoService.userForgotPassword');
          PromiseResult.result = err.message;
          reject(PromiseResult);
          return PromiseResult;
        },
        // Optional automatic callback
        inputVerificationCode: function (data) {
          notification.success('Data request: ' + JSON.stringify(data), 'CognitoService.userForgotPassword', { data });
          var verificationCode = prompt('Input verification code sent to ' + cognitoUser.getUsername() + ': ', '');
          const code = verificationCode;

          cognitoUser.getAttributeVerificationCode('email', {
            onSuccess: function (verifyEmailResult) {
              notification.success('call result: ' + verifyEmailResult, 'CognitoService.userForgotPassword', { verifyEmailResult });
            },
            onFailure: function (err) {
              errorHandling.handleError(err, 'CognitoService.userForgotPassword');
            },
            inputVerificationCode: function () {
              cognitoUser.verifyAttribute('email', code as string, this);
            }
          });

          cognitoUser.confirmPassword(code as string, newPassword, {
            onSuccess() {
              PromiseResult.message = 'Password confirmed!';
              PromiseResult.result = data;
              notification.success('Password confirmed successfully', 'CognitoService.userForgotPassword', { PromiseResult });
              resolve(PromiseResult);
              return PromiseResult;
            },
            onFailure(err) {
              PromiseResult.message = 'Error confirming password';
              PromiseResult.result = err;
              errorHandling.handleError(err, 'CognitoService.userForgotPassword');
              reject(PromiseResult);
              return PromiseResult;
            }
          });
        },
      });
    });
  }

  /**
   * Change user password
   */
  public changeUserPassword(oldPassword: string, newPassword: string): Promise<any> {
    const cognitoUser = this.getCognitoUser();
    if (cognitoUser) {
      return new Promise((resolve, reject) => {
        cognitoUser.changePassword(oldPassword, newPassword, (err, result) => {
          if (!err) {
            this.notification.success('Password updated successfully', 'CognitoService.changeUserPassword', { result });
            resolve(result);
            window.location.reload();
          } else {
            this.errorHandling.handleError(err, 'CognitoService.changeUserPassword');
            reject(err);
          }
        });
      });
    } else {
      return Promise.reject(cognitoUser);
    }
  }

  /**
   * User Logout
   */

  public async signOut(): Promise<void> {
    if (this.cognitoUser) {
      this.cognitoUser.signOut();
      this.sessionData = null;
      this.notification.success('Signed out successfully', 'CognitoService.signOut', { sessionData: null });
    }
    localStorage.removeItem('isLoggedIn');
    await this.router.navigate(['/']);
  }

  // MFA Methods
  public async setupMFA(config: MFASetupConfig): Promise<CognitoAuthResponse> {
    try {
      const cognitoUser = this.getCurrentUser();
      if (!cognitoUser) {
        throw new Error('No authenticated user');
      }

      return await this.configureMFA(cognitoUser, config);
    } catch (error) {
      return this.handleAuthError('MFA setup failed', error as AuthError);
    }
  }

  // Password Management
  public async changePassword(request: PasswordChangeRequest): Promise<CognitoAuthResponse> {
    try {
      const cognitoUser = this.getCurrentUser();
      if (!cognitoUser) {
        throw new Error('No authenticated user');
      }

      return await this.updatePassword(cognitoUser, request);
    } catch (error) {
      return this.handleAuthError('Password change failed', error as AuthError);
    }
  }

  public async forgotPassword(username: string): Promise<CognitoAuthResponse> {
    try {
      const cognitoUser = this.createCognitoUser(username);
      return await this.initiatePasswordReset(cognitoUser);
    } catch (error) {
      return this.handleAuthError('Password reset failed', error as AuthError);
    }
  }

  // Session Management
  public async getSession(): Promise<CognitoUserSession | null> {
    try {
      const cognitoUser = this.getCurrentUser();
      if (!cognitoUser) return null;

      const session = await this.getUserSession(cognitoUser);
      this.sessionData = {
        IdToken: session.getIdToken(),
        AccessToken: session.getAccessToken()
      };
      return session;
    } catch (error) {
      this.handleAuthError('Failed to get session', error as AuthError);
      return null;
    }
  }

  public getIdToken(): string | null {
    return this.sessionData?.IdToken?.getJwtToken() || null;
  }

  // Private Helper Methods
  private getCurrentUser(): CognitoUser | null {
    const cognitoUser = this.userPool.getCurrentUser();
    if (!cognitoUser) {
      this.notification.error('No current user found', 'CognitoService.getCurrentUser');
    }
    return cognitoUser;
  }

  private createCognitoUser(username: string): CognitoUser {
    return new CognitoUser({
      Username: username,
      Pool: this.userPool
    });
  }

  private createAuthDetails(username: string, password: string): AuthenticationDetails {
    return new AuthenticationDetails({
      Username: username,
      Password: password
    });
  }

  private async authenticateUser(
    cognitoUser: CognitoUser,
    authDetails: AuthenticationDetails
  ): Promise<CognitoAuthResponse> {
    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authDetails, {
        onSuccess: (session) => {
          this.handleAuthSuccess(session);
          resolve({
            success: true,
            message: 'Authentication successful',
            result: session
          });
        },
        onFailure: (error) => {
          reject(this.handleAuthError('Authentication failed', error));
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          const msg = 'New password required';
          this.notification.success(msg, 'CognitoService.authenticateUser', { userAttributes });
          resolve({
            success: false,
            message: msg,
            result: { type: 'newPasswordRequired', userAttributes, requiredAttributes }
          });
        },
        mfaRequired: (challengeName, challengeParameters) => {
          this.handleMFAChallenge(cognitoUser, challengeName, challengeParameters);
        }
      });
    });
  }

  private async configureMFA(
    cognitoUser: CognitoUser,
    config: MFASetupConfig
  ): Promise<CognitoAuthResponse> {
    return new Promise((resolve, reject) => {
      const mfaSettings = {
        PreferredMfa: config.preferred,
        Enabled: config.enabled
      };

      cognitoUser.setUserMfaPreference(
        config.type === 'SMS' ? mfaSettings : null,
        config.type === 'TOTP' ? mfaSettings : null,
        (error, result) => {
          if (error) {
            reject(this.handleAuthError('MFA setup failed', error as AuthError));
            return;
          }
          resolve({
            success: true,
            message: 'MFA setup successful',
            result
          });
        }
      );
    });
  }

  private async updatePassword(
    cognitoUser: CognitoUser,
    request: PasswordChangeRequest
  ): Promise<CognitoAuthResponse> {
    return new Promise((resolve, reject) => {
      cognitoUser.changePassword(
        request.oldPassword,
        request.newPassword,
        (error, result) => {
          if (error) {
            reject(this.handleAuthError('Password change failed', error as AuthError));
            return;
          }
          resolve({
            success: true,
            message: 'Password changed successfully',
            result
          });
        }
      );
    });
  }

  private async initiatePasswordReset(cognitoUser: CognitoUser): Promise<CognitoAuthResponse> {
    return new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: (result) => {
          resolve({
            success: true,
            message: 'Password reset initiated',
            result
          });
        },
        onFailure: (error) => {
          reject(this.handleAuthError('Password reset failed', error as AuthError));
        }
      });
    });
  }

  private async getUserSession(cognitoUser: CognitoUser): Promise<CognitoUserSession> {
    return new Promise((resolve, reject) => {
      cognitoUser.getSession((error: Error | null, session: CognitoUserSession) => {
        if (error) {
          reject(error);
          return;
        }
        resolve(session);
      });
    });
  }

  private handleAuthSuccess(session: CognitoUserSession): void {
    this.sessionData = {
      IdToken: session.getIdToken(),
      AccessToken: session.getAccessToken()
    };
    localStorage.setItem('isLoggedIn', 'true');
    this.notification.success('Authentication successful', 'CognitoService.handleAuthSuccess', { token: session.getIdToken().getJwtToken() });
  }

  private handleAuthError(message: string, error: AuthError): CognitoAuthResponse {
    this.notification.error(error, message);
    return {
      success: false,
      message,
      error
    };
  }
}
