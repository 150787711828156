// Angular
import { Component, OnInit, SimpleChange, ViewChild, WritableSignal, signal, Output, EventEmitter, SimpleChanges } from '@angular/core';

// 3rd Party
import { DataManager, Query } from '@syncfusion/ej2-data';
import { GridModel } from '@syncfusion/ej2-grids';
import { CommandModel } from '@syncfusion/ej2-angular-grids';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { CaseFile } from '@models/data-contracts';
import { BoxComponentsType } from '@models/components/box-ui.model';
import { ApiService } from '@services/api/api.service';
import { SignalRService } from '@services/api/signal-r.service';
import { EmailService } from '@services/email/email.service';
import { GridTemplateModule } from '@modules/grid-template.module';
import { GridTemplateComponent } from '@grids/grid-template/grid-template.component';
import { BulkEditCaseFilesForm } from '@root/src/app/components/forms/bulk-edit-forms/bulk-edit-case-file/bulk-edit-case-file.component';
import { IntakeSurveyFormComponent } from '@forms/intake-survey-form/intake-survey-form.component';
import { AddFeeScheduleForm } from '@root/src/app/components/forms/add-forms/add-fee-schedule-form/add-fee-schedule-form.component';
import { WorkflowBuilderComponent } from '@features/workflow-builder/workflow-builder.component';
import { WorkflowComponent } from '@features/workflow/workflow.component';
import { ProviderMapComponent } from '@features/provider-map/provider-map.component';
import { AutoFormBuilderComponent } from '@forms/auto-form-builder/auto-form-builder.component';
import { BoxUiComponent } from '@features/box/box-ui/box-ui.component';
import { BoxSignComponent } from '@features/box/box-sign/box-sign.component';
import { SchedulerModalitiesComponent } from '@features/scheduler/scheduler-modalities/scheduler-modalities.component';
import { SchedulerLocationsComponent } from '@features/scheduler/scheduler-locations/scheduler-locations.component';
import { SchedulerCalendarComponent } from '@features/scheduler/scheduler-calendar/scheduler-calendar.component';
import { FileHubComponent } from '../../components/file-hub/file-hub.component';

@Component({
  selector: 'app-test',
  standalone: true,
  imports: [
    ButtonModule,
    GridTemplateModule,
    WorkflowBuilderComponent,
    WorkflowComponent,
    
    BulkEditCaseFilesForm,
    AddFeeScheduleForm,
    ProviderMapComponent,
    AutoFormBuilderComponent,
    IntakeSurveyFormComponent,
    BoxUiComponent,
    BoxSignComponent,
    SchedulerModalitiesComponent,
    SchedulerLocationsComponent,
    SchedulerCalendarComponent,
    FileHubComponent
],
  templateUrl: './test.component.html',
  styleUrl: './test.component.scss'
})
export class TestPageComponent implements OnInit {
  
  constructor(
    private api: ApiService,
    private email: EmailService,
    public signalR: SignalRService,
  ) { }

  caseFileModel: CaseFile;
  usersGridSettings: GridModel;
  rolesGridSettings: GridModel;
  lawfirmsGridSettings: GridModel;
  caseFilesGridSettings: GridModel;
  caseFilesGridSettingsWithForeignKeys: GridModel;
  caseFilesData: DataManager;
  caseFilesPatientData: DataManager;
  caseFilesLawFirmData: DataManager;
  caseFilesMarketManagerData: DataManager;
  caseFilesCaseManagerData: DataManager;
  caseFilesLawFirmContactData: DataManager;
  caseFilesAttorneyData: DataManager;
  caseFilesPAndLData: DataManager;
  caseFilesTaskData: DataManager;
  schedulerLocations: WritableSignal<any> = signal(undefined);
  schedulerLocationsQuery: WritableSignal<any> = signal(undefined);
  schedulerModalities: WritableSignal<any> = signal(undefined);
  schedulerModalitiesQuery: WritableSignal<any> = signal(undefined);
  @Output() calendarLocations = new EventEmitter<any>();
  @Output() calendarModalities = new EventEmitter<any>();
  defaultManageColumnCommands: CommandModel[] = [
    { type: 'Edit', buttonOption: { iconCss: 'e-icons e-edit', cssClass: 'e-flat' } },
    { type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } },
  ];
  contentExplorer = {
    folderId: '0',
    boxCdnJS: 'https://cdn01.boxcdn.net/platform/elements/16.0.0/en-US/explorer.js',
    boxCdnCss: 'https://cdn01.boxcdn.net/platform/elements/16.0.0/en-US/explorer.css',
    name: BoxComponentsType.ContentExplorer
  }
  contentUploader = {
    folderId: '0',
    boxCdnJS: 'https://cdn01.boxcdn.net/platform/elements/16.0.0/en-US/uploader.js',
    boxCdnCss: 'https://cdn01.boxcdn.net/platform/elements/21.0.0/en-US/uploader.css',
    name: BoxComponentsType.ContentUploader
  }
  formResult: any;

  @ViewChild('UsersGrid', {static: true}) UsersGrid!: GridTemplateComponent;

  ngOnInit(): void {
    this.usersGridSettings = {
      dataSource: this.api.getOdata(APIEndpoints.Users),
      query: new Query().expand('XrefUsersRoles($expand=Role($select=RoleName)),XrefUsersRights($expand=Right($select=FriendlyName))'),
      columns: [
        { type: 'checkbox' },
        { field: 'Id', isPrimaryKey: true },
        { field: 'Name' },
        { field: 'Username' },
        { field: 'Email' },
        { field: 'Title' },
        { field: 'Active' },
        { field: 'XrefUsersRoles' },
        { commands: this.defaultManageColumnCommands }
      ]
    };

    this.rolesGridSettings = {
      dataSource: this.api.getOdata(APIEndpoints.Roles),
      query: new Query().expand('XrefRolesRights($expand=Right($select=FriendlyName))'),
      columns: [
        { field: 'Id', isPrimaryKey: true },
        { field: 'RoleName', headerText: 'Role' },
        { field: 'Description' },
        { field: 'Active' },
        { field: 'CreatedAt', headerText: 'Date Created', type: 'datetime', format: 'MM/dd/yyyy hh:mm a' }
      ]
    }

    this.lawfirmsGridSettings = {
      dataSource: this.api.getOdata(APIEndpoints.Lawfirms),
      query: new Query().select(['Id', 'Name', 'Abbreviation', 'Rating', 'MarketManager', 'CaseManager', 'DocumentLink']).where('Active', 'equal', true),
    }

    this.caseFilesGridSettings = {
      dataSource: this.api.getOdata(APIEndpoints.CaseFilesTableData),
      columns: [
        { type: 'checkbox' },
        { field: 'Id' },
        { field: 'CaseNumber', headerText: 'Case #', allowEditing: false },
        { field: 'FileNumber', headerText: 'File #', allowEditing: false , visible: false },
        { field: 'FileOpened', headerText: 'Case Opened', allowEditing: false, format: 'yMd', visible: false },
        { field: 'DateOfLoss', headerText: 'DOL', },
        { field: 'InTreatment', headerText: 'In Treatment' },
        { field: 'LawFirm.Name', headerText: 'Law Firm' },
        { field: 'LawFirmFileNumber', headerText: 'Law Firm File #', visible: false },
        { field: 'PAndLNavigation.Description', headerText: 'P & L' },
        { field: 'Status', headerText: 'Status' },
        { field: 'Active', headerText: 'Active' },
        { field: 'LawFirmContactNavigation.ContactName', headerText: 'Contact' },
        { field: 'AttorneyNavigation.ContactName', headerText: 'Attorney' },
        { field: 'LawFirmFileNumber', headerText: 'Law Firm File Number', visible: false },
        { field: 'FileRating', headerText: 'Rating', visible: false },
        { field: 'Comments', headerText: 'Comments', allowFiltering: false, allowSorting: false, valueAccessor: (field: string, data: Object): string[] => this.getCommentValues(field, data) },
        { field: 'CaseType', headerText: 'Case Type', visible: false },
        { field: 'CaseTypeId', headerText: 'Case Type ID', visible: false },
        { field: 'StatusingGroup', headerText: 'Group', visible: false },
        { field: 'MarketManagerNavigation.Name', headerText: 'Market Manager', visible: false },
        { field: 'CaseManagerNavigation.Name', headerText: 'Case Manager', visible: false },
        { field: 'IsSurgical', headerText: 'Surgical', visible: false },
        { type: 'commands' }
      ],
    }
  }

  /**
   * GRID Tests
   */
  async fetchCaseFilesData() {
    this.caseFilesData = this.api.getOdata(APIEndpoints.CaseFilesTableData);
  }
  // Value accessor for displaying strings in comments column
  getCommentValues(field: string, data: object): string[] {
    if ((data as any)[field]) {
      return ((data as any)[field as string]);
    } else {
      return [''];
    }
  }

  getFormResult(results: any) {
    this.formResult = results;
  }
  
  hideVisibility(e: Event) {
    const target = e.target as HTMLElement;
    const nextSibling = target.nextElementSibling as HTMLElement;
    const iconElement = target.querySelector('span') as HTMLElement;

    if (nextSibling) {
      if (nextSibling.style.display === 'none') {
        nextSibling.style.display = 'block';
        if (iconElement) {
          iconElement.classList.remove('e-eye-slash');
          iconElement.classList.add('e-eye');
        }
      } else {
        nextSibling.style.display = 'none';
        if (iconElement) {
          iconElement.classList.remove('e-eye');
          iconElement.classList.add('e-eye-slash');
        }
      }
    }
  }

  getData(result: any) {
    console.log(result);
    return result;
  }
  
  public sendEmail() {
    const data = {
      fromEmail: 'robert@injuryclaimstatus.com',
      fromName: 'Case Compass noreply',
      toName: ['Robert Poplawsky', 'Robert Poplawsky'],
      toEmail: ['robert.poplawsky@varent.us', 'robert.poplawsky@wshcgroup.com'],
      subject: 'Test',
      htmlBody: '<b>test</b>'
    }
    this.email.sendEmail(data);
  }

  setLocations(result: any) {
    this.schedulerLocations.set(result);
    return result;
  }

  getLocations(result: any) {
    this.schedulerLocationsQuery.set(result);
    return result;
  }

  setModalities(result: any) {
    this.schedulerModalities.set(result);
    return result;
  }

  getModalities(result: any) {
    this.schedulerModalitiesQuery.set(result);
    return result;
  }
}