import { Query } from '@syncfusion/ej2-data';

export const lawFirmQuery = new Query()
  .expand([
    `XrefLawFirmContacts($expand=Contact($expand=XrefAddressContacts($expand=Address($expand=StateNavigation)),XrefPhoneContacts($expand=Phone($select=PhoneNumber));$select=Id,ContactName,ContactEmail,ContactTitle);$select=Contact)`,
    'XrefAddressLawfirms($expand=Address($expand=StateNavigation($select=Id,Name);$select=Id,Address1,City,State,Zip))',
    'XrefPhoneLawfirms($expand=Phone($select=Id,PhoneNumber))'])
  .select([
    'Id',
    'Name',
    'WebsiteUrl',
    'Rating',
    'Abbreviation',
    'MarketManager',
    'CaseManager',
    'XrefLawFirmContacts',
    'XrefAddressLawfirms',
    'XrefPhoneLawfirms'
  ]);