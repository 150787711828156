import { Component } from '@angular/core';
import { ApiService } from '@root/src/app/shared/services/api/api.service';
import { FormBuilder } from '@angular/forms';
import { RemittanceGridComponent } from "../../grids/remittance-grid/remittance-grid.component";

@Component({
  selector: 'app-remittance-report',
  standalone: true,
  imports: [RemittanceGridComponent],
  templateUrl: './remittance-report.component.html',
  styleUrl: './remittance-report.component.scss'
})
export class RemittanceReportComponent {

  constructor(
    private api: ApiService,
    private fb: FormBuilder
  ) { }
  
}