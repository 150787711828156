import { Injectable } from "@angular/core";
import { APIEndpoints } from "@root/src/app/shared/models/api/Endpoints";
import { LawFirm } from "@root/src/app/shared/models/data-contracts";
import { ApiService } from "@root/src/app/shared/services/api/api.service";
import { Query } from "@syncfusion/ej2-data";

@Injectable({
    providedIn: 'root'
  })

export class LawFirmService {
  constructor(private api: ApiService) {}

  async getLawFirmById(id: number, query: Query): Promise<LawFirm> {
    return this.api.getOdata(`${APIEndpoints.Lawfirms}(${id})`)
    .executeQuery(query)
    .then((response: any) => response.result?.[0] || null);
  };

  async getUserById(userId?: number): Promise<any> {
    // If no valid userId, do nothing
    if (!userId) return;

    return this.api.getOdata(`${APIEndpoints.Users}(${userId})`).executeQuery(new Query()).then((res: any) => {
      const user = res.result[0];
      return user.Name;
    });
  }
}