<div id="phone-form" [class]="formClasses.formContainer">
    <h2 [class]="formClasses.mainTitle" *ngIf="displayTitle">
      <fa-icon [icon]="phoneIcons.form" />
      <span>{{formMainTitle ?? 'Phone'}}</span>
    </h2>

    <form [formGroup]="phoneForm" [class]="formClasses.form" (ngSubmit)="onSubmit()">

      <section [class]="formClasses.section">

        <div [class]="formClasses.group">

          <div *ngIf="displayFields.includes('PhoneNumber')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol" [class.error]="phoneForm.get('PhoneNumber')?.errors && phoneForm.get('PhoneNumber')?.touched">
            <span [class]="formClasses.icon">
              <fa-icon [icon]="phoneIcons.phoneNumber" />
              <ejs-tooltip *ngIf="phoneForm.get('PhoneNumber')?.errors && phoneForm.get('PhoneNumber')?.touched" [content]="getErrorMessage('PhoneNumber')" />
            </span>
            <ejs-maskedtextbox formControlName="PhoneNumber"
              [placeholder]="requiredFields.includes('PhoneNumber') ? phoneTypeLabel + ' *' : phoneTypeLabel"
              floatLabelType="Auto"
              mask="(999) 999-9999"
              [class]="formClasses.input" />
            <ng-container *ngIf="phoneForm.get('PhoneNumber')?.invalid && phoneForm.get('PhoneNumber')?.touched">
                <span [class]="formClasses.error">
                  <span [class]="formClasses.errorMessage" *ngFor="let error of phoneForm.get('PhoneNumber')?.errors | keyvalue">{{getErrorMessage('PhoneNumber')}}</span>
                </span>
            </ng-container>
          </div>

          <div *ngIf="displayFields.includes('PhoneType')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol" [class.error]="phoneForm.get('PhoneType')?.errors && phoneForm.get('PhoneType')?.touched">
            <span [class]="formClasses.icon">
                <fa-icon [icon]="phoneIcons.phoneType" />
            </span>
            <ejs-dropdownlist formControlName="PhoneType"
              floatLabelType="Auto"
              [placeholder]="requiredFields.includes('PhoneType') ? 'Type *' : 'Type'"
              [class]="formClasses.input"
              [dataSource]="phoneTypes"
              [value]="phoneTypeValue"
              [fields]="{ text: 'Description', value: 'Id', id: 'Id' }"
              (created)="onPhoneTypesCreated($event)"
              (change)="onPhoneTypeChange($event)" />
            <ng-container *ngIf="phoneForm.get('PhoneType')?.invalid && phoneForm.get('PhoneType')?.touched">
              <span [class]="formClasses.error">{{getErrorMessage('PhoneType')}}</span>
            </ng-container>
          </div>
        </div>

      </section>

      <section [class]="formClasses.section + ' col-12'" *ngIf="displayButtons">
        <div [class]="formClasses.actions">
          <button ejs-button type="button">Cancel</button>
          <button ejs-button [isPrimary]="true" type="submit">Submit</button>
        </div>
      </section>
    </form>
</div>