// Angular
import { computed, Injectable, Signal, signal, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// 3rd Party
import { fetchAuthSession } from '@aws-amplify/auth';
import { Query } from '@syncfusion/ej2-data';


// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { UserError } from '@models/services/user-preferences.model';
import { ApiService } from '@services/api/api.service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { ThemeService } from '@services/theme/theme.service';
import { SidebarComponent } from '@syncfusion/ej2-angular-navigations';

export interface UpdateLogoParams {
  theme?: string | null;
  darkMode?: string | null;
  layoutMode?: string | null;
  sidebar?: SidebarComponent | null;
}

interface SmsPreferences {
  phoneNumber?: string;
  isOptedIn: boolean;
  preferredMethod?: 'sms' | 'app';
}

@Injectable({
  providedIn: 'root',
})
export class UserPreferencesService {

  constructor(
    private api: ApiService,
    private toast: ToastMessageService,
    private theme: ThemeService
  ) {}

  // Key for storing preferences in localStorage
  private localStorageKey = 'userPreferences';
  private userRightsKey = 'userRights';
  private appLayout = new BehaviorSubject<string>(this.getLayout());
  private userIdSignal = signal<number | null>( localStorage.getItem('userId') ? parseInt(localStorage.getItem('userId')!) : null);
  private isAdminSignal = signal<boolean | null>(localStorage.getItem('isAdmin') ? JSON.parse(localStorage.getItem('isAdmin')!) : null);
  private userRightsSignal = signal<string[]>(this.getUserRightsFromStorage());
  appLayout$ = this.appLayout.asObservable(); // Expose Observable
  loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  username: any = this.getUsername().then((result) => { return result; });
  darkMode: any = this.getLocalUserPreference('darkMode');
  primaryColor: any = this.getLocalUserPreference('primaryColor');
  secondaryColor: any = this.getLocalUserPreference('secondaryColor');
  tertiaryColor: any = this.getLocalUserPreference('tertiaryColor');
  appLogo = signal('');

  private readonly SMS_PREFS_KEY = 'sms_preferences';

  // Get currently observed value of loggedIn
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  // Add a getter for the userId signal
  get userId(): Signal<number | null> {
    return this.userIdSignal.asReadonly();
  }

  // Set loggedIn observable
  set isLoggedIn(value: any) {
    this.loggedIn.next(value);
  }

  // Get current user session
  async getUserSession(): Promise<any> {
    return await fetchAuthSession();
  }

  // Get username 
  async getUsername(): Promise<string> {
    return await fetchAuthSession().then((session) => {
      return session.tokens?.signInDetails?.loginId as string;
    })
  }
  
  isAdminPresent = (data: any): boolean => {
    // Early return if no data
    if (!data) return false;

    // Check XrefUsersRoles array directly since we know the structure
    const userRoles = data.XrefUsersRoles;
    if (!Array.isArray(userRoles)) return false;

    // Look for Admin role in the array
    return userRoles.some(xref => 
      xref?.Role?.RoleName?.includes('Admin')
    );
  }

  // Get user preferences and save them to local storage if there are any
  async getUserPreferences(userName?: string) {
    const username = userName ?? await this.getUsername();
    const endpoint = APIEndpoints.Users;
    const query = new Query()
      .where('Username', 'equal', username)
      .expand(['XrefUsersRoles($select=Role;$expand=Role($select=RoleName))']);

    await this.api.getOdata(endpoint).executeQuery(query).then((res) => {
      const user = (res as any).result[0];
      localStorage.setItem('userId', user.Id);
      const isAdmin = this.isAdminPresent(user);
      localStorage.setItem('isAdmin', isAdmin.toString())
      this.isAdminSignal.set(isAdmin);
      const noPreferencesSaved = !user.UserPreferences;
      
      if (!noPreferencesSaved) {
        localStorage.setItem(this.localStorageKey, user.UserPreferences);
      }
      
    });
  }

  // Save user's current preferences to DB
  async patchUserPreferences() {
    const preferences = this.getAllLocalPreferences();

    if (!preferences || !this.userId()) {
      this.toast.showError('No user or preferences found to save.');
    } else {
      const endpoint = `odata${APIEndpoints.Users}/${this.userId()}`;
      const data = { UserPreferences: JSON.stringify(preferences) };
      await this.api.fetchRequest(endpoint, 'PATCH', data).then(async (res: any) => {
        if (!res.ok) {
          this.toast.showError(`Error ${res.status}: (Bad Request)`);
          return;
        } else {
          this.toast.showSuccess(`User settings saved.`);
          return;
        }
      });
    }
  }

  // Return an object of user preferences, pulled from localStorage string
  getAllLocalPreferences() {
    const storedSettings = localStorage.getItem(this.localStorageKey);
    return storedSettings ? JSON.parse(storedSettings) : {};
  }

  // Store userPreferences to localStorage as string
  saveAllLocalPreferences(preferences: any): any {
    localStorage.setItem(this.localStorageKey, JSON.stringify(preferences));
  }

  // Add or update single preference in userPreferences in localStorage
  saveLocalUserPreference(key: any, value: any): void {
    const preferences = this.getAllLocalPreferences();
    preferences[key] = value;
    this.saveAllLocalPreferences(preferences);
  }

  // Get a single value from userPreferences string in localStorage
  getLocalUserPreference(key: string): any {
    const preferences = this.getAllLocalPreferences();
    return preferences && preferences[key] ? preferences[key] : undefined;
  }

  // Remove single preference from userPreferences string in localStorage
  removeLocalUserPreference(key: string): void {
    const preferences = this.getAllLocalPreferences();

    if (preferences[key]) {
      delete preferences[key];
      console.log(`[${key}] ${UserError.REMOVED}`);
      this.saveAllLocalPreferences(preferences);
    } else {
      console.log(`[${key}] ${UserError.NOT_FOUND}`);
      this.toast.showError(`Unable to remove preference. User Preference, \"${key}\" not found.`)
    }
  }

  // Apply dark mode based on userPreferences
  applyDarkMode() {
    const darkMode = this.getLocalUserPreference('darkMode');

    switch (darkMode) {
      case 'on': this.turnDarkModeOn(); break;
      case 'off': this.turnDarkModeOff(); break;
      default: this.turnDarkModeOff(); break;
    }
  }
  

  // Switch between dark modes
  toggleDarkMode() {
    const darkMode = this.getLocalUserPreference('darkMode');

    switch (darkMode) {
      case 'on': this.turnDarkModeOff(); break;
      case 'off': this.turnDarkModeOn(); break;
      default: this.turnDarkModeOff(); break;
    }

    this.appLogo.set(this.getLogo());
  }

  turnDarkModeOn = () => {
    document.body.classList.add('e-dark-mode'); 
    this.saveLocalUserPreference('darkMode', 'on');
  }

  turnDarkModeOff = () => {
    document.body.classList.remove('e-dark-mode'); 
    this.saveLocalUserPreference('darkMode', 'off');
  }

  // Apply layout mode based on userPreferences
  applyLayoutMode() {
    const layoutMode = this.getLocalUserPreference('layoutMode');

    switch (layoutMode) {
      case 'app': this.turnAppModeOn(); break;
      case 'standard': this.turnAppModeOff(); break;
      default: this.turnAppModeOff(); break;
    }
    
    this.appLayout.next(this.getLayout());  // Notifies any subscribers of change
  }

  // Switch between layout modes
  toggleLayoutMode() {
    const layoutMode = this.getLocalUserPreference('layoutMode');

    switch (layoutMode) {
      case 'app': this.turnAppModeOff(); break;
      case 'standard': this.turnAppModeOn(); break;
      default: this.turnAppModeOn(); break;
    }
    
    this.appLayout.next(this.getLayout());  // Notifies any subscribers of change
  }

  turnAppModeOn = () => {
    document.body.classList.add('app-mode'); 
    this.saveLocalUserPreference('layoutMode', 'app');
    this.appLogo.set(this.getLogo({ layoutMode: 'app' }));
  }

  turnAppModeOff = () => {
    document.body.classList.remove('app-mode'); 
    document.querySelector('app-content')?.removeAttribute('class');
    this.saveLocalUserPreference('layoutMode', 'standard');
    this.appLogo.set(this.getLogo({ layoutMode: 'standard' }));
  }

  getLayout(): string {
    return this.getLocalUserPreference('layoutMode');
  }  

  getLogo(args?: UpdateLogoParams | null): string {
    // Get layout options to determine which logo to use
    const theme = args?.theme ?? this.getLocalUserPreference('theme') ?? undefined;
    const darkMode = args?.darkMode ?? this.getLocalUserPreference('darkMode') ?? undefined;
    const sidebarSettings = localStorage.getItem('sidebar_ejs-component-mainSidebar');
    const sidebar = args?.sidebar ?? (sidebarSettings ? JSON.parse(sidebarSettings) : undefined);
    const layoutMode = args?.layoutMode ?? this.getLocalUserPreference('layoutMode') ?? undefined;
    const sidebarIsOpen = sidebar && sidebar.isOpen ? true : false;

    // Initialize variables that will be used in return
    const logoBaseUrl = 'assets/logos/Varent-Logo-RGB_';
    let logoUrl = '';

    switch (theme) {
      case 'default': logoUrl = darkMode === 'on' ? `${logoBaseUrl}White` : `${logoBaseUrl}Mint`; break;
      case 'basic': logoUrl = darkMode === 'on' ? `${logoBaseUrl}White` : `${logoBaseUrl}Magenta`; break;
      case 'ocean': logoUrl = darkMode === 'on' ? `${logoBaseUrl}White` : `${logoBaseUrl}Blue`; break;
      case 'orange': logoUrl = darkMode === 'on' ? `${logoBaseUrl}White` : `${logoBaseUrl}Orange`; break;
      case 'glamour': logoUrl = darkMode === 'on' ? `${logoBaseUrl}White` : `${logoBaseUrl}Magenta`; break;
      case 'rainforest': logoUrl = darkMode === 'on' ? `${logoBaseUrl}White` : `${logoBaseUrl}Green`; break;
      case 'purple':  logoUrl = darkMode === 'on' ? `${logoBaseUrl}White` : `${logoBaseUrl}Magenta`; break;
      case 'modern': logoUrl = darkMode === 'on' ? `${logoBaseUrl}White` : `${logoBaseUrl}Mint`; break;
      case 'custom': logoUrl = darkMode === 'on' ? `${logoBaseUrl}White` : `${logoBaseUrl}Black`; break;
      default: logoUrl = darkMode === 'on' ? `${logoBaseUrl}White` : `${logoBaseUrl}Mint`; break;
    }

    if (!sidebarIsOpen && layoutMode === 'app') logoUrl = `${logoUrl}_Icon`;
    this.appLogo.set(`${logoUrl}.png`);
    return `${logoUrl}.png`;
  }

  // Apply layout mode based on userPreferences
  applyUserTheme() {
    const theme = this.getLocalUserPreference('theme');
    this.applyTheme(theme);
  }

  saveTheme(theme: string) {
    this.saveLocalUserPreference('theme', theme);
  }

  loadTheme() {
    const theme = this.getLocalUserPreference('theme') ?? 'default';
    this.applyTheme(theme);
  }
  
  applyTheme(theme: string) {
    theme = !theme ? 'default' : theme;
    const themeColors = this.theme.getThemeColors(theme);
    const customColors = this.getLocalUserPreference('customThemeColors');

    switch (theme) {
      case 'false': this.theme.resetTheme(); break;
      case 'default': this.theme.resetTheme(); break;
      case 'custom': this.theme.setTheme(customColors.PrimaryColor as string, customColors.SecondaryColor, customColors.TertiaryColor); break;
      default: this.theme.setTheme(themeColors?.PrimaryColor as string, themeColors?.SecondaryColor, themeColors?.TertiaryColor); break;
    }

    this.saveTheme(theme);
  }

  // Add method to initialize userId
  async initializeUserId(): Promise<void> {
    const session = await this.getUserSession();
    if (session?.tokens?.accessToken) {
      await this.getUserPreferences(); // This sets userId in localStorage
      this.userIdSignal.set( localStorage.getItem('userId') ? parseInt(localStorage.getItem('userId')!) : null);
    }
  }

  get isAdmin(): Signal<boolean | null> {
    return this.isAdminSignal.asReadonly();
  }

  // Get user rights as a readonly signal
  get userRights(): Signal<string[]> {
    return this.userRightsSignal.asReadonly();
  }

  private getUserRightsFromStorage(): string[] {
    const storedRights = localStorage.getItem(this.userRightsKey);
    return storedRights ? JSON.parse(storedRights) : [];
  }

  // Fetch and store user rights
  async fetchAndStoreUserRights(username: string): Promise<void> {
    try {
      const query = new Query()
        .where('Username', 'equal', username)
        .expand([
          'XrefUsersRights($expand=Right($select=RightName))',
          'XrefUsersRoles($expand=Role($expand=XrefRolesRights($expand=Right($select=RightName))))'
        ]);

      const userData = await this.api.getOdata('/users').executeQuery(query);
      const user = (userData as any).result[0];

      if (!user) {
        throw new Error('User not found');
      }

      // Extract direct user rights
      const directUserRights = user.XrefUsersRights?.map((xref: any) => xref.Right.RightName) || [];

      // Extract rights from roles
      const roleRights = [];
      if (user.XrefUsersRoles) {
        for (const roleXref of user.XrefUsersRoles) {
          if (roleXref.Role?.XrefRolesRights) {
            const rights = roleXref.Role.XrefRolesRights
              .map((rightXref: any) => rightXref.Right?.RightName)
              .filter(Boolean);
            roleRights.push(...rights);
          }
        }
      }

      // Combine and store unique rights
      const allUserRights = [...new Set([...directUserRights, ...roleRights])];
      localStorage.setItem(this.userRightsKey, JSON.stringify(allUserRights));
      this.userRightsSignal.set(allUserRights);

    } catch (error) {
      console.error('Error fetching user rights:', error);
      throw error;
    }
  }

  // Clear user rights on logout
  clearUserRights(): void {
    localStorage.removeItem(this.userRightsKey);
    this.userRightsSignal.set([]);
  }

  // Check if user has required rights
  hasRequiredRights(requiredRights: string[]): boolean {
    if (!requiredRights || requiredRights.length === 0) return true;
    const userRights = this.getUserRightsFromStorage();
    return requiredRights.every(right => userRights.includes(right));
  }

  async getSmsPreferences(): Promise<SmsPreferences | null> {
    const prefs = localStorage.getItem(this.SMS_PREFS_KEY);
    return prefs ? JSON.parse(prefs) : null;
  }

  async updateSmsPreferences(prefs: Partial<SmsPreferences>): Promise<void> {
    const currentPrefs = await this.getSmsPreferences() || { isOptedIn: false };
    const updatedPrefs = { ...currentPrefs, ...prefs };
    localStorage.setItem(this.SMS_PREFS_KEY, JSON.stringify(updatedPrefs));
  }
}
