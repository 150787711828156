<div id="users-grid">

    <grid-template #usersGrid [settings]="usersGridSettings" [name]="'Users'" [useRoundedEdges]="true" />

    <!-- create dropdown form for editing user roles -->
    <ng-template #XrefUsersRolesEditTemplate let-data>
        <ejs-dropdownlist 
            #rolesDD 
            [dataSource]="rolesData"
            [fields]="{ text: 'RoleName', value: 'Id' }"
            [value]="data.XrefUsersRoles?.[0]?.Role?.Id"
            (change)="rolesDDChanged($event)">
        </ejs-dropdownlist>
    </ng-template>

    <!-- Modal popup for adding new users -->
    <div class="dialog-container" *ngIf="showAddUserDialog">
        <ejs-dialog #addUserDialog [isModal]='true' 
                   [visible]="showAddUserDialog"
                   [header]="''"
                   [width]='600' 
                   [showCloseIcon]='true' 
                   (close)='handleCloseForm(false)'>
          <ng-template #content>
            <add-user *ngIf="showAddUserForm"
                     [grid]="usersGrid!.grid!"
                     [mode]="mode"
                     [userData]="selectedUser"
                     (closeForm)="handleCloseForm(true)">
            </add-user>
          </ng-template>
        </ejs-dialog>
    </div>
    
    
</div>