// Angular
import { Component, signal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';

// 3rd Party
import { Query } from '@syncfusion/ej2-data';

// Models
import { APIEndpoints } from '@models/api/Endpoints';
import { Intake } from '@models/data-contracts';

// Services
import { ApiService } from '@services/api/api.service';
import { FileHubService } from '@root/src/app/features/file-hub/services/file-hub.service';

// Modules
import { LoadingModule } from "@modules/loading.module";

// Components
import { TabCardComponent } from "../tab-card/tab-card.component";
import { CasefileIntakeFormComponent } from '@forms/casefile-intake-form/casefile-intake-form.component';

@Component({
  selector: 'intake-tab',
  standalone: true,
  imports: [
    CasefileIntakeFormComponent,
    LoadingModule,
    TabCardComponent,
    CommonModule,
  ],
  templateUrl: './intake-tab.component.html',
  styleUrl: './intake-tab.component.scss'
})
export class InjuryTab {

  /**
   * Main functionality can be found in the FileHubService.
   * @see {@link FileHubService} for more info
   */
  constructor(
    public fileHub: FileHubService,
    private api: ApiService
  ) {}

  loadingIntake: WritableSignal<boolean> = signal(true);
  caseFileId: number;
  existingIntake: WritableSignal<Intake | null> = signal(null);
  existingInsurances: WritableSignal<any> = signal([]);
  existingAccidentDetails: WritableSignal<any> = signal([]);
  existingInjuries: WritableSignal<any> = signal([]);


  ngOnInit() {
    this.checkData();
  }

  // Verifies data is available
  checkData() {

    if (!this.fileHub.caseFile && !this.fileHub.caseFileId) {
      this.fileHub.showNoCaseFileError('Unable to display Injury forms.');
    } else {
      this.caseFileId = this.fileHub.caseFileId ?? this.fileHub.caseFile?.Id as number;
      this.getIntake();
      this.getInjuries();
    }
  }

  getIntake() {
    this.api.getOdata(`${APIEndpoints.Casefiles}(${this.caseFileId})`)
    .executeQuery(new Query().expand(['Intakes', 'Insurances(expand=Address, ClaimAdjusterContact)']).select(['Intakes', 'Insurances']))
    .then((response: any) => {
      this.existingIntake.set(response.result[0].Intakes[0]);
      this.existingInsurances.set(response.result[0].Insurances);
      if (this.existingIntake() && this.existingIntake()?.Id) {
        this.getXrefIntakeAccidents(this.existingIntake()?.Id);
      }
    })
  }

  getXrefIntakeAccidents(id: number | undefined ) {
    if(id) {
      this.api.getOdata(`${APIEndpoints.XrefIntakeAccidents}`)
        .executeQuery(new Query().where('IntakeId', 'equal', id))
        .then((response: any) => {
          this.existingAccidentDetails.set(response.result);
          this.loadingIntake.set(false);
        })
    }
  }

  getInjuries() {

    try {
      this.api.getOdata(`${APIEndpoints.XrefIntakeInjury}`)
      .executeQuery(new Query().where('CaseFileId', 'equal', this.caseFileId))
      .then((response: any) => {
        this.existingInjuries.set(response.result);
        this.loadingIntake.set(false);
      })
    } catch (error) {
      console.error('Error fetching injuries:', error);
    }
  }
}
