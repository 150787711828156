<div *ngIf="isLoading; else content">
</div>

<ng-template #content> 
    <div id="account-details">
        
        <div class="header">
            <h2>{{ getEntityTypeLabel() }} Details</h2>
            <button ejs-button (click)="openEditDialog()">Edit Details</button>
        </div>
    
        <ejs-accordion [enablePersistence]="true">
            <e-accordionitems>
                <e-accordionitem [expanded]="true">
                    <ng-template #header>
                        <div class="header" [ngClass]="{'expanded': isExpanded}" [attr.aria-expanded]="isExpanded">
                            <span *ngFor="let detail of expandableDetails">
                                <label><strong>{{ detail.label }}:</strong> {{ detail.value }}</label>
                            </span>
                        </div>
                    </ng-template>
    
                    <ng-template #content>
                        <div class="content">
                            <div class="details" *ngFor="let detailGroup of details">
                                <div *ngFor="let detail of detailGroup" class="detail-row">
                                    <span><strong>{{ detail.label }}:</strong></span>
                                    <span class="info-text">{{ detail.value }}</span>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </e-accordionitem>
            </e-accordionitems>
        </ejs-accordion>
        
        <div id="file-hub-tabs">
            <ejs-tab #ejsTab id="_ejs-component-ejsTab" 
                [enablePersistence]="true">
                <e-tabitems id="content">
                    <e-tabitem *ngFor="let tab of tabs" 
                              [header]="{ text: tab.text }" 
                              [id]="tab.id">
                        <ng-template #content>
                            <div style="height: 20px;"></div>
                            <div class="tab-contents">
                                <ng-container [ngComponentOutlet]="tab.component"
                                            [ngComponentOutletInputs]="{
                                              entityType: entityType,
                                              entityId: entityId,
                                              payload: payload
                                            }">
                                </ng-container>
                            </div>
                        </ng-template>
                    </e-tabitem>
                </e-tabitems>
            </ejs-tab>
        </div>
    </div>
</ng-template>

<ejs-dialog id="edit-entity-dialog" #editLawFirmDialog
    [showCloseIcon]="true"
    [target]="'body'"
    [isModal]="true"
    [closeOnEscape]="true"
    [visible]="editLawFirmDialogVisibility"
    [header]="'Edit ' + [payload.Name]"
    [buttons]="lawFirmDialogButtons"
    (close)="editLawFirmDialog.hide()">
    <law-firm-form #lawFirmFormComp
        [lawFirm]="lawFirmSignal"
        [isLawFirm]="true"
        [hideActions]="false"
    ></law-firm-form>
</ejs-dialog>

<ejs-dialog id="edit-provider-dialog" #editProviderDialog
    [showCloseIcon]="true"
    [target]="'body'"
    [isModal]="true"
    [closeOnEscape]="true"
    [visible]="editProviderDialogVisibility"
    [header]="'Edit ' + [payload.Name]"
    (close)="editProviderDialog.hide()">
    <provider-form #providerFormComp
        [provider]="providerSignal"
        [submitType]="'PATCH'"
        (formCancelled)="editProviderDialog.hide()"
        (formSubmitted)="editProviderDialogVisibility = false; refreshGrid()"
    ></provider-form>
</ejs-dialog>

<ejs-dialog id="edit-patient-dialog" #editPatientDialog
    [showCloseIcon]="true"
    [target]="'body'"
    [isModal]="true"
    [closeOnEscape]="true"
    [visible]="editPatientDialogVisibility"
    [header]="'Editing ' + [payload.Firstname + ' ' + payload.Lastname]"
    (close)="editPatientDialog.hide()">
    <patient-form-component #patientFormComp 
        [patient]="patientSignal"
        (formCancelled)="editPatientDialog.hide()" 
        (formSubmitted)="editPatientDialogVisibility = false; refreshGrid()" 
        [displayButtons]="true"
        [isEditPatient]="true"
        [displayMainTitle]="false"
    />
</ejs-dialog>
