// Angular
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

// 3rd Party
import { Amplify } from 'aws-amplify';
import { registerLicense } from '@syncfusion/ej2-base';

// Internal
import keys from './assets/syncfusion/keys.json';
import config from './amplifyconfiguration.json';
import { AppModule } from '@modules/app.module';

Amplify.configure(config);

// Register Syncfusion license
registerLicense(keys.SYNCFUSION_KEY);
enableProdMode();

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
