import { Injectable } from '@angular/core';
import { CognitoService } from '@services/auth/cognito.service';

@Injectable({ providedIn: 'root' })
export class AuthHeadersService {
    constructor(private cognito: CognitoService) { }

    getAuthHeaders(): { [key: string]: string } {
        return {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.cognito.getUserIdToken()}`
        };
    }
}
