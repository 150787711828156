<div id="home-tab" class="tab-content">
    
    <div class="row">

        <!-- Replace Recent File Activity with Comments -->
        <div class="col col-12 col-lg-6">
            <tab-card>
                <comments-grid [caseFileId]="readyCaseFileId()" />
            </tab-card>
        </div>

        <!-- Summaries -->
        <div class="col-12 col-lg-6">

            <div class="row">

                <!-- Counts Summaries -->
                <div class="col-12">
                    
                    <div class="row">

                        <div class="col col-12 col-lg-4">
                            <tab-card [heading]="'Task Count'" [loadingContent]="loadingCardData">
                                <div class="summary row">
                                    <span class="col-12 col-sm-6"><label>Active: </label>{{workItemsCountData ? workItemsCountData.IsActive : ''}}</span>
                                    <span class="col-12 col-sm-6"><label>Total: </label>{{workItemsCountData ? workItemsCountData.Total : ''}}</span>
                                </div>
                            </tab-card>
                        </div>

                        <div class="col col-12 col-lg-4">
                            <tab-card [heading]="'Invoices Count'" [loadingContent]="loadingCardData">
                                <div class="summary row">
                                    <span class="col-12 col-sm-6"><label>Providers: </label>{{invoicesCountData ? invoicesCountData.Providers : ''}}</span>
                                    <span class="col-12 col-sm-6"><label>Total: </label>{{invoicesCountData ? invoicesCountData.Total : ''}}</span>
                                </div>
                            </tab-card>
                        </div>

                        <div class="col col-12 col-lg-4">
                            <tab-card [heading]="'Appts Count'" [loadingContent]="loadingCardData">
                                <div class="summary row">
                                    <span class="col-12 col-sm-6"><label>Active: </label>{{appointmentsCountData ? appointmentsCountData.IsActive : ''}}</span>
                                    <span class="col-12 col-sm-6"><label>Total: </label>{{appointmentsCountData ? appointmentsCountData.Total : ''}}</span>
                                </div>
                            </tab-card>
                        </div>

                    </div>

                </div>

                <!-- Financial Summaries -->
                <div class="col col-12">
                    <tab-card [heading]="'Financial Summary'" [loadingContent]="loadingCardData">
                        <div class="summary financial row">
                            <span class="col-12 col-sm-6 col-lg-4 col-xl-3"><label>Days Open: </label>{{appointmentsCountData ? appointmentsCountData.IsActive : ''}}</span>
                            <span class="col-12 col-sm-6 col-lg-4 col-xl-3"><label>Total COGS: </label>{{appointmentsCountData ? appointmentsCountData.Total : ''}}</span>
                            <span class="col-12 col-sm-6 col-lg-4 col-xl-3"><label>Total Billed: </label>{{appointmentsCountData ? appointmentsCountData.IsActive : ''}}</span>
                            <span class="col-12 col-sm-6 col-lg-4 col-xl-3"><label>Reductions: </label>{{appointmentsCountData ? appointmentsCountData.Total : ''}}</span>
                            <span class="col-12 col-sm-6 col-lg-4 col-xl-3"><label>ROIC: </label>{{appointmentsCountData ? appointmentsCountData.IsActive : ''}}</span>
                            <span class="col-12 col-sm-6 col-lg-4 col-xl-3"><label>Deposits: </label>{{appointmentsCountData ? appointmentsCountData.Total : ''}}</span>
                        </div>
                    </tab-card>
                </div>
            </div>
        </div>

        <!-- Recent Activity -->
        <div class="col col-12">
            <tab-card [loadingContent]="loadingActivityData">
                <recent-activity-grid [caseFileId]="readyCaseFileId()" />
            </tab-card>
        </div>
    </div>
</div>