<!-- Patient Form Start -->
<div id="patient-form">
    <!-- Add loading overlay -->
    <div *ngIf="loadingStatus().loading" class="loading-overlay">
        <div class="loading-content">
            <div class="spinner"></div>
            <div class="loading-message">{{ loadingStatus().message }}</div>
        </div>
    </div>

    <h2 [class]="formClasses.mainTitle" *ngIf="displayMainTitle">
        <fa-icon [icon]="patientIcons.form" />
        <span>{{formMainTitle ?? 'Patient'}}</span>
    </h2>

    <!-- Add Patient Search -->
    <div class="patient-search row" *ngIf="!isEditPatient">
        <div class="col-11">
            <ejs-dropdownlist #patientsDropdown
                placeholder="Search Patients"
                floatLabelType="Auto"
                [dataSource]="patients"
                [fields]="{ text: 'text', value: 'value' }"
                [allowFiltering]="true"
                [value]="selectedPatientId ?? patient()?.Id ?? patientForm.get('Id')?.value ?? undefined"
                (change)="onPatientSelect($event)"
                (filtering)="onDropdownFiltering($event, patients)" />
        </div>
        
        <div class="col-1 d-flex align-items-center justify-content-end">
            <button ejs-button 
                class="patient-form-toggle" 
                [title]="displayForm ? 'Hide Patient Info' : 'Show Patient Info'"
                (click)="displayForm = !displayForm" 
                type="button">
                <fa-icon [icon]="displayForm ? patientIcons.hide : patientIcons.show"></fa-icon>
            </button> 
        </div>
    </div>

    <div [class]="formClasses.formContainer + ' ' + (displayForm ? '' : 'hide')">

        <form [formGroup]="patientForm" [class]="formClasses.form + ' ' + (displayForm ? '' : 'hide')" (ngSubmit)="onSubmit()">

            <section [class]="formClasses.section + ' ' + formClasses.twoCol + ' patient-section'">
                
                <div [class]="formClasses.group">

                    <div *ngIf="displayFields.includes('Firstname')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol" [class.error]="patientForm.get('Firstname')?.errors && patientForm.get('Firstname')?.touched">
                        <span [class]="formClasses.icon">
                            <fa-icon [icon]="patientIcons.patient" />
                            <ejs-tooltip *ngIf="patientForm.get('Firstname')?.errors && patientForm.get('Firstname')?.touched" [content]="getErrorMessage('Firstname')" />
                        </span>
                        <ejs-textbox formControlName="Firstname" 
                            placeholder="First Name"
                            floatLabelType="Auto"
                            [class]="formClasses.input"
                            [value]="patientForm.get('Firstname')?.value" />
                        <ng-container *ngIf="patientForm.get('Firstname')?.invalid && patientForm.get('Firstname')?.touched">
                            <span [class]="formClasses.error">{{errorMessage}}</span>
                        </ng-container>
                    </div>

                    <div *ngIf="displayFields.includes('Lastname')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol" [class.error]="patientForm.get('Lastname')?.errors && patientForm.get('Lastname')?.touched">
                        <ejs-textbox formControlName="Lastname" 
                            placeholder="Last Name"
                            floatLabelType="Auto"
                            [class]="formClasses.input"
                            [value]="patientForm.get('Lastname')?.value" />
                        <ng-container *ngIf="patientForm.get('Lastname')?.invalid && patientForm.get('Lastname')?.touched">
                            <span [class]="formClasses.error">{{errorMessage}}</span>
                        </ng-container>
                    </div>
    
                    <div *ngIf="displayFields.includes('Email')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol" [class.error]="patientForm.get('Email')?.errors && patientForm.get('Email')?.touched">
                        <span [class]="formClasses.icon">
                            <fa-icon [icon]="patientIcons.email" />
                            <ejs-tooltip *ngIf="patientForm.get('Email')?.errors && patientForm.get('Email')?.touched" [content]="getErrorMessage('Email')" />
                        </span>
                        <ejs-textbox formControlName="Email" 
                            placeholder="Email"
                            floatLabelType="Auto"
                            [type]="'email'"
                            [class]="formClasses.input"
                            [value]="patientForm.get('Email')?.value" />
                        <ng-container *ngIf="patientForm.get('Email')?.invalid && patientForm.get('Email')?.touched">
                            <span [class]="formClasses.error">{{errorMessage}}</span>
                        </ng-container>
                    </div>
    
                    <div *ngIf="displayFields.includes('Gender')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol">
                        <span [class]="formClasses.icon">
                            <fa-icon [icon]="patientIcons.gender" />
                        </span>
                        <ejs-dropdownlist formControlName="Gender" 
                            placeholder="Gender"
                            floatLabelType="Auto"
                            [class]="formClasses.input"
                            [dataSource]="genders"
                            [value]="patientForm.get('Gender')?.value" />
                    </div>
    
                    <div *ngIf="displayFields.includes('Dob')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol">
                        <span [class]="formClasses.icon">
                            <fa-icon [icon]="patientIcons.dob" />
                        </span>
                        <ejs-datepicker formControlName="Dob" #dobComp
                            placeholder="D.O.B"
                            floatLabelType="Auto"
                            [format]="'yyyy-MM-dd'"
                            [class]="formClasses.input"
                            [value]="patientForm.get('Dob')?.value"
                            (focus)="onDobFocus($event)"
                            (change)="onDobChange($event)" />
                    </div>
    
                    <div *ngIf="displayFields.includes('Language')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol">
                        <span [class]="formClasses.icon">
                            <fa-icon [icon]="patientIcons.language" />
                        </span>
                        <ejs-dropdownlist formControlName="Language" 
                            placeholder="Language"
                            floatLabelType="Auto"
                            [class]="formClasses.input"
                            [dataSource]="languages"
                            [fields]="{ text: 'Description', value: 'Description' }"
                            [value]="patientForm.get('Language')?.value" />
                    </div>
    
                    <div *ngIf="displayFields.includes('Minor')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol">
                        <span [class]="formClasses.icon">
                            <fa-icon [icon]="patientIcons.minor" />
                        </span>
                        <ejs-checkbox formControlName="Minor" 
                            label="Minor" 
                            labelPosition="Before"
                            [value]="patientForm.get('Minor')?.value" />
                    </div>
    
                </div>
        
            </section>
    
            <section [class]="formClasses.section + ' ' + formClasses.twoCol + ' address-section'">
                <address-form #addressFormComp 
                    [address]="patientAddress" 
                    [displayFields]="['Address1', 'Address2', 'City', 'State', 'Zip', 'County', 'AddressType']" 
                    [displayButtons]="false" />
            </section>
            
            <section [class]="formClasses.section + ' col-12'" *ngIf="displayButtons">
                <div [class]="formClasses.actions">
                    <button ejs-button [isPrimary]="true" type="submit">Submit</button>
                    <button ejs-button type="button" (click)="formCancelled.emit()">Cancel</button>
                </div>
            </section>
    
        </form>

    </div>

</div>
<!-- Patient Form End -->