<div id="procedure-codes-grid" class="procedure-codes-grid">

    @if (loadingData()) {
        <loading-overlay [loading]="loadingData" />
    } @else {
        <grid-template #procedureCodesGrid [settings]="procedureCodesGridSettings" [name]="'Procedure Codes'"></grid-template>

        <div *ngIf="isAddProcCodeDialogVisible">
            <ejs-dialog #addProcCodeDialog
                [showCloseIcon]="true"
                [target]="'#procedure-codes-grid'"
                [isModal]="true"
                [closeOnEscape]="true"
                [visible]="isAddProcCodeDialogVisible"
                [header]="'Add Procedure Codes'"
                (beforeOpen)="beforeOpenAddProcCodeDialog($event)">
                    <add-procedure-code-form #addProcedureCodeForm></add-procedure-code-form>
            </ejs-dialog>
        </div>
    }

</div>
