<div id="add-provider-form">
    <form [formGroup]="providerFormGroup" (ngSubmit)="addProvider()" class="d-flex flex-column search-form">

        <!-- Form Entries -->
        <div class="row">
            <div class="col-6">
                <ejs-textbox required id="name" type="string" formControlName="Name" floatLabelType="Auto"
                    placeholder="Provider name" />
            </div>
            <div class="col-6">
                <ejs-textbox required id="website" formControlName="WebsiteUrl" floatLabelType="Auto"
                    placeholder="Website Url" />
            </div>
            <div class="col-6">
                <app-dropdown-multi id="type" name="ProviderType" placeholder="Provider Type" [endpoint]=providerTypes
                    [form]="providerFormGroup"></app-dropdown-multi>
            </div>
            <div class="col-6">
                <app-dropdown-single id="priority" name="PriorityId" placeholder="Priority Status"
                    [endpoint]=providerPriorities [form]="providerFormGroup"></app-dropdown-single>
            </div>
            <div class="col-6">
                <app-dropdown-single id="user" name="CareCoordinator" placeholder="Care Coordinator" [endpoint]=users
                    descriptionName="Email" [form]="providerFormGroup"></app-dropdown-single>
            </div>
            <div class="col-6">
                <app-dropdown-single id="user" name="ProviderRelationsManager" placeholder="Provider Relations Manager"
                    [endpoint]=users descriptionName="Email" [form]="providerFormGroup"></app-dropdown-single>
            </div>
            <app-add-address #AddressForm></app-add-address>
            <div class="col-6" [formGroup]="phoneFormGroup">
                <ejs-textbox required id="phone" formControlName="PhoneNumber" floatLabelType="Auto"
                    placeholder="Primary Phone"></ejs-textbox>
            </div>
            <div class="col-6" [formGroup]="faxFormGroup">
                <ejs-textbox required id="fax" formControlName="PhoneNumber" floatLabelType="Auto"
                    placeholder="Primary Fax"></ejs-textbox>
            </div>
            <div class="col-6">
                <ejs-numerictextbox required id="reimbursement" formControlName="ReimbursementRate"
                    floatLabelType="Auto" placeholder="Reimbursement rate"></ejs-numerictextbox>
            </div>
            <div class="col-6">
                <ejs-datepicker required id="reimbursement Date"
                    [(value)]="providerFormGroup.value.ReimbursementRateEffectiveDate" floatLabelType="Auto"
                    placeholder="Reimbursement Rate Date"></ejs-datepicker>
            </div>
            <div class="col-6">
                <ejs-textbox id="w9_name" type="string" required formControlName="W9Name" floatLabelType="Auto"
                    placeholder="W-9 name"></ejs-textbox>
            </div>
            <div class="col-6">
                <ejs-textbox required id="tax" formControlName="TaxId" floatLabelType="Auto"
                    placeholder="Tax Id"></ejs-textbox>
            </div>
            <div class="col-12">
                <ejs-textbox id="notes" required formControlName="Notes" floatLabelType="Auto"
                    placeholder="Notes"></ejs-textbox>
            </div>
            <div class="col-12">
                <spacer-element></spacer-element>
            </div>
        </div>

        <!-- CheckBoxes -->
        <div class="row">
            <div class="col-4">
                <ejs-checkbox label="Signed Purchase Agreement?"
                    [checked]="providerFormGroup.value.SignedPurchaseAgreement"></ejs-checkbox>
            </div>
            <div class="col-4">
                <ejs-checkbox label="Signed W-9?" [checked]="providerFormGroup.value.SignedW9"></ejs-checkbox>
            </div>
            <div class="col-4">
                <ejs-checkbox label="Signed HIPAA?" [checked]="providerFormGroup.value.SignedHipaa"></ejs-checkbox>
            </div>
            <div class="col-4">
                <ejs-checkbox label="Notes Important?"
                    [checked]="providerFormGroup.value.NotesImportant"></ejs-checkbox>
            </div>
            <div class="col-4">
                <ejs-checkbox label="Holds Own AR?" [checked]="providerFormGroup.value.HoldsOwnAR"></ejs-checkbox>
            </div>
            <div class="col-4">
                <ejs-checkbox label="Referring Provider?"
                    [checked]="providerFormGroup.value.ReferringProvider"></ejs-checkbox>
            </div>
            <div class="col-4">
                <ejs-checkbox label="Virtual?" [checked]="providerFormGroup.value.VirtualProvider"></ejs-checkbox>
            </div>
            <div class="col-4">
                <ejs-checkbox label="Checkpoint Eligible?"
                    [checked]="providerFormGroup.value.CheckpointEligible"></ejs-checkbox>
            </div>
            <div class="col-4">
                <ejs-checkbox label="Spanish Speaking?"
                    [checked]="providerFormGroup.value.SpanishSpeaking"></ejs-checkbox>
            </div>
            <div class="col-10">
                <ejs-checkbox label="In Network?" [checked]="providerFormGroup.value.InNetwork"></ejs-checkbox>
            </div>
            <div class="col-12 form-submit d-flex justify-content-end">
                <button class="e-primary e-flat" ejs-button type="submit" isPrimary="true">Submit</button>
            </div>
        </div>
    </form>
</div>