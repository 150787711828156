<div id="file-hub">
    
    @if (loadingCaseFile()) {
        <div class="loading-container d-flex justify-content-center align-items-center">
            <loading-overlay [loading]="loadingCaseFile" />
        </div>
    } @else {
        <hub-header />
        <hub-tabs />
    }

</div>