import { Component, ViewChild, Input, signal, WritableSignal, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { DialogAllModule, DialogComponent } from '@syncfusion/ej2-angular-popups';
import { Query, Predicate } from '@syncfusion/ej2-data';
import { GridModel, CommandClickEventArgs, ColumnModel } from '@syncfusion/ej2-grids';
import { GridTemplateComponent } from '@grids/grid-template/grid-template.component';
import { LoadingModule } from '@modules/loading.module';
import { WorkItemFormComponent } from '../work-item-form/work-item-form.component';
import { BulkEditWorkItemsComponent } from '../bulk-edit-work-items/bulk-edit-work-items.component';
import { WorkItemsService } from '../../services/work-items.service';
import { WorkItem } from '@shared/models/data-contracts';
import { APIEndpoints } from '@models/api/Endpoints';
import { UserPreferencesService } from '@services/user/user-preferences.service';
import { SwitchAllModule } from '@syncfusion/ej2-angular-buttons';
import { ItemModel } from '@syncfusion/ej2-navigations';
import { NotificationSeverity } from '@core/notification/notification.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BasicGridComponent } from '@root/app/shared/components/basic-grid/basic-grid.component';
import { ToastMessageService } from '@root/app/shared/services/toast-message/toast-message.service';

interface PriorityColors {
  [key: string]: string;
}

interface PriorityData {
  Id: number;
  Name: string;
  color_hex: string;
}

@Component({
  selector: 'work-items-grid',
  standalone: true,
  imports: [
    CommonModule,
    DialogAllModule,
    GridTemplateComponent,
    LoadingModule,
    WorkItemFormComponent,
    BulkEditWorkItemsComponent,
    SwitchAllModule,
    BasicGridComponent
  ],
  templateUrl: './work-items-grid.component.html',
  styleUrl: './work-items-grid.component.scss'
})
export class WorkItemsGridComponent extends BasicGridComponent {

  @ViewChild('workItemsGrid') workItemsGrid!: BasicGridComponent;
  @ViewChild('workItemsDialog') workItemsDialog!: DialogComponent;
  @ViewChild('workItemFormComponent') workItemFormComponent!: WorkItemFormComponent;
  @ViewChild('bulkEditWorkItemsComponent') bulkEditWorkItemsComponent: BulkEditWorkItemsComponent;
  @ViewChild('nameTemplate', { static: true }) nameTemplate!: string;
  @ViewChild('caseFilesTemplate', { static: true }) caseFilesTemplate!: string;
  @ViewChild('providerTemplate', { static: true }) providerTemplate!: string;
  @ViewChild('dueDateTemplate', { static: true }) dueDateTemplate!: string;
  @ViewChild('descriptionTemplate', { static: true }) descriptionTemplate!: string;
  @Input() initialQuery?: Query;
  @Input() inCaseFileContext = false;
  @Input() caseFileId?: number;
  @Input() defaultProviderAgreement = false;

  workItems: GridModel;
  loadingData: WritableSignal<boolean> = signal(true);
  selectedWorkItem: WritableSignal<WorkItem | undefined> = signal(undefined);
  selectedWorkItems: WorkItem[] = [];
  showActive = true;

  // Track selected rows count for toolbar
  private selectedRowCount: number = 0;

  // Add these properties
  selectedRecords: any[] = [];
  workItemsGridDefaultToolbar = ['Add', 'Search', 'ColumnChooser'];

  readonly workItemDialogButtons = [
    {
      click: () => this.workItemFormComponent.formCancelled.emit(),
      buttonModel: { content: 'Cancel', cssClass: 'e-outline' }
    },
    {
      click: () => this.submitWorkItemForm(),
      buttonModel: { content: 'Save', isPrimary: true }
    }
  ] as const;

  @ViewChild('completeTaskDialog') completeTaskDialog!: DialogComponent;
  @ViewChild('alreadyCompletedDialog') alreadyCompletedDialog!: DialogComponent;
  private taskToComplete: any;

  readonly completeTaskButtons: any[] = [
    {
      click: () => this.completeTaskDialog.hide(),
      buttonModel: { content: 'No', cssClass: 'e-outline' }
    },
    {
      click: () => this.completeTask(),
      buttonModel: { content: 'Yes', isPrimary: true }
    }
  ];

  readonly alreadyCompletedButtons: any[] = [
    {
      click: () => this.alreadyCompletedDialog.hide(),
      buttonModel: { content: 'Close' }
    }
  ];

  showProviderAgreementTasks = false;

  // Modify priorityColors to be a signal since it will be populated asynchronously
  private priorityColors: WritableSignal<PriorityColors> = signal({
    'default': 'transparent'
  });

  @ViewChild('priorityColorTemplate', { static: true }) priorityColorTemplate!: string;

  workItemDetailsSettings = [
    {
      header: 'Description',
      columns: [
        {
          field: 'Description',
          headerText: 'Description',
          width: '100%'
        }
      ],
      dataKey: "",
      renderMode: "Vertical" as const
    }
  ];

  // Add this to make console available in template
  console = console;

  // Add to component
  expandedRows: Set<number> = new Set();

  // Add dialog reference
  @ViewChild('descriptionDialog') descriptionDialog!: DialogComponent;

  // Add dialog buttons
  readonly descriptionDialogButtons = [
    {
      click: () => this.descriptionDialog.hide(),
      buttonModel: { content: 'Close', cssClass: 'e-flat' }
    }
  ];

  // Add this property to track current page state
  private currentPageSettings = {
    skip: 0,
    take: 10
  };

  // Add near other properties
  private filterSettings: { columns: Array<{ field: string; value: string }> } = { columns: [] };

  // Add new property near other state properties
  showCompletedTasks = false;

  constructor(
    private router: Router,
    private workItemsService: WorkItemsService,
    private toast: ToastMessageService,
    private user: UserPreferencesService,
    private sanitizer: DomSanitizer
  ) {
    super();
  }

  ngOnInit(): void {
    // Only initialize if we're not in case file context or if we have a case file ID
    if (!this.inCaseFileContext || this.caseFileId) {
      this.initializeGridData();
    }
  }

  // Add new method to handle grid initialization
  private async initializeGridData(): Promise<void> {
    try {
      const [_, data] = await Promise.all([
        this.fetchPriorityColors(),
        this.getData()
      ]);

      this.initializeGrid(data);
    } catch (error: any) {
      const context = error.queryDetails?.operation || 'WorkItemsGrid.initialization';
      const userMessage = 'Failed to initialize work items grid. Please try refreshing the page.';
      this.handleError(error, { context, userMessage });
    } finally {
      this.loadingData.set(false);
    }
  }

  // Add ngOnChanges to handle when caseFileId becomes available
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['caseFileId'] &&
        this.inCaseFileContext &&
        changes['caseFileId'].currentValue &&
        !changes['caseFileId'].firstChange) {
      this.initializeGridData();
    }
  }

  async getData(pageSettings: { skip: number, take: number } = { skip: 0, take: 10 }) {
    // Add early return if we're in case file context but don't have an ID
    if (this.inCaseFileContext && !this.caseFileId) {
      return {
        result: [],
        count: 0,
        aggregates: null,
        groupDs: null
      };
    }

    const endpoint = APIEndpoints.WorkItems;
    let query = new Query()
      .expand([
        'Type($select=Name)',
        'Category($select=Name)',
        'Priority($select=Name)',
        'Status($select=Name)',
        'AssignedToNavigation($select=Name)',
        'XrefWorkItemCaseFiles($select=CaseFileId;$expand=CaseFile($select=FileNumber))',
        'XrefWorkItemProviders($select=ProviderId;$expand=Provider($select=Name))'
      ])
      .skip(pageSettings.skip)
      .take(pageSettings.take)
      .requiresCount();

    // Always apply the appropriate sorting based on completion status
    if (this.showCompletedTasks) {
      query = query
        .where('Status/Name', 'equal', 'Completed')
        .sortBy('DueDate', 'desc'); // Completed items sorted by most recent due date first
    } else {
      query = query
        .where('Status/Name', 'notequal', 'Completed')
        .sortBy('DueDate', 'asc'); // Active items sorted by earliest due date first
    }

    // Add case file context filtering if needed
    if (this.inCaseFileContext) {
      if (this.showProviderAgreementTasks) {
        query = query
          .where('XrefWorkItemCaseFiles/any(x: x/CaseFileId eq ' + this.caseFileId + ')', 'equal', true)
          .where('Category/Name', 'equal', 'Provider Agreement');
      } else {
        query = query
          .where('XrefWorkItemCaseFiles/any(x: x/CaseFileId eq ' + this.caseFileId + ')', 'equal', true)
          .where('Category/Name', 'notequal', 'Provider Agreement');
      }
    }

    const response = await this.workItemsService.getWorkItemsData(query);

    if (!response || !response.result) throw new Error('Failed to fetch work items.');

    this.loadingData.set(false);
    return {
      result: response.result,
      count: response.count,
      aggregates: null,
      groupDs: null
    };
  }

  async customToolbarClick(args: any): Promise<void> {
    if (args.item.text === 'Add') {
      args.cancel = true;
      this.selectedWorkItem.set(undefined);  // Clear selected item first
      this.workItemsDialog.header = 'Add New Task';

      // Clear form and set defaults for new item
      this.workItemFormComponent?.workItemForm.reset();
      this.workItemFormComponent.showProviderField = false;
      const defaults = await this.workItemsService.getNewWorkItemDefaults();
      this.workItemFormComponent.workItemForm.patchValue({
        ...defaults,
        Title: 'Default'  // Set default title for new items
      });

      // If in case file context, pre-populate the case file
      if (this.inCaseFileContext && this.caseFileId) {
        this.workItemFormComponent.selectedCaseFiles = [this.caseFileId];
        this.workItemFormComponent.workItemForm.patchValue({
          caseFileIds: [this.caseFileId]
        });
      } else {
        this.workItemFormComponent.selectedCaseFiles = [];
      }

      // Set default category if provider agreement is toggled on
      if (this.showProviderAgreementTasks) {
        this.workItemFormComponent.setDefaultCategory('Provider Agreement');
      }

      this.workItemsDialog.show();
    }
    else if (args.item.id === 'completedTasksToggle') {
      try {
        args.cancel = true;
        // Disable both toggle buttons
        this.updateToolbarButtonState('completedTasksToggle', true);
        this.updateToolbarButtonState('providerAgreementToggle', true);

        this.showCompletedTasks = !this.showCompletedTasks;
        const newText = this.showCompletedTasks ? 'Active Tasks' : 'Completed Tasks';
        this.updateToolbarButtonState('completedTasksToggle', true, newText);

        args.item.cssClass = this.showCompletedTasks ? 'e-active' : '';

        if (this.workItemsGrid?.grid) {
          const data = await this.getData();
          this.workItemsGrid.grid.setProperties({
            dataSource: data
          });
        }
      } catch (error) {
        this.showCompletedTasks = !this.showCompletedTasks;
        this.handleError(error, {
          context: 'WorkItemsGrid.customToolbarClick',
          userMessage: 'Failed to toggle completed tasks view'
        });
      } finally {
        // Re-enable both buttons
        this.updateToolbarButtonState('completedTasksToggle', false,
          this.showCompletedTasks ? 'Active Tasks' : 'Completed Tasks');
        this.updateToolbarButtonState('providerAgreementToggle', false);
      }
    }
    else if (args.item.id === 'providerAgreementToggle') {
      try {
        args.cancel = true;
        // Disable both toggle buttons
        this.updateToolbarButtonState('completedTasksToggle', true);
        this.updateToolbarButtonState('providerAgreementToggle', true);

        this.showProviderAgreementTasks = !this.showProviderAgreementTasks;
        const newText = this.showProviderAgreementTasks ? 'File Tasks' : 'Provider Agreement Tasks';
        this.updateToolbarButtonState('providerAgreementToggle', true, newText);

        args.item.cssClass = this.showProviderAgreementTasks ? 'e-active' : '';

        if (this.workItemsGrid?.grid) {
          const data = await this.getData();
          this.workItemsGrid.grid.setProperties({
            dataSource: data
          });
        }
      } catch (error) {
        this.showProviderAgreementTasks = !this.showProviderAgreementTasks;
        this.handleError(error, {
          context: 'WorkItemsGrid.customToolbarClick',
          userMessage: 'Failed to toggle provider agreements view'
        });
      } finally {
        // Re-enable both buttons
        this.updateToolbarButtonState('completedTasksToggle', false);
        this.updateToolbarButtonState('providerAgreementToggle', false,
          this.showProviderAgreementTasks ? 'File Tasks' : 'Provider Agreement Tasks');
      }
    }
    else if (args.item.id === 'BulkEdit' && this.selectedWorkItems.length > 0) {
      this.bulkEditWorkItemsComponent.show();
    }
  }

  async onCommandClick(args: any): Promise<void> {
    const commandArgs = args as CommandClickEventArgs;
    if (!commandArgs.commandColumn || !commandArgs.rowData) return;

    if (commandArgs.commandColumn.type === 'Edit') {
      commandArgs.cancel = true;
      this.workItemsDialog.header = 'Edit Task';
      this.selectedWorkItem.set(undefined);  // Clear any previous data
      this.workItemsDialog.show();

      try {
        const workItemId = (commandArgs.rowData as any).Id;
        const workItem = await this.workItemsService.getWorkItemDetails(workItemId);
        this.selectedWorkItem.set(workItem);
      } catch (error) {
        this.handleError(error, {
          context: 'WorkItemsGrid.onCommandClick',
          userMessage: 'Failed to fetch work item details'
        });
        this.workItemsDialog.hide();
      }
    } else if (commandArgs.commandColumn.type === 'Delete') {
      commandArgs.cancel = true;
      this.taskToComplete = commandArgs.rowData;

      if (this.taskToComplete.Status?.Name?.toLowerCase().includes('complete')) {
        this.alreadyCompletedDialog.show();
      } else {
        this.completeTaskDialog.show();
      }
    }
  }

  async submitWorkItemForm(): Promise<void> {
    const result = await this.workItemFormComponent.onSubmit();

    if (result) {
        this.workItemsDialog.hide();

        // Clear form data consistently with cancel
        this.selectedWorkItem.set(undefined);  // Clear selected work item
        this.workItemFormComponent?.workItemForm.reset();  // Reset form

        // Reset case file selection
        if (this.workItemFormComponent) {
            this.workItemFormComponent.selectedCaseFiles = [];
            this.workItemFormComponent.workItemForm.patchValue({
                caseFileIds: []
            });
        }

        // Get fresh data with current page settings and ordering rules
        const data = await this.getData({
            skip: ((this.workItemsGrid?.grid?.pageSettings?.currentPage || 1) - 1) * (this.workItemsGrid?.grid?.pageSettings?.pageSize || 10),
            take: this.workItemsGrid?.grid?.pageSettings?.pageSize || 10
        });

        // Update grid while maintaining current page settings
        if (this.workItemsGrid?.grid) {
            const currentPageSettings = {
                pageSize: this.workItemsGrid.grid.pageSettings.pageSize,
                pageSizes: this.workItemsGrid.grid.pageSettings.pageSizes,
                currentPage: this.workItemsGrid.grid.pageSettings.currentPage
            };

            this.workItemsGrid.grid.setProperties({
                dataSource: data,
                pageSettings: currentPageSettings
            });
        }

        const action = result.Id ? 'updated' : 'created';
        this.notify(`Task #${result.Id} ${action} successfully`);
    }
  }

  cancelWorkItemForm(): void {
    this.selectedWorkItem.set(undefined);  // Clear selected work item
    this.workItemFormComponent?.workItemForm.reset();  // Reset form

    // Reset case file selection
    if (this.workItemFormComponent) {
      this.workItemFormComponent.selectedCaseFiles = [];
      this.workItemFormComponent.workItemForm.patchValue({
        caseFileIds: []
      });
    }

    this.workItemsDialog.hide();
  }

  onWorkItemClick(data: WorkItem): void {
    this.router.navigate([`/work-item-detail/${data.Id}`]);
  }

  async onActionComplete(args: any): Promise<void> {
    try {
      if (args.requestType === 'save') {
        await this.handleSave(args.data);
        this.notify('Work item saved successfully', NotificationSeverity.Info);
      }
    } catch (error) {
      this.handleError(error, {
        context: 'WorkItemsGrid.onActionComplete',
        userMessage: 'Failed to save work item'
      });
    }
  }

  private async handleSave(data: any) {
    try {
      await this.workItemsService.save(data);
    } catch (error) {
      this.handleError(error, {
        context: 'WorkItemsGrid.handleSave',
        userMessage: 'Failed to save work item'
      });
      throw error;
    }
  }

  onSelectionChange(): void {
    this.selectedWorkItems = this.workItemsGrid.grid.getSelectedRecords() as WorkItem[];
    const bulkEditButton = this.workItemsGrid.grid.toolbarModule.getToolbar().querySelector('#bulk-edit');
    if (bulkEditButton) {
      (bulkEditButton as HTMLElement).style.display = this.selectedWorkItems.length > 0 ? 'block' : 'none';
    }
  }

  async onBulkEditComplete(success: boolean): Promise<void> {
    if (success) {
      // Refresh grid data
      const data = await this.getData();
      this.workItemsGrid.grid.dataSource = data;
      this.workItemsGrid.grid.refresh();

      // Reset selection
      this.selectedRecords = [];
      this.selectedWorkItems = [];
      this.updateToolbar();
    }
  }

  onRowSelected(): void {
    this.selectedRecords = this.workItemsGrid.grid.getSelectedRecords();
    this.selectedWorkItems = this.selectedRecords as WorkItem[];
    this.updateToolbar();
  }

  onRowDeselected = this.onRowSelected;  // Same logic for deselection

  getCaseFileNumbers(xrefs: any[]): string {
    if (!xrefs?.length) return '';
    return xrefs
      .filter(x => x?.CaseFile?.FileNumber)
      .map(x => x.CaseFile.FileNumber)
      .join(', ');
  }

  onCaseFileClick(caseFile: any) {
    const openFiles = this.user.getLocalUserPreference('openFiles') ?? [];

    if (caseFile.FileNumber) {
      const foundFile = openFiles.find((obj: any) => obj['id'] === caseFile.FileNumber) as any;

      if (!foundFile) {
        openFiles.push({
          id: caseFile.FileNumber,
          name: `${caseFile.Patient?.Firstname} ${caseFile.Patient?.Lastname}`
        });
        this.user.saveLocalUserPreference('openFiles', openFiles);
      }

      this.router.navigate(['/case-files/hub'], {
        queryParams: { fileNumber: caseFile.FileNumber }
      });
    } else {
      this.notify('No File Number found.');
    }
  }

  // Add updateToolbar method
  updateToolbar() {
    if (this.selectedRecords && this.selectedRecords.length) {
      const length = this.selectedRecords.length;

      if (length === 1) {
        this.workItemsGrid.grid.toolbar = this.workItemsGridDefaultToolbar;
      } else if (length > 1) {
        const bulkEditButton = { text: 'Bulk Edit', tooltipText: 'Bulk Edit', id: 'BulkEdit', prefixIcon: 'e-properties-1' };
        const buttonExists = this.workItemsGrid.grid.toolbar.some(button => (button as any).id === bulkEditButton.id);
        if (!buttonExists) {
          const newToolbar = [...this.workItemsGridDefaultToolbar, bulkEditButton];
          this.workItemsGrid.grid.toolbar = newToolbar;
        }
      }
    } else {
      this.workItemsGrid.grid.toolbar = this.workItemsGridDefaultToolbar;
    }
  }

  onCreated() {
    this.workItemsGridDefaultToolbar = this.workItemsGrid.gridSettings?.toolbar as any[];
  }

  onDataBound(args: any) {
    this.workItemsGrid.grid = this.workItemsGrid.grid;
  }

  private onQueryCellInfo(args: any): void {
    if (this.inCaseFileContext && args.data?.Priority?.Name) {
      const priority = args.data.Priority.Name;
      const color = this.priorityColors()[priority] || this.priorityColors()['default'];
      args.cell.style.backgroundColor = color;
    }
  }

  // Change to public so template can access it
  public getPriorityColor(props: any): string {
    const priority = props?.Priority?.Name || 'default';
    const color = this.priorityColors()[priority as keyof PriorityColors] || this.priorityColors()['default'];
    return color;
  }

  isOverdue(dueDate: any): boolean {
    if (!dueDate) return false;
    const oneDayInMs = 24 * 60 * 60 * 1000;
    return (new Date().getTime() - new Date(dueDate).getTime()) > oneDayInMs;
  }

  private async completeTask() {
    try {
      const result = await this.workItemsService.completeWorkItem(this.taskToComplete.Id);
      if (result) {
        this.completeTaskDialog.hide();
        const data = await this.getData();
        this.workItemsGrid.grid.dataSource = data;
        this.workItemsGrid.grid.refresh();
        this.notify('Task marked as completed');
      }
    } catch (error) {
      this.handleError(error, {
        context: 'WorkItemsGrid.completeTask',
        userMessage: 'Failed to complete task'
      });
    }
  }

  private async refreshGrid(): Promise<void> {
    this.loadingData.set(true);
    try {
      const data = await this.getData();
      if (this.workItemsGrid?.grid) {
        this.workItemsGrid.grid.dataSource = data;
        this.workItemsGrid.grid.refresh();
      }
    } catch (error) {
      this.handleError(error, {
        context: 'WorkItemsGrid.refreshGrid',
        userMessage: 'Failed to refresh grid'
      });
    } finally {
      this.loadingData.set(false);
    }
  }

  // Add method to fetch and set priority colors
  private async fetchPriorityColors(): Promise<void> {
    try {
      const response = await this.workItemsService.getPriorityColors();

      if (response?.result) {
        const colors: PriorityColors = {
          'default': 'transparent'
        };

        response.result.forEach((priority: PriorityData) => {
          colors[priority.Name] = `${priority.color_hex}CC`; // Add 80% opacity
        });

        this.priorityColors.set(colors);
      }
    } catch (error) {
      this.handleError(error, {
        context: 'WorkItemsGrid.fetchPriorityColors',
        userMessage: 'Failed to fetch work item priorities'
      });
    }
  }

  // Update the method signature to accept possibly undefined input
  getSafeDescription(description: string | null | undefined): SafeHtml {
    if (!description) return this.sanitizer.bypassSecurityTrustHtml('');

    const formattedText = this.formatDescription(description);
    return this.sanitizer.bypassSecurityTrustHtml(formattedText);
  }

  // Update the formatDescription method to handle HTML better
  formatDescription(text: string): string {
    if (!text) return '';
    return text
        .trim()  // Move trim to start to remove leading/trailing whitespace first
        .replace(/\n\n/g, '</p><p>')
        .replace(/\n/g, '<br>')
        .replace(/\s\s/g, '&nbsp;&nbsp;')
        .replace(/^(.+?)$/, '<p>$1</p>')
        .replace(/<p><\/p>/g, '');
  }

  toggleRow(id: number) {
    if (this.expandedRows.has(id)) {
      this.expandedRows.delete(id);
    } else {
      this.expandedRows.add(id);
    }
  }

  isExpanded(id: number): boolean {
    return this.expandedRows.has(id);
  }

  // Add method to show dialog
  showDescription(data: any) {
    this.selectedWorkItem.set(data);
    this.descriptionDialog.show();
  }

  // Add handler for page changes
  onPageChange(args: any) {
    if (args.action && args.action.requestType === 'paging') {
      this.currentPageSettings = {
        skip: args.skip,
        take: args.take
      };

      this.getData(this.currentPageSettings).then(data => {
        this.workItems.dataSource = data;
        if (this.workItemsGrid?.grid) {
          this.workItemsGrid.grid.refresh();
        }
      });
    }
  }

  async onDataStateChange(state: any) {
    if (!state.action) return;

    const pageSettings = {
      skip: state.skip || 0,
      take: state.take || 10
    };

    // Build base query
    const query = new Query()
      .expand([
        'Type($select=Name)',
        'Category($select=Name)',
        'Priority($select=Name)',
        'Status($select=Name)',
        'AssignedToNavigation($select=Name)',
        'XrefWorkItemCaseFiles($select=CaseFileId;$expand=CaseFile($select=FileNumber))',
        'XrefWorkItemProviders($select=ProviderId;$expand=Provider($select=Name))'
      ])
      .skip(pageSettings.skip)
      .take(pageSettings.take)
      .requiresCount();

    // Add completed status filtering
    if (this.showCompletedTasks) {
      query.where('Status/Name', 'equal', 'Completed');
    } else {
      query.where('Status/Name', 'notequal', 'Completed');
    }

    // Handle filtering
    if (state.action.requestType === 'filtering' && state.where) {
      state.where.forEach((filter: any) => {
        filter.predicates?.forEach((pred: any) => {
          if (pred.field === 'XrefWorkItemProviders') {
            query.where(`XrefWorkItemProviders/any(x: contains(x/Provider/Name, '${pred.value}'))`, 'equal', true);
            return;
          }
          // ... rest of the existing filtering logic
        });
      });
    }

    // Handle sorting
    if (state.sorted?.length) {
      state.sorted.forEach((sort: any) => {
        query.sortBy(sort.name, sort.direction.toLowerCase());
      });
    }

    // Add case file context filtering if needed
    if (this.inCaseFileContext) {
      if (this.showProviderAgreementTasks) {
        query.where('XrefWorkItemCaseFiles/any(x: x/CaseFileId eq ' + this.caseFileId + ')', 'equal', true)
          .where('Category/Name', 'equal', 'Provider Agreement');
      } else {
        query.where('XrefWorkItemCaseFiles/any(x: x/CaseFileId eq ' + this.caseFileId + ')', 'equal', true)
          .where('Category/Name', 'notequal', 'Provider Agreement');
      }
    }

    const response = await this.workItemsService.getWorkItemsData(query) as any;

    if (this.workItems) {
      this.workItems.dataSource = {
        result: response.result,
        count: response.count
      };
    }

    // Only refresh for paging
    if (state.action.requestType === 'paging' && this.workItemsGrid) {
      this.workItemsGrid.grid.refresh();
    }
  }

  private initializeGrid(data: any) {
    if (!data || !data.result) {
      console.warn('No data available for grid initialization');
      return;
    }

    const columns: ColumnModel[] = [];

    // Only add checkbox column if NOT in case file context
    if (!this.inCaseFileContext) {
      columns.push({
        type: 'checkbox',
        allowFiltering: false,
        width: 30
      } as ColumnModel);
    }

    // Add case files column if not in case file context
    if (!this.inCaseFileContext) {
      columns.push({
        field: 'XrefWorkItemCaseFiles',
        headerText: 'Case Files',
        width: 200,
        template: this.caseFilesTemplate,
        allowSorting: false,
        allowFiltering: true,
        valueAccessor: (field: string, data: any): string => {
          return data.XrefWorkItemCaseFiles?.map((x: any) => x.CaseFile?.FileNumber).join(', ') || '';
        }
      } as ColumnModel);
    }

    // Add remaining columns
    columns.push(
      { field: 'Id', headerText: '#', width: 60, template: this.nameTemplate } as ColumnModel,
      {
        field: 'Title',
        headerText: 'Title',
        width: 120,
        visible: false  // Hide the Title column
      } as ColumnModel,
      { field: 'Category.Name', headerText: 'Category', width: 100 } as ColumnModel,
      { field: 'Type.Name', headerText: 'Type', width: 100 } as ColumnModel,
      {
        field: 'Description',
        headerText: 'Notes',
        width: 400,
        textAlign: 'Left',
        template: this.descriptionTemplate,
        allowHtml: true,
        wrapMode: 'Content'
      } as ColumnModel,
      {
        field: 'XrefWorkItemProviders',
        headerText: 'Provider',
        width: 150,
        visible: true,
        template: this.providerTemplate,
        valueAccessor: (field: string, data: any): string => {
          return data.XrefWorkItemProviders?.[0]?.Provider?.Name || '';
        },
        allowFiltering: true
      } as ColumnModel,
      { field: 'Status.Name', headerText: 'Status', width: 100, template: '' } as ColumnModel,
      { field: 'AssignedToNavigation.Name', headerText: 'Assigned To', width: 120, template: '' } as ColumnModel,
      {
        field: 'DueDate',
        headerText: 'Due Date',
        width: 100,
        type: 'date',
        format: 'MM/dd/yyyy',
        template: this.dueDateTemplate
      } as ColumnModel,
      {
        type: 'commands',
        headerText: 'Actions',
        width: 80,
        commands: [
          { type: 'Edit', buttonOption: { iconCss: 'e-icons e-edit', cssClass: 'e-flat' } },
          {
            type: 'Delete',
            title: 'Complete Task',
            buttonOption: {
              cssClass: 'e-flat complete-task',
              iconCss: 'e-icons e-circle-check',
              styles: {
                color: 'rgb(var(--status-good))'
              }
            }
          }
        ]
      } as ColumnModel
    );

    // Modify the toolbar setup
    let toolbarItems: (string | ItemModel)[] = ['Add', 'Search', 'ColumnChooser'];

    // Add the completed tasks toggle button
    toolbarItems.push({
      text: this.showCompletedTasks ? 'Active Tasks' : 'Completed Tasks',
      tooltipText: 'Toggle between Active and Completed Tasks',
      cssClass: this.showCompletedTasks ? 'e-active' : '',
      id: 'completedTasksToggle',
      prefixIcon: 'e-icons e-check'
    } as ItemModel);

    // Add provider agreement toggle if in case file context
    if (this.inCaseFileContext) {
      toolbarItems.push({
        text: this.showProviderAgreementTasks ? 'File Tasks' : 'Provider Agreement Tasks',
        tooltipText: 'Toggle between Provider Agreement and File Tasks',
        cssClass: this.showProviderAgreementTasks ? 'e-active' : '',
        id: 'providerAgreementToggle',
        prefixIcon: 'e-icons e-filter'
      } as ItemModel);
    }

    this.workItems = {
      dataSource: data,
      columns: columns,
      allowPaging: true,
      pageSettings: {
        pageSize: 10,
        pageSizes: true,
        pageCount: 5,
        enableQueryString: true
      },
      allowFiltering: true,
      showColumnChooser: true,
      rowHeight: 100,
      toolbar: toolbarItems,
      selectionSettings: {
        type: 'Multiple',
        mode: 'Row',
        checkboxOnly: true
      },
      toolbarClick: ($event: any) => this.customToolbarClick($event),
      commandClick: ($event: any) => this.onCommandClick($event),
      rowSelected: () => this.onRowSelected(),
      rowDeselected: () => this.onRowDeselected(),
      created: () => this.onCreated(),
      dataBound: ($event: any) => this.onDataBound($event),
      queryCellInfo: this.onQueryCellInfo.bind(this),
      dataStateChange: ($event: any) => this.onDataStateChange($event),
    };
  }

  private updateToolbarButtonState(buttonId: string, disabled: boolean, text?: string) {
    if (this.workItemsGrid?.grid) {
      // Get current toolbar
      const currentToolbar = [...this.workItemsGrid.grid.toolbar];

      // Find and update the target item
      const itemIndex = currentToolbar.findIndex(item =>
        typeof item === 'object' && (item as ItemModel).id === buttonId
      );

      if (itemIndex !== -1) {
        const updatedItem = {
          ...currentToolbar[itemIndex] as ItemModel,
          disabled: disabled,
          text: text || (currentToolbar[itemIndex] as ItemModel).text // Preserve text if not provided
        };
        currentToolbar[itemIndex] = updatedItem;

        // Update the entire toolbar
        this.workItemsGrid.grid.toolbar = currentToolbar;
      }
    }
  }
}
