import { Injectable } from '@angular/core';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { ApiService } from '@services/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(
    private api: ApiService,
  ) { }

  public sendEmail(emailData: any) {
    this.api.basicPut('/api' + APIEndpoints.Email + '/send', emailData).then(response => {
      console.log(response)
    }).catch(error => {
      console.log(error)
    });

  }
}
