import { getEndpointPlugin } from "@smithy/middleware-endpoint";
import { getSerdePlugin } from "@smithy/middleware-serde";
import { Command as $Command } from "@smithy/smithy-client";
import { commonParams } from "../endpoint/EndpointParameters";
import { StartLiveTailResponseFilterSensitiveLog } from "../models/models_0";
import { de_StartLiveTailCommand, se_StartLiveTailCommand } from "../protocols/Aws_json1_1";
export { $Command };
export class StartLiveTailCommand extends $Command.classBuilder().ep(commonParams).m(function (Command, cs, config, o) {
  return [getSerdePlugin(config, this.serialize, this.deserialize), getEndpointPlugin(config, Command.getEndpointParameterInstructions())];
}).s("Logs_20140328", "StartLiveTail", {
  eventStream: {
    output: true
  }
}).n("CloudWatchLogsClient", "StartLiveTailCommand").f(void 0, StartLiveTailResponseFilterSensitiveLog).ser(se_StartLiveTailCommand).de(de_StartLiveTailCommand).build() {}