<div id="financial-info">

  
  
  <div class="financial-container">

    @if(isLoading()) {

      <loading-overlay [loading]="isLoading"></loading-overlay>

    } @else {

      @if(financialPerformanceData && !isReductionPage) {
        <div class="performance-data d-flex flex-row flex-wrap">
          <div class="item d-flex flex-nowrap"><label>Total Billed Cost: </label><span>${{financialPerformanceData.TotalBilledCost}}</span></div>
          <div class="item d-flex flex-nowrap"><label>Total Payments: </label><span>${{financialPerformanceData.TotalPaymentReceived}}</span></div>
          <div class="item d-flex flex-nowrap"><label>Settlement Cost: </label><span>${{financialPerformanceData.ActualSettlementCost}}</span></div>
          <div class="item d-flex flex-nowrap"><label>Balance Due: </label><span>${{financialPerformanceData.BalanceDue}}</span></div>
          <div class="item d-flex flex-nowrap"><label>Authorized Amount: </label><span>${{financialPerformanceData.AuthorizedAmount}}</span></div>
          <div class="item d-flex flex-nowrap"><label>Days Open: </label><span>{{financialPerformanceData.DaysOpen}}</span></div>
          <div class="item d-flex flex-nowrap"><label>Settlement Value: </label><span>${{financialPerformanceData.FullSettlementValue}}</span></div>
          <div class="item d-flex flex-nowrap"><label>Profit: </label><span>${{financialPerformanceData.Profit}}</span></div>
          <div class="item d-flex flex-nowrap"><label>Non-SOCAL Invoices: </label><span>{{financialPerformanceData.NonSOCALInvoices ? '$' + financialPerformanceData.NonSOCALInvoices : 'Unknonwn'}}</span></div>
          <div class="item d-flex flex-nowrap"><label>ROIC: </label><span>{{financialPerformanceData.ROIC}}</span></div>
          <div class="item d-flex flex-nowrap"><label>XIRR: </label><span>{{financialPerformanceData.XIRR}}</span></div>
          <div class="item d-flex flex-nowrap"><label>Final Balance Generated?: </label><span>{{financialPerformanceData.FinalBalanceDueGenerated}}</span></div>
          <div class="item item-reduction d-flex flex-nowrap">
            <div class="row">
              <div class="col-6 d-flex">
                <label>Reduction: </label><span>${{financialPerformanceData.CourtesyReduction}}</span>
              </div>
              <div class="col-6 d-flex flex-row flex-nowrap justify-content-around">
                <label>(or %): </label><span>{{financialPerformanceData.CourtesyReductionPercent}}</span>
              </div>
            </div>
          </div>
          <div class="item d-flex flex-nowrap"><label>Final FSV: </label><span>${{financialPerformanceData.FinalFSV}}</span></div>
        </div>
      }
    }
    
    @if(financialData && isReductionPage){
    <div class="performance-data d-flex flex-row flex-wrap">
      <div class="item d-flex">
        <div class="col-6"><label>Total Billed Cost: </label>${{financialData.totalBilledCost}}</div>
        <div class="col-6"><label>Total Payments Recieved: </label>${{financialData.totalPaymentsReceived}}</div>
      </div>
      <div class="item d-flex">
        <div class="col-6"><label>Settlement Cost: </label>${{financialData.actualSettlementCost}}</div>
        <div class="col-6"><label>Authorized Amount: </label>${{financialData.amountAuthorized}}</div>
      </div>
      <div class="item d-flex">
        <div class="col-6"><label>Full Settlement Value: </label>${{financialData.fullSettlementValue}}</div>
        <div class="col-6"><label>Final FSV: </label>${{financialData.finalFsv}}</div>
      </div>
      <div class="item d-flex">
        <div class="col-6"><label>Total Due Provider: </label>${{balanceDue?.totalDueProvider}}</div>
        <div class="col-6"><label>Amount Billed: </label>${{balanceDue?.amountBilled}}</div>
      </div>
      <div class="item d-flex">
        <div class="col-6"><label>Days Open: </label>{{financialData.daysOpen}} days</div>
        <div class="col-6"><label>Profit: </label>${{financialData.profit}}</div>
      </div>
      <div class="item d-flex flex"><label>XIRR: </label>{{financialData.xirr}}</div>
      <div class="col-12">
        <ejs-numerictextbox 
          [(ngModel)]="formData.roic"
          [min]="0"
          format='n2'
          placeholder="ROIC"
          floatLabelType="Auto"
          [enabled]="false"
        />
      </div>
    </div> 
    @if(isReductionPage && parentSelectedOption === 'totalCourtesyReduction') {
      <div class="form-row multi">
        <div class="form-column">
          <ejs-numerictextbox 
            [(ngModel)]="formData.reductionAmount"
            (ngModelChange)="onReductionAmountChange()"
            [min]="0"
            format='c2'
            placeholder="Reduction Amount"
            floatLabelType="Auto"
          />
        </div>
        <br/>
        OR
        <div class="form-column">
          <ejs-numerictextbox 
            [(ngModel)]="formData.reductionPct"
            (ngModelChange)="onReductionPercentChange()"
            [min]="0"
            format='p2'
            step='0.01'
            placeholder="Reduction %"
            floatLabelType="Auto"
          />
        </div>
      </div>
    }
  }
  </div>
</div>