// Angular
import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// 3rd Party
import { DataManager, ODataV4Adaptor, Query } from '@syncfusion/ej2-data';
import { Fetch } from '@syncfusion/ej2-base';

// Internal
import config from '@app/config';
import { CognitoService } from '@app/shared/services/auth/cognito.service';
import { ErrorHandlingService } from '@core/error/error.service';
import { ApiDeduplicationService } from './api-dedupe.service';

export interface BatchData {
  action: string;
  Changed: any[];
  Added: any[];
  Deleted: any[];
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  public ODATA_BASE_URL: string = config.backendUrl + 'odata';
  protected readonly cognito = inject(CognitoService);
  private readonly errorHandling = inject(ErrorHandlingService);
  private readonly dedupe = inject(ApiDeduplicationService);
  // Add cache for frequently accessed resources
  private requestCache: Map<string, any> = new Map();
  private cacheTTL: number = 300000; // 5 minutes cache lifetime in milliseconds

  constructor(private http: HttpClient) {}

  /**
   * basicFetch
   * @description This method will build the request object for the API calls for the rest of the methods in this service
   * @param {string} url
   * @memberof ApiService
   * @returns Promise - fetch() returns a promise
   */
  async basicFetch(url: string, hasOdata = true) {
    const handleBaseUrl = hasOdata ? this.ODATA_BASE_URL : config.backendUrl;
    const requestUrl = handleBaseUrl + url;

    // Use deduplication for GET request
    return this.dedupe.getOrFetchData(`GET:${requestUrl}`, async () => {
      return fetch(requestUrl, { method: 'GET', headers: this.getAuthHeaders() }).then(res => res.json());
    });
  }

  /**
   * basicPatch
   * @description Builds a basic patch request that accepts url and data
   * @param {string} url
   * @param data
   * @returns promise
   */
  basicPatch(url: string, data: any, hasOdata = true) {
    const fetchURL = async () => {
      return await fetch(hasOdata ? this.ODATA_BASE_URL + url : config.backendUrl + url, {
        method: 'PATCH',
        headers: this.getAuthHeaders(),
        body: JSON.stringify(data),
      }).catch(error => {
        console.log(error);
        throw error;
      });
    };
    return fetchURL();
  }

  basicPost(url: string, data: any, hasOdata = true) {
    const fetchURL = async () => {
      return await fetch(hasOdata ? this.ODATA_BASE_URL + url : config.backendUrl + url, {
        method: 'POST',
        headers: this.getAuthHeaders(),
        body: JSON.stringify(data),
      }).catch(error => {
        console.log(error);
        throw error;
      });
    };
    return fetchURL();
  }

  /**
   * basicPut
   * @description Builds a basic put request that accepts url and data
   * @param {string} url
   * @param data
   * @returns promise
   */
  basicPut(url: string, data: any) {
    const fetchURL = async () => {
      return await fetch(config.backendUrl + url, {
        method: 'PUT',
        headers: this.getAuthHeaders(),
        body: JSON.stringify(data),
      }).catch(error => {
        console.log(error);
        throw error;
      });
    };
    return fetchURL();
  }

  /**
   * GET
   * @description Fetches data from api endpoint into a DataManager
   * @param {string} url
   * @memberof ApiService
   * @returns DataManager - builds fetched data through ODataV4Adaptor()
   */
  getOdata(endpoint: string, useCache: boolean = true): DataManager {
    try {
      // Create the dataManager - keeping all existing functionality
      const dataManager = new DataManager({
        url: this.ODATA_BASE_URL + endpoint,
        adaptor: new VarentODataAdaptor(this.getAuthHeaders()['Authorization'].split(' ')[1]),
        crossDomain: true,
        headers: [this.getAuthHeaders()],
        beforeSend: (dm: DataManager, request: any) => {
          // For cacheable endpoints, check if we have the response cached
          if (
            useCache &&
            (endpoint.includes('Lawfirms') || endpoint.includes('FileGroups') || endpoint.includes('addresstypes'))
          ) {
            const cacheKey = request.url;
            const cachedData = this.getCachedResponse(cacheKey);

            if (cachedData) {
              // Return the cached data
              return {
                url: request.url,
                result: cachedData,
                cancel: true,
              };
            }

            // Add a hook to cache the response after it's received
            if (request.onSuccess) {
              const originalSuccess = request.onSuccess;
              request.onSuccess = (data: any) => {
                // Cache the response data
                this.setCachedResponse(cacheKey, data);

                // Call the original success handler
                originalSuccess(data);
              };
            }
          }
          return request;
        },
      } as any);

      // Override executeQuery to add deduplication
      const originalExecuteQuery = dataManager.executeQuery.bind(dataManager);
      dataManager.executeQuery = async (query: any) => {
        const queryString = query ? JSON.stringify(query) : '';
        const cacheKey = `OData:${endpoint}:${queryString}`;

        return this.dedupe.getOrFetchData(cacheKey, () => {
          return originalExecuteQuery(query);
        });
      };

      return dataManager;
    } catch (error: any) {
      // Keep original error handling
      const apiError = new Error(`API Error: ${error.message}`);
      apiError.name = 'ApiError';
      apiError.cause = error;
      apiError.stack = error.stack;
      Object.assign(apiError, {
        endpoint,
        url: this.ODATA_BASE_URL + endpoint,
        method: 'GET',
        timestamp: new Date().toISOString(),
        originalError: error,
      });
      throw apiError;
    }
  }

  private getErrorMessage(error: any): string {
    if (error.error?.message) {
      return error.error.message;
    }
    if (error.message) {
      return error.message;
    }
    if (typeof error.error === 'string') {
      return error.error;
    }
    if (error.status === 0) {
      return 'Unable to connect to server';
    }
    return 'An unexpected error occurred';
  }

  private async executeWithRetry<T>(action: () => Promise<T>, retries = 3, timeout = 30000): Promise<T> {
    for (let i = 0; i < retries; i++) {
      try {
        const timeoutPromise = new Promise((_, reject) =>
          setTimeout(() => reject(new Error('Request timed out')), timeout)
        );
        return (await Promise.race([action(), timeoutPromise])) as T;
      } catch (error) {
        if (i === retries - 1) throw error;
        await new Promise(resolve => setTimeout(resolve, 1000 * (i + 1)));
      }
    }
    throw new Error('Max retries reached');
  }

  /**
   * PATCH
   * @description Saves any updated server to odata endpoit
   * @param {string} url
   * @memberof ApiService
   */
  patchOdata(url: string, data: any) {
    return fetch(this.ODATA_BASE_URL + url, {
      method: 'PATCH',
      headers: this.getAuthHeaders(),
      body: JSON.stringify(data),
    });
  }

  /**
   * POST
   * @description Creates a new record in the odata endpoint
   * @param {string} url
   * @memberof ApiService
   */
  postOdata(url: string, data: any) {
    return fetch(this.ODATA_BASE_URL + url, {
      method: 'POST',
      headers: this.getAuthHeaders(),
      body: JSON.stringify(data),
    });
  }

  /**
   * DELETE
   * @description Deletes any updated server to odata endpoit
   * @param {string} url
   * @memberof ApiService
   */
  deleteOdata(url: string) {
    return fetch(this.ODATA_BASE_URL + url, { method: 'DELETE', headers: this.getAuthHeaders() });
  }

  /**
   * Fetch for all method types
   * @param url
   * @param method
   * @param body
   * @param headers
   * @returns fetch()
   */
  async fetchRequest(url: string, method?: string, body?: any, headers?: any, hasOdata: boolean = false) {
    const requestUrl = `${hasOdata ? this.ODATA_BASE_URL : config.backendUrl}${url}`;
    const requestMethod = method ?? 'GET';
    const requestHeader = headers ?? this.getAuthHeaders();

    try {
      // Apply deduplication only for GET requests - minimal changes to original logic
      if (requestMethod === 'GET') {
        return this.dedupe.getOrFetchData(`${requestMethod}:${requestUrl}`, async () => {
          const response = await fetch(requestUrl, {
            method: requestMethod,
            headers: requestHeader,
            body: body ? JSON.stringify(body) : undefined,
          });

          // Leave original error handling intact
          if (!response.ok) {
            const errorResponse = response.clone();
            const errorText = await errorResponse.text();
            let errorMessage = errorText;

            try {
              const errorJson = JSON.parse(errorText);
              errorMessage = errorJson.message || errorJson.error?.message || errorJson.error || errorText;
            } catch {
              // If JSON parsing fails, use the text as is
              errorMessage = errorText;
            }

            // Log error details
            this.errorHandling.handleError(
              {
                status: response.status,
                statusText: response.statusText,
                url: requestUrl,
                method: requestMethod,
                message: errorMessage,
                body: body,
              },
              'ApiService.fetchRequest'
            );

            throw new Error(errorMessage);
          }

          // Only try to parse JSON if we're expecting it
          if (response.headers.get('content-type')?.includes('application/json')) {
            return await response.json();
          }

          return response;
        });
      } else {
        // Non-GET requests proceed with original logic (no deduplication)
        const response = await fetch(requestUrl, {
          method: requestMethod,
          headers: requestHeader,
          body: body ? JSON.stringify(body) : undefined,
        });

        if (!response.ok) {
          const errorResponse = response.clone();
          const errorText = await errorResponse.text();
          let errorMessage = errorText;

          try {
            const errorJson = JSON.parse(errorText);
            errorMessage = errorJson.message || errorJson.error?.message || errorJson.error || errorText;
          } catch {
            // If JSON parsing fails, use the text as is
            errorMessage = errorText;
          }

          // Log error details
          this.errorHandling.handleError(
            {
              status: response.status,
              statusText: response.statusText,
              url: requestUrl,
              method: requestMethod,
              message: errorMessage,
              body: body,
            },
            'ApiService.fetchRequest'
          );

          throw new Error(errorMessage);
        }

        // Only try to parse JSON if we're expecting it
        if (response.headers.get('content-type')?.includes('application/json')) {
          return await response.json();
        }

        return response;
      }
    } catch (error) {
      console.error('API Request Error:', error);
      throw error;
    }
  }

  public async basicDelete(url: string, body?: any): Promise<Response> {
    const options: RequestInit = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    };

    if (body) {
      options.body = JSON.stringify(body);
    }

    const response = await fetch(`${this.ODATA_BASE_URL}${url}`, options);
    return response;
  }

  protected getAuthHeaders(): { [key: string]: string } {
    return {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.cognito.getUserIdToken()}`,
    };
  }

  // Add method to check and retrieve from cache
  private getCachedResponse(cacheKey: string): any {
    if (this.requestCache.has(cacheKey)) {
      const cachedData = this.requestCache.get(cacheKey);
      // Check if cache is still valid
      if (cachedData && Date.now() - cachedData.timestamp < this.cacheTTL) {
        console.log(`Using cached data for: ${cacheKey}`);
        return cachedData.data;
      } else {
        // Cache expired, remove it
        this.requestCache.delete(cacheKey);
      }
    }
    return null;
  }

  // Add method to store in cache
  private setCachedResponse(cacheKey: string, data: any): void {
    this.requestCache.set(cacheKey, {
      data: data,
      timestamp: Date.now(),
    });
    console.log(`Cached data for: ${cacheKey}`);
  }
}

export class VarentODataAdaptor extends ODataV4Adaptor {
  private readonly token: string;

  constructor(token: string) {
    super();
    this.token = token;
  }

  public override beforeSend(dm: DataManager, request: Request, settings: Fetch): void {
    request.headers.set('Authorization', `Bearer ${this.token}`);
    request.headers.set('Content-Type', 'application/json');
    super.beforeSend(dm, request, settings);
  }
}

interface ODataResponse<T> {
  result: T[];
  count?: number;
}
