import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'form-field',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule],
  template: `
    <div class="cc-input-container">
      <span class="icon">
        <fa-icon [icon]="icon" />
      </span>
      <ng-content></ng-content>
      <ng-container *ngIf="fieldName && form.get(fieldName)?.invalid && form.get(fieldName)?.touched">
          <span [class]="formClasses.error">{{getErrorMessage(fieldName)}}</span>
      </ng-container>
    </div>
  `
})
export class FormFieldComponent {
  @Input() icon: any;
  @Input() label: string;
  @Input() required = false;
  @Input() control!: AbstractControl;
  @Input() fieldName?: string;
  @Input() form!: FormGroup;
  @Input() formClasses: any;

  getErrorMessage(fieldName: string): string {
    const control = this.form.get(fieldName);
    if (!control?.errors) return '';
    return control.errors['required'] ? 'This field is required' : 'Invalid value';
  }
}   