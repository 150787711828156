// Angular
import { Component, OnInit, ViewEncapsulation, effect } from '@angular/core';
import { CommonModule } from '@angular/common';

// 3rd Party
import { Browser } from '@syncfusion/ej2-base';
import { DashboardLayoutModule } from '@syncfusion/ej2-angular-layouts';

// Services
import { ApiService } from '@services/api/api.service';
import { UserPreferencesService } from '@services/user/user-preferences.service';
import { DashboardQueriesService } from '@root/src/app/shared/services/dashboard-queries/dashboard-queries.service';
import { DashboardCardsService } from '@root/src/app/shared/services/dashboard-cards/dashboard-cards.service';
import { WorkItemsService } from '@features/work-items/services/work-items.service';

// Models & Constants
import { APIEndpoints } from '@models/api/Endpoints';
import { CustomCardModel } from '@models/components/card.model';
import { CardComponent } from '@ui/card/card.component';

interface DashboardState {
  activeFilter: 'all' | 'upcoming' | 'overdue';
}

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [DashboardLayoutModule, CardComponent, CommonModule],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class DashboardComponent implements OnInit {
  constructor(
    private api: ApiService,
    private user: UserPreferencesService,
    private queries: DashboardQueriesService,
    private cardService: DashboardCardsService,
    private workItems: WorkItemsService
  ) {
    this.today.setHours(0, 0, 0, 0);
    
    // Move initialization logic to constructor effect
    effect(() => {
      const userId = this.user.userId();
      if (userId) {
        this.cards = this.initializeCards();
      }
    });
  }

  // Lifecycle hook - initializes dashboard
  async ngOnInit(): Promise<void> {
    await this.user.initializeUserId();
  }

  // Responsive layout configuration
  readonly cellSpacing: number[] = [20, 15];
  readonly columns: number = Browser.isDevice ? 1 : 3;
  readonly cellAspectRatio: number = Browser.isDevice ? 1 : 1.5;

  // Core state management
  private readonly today = new Date();

  private state: DashboardState = {
    activeFilter: 'all'
  };

  cards: CustomCardModel[];

  // Replace the card creation methods with service calls
  private initializeCards(): CustomCardModel[] {
    return [
      this.cardService.createAppointmentsCard(),
      this.cardService.createWorkItemsCard((args) => this.handleWorkItemsToolbarClick(args)),
      this.cardService.createCaseFilesCard(),
      this.cardService.createRoicOverviewCard(),
    ];
  }

  private handleWorkItemsToolbarClick(args: any): void {
    const workItemsCard = this.cards.find((card) => card.cardId === 'work-items');
    if (!workItemsCard?.gridSettings) return;
    const userId = this.user.userId();
    if (!userId) return;

    let isCalendarView = false;
    try {
      isCalendarView = workItemsCard.showCalendarView?.() ?? false;
      console.log('View type:', { isCalendarView });
    } catch (error) {
      console.error('Error accessing calendar view signal:', error);
      isCalendarView = false;
    }

    const filterMap = {
      All: () => {
        const query = isCalendarView ? this.workItems.getBaseQuery(true) : this.workItems.getTodaysTasksQuery();
        console.log('Selected query:', query);
        return query;
      },
      Overdue: () => isCalendarView ? this.workItems.getOverdueWorkItemsQuery(true) : this.workItems.getTodaysTasksQuery(),
      Upcoming: () => isCalendarView ? this.workItems.getUpcomingWorkItemsQuery(true) : this.workItems.getTodaysTasksQuery(),
    };

    // Extract the filter type (All, Overdue, or Upcoming) from the button text
    const buttonText = args.item.text;
    const filterType =
      buttonText === 'All'
        ? 'All'
        : buttonText.includes('Overdue')
          ? 'Overdue'
          : buttonText.includes('Upcoming')
            ? 'Upcoming'
            : null;

    const queryFunction = filterMap[filterType as keyof typeof filterMap];
    if (queryFunction) {
      workItemsCard.gridSettings.query = queryFunction();
      this.state.activeFilter = this.getFilterTypeFromText(buttonText);
    }
  }

  setActiveWorkItemFilter(filter: 'all' | 'overdue' | 'upcoming'): void {
    this.state.activeFilter = filter;
    const workItemsCard = this.cards.find((card) => card.cardId === 'work-items');
    if (!workItemsCard?.gridSettings) return;
    const userId = this.user.userId();
    if (!userId) return;

    // Safely check calendar view with error handling
    let isCalendarView = false;
    try {
      isCalendarView = workItemsCard.showCalendarView?.() ?? false;
    } catch (error) {
      console.error('Error accessing calendar view signal:', error);
      // Default to grid view on error
      isCalendarView = false;
    }

    const filterMap = {
      all: () => isCalendarView ? this.workItems.getBaseQuery() : this.workItems.getTodaysTasksQuery(),
      overdue: () => this.workItems.getOverdueWorkItemsQuery(),
      upcoming: () => this.workItems.getUpcomingWorkItemsQuery(),
    };

    workItemsCard.gridSettings.query = filterMap[filter]();
  }

  getWorkItemCount(filter: 'overdue' | 'upcoming'): number {
    const workItemsCard = this.cards.find((card) => card.cardId === 'work-items');
    if (!workItemsCard?.gridSettings?.dataSource) return 0;

    const items = workItemsCard.gridSettings.dataSource as any[];
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return items.filter(item => {
      const dueDate = new Date(item.DueDate);
      if (filter === 'overdue') {
        return dueDate < today && item.Status !== 'Completed';
      } else {
        return dueDate >= today && item.Status !== 'Completed';
      }
    }).length;
  }

  private getFilterTypeFromText(text: string): 'all' | 'upcoming' | 'overdue' {
    if (text.includes('Upcoming')) return 'upcoming';
    if (text.includes('Overdue')) return 'overdue';
    return 'all';
  }
}
