<div id="case-file-appointments">

  <ng-container *ngIf="list.length > 0; else noAppointments">
    <ejs-listview #appointmentsList
      [dataSource]="list"
      cssClass="appointment-list"
      (select)="onItemSelected($event)">
    </ejs-listview>
  </ng-container>

  <ng-template #noAppointments>
    <div class="appointments-none">
      <p>No appointments found for Case File #{{caseFile()?.FileNumber}}</p>
    </div>
  </ng-template>
  
</div>