// Angular
import { Component, Signal, signal, ViewChild, WritableSignal } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormBuilder } from '@angular/forms';


// 3rd Party
import { GridModel } from '@syncfusion/ej2-grids';
import { Query } from '@syncfusion/ej2-data';
import { DialogAllModule, DialogComponent } from '@syncfusion/ej2-angular-popups';

// Models
import { APIEndpoints } from '@models/api/Endpoints';
import { Patient } from '@models/data-contracts';

// Services
import { ApiService } from '@services/api/api.service';

// Modules
import { LoadingModule } from '@modules/loading.module';

// Components
import { GridTemplateComponent } from '@components/grids/grid-template/grid-template.component';
import { PatientFormComponent } from "../../forms/patient-form/patient-form.component";
import { ToastMessageService } from '@root/src/app/shared/services/toast-message/toast-message.service';

@Component({
  selector: 'patients-grid',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    GridTemplateComponent,
    DialogAllModule,
    LoadingModule,
    PatientFormComponent
],
  templateUrl: './patients-grid.component.html',
  styleUrl: './patients-grid.component.scss'
})
export class PatientsGridComponent {

  constructor(
    private api: ApiService,
    private router: Router,
    private fb: FormBuilder,
    private toast: ToastMessageService
  ) {}

  @ViewChild('nameTemplate', { static: true }) nameTemplate!: string;
  @ViewChild('patientDialog') patientDialog!: DialogComponent;
  @ViewChild('patientsGridComponent') patientsGridComponent!: GridTemplateComponent;
  @ViewChild('patientFormComponent') patientFormComponent!: PatientFormComponent;
  APIEndpoints = APIEndpoints;
  patients: GridModel;
  patientsGrid: GridTemplateComponent;
  patientForm: WritableSignal<any> = signal(undefined);
  patientEndpoint: string;
  loading = signal(true);
  patient = signal(undefined);
  patientDialogButtons: Object[] = [
    { click: this.cancelPatientForm.bind(this), buttonModel: { content: 'Cancel', cssClass: 'e-outline' } },
    { click: this.submitPatientForm.bind(this), buttonModel: { content: 'Submit', isPrimary: true } },
  ];

  ngOnInit() {
    this.patientForm = signal(this.fb.group({
      Id: [''],
      Firstname: [''],
      Lastname: [''],
      Dob: [''],
      Gender: [''],
      Language: [''],
      Email: [''],
      Minor: [false]
    }));
    this.getData().then((data: any) => {
      this.patients = {
        dataSource: data,
        columns: [
          { field: 'Id' },
          { field: 'Firstname', headerText: 'Patient Name', template: this.nameTemplate  },
          { field: 'TotalFiles', headerText: 'Total Files' },
          { field: 'XrefAddressPatients.Address.Address1', headerText: 'Address' },
          { field: 'XrefAddressPatients.Address.City', headerText: 'City' },
          { field: 'XrefAddressPatients.Address.StateNavigation.Name', headerText: 'State' },
          { field: 'XrefAddressPatients.Address.Zip', headerText: 'Zip' },
          { field: 'Dob', headerText: 'Date Of Birth' },
          { field: 'Gender' },
          { field: 'Language' },
          { field: 'Email' },
          { field: 'Minor' },
          { type: 'commands', headerText: 'Actions' }
        ],
        toolbarClick: (args: any) => this.onToolbarClick(args),
        commandClick: (args: any) => this.onCommandClick(args)
      }
    });
  }

  async getData() {
    const endpoint = APIEndpoints.Patients;
    const query = new Query().expand('XrefAddressPatients($expand=Address($expand=StateNavigation)),CaseFiles');

    return this.api.getOdata(endpoint).executeQuery(query).then((res: any) => {
      if (!res || !res.result) throw new Error('Failed to fetch patients.');

      const patientsWithHomeAddress = res.result.map((patient: any) => {
        const homeAddress = patient.XrefAddressPatients?.find((x: any) => x.Address?.AddressType === 2);
        return {
          ...patient,
          XrefAddressPatients: homeAddress ? [homeAddress][0] : [],
          TotalFiles: patient.CaseFiles?.length || 0
        };
      });

      this.loading.set(false);
      return patientsWithHomeAddress;
    });
  }

  onToolbarClick(args: any) {

    if (args.item.text === 'Add') {
      args.cancel = true;
      this.patient.set(undefined);
      this.patientDialog.show();
    }
  }

  onCommandClick(args: any) {
    
    if (args.commandColumn.title === 'Edit') {
      args.cancel = true;
      // this.patient.set(args.rowData);
      this.patientFormComponent.patient.set(args.rowData);
      this.patientDialog.show();
    }
  }

  onPatientNameClick(data: any) {
    this.router.navigate([`/patient-detail/${data.Id}`]);
  }

  async submitPatientForm() {
    try {
      const result = await this.patientFormComponent.onSubmit();
      
      if (result && !(result instanceof Error)) {
        this.patient.set(undefined);
        this.patientDialog.hide();
        this.toast.showSuccess('Patient saved successfully');

        this.getData().then((data) => {
          this.patientsGridComponent.grid.dataSource = data;
        });
      }
    } catch (error) {
      console.error('Error submitting patient:', error);
      this.toast.showError('Failed to save patient');
    }
  }

  cancelPatientForm() {
    this.patientDialog.hide();
  }
}
