import { Component, Input } from '@angular/core';
import { GridModel } from '@syncfusion/ej2-grids';
import { LoadingSpinnerComponent } from '@root/src/app/shared/components/loading-spinner/loading-spinner.component';

@Component({
  selector: 'base-grid',
  standalone: true,
  imports: [LoadingSpinnerComponent],
  templateUrl: './base-grid.component.html',
  styleUrls: ['./base-grid.component.scss']
})
export class BaseGridComponent {
  @Input() loading = false;
  gridSettings!: GridModel;

  protected getBaseTemplate(): string {
    return `
      <div class="grid-container">
        <loading-spinner [loading]="loading" />
        <div [class.hidden]="loading">
          <ng-content></ng-content>
        </div>
      </div>
    `;
  }

  protected getBaseStyles(): string {
    return `
      .grid-container {
        height: 100%;
        width: 100%;
        padding: 1rem;
      }
    `;
  }
} 