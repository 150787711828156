import { Injectable } from '@angular/core';
import { ApiService } from '@services/api/api.service';
import { ProcedureCode } from '@models/data-contracts';
import { APIEndpoints } from '@models/api/Endpoints';

@Injectable({ providedIn: 'root' })
export class ProcedureCodeService {
  constructor(private api: ApiService) {}

  async updateProcedureCode(code: ProcedureCode): Promise<void> {
    await this.api.basicPatch(`${APIEndpoints.ProcedureCodes}(${code.Id})`, code);
  }
} 