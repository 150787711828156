<div id="bulk-edit-invoices">

    <form id="bulk-edit-case-files-form" [formGroup]="bulkEditInvoicesForm" #formDir="ngForm" class="row d-flex flex-row">
        <div class="form-group col-12 col-md-6">
            <label class="custom-template-label">Date Paid</label>
            <ejs-datepicker formControlName="DatePaid"></ejs-datepicker>
        </div>
    
        <div class="form-group col-12 col-md-6">
            <label class="custom-template-label">Payment Method</label>
            <ejs-dropdownlist [dataSource]="paymentMethods" [fields]="{ value: 'Id', id: 'Id', text: 'Description' }" formControlName="PaymentMethod"></ejs-dropdownlist>
        </div>
    
        <div class="form-group col-12 col-md-6">
            <label class="custom-template-label">Payment Status</label>
            <ejs-dropdownlist [dataSource]="paymentStatuses" [fields]="{ value: 'Id', id: 'Id', text: 'Description' }" formControlName="PaymentStatus"></ejs-dropdownlist>
        </div>
    
        <div class="form-group col-12 col-md-6">
            <label class="custom-template-label">Check Number</label>
            <ejs-textbox formControlName="CheckNumber" ></ejs-textbox>
        </div>
    
        <div class="form-group col-12 col-md-6">
            <label class="custom-template-label">Notes</label>
            <ejs-textbox [multiline]="true" formControlName="Notes"></ejs-textbox>
        </div>
    </form>

</div>