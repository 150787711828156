<div id="financial-tab">

	<!-- Dialogs -->
	<ejs-dialog id="generateBalanceStatement" #generateBalanceStatement *ngIf="generateBalanceStatementVisibility"
		header="Generate Balance Statement" [target]="'main'" [showCloseIcon]="true" [isModal]="true"
		[closeOnEscape]="true" [visible]="generateBalanceStatementVisibility" [width]="'90%'"
		(close)="generateBalanceStatementVisibility = false">
		<app-generate-balance-statement [caseFileId]="fileHub.caseFile?.Id ?? 0" />
	</ejs-dialog>
	<ejs-dialog id="viewBalanceStatement" #viewBalanceStatement *ngIf="viewBalanceStatementVisibility"
		header="View Balance Statement" [target]="'main'" [showCloseIcon]="true" [isModal]="true" [closeOnEscape]="true"
		[visible]="viewBalanceStatementVisibility" (close)="viewBalanceStatementVisibility = false" [width]="'90%'">
		<app-view-balance-statement [caseFileId]="fileHub.caseFile?.Id ?? 0" />
	</ejs-dialog>
	<ejs-dialog id="transferInvoice" #transferInvoice *ngIf="transferInvoiceVisibility" header="Transfer Invoice"
		[showCloseIcon]="true" [isModal]="true" [closeOnEscape]="true" [visible]="transferInvoiceVisibility"
		(close)="transferInvoice.hide()" [width]="'90%'">
		<transfer-invoice [selectedInvoices]="selectedInvoices" (submit)="handleSubmitComplete()"></transfer-invoice>
	</ejs-dialog>
	<ejs-dialog id="addNewInvoice" #addNewInvoice
		[header]="editingInvoice() ? 'Edit Invoice' : 'Add Invoice'"
		[target]="'main'"
		[showCloseIcon]="true"
		[isModal]="true"
		[closeOnEscape]="true"
		[visible]="false"
		[width]="'90%'"
		(close)="onDialogClose()">
		<add-invoice-form
			(submit)="onInvoiceSubmit()"
			(warningsChanged)="onInvoiceWarningsChanged($event)"
			(close)="onDialogClose()"
			[invoice]="editingInvoice()">
		</add-invoice-form>
	</ejs-dialog>
	<ejs-dialog id="addDeposit" #addDeposit *ngIf="addDepositVisibility"
		[header]="editingDeposit() ? 'Edit Deposit' : 'Add Deposit'" [showCloseIcon]="true" [isModal]="true"
		[closeOnEscape]="true" [visible]="addDepositVisibility" [width]="600" [target]="'#financial-tab'"
		(close)="handleDialogClose()">
		<ng-template #content>
			<add-deposit-form [caseFileId]="fileHub.caseFile?.Id ?? 0" [deposit]="editingDeposit()"
				(close)="handleDialogClose()" (submit)="handleSubmitComplete()"></add-deposit-form>
		</ng-template>
	</ejs-dialog>

	<ejs-dialog #logsDialog [visible]="logsDialogVisibility" [header]="'Invoice Logs'" [buttons]="logsDialogButtons"
		[target]="'body'" [isModal]="true" [showCloseIcon]="true" [width]="'60%'" [height]="'60vh'"
		(beforeOpen)="beforeOpening($event)">
		<ng-template #content>
			<div class="dialog-content logs-dialog-content">
				<app-audit-logs [logs]="auditLogs" [loading]="auditLogsLoading">
				</app-audit-logs>
			</div>
		</ng-template>
	</ejs-dialog>

	<casefile-invoices-grid
		[fileNumber]="fileHub.caseFile?.Id"
		(addInvoice)="handleAddInvoice()"
		(editInvoice)="handleEditInvoice($event)"
		(viewLogs)="handleLogsCommand($event)">
	</casefile-invoices-grid>

</div>
