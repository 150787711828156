// Angular
import { Component, signal, ViewChild, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

// 3rd Party - Syncfusion
import { GridModel, CommandClickEventArgs } from '@syncfusion/ej2-grids';
import { DialogComponent, DialogAllModule } from '@syncfusion/ej2-angular-popups';
import { Query } from '@syncfusion/ej2-data';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { ApiService } from '@services/api/api.service';
import { AuditLogService } from '@services/audit-logs/audit-log-service';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { AuditLogsComponent } from '@ui/audit-logs/audit-logs.component';
import { LoadingModule } from '@modules/loading.module';
import { GridTemplateComponent } from '@grids/grid-template/grid-template.component';
import { ProviderFormComponent } from '@forms/provider-form/provider-form.component';
import { BulkEditProvidersComponent } from '@forms/bulk-edit-forms/bulk-edit-providers/bulk-edit-providers.component';
import { Provider } from '@models/data-contracts';
import { formatBoolean } from '@root/src/app/utils';
import { providerQuery } from '@root/src/app/shared/queries/provider-queries';

@Component({
  selector: 'providers-grid',
  standalone: true,
  imports: [
    CommonModule,
    DialogAllModule,
    LoadingModule,
    AuditLogsComponent,
    ProviderFormComponent,
    BulkEditProvidersComponent,
    GridTemplateComponent
  ],
  templateUrl: './providers-grid.component.html',
  styleUrl: './providers-grid.component.scss'
})
export class ProvidersGridComponent {
  // Class constants
  private readonly dialogMaxHeight = '85vh';
  private readonly dialogWidth = '90vw';

  // Grid related properties
  @ViewChild('providersGrid') providersGrid!: GridTemplateComponent;
  @ViewChild('nameTemplate', { static: true }) nameTemplate!: string;
  providers: GridModel;
  showActive = true;

  // Provider form related properties
  @ViewChild('providerDialog') providerDialog: DialogComponent;
  @ViewChild('providerFormComponent') providerFormComponent!: ProviderFormComponent;
  providerForm: WritableSignal<any> = signal(undefined);
  selectedProvider: WritableSignal<Provider | undefined> = signal(undefined);
  providerDialogVisibility = false;
  providerDialogButtons: Object[] = [
    { click: this.cancelProviderForm.bind(this), buttonModel: { content: 'Cancel', cssClass: 'e-outline' } },
    { click: this.submitProviderForm.bind(this), buttonModel: { content: 'Submit', isPrimary: true } },
  ];

  // Bulk edit component reference
  @ViewChild('bulkEditProvidersComponent') bulkEditProvidersComponent: BulkEditProvidersComponent;

  // Audit logs related properties
  @ViewChild('logsDialog') logsDialog: any;
  auditLogs: any[] = [];
  auditLogsLoading = false;
  logsDialogVisibility = false;
  
  // Track selected rows count for toolbar
  private selectedRowCount: number = 0;

  readonly logsDialogButtons: Object[] = [
    { click: this.closeLogsDialog.bind(this), buttonModel: { content: 'Close', cssClass: 'e-flat' } }
  ];

  constructor(
    private api: ApiService,
    private auditLogService: AuditLogService,
    private toast: ToastMessageService,
    private router: Router,
  ) { }

  async loadData() {
    const query = new Query()
      .expand(['XrefAddressProviders($expand=Address($expand=StateNavigation))', 'XrefProviderProviderTypes($expand=ProviderType)'])
      .where('IsActive', 'equal', this.showActive);

    const providers = await this.api.getOdata(APIEndpoints.Providers).executeQuery(query).then((res: any) => {
      if (!res || !res.result) throw new Error('Failed to fetch providers.');
      return res.result.map((provider: Provider) => ({
        ...provider,
        XrefAddressProviders: provider.XrefAddressProviders?.sort((a, b) => 
          (a.Address?.City || '').localeCompare(b.Address?.City || '') || 0
        )
      }));
    });
    return providers;
  }

  ngOnInit(): void {
    this.loadData().then((data) => {    
      this.providers = {
      dataSource: data,
      columns: [
        { type: 'checkbox', width: 50 },
        { field: 'Id', headerText: 'ID', width: 70 },
        { field: 'Name', headerText: 'Name', template: this.nameTemplate, width: 100 },
        { field: 'XrefProviderProviderTypes', headerText: 'Provider Types',
          width: 120,
          valueAccessor: (field: any, data: Provider) => {
            return data.XrefProviderProviderTypes?.map(x => x.ProviderType?.Description).filter(Boolean).join(', ') || '' }
        },
        { field: 'Notes', headerText: 'Notes' },
        { field: 'SpanishSpeaking', headerText: 'Spanish Speaking', visible: false },
        { field: 'ReimbursementRate', headerText: 'Purchase Rate', template: '<div>${ReimbursementRate}%</div>', width: 40 },
        { field: 'IsActive', headerText: 'Is Active', width: 40 },
        { 
          field: 'XrefAddressProviders', 
          headerText: 'City',
          width: 60,
          valueAccessor: (field: any, data: Provider) => data.XrefAddressProviders?.[0]?.Address?.City || ''
        },
        { 
          field: 'XrefAddressProviders', 
          headerText: 'State',
          width: 60,
          valueAccessor: (field: any, data: Provider) => data.XrefAddressProviders?.[0]?.Address?.StateNavigation?.Name || ''
        },
        { type: 'commands', headerText: 'Actions',
          width: 130,
          commands: [
            { type: 'Edit', title: 'Edit', buttonOption: { iconCss: 'e-icons e-edit', cssClass: 'e-flat' } },
            { type: 'Delete', title: 'Toggle Active', buttonOption: { cssClass: 'e-flat', iconCss: 'e-icons e-circle-check' } },
            { type: 'None', title: 'Logs', buttonOption: { iconCss: 'e-icons e-description', cssClass: 'e-flat' } }
          ]
        }
      ],
      allowPaging: true,
      pageSettings: { pageSize: 20 },
      allowFiltering: true,
      showColumnChooser: true, 
      toolbarClick: ($event: any) => this.customToolbarClick($event),
      commandClick: ($event: CommandClickEventArgs) => this.onCommandClick($event),
      rowSelected: () => this.onRowSelected(),
      rowDeselected: () => this.onRowDeselected(),
    };
  })
  }


  onRowSelected(): void {
    this.selectedRowCount = this.providersGrid.getSelectedRows().length;
    const currentToolbar = this.providersGrid.grid.toolbar || [];
    
    // Remove bulk edit button if it exists
    const toolbarWithoutBulkEdit = currentToolbar.filter((item: any) => item.id !== 'bulkEditProviders');
    
    // Add bulk edit button if more than one row is selected
    if (this.selectedRowCount > 1) {
      toolbarWithoutBulkEdit.push({ text: 'Bulk Edit', id: 'bulkEditProviders' });
    }
    
    this.providersGrid.grid.toolbar = toolbarWithoutBulkEdit;
  }

  onRowDeselected(): void {
    this.selectedRowCount = this.providersGrid.getSelectedRows().length;
    const currentToolbar = this.providersGrid.grid.toolbar || [];
    
    // Remove bulk edit button if it exists
    const toolbarWithoutBulkEdit = currentToolbar.filter((item: any) => item.id !== 'bulkEditProviders');
    
    // Add bulk edit button if more than one row is selected
    if (this.selectedRowCount > 1) {
      toolbarWithoutBulkEdit.push({ text: 'Bulk Edit', id: 'bulkEditProviders' });
    }
    
    this.providersGrid.grid.toolbar = toolbarWithoutBulkEdit;
  }

  customToolbarClick(args: any): void {
    if (args.item.id === 'add') {
      this.providerDialog.header = `Add Provider`;
      this.providerFormComponent.addressFormComp.displayTitle = false;
      this.selectedProvider.set(undefined);
      this.providerDialog.show();
    } else if (args.item.id === 'bulkEditProviders') {
      this.getSelected();
    }
  }

  async onCommandClick(args: CommandClickEventArgs): Promise<void> {
    const provider = args.rowData as any;

    if (args.commandColumn?.type === 'Edit') {
      args.cancel = true;
      this.providerDialog.header = `Editing: ${provider.Name}`;
      this.providerFormComponent.addressFormComp.displayTitle = false;
      this.selectedProvider.set(provider);
      this.providerDialog.show();

    } else if (provider?.Id && args.commandColumn?.title === 'Logs') {

      try {
        this.auditLogsLoading = true;
        this.showLogsDialog();
        const logs = await this.auditLogService.getAuditLogs(provider.Id, 'Provider');

        if (!logs) {
          throw new Error('No audit logs returned from service');
        }

        this.auditLogs = this.auditLogService.mapAuditDataToLogFormat(logs);
      } catch (error) {
        this.closeLogsDialog();
        this.toast.showError('Failed to load audit logs. Please try again.');
        console.error('Error loading audit logs:', error);
      } finally {
        this.auditLogsLoading = false;
      }
    } 
  }

  showLogsDialog(): void {
    try {
      this.logsDialogVisibility = true;
      if (this.logsDialog) {
        this.logsDialog.show();
      } else {
        throw new Error('Logs dialog not initialized');
      }
    } catch (error) {
      this.toast.showError('Error displaying logs dialog');
      console.error('Error showing logs dialog:', error);
    }
  }

  closeLogsDialog(): void {
    try {
      if (this.logsDialog) {
        this.logsDialog.hide();
        this.logsDialogVisibility = false;
        this.auditLogs = [];
      }
    } catch (error) {
      this.toast.showError('Error closing logs dialog');
      console.error('Error closing logs dialog:', error);
    }
  }

  closeDialog(): void {
    this.providerDialogVisibility = false;
  }

  onProviderClicked(data: any): void {
    this.router.navigate(['/provider-detail', data.Id]);
  }

  getSelected() {
    const selectedRecords = this.providersGrid.getSelectedRows() as Provider[];
    if (selectedRecords && selectedRecords.length > 1) {
      this.bulkEditProvidersComponent.selectedRows = selectedRecords;
      this.bulkEditProvidersComponent.showModal();
    } else {
      this.toast.showWarning('Please select at least two providers to bulk edit.');
    }
  }

  async submitProviderForm() {
    await this.providerFormComponent.onSubmit().then(async (res) => {
      if (res && res.Id) {
        console.log('Provider RESPONSE', res);
        const updatedData = await this.loadData();
        this.providers.dataSource = updatedData;
        this.providersGrid.grid.refresh();
        this.providerDialog.hide();
      }
    });
  }

  cancelProviderForm() {
    this.selectedProvider.set(undefined);
    this.providerDialog.hide();
  }
}
