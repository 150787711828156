import { Injectable } from '@angular/core';
import { ErrorHandlingService } from './error-handling.service';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { NotificationService } from './notification.service';
/**
 * Base service providing standardized error handling for all services.
 *
 * Features:
 * - Standardized error handling via ErrorHandlingService
 * - Returns RxJS error Observable for proper error propagation
 * - Includes context in error reporting
 *
 * @example
 * export class MyService extends BaseService {
 *   async getData(): Promise<Data[]> {
 *     try {
 *       return await this.api.fetch();
 *     } catch (error) {
 *       return this.handleError(error, 'MyService.getData');
 *     }
 *   }
 * }
 */
@Injectable()
export abstract class BaseService {
  protected readonly BACKEND_URL: string;
  protected readonly http: HttpClient;
  protected readonly errorHandling: ErrorHandlingService;
  protected readonly notification: NotificationService;

  constructor(
    http: HttpClient,
    errorHandling: ErrorHandlingService,
    notification: NotificationService
  ) {
    this.http = http;
    this.errorHandling = errorHandling;
    this.notification = notification;
    this.BACKEND_URL = environment.BACKENDURL;
  }

  protected handleError(error: any, context?: string): Observable<never> {
    this.errorHandling.handleError(error, context);
    return throwError(() => error);
  }
}
