// Angular
import { Component, signal, ViewChild, WritableSignal, OnInit, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

// 3rd Party
import { Query } from '@syncfusion/ej2-data';
import { GridModel } from '@syncfusion/ej2-grids';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { CaseFile, SignedAuthorization } from '@models/data-contracts';
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { ApiService } from '@services/api/api.service';
import { GlobalsService } from '@services/globals/globals.service';
import { GridTemplateComponent } from '@grids/grid-template/grid-template.component';
import { LoadingModule } from "@modules/loading.module";
import { TabCardComponent } from '@root/src/app/features/file-hub/components/file-hub-tabs/tab-card/tab-card.component';
import { FileHubService } from '@root/src/app/features/file-hub/services/file-hub.service';
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';
import { AddAuthorizationsComponent } from '@forms/add-forms/add-authorizations/add-authorizations.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'authorizations-tab',
  standalone: true,
  imports: [
    TabCardComponent,
    GridTemplateComponent,
    LoadingModule,
    AddAuthorizationsComponent,
    DialogModule,
    CommonModule,
  ],
  templateUrl: './authorizations-tab.component.html',
  styleUrl: './authorizations-tab.component.scss'
})
export class AuthorizationsTab implements OnInit, OnDestroy {

  /**
   * Main functionality can be found in the FileHubService.
   * @see {@link FileHubService} for more info
   */
  constructor(
    private api: ApiService,
    private toast: ToastMessageService,
    private globals: GlobalsService,
    public fileHub: FileHubService
  ) {}

  loadingAuthorizationsData: WritableSignal<boolean> = signal(true);
  authorizations: SignedAuthorization;
  fileAuthorizationsGrid: GridModel;
  providerFundingGrid: GridModel;

  selectedAuthorization: WritableSignal<SignedAuthorization | null> = signal(null);

  @ViewChild('addAuthorizations') addAuthorizations: DialogComponent;
  addAuthorizationsVisibility: boolean = false;

  @ViewChild('grid') grid: GridTemplateComponent;

  private destroy$ = new Subject<void>();

  ngOnInit() {
    // Subscribe to changes in the fileHub service
    this.fileHub.reload$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.initializeData();
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit() {
    this.initializeData();
  }

  async initializeData() {
    try {
      await this.setActiveFileData();
      if (this.fileHub.caseFile?.Id) {
        this.setAuthorizationsGrid();
      }
    } catch (error) {
      console.error('Error initializing authorization data:', error);
      this.toast.showError('Failed to load authorizations');
    } finally {
      this.loadingAuthorizationsData.set(false);
    }
  }

  async setActiveFileData() {
    try {
      // Check for valid case file ID first
      if (!this.fileHub.caseFile?.Id) {
        return;
      }

      const authorizationsExist = this.globals.objHasKey(this.fileHub.caseFile, 'SignedAuthorizations');

      if (!authorizationsExist) {
        const endpoint = `${APIEndpoints.Casefiles}(${this.fileHub.caseFile.Id})`;
        const query = new Query().expand('SignedAuthorizations').select('SignedAuthorizations');

        return await this.api.getOdata(endpoint).executeQuery(query).then((res: any) => {
          const file = res.result[0] as CaseFile;
          this.authorizations = file.SignedAuthorizations as SignedAuthorization;
          this.fileHub.updateCaseFile('SignedAuthorizations', file.SignedAuthorizations);
        });
      }

      return this.fileHub.caseFile?.SignedAuthorizations;

    } catch (error) {
      console.error('Error setting active file data:', error);
      this.toast.showError('Failed to load authorizations data');
      return error;

    } finally {
      this.loadingAuthorizationsData.set(false);
      return;
    }
  }

  setAuthorizationsGrid() {
    // Check for valid case file ID first
    if (!this.fileHub.caseFile?.Id) {
      console.error('No case file ID available');
      return;
    }

    this.fileAuthorizationsGrid = {
      dataSource: this.api.getOdata(APIEndpoints.SignedAuthorizations),
      query: new Query()
        .where('CaseFileId', 'equal', this.fileHub.caseFile.Id)
        .where('AuthType', 'equal', "File Authorization Limit")
        .expand('Provider'),
      toolbar: ['Add Authorization', 'ColumnChooser'],
      columns: [
        { field: 'Id', isPrimaryKey: true, visible: false },
        { field: 'DateSigned', headerText: 'Signed' },
        { field: 'Amount', format: 'C2', editType: 'numeric' },
        { field: 'AuthType', headerText: 'Type' },
        { field: 'Provider.Name', headerText: 'Provider', allowEditing: false },
        { field: 'ProviderId', headerText: 'Provider Id' },
        { field: 'Notes' },
        { type: 'commands', headerText: 'Actions', commands: [
          { type: 'Edit', title: 'Edit', buttonOption: { iconCss: 'e-icons e-edit', cssClass: 'e-flat' }  },
        ] }
      ],
      toolbarClick: (args: any) => this.onToolbarClick(args),
      commandClick: (args: any) => this.onCommandClick(args)
    }

    this.providerFundingGrid = {
      dataSource: this.api.getOdata(APIEndpoints.SignedAuthorizations),
      query: new Query()
        .where('CaseFileId', 'equal', this.fileHub.caseFile.Id)
        .where('AuthType', 'equal', "Provider Funding")
        .expand('Provider'),
      toolbar: ['Add Provider Funding', 'ColumnChooser'],
      columns: [
        { field: 'Id', isPrimaryKey: true, visible: false },
        { field: 'DateSigned', headerText: 'Signed' },
        { field: 'Amount', format: 'C2', editType: 'numeric' },
        { field: 'AuthType', headerText: 'Type' },
        { field: 'Provider.Name', headerText: 'Provider', allowEditing: false },
        { field: 'ProviderId', headerText: 'Provider Id' },
        { field: 'Notes' },
        { type: 'commands', headerText: 'Actions', commands: [
          { type: 'Edit', title: 'Edit', buttonOption: { iconCss: 'e-icons e-edit', cssClass: 'e-flat' }  },
        ] }
      ],
      toolbarClick: (args: any) => this.onToolbarClick(args),
      commandClick: (args: any) => this.onCommandClick(args)
    }
  }
  authType: string | null = null;

  onToolbarClick(args: any) {
    if (args.item.text === 'Add Authorization') {
      if (!this.fileHub.caseFile?.Id) {
        this.toast.showError('No case file selected');
        return;
      }
      this.selectedAuthorization.set(null);
      this.authType = "File Authorization Limit";
      this.addAuthorizationsVisibility = true;
      this.addAuthorizations.show();
    }
    if (args.item.text === 'Add Provider Funding') {
      if (!this.fileHub.caseFile?.Id) {
        this.toast.showError('No case file selected');
        return;
      }
      this.selectedAuthorization.set(null);
      this.authType = "Provider Funding";
      this.addAuthorizationsVisibility = true;
      this.addAuthorizations.show();
    }
  }

  onCommandClick(args: any) {
    console.log(args);
    const rowData = args.rowData;
    args.cancel = true;
    if (args.commandColumn.title === 'Edit') {
      this.selectedAuthorization.set(rowData);
      this.addAuthorizationsVisibility = true;
      this.addAuthorizations.show();
    }
  }

  async onSubmit() {
    try {
      await this.fileHub.setPerformanceData();
      this.selectedAuthorization.set(null);
      // Reinitialize both grids with their settings
      this.setAuthorizationsGrid();
      this.grid.grid.refresh();
    } catch (error) {
      console.error('Error on submit:', error);
      this.toast.showError('Failed to save authorization');
      return error;
    } finally {
      this.loadingAuthorizationsData.set(false);
      this.addAuthorizations.hide();
      return;
    }
  }

  onClose() {
    this.addAuthorizations.hide();
    this.selectedAuthorization.set(null);
  }
}
