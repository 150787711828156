// Angular
import { Component, computed, effect, EventEmitter, HostListener, Input, Output, Signal, signal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

// 3rd Party
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { TextBoxAllModule, MaskedTextBoxAllModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownListAllModule, FilteringEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { TooltipAllModule } from '@syncfusion/ej2-angular-popups';
import { Query } from '@syncfusion/ej2-data';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faBuilding, faCity, faHouseUser, faHashtag, faBuildingUser, faFlagUsa, faTableList, faMapLocationDot } from '@fortawesome/free-solid-svg-icons';

// Models
import { Address } from '@models/data-contracts';
import { APIEndpoints } from '@models/api/Endpoints';

// Services
import { FormCrudService } from '@services/forms/form-crud.service';
import { ApiService } from '@services/api/api.service';
import { EmitType } from '@syncfusion/ej2-base';

// Type & Interface setup
type AddressFormComponentControls = {
  [K in keyof Address]: FormControl<Address[K] | null>;
};

@Component({
  selector: 'address-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    TextBoxAllModule,
    MaskedTextBoxAllModule, 
    DropDownListAllModule,
    TooltipAllModule,
    FontAwesomeModule
  ],
  templateUrl: './address-form.component.html',
  styleUrl: './address-form.component.scss'
})
export class AddressFormComponent {

  constructor(
    private formCRUD: FormCrudService,
    private api: ApiService
  ) {
    // Set up address data effect
    effect(() => {
      const address = this.address();
      if (address) {
        this.addressForm.patchValue(address, { emitEvent: false });
      } else {
        this.resetForm();
      }
    });

    // Initialize all dropdown data
    this.initializeDropdownData();
  }

  // States
  protected readonly formState = computed(() => {
    const currentAddress = this.address();
    return {
      isValid: this.addressForm.valid,
      hasChanges: currentAddress !== this.addressForm.value,
      currentValue: currentAddress
    };
  });
  protected readonly errorMessages = computed(() => {
    const errors: Record<string, string> = {};
    Object.keys(this.addressForm.controls).forEach(key => {
      const control = this.addressForm.get(key);
      if (control?.errors) errors[key] = this.getErrorMessage(key);
    });
    return errors;
  });

  // Decorator variabls
  @Input() address!: WritableSignal<Address | undefined>;
  @Input() submitType?: 'POST' | 'PATCH' | 'DELETE';
  @Input() title: string = 'Address';
  @Input() displayFields: string[] = ['Address1', 'Address2', 'City', 'State', 'Zip', 'AddressType'];
  @Input() displayButtons: boolean = true;
  @Input() displayTitle: boolean = true;
  @Input() formMainTitle?: string;
  @Input() requiredFields: string[] = ['Address1', 'Zip', 'AddressType'];
  @Output() formSubmitted = new EventEmitter<void>();
  @Output() formResult = new EventEmitter<any>();

  // Public variables
  loadingForm: boolean = true;
  errorMessage: string = '';
  addressHTMLElement: Element | null = null;
  addressTypes: any;
  addressTypesQuery: Query;
  states: any;
  counties: any;
  countiesQuery: Query;
  addressForm = new FormGroup<AddressFormComponentControls>({
    Id: new FormControl<Address['Id']>(undefined),
    Address1: new FormControl<Address['Address1']>(undefined),
    Address2: new FormControl<Address['Address2']>(undefined),
    City: new FormControl<Address['City']>(undefined),
    State: new FormControl<Address['State']>(undefined),
    Zip: new FormControl<Address['Zip']>(undefined),
    County: new FormControl<Address['County']>(undefined),
    AddressType: new FormControl<Address['AddressType']>(undefined, [Validators.required])
  });
  addressIcons = {
    form: faMapLocationDot,
    address1: faHouseUser,
    address2: faBuildingUser,
    addressType: faTableList,
    city: faCity,
    state: faFlagUsa,
    zip: faHashtag,
    county: faBuilding,
  }
  formClasses = {
    formContainer: 'cc-form-container',
    form: 'cc-form flex-column',
    subForm: 'cc-sub-form',
    mainTitle: 'cc-main-title',
    section: 'cc-form-section',
    group: 'cc-form-group row',
    inputContainer: 'cc-input-container',
    label: 'cc-label',
    input: 'cc-input',
    icon: 'cc-input-icon',
    error: 'cc-input-error',
    actions: 'cc-form-actions',
    twoCol: 'col-12',
    threeCol: 'col-12'
  };

  addressTypeValue: any;
  addressTypeLabel: string = 'Address';

  ngAfterViewInit() {
    this.addressHTMLElement = document.querySelector('address-form');
    this.updateWidth(this.addressHTMLElement?.clientWidth || window.innerWidth);
    this.watchInputElements();
  }

  ngOnInit() {
    // Add validators based on requiredFields
    this.requiredFields.forEach(field => {
      const control = this.addressForm.get(field);
      if (control) {
        control.addValidators(Validators.required);
        control.updateValueAndValidity();
      }
    });
  }

  // On form submit
  async onSubmit(): Promise<any> {
    const submitType = this.submitType || (this.addressForm.get('Id')?.value ? 'PATCH' : 'POST');
    const endpoint = this.addressForm.get('Id')?.value ? `${APIEndpoints.Addresses}/${this.addressForm.get('Id')?.value}` : APIEndpoints.Addresses;

    try {

      this.markAllFieldsAsTouched();
      
      // Validate form
      if (this.addressForm.invalid) {
        const errors = this.errorMessages();
        const errorMessage = Object.entries(errors)
          .map(([key, value]) => `<b>${key}</b>: ${value}`)
          .join('<br>');
        throw new Error(`Invalid address data:<br>${errorMessage}`);
      }

      // Check for changes
      if (this.addressForm.pristine) {
        return 'no-change';
      }

      const result = await this.formCRUD.submitForm(this.addressForm, `odata${endpoint}`, submitType);
      this.address.set(result);
      return result;

    } catch (error) {
      console.error('Address form submission error:', error);
      return new Error(error instanceof Error ? error.message : 'Failed to submit address form');
    }
  }

  markAllFieldsAsTouched(): void {
    Object.values(this.addressForm.controls).forEach(control => {
      control.markAsTouched();
      control.markAsDirty();
      control.updateValueAndValidity();
    });
  }

  // Update the layout on window resize
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    const containerWidth = this.addressHTMLElement?.clientWidth || window.innerWidth;
    this.updateWidth(containerWidth);
  }

  // Switches form to 2 columns when parent is larger 1600px
  updateWidth(containerWidth: number) {

    if (containerWidth > 576 && containerWidth < 768) {
      this.formClasses.form = this.formClasses.form.replace('flex-column', 'flex-row flex-wrap');
      this.formClasses.twoCol = this.formClasses.twoCol.replace('col-12', 'col-6');
      this.formClasses.threeCol = this.formClasses.threeCol.replace('col-12', 'col-6');
    } else if (containerWidth > 768) {
      this.formClasses.form = this.formClasses.form.replace('flex-column', 'flex-row flex-wrap');
      this.formClasses.twoCol = this.formClasses.twoCol.replace('col-12', 'col-6');
      this.formClasses.threeCol = this.formClasses.threeCol.replace('col-12', 'col-4');
    } else {
      this.formClasses.form = this.formClasses.form.replace('flex-row', 'flex-column');
      this.formClasses.twoCol = this.formClasses.twoCol.replace('col-6', 'col-12');
      this.formClasses.threeCol = this.formClasses.threeCol.replace('col-4', 'col-12');
    }
  }

  // Perform updates on input elements
  watchInputElements() {
    document.querySelectorAll(`.cc-input-container`).forEach((inputContainer) => {
      const container = inputContainer as HTMLElement;

      container.querySelectorAll('input, span').forEach((input) => {
        input.addEventListener('focus', () => inputContainer.classList.add('focus'));
        input.addEventListener('blur', () => inputContainer.classList.remove('focus'));
      });
    });
  }

  // Returns appropraite error message for form control
  getErrorMessage(controlName: string): string {
    let message = '';
    const control = this.addressForm.get(controlName);
    if (control?.errors) {
      if (control.errors['required']) message = 'This field is required';
      if (control.errors['email']) message = 'Invalid email format';
      if (control.errors['invalidPhone']) message = 'Invalid phone number (10 digits required)';
      if (control.errors['serverError']) message = control.errors['serverError'].message;
    };
    this.errorMessage = message;
    return message;
  }

  // Add initialization function
  async initializeDropdownData() {

    try {
      
      this.api.getOdata(APIEndpoints.States).executeQuery(new Query()).then((res: any) => {
        this.states = (res as any).result;
      });
      this.api.getOdata(APIEndpoints.AddressTypes).executeQuery(new Query()).then((res: any) => {
        this.addressTypes = (res as any).result;
      });

      return;

    } catch (error) {
      console.error('Error initializing dropdowns:', error);
      return error;
    }
  }

  // Helper methods
  private resetForm(): void {
    this.addressForm.reset({}, { emitEvent: false });
  }

  // Filtering handler for dropdowns
  onDropdownFiltering: EmitType<FilteringEventArgs> = (filterEvent: FilteringEventArgs, data: any) => {
    let query = new Query();
    query = (filterEvent.text !== "") ? query.where("Text", "contains", filterEvent.text, true) : query;
    filterEvent.updateData(data, query);
  };

  async onAddressTypesCreated(args: any) {
    const endpoint = APIEndpoints.AddressTypes;
    const query = new Query();

    await this.api.getOdata(endpoint).executeQuery(query).then((res: any) => {
      const types = res.result;
      this.addressTypes = types;
      
      const currentAddressType = this.addressForm.get('AddressType')?.value;
      
      if (currentAddressType) {
        const description = types.find((type: any) => type.Id === currentAddressType)?.Description;
        this.addressTypeLabel = description ? `${description} Address` : 'Address';
        this.addressTypeValue = currentAddressType;
      }
    });
  }

  onAddressTypeChange(args: any) {
    if (!args || !this.addressTypes) return;
    
    const addressType = args.value;
    const addressTypeDesc = this.addressTypes.find((type: any) => type.Id === addressType)?.Description;
    this.addressTypeLabel = addressTypeDesc ? `${addressTypeDesc} Address` : 'Address';
  }
}