// Production environment
export const environment = {
    production: true,
    mondayBoardId: '8206260134',
    mondayApiToken: 'eyJhbGciOiJIUzI1NiJ9...',
    BoxDeveloperToken: 'ca0pY44Lhfg2WXsZnTZyHcEeCtDioCjM',
    aws: {
        region: 'us-west-2',
        cloudwatch: {
            logGroup: 'casecompass-logs',
            logStream: 'frontend-logs'
        }
    },
    BACKENDURL: 'https://backend.casecompass.us/',
    amplifyConfiguration: {} as {
        aws_project_region: string;
        aws_cognito_identity_pool_id: string;
        aws_cognito_region: string;
        aws_user_pools_id: string;
        aws_user_pools_web_client_id: string;
        aws_cognito_signup_attributes: string[];
        aws_cognito_mfa_configuration: string;
        aws_cognito_mfa_types: string[];
        aws_cognito_password_protection_settings: {
            passwordPolicyMinLength: number;
            passwordPolicyCharacters: string[];
        };
        aws_cognito_verification_mechanisms: string[];
    }
};

// These values should be injected during CI/CD from vault
declare global {
    interface Window {
        ENV_CONFIG: typeof environment & {
            BACKENDURL: string;
            BoxDeveloperToken: string;
        }
    }
}

export const BACKENDURL = window.ENV_CONFIG?.BACKENDURL || 'https://backend.casecompass.us/';
