<div id="file-hub-tabs">
    
    <ejs-tab #ejsTab id="_ejs-component-ejsTab" 
        [enablePersistence]="true"
        (selected)="onTabSelected($event)">

        <e-tabitems id="content">

            @for(tab of tabs; track trackByTabId) {
                <e-tabitem [header]="tab" [id]="tab.Id" [tabIndex]="$index">
                    <ng-template #headerTemplate>
                        <div class="tab-header">
                            <fa-icon [icon]="tab.faIcon" />
                            <label>{{tab.HeaderText}}</label>
                        </div>
                    </ng-template>

                    <ng-template #content let-data>
                        <div class="tab-contents">
                            @defer (when activeTab() === tab.HeaderText) {
                                @switch (tab.Id) {
                                    @case ('home') { <home-tab /> }
                                    @case ('appointments') { <appointments-tab /> }
                                    @case ('tasks') { <tasks-tab /> }
                                    @case ('financial') { <financial-tab /> }
                                    @case ('documents') { <documents-tab /> }
                                    @case ('authorizations') { <authorizations-tab /> }
                                    @case ('injury') { <injury-tab /> }
                                    @case ('box-sign') { <box-sign-tab /> }
                                    @case ('compliance') { <compliance-tab /> }
                                    @case ('surgical') { <surgical-tab /> }
                                    @case ('settings') { <settings-tab /> }
                                }
                            }
                        </div>
                    </ng-template>
                </e-tabitem>
            }

        </e-tabitems>
    </ejs-tab>

</div>