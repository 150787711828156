import { Invoice, Deposit } from "@shared/models/data-contracts";

export interface PerformanceData {
    TotalBilledCost?: number;
    TotalPaymentReceived?: number;
    ActualSettlementCost?: number;
    BalanceDue?: number;
    AuthorizedAmount?: number;
    DaysOpen: string;
    FullSettlementValue?: number;
    Profit?: number;
    NonSOCALInvoices?: number;
    ROIC: string;
    FinalBalanceDueGenerated?: boolean;
    CourtesyReduction?: number;
    CourtesyReductionPercent?: number;
    FinalFSV?: number;
    XIRR?: number;
}

export interface FinancialData {
    Invoices?: Invoice[];
    Deposits?: Deposit[];
}

export const DEFAULT_PERFORMANCE_DATA: Required<PerformanceData> = {
    TotalBilledCost: 0,
    TotalPaymentReceived: 0,
    ActualSettlementCost: 0,
    BalanceDue: 0,
    AuthorizedAmount: 0,
    DaysOpen: '0',
    FullSettlementValue: 0,
    Profit: 0,
    NonSOCALInvoices: 0,
    ROIC: '0',
    CourtesyReduction: 0,
    CourtesyReductionPercent: 0,
    FinalBalanceDueGenerated: false,
    FinalFSV: 0,
    XIRR: 0
};
