import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ApiService } from '@root/src/app/shared/services/api/api.service';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { Dialog } from '@syncfusion/ej2-popups';
import { Spacer } from "../../../ui/spacer/spacer.component";
import { APIEndpoints } from '@root/src/app/shared/models/api/Endpoints';
import { DropdownSingleComponent } from "../../../ui/dropdown-single/dropdown-single.component";
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-bulk-edit-remittances',
  standalone: true,
  imports: [
    DialogModule,
    ReactiveFormsModule,
    ButtonModule,
    TextBoxModule,
    Spacer,
    DropdownSingleComponent,
    DatePickerModule,
  ],
  providers: [DatePipe],
  templateUrl: './bulk-edit-remittances.component.html',
  styleUrl: './bulk-edit-remittances.component.scss'
})
export class BulkEditRemittancesComponent {

  @ViewChild('Modal') modal: Dialog;
  selectedRows: any;

  //Dropdowns
  paymentMethod = APIEndpoints.PaymentMethods
  paymentStatus = APIEndpoints.PaymentStatuses

  editForm: FormGroup = this.fb.group({
    DatePaid: ["1900-01-01", [Validators.required]],
    TransactionNumber: ['', [Validators.required]],
    InvoiceNotes: ['', [Validators.required]],
    PaymentMethod: [-1, [Validators.required]],
    PaymentStatus: [-1, [Validators.required]],
  })

  constructor(
    private api: ApiService,
    private fb: FormBuilder,
    private datePipe: DatePipe,
  ) { }

  beforeModalOpened = (args: any) => args.maxHeight = '85vh';

  showModal = (rows: any) => {
    this.selectedRows = rows
    this.modal.show();
  }

  submit = async () => {
    let formatedDate = this.datePipe.transform(this.editForm.value.DatePaid, "yyyy-MM-dd");
    let overwriteObject = { DatePaid: formatedDate, TransactionNumber: this.editForm.value.TransactionNumber, PaymentMethod: this.editForm.value.PaymentMethod, PaymentStatus: this.editForm.value.PaymentStatus, InvoiceNotes: this.editForm.value.Notes }
    await this.api.basicPost(APIEndpoints.Remittances, { overwriteValues: overwriteObject, remittancesArray: this.selectedRows });
    this.modal.hide();
    window.location.reload()
  }
}
