// Angular
import { Injectable } from '@angular/core';

// 3rd Party
import { Query, DataManager } from '@syncfusion/ej2-data';

// Models
import { APIEndpoints } from '@models/api/Endpoints';
import { Role, XrefRolesRight } from '@models/data-contracts';

// Services
import { ApiService } from '@services/api/api.service';

// Interfaces
interface RoleResponse {
  result: Role[];
}

interface RoleGridItem extends Role {
  Rights: string[];
}

interface RightsResponse {
  result: Array<{ RightId: number }>;
}

// Error constants
const ERRORS = {
  LOAD_RIGHTS: {
    message: 'Failed to load rights data. Please try again.',
    technical: 'Error loading rights data for role'
  },
  UPDATE_RIGHTS: {
    message: 'Failed to update role rights. Please try again.',
    technical: 'Error updating rights for role'
  }
};

@Injectable({
  providedIn: 'root'
})
export class RolesService {
  private readonly rolesGridQuery = new Query()
    .expand('XrefRolesRights($expand=Right($select=FriendlyName))')
    .select('Id, RoleName, Description, CreatedAt, XrefRolesRights')
    .requiresCount();

  private readonly rightsQuery = new Query()
    .select(['Id', 'FriendlyName']);

  constructor(
    private api: ApiService
  ) {}

  getRolesGridQuery(): Query {
    return this.rolesGridQuery;
  }

  getRightsDataManager(): DataManager {
    return this.api.getOdata(APIEndpoints.Rights);
  }

  getRoleRightsQuery(roleId: number): Query {
    return new Query()
      .select(['RightId', 'Friendly_name'])
      .from(`${APIEndpoints.Roles}(${roleId})/Rights`);
  }

  transformRightsForGrid(data: XrefRolesRight[] | null | undefined): string[] {
    if (!data) return [];
    return data.map((xref: XrefRolesRight) =>
      xref.Right?.FriendlyName || ''
    );
  }

  transformRolesForGrid(data: Role[]): RoleGridItem[] {
    return data.map((role: Role) => ({
      ...role,
      Rights: this.transformRightsForGrid(role.XrefRolesRights)
    }));
  }

  async getRoleById(id: number): Promise<Role> {
    try {
      const response = await this.api.getOdata(`${APIEndpoints.Roles}(${id})`).executeQuery(this.rolesGridQuery);
      const typedResponse = response as unknown as RoleResponse;
      return typedResponse.result[0];
    } catch (error) {
      console.error('Error fetching role:', error);
      throw error;
    }
  }

  async getRoleRights(roleId: number): Promise<number[]> {
    try {
      const response = await this.api.getOdata(`${APIEndpoints.Roles}(${roleId})/Rights`)
        .executeQuery(new Query());
      const typedResponse = response as unknown as RightsResponse;
      return typedResponse.result.map(right => right.RightId);
    } catch (error) {
      console.error('Error fetching role rights:', error);
      throw error;
    }
  }

  async updateRoleRights(roleId: number, rights: XrefRolesRight[]): Promise<void> {
    try {
      const endpoint = `odata${APIEndpoints.Roles}(${roleId})/Rights`;
      await this.api.fetchRequest(endpoint, 'POST', rights);
    } catch (error) {
      console.error('Error updating role rights:', error);
      throw error;
    }
  }

  async createRole(data: Partial<Role>): Promise<Role> {
    try {
      const response = await this.api.postOdata(APIEndpoints.Roles, data);
      return response as unknown as Role;
    } catch (error) {
      console.error('Error creating role:', error);
      throw error;
    }
  }

  async updateRole(id: number, data: Partial<Role>): Promise<Role> {
    try {
      const response = await this.api.patchOdata(`${APIEndpoints.Roles}/${id}`, data);
      return response as unknown as Role;
    } catch (error) {
      console.error('Error updating role:', error);
      throw error;
    }
  }

  getErrors() {
    return ERRORS;
  }
}
