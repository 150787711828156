import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatDialogModule } from '@angular/material/dialog';

import { AppComponent } from './app.component';
import { AgentService } from './services/agent.service';
import { ErrorHandlingService } from '@core/error/error.service';
import { ErrorInterceptor } from '@core/internal/interceptors/error.interceptor';
import { AppRoutingModule } from '@modules/app-routing.module';
import { AiAgentModule } from './features/ai-agent/ai-agent.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    OverlayModule,
    MatDialogModule,
    AppRoutingModule,
    AiAgentModule,
  ],
  providers: [
    ErrorHandlingService,
    { provide: ErrorHandler, useClass: ErrorHandlingService },
    provideHttpClient(withInterceptors([ErrorInterceptor])),
    AgentService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
