import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { updatePassword, UpdatePasswordInput } from '@aws-amplify/auth';
import { GlobalsService } from '@services/globals/globals.service';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { FormValidators, TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { Dialog, DialogModule } from '@syncfusion/ej2-angular-popups';
import { ToastUtility } from '@syncfusion/ej2-notifications';

@Component({
  selector: 'app-change-password',
  standalone: true,
  imports: [
    TextBoxModule,
    ReactiveFormsModule,
    DialogModule,
    ButtonModule,
  ],
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.scss'
})
export class ChangePasswordComponent {

  constructor(
    private globals: GlobalsService
  ) { }

  @ViewChild('Modal') modal: Dialog;

  // Form to verify new password
  formGroup: FormGroup = new FormGroup({
    oldPassword: new FormControl<string>('', [FormValidators.required]),
    password1: new FormControl<string>('', [FormValidators.required, this.globals.strongPasswordValidator()]),
    password2: new FormControl<string>('', [FormValidators.required, this.globals.strongPasswordValidator()]),
  }, { validators: this.globals.confirmPasswordValidator });

  async changePassword() {
    await this.updatePassword({ oldPassword: this.formGroup.value.oldPassword, newPassword: this.formGroup.value.password2 });
  }

  async updatePassword({ oldPassword, newPassword }: UpdatePasswordInput) {
    try {
      await updatePassword({ oldPassword, newPassword });
      ToastUtility.show({
        title: 'Success',
        content: "Password Changed",
        cssClass: 'e-toast-success',
        position: { X: 'Center', Y: 'Top' },
        timeOut: 3000,
      });
      this.modal.hide();
    } catch (err) {
      console.log(err);
      let message = 'Unknown Error'
      if (err instanceof Error) { message = err.message }
      ToastUtility.show({
        title: 'Something Went Wrong',
        content: message,
        cssClass: 'e-toast-danger',
        position: { X: 'Center', Y: 'Top' },
        timeOut: 3000,
      });
    }
  }

  showModal() {
    this.modal.show();
  }

  beforeModalOpened(args: any) {
    args.maxHeight = '85vh';
  }

}
