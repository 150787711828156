// Angular
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ReactiveFormsModule, FormBuilder, FormControl, FormGroup } from '@angular/forms';

// 3rd Party
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { ProcedureCode } from '@models/data-contracts';
import { ApiService } from '@services/api/api.service';
import { NotificationService } from '@core/services/notification.service';
import { ProcedureCodeService } from '../../../services/procedure-code/procedure-code.service';
import { Query } from '@syncfusion/ej2-data';

@Component({
  selector: 'edit-procedure-code-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    DropDownListModule,
    TextBoxModule,
    ButtonModule
  ],
  templateUrl: './edit-procedure-code-form.component.html'
})
export class EditProcedureCodeFormComponent {
  @Input() procedureCode: ProcedureCode;
  @Input() showFormButtons = false;
  @Output() closeDialog = new EventEmitter<void>();
  @Output() refreshGrid = new EventEmitter<void>();

  form: FormGroup;
  modalityData: any;
  modalityFields = { text: 'Description', value: 'Id' };

  constructor(
    private fb: FormBuilder,
    private notification: NotificationService,
    private procedureCodeService: ProcedureCodeService,
    private api: ApiService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.loadModalityTypes();
  }

  private initForm(): void {
    this.form = this.fb.group({
      Code: [this.procedureCode?.ProcedureCodeName || ''],
      Modality: [this.procedureCode?.ModalityTypeId],
      Description: [this.procedureCode?.Description || '']
    });
  }

  private async loadModalityTypes(): Promise<void> {
    try {
      const query = new Query();
      const response = await this.api.getOdata(APIEndpoints.ModalityTypes).executeQuery(query) as any;
      this.modalityData = response.result;
    } catch (error) {
      this.notification.error(error, 'Failed to load modality types');
    }
  }

  async onSubmit(): Promise<void> {
    if (!this.form.valid) return;

    try {
      const updatedCode = {
        Id: this.procedureCode.Id,
        ProcedureCodeName: this.form.value.Code,
        ModalityTypeId: parseInt(this.form.value.Modality),
        Description: this.form.value.Description
      };

      await this.procedureCodeService.updateProcedureCode(updatedCode);
      this.notification.success('Successfully updated procedure code');
      this.form.reset();
      this.refreshGrid.emit();
      this.closeDialog.emit();
    } catch (error) {
      this.notification.error(error, 'Failed to update procedure code');
    }
  }

  cancel(): void {
    this.closeDialog.emit();
  }
}
