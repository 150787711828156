<div id="patient-info" class="patient-info">

    <div class="row">
        @if (loading.patient()) {
            <loading-overlay [loading]="loading.patient" />
        } @else {
            @for (item of data.header(); track item.value) {
                <div class="{{ui.columnClass()}}" id="tooltipTarget" #tooltipTarget>
                    <div class="info">
                        <label class="info-label">{{item.label}}: </label>
                        <span class="info-text">{{item.value}}</span>
                    </div>
                </div>
            }
        }
    </div>
</div>