import { Injectable, inject } from '@angular/core';
import { ApiService } from '@services/api/api.service';
import { Base } from '@core/base/base';

@Injectable()
export abstract class ServiceBase extends Base {
    protected readonly api = inject(ApiService);
    protected abstract readonly endpoint: string;

    constructor() {
        super();
    }

    protected getOdata() {
        return this.api.getOdata(this.endpoint);
    }
}
