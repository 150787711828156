<ejs-grid #grid
    [class]="useRoundedEdges ? 'base-grid rounded-edges' : 'base-grid'"
    [height]="gridSettings.height ?? '100%'"
    [groupSettings]="gridSettings.groupSettings"
    [columns]="gridSettings.columns"
    [dataSource]="gridSettings.dataSource ?? []"
    [query]="gridSettings.query ?? defaults.query"
    [allowGrouping]="gridSettings.allowGrouping ?? false"
    [allowPaging]="gridSettings.allowPaging ?? true"
    [allowSorting]="gridSettings.allowSorting ?? true"
    [allowFiltering]="gridSettings.allowFiltering ?? true"
    [allowResizing]="gridSettings.allowResizing ?? false"
    [allowReordering]="gridSettings.allowReordering ?? false"
    [allowPdfExport]="gridSettings.allowPdfExport ?? true"
    [allowExcelExport]="gridSettings.allowExcelExport ?? true"
    [selectionSettings]="gridSettings.selectionSettings ?? { type: 'Multiple', mode: 'Row' }"
    [showColumnChooser]="gridSettings.showColumnChooser ?? true"
    [columnChooserSettings]="gridSettings.columnChooserSettings ?? { operator: 'contains', ignoreAccent: true }"
    [pageSettings]="gridSettings.pageSettings ?? { pageSize: 20, pageSizes: [20, 50, 100], pageCount: 5, enableQueryString: true }"
    [filterSettings]="gridSettings.filterSettings ?? { type: 'FilterBar' }"
    [loadingIndicator]="gridSettings.loadingIndicator ?? { indicatorType: 'Shimmer' }"
    [toolbar]="toolbar"
    [editSettings]="gridSettings.editSettings ?? { allowEditOnDblClick: false, allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Dialog' }"
    [rowRenderingMode]="gridSettings.rowRenderingMode ?? (window.innerWidth < 768 ? 'Vertical' : 'Horizontal')"
    [enableAdaptiveUI]="gridSettings.enableAdaptiveUI ?? (window.innerWidth < 768)"
    [enablePersistence]="gridSettings.enablePersistence ?? false"
    [aggregates]="gridSettings.aggregates"
    [sortSettings]="gridSettings.sortSettings ?? { columns: [{ field: 'Id', direction: 'Descending' }] }"
    [searchSettings]="gridSettings.searchSettings ?? { operator: 'contains', key: '', ignoreCase: true, highlight: true }"
    (toolbarClick)="toolbarClick($event)"
    (load)="gridSettings.load ? gridSettings.load($event) : undefined"
    (created)="gridSettings.created ? gridSettings.created($event) : undefined"
    (actionBegin)="gridSettings.actionBegin ? gridSettings.actionBegin($event) : undefined"
    (actionComplete)="gridSettings.actionComplete ? gridSettings.actionComplete($event) : undefined"
    (beforeBatchSave)="gridSettings.beforeBatchSave ? gridSettings.beforeBatchSave($event) : undefined"
    (commandClick)="gridSettings.commandClick ? gridSettings.commandClick($event) : undefined"
    (rowSelected)="gridSettings.rowSelected ? gridSettings.rowSelected($event) : undefined"
    (rowSelecting)="gridSettings.rowSelecting ? gridSettings.rowSelecting($event) : undefined"
    (rowDeselected)="gridSettings.rowDeselected ? gridSettings.rowDeselected($event) : undefined"
    (rowDeselecting)="gridSettings.rowDeselecting ? gridSettings.rowDeselecting($event) : undefined"
    (dataStateChange)="gridSettings.dataStateChange ? gridSettings.dataStateChange($event) : undefined"
    (rowDataBound)="gridSettings.rowDataBound ? gridSettings.rowDataBound($event) : undefined"
    (cellEdit)="gridSettings.cellEdit ? gridSettings.cellEdit($event) : undefined"
    (cellSave)="gridSettings.cellSave ? gridSettings.cellSave($event) : undefined"
    (queryCellInfo)="gridSettings.queryCellInfo ? gridSettings.queryCellInfo($event) : undefined"
    (detailDataBound)="gridSettings.detailDataBound ? gridSettings.detailDataBound($event) : undefined"
    (dataBound)="gridSettings.dataBound ? gridSettings.dataBound($event) : undefined"
    (beforeCopy)="gridSettings.beforeCopy ? gridSettings.beforeCopy($event) : undefined">

    <ng-container *ngIf="gridSettings.detailDataBound">
        <ng-template #detailTemplate let-data>
            <div class="custom-details"></div>
        </ng-template>
    </ng-container>
</ejs-grid>

<ng-content></ng-content>