// Angular
import { Component, ViewChild } from '@angular/core';
import { ReactiveFormsModule, Validators, FormBuilder } from '@angular/forms';

// 3rd Party
import { Dialog } from '@syncfusion/ej2-popups';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { TextBoxModule, NumericTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { MultiSelectModule, ComboBoxAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { ListViewModule } from '@syncfusion/ej2-angular-lists';
import { ButtonModule, CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { DropdownSingleComponent } from "../../../ui/dropdown-single/dropdown-single.component";
import { DropdownMultiComponent } from "../../../ui/dropdown-multi/dropdown-multi.component";
import { ApiService } from '@root/src/app/shared/services/api/api.service';

@Component({
  selector: 'app-bulk-edit-tasks',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    DialogModule,
    TextBoxModule,
    NumericTextBoxModule,
    MultiSelectModule,
    ComboBoxAllModule,
    ListViewModule,
    CheckBoxModule,
    DatePickerModule,
    DropdownSingleComponent,
    DropdownMultiComponent,
    ButtonModule,
  ],
  templateUrl: './bulk-edit-tasks.component.html',
  styleUrl: './bulk-edit-tasks.component.scss'
})

export class BulkEditTasksComponent {
  constructor(
    private fb: FormBuilder,
    private api: ApiService,
  ) { }

  @ViewChild('Modal') modal: Dialog;
  selectedRows: any;

  //Dropdowns
  providerTypes = APIEndpoints.ProviderTypes;
  users = APIEndpoints.Users;
  nextActivityType = APIEndpoints.NextActivityTypes;
  taskType = APIEndpoints.TaskTypes;

  editForm = this.fb.group({
    TaskType: ['', [Validators.required]],
    NextDateOfActivity: [, [Validators.required]],
    NextActivityType: [, [Validators.required]],
    Priority: [, [Validators.required]],
    Remind: [, [Validators.required]],
  });

  // Add custom functionality when grid toolbar is clicked
  showModal() {
    this.modal.show();
  }

  // Occurs before #Modal is opened
  beforeModalOpened(args: any) {
    args.maxHeight = '85vh';
  }

  submit = async () => {
    //let overwriteObject = { DatePaid: formatedDate, TransactionNumber: this.editForm.value.TransactionNumber, PaymentMethod: this.editForm.value.PaymentMethod, PaymentStatus: this.editForm.value.PaymentStatus, InvoiceNotes: this.editForm.value.Notes }
    //await this.api.basicPost(APIEndpoints.Remittances, { overwriteValues: overwriteObject, remittancesArray: this.selectedRows });
    this.modal.hide();
    window.location.reload()
  }

  onSubmit() {
    for (const [key, value] of Object.entries(this.editForm.controls)) {
      console.log(`${key}: ${value.value}`);


      //TODO
      // this.selectedRows.forEach((row) => {
      //   for (const [key, value] of Object.entries(this.bulkEditForm.bulkEditCaseFilesForm.value)) {
      //     const updatedValue: any = value;
      //     if (value !== null) {
      //       this.caseFilesGrid.updateCell(row, key, updatedValue);
      //     }
      //   }
      // })
      // this.closeDialog();
      // this.mapBulkEditChanges();
      //  }
    }
  }
}