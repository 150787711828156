// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

// 3rd Party
import { Amplify } from 'aws-amplify';
import { enableRipple } from '@syncfusion/ej2-base';

// Internal
import { HeadService } from '@services/head/head.service';
import { AuthGuard } from '@services/auth/auth-guard.guard';
import { AppRoutingModule } from '@modules/app-routing.module';
import { AppComponent } from '@app/app.component';
import { LayoutWrapper } from '../../components/layout/layout-wrapper.component';

// Set Amplify environment for user authentication
import amplifyconfig from 'src/amplifyconfiguration.json';
Amplify.configure(amplifyconfig);

// Enable Syncfusion animation
enableRipple(true);

@NgModule({ 
  declarations: [
    AppComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    LayoutWrapper
  ], 
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    AuthGuard,
    HeadService
  ],
  bootstrap: [AppComponent], 
})
export class AppModule { }
