// src/app/components/file-hub/file-hub-tabs/surgical-tab/surgical-tab.component.ts

import { Component, OnInit } from '@angular/core';
import { SurgicalTabService } from './surgical-tab.service';
import { CommonModule } from '@angular/common';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TabCardComponent } from '../tab-card/tab-card.component';
import { SurgeriesGridComponent } from '@grids/surgeries-grid/surgeries-grid.component';
import { LoadingModule } from "@modules/loading.module";

@Component({
  selector: 'app-surgical-tab',
  templateUrl: './surgical-tab.component.html',
  styleUrls: ['./surgical-tab.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    DialogModule,
    FormsModule,
    ReactiveFormsModule,
    TabCardComponent,
    SurgeriesGridComponent,
    LoadingModule,
]
})
export class SurgicalTabComponent implements OnInit {
  segments$ = this.surgicalTabService.segments$;
  loading$ = this.surgicalTabService.loading$;
  loadingSurgicalProgression$ = this.surgicalTabService.loadingSurgicalProgression$;
  updatingSegments$ = this.surgicalTabService.updatingSegments$;

  selectedSurgeryId: number | null = null;
  confirmUndoDialogVisible = false;
  isDialogVisible = false;
  dialogBody = '';
  currentStepIndex = 0;
  segmentToUndo: number | null = null;
  selectedSegments: boolean[] = [];
  selectedSegmentId: number | undefined = undefined;

  // Maps segment IDs to their corresponding index positions for efficient lookup.
  segmentIdToIndexMap: Map<number, number> = new Map();


  confirmUndoDialogBody = 'Are you sure that you\'d like to undo this step?';

  constructor(private surgicalTabService: SurgicalTabService) {}

  dialogButtons = [
    {
      click: () => this.onSaveClick(),
      buttonModel: {
        content: 'Confirm',
        isPrimary: true,
        cssClass: 'e-success',
      },
    },
    {
      click: () => this.onCancelClick(),
      buttonModel: {
        content: 'Cancel',
        cssClass: 'e-danger',
      },
    },
  ];

  confirmUndoButtons = [
    {
      click: () => this.onConfirmUndo(),
      buttonModel: {
        content: 'Yes',
        isPrimary: true,
        cssClass: 'e-success',
      },
    },
    {
      click: () => this.onCancelUndo(),
      buttonModel: {
        content: 'No',
        cssClass: 'e-danger',
      },
    },
  ];


  ngOnInit(): void {
    this.surgicalTabService.loadSegments();
  }

  getSegmentId(index: number): number | undefined {
    const xref = this.surgicalTabService.getSegmentXref(index);
    return xref?.[0]?.Id;
  }

  onSegmentClick(index: number): void {
    if (this.selectedSegments[index]) {
      const currentId = this.getSegmentId(index);
      if (currentId) {
        this.segmentToUndo = currentId;
        this.confirmUndoDialogVisible = true;
      }
    } else {
      this.currentStepIndex = index;
      this.dialogBody = this.surgicalTabService.getSegmentContent(index) ?? '';
      this.isDialogVisible = true;
    }
  }

  onDialogClose(): void {
    this.isDialogVisible = false;
  }

  async onSaveClick() {
    try {
      this.selectedSegments[this.currentStepIndex] = !this.selectedSegments[this.currentStepIndex];
      const result = await this.surgicalTabService.saveSegment(this.currentStepIndex, this.selectedSurgeryId);
      
      if (result?.Id) {
        this.segmentIdToIndexMap.set(result.Id, this.currentStepIndex);
        this.selectedSegmentId = result.Id;
      }
    } catch (error) {
      console.error('Error in onSaveClick:', error);
      return error;

    } finally {
      this.isDialogVisible = false;
      return;
    }
  }

  onCancelClick(): void {
    this.isDialogVisible = false;
  }

  async onConfirmUndo() {

    try {
      if (this.segmentToUndo !== null) {
        const index = this.selectedSegments.findIndex((selected, i) => 
          selected && this.getSegmentId(i) === this.segmentToUndo
        );
        
        if (index !== -1) {
          await this.surgicalTabService.removeSegment(this.segmentToUndo);
          this.selectedSegments[index] = false;
        }
      }
    } catch (error) {
      console.error('Error in onConfirmUndo:', error);
      return error;

    } finally {
      this.confirmUndoDialogVisible = false;
      this.segmentToUndo = null;
      return;
    }
  }

  onCancelUndo(): void {
    this.confirmUndoDialogVisible = false;
    this.segmentToUndo = null;
  }

  async onSurgerySelect(data: any) {
    
    try {
      this.selectedSurgeryId = data.surgery.Id;
      this.selectedSegments = await this.surgicalTabService.getProgessionSegments(this.selectedSurgeryId);

      this.segmentIdToIndexMap.clear();
      this.selectedSegments.forEach((_, index) => {
        const xref = this.surgicalTabService.getSegmentXref(index);
        if (xref && xref.length > 0) {
          this.segmentIdToIndexMap.set(xref[0].Id, index);
        }
      });

    } catch (error) {
      console.error('Error in onSurgerySelect:', error);
      return error;

    } finally {
      return;
    }
  }
}