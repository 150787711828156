import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { confirmResetPassword } from '@aws-amplify/auth';
import { FormValidators } from '@syncfusion/ej2-angular-inputs';
import { ToastUtility } from '@syncfusion/ej2-angular-notifications';
import { GlobalsService } from '@services/globals/globals.service';
import { resetPassword, type ResetPasswordOutput } from 'aws-amplify/auth';
import { Router } from '@angular/router';
import { ApiService } from '@services/api/api.service';
import { Query, ReturnOption } from '@syncfusion/ej2-data';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalComponent } from '@ui/modal/modal.component';

@Component({
  selector: 'app-edit-users',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ModalComponent
  ],
  templateUrl: './edit-users.component.html',
  styleUrl: './edit-users.component.scss'
})
export class EditUsersComponent {

  constructor(
    private globals: GlobalsService, 
    private router: Router, 
    private api: ApiService) { }

  public id = this.router.url.substring(11)
  public username: string;
  public name: string;
  public title: string;
  public logo: string = 'assets/logos/casecompass-logo_solid.svg';
  public changePasswordFormGroup: FormGroup = new FormGroup({
    confirmationCode: new FormControl<string>('', [FormValidators.required]),
    password1: new FormControl<string>('', [FormValidators.required, this.globals.strongPasswordValidator()]),
    password2: new FormControl<string>('', [FormValidators.required, this.globals.strongPasswordValidator()]),
  }, {
    validators: this.globals.confirmPasswordValidator
  });

  public ngOnInit(): void {
    this.checkUser()
  }

  async changePassword() {
    const confirmationCode: string = this.changePasswordFormGroup.value.confirmationCode;
    const newPassword: string = this.changePasswordFormGroup.value.password2;
    await this.handleConfirmResetPassword(confirmationCode, newPassword);
  }

  async handleConfirmResetPassword(
    confirmationCode: string,
    newPassword: string
  ) {
    try {
      await confirmResetPassword({ username: this.username, confirmationCode, newPassword });
      ToastUtility.show({
        title: 'Success',
        content: "Password Changed",
        cssClass: 'e-toast-success',
        position: { X: 'Center', Y: 'Top' },
        timeOut: 3000,
      });
    } catch (error) {
      {
        console.log(error);
        let message = 'Unknown Error'
        if (error instanceof Error) message = error.message
        ToastUtility.show({
          title: 'Something Went Wrong',
          content: message,
          cssClass: 'e-toast-danger',
          position: { X: 'Center', Y: 'Top' },
          timeOut: 3000,
        });
      }
    }
  }

  async handleResetPassword() {
    try {
      const output = await resetPassword({ username: this.username });
      this.handleResetPasswordNextSteps(output);
    } catch (error) {
      console.log(error);
    }
  }

  handleResetPasswordNextSteps(output: ResetPasswordOutput) {
    const { nextStep } = output;
    switch (nextStep.resetPasswordStep) {
      case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
        const codeDeliveryDetails = nextStep.codeDeliveryDetails;
        console.log(
          `Confirmation code was sent to ${codeDeliveryDetails.deliveryMedium}`
        );
        // Collect the confirmation code from the user and pass to confirmResetPassword.
        break;
      case 'DONE':
        console.log('Successfully reset password.');
        break;
    }
  }
  checkUser() {
    this.api.getOdata(`/users(${this.id})`).executeQuery(new Query()).then((e: ReturnOption) => {
      const item = (e.result as any)[0] as any
      this.username = item.Username
      this.name = item.Name
      this.title = item.Title
    }).catch((e) => true);
  }
}
