import { Component, Input, OnInit, Output, EventEmitter, HostListener, effect, WritableSignal, signal, AfterViewInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, ReactiveFormsModule, Validators, FormGroup, FormControl } from '@angular/forms';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { EntityType } from '@shared/models/entity-types';
import { CommentThread } from '@models/data-contracts';
import { faHeading, faAlignLeft, faListCheck } from '@fortawesome/free-solid-svg-icons';
import { FormFieldComponent } from '@shared/components/form-field/form-field.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TextBoxAllModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownListAllModule } from '@syncfusion/ej2-angular-dropdowns';

@Component({
  selector: 'thread-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    TextBoxModule,
    DropDownListModule,
    FontAwesomeModule,
    TextBoxAllModule,
    DropDownListAllModule,
    FormFieldComponent
  ],
  templateUrl: './comment-thread-form.component.html',
  styleUrl: './comment-thread-form.component.scss'
})
export class ThreadFormComponent implements AfterViewInit {

  @Input() thread?: CommentThread;
  @Input() entityType!: EntityType;
  @Input() entityId!: number;
  @Input() commentThread!: WritableSignal<CommentThread | undefined>;
  @Output() formSubmitted = new EventEmitter<void>();
  @Output() formCancelled = new EventEmitter<void>();

  form: FormGroup = new FormGroup({
    Id: new FormControl(0),
    Title: new FormControl('', Validators.required),
    Description: new FormControl(''),
    Status: new FormControl('Open', Validators.required)
  });
  icons = {
    title: faHeading,
    description: faAlignLeft,
    status: faListCheck
  };
  formClasses = {
    formContainer: 'cc-form-container',
    form: 'cc-form flex-column',
    subForm: 'cc-sub-form',
    mainTitle: 'cc-main-title',
    section: 'cc-form-section',
    group: 'cc-form-group row',
    inputContainer: 'cc-input-container',
    label: 'cc-label',
    input: 'cc-input',
    icon: 'cc-input-icon',
    error: 'cc-input-error',
    actions: 'cc-form-actions',
    twoCol: 'col-12',
    threeCol: 'col-12'
  };
  threadHTMLElement: Element | null = null;

  constructor(
    private fb: FormBuilder
  ) {
    // Update the form with the comment thread when it changes
    effect(() => {
      if (this.commentThread()) {
        this.form.patchValue(this.commentThread() as Partial<CommentThread>);
      }
    });
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.formSubmitted.emit();
    }
  }

  onCancel(): void {
    this.form.reset();
    this.formCancelled.emit();
  }

  ngAfterViewInit() {
    this.threadHTMLElement = document.querySelector('thread-form');
    this.updateWidth(this.threadHTMLElement?.clientWidth || window.innerWidth);
    this.watchInputElements();
  }

  // Update the layout on window resize
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    const containerWidth = this.threadHTMLElement?.clientWidth || window.innerWidth;
    this.updateWidth(containerWidth);
  }

  // Switches form to 2 columns when parent is larger 1600px
  updateWidth(containerWidth: number) {

    if (containerWidth > 576 && containerWidth < 768) {
      this.formClasses.form = this.formClasses.form.replace('flex-column', 'flex-row flex-wrap');
      this.formClasses.twoCol = this.formClasses.twoCol.replace('col-12', 'col-6');
      this.formClasses.threeCol = this.formClasses.threeCol.replace('col-12', 'col-6');
    } else if (containerWidth > 768) {
      this.formClasses.form = this.formClasses.form.replace('flex-column', 'flex-row flex-wrap');
      this.formClasses.twoCol = this.formClasses.twoCol.replace('col-12', 'col-6');
      this.formClasses.threeCol = this.formClasses.threeCol.replace('col-12', 'col-4');
    } else {
      this.formClasses.form = this.formClasses.form.replace('flex-row', 'flex-column');
      this.formClasses.twoCol = this.formClasses.twoCol.replace('col-6', 'col-12');
      this.formClasses.threeCol = this.formClasses.threeCol.replace('col-4', 'col-12');
    }
  }

  // Perform updates on input elements
  watchInputElements() {
    document.querySelectorAll(`.cc-input-container`).forEach((inputContainer) => {
      const container = inputContainer as HTMLElement;

      container.querySelectorAll('input, span').forEach((input) => {
        input.addEventListener('focus', () => inputContainer.classList.add('focus'));
        input.addEventListener('blur', () => inputContainer.classList.remove('focus'));
      });
    });
  }
} 