import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Query } from '@syncfusion/ej2-data';

const fb = new FormBuilder();

export const lawFirmForm: FormGroup = fb.group({
    Name: [null, [Validators.required]],
    Abbreviation: [null],
    MarketManager: [null],
    CaseManager: [null],
    StatusGroupId: [null],
    DocumentLink: [null],
    BoxUserId: [null],
    BoxFolderId: [null],
    Notes: [null],
    NotesImportant: [false],
    Website: [null],
    TaxId: [null],
    Rating: [null],
    AlternateId: [null],
    IsActive: [true],
    NotifyContactOnApptDone: [true],
    XrefAddressLawfirms: [null],
    XrefPhoneLawfirms: [null],
    WebsiteUrl: [null]
});

export const editLawFirmForm: FormGroup = fb.group({
    Name: [null, [Validators.required]],
    Abbreviation: [null],
    MarketManager: [null],
    CaseManager: [null],
    DocumentLink: [null],
    Website: [null],
    Rating: [null],
    XrefAddressLawfirms: [null],
    XrefPhoneLawfirms: [null],
    WebsiteUrl: [null]
});