<div id="main-nav">
    <ejs-menu #mainNav id="main-nav" [cssClass]="" [items]="mainNavItems" [hamburgerMode]="globals.isResponsive"
        [animationSettings]='animationSettings' (created)="onCreated()" (beforeItemRender)="beforeItemRender($event)"
        (select)="select($event)">
        <ng-template #template let-data>
            @if (isSideBarSmall && data.top === true) {
                <ejs-tooltip id='tooltip' content={{data.text}} position="RightCenter" [animation]='TooltipAnimation'>
                    @if (!data.separator && data.faIcon) {
                        <div class="custom-menu-item" #item>
                            <fa-icon class="custom-menu-item-icon" [icon]="data.faIcon" />
                            <span class="custom-menu-item-text e-text">{{data.text}}</span>
                        </div>
                    }
                </ejs-tooltip>
            } @else {
                @if (!data.separator && data.faIcon) {
                    <div class="custom-menu-item" #item>
                        <fa-icon class="custom-menu-item-icon" [icon]="data.faIcon" />
                        <span class="custom-menu-item-text e-text">{{data.text}}</span>
                    </div>
                }
            }
        </ng-template>
    </ejs-menu>
</div>