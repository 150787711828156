<div id="bulk-edit-case-files">

    <form id="bulk-edit-case-files-form" [formGroup]="bulkEditCaseFilesForm" #formDir="ngForm" class="row d-flex flex-row">

        <div class="form-group col-12 col-sm-6 col-xl-4">
            <label class="custom-template-label">Case Manager</label>
            <ejs-dropdownlist [dataSource]="users" [fields]="usersFields" [query]="caseManagersQuery" formControlName="CaseManager"></ejs-dropdownlist>
        </div>
    
        <div class="form-group col-12 col-sm-6 col-xl-4">
            <label class="custom-template-label">Case Types</label>
            <ejs-dropdownlist [dataSource]="caseTypes" formControlName="CaseType"></ejs-dropdownlist>
        </div>
    
        <div class="form-group col-12 col-sm-6 col-xl-4">
            <label class="custom-template-label">File Group</label>
            <ejs-dropdownlist [dataSource]="fileGroups" [fields]="keyValueFields" formControlName="FileGroup"></ejs-dropdownlist>
        </div>
    
        <div class="form-group col-12 col-sm-6 col-xl-4">
            <label class="custom-template-label">Rating</label>
            <ejs-dropdownlist [dataSource]="fileRating" [fields]="fileRatingFields" formControlName="FileRating"></ejs-dropdownlist>
        </div>
    
        <div class="form-group col-12 col-sm-6 col-xl-4">
            <label class="custom-template-label">File Status</label>
            <ejs-dropdownlist [dataSource]="fileStatus" [fields]="keyValueFields" formControlName="FileStatus"></ejs-dropdownlist>
        </div>
    
        <div class="form-group col-12 col-sm-6 col-xl-4">
            <label class="custom-template-label">Market Manager</label>
            <ejs-dropdownlist [dataSource]="users" [fields]="usersFields" [query]="marketManagersQuery" formControlName="MarketManager"></ejs-dropdownlist>
        </div>
    
        <div class="form-group col-12 col-sm-6 col-xl-4">
            <label class="custom-template-label">P & L</label>
            <ejs-dropdownlist [dataSource]="pAndL" [fields]="keyValueFields" formControlName="PAndL"></ejs-dropdownlist>
        </div>
    
        <div class="form-group col-12 col-sm-6 col-xl-4">
            <label class="custom-template-label">Referral Source</label>
            <ejs-dropdownlist [dataSource]="referrals" [fields]="referralsFields" formControlName="ReferralSource"></ejs-dropdownlist>
        </div>
    
        <div class="form-group col-12 col-sm-6 col-xl-4">
            <label class="custom-template-label">Referring Physician</label>
            <ejs-dropdownlist [dataSource]="users" [fields]="usersFields" formControlName="ReferringPhysician"></ejs-dropdownlist>
        </div>
    
        <div class="form-group col-12 col-sm-6 col-xl-4">
            <label class="custom-template-label">Statuser</label>
            <ejs-dropdownlist [dataSource]="users" [fields]="usersFields" [query]="statusersQuery" formControlName="Statuser"></ejs-dropdownlist>
        </div>
    
        <div class="form-group col-12 col-sm-6 col-xl-4">
            <label class="custom-template-label">Statusing Group</label>
            <ejs-dropdownlist [dataSource]="fileGroups" [fields]="keyValueFields" formControlName="StatusingGroup"></ejs-dropdownlist>
        </div>
    
        <div class="form-group col-12 col-sm-6 col-xl-4">
            <label class="custom-template-label">Current Status</label>
            <ejs-dropdownlist [dataSource]="currentStatus" [fields]="keyValueFields" formControlName="Status"></ejs-dropdownlist>
        </div>

        <div class="form-group col-12 col-sm-6 col-xl-4">
            <label class="custom-template-label">Active?</label>
            <ejs-checkbox formControlName="IsActive" [label]="bulkEditCaseFilesForm.value.IsActive == true ? 'Yes' : 'No'"></ejs-checkbox>
        </div>

        <div class="form-group col-12 col-sm-6 col-xl-4">
            <label class="custom-template-label">Exclude From Balance Due?</label>
            <ejs-checkbox formControlName="ExcludeFromBalanceDue" [label]="bulkEditCaseFilesForm.value.ExcludeFromBalanceDue== true ? 'Yes' : 'No'"></ejs-checkbox>
        </div>

        <div class="form-group col-12 col-sm-6 col-xl-4">
            <label class="custom-template-label">In Treatment?</label>
            <ejs-checkbox formControlName="InTreatment" [label]="bulkEditCaseFilesForm.value.InTreatment == true ? 'Yes' : 'No'"></ejs-checkbox>
        </div>
    </form>

</div>