<!-- Law Firm Form Start -->
<div id="law-firm-form" [class]="formClasses.formContainer">
    <!-- Add loading overlay -->
    <div *ngIf="loadingStatus().loading" [class]="'loading-overlay ' + loadingStatus().action">
        <div [class]="'loading-content ' + loadingStatus().action">
            <div class="spinner"></div>
            <div class="loading-message">{{ loadingStatus().message }}</div>
        </div>
    </div>

    <form [formGroup]="lawFirmForm" [class]="formClasses.form" (ngSubmit)="onSubmit()">

        <section [class]="formClasses.section + ' col-12 col-lg-6 law-firm-section'">
            
            <div [class]="formClasses.group">

                <div *ngIf="displayFields.includes('Name')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol" [class.error]="lawFirmForm.get('Name')?.errors && lawFirmForm.get('Name')?.touched">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="formIcons.faUser" />
                        <ejs-tooltip *ngIf="lawFirmForm.get('Name')?.errors && lawFirmForm.get('Name')?.touched" [content]="getErrorMessage('Name')" />
                    </span>
                    <ejs-textbox formControlName="Name" 
                        placeholder="Law Firm Name"
                        floatLabelType="Auto"
                        [class]="formClasses.input"
                        [value]="lawFirmForm.get('Name')?.value" />
                    <ng-container *ngIf="lawFirmForm.get('Name')?.invalid && lawFirmForm.get('Name')?.touched">
                        <span [class]="formClasses.error">{{getErrorMessage('Name')}}</span>
                    </ng-container>
                </div>

                <div *ngIf="displayFields.includes('Abbreviation')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="formIcons.faIdCard" />
                    </span>
                    <ejs-textbox formControlName="Abbreviation" 
                        placeholder="Abbreviation" 
                        floatLabelType="Auto"
                        [class]="formClasses.input"
                        [value]="lawFirmForm.get('Abbreviation')?.value" />
                </div>

                <div *ngIf="displayFields.includes('MarketManager')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol" [class.error]="lawFirmForm.get('MarketManager')?.errors && lawFirmForm.get('MarketManager')?.touched">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="formIcons.faUserTag" />
                        <ejs-tooltip *ngIf="lawFirmForm.get('MarketManager')?.errors && lawFirmForm.get('MarketManager')?.touched" [content]="getErrorMessage('MarketManager')" />
                    </span>
                    <ejs-dropdownlist formControlName="MarketManager" 
                        placeholder="Market Manager"
                        floatLabelType="Auto"
                        [class]="formClasses.input"
                        [value]="lawFirmForm.get('MarketManager')?.value"
                        [dataSource]="marketManagers()" 
                        [fields]="fields" 
                        (beforeOpen)="beforeOpenMarketManagerDrpDwn($event)" />
                    <ng-container *ngIf="lawFirmForm.get('MarketManager')?.invalid && lawFirmForm.get('MarketManager')?.touched">
                        <span [class]="formClasses.error">{{getErrorMessage('MarketManager')}}</span>
                    </ng-container>
                </div>
                
                <div *ngIf="displayFields.includes('CaseManager')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol" [class.error]="lawFirmForm.get('CaseManager')?.errors && lawFirmForm.get('CaseManager')?.touched">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="formIcons.faUserTag" />
                        <ejs-tooltip *ngIf="lawFirmForm.get('CaseManager')?.errors && lawFirmForm.get('CaseManager')?.touched" [content]="getErrorMessage('CaseManager')" />
                    </span>
                    <ejs-dropdownlist formControlName="CaseManager" 
                        placeholder="Case Manager"
                        floatLabelType="Auto"
                        [class]="formClasses.input"
                        [value]="lawFirmForm.get('CaseManager')?.value"
                        [dataSource]="caseManagers()" 
                        [fields]="fields" 
                        (beforeOpen)="beforeOpenCaseManagerDrpDwn($event)" />
                    <ng-container *ngIf="lawFirmForm.get('CaseManager')?.invalid && lawFirmForm.get('CaseManager')?.touched">
                        <span [class]="formClasses.error">{{getErrorMessage('CaseManager')}}</span>
                    </ng-container>
                </div>

                <!-- <div *ngIf="displayFields.includes('DocumentLink')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="formIcons.faComments" />
                    </span>
                    <ejs-textbox formControlName="DocumentLink"
                        placeholder="Document Link"
                        type="textarea"
                        [class]="formClasses.input"
                        [multiline]="false"
                        [value]="lawFirmForm.get('DocumentLink')?.value" />
                </div> -->

                <div *ngIf="displayFields.includes('Website') || displayFields.includes('WebsiteUrl')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="formIcons.faGlobe" />
                    </span>
                    <ejs-textbox formControlName="WebsiteUrl"
                        placeholder="Website URL"
                        floatLabelType="Auto"
                        [class]="formClasses.input"
                        [value]="lawFirmForm.get('WebsiteUrl')?.value ?? lawFirmForm.get('Website')?.value" />
                </div>
                
                <div *ngIf="displayFields.includes('Rating')" [class]="formClasses.inputContainer + ' ' + formClasses.twoCol">
                    <span [class]="formClasses.icon">
                        <fa-icon [icon]="formIcons.faStar" />
                    </span>
                    <ejs-textbox formControlName="Rating"
                        placeholder="Rating"
                        floatLabelType="Auto"
                        [class]="formClasses.input"  
                        [value]="lawFirmForm.get('Rating')?.value" />
                </div>

                <section *ngIf="displayFields.includes('XrefPhoneLawfirms')" [class]="formClasses.section + ' phone-section'">
                    <phone-form #phoneFormComp [phone]="lawFirmPhone"  [displayFields]="['PhoneNumber', 'PhoneType']" [displayButtons]="false" />
                </section>

            </div>
        </section>

        <section [class]="formClasses.section + ' ' + formClasses.subForm + ' col-12 col-lg-6 address-section'">
            <address-form #addressFormComp [address]="lawFirmAddress" 
                [displayFields]="['Address1', 'Address2', 'City', 'State', 'Zip', 'County', 'AddressType']" 
                [requiredFields]="['Address1', 'City', 'State', 'Zip', 'AddressType']"
                [displayButtons]="false" />
        </section>
        
        <ng-template #actionsSection *ngIf="!hideActions">
            <section [class]="formClasses.section + ' col-12 actions-section'">
                <div [class]="formClasses.actions">
                    <button ejs-button [isPrimary]="true" type="submit">Submit</button>
                    <button ejs-button type="button" (click)="lawFirmForm.reset()" >Cancel</button>
                </div>
            </section>
        </ng-template>

    </form>

</div>
<!-- Law Firm Form End -->