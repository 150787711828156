<div id="intake-survey">

    @if (loading()) {
        <loading-overlay [loading]="loading" />
    } @else {
        <form #intakeSurveyFormElm id="intake-survey-form" class="row mt-0 flex-row" [formGroup]="intakeSurveyForm" (submit)="onSubmit(currentIntake?.Id || 0)">

            @if (currentIntake) {
        
                @if (currentIntake.FileNumber) {
                    <div class="col col-12 col-sm-6 col-xl-4">
                        <div class="d-flex justify-content-between align-items-center">
                            <label>Existing File #</label>
                            <ejs-textbox [enabled]="false" [value]="currentIntake.FileNumber" />
                        </div>
                    </div>
                }
                
                @if (currentIntake.Lawfirm) {
                    <div class="col col-12 col-sm-6 col-xl-4">
                        <div class="d-flex justify-content-between align-items-center">
                            <label>Law Firm Name</label>
                            <ejs-textbox [enabled]="false" [value]="currentIntake.Lawfirm.Name" />
                        </div>
                    </div>
                }
            
                @if (currentIntake.LawfirmContact) {
                    <div class="col col-12 col-sm-6 col-xl-4">
                        <div class="d-flex justify-content-between align-items-center">
                            <label>Law Firm Contact</label>
                            <ejs-textbox [enabled]="false" [value]="currentIntake.LawfirmContact.ContactName" />
                        </div>
                    </div>
                }
            
                @if (currentIntake.Status) {
                    <div class="col col-12 col-sm-6 col-xl-4">
                        <div class="d-flex justify-content-between align-items-center">
                            <label>Intake Current Status</label>
                            <ejs-textbox [enabled]="false" value="{{currentIntake.Status}}" />
                        </div>
                    </div>
                }
            
                <div class="col col-12 col-sm-6 col-xl-4">
                    <div class="d-flex justify-content-between align-items-center">
                        <label>Select Law Firm</label>
                        <ejs-dropdownlist formControlName="LawfirmId" [dataSource]="lawFirms" [fields]="{value: 'Id', text: 'Name'}"  />
                    </div>
                </div>
            
                <div class="col col-12 col-sm-6 col-xl-4">
                    <div class="d-flex justify-content-between align-items-center">
                        <label>Updated File Number</label>
                        <ejs-textbox formControlName="FileNumber"/>
                    </div>
                </div>
            
                <div class="col col-12 col-sm-6 col-xl-4">
                    <div class="d-flex justify-content-between align-items-center">
                        <label>Select Law Firm Contact</label>
                        <ejs-dropdownlist formControlName="LawfirmContactId" [dataSource]="contacts" [fields]="{value: 'Id', text: 'ContactName'}" />
                    </div>
                </div>
            
                <div class="col col-12 col-sm-6 col-xl-4">
                    <div class="d-flex justify-content-between align-items-center">
                        <label>Attorney State</label>
                        <ejs-dropdownlist [dataSource]="states" [fields]="{ value: 'id', text: 'key' }" />
                    </div>
                </div>
            
                <div class="col col-12 col-sm-6 col-xl-4">
                    <div class="d-flex justify-content-between align-items-center">
                        <label>Attorney Email Address</label>
                        <ejs-textbox />
                    </div>
                </div>
            
                <div class="col col-12 col-sm-6 col-xl-4">
                    <div class="d-flex phone-number">
                        <label>Attorney Phone Number</label>
                        <div class="d-flex flex-row">
                            <ejs-dropdownlist style="width: 20%" #phoneCountryCode [dataSource]="countryCodes" [fields]="{ text: 'description', value: 'code', iconCSS: 'icon' }" class="col-2">
                                <ng-template #itemTemplate let-data>
                                    <span class="{{data.icon}}"> {{data.code}} - {{data.description}}</span>
                                </ng-template>
                                <ng-template #valueTemplate let-data>
                                    <span class="{{data.icon}}"> {{data.code}}</span>
                                </ng-template>
                            </ejs-dropdownlist>
                            <ejs-maskedtextbox style="width: 60%;" mask='000-000-0000' class="col-10"></ejs-maskedtextbox>
                            <ejs-textbox style="width: 20%;" placeholder="Ext." />
                        </div>
                    </div>
                </div>
            
                <div class="col col-12 col-sm-6 col-xl-4">
                    <div class="d-flex justify-content-between align-items-center">
                        <label>Select Patient</label>
                        <ejs-dropdownlist formControlName="PatientId" 
                            [dataSource]="patients" 
                            [fields]="{value: 'Id', text: 'Firstname'}" 
                            [value]="currentIntake.PatientId || ''" 
                            [itemTemplate]="patientItemTemplate"
                            [valueTemplate]="patientValueTemplate">
                            <ng-template #patientItemTemplate let-data>
                                <span>{{data?.Firstname ?? ''}} {{data?.Lastname ?? ''}}</span>
                            </ng-template>
                            <ng-template #patientValueTemplate let-data>
                                <span>{{data?.Firstname ?? ''}} {{data?.Lastname ?? ''}}</span>
                            </ng-template>
                        </ejs-dropdownlist>
                    </div>
                </div>
            
                <div class="col col-12 col-sm-6 col-xl-4">
                    <div class="d-flex justify-content-between align-items-center">
                        <label>Attach New Patient?</label>
                        <ejs-switch 
                            onLabel="Yes"
                            offLabel="No"
                            (change)="toggleNewPatient($event)" />
                    </div>
                </div>
            
                @if (attachNewPatientToggle) {
            
                    <div class="col col-12 col-sm-6 col-xl-4" formGroupName="Patient">
                        <div class="d-flex justify-content-between align-items-center">
                            <label>Client First Name</label>
                            <ejs-textbox formControlName="Firstname" />
                        </div>
                    </div>
                
                    <div class="col col-12 col-sm-6 col-xl-4" formGroupName="Patient">
                        <div class="d-flex justify-content-between align-items-center">
                            <label>Client Last Name</label>
                            <ejs-textbox formControlName="Lastname" />
                        </div>
                    </div>
                
                    <div class="col col-12 col-sm-6 col-xl-4" formGroupName="Patient">
                        <div class="d-flex" formGroupName="Address">
                            <label>Client Address Line 1</label>
                            <ejs-textbox formControlName="Address1" />
                        </div>
                    </div>
                
                    <div class="col col-12 col-sm-6 col-xl-4" formGroupName="Patient">
                        <div class="d-flex" formGroupName="Address">
                            <label>Client Address Line 2</label>
                            <ejs-textbox formControlName="Address2" />
                        </div>
                    </div>
                
                    <div class="col col-12 col-sm-6 col-xl-4" formGroupName="Patient">
                        <div class="d-flex" formGroupName="Address">
                            <label>Client City</label>
                            <ejs-textbox formControlName="City" />
                        </div>
                    </div>
                
                    <div class="col col-12 col-sm-6 col-xl-4" formGroupName="Patient">
                        <div class="d-flex" formGroupName="Address">
                            <label>Client State</label>
                            <ejs-dropdownlist formControlName="State" [dataSource]="states" [fields]="{ value: 'id', text: 'key' }" />
                        </div>
                    </div>
                
                    <div class="col col-12 col-sm-6 col-xl-4" formGroupName="Patient">
                        <div class="d-flex" formGroupName="Address">
                            <label>Client Zip Code</label>
                            <ejs-textbox formControlName="Zip" />
                        </div>
                    </div>
                
                    <div class="col col-12 col-sm-6 col-xl-4">
                        <div class="d-flex justify-content-between align-items-center">
                            <label>Client Phone Number</label>
                            <div class="d-flex flex-row">
                                <ejs-dropdownlist style="width: 20%" #phoneCountryCode [dataSource]="countryCodes" [fields]="{ text: 'description', value: 'code', iconCSS: 'icon' }" class="col-2">
                                    <ng-template #itemTemplate let-data>
                                        <span class="{{data.icon}}"> {{data.code}} - {{data.description}}</span>
                                    </ng-template>
                                    <ng-template #valueTemplate let-data>
                                        <span class="{{data.icon}}"> {{data.code}}</span>
                                    </ng-template>
                                </ejs-dropdownlist>
                                <ejs-maskedtextbox style="width: 80%;"  mask='000-000-0000' class="col-10"></ejs-maskedtextbox>
                            </div>
                        </div>
                    </div>
                
                    <div class="col col-12 col-sm-6 col-xl-4" formGroupName="Patient">
                        <div class="d-flex justify-content-between align-items-center">
                            <label>Client Date of Birth</label>
                            <ejs-datepicker formControlName="Dob" />
                        </div>
                    </div>
                
                    <div class="col col-12 col-sm-6 col-xl-4" formGroupName="Patient">
                        <div class="d-flex justify-content-between align-items-center">
                            <label>Client Language</label>
                            <ejs-dropdownlist formControlName="Language" [dataSource]="languages" />
                        </div>
                    </div>
                }
            
                <div class="col col-12 col-sm-6 col-xl-4">
                    <div class="d-flex justify-content-between align-items-center">
                        <label>P&L</label>
                        <ejs-dropdownlist [dataSource]="pAndLs" [fields]="{value: 'Id', text: 'Description'}" />
                    </div>
                </div>
            
                <div class="col col-12 col-sm-6 col-xl-4">
                    <div class="d-flex justify-content-between align-items-center">
                        <label>Checkpoint Eligible?</label>
                        <ejs-switch 
                            offLabel="No"
                            onLabel="Yes"
                            formControlName="PatientCheckpointEligible" />
                    </div>
                </div>
            
                <div class="col col-12 col-sm-6 col-xl-4">
                    <div class="d-flex justify-content-between align-items-center">
                        <label>Lawfirm File #</label>
                        <ejs-textbox formControlName="LawfirmClaimFileNumber" />
                    </div>
                </div>
            
                <div class="col col-12 col-sm-6 col-xl-4">
                    <div class="d-flex justify-content-between align-items-center">
                        <label>Date of Loss</label>
                        <ejs-datepicker formControlName="ClaimDateOfLoss" />
                    </div>
                </div>
            
                <div class="col col-12 col-sm-6 col-xl-4">
                    <div class="d-flex justify-content-between align-items-center">
                        <label>Statute of Limitations</label>
                        <ejs-textbox formControlName="ClaimStatuteOfLimitations" />
                    </div>
                </div>
            
                <div class="col col-12 col-sm-6 col-xl-4">
                    <div class="d-flex justify-content-between align-items-center">
                        <label>Accident Information</label>
                        <ejs-dropdownlist formControlName="ClaimAccidentInfo" [dataSource]="accidentInfo" [fields]="{value: 'Description', text: 'Description'}" />
                    </div>
                </div>
            
                <div class="col col-12 col-sm-6 col-xl-4">
                    <div class="d-flex justify-content-between align-items-center">
                        <label>Other Accident Information</label>
                        <ejs-textbox formControlName="ClaimOtherAccidentInfo" />
                    </div>
                </div>
            
                <div class="col col-12 col-sm-6 col-xl-4">
                    <div class="d-flex justify-content-between align-items-center">
                        <label>Accident Details</label>
                        <ejs-multiselect formControlName="ClaimAdditionalAccidentInfo" 
                            [dataSource]="accidentDetails"
                            [fields]="{value: 'Id', text: 'Description'}" />
                    </div>
                </div>
            
                <div class="col col-12 col-sm-6 col-xl-4">
                    <div class="d-flex justify-content-between align-items-center">
                        <label>Liability Dispute?</label>
                        <ejs-switch 
                            offLabel="No"
                            onLabel="Yes" 
                            formControlName="ClaimLiabilityDispute" />
                    </div>
                </div>
            
                <div class="col col-12 col-sm-6 col-xl-4">
                    <div class="d-flex justify-content-between align-items-center">
                        <label>Amount of Property Damage</label>
                        <ejs-numerictextbox formControlName="ClaimPropertyDamage" />
                    </div>
                </div>
            
                <div class="col col-12 col-sm-6 col-xl-4">
                    <div class="d-flex justify-content-between align-items-center">
                        <label>Total Special Damages to Date</label>
                        <ejs-numerictextbox formControlName="ClaimSpecialDamage" />
                    </div>
                </div>
            
                <div class="col col-12 col-sm-6 col-xl-4">
                    <div class="d-flex justify-content-between align-items-center">
                        <label>Liability Policy Confirmed in Force?</label>
                        <ejs-switch 
                            onLabel="Yes"
                            offLabel="No"
                            formControlName="InsuranceLiabilityPolicy" />
                    </div>
                </div>
            
                <div class="col col-12 col-sm-6 col-xl-4">
                    <div class="d-flex justify-content-between align-items-center">
                        <label>Liability Policy Limits</label>
                        <ejs-textbox formControlName="InsuranceLiabilityPolicyLimit" />
                    </div>
                </div>
            
                <div class="col col-12 col-sm-6 col-xl-4">
                    <div class="d-flex justify-content-between align-items-center">
                        <label>UM/UIM Policy Confirmed in Force?</label>
                        <ejs-switch 
                            onLabel="Yes"
                            offLabel="No" 
                            formControlName="InsuranceUmPolicy" />
                    </div>
                </div>
            
                <div class="col col-12 col-sm-6 col-xl-4">
                    <div class="d-flex justify-content-between align-items-center">
                        <label>Type of UM/UIM Policy</label>
                        <ul class="radio-buttons">
                            <li><ejs-radiobutton label="Traditional ('Reduced')" formControlName="InsuranceUmPolicyType" [value]="'Traditional'"></ejs-radiobutton></li>
                            <li><ejs-radiobutton label="New ('Added On')" formControlName="InsuranceUmPolicyType" [value]="'New'"></ejs-radiobutton></li>
                        </ul>
                    </div>
                </div>
            
                <div class="col col-12 col-sm-6 col-xl-4">
                    <div class="d-flex justify-content-between align-items-center">
                        <label>UM/UIM Policy Limits</label>
                        <ejs-textbox formControlName="InsuranceUmPolicyLimit" />
                    </div>
                </div>
            
                <div class="col col-12 col-sm-6 col-xl-4">
                    <div class="d-flex justify-content-between align-items-center">
                        <label>MedPay/PIP Policy in Force</label>
                        <ejs-switch 
                            onLabel="Yes"
                            offLabel="No" 
                            formControlName="InsuranceMedpayPolicy" />
                    </div>
                </div>
            
                <div class="col col-12 col-sm-6 col-xl-4">
                    <div class="d-flex justify-content-between align-items-center">
                        <label>MedPay/PIP Policy Limits</label>
                        <ejs-textbox formControlName="InsuranceMedpayPolicyLimit" />
                    </div>
                </div>
            
                <div class="col col-12 col-sm-6 col-xl-4">
                    <div class="d-flex justify-content-between align-items-center">
                        <label>When do you anticipate this claim to settle</label>
                        <ejs-dropdownlist formControlName="InsuranceSettleTerm" [dataSource]="timeline" />
                    </div>
                </div>
            
                <div class="col col-12 col-sm-6 col-xl-4">
                    <div class="d-flex justify-content-between align-items-center">
                        <label>Has your client previously been represented by another law firm on this claim?</label>
                        <ejs-switch formControlName="PreviousAttorneyRepresentation"
                            onLabel="Yes"
                            offLabel="No" />
                    </div>
                </div>
            
                <div class="col col-12 col-sm-6 col-xl-4">
                    <div class="d-flex justify-content-between align-items-center">
                        <label>Has your client already received cash assistance, liens, or loans against this claim?</label>
                        <ejs-switch formControlName="PreviousFunding"
                            onLabel="Yes"
                            offLabel="No" />
                    </div>
                </div>
            
                <div class="col col-12 col-sm-6 col-xl-4">
                    <div class="d-flex justify-content-between align-items-center">
                        <label>Is your client seeking cash assistance, medical payment assistance or both?</label>
                        <ul class="radio-buttons">
                            <li><ejs-radiobutton label="Cash Only" formControlName="AssistanceType" [value]="'Cash Only'"></ejs-radiobutton></li>
                            <li><ejs-radiobutton label="Medical Only" formControlName="AssistanceType" [value]="'Medical Only'"></ejs-radiobutton></li>
                            <li><ejs-radiobutton label="Both" formControlName="AssistanceType" [value]="'Both'"></ejs-radiobutton></li>
                        </ul>
                    </div>
                </div>
            
                <div class="col col-12 col-sm-6 col-xl-4">
                    <div class="d-flex justify-content-between align-items-center">
                        <label>Please enter the treatment your client is seeking funding for (Enter unknown if not known)</label>
                        <ejs-textbox formControlName="RequestedTreatment" />
                    </div>
                </div>
            
                <div class="col col-12 col-sm-6 col-xl-4">
                    <div class="d-flex justify-content-between align-items-center">
                        <label>Additional information regarding the injury</label>
                        <ejs-textbox formControlName="ClaimAdditionalAccidentInfo" />
                    </div>
                </div>
            
                <div class="col col-12 col-sm-6 col-xl-4">
                    <div class="d-flex justify-content-between align-items-center">
                        <label>Has there been a gap in treatment?(>30 days)</label>
                        <ejs-switch formControlName="TreatmentGap" 
                            onLabel="Yes"
                            offLabel="No" />
                    </div>
                </div>
            
                <div class="col col-12 col-sm-6 col-xl-4">
                    <div class="d-flex justify-content-between align-items-center">
                        <label>Are you or your client aware of any pre-existing conditions?</label>
                        <ejs-switch formControlName="PreExistingCondition"
                            onLabel="Yes"
                            offLabel="No" />
                    </div>
                </div>
            
                <div class="col col-12 col-sm-6 col-xl-4">
                    <div class="d-flex justify-content-between align-items-center">
                        <label>Does your client have insurance?</label>
                        <ejs-switch formControlName="HasHealthInsurance"
                            onLabel="Yes"
                            offLabel="No" />
                    </div>
                </div>
            
                <div class="col col-12 col-sm-6 col-xl-4">
                    <div class="d-flex justify-content-between align-items-center">
                        <label>Please provide any additional information you feel would be helpful for evaluating this request</label>
                        <ejs-textbox formControlName="Essay" [multiline]="true" />
                    </div>
                </div>
            
                <div class="col col-12 col-sm-6 col-xl-4">
                    <div class="d-flex justify-content-between align-items-center">
                        <label>Updated Status</label>
                        <ejs-dropdownlist formControlName="Status" [dataSource]="intakeStatuses" />
                    </div>
                </div>

                @if (matchingCasesGrid) {
                    <div class="col-12">
                        <grid-template name="Matching Case Files" [settings]="matchingCasesGrid"></grid-template>
                    </div>
                }
            }
        </form>
    }
</div>