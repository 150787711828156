import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MainContent } from '@layout/main/main.component';
import { OpenFileMenuComponent } from "../../components/ui/open-file-menu/open-file-menu.component";

@NgModule({
  imports: [CommonModule, RouterModule, OpenFileMenuComponent],
  declarations: [MainContent],
  exports: [MainContent]
})
export class MainContentModule {}