// Angular
import { Component, signal, WritableSignal, ViewChild, AfterViewInit, OnInit } from '@angular/core';

// 3rd Party
import { Query } from '@syncfusion/ej2-data';

// Internal
import { Appointment, CaseFile } from '@models/data-contracts';
import { GlobalsService } from '@services/globals/globals.service';
import { SchedulerComponent } from '@root/app/features/scheduler/scheduler.component';
import { TabCardComponent } from '@root/src/app/features/file-hub/components/file-hub-tabs/tab-card/tab-card.component';
import { FileHubService } from '@root/src/app/features/file-hub/services/file-hub.service';
import { LoadingModule } from '@modules/loading.module';
import { CommonModule } from '@angular/common';
import { ComponentBase } from '@core/base/component.base';

@Component({
  selector: 'appointments-tab',
  standalone: true,
  imports: [
    SchedulerComponent,
    TabCardComponent,
    LoadingModule,
    CommonModule,
  ],
  templateUrl: './appointments-tab.component.html',
  styleUrl: './appointments-tab.component.scss'
})
export class AppointmentsTab extends ComponentBase implements OnInit, AfterViewInit {
  @ViewChild(SchedulerComponent) schedulerComponent: SchedulerComponent;

  /**
   * Main functionality can be found in the FileHubService.
   * @see {@link FileHubService} for more info
   */
  constructor(
    public fileHub: FileHubService,
    private globals: GlobalsService,
  ) {
    super();
  }

  loadingAppointments: WritableSignal<boolean> = signal(true);
  appointments: Appointment[];

  ngOnInit() {
    // Start with loading indicator active
    this.loadingAppointments.set(true);
  }

  ngAfterViewInit() {
    // Load appointments data first, then initialize calendar
    this.loadAppointmentsData();
  }

  // Keep the method but optimize it to avoid redundant work
  async loadAppointmentsData() {
    this.loadingAppointments.set(true);
    
    try {
      if (!this.fileHub.caseFileId) {
        throw new Error('Case file ID is required');
      }
      
      // NOTE; The scheduler component will handle loading the appointments on its won, but this is required still the way the tabs work.  
      return this.appointments;
    } catch (error) {
      return [];
    } finally {
      this.loadingAppointments.set(false);
    }
  }
}
