import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SidebarAllModule } from '@syncfusion/ej2-angular-navigations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MainNavComponent } from '@ui/main-nav/main-nav.component';
import { HeaderContent } from '@layout/header/header.component';
import { MainContent } from '@layout/main/main.component';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';

@NgModule({
  imports: [
    CommonModule,
    MainNavComponent,
    SidebarAllModule,
    FontAwesomeModule,
    MainContent,
    GridModule,
    ButtonModule
  ],
  declarations: [HeaderContent],
  exports: [HeaderContent]
})
export class HeaderContentModule { }
