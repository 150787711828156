import { GridComponent } from "@syncfusion/ej2-angular-grids";
import { Column, ColumnModel, DetailDataBoundEventArgs, Grid, RowRenderingDirection } from "@syncfusion/ej2-grids";
import { GridTemplateComponent } from "./grid-template/grid-template.component";
import { Query } from "@syncfusion/ej2-data";
import { formatDate } from "@angular/common";

export interface GridDetailsConfig {
  header: string;
  columns: ColumnModel[];
  dataKey?: string
  renderMode?: RowRenderingDirection
}

// creates grid div, used for expanding details on a grid 
export const createGridDiv = (headerText: string, dataSource: any[], columns: any[], renderingMode: RowRenderingDirection) => {
  const gridDiv = document.createElement('div');
  const id = headerText.toLowerCase().replace(/\s+/g, '-') + '-div'
  gridDiv.setAttribute('id', id);
  // gridDiv.setAttribute('style', 'width: auto; padding: 1rem;');

  const gridHeader = document.createElement('h4');
  gridHeader.innerHTML = headerText;
  gridHeader.setAttribute('style', 'margin: 0; width: 100%');

  const processedDataSource = Array.isArray(dataSource)
    ? dataSource
    : (dataSource != null ? [dataSource] : []);


  const grid = new Grid({
    dataSource: processedDataSource,
    editSettings: { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Dialog' },
    toolbar: [{ template: `<span class="h4">${headerText}</span>` }, 
      'ColumnChooser'
    ],
    allowFiltering: true,
    columns,
    rowRenderingMode: renderingMode,
    enableAdaptiveUI: true,
  });
  grid.enableAdaptiveUI = true

  if (processedDataSource.length > 0) {
      grid.appendTo(gridDiv as HTMLElement)
      // gridDiv.classList.add("e-bigger")
  } else {
      gridHeader.innerHTML = `No ${headerText}`;
      gridDiv.appendChild(gridHeader);
  }

  return gridDiv;
};

export function onGridDetailDataBound(e: DetailDataBoundEventArgs, gridsConfig: GridDetailsConfig[]) {
  const detailDiv = document.createElement('div');
  detailDiv.setAttribute('id', 'detail-div');
  gridsConfig.forEach(gridConfig => {
    const { columns, header, dataKey, renderMode} = gridConfig;
    const data = dataKey ? (e.data as any)[dataKey] : (e.data as any)
    const gridDiv = createGridDiv(header, data, columns, renderMode || "Horizontal");
    detailDiv.appendChild(gridDiv);
  });

  ((e.detailElement as HTMLElement).querySelector('.custom-details') as HTMLElement).appendChild(detailDiv);
  return;
}

export const toggleActive = (grid: GridTemplateComponent, id: string, showActive: boolean, query: Query): boolean => {
  // Flip the current active state
  const newShowActive = !showActive;
  if (grid?.grid) {
    // Create a new query that filters records based on 'IsActive' field
    const newQuery = new Query()
      .where('IsActive', 'equal', newShowActive);
    
    // Preserve any existing expand operations from the original query
    // (expand is used when the grid needs to show related data)
    if (query.expand) {
      const expandQuery = (query.expand as any).tables;
      if (expandQuery) {
        newQuery.expand(expandQuery);
      }
    }
    // Apply the new query to the grid
    grid.grid.setProperties({ query: newQuery });
    // Update the toolbar button text to reflect the new state
    // Map through the toolbar and update the button text 
    grid.grid.toolbar = grid.grid.toolbar.map((item: any) => 
      item.id === id 
        ? { ...item, text: newShowActive ? 'Show Inactive' : 'Show Active' }
        : item
    );
    // Refresh the grid to show the filtered data
    grid.grid.refresh();
  }
  // Return the new state for external tracking
  return newShowActive;
}

