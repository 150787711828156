// Angular
import { Component, ViewChild } from '@angular/core';
import { ReactiveFormsModule, Validators, FormBuilder } from '@angular/forms';

// 3rd Party
import { Dialog } from '@syncfusion/ej2-popups';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { TextBoxModule, NumericTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { MultiSelectModule, ComboBoxAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { ListViewModule } from '@syncfusion/ej2-angular-lists';
import { ButtonModule, CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';

// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { DropdownSingleComponent } from "../../../ui/dropdown-single/dropdown-single.component";
import { ApiService } from '@root/src/app/shared/services/api/api.service';
import { Provider, ProviderUpdateDTO } from '@models/data-contracts';

@Component({
  selector: 'app-bulk-edit-providers',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    DialogModule,
    TextBoxModule,
    NumericTextBoxModule,
    MultiSelectModule,
    ComboBoxAllModule,
    ListViewModule,
    CheckBoxModule,
    DatePickerModule,
    DropdownSingleComponent,
    ButtonModule,
  ],
  templateUrl: './bulk-edit-providers.component.html',
  styleUrl: './bulk-edit-providers.component.scss'
})
export class BulkEditProvidersComponent {
  constructor(
    private fb: FormBuilder,
    private api: ApiService,
  ) { }

  @ViewChild('Modal') modal: Dialog;
  selectedRows: Provider[] = [];

  // Dropdowns
  providerTypes = APIEndpoints.ProviderTypes;

  editForm = this.fb.group({
    ReimbursementRate: [null],
    ReimbursementRateEffectiveDate: [null],
    XrefProviderProviderTypes: [null],
    SignedPurchaseAgreement: [false],
    SignedHipaa: [false],
    SignedW9: [false]
  });

  showModal() {
    console.log('Opening modal');
    this.modal.show();
  }

  beforeModalOpened(args: any) {
    console.log('Modal opening', args);
    args.maxHeight = '85vh';
  }

  submit = async () => {
    console.log('Submit called');
    try {
      console.log('Selected rows:', this.selectedRows);
      const changedProviders: ProviderUpdateDTO[] = this.selectedRows.map(provider => ({
        Id: provider.Id,
        ...this.getChangedValues()
      }));
      console.log('Changed providers:', changedProviders);

      const syncFusionDTO = {
        Action: "Update",
        Added: [],
        Changed: changedProviders,
        Deleted: []
      };
      console.log('Sending DTO:', syncFusionDTO);

      const response = await this.api.basicPost(APIEndpoints.ProvidersBulk, syncFusionDTO);
      console.log('API Response:', response);
      
      this.modal.hide();
      window.location.reload();
    } catch (error) {
      console.error('Error in submit:', error);
    }
  }

  private getChangedValues(): Partial<ProviderUpdateDTO> {
    console.log('Getting changed values');
    const changes: Partial<ProviderUpdateDTO> = {};
    const formValues = this.editForm.value;
    console.log('Form values:', formValues);

    if (formValues.ReimbursementRate !== null) {
      changes.ReimbursementRate = formValues.ReimbursementRate;
    }
    if (formValues.ReimbursementRateEffectiveDate !== null) {
      changes.ReimbursementRateEffectiveDate = formValues.ReimbursementRateEffectiveDate;
    }
    if (formValues.XrefProviderProviderTypes !== null) {
      changes.XrefProviderProviderTypes = formValues.XrefProviderProviderTypes;
    }
    if (formValues.SignedPurchaseAgreement !== null) {
      changes.SignedPurchaseAgreement = formValues.SignedPurchaseAgreement ? 1 : 0;
    }
    if (formValues.SignedHipaa !== null) {
      changes.SignedHipaa = formValues.SignedHipaa ? 1 : 0;
    }
    if (formValues.SignedW9 !== null) {
      changes.SignedW9 = formValues.SignedW9 ? 1 : 0;
    }

    console.log('Changes to be applied:', changes);
    return changes;
  }

  onSubmit() {
    console.log('onSubmit called');
    console.log('Form state:', this.editForm);
    console.log('Form values:', this.editForm.value);
    console.log('Form valid:', this.editForm.valid);
    this.submit();
  }
} 
