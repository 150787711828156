import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataUtilsService {

  constructor() { }

  updateArrayItemsWithMatchingIds<T extends { id: string }>(array: T[], updates: Partial<T>[],idField: keyof T = 'id'): T[] {
    const currentArray = [...array];
    
    updates.forEach(update => {
      const item = currentArray.find(item => item[idField] === update[idField]);
      if (item) Object.assign(item, update);
    });

    return currentArray;
  }
}
