<div id="financial-tab">

	<!-- Upper Section -->
	<div class="section financial-section" *ngIf="showFinancialSection">
		<div class="row">

			<div class="col-12 col-md-7 col-xl-5 position-relative">

				<tab-card class="tab-card" [heading]="'Financial Performance'" [loadingContent]="loadingFinancialPerformanceData">
					<div class="loading-overlay-container" *ngIf="!performanceData; else performanceDataSection">
						<loading-overlay [loading]="loadingFinancialPerformanceData" />
					</div>
					<ng-template #performanceDataSection>
						<div class="performance-data d-flex flex-row flex-wrap">
							<div class="item d-flex flex-nowrap"><label>Total Billed Cost: </label><span>${{performanceData?.TotalBilledCost}}</span></div>
							<div class="item d-flex flex-nowrap"><label>Total Payments: </label><span>${{performanceData?.TotalPaymentReceived}}</span></div>
							<div class="item d-flex flex-nowrap"><label>Settlement Cost: </label><span>${{performanceData?.ActualSettlementCost}}</span></div>
							<div class="item d-flex flex-nowrap"><label>Balance Due: </label><span>${{performanceData?.BalanceDue}}</span></div>
							<div class="item d-flex flex-nowrap"><label>Authorized Amount: </label><span>${{performanceData?.AuthorizedAmount}}</span></div>
							<div class="item d-flex flex-nowrap"><label>Days Open: </label><span>{{performanceData?.DaysOpen}}</span></div>
							<div class="item d-flex flex-nowrap"><label>Settlement Value: </label><span>${{performanceData?.FullSettlementValue}}</span></div>
							<div class="item d-flex flex-nowrap"><label>Profit: </label><span>${{performanceData?.Profit}}</span></div>
							<div class="item d-flex flex-nowrap"><label>Non-SOCAL Invoices: </label><span>{{performanceData?.NonSOCALInvoices ? '$' + performanceData?.NonSOCALInvoices : 'Unknown'}}</span></div>
							<div class="item d-flex flex-nowrap"><label>ROIC: </label><span>{{performanceData?.ROIC}}</span></div>
							<div class="item d-flex flex-nowrap">
								<label>XIRR: </label>
								<span>{{xirrValue !== null ? xirrValue.toFixed(4) : 'Insufficient transactions'}}</span>
							</div>
							<div class="item d-flex flex-nowrap"><label>Final Balance Generated?: </label><span>{{performanceData?.FinalBalanceDueGenerated}}</span></div>
							<div class="item item-reduction">
								<div class="row d-flex flex-nowrap">
									<div class="col-6 d-flex">
										<label>Reduction: </label><span>${{performanceData?.CourtesyReduction}}</span>
									</div>
									<div class="col-6 d-flex flex-row flex-nowrap justify-content-between">
										<label>(or %): </label><span>{{performanceData?.CourtesyReductionPercent}}</span>
									</div>
								</div>
							</div>
							<div class="item d-flex flex-nowrap"><label>Final FSV: </label><span>${{performanceData?.FinalFSV}}</span></div>
						</div>
					</ng-template>
				</tab-card>

			</div>

			<div class="col-12 col-md-5 col-xl-3">

				<div class="row">

					<div class="col-12">
						<tab-card [heading]="'Reductions'" [loadingContent]="loadingFinancialPerformanceData">
							<div class="reductions-data-container" *ngIf="reductionsData">
								<div id="reductions-data" class="reductions-data">
									<ejs-accordion>
										<e-accordionitems>
											<e-accordionitem *ngFor="let request of reductionsData; let i = index">
												<ng-template #header>
													<div class="row">
														<div class="col-12 col-sm-6">
															<div><label>Requested: </label><span>${{request.RequestedAmount}}</span></div>
														</div>
														<div class="col-12 col-sm-6">
															<div><label>Status: </label><span><status [status]="request.Status ?? ''"></status></span></div>
														</div>
													</div>
												</ng-template>
												<ng-template #content>
													<div class="request">
														<div class="row">
															<div [class]="setInfoClass()">
																<div class="info"><label>Status: </label><span><status [status]="request.Status ?? ''"></status></span></div>
															</div>
															<div [class]="setInfoClass()">
																<div class="info"><label>Approved: </label><span>${{request.ApprovedAmount}}</span></div>
															</div>
															<div [class]="setInfoClass()">
																<div class="info"><label>Approved %: </label><span>{{request.ApprovedPercentage}}%</span></div>
															</div>
															<div [class]="setInfoClass()">
																<div class="info"><label>Requested: </label><span>${{request.RequestedAmount}}</span></div>
															</div>
															<div [class]="setInfoClass()">
																<div class="info"><label>Requested %: </label><span>{{request.RequestedPercentage}}%</span></div>
															</div>
															<div [class]="setInfoClass()">
																<div class="info"><label>Approved By: </label><span>{{request.ApprovedByName}}</span></div>
															</div>
															<div [class]="setInfoClass()">
																<div class="info"><label>Explained: </label><span>{{request.RequesterExplanation}}</span></div>
															</div>
															<div [class]="setInfoClass()">
																<div class="info"><label>Comments:</label><span>{{request.ApprovedComment}}</span></div>
															</div>
														</div>
													</div>
												</ng-template>
											</e-accordionitem>
										</e-accordionitems>
									</ejs-accordion>
								</div>
							</div>
						</tab-card>
					</div>

					<div class="col-12">
						<tab-card [heading]="'Balance Statements'" [loadingContent]="loadingFinancialPerformanceData">
							<div class="balances-data">
								<div class="row flex-row">
									<div class="col-12">
										<button ejs-button class="bg-tertiary" (click)="generateBalanceStatement.show()">Generate/View</button>
									</div>
									<!-- TODO discuss if we need this folder in future -->
									<!-- <div class="col-6">
										<button ejs-button class="bg-tertiary" (click)="viewBalanceStatement.show()">View</button>
									</div>  -->
									
								</div>
							</div>
						</tab-card>
					</div>
				</div>
			</div>
			
			<div class="col-12 col-md-6 col-xl-4">
				<tab-card class="tab-card" [heading]="'Deposits'" [loadingContent]="loadingFinancialPerformanceData">
					<div class="grid-container" *ngIf="depositsGrid">
						<grid-template [settings]="depositsGrid" />
					</div>
				</tab-card>
			</div>

		</div>
	</div>

	<!-- Divider -->
	<div class="section-divider" (click)="toggleSections()">
		<div class="divider-content">
			<span class="divider-text">
				{{ showFinancialSection ? 'Show Invoices' : 'Show Financial Info' }}
			</span>
			<span class="divider-icon">
				{{ showFinancialSection ? '▼' : '▲' }}
			</span>
		</div>
	</div>

	<!-- Lower Section -->
	<div class="section invoices-section" *ngIf="!showFinancialSection">
		<div class="row">
			<div class="col-12">
				<tab-card [heading]="'Provider Invoices'" [loadingContent]="loadingFinancialPerformanceData">
					<div class="grid-container" *ngIf="invoicesGrid">
						<grid-template [settings]="invoicesGrid" />
					</div>
				</tab-card>
			</div>
		</div>
	</div>

	<!-- Dialogs -->
	<ejs-dialog id="generateBalanceStatement" #generateBalanceStatement 
		header="Generate Balance Statement"
		[target]="'main'"
		[showCloseIcon]="true"
		[isModal]="true"
		[closeOnEscape]="true"
		[visible]="false"
		[width]="'90%'">
		<app-generate-balance-statement [caseFileId]="fileHub.caseFile?.Id ?? 0" />
	</ejs-dialog>
	<ejs-dialog id="viewBalanceStatement" #viewBalanceStatement *ngIf="viewBalanceStatementVisibility"
		header="View Balance Statement"
		[target]="'main'"
		[showCloseIcon]="true"
		[isModal]="true"
		[closeOnEscape]="true"
		[visible]="viewBalanceStatementVisibility"
		(close)="viewBalanceStatementVisibility = false"
		[width]="'90%'">
		<app-view-balance-statement [caseFileId]="fileHub.caseFile?.Id ?? 0" />
	</ejs-dialog>
	<ejs-dialog id="transferInvoice" #transferInvoice *ngIf="transferInvoiceVisibility"
		header="Transfer Invoice"
		[showCloseIcon]="true"
		[isModal]="true"
		[closeOnEscape]="true"
		[visible]="transferInvoiceVisibility"
		(close)="transferInvoice.hide()"
		[width]="'90%'">
		<transfer-invoice 
			[selectedInvoices]="selectedInvoices" 
			(submit)="handleSubmitComplete()"
		></transfer-invoice>
	</ejs-dialog>
	<ejs-dialog id="addNewInvoice" #addNewInvoice
		[header]="editingInvoice() ? 'Edit Invoice' : 'Add Invoice'"
		[target]="'main'"
		[showCloseIcon]="true"
		[isModal]="true"
		[closeOnEscape]="true"
		[visible]="false"
		(close)="handleSubmitComplete()"
		[width]="'90%'">
		<add-invoice-form
			(submit)="handleSubmitComplete()"
			(warningsChanged)="onInvoiceWarningsChanged($event)"
			[invoice]="editingInvoice()"
		>
		</add-invoice-form>	
	</ejs-dialog>
	<ejs-dialog id="addDeposit" #addDeposit *ngIf="addDepositVisibility"
		[header]="editingDeposit() ? 'Edit Deposit' : 'Add Deposit'"
		[showCloseIcon]="true"
		[isModal]="true"
		[closeOnEscape]="true"
		[visible]="addDepositVisibility"
		[width]="600"
		[target]="'#financial-tab'"
		(close)="handleDialogClose()">
		<ng-template #content>
			<add-deposit-form 
				[caseFileId]="fileHub.caseFile?.Id ?? 0"     
				[deposit]="editingDeposit()"
				(close)="handleDialogClose()"	
				(submit)="handleSubmitComplete()"
			></add-deposit-form>
		</ng-template>
	</ejs-dialog>

<ejs-dialog #logsDialog [visible]="logsDialogVisibility" 
    [header]="'Invoice Logs'" 
    [buttons]="logsDialogButtons"
    [target]="'body'"
    [isModal]="true"
    [showCloseIcon]="true"
    [width]="'60%'"
    [height]="'60vh'"
    (beforeOpen)="beforeOpening($event)">
    <ng-template #content>
        <div class="dialog-content logs-dialog-content">
            <app-audit-logs 
                [logs]="auditLogs"
                [loading]="auditLogsLoading">
            </app-audit-logs>
        </div>
    </ng-template>
</ejs-dialog>

</div>