// Angualr
import { Component, computed, effect, Input, signal, SimpleChanges, ViewChild, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';

// 3rd Party
import { GridModel } from '@syncfusion/ej2-angular-grids';
import { DialogAllModule } from '@syncfusion/ej2-angular-popups';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';

// Services
import { CommentsGridService } from '../../services/comments-grid.service';

// Components
import { LoadingModule } from '@modules/loading.module';
import { GridTemplateComponent } from '@components/grids/grid-template/grid-template.component';
import { ThreadFormComponent } from '@shared/features/comments/components/threads/comment-thread-form/comment-thread-form.component';
import { CommentFormComponent } from '@shared/features/comments/components/comments/comment-form/comment-form.component';
import { ToastMessageService } from '@services/toast-message/toast-message.service';

@Component({
  selector: 'comments-grid',
  standalone: true,
  imports: [
    CommonModule,
    DialogAllModule,
    LoadingModule,
    GridTemplateComponent,
    ThreadFormComponent,
    CommentFormComponent
  ],
  templateUrl: './comments-grid.component.html',
  styleUrl: './comments-grid.component.scss'
})
export class CommentsGridComponent {

  // Decorators
  @Input() caseFileId: number;
  @ViewChild('formDialog') formDialog!: DialogComponent;
  @ViewChild('commentsFormComponent') commentsFormComponent!: ThreadFormComponent;

  // Signals
  protected readonly loadingData = computed(() => this.commentsGridService.loading.Comments());
  protected readonly comments = computed(() => this.commentsGridService.data.Comments());

  // Public
  commentsGridSettings: GridModel;
  dialogButtons = [
    { 
      click: () => {
        this.cancelCommentForm();
        this.formDialog.hide();
      },
      buttonModel: { content: 'Cancel', cssClass: 'e-outline' }
    },
    { 
      click: () => {
        this.submitCommentForm();
      },
      buttonModel: { content: 'Save', isPrimary: true }
    }
  ]

  // Services 
  constructor(
    private commentsGridService: CommentsGridService,
    private toast: ToastMessageService
  ) {}

  // Ensure there is a case file ID before initializing the grid
  ngOnChanges(changes: SimpleChanges) {
    if (changes['caseFileId'] && this.caseFileId) {
      this.initializeGrid();
    }
  }

  async initializeGrid() {

    try {

      await this.commentsGridService.fetchComments(this.caseFileId);
      this.commentsGridSettings = {
        dataSource: this.comments(),
        columns: [
          { field: 'Id' },
          { field: 'Date' },
          { field: 'Type' }, 
          { field: 'Comment' }
        ],
        sortSettings: { columns: [{ field: 'Date', direction: 'Descending' } ]},
        toolbarClick: ($event: any) => {
          if ($event.item.id === 'add') {
            $event.cancel = true;
            this.commentsFormComponent?.form.reset();
            this.formDialog.header = 'Add Comment';
            this.formDialog.show();
          }
        },
        commandClick: ($event: any) => {
          if ($event.commandColumn.type === 'Edit') {
            $event.cancel = true;
            this.commentsFormComponent?.form.setValue($event.rowData);
            this.formDialog.header = 'Edit Comment';
            this.formDialog.show();
          }
        }
      };

      return this.commentsGridSettings;

    } catch (error) {
      console.error('CommentsGrid: Error loading data', error);
      return error;
      
    } finally {
      this.commentsGridService.loading.Comments.set(false);
      return;
    }
  }

  submitCommentForm() {

    if (this.commentsFormComponent.form.valid && this.caseFileId) {
      this.commentsGridService.submitCommentForm(this.commentsFormComponent.form.value, this.caseFileId);
      this.toast.showSuccess('Comment saved successfully');
      this.formDialog.hide();
    } else {
      this.commentsGridService.cancelCommentForm(this.commentsFormComponent.form);
      this.toast.showError('Please fill out all required fields');
    }
  }

  cancelCommentForm() {
    this.commentsGridService.cancelCommentForm(this.commentsFormComponent.form);
  }
}
