import { Component, Inject, Type, ViewChild, ViewContainerRef, ComponentRef, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { BaseDialogComponent } from './base-dialog.component';
import { BaseFormMaterialComponent } from './base-form-material.component';

interface DialogData<T> {
  component: Type<T>;
  title: string;
  submitLabel: string;
  formData?: any;
  providers?: any[];
  cptCodes?: any[];
  [key: string]: any;  // Allow other properties
}

@Component({
  selector: 'dialog-wrapper',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule
  ],
  template: `
    <div class="dialog-container">
      <div mat-dialog-title class="dialog-header">
        <h2>{{ title }}</h2>
        <button mat-icon-button (click)="close()" class="close-button">
          <mat-icon>close</mat-icon>
        </button>
      </div>

      <mat-dialog-content class="dialog-content">
        <ng-template #contentContainer></ng-template>
      </mat-dialog-content>

      <mat-dialog-actions align="end" class="dialog-actions">
        <button mat-flat-button (click)="cancel()">Cancel</button>
        <button mat-flat-button color="primary" [disabled]="isSubmitDisabled" (click)="submit()">
          {{ submitLabel }}
        </button>
      </mat-dialog-actions>
    </div>
  `,
  styleUrls: ['./base-dialog.component.scss']
})
export class DialogWrapperComponent extends BaseDialogComponent implements OnInit {
  @ViewChild('contentContainer', { read: ViewContainerRef, static: true })
  contentContainer!: ViewContainerRef;

  private componentRef: ComponentRef<BaseFormMaterialComponent>;

  constructor(
    dialogRef: MatDialogRef<DialogWrapperComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogData: DialogData<BaseFormMaterialComponent>
  ) {
    super(dialogRef);
    this.title = dialogData.title;
    this.submitLabel = dialogData.submitLabel;
  }

  ngOnInit() {
    this.loadComponent();
  }

  private loadComponent() {
    this.contentContainer.clear();
    this.componentRef = this.contentContainer.createComponent(this.dialogData.component);
    
    // Pass all data except the component itself
    const { component, ...componentData } = this.dialogData;
    Object.assign(this.componentRef.instance, componentData);
  }

  override submit(): void {
    if (this.componentRef.instance.canSubmit()) {
      this.componentRef.instance.submit();
    }
  }
} 