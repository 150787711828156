// Angular
import { Injectable, inject } from '@angular/core';

// 3rd Party
import { ColumnModel, GridModel } from '@syncfusion/ej2-angular-grids';

// Models
import {
  CustomToolbarItem,
  ToolbarItem,
  CommandModel,
  SearchSettingsModel,
  DefaultGridProperties,
  ColumnType,
  DATE_FIELDS,
  SetGridDataArgs
} from '../models/grid.models';

// Services
import { BaseService } from './base.service';
import { BaseGridSignals } from './signals.service';

/**
 * Service responsible for managing grid initialization values and configuration
 * This service provides default values and configuration options for grid components
 */
@Injectable({
  providedIn: 'root'
})
export class GridInitializationService extends BaseService {
  private readonly signals = inject(BaseGridSignals);

  /**
   * Config Setup
   */
  readonly defaultMobileToolbar: CustomToolbarItem[] = DefaultGridProperties.mobileToolbar;
  readonly defaultPagination = DefaultGridProperties.pagination;
  readonly defaultToolbar: CustomToolbarItem[] = DefaultGridProperties.toolbar;
  readonly defaultActions: CommandModel[] = DefaultGridProperties.actions;
  readonly defaultPageSize = DefaultGridProperties.pageSize;
  readonly defaultSearchSettings: SearchSettingsModel = DefaultGridProperties.searchSettings;
  readonly DATE_FIELDS = DATE_FIELDS;
  private readonly FIELD_CONFIGS: Record<string, Partial<ColumnModel>> = {
    Id: { isPrimaryKey: true, visible: false },
    id: { isPrimaryKey: true, visible: false },
    InTreatment: { editType: 'booleanedit' },
    Final: { editType: 'booleanedit' },
    Status: { editType: 'dropDownEdit' },
    IsActive: { editType: 'booleanEdit' },
    Language: { editType: 'dropDownEdit' },
    Minor: { editType: 'booleanedit' },
    CreatedBy: { allowEditing: false },
    UpdatedBy: { allowEditing: false },
    CreatedAt: { type: ColumnType.Date, format: 'M/d/y', allowEditing: false },
    UpdatedAt: { type: ColumnType.Date, format: 'M/d/y', allowEditing: false },
    CreatedDate: { type: ColumnType.Date, format: 'M/d/y', allowEditing: false },
    UpdatedDate: { type: ColumnType.Date, format: 'M/d/y', allowEditing: false },
    TotalDueProvider: { format: 'C2', editType: 'numericEdit' },
    SettlementValue: { format: 'C2', editType: 'numericEdit' },
    AmountBilled: { format: 'C2', editType: 'numericEdit' },
    AmountPaid: { format: 'C2', editType: 'numericEdit' },
    Amount: { format: 'C2', editType: 'numericEdit' },
  };

  constructor() {
    super();
  }

  /**
   * Filters toolbar items based on excluded IDs
   */
  filterToolbarItems(items: CustomToolbarItem[], excludeIds: string[]): ToolbarItem[] {
    return items
      .filter(item => {
        const id = typeof item === 'string' ? item : item.id;
        return !excludeIds.includes(id);
      })
      .map(item => {
        if (typeof item === 'string') {
          return {
            id: item,
            text: item,
            tooltipText: item
          };
        }
        return item;
      });
  }

  /**
   * Set up initial grid state and settings
   */
  async initializeGrid(args: SetGridDataArgs): Promise<any> {
    try {
      // Set loading state
      this.signals.setLoading(true);

      // Update grid setup arguments
      this.signals.setGridSetupArgs(args);

      // Set grid name
      if (args.name) {
        this.signals.setGridName(args.name);
        this.addNameToToolbar(args.name);
      }

      // Set grid ID
      if (args.id) {
        this.signals.setGridId(args.id);
      }

      // Set endpoint
      if (args.endpoint) {
        this.signals.setEndpoint(args.endpoint);
      }

      // Set pagination
      if (args.pageSettings) {
        this.signals.setPagination(args.pageSettings.skip, args.pageSettings.take);
      }

      // Set query
      if (args.query) {
        this.signals.setQuery(args.query);
      }

      // Set grid settings and apply default commands
      if (args.gridSettings) {
        if (args.gridSettings.columns) {
          args.gridSettings.columns = this.templatizeGridColumns(args.gridSettings.columns as ColumnModel[]);
        }
        this.signals.setGridSettings(args.gridSettings);
      }

      // Filter toolbar items if excludeToolbarItems is provided
      const currentToolbar = this.signals.toolbar();
      const nonDefaultItems = currentToolbar.filter(item => 
        !DefaultGridProperties.toolbar.some(defaultItem => 
          (typeof item === 'string' && item === defaultItem) ||
          (typeof item === 'object' && typeof defaultItem === 'object' && item.id === defaultItem.id)
        )
      );
      
      const filteredDefaultItems = args.excludeToolbarItems?.length 
        ? this.filterToolbarItems(DefaultGridProperties.toolbar, args.excludeToolbarItems)
        : this.filterToolbarItems(DefaultGridProperties.toolbar, []);

      // Convert any string items in nonDefaultItems to ToolbarItem format
      const convertedNonDefaultItems = nonDefaultItems.map(item => 
        typeof item === 'string' ? { id: item, text: item, tooltipText: item } : item
      );

      const toolbarItems = [...convertedNonDefaultItems, ...filteredDefaultItems];

      // Update the state with filtered toolbar items
      this.signals.setToolbar(toolbarItems);

      // Add active records toggle if specified
      if (args.activeRecordsToggle) {
        const addIndex = toolbarItems.findIndex(item => 
          typeof item === 'object' && item.id === 'Add'
        );
        if (addIndex !== -1) {
          toolbarItems.splice(addIndex + 1, 0, {
            id: 'toggle-active',
            text: 'View Inactive',
            tooltipText: 'Toggle Active/Inactive Records'
          });
          this.signals.setToolbar(toolbarItems);
        }
      }

      // Update grid settings with filtered toolbar items
      if (args.gridSettings) {
        args.gridSettings.toolbar = toolbarItems;
        this.signals.setGridSettings(args.gridSettings);
      }

      // Update grid toolbar if component exists
      if (this.signals.grid()) {
        this.signals.grid()!.toolbar = toolbarItems;
        this.signals.grid()!.refresh();
      }

      if (args.gridSettings?.dataSource) {
        return {
          result: args.gridSettings.dataSource,
          count: (args.gridSettings.dataSource as any).length,
        }
      }
    } catch (error) {
      this.handleError(error, {
        userMessage: 'Error setting grid data',
        context: 'GridInitializationService',
        severity: this.ErrorSeverity.Error,
      });
      throw error;
    } finally {
      // Clear loading state
      this.signals.setLoading(false);
    }
  }

  /**
   * Applies default command actions to grid columns
   */
  applyDefaultCommands(columns: ColumnModel[]): ColumnModel[] {
    return columns.map(col => {
      if (typeof col === 'object' && col.type === 'commands' && !col.commands) {
        return {
          ...col,
          commands: this.defaultActions
        } as ColumnModel;
      }
      return col;
    });
  }

  /**
   * Configures grid columns with appropriate types and settings
   */
  templatizeGridColumns(columns: ColumnModel[]): ColumnModel[] {
    return columns.map(column => {
      // Handle column type
      this.handleColumnType(column);

      // Handle field-specific configuration
      this.applyFieldConfig(column);

      // Handle date fields
      if (this.isDateField(column.field)) {
        this.handleDateColumnLogs(column);
      }

      // Handle amount fields
      if (column.field?.includes('Amount')) {
        column.editType = 'numericEdit';
      }

      return column;
    });
  }

  /**
   * Configures column types with appropriate settings
   */
  private handleColumnType(column: ColumnModel): void {
    switch (column.type) {
      case ColumnType.Checkbox:
        column.allowFiltering = false;
        column.textAlign = 'Center';
        break;
      case ColumnType.Commands:
        column.commands = column.commands ?? this.defaultActions;
        column.headerText = column.headerText ?? 'Actions';
        break;
    }
  }

  /**
   * Applies field-specific configurations to columns
   */
  private applyFieldConfig(column: ColumnModel): void {
    if (column.field) {
      // Handle Id fields first
      if (column.field === 'Id' || column.field === 'id') {
        Object.assign(column, {
          isPrimaryKey: true,
          visible: false
        });
        return;
      }

      // Handle other field configs
      const config = this.FIELD_CONFIGS[column.field];
      if (config) {
        Object.assign(column, config);
      }
    }
  }

  /**
   * Checks if a field is a date field
   */
  private isDateField(field?: string): boolean {
    if (!field) return false;
    return this.DATE_FIELDS.some((dateField: string) => field.includes(dateField));
  }

  /**
   * Configures date column formatting and value accessor
   */
  private handleDateColumnLogs(column: ColumnModel): void {
    column.type = 'date';
    column.format = 'M/d/y';
    column.valueAccessor = (field: string, data: any): string => {
      const value = field.includes('.') ? field.split('.').reduce((obj, key) => obj?.[key], data) : data[field];
      if (!value) return '';

      const date = new Date(value);
      if (isNaN(date.getTime())) return '';

      return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        timeZone: 'UTC',
      }).format(date);
    };
  }

  /**
   * Adds grid name to toolbar
   */
  private addNameToToolbar(name: string) {
    if (name) {
      this.signals.setGridName(name);
      this.updateToolbar([{ id: 'grid-title', template: `<span class="h2">${name}</span>` }, ...DefaultGridProperties.toolbar]);
    }
  }

  /**
   * Updates toolbar items
   */
  private updateToolbar(customItems?: CustomToolbarItem[]): void {
    try {
      let toolbarItems: CustomToolbarItem[] = [];

      // Add custom items if provided
      if (customItems?.length) {
        toolbarItems = [...customItems];
      } else {
        toolbarItems = this.signals.toolbar();
      }

      // Update signals
      this.signals.setToolbar(toolbarItems);
      this.signals.updateGridSettings((settings: GridModel | undefined) => ({
        ...settings,
        toolbar: toolbarItems,
      }));
    } catch (error) {
      this.signals.setError('updateToolbar', error);
      throw error;
    }
  }
} 