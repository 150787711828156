import { Injectable, Type } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BaseFormMaterialComponent } from '../components/base-dialog/base-form-material.component';
import { DialogWrapperComponent } from '../components/base-dialog/dialog-wrapper.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  constructor(private dialog: MatDialog) {}

  openDialog<T extends BaseFormMaterialComponent>(
    component: Type<T>,
    config: MatDialogConfig & {
      title: string;
      submitLabel: string;
    }
  ) {
    const dialogConfig = {
      ...config,
      panelClass: ['base-dialog-container', ...(config.panelClass || [])],
      data: {
        component,
        ...config.data,
        title: config.title,
        submitLabel: config.submitLabel
      }
    };

    return this.dialog.open(DialogWrapperComponent, dialogConfig);
  }
} 