// src/app/components/file-hub/file-hub-tabs/surgical-tab/surgical-tab.service.ts

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from '@services/api/api.service';
import { SurgicalProgression } from '@models/data-contracts';
import { APIEndpoints } from '@models/api/Endpoints';
import { Query } from '@syncfusion/ej2-data';

@Injectable({
  providedIn: 'root'
})
export class SurgicalTabService {
    private segmentsSubject = new BehaviorSubject<SurgicalProgression[]>([]);
    segments$ = this.segmentsSubject.asObservable();
  
    private loadingSubject = new BehaviorSubject<boolean>(true);
    loading$ = this.loadingSubject.asObservable();

    private loadingSurgicalProgressionSubject = new BehaviorSubject<boolean>(false);
    loadingSurgicalProgression$ = this.loadingSurgicalProgressionSubject.asObservable();
  
    private updatingSegmentsSubject = new BehaviorSubject<boolean>(false);
    updatingSegments$ = this.updatingSegmentsSubject.asObservable();
  
    constructor(private api: ApiService) {}

    async loadSegments(showLoading: boolean = true) {
        
        try {
            if (showLoading) {
                this.loadingSubject.next(true);
            } else {
                this.updatingSegmentsSubject.next(true);
            }
    
            try {
                const response: any = await this.api.getOdata(APIEndpoints.SurgicalProgression)
                    .executeQuery(new Query().expand('XrefSurgeriesSurgicalProgressions'));
                this.segmentsSubject.next(response.result);
            } catch (error) {
                console.error('Error loading segments:', error);
            } finally {
                this.loadingSubject.next(false);
                this.updatingSegmentsSubject.next(false);
            }
        } catch (error) {
            console.error('Error loading segments:', error);
            return error;

        } finally {
            this.loadingSubject.next(false);
            this.updatingSegmentsSubject.next(false);
            return;
        }
    }

    getProgessionSegments(surgeryId: number | null): Promise<boolean[]> {
        return this.api.getOdata(APIEndpoints.XrefSurgeriesSurgicalProgression)
            .executeQuery(new Query().where('SurgeriesId', 'equal', surgeryId))
            .then((result: any) => {
                const progressionIds = result.result.map((xref: any) => xref.SurgicalProgressionId);
                const maxProgressionId = 5;
                const booleanArray = Array.from({ length: maxProgressionId }, (_, i) => progressionIds.includes(i + 1));
                return booleanArray;
            })
            .catch(error => {
                console.error('Error loading segments:', error);
                return Array(5).fill(false); 
            });
    }

    getSegmentXref(index: number): any[] {
        const segments = this.segmentsSubject.getValue();
        return segments[index]?.XrefSurgeriesSurgicalProgressions || [];
    }

    getSegmentContent(index: number): string | null | undefined {
        const segments = this.segmentsSubject.getValue();
        return segments[index]?.Content;
    }

    async saveSegment(index: number, surgeryId: number | null): Promise<any | void> {
        if (!surgeryId) return;
        
        this.loadingSurgicalProgressionSubject.next(true);
        try {
            const response = await this.api.postOdata(`${APIEndpoints.XrefSurgeriesSurgicalProgression}`, {
                SurgicalProgressionId: index + 1,
                SurgeriesId: surgeryId
            });
            
            const result = await response.json();
            await this.loadSegments(false);
            return result;
        } finally {
            this.loadingSurgicalProgressionSubject.next(false);
        }
    }

    async removeSegment(segmentId: number | null) {
        
        try {
            if (segmentId == null) return;
            this.loadingSurgicalProgressionSubject.next(true);
            await this.api.deleteOdata(`${APIEndpoints.XrefSurgeriesSurgicalProgression}/${segmentId}`);
            await this.loadSegments(false);
            return;

        } catch (error) {
            console.error('Error removing segment:', error);
            return error;
            
        } finally {
            this.loadingSurgicalProgressionSubject.next(false);
            return;
        }
    }
}