import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import {
  AiAgentService,
  ProcessedComment,
  CaseStatus,
  CaseStatistics,
  CaseAssignment,
  AgentResponse,
} from '../services/ai-agent.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ReplacePipe } from '../pipes/replace.pipe';
import { Observable } from 'rxjs';

interface PromptOption {
  label: string;
  value: string;
}

@Component({
  selector: 'app-ai-agent',
  templateUrl: './ai-agent.component.html',
  styleUrls: ['./ai-agent.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatCardModule,
    ReplacePipe,
  ],
})
export class AiAgentComponent implements OnInit {
  agentForm: FormGroup;
  isLoading = false;
  error: string | null = null;
  response: AgentResponse | null = null;

  predefinedPrompts: PromptOption[] = [
    { label: '1. Basic Case Status', value: 'Get the status of case {caseId}' },
    {
      label: '2. Case Comments Analysis',
      value: 'Show me all comments for case {caseId} and analyze them for key information',
    },
    { label: '3. Comment Search', value: 'Search for any comments in case {caseId} that mention "PRP" or "injection"' },
    { label: '4. Medical Treatment Analysis', value: 'Analyze the medical treatment history for case {caseId}' },
    {
      label: '5. Financial Information',
      value: 'What are the financial details and costs associated with case {caseId}?',
    },
    { label: '6. Contact Information', value: "Extract all contact information from case {caseId}'s comments" },
    {
      label: '7. Appointment History',
      value: 'Show me the appointment history and upcoming appointments for case {caseId}',
    },
    {
      label: '8. Case Summary',
      value: 'Give me a comprehensive summary of case {caseId} including status, treatment, and key events',
    },
    { label: '9. Document Analysis', value: 'Analyze the latest documents and updates for case {caseId}' },
    { label: '10. Action Items', value: 'What are the pending action items for case {caseId}?' },
  ];

  constructor(
    private fb: FormBuilder,
    private aiAgentService: AiAgentService
  ) {
    this.agentForm = this.fb.group({
      caseId: ['WN082824', Validators.required],
      selectedPrompt: [''],
      customPrompt: ['', Validators.required],
    });
  }

  ngOnInit(): void {}

  onPromptSelected(): void {
    const selectedPrompt = this.agentForm.get('selectedPrompt')?.value;
    const caseId = this.agentForm.get('caseId')?.value;

    if (selectedPrompt && caseId) {
      const customPrompt = selectedPrompt.replace('{caseId}', caseId);
      this.agentForm.patchValue({ customPrompt });
    }
  }

  sendPrompt(): void {
    if (this.agentForm.valid) {
      this.isLoading = true;
      this.error = null;
      this.response = null;

      const prompt = this.agentForm.get('customPrompt')?.value;
      const caseId = this.agentForm.get('caseId')?.value;

      this.aiAgentService.sendPrompt(prompt, caseId).subscribe({
        next: (response: AgentResponse) => {
          this.response = response;
          this.isLoading = false;
        },
        error: (err: HttpErrorResponse) => {
          this.error = err.message || 'An error occurred while processing your request';
          this.isLoading = false;
        },
      });
    }
  }
}
