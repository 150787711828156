<div id="add-fee-schedule" class="content">

    <form class="add-fee-schedule-form" [formGroup]="newFeeScheduleForm" (ngSubmit)="onSubmit()">

        <div class="row">

            <div class="col-6">
                <ejs-textbox formControlName="Name" placeholder="Name" floatLabelType="Auto"></ejs-textbox>
                <div *ngIf="newFeeScheduleForm.get('Name')?.errors" class="error-container">
                    <div *ngIf="newFeeScheduleForm.get('Name')?.errors?.['required'] && newFeeScheduleForm.get('Name')?.touched"
                        class="e-error">
                        <span>This field is required.</span>
                    </div>
                </div>
            </div>

            <div class="col-6">
                <ejs-textbox formControlName="Description" placeholder="Description" floatLabelType="Auto"
                    [multiline]="true" />
            </div>

            <div class="col-12">
                <label>CPT Codes</label>

                <div class="d-flex justify-content-end">
                    <span class="e-btn e-outline" (click)="toggleProcedureCodeRepeater()">{{cptCodeBtnText}}</span>
                </div>

                <div class="d-flex flex-column" formControlName="Description">
                    @if (entries.controls.length > 0 && cptCodeRepeaterVisibile) {
                    <form [formGroup]="attachedProcedureCodesForm">
                        <div formArrayName="entries">
                            @for (entry of entries.controls; track $index) {

                            <div [formGroupName]="$index"
                                class="d-flex flex-row justify-content-between align-items-center mt-1">
                                @if (entry.value['IsMedicare']) {
                                <button ejs-button cssClass="e-outline"
                                    (click)="entry.value['IsMedicare'] = false">Medicare
                                    Billing</button>
                                <ejs-dropdownlist floatLabelType="Always" placeholder="CPT Code"
                                    formControlName="cptCodeId" [dataSource]="medicareCptCodesData"
                                    [fields]="{text: 'code', value: 'id'}" (change)="changedProcedureCode($event)" />
                                <ejs-dropdownlist floatLabelType="Always" placeholder="Locality"
                                    formControlName="localityId" [dataSource]="medicareLocalitiesData"
                                    [fields]="{text: 'localityName', value: 'id'}" />
                                <ejs-dropdownlist floatLabelType="Always" placeholder="Participating"
                                    formControlName="participating" [dataSource]="medicareParticipating"
                                    [fields]="{text: 'name', value: 'name'}" />
                                <ejs-numerictextbox floatLabelType="Always" placeholder="Multiplier"
                                    formControlName="multiplier" />
                                <ejs-checkbox floatLabelType="Always" label="Facility?" formControlName="facility" />
                                }
                                @else {
                                <button ejs-button cssClass="e-outline"
                                    (click)="entry.value['IsMedicare'] = true">Medicare
                                    Billing</button>
                                <ejs-dropdownlist floatLabelType="Always" placeholder="CPT Code" formControlName="Code"
                                    [dataSource]="cptCodesData" [fields]="{text: 'ProcedureCodeName', value: 'Id'}"
                                    (change)="changedProcedureCode($event)" />
                                <ejs-numerictextbox floatLabelType="Always" placeholder="Billed Amount"
                                    formControlName="BilledAmount" />
                                <ejs-numerictextbox floatLabelType="Always" placeholder="Reimbursement Rate"
                                    formControlName="ReimbursementRate" />
                                <ejs-numerictextbox floatLabelType="Always" placeholder="Amount Due"
                                    formControlName="DueAmount" />
                                }
                                <ejs-checkbox floatLabelType="Always" label="Split Invoice?"
                                    formControlName="SplitInvoice" />
                                <button ejs-button type="button" iconCss="e-icons e-circle-add" [isPrimary]="false"
                                    (click)="addEntry()"></button>
                            </div>
                            }
                            <div class="d-flex flex-row justify-content-end align-items-center mt-3">
                                <button ejs-button type="button" iconCss="e-icons e-circle-remove" [isPrimary]="false"
                                    (click)="removeEntry()"></button>
                            </div>
                        </div>
                    </form>
                    }
                </div>

            </div>

            <div class="col-12">
                <label style="display: block; margin-bottom: 1rem;">Providers</label>
                <div class="custom-providers-editor">
                    <custom-assignments-editor #customProvidersEditor
                        [availableGridSettings]="availableProvidersGridSettings"
                        [assignmentsGridSettings]="assignedProvidersGridSettings" [showAvailableHeader]="false"
                        [showAssignedHeader]="false" [assignmentsGridPredicateField]="'Name'" />
                </div>
            </div>

            <div class="col-10"> </div>
            <div class="col-2 form-submit ">
                <button ejs-button class="e-primary e-flat" type="submit" isPrimary="true">Submit</button>
            </div>
        </div>
    </form>
</div>