// Angular
import { Injectable, Signal, signal, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

// 3rd Party
import { fetchAuthSession, signOut } from '@aws-amplify/auth';
import { Query } from '@syncfusion/ej2-data';


// Internal
import { APIEndpoints } from '@models/api/Endpoints';
import { ThemeService } from '@services/theme/theme.service';
import { SidebarComponent } from '@syncfusion/ej2-angular-navigations';
import { TokenRefreshService } from '@services/auth/token-refresh.service';
import { ErrorSeverity } from '@core/error/error.types';
import { AuthenticatedServiceBase } from '@core/auth/auth.base';

export interface UpdateLogoParams {
  theme?: string | null;
  darkMode?: string | null;
  layoutMode?: string | null;
  sidebar?: SidebarComponent | null;
}

interface SmsPreferences {
  phoneNumber?: string;
  isOptedIn: boolean;
  preferredMethod?: 'sms' | 'app';
}

const ERRORS = {
  PREFERENCES: {
    NOT_FOUND: {
      message: 'Unable to save preferences',
      technical: 'No user or preferences found to save'
    },
    SAVE_FAILED: {
      message: 'Failed to save preferences',
      technical: 'API request to save preferences failed'
    }
  },
  AUTH: {
    SESSION_EXPIRED: {
      message: 'Session expired. Please log in again',
      technical: 'Authentication token expired or invalid'
    },
    FETCH_FAILED: {
      message: 'Failed to fetch user permissions',
      technical: 'Error retrieving user rights from API'
    }
  }
} as const;

@Injectable({
  providedIn: 'root',
})
export class UserPreferencesService extends AuthenticatedServiceBase {
  protected override readonly endpoint = this.APIEndpoints.Users;

  private readonly tokenRefresh = inject(TokenRefreshService);
  private readonly theme = inject(ThemeService);
  private readonly router = inject(Router);

  constructor() {
    super();
  }

  // Key for storing preferences in localStorage
  private localStorageKey = 'userPreferences';
  private userRightsKey = 'userRights';
  private appLayout = new BehaviorSubject<string>(this.getLayout());
  private userIdSignal = signal<number | null>(localStorage.getItem('userId') ? parseInt(localStorage.getItem('userId')!) : null);
  private isAdminSignal = signal<boolean | null>(localStorage.getItem('isAdmin') ? JSON.parse(localStorage.getItem('isAdmin')!) : null);
  private userRightsSignal = signal<string[]>(this.getUserRightsFromStorage());
  appLayout$ = this.appLayout.asObservable(); // Expose Observable
  loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  username: any = this.getUsername().then((result) => { return result; });
  darkMode: any = this.getLocalUserPreference('darkMode');
  primaryColor: any = this.getLocalUserPreference('primaryColor');
  secondaryColor: any = this.getLocalUserPreference('secondaryColor');
  tertiaryColor: any = this.getLocalUserPreference('tertiaryColor');
  appLogo = signal('');

  private readonly SMS_PREFS_KEY = 'sms_preferences';

  // Get currently observed value of loggedIn
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  // Add a getter for the userId signal
  get userId(): Signal<number | null> {
    return this.userIdSignal.asReadonly();
  }

  // Set loggedIn observable
  set isLoggedIn(value: any) {
    this.loggedIn.next(value);
  }

  // Get current user session
  async getUserSession(): Promise<any> {
    return await fetchAuthSession();
  }

  // Get username
  async getUsername(): Promise<string> {
    return await fetchAuthSession().then((session) => {
      return session.tokens?.signInDetails?.loginId as string;
    })
  }

  isAdminPresent = (data: any): boolean => {
    // Early return if no data
    if (!data) return false;

    // Check XrefUsersRoles array directly since we know the structure
    const userRoles = data.XrefUsersRoles;
    if (!Array.isArray(userRoles)) return false;

    // Look for Admin role in the array
    return userRoles.some(xref =>
      xref?.Role?.RoleName?.includes('Admin')
    );
  }

  // Get user preferences and save them to local storage if there are any
  async getUserPreferences(userName?: string) {
    const username = userName ?? await this.getUsername();
    const query = new Query()
      .where('Username', 'equal', username)
      .expand(['XrefUsersRoles($select=Role;$expand=Role($select=RoleName))']);

    await this.api.getOdata(this.endpoint).executeQuery(query).then((res) => {
      const user = (res as any).result[0];
      localStorage.setItem('userId', user.Id);
      const isAdmin = this.isAdminPresent(user);
      localStorage.setItem('isAdmin', isAdmin.toString())
      this.isAdminSignal.set(isAdmin);
      const noPreferencesSaved = !user.UserPreferences;

      if (!noPreferencesSaved) {
        localStorage.setItem(this.localStorageKey, user.UserPreferences);
      }

    });
  }

  // Save user's current preferences to DB
  async patchUserPreferences() {
    const preferences = this.getAllLocalPreferences();

    if (!preferences || !this.userId()) {
      this.handleError(
        new Error(ERRORS.PREFERENCES.NOT_FOUND.technical),
        {
          context: 'UserPreferencesService.patchUserPreferences',
          userMessage: ERRORS.PREFERENCES.NOT_FOUND.message
        }
      );
      return;
    }

    const endpoint = `odata${this.endpoint}/${this.userId()}`;
    const data = { UserPreferences: JSON.stringify(preferences) };

    try {
      const res = await this.api.fetchRequest(endpoint, 'PATCH', data);
      if (!res.ok) {
        throw new Error(ERRORS.PREFERENCES.SAVE_FAILED.technical);
      }
      // Success case - don't use error handling service for success
      return res;
    } catch (error) {
      this.handleError(error, {
        context: 'UserPreferencesService.patchUserPreferences',
        userMessage: ERRORS.PREFERENCES.SAVE_FAILED.message
      });
      return null;
    }
  }

  // Return an object of user preferences, pulled from localStorage string
  getAllLocalPreferences() {
    const storedSettings = localStorage.getItem(this.localStorageKey);
    return storedSettings ? JSON.parse(storedSettings) : {};
  }

  // Store userPreferences to localStorage as string
  saveAllLocalPreferences(preferences: any): any {
    localStorage.setItem(this.localStorageKey, JSON.stringify(preferences));
  }

  // Add or update single preference in userPreferences in localStorage
  saveLocalUserPreference(key: any, value: any): void {
    const preferences = this.getAllLocalPreferences();
    preferences[key] = value;
    this.saveAllLocalPreferences(preferences);
  }

  // Get a single value from userPreferences string in localStorage
  getLocalUserPreference(key: string): any {
    const preferences = this.getAllLocalPreferences();
    return preferences && preferences[key] ? preferences[key] : undefined;
  }

  // Remove single preference from userPreferences string in localStorage
  removeLocalUserPreference(key: string): void {
    const preferences = this.getAllLocalPreferences();

    if (preferences[key]) {
      delete preferences[key];
      this.saveAllLocalPreferences(preferences);
    } else {
      this.handleError(
        new Error(`Preference "${key}" not found`),
        {
          context: 'UserPreferencesService.removeLocalUserPreference',
          userMessage: 'Preference not found'
        }
      );
    }
  }

  // Apply dark mode based on userPreferences
  applyDarkMode() {
    const darkMode = this.getLocalUserPreference('darkMode');

    switch (darkMode) {
      case 'on': this.turnDarkModeOn(); break;
      case 'off': this.turnDarkModeOff(); break;
      default: this.turnDarkModeOff(); break;
    }
  }


  // Switch between dark modes
  toggleDarkMode() {
    const darkMode = this.getLocalUserPreference('darkMode');

    switch (darkMode) {
      case 'on': this.turnDarkModeOff(); break;
      case 'off': this.turnDarkModeOn(); break;
      default: this.turnDarkModeOff(); break;
    }

    this.appLogo.set(this.getLogo());
  }

  turnDarkModeOn = () => {
    document.body.classList.add('e-dark-mode');
    this.saveLocalUserPreference('darkMode', 'on');
  }

  turnDarkModeOff = () => {
    document.body.classList.remove('e-dark-mode');
    this.saveLocalUserPreference('darkMode', 'off');
  }

  // Apply layout mode based on userPreferences
  applyLayoutMode() {
    const layoutMode = this.getLocalUserPreference('layoutMode');

    switch (layoutMode) {
      case 'app': this.turnAppModeOn(); break;
      case 'standard': this.turnAppModeOff(); break;
      default: this.turnAppModeOff(); break;
    }

    this.appLayout.next(this.getLayout());  // Notifies any subscribers of change
  }

  // Switch between layout modes
  toggleLayoutMode() {
    const layoutMode = this.getLocalUserPreference('layoutMode');

    switch (layoutMode) {
      case 'app': this.turnAppModeOff(); break;
      case 'standard': this.turnAppModeOn(); break;
      default: this.turnAppModeOn(); break;
    }

    this.appLayout.next(this.getLayout());  // Notifies any subscribers of change
  }

  turnAppModeOn = () => {
    document.body.classList.add('app-mode');
    this.saveLocalUserPreference('layoutMode', 'app');
    this.appLogo.set(this.getLogo({ layoutMode: 'app' }));
  }

  turnAppModeOff = () => {
    document.body.classList.remove('app-mode');
    document.querySelector('app-content')?.removeAttribute('class');
    this.saveLocalUserPreference('layoutMode', 'standard');
    this.appLogo.set(this.getLogo({ layoutMode: 'standard' }));
  }

  getLayout(): string {
    return this.getLocalUserPreference('layoutMode');
  }

  getLogo(args?: UpdateLogoParams | null): string {
    // Get layout options to determine which logo to use
    const theme = args?.theme ?? this.getLocalUserPreference('theme') ?? undefined;
    const darkMode = args?.darkMode ?? this.getLocalUserPreference('darkMode') ?? undefined;
    const sidebarSettings = localStorage.getItem('sidebar_ejs-component-mainSidebar');
    const sidebar = args?.sidebar ?? (sidebarSettings ? JSON.parse(sidebarSettings) : undefined);
    const layoutMode = args?.layoutMode ?? this.getLocalUserPreference('layoutMode') ?? undefined;
    const sidebarIsOpen = sidebar && sidebar.isOpen ? true : false;

    // Initialize variables that will be used in return
    const logoBaseUrl = 'assets/logos/Varent-Logo-RGB_';
    let logoUrl = '';

    switch (theme) {
      case 'default': logoUrl = darkMode === 'on' ? `${logoBaseUrl}White` : `${logoBaseUrl}Mint`; break;
      case 'basic': logoUrl = darkMode === 'on' ? `${logoBaseUrl}White` : `${logoBaseUrl}Magenta`; break;
      case 'ocean': logoUrl = darkMode === 'on' ? `${logoBaseUrl}White` : `${logoBaseUrl}Blue`; break;
      case 'orange': logoUrl = darkMode === 'on' ? `${logoBaseUrl}White` : `${logoBaseUrl}Orange`; break;
      case 'glamour': logoUrl = darkMode === 'on' ? `${logoBaseUrl}White` : `${logoBaseUrl}Magenta`; break;
      case 'rainforest': logoUrl = darkMode === 'on' ? `${logoBaseUrl}White` : `${logoBaseUrl}Green`; break;
      case 'purple': logoUrl = darkMode === 'on' ? `${logoBaseUrl}White` : `${logoBaseUrl}Magenta`; break;
      case 'modern': logoUrl = darkMode === 'on' ? `${logoBaseUrl}White` : `${logoBaseUrl}Mint`; break;
      case 'custom': logoUrl = darkMode === 'on' ? `${logoBaseUrl}White` : `${logoBaseUrl}Black`; break;
      default: logoUrl = darkMode === 'on' ? `${logoBaseUrl}White` : `${logoBaseUrl}Mint`; break;
    }

    if (!sidebarIsOpen && layoutMode === 'app') logoUrl = `${logoUrl}_Icon`;
    this.appLogo.set(`${logoUrl}.png`);
    return `${logoUrl}.png`;
  }

  // Apply layout mode based on userPreferences
  applyUserTheme() {
    const theme = this.getLocalUserPreference('theme');
    this.applyTheme(theme);
  }

  saveTheme(theme: string) {
    this.saveLocalUserPreference('theme', theme);
  }

  loadTheme() {
    const theme = this.getLocalUserPreference('theme') ?? 'default';
    this.applyTheme(theme);
  }

  applyTheme(theme: string) {
    theme = !theme ? 'default' : theme;
    const themeColors = this.theme.getThemeColors(theme);
    const customColors = this.getLocalUserPreference('customThemeColors');

    switch (theme) {
      case 'false': this.theme.resetTheme(); break;
      case 'default': this.theme.resetTheme(); break;
      case 'custom': this.theme.setTheme(customColors.PrimaryColor as string, customColors.SecondaryColor, customColors.TertiaryColor); break;
      default: this.theme.setTheme(themeColors?.PrimaryColor as string, themeColors?.SecondaryColor, themeColors?.TertiaryColor); break;
    }

    this.saveTheme(theme);
  }

  // Add method to initialize userId
  async initializeUserId(): Promise<void> {
    const session = await this.getUserSession();
    if (session?.tokens?.accessToken) {
      await this.getUserPreferences(); // This sets userId in localStorage
      this.userIdSignal.set(localStorage.getItem('userId') ? parseInt(localStorage.getItem('userId')!) : null);
    }
  }

  get isAdmin(): Signal<boolean | null> {
    return this.isAdminSignal.asReadonly();
  }

  // Get user rights as a readonly signal
  get userRights(): Signal<string[]> {
    return this.userRightsSignal.asReadonly();
  }

  private getUserRightsFromStorage(): string[] {
    const storedRights = localStorage.getItem(this.userRightsKey);
    return storedRights ? JSON.parse(storedRights) : [];
  }

  // Fetch and store user rights
  async fetchAndStoreUserRights(username: string): Promise<void> {
    try {
      await this.tokenRefresh.ensureValidToken();

      const session = await this.getUserSession();
      if (!session?.tokens?.accessToken) {
        await this.handleFailedRightsFetch('No valid authentication session');
        return;
      }

      const query = new Query()
        .where('Username', 'equal', username)
        .expand([
          'XrefUsersRights($expand=Right($select=RightName))',
          'XrefUsersRoles($expand=Role($expand=XrefRolesRights($expand=Right($select=RightName))))'
        ]);

      const userData = await this.api.getOdata('/users').executeQuery(query);
      const user = (userData as any).result[0];

      if (!user) {
        await this.handleFailedRightsFetch('User account not found in the system');
        return;
      }

      const allUserRights = this.processUserRights(user);
      localStorage.setItem(this.userRightsKey, JSON.stringify(allUserRights));
      this.userRightsSignal.set(allUserRights);

    } catch (error) {
      this.handleError(error, {
        context: 'UserPreferencesService.fetchAndStoreUserRights',
        userMessage: ERRORS.AUTH.FETCH_FAILED.message
      });

      if (this.isAuthenticationError(error)) {
        await this.handleAuthenticationError();
      } else {
        await this.handleFailedRightsFetch(ERRORS.AUTH.FETCH_FAILED.message);
      }
    }
  }

  private async handleFailedRightsFetch(errorMessage: string): Promise<void> {
    // Clear authentication state
    this.clearUserRights();
    this.loggedIn.next(false);
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem(this.localStorageKey);

    try {
      await signOut();
    } catch (error) {
      console.error('Error during sign out:', error);
    }

    // Use notification from base class
    this.notification.error(
      `${errorMessage}. Please contact your administrator.`
    );

    await this.router.navigate(['/']);
  }

  private async handleAuthenticationError(): Promise<void> {
    try {
      // Try to refresh token first
      await this.tokenRefresh.refreshToken();
    } catch (error) {
      // If refresh fails, clear session
      localStorage.removeItem(this.userRightsKey);
      this.userRightsSignal.set([]);
      this.loggedIn.next(false);

      this.handleError(
        new Error(ERRORS.AUTH.SESSION_EXPIRED.message),
        {
          context: 'UserPreferencesService.handleAuthenticationError',
          userMessage: ERRORS.AUTH.SESSION_EXPIRED.message
        }
      );
    }
  }

  private isAuthenticationError(error: any): boolean {
    return error?.statusCode === 400 ||
      error?.message?.includes('authentication') ||
      error?.message?.includes('session');
  }

  private processUserRights(user: any): string[] {
    const directUserRights = user.XrefUsersRights?.map((xref: any) => xref.Right.RightName) || [];
    const roleRights = this.extractRoleRights(user.XrefUsersRoles);
    return [...new Set([...directUserRights, ...roleRights])];
  }

  private extractRoleRights(userRoles: any[]): string[] {
    const roleRights: string[] = [];
    if (userRoles) {
      for (const roleXref of userRoles) {
        if (roleXref.Role?.XrefRolesRights) {
          const rights = roleXref.Role.XrefRolesRights
            .map((rightXref: any) => rightXref.Right?.RightName)
            .filter(Boolean);
          roleRights.push(...rights);
        }
      }
    }
    return roleRights;
  }

  // Clear user rights on logout
  clearUserRights(): void {
    localStorage.removeItem(this.userRightsKey);
    this.userRightsSignal.set([]);
  }

  // Check if user has required rights
  hasRequiredRights(requiredRights: string[]): boolean {
    if (!requiredRights || requiredRights.length === 0) return true;
    const userRights = this.getUserRightsFromStorage();
    return requiredRights.every(right => userRights.includes(right));
  }

  async getSmsPreferences(): Promise<SmsPreferences | null> {
    const prefs = localStorage.getItem(this.SMS_PREFS_KEY);
    return prefs ? JSON.parse(prefs) : null;
  }

  async updateSmsPreferences(prefs: Partial<SmsPreferences>): Promise<void> {
    const currentPrefs = await this.getSmsPreferences() || { isOptedIn: false };
    const updatedPrefs = { ...currentPrefs, ...prefs };
    localStorage.setItem(this.SMS_PREFS_KEY, JSON.stringify(updatedPrefs));
  }
}
