<div id="profile-appearance">

    <div class="row">

        <div class="col-12 col-sm-2">
            <label>Upload Logo</label>
        </div>
        <div class="col-12 col-sm-10">
            <div id='droparea' #drop></div>
            <span>Drop files here to upload or browse</span>
            <ejs-uploader [autoUpload]="false" [multiple]="false" [dropArea]="drop" height="100"
                (beforeUpload)="uploadFile()" #LogoUploader>
            </ejs-uploader>

        </div>

        <div class="col-12 col-sm-2">
            <label>Toggle Darkmode </label>
        </div>
        <div class="col-12 col-sm-10">
            <button ejs-button #darkModeBtn cssClass="e-outline" iconCss="e-icons e-brightness" isToggle="true"
                data-ripple="true" (click)="toggleDarkMode()"></button>
        </div>

        <div class="col-12 col-sm-2">
            <label>Themes</label>
        </div>
        <div class="col-12 col-sm-10">
            <theme-picker />
        </div>
    </div>
</div>