<div class="container">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Case Companion Agent</mat-card-title>
      <mat-card-subtitle>Ask anything about your case.</mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
      <form [formGroup]="agentForm" (ngSubmit)="sendPrompt()">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Case ID</mat-label>
          <input matInput formControlName="caseId" placeholder="Enter case ID" />
          <mat-error *ngIf="agentForm.get('caseId')?.hasError('required')"> Case ID is required </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Quick Prompts</mat-label>
          <mat-select formControlName="selectedPrompt" (selectionChange)="onPromptSelected()">
            <mat-option [value]="''">Custom Prompt</mat-option>
            <mat-option *ngFor="let prompt of predefinedPrompts" [value]="prompt.value">
              {{ prompt.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Your Question</mat-label>
          <textarea
            matInput
            formControlName="customPrompt"
            rows="3"
            placeholder="Type your question here..."
          ></textarea>
          <mat-error *ngIf="agentForm.get('customPrompt')?.hasError('required')"> Please enter a question </mat-error>
        </mat-form-field>

        <button mat-raised-button color="primary" type="submit" [disabled]="!agentForm.valid || isLoading">
          <mat-icon *ngIf="isLoading">hourglass_empty</mat-icon>
          {{ isLoading ? 'Processing...' : 'Ask Question' }}
        </button>
      </form>

      <div *ngIf="isLoading" class="loading-container">
        <mat-spinner diameter="40"></mat-spinner>
        <p>Processing your question...</p>
      </div>

      <div *ngIf="error" class="error-container">
        <mat-icon color="warn">error</mat-icon>
        <p>{{ error }}</p>
      </div>

      <div *ngIf="response" class="response-container">
        <div class="response-message">
          <p>{{ response.message }}</p>
        </div>

        <div *ngIf="response.data" class="response-data">
          <pre>{{ response.data | json }}</pre>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
