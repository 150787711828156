// Angular
import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
  OnInit,
  OnDestroy,
  signal,
  WritableSignal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, FormArray, ReactiveFormsModule, Validators, AbstractControl } from '@angular/forms';

// 3rd Party
import { Predicate, Query } from '@syncfusion/ej2-data';
import { ButtonModule, SwitchModule } from '@syncfusion/ej2-angular-buttons';
import { TextBoxModule, NumericTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';

// Internal
import { ComponentBase } from '@core/base/component.base';
import { APIEndpoints } from '@models/api/Endpoints';
import { Invoice, InvoiceRow, InvoicePayment, ProcedureCode, Provider, CaseFile, ListPaymentMethod } from '@models/data-contracts';
import { ApiService } from '@services/api/api.service';
import { FileHubService } from '@root/src/app/features/file-hub/services/file-hub.service';
import { Subject, takeUntil } from 'rxjs';
import { LoadingModule } from '@root/src/app/shared/modules/loading.module';

enum WarningType {
  Closed = 'closed',
  Important = 'notesImportant',
  SingleAgreement = 'singleAgreement',
  SignedLien = 'signedLien',
  NoProcedure = 'NoProcedure',
}

interface Warnings {
  [WarningType.Closed]: boolean | null;
  [WarningType.Important]: boolean | null;
  [WarningType.SingleAgreement]: boolean | null;
  [WarningType.SignedLien]: boolean | null;
  [WarningType.NoProcedure]: boolean | null;
}

// Add this interface to help with type safety
interface XrefFeeScheduleProcedureCode {
  ProcedureCodeId: number;
  BilledAmount?: number;
  BackEndReimbursmentRate?: number;
  FrontEndReimbursmentRate?: number;
  SettlementValue?: number;
}

@Component({
  selector: 'add-invoice-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    SwitchModule,
    TextBoxModule,
    DropDownListModule,
    DatePickerModule,
    NumericTextBoxModule,
    LoadingModule,
  ],
  templateUrl: './add-invoice.component.html',
  styleUrl: './add-invoice.component.scss',
})
export class AddInvoiceForm extends ComponentBase implements OnInit, OnDestroy {
  protected readonly context = 'AddInvoiceForm';
  protected readonly loading = signal(false);

  private destroy$ = new Subject<void>();
  invoiceForm: FormGroup;
  WarningType = WarningType;

  @Input() invoice: Invoice | null = null;
  @Input() hideWarnings: boolean = false;
  @Output() submit = new EventEmitter<any>();
  @Output() close = new EventEmitter<any>();
  @Output() warningsChanged = new EventEmitter<boolean>();

  @ViewChild('providerDropdown') providerDropdown: any;

  // Data Sources
  surgicalProviderTypes = this.api.getOdata(APIEndpoints.SurgicalInvoices);

  // State
  showSurgicalFields: boolean = false;

  // Queries
  expandProviderQuery = 'feeSchedule';

  warnings: Warnings = {
    [WarningType.Closed]: null,
    [WarningType.Important]: null,
    [WarningType.SingleAgreement]: null,
    [WarningType.SignedLien]: null,
    [WarningType.NoProcedure]: null,
  };

  // Convert to signals
  public readonly providerListSignal = signal<Provider[]>([]);
  public readonly selectedProviderSignal = signal<Provider | null>(null);
  public readonly procedureCodesSignal = signal<ProcedureCode[]>([]);
  public readonly paymentMethodsSignal = signal<ListPaymentMethod[]>([]);
  private readonly feeScheduleXrefsSignal = signal<XrefFeeScheduleProcedureCode[]>([]);
  protected readonly paymentStatusesSignal = signal<any[]>([]);

  // Add getter for selectedProvider
  get selectedProvider(): Provider | null {
    return this.selectedProviderSignal();
  }

  // Add getter for procedureCodes
  get procedureCodes(): ProcedureCode[] {
    return this.procedureCodesSignal();
  }

  get paymentMethods(): ListPaymentMethod[] {
    return this.paymentMethodsSignal();
  }

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    public fileHub: FileHubService
  ) {
    super();
    this.initForm();
  }

  async ngOnInit() {
    try {
      await Promise.all([
        this.loadProviders(),
        this.loadPaymentStatuses(),
        this.loadPaymentMethods()
      ]);

      if (!this.fileHub.caseFile?.IsActive) this.warnings[WarningType.Closed] = true;
      if (this.fileHub.caseFile?.IsSurgical) this.showSurgicalFields = true;
    } catch (error) {
      this.handleError(error, {
        context: 'AddInvoiceForm.ngOnInit',
        userMessage: 'Failed to initialize invoice form'
      });
    }
  }

  private async loadProviders(): Promise<void> {
    try {
      this.loading.set(true);  // Set loading when loading providers
      const query = this.invoice ?
        new Query().select(['Name', 'Id']) :
        new Query().select(['Name', 'Id']).where('IsActive', 'equal', true);
      query.sortBy('Name', 'asc');

      const response: any = await this.api.getOdata(APIEndpoints.Providers).executeQuery(query);
      this.providerListSignal.set(response.result);

      if (this.invoice?.ProviderId) {
        this.invoiceForm.patchValue({ ProviderId: this.invoice.ProviderId });
      }
    } catch (error) {
      this.handleError(error, {
        context: 'AddInvoiceForm.loadProviders',
        userMessage: 'Failed to load providers'
      });
    } finally {
      this.loading.set(false);  // Clear loading when done
    }
  }

  private async loadPaymentStatuses(): Promise<void> {
    try {
      this.loading.set(true);
      const query = new Query();
      query.sortBy('Description', 'asc');
      const response: any = await this.api.getOdata(APIEndpoints.PaymentStatuses).executeQuery(query);
      this.paymentStatusesSignal.set(response.result);
    } catch (error) {
      this.handleError(error, {
        context: 'AddInvoiceForm.loadPaymentStatuses',
        userMessage: 'Failed to load payment statuses'
      });
    } finally {
      this.loading.set(false);
    }
  }

  private async loadPaymentMethods(): Promise<void> {
    try {
      this.loading.set(true);  // Set loading when loading payment methods
      const query = new Query().sortBy('Description', 'asc');
      const response: any = await this.api.getOdata(`${APIEndpoints.PaymentMethods}`).executeQuery(query);
      this.paymentMethodsSignal.set(response.result);
    } catch (error) {
      this.handleError(error, {
        context: 'AddInvoiceForm.loadPaymentMethods',
        userMessage: 'Failed to load payment methods'
      });
    } finally {
      this.loading.set(false);  // Clear loading when done
    }
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes['invoice']) {
      this.resetForm();
    }
    if (changes['invoice'] && this.invoice) {
      await this.initializeEditForm();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.resetForm();
  }

  private initForm(): void {
    this.invoiceForm = this.fb.group({
      ProviderInvoiceNumber: [''],
      InternalInvoiceNumber: [''],
      InvoiceDate: ['', Validators.required],
      LockInvoice: [0],
      Notes: [''],
      SurgicalInvoiceId: [null],
      SplitInvoice: [0],
      SplitInvoiceId: [null],
      InvoiceTransferred: [0],
      CaseFileId: [0],
      ProviderId: ['', Validators.required],
      InvoiceRows: this.fb.array([]),
      InvoicePayments: this.fb.array([])
    });

    // Subscribe to Invoice Date changes
    this.invoiceForm.get('InvoiceDate')?.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(date => {
        if (date) {
          // Update DateOfService in Invoice Rows if pristine
          this.invoiceRows.controls.forEach(row => {
            if (row.get('DateOfService')?.pristine) {
              row.patchValue({ DateOfService: date }, { emitEvent: false });
            }
          });

          // Update DatePaid in Payment Details if pristine
          this.invoicePayments.controls.forEach(payment => {
            if (payment.get('DatePaid')?.pristine) {
              payment.patchValue({ DatePaid: date }, { emitEvent: false });
            }
          });
        }
      });
  }

  get invoiceRows(): FormArray {
    return this.invoiceForm.get('InvoiceRows') as FormArray;
  }

  get invoicePayments(): FormArray {
    return this.invoiceForm.get('InvoicePayments') as FormArray;
  }

  // Add method to update all payment balances
  private updateAllPaymentBalances(): void {
    const totalDue = this.calculateTotalDueProviderAmount();
    this.invoicePayments.controls.forEach(payment => {
      const balanceDueControl = payment.get('BalanceDue');
      if (balanceDueControl) {
        balanceDueControl.setValue(totalDue, { emitEvent: false });
      }
    });
  }

  private createInvoiceRow(): FormGroup {
    return this.fb.group({
      DateOfService: ['', Validators.required],
      ProcedureCodeId: ['', Validators.required],
      AmountBilled: [''],
      ReimbursementRate: [''],
      TotalDueProvider: [''],
      SettlementValue: [{ value: '', disabled: true }]
    });
  }

  private calculateTotalDueProviderAmount(): number {
    return this.invoiceRows.controls.reduce((total, row) => {
      const tdProvider = row.get('TotalDueProvider')?.value || 0;
      return total + Number(tdProvider);
    }, 0);
  }

  private createInvoicePayment(): FormGroup {
    const totalDue = this.calculateTotalDueProviderAmount();
    return this.fb.group({
      DatePaid: ['', Validators.required],
      BalanceDue: [{ value: totalDue, disabled: true }],
      AmountPaid: [0],
      PaymentStatus: ['', Validators.required],
      PaymentMethod: ['', Validators.required],
      TransactionNumber: ['']
    });
  }

  addInvoiceRow(): void {
    const invoiceDate = this.invoiceForm.get('InvoiceDate')?.value;
    const newRow = this.createInvoiceRow();

    if (invoiceDate) {
      newRow.patchValue({ DateOfService: invoiceDate });
    }

    this.invoiceRows.push(newRow);
  }

  addInvoicePayment(): void {
    this.invoicePayments.push(this.createInvoicePayment());
    this.setupPaymentValidation();
  }

  deleteInvoiceRow(index: number): void {
    this.invoiceRows.removeAt(index);
    this.updateAllPaymentBalances();  // Update balances when row is deleted
  }

  deleteInvoicePayment(index: number): void {
    this.invoicePayments.removeAt(index);
  }

  async initializeEditForm(): Promise<void> {
    if (!this.invoice) return;
    this.loading.set(true);

    try {
        // Set the form data
        this.invoiceForm.patchValue({
            ...this.invoice,
            ProviderId: this.invoice.ProviderId,
        });

        // Clear existing arrays
        while (this.invoiceRows.length) {
            this.invoiceRows.removeAt(0);
        }
        while (this.invoicePayments.length) {
            this.invoicePayments.removeAt(0);
        }

        // First load provider data to get procedure codes
        if (this.invoice.ProviderId) {
            await this.onProviderChange(null, this.invoice.ProviderId);
        }

        // Now set invoice rows after procedure codes are loaded
        if (this.invoice.InvoiceRows) {
            this.invoice.InvoiceRows.forEach(row => {
                const rowGroup = this.fb.group({
                    Id: [row.Id],
                    InvoiceId: [row.InvoiceId],
                    DateOfService: [row.DateOfService ? new Date(row.DateOfService).toISOString().split('T')[0] : '', Validators.required],
                    ProcedureCodeId: [row.ProcedureCodeId, Validators.required],
                    AmountBilled: [row.AmountBilled],
                    ReimbursementRate: [row.ReimbursementRate],
                    TotalDueProvider: [row.TotalDueProvider],
                    SettlementValue: [row.SettlementValue || row.AmountBilled]  // Use settlement value or fallback to billed amount
                });
                this.invoiceRows.push(rowGroup);
            });

            // Update payment balances after rows are loaded
            this.updateAllPaymentBalances();
        }

        // Set invoice payments
        if (this.invoice.InvoicePayments) {
            const totalDue = this.calculateTotalDueProviderAmount();
            this.invoice.InvoicePayments.forEach(payment => {
                const paymentGroup = this.fb.group({
                    Id: [payment.Id],
                    InvoiceId: [payment.InvoiceId],
                    DatePaid: [payment.DatePaid ? new Date(payment.DatePaid).toISOString().split('T')[0] : '', Validators.required],
                    BalanceDue: [{ value: totalDue, disabled: true }],
                    AmountPaid: [payment.AmountPaid],
                    PaymentStatus: [payment.PaymentStatus, Validators.required],
                    PaymentMethod: [payment.PaymentMethod, Validators.required],
                    TransactionNumber: [payment.TransactionNumber]
                });
                this.invoicePayments.push(paymentGroup);
            });
        }
    } catch (error) {
        this.handleError(error, {
            context: 'AddInvoiceForm.initializeEditForm',
            userMessage: 'Failed to initialize invoice form'
        });
    } finally {
        this.loading.set(false);
    }
  }

  async onProviderChange(args?: any, providerId?: number): Promise<void> {
    try {
        this.loading.set(true);  // Set loading when provider changes
        const id = providerId ?? args?.value;
        if (!id) return;

        this.invoiceForm.patchValue({ ProviderId: id });

        const query = new Query()
            .where('Id', 'equal', id)
            .expand(this.expandProviderQuery);

        const providerResponse: any = await this.api
            .getOdata(`${APIEndpoints.Providers}(${id})`)
            .executeQuery(query);

        const provider = providerResponse.result[0];
        this.selectedProviderSignal.set(provider);
        if (!provider?.InNetwork) this.warnings['singleAgreement'] = true;
        if (provider?.NotesImportant) this.warnings['notesImportant'] = true;

        if (provider) {
            await this.fetchProcedureCodeData(provider);
        }
    } catch (error) {
        this.handleError(error, {
            context: 'AddInvoiceForm.onProviderChange',
            userMessage: 'Failed to change provider'
        });
    } finally {
        this.loading.set(false);  // Clear loading when done
    }
  }

  async fetchProcedureCodeData(provider: Provider): Promise<void> {
    if (provider.FeeScheduleId) {
        try {
            this.loading.set(true);  // Set loading when fetching procedure codes
            const query = new Query()
                .where('Id', 'equal', provider.FeeScheduleId)
                .expand('XrefFeeScheduleProcedureCodes($expand=ProcedureCode($select=Id,ProcedureCodeName,Description))');
            const procedureCodesResponse: any = await this.api
                .getOdata(`${APIEndpoints.FeeSchedules}(${provider.FeeScheduleId})`)
                .executeQuery(query);

            const feeSchedule = procedureCodesResponse.result[0];
            this.feeScheduleXrefsSignal.set(feeSchedule.XrefFeeScheduleProcedureCodes);

            this.procedureCodesSignal.set(feeSchedule.XrefFeeScheduleProcedureCodes.map(
                (xref: { ProcedureCode: any; }) => xref.ProcedureCode
            ));
            this.formatProcedureCodes(this.procedureCodesSignal());
        } catch (error) {
            this.handleError(error, {
                context: 'AddInvoiceForm.fetchProcedureCodeData',
                userMessage: 'Failed to fetch procedure codes'
            });
        } finally {
            this.loading.set(false);  // Clear loading when done
        }
    } else {
        this.warnings['NoProcedure'] = true;
    }
  }

  formatProcedureCodes(codes: ProcedureCode[]): void {
    codes.forEach(code => {
      if (code.ProcedureCodeName && code.Description) {
        code.ProcedureCodeName = `${code.ProcedureCodeName} - ${code.Description}`;
      }
    });
    codes.sort((a, b) => {
      if (!a.ProcedureCodeName || !b.ProcedureCodeName) return 0;
      return a.ProcedureCodeName.localeCompare(b.ProcedureCodeName);
    });
  }

  // Modify onProcedureCodeChange to update balances
  onProcedureCodeChange(args: any, rowIndex: number): void {
    if (!args.itemData) return;

    const row = this.invoiceRows.at(rowIndex);
    const selectedProcedureId = args.itemData.Id;

    const xrefData = this.feeScheduleXrefsSignal().find(
        xref => xref.ProcedureCodeId === selectedProcedureId
    );

    if (xrefData) {
        const billedAmount = xrefData.BilledAmount || 0;
        const frontEndRate = xrefData.FrontEndReimbursmentRate || 0;
        const totalDueProvider = this.calculateTotalDueProvider(billedAmount, frontEndRate);
        const settlementValue = xrefData.SettlementValue || billedAmount; // Use settlement value from xref or fallback to billed amount

        row.patchValue({
            ProcedureCodeId: selectedProcedureId,
            AmountBilled: billedAmount,
            ReimbursementRate: frontEndRate,
            TotalDueProvider: totalDueProvider,
            SettlementValue: settlementValue
        });

        // Update all payment balances after changing T.D.Provider
        this.updateAllPaymentBalances();
    }
  }

  private calculateTotalDueProvider(billedAmount: number, reimbursementRate: number): number {
    return billedAmount * (reimbursementRate / 100);
  }

  hideWarning(warning: WarningType): void {
    this.warnings[warning] = false;
    this.isFormHidden(); // Emit warning change event
  }

  resetWarnings(): void {
    for (const key in WarningType) {
      if (Object.prototype.hasOwnProperty.call(this.warnings, key)) {
        this.warnings[key as keyof Warnings] = null;
      }
    }
  }

  isFormHidden(): boolean {
    const hasWarnings = !!(
      this.warnings[WarningType.Closed] ||
      this.warnings[WarningType.Important] ||
      this.warnings[WarningType.SingleAgreement] ||
      this.warnings[WarningType.SignedLien] ||
      this.warnings[WarningType.NoProcedure]
    );
    this.warningsChanged.emit(hasWarnings);
    return hasWarnings;
  }

  resetForm(): void {
    this.initForm();
    this.selectedProviderSignal.set(null);
    this.procedureCodesSignal.set([]);
    this.resetWarnings();

    // Reset the provider dropdown
    if (this.providerDropdown) {
      this.providerDropdown.value = null;
      this.providerDropdown.text = null;
    }
  }

  closeForm(): void {
    // Remove the form validation check to allow closing anytime
    this.resetForm();
    this.close.emit();
  }

  onSubmit(): void {
    if (!this.invoiceForm.valid) {
      // Mark all fields as touched to trigger validation messages
      Object.keys(this.invoiceForm.controls).forEach(key => {
        const control = this.invoiceForm.get(key);
        control?.markAsTouched();
      });

      // Mark all fields in the form arrays as touched
      this.invoiceRows.controls.forEach((control: AbstractControl) => {
        if (control instanceof FormGroup) {
          Object.keys(control.controls).forEach(key => {
            control.get(key)?.markAsTouched();
          });
        }
      });

      this.invoicePayments.controls.forEach((control: AbstractControl) => {
        if (control instanceof FormGroup) {
          Object.keys(control.controls).forEach(key => {
            control.get(key)?.markAsTouched();
          });
        }
      });

      this.notification.warn('Please fill in all required fields before submitting');
      return;
    }

    if (!this.validateTotalPayments()) {
      this.notification.error('Amount Paid should be equal to Balance Due');
      return;
    }

    this.loading.set(true); // Show loading state while submitting

    const formValue = this.invoiceForm.value;

    // Clean up invoice rows data
    const invoiceRowsData = this.invoiceRows.controls.map(control => control.value) as InvoiceRow[];
    invoiceRowsData.forEach(row => {
      delete row.Invoice;
      delete row.ProcedureCode;
      delete row.DepositAllocations;
    });

    // Clean up invoice payments data
    const invoicePaymentsData = this.invoicePayments.controls.map(control => control.value) as InvoicePayment[];
    invoicePaymentsData.forEach(payment => {
      delete payment.Invoice;
    });

    const invoiceData: Invoice = {
      ...this.formatDates(formValue),
      CaseFileId: this.fileHub.caseFile?.Id || 0,
      InvoiceRows: invoiceRowsData,
      InvoicePayments: invoicePaymentsData
    };

    if (this.invoice?.Id) {
      invoiceData.Id = this.invoice.Id;
      invoiceData.InvoiceRows?.forEach(row => (row.InvoiceId = invoiceData.Id));
      invoiceData.InvoicePayments?.forEach(payment => (payment.InvoiceId = invoiceData.Id));
    }

    this.createInvoice(invoiceData);
  }

  createInvoice(formInvoice: Invoice): void {
    const endpoint = this.invoice ?
      `odata${APIEndpoints.Invoices}(${this.invoice?.Id})` :
      `odata${APIEndpoints.Invoices}`;

    const method = this.invoice ? 'PATCH' : 'POST';

    this.api.fetchRequest(endpoint, method, formInvoice)
      .then((response: any) => {
        if (response) {
          this.notification.success(`Invoice successfully ${this.invoice ? 'updated' : 'created'}`);
          this.submit.emit();
          this.resetForm();
          this.close.emit();
        } else {
          throw new Error('Failed to save invoice');
        }
      })
      .catch((error) => {
        this.handleError(error, {
          context: 'AddInvoiceForm.createInvoice',
          userMessage: 'Failed to create or update invoice'
        });
      })
      .finally(() => {
        this.loading.set(false);
      });
  }

  formatDates(formObj: any): any {
    if (Array.isArray(formObj)) {
      return formObj.map(item => this.formatDates(item));
    }

    const formatted = { ...formObj };
    for (const key in formatted) {
      if (formatted.hasOwnProperty(key)) {
        const value = formatted[key];

        // Handle nested arrays (InvoiceRows and InvoicePayments)
        if (Array.isArray(value)) {
          formatted[key] = value.map(item => {
            const formattedItem = { ...item };
            // Format DateOfService in InvoiceRows
            if (formattedItem.DateOfService) {
              formattedItem.DateOfService = new Date(formattedItem.DateOfService)
                .toISOString().split('T')[0];
            }
            // Format DatePaid in InvoicePayments
            if (formattedItem.DatePaid) {
              formattedItem.DatePaid = new Date(formattedItem.DatePaid)
                .toISOString().split('T')[0];
            }
            return formattedItem;
          });
        }
        // Handle regular date fields
        else if (value instanceof Date) {
          formatted[key] = value.toISOString().split('T')[0];
        }
      }
    }
    return formatted;
  }

  // Modify isFieldDisabled method
  isFieldDisabled(field: string | null = null): boolean {
    // If loading, disable all fields
    if (this.loading()) {
      return true;
    }

    // If invoice is locked, everything except LockInvoice switch is disabled
    if (this.invoiceForm.get('LockInvoice')?.value && field !== 'LockInvoice') {
      return true;
    }

    // Handle specific fields based on existing rules
    switch (field) {
      case 'provider':
      case 'settlement':
      case 'balance':
        // These fields should be disabled when editing
        return this.invoice !== null;
      default:
        // All other fields follow default behavior
        return false;
    }
  }

  hasFeeSchedule(): boolean {
    return !!this.selectedProvider?.FeeScheduleId;
  }

  // Change from private to public
  validateTotalPayments(): boolean {
    const totalDueProvider = this.invoiceRows.controls.reduce((total, row) => {
      return total + (row.get('TotalDueProvider')?.value || 0);
    }, 0);

    const totalAmountPaid = this.invoicePayments.controls.reduce((total, payment) => {
      return total + (payment.get('AmountPaid')?.value || 0);
    }, 0);

    return Math.abs(totalDueProvider - totalAmountPaid) < 0.01; // Using small epsilon for float comparison
  }

  // Add validation when payment amount changes
  private setupPaymentValidation(): void {
    this.invoicePayments.controls.forEach(payment => {
      payment.get('AmountPaid')?.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          // Remove notification, only validate
          this.validateTotalPayments();
        });
    });
  }

  hasInvoiceRowErrors(): boolean {
    return this.hasDateOfServiceErrors() || this.hasProcedureCodeErrors();
  }

  hasDateOfServiceErrors(): boolean {
    return this.invoiceRows.controls.some(row =>
      row.get('DateOfService')?.invalid && row.get('DateOfService')?.touched
    );
  }

  hasProcedureCodeErrors(): boolean {
    return this.invoiceRows.controls.some(row =>
      row.get('ProcedureCodeId')?.invalid && row.get('ProcedureCodeId')?.touched
    );
  }

  hasInvoicePaymentErrors(): boolean {
    return this.hasDatePaidErrors() ||
           this.hasPaymentMethodErrors() ||
           this.hasPaymentStatusErrors() ||
           !this.validateTotalPayments();
  }

  hasDatePaidErrors(): boolean {
    return this.invoicePayments.controls.some(payment =>
      payment.get('DatePaid')?.invalid && payment.get('DatePaid')?.touched
    );
  }

  hasPaymentMethodErrors(): boolean {
    return this.invoicePayments.controls.some(payment =>
      payment.get('PaymentMethod')?.invalid && payment.get('PaymentMethod')?.touched
    );
  }

  hasPaymentStatusErrors(): boolean {
    return this.invoicePayments.controls.some(payment =>
      payment.get('PaymentStatus')?.invalid && payment.get('PaymentStatus')?.touched
    );
  }
}
