import { CommonModule } from "@angular/common";
import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges, HostListener } from "@angular/core";
import { 
  FormControl, 
  FormGroup, 
  ReactiveFormsModule, 
  Validators
} from "@angular/forms";
import { APIEndpoints } from "@root/src/app/shared/models/api/Endpoints";
import { ApiService } from "@root/src/app/shared/services/api/api.service";
import { ButtonModule } from "@syncfusion/ej2-angular-buttons";
import { DropDownListModule, MultiSelectModule } from "@syncfusion/ej2-angular-dropdowns";
import { GridComponent } from "@syncfusion/ej2-angular-grids";
import { TextBoxModule } from "@syncfusion/ej2-angular-inputs";
import { TooltipModule } from '@syncfusion/ej2-angular-popups';
import { DataManager } from "@syncfusion/ej2-data";
import { ToastMessageService } from '@services/toast-message/toast-message.service';
import { GlobalsService } from '@services/globals/globals.service';
import { MaskedTextBoxModule } from "@syncfusion/ej2-angular-inputs";
import { User } from "@root/src/app/shared/models/data-contracts";
import { BaseGridService } from "@shared/components/base-grid/services/state.service";

interface ApiError {
  code: string;
  message: string;
  field: string | null;
}

interface ApiResponse<T> {
  success: boolean;
  data: T | null;
  errors: ApiError[];
}

/**
 * Component for adding new users to the system
 * Includes form validation and role assignment
 */
@Component({
  selector: 'add-user',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    TextBoxModule,  
    DropDownListModule,
    MultiSelectModule,
    TooltipModule,
    MaskedTextBoxModule
  ],
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})

export class AddUserComponent implements OnInit, OnChanges {
  @Input() grid: GridComponent;
  @Input() mode: 'add' | 'edit' = 'add';
  @Input() userId?: number;
  @Input() userData?: User | null;
  @Output() closeForm = new EventEmitter<void>();
  
  // Form group with validation rules
  addUserForm = new FormGroup({
    userName: new FormControl('', { 
      validators: [Validators.required],
      updateOn: 'blur'
    }),
    name: new FormControl('', { 
      validators: [Validators.required],
      updateOn: 'blur'
    }),
    email: new FormControl('', { 
      validators: [Validators.required, Validators.email],
      updateOn: 'blur'
    }),
    title: new FormControl(''),
    phoneNumber: new FormControl('', { 
      validators: [Validators.required, this.globals.phoneNumberValidator()],
      updateOn: 'blur'
    }),
    phoneType: new FormControl('', { 
      validators: [Validators.required],
      updateOn: 'change'
    }),
    roleIds: new FormControl<number[]>([], { 
      validators: [Validators.required],
      updateOn: 'change'
    }),
    rightIds: new FormControl<number[]>([])
  });

  rolesData: DataManager;
  rightsData: DataManager;
  errorMessage: string = '';
  phoneTypes: any[] = [];
  originalRoleIds: number[] = [];
  originalRightIds: number[] = [];

  constructor(
    private api: ApiService, 
    private toast: ToastMessageService,
    private globals: GlobalsService,
    private gridService: BaseGridService
  ) {}

  ngOnInit(): void {
    this.resetFormState();
    this.setRoles();
    this.setRights();
    this.loadPhoneTypes();
    
    // Mark form as pristine and untouched initially
    this.addUserForm.markAsPristine();
    this.addUserForm.markAsUntouched();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Only load user data if we're in edit mode AND we have userData
    if (this.mode === 'edit' && changes['userData'] && this.userData) {
      this.loadUserData();
    } else if (this.mode === 'add') {
      // Reset form when in add mode
      this.resetFormState();
    }
  }

  /**
   * Fetches available roles from the API
   */
  private setRoles(): void {
    this.rolesData = this.api.getOdata(APIEndpoints.Roles);
  }

  /**
   * Fetches available rights from the API
   */
  private setRights(): void {
    this.rightsData = this.api.getOdata(APIEndpoints.Rights);
  }

  /**
   * Handles escape key press to close form
   */
  @HostListener('document:keydown.escape')
  handleEscapeKey(): void {
    this.cancel();
    this.closeForm.emit();
  }

  /**
   * Resets form and clears error messages
   */
  cancel(): void {
    this.resetFormState();
    this.userData = null; // Clear the userData
    this.closeForm.emit();
  }

  /**
   * Handles form submission and user creation
   */
  async onSubmit(): Promise<void> {
    if (!this.addUserForm.valid) {
      this.markFormGroupTouched(this.addUserForm);
      return;
    }

    try {
      if (this.mode === 'add') {
        await this.createUser();
      } else {
        await this.updateUser();
      }
      
      // Refresh grid after successful submit
      if (this.grid) {
        this.gridService.refreshGrid();
      }
      
      this.closeForm.emit();
    } catch (error) {
      this.handleError(error);
    }
  }
  
  private formatPhoneNumberForCognito(phoneNumber: string): string {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    return `+1${cleaned}`; // E.164 format required by Cognito
  }

  private async createCognitoUser(): Promise<any> {
    const cognitoData = {
      email: this.addUserForm.value.email,
      name: this.addUserForm.value.name,
      phoneNumber: this.formatPhoneNumberForCognito(this.addUserForm.value.phoneNumber ?? ''),
      userName: this.addUserForm.value.userName
    };
    
    console.log('Sending Cognito request:', cognitoData);
    
    const cognitoResponse = await this.api.basicPost('api/cognito/create-user', cognitoData, false) as Response;
    
    console.log('Cognito response:', await cognitoResponse.clone().json());

    if (!cognitoResponse.ok) {
      throw new Error(`Failed to create Cognito user: ${cognitoResponse.statusText}`);
    }
    
    return this.parseResponse(cognitoResponse, 'Cognito');
  }
  
  private async createPhone(): Promise<number> {
    const formData = {
      phoneNumber: this.formatPhoneNumber(this.addUserForm.value.phoneNumber ?? ''),
      phoneType: this.addUserForm.value.phoneType
    };
    
    const response = await this.api.basicPost(APIEndpoints.Phones, formData) as Response;
    const data = await response.json();
    return data.Id;  // Note: Capital 'I' in Id based on backend convention
  }
  
  private prepareUserData(userSub: string): any {
    return {
      Name: this.addUserForm.value.name,
      Username: this.addUserForm.value.userName,
      Email: this.addUserForm.value.email,
      PhoneNumber: this.formatPhoneNumberForCognito(this.addUserForm.value.phoneNumber ?? ''),
      Title: this.addUserForm.value.title || '',
      FileGroupId: null,
      IsActive: 1,
      UserPreferences: '',
      UserSub: userSub,
      XrefUsersRights: this.getRightIds().map(rightId => ({ RightId: rightId })),
      XrefUsersRoles: this.getRoleIds().map(roleId => ({ RoleId: roleId })),
      XrefAddressUsers: [],
      XrefPhoneUsers: []
    };
  }
  
  private async createDatabaseUser(userData: User): Promise<number> {
    const response = await this.api.basicPost(APIEndpoints.Users, userData) as Response;
    if (!response.ok) {
        throw new Error('Failed to create database user');
    }
    const data = await response.json();
    return data.Id;  // Note: Capital 'I' in Id based on backend convention
  }
  
  private async parseResponse(response: Response, context: string): Promise<any> {
    try {
      return await response.json();
    } catch (error) {
      throw new Error(`Failed to parse ${context} response: ${response.statusText}`);
    }
  }
  
  private showSuccessMessage(userName: string, userId: number, action: 'created' | 'updated'): void {
    this.toast.showSuccess(`User ${userName} has been ${action}.`);
  }
  
  private handleError(error: any): void {
    this.errorMessage = (error as Error).message || 'Failed to create user. Please try again.';
    this.toast.showError(this.errorMessage);
  }
  // Helper method to mark all form controls as touched
  private markFormGroupTouched(formGroup: FormGroup): void {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }

  /**
   * Returns appropriate error message for form validation
   */
  getErrorMessage(controlName: string): string {
    const control = this.addUserForm.get(controlName);
    if (control?.errors) {
      if (control.errors['required']) return 'This field is required';
      if (control.errors['email']) return 'Invalid email format';
      if (control.errors['invalidPhone']) return 'Invalid phone number (10 digits required)';
      if (control.errors['serverError']) return this.errorMessage;
    }
    return '';
  }

  // Add this method to clear form state
  resetFormState(): void {
    this.addUserForm.reset();
    this.errorMessage = '';
    // Reset validation state
    Object.keys(this.addUserForm.controls).forEach(key => {
      const control = this.addUserForm.get(key);
      control?.markAsPristine();
      control?.markAsUntouched();
    });
  }

  private getRoleIds(): number[] {
    return this.addUserForm.get('roleIds')?.value ?? [];
  }

  private getRightIds(): number[] {
    return this.addUserForm.get('rightIds')?.value ?? [];
  }

  /**
   * Formats phone number to E.164 format for Cognito
   */
  private formatPhoneNumber(phoneNumber: string): string {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
    return phoneNumber;
  }

  private async createPhoneUserXref(userId: number, phoneId: number): Promise<void> {
    const xrefData = {
      userId: userId,
      phoneId: phoneId
    };
    await this.api.basicPost(APIEndpoints.XrefPhoneUsers, xrefData);
  }

  private async addUserRoles(userId: number, roleIds: number[]): Promise<void> {
    const url = `/users(${userId})/AddUserRoles`;
    const response = await this.api.basicPost(url, roleIds) as Response;
    if (!response.ok) {
      throw new Error('Failed to add user roles');
    }
  }

  private async loadPhoneTypes(): Promise<void> {
    const response = await this.api.basicFetch(APIEndpoints.PhoneTypes);
    this.phoneTypes = response.value.map((item: any) => ({
      id: item.Id.toString(),
      description: item.Description
    }));
  }

  private async loadUserData(): Promise<void> {
    if (!this.userData) return;

    try {
      const response = await this.api.basicFetch(
        `${APIEndpoints.Users}(${this.userData.Id})?$expand=XrefUsersRoles($expand=Role),XrefUsersRights($expand=Right),XrefPhoneUsers($expand=Phone)`
      );
      
      const userData = response.value[0];
      const phoneData = userData.XrefPhoneUsers?.[0]?.Phone;
      
      const roleIds = userData.XrefUsersRoles?.map((xref: any) => xref.Role.Id) || [];
      const rightIds = userData.XrefUsersRights?.map((xref: any) => xref.Right.Id) || [];
      
      const formValues = {
        userName: userData.Username,
        name: userData.Name,
        email: userData.Email,
        title: userData.Title || '',
        phoneNumber: phoneData?.PhoneNumber || '',
        phoneType: phoneData?.PhoneType?.toString() || '',
        roleIds: roleIds,
        rightIds: rightIds
      };

      this.addUserForm.patchValue(formValues);

      if (this.mode === 'edit') {
        // Disable fields in edit mode
        this.addUserForm.get('userName')?.disable();
        this.addUserForm.get('email')?.disable();
        this.addUserForm.get('phoneNumber')?.disable();
        this.addUserForm.get('phoneType')?.disable();
      }

      this.originalRoleIds = roleIds;
      this.originalRightIds = rightIds;
    } catch (error) {
      console.error('Error loading user data:', error);
    }
  }

  private async createUser(): Promise<void> {
    const cognitoData = await this.createCognitoUser();
    const phoneId = await this.createPhone();
    const userData = this.prepareUserData(cognitoData.userSub);
    const newUserId = await this.createDatabaseUser(userData);
    await this.createPhoneUserXref(newUserId, phoneId);
    await this.addUserRoles(newUserId, this.getRoleIds());
    
    // Only add rights if there are any selected
    const rightIds = this.getRightIds();
    if (rightIds.length > 0) {
      await this.addUserRights(newUserId, rightIds);
    }
    
    this.showSuccessMessage(userData.Name ?? '', newUserId, 'created');
  }

  private async updateUser(): Promise<void> {
    if (!this.userData?.Id) {
      throw new Error('No user ID provided for update');
    }

    // Get new and original IDs for both roles and rights
    const newRoleIds = this.getRoleIds();
    const originalRoleIds = this.originalRoleIds;
    const newRightIds = this.getRightIds();
    const originalRightIds = this.originalRightIds;
    
    // Prevent removing all roles
    if (newRoleIds.length === 0) {
      this.toast.showError('User must have at least one role');
      throw new Error('User must have at least one role');
    }

    try {
      // Update basic info
      const updateData = {
        Id: this.userData.Id,
        Name: this.addUserForm.value.name,
        Title: this.addUserForm.value.title || '',
        IsActive: true
      };

      await this.api.basicPatch(`${APIEndpoints.Users}(${this.userData.Id})`, updateData);

      // Handle roles
      const rolesToRemove = originalRoleIds.filter(id => !newRoleIds.includes(id));
      const rolesToAdd = newRoleIds.filter(id => !originalRoleIds.includes(id));

      if (rolesToRemove.length > 0) {
        const removeResponse = await this.api.basicPost(
          `/users(${this.userData.Id})/RemoveUserRoles`,
          rolesToRemove
        ) as Response;
        
        if (!removeResponse.ok) {
          throw new Error('Failed to remove user roles');
        }
      }

      if (rolesToAdd.length > 0) {
        await this.addUserRoles(this.userData.Id, rolesToAdd);
      }

      // Handle rights only if there are changes
      const rightsToRemove = originalRightIds.filter(id => !newRightIds.includes(id));
      const rightsToAdd = newRightIds.filter(id => !originalRightIds.includes(id));

      if (rightsToRemove.length > 0) {
        const removeResponse = await this.api.basicPost(
          `/users(${this.userData.Id})/RemoveUserRights`,
          rightsToRemove
        ) as Response;
        
        if (!removeResponse.ok) {
          throw new Error('Failed to remove user rights');
        }
      }

      if (rightsToAdd.length > 0) {
        await this.addUserRights(this.userData.Id, rightsToAdd);
      }

      this.showSuccessMessage(updateData.Name ?? '', this.userData.Id, 'updated');
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  private async addUserRights(userId: number, rightIds: number[]): Promise<void> {
    const url = `/users(${userId})/AddUserRights`;
    const response = await this.api.basicPost(url, rightIds) as Response;
    if (!response.ok) {
      throw new Error('Failed to add user rights');
    }
  }

}
