// Angular
import { Component, Input, Output, EventEmitter, signal } from '@angular/core';
import { ReactiveFormsModule, FormBuilder, FormGroup } from '@angular/forms';
import { CommonModule } from '@angular/common';

// 3rd Party
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';

// Internal
import { ProcedureCode } from '@models/data-contracts';
import { ProcedureCodeService } from '../../../services/procedure-code/procedure-code.service';
import { Query } from '@syncfusion/ej2-data';
import { ComponentBase } from '@core/base/component.base';
import { LoadingModule } from '@shared/modules/loading.module';

@Component({
  selector: 'edit-procedure-code-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DropDownListModule,
    TextBoxModule,
    ButtonModule,
    LoadingModule
  ],
  templateUrl: './edit-procedure-code-form.component.html'
})
export class EditProcedureCodeFormComponent extends ComponentBase {
  @Input() procedureCode: ProcedureCode;
  @Input() showFormButtons = false;
  @Output() closeDialog = new EventEmitter<void>();
  @Output() refreshGrid = new EventEmitter<void>();

  form: FormGroup;
  modalityData: any;
  modalityFields = { text: 'Description', value: 'Id' };
  isSubmitting = signal(false);
  loadingMessage = 'Updating procedure code...';

  constructor(
    private fb: FormBuilder,
    private procedureCodeService: ProcedureCodeService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
    this.loadModalityTypes();
  }

  private initForm(): void {
    this.form = this.fb.group({
      Code: [this.procedureCode?.ProcedureCodeName || ''],
      Modality: [this.procedureCode?.ModalityTypeId],
      Description: [this.procedureCode?.Description || '']
    });
  }

  private async loadModalityTypes(): Promise<void> {
    try {
      const query = new Query();
      const response = await this.procedureCodeService.getModalityTypes(query);
      this.modalityData = response.result;
    } catch (error) {
      this.handleError(error, {
        context: 'EditProcedureCodeFormComponent.loadModalityTypes',
        userMessage: 'Failed to load modality types'
      });
    }
  }

  async onSubmit(): Promise<void> {
    if (!this.form.valid) return;

    try {
      this.isSubmitting.set(true);
      const updatedCode = {
        Id: this.procedureCode.Id,
        ProcedureCodeName: this.form.value.Code,
        ModalityTypeId: parseInt(this.form.value.Modality),
        Description: this.form.value.Description
      };

      await this.procedureCodeService.updateProcedureCode(updatedCode);
      this.notify('Successfully updated procedure code');
      this.form.reset();
      this.refreshGrid.emit();
      this.closeDialog.emit();
    } catch (error) {
      this.handleError(error, {
        context: 'EditProcedureCodeFormComponent.onSubmit',
        userMessage: 'Failed to update procedure code'
      });
    } finally {
      this.isSubmitting.set(false);
    }
  }

  cancel(): void {
    this.closeDialog.emit();
  }
}
