// Angular
import { effect, inject, Injectable, signal, WritableSignal } from '@angular/core';
import { Subscription } from 'rxjs';

// 3rd Party
import { DataUtil, Query, Predicate, DataManager, QueryOptions, ReturnOption } from '@syncfusion/ej2-data';
import { 
    GridComponent, 
    GridModel, 
    ColumnModel,
    SortSettingsModel,
    DataResult,
    CommandModel,
    DataStateChangeEventArgs,
    CommandClickEventArgs
} from '@syncfusion/ej2-angular-grids';

// Services
import { AuthenticatedServiceBase } from '@core/auth/auth.base'
import { FileHubService } from '@features/file-hub/services/file-hub.service';

// Models
import { APIEndpoints } from '@models/api/Endpoints';   
import { Surgery } from '@models/data-contracts';
import { SetGridDataArgs } from '@shared/components/base-grid/models/grid.models';
import { formatBoolean } from '@root/app/utils';
import { DialogModel } from '@syncfusion/ej2-popups';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { SurgeryDetailService } from './surgery-detail/surgery-detail.service';
import { ProviderTypeService } from './provider-type/provider-type.service';

// Constants
export interface SurgeriesGridStates {
    settings: WritableSignal<SetGridDataArgs | undefined>;
    caseFileId: WritableSignal<number | undefined>;
    dialog: WritableSignal<DialogModel | undefined>;
    surgeries: WritableSignal<Surgery[] | undefined>;
    selected: WritableSignal<Surgery | undefined>;
    surgeryProgressMap: Map<number, SurgeryProgress>;
}

export interface SurgeryProgress {
    selectedSegments: boolean[];
    currentStep: number;
}

export interface SurgeriesGridDefaults {
    ENDPOINT: string;
    QUERY: Query;
    PREDICATE: Predicate;
    DIALOG: DialogModel;
    PROGRESS: SurgeryProgress;
}

const DEFAULTS: SurgeriesGridDefaults = {
    ENDPOINT: APIEndpoints.Surgeries,
    QUERY: new Query()
      .expand([
        'SurgeryType($select=Description)',
        'ConfirmedBy($select=Id,ContactName)'
      ])
      .select([
        'Id', 
        'CaseFileId', 
        'SurgeryType', 
        'ProviderId', 
        'Surgeon', 
        'SurgeryDate',  
        'MissingInvoices', 
        'ConfirmedInvoices', 
        'ConfirmedById'
      ]),
    PREDICATE: new Predicate('CaseFileId', 'equal', 0),
    DIALOG: {
      header: 'Add',
      visible: false,
      isModal: true,
      showCloseIcon: true,
      buttons: [
        { buttonModel: { content: 'Cancel', cssClass: 'e-outline' }, click: (args: any) => args.cancel = true },
        { buttonModel: { content: 'Add', cssClass: 'e-primary' }, click: (args: any) => args.cancel = true }
      ]
    },
    PROGRESS: {
      selectedSegments: Array(5).fill(false),
      currentStep: 0
    }
};
export const SURGERIES_GRID_DEFAULTS: SurgeriesGridDefaults = DEFAULTS;

const ERRORS = {
    NO_CASE_FILE: {
      message: 'No case file selected',
      technical: 'Attempt to load surgeries with no case file selected'
    },
    LOAD_SURGERIES: {
      message: 'Unable to load surgeries',
      technical: 'Error loading surgeries from API'
    },
    UPDATE_SURGERIES: {
      message: 'Unable to update surgeries',
      technical: 'Error updating surgeries during state change'
    },
    DELETE_SURGERY: {
      message: 'Unable to delete surgery',
      technical: 'Error deleting surgery'
    }
};

@Injectable({   
    providedIn: 'root'
})
export class SurgeriesGridService extends AuthenticatedServiceBase {
    // Injects and readonly services
    protected readonly detailService = inject(SurgeryDetailService);
    protected readonly providerTypeService = inject(ProviderTypeService);

    // Signals
    state: SurgeriesGridStates = {
        settings: signal(undefined),
        caseFileId: signal(undefined),
        surgeries: signal(undefined),
        selected: signal(undefined),
        dialog: signal(undefined),
        surgeryProgressMap: new Map()
    };

    constructor() {
        super();
    }

    get endpoint() { return this.state.settings()?.endpoint as string; }  
    get query() { return this.state.settings()?.query as Query; }
    get surgeries() { return this.state.surgeries() as Surgery[]; }
    get settings() { return this.state.settings() as SetGridDataArgs; }

    // Load surgeries from updated case file id
    async setSurgeries(surgeries: Surgery[]) {
        this.state.surgeries.set(this.surgeries);
    }

    updateSurgeryProgress(surgeryId: number, progress: SurgeryProgress): void {
        this.state.surgeryProgressMap.set(surgeryId, progress);
    }

    async onDetailDataBound(args: any): Promise<void> {
        const rowData = args.data;
        const detailDiv = document.createElement('div');
        detailDiv.setAttribute('id', `detail-div-${rowData.id}`);
    
        const invoices = await this.detailService.getInvoicesForSurgery(rowData.id);
        const surgeryDate = new Date(rowData.SurgeryDate);
        const filteredInvoices = this.detailService.filterInvoicesByDate(invoices.result, surgeryDate);
    
        const groupedInvoices = this.groupInvoicesByProviderType(filteredInvoices, surgeryDate);
        const tableRows = this.generateTableRows(groupedInvoices);
        const amountBilled = this.calculateTotalSum(groupedInvoices);
    
        detailDiv.innerHTML = this.generateDetailContent(tableRows, amountBilled);
        ((args.detailElement as HTMLElement).querySelector('.custom-details') as HTMLElement).appendChild(detailDiv);
    }

    private groupInvoicesByProviderType(invoices: any[], surgeryDate: Date): any {
        const allProviderTypes = this.providerTypeService.getAllProviderTypes();
        const groupedInvoices = invoices.reduce((acc: any, invoice: any) => {
          const providerTypeId = invoice.SurgicalInvoiceId;
          if (!acc[providerTypeId]) {
            acc[providerTypeId] = {
              providerTypeDescription: this.providerTypeService.getProviderTypeDescription(providerTypeId),
              providerName: invoice.Provider?.Name || 'Unknown',
              amountBilled: 0,
              invoiceDate: invoice.InvoiceDate ? new Date(invoice.InvoiceDate).toLocaleDateString() : 'N/A',
              isDateMismatch: false
            };
          }
          acc[providerTypeId].amountBilled += invoice.InvoiceRows.reduce((sum: number, row: any) => sum + (row.AmountBilled || 0), 0);
    
          if (invoice.InvoiceDate && new Date(invoice.InvoiceDate).toLocaleDateString() !== surgeryDate.toLocaleDateString()) {
            acc[providerTypeId].isDateMismatch = true;
          }
    
          return acc;
        }, {});
    
        // Ensure all provider types are included
        allProviderTypes.forEach((type: any) => {
          if (!groupedInvoices[type.id]) {
            groupedInvoices[type.id] = {
              providerTypeDescription: type.description,
              providerName: 'N/A',
              amountBilled: 0,
              invoiceDate: 'N/A',
              isDateMismatch: false
            };
          }
        });
    
        return groupedInvoices;
    }

    private generateTableRows(groupedInvoices: any): string {
        let tableRows = '';

        for (const key in groupedInvoices) {
            const item = groupedInvoices[key];
            const rowStyle = item.isDateMismatch
            ? 'background-color: rgba(255, 193, 7, 0.1) !important; border-color: rgba(255, 193, 7, 0.2) !important;'
            : '';
            tableRows += `
            <tr style="${rowStyle}">
                <td style="width: 15%;">${item.providerTypeDescription}</td>
                <td style="width: 45%;">${item.providerName}</td>
                <td style="width: 25%;">${item.invoiceDate}</td>
                <td style="width: 15%;">${item.amountBilled.toLocaleString('en-US', { minimumFractionDigits: 2 })}</td>
            </tr>
            `;
        }
        return tableRows;
    }

    private calculateTotalSum(groupedInvoices: any): number {
        return Object.values(groupedInvoices).reduce((sum: number, item: any) => sum + item.amountBilled, 0);
    }

    private generateDetailContent(tableRows: string, amountBilled: number): string {
        return `
            <div class="detail-container">
            <table class="detail-table" style="width: 50%;">
                <thead>
                <tr>
                    <th>Provider Type</th>
                    <th>Provider</th>
                    <th>Date of Service</th>
                    <th>Sum of Invoices</th>
                </tr>
                </thead>
                <tbody>
                ${tableRows}
                <tr class="total-row">
                    <td colspan="3"><b>Total</b></td>
                    <td><b>$${amountBilled.toLocaleString('en-US', { minimumFractionDigits: 2 })}</b></td>
                </tr>
                </tbody>
            </table>
            </div>`;
    }
}