import { CloudWatchLogsServiceException as __BaseException } from "./CloudWatchLogsServiceException";
export class AccessDeniedException extends __BaseException {
  name = "AccessDeniedException";
  $fault = "client";
  constructor(opts) {
    super({
      name: "AccessDeniedException",
      $fault: "client",
      ...opts
    });
    Object.setPrototypeOf(this, AccessDeniedException.prototype);
  }
}
export const PolicyType = {
  DATA_PROTECTION_POLICY: "DATA_PROTECTION_POLICY",
  FIELD_INDEX_POLICY: "FIELD_INDEX_POLICY",
  SUBSCRIPTION_FILTER_POLICY: "SUBSCRIPTION_FILTER_POLICY",
  TRANSFORMER_POLICY: "TRANSFORMER_POLICY"
};
export const Scope = {
  ALL: "ALL"
};
export const State = {
  Active: "Active",
  Baseline: "Baseline",
  Suppressed: "Suppressed"
};
export const AnomalyDetectorStatus = {
  ANALYZING: "ANALYZING",
  DELETED: "DELETED",
  FAILED: "FAILED",
  INITIALIZING: "INITIALIZING",
  PAUSED: "PAUSED",
  TRAINING: "TRAINING"
};
export const EvaluationFrequency = {
  FIFTEEN_MIN: "FIFTEEN_MIN",
  FIVE_MIN: "FIVE_MIN",
  ONE_HOUR: "ONE_HOUR",
  ONE_MIN: "ONE_MIN",
  TEN_MIN: "TEN_MIN",
  THIRTY_MIN: "THIRTY_MIN"
};
export class InvalidParameterException extends __BaseException {
  name = "InvalidParameterException";
  $fault = "client";
  constructor(opts) {
    super({
      name: "InvalidParameterException",
      $fault: "client",
      ...opts
    });
    Object.setPrototypeOf(this, InvalidParameterException.prototype);
  }
}
export class OperationAbortedException extends __BaseException {
  name = "OperationAbortedException";
  $fault = "client";
  constructor(opts) {
    super({
      name: "OperationAbortedException",
      $fault: "client",
      ...opts
    });
    Object.setPrototypeOf(this, OperationAbortedException.prototype);
  }
}
export class ResourceNotFoundException extends __BaseException {
  name = "ResourceNotFoundException";
  $fault = "client";
  constructor(opts) {
    super({
      name: "ResourceNotFoundException",
      $fault: "client",
      ...opts
    });
    Object.setPrototypeOf(this, ResourceNotFoundException.prototype);
  }
}
export class ServiceUnavailableException extends __BaseException {
  name = "ServiceUnavailableException";
  $fault = "server";
  constructor(opts) {
    super({
      name: "ServiceUnavailableException",
      $fault: "server",
      ...opts
    });
    Object.setPrototypeOf(this, ServiceUnavailableException.prototype);
  }
}
export class InvalidOperationException extends __BaseException {
  name = "InvalidOperationException";
  $fault = "client";
  constructor(opts) {
    super({
      name: "InvalidOperationException",
      $fault: "client",
      ...opts
    });
    Object.setPrototypeOf(this, InvalidOperationException.prototype);
  }
}
export const OutputFormat = {
  JSON: "json",
  PARQUET: "parquet",
  PLAIN: "plain",
  RAW: "raw",
  W3C: "w3c"
};
export const DeliveryDestinationType = {
  CWL: "CWL",
  FH: "FH",
  S3: "S3"
};
export class ConflictException extends __BaseException {
  name = "ConflictException";
  $fault = "client";
  constructor(opts) {
    super({
      name: "ConflictException",
      $fault: "client",
      ...opts
    });
    Object.setPrototypeOf(this, ConflictException.prototype);
  }
}
export class ServiceQuotaExceededException extends __BaseException {
  name = "ServiceQuotaExceededException";
  $fault = "client";
  constructor(opts) {
    super({
      name: "ServiceQuotaExceededException",
      $fault: "client",
      ...opts
    });
    Object.setPrototypeOf(this, ServiceQuotaExceededException.prototype);
  }
}
export class ThrottlingException extends __BaseException {
  name = "ThrottlingException";
  $fault = "client";
  constructor(opts) {
    super({
      name: "ThrottlingException",
      $fault: "client",
      ...opts
    });
    Object.setPrototypeOf(this, ThrottlingException.prototype);
  }
}
export class ValidationException extends __BaseException {
  name = "ValidationException";
  $fault = "client";
  constructor(opts) {
    super({
      name: "ValidationException",
      $fault: "client",
      ...opts
    });
    Object.setPrototypeOf(this, ValidationException.prototype);
  }
}
export class LimitExceededException extends __BaseException {
  name = "LimitExceededException";
  $fault = "client";
  constructor(opts) {
    super({
      name: "LimitExceededException",
      $fault: "client",
      ...opts
    });
    Object.setPrototypeOf(this, LimitExceededException.prototype);
  }
}
export class ResourceAlreadyExistsException extends __BaseException {
  name = "ResourceAlreadyExistsException";
  $fault = "client";
  constructor(opts) {
    super({
      name: "ResourceAlreadyExistsException",
      $fault: "client",
      ...opts
    });
    Object.setPrototypeOf(this, ResourceAlreadyExistsException.prototype);
  }
}
export const LogGroupClass = {
  INFREQUENT_ACCESS: "INFREQUENT_ACCESS",
  STANDARD: "STANDARD"
};
export class DataAlreadyAcceptedException extends __BaseException {
  name = "DataAlreadyAcceptedException";
  $fault = "client";
  expectedSequenceToken;
  constructor(opts) {
    super({
      name: "DataAlreadyAcceptedException",
      $fault: "client",
      ...opts
    });
    Object.setPrototypeOf(this, DataAlreadyAcceptedException.prototype);
    this.expectedSequenceToken = opts.expectedSequenceToken;
  }
}
export const DataProtectionStatus = {
  ACTIVATED: "ACTIVATED",
  ARCHIVED: "ARCHIVED",
  DELETED: "DELETED",
  DISABLED: "DISABLED"
};
export const ExportTaskStatusCode = {
  CANCELLED: "CANCELLED",
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
  PENDING: "PENDING",
  PENDING_CANCEL: "PENDING_CANCEL",
  RUNNING: "RUNNING"
};
export const IndexSource = {
  ACCOUNT: "ACCOUNT",
  LOG_GROUP: "LOG_GROUP"
};
export const InheritedProperty = {
  ACCOUNT_DATA_PROTECTION: "ACCOUNT_DATA_PROTECTION"
};
export const OrderBy = {
  LastEventTime: "LastEventTime",
  LogStreamName: "LogStreamName"
};
export const StandardUnit = {
  Bits: "Bits",
  BitsSecond: "Bits/Second",
  Bytes: "Bytes",
  BytesSecond: "Bytes/Second",
  Count: "Count",
  CountSecond: "Count/Second",
  Gigabits: "Gigabits",
  GigabitsSecond: "Gigabits/Second",
  Gigabytes: "Gigabytes",
  GigabytesSecond: "Gigabytes/Second",
  Kilobits: "Kilobits",
  KilobitsSecond: "Kilobits/Second",
  Kilobytes: "Kilobytes",
  KilobytesSecond: "Kilobytes/Second",
  Megabits: "Megabits",
  MegabitsSecond: "Megabits/Second",
  Megabytes: "Megabytes",
  MegabytesSecond: "Megabytes/Second",
  Microseconds: "Microseconds",
  Milliseconds: "Milliseconds",
  None: "None",
  Percent: "Percent",
  Seconds: "Seconds",
  Terabits: "Terabits",
  TerabitsSecond: "Terabits/Second",
  Terabytes: "Terabytes",
  TerabytesSecond: "Terabytes/Second"
};
export const QueryLanguage = {
  CWLI: "CWLI",
  PPL: "PPL",
  SQL: "SQL"
};
export const QueryStatus = {
  Cancelled: "Cancelled",
  Complete: "Complete",
  Failed: "Failed",
  Running: "Running",
  Scheduled: "Scheduled",
  Timeout: "Timeout",
  Unknown: "Unknown"
};
export const Distribution = {
  ByLogStream: "ByLogStream",
  Random: "Random"
};
export const EntityRejectionErrorType = {
  ENTITY_SIZE_TOO_LARGE: "EntitySizeTooLarge",
  INVALID_ATTRIBUTES: "InvalidAttributes",
  INVALID_ENTITY: "InvalidEntity",
  INVALID_KEY_ATTRIBUTE: "InvalidKeyAttributes",
  INVALID_TYPE_VALUE: "InvalidTypeValue",
  MISSING_REQUIRED_FIELDS: "MissingRequiredFields",
  UNSUPPORTED_LOG_GROUP_TYPE: "UnsupportedLogGroupType"
};
export const FlattenedElement = {
  FIRST: "first",
  LAST: "last"
};
export const OpenSearchResourceStatusType = {
  ACTIVE: "ACTIVE",
  ERROR: "ERROR",
  NOT_FOUND: "NOT_FOUND"
};
export var IntegrationDetails;
(function (IntegrationDetails) {
  IntegrationDetails.visit = (value, visitor) => {
    if (value.openSearchIntegrationDetails !== undefined) return visitor.openSearchIntegrationDetails(value.openSearchIntegrationDetails);
    return visitor._(value.$unknown[0], value.$unknown[1]);
  };
})(IntegrationDetails || (IntegrationDetails = {}));
export const IntegrationStatus = {
  ACTIVE: "ACTIVE",
  FAILED: "FAILED",
  PROVISIONING: "PROVISIONING"
};
export const IntegrationType = {
  OPENSEARCH: "OPENSEARCH"
};
export const Type = {
  BOOLEAN: "boolean",
  DOUBLE: "double",
  INTEGER: "integer",
  STRING: "string"
};
export class InvalidSequenceTokenException extends __BaseException {
  name = "InvalidSequenceTokenException";
  $fault = "client";
  expectedSequenceToken;
  constructor(opts) {
    super({
      name: "InvalidSequenceTokenException",
      $fault: "client",
      ...opts
    });
    Object.setPrototypeOf(this, InvalidSequenceTokenException.prototype);
    this.expectedSequenceToken = opts.expectedSequenceToken;
  }
}
export const SuppressionState = {
  SUPPRESSED: "SUPPRESSED",
  UNSUPPRESSED: "UNSUPPRESSED"
};
export var ResourceConfig;
(function (ResourceConfig) {
  ResourceConfig.visit = (value, visitor) => {
    if (value.openSearchResourceConfig !== undefined) return visitor.openSearchResourceConfig(value.openSearchResourceConfig);
    return visitor._(value.$unknown[0], value.$unknown[1]);
  };
})(ResourceConfig || (ResourceConfig = {}));
export class UnrecognizedClientException extends __BaseException {
  name = "UnrecognizedClientException";
  $fault = "client";
  constructor(opts) {
    super({
      name: "UnrecognizedClientException",
      $fault: "client",
      ...opts
    });
    Object.setPrototypeOf(this, UnrecognizedClientException.prototype);
  }
}
export class SessionStreamingException extends __BaseException {
  name = "SessionStreamingException";
  $fault = "client";
  constructor(opts) {
    super({
      name: "SessionStreamingException",
      $fault: "client",
      ...opts
    });
    Object.setPrototypeOf(this, SessionStreamingException.prototype);
  }
}
export class SessionTimeoutException extends __BaseException {
  name = "SessionTimeoutException";
  $fault = "client";
  constructor(opts) {
    super({
      name: "SessionTimeoutException",
      $fault: "client",
      ...opts
    });
    Object.setPrototypeOf(this, SessionTimeoutException.prototype);
  }
}
export var StartLiveTailResponseStream;
(function (StartLiveTailResponseStream) {
  StartLiveTailResponseStream.visit = (value, visitor) => {
    if (value.sessionStart !== undefined) return visitor.sessionStart(value.sessionStart);
    if (value.sessionUpdate !== undefined) return visitor.sessionUpdate(value.sessionUpdate);
    if (value.SessionTimeoutException !== undefined) return visitor.SessionTimeoutException(value.SessionTimeoutException);
    if (value.SessionStreamingException !== undefined) return visitor.SessionStreamingException(value.SessionStreamingException);
    return visitor._(value.$unknown[0], value.$unknown[1]);
  };
})(StartLiveTailResponseStream || (StartLiveTailResponseStream = {}));
export class MalformedQueryException extends __BaseException {
  name = "MalformedQueryException";
  $fault = "client";
  queryCompileError;
  constructor(opts) {
    super({
      name: "MalformedQueryException",
      $fault: "client",
      ...opts
    });
    Object.setPrototypeOf(this, MalformedQueryException.prototype);
    this.queryCompileError = opts.queryCompileError;
  }
}
export class TooManyTagsException extends __BaseException {
  name = "TooManyTagsException";
  $fault = "client";
  resourceName;
  constructor(opts) {
    super({
      name: "TooManyTagsException",
      $fault: "client",
      ...opts
    });
    Object.setPrototypeOf(this, TooManyTagsException.prototype);
    this.resourceName = opts.resourceName;
  }
}
export const SuppressionUnit = {
  HOURS: "HOURS",
  MINUTES: "MINUTES",
  SECONDS: "SECONDS"
};
export const SuppressionType = {
  INFINITE: "INFINITE",
  LIMITED: "LIMITED"
};
export const StartLiveTailResponseStreamFilterSensitiveLog = obj => {
  if (obj.sessionStart !== undefined) return {
    sessionStart: obj.sessionStart
  };
  if (obj.sessionUpdate !== undefined) return {
    sessionUpdate: obj.sessionUpdate
  };
  if (obj.SessionTimeoutException !== undefined) return {
    SessionTimeoutException: obj.SessionTimeoutException
  };
  if (obj.SessionStreamingException !== undefined) return {
    SessionStreamingException: obj.SessionStreamingException
  };
  if (obj.$unknown !== undefined) return {
    [obj.$unknown[0]]: "UNKNOWN"
  };
};
export const StartLiveTailResponseFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.responseStream && {
    responseStream: "STREAMING_CONTENT"
  })
});